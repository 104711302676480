import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {OmsConstants} from '../../../../../../common/oms-constants.service';
import {ValueAccessorBase} from '../../../base/value-accessor';
import {checkNumber} from '../../../../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {Size} from '../../../../../../common/oms-types';

@Component({
  selector: 'oms-pieces-input',
  templateUrl: './pieces-input.component.html',
  styleUrls: ['./pieces-input.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: PiecesInputComponent, multi: true}]
})
export class PiecesInputComponent extends ValueAccessorBase<number> implements OnChanges {
  Size = Size;
  @Input() label: string = 'Quantity';
  @Input() size: Size = Size.DEFAULT;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input('units-disabled') unitsDisabled: boolean = false;
  @Input() showButton: boolean = true;
  @Input('show-label') showLabel: boolean = true;
  @Input() containers: boolean = false;
  @Input() units: string = this._constants.packagingUnits.default.label;
  @Output() unitsChange: EventEmitter<string> = new EventEmitter<string>();

  private inputValue: number; // value in visible units

  constructor(
    private cdr: ChangeDetectorRef,
    public _constants: OmsConstants) {
    super();
  }

  private toDisplay(value) {return checkNumber(value); }
  private toDefault(value) {return checkNumber(value); }


  get internal() {
    if (isNullOrUndefined(this.inputValue)) {
      this.inputValue = this.toDisplay(super.getValue());
    }
    return this.inputValue;
  }

  set internal(value) {
    if (value !== this.inputValue) {
      this.inputValue = value;
      super.setValue(this.toDefault(value));
    }
  }

  onUnitsChanged(event) {
//    console.log('Units changed ', event);
    super.setValue(this.toDefault(this.inputValue));
    this.unitsChange.next(this.units);
    this.cdr.detectChanges();
  }

  protected setValue(value: number) {
    this.inputValue = this.toDisplay(value);
//    super.setValue(value);
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['containers']) {
        console.log('Changes', changes);
        let items = this.containers ? this._constants.containerSizes.getAllLabels() : this._constants.packagingUnits.getAllLabels();
        if (items && items.length && !items.includes(this.units)) {
          this.units = items[0];
        }
        this.onUnitsChanged(this.units);

//        this.unitsChange.next(this.units);
        this.cdr.markForCheck();

    }
  }

}
