import {BaseEntity} from '../base-entity';
import {MasterAir} from './master.air';
import {Type, Exclude} from 'class-transformer';

export class Uld extends BaseEntity {
  public javaClassName: string = 'com.oms.entity.air.Uld';

  @Type(() => MasterAir)
  masterAir: MasterAir;
  uldNumber: string;
  @Type(() => Date)
  datePickupAct: Date;
  @Type(() => Date)
  dateReturnAct: Date;

  @Exclude()
  get isPickedUp(): boolean {
    return !!this.datePickupAct;
  }

  @Exclude()
  get isReturned(): boolean {
    return !!this.dateReturnAct;
  }

}

export class UldRecovery extends BaseEntity {
  @Type(() => Uld)
  public uld: Uld;

  public pcs: number;
  public hu: number;
}

