﻿import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CustomerService} from '../../../services/customer.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Address, Customer, Uld} from '../../../modules/shared/models';
import {CustomerGroupService} from '../../../services/customer.group.service';
import {NgSelectSearchParams} from '../../../modules/settings/util/ng-select-search-params';
import {CustomerGroup} from '../../../modules/shared/models/customer.group';
import {AddressService} from '../../../modules/shared/services/address.service';
import {AbstractEntityDialogComponent} from '../../../modules/settings/pages/abstract-entity-dialog.component';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {flatMap} from 'rxjs/operators';
import {CustomerEmail} from "../../../modules/shared/models/customer-email";
import {isNullOrUndefined} from "util";


@Component({
  templateUrl: 'customer.dialog.component.html',
  styleUrls: ['./customer.dialog.component.scss'],
  selector: 'oms-customer-dialog'
})
export class CustomerDialogComponent extends AbstractEntityDialogComponent<Customer> implements OnInit {

  customer: Customer = new Customer();

  customerGroupSearch: NgSelectSearchParams<CustomerGroup>;
  addressSearch: NgSelectSearchParams<Address>;
  emails = Object.values(CustomerEmail);

  constructor(protected dialogRef: MatDialogRef<CustomerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private customerService: CustomerService,
              private customerGroupService: CustomerGroupService,
              private addressService: AddressService,
              protected alerts: OmsAlertsService
  ) {
    super(dialogRef, customerService, alerts);
  }

  ngOnInit() {
    this.viewLoading = true;
    this.customerGroupSearch = new NgSelectSearchParams<CustomerGroup>(this.customerGroupService);
    this.addressSearch = new NgSelectSearchParams<Address>(this.addressService);
    this.customer = Object.assign(this.customer, this.data.customer);
    this.createForm();
    this.viewLoading = false;
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.customer.name, Validators.required],
      address: [this.customer.address],
      customerGroup: [this.customer.customerGroup, Validators.required],
      email: [this.customer.emails],
      createBOL: [this.customer.createBOL],
      sendPODToEmails: [this.customer.sendPODToEmails]
    });
  }

  getTitle(): string {
    if (this.customer.id > 0) {
      return 'Edit Customer';
    }
    return 'New Customer';
  }

  prepare(): Customer {
    const controls = this.form.controls;
    const _customer = this.customer;
    _customer.name = controls['name'].value;
    _customer.customerGroup = controls['customerGroup'].value;
    _customer.address = controls['address'].value;

    let emailValues = controls['email'].value;
    if (emailValues) {
      let updateCustomerEmails = _customer.emails;
      _customer.emails = [];
      emailValues.forEach((emailValue, index) => {
        if (!isNullOrUndefined(emailValue)) {
          if (updateCustomerEmails && updateCustomerEmails.length > index) {
            let customerEmail = updateCustomerEmails[index];
            customerEmail.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
            _customer.emails.push(customerEmail);
          } else {
            let email = new CustomerEmail();
            email.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
            _customer.emails.push(email);
          }
        }
      });
    } else {
      _customer.emails = [];
    }
    _customer.createBOL = controls['createBOL'].value;
    _customer.sendPODToEmails = controls['sendPODToEmails'].value;

    return _customer;
  }

  update(item: Customer) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    console.log("UPDATE", item);
    let cg: CustomerGroup;
    if (typeof item.customerGroup === 'string') {
      cg = new CustomerGroup();
      cg.name = item.customerGroup;
    } else
      cg = Object.assign(new CustomerGroup(), item.customerGroup);

//    let cg: CustomerGroup = typeof item.customerGroup === 'string' ? {name: item.customerGroup} : Object.assign(new CustomerGroup(), item.customerGroup);
    this.customerGroupService.save(cg)
      .pipe(flatMap(customerGroup => {
        item.customerGroup = customerGroup;
        return this.customerService.save(item);
      }))
      .subscribe(res => {
        this.viewLoading = false;
        this.dialogRef.close({res, isEdit: !item.isNew()});
      }, error => {
        this.viewLoading = false;
        this.alerts.danger(error);
      });
  }
}
