/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./wizard-header.component";
import * as i5 from "../../../services/wizard.header.config.service";
var styles_WizardHeaderComponent = [i0.styles];
var RenderType_WizardHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardHeaderComponent, data: {} });
export { RenderType_WizardHeaderComponent as RenderType_WizardHeaderComponent };
function View_WizardHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "badge badge-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "chevron"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isActive(_v.context.$implicit.href) ? "active" : ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.href; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.index + 1); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_3); }); }
function View_WizardHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "widget-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "wizard-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "wizard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "steps"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardHeaderComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentHeaders.steps; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_WizardHeaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WizardHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wizard-header", [], null, null, null, View_WizardHeaderComponent_0, RenderType_WizardHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.WizardHeaderComponent, [i1.ChangeDetectorRef, i3.Router, i5.WizardHeaderConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WizardHeaderComponentNgFactory = i1.ɵccf("wizard-header", i4.WizardHeaderComponent, View_WizardHeaderComponent_Host_0, {}, {}, []);
export { WizardHeaderComponentNgFactory as WizardHeaderComponentNgFactory };
