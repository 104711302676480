<span class="dropdown" dropdown container="body" placement="right">

  <button *ngIf="toggle"
          type="button"
          class="btn dropdown-toggle" [class]="btnClass"
          [ngClass]="{'disabled': disabled, 'btn-sm': size == Size.SMALL, 'btn-lg': size == Size.LARGE}"
          [attr.disabled]="disabled ? 'disabled':null"
          (click)="onSelectToggle()">{{selectedLabel}}<span class="caret" style="margin-left: 5px"></span>
  </button>

  <button *ngIf="!toggle"
          id="dropdown-button" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          class="btn dropdown-toggle" [class]="btnClass"
          [ngClass]="{'disabled': disabled, 'btn-sm': size == Size.SMALL, 'btn-lg': size == Size.LARGE}"
          [attr.disabled]="disabled ? 'disabled':''"
          aria-controls="dropdown-panel" dropdownToggle>{{selectedLabel}}<span class="caret" style="margin-left: 5px"></span>
  </button>

  <ul id="dropdown-panel" *dropdownMenu role="menu" aria-labelledby="dropdown-button"
      class="multiselect-container dropdown-menu dropdown-menu-right" style="top:15px;">
    <ng-container *ngFor="let item of items">
      <li role="menuitem" [ngClass]="{'active':isActive(item)}">
        <a (click)="onSelect(item)">
          <label class="radio"><input type="radio">{{labelOf(item)}}</label>
        </a>
      </li>
    </ng-container>
  </ul>
</span>
