import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {BaseInputComponent} from "../../base/base-input/base-input.component";

@Component({
  selector: 'oms-toggle-switch',
//  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,

  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: ToggleSwitchComponent, multi: true}]})

export class ToggleSwitchComponent extends BaseInputComponent<boolean> {
//  protected static current_id = 0;


  @Input() type: 'yes-no' | 'on-off' | 'yes-empty' | 'no-empty' = 'yes-no';
  @Input() marks: boolean = false;

  @Input() public showLabel: boolean = true;
  @Input() label: string;
  @Input() disabled: boolean = false;
  @ViewChild(NgModel) model: NgModel;
  @Input() set checked(value: boolean) { this.setValue(value); }
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

//  public identifier = `oms-toggle-${ToggleSwitchComponent.current_id++}`;


  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  onChange(event) {
    this.checkedChange.emit(event);
  }

}
