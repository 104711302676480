<div id="gritter-notice-wrapper" class="top-left">
  <ng-container *ngFor="let alert of (alerts$ | async) as alerts">
    <bs-alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout"
              (onClosed)="onAlertClosed(alert)">
      <!--a class="gritter-close" href="#" tabindex="1" style="display: block;">Close Notification</a-->
      <div class="gritter-without-image">
        <span class="gritter-title" style="text-shadow: none"><i [class]="alert.icon"></i> {{alert.title}}</span>
        <p style="white-space: pre-line;">
          {{alert.message}}
        </p>

        <a *ngIf="alert.stack" (click)="copyToClipboard(alert.message, alert.stack)" class="btn-copy-details">
          <i class="fa fa-clipboard">&nbsp;</i>Copy Details&hellip;
        </a>

        <div *ngIf="alert.progress">
          <label>{{alert.progress.status}}</label>
          <div class="progress progress-striped active" *ngIf="!alert.progress.completed && !alert.progress.error">
            <div class="progress-bar" [style.width]="alert.progress.percents+'%'">{{alert.progress.percents}}%</div>
          </div>
        </div>
      </div>

      <div style="clear:both">
      </div>
    </bs-alert>
  </ng-container>
</div>

<ng-container>
  <div id="gritter-notice-wrapper" class="oms-alerts__panel bottom-right"
       *ngIf="showNotifications && displayMessage"
       #messagesPanel
       [hidden]="!isOpen">
    <div class="oms-alerts__panel-inner">
      <ng-container *ngFor="let message of messages; trackBy: createTrackBy('id')">

        <ng-container *ngIf="!message.componentType">
          <bs-alert
            [type]="message.type"
            [dismissible]="true"
            [dismissOnTimeout]="message.timeout"
            (onClosed)="onMessageClosed(message)">

            <div class="gritter-without-image">
            <span class="gritter-title" style="text-shadow: none"><i
              [class]="message.icon"></i> {{message.title}}</span>
              <p style="white-space: pre-line;">
                {{message.message}}
              </p>

              <div *ngIf="message.progress">
                <label>{{message.progress.status}}</label>
                <div class="progress progress-striped active"
                     *ngIf="!message.progress.completed && !message.progress.error">
                  <div class="progress-bar" [style.width]="message.progress.percents+'%'">{{message.progress.percents}}%
                  </div>
                </div>
              </div>
            </div>

            <div style="clear:both">
            </div>

          </bs-alert>
        </ng-container>

        <ng-container *ngIf="message.componentType">
          <oms-dynamic-injector [component]="message.componentType"
                                [data]="message.componentData"
                                (close)="onMessageClosed(message)"></oms-dynamic-injector>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<div *ngIf="showNotifications && displayMessage"
     class="oms-alerts__collapse-expand" (click)="toggleOpen()">
  <a href="#" class="dropdown-toggle" data-toggle="dropdown">
    <i class="oms-alerts__collapse-expand-icon fa fa-bell" [ngClass]="{'has-new': hasNew}"></i>
  </a>
</div>
