var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { isNullOrUndefined } from "util";
var RestService = /** @class */ (function () {
    function RestService(window, http) {
        this.window = window;
        this.http = http;
        this.serverUrl = environment.apiUrl;
        console.log('CURRENT HOST', this.serverUrl);
        if (!this.serverUrl || this.serverUrl.length === 0 || this.serverUrl === 'https://' || this.serverUrl === 'http://') {
            console.log('Detect Host', this.window.location.host);
            this.serverUrl =
                this.window.location.protocol + '//' +
                    this.window.location.host; // +
            //        (this.window.location.port ? (':' + this.window.location.port) : '' );
            environment.apiUrl = this.serverUrl;
        }
        console.log('API URL>>', environment.apiUrl, 'HOST >>', this.serverUrl);
    }
    RestService.prototype.getWithHeader = function (url, headers) {
        return this.http.get(this.serverUrl + url + '/', { headers: headers, withCredentials: true });
    };
    RestService.prototype.getWithOptions = function (url, httpHeaders, httpParams) {
        var params = null;
        var isHttpParams = httpParams && httpParams instanceof HttpParams;
        if (httpParams && !isHttpParams) {
            params = this.removeBlankProperty(httpParams);
        }
        else if (httpParams && isHttpParams) {
            params = this.removeBlankPropertyFromHttp(httpParams);
        }
        return this.http.get(this.serverUrl + url, {
            headers: httpHeaders,
            params: params,
            withCredentials: true
        });
    };
    RestService.prototype.removeBlankProperty = function (object) {
        var params = {};
        Object.keys(object).forEach(function (key) {
            if (!isNullOrUndefined(object[key])) {
                params[key] = object[key];
            }
        });
        return params;
    };
    RestService.prototype.removeBlankPropertyFromHttp = function (httpParams) {
        httpParams.keys().forEach(function (key) {
            if (isNullOrUndefined(httpParams.get(key))) {
                httpParams.delete(key);
            }
        });
        return httpParams;
    };
    RestService.prototype.buildUrl = function (url) {
        var parameters = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            parameters[_i - 1] = arguments[_i];
        }
        return this.serverUrl + url + (parameters && parameters.length ? '/' + parameters.join("/") : '');
    };
    RestService.prototype.encodeQueryData = function (data) {
        return '?' + Object.keys(data)
            .filter(function (key) { return !!data[key]; })
            .map(function (key) { return [key, data[key]].map(encodeURIComponent).join('='); })
            .join('&');
    };
    RestService.prototype.buildUrlForDownload = function (url, fileType) {
        return this.serverUrl + url + fileType;
    };
    RestService.prototype.get = function (url) {
        var parameters = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            parameters[_i - 1] = arguments[_i];
        }
        return this.http.get(this.serverUrl + url + '/' + parameters.join("/") + '/', { withCredentials: true });
    };
    RestService.prototype.post = function (url, body, options) {
        var extendedOptions = Object.assign({ withCredentials: true }, options);
        return this.http.post(this.serverUrl + url, body, extendedOptions);
    };
    RestService.prototype.postWithHeader = function (url, httpHeader, parameter) {
        return this.http.post(this.serverUrl + url, parameter, { headers: httpHeader, withCredentials: true });
    };
    RestService.prototype.delete = function (url) {
        var parameters = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            parameters[_i - 1] = arguments[_i];
        }
        return this.http.delete(this.serverUrl + url + '/' + parameters.join("/"), { withCredentials: true });
    };
    RestService.prototype.deleteWithOptions = function (url, options) {
        options = options || {};
        options = __assign({}, options, { withCredentials: true });
        return this.http.delete(this.serverUrl + url, options);
    };
    RestService.prototype.getHttpParamsFromObject = function (params) {
        if (params) {
            var p_1 = new HttpParams();
            Object.keys(params).forEach(function (key) {
                p_1 = p_1.append(key, JSON.stringify(params[key]));
            });
            return p_1;
        }
    };
    RestService.prototype.put = function (url, body, httpHeader, params) {
        if (body === void 0) { body = ''; }
        var p = params && params instanceof HttpParams ? params : this.getHttpParamsFromObject(params);
        return this.http.put(this.serverUrl + url, body, {
            headers: httpHeader,
            params: p,
            withCredentials: true
        });
    };
    RestService.prototype.requestQuery = function (method, url, data, options) {
        var req = new HttpRequest(method, this.serverUrl + url, data, Object.assign({
            reportProgress: true,
            responseType: 'text',
            withCredentials: true
        }, options || {}));
        return this.http.request(req);
    };
    RestService.prototype._getAuth = function (url) {
        var _this = this;
        return new Observable(function (observer) {
            var objectUrl = null;
            _this.http
                .get(url, {
                headers: new HttpHeaders().append('JSESSIONID', '123'),
                responseType: 'blob'
            }).subscribe(function (m) {
                console.log('BLOB', m);
                objectUrl = URL.createObjectURL(m);
                console.log('objectUrl', objectUrl);
                observer.next(objectUrl);
            });
            return function () {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    };
    return RestService;
}());
export { RestService };
