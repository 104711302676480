import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
var SlidePanelComponent = /** @class */ (function () {
    function SlidePanelComponent(cdr, router) {
        var _this = this;
        this.cdr = cdr;
        this.router = router;
        this.activePane = 'left';
        this.unsubscribe$ = new Subject();
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(function () { return _this.cdr.markForCheck(); });
    }
    Object.defineProperty(SlidePanelComponent.prototype, "isLeft", {
        get: function () {
            return this.activePane === 'left';
        },
        enumerable: true,
        configurable: true
    });
    SlidePanelComponent.prototype.ngOnInit = function () {
    };
    SlidePanelComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return SlidePanelComponent;
}());
export { SlidePanelComponent };
