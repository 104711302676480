var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { RestService } from "./rest.service";
import { Customer } from "../modules/shared/models";
import { HttpUtilsService } from "./http-utils.service";
import { plainToClass } from 'class-transformer';
import { BaseEntityService } from './base/base-entity.service';
var CustomerService = /** @class */ (function (_super) {
    __extends(CustomerService, _super);
    function CustomerService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/customer/';
        _this.classType = Customer;
        _this.customerSubject = new Subject();
        _this.customerPopUpEvent = new Subject();
        return _this;
    }
    CustomerService.prototype.showCustomerPopUp = function (item) {
        this.customerPopUpEvent.next({
            show: true,
            customer: item
        });
    };
    CustomerService.prototype.hideCustomerPopUp = function () {
        this.customerPopUpEvent.next({
            show: false,
            customer: null
        });
    };
    CustomerService.prototype.getAllCustomers = function () {
        var _this = this;
        this.findAll().then(function (items) { return _this.customerSubject.next(items); });
    };
    CustomerService.prototype.getAllCustomersByCustomerGroup = function (customerGroupId) {
        var _this = this;
        this.httpRestService.get(this.apiUrl + 'getAllByCustomerGroupId', customerGroupId).subscribe(function (items) {
            _this.customerSubject.next(plainToClass(Customer, items));
        });
    };
    return CustomerService;
}(BaseEntityService));
export { CustomerService };
