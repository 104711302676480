﻿<oms-spinner [message]="loadingMessage" [show]="!!loadingMessage">
  <perfect-scrollbar style="max-height: 90vh;">
    <div class="oms-modal widget" [ngClass]="{ '_loading' : !!loadingMessage, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">
      <!--mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner-->

      <div class="widget-header">
        <h3><i class="fa fa-industry"></i>
          {{getTitle()}}
        </h3>
        <div class="btn-group widget-header-toolbar">
          <a class="btn-borderless btn-remove" (click)="closeDialog()">
            <i class="fa fa-times dialog-header-button"></i>
          </a>
        </div>
      </div>


      <form class="widget-content" [formGroup]="form">

        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">FORWARDER / BROKER</label>
                <input class="form-control" placeholder="Forwarder Name" formControlName="forwarder" />
                <div *ngIf="isControlInvalid('forwarder')" class="invalid-feedback">Forwarder is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Customer</label>
                <input class="form-control" placeholder="Customer Name" formControlName="customer" />
                <div *ngIf="isControlInvalid('customer')" class="invalid-feedback">Customer is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Other</label>
                <input class="form-control" placeholder="Other" formControlName="other" maxlength="70" />
                <div *ngIf="isControlInvalid('other')" class="invalid-feedback">Other is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Airport of Destination</label>
                <input class="form-control" placeholder="Airport of Destination" formControlName="airportOfDestination" />
                <div *ngIf="isControlInvalid('airportOfDestination')" class="invalid-feedback">Airport of Destination is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Airline</label>
                <input class="form-control" placeholder="Airline Name" formControlName="airline" />
                <div *ngIf="isControlInvalid('airline')" class="invalid-feedback">Airline is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Flight#</label>
                <input class="form-control" placeholder="Flight" formControlName="flight" />
                <div *ngIf="isControlInvalid('flight')" class="invalid-feedback">Flight# is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <oms-date-input [size]="Size.SMALL" label="Arrival Date"
                                [show-checkbox]="false" [show-switch]="false"
                                [ngModel]="arrivalOrEstDate"
                                [estimation]="true"
                                formControlName="arrivalDate"></oms-date-input>
                <div *ngIf="isControlInvalid('arrivalDate')" class="invalid-feedback">Arrival Date is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Airport of Origin</label>
                <input class="form-control" placeholder="Airport of Origin" formControlName="airportOfOrigin" />
                <div *ngIf="isControlInvalid('airportOfOrigin')" class="invalid-feedback">Airport of Origin is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">MAWB #</label>
                <input class="form-control" placeholder="MAWB" formControlName="mawb" />
                <div *ngIf="isControlInvalid('mawb')" class="invalid-feedback">MAWB is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="input-group">
              <label class="control-label">Total AMS Pieces</label>
              <input class="form-control" placeholder="Total AMS Pieces" formControlName="totalAMSPieces" />
              <div *ngIf="isControlInvalid('totalAMSPieces')" class="invalid-feedback">Total AMS Pieces is required</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Total KG</label>
                <input class="form-control" placeholder="Total KG" formControlName="totalKG" />
                <div *ngIf="isControlInvalid('totalKG')" class="invalid-feedback">Total KG is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Count of hawb(s)</label>
                <input class="form-control" placeholder="Count of hawb(s)" formControlName="countOfHawbs" />
                <div *ngIf="isControlInvalid('countOfHawbs')" class="invalid-feedback">Count of hawb(s) is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 no-left-right-padding">
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo1" />
                  <div *ngIf="isControlInvalid('uldInfo1')" class="invalid-feedback">{{getErrorMessage('uldInfo1')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo2" />
                  <div *ngIf="isControlInvalid('uldInfo2')" class="invalid-feedback">{{getErrorMessage('uldInfo2')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo3" />
                  <div *ngIf="isControlInvalid('uldInfo3')" class="invalid-feedback">{{getErrorMessage('uldInfo3')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo4" />
                  <div *ngIf="isControlInvalid('uldInfo4')" class="invalid-feedback">{{getErrorMessage('uldInfo4')}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 no-left-right-padding">
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo5" />
                  <div *ngIf="isControlInvalid('uldInfo5')" class="invalid-feedback">{{getErrorMessage('uldInfo5')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo6" />
                  <div *ngIf="isControlInvalid('uldInfo6')" class="invalid-feedback">{{getErrorMessage('uldInfo6')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo7" />
                  <div *ngIf="isControlInvalid('uldInfo7')" class="invalid-feedback">{{getErrorMessage('uldInfo7')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo8" />
                  <div *ngIf="isControlInvalid('uldInfo8')" class="invalid-feedback">{{getErrorMessage('uldInfo8')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 no-left-right-padding">
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo9" />
                  <div *ngIf="isControlInvalid('uldInfo9')" class="invalid-feedback">{{getErrorMessage('uldInfo9')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo10" />
                  <div *ngIf="isControlInvalid('uldInfo10')" class="invalid-feedback">{{getErrorMessage('uldInfo10')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo11" />
                  <div *ngIf="isControlInvalid('uldInfo11')" class="invalid-feedback">{{getErrorMessage('uldInfo11')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo12" />
                  <div *ngIf="isControlInvalid('uldInfo12')" class="invalid-feedback">{{getErrorMessage('uldInfo12')}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 no-left-right-padding">
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo13" />
                  <div *ngIf="isControlInvalid('uldInfo13')" class="invalid-feedback">{{getErrorMessage('uldInfo13')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo14" />
                  <div *ngIf="isControlInvalid('uldInfo14')" class="invalid-feedback">{{getErrorMessage('uldInfo14')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo15" />
                  <div *ngIf="isControlInvalid('uldInfo15')" class="invalid-feedback">{{getErrorMessage('uldInfo15')}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-row">
                <div class="input-group">
                  <input class="form-control" placeholder="Uld Info" formControlName="uldInfo16" />
                  <div *ngIf="isControlInvalid('uldInfo16')" class="invalid-feedback">{{getErrorMessage('uldInfo16')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Name</label>
                <input class="form-control" placeholder="Name" formControlName="containerSectionName" />
                <div *ngIf="isControlInvalid('containerSectionName')" class="invalid-feedback">Name is required</div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
         <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">
                  To Address&nbsp;
                  <oms-checkbox [condensed]="true" [label]="'EAJ4 (SARCONA)'" [(checked)]="isSarcona" (checkedChange)="initCfsProperties(isSarcona)"></oms-checkbox>
                </label>
                <input class="form-control" placeholder="Address first line" formControlName="address1" />
              </div>
            </div>

            <div class="form-row">
              <div class="input-group">
                <input class="form-control" placeholder="Address second line" formControlName="address2" />
              </div>
            </div>

            <div class="form-row">
              <div class="input-group">
                <input class="form-control" placeholder="Address third line" formControlName="address3" />
              </div>
            </div>
          </div>


          <div class="col-md-6">
            <div class="form-row">
              <div class="input-group">
                <label class="control-label">Comment</label>
                <input class="form-control" placeholder="Comment" formControlName="comment" />
              </div>
            </div>
          </div>
        </div>



      </form>

      <div class="widget-footer" *ngIf="generatedDocumentInfo">
        The PTT was saved as a Master <a (click)="buildMasterUrl()" >({{this.master.id}})</a> document and opened in a new tab.
      </div>

      <div class="widget-footer right">
        <button type="button" class="btn btn-primary" (click)="onSubmit()">
          <!--span *ngIf="viewLoading"><i class="fa fa-circle-o-notch fa-spin"></i>Loading...</span-->
          <span *ngIf="!loadingMessage">Generate&hellip;</span>
        </button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Close</button>
      </div>

    </div>
  </perfect-scrollbar>
</oms-spinner>
