var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { BaseEntity } from "../base-entity";
import { Transform, Type } from "class-transformer";
import { Order } from "../order";
import { User } from "../user";
import { MasterStatus, transformMasterStatus } from "..";
var ɵ0 = function () { return Order; }, ɵ1 = function () { return User; }, ɵ2 = function () { return MasterStatus; }, ɵ3 = function (v, o, tt) { return v.map(function (item) { return transformMasterStatus(item, o, tt); }); };
var OrderSubscription = /** @class */ (function (_super) {
    __extends(OrderSubscription, _super);
    function OrderSubscription() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rowId = OrderSubscription.ROW_ID;
        _this.additionalStatuses = [];
        return _this;
    }
    OrderSubscription.prototype.trackBy = function () {
        return this.id;
    };
    OrderSubscription.ROW_ID = 'OrderSubscription';
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Order)
    ], OrderSubscription.prototype, "order", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", User)
    ], OrderSubscription.prototype, "user", void 0);
    __decorate([
        Type(ɵ2),
        Transform(ɵ3),
        __metadata("design:type", Array)
    ], OrderSubscription.prototype, "statuses", void 0);
    return OrderSubscription;
}(BaseEntity));
export { OrderSubscription };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
