import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, Validators} from '@angular/forms';
import {OmsAlertsService} from '../../../../../../shared/components/oms-alerts/oms-alerts.service';
import {AbstractEntityDialogComponent} from '../../../../abstract-entity-dialog.component';
import {Carrier} from '../../../../../../shared/models/carrier';
import {CarrierService} from '../../../../../../shared/services/carrier.service';

@Component({
  selector: 'oms-carrier-dialog',
  templateUrl: 'carrier-dialog.component.html',
  styleUrls: ['./carrier-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarrierDialogComponent extends AbstractEntityDialogComponent<Carrier> implements OnInit, OnChanges {

  @Input()
  carrier: Carrier;

  @Input()
  displayInHouse: boolean = false;

  constructor(public dialogRef: MatDialogRef<CarrierDialogComponent>,
              private fb: FormBuilder,
              private carrierService: CarrierService,
              public alerts: OmsAlertsService) {
    super(dialogRef, carrierService, alerts);
  }

  ngOnInit() {
    this.carrier = this.carrier || new Carrier();
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.carrier) {
      this.carrier = this.carrier
        ? Object.assign(new Carrier(), this.carrier)
        : new Carrier();
      this.edit = !this.carrier.isNew();
    }
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.carrier.name, [Validators.required]],
      scacCode: [this.carrier.scacCode, [Validators.required]],
      inHouse: [this.carrier.inHouse]
    });
  }

  getTitle(): string {
    return this.carrier.isNew() ? 'New carrier' : 'Carrier';
  }

  prepare(): Carrier {
    const controls = this.form.controls;
    this.carrier.name = controls['name'].value;
    this.carrier.scacCode = controls['scacCode'].value;
    this.carrier.inHouse = controls['inHouse'].value;

    return this.carrier;
  }
}
