export enum ColumnIds {
  MASTER_ID = 1,
  MASTER_MAWB = 2,
  MASTER_HAWB = 3,
  MASTER_CUSTOMER = 4,
  MASTER_HU = 5,
  MASTER_PCS = 6,
  MASTER_WEIGHT = 7,
  MASTER_VOLUME = 8,
  MASTER_STATUS = 9,
  MASTER_FILL_STATUS = 234,
  MASTER_PROBLEM = 10,
  MASTER_AIRLINE = 11,
  MASTER_FLIGHT = 12,
  MASTER_CARGO_BLD = 13,
  MASTER_DELIVERY_LOCATION = 14,
  MASTER_ARRIVAL_DATE = 15,
  MASTER_LFD = 16,
  MASTER_ISC = 17,
  MASTER_1C = 18,
  MASTER_1F = 19,
  MASTER_PICKUP_DATE = 20,
  MASTER_DELIVERY_DATE = 21,
  MASTER_DOCS = 22,
  MASTER_OSD = 23,
  MASTER_HAZ = 24,
  MASTER_COM = 25,
  MASTER_DATE_CREATED = 26,
  MASTER_DATE_CFS_IN = 27,
  MASTER_DATE_CFS_OUT = 28,
  MASTER_FREIGHT_FORWARDER = 29,
  MASTER_FREIGHT_FORWARDER_REF = 35,
  MASTER_DELIVERY_APPROVAL = 30,
  MASTER_SPLIT_NUMBER = 31,
  MASTER_PDF = 33,
  MASTER_COLOR_STATUS = 34,
  MASTER_DATE_CFS_FREE_TIME = 36,
  MASTER_CBP_STATUS = 37,
  MASTER_CREATED_BY = 38,
  MASTER_REQUESTED_BY = 78,
  MASTER_AIR_ULD_COUNT = 39,
  MASTER_DATE_DELETED = 55,
  MASTER_DIRECT = 71,
  MASTER_NON_AMS = 72,
  MASTER_IS_CARGO = 73,
  MASTER_IS_3D_PARTY_CFS = 74,
  MASTER_PENDING_RECOVERIES = 76,
  MASTER_DATE_BILLED = 77,

  ORDER_FILL_STATUS = 143,
  LOAD_DISPATCH = 56,
  MASTER_GENERATE_PTT = 57,
  MASTER_PTT_CREATED = 58,
  MASTER_DISPATCHED_STATUS = 59,
  MASTER_HAS_PROBLEM = 60,

  ORDER_ID = 1,
  ORDER_MAWB = 2,
  ORDER_HAWB = 3,
  ORDER_CARGO_BLD = 4,
  ORDER_DELIVERY_LOCATION = 5,
  ORDER_HU = 6,
  ORDER_PCS = 7,
  ORDER_PCS_RCVD = 50,
  ORDER_WEIGHT = 8,
  ORDER_VOLUME = 9,
  ORDER_CUSTOMER = 10,
  ORDER_PICKUP_DATE = 11,
  ORDER_DELIVERY_DATE = 12,
  ORDER_ARRIVAL_DATE = 13,
  ORDER_LFD = 14,
  ORDER_ISC = 15,
  ORDER_1C = 16,
  ORDER_1F = 17,
  ORDER_BILLED = 18,
  ORDER_DATE_CREATED = 19,
  ORDER_DATE_CFS_IN = 20,
  ORDER_DATE_CFS_OUT = 21,
  ORDER_CUSTOMER_REF = 22,
  ORDER_PART = 23,
  ORDER_FLIGHT = 25,
  ORDER_DATE_CFS_FREE_TIME = 26,
  ORDER_CBP_STATUS = 37,
  ORDER_DELIVERY_APPROVAL = 38,
  ORDER_CREATED_BY = 39,
  ORDER_REQUESTED_BY = 79,

  SPLIT_MAWB = 24,
  SPLIT_HAWB = 25,
  SPLIT_CUSTOMER = 26,
  SPLIT_FLIGHT = 27,
  SPLIT_CARGO_BLD = 28,
  SPLIT_DELIVERY_LOCATION = 29,
  SPLIT_ARRIVAL_DATE = 30,
  SPLIT_LFD = 31,


  LOAD_SPLIT_NUMBER = 32,
  LOAD_PCS_RCVD = 40,
  LOAD_DRIVER = 60,

  ADDRESS_NAME = 39,
  ADDRESS_LINES = 41,
  ADDRESS_REGION = 42,
  ADDRESS_COUNTRY = 43,
  ADDRESS_CITY = 44,
  ADDRESS_STATE = 45,
  ADDRESS_ZIP = 46,
  ADDRESS_PHONE = 47,
  ADDRESS_IS_CARGO_BUILDING = 48,
  ADDRESS_IS_CFS = 49,
  ADDRESS_IS_CFS_3PL = 51,
  ADDRESS_IS_PICKUP_LOCATION = 52,
  ADDRESS_IS_DELIVERY_LOCATION = 53,
  ADDRESS_IS_ARCHIVED = 54,

  USER_EMAIL = 58,
  USER_PASSWORD = 59,
  USER_FIRSTNAME = 61,
  USER_LASTNAME = 62,
  USER_ACTIVE = 63,
  USER_ROLE = 64,
  USER__IS_ARCHIVED = 65,
}
