var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Logger } from "../../../../../../../_helpers/logger";
import { isNullOrUndefined } from "util";
import { isEmptyString } from "../../../../../../../_helpers/utils";
import { DataTableComponent } from "../../data-table.component";
import { composeMawbTrackLink, convertAddress, convertLoadNumber, convertMawbNumber, convertOrderNumber, getInvalidMAWBMessage, validMAWB } from "../../../../../services/oms-converters.service";
import { GenericTableCell } from "../mawb-table-cell/generic-table-cell";
import { LoadType, LoadTypes } from "../../../../../models";
import { ArrayUtils } from "../../../../../../../_helpers/array.utils";
import { ContextMenuService } from "ngx-contextmenu";
import { DispatchService } from "../../../../../services/dispatch/dispatch.service";
import { ManifestItemContextMenuComponent } from "../../../../../../dispatch/pages/manifest-create/manifest-item-context-menu/manifest-item-context-menu.component";
import { tap } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { NotesCenterDialogComponent } from "../../../../../../../components/common/notes-center-dialog";
import { OmsConstants } from "../../../../../../../common/oms-constants.service";
import { DateTimeService } from "../../../../../../../services/date-time.service";
import { OmsDialogsService } from "../../../../../../../components/common/oms-dialogs";
var DriverRoutesLoadsTableCellComponent = /** @class */ (function (_super) {
    __extends(DriverRoutesLoadsTableCellComponent, _super);
    function DriverRoutesLoadsTableCellComponent(table, cdr, contextMenuService, dispatchService, dialog, dialogs, dateTimeService) {
        var _this = _super.call(this) || this;
        _this.table = table;
        _this.cdr = cdr;
        _this.contextMenuService = contextMenuService;
        _this.dispatchService = dispatchService;
        _this.dialog = dialog;
        _this.dialogs = dialogs;
        _this.dateTimeService = dateTimeService;
        _this.editing = false;
        _this.editOnFocus = false;
        _this.closeOnBlur = true;
        _this.displayLoads = [];
        _this.manifest = null;
        _this.manifestItem = null;
        _this.convertOrderNumber = function (orderId, isRecovery) { return convertOrderNumber(orderId, isRecovery); };
        _this.convertLoadNumber = function (id) { return convertLoadNumber(id); };
        return _this;
    }
    DriverRoutesLoadsTableCellComponent.prototype.ngOnChanges = function (changes) {
        //    console.log('CHANGE CELL', changes);
        if (changes.column) {
            this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
        }
        if (changes.row || changes.column || changes.search) {
            this.updateObject();
        }
    };
    DriverRoutesLoadsTableCellComponent.prototype.updateObject = function () {
        _super.prototype.updateObject.call(this);
        this.rowObject = this.column.rowObject(this.row);
        this.rawValue = this.column.getValue(this.rowObject);
        this.editable = this.column.editable(this.row);
        this.value = this.convert(this.rowObject, this.rawValue);
        this.empty = isNullOrUndefined(this.rawValue);
        this.iconClass = this.column.getIconClass(this.rowObject, this.rawValue);
        this.cellClass = this.column.getClass(this.rowObject, this.rawValue) || '';
        this.hasIcon = !isNullOrUndefined(this.iconClass);
        this.tooltip = this.column.getTooltip(this.rowObject);
        var editor = this.editor;
        this.placeholder = typeof editor.placeholder === 'function' ? editor.placeholder(this.row, this.rawValue) : editor.placeholder || '';
        this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);
        this.display = this.column.editable && isEmptyString(this.value) ? this.table.emptyValue : this.value;
        this.cellButtonIcon = validMAWB(this.rawValue) ? 'fa fa-up-right-from-square' : 'fa fa-exclamation-triangle red-font';
        this.cellButtonTooltip = getInvalidMAWBMessage(this.rawValue) || 'Track MAWB';
        this.cellButtonLink = composeMawbTrackLink(this.rawValue);
        this.displayLoads = (this.row ? ArrayUtils.removeDuplicate(this.row.loads, function (l) { return l.dispatchItemId; }) : [])
            .sort(function (l1, l2) { return l1.dispatchItemOrderNumber - l2.dispatchItemOrderNumber; });
        this.cdr.markForCheck();
    };
    DriverRoutesLoadsTableCellComponent.prototype.convert = function (rowObject, rawValue) {
        // return this.column.convert(rowObject, rawValue);
        return convertMawbNumber(rawValue);
    };
    DriverRoutesLoadsTableCellComponent.prototype.ngOnInit = function () {
        this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
        this.updateObject();
    };
    DriverRoutesLoadsTableCellComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    DriverRoutesLoadsTableCellComponent.prototype.getTooltipForLoad = function (load) {
        var tooltip = LoadTypes.prefixOf(load.loadType) + " load, ";
        var dependItems = this.row.loads.filter(function (l) { return l.dispatchItemId === load.dispatchItemId; });
        tooltip += dependItems.map(function (item) { return convertOrderNumber(item.orderId); }).join(", ") + "\n";
        tooltip += "PCS: " + dependItems.reduce(function (total, i) { return total + i.pcs; }, 0) + "\n";
        tooltip += "Weight: " + dependItems.reduce(function (total, i) { return total + i.weight; }, 0) + " kg\n";
        tooltip += "Customer: " + (load.customer ? load.customer.name : "undefined") + "\n";
        tooltip += "To: " + (load.loadType === LoadType.PICKUP
            ? convertAddress(load.addressPickup)
            : convertAddress(load.addressDelivery));
        return tooltip;
    };
    DriverRoutesLoadsTableCellComponent.prototype.getFormatTime = function (date) {
        return this.dateTimeService.utcToNYFormat(date, OmsConstants.MOMENT_TIME_SHORT_FORMAT);
    };
    DriverRoutesLoadsTableCellComponent.prototype.onContextMenu = function ($event, item) {
        var _this = this;
        this.receiveManifest(item)
            .subscribe(function (manifest) {
            _this.contextMenuService.show.next({
                contextMenu: _this.menu.contextMenu,
                event: $event,
                item: null
            });
            _this.cdr.markForCheck();
        });
        $event.preventDefault();
        $event.stopPropagation();
    };
    DriverRoutesLoadsTableCellComponent.prototype.onLeftClick = function (item) {
        this.receiveManifest(item)
            .subscribe();
    };
    DriverRoutesLoadsTableCellComponent.prototype.receiveManifest = function (item) {
        var _this = this;
        this.manifest = null;
        this.manifestItem = null;
        return this.dispatchService.get(item.dispatchId)
            .pipe(tap(function (manifest) {
            _this.manifest = manifest;
            _this.manifestItem = manifest.items.find(function (mi) { return mi.id === item.dispatchItemId; });
            _this.cdr.markForCheck();
        }));
    };
    DriverRoutesLoadsTableCellComponent.prototype.onManifestChange = function (manifest) {
        if (this.data && this.data.manifestChanged) {
            this.data.manifestChanged(manifest);
            this.cdr.markForCheck();
        }
    };
    DriverRoutesLoadsTableCellComponent.prototype.onLoading = function (loading) {
        if (this.data && this.data.loading) {
            this.data.loading(loading);
            this.cdr.markForCheck();
        }
    };
    DriverRoutesLoadsTableCellComponent.prototype.onRefresh = function () {
        if (this.data && this.data.refresh) {
            this.data.refresh();
            this.cdr.markForCheck();
        }
    };
    DriverRoutesLoadsTableCellComponent.prototype.convertMawb = function (mawb) {
        return convertMawbNumber(mawb);
    };
    DriverRoutesLoadsTableCellComponent.prototype.getDisplayAddressName = function (add) {
        if (!add) {
            return "";
        }
        return add.label + "/" + (add.city || "") + "/" + (add.usaState ? add.usaState.uspsCode : "");
    };
    DriverRoutesLoadsTableCellComponent.prototype.openDocuments = function (order) {
        this.dialogs.openDocCenterWindow({ id: order.id, objectType: 'ORDER' });
    };
    DriverRoutesLoadsTableCellComponent.prototype.openComCenter = function (order) {
        var dialogRef = this.dialog.open(NotesCenterDialogComponent, {
            width: '80%',
            data: { id: order.id, objectType: 'ORDER', noteTypeId: 0 }
        });
    };
    DriverRoutesLoadsTableCellComponent = __decorate([
        Logger({
            name: 'DriverRoutesLoadsTableCellComponent'
        }),
        __metadata("design:paramtypes", [DataTableComponent,
            ChangeDetectorRef,
            ContextMenuService,
            DispatchService,
            MatDialog,
            OmsDialogsService,
            DateTimeService])
    ], DriverRoutesLoadsTableCellComponent);
    return DriverRoutesLoadsTableCellComponent;
}(GenericTableCell));
export { DriverRoutesLoadsTableCellComponent };
