import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Size} from "../../../../../common/oms-types";
import {Logger} from "../../../../../_helpers/logger";

@Component({
  selector: 'oms-select-button',
  templateUrl: './select-button.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-button.component.css']
})
@Logger({})
export class SelectButtonComponent<T = any> {
  Size = Size;
  @Input() size: Size = Size.DEFAULT;
  @Input('btn-class') btnClass: string = 'btn-outline-secondary';

  @Input() selected: T;
  @Input() bindValue: string;
  @Input() items: T[];
  @Input() disabled: boolean = false;
  @Input() converter: (value: T) => string;
  @Input() toggle: boolean = false;

  @Output() selectedChange: EventEmitter<T> = new EventEmitter<T>();

  onSelect(item: T) {
    this.selected = this.bindValue ? item[this.bindValue] : item;
    this.selectedChange.emit(this.selected);
  }

  labelOf(item: T): string {
    return this.converter ? this.converter(item) : item ? item['label'] || item : '';
  }

  get selectedLabel(): string {
    let item = this.items.find((i) => {
      return (this.bindValue ? i[this.bindValue] : i) === this.selected;
    });
    return this.labelOf(item/*this.selected*/);
  }

  isActive(item: T): boolean {
    return this.selected === (this.bindValue ? item[this.bindValue] : item);
  }

  onSelectToggle() {
    const notSelected = this.items.find(item => !this.isActive(item));
    this.onSelect(notSelected);
  }
}
