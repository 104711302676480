<div dropdown #dropdown="bs-dropdown" container="body" (isOpenChange)="onOpenChange();" (onHidden)="onClose(false)" [dropup]="isDropUp" [isOpen]="showPopup">
  <ng-content></ng-content>

  <ul #container class="widget datetime-popup dropdown-menu" *dropdownMenu role="menu" (offClick)="onClose(false)" style="z-index: 10000"  [ngStyle]="popupStyles">

    <div class="widget-header" *ngIf="showHeader">

      <h3>
        <i class="fa fa-calendar"></i>
<!--        {{estValue | oms_date_time_zone}} -->
      </h3>
      <em>{{estValue | oms_date_time_zone}}</em>

      <div class="oms-modal btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" style="padding:0; margin: 0 -10px 0 0;" (click)="onClose(false)">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>


    <li class="widget-content">
      <datepicker *ngIf="showDate"
                  [ngModel]="viewDateValue"
                  (ngModelChange)="onDatePickerChange($event)"
                  [showWeeks]="showWeeks"
                  [datepickerMode]="datepickerMode"
                  [minDate]="minDate"
                  [maxDate]="getMaxDate"
                  [dateDisabled]="dateDisabled"></datepicker>

      <div class="row">
        <div class="col col-xs-4">
          <div class="btn btn-primary"
               style="margin-top: 23px;"
               *ngIf="showTime" (click)="userPreferences.setTimeFormat12(!userPreferences.isTimeFormat12())">
            <i class="fas fa-clock"></i>
            {{userPreferences.getTimeFormatLabel()}}
          </div>
        </div>

        <div class="col col-lg-8">
          <timepicker *ngIf="showTime"
                      [showMeridian]="userPreferences.getTimeFormat12() | async"
                      [ngModel]="viewTimeValue"
                      [min]="minDate"
                      (ngModelChange)="onTimePickerChange($event)"></timepicker>
        </div>
      </div>
      <span *ngIf="actualDateInTheFuture" class="validation-error">Actual Date can't be in the future</span>
    </li>
    <li class="widget-footer" >
      <span class="btn-group pull-left">
          <button #btnNow type="button"
                  (click)="onNow()"
                  *ngIf="nowButton && nowButton.show"
                  [ngClass]="nowButton.cssClass">{{ nowButton.label }}</button>
          <button type="button"
                  (click)="onClear()"
                  *ngIf="clearButton && clearButton.show"
                  [ngClass]="clearButton.cssClass">{{ clearButton.label }}</button>
      </span>


      <span class="btn-group pull-right" style="display: flex;">
        <!--button [ngClass]="closeButton.cssClass">EST</button-->

        <span #dateTimeEdit [class]="'fancy-checkbox custom-bgcolor-green'" (click)="actualDateInTheFuture=false; isEstimated = ! isEstimated" *ngIf="estimation" style="margin: auto 0;">
          <input type="checkbox" [ngModel]="isEstimated" class="table-checkbox" title="" >
          <span>EST</span>
        </span>


        <button #btnClose type="button"
                autofocus
                (click)="onClose(true)"
                *ngIf="closeButton && closeButton.show"
                [ngClass]="closeButton.cssClass">{{ closeButton.label }}</button>
      </span>
    </li>
  </ul>
</div>
