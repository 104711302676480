import {Transform, Type} from 'class-transformer';
import {RouteInfo} from "./route-info";
import {Address} from "../address";
import {Order} from "../order";
import {Status, transformStatus} from "./status";
import {MasterStatusId, transformMasterStatusId} from "..";
import {TrackByObject} from "../../components/base/data-table/columns/column-types";
import {Receiving} from "./receiving";
import {LoadOut} from "./load.out";

export class OrderWarehouse extends Order implements TrackByObject {
  @Type(() => Date)
  dateWhseIn: Date;
  @Type(() => Date)
  dateWhseOut: Date;
  @Type(() => Address)
  addressWhse: Address;

  // If manifested OrderWarehouse is Recovery Order
  // then receiving contains list of Orders of Recovering Master to receive
  @Type(() => OrderWarehouse)
  public receiving: OrderWarehouse[] = [];
  public toReceive: boolean;
  public toLoadOut: boolean;

  public masterId: number;
  public pcsReceived: number;
  public huReceived: number;
  public pcsLoadOut: number;
  public huLoadOut: number;

  @Transform((v, o, tt) => transformMasterStatusId(v, o, tt))
  masterStatus: MasterStatusId = MasterStatusId.NEW;

  @Type(() => RouteInfo)
  public routeIn: RouteInfo;
  @Type(() => RouteInfo)
  public routeOut: RouteInfo;

  public routeInOrderNumber: number;
  public routeOutOrderNumber: number;

  public routeInLoadId: number;
  public routeOutNumberId: number;

  @Type(() => Address)
  addressRouteFrom: Address;
  @Type(() => Address)
  addressRouteTo: Address;

  @Transform((v, o, tt) => transformStatus(v, o, tt))
  receivingStatus: Status;

  @Type(() => Receiving)
  public lastReceiving: Receiving;
  @Type(() => LoadOut)
  public lastLoadOut: LoadOut;

  @Transform((v, o, tt) => transformStatus(v, o, tt))
  loadOutStatus: Status;

  private _nonAMS: boolean;

  public get nonAMS(): boolean {
    return this._nonAMS;
  }

  public set nonAMS(nonAMS: boolean) {
    this._nonAMS = nonAMS;
  }

  routeInId: number;
  routeOutId: number;
  routeInShipmentId: number;
  routeOutShipmentId: number;
  routeInSealNumber: string;
  routeOutSealNumber: string;
  routeInProblem: boolean;
  routeOutProblem: boolean;


  public trackBy(): any {
    return this.id + '-' + this.routeInId + '-' + this.routeOutId;
  }


}
