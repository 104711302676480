import {Country} from "./country";
import {BaseEntity} from "../base-entity";
import {Labeled} from '../../../../common/oms-types';

export class City extends BaseEntity implements Labeled {
  name: string;
  country: Country;
//  countryString: string;
//  subdivision: string;

  constructor(name?:string) {
    super();
    this.name = name;
  }

  get label(): string {
    return this.name;
  };
}
