/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-button.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/dropdown";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./select-button.component";
var styles_SelectButtonComponent = [i0.styles];
var RenderType_SelectButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SelectButtonComponent, data: {} });
export { RenderType_SelectButtonComponent as RenderType_SelectButtonComponent };
function View_SelectButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn dropdown-toggle"], ["type", "button"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disabled": 0, "btn-sm": 1, "btn-lg": 2 }), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "caret"], ["style", "margin-left: 5px"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.btnClass; var currVal_2 = _ck(_v, 2, 0, _co.disabled, (_co.size == _co.Size.SMALL), (_co.size == _co.Size.LARGE)); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled ? "disabled" : null); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.selectedLabel; _ck(_v, 3, 0, currVal_3); }); }
function View_SelectButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["aria-controls", "dropdown-panel"], ["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn dropdown-toggle"], ["data-toggle", "dropdown"], ["dropdownToggle", ""], ["id", "dropdown-button"], ["type", "button"]], [[1, "disabled", 0], [1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disabled": 0, "btn-sm": 1, "btn-lg": 2 }), i1.ɵdid(3, 147456, null, 0, i3.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i3.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i3.BsDropdownState], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "caret"], ["style", "margin-left: 5px"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.btnClass; var currVal_5 = _ck(_v, 2, 0, _co.disabled, (_co.size == _co.Size.SMALL), (_co.size == _co.Size.LARGE)); _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled ? "disabled" : ""); var currVal_1 = true; var currVal_2 = i1.ɵnov(_v, 3).isDisabled; var currVal_3 = i1.ɵnov(_v, 3).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.selectedLabel; _ck(_v, 4, 0, currVal_6); }); }
function View_SelectButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "li", [["role", "menuitem"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "label", [["class", "radio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["type", "radio"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isActive(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.labelOf(_v.context.$implicit); _ck(_v, 7, 0, currVal_1); }); }
function View_SelectButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["aria-labelledby", "dropdown-button"], ["class", "multiselect-container dropdown-menu dropdown-menu-right"], ["id", "dropdown-panel"], ["role", "menu"], ["style", "top:15px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectButtonComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SelectButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "span", [["class", "dropdown"], ["container", "body"], ["dropdown", ""], ["placement", "right"]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i3.BsDropdownState, i3.BsDropdownState, []), i1.ɵdid(2, 212992, null, 0, i3.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.BsDropdownConfig, i3.BsDropdownState], { placement: [0, "placement"], container: [1, "container"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectButtonComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectButtonComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectButtonComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.BsDropdownMenuDirective, [i3.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "right"; var currVal_4 = "body"; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = _co.toggle; _ck(_v, 4, 0, currVal_5); var currVal_6 = !_co.toggle; _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = (i1.ɵnov(_v, 2).isOpen && i1.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SelectButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-select-button", [], null, null, null, View_SelectButtonComponent_0, RenderType_SelectButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.SelectButtonComponent, [], null, null)], null, null); }
var SelectButtonComponentNgFactory = i1.ɵccf("oms-select-button", i5.SelectButtonComponent, View_SelectButtonComponent_Host_0, { size: "size", btnClass: "btn-class", selected: "selected", bindValue: "bindValue", items: "items", disabled: "disabled", converter: "converter", toggle: "toggle" }, { selectedChange: "selectedChange" }, []);
export { SelectButtonComponentNgFactory as SelectButtonComponentNgFactory };
