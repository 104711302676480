  <div class="oms-modal widget" [hidden]="!visible"
       [style.maxHeight]="maxHeight || '90vh'"
       [style.maxWidth]="maxWidth || '90vw'"
       [style.minHeight]="minHeight"
       [style.minWidth]="minWidth"
       [style.height]="height || 'unset'"
       [style.width]="width || 'unset'"
       [ngClass]="{'sizable':sizable, 'loading' : viewLoading}">

    <ngx-spinner name="spModalDialog" bdColor = "#33333366" color = "#fff" [fullScreen]="false" type="pacman" size="medium">
      <p style="color: white" > Loading... </p>
    </ngx-spinner>

    <div class="widget-header" [dialog-draggable-title]="draggable" [keep-position]="storePosition">
      <h3 *ngIf="showLabel">
        <i class="{{_icon}}" *ngIf="_icon"></i>
        <mat-icon [svgIcon]="svg_icon" *ngIf="svg_icon"></mat-icon>
        <label>{{_title}}</label>
      </h3>

      <!--div style="width: 100%" class="if-not-empty">
      </div-->

      <ng-content select="[widget-header]">
      </ng-content>

      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="onCancel()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <perfect-scrollbar [disabled]="sbDisableOnInit" style="max-height: 80vh;" [config]="{suppressScrollX: false}">
      <div class="widget-content" [ngClass]="{'no-padding': no_padding, 'flex': flexContent}">
        <ng-content>
        </ng-content>
      </div>
    </perfect-scrollbar>

    <div class="widget-footer" *ngIf="showFooter">
      <div style="width: 100%; margin-right: 10px;">
        <ng-content select="[widget-footer]">
        </ng-content>
      </div>

      <div class="dialog-btns" *ngIf="showDialogBtns">

        <ng-content select="[widget-footer-buttons]">
        </ng-content>

        <button type="button" *ngIf="!_readonly && btnOk" class="btn btn-primary"
                (click)="onOK()" [disabled]="viewLoading || !canOk">
          <i [class]="btnOk.icon || 'fa fa-check'"></i>
          <span>{{btnOk.caption || 'OK'}}</span>
        </button>

        <button type="button" class="btn btn-default" *ngIf="btnCancel"
                (click)="onCancel()">
          <i [class]="btnCancel.icon || 'fa fa-times'"></i>
          <span>{{btnCancel.caption || 'Cancel'}}</span>
        </button>
      </div>
    </div>

  </div>
