import {TransformationType} from "class-transformer";
import {isNullOrUndefined} from "util";
import {OrderModeItem} from "./order-mode-item";

export enum OrderMode {
  UNDEF = 0,    // not specified
  AICFS = 1,    // Air Import CFS
  AIDOM = 2,    // Air Import Non-CFS
  AEMSS = 3,    // Air Export MSS
  AEDIR = 4,    // Air Export Direct
//  LCLEX = 5,  // LCL Export removed
//  LCLIM = 6,  // LCL Import removed
  FCLEX = 7,    // FCL Export
  FCLIM = 8,    // FCL Import
  DOMLD = 9,    // Domestic Load
  DAYRENT = 10, // Day Rent
  RECOV = 11,   // Recovery Order
  LTL = 12,     // Less Than Truck Load
  FTL = 13,     // Full Truck Load
  SHUTTLE = 14,
}


export namespace OrderModes {
  const labels: Map<OrderMode, string> = new Map<OrderMode, string>([
    [OrderMode.UNDEF, 'Not Specified'],
    [OrderMode.AICFS, 'Air Import CFS'],
    [OrderMode.AIDOM, 'Air Import DOM'],
    [OrderMode.AEMSS, 'Air Export MSS'],
    [OrderMode.AEDIR, 'Air Export Direct'],
    [OrderMode.FCLEX, 'FCL Export'],
    [OrderMode.FCLIM, 'FCL Import'],
    [OrderMode.DOMLD, 'Domestic Load'],
    [OrderMode.DAYRENT, 'Day Rent'],
    [OrderMode.RECOV, 'Recovery'],
    [OrderMode.LTL, 'LTL'],
    [OrderMode.FTL, 'FTL'],
    [OrderMode.SHUTTLE, 'Shuttle'],
  ]);

  export const defaultMode: OrderMode = OrderMode.DOMLD;

  const FCL_MODES: OrderMode[] = [OrderMode.FCLEX, OrderMode.FCLIM];
  const CONT_MODES: OrderMode[] = [OrderMode.FCLEX, OrderMode.FCLIM];
  const AIR_MODES: OrderMode[] = [OrderMode.AICFS, OrderMode.AIDOM, OrderMode.AEDIR, OrderMode.AEMSS];
  const AIR_EXPORT_MODES: OrderMode[] = [OrderMode.AEDIR, OrderMode.AEMSS];
  const AI_MODES: OrderMode[] = [OrderMode.AICFS, OrderMode.AIDOM];
  const DOMESTIC_MODES: OrderMode[] = [OrderMode.DOMLD, OrderMode.LTL, OrderMode.FTL, OrderMode.SHUTTLE];

  export const allValues: OrderMode[] = Object.keys(OrderMode).filter(v => isNaN(Number(v))).map(key => valueOf(key));

  // Available values to create generic order
  export const values: OrderMode[] = [
    OrderMode.AEDIR,
    OrderMode.FCLEX,
    OrderMode.FCLIM,
    OrderMode.DOMLD,
    OrderMode.DAYRENT,
    OrderMode.LTL,
    OrderMode.FTL,
    OrderMode.SHUTTLE
  ];

  // Available values to create multiple order
  export const multipleOrderValues: OrderMode[] = [
    OrderMode.AIDOM,
    OrderMode.AICFS,
    OrderMode.AEDIR,
    OrderMode.FCLEX,
    OrderMode.FCLIM,
    OrderMode.DOMLD,
    OrderMode.DAYRENT,
    OrderMode.LTL,
    OrderMode.FTL,
    OrderMode.SHUTTLE
  ];

  export const filterItems: OrderModeItem[] = allValues.filter(v => v !== OrderMode.UNDEF).map(v => ({id: v, label: labelOf(v), name: nameOf(v) }));

//  export function names(): string[] {return values.map(v=>nameOf(v))}
  export function valueOf(key: string): OrderMode {return OrderMode[key]; }
  export function nameOf(mode: OrderMode): string {return mode ? OrderMode[mode] : 'AI'; }
  export function labelOf(mode: OrderMode | any): string {return labels.get(mode); }
  export function isFCL(mode: OrderMode): boolean { return mode && FCL_MODES.includes(mode); }
  export function isAir(mode: OrderMode): boolean { return isNullOrUndefined(mode) || AIR_MODES.includes(mode); }
  export function isAirImport(mode: OrderMode): boolean { return isNullOrUndefined(mode) || AI_MODES.includes(mode); }
  export function isAirExport(mode: OrderMode): boolean { return AIR_EXPORT_MODES.includes(mode); }
  export function isAirExportDirect(mode: OrderMode): boolean { return mode === OrderMode.AEDIR; }
  export function isContainerMode(mode: OrderMode): boolean {return mode && CONT_MODES.includes(mode); }
  export function isRecovery(mode: OrderMode): boolean {return OrderMode.RECOV === mode; }
  export function isDomestic(mode: OrderMode): boolean {return DOMESTIC_MODES.includes(mode); }
}

export function transformOrderMode(value: any, obj: any, transformationType: TransformationType): any {
  switch (transformationType) {
    case TransformationType.PLAIN_TO_CLASS: return value ? value.id : undefined;
    case TransformationType.CLASS_TO_PLAIN: return {id: value};
    default: return value;
  }
}

