var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { BaseDeletable } from "../base-deletable";
import { Schedule } from "./schedule";
import { Transform, Type } from "class-transformer";
import { ReportRecipient } from "./report-recipient";
import { FilterPageRequest } from "../filter.page.request";
import { ReportExecutionStatus, transformReportExecutionStatus } from "./report-execution-status";
var ɵ0 = function () { return Schedule; }, ɵ1 = function () { return FilterPageRequest; }, ɵ2 = function () { return ReportRecipient; }, ɵ3 = function () { return Date; }, ɵ4 = function (v, o, tt) { return transformReportExecutionStatus(v, o, tt); };
var ScheduledReport = /** @class */ (function (_super) {
    __extends(ScheduledReport, _super);
    function ScheduledReport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.active = true;
        _this.schedule = new Schedule();
        _this.filters = new FilterPageRequest(0, 0, null, { field: 'id', sortedAsc: false }, []);
        _this.recipients = [];
        _this.html = false;
        _this.outputType = 0;
        _this.fields = [];
        return _this;
    }
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Schedule)
    ], ScheduledReport.prototype, "schedule", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", FilterPageRequest)
    ], ScheduledReport.prototype, "filters", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Array)
    ], ScheduledReport.prototype, "recipients", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Date)
    ], ScheduledReport.prototype, "dateLastExecutedOn", void 0);
    __decorate([
        Transform(ɵ4),
        __metadata("design:type", Number)
    ], ScheduledReport.prototype, "lastExecutedStatus", void 0);
    return ScheduledReport;
}(BaseDeletable));
export { ScheduledReport };
var ScheduledReportField = /** @class */ (function () {
    function ScheduledReportField() {
    }
    return ScheduledReportField;
}());
export { ScheduledReportField };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
