var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { OmsColumns } from "../../../common/oms-columns.service";
import { AbstractEntityListComponent } from "../../../modules/settings/pages/abstract-entity-list.component";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { OmsDialogsService } from "../oms-dialogs/oms-dialogs.service";
import { FileUploadService } from '../../../services/file.upload.service';
import { FilterPageRequest } from "../../../modules/shared/models/filter.page.request";
import { SearchSortRequest } from "../../../modules/shared/models/search.sort.request";
import { FilterSearchColumn } from "../../../modules/shared/models/filter.search.column";
import { MawbTrackService } from "../../../modules/shared/services/mawb-track/mawb.track.service";
var MawbTrackDialogComponent = /** @class */ (function (_super) {
    __extends(MawbTrackDialogComponent, _super);
    function MawbTrackDialogComponent(dialogRef, data, dialog, cdr, alerts, dialogs, mawbTrackService, omsColumns, fileUploadService) {
        var _this = _super.call(this, cdr, alerts, mawbTrackService, dialogs, fileUploadService) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.dialogs = dialogs;
        _this.mawbTrackService = mawbTrackService;
        _this.omsColumns = omsColumns;
        _this.fileUploadService = fileUploadService;
        _this.specificRowClassHandler = [
            {
                field: "inSystem",
                value: "false",
                class: "table-row-missed"
            }
        ];
        _this.mawbTracks = [];
        _this.defaultSortColumn = "id";
        _this.mawbTrackService.asmDataSubject.subscribe(function (mawbTrack) {
            _this.mawbTracks = mawbTrack;
        });
        return _this;
    }
    MawbTrackDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.omsColumns.asmDataColumns.subscribe(function (columns) {
            _this.columns = columns;
        });
        this.omsColumns.getAmsDataColumns();
        this.mawbTrackService.findAllByMasterId(this.data.id);
    };
    MawbTrackDialogComponent.prototype.getTitle = function () {
        return 'ASM Data';
    };
    MawbTrackDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    MawbTrackDialogComponent.prototype.generateExcel = function () {
        var searchField = "masterId";
        var filterPageRequest = this.createDefaultRequest(searchField);
        this.request = filterPageRequest;
        this.downloadExcel();
        this.dialogRef.close();
    };
    MawbTrackDialogComponent.prototype.createDefaultRequest = function (searchField) {
        var filterSearchColumn = new FilterSearchColumn(searchField, this.data.id, null, null);
        return new FilterPageRequest(1, 999, '', new SearchSortRequest(searchField, true), [filterSearchColumn]);
    };
    return MawbTrackDialogComponent;
}(AbstractEntityListComponent));
export { MawbTrackDialogComponent };
