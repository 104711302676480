/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selectable-text-area-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../toggle-switch/toggle-switch.component.ngfactory";
import * as i3 from "../../toggle-switch/toggle-switch.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./selectable-text-area-input.component";
var styles_SelectableTextAreaInputComponent = [i0.styles];
var RenderType_SelectableTextAreaInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectableTextAreaInputComponent, data: {} });
export { RenderType_SelectableTextAreaInputComponent as RenderType_SelectableTextAreaInputComponent };
function View_SelectableTextAreaInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "input-group-addon"], ["style", "padding: 0; vertical-align: bottom; text-align: -webkit-match-parent; padding-left: 8px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "oms-toggle-switch", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.value || (_co.isEdit = $event)) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.value = null) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ToggleSwitchComponent_0, i2.RenderType_ToggleSwitchComponent)), i1.ɵdid(2, 49152, null, 0, i3.ToggleSwitchComponent, [[2, i4.NG_VALIDATORS], [2, i4.NG_ASYNC_VALIDATORS]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ToggleSwitchComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = (_co.value || _co.isEdit); _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_SelectableTextAreaInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "input-group"], ["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectableTextAreaInputComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "textarea", [["class", "form-control"], ["placeholder", "Details"], ["rows", "3"]], [[1, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.value = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(11, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hasToggle; _ck(_v, 5, 0, currVal_1); var currVal_10 = _co.value; _ck(_v, 9, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_2 = ((_co.value || _co.isEdit) ? null : ""); var currVal_3 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 11).ngClassValid; var currVal_8 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_SelectableTextAreaInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "oms-text-area-input", [], null, null, null, View_SelectableTextAreaInputComponent_0, RenderType_SelectableTextAreaInputComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.SelectableTextAreaInputComponent]), i1.ɵdid(2, 638976, null, 0, i6.SelectableTextAreaInputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SelectableTextAreaInputComponentNgFactory = i1.ɵccf("oms-text-area-input", i6.SelectableTextAreaInputComponent, View_SelectableTextAreaInputComponent_Host_0, { label: "label", hasToggle: "hasToggle" }, {}, []);
export { SelectableTextAreaInputComponentNgFactory as SelectableTextAreaInputComponentNgFactory };
