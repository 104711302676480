var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit } from '@angular/core';
import { BaseInputComponent } from "../../../../base/base-input/base-input.component";
import { NgModel } from "@angular/forms";
import { DriverService } from "../../../../../../../services/driver-service";
import { NgSelectSearchParams } from "../../../../../../settings/util/ng-select-search-params";
import { Size } from "../../../../../../../common/oms-types";
import { PageResult } from "../../../../../models/query-models/page-result";
import { UserService } from "../../../../../services/user.service";
import { map } from "rxjs/operators";
var DriverSearchComponent = /** @class */ (function (_super) {
    __extends(DriverSearchComponent, _super);
    function DriverSearchComponent(validators, asyncValidators, service, userService) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.service = service;
        _this.userService = userService;
        _this.multiple = false;
        _this.size = Size.DEFAULT;
        _this.valueChange = new EventEmitter();
        _this.searchChange = new EventEmitter();
        _this.searchTimeout = 1000;
        return _this;
    }
    DriverSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.search = new NgSelectSearchParams(function (text) {
            return _this.userService.findUsersByRoles(['ROLE_DRIVER'], true, text).pipe(map(function (response) {
                return PageResult.fromArray(response.content.filter(function (user) { return user.driver; }).map(function (user) { return user.driver; }));
                // response.content.forEach(driver => driver['label'] = driver.fullName);
            }));
        });
    };
    DriverSearchComponent.prototype.onSelect = function (event) {
        var _this = this;
        this.valueChange.emit(event);
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(function () {
            _this.searchChange.emit(event);
        }, this.searchTimeout);
    };
    return DriverSearchComponent;
}(BaseInputComponent));
export { DriverSearchComponent };
