import {BaseEntityService} from './base/base-entity.service';
import {Truck} from '../modules/shared/models';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {TruckDialogComponent} from '../components/dialogs/truck-dialog/truck-dialog.component';
import {DialogResult, ModalResult, OmsDialogsService} from "../components/common/oms-dialogs";

@Injectable()
export class TruckService extends BaseEntityService<Truck> {
  apiUrl = '/oms/truck/';
  classType = Truck;

  public constructor(
    public dialogs: OmsDialogsService,
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public createEditAndSave(truck: Truck, readonly?: boolean): Promise<Truck> {
    return new Promise<Truck>((success, reject) => {
      this.dialogs.openDialog(TruckDialogComponent, {truck: truck, readonly: readonly})
        .then((result: DialogResult<any>) => {
          if (result.result === ModalResult.OK) {
            this.save(truck).subscribe(
              saved => success(saved),
              error => reject(error === 'DataIntegrityViolation' ? 'Can\'t create new Truck: \n Truck ' + truck.number + ' already exists!' : error));
          }
        })
        .catch(error => reject(error));
    });
  }
}
