var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OrderPart } from "./order-part";
import { Receiving } from "./receiving";
var LoadOut = /** @class */ (function (_super) {
    __extends(LoadOut, _super);
    function LoadOut(orders) {
        var _this = _super.call(this) || this;
        if (orders) {
            orders.forEach(function (o) { return _this.addPart(o); });
        }
        return _this;
    }
    LoadOut.prototype.fromOrderWarehouse = function (o) {
        var part = new OrderPart();
        part.order = {
            id: o.id,
            hawb: o.hawb,
            pieces: o.pieces,
            hu: o.hu,
            customerRef: o.customerRef,
            purchaseOrder: o.purchaseOrder,
            ref3: o.ref3,
            addressFrom: o.addressRouteFrom,
            addressTo: o.addressDelivery,
            mode: o.genericMode
        };
        part.pieces = part.pcsProcessed = o.pcsLoadOut || 0;
        part.hu = part.huProcessed = o.huLoadOut || 0;
        part.weightProcessed = o.weight;
        part.orderNumber = o.routeOutOrderNumber;
        part.address = o.addressWhse;
        part.status = o.loadOutStatus; // */ o.routeOutProblem ? Status.NOT_COMPLETED : (o.pcsLoadOut >= o.pieces ? Status.COMPLETED : Status.UNDEFINED);
        part.routeProblem = o.routeOutProblem;
        part.sealNumber = o.routeOutSealNumber;
        part.dispatchId = o.routeOutId;
        part.shipmentId = o.routeOutShipmentId;
        part.masterId = o.masterId;
        if (o.routeOut) {
            this.sealNumber = o.routeOutSealNumber;
            this.driver = o.routeOut.driver;
            this.carrier = o.routeOut.carrier;
            this.truck = o.routeOut.truck;
            this.trailer = o.routeOut.trailer;
        }
        return part;
    };
    return LoadOut;
}(Receiving));
export { LoadOut };
