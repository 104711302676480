var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { Trailer } from '../modules/shared/models';
import { TrailerDialogComponent } from '../components/dialogs/trailer-dialog/trailer-dialog.component';
import { ModalResult, OmsDialogsService } from "../components/common/oms-dialogs";
var TrailerService = /** @class */ (function (_super) {
    __extends(TrailerService, _super);
    function TrailerService(dialogs, httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.dialogs = dialogs;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/trailer/';
        _this.classType = Trailer;
        return _this;
    }
    TrailerService.prototype.createEditAndSave = function (trailer, readonly) {
        var _this = this;
        return new Promise(function (success, reject) {
            _this.dialogs.openDialog(TrailerDialogComponent, { trailer: trailer, readonly: readonly })
                .then(function (result) {
                if (result.result === ModalResult.OK) {
                    _this.save(trailer).subscribe(function (saved) { return success(saved); }, function (error) { return reject(error === 'DataIntegrityViolation' ? 'Can\'t create new Trailer: \n Trailer ' + trailer.number + ' already exists!' : error); });
                }
            })
                .catch(function (error) { return reject(error); });
        });
    };
    return TrailerService;
}(BaseEntityService));
export { TrailerService };
