var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { FilterSearchColumn } from './filter.search.column';
import { SearchSortRequest } from './search.sort.request';
import { isNullOrUndefined } from 'util';
import { Type } from "class-transformer";
import { assigned, isEmpty } from "../../../_helpers/utils";
import { SearchRange } from "./search.range";
var ɵ0 = function () { return FilterSearchColumn; };
var FilterPageRequest = /** @class */ (function () {
    function FilterPageRequest(pageNumber, pageSize, findByOccurs, sort, filterByColumns) {
        this.operation = 'and';
        this.filterByColumns = [];
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.findByOccurs = findByOccurs;
        this.filterByColumns = filterByColumns;
        this.sort = sort;
    }
    FilterPageRequest.simpleFilters = function () {
        return new FilterPageRequest(0, 100, '', new SearchSortRequest(''), []);
    };
    FilterPageRequest.prototype.setSort = function (sort) {
        this.sort = sort;
        return this;
    };
    FilterPageRequest.prototype.addNamedCondition = function (conditionName) {
        if (!this.namedConditions) {
            this.namedConditions = [];
        }
        if (!!conditionName) {
            this.removeNamedCondition(conditionName.split('|')[0]);
            this.namedConditions.push(conditionName);
        }
        return this;
    };
    FilterPageRequest.prototype.clearNamedConditions = function () {
        this.namedConditions = [];
    };
    FilterPageRequest.prototype.removeNamedCondition = function (conditionName) {
        if (this.namedConditions) {
            // Remove all conditions starting with Name as it might be formatted like `CONDITION_NAME|CONDITION_PARAMETERS`
            this.namedConditions.removeAll(function (s) { return s && s.startsWith(conditionName); });
        }
    };
    FilterPageRequest.prototype.removeNamedConditions = function () {
        var _this = this;
        var conditionName = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            conditionName[_i] = arguments[_i];
        }
        if (conditionName && conditionName.length) {
            conditionName.forEach(function (c) { return _this.removeNamedCondition(c); });
        }
    };
    FilterPageRequest.prototype.hasNamedCondition = function (conditionName) {
        return this.namedConditions && assigned(this.namedConditions.find(function (s) { return s && s.startsWith(conditionName); }));
    };
    FilterPageRequest.prototype.getNamedCondition = function (conditionName) {
        return this.namedConditions && this.namedConditions.find(function (s) { return s && s.startsWith(conditionName); });
    };
    FilterPageRequest.prototype.anyNamedCondition = function (conditionNames) {
        return this.namedConditions && this.namedConditions.find(function (s) { return conditionNames.hasEquals(function (c) { return c && c.startsWith(s); }); });
    };
    FilterPageRequest.prototype.getColumnFilter = function (field, canCreate) {
        var filter = this.filterByColumns.find(function (item) { return item.field === field; });
        if (!filter && canCreate) {
            filter = new FilterSearchColumn(field);
            this.filterByColumns.push(filter);
        }
        return filter;
    };
    FilterPageRequest.prototype.getColumnSearchMultipleValues = function (field) {
        var filter = this.getColumnFilter(field);
        return filter ? filter.multipleValues : undefined;
    };
    FilterPageRequest.prototype.setColumnSearchMultipleValues = function (field, values) {
        if (isEmpty(values)) {
            this.removeColumnFilter(field);
        }
        else {
            var filter = this.getColumnFilter(field, true);
            filter.multipleValues = values;
        }
    };
    FilterPageRequest.prototype.addColumnFilter = function (field, value) {
        var filter = this.getColumnFilter(field, true);
        if (Array.isArray(value)) {
            filter.value = undefined;
            filter.multipleValues = value;
        }
        else {
            filter.value = value;
            filter.multipleValues = undefined;
        }
        return filter;
    };
    FilterPageRequest.prototype.removeColumnFilter = function (field, value) {
        var filter = this.filterByColumns.find(function (item) { return item.field === field; });
        if (filter) {
            if (isNullOrUndefined(value) || filter.value === value) {
                this.filterByColumns.removeAll(filter);
            }
            else {
                filter.multipleValues.removeAll(value);
                if (filter.multipleValues.length === 0) {
                    this.filterByColumns.removeAll(filter);
                }
            }
        }
    };
    FilterPageRequest.prototype.setColumnFilter = function (field, value) {
        if (!value || !value['length']) {
            this.filterByColumns.removeAll(function (f) { return f.field === field; });
        }
        else {
            var filter = this.getColumnFilter(field, true);
            if (value instanceof SearchRange) {
                filter.range = value;
                filter.multipleValues = undefined;
                filter.value = undefined;
            }
            else if (value['length']) {
                filter.range = undefined;
                filter.multipleValues = value;
                filter.value = undefined;
            }
            else {
                filter.range = undefined;
                filter.multipleValues = undefined;
                filter.value = value;
            }
        }
    };
    FilterPageRequest.prototype.replaceColumns = function (columns) {
        var _this = this;
        columns.forEach(function (column) { return _this.replaceColumn(column); });
    };
    FilterPageRequest.prototype.replaceColumn = function (column) {
        var allColumns = this.filterByColumns.filter(function (c) { return c.field !== column.field; });
        allColumns.push(column);
        this.filterByColumns = allColumns;
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Array)
    ], FilterPageRequest.prototype, "filterByColumns", void 0);
    return FilterPageRequest;
}());
export { FilterPageRequest };
export { ɵ0 };
