import { OnInit } from '@angular/core';
import { FilterPageRequest } from "../../../../../modules/shared/models/filter.page.request";
import { MasterStatus } from "../../../../../modules/shared/models";
import { FreightForwarderService } from "../../../../../services/freight.forwarder.service";
import { FreightForwarderGroupService } from "../../../../../services/freight.forwarder.group.service";
import { CustomerService } from "../../../../../services/customer.service";
import { assigned, isEmptyString } from "../../../../../_helpers/utils";
var OrdersReportFiltersComponent = /** @class */ (function () {
    function OrdersReportFiltersComponent(freightForwarderService, freightForwarderGroupService, customerService) {
        this.freightForwarderService = freightForwarderService;
        this.freightForwarderGroupService = freightForwarderGroupService;
        this.customerService = customerService;
        this.selectedFreightForwarders = [];
        this.selectedFreightForwarderGroups = [];
        this.filterYes = { id: 1, label: 'Yes' };
        this.filterNo = { id: 0, label: 'No' };
        this.modes = {
            field: "genericMode",
            items: [],
            selected: [],
            search: {
                searchable: true,
                multiple: true,
                searchType: "list",
            }
        };
        this.status = {
            label: "Order Status",
            field: 'status',
            items: MasterStatus.items,
            selected: [],
            search: {
                searchable: true,
                searchType: "list",
                items: MasterStatus.items,
                multiple: true,
            }
        };
        this.deliveredOptions = [
            { id: 'NOT_DELIVERED', label: 'All Not Delivered' },
            { id: 'ALL_DELIVERED', label: 'All Delivered' },
            { id: 'DELIVERED_LAST_MONTH', label: 'Delivered Last Month' },
            { id: 'DELIVERED_LAST_WEEK', label: 'Delivered Last Week' },
            { id: 'DELIVERED_YESTERDAY', label: 'Delivered Yesterday' },
            { id: 'DELIVERED_SINCE_PREVIOUS', label: 'Delivered Since Previous Report' },
            { id: 'DELIVERED_CURRENT_YEAR', label: 'Delivered in Current Year (YTD)' },
        ];
    }
    OrdersReportFiltersComponent.prototype.compareById = function (a, b) {
        return a && b && a['id'] && a['id'] === b['id'];
    };
    OrdersReportFiltersComponent.prototype.onOrderModesChange = function (event) {
        this.filters.modesFilter = event ? event.map(function (o) { return o.id; }) : [];
    };
    OrdersReportFiltersComponent.prototype.onOrderStatusChange = function (event) {
        this.filters.setColumnSearchMultipleValues('status', event ? event.map(function (o) { return o.id; }) : []);
    };
    OrdersReportFiltersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modes.items = [
            { id: 0, label: 'Recovery' },
            { id: 1, label: 'Air Import' },
            { id: 2, label: 'Air Export' },
            { id: 3, label: 'LCL Import' },
            { id: 4, label: 'LCL Export' },
            { id: 5, label: 'FCL Import' },
            { id: 6, label: 'FCL Export' },
            { id: 7, label: 'Domestic' },
            { id: 8, label: 'Day Rent' },
            { id: 9, label: 'LTL' },
            { id: 10, label: 'FTL' },
            { id: 11, label: 'Shuttle' },
        ];
        if (this.filters) {
            this.dateCreatedSince = this.getDateCreatedSince();
            for (var _i = 0, _a = this.filters.filterByColumns; _i < _a.length; _i++) {
                var filter = _a[_i];
                if (filter.field === 'customerId') {
                    this.customerService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (customers) { return _this.selectedCustomers = customers; });
                }
                if (filter.field === 'freightForwarderId') {
                    if (filter.multipleValues) {
                        this.freightForwarderService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (ff) { return _this.selectedFreightForwarders = ff; });
                    }
                }
                if (filter.field === 'freightForwarderGroupId') {
                    this.freightForwarderGroupService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (ffg) { return _this.selectedFreightForwarderGroups = ffg; });
                }
            }
        }
        this.modes.selected =
            this.filters &&
                this.filters.modesFilter &&
                this.filters.modesFilter.map(function (mode) { return _this.modes.items.find(function (m) { return m.id === mode; }); });
        var selectedStatuses = this.filters.getColumnSearchMultipleValues('status') || [];
        this.status.selected = selectedStatuses.map(function (s) { return MasterStatus.get(Number(s)); });
        this.selectedDeliveredOption = this.filters && this.filters.anyNamedCondition(this.deliveredOptions.map(function (o) { return o.id; }));
    };
    /*  get selectedDeliveredOption(): string {
        return this.filters && this.filters.anyNamedCondition(this.deliveredOptions.map((o) => o.id));
      }*/
    OrdersReportFiltersComponent.prototype.setSelectedDeliveredOption = function (option) {
        var _this = this;
        if (this.filters) {
            this.deliveredOptions.forEach(function (o) {
                _this.filters.removeNamedCondition(o.id);
            });
            //      this.filters.removeNamedCondition(this.selectedDeliveredOption);
            if (!isEmptyString(option)) {
                this.filters.addNamedCondition(option);
            }
        }
    };
    Object.defineProperty(OrdersReportFiltersComponent.prototype, "updatedYtd", {
        get: function () {
            return this.filters.hasNamedCondition("UPDATED_YTD");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("UPDATED_YTD");
            }
            else {
                this.filters.removeNamedCondition("UPDATED_YTD");
            }
        },
        enumerable: true,
        configurable: true
    });
    OrdersReportFiltersComponent.prototype.getDateCreatedSince = function () {
        var s = this.filters && this.filters.getNamedCondition('CREATED_SINCE');
        if (!!s) {
            s = s.split('|')[1];
            return new Date(Date.parse(s));
        }
        else {
            return undefined;
        }
    };
    OrdersReportFiltersComponent.prototype.setDateCreatedSince = function (date) {
        this.dateCreatedSince = date;
        if (assigned(date)) {
            this.filters.addNamedCondition("CREATED_SINCE|" + date.toISOString());
        }
        else {
            this.filters.removeNamedCondition('CREATED_SINCE');
        }
    };
    OrdersReportFiltersComponent.prototype.onFreightForwarderChange = function () {
        if (this.selectedFreightForwarders && this.selectedFreightForwarders.length) {
            this.filters.addColumnFilter('freightForwarderId', this.selectedFreightForwarders.map(function (ff) { return ff.id; }));
        }
        else {
            this.filters.removeColumnFilter('freightForwarderId');
        }
    };
    OrdersReportFiltersComponent.prototype.onFreightForwarderGroupChange = function () {
        if (this.selectedFreightForwarderGroups && this.selectedFreightForwarderGroups.length) {
            this.filters.addColumnFilter('freightForwarderGroupId', this.selectedFreightForwarderGroups.map(function (ffg) { return ffg.id; }));
        }
        else {
            this.filters.removeColumnFilter('freightForwarderGroupId');
        }
    };
    OrdersReportFiltersComponent.prototype.onCustomerChange = function () {
        if (this.selectedCustomers && this.selectedCustomers.length) {
            this.filters.addColumnFilter('customerId', this.selectedCustomers.map(function (c) { return c.id; }));
        }
        else {
            this.filters.removeColumnFilter('customerId');
        }
    };
    Object.defineProperty(OrdersReportFiltersComponent.prototype, "ordersBilled", {
        get: function () {
            if (this.filters.hasNamedCondition('BILLED')) {
                return this.filterYes;
            }
            if (this.filters.hasNamedCondition('NOT_BILLED')) {
                return this.filterNo;
            }
            return undefined;
        },
        set: function (value) {
            this.filters.removeNamedConditions('NOT_BILLED', 'BILLED');
            switch (value && value.id) {
                case 0:
                    this.filters.addNamedCondition('NOT_BILLED');
                    break;
                case 1:
                    this.filters.addNamedCondition('BILLED');
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    return OrdersReportFiltersComponent;
}());
export { OrdersReportFiltersComponent };
