var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject } from 'rxjs';
import { RestService } from "../../../../services/rest.service";
import { HttpUtilsService } from "../../../../services/http-utils.service";
import { plainToClass } from "class-transformer";
import { CbpStatus } from "../../models/cbp.status";
import { BaseEntityService } from "../../../../services/base/base-entity.service";
import { map, tap } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
var CbpStatusService = /** @class */ (function (_super) {
    __extends(CbpStatusService, _super);
    function CbpStatusService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/cbp-status/';
        _this.classType = CbpStatus;
        _this.cbpStatusSubject = new BehaviorSubject([]);
        return _this;
    }
    CbpStatusService.prototype.findAllByMasterId = function (id, uscsFsnStatus) {
        var _this = this;
        var params = new HttpParams();
        if (uscsFsnStatus) {
            params = params.append('uscsFsnStatus', uscsFsnStatus);
        }
        var headers = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.getWithOptions(this.apiUrl + 'find-by-master-id/' + id, headers, params)
            .pipe(map(function (items) { return plainToClass(CbpStatus, items); }), tap(function (items) { return _this.cbpStatusSubject.next(items); }));
    };
    CbpStatusService.prototype.findAllByOrderId = function (id) {
        var _this = this;
        var headers = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.getWithHeader(this.apiUrl + 'find-by-order-id/' + id, headers)
            .pipe(map(function (items) { return plainToClass(CbpStatus, items); }), tap(function (items) { return _this.cbpStatusSubject.next(items); }));
    };
    return CbpStatusService;
}(BaseEntityService));
export { CbpStatusService };
