import {isNullOrUndefined} from 'util';
import {Type, Exclude, TransformationType, Transform} from 'class-transformer';
import {BaseEntity} from './base-entity';
import {Shipment} from './shipment';
import {Driver} from './driver';
import {Truck} from './truck';
import {Trailer} from './trailer';
import {OrderModeItem} from "./order/order-mode-item";

export enum LoadType {
  UNDEFINED = 0,
  RECOVERY = 1,
  DELIVERY = 2,
  PICKUP = 3,
  DIRECT = 4,
  X_DOCK = 5
}

export namespace LoadTypes {
  const labels: Map<LoadType, string> = new Map<LoadType, string>([
    [LoadType.UNDEFINED, 'Not Specified'],
    [LoadType.RECOVERY, 'Recovery'],
    [LoadType.DELIVERY, 'Delivery'],
    [LoadType.PICKUP, 'Pick Up'],
    [LoadType.DIRECT, 'Direct'],
    [LoadType.X_DOCK, 'X-Dock']
  ]);

  const prefixes: Map<LoadType, string> = new Map<LoadType, string>([
    [LoadType.UNDEFINED, ''],
    [LoadType.RECOVERY, 'RLD'],
    [LoadType.DELIVERY, 'DLD'],
    [LoadType.PICKUP, 'PLD'],
    [LoadType.DIRECT, 'DIR'],
    [LoadType.X_DOCK, 'XLD']
  ]);




  export const values: LoadType[] = Object.keys(LoadType).filter(v => isNaN(Number(v))).map(key => valueOf(key));
  export function valueOf(key: string): LoadType {return LoadType[key]; }
  export function nameOf(value: LoadType): string {return value ? LoadType[value] : null; }
  export function labelOf(value: LoadType): string {return labels.get(value); }
  export function prefixOf(value: LoadType): string {return prefixes.get(value); }

  export const filterItems = values.filter(v => v !== LoadType.UNDEFINED).map(v => ({id: v, label: labelOf(v), name: nameOf(v) }));
}

export function transformLoadType(value: any, obj: any, transformationType: TransformationType): any {
  switch (transformationType) {
    case TransformationType.PLAIN_TO_CLASS: return value ? value.id : undefined;
    case TransformationType.CLASS_TO_PLAIN: return {id: value};
    default: return value;
  }
}


export class Load extends BaseEntity {
  public javaClassName: string = 'com.oms.entity.load.Load';
  @Type(() => Shipment)
  shipment: Shipment;
  @Type(() => Driver)
  driver: Driver;
  @Type(() => Truck)
  truck: Truck;
  @Type(() => Trailer)
  trailer: Trailer;
  direct: boolean;
  splitNumber: number;

  @Transform((v, o, tt) => transformLoadType(v, o, tt))
  loadType: LoadType = LoadType.UNDEFINED;

  @Exclude()
  get isDelivery(): boolean {
    return this.loadType === LoadType.DELIVERY;
  }

  @Exclude()
  get isRecovery(): boolean {
    return this.loadType === LoadType.RECOVERY;
  }

  @Exclude()
  get isPickedUp(): boolean {
    return this.shipment && !isNullOrUndefined(this.shipment.datePickUpActual);
  }

  @Exclude()
  get isDelivered(): boolean {
    return this.shipment && !isNullOrUndefined(this.shipment.dateDeliveryActual);
  }

  @Exclude()
  get driverName(): string {
    return this.driver ? this.driver.fullName : null;
  }
}
