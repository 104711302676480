var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Exclude, Type } from 'class-transformer';
import { Driver, Trailer, Truck, User } from '../';
import { ManifestItem } from './manifest-item';
import { BaseEntity } from '../base-entity';
import { UploadedFiles } from "../documents/uploaded.files";
import { convertManifestNumber } from "../../services/oms-converters.service";
import { OmsConstants } from "../../../../common/oms-constants.service";
import { Carrier } from "../carrier";
import { absent } from "../../../../_helpers/utils";
var ɵ0 = function () { return Date; }, ɵ1 = function () { return Date; }, ɵ2 = function () { return Date; }, ɵ3 = function () { return User; }, ɵ4 = function () { return User; }, ɵ5 = function () { return Driver; }, ɵ6 = function () { return Truck; }, ɵ7 = function () { return Trailer; }, ɵ8 = function () { return ManifestItem; }, ɵ9 = function () { return UploadedFiles; }, ɵ10 = function () { return Carrier; };
var Manifest = /** @class */ (function (_super) {
    __extends(Manifest, _super);
    function Manifest(id, dateDispatched) {
        var _this = _super.call(this, id) || this;
        _this.items = [];
        _this.dateDispatchedFor = dateDispatched;
        return _this;
    }
    Object.defineProperty(Manifest.prototype, "manifestNumber", {
        get: function () {
            return this.id ? convertManifestNumber(this.id) : 'N/A';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "addressPickup", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.addressPickUp; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "addressPickupName", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.addressPickUp && s.addressPickUp.name; }, OmsConstants.MULTIPLE_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "addressDeliveryName", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.addressDelivery && s.addressDelivery.name; }, OmsConstants.MULTIPLE_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "addressDelivery", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.addressDelivery; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "pieces", {
        get: function () {
            return this.items.aggregate(function (sum, s) { return sum + ~~s.pieces; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "hu", {
        get: function () {
            return this.items.aggregate(function (sum, s) { return sum + ~~s.hu; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "weight", {
        get: function () {
            return this.items.aggregate(function (sum, s) { return sum + (s.weight || 0); }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "customerRef", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.customerRef; }, OmsConstants.MULTIPLE_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "customer", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.customer; }, OmsConstants.MULTIPLE_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "freightForwarder", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.freightForwarder; }, OmsConstants.MULTIPLE_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "hawb", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.hawb; }, OmsConstants.MULTIPLE_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "mawb", {
        get: function () {
            return this.items.asUniqueValue(function (s) { return s.mawb; }, OmsConstants.MULTIPLE_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Manifest.prototype.findItem = function (orderId) {
        return this.items.find(function (mi) { return mi.hasOrder(orderId); });
    };
    Object.defineProperty(Manifest.prototype, "isApproved", {
        get: function () {
            return !!this.dateApprovedOn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Manifest.prototype, "isStaging", {
        get: function () {
            return absent(this.driver);
        },
        enumerable: true,
        configurable: true
    });
    Manifest.prototype.enumerate = function () {
        var index = 0;
        this.items.forEach(function (i) { return i.orderNumber = index++; });
    };
    Manifest.prototype.update = function (updatePieces) {
        if (updatePieces) {
            this.items.forEach(function (mi) { return mi.update(); });
        }
        this.items.sort(function (i1, i2) { return i1.orderNumber - i2.orderNumber; });
        this.enumerate();
    };
    Manifest.prototype.getUpdatedLoads = function () {
        return this.items.filter(function (mi) { return mi.isUpdatedByDriver(); });
    };
    Manifest.prototype.getNotUpdatedLoads = function () {
        return this.items.filter(function (mi) { return !mi.isUpdatedByDriver(); });
    };
    Manifest.prototype.allLoadsUpdated = function () {
        return this.items && this.items.length && this.items.every(function (mi) { return mi.isUpdatedByDriver(); });
    };
    Manifest.prototype.someLoadsUpdated = function () {
        return this.items && this.items.length && this.items.some(function (mi) { return mi.isUpdatedByDriver(); });
    };
    Manifest.prototype.allTrailerTheSame = function () {
        var trailers = [];
        trailers = this.items.map(function (mi) { return mi.trailer; }).unique();
        return trailers.length < 2;
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Date)
    ], Manifest.prototype, "dateDispatchedFor", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Date)
    ], Manifest.prototype, "dateDispatched", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Date)
    ], Manifest.prototype, "dateApprovedOn", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", User)
    ], Manifest.prototype, "dispatchedByUser", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", User)
    ], Manifest.prototype, "approvedByUser", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Driver)
    ], Manifest.prototype, "driver", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Truck)
    ], Manifest.prototype, "truck", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", Trailer)
    ], Manifest.prototype, "trailer", void 0);
    __decorate([
        Type(ɵ8),
        __metadata("design:type", Array)
    ], Manifest.prototype, "items", void 0);
    __decorate([
        Type(ɵ9),
        __metadata("design:type", UploadedFiles)
    ], Manifest.prototype, "manifestDocument", void 0);
    __decorate([
        Type(ɵ10),
        __metadata("design:type", Carrier)
    ], Manifest.prototype, "carrier", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", ManifestItem)
    ], Manifest.prototype, "findItem", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Manifest.prototype, "isApproved", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Manifest.prototype, "isStaging", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Array)
    ], Manifest.prototype, "getUpdatedLoads", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Array)
    ], Manifest.prototype, "getNotUpdatedLoads", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], Manifest.prototype, "allLoadsUpdated", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], Manifest.prototype, "someLoadsUpdated", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], Manifest.prototype, "allTrailerTheSame", null);
    return Manifest;
}(BaseEntity));
export { Manifest };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
