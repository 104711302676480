import {BehaviorSubject} from 'rxjs';
import {Logger} from "../../../../../../_helpers/logger";

@Logger({
  name: 'RowUpdater'
})
export class RowUpdater<T> {
  subject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public items: T[];

  constructor(
    private trackByRowId: (index, row: any) => any,
    private portions: number = 50,
    private interval: number = 0) {}

  private addData(buffer: T[], data: T[], afterUpdate?: () => void) {
    setTimeout((portion, rest) => {
      buffer = buffer.concat(portion);
      this.subject.next(buffer);
      if (rest.length > 0) {
        this.addData(buffer, rest);
      } else if (afterUpdate) {
        afterUpdate();
      }
    }, this.interval, data.splice(0, this.portions), data);
  }

  public setData(data: T[], instant: boolean = false, afterUpdate?: () => void) {
    this.items = data;
    if (instant) {
      this.subject.next(this.items);
      if (afterUpdate) { afterUpdate(); }
    } else {
      this.addData([], this.items ? [...this.items] : [], afterUpdate);
    } // new instance array!
  }

  public update(items: T[]) {
    if (this.items) {
      items.forEach(item => {
        this.items.forEach((el, index, array) => {
          if (this.trackByRowId(index, el) === this.trackByRowId(index, item)) {
            array[index] = item;
          }
        });
      });
      this.subject.next(this.items);
    }
  }

}
