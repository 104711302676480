var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { GenericTableCell } from "./generic-table-cell";
import { Logger } from "../../../../../../../_helpers/logger";
import { isNullOrUndefined } from "util";
import { isEmptyString } from "../../../../../../../_helpers/utils";
import { take, takeUntil } from "rxjs/operators";
import { DataTableComponent } from "../../data-table.component";
import { Router } from "@angular/router";
import { MasterService } from "../../../../../../../services/master/master.service";
import { MatDialog } from "@angular/material";
import { conformToMask } from "text-mask-core/dist/textMaskCore";
import { HistoryInformationDialogComponent } from "../../../../../../../components/common/history-information-dialog";
import { LogChangesService } from "../../../../../../../services/logchanges/log.changes.service";
import { composeMawbTrackLink, convertMawbNumber, getInvalidMAWBMessage, validMAWB } from "../../../../../services/oms-converters.service";
import { CbpStatusDialogComponent } from "../../../../../../../components/common/cbp-status-dialog/cbp-status-dialog.component";
var MawbTableCellComponent = /** @class */ (function (_super) {
    __extends(MawbTableCellComponent, _super);
    function MawbTableCellComponent(table, cdr, logChangesService, router, masterService, dialog) {
        var _this = _super.call(this) || this;
        _this.table = table;
        _this.cdr = cdr;
        _this.logChangesService = logChangesService;
        _this.router = router;
        _this.masterService = masterService;
        _this.dialog = dialog;
        _this.editing = false;
        _this.editOnFocus = false;
        _this.closeOnBlur = true;
        return _this;
    }
    MawbTableCellComponent.prototype.ngOnChanges = function (changes) {
        //    console.log('CHANGE CELL', changes);
        if (changes.column) {
            this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
        }
        if (changes.row || changes.column || changes.search) {
            this.updateObject();
        }
    };
    MawbTableCellComponent.prototype.updateObject = function () {
        _super.prototype.updateObject.call(this);
        this.rowObject = this.column.rowObject(this.row);
        this.rawValue = this.column.getValue(this.rowObject);
        this.editable = this.column.editable(this.row);
        this.value = this.convert(this.rowObject, this.rawValue);
        this.empty = isNullOrUndefined(this.rawValue);
        this.iconClass = this.column.getIconClass(this.rowObject, this.rawValue);
        this.cellClass = this.column.getClass(this.rowObject, this.rawValue) || '';
        this.showTracking = this.column.showTracking(this.row);
        this.hasIcon = !isNullOrUndefined(this.iconClass);
        this.tooltip = this.column.getTooltip(this.rowObject);
        var editor = this.editor;
        this.placeholder = typeof editor.placeholder === 'function' ? editor.placeholder(this.row, this.rawValue) : editor.placeholder || '';
        this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);
        this.display = this.column.editable && isEmptyString(this.value) ? this.table.emptyValue : this.value;
        this.cellButtonIcon = validMAWB(this.rawValue) ? 'fa fa-up-right-from-square' : 'fa fa-exclamation-triangle red-font';
        this.cellButtonTooltip = getInvalidMAWBMessage(this.rawValue) || 'Track MAWB';
        this.cellButtonLink = composeMawbTrackLink(this.rawValue);
        this.cdr.markForCheck();
    };
    MawbTableCellComponent.prototype.convert = function (rowObject, rawValue) {
        // return this.column.convert(rowObject, rawValue);
        return convertMawbNumber(rawValue);
    };
    MawbTableCellComponent.prototype.editColumnValue = function (ref, event) {
        if (this.column.editable(this.row)) {
            if (this.column.isDialog) {
                this.openDialog(ref, event);
            }
            else {
                // Textbox or Select edit
                this.startEdit();
            }
        }
    };
    MawbTableCellComponent.prototype.openDialog = function (ref, event) {
        this.dialog.open(CbpStatusDialogComponent, {
            width: '80%',
            data: { id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: this.table.readonly, uscsFsnStatus: CbpStatusDialogComponent.FILTER_BY_STATUS }
        });
    };
    MawbTableCellComponent.prototype.ngOnInit = function () {
        this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
        this.updateObject();
    };
    MawbTableCellComponent.prototype.startEdit = function () {
        var _this = this;
        console.log('Start Edit', this.column);
        //    if (this.table.editing && this.table.editing.error)
        //      return;
        this.editing = true;
        //    this.table.editing = this;
        this.localValue = isNullOrUndefined(this.rawValue) ? null : this.value; // !Important start edit with converted value
        //    console.log('Local', this.localValue);
        // Important: do on timeout after editors initialized!
        setTimeout(function () {
            if (_this.textInput) {
                _this.textInput.nativeElement.focus();
                _this.textInput.nativeElement.select();
            }
            _this.cdr.markForCheck();
        });
        this.cdr.markForCheck();
    };
    MawbTableCellComponent.prototype.cancelEdit = function () {
        if (this.editing) {
            this.editing = false;
            //      this.table.editing = undefined;
            this.error = undefined;
            this.cdr.markForCheck();
        }
    };
    MawbTableCellComponent.prototype.editError = function (error) {
        console.log('Error', error);
        this.error = error;
        this.table.editErrorEvent.emit(error);
    };
    MawbTableCellComponent.prototype.doneEdit = function () {
        var _this = this;
        console.log('Done Edit:', this.localValue);
        try {
            var newValue = this.convertBack(this.localValue);
            this.table.updateColumnValue(this.rowObject, this.column, newValue, this.column.field)
                .pipe(takeUntil(this.destroy$))
                .pipe(take(1))
                .subscribe(function (object) {
                _this.row = object;
                _this.updateObject();
            });
            this.error = undefined;
        }
        catch (error) {
            this.editError(error);
        }
        finally {
            this.editing = false;
            this.cdr.markForCheck();
        }
    };
    MawbTableCellComponent.prototype.onKeyDown = function (event) {
        if (this.editing) {
            if (event.key === 'Enter') {
                this.doneEdit();
                event.preventDefault();
            }
            else if (event.key === 'Escape') {
                this.cancelEdit();
                event.preventDefault();
            }
        }
    };
    MawbTableCellComponent.prototype.onClear = function () {
        this.closeOnBlur = false;
        this.localValue = null;
        this.textInput.nativeElement.focus();
        this.cdr.markForCheck();
    };
    MawbTableCellComponent.prototype.onBlurEditor = function () {
        var _this = this;
        this.editOnFocus = true;
        this.closeOnBlur = true;
        setTimeout(function () {
            if (_this.closeOnBlur) {
                _this.doneEdit();
            }
            _this.editOnFocus = false;
        });
    };
    MawbTableCellComponent.prototype.onDoubleClick = function (event) {
        if (this.editing) {
            event.stopPropagation();
        }
    };
    MawbTableCellComponent.prototype.cleanupMask = function (s, mask, clearMask) {
        // remove _ guide before passing to the model
        if (mask && mask.mask) {
            s = conformToMask(s, mask.mask, { guide: false }).conformedValue;
        }
        // keep only alpha-numerical values and '.' for number support
        if (clearMask) {
            s = s.replace(/[^0-9A-Za-z.]/g, '');
        }
        return s; // this.isNumber ? ( s ? Number(s) : null): s;
    };
    MawbTableCellComponent.prototype.convertBack = function (value) {
        if (typeof value === 'string') {
            value = this.cleanupMask(value, this.column.handlers.editor.mask, this.column.handlers.editor.clearMask);
        }
        return this.column.convertBack(value);
    };
    MawbTableCellComponent.prototype.onFocusColumn = function (cell, event) {
        if (this.editOnFocus) {
            this.editColumnValue(cell, event);
        }
    };
    MawbTableCellComponent.prototype.onRightClick = function () {
        if (this.column.history.logChanges) {
            var columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;
            var params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField);
            if (this.column.history.methodForExecution) {
                this[this.column.history.methodForExecution](params);
            }
            else {
                this.executeLogChanges(params, this.column.history.logConverter);
            }
        }
        return false;
    };
    MawbTableCellComponent.prototype.executeLogChanges = function (logParams, convertByBaseType) {
        this.dialog.open(HistoryInformationDialogComponent, {
            width: '80%',
            data: { logParams: logParams, column: this.column, convertByBaseType: convertByBaseType }
        });
    };
    MawbTableCellComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.cancelEdit();
        _super.prototype.ngOnDestroy.call(this);
    };
    MawbTableCellComponent = __decorate([
        Logger({
            name: 'MawbTableCellComponent'
        }),
        __metadata("design:paramtypes", [DataTableComponent,
            ChangeDetectorRef,
            LogChangesService,
            Router,
            MasterService,
            MatDialog])
    ], MawbTableCellComponent);
    return MawbTableCellComponent;
}(GenericTableCell));
export { MawbTableCellComponent };
