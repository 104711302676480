<div class="form-group oms-date-input" [ngClass]="{'inline': inline}">
  <label class="control-label" *ngIf="showLabel">{{label}}</label>
  <div  class="input-group" [ngClass]="{'input-group-sm': size == Size.SMALL, 'input-group-lg': size == Size.LARGE}" style="width: 100%">

    <span class="input-group-addon" *ngIf="showSwitch" style="vertical-align: bottom; text-align: -webkit-match-parent; padding: 0 0 0 8px;">
        <oms-toggle-switch [(ngModel)]="value || isEdit" (ngModelChange)="value = null"></oms-toggle-switch>
    </span>
    <span class="input-group-addon" *ngIf="showCheckbox">
      <oms-checkbox [checked]="!!value || isEdit" (checkedChange)="onCbClick()" [disabled]="disabled"></oms-checkbox>
      <!--span class="fancy-checkbox custom-bgcolor-green" (click)="onCbClick()">
        <input type="checkbox" [(ngModel)]="value || isEdit" (ngModelChange)="value = null" class="table-checkbox" title="" [attr.disabled]="disabled">
        <span></span>
      </span-->
    </span>

    <span class="input-group-addon if-not-empty">
        <ng-content select="[group-before]"></ng-content>
    </span>


    <input type="text" #input class="form-control" title=""
           [placeholder]="placeholder"
           [ngClass]="{'form-control-invalid':invalid | async, 'date-estimated':estimatedOnly}"
           [ngModel]="displayValue | oms_date:{time: time, format: displayFormat}"
           [attr.readonly]="''"
           [attr.disabled]="disabled ? '' : null"
           (click)="isEdit = true">

      <oms-date-time-popup #dateTimePopup
                    [top]="popupTop"
                    [bottom]="popupBottom"
                    [(value)]="value"
                    [(estimated)] = "estimated"
                    [minDate]="minDate" [maxDate]="maxDate"
                    (estimatedChange)="onEstimatedDateChanged($event)"
                    (valueChange)="onActualDateChanged($event)"
                    [estimation]="estimation"
                    [(showPopup)]="isEdit"
                    [showDate]="true"
                    [showTime]="time"
                    [clearButton]="{show: cleanable, label:'Clear', cssClass:'btn btn-danger'}"
                    [nowButton]="{show: true, label:'Now', cssClass:'btn btn-primary'}"
                    [closeButton]="{show: true, label:'Select', cssClass:'btn btn-primary'}">

    </oms-date-time-popup>

    <span class="input-group-btn">
      <button class="btn btn-clean btn-clear-input" *ngIf="value && showClearButton && cleanable" (click)="onClear(dateTimePopup.isActual);"
              [attr.disabled]="disabled ? '' : null">
          <i class="fa fa-remove" aria-hidden="true"></i>
      </button>

      <button class="btn btn-outline-secondary" (click)="isEdit = !isEdit" *ngIf="showButton" [attr.disabled]="disabled ? '' : null">
          <i class="fa fa-calendar" aria-hidden="true"></i>
      </button>
    </span>
  </div>
  <validation [@flyInOut]="'in,out'" *ngIf="invalid | async" [messages]="failures | async"></validation>
</div>

