import { Injectable } from '@angular/core';
import {RestService} from "../../../services/rest.service";
import {HttpUtilsService} from "../../../services/http-utils.service";
import {UsaState} from "../models/address/usa-state";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {Observable} from "rxjs";
import {Country} from "../models/address/country";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

const API_URL = "/oms/usa-state/";
@Injectable()
export class UsaStateService {

  public usaStateSubject = new BehaviorSubject<UsaState[]>(null);

  constructor(private httpRestService: RestService, private httpUtils: HttpUtilsService) { }

  getUsaStates() {
    this.httpRestService.get<UsaState[]>(API_URL + 'getAllUsaStates').subscribe(items => {
      this.usaStateSubject.next(items);
    });
  }

  getStateByUspsCode(code): Observable<UsaState> {
    return this.httpRestService.get<UsaState>(API_URL + 'getByUspsCode', code)
      .pipe(map(item=>{return plainToClass(UsaState, item)}));
  }




}
