
<div class="widget-content manifest-create-content">

  <div class="form-row top-panel">
    <!-- DATE -->
    <div class="col col-xs-2">
      <b>Date</b>
      <oms-date-input #dateInput
          [size]="Size.SMALL"
                      label="Route Date"
                      [showLabel]="false"
                      [placeholder]="'Route Date'"
                      [time]="false"
                      [show-switch]="false"
                      [show-checkbox]="false"
                      [ngModelOptions]="{standalone: true}"
                      [ngModel]="manifest.dateDispatchedFor" (ngModelChange)="onDateChange($event)">
      </oms-date-input>
    </div>

    <!-- CARRIER -->
    <div class="col col-xs-5" *ngIf="true">
      <b>Carrier</b>
      <oms-custom-search label="Carrier" [showLabel]="false"
                         [placeholder]="'Carrier'"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="manifest.carrier"
                         (create)="onCreateCarrier()"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         [size]="Size.SMALL"
                         bindLabel="name"
                         [allowCreate]="true"
                         [sort-by]="'name'"
                         [service]="carrierService">
      </oms-custom-search>
    </div>

    <!-- SEAL # -->
    <div class="col col-xs-5">
      <b>Seal#</b>
      <oms-string-input [(ngModel)]="manifest.sealNumber"
                        [ngModelOptions]="{standalone: true}"
                        [size]="Size.SMALL">
      </oms-string-input>
    </div>
  </div>

  <div class="form-row top-panel" *ngIf="!isExternalCarrier()">
    <!-- DRIVER -->
    <div class="col col-xs-4">
      <b>Driver</b>
      <oms-custom-search #driverInput
                         label="Driver" [showLabel]="false"
                         [placeholder]="'Driver'"
                         [ngModelOptions]="{standalone: true}"
                         [ngModel]="manifest.driver" (ngModelChange)="onDriverChange($event)"
                         (create)="onCreateDriver($event)"
                         [readonly]="readonly || _isStaging"
                         [disabled]="readonly || _isStaging"
                         [required]="!_isStaging"
                         [size]="Size.SMALL"
                         bindLabel="fullName"
                         [allowCreate]="true"
                         [sort-by]="'firstName'"
                         [items]="searchDrivers"
                         [service]="userService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="search">
          <div [ngOptionHighlight]="search">{{item.firstName}} {{item.lastName}}</div>
        </ng-template-->
      </oms-custom-search>
    </div>

    <!-- TRUCK -->
    <div class="col col-xs-4">
      <b>Truck</b>
      <oms-custom-search label="Truck #" [showLabel]="false"
                         [placeholder]="'Truck #'"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="manifest.truck"
                         [readonly]="readonly || _isStaging"
                         [disabled]="readonly || _isStaging"
                         [size]="Size.SMALL"
                         (create)="onCreateTruck($event)"
                         bindLabel="number"
                         [allowCreate]="true"
                         [sort-by]="'number'"
                         [service]="truckService">
      </oms-custom-search>
    </div>

    <!-- TRAILER -->
    <div class="col col-xs-4">
      <b>Trailer</b>
      <oms-custom-search label="Trailer #" [showLabel]="false"
                         [placeholder]="'Trailer #'"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="manifest.trailer"
                         (ngModelChange)="onManifestTrailerChange($event)"
                         (create)="onCreateTrailer($event, manifest)"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         [size]="Size.SMALL"
                         bindLabel="number"
                         [allowCreate]="true"
                         [sort-by]="'number'"
                         [service]="trailerService">
      </oms-custom-search>
    </div>
  </div>

  <div class="manifest-container-items">

    <div class="form-row manifest-items-scroller">
      <div class="manifest-items-header flex-row">
        <div class="col col-xs-3" style="min-width: 300px;">
            <label class="col col-xs-4 control-label">
              <input type="checkbox" [ngModel]="selectedAll"
                     [ngModelOptions]="{standalone: true}"
                     [indeterminate]="selectedSome && !selectedAll" (click)="toggleManifestSelected()">
              J#
            </label>
            <label class="col col-xs-4 control-label">Mawb</label>
            <label class="col col-xs-4 control-label">Hawb</label>
        </div>
        <div class="col col-xs-5">
            <label class="col col-xs-3 control-label">Ref</label>
            <label class="col col-xs-3 control-label">PO</label>
            <label class="col col-xs-3 control-label">From</label>
            <label class="col col-xs-3 control-label ">To</label>
        </div>
        <div class="col col-xs-4">
          <label class="col col-xs-2 control-label text-center">Hu</label>
          <label class="col col-xs-2 control-label text-center">PCS</label>
          <label class="col col-xs-2 control-label text-center">WGT</label>
          <label class="col col-xs-1 control-label text-center">ULD</label>
          <label class="col col-xs-5 control-label text-center">Trailer</label>
        </div>
      </div>

      <div #manifestItemPanel class="manifest-item row" *ngFor="let manifestItem of getDisplayManifestItems(); trackBy: trackByOrder; index as i;"
           droppable [dropScope]="'manifestItem'" (onDrop)="onDropItem(manifestItem, $event)"
           [ngClass]="getItemClass(manifestItem)"
           [draggable]="true" [dragScope]="'manifestItem'" [dragData]="manifestItem" [dragEnabled]="manifest.items.length > 1"
           [dragOverClass]="'item-droppable'" [dragHandleClass]="'item-draggable'" (contextmenu)="onContextMenu($event,  manifestItem, menu)">

        <div class="manifest-item-btns">
          <div (click)="upOrder(i)">
            <a title="Up" class="btn-borderless btn-remove"><i class="fa fa-arrow-up"></i></a>
          </div>
          <div class="" (click)="removeManifestItem(manifestItem)">
            <a title="Remove" class="btn-borderless btn-remove"><i class="fa fa-times"></i></a>
          </div>
          <div class="" (click)="downOrder(i)">
            <a title="Down" class="btn-borderless btn-remove"><i class="fa fa-arrow-down"></i></a>
          </div>
        </div>

        <div style="margin-left: 25px;">
          <!-- CONSOLIDATION HEADER -->
          <div class="row row-top flex-row" *ngIf="showAsConsolidated(manifestItem)" [ngClass]="{'panel-open': manifestItemPanel.open}">
            <div class="col col-xs-3" style="min-width: 300px;">
              <div class="col col-xs-4 " [style.background-color]="getColorForOrder(manifestItem.orders[0], manifestItem)">
                <input type="checkbox"
                       [ngModel]="manifestItem.isSelectedFully"
                       [ngModelOptions]="{standalone: true}"
                       [indeterminate]="manifestItem.isSelectedPartially" (click)="toggleManifestItemSelected(manifestItem)">
                <ng-container *ngIf="isRecoveryDirect(manifestItem); else ifConsolidated">
                  DR{{manifestItem.rld.id}}
                </ng-container>
                <ng-template #ifConsolidated>
                  <a class="manifest-item-value"
                     [popover]="convertLoadNumber(manifestItem?.shipment?.id)"
                     [ngClass]="{'dropup': manifestItemPanel?.open}"
                     (click)="manifestItemPanel.open = !manifestItemPanel.open">Consolidated<span class="caret" style="margin-left: 5px"></span>
                  </a>
                </ng-template>
              </div>
              <div class="col col-xs-4 manifest-item-value">{{getItemMawb(manifestItem)}}</div>
              <div class="col col-xs-4 manifest-item-value">{{getItemHawb(manifestItem)}}</div>
            </div>


            <div class="col col-xs-5">
              <div class="col col-xs-3 manifest-item-value"><!--{{getItemRef(manifestItem)}}-->
                <editable-cell [value]="getItemRef(manifestItem) || 'N/A'" (valueChange)="onCustomerRefChange(manifestItem, $event)"></editable-cell>
              </div>
              <div class="col col-xs-3 manifest-item-value"><!--{{getItemRef(manifestItem)}}-->
                <editable-cell [value]="getItemPo(manifestItem) || 'N/A'" (valueChange)="onPoChange(manifestItem, $event)"></editable-cell>
              </div>
              <div class="col col-xs-3 manifest-item-value">

                <editable-cell [custom]="true" [value]="manifestItem.addressPickUp?.label" [enabled]="canChangePickup(manifestItem)"
                               [popover]="getFullAddressText(manifestItem.addressPickUp)">
                  <ng-template #customTemplate>
                    <oms-address-search style="height: 24px;margin-top: 1px;" [showTooltip]="false"
                                        [separateSearch]="true"
                                        [(address)]="manifestItem.addressPickUp" [addressTypes]="getPickupAddressTypes(manifestItem)"
                                        class="inplace" [size]="Size.SMALL" [label]="null" [iconClass]="null">
                    </oms-address-search>
                  </ng-template>
                </editable-cell>

              </div>
              <div class="col col-xs-3 manifest-item-value">
                <editable-cell [custom]="true" [value]="getManifestItemAddressDeliveryName(manifestItem)" [enabled]="canChangeDelivery(manifestItem)"
                               [popover]="getFullAddressText(manifestItem.addressDelivery)">
                  <ng-template #customTemplate>
                    <oms-address-search style="height: 24px;margin-top: 1px;" [showTooltip]="false"
                                        [separateSearch]="true"
                                        [(address)]="manifestItem.addressDelivery" (addressChange)="onManifestItemAddressDeliveryChange(manifestItem, $event)" [addressTypes]="getDeliveryAddressTypes(manifestItem)"
                                        class="inplace" [size]="Size.SMALL" [label]="null" [iconClass]="null">
                    </oms-address-search>
                  </ng-template>
                </editable-cell>
                <!--
                <oms-address-search style="height: 24px;margin-top: 1px;" class="inplace" [size]="Size.SMALL" [label]="null" [iconClass]="null"></oms-address-search>
                -->
                <!-- {{manifestItem.addressDelivery?.name}} -->
              </div>
            </div>


            <div class="col col-xs-4">
              <!-- HU -->
              <div class="col col-xs-2 manifest-item-value">
                <span class="pull-right">{{manifestItem.hu}}</span>
                <!--<editable-cell type="number" [value]="manifestItem.hu"
                               (valueChange)="onChangeInput(manifestItem, 'hu', $event)">
                </editable-cell>-->
              </div>
              <!-- PCS -->
              <div class="col col-xs-2 manifest-item-value">
                <span class="pull-right">{{manifestItem.pieces}}</span>
                <!--editable-cell type="number" [value]="manifestItem.pieces"
                              (valueChange)="onChangeInput(manifestItem, 'pieces', $event)">
                </editable-cell-->
              </div>
              <!-- WGHT -->
              <div class="col col-xs-2 manifest-item-value">
                <span class="pull-right">{{manifestItem.weight | number:'1.0-1'}}</span>
                <!--<editable-cell
                  type="number"
                  [value]="manifestItem.weight"
                  (valueChange)="onChangeInput(manifestItem, 'weight', $event)"></editable-cell>-->
              </div>
              <!-- ULD -->
              <div class="col col-xs-1 manifest-item-value" (click)="openUldDialog(manifestItem)">
                  <a class="editable editable-click pull-right" style="margin-right: 5px; height: 22px">{{manifestItem.uldCount || 0}}</a>
              </div>
              <!-- TRAILER -->
              <div class="col col-xs-5 manifest-item-value text-center">
                <editable-cell [custom]="true" [value]="manifestItem.trailer?.number" [enabled]="true">
                  <ng-template #customTemplate>
                    <oms-custom-search label="Trailer #" [showLabel]="false"
                                       [placeholder]="'Trailer #'"
                                       [ngModelOptions]="{standalone: true}"
                                       [(ngModel)]="manifestItem.trailer"
                                       (create)="onCreateTrailer($event, manifestItem)"
                                       [readonly]="readonly"
                                       [disabled]="readonly"
                                       [size]="Size.SMALL"
                                       bindLabel="number"
                                       [allowCreate]="true"
                                       [sort-by]="'number'"
                                       [service]="trailerService">
                    </oms-custom-search>
                  </ng-template>
                </editable-cell>
              </div>
            </div>

          </div>

          <!-- ORDER ROW -->
          <div class="row row-top flex-row"
               [ngClass]="{'row-consolidated': showAsConsolidated(manifestItem)}" *ngFor="let order of getDisplayManifestItemOrders(manifestItem); trackBy: trackById"
               [hidden]="showAsConsolidated(manifestItem) && !manifestItemPanel?.open">
            <div class="col col-xs-3" style="min-width: 300px;">
              <div class="col col-xs-4">
                <label style="padding: 0 5px; white-space: nowrap;" class="select-checkbox"
                       [style.background-color]="getColorForOrder(order, manifestItem)"
                       [popover]="convertLoadNumber(manifestItem?.shipment?.id)">
                  <input type="checkbox"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="order.selected">{{' '+convertOrderNumber(order.id, order.isRecovery)+' '}}
                  <!--a [routerLink]="['/home/order', {id:order.id}]" target="_blank"><i class="fa fa-up-right-from-square"></i></a-->

                  <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu" role="menu">
                    <li><a [routerLink]="['/home/order', {id:order.id}]" target="_blank"><i class="fa fa-up-right-from-square"></i><span>Open Order&hellip;</span></a></li>
                    <li class="divider if-not-last-child"></li>
                    <!--li *ngIf="showAsConsolidated(manifestItem)"><a (click)="deconsolidateOrder(manifestItem, order)"><i class="fa fa-object-ungroup"></i><span>Deconsolidate&hellip;</span></a></li-->
                    <li><a (click)="removeOrder(manifestItem, order)"><i class="fa fa-times"></i><span>Delete from Manifest&hellip;</span></a></li>
                    <li class="divider if-not-last-child"></li>
                  </ul>

                </label>




              </div>
              <div class="col col-xs-4 manifest-item-value">{{convertMawb(order.mawb)}}</div>
              <div class="col col-xs-4 manifest-item-value">{{order.hawb}}</div>
            </div>
            <div class="col col-xs-5">
              <div class="col col-xs-3 manifest-item-value">
                <editable-cell [value]="order.customerRef || 'N/A'" (valueChange)="order.customerRef = $event"></editable-cell>
              </div>
              <div class="col col-xs-3 manifest-item-value">
                <editable-cell [value]="order.purchaseOrder || 'N/A'" (valueChange)="order.purchaseOrder = $event"></editable-cell>
              </div>
              <div class="col col-xs-3 manifest-item-value">
                <editable-cell [custom]="true" [value]="manifestItem.addressPickUp?.label"
                               [enabled]="canChangePickup(manifestItem)"
                               [popover]="getFullAddressText(manifestItem.addressPickUp)">
                  <ng-template #customTemplate>
                    <oms-address-search style="height: 24px;margin-top: 1px;" [showTooltip]="false"
                                        [(address)]="manifestItem.addressPickUp" [addressTypes]="getPickupAddressTypes(manifestItem)"
                                        class="inplace" [size]="Size.SMALL" [label]="null" [iconClass]="null">
                    </oms-address-search>
                  </ng-template>
                </editable-cell>

                <!--
                                {{manifestItem.addressPickUp?.name}}
                -->
              </div>
              <div class="col col-xs-3 manifest-item-value">
                <editable-cell [custom]="true" [value]="order.info.legAddressDelivery?.label"
                               [enabled]="canChangeDeliveryForOrder(manifestItem)"
                               [popover]="getFullAddressText(order.info.legAddressDelivery)">
                  <ng-template #customTemplate>
                    <oms-address-search style="height: 24px;margin-top: 1px;" [showTooltip]="false"
                                        [(address)]="order.info.legAddressDelivery" (addressChange)="onOrderAddressDeliveryChange(manifestItem, order, $event)"
                                        [addressTypes]="getDeliveryAddressTypes(manifestItem)"
                                        class="inplace" [size]="Size.SMALL" [label]="null" [iconClass]="null">
                    </oms-address-search>
                  </ng-template>
                </editable-cell>
                <!--
                <oms-address-search style="height: 24px;margin-top: 1px;" class="inplace" [size]="Size.SMALL" [label]="null" [iconClass]="null"></oms-address-search>
                -->
                <!-- {{manifestItem.addressDelivery?.name}} -->
              </div>
            </div>
            <div class="col col-xs-4">
              <div class="col col-xs-2 manifest-item-value">
                <!--span class="pull-right">{{manifestItem.isRecovery ? manifestItem.hu : order.hu}}</span-->
                <editable-cell class="pull-right"  type="number" [(value)]="order.hu"
                               (valueChange)="onChangeInput(order, 'hu', $event)">
                </editable-cell>
              </div>
              <div class="col col-xs-2 manifest-item-value">
                <!--span class="pull-right">{{manifestItem.isRecovery ? manifestItem.pieces : order.pieces}}</span-->
                <editable-cell class="pull-right"  type="number"
                    [(value)]="order.pieces" (valueChange)="onChangeInput(order, 'pieces', $event)">
                </editable-cell>
              </div>
              <div class="col col-xs-2 manifest-item-value">
                <span class="pull-right">{{order.weight | number:'1.0-1'}}</span>
                <!--<editable-cell
                  type="number"
                  [value]="manifestItem.weight"
                  (valueChange)="onChangeInput(manifestItem, 'weight', $event)"></editable-cell>-->
              </div>
              <div class="col col-xs-1 manifest-item-value" (click)="openUldDialog(manifestItem)">
                <a class="editable editable-click pull-right" style="margin-right: 5px; height: 22px">{{manifestItem.uldCount || 0}}</a>
              </div>
              <div class="col col-xs-5 manifest-item-value text-center">
                <editable-cell [custom]="true" [value]="manifestItem.trailer?.number" [enabled]="true">
                  <ng-template #customTemplate>
                    <oms-custom-search label="Trailer #" [showLabel]="false"
                                       [placeholder]="'Trailer #'"
                                       [(ngModel)]="manifestItem.trailer"
                                       (create)="onCreateTrailer($event, manifestItem)"
                                       [readonly]="readonly"
                                       [disabled]="readonly"
                                       [size]="Size.SMALL"
                                       bindLabel="number"
                                       [allowCreate]="true"
                                       [sort-by]="'number'"
                                       [service]="trailerService">
                    </oms-custom-search>
                  </ng-template>
                </editable-cell>
              </div>
            </div>
          </div>

          <div class="form-row row-bottom" style="display: flex;">

            <!-- LOAD TYPE SELECTOR -->
            <oms-select-button class="mode-selector" btn-class="btn-xs btn-primary" [size]="Size.SMALL" (selectedChange)="onLoadTypeChange(manifestItem, $event)"
              [(selected)]="manifestItem.loadType" [items]="getAllowedLoadTypes(manifestItem)" [converter]="LoadTypes.labelOf">
            </oms-select-button>

            <!-- COMMENTS -->
            <textarea class="form-control white-space_no-wrap" placeholder="Comments" appOnInit (onInit)="onCommentsInit($event)"
                      oninput='this.style.height = "";this.style.height = this.scrollHeight + 3+ "px"'
                      [(ngModel)]="manifestItem.comments" rows="1" maxlength="85">

            </textarea>
          </div>
        </div>
      </div>
    </div>

  </div>

  <manifest-item-context-menu #menu
                              [hidden]="true"
                              [manifest]="manifest"
                              [manifestItem]="manifestItem"
                              [items]="getDisplayManifestItems()"
                              [selectedOrder]="selectedOneOrder"
                              [isVisibleSelect]="true"
                              (manifestChange)="manifestChange.emit($event)"
                              (loadingChange)="onLoading($event)"
                              (refreshChange)="onRefresh()">
  </manifest-item-context-menu>

  <div class="table-footer widget-footer btn-toolbar">
    <ul class="paging-body">
      <li class="paging-item">
        <label>
          PCS: {{totalPcs}}
        </label>
      </li>
      <li class="paging-item">
        <label>
          HU: {{totalHu}}
        </label>
      </li>
      <li class="paging-item">
        <label>
          Weight: {{totalWeight | number:'0.0-1'}} KG
        </label>
      </li>
      <li class="paging-item">
        <label>
          ULD: {{totalUld}}
        </label>
      </li>
    </ul>
  </div>
</div>


