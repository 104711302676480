var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntity } from '../base-entity';
var Country = /** @class */ (function (_super) {
    __extends(Country, _super);
    function Country() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Country.prototype, "isUsa", {
        get: function () {
            return !this.isNew() && (this.iso2Code === Country.USA_CODE);
        },
        enumerable: true,
        configurable: true
    });
    Country.isUsa = function (country) {
        return country.iso2Code === Country.USA_CODE;
    };
    Country.USA_CODE = 'US';
    return Country;
}(BaseEntity));
export { Country };
