import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {
  convertESTToLocal,
  convertLocalDateToEST,
  ensureDate,
  minDate,
} from '../../../../../../common/oms-date-time.utils';
import {convertToStandardDateOnly, convertToStandardDateTime} from '../../../../services/oms-converters.service';
import {isNullOrUndefined} from "util";
import {UserPreferencesService} from "../../../../../../services/user-preferences.service";
import {Subscription} from "rxjs";


export interface IDatetimePopupButtonOptions {
  show: boolean;
  label: string;
  cssClass: string;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'oms-date-time-popup',
  templateUrl: './date-time-popup.component.html',
  styleUrls: ['date-time-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateTimePopupComponent implements OnChanges, OnDestroy {
  @Input('show-header') public showHeader = true;
  @Input() public left: number;
  @Input() public top: number;
  @Input() public bottom: number;
  @Input() public right: number;

  @Input() public  timeFormat12: boolean = true;

  @Input() set position(value: {left: number, top: number, right?: number, bottom?: number}) {
    this.left = value.left;
    this.top = value.top;
    this.bottom = value.bottom;
    this.right = value.right;
  }

  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('btnClose') btnClose: ElementRef;
  @ViewChild('dropdown') public dropdown: BsDropdownDirective;

  @Input() public value: Date | string;
  @Output() public valueChange = new EventEmitter();
  @Input() public estimated: Date | string;
  @Output() public estimatedChange = new EventEmitter();

  @Input() public appendTo: string = 'body';
  @Input() public showPopup = false;
  @Output() public showPopupChange = new EventEmitter();
  @Input() public showDate = true;
  @Input() public showTime = true;
  @Input() public showWeeks = false;
  @Input() public datepickerMode = 'day';
  @Input() public initDate: Date = null;
  @Input() public minDate: Date = null;
  @Input() public maxDate: Date = null;
  @Input() public dateDisabled: any[] = [];
  @Input() public nowButton: IDatetimePopupButtonOptions;
  @Input() public clearButton: IDatetimePopupButtonOptions;
  @Input() public closeButton: IDatetimePopupButtonOptions;

  @Input() estimation: boolean = false;
  public activeTab: 'actual' | 'estimated'  = 'actual';

  public isOpening: boolean = false;
  public isDropUp: boolean = false;
  private today: Date = new Date();
  private sub$: Subscription[] = [];

  public tempLocalActual: Date = null;
  public tempLocalEstimated: Date = null;
  public actualDateInTheFuture: boolean = false;

  constructor(public userPreferences: UserPreferencesService) {
    this.sub$.push(userPreferences.getTimeFormat12().subscribe((format) => {

    }));
  }

  public ngOnDestroy() {
    this.sub$.forEach((s)=>s.unsubscribe());
  }

  get estValue(): Date {
    return this.estimation && this.activeTab === 'estimated' ? this.tempLocalEstimated : this.tempLocalActual;
  }

  get viewTimeValue() {
    return convertToStandardDateTime(this.estValue);
  }

  get viewDateValue() {
    return convertToStandardDateOnly(this.estValue);
  }


  set estValue(value: Date) {
    if (this.estimation && this.activeTab === 'estimated') {
      this.tempLocalEstimated = value;
    } else {
      this.tempLocalActual = value;
    }
  }


  public ngOnChanges(changes: any) {
//    console.log('CHANGES', changes);

    if (!this.nowButton) {this.nowButton = { show: true, label: 'Now', cssClass: 'btn btn-secondary btn-sm'}; }
    if (!this.clearButton) {this.clearButton = { show: true, label: 'Clear', cssClass: 'btn btn-secondary btn-sm'}; }
    if (!this.closeButton) {this.closeButton = { show: true, label: 'Close', cssClass: 'btn btn-secondary btn-sm'}; }

    // user maybe typing a value into an input box, so would come in as string
    this.tempLocalActual = /*this.localActual =*/ ensureDate(this.value) || this.localToEst(new Date()); // this.localToEst(new Date());
    this.tempLocalEstimated = /*this.localEstimated =*/ ensureDate(this.estimated) || this.localToEst(new Date());

//    this.tempLocalActual = this.localActual;
//    this.tempLocalEstimated = this.localEstimated;
    this.actualDateInTheFuture = false;

    // toggle if open
    if (changes.showPopup) {
      this.showPopup = changes.showPopup.currentValue === true;
      if (this.showPopup) {
        setTimeout(() => this.focus(), 100);
      }
    }
  }


  public onOpenChange() {
    console.log(this.dropdown);
    this.isOpening = true;
    setTimeout(() => this.isOpening = false, 250);
  }

  public onNow() {
    this.applyDate(this.localToEst(new Date()), true);
  }

  private localToEst(date: Date) {
    let convertedDate = isNullOrUndefined(date) ? date :
      new Date(this.showTime ?
        convertToStandardDateTime(date) :
        convertToStandardDateOnly(date));

//    console.log('CONVERTED', date, '->', convertedDate);
    return convertLocalDateToEST(convertedDate);
  }

  public onClear() {
    this.applyDate(null, true);
  }

  isInFuture(date: Date): boolean {
//    console.log('CHECKING DATE IN FUTURE', date, this.estValue);
    return isNullOrUndefined(date) ? false : date > new Date(); // !moment(this.maxDateInESTFunc).isSameOrAfter(date);
  }

  public onClose(apply: boolean) {

    if (this.showPopup) {
      if (apply) {
        if (this.activeTab === 'actual') {
          if (this.estimation && this.isInFuture(this.tempLocalActual)) {
            this.actualDateInTheFuture = true;
            return;
          }
//          console.log("ACT", this.tempLocalActual);
          this.valueChange.emit(this.tempLocalActual);
        }

        if (this.activeTab === 'estimated') {
//          console.log("EST", this.tempLocalEstimated);
          this.estimatedChange.emit(this.tempLocalEstimated);
        }

      }

      this.showPopup = false;
      this.showPopupChange.emit(false);
    }
  }

  public applyDate(date: Date, forceClose?: boolean) {
//    console.log('APPLY---', date);
    this.actualDateInTheFuture = false;
    this.estValue = date;

    if (!this.isOpening) {
      if (this.showDate && !this.showTime || forceClose) {
        this.onClose(true);
      }
    }
  }

  public onTimePickerChange(date: Date) {
    if (!this.isOpening) {
      let currentDate: Date = this.estValue;

      if (isNullOrUndefined(currentDate)) {
        currentDate = date;
      } else {
        currentDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        currentDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
      }

      this.applyDate(convertLocalDateToEST(currentDate), false);
    }
  }

  public onDatePickerChange(date: Date) {
    if (!this.isOpening) {

      let currentTime: Date = this.estValue;

      if (isNullOrUndefined(currentTime)) {
        currentTime = date;
      } else {
        if (this.showTime) {
          let t = convertESTToLocal(currentTime);
          currentTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
          currentTime.setHours(t.getHours(), t.getMinutes(), t.getSeconds());
        } else {
          currentTime = date;
        }
      }

      this.applyDate(convertLocalDateToEST(currentTime), !this.showTime);
    }
  }

  private focus() {
    if (this.btnClose) {
      this.btnClose.nativeElement.focus();
    }
  }

  set isEstimated(value) {
    this.activeTab = value ? 'estimated' : 'actual';
  }

  get isEstimated() {
    return this.activeTab === 'estimated';
  }

  get isActual() {
    return this.activeTab === 'actual';
  }


  get getMaxDate() {
    return this.estimation ? (this.isActual  ?  minDate(this.maxDate, this.today) : this.maxDate) : this.maxDate;
  }

  get popupStyles() {
    let styles = {};
    styles['top'] = isNullOrUndefined(this.top) ? 'auto' : this.top + 'px';
    styles['left'] = isNullOrUndefined(this.left) ? 'auto' : this.left + 'px';
    styles['right'] = isNullOrUndefined(this.right) ? 'auto' : this.right + 'px';
    styles['bottom'] = isNullOrUndefined(this.bottom) ? 'auto' : this.bottom + 'px';
    return styles;
  }

}
