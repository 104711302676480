import {Airline, Airport, Master, MasterShipmentAir, MasterStatusId, transformMasterStatusId} from '../index';
import {Shipment} from '../shipment';
import {Type, Transform} from 'class-transformer';
import {addHours} from '../../../../common/oms-date-time.utils';
import {isNullOrUndefined} from 'util';
import {BaseEntity} from '../base-entity';

export class MasterShipment extends BaseEntity {
  public rowId = 'MasterShipment';
  public javaClassName: string = 'com.oms.entity.master.MasterShipment';

  @Type(() => Master)
  master: Master;
  @Type(() => Shipment)
  shipment: Shipment;
  @Type(() => MasterShipmentAir)
  masterShipmentAir: MasterShipmentAir;
  @Type(() => Date)
  dateLastFreeDay: Date;
  @Type(() => Date)
  date1F: Date;
  @Transform((v, o, tt)=>transformMasterStatusId(v, o, tt))
  status: MasterStatusId = MasterStatusId.NEW;

  get flightNumber(): string {
    return this.masterShipmentAir ? this.masterShipmentAir.flightNumber : undefined;
  }

  set flightNumber(value: string) {
    if (this.masterShipmentAir)
      this.masterShipmentAir.flightNumber = value;
  }

  get airline(): Airline {
    return this.masterShipmentAir ? this.masterShipmentAir.airline : undefined;
  }

  set airline(value: Airline) {
    if (this.masterShipmentAir)
      this.masterShipmentAir.airline = value;
  }

  get airport(): Airport {
    return this.masterShipmentAir ? this.masterShipmentAir.airport : undefined;
  }

  set airport(value: Airport) {
    if (this.masterShipmentAir)
      this.masterShipmentAir.airport = value;
  }


  get splitNumber(): number {
    return this.master.masterShipments.indexOf(this) + 1;
  }

  get hu(): number {
    return this.shipment ? this.shipment.hu : null;
  }

  set hu(value: number) {
    if (this.shipment)
      this.shipment.hu = value;
  }

  get pieces(): number {
    return this.shipment ? this.shipment.pieces : null;
  }

  set pieces(value: number) {
    if (this.shipment)
      this.shipment.pieces = value;
  }

  get weight(): number {
    return this.shipment ? this.shipment.weight : null;
  }

  set weight(value: number) {
    if (this.shipment)
      this.shipment.weight = value;
  }

  get volume(): number {
    return this.shipment ? this.shipment.volume : null;
  }

  set volume(value: number) {
    if (this.shipment)
      this.shipment.volume = value;
  }

  get dateArrivalAct(): Date {
    return this.shipment ? this.shipment.dateDeliveryActual : null;
  }

  set dateArrivalAct(date: Date) {
    if (this.shipment)
      this.shipment.dateDeliveryActual = date;
  }

  get dateArrivalEst() {
    return this.shipment ? this.shipment.dateDeliveryExpectation : null;
  }

  set dateArrivalEst(date: Date) {
    if (this.shipment)
      this.shipment.dateDeliveryExpectation = date;
  }

  public updateLastFreeDay(): Date {
    let date = this.dateArrivalAct;
    if (isNullOrUndefined(date)) {
      date = this.dateArrivalEst;
    }
    this.dateLastFreeDay = addHours(date, 24);
    return this.dateLastFreeDay;
  }


}
