<label>{{options.label}}</label>

<span dropdown container="body" class="input-group input-group-sm"
      *ngIf="options.search?.searchable && (options.search.searchType == null || options.search.searchType == 'text'|| options.search.searchType == 'boolean')">
    <input dropdownToggle class="form-control input-sm inplace" placeholder="Search..." type="text" [(ngModel)]="options.search.search"
       aria-controls="column-search-input-menu" (ngModelChange)="searchChanged()" [readonly]="false">

    <span class="input-group-btn">
      <button class="btn btn-clean btn-clear-input" (click)="clearInput(options)" *ngIf="options.search.search && options.search.search.length">
        <i class="fa fa-times"></i>
      </button>

      <ul *dropdownMenu id="column-search-input-menu" class="dropdown-menu" role="menu">
        <!--li class="dropdown-header">Filter:</li-->
        <li *ngIf="options.search?.searchType === 'boolean'"><a (click)="onSearch(options, 'YES')">Yes <em style="float: right;">(Checked)</em></a></li>
        <li *ngIf="options.search?.searchType === 'boolean'"><a (click)="onSearch(options, 'NO')">No <em style="float: right;">(Unchecked)</em></a></li>

        <li class="divider if-not-first-child"></li>

        <li><a (click)="onSearch(options, 'N/A')">Search N/A</a></li>
        <li><a (click)="onSearch(options, null)">Clear</a></li>
      </ul>

    </span>
</span>


<ng-select *ngIf="options.search?.searchable && options.search?.searchType == 'list'"
  class="form-control input-sm inplace filters"

  [(ngModel)]="options.search.selected"
  [id]="options.field"
  [labelForId]="options.field"
  [clearable]="true"
  [items]="options.search.items"
  (change)="onSelectSearchItem($event, options, options.search.multiple)"
  (clear)="onSelectSearchItem($event, options, options.search.multiple)"
  [multiple]="options.search.multiple"
  [compareWith]="compareById"
  [searchable]="options.search.searchable"
  [closeOnSelect]="!options.search.multiple"
  appendTo="body"
  style="position: relative;">

  <ng-template ng-multi-label-tmp let-items="items">
    <div class="multi-selected-items">{{items.length>1 ? items.length+' selected' : items[0]?.label || items[0]}}</div>
  </ng-template>

  <!--ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!!labelTemplate">
    <ng-container *ngTemplateOutlet="labelTemplate; context:{item: item, clear: clear}"></ng-container>
  </ng-template-->

  <ng-template ng-option-tmp let-item="item">
    <li><a><label [ngClass]="{'radio': !options.search.multiple, 'checkbox': options.search.multiple}">{{item?.label || item}}</label></a></li>
  </ng-template>



</ng-select>
