import {BaseEntity} from '../../shared/models';
import {BaseEntityService} from '../../../services/base/base-entity.service';
import {OmsAlertsService} from '../../shared/components/oms-alerts/oms-alerts.service';
import {FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {FormUtils} from '../../../_helpers/form-utils';
import {AbstractComponent} from "../../../common/component/abstract.component";

export abstract class AbstractEntityDialogComponent<T extends BaseEntity> extends AbstractComponent {

  edit = false;

  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;

  form: FormGroup;

  protected constructor(protected dialogRef: MatDialogRef<any>,
                        protected itemService: BaseEntityService<T>,
                        protected alerts: OmsAlertsService) {
    super();
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && control.touched;
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;
    const controls = this.form.controls;

    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach((controlName) => {
        controls[controlName].markAsTouched();
      });
      this.hasFormErrors = true;
      return;
    }

    this.update(this.prepare());
  }

  abstract prepare(): T;

  update(item: T) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    this.itemService.save(item)
      .subscribe((res) => {
        this.viewLoading = false;
        this.dialogRef.close({res, isEdit: !item.isNew()});
      }, error => {
        this.viewLoading = false;
        this.alerts.danger(error);
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getControlErrorMessage(controlName: string, fieldName: string): string {
    const control = this.form.controls[controlName];
    return FormUtils.getControlErrorMessage(control, fieldName);
  }
}
