var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from "class-transformer";
import { BaseEntity } from "./base-entity";
import { Uld } from "./master/masterAirUld";
import { Shipment } from "./shipment";
var ɵ0 = function () { return Uld; }, ɵ1 = function () { return Shipment; };
var UldLinked = /** @class */ (function (_super) {
    __extends(UldLinked, _super);
    function UldLinked() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.javaClassName = 'com.oms.entity.air.UldLinked';
        return _this;
    }
    Object.defineProperty(UldLinked.prototype, "docCenter", {
        get: function () {
            return "";
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Uld)
    ], UldLinked.prototype, "uld", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Shipment)
    ], UldLinked.prototype, "recoveryShipment", void 0);
    return UldLinked;
}(BaseEntity));
export { UldLinked };
export { ɵ0, ɵ1 };
