import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {Logger} from "../../../../../../../_helpers/logger";
import {isNullOrUndefined} from "util";
import {take, takeUntil} from "rxjs/operators";
import {DataTableComponent} from "../../data-table.component";
import {GenericTableCell} from "../mawb-table-cell/generic-table-cell";

@Component({
  selector: 'editing-table-cell',
  templateUrl: './editing-table-cell.component.html',
  styleUrls: ['./editing-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({
  name: 'EditingTableCellComponent'
})
export class EditingTableCellComponent extends GenericTableCell<any> implements OnInit, OnDestroy, OnChanges {

  @ViewChild('textInput') textInput: ElementRef;

  public localValue: any;
  public error;
  value: string;
  rawValue: any;
  private rowObject: any;

  constructor(
    @Inject(DataTableComponent) public table: DataTableComponent,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.row || changes.column) {
      this.updateObject();
    }
  }

  public updateObject() {
    super.updateObject();
    this.rowObject = this.column.rowObject(this.row);
    this.rawValue = this.column.getValue(this.rowObject);
    this.value = this.column.convert(this.rowObject, this.rawValue);
    this.localValue = this.value;
    this.cdr.markForCheck();
  }

  ngOnInit() {
    this.updateObject();
  }

  private editError(error) {
    console.log('Error', error);
    this.error = error;
    this.table.editErrorEvent.emit(error);
  }

  public doneEdit() {
    console.log('Done Edit:', this.localValue);
    try {
      let newValue = this.convertBack(this.localValue);
      this.table.updateColumnValue(this.rowObject, this.column, newValue, this.column.field)
        .pipe(takeUntil(this.destroy$))
        .pipe(take(1))
        .subscribe(object => {
          this.row = object;
          this.updateObject();
        });
      this.error = undefined;
    } catch (error) {
      this.editError(error);
    } finally {
      this.cdr.markForCheck();
    }
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.doneEdit();
      event.preventDefault();
    } else if (event.key === 'Escape') {
      event.preventDefault();
    }
  }

  onBlurEditor() {
    if (this.localValue !== this.value) {
      this.doneEdit();
    }
  }

  private convertBack(value: any): any {
    return this.column.convertBack(value);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    delete this.table;
  }
}
