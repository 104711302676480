﻿import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import {AddressService} from '../../../services';
import {Address, AddressType, Airport, Customer} from '../../../modules/shared/models';
import {AddressDialogComponent} from '../../../modules/shared/components/address-dialog';
import {MatDialog} from '@angular/material';
import {asArray} from '../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {OmsDialogsService} from '../oms-dialogs/oms-dialogs.service';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {Size} from '../../../common/oms-types';
import {BaseInputSelectComponent} from "../../../modules/shared/components/base/base-input/base-input-select.component";
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {AddressSearchService} from '../../../modules/shared/services/address-search.service';
import {NgSelectComponent} from "@ng-select/ng-select";
import {AirportService} from "../../../services/air/airport.service";
import {FilterSearchColumn} from "../../../modules/shared/models/filter.search.column";
import {FilterPageRequest} from "../../../modules/shared/models/filter.page.request";
import {SearchSortRequest} from "../../../modules/shared/models/search.sort.request";
import {KeyCode} from "../../../modules/shared/components/common/input/ng-select-types/ng-select.types";

@Component({templateUrl: 'airport-search.component.html',
            selector: 'oms-airport-search',
            styleUrls: ['./airport-search.component.scss'],
            encapsulation: ViewEncapsulation.None,
            providers: [{provide: NG_VALUE_ACCESSOR, useExisting: AirportSearchComponent, multi: true}],
            changeDetection: ChangeDetectionStrategy.OnPush})
export class AirportSearchComponent extends BaseInputSelectComponent<any> implements OnInit {
  static initial_id = 0;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  identifier = 'airport-search_' + AirportSearchComponent.initial_id++;

  Size = Size;

  @Input()size: Size = Size.DEFAULT;
  @Input() disabled: boolean;
  @Output() airportChange = new EventEmitter();
  @Input() arrival: boolean;
  @Input() departure: boolean;
  @Input() count: number;
  private _input: string;
  subjectAirports: BehaviorSubject<Airport[]> = new BehaviorSubject([]);
  searchSubjectText: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    public airportService: AirportService,
    public dialog: MatDialog,
    public alerts: OmsAlertsService,
    public dialogs: OmsDialogsService) {
      super(validators, asyncValidators);
      this.iconClass = 'fa fa-fighter-jet';
  }

  change(airport: Airport) {
    this.isOpen = false;
    this.airportChange.emit(airport);
  }

  ngOnInit() {
    this.initSearch();
  }

  get tooltip(){
    return isNullOrUndefined(this.value) ? "" : this.value.fullName;
  }

  onOpen() {
  /*  if (this.items && this.items.length && this.searchService)
      this.searchService.subjAddresses.next(this.items);
    else*/
      this.searchSubjectText.next('');
  }

  setValue(value) {
    super.setValue(value);
  }

  public search() {
    this.searchSubjectText.next(this.searchSubjectText.value);
  }

  onKeyUp(event:KeyboardEvent) {
    if (event.key !== 'Tab') {
      const search = (<HTMLInputElement>event.target).value.trim();
      if (search !== this._input) {
        this._input = search;
        this.searchSubjectText.next(search);

       // this.search();
      }
    }
  }

  onKeyDown(event) {
    if (event.keyCode === KeyCode.Tab) {
      if(this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value){
        this.setValue(this.ngSelect.itemsList.markedItem.value);
      }
      this.ngSelect.filterValue = null;
      this.isOpen = false;
    }
  }

  initSearch(){
    const results$ = this.searchSubjectText.pipe(
      debounceTime(300),
      switchMap(search =>
        this.searchServiceFunction(search)
      )
    ).subscribe(items=> {this.subjectAirports.next(plainToClass(Airport, items))});
  }

  searchServiceFunction(search) : Promise<any>{
    let filterPageRequest = new FilterPageRequest(1, 15, '', new SearchSortRequest("iataCode", true), []);

    if(this.arrival && !this.departure){
      filterPageRequest.filterByColumns.push(new FilterSearchColumn('arrival', '1'));
    } else if(!this.arrival && this.departure){
      filterPageRequest.filterByColumns.push(new FilterSearchColumn('departure', '1'));
    }

    filterPageRequest.filterByColumns.push(new FilterSearchColumn('iataCode', search));

    return this.airportService.findByFilterPromise(filterPageRequest, true);
  }

  setDisabledState(isDisabled: boolean): void {
  }

}
