import { isNullOrUndefined } from 'util';
var SearchRange = /** @class */ (function () {
    function SearchRange(from, to, est) {
        this.from = from;
        this.to = to;
        this.est = est;
    }
    Object.defineProperty(SearchRange.prototype, "isEmpty", {
        get: function () {
            return isNullOrUndefined(this.from) && isNullOrUndefined(this.to);
        },
        enumerable: true,
        configurable: true
    });
    return SearchRange;
}());
export { SearchRange };
