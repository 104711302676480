import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../../services/base/base-entity.service';
import {RestService} from '../../../services/rest.service';
import {HttpUtilsService} from '../../../services/http-utils.service';
import {Carrier} from '../models/carrier';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

@Injectable({providedIn: 'root'})
export class CarrierService extends BaseEntityService<Carrier> {

  protected apiUrl = '/api/carriers/';
  protected classType = Carrier;


  getDefaultCarrier(): Observable<Carrier> {
    return this.httpRestService.getWithHeader<Carrier>(this.apiUrl + 'getDefaultCarrier', this.httpUtils.getHTTPHeaders())
      .pipe(map(item => plainToClass(this.classType, item)));
  }


  getOrderCarrier(orderId: number): Observable<Carrier> {
    return this.httpRestService.getWithHeader<Carrier>(this.apiUrl + `getOrderCarrier/${orderId}`, this.httpUtils.getHTTPHeaders())
      .pipe(map(item => plainToClass(this.classType, item)));
  }

  getMasterCarrier(masterId: number): Observable<Carrier> {
    return this.httpRestService.getWithHeader<Carrier>(this.apiUrl + `getMasterCarrier/${masterId}`, this.httpUtils.getHTTPHeaders())
      .pipe(map(item => plainToClass(this.classType, item)));
  }

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }
}
