<perfect-scrollbar style="max-height: 90vh;">
  <div #toolbar class="oms-modal widget">
    <div class="widget-header">
      <h3><i class="fa fa-info"></i></h3>

      <ul class="nav nav-tabs">
        <li (click)="changeTab('ams')" [ngClass]="{'active': selectedMainTab == 'ams'}">
          <a href="#documents" data-toggle="tab"><i class="fa fa-bullhorn" style="margin-right: 15px;"></i>Ams Information</a>
        </li>
        <li (click)="changeTab('log')" [ngClass]="{'active': selectedMainTab == 'log'}">
          <a href="#documents" data-toggle="tab"><i class="fa fa-history" style="margin-right: 15px;"></i>Log History</a>
        </li>
      </ul>

      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>


    <div class="widget-content widget-content_no-padding"
         style="align-content: start; min-height: 300px; overflow-y: auto;height: 60vh">
      <div class="form-row" style="height: 100%;">
        <ng-template [ngIf]="selectedMainTab == 'ams'" [ngIfElse]="logsTemplate">
          <div class="oms-cbp__table-container">
            <!--            <perfect-scrollbar style="max-height: 300vh;" class="oms-cbp__table">-->
            <oms-data-table class="oms-cbp__table"
                            #table
                            [striped]="true"
                            [condensed]="true"
                            [selectable]="false"
                            [searchable]="false"
                            [multi-select]="false"
                            [checkboxes]="true"
                            [fixed]="false"
                            [fixed-columns]="6"
                            [columns]="columns"
                            [defaultColumnForSort]="defaultSortColumn"
                            [data]="cbpStatuses"
                            [specificRowClassHandler]="specificRowClassHandler"
                            [row-class]="getRowClass.bind(this)">
            </oms-data-table>
            <!--            </perfect-scrollbar>-->

            <!--            <perfect-scrollbar style="max-height: 300vh;" class="oms-cbp__table">-->
            <oms-cbp-status-table class="oms-cbp__table"
                                  [objectId]="data.id"
                                  [objectType]="data.objectType"
                                  [uscsFsnStatus]="data.uscsFsnStatus">
            </oms-cbp-status-table>
            <!--            </perfect-scrollbar>-->
          </div>
        </ng-template>
      </div>
    </div>


    <div class="widget-footer">
      <div></div>

      <div class="dialog-btns">
        <button type="button" class="btn btn-default" (click)="generateExcel()">Generate Excel</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div>
  </div>
</perfect-scrollbar>

<ng-template #logsTemplate>
  <table class="table table-hover table condensed">
    <thead>
    <tr>
      <th><a>Old Value</a></th>
      <th><a>New Value</a></th>
      <th><a>Changes Date</a></th>
      <th><a>Changed By</a></th>
      <!--<th>Remove</th>-->
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let log of logChanges">
      <td>{{convertValue(log.oldObjectValue, log)}}</td>
      <td>{{convertValue(log.newObjectValue, log)}}</td>
      <td>{{log.createdAt | oms_date_time}}</td>
      <td>{{log.systemUserName}}</td>
    </tr>
    </tbody>
  </table>
</ng-template>
