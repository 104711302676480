export enum LoadStatusFilterTypeId {
  NO_UPDATE = "no_update",
  COMPLETED = "completed",
  COMPLETED_WITH_PROBLEM = "completed_with_problem",
  NOT_COMPLETED = "not_completed"
}

export class LoadStatusFilterType {
  private static readonly _values = [
    new LoadStatusFilterType(LoadStatusFilterTypeId.NO_UPDATE, "No update"),
    new LoadStatusFilterType(LoadStatusFilterTypeId.COMPLETED, "Completed"),
    new LoadStatusFilterType(LoadStatusFilterTypeId.COMPLETED_WITH_PROBLEM, "Completed with prb"),
    new LoadStatusFilterType(LoadStatusFilterTypeId.NOT_COMPLETED, "Not completed")
  ];

  readonly id: LoadStatusFilterTypeId;
  readonly label: string;

  constructor(id: LoadStatusFilterTypeId, label: string) {
    this.id = id;
    this.label = label;
  }

  public static values() {
    return [...this._values];
  }
}
