var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalResult, OmsDialogsService } from "../../../../../components/common/oms-dialogs";
import { AuthService, OrdersService } from "../../../../../services";
import { DispatchService } from "../../../../shared/services/dispatch/dispatch.service";
import { DateTimeService } from "../../../../../services/date-time.service";
import { DriverService } from "../../../../../services/driver-service";
import { OmsAlertsService } from "../../../../shared/components/oms-alerts/oms-alerts.service";
import { TruckService } from "../../../../../services/truck-service";
import { TrailerService } from "../../../../../services/trailer-service";
import { UserService } from "../../../../shared/services/user.service";
import { ManifestItem } from "../../../../shared/models/manifest/manifest-item";
import { convertLoadNumber, convertManifestNumber } from "../../../../shared/services/oms-converters.service";
import { Order } from "../../../../shared/models";
import { OrdersDispatchDialogComponent } from "../../../../../components/dialogs/orders-dispatch-dialog/orders-dispatch-dialog.component";
import { Manifest } from "../../../../shared/models/manifest/manifest";
import { assigned } from "../../../../../_helpers/utils";
import { ContextMenuComponent } from "ngx-contextmenu";
import { SplitOrderDialogComponent } from "../split-order-dialog/split-order-dialog.component";
import { isNullOrUndefined } from 'util';
import { DispatchUtils } from "../../../../../_helpers/dispatch-utils";
import { Logger } from "../../../../../_helpers/logger";
var ManifestItemContextMenuComponent = /** @class */ (function () {
    function ManifestItemContextMenuComponent(dateTime, cdr, dialogs, dialog, alerts, orderService, truckService, trailerService, driverService, dispatchService, dispatchUtils, userService, authService) {
        this.dateTime = dateTime;
        this.cdr = cdr;
        this.dialogs = dialogs;
        this.dialog = dialog;
        this.alerts = alerts;
        this.orderService = orderService;
        this.truckService = truckService;
        this.trailerService = trailerService;
        this.driverService = driverService;
        this.dispatchService = dispatchService;
        this.dispatchUtils = dispatchUtils;
        this.userService = userService;
        this.authService = authService;
        this.manifestChange = new EventEmitter();
        this.loadingChange = new EventEmitter();
        this.refreshChange = new EventEmitter();
        this.canDriverUpdate = false;
        this.menuHeader = "";
    }
    ManifestItemContextMenuComponent.prototype.ngOnInit = function () {
        this.initCanDriverUpdate();
    };
    ManifestItemContextMenuComponent.prototype.initCanDriverUpdate = function () {
        var _this = this;
        this.userService.getCurrentUser()
            .subscribe(function (user) {
            _this.canDriverUpdate = user.canDriverUpdate;
        });
    };
    ManifestItemContextMenuComponent.prototype.ngOnChanges = function (changes) {
        if (changes.manifestItem || changes.manifest) {
            this.menuHeader = this.manifestItem ? this.getMenuHeader(this.manifestItem) : "";
        }
    };
    ManifestItemContextMenuComponent.prototype.getMenuHeader = function (current) {
        var selected = this.getSelectedManifestItems(current);
        if (selected.length > 1) {
            return 'Multiple Selected';
        }
        else if (selected.length === 1) {
            return convertLoadNumber(selected[0].shipment ? selected[0].shipment.id : null);
        }
        else {
            return 'None Selected';
        }
    };
    ManifestItemContextMenuComponent.prototype.generateBOL = function (isOpenPDF, isPrintBOL) {
        var _this = this;
        var isNew = this.manifest.isNew() || !isNullOrUndefined(this.items.find(function (mi) { return mi.id === undefined; }));
        var items = this.getSelectedManifestItems(this.manifestItem);
        var orderIds = isPrintBOL ? items.flatMap(function (mi) { return mi.orders.map(function (or) { return or.id; }); }) :
            items.filter(function (mi) { return mi.id === undefined; }).flatMap(function (mi) { return mi.orders.map(function (or) { return or.id; }); });
        var manifestIds = items.filter(function (mi) { return mi.id !== undefined; }).map(function (mi) { return mi.id; });
        if (isPrintBOL) {
            this.orderService.printBOLs(orderIds).subscribe(function (response) {
                _this.findBOLOpenPDF(response);
            });
        }
        else {
            if (orderIds.length) {
                this.orderService.generateBOLs(orderIds).subscribe(function (response) {
                    _this.generateBOLOpenPDF(response, isOpenPDF);
                });
            }
            if (manifestIds.length) {
                this.dispatchService.generateBOLs(manifestIds).subscribe(function (response) {
                    _this.generateBOLOpenPDF(response, isOpenPDF);
                });
            }
        }
    };
    ManifestItemContextMenuComponent.prototype.findBOLOpenPDF = function (response) {
        if (!isNullOrUndefined(response)) {
            this.orderService.openBase64PDF(response);
            this.alerts.success('Find BOLs successful', 5000);
        }
        else {
            this.alerts.error('No BOL available');
        }
    };
    ManifestItemContextMenuComponent.prototype.generateBOLOpenPDF = function (response, isOpenPDF) {
        if (!isNullOrUndefined(response)) {
            if (isOpenPDF) {
                this.orderService.openBase64PDF(response);
            }
            this.alerts.success('Generate BOL successful', 5000);
        }
        else {
            this.alerts.error('Error generate BOL');
        }
    };
    ManifestItemContextMenuComponent.prototype.driverActionCancel = function () {
        var _this = this;
        var manifestItem = this.manifestItem;
        console.log(manifestItem);
        if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
            this.alerts.warning("The manifest is not dispatched");
            return;
        }
        var items = this.getSelectedManifestItems(manifestItem);
        if (manifestItem && !items.includes(manifestItem)) {
            items.push(manifestItem);
        }
        items.forEach(function (mi) { mi.orders.forEach(function (o) { return o.selected = !mi.isNew(); }); });
        items = items.filter(function (mi) { return mi.isSelectedFully; });
        if (items.length === 0) {
            this.alerts.warning("All selected items must be dispatched");
            return;
        }
        var ids = items.map(function (mi) { return mi.id; }).filter(function (n) { return assigned(n); });
        this.startLoading();
        this.dispatchService.routeDriverUpdateCancel(ids)
            .then(function (manifest) {
            _this.stopLoading();
            _this.alerts.success("Manifest Updated");
            _this.manifest = _this.prepareManifest(manifest);
            _this.updateManifest();
            _this.refreshChange.emit();
            _this.cdr.markForCheck();
        })
            .catch(function (error) {
            _this.stopLoading();
            _this.alerts.error(error);
        });
    };
    ManifestItemContextMenuComponent.prototype.getSelectedManifestItems = function (current) {
        var selected = this.items ? this.items.filter(function (mi) { return mi.hasSelected; }) : [];
        return selected.length > 0 ? selected : (current ? [current] : []);
    };
    Object.defineProperty(ManifestItemContextMenuComponent.prototype, "selectedOneOrder", {
        get: function () {
            if (this.selectedOrder) {
                return this.selectedOrder;
            }
            if (!this.manifestItem || this.manifestItem.orders.length !== 1) {
                return null;
            }
            return this.manifestItem.orders[0];
        },
        enumerable: true,
        configurable: true
    });
    ManifestItemContextMenuComponent.prototype.consolidate = function () {
        var _this = this;
        this.dispatchUtils.consolidateSelectedItems(this.manifest)
            .then(function (result) { if (result) {
            _this.updateManifest();
        } })
            .catch(function (error) { return _this.alerts.error(error); });
    };
    ManifestItemContextMenuComponent.prototype.splitOrder = function (order) {
        var _this = this;
        //    const originalPcs = order.pieces;
        this.dialogs.openDialog(SplitOrderDialogComponent, {
            order: order,
            onOk: function (newPcs, newHu, newWeight) {
                order.pieces = newPcs;
                order.hu = newHu;
                order.weight = newWeight;
                var mi = _this.manifest.findItem(order.id);
                mi.update();
                _this.updateManifest();
                _this.refreshChange.emit();
                _this.cdr.markForCheck();
            }
        }).then();
    };
    ManifestItemContextMenuComponent.prototype.unconsolidate = function () {
        var _this = this;
        this.dispatchUtils.unconsolidateSelectedItems(this.manifest)
            .then(function (result) { if (result) {
            _this.updateManifest();
        } })
            .catch(function (error) { return _this.alerts.error(error); });
    };
    ManifestItemContextMenuComponent.prototype.redispatchSelected = function (current) {
        var _this = this;
        var orders = [];
        var hasNotCompleted = false;
        var notCompleted;
        this.items.forEach(function (mi) {
            var o = mi.orders.filter(function (o1) { return o1.selected; });
            if (o && o.length) {
                orders = orders.concat(o);
                if (mi.isNotCompleted()) {
                    hasNotCompleted = true;
                    notCompleted = mi;
                }
            }
        });
        if (orders.length === 0 && !!current) {
            orders = current.orders.slice();
            if (current.isNotCompleted()) {
                hasNotCompleted = true;
                notCompleted = current;
            }
        }
        if (this.selectedAll || this.items.length === 1) {
            this.alerts.warning("Unable to redispatch all Manifest Routes!\nPlease update Date or Driver in the Manifest Form", 5000);
        }
        else if (hasNotCompleted) {
            this.alerts.warning("Not Completed Load " + convertLoadNumber(notCompleted.shipment.id) + " cannot be redispatched", 5000);
        }
        else if (orders.length === 0) {
            this.alerts.warning("No items selected!", 5000);
        }
        else {
            var data = {
                noCarrier: true,
                delivery: false,
                orders: orders,
                date: this.dateTime.utcDateOfMidnight(new Date()),
                driver: null,
                trailer: null,
                truck: null,
                manifestOriginal: this.manifest,
            };
            this.dialogs.openDialog(OrdersDispatchDialogComponent, data, {
                handler: function (res) {
                    return new Promise(function (resolve, reject) {
                        if (res.result === ModalResult.OK) {
                            var data1 = res.data;
                            var manifest_1 = data1.manifestToMerge;
                            if (!manifest_1) {
                                // Create new Manifest
                                manifest_1 = new Manifest();
                                manifest_1.driver = data1.driver;
                                manifest_1.dateDispatchedFor = data1.date;
                            }
                            manifest_1.truck = data1.truck;
                            manifest_1.trailer = data1.trailer;
                            _this.startLoading();
                            _this.moveSelectedItemsToManifest(manifest_1)
                                .then(function (r) {
                                _this.stopLoading();
                                _this.cdr.markForCheck();
                                _this.alerts.success("Selected items moved to " + (manifest_1.isNew() ? 'new' : 'existing') + " Manifest " + convertManifestNumber(r.id), 10000);
                                _this.manifest = _this.prepareManifest(r);
                                _this.updateManifest();
                                _this.refreshChange.emit();
                                _this.cdr.markForCheck();
                                resolve(res); // close dialog
                            })
                                .catch(function (error) {
                                _this.stopLoading();
                                _this.alerts.error(error);
                                reject(error);
                            });
                        }
                        else {
                            // Cancel - allow close;
                            resolve(res);
                        }
                    });
                }
            }).catch(function (error) {
                _this.alerts.error(error);
            });
        }
    };
    ManifestItemContextMenuComponent.prototype.selectAll = function () {
        this.items.forEach(function (mi) {
            mi.orders.forEach(function (o) { o.selected = true; });
        });
    };
    ManifestItemContextMenuComponent.prototype.unselect = function () {
        this.items.forEach(function (mi) {
            mi.orders.forEach(function (o) { o.selected = false; });
        });
    };
    ManifestItemContextMenuComponent.prototype.driverAction = function (action) {
        var _this = this;
        var manifestItem = this.manifestItem;
        console.log(manifestItem, action);
        if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
            this.alerts.warning("The manifest is not dispatched");
            return;
        }
        var items = this.getSelectedManifestItems(manifestItem);
        if (manifestItem && !items.includes(manifestItem)) {
            items.push(manifestItem);
        }
        items.forEach(function (mi) {
            mi.orders.forEach(function (o) { return o.selected = !mi.isNew(); });
        });
        items = items.filter(function (mi) { return mi.isSelectedFully; });
        if (items.length === 0) {
            this.alerts.warning("All selected items must be dispatched");
            return;
        }
        var ids = items.map(function (mi) { return mi.id; }).filter(function (n) { return assigned(n); });
        var driverId = this.manifest.driver.id;
        this.startLoading();
        this.dispatchService.routeDriverUpdate(driverId, ids, action /*, minDate(this.manifest.dateDispatchedFor, new Date())*/)
            .then(function (manifest) {
            _this.stopLoading();
            _this.alerts.success("Manifest Updated");
            _this.manifest = _this.prepareManifest(manifest);
            _this.updateManifest();
            _this.refreshChange.emit();
            _this.cdr.markForCheck();
        })
            .catch(function (error) {
            _this.stopLoading();
            _this.alerts.error(error);
        });
    };
    Object.defineProperty(ManifestItemContextMenuComponent.prototype, "selectedAll", {
        get: function () {
            for (var _i = 0, _a = this.items; _i < _a.length; _i++) {
                var mi = _a[_i];
                for (var _b = 0, _c = mi.orders; _b < _c.length; _b++) {
                    var o = _c[_b];
                    if (!o.selected) {
                        return false;
                    }
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    ManifestItemContextMenuComponent.prototype.startLoading = function () {
        this.loadingChange.emit(true);
    };
    ManifestItemContextMenuComponent.prototype.stopLoading = function () {
        this.loadingChange.emit(false);
    };
    ManifestItemContextMenuComponent.prototype.updateManifest = function () {
        this.manifestChange.emit(this.manifest);
        this.cdr.markForCheck();
    };
    ManifestItemContextMenuComponent.prototype.prepareManifest = function (manifest) {
        manifest.items.forEach(function (mi) {
            mi.loadTypeOriginal = mi.loadType;
            mi.orders.forEach(function (o) {
                o.info.legAddressDelivery = mi.addressDelivery;
            });
        });
        return manifest;
    };
    ManifestItemContextMenuComponent.prototype.moveSelectedItemsToManifest = function (target) {
        var _this = this;
        var source = Object.assign(new Manifest(), this.manifest);
        var noSelected = source.items.every(function (item) { return !item.hasSelected; });
        console.log('SOURCE', source);
        var items = source.items.slice();
        items.forEach(function (mi) {
            if (mi.isSelectedFully || (noSelected && mi === _this.manifestItem)) {
                source.items.removeAll(function (i) { return i.id === mi.id; });
                target.items.push(mi);
                mi.id = undefined;
            }
            else if (mi.isSelectedPartially) {
                var item_1 = Object.assign(new ManifestItem(), mi);
                item_1.id = undefined;
                item_1.orders = [];
                target.items.push(item_1);
                mi.orders.slice().forEach(function (o) {
                    if (o.selected) {
                        mi.orders.removeAll(o);
                        item_1.orders.push(o);
                    }
                });
            }
        });
        source.update(true);
        target.update(true);
        //    return this.dispatchService.redispatch(source, target);
        return this.dispatchService.dispatchOrders(source, false, false)
            .toPromise()
            .then(function (manifest) { return _this.dispatchService.dispatchOrders(target, false, false).toPromise(); });
    };
    ManifestItemContextMenuComponent = __decorate([
        Logger({}),
        __metadata("design:paramtypes", [DateTimeService,
            ChangeDetectorRef,
            OmsDialogsService,
            MatDialog,
            OmsAlertsService,
            OrdersService,
            TruckService,
            TrailerService,
            DriverService,
            DispatchService,
            DispatchUtils,
            UserService,
            AuthService])
    ], ManifestItemContextMenuComponent);
    return ManifestItemContextMenuComponent;
}());
export { ManifestItemContextMenuComponent };
