import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DocCenterComponent } from "../doc-center.component";
var DocCenterDialogComponent = /** @class */ (function () {
    function DocCenterDialogComponent(dialogRef, data, dialog) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialog = dialog;
        this.class = 'doc-center';
        this.isVisibleHistory = true;
        this.progress = { percentage: 0 };
        console.log('CONFIG', data.config);
        this.config = data.config;
    }
    DocCenterDialogComponent.prototype.ngOnInit = function () {
    };
    DocCenterDialogComponent.prototype.getTitle = function () {
        return 'Document Center';
    };
    DocCenterDialogComponent.prototype.closeDialog = function () {
    };
    Object.defineProperty(DocCenterDialogComponent.prototype, "showHistory", {
        get: function () {
            return this.config && !this.config.readonly;
        },
        enumerable: true,
        configurable: true
    });
    DocCenterDialogComponent.prototype.onCancel = function (event) {
        if (this.docCenter && this.docCenter.isPreview()) {
            event.canClose = false;
            this.docCenter.changeTab('documents');
        }
        else {
            this.dialogRef.close();
        }
    };
    DocCenterDialogComponent.prototype.getButtonCancel = function () {
        if (this.docCenter && this.docCenter.isPreview()) {
            return { icon: 'fa fa-times', caption: 'Back' };
        }
        else {
            return { icon: 'fa fa-times', caption: 'Close' };
        }
    };
    return DocCenterDialogComponent;
}());
export { DocCenterDialogComponent };
