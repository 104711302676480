import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";

@Injectable({
  providedIn: 'root'
})
export class OmsMapsService {
  private apiUrl = '/oms/oms-maps-integration/';

  constructor(public httpRestService: RestService,
              public httpUtils: HttpUtilsService) {
  }

  public getOmsMapsOptions(): Observable<any> {
    return this.httpRestService
      .getWithHeader<any>(this.apiUrl + 'get-maps-options', this.httpUtils.getHTTPHeaders());
  }
}
