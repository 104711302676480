import {isNullOrUndefined} from "util";
import {isArray} from 'rxjs/internal-compatibility';
import {padZeros} from "../modules/shared/services/oms-converters.service";
import {BehaviorSubject} from "rxjs";

export function assigned(value: any): boolean {
  return !absent(value);
}

export function absent(value: any): boolean {
  return value === undefined || value === null;
//  return isNullOrUndefined(object);
}

export function isEmpty(array: any[]): boolean {
  return !(array && array['length'] > 0);
}

export function getBaseUrl(): string {
  return window.location.origin;
}

export function encryptNumber(i: number, additional?: number): string {
  let n: string = ((++i) * 12345) + '';
  if (additional) {
    n += '#' + padZeros(additional || 0, 6);
  }

  return btoa(n);
}

export function buildCfsStatusPageUrl(ffgId: number, orderId?: number): string {
  let encrypted = encryptNumber(ffgId, orderId);
  let path = `cfs-status`;
  return `${getBaseUrl()}/${path}/${encodeURIComponent(encrypted)}`;
}

export function openFullscreen() {
  let elem = document.documentElement;
  let methodToBeInvoked = elem.requestFullscreen || elem['webkitRequestFullScreen'] ||
    elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];

  if (methodToBeInvoked) {
    methodToBeInvoked.call(elem);
  }
}

/*
export function closeFullscreen() {
  if (this.document.exitFullscreen) {
    this.document.exitFullscreen();
  } else if (this.document.mozCancelFullScreen) {
    this.document.mozCancelFullScreen();
  } else if (this.document.webkitExitFullscreen) {
    this.document.webkitExitFullscreen();
  } else if (this.document.msExitFullscreen) {
    this.document.msExitFullscreen();
  }
}
*/

export function copyToClipboard(text: string) {
  let selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = text;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}

export function printElement(elem) {
//  let printContents = document.getElementById(divName).innerHTML;
//  let originalContents = document.body.innerHTML;
//  document.body.innerHTML = elem.innerHtml;
//  window.print();
//  document.body.innerHTML = originalContents;


  let w = window.open('', '_blank');
  w.document.write(elem.innerHTML);
  w.print();
  w.close();
}

export function downloadFile(uri: string) {
  let link = document.createElement('a');
  link.href = uri;
  link.download = uri.substr(uri.lastIndexOf('/') + 1);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function sendEmail(subject: string, text: string,  to?: string) {
  let link = document.createElement('a');
  link.href = `mailto:${to}?subject=${subject}&body=${text}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function openMailTo(email: string, subject: string, cc: string[] = [], body?: any): void {
  let content = 'mailto:' + email + '?';
  if (cc && cc.length) {
    content += 'cc=' + encodeURIComponent(cc.join(", ")) + '&';
  }
  if (subject) {
    content += 'subject=' + encodeURIComponent(subject) + '&';
  }
  if (body) {
    content += 'body=' + encodeURIComponent(body);
  }
  window.open(content);
}

export function checkNumber(n: any, def: number = null): number | null {
  if (isNullOrUndefined(n)) {
    return def;
  }

  if (isNaN(n)) {
    n = Number(n.toString().replace(/[^\d.-]/g, ''));
  }

  return isNaN(n) ? def : n;
}

export function ensureNumber(n: any): number {
  return isNullOrUndefined(n) || typeof n !== 'number' ? 0 : n;
}

export function toFixed(x, d) {
  return isNullOrUndefined(x) ? x : parseFloat(x.toFixed(d));
//  if (!d) return x.toFixed(d); // don't go wrong if no decimal
//  return x.toFixed(d).replace(/\.?0+$/, '');
}

export function clamp(n: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, n));
}

export function getParent (element, type): any {
  do {element = element.parentElement; } while (element && (element.constructor.name !== type.name));
  return element;
}

export function findAncestor(el, sel: string) {
  while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el, sel))) {}
  return el;
}

export function ifChanged(oldValue: any, newValue: any, onChanged: (value: any) => void) {
  if (!equals(oldValue, newValue)) {
    onChanged(newValue);
  }
}

export class BehaviorArraySubject<T> extends BehaviorSubject<T[]> {
  constructor() {
    super([]);
  }

  public next(value: T[]): void {
    super.next(value ? value : []);
  }

  public get length(): number {
    return this.value.length;
  }

  public clear() {
    this.next([]);
  }

  public add(...items: T[]) {
    let toSelect: T[] = this.value.concat(items).unique();
    this.next([...toSelect]);
  }

  public remove(...items: T[]) {
    console.log('Remove Selection 1');
    let toSelect = this.value.filter((item) => !items.includes(item));
    this.next(toSelect);
  }

  public has(item: T): boolean {
    return this.value.includes(item);
  }
}

declare global {
  interface Date {
    addHours(h: number): Date;
    isToday(): boolean;
  }
}

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

Date.prototype.isToday = function () {
  const today = new Date();
  return this.getDate() === today.getDate() &&
    this.getMonth() === today.getMonth() &&
    this.getFullYear() === today.getFullYear();
};

declare global {
  interface Array<T> {
    clear(): Array<T>;
    hasEquals(o: T | ((item: T) => boolean)): boolean;
    findEquals(o: T): T;
    /**
     *  @deprecated use find()
     */
    findFirst(fn: (item: T) => boolean): T;
    removeAll(o: T | ((item: T) => boolean), onRemove?: (item: T) => void): boolean;
    flatMap<N>(fn: (item: T) => N[]): N[];

    replaceAll(o: T, n: T, onFound?: (newItem: T, oldItem?: T) => void): boolean;
    lastValueOf(field?: (item: T) => any): any | null;
    first(): T | null;
    last(): T | null;
    next(item: T, loop?: boolean): T | null;
    prev(item: T, loop?: boolean): T | null;
    compare(arr: T[], action: (toAdd: T[], toDelete: T[]) => void, fncmp?: (a, b) => boolean): void;

    /***
     * Returns single value if array contains unique objects or internal fields of object or null if multiple values
     * @param {(item: T) => D} field : object field accessor
     * @param ifMultiple: is return value in case if array contains different values
     * @param ifEmpty
     * @returns {D[]}
     */
    asUniqueValue(field?: (item: T) => any, ifMultiple?: any, ifEmpty?: any): any;

    /***
     * Returns array of objects, or its internal fields with duplicates removed
     * @param {(item: T) => D} field : object field accessor
     * @returns {D[]}
     */
    unique<D>(field?: (item: T) => D): D[];

    // unlike reduce can operate with internal object fields
    aggregate<V>(fn: (acc: V, item: T) => any, initial?: any): V;

    /***
     * Arrays can be comparable using equals for every object.
     * two arrays can be compared by external equals() function
     * @param {any[]} array
     * @returns {boolean}
     */

    equals(array: any[]): boolean;

    // note: need to check
    duplicates<T>(field?: (item: T) => any): T[];

  }
}

Array.prototype.compare = function(target, action, fncmp) {
  if (target) {
    const toAdd = [];
    const toDelete = [];

    const cmp = fncmp || equals;
    target.forEach((o) => {
      if (!this.some((i) => cmp(i, o))) {
        toAdd.push(o);
      }
    });

    this.forEach((o) => {
      if (!target.some((i) => cmp(i, o))) {
        toDelete.push(o);
      }
    });

    action(toAdd, toDelete);
  }
};

Array.prototype.first = function () {
  return this.length > 0 ? this[0] : null;
};

Array.prototype.last = function () {
  return this.length > 0 ? this[this.length - 1] : null;
};

Array.prototype.next = function (item, loop) {
  let i = this.indexOf(item);
  return i < 0 || this.length <= i + 1 ? (loop ? this.first() : null) : this[i + 1];
};

Array.prototype.prev = function (item, loop) {
  let i = this.indexOf(item);
  return i < 1 ? (loop ? this.last() : null) : this[i - 1];
};

Array.prototype.replaceAll = function(o, n, fn): boolean {
  let result = false;
  this.forEach((e, i) => {if (equals(e, o)) {result = true; if (fn) { fn(n, this[i]); } this[i] = n; }});
  return result;
};

Array.prototype.aggregate = function(fn, initial) {
  let acc = initial;
  this.forEach((item) => {acc = fn(acc, item); });
  return acc;
};

Array.prototype.lastValueOf = function(fn) {
  const item = this.length ? this[this.length - 1] : null;
  return item && fn ? fn(item) : item;
};

Array.prototype.asUniqueValue = function(f, ifMultiple, ifEmpty) {
  let result = null;
  let multi: boolean = false;
  this.some((item) => {
    let next = f ? f(item) : item;
    if (isNullOrUndefined(result)) {
      result = next;
    } else {
      multi = !equals(result, next);
    }

    return multi;
  });

  return multi ? ifMultiple : result == null && ifEmpty ? ifEmpty : result;
};

Array.prototype.unique = function (f) {
  const result: any[] = [];
  this.forEach((item) => {
    const d = f ? f(item) : item;
    if (d && !result.hasEquals(d)) { result.push(d); }
  });
  return result;
};

Array.prototype.duplicates = function(f) {
  return this.filter(i => this.filter(ii => isNullOrUndefined(f) ? equals(ii, i) : equals(f(ii), f(i))).length > 1);
};

Array.prototype.removeAll = function (o, onRemove) {
  let had: boolean = false;
  this.forEach((item, index) => {
    if ( typeof o === 'function' ? o(item) : equals(o, item)) {
      had = true;
      if (onRemove) { onRemove(item); }
      this.splice(index, 1);
    }
  });
  return had;
};

Array.prototype.flatMap = function(fn): any[] {
  let result: any[] = [];
  this.forEach((item) => {
    let items: any[] = fn(item);
    if (items && items.length) {
      result.push(...items);
    } });
  return result;
};

Array.prototype.hasEquals = function (o) {
  return this.some((item) => typeof o === 'function' ? o(item) : equals(item, o));
};

Array.prototype.clear = function () {
  this.length = 0;
  return this;
};

Array.prototype.findEquals = function (o) {
  let found;
  this.some( (item) => {if (equals(item, o)) { found = item; } return !isNullOrUndefined(found); });
  return found;
};

Array.prototype.findFirst = function (f) {
  let found;
  this.some((item) => {if (f(item)) { found = item; } return !isNullOrUndefined(found); });
  return found;
};

Array.prototype.equals = function(array: any[]): boolean {
  return isNullOrUndefined(array) ? this.length === 0 : !(this.some((item) => !array.hasEquals(item)) || array.some(item => !this.hasEquals(item)));
};

export function cumulativeOffset(element, relative?: HTMLElement) {
  let top = 0, left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent || element.parentElement;
  } while (element && (relative ? relative !== element : true));
  return {
    top: top,
    left: left
  };
}

export function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}

export function isEmptyString(s): boolean {
  return isNullOrUndefined(s) || s.toString().trim().length === 0;
}

export function notEmptyString(s: string): boolean {
  return s && !!s.trim().length;
}


// http://stackoverflow.com/a/10899795/604296
export function addThousandsSeparator(number, sep) {
  let parts = number.toString().split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep) + (parts[1] ? "." + parts[1] : "");
}

export function isComparable(object): boolean {
  return object && typeof object.equals === 'function';
}

export function equals(a, b): boolean {
  return isComparable(a) ? a.equals(b) : a === b;
}

export function contains(object, arr: any[]): boolean {
  return arr.some(item => equals(item, object));
}

export function cmpNumeric(a: number, b: number): number {
  if (isNaN(a)) {
    return isNaN(b) ? 0 : -1;
  }

  if (isNaN(b)) {
    return isNaN(a) ? 0 : 1;
  }

  return a - b;
}


export function indexOf(object, arr: any[]): number {
  let res = -1;
  arr.some((item, index) => {if (equals(item, object)) { res = index; } return res >= 0; });
  return res;
}

export function find(object: any, array: any[]): any {
  let found;
  if (object && array) { array.some(item => {if (equals(item, object)) { found = item; } return !isNullOrUndefined(found); }); }
  return found;
}

export function asArray(o: any): any[] {
  return isNullOrUndefined(o) || isArray(o) ? o : [o];
}

export function separatedValuesString(array: any[], valueFn: (item: any) => string, separator: string = ', '): string {
//  let s ='';
//  if (array) {array.forEach(item => {s += s.length===0 ? valueFn(item) : separator+ valueFn(item);});}
//  return s;

  return array.aggregate((acc, item) => acc + (acc.length === 0 ? valueFn(item) : separator + valueFn(item)), '');
}

export function isMobile() {
  let userAgent = navigator.userAgent;
  return /\b(iPhone|iP[ao]d)/.test(userAgent) ||
    /\b(iP[ao]d)/.test(userAgent) ||
    /Android/i.test(userAgent) ||
    /Mobile/i.test(userAgent);
}

export function popupCenter(url, title, w, h): Window {
  let mobile = isMobile(),
    screenX = window.screenX | window.screenLeft,
    screenY = window.screenY | window.screenTop,
    outerWidth = window.outerWidth | document.documentElement.clientWidth,
    outerHeight = window.outerHeight | (document.documentElement.clientHeight - 22),
    V = screenX < 0 ? window.screen.width + screenX : screenX,
    left = V + (outerWidth - w) / 2,
    top = screenY + (outerHeight - h) / 2.5,
    features = [
      'left=' + left,
      'top=' + top,
      'scrollbars=yes',
      'resizable=yes',
      'directories=no',
      'titlebar=no',
      'toolbar=no',
      'location=no',
      'status=no',
      'menubar=no'
    ];

  if (!mobile) {
    features.push('width=' + w);
    features.push('height=' + h);
  }

  const newWindow = window.open(url, title, features.join(','));

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}


export function nextChar(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}

export function nextLetter(s: string): string {
  return s.replace(/([a-zA-Z])[^a-zA-Z]*$/, a => {
    let c = a.charCodeAt(0);
    switch (c) {
      case 90: return 'A';
      case 122: return 'a';
      default: return String.fromCharCode(++c);
    }
  });
}

export function diff(source: any[], dest: any[], add: (item) => void, remove: (item) => void): boolean {
  if (isNullOrUndefined(dest) || isNullOrUndefined(source)) {
    return false;
  }

  let changed = false;
//  let toAdd: any[] = [];
//  let toDelete: any[] = [];

  if (isNullOrUndefined(source)) {

    if (isNullOrUndefined(dest)) {
      return false;
    } else {
      dest.forEach((item) => {changed = true; add(item); });
    }
  } else {
      if (isNullOrUndefined(dest)) {
        source.forEach((item) => {changed = true; remove(item); });
      } else {
        dest.forEach((item) => {
          if (!source.hasEquals(item)) {
            changed = true;
            add(item);
          }
        });

        source.forEach((item) => {
          if (!dest.hasEquals(item)) {
            changed = true;
            remove(item);
          }
        });


      }
  }

  return changed;
}

// need to check
export function chain(...promises: Promise<any>[]): Promise<any> {
  return new Promise<any>((confirm, reject) => {
    if (promises && promises.length > 0) {
      promises.shift()
        .then(() => {
          chain(...promises)
            .then((r) => confirm(r))
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    } else {
      confirm();
    }
  });
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target Target Object
 * @param sources Source Object Array
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) { return target; }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) { Object.assign(target, { [key]: {} }); }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function generateUid() {
  return (new Date).getTime() + Math.random();
}

export function messageBroadcast(data: any) {
  data.uid = generateUid();
  localStorage.setItem('message', JSON.stringify(data));
  localStorage.removeItem('message');
}

export function messageListen(handler: (data) => void) {
  window.addEventListener('storage', (ev: StorageEvent) => {
    if (ev.key === 'message' && ev.newValue) {
      handler(JSON.parse(ev.newValue));
    }
  });
}
