import {ISearchItemsFunction} from "../../settings/util/search-items.function";

import {SearchRange} from "./search.range";

export type ColumnAlign = 'start' | 'center' | 'end';

export class HistoryColumnSettings {
  logChanges?: boolean = false;
  field?: {actual: string, estimated: string} | string;
  methodForExecution?: string;
  logConverter?: any;
  logChangesWithCbpStatuses?: boolean;
  className?: string;
  useLinkParamsBasedOnColumn?: boolean;
  useId?: string;
}

export class ColumnSettings {
  id: number | string;
  label: string;
  width: string;
  maxWidth: string;
  orderNumber: number = 1;
  visible: boolean = true;
  collapsible: boolean = false;

  field: any; // string | ((obj: any) => string)
  align: ColumnAlign = 'start';

  sortable: boolean = true;
  sortField: any;
  stretch: boolean = false;
  search: IColumnSearchOptions = new DefaultColumnSearchOptions();
  history: HistoryColumnSettings = {logChanges: false};

  public setSortField(sortField) {
    this.sortField = sortField;
  }

  public setVisible(visible: boolean) {
    this.visible = visible;
    return this;
  }

  public setSortable(sortable: boolean) {
    this.sortable = sortable;
    return this;
  }

  public setStretch(stretch: boolean) {
    this.stretch = stretch;
    return this;
  }

  public setLabel(label: string) {
    this.label = label;
    return this;
  }

  constructor(fieldName: number | string, label: string, field, width) {
    this.id = fieldName;
    this.label = label;
    this.field = field;
    this.width = width;
  }

}

type SearchFunctionType = 'isTheSameDay' | 'isSelectedValue' | 'findByIndexOf' | 'findByNameIndexOf'
  | 'numberEquals' | 'itemNumberEquals' | 'findByFirst3OrLast4Chars' | 'findCustomerByNameIndexOf'
  | 'findByProblemStatus' | 'list' | '' | null;

export type ColumnSearchType = 'text' | 'time' | 'list' | 'list-multi' | 'boolean' | null;

export interface IColumnSearchOptions<T = any> {
  searchable: boolean;
  search?: string | [] | SearchRange;
  searchFunction?: SearchFunctionType;
  searchType?: ColumnSearchType;
  items?: ISearchItemsFunction<T> | T[];
  selected?: any[];
  /** @deprecated don't uses */
  baseLineSearch?: boolean;
  multiple?: boolean;
  field?: string;
  displayFilter?: boolean;
  convertInput?: (str: string) => string;
}

export class DefaultColumnSearchOptions<T = any, ID = any> implements IColumnSearchOptions {
  searchable: boolean = true;
  search: string = '';
  searchFunction: SearchFunctionType = 'findByIndexOf';
  searchType: ColumnSearchType = 'text';
  items: ID[] = [];
  selected: ID[] = [];
  baseLineSearch: boolean = false;
  model: T[] = [];
}
