import {BaseEntity} from './base-entity';
import {Exclude} from "class-transformer";
import {Labeled} from "../../../common/oms-types";
import {FreightForwarder} from "./freight.forwarder";

export class FreightForwarderGroup extends BaseEntity implements Labeled {
  name: string;
  freightForwarders: FreightForwarder[] = [];

  @Exclude()
  get label() {
    return this.name;
  }

}
