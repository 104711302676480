import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Order, User} from '../modules/shared/models';
import {ColumnSettings} from '../modules/shared/models';
import {Subject} from 'rxjs';
import {RestService} from "./rest.service";
import {convertMawbNumber, order1CByAmsStatusesClass} from '../modules/shared/services/oms-converters.service';
import {BaseColumn, FieldType, HyperLinkColumn, VolumeColumn, WeightColumn} from '../modules/shared/components/base/data-table/columns/column-types';

@Injectable()
export class AccountService {
  constructor(private restService: RestService) { }
  public columnsSettingsSubject = new Subject<ColumnSettings[]>();

  public loadColumnsSubject = new Subject<ColumnSettings[]>();
  public tableSettingsSubject = new Subject<any[]>();
  public hideShowColumnsSubject = new Subject<any>();

  showHideShowPopUp(columnSettings: ColumnSettings[]) {
    this.hideShowColumnsSubject.next({
      columnSettings: columnSettings,
      show: true
    });
  }

  hideShowHidePopUp() {
    this.hideShowColumnsSubject.next({
      columnSettings: [],
      show: false
    });
  }

  createAccount(user: User) {
    return this.restService.post('/account/register', user).pipe(map(resp => console.log(resp)));
  }

  getLoadColumns() {
    const columns = [];
    columns.push(
      new BaseColumn(1, 'LD#', 'load', FieldType.TEXT, '40px'),
      new BaseColumn(2, 'LD#', 'load', FieldType.TEXT, '40px'),
    );
    this.loadColumnsSubject.next(columns);
  }

  getOrderTableConfigurations() {
    const columnsSettings = [];
    columnsSettings.push(new (class extends HyperLinkColumn {
      getHyperLink(order: Order): any {
        return ['order', {id: order ? order.id : null}]; }
    }) (1, 'JJS #', 'orderNumber', FieldType.TEXT, '100px'));

    columnsSettings.push(new BaseColumn(1, 'Customer', 'customerName', FieldType.TEXT, '200px'));

    columnsSettings.push(new BaseColumn(11, 'Airline', 'master.masterAir.airline.name', FieldType.TEXT, '100px'));
    columnsSettings.push(new BaseColumn(12, 'Cargo Bldg', 'master.shipment.addressDelivery', FieldType.ADDRESS, '100px'));
    columnsSettings.push(new BaseColumn(13, 'Arrival', 'master.shipment.dateDeliveryActual', FieldType.DATETIME, '100px'));
//    columnsSettings.push(new ColumnSettings(14, 'MAWB#', 'master.mawbNumber', 'master.mawbNumber', FieldType.TEXT, '100px'));
    columnsSettings.push(new (class extends HyperLinkColumn {getHyperLink(order: Order): any { return ['../master',
          {id: order && order.master ? order.master.id : null}]; }
          convert(row, value): string {return convertMawbNumber(value); }})
    (14, 'MAWB#', 'master.mawbNumber', FieldType.TEXT, '100px'));

    columnsSettings.push(new BaseColumn(15, 'HAWB#', 'hawb',  FieldType.TEXT, '100px'));
    columnsSettings.push(new BaseColumn(16, 'LFD', 'master.dateLastFreeDay', FieldType.DATE, '80px'));
    columnsSettings.push(new BaseColumn(17, 'ISC', 'master.dateISCPaid', FieldType.BOOLEAN, '40px'));
    columnsSettings.push(new BaseColumn(18, '1C', 'date1C',  FieldType.BOOLEAN, '40px', {class: (o) => order1CByAmsStatusesClass(o)}));
    columnsSettings.push(new BaseColumn(19, '1F', 'date1F', FieldType.BOOLEAN, '40px'));
    columnsSettings.push(new BaseColumn(20, 'HAZ', 'isHazardous',  FieldType.BOOLEAN, '40px'));

//    columnsSettings.push(new ColumnSettings(4, 'JJS Master #', 'masterNumber', 'masterNumber', FieldType.TEXT, '100px'));
    columnsSettings.push(new BaseColumn(2, 'PickUp Address', 'addressPickup',  FieldType.ADDRESS, '250px'));
    columnsSettings.push(new BaseColumn(3, 'Pick UP', 'child.pickupDate',  FieldType.DATE, '120px'));
    columnsSettings.push(new BaseColumn(4, 'Delivery Address', 'addressDelivery', FieldType.ADDRESS, '250px'));
    columnsSettings.push(new BaseColumn(5, 'Est. Delivery', 'dateDeliveryReq',  FieldType.DATE, '120px'));
    columnsSettings.push(new BaseColumn(6, 'Act. Delivery', 'dateDeliveryAct', FieldType.DATE, '120px'));
    columnsSettings.push(new BaseColumn(7, 'PCS(HU)', 'pieces', FieldType.TEXT, '100px'));
    columnsSettings.push(new WeightColumn(8, 'GW(LB)', 'weight', FieldType.TEXT, '100px'));
    columnsSettings.push(new VolumeColumn(9, 'VOL(CFT)', 'volume', FieldType.TEXT, '100px'));
//    columnsSettings.push(new ColumnSettings(10, 'Load', 'loadsNumber', 'child.loadID', FieldType.TEXT, '80px'));
    this.columnsSettingsSubject.next(columnsSettings);
  }

  getTableColumnConfigurations() {
    const columnsSettings = [];
    columnsSettings.push({name: 'JJS #', prop: 'orderNumber'});
    columnsSettings.push({name: 'Order Pieces', prop: 'pieces'});
    columnsSettings.push({name: 'Customer Name', prop: 'customerName'});
    columnsSettings.push({name: 'JJS Master #', prop: 'masterNumber'});
    columnsSettings.push({name: 'PickUp Address', prop: 'masterNumber'});
    columnsSettings.push({name: 'Load', prop: 'loadsNumber'});

    this.tableSettingsSubject.next(columnsSettings);
  }
}
