var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Type } from "class-transformer";
import { isNullOrUndefined } from "util";
var ɵ0 = function () { return Date; };
var Problem = /** @class */ (function () {
    function Problem(problemType, description) {
        this.problemType = problemType;
        this.description = description;
    }
    Object.defineProperty(Problem.prototype, "label", {
        get: function () {
            return this.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Problem.prototype, "isResolved", {
        get: function () {
            return !isNullOrUndefined(this.dateResolved);
        },
        set: function (value) {
            this.dateResolved = value ? new Date() : null;
            //    console.log(this);
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Date)
    ], Problem.prototype, "dateResolved", void 0);
    return Problem;
}());
export { Problem };
export { ɵ0 };
