import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {RestService} from "../../../../services/rest.service";
import {HttpUtilsService} from "../../../../services/http-utils.service";
import {plainToClass} from "class-transformer";
import {BaseEntityService} from "../../../../services/base/base-entity.service";
import {MawbTrack} from "../../models/mawb.track";

@Injectable()
export class MawbTrackService extends BaseEntityService<MawbTrack> {
  apiUrl = '/oms/mawb-track/';
  classType = MawbTrack;

  public asmDataSubject = new Subject<MawbTrack[]>();

  public constructor(public httpRestService: RestService,  public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  findAllByMasterId(id) {
    this.httpRestService.get<MawbTrack[]>(this.apiUrl + 'find-by-id', id).subscribe(masterAsmData => {
      this.asmDataSubject.next(plainToClass(MawbTrack, masterAsmData as Object[]));
    });
  }
}
