var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Airline } from '../../modules/shared/models/air/airline';
import { BaseEntityService } from '../base/base-entity.service';
import { RestService } from '../rest.service';
import { HttpUtilsService } from '../http-utils.service';
import * as i0 from "@angular/core";
import * as i1 from "../rest.service";
import * as i2 from "../http-utils.service";
var AirlineService = /** @class */ (function (_super) {
    __extends(AirlineService, _super);
    function AirlineService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/airline/';
        _this.classType = Airline;
        return _this;
    }
    AirlineService.prototype.getByPrefix = function (awbPrefix) {
        return this.httpRestService.get(this.apiUrl + 'getByPrefix', awbPrefix);
    };
    AirlineService.ngInjectableDef = i0.defineInjectable({ factory: function AirlineService_Factory() { return new AirlineService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: AirlineService, providedIn: "root" });
    return AirlineService;
}(BaseEntityService));
export { AirlineService };
