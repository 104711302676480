import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {OmsTabDirective} from "./oms-tab.directive";
import {Subscription} from "rxjs";

export interface TabConfig {
  tabId: string;
  name: string;
  template?: TemplateRef<any>;
  visible: boolean;
  active: boolean;
  iconClass?: string;
  tabClass?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'oms-tabs',
  templateUrl: './oms-tabs.component.html',
  styleUrls: ['./oms-tabs.component.scss']
})
export class OmsTabsComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input('tabs-style') public style: 'tabs'| 'tabs-left' | 'pills' | 'minimal' | 'minimal-bottom' = 'tabs';
  @Input('external-buttons') externalButtons: boolean = false;
  @Input() public colored: boolean = false;
  @ContentChildren(OmsTabDirective) private items: QueryList<OmsTabDirective>;

  @Input('content-class') contentClass: string;
  @Input() active: string;
  @Output() activeChange: EventEmitter<string> = new EventEmitter<string>();

  public activeTab: TabConfig;
  public tabs: TabConfig[] = [];
  private sub: Subscription[] = [];


  constructor(
    public cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.style) {
      this.cdr.markForCheck();
    }

    if (changes.active) {
      setTimeout(() => this.initTabs());
    }
  }

  ngAfterViewInit(): void {
    this.items.forEach((item) => {this.sub.push(item.visibleChange.subscribe(() => this.initTabs())); });
    setTimeout(() => this.initTabs());
  }

  ngOnDestroy(): void {
    this.sub.forEach((s) => s.unsubscribe());
  }

  private initTabs() {
    this.tabs = this.items ? this.items.filter((tab) => tab.visible) : [];

    this.setActive(this.tabs.find((t) => (this.active ? t.tabId === this.active : t.active)) || this.tabs[0]);
    this.cdr.markForCheck();
  }

  public setActive(tab: TabConfig) {
    if (tab && tab.visible && this.activeTab !== tab) {
      this.activeTab = tab;
      this.active = tab.tabId;
      this.items.forEach(t => t.setActive(t === this.activeTab));
      this.activeChange.emit(tab.tabId);
    }
  }

}
