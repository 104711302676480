var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { AbstractComponent } from "../../../common/component/abstract.component";
import { LoadStatusFilterChoose } from "./load-status-filter-choose";
import { LoadStatusFilterType } from "./load-status-filter-type";
var LoadStatusFilterComponent = /** @class */ (function (_super) {
    __extends(LoadStatusFilterComponent, _super);
    function LoadStatusFilterComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.chooses = _this.createDefaultTypes();
        _this.choose = new EventEmitter();
        _this.rootClass = true;
        return _this;
    }
    LoadStatusFilterComponent.prototype.applyFilterByStatus = function (choose) {
        choose.selected = !choose.selected;
        this.choose.emit(choose);
    };
    LoadStatusFilterComponent.prototype.createDefaultTypes = function () {
        return LoadStatusFilterType.values().map(function (type) { return new LoadStatusFilterChoose(type, false); });
    };
    return LoadStatusFilterComponent;
}(AbstractComponent));
export { LoadStatusFilterComponent };
