import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";
import {BaseEntityService} from './base/base-entity.service';
import {FreightForwarderGroup} from "../modules/shared/models/freight.forwarder.group";

@Injectable()
export class FreightForwarderGroupService extends BaseEntityService<FreightForwarderGroup> {
  apiUrl = '/oms/freight-forwarder-group/';
  classType = FreightForwarderGroup;
  public freightForwarderGroupSubject = new Subject<FreightForwarderGroup[]>();
  public freightForwarderGroupPopUpEvent = new Subject<any>();

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public showFreightForwarderGroupPopUp(item: FreightForwarderGroup) {
    this.freightForwarderGroupPopUpEvent.next({
      show: true,
      freightForwarder: item
    });
  }

  public hideFreightForwarderGroupPopUp() {
    this.freightForwarderGroupPopUpEvent.next({
      show: false,
      freightForwarder: null
    });
  }

  getAllFreightForwarderGroups() {
    this.findAll().then(items=>this.freightForwarderGroupSubject.next(items));
  }

}
