import {NgModel} from '@angular/forms';
import {Observable} from 'rxjs';
import {ValueAccessorBase} from './value-accessor';

import {
  AsyncValidatorArray,
  ValidatorArray,
  ValidationResult,
  message,
  validate,
} from './base-input/validate';
import {map} from "rxjs/operators";

export abstract class ElementBase<T> extends ValueAccessorBase<T> {
  protected abstract model: NgModel;

  protected constructor(
    private validators: ValidatorArray,
    private asyncValidators: AsyncValidatorArray,
  ) {
    super();
  }

  protected validate(): Observable<ValidationResult> {
    return <Observable<ValidationResult>>validate(this.validators, this.asyncValidators)(this.model.control);
  }

  protected get invalid(): Observable<boolean> {
    return this.validate().pipe(map((v => Object.keys(v || {}).length > 0)));
  }

  protected get failures(): Observable<Array<string>> {
    return this.validate().pipe(map((v => Object.keys(v).map(k => message(v, k)))));
  }
}
