var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { SearchRange } from './search.range';
import { isNullOrUndefined } from "util";
import { Exclude, Type } from "class-transformer";
var ɵ0 = function () { return SearchRange; };
var FilterSearchColumn = /** @class */ (function () {
    function FilterSearchColumn(field, value, multipleValues, range) {
        this.fullMatchValue = false;
        this.field = field;
        this.value = value;
        this.multipleValues = multipleValues;
        this.range = range;
    }
    FilterSearchColumn.fromSearchOptions = function (search, field) {
        if (search.searchable && !isNullOrUndefined(search.search) && (search.search + "").length > 0) {
            var fieldName = search.field || field;
            if (search.search instanceof Array) {
                return new FilterSearchColumn(fieldName, null, search.search, null);
            }
            else if (search.search instanceof SearchRange) {
                if (!search.search.isEmpty) {
                    return new FilterSearchColumn(fieldName, null, null, search.search);
                }
            }
            else {
                var searchText = search.search;
                if (search.convertInput) {
                    searchText = search.convertInput(searchText);
                }
                return new FilterSearchColumn(fieldName, searchText, null, null);
            }
        }
        return null;
    };
    FilterSearchColumn.prototype.setFullMatchValue = function (value) {
        this.fullMatchValue = value;
        return this;
    };
    Object.defineProperty(FilterSearchColumn.prototype, "values", {
        get: function () {
            return [this.value].concat(this.multipleValues).filter(function (s) { return !isNullOrUndefined(s); });
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", SearchRange)
    ], FilterSearchColumn.prototype, "range", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], FilterSearchColumn.prototype, "values", null);
    return FilterSearchColumn;
}());
export { FilterSearchColumn };
export { ɵ0 };
