<as-split unit="percent" direction="horizontal" [gutterDblClickDuration]="10" (dragEnd)="dragEnd($event)">
  <as-split-area [size]="widthFirstPanel">

    <oms-base-page [title]="title" [subtitle]="orderMode">


      <ng-container page-header>
        <div class="btn-toolbar">
          <button class="btn btn-primary" *ngIf="!isReadMode" [disabled]="isDisabled" (click)="onSave()">Save</button>
          <button class="btn btn-danger" (click)="location.back();">Back</button>
        </div>
      </ng-container>

      <ng-container page-body>
        <perfect-scrollbar style="max-height: 80vh;" [config]="{suppressScrollX: true}" >
          <!--oms-import-air-order [order]="order" *ngIf="order" [disableMaster]="masterNotEditable">
            </oms-import-air-order-->
          <oms-generic-order [order]="order" *ngIf="order" [type]="type">
          </oms-generic-order>
          <div class="col-lg-12">
            <oms-widget [iconClass]="'fa fa-truck'" label="Loads" [no-padding]="true" [header-left]="true">
              <div widget-header>
                <span style="margin-left: 15px;">
                  <div class="btn btn-xs btn-primary" (click)="onDispatch()">
                    <i class="fa fa-truck"></i>Dispatch&hellip;
                  </div>
                </span>
              </div>
              <!--div class="widget-header-actions" toolbar>
              </div-->
              <oms-data-table #table
                              [striped]="false"
                              [condensed]="true"
                              [hover]="false"
                              [selectable]="true"
                              [multi-select]="true"
                              [checkboxes]="true"
                              [fixed]="false"
                              [fixed-columns]="3"
                              [columns]="loadColumns"
                              [select-by-row]="false"
                              [sort]="{field: 'dispatchId', order:'asc'}"
                              [sortBySelected]="'asc'"
                              [data]="loads"
                              (update)="updateField($event)"
                              [selected]="[]"
                              [keep-selected]="true"
                              [searchable]="false"
                              [virtualization]="false"
                              [row-class]="getRowClass.bind(this)"
                              [use-ps]="false">
              </oms-data-table>
            </oms-widget>
          </div>
        </perfect-scrollbar>
      </ng-container>

      <ng-container page-bottom>
        <div class="btn-toolbar pull-right bottom-navigation-buttons">
          <button class="btn btn-primary" *ngIf="!isReadMode" [disabled]="isDisabled" (click)="onSave()">Save</button>
          <button class="btn btn-danger" (click)="location.back();">Back</button>
        </div>
      </ng-container>

    </oms-base-page>

  </as-split-area>

  <as-split-area [size]="widthSecondPanel">
    <manifest-create-panel [(manifest)]="manifestForRightPanel" [mergeToExisting]="true"
                           (manifestChange)="onManifestChange($event)"
                           (selectedChange)="onDispatchListSelectionChange($event)"
                           (created)="onCreatedEvent($event)"></manifest-create-panel>
  </as-split-area>
</as-split>
