import { Injectable } from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";
import {ShipmentContent} from "../modules/shared/models";

const API_URL = "/oms/shipment-content/";
@Injectable()
export class ShipmentContentService {

  public shipmentContentsSubject = new Subject<ShipmentContent[]>();

  constructor(private httpRestService: RestService, private httpUtils: HttpUtilsService) { }

  getAllShipmentContents() {
    this.httpRestService.get<ShipmentContent[]>(API_URL + 'getAllShipmentContents').subscribe(items => {
      this.shipmentContentsSubject.next(items);
    });
  }

  create(item: ShipmentContent): Observable<ShipmentContent> {
    return this.httpRestService.post<ShipmentContent>(API_URL + 'create', item);
  }

  createWithUpload(shipmentContent: ShipmentContent, files: FileList): Observable<ShipmentContent> {

    let formData: any = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files.item(i), files.item(i)['name']);
    }
    formData.append("shipmentContent", JSON.stringify(shipmentContent));
    return this.httpRestService.requestQuery('POST', API_URL + '/createWithUpload', formData);

  }



  update(item: ShipmentContent): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(API_URL + 'update', item, { headers: httpHeader });
  }

  delete(id: number): Observable<ShipmentContent> {
    return this.httpRestService.delete<ShipmentContent>(API_URL + 'delete', id);
  }
}
