import { OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { OmsConstants } from '../../../common/oms-constants.service';
import { Uld } from '../../../modules/shared/models';
import { isNullOrUndefined } from 'util';
import { range } from 'rxjs';
import { UldNumberValidator } from '../../../common/validators/uld-number.validator';
import { MasterService } from '../../../services/master/master.service';
import { OrdersService } from '../../../services';
import { UldService } from '../../../services/uld.service';
var UldsDialogComponent = /** @class */ (function () {
    function UldsDialogComponent(dialogRef, data, fb, _constants, ordersService, masterService, uldService) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this._constants = _constants;
        this.ordersService = ordersService;
        this.masterService = masterService;
        this.uldService = uldService;
        this.readonly = false;
        this.ulds = [];
        this.dependUlds = [];
        this.isDisplayCheckbox = false;
        this.data = data;
        this.readonly = data.readonly;
        if (data.masterId) {
            this.masterService.getMaster(data.masterId)
                .then(function (master) {
                _this.uldsCount = master.uldCount;
                _this.ulds = master.ulds;
                _this.createForm();
            });
        }
        else if (data.orderId) {
            this.ordersService.getOrder(data.orderId)
                .then(function (order) {
                if (order.master) {
                    _this.isDisplayCheckbox = true;
                    _this.uldsCount = order.master.uldCount;
                    _this.ulds = order.master.ulds;
                    _this.dependUlds = order.ulds;
                }
                else {
                    _this.uldsCount = order.uldCount;
                    _this.ulds = order.ulds;
                }
                _this.createForm();
            });
        }
    }
    UldsDialogComponent.prototype.createForm = function () {
        var uldCount = this.getUldCount();
        this.uldForm = this.fb.group({
            uldsCount: uldCount,
            ulds: this.fb.array(this.getChildItemsFields(this.ulds, uldCount))
        });
    };
    UldsDialogComponent.prototype.getUldCount = function () {
        if (this.isDisplayCheckbox) {
            return this.uldsCount || 0;
        }
        return this.uldsCount || 8;
    };
    UldsDialogComponent.prototype.getChildItemsFields = function (items, uldCount) {
        var _this = this;
        var itemFormGroups = [];
        if (!isNullOrUndefined(items) && items.length > 0) {
            items.forEach(function (item) {
                var fieldSet = {
                    value: new FormControl({
                        value: item.uldNumber,
                        disabled: _this.isDisplayCheckbox
                    }, UldNumberValidator.validateULDNumber),
                    id: item.id,
                    uld: item
                };
                itemFormGroups.push(_this.fb.group(fieldSet));
            });
            if (itemFormGroups.length < uldCount) {
                range(0, uldCount - itemFormGroups.length).subscribe(function (item) {
                    var fieldSet = {
                        value: new FormControl(null, UldNumberValidator.validateULDNumber),
                        id: null
                    };
                    itemFormGroups.push(_this.fb.group(fieldSet));
                });
            }
        }
        else {
            range(0, uldCount).subscribe(function (item) {
                var fieldSet = {
                    value: new FormControl(null, UldNumberValidator.validateULDNumber),
                    id: null
                };
                itemFormGroups.push(_this.fb.group(fieldSet));
            });
        }
        return itemFormGroups;
    };
    UldsDialogComponent.prototype.ngOnInit = function () {
    };
    UldsDialogComponent.prototype.isSelectedUld = function (uld) {
        return this.dependUlds.some(function (dUld) { return dUld.id === uld.id; });
    };
    UldsDialogComponent.prototype.changeSelectedUld = function (uld, value) {
        if (value) {
            this.dependUlds.push(uld);
        }
        else {
            this.dependUlds = this.dependUlds.filter(function (dUld) { return dUld.id !== uld.id; });
        }
    };
    Object.defineProperty(UldsDialogComponent.prototype, "formData", {
        get: function () {
            return this.uldForm.get('ulds');
        },
        enumerable: true,
        configurable: true
    });
    UldsDialogComponent.prototype.getTitle = function () {
        return 'ULDs';
    };
    UldsDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    UldsDialogComponent.prototype.prepare = function () {
        var controls = this.uldForm.controls;
        var uldsValues = controls['ulds'].value;
        var ulds = [];
        uldsValues.forEach(function (uld) {
            if (!isNullOrUndefined(uld.value)) {
                var masterAIrUld = new Uld();
                masterAIrUld.id = uld.id;
                masterAIrUld.uldNumber = uld.value;
                ulds.push(masterAIrUld);
            }
        });
        this.uldsCount = controls['uldsCount'].value;
        this.ulds = ulds;
        return this.ulds;
    };
    UldsDialogComponent.prototype.isCountAutomated = function () {
        return this.isDisplayCheckbox;
    };
    Object.defineProperty(UldsDialogComponent.prototype, "notEmptyULDsLength", {
        get: function () {
            var controls = this.uldForm.controls;
            var uldsValues = controls['ulds'].value;
            var ulds = [];
            uldsValues.forEach(function (uld) {
                var masterAIrUld = new Uld();
                masterAIrUld.id = uld.id;
                masterAIrUld.uldNumber = uld.value;
                ulds.push(masterAIrUld);
            });
            var notEmptyULD = ulds.filter(function (uld) {
                return (!isNullOrUndefined(uld.uldNumber) && uld.uldNumber.trim().length > 0);
            });
            return notEmptyULD.length;
        },
        enumerable: true,
        configurable: true
    });
    UldsDialogComponent.prototype.changeULDNumber = function () {
        var notEmptyUldLength = this.notEmptyULDsLength;
        if (notEmptyUldLength > 0) {
            var controls = this.uldForm.controls;
            this.uldCountNotAutomated = controls['uldsCount'].value;
            controls['uldsCount'].setValue(notEmptyUldLength);
        }
        else {
            var controls = this.uldForm.controls;
            controls['uldsCount'].setValue(this.uldCountNotAutomated);
        }
    };
    UldsDialogComponent.prototype.changeUldCount = function () {
        var _this = this;
        var uldCount = +this.uldForm.get('uldsCount').value;
        var uldFields = this.uldForm.get('ulds');
        var controls = uldFields.controls.slice(0, uldCount);
        if (controls.length < uldCount) {
            range(controls.length, uldCount - 1).subscribe(function (item) {
                var fieldSet = {
                    value: null,
                    id: null
                };
                controls.push(_this.fb.group(fieldSet));
            });
        }
        this.uldForm.setControl('ulds', this.fb.array(controls));
    };
    UldsDialogComponent.prototype.isControlInvalid = function (index) {
        var formArray = this.uldForm.get('ulds');
        var control = formArray.controls[index];
        return control.invalid && control.touched;
    };
    UldsDialogComponent.prototype.getUldErrorMessage = function (index) {
        var formArray = this.uldForm.get('ulds');
        var control = formArray.controls[index];
        return [control.controls.value.errors.uld];
    };
    UldsDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        var controls = this.uldForm.controls;
        /** check form */
        if (this.uldForm.invalid) {
            Object.keys(controls).forEach(function (controlName) {
                return controls[controlName].markAsTouched();
            });
            return;
        }
        var ulds = this.isDisplayCheckbox ? this.dependUlds : this.prepare();
        this.uldService.updateULDs(ulds, this.data.masterId, this.data.orderId).subscribe(function (data) {
            _this.dialogRef.close(ulds);
        });
        /*if (!isNullOrUndefined(this.masterAirId)) {
    
    
          let masterAir = new MasterAir();
          masterAir.id = this.masterAirId;
          masterAir.uldCount = this.uldsCount;
          masterAir.uldAirs = ulds;
    
        } else {
          this.dialogRef.close({
              ULDs: ulds,
              uldCount: this.uldsCount
            }
          );
        }*/
    };
    return UldsDialogComponent;
}());
export { UldsDialogComponent };
