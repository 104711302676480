<oms-base-dialog [title]="label" (ok)="onOk($event)" [loading]="query" [icon]="'fa fa-clone'" [auto-close]="false">
  <div class="form-row">
    <oms-pieces-input [(ngModel)]="pcs"  (ngModelChange)="onPcsChange($event)" [label]="'Original PCS:'+originalPcs" [maxCount]="originalPcs" [minCount]="1" [showButton]="false" required>
      <span group-before style="display: block; min-width: 40px;">
        PCS<!--{{order.pieces}}-->
      </span>
    </oms-pieces-input>
  </div>
  <div class="form-row">
    <oms-pieces-input [(ngModel)]="hu" (ngModelChange)="onHuChange($event)" [label]="'Original HU:' + originalHu" [maxCount]="pcs" [minCount]="1" required [showButton]="false" >
      <span group-before style="display: block; min-width: 40px;">
        HU<!--{{order.hu}}-->
      </span>
    </oms-pieces-input>
  </div>

  <div class="form-row" *ngIf="showWeight">
    <oms-weight-input [(ngModel)]="weight" [label]="'Original Weight: ' + ( originalWeight | oms_weight)" [maxCount]="originalWeight" [minCount]="0" >
      <span group-before style="display: block; min-width: 40px;">
        Weight<!--{{order.weight}}-->
      </span>
    </oms-weight-input>
  </div>


</oms-base-dialog>
