import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { isNullOrUndefined } from 'util';
import {
  ActionsColumn,
  ArrivalDateColumn,
  BaseColumn,
  CheckboxColumn,
  CustomColumn,
  DateCheckboxColumn,
  DateColumn,
  DateTimeColumn,
  DialogLinkColumn,
  FieldType,
  HyperLinkColumn,
  LoadTypeColumn,
  TextColumn,
  TreeColumn,
  VolumeColumn,
  WeightColumn
} from '../modules/shared/components/base/data-table/columns/column-types';
import {
  convertAddress,
  convertBracketed,
  convertLoadNumber,
  convertMasterCargoBuilding,
  convertMawbStatus,
  convertOrderNumber,
  fsrStatusTooltip,
  getMasterLink,
  getOrderLink,
  masterDateCFSFreeDayClass,
  masterDateLastFreeDayClass,
  orderDateCFSFreeDayClass,
} from '../modules/shared/services/oms-converters.service';
import { OmsConstants } from './oms-constants.service';
import {
  AddressType,
  Customer,
  isMaster,
  LoadType,
  Master,
  MasterStatus, MasterStatusId,
  Order,
  Part,
  RecoveryOrder,
  Shipment,
  Split
} from '../modules/shared/models';
import { checkNumber } from '../_helpers/utils';
import { AddressService, AuthService, OrdersService } from '../services';
import { CustomerService } from '../services/customer.service';
import { AirlineService } from '../services/air/airline.service';
import { PARENT_REF } from '../modules/shared/components/base/data-table/data-table.utils';
import { FreightForwarderService } from '../services/freight.forwarder.service';
import { ColumnType, getDocumentLink, getDocumentNumber } from './column-type';
import { ColumnIds } from './column-ids';
import { ColumnWidth } from './column-width';
import { CarrierService } from '../modules/shared/services/carrier.service';
import { Carrier } from '../modules/shared/models/carrier';
import { MasterService } from '../services/master/master.service';
import { MawbTableCellComponent } from "../modules/shared/components/base/data-table/data-table-cell/mawb-table-cell/mawb-table-cell.component";
import {
  checkboxTypeByProblem,
  fillIndicatorClass,
  iconFillStatus,
  receivedValueClass,
  statusIndicatorClass
} from "./oms-columns.utils";
import { TabInfo, TabsType } from '../pages/masters/masters-list/tabs-type';
import { OmsDialogsService } from "../components/common/oms-dialogs";
import { MatDialog } from '@angular/material';
import { CarrierDialogComponent } from "../modules/settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import { map } from "rxjs/operators";
import { DispatchAndRecoveryLog } from "../modules/shared/models/dispatch.and.recovery.log";
import {EMPTY_LINK, HyperLink} from "./oms-types";

export * from './column-width';
export * from './column-ids';

let getLoadSpitNumber = function (value) {
  return 'RLD-' + value;
};

export let getCbpLink = function (row): HyperLink {
  if (row.master) {
    return {path: ['/home', 'masters', 'master'], params: {id: row.master.id}};
  }
  return null;
};

export let displayLoadActionType = function (item: DispatchAndRecoveryLog) {
  if (item.action) {
    return item.action.name;
  }
/*  switch (item.action) {
    case 0:
      return "Pending";
    case 1:
      return "Updated";
    case 2:
      return "Cancelled";
    case 3:
      return "Dispatched";
    case 4:
      return "Confirmed";
    case 5:
      return "Completed";
    case 6:
      return "Completed with problem";
    case 7:
      return "Not completed";
  }*/
  return "N/A";
};


@Injectable()
export class OmsColumns {
  public masterOrdersColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();
  public masterListColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();
  public masterLoadsColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();

  public orderPartsColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();
  public recoveryLoadColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();
  public asmDataColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();
  public masterFsrColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();
  public uldLinkedColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();
  public dispatchAndRecoveryLogsColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();


  private masterDefaultColumns: BaseColumn[];
  private masterOrdersDefaultColumns: BaseColumn[];
  private masterLoadsDefaultColumns: BaseColumn[];
  private orderPartsDefaultColumns: BaseColumn[];
  private recoveryLoadDefaultColumns: BaseColumn[];
  private asmDataDefaultColumns: BaseColumn[];
  private masterFsrDefaultColumns: BaseColumn[];
  private uldLinkedDefaultColumns: BaseColumn[];
  private dispatchAndRecoveryLogsDefaultColumns: BaseColumn[];

  public shipmentFromMasterList$ = new BehaviorSubject(null);
  public shipmentFromMasterEdit$ = new BehaviorSubject(null);


  constructor(
    public constants: OmsConstants,
    public addressService: AddressService,
    public airlineService: AirlineService,
    public customerService: CustomerService,
    public freightForwarderService: FreightForwarderService,
    public carrierService: CarrierService,
    public masterService: MasterService,
    public ordersService: OrdersService,
    public dialogs: OmsDialogsService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.initialize();
  }


  private initialize() {

    this.masterDefaultColumns = this.createMasterDefaultColumns();


    this.masterOrdersDefaultColumns = [
      new HyperLinkColumn(ColumnIds.ORDER_ID, 'Order #', 'id', FieldType.TEXT, '100px',
        {converter: (row, value) => convertOrderNumber(value)}, null, null, order => getOrderLink(order))
        .setNewWindow(true)
        .setSearch(false),
      new TextColumn(ColumnIds.ORDER_HAWB, 'HAWB #', 'hawb', FieldType.TEXT, '150px')
        .setEditor({editable: true, cleanable: false}),

      ColumnType.ORDER_PO.clone()
        .setEditor(true),

      new TextColumn(ColumnIds.ORDER_HU, 'HHU', 'hu', FieldType.TEXT, '100px', null, null, 'end')
        .setEditor({editable: true, cleanable: false})
        .setSearch(false),

      new TextColumn(ColumnIds.ORDER_PCS, 'H-AMS PCS', 'pieces', FieldType.TEXT, '110px', {
        editor: {
          editable: true,
          cleanable: false,
          converter: value => checkNumber(value)
        }
      }, {searchable: false}, 'end'),

      new WeightColumn(ColumnIds.ORDER_WEIGHT, 'HAWB LBS', 'weight', FieldType.TEXT, '110px', {
        editor: {
          editable: true,
          cleanable: false,
          converter: (value) => {
            return value;
          }
        }
      }, {searchable: false}),

      new TextColumn(ColumnIds.ORDER_DELIVERY_LOCATION, 'Delivery Location', 'addressDelivery', FieldType.ADDRESS, ColumnWidth.ADDRESS, {tooltip: o => convertAddress(o.addressDelivery)})
        .setStretch(false)
        .setSearch({searchable: true, search: '', searchFunction: "findByNameIndexOf", baseLineSearch: true})
        .setEditor({
          editable: o => o.master && !o.master.direct,
          select: {addressTypes: [AddressType.DELIVERY_LOCATION], search: this.addressService}
        }),

      new TextColumn(ColumnIds.ORDER_PCS_RCVD, 'H-AMS RCVD', 'pcsReceived', FieldType.TEXT, '110px', {
        class: fillIndicatorClass,
        editor: {editable: false, cleanable: false, converter: value => checkNumber(value)}
      }, {searchable: false}, 'end'),

      new VolumeColumn(ColumnIds.ORDER_VOLUME, 'HAWB CFT', 'volume', FieldType.TEXT, '110px', {
        editor: {
          editable: true,
          cleanable: false
        }
      }, {searchable: false}),

      new TextColumn(ColumnIds.ORDER_FILL_STATUS, 'RCVD', 'fillStatus', FieldType.TEXT, '50px', {
        converter: () => null,
        tooltip: (row) => row.fillStatusDescription,
        icon: iconFillStatus,
        class: fillIndicatorClass
      }, null, 'center').setEditor(false),

      new TextColumn(ColumnIds.ORDER_CUSTOMER, 'Customer', 'customer', FieldType.TEXT, '150px', {converter: (row, customer: Customer) => customer ? customer.name : null}, null)
        .setEditor({
          editable: true,
          cleanable: true,
          select: {search: this.customerService, searchFn: () => true, bindLabel: name}
        }),
      new TextColumn(ColumnIds.ORDER_CUSTOMER_REF, 'Customer Ref', 'customerRef', FieldType.TEXT, '150px', {
        editor: {
          editable: true,
          cleanable: false
        }
      }, {searchable: false}),

      new DateTimeColumn(ColumnIds.ORDER_DELIVERY_DATE, 'Delivered', {
        actual: 'dateDeliveryAct',
        estimated: 'dateDeliveryReq'
      }, ColumnWidth.DATE_TIME, {searchable: false}, {editor: {editable: true}}),
      ColumnType.ORDER_1C_BATTERY,
      new CheckboxColumn(ColumnIds.ORDER_DELIVERY_APPROVAL, 'D/O', 'deliveryApproval', FieldType.BOOLEAN, '60px', {})
        .setSearch({
        searchable: true,
        search: '',
        searchFunction: "numberEquals"
      }).setEditor(true),
//        new DateCheckboxColumn(ColumnIds.ORDER_1F, '1F', 'date1F', '60px', {editor:{editable:true}}).setSearch({searchable: true, search: '', searchFunction: "numberEquals"}),
      new DateCheckboxColumn(ColumnIds.ORDER_BILLED, 'Billed', 'dateBilled', '60px', {editor: {editable: true}}).setSearch({
        searchable: true,
        search: '',
        searchFunction: "numberEquals"
      }),
//        new TextColumn(ColumnIds.MASTER_CUSTOMER, '', null, FieldType.TEXT, ColumnWidth.AUTO_SIZE, {header: {noarrows: true}, converter: ()=>null}, null).setEditor(false).setSearch(false)
    ];

    this.masterLoadsDefaultColumns = [
      new LoadTypeColumn(1, 'Type', 'loadType', FieldType.TEXT, '50px').setHandlers({sorted: {asc: true}}),
      new HyperLinkColumn(2, 'Load #', 'id', FieldType.TEXT, null, {converter: (row, value) => convertLoadNumber(value)}, {searchable: false}, 'start', load => {
        return ['load', {id: load.id}];
      }),
      new DateTimeColumn(3, 'Pick Up Date', {
        actual: 'shipment.datePickUpActual',
        estimated: 'shipment.datePickUpExpectation'
      }, ColumnWidth.DATE_TIME, {
        searchable: true,
        search: '',
        searchFunction: "isTheSameDay",
        searchType: "time"
      }, {editor: {editable: true}}),
      new DateTimeColumn(4, 'Delivery Date', {
        actual: 'shipment.dateDeliveryActual',
        estimated: 'shipment.dateDeliveryExpectation'
      }, ColumnWidth.DATE_TIME, {
        searchable: true,
        search: '',
        searchFunction: "isTheSameDay",
        searchType: "time"
      }, {editor: {editable: true}}),
    ];

    this.orderPartsDefaultColumns = [
      new HyperLinkColumn(ColumnIds.ORDER_ID, 'Order #', 'order.id', FieldType.TEXT, '65px', {
        sorted: {asc: true},
        getter: getDocumentNumber,
        link: getDocumentLink
      }, null, 'end')
        .setSearch({searchable: true, searchFunction: "findByIndexOf", baseLineSearch: true}),
      new TextColumn(ColumnIds.LOAD_SPLIT_NUMBER, 'HAWB #', 'order.hawb', FieldType.TEXT, '150px').setEditor({
        editable: true,
        cleanable: false
      }),
//      new TextColumn(ColumnIds.ORDER_PART, 'Part', 'part', FieldType.TEXT, '50px').setEditor(false),
      new HyperLinkColumn(ColumnIds.MASTER_ID, 'Load', 'splitNumber', FieldType.TEXT, '65px',
        {converter: (row, value) => getLoadSpitNumber(value), link: () => null}, null, 'end')
        .setSearch({searchable: true, searchFunction: "findByIndexOf", baseLineSearch: true}),

//      new TextColumn(0, '', null, FieldType.TEXT, ColumnWidth.AUTO_SIZE, {header: {noarrows: true}, converter: ()=>null}, null).setEditor(false).setSearch(false),

//      new TextColumn(ColumnIds.ORDER_HU, 'HHU', 'huOrder', FieldType.TEXT, '100px', null,  null, 'end').setEditor({editable: true, cleanable:false}).setSearch(false),
      new TextColumn(ColumnIds.ORDER_PCS, 'H-AMS PCS', 'pcsOrder', FieldType.TEXT, '100px', {
        editor: {
          editable: true,
          cleanable: false,
          converter: value => checkNumber(value)
        }
      }, {searchable: false}, 'end'),
      new TextColumn(ColumnIds.ORDER_PCS, 'PCS RCVD', 'pieces', FieldType.TEXT, '100px', {
        editor: {
          editable: true,
          cleanable: false,
          converter: value => checkNumber(value)
        }
      }, {searchable: false}, 'end').setSortable(false),
//      new WeightColumn(ColumnIds.ORDER_WEIGHT, 'HAWB LBS', 'weight', FieldType.TEXT, '100px',  {editor:{editable: true, cleanable: false, converter:(value)=>{return value}}},  {searchable: false}),
//      new VolumeColumn(ColumnIds.ORDER_VOLUME, 'HAWB CFT', 'volume', FieldType.TEXT, '100px', {editor:{editable:true, cleanable:false}},  {searchable: false}),

      new TextColumn(0, '', null, FieldType.TEXT, ColumnWidth.AUTO_SIZE, {
        header: {noarrows: true},
        converter: () => null
      }, null).setEditor(false).setSearch(false),

//      new DateTimeColumn(ColumnIds.ORDER_ARRIVAL_DATE, 'Arrived', {actual:'dateArrivalAct', estimated:'dateArrivalEst'}, ColumnWidth.DATE_TIME, {searchable: false}, {editor:{editable:true}}),

    ];

    this.recoveryLoadDefaultColumns = [
      new HyperLinkColumn(ColumnIds.MASTER_ID, 'Load', 'id', FieldType.TEXT, '65px', {
        sorted: {asc: true},
        getter: getDocumentNumber,
        link: getDocumentLink
      }, null, 'end')
        .setSearch({searchable: true, searchFunction: "findByIndexOf", baseLineSearch: true}),

      new HyperLinkColumn(ColumnIds.LOAD_DRIVER, 'Driver', 'dispatchedOn', FieldType.TEXT, '120px', {}, null)
        .setEditor({editable: false})
        .setSortField('driver.fullName')
        .setHandlers({onClick: row => this.openManifestFromMasterEdit(row)}),

      new TextColumn(ColumnIds.MASTER_HU, 'HU', 'hu', FieldType.TEXT, '80px', null, null, 'end')
        .setSearch(false)
        .setEditor({
          editable: split => !split.pcsReceived,
          cleanable: false,
          clearMask: true,
          mask: {mask: this.constants.piecesMask},
          placeholder: this.constants.piecesPlaceholder
        })
        .setHistory({logChanges: true, field: 'hu'}),

      new TextColumn(ColumnIds.MASTER_PCS, 'PCS', 'pcsToRecover', FieldType.TEXT, '80px', {}, null, 'end')
        .setSearch(false)
        .setEditor({
          editable: split => !split.pcsReceived,
          cleanable: false,
          clearMask: true,
          mask: {mask: this.constants.piecesMask},
          placeholder: this.constants.piecesPlaceholder
        })
        .setHistory({logChanges: true, field: 'pcsToRecover'}),

      new TextColumn(ColumnIds.LOAD_PCS_RCVD, 'PCS RCVD', 'pcsReceived', FieldType.TEXT, '80px', null, null, 'end')
        .setSearch(false).setEditor(false).setSortable(false)
        .setHistory({logChanges: true, field: 'pcsReceived'}),

      new WeightColumn(ColumnIds.MASTER_WEIGHT, 'LBS', 'weight', FieldType.TEXT, '70px')
        .setSearch(false).setEditor(false)
        .setHistory({logChanges: true, field: 'weight'}),

      /*      new VolumeColumn(ColumnIds.MASTER_VOLUME, 'CFT', 'volume',FieldType.TEXT, '70px')
              .setSearch(false).setEditor(false)
              .setHistory({logChanges: true, field: 'volume'}),*/

      new TextColumn(0, '', null, FieldType.TEXT, ColumnWidth.AUTO_SIZE, {
        header: {noarrows: true},
        converter: () => null
      }, null).setEditor(false).setSearch(false),

      ColumnType.SPLIT_DISPATCH_COLUMN,

      new TextColumn(0, '', null, FieldType.TEXT, ColumnWidth.AUTO_SIZE, {
        header: {noarrows: true},
        converter: () => null
      }, null).setEditor(false).setSearch(false),

      new DateTimeColumn(3, 'Pick Up Date', {
          actual: 'datePickupAct',
          estimated: 'datePickupEst'
        }, ColumnWidth.DATE_TIME, {searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"},
        {editor: {editable: (split) => !split.isVirtual && !split.pcsReceived}}),
      new DateTimeColumn(4, 'Receive Date', 'dateDeliveryAct', ColumnWidth.DATE_TIME, {
        searchable: true,
        search: '',
        searchFunction: "isTheSameDay",
        searchType: "time"
      }, {editor: {editable: (row) => !row.isVirtual}}),

    ];

    this.asmDataDefaultColumns = [
      new HyperLinkColumn('mawbNumber', 'Mawb', 'mawbNumber', FieldType.TEXT, '100px', {
        link: getCbpLink,
        sorted: {asc: true}
      }),
      new HyperLinkColumn('part', 'Part', 'part', FieldType.TEXT, '70px', {
        link: getCbpLink,
        converter: (row, value) => {
          return isNullOrUndefined(value) ? '-' : value;
        }
      }),
      new BaseColumn('totalPieces', 'Pieces', 'totalPieces', FieldType.TEXT, '70px'),
      new BaseColumn('totalWeight', 'Weight', 'totalWeight', FieldType.TEXT, '70px'),
      new BaseColumn('destinationAirport.iataCode', 'Destination Airport', 'destinationAirport.iataCode', FieldType.TEXT, '150px'),
      new BaseColumn('originAirport.iataCode', 'Origin Airport', 'originAirport.iataCode', FieldType.TEXT, '150px'),
      new BaseColumn('carrierCode', 'Carrier', 'carrierCode', FieldType.TEXT, '80px'),
      new BaseColumn('flightNo', 'Flight No', 'flightNo', FieldType.TEXT, '80px'),
      new BaseColumn('etaDate', 'Eta Date', 'etaDate', FieldType.DATETIME, '100px'),
      new BaseColumn('actualDate', 'Actual Date', 'actualDate', FieldType.DATETIME, '100px'),
      new BaseColumn('dateCreated', 'Created Date', 'dateCreated', FieldType.DATETIME, ColumnWidth.DATE_TIME),
    ];

    this.masterFsrDefaultColumns = [
      new HyperLinkColumn('mawbNumber', 'Mawb', 'mawbNumber', FieldType.TEXT, '100px', {
        link: getCbpLink,
        sorted: {asc: true}
      }),
      new BaseColumn('originAirport.iataCode', 'Ori Airport', 'originAirport.iataCode', FieldType.TEXT, '80px'),
      new BaseColumn('destinationAirport.iataCode', 'Dest Airport', 'destinationAirport.iataCode', FieldType.TEXT, '80px'),
      new BaseColumn('flightNo', 'Flight No', 'flightNo', FieldType.TEXT, '80px'),
      new BaseColumn('eventLoc', 'Event LOC', 'eventLoc', FieldType.TEXT, '80px'),
      new HyperLinkColumn('status', 'FSR Status', 'status', FieldType.TEXT, '80px', {tooltip: value => fsrStatusTooltip(value)}),
      new BaseColumn('statusDate', 'FSR Status Date', 'statusDate', FieldType.DATETIME, '100px'),
      new BaseColumn('arrivalDate', 'Arrival Date', 'arrivalDate', FieldType.DATETIME, '100px'),
      new BaseColumn('arrivalDateEst', 'Arrival Date Est', 'arrivalDateEst', FieldType.DATETIME, '100px'),
      new BaseColumn('departureDate', 'Departure Date', 'departureDate', FieldType.DATETIME, '100px'),
      new BaseColumn('departureDateEst', 'Departure Date Est', 'departureDateEst', FieldType.DATETIME, '120px'),
      new BaseColumn('messageDateTime', 'Message Date Time', 'messageDateTime', FieldType.DATETIME, '120px'),

      new BaseColumn('eventLoc', 'Event Loc', 'eventLoc', FieldType.TEXT, '80px'),
      new BaseColumn('eventFrom', 'Event From', 'eventFrom', FieldType.TEXT, '80px'),
      new BaseColumn('eventTo', 'Event To', 'eventTo', FieldType.TEXT, '80px'),
      new BaseColumn('eventAt', 'Event At', 'eventAt', FieldType.TEXT, '80px'),
      new BaseColumn('airline', 'Airline', 'airline', FieldType.TEXT, '80px'),
      new BaseColumn('docsDeliveredAtDestination', 'DDAD', 'docsDeliveredAtDestination', FieldType.DATETIME, '120px'),
      new BaseColumn('deliveryTime', 'Delivery Time', 'deliveryTime', FieldType.DATETIME, '120px'),
      new BaseColumn('eventPieces', 'Event Pieces', 'eventPieces', FieldType.TEXT, '80px'),
      new BaseColumn('totalPieces', 'Total Pieces', 'totalPieces', FieldType.TEXT, '80px'),
      new BaseColumn('eventWeight', 'Event Weight', 'eventWeight', FieldType.TEXT, '80px'),
      new BaseColumn('totalWeight', 'Total Weight', 'totalWeight', FieldType.TEXT, '80px'),
      new BaseColumn('partial', 'Partial', 'partial', FieldType.BOOLEAN, '80px'),
      new BaseColumn('subCode', 'Sub Code', 'subCode', FieldType.TEXT, '80px'),

      ColumnType.DATE_CREATED,
      new BaseColumn('inboundFileName', 'Inbound File Name', 'inboundFileName', FieldType.TEXT, '280px')
    ];

    this.uldLinkedDefaultColumns = [
      new BaseColumn('uld.uldNumber', 'ULD #', 'uld.uldNumber', FieldType.TEXT, '100px', {
        sorted: {asc: true}
      }),
      new HyperLinkColumn('mawbNumber', 'MAWB', 'mawbNumber', FieldType.TEXT, ColumnWidth.MAWB, {link: (uld) => getMasterLink(uld.masterId)}),
      new HyperLinkColumn('hawb', 'HAWB', 'hawb', FieldType.TEXT, '80px', {link: (uld) => getOrderLink(uld.orderId)}),
      new BaseColumn('freightForwarderName', 'FF', 'freightForwarderName', FieldType.TEXT, '120px'),
      new BaseColumn('customerName', 'Customer', 'customerName', FieldType.TEXT, '120px'),

      new BaseColumn('jjsNumber', 'JJS#', 'jjsNumber', FieldType.TEXT, '100px'),
      new BaseColumn('addressFrom', 'FROM', 'addressFrom', FieldType.TEXT, '140px'),
      new BaseColumn('addressTo', 'TO', 'addressTo', FieldType.TEXT, '140px'),
      new BaseColumn('pickupDate', 'Pickup Date', 'pickupDate', FieldType.DATETIME, '120px').setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'}),
      new BaseColumn('deliveryDate', 'Delivery Date', 'deliveryDate', FieldType.DATETIME, '120px').setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'}),
      new DialogLinkColumn(ColumnIds.MASTER_DOCS, '', 'documents', FieldType.TEXT, '23px', {
        header: {
          noarrows: true,
          icon: 'fa fa-paperclip'
        }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
          return value ? '' : 'fa fa-paperclip';
        }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
      }).setSearch(false).setEditor(false)
    ];

    this.dispatchAndRecoveryLogsDefaultColumns = [
      new BaseColumn('dateCreated', 'Date/Time', "dateCreated", FieldType.DATETIME, '100px'),
      new BaseColumn('createdBy', 'User Name', "createdBy", FieldType.TEXT, '100px'),
      new HyperLinkColumn('mawbNumber', 'Mawb', "mawbNumber", FieldType.TEXT, '100px', {
        link: getCbpLink,
        sorted: {asc: true}
      }),
      new HyperLinkColumn('hawb', 'Hawb', "hawb", FieldType.TEXT, '100px', {link: getCbpLink, sorted: {asc: true}}),
      new BaseColumn('hu', 'HU', 'hu', FieldType.TEXT, '100px'),
      new BaseColumn('pieces', 'Pieces', 'pieces', FieldType.TEXT, '100px'),
      new BaseColumn('datePickUpActual', 'Pick Up', 'datePickUpActual', FieldType.DATETIME, '100px'),
      new BaseColumn('loadType', 'loadType', 'loadType', FieldType.TEXT, '100px', {
        converter: (row, value) => {
          return LoadType.DELIVERY === value ? "Received" : "Dispatched";
        }
      }),
      new BaseColumn('shipmentId', 'shipmentId', 'shipmentId', FieldType.TEXT, '100px'),

      new BaseColumn('driver.fullName', 'Driver', 'driver.fullName', FieldType.TEXT, '100px'),
      new BaseColumn('truck.number', 'Truck', 'truck.number', FieldType.TEXT, '100px'),
      new BaseColumn('trailer.number', 'Trailer', 'trailer.number', FieldType.TEXT, '100px'),

      new BaseColumn('action', 'Action', item => displayLoadActionType(item), FieldType.TEXT, '70px'),
    ];

  }

  public createAirExportOrderDefaultColumns(config: OrderColumnsConfig): BaseColumn[] {
    let columns = [];
    columns = columns.concat([

      ColumnType.ORDER_JJSM(true),
      ColumnType.ORDER_DOC_CENTER(false, this.ordersService, this.dialogs),

      ColumnType.ORDER_OSD,
      ColumnType.ORDER_HAZ,
      ColumnType.ORDER_COM,
    ]);

    if (config.isActiveTab && !config.isFCL) {
      columns = columns.concat([ColumnType.ORDER_CUSTOM_REF3(config.isFCL)
      ]);
    } else {
      columns = columns.concat([ColumnType.ORDER_REF3(config.isFCL)]);
    }

//    let pcsLabel = config.isFCL ? 'CNT' : 'PCS';
    columns = columns.concat([
      ColumnType.ORDER_HAWB(config.isFCL),
      ColumnType.ORDER_CUSTOMER_REF,
      ColumnType.ORDER_PO,
      ColumnType.ORDER_CUSTOMER(this.customerService)
    ]);


    if (config.isFCL) {
      columns = columns.concat([
        ColumnType.ORDER_CHASSIS
      ]);
    } else {
      columns = columns.concat([
        new DialogLinkColumn(ColumnIds.MASTER_AIR_ULD_COUNT, "ULD", 'uldCount', FieldType.TEXT, '50px', {
          header: {noarrows: true},
          converter: (row, value) => isNullOrUndefined(value) || value === 0 ? 'N/A' : value,
          link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
        })
          .setHistory(true)
      ]);
    }

    let weightParams = config.isFCL ? {digits: 0} : {};

    if (!config.isFCL) {
      columns = columns.concat([
        new TextColumn(ColumnIds.ORDER_HU, 'HU', 'hu', FieldType.TEXT, '40px', null, null, 'end')
          .setSearch(false)
          .setHistory(true)
      ]);
    }

    columns = columns.concat([
      ColumnType.ORDER_PCS(this.constants.piecesMask, this.constants.piecesPlaceholder, config.isFCL)
        .setEditor(!config.isFCL),
      ColumnType.ORDER_WEIGHT_KG.setHandlers({params: weightParams})
    ]);

      if (config.displayDateBilled) {
      columns = columns.concat([
        ColumnType.DATE_BILLED
          .setEditor(config.canChangeDateBilled)
          .setHistory(true),
      ]);
    }

    if (config.displayFF) {
      columns = columns.concat([
        ColumnType.FREIGHT_FORWARDER,
      ]);
    }

    if (!config.isFCL) {
      columns = columns.concat([
        new BaseColumn('originAirport.iataCode', 'Fm Airp', 'originAirport.iataCode', FieldType.TEXT, '60px'),
      ]);
    }

    columns = columns.concat([
      ColumnType.ORDER_LOCATION_FROM,
      ColumnType.ORDER_LOCATION_TO
    ]);

    if (!config.isFCL || config.displayRequestedDeliveryDate) {
      columns = columns.concat([
        new DateTimeColumn("requestedDeliveryDate", 'RDD Req Del', "requestedDeliveryDate", ColumnWidth.DATE_TIME)
          .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
          .setHistory({logChanges: true, field: "requestedDeliveryDate"})
      ]);
    }

    if (config.displayDelivery) {
      columns = columns.concat([
        ColumnType.ORDER_DELIVERY_DATE(),
      ]);
    }

    if (config.displayDriver) {
      columns = columns.concat([
        ColumnType.ORDER_DELIVERY_DRIVER,
      ]);
    }

    columns = columns.concat([
      ColumnType.DATE_CREATED,
      ColumnType.CREATED_BY,
      ColumnType.REQUESTED_BY
    ]);

    columns = columns.concat([
      ColumnType.UPDATED_BY
    ]);



    if (config.isArchived) {
      columns = columns.concat([
        ColumnType.DELETED_DATE,
      ]);
    }
    return columns;
  }

  public createOrderDefaultColumns(config: OrderColumnsConfig): BaseColumn[] {
    let columns: BaseColumn[] = [];
    columns = columns.concat([
      ColumnType.ORDER_JJSM(config.jjsToOrderEditor),
      ColumnType.ORDER_DOC_CENTER(false, this.ordersService, this.dialogs),
      ColumnType.ORDER_OSD,
      ColumnType.ORDER_HAZ,
      ColumnType.ORDER_COM,
      ColumnType.ORDER_CUSTOMER(this.customerService),
      ColumnType.ORDER_MAWB(false),
      ColumnType.ORDER_HAWB(false),
      ColumnType.ORDER_CUSTOMER_REF,
    ]);

    if (config.displayPO) {
      columns = columns.concat([
        ColumnType.ORDER_PO
      ]);
    }

    columns = columns.concat([
      ColumnType.ORDER_HU,
      ColumnType.ORDER_PCS(this.constants.piecesMask, this.constants.piecesPlaceholder),
      ColumnType.ORDER_WEIGHT_KG,
      ColumnType.ORDER_RCVD,
      ColumnType.ORDER_1F_BATTERY,
      ColumnType.ORDER_1C_BATTERY
    ]);

    if (config.displayDO) {
      columns = columns.concat([
        ColumnType.DATE_DO,
      ]);
    }

    if (config.displayDateBilled) {
      columns = columns.concat([
        ColumnType.DATE_BILLED
          .setEditor(config.canChangeDateBilled),
      ]);
    }

    columns = columns.concat([
      ColumnType.ORDER_STATUS(config.statuses),
      ColumnType.ORDER_WHSE,
      ColumnType.ORDER_WHSE_LOC,
    ]);

    if (config.displayFF) {
      columns = columns.concat([
        ColumnType.FREIGHT_FORWARDER,
      ]);
    }

    columns = columns.concat([
      ColumnType.ORDER_DELIVERY_LOCATION,
    ]);

    if (config.displayCfsIn) {
      columns = columns.concat([
        ColumnType.ORDER_CFS_IN
      ]);
    }

    if (config.displayCfsOut) {
      columns = columns.concat([

        new TreeColumn('dateCfsOutAct', 'CFS Out', ColumnWidth.DATE_TIME, {
            row: 'Order',
            column: ColumnType.ORDER_PICKUP_DATE(this.masterService)
          }
        ).setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'}),
      ]);
    }

    if (config.displayDelivery) {
      columns = columns.concat([

        new TreeColumn(ColumnIds.MASTER_DELIVERY_DATE, 'Delivery', ColumnWidth.DATE_TIME, {
            row: 'Order',
            column: ColumnType.ORDER_DELIVERY_DATE()
          },
        ).setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'}),
      ]);
    }

    if (config.displayCarrier) {
      columns = columns.concat([
        ColumnType.CARRIER
          .setEditor({
            editable: (o: Order) => !this.masterService.isDisableOrder(o),
            cleanable: false,
            select: {bindLabel: 'name', search: this.carrierService}
          })
      ]);
    }

    if (config.displayCfsLfd) {
      columns = columns.concat([
        ColumnType.ORDER_CFS_LAST_FREE_DAY([])
      ]);
    }

    if (config.displayJjsLfd) {
      columns = columns.concat([
        ColumnType.ORDER_JJS_STORAGE_DUE
      ]);
    }

    if (config.displayDriver) {
      columns = columns.concat([
        ColumnType.ORDER_DELIVERY_DRIVER,
      ]);
    }

    if (config.displayTruckAndTrailer) {
      columns = columns.concat([
        ColumnType.ORDER_TRUCK,
        ColumnType.ORDER_TRAILER
      ]);
    }

    columns = columns.concat([
      ColumnType.LAST_UPDATED_DATE
    ]);
    return columns;
  }

  public createMasterDefaultColumns(config?: MasterColumnsConfig): BaseColumn[] {
//    console.log('CONFIG', config);
    let columns = [];
    columns = columns.concat([

      new DialogLinkColumn(ColumnIds.MASTER_GENERATE_PTT, '', 'id', FieldType.TEXT, '15px', {
        header: {
          noarrows: true,
          icon: 'fa fa-pencil-square-o'
        }, converter: () => '', icon: () => {
          return 'fa fa-pencil-square-o';
        }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
      }).setSearch(false).setEditor(false),

      ColumnType.MASTER_JJSM,

      ColumnType.MASTER_DOC_CENTER(null, this.dialog, this.dialogs),

      new DialogLinkColumn(ColumnIds.MASTER_OSD, '', 'isOSD', FieldType.TEXT, '15px', {
        header: {
          noarrows: true,
          icon: 'fa fa-exclamation-triangle'
        }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
          return value ? '' : 'fa fa-exclamation-triangle';
        }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
      }).setSearch(false),
      new DialogLinkColumn(ColumnIds.MASTER_HAZ, '', 'hazardousNotes', FieldType.TEXT, '15px', {
        header: {
          noarrows: true,
          icon: 'fa fa-exclamation-circle'
        }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
          return value ? '' : 'fa fa-exclamation-circle';
        }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
      }).setSearch(false),
      new DialogLinkColumn(ColumnIds.MASTER_COM, '', 'defaultNotes', FieldType.TEXT, '15px', {
        header: {
          noarrows: true,
          icon: 'fa fa-comments'
        }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
          return value ? '' : 'fa fa-comments';
        }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
      }).setSearch(false),

      new TreeColumn(ColumnIds.MASTER_MAWB, 'MAWB/H', '145px',
        /*{row: 'Master', column: new TextColumn(ColumnIds.MASTER_MAWB, 'MAWB#', 'masterAir.mawbNumber', FieldType.TEXT, '120px',
            {converter:(row, value)=>{return convertMawbNumber(value) + '    ('+row.orders.length+')'}})
            .setEditor({editable: isMaster, mask: OmsConstants.mawbMask, clearMask: true, placeholder:OmsConstants.mawbPlaceholder, cleanable:false})
            .setHistory({logChanges: true})
            .setButton(this.getMawbTrackButton())
            .setSearch({searchable: true, search: '', searchFunction: "findByFirst3OrLast4Chars", baseLineSearch: true}) },*/
        {
          row: 'Master',
          column: new CustomColumn(ColumnIds.MASTER_MAWB, 'MAWB#', 'masterAir.mawbNumber', FieldType.TEXT, '145px')
            .setComponent(MawbTableCellComponent)
            .setEditor({
              editable: isMaster,
              mask: OmsConstants.mawbMask,
              clearMask: true,
              placeholder: OmsConstants.mawbPlaceholder,
              cleanable: false
            })
            .setHistory({logChanges: true})
            .setSearch({searchable: true, search: '', searchFunction: "findByFirst3OrLast4Chars", baseLineSearch: true})
        },
        {
          row: 'Split',
          column: new CheckboxColumn(14, 'Dispatch', 'isDispatched', FieldType.BOOLEAN, '120px',
            {
              class: (s) => s.hasProblem() ? 'cb-alert' : '',
              tooltip: (s) => s.dispatchedTooltip})
            .setAlign('start').setSortField('splitNumber')
            .setEditor({
              editable: true,
              placeholder: (s: RecoveryOrder) => s.getRouteStatus(),
              postUpdate: true
            })
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_HAWB, 'HAWB#', 'hawb', FieldType.TEXT, '120px', {converter: (row, value) => convertBracketed(value)})
            .setEditor({editable: true, cleanable: false})
            .setHistory({logChanges: true})
            .setSearch({searchable: true, searchType: 'text', search: '', searchFunction: "findByIndexOf", baseLineSearch: true})
        }
      ).setSearch({searchable: true, searchType: 'text', search: '', searchFunction: "findByFirst3OrLast4Chars", baseLineSearch: true}),

      new TreeColumn(ColumnIds.MASTER_CUSTOMER, 'Customer', '80px',
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_CUSTOMER, 'Customer', 'customerNameBasedOnOrders', FieldType.TEXT, '120px', null, null)
            .setEditor({editable: false})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "findCustomerByNameIndexOf",
              baseLineSearch: true
            })
            .setSortField('name')
        },
        {
          row: 'Split',
          column: new HyperLinkColumn(ColumnIds.LOAD_DRIVER, 'Driver', 'dispatchedOn', FieldType.TEXT, '120px', {
            converter: (row, value) => value || (row.shipment && convertLoadNumber(row.shipment.id))
          }, null)
            .setEditor({editable: false})
            .setHistory({logChanges: true, field: 'shipment.load.driver'})
            .setSearch({searchable: true, search: 'dispatchedOn', searchFunction: "", baseLineSearch: true})
            .setSortField('driver.fullName')
            .setHandlers(
              {onClick: row => this.openManifestFromMasterList(row)}
            )
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_CUSTOMER, 'Customer', 'customer', FieldType.TEXT, '120px', {converter: (row, value) => value ? value.name : null}, null)
            .setEditor({editable: true, select: {search: this.customerService}})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "findCustomerByNameIndexOf",
              baseLineSearch: true
            })
            .setHistory({logChanges: true})
            .setSortField('name')
        }),

      new TreeColumn(ColumnIds.MASTER_FREIGHT_FORWARDER, 'Forwarder', '70px',
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_FREIGHT_FORWARDER, 'Forwarder', 'freightForwarder', FieldType.TEXT, '70px', {converter: (row, value) => value ? value.name : null}, null)
            .setEditor({editable: true, select: {search: this.freightForwarderService}})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "findCustomerByNameIndexOf",
              baseLineSearch: true
            })
            .setHistory({logChanges: true})
            .setSortField('name')
        },
        {
          row: 'Order',
          column: ColumnType.ORDER_FF
        }
      ),

      new TreeColumn(ColumnIds.MASTER_HU, 'HU', '40px',
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_HU, 'HU', 'hu', FieldType.TEXT, '40px', null, null, 'end')
            .setSearch(false)
            .setEditor({
              editable: (object) => {
                return object instanceof Master && object.isSingleOrder;
              },
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.piecesMask},
              placeholder: this.constants.piecesPlaceholder
            })
            .setHistory({logChanges: true, field: 'shipment.hu'})
        },
        {
          row: 'Split',
          column: new TextColumn(ColumnIds.MASTER_HU, 'HU', 'huToRecover', FieldType.TEXT, '40px', null, null, 'end')
            .setSearch(false)
            .setEditor({
              editable: (row) => {
                return row instanceof RecoveryOrder ? !row.isVirtual : true;
              },
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.piecesMask},
              placeholder: this.constants.piecesPlaceholder
            })
            .setHistory({logChanges: true, field: 'shipment.hu'})
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_HU, 'HU', 'hu', FieldType.TEXT, '40px', null, null, 'end')
            .setSearch(false)
            .setEditor({
              editable: (row) => {
                return row instanceof RecoveryOrder ? !row.isVirtual : true;
              },
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.piecesMask},
              placeholder: this.constants.piecesPlaceholder
            })
            .setHistory({logChanges: true, field: 'hu'})
        },
      ).setAlign('end').setSearch(false),

      new TreeColumn(ColumnIds.MASTER_PCS, 'PCS', '50px',
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_PCS, 'PCS', 'pieces', FieldType.TEXT, '50px', null, null, 'end')
            .setSearch(false)
            .setEditor({
              editable: (object) => {
                return object instanceof Master && object.isSingleOrder;
              },
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.piecesMask},
              placeholder: this.constants.piecesPlaceholder
            })
            .setHistory({logChanges: true, field: 'shipment.pieces'})
        },
        {
          row: 'Split',
          column: new TextColumn(ColumnIds.MASTER_PCS, 'PCS', 'pcsToRecover', FieldType.TEXT, '50px', {}, null, 'end')
            .setSearch(false)
            .setEditor({
              editable: (row: RecoveryOrder) => !row.pcsReceived,
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.piecesMask},
              placeholder: this.constants.piecesPlaceholder
            })
            .setHistory({logChanges: true, field: 'shipment.pieces'})
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_PCS, 'PCS', 'pieces', FieldType.TEXT, '50px', {}, null, 'end')
            .setSearch(false).setEditor({
              editable: true,
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.piecesMask},
              placeholder: this.constants.piecesPlaceholder
            })
            .setHistory({logChanges: true, field: 'pieces'})
        },
      ).setAlign('end').setSearch(false),

      new TreeColumn(ColumnIds.MASTER_WEIGHT, 'KG', '70px',
        {
          row: 'Master', column: new WeightColumn(ColumnIds.MASTER_WEIGHT, 'KG', 'weight', FieldType.TEXT, '70px')
            .setSearch(false)
            .setEditor({
              editable: (object) => {
                return object instanceof Master && object.isSingleOrder;
              }, cleanable: false, clearMask: true, mask: {mask: this.constants.weightMask}
            })
            .setHistory({logChanges: true, field: 'shipment.weight'})
        },
        {
          row: 'Split', column: new WeightColumn(ColumnIds.MASTER_WEIGHT, 'KG', 'weight', FieldType.TEXT, '70px')
            .setSearch(false)
            .setEditor({
              editable: (object) => {
                return (object instanceof RecoveryOrder && !object.isVirtual);
              }, cleanable: false, clearMask: true, mask: {mask: this.constants.weightMask}
            })
            .setHistory({logChanges: true, field: 'shipment.weight'})
        },
        {
          row: 'Order', column: new WeightColumn(ColumnIds.MASTER_WEIGHT, 'KG', 'weight', FieldType.TEXT, '70px')
            .setSearch(false)
            .setEditor({
              editable: (object) => {
                return object instanceof Order;
              }, cleanable: false, clearMask: true, mask: {mask: this.constants.weightMask}
            })
            .setHistory({logChanges: true, field: 'weight'})
        },
      ).setAlign('end').setSearch(false),

      new DialogLinkColumn(ColumnIds.MASTER_AIR_ULD_COUNT, 'ULD', 'uldCount', FieldType.TEXT, '40px', {
        header: {noarrows: true},
        converter: (row, value) => isNullOrUndefined(value) || value === 0 ? 'N/A' : value,
        link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
      })
        .setSearch(false).setEditor(false).setAlign('center'),

      new TreeColumn(ColumnIds.MASTER_PCS, 'RCVD', '40px',
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_PCS, 'PCS RCVD', 'pcsReceived', FieldType.TEXT, '40px', {class: receivedValueClass}, null, 'end')
            .setSearch(false).setEditor(false).setHistory({logChanges: true, field: 'pcsReceived'})
        },
/*        {
          row: 'Split',
          column: new TextColumn(ColumnIds.MASTER_PCS, 'PCS RCVD', 'pcsReceived', FieldType.TEXT, '40px', null, null, 'end')
            .setSearch(false).setEditor(false).setHistory({logChanges: true, field: 'pcsReceived'})
        },

 */
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_PCS_RCVD, 'PCS RCVD', 'pcsReceived', FieldType.TEXT, '40px', {class: receivedValueClass}, null, 'end')
            .setSearch(false).setEditor(false).setHistory({logChanges: true, field: 'pcsReceived'})
        },
      ).setAlign('end').setSearch(false).setSortable(false),

      new TreeColumn(ColumnIds.MASTER_FILL_STATUS, 'RCVD', '50px',
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_FILL_STATUS, 'RCVD', 'fillStatus', FieldType.TEXT, '50px',
            {
              converter: () => null,
              tooltip: (row) => row.fillStatusDescription,
              icon: iconFillStatus,
              class: fillIndicatorClass
            }, null, 'center').setEditor(false)
        },
        {row: 'Order', column: ColumnType.ORDER_RCVD}
      ).setSearch(false).setSortable(false),

      new TreeColumn('isAcknowledged', 'ACK', '25px',
        {
          row: 'Master',
          column: new CheckboxColumn(ColumnIds.MASTER_PTT_CREATED, 'ACK', 'isAcknowledged', FieldType.BOOLEAN, '25px')
          // .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "isSelectedValue",
              searchType: "list",
              items: BaseColumn.YES_NO_FILTERS
            })
            .setEditor(true)
            .setHistory({logChanges: true, field: 'isAcknowledged'})
        },
        {
          row: 'Order',
          column: new CheckboxColumn(ColumnIds.MASTER_PTT_CREATED, 'ACK', 'isAcknowledged', FieldType.BOOLEAN, '25px')
            .setEditor(true)
            .setSortable(true)
            .setHistory({logChanges: true})
        }
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "isSelectedValue",
        searchType: "list",
        items: BaseColumn.YES_NO_FILTERS
      })
        .setSortable(true),

      new TreeColumn(ColumnIds.MASTER_1F, '1F', '25px',
        {row: 'Master', column: ColumnType.MASTER_1F_BATTERY},
        {row: 'Order', column: ColumnType.ORDER_1F_BATTERY}
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "isSelectedValue",
        searchType: "list",
        items: BaseColumn.YES_NO_FILTERS
      })
      //       .setSearch(false),
      ,

      new TreeColumn(ColumnIds.MASTER_1C, '1C', '25px',
        {row: 'Master', column: ColumnType.MASTER_1C_BATTERY},
        {row: 'Order', column: ColumnType.ORDER_1C_BATTERY}
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "isSelectedValue",
        searchType: "list",
        items: BaseColumn.ynFilterItems
      }),

      new TextColumn(ColumnIds.MASTER_STATUS, 'Status', 'status', FieldType.TEXT, '100px',
        {
          tooltip: (row) => convertMawbStatus(row.status),
          sorted: {asc: true},
          converter: (row, value) => convertMawbStatus(value),
          editor: {
            editable: (row) => row instanceof Master || row instanceof Order, cleanable: false,
            select: {items: MasterStatus.items, converter: (value) => MasterStatus.get(value)},
            converter: (value) => {
              return value instanceof MasterStatus ? value.id : value;
            }
          },
          class: statusIndicatorClass
        })
        .setSearch({
          searchable: true,
          search: '',
          searchFunction: "itemNumberEquals",
          searchType: "list-multi",
          items:  (config && config.statuses && config.statuses.available) ?
                      config.statuses.available.map((id) => MasterStatus.get(id)) : MasterStatus.items,
          multiple: true
        })
        .setHistory({logChanges: true, field: 'firstShipment.status'}),

      new TextColumn(ColumnIds.MASTER_COLOR_STATUS, 'Color Status', 'colorStatus', FieldType.TEXT, '150px', null)
        .setSearch({searchable: true})
        .setVisible(false)
        .setHistory({logChanges: false}),

      ColumnType.FREIGHT_FORWARDER_REF,

      new TreeColumn(ColumnIds.MASTER_PROBLEM, 'Problem', '50px',
        {
          row: 'Master',
          column: new CheckboxColumn(ColumnIds.MASTER_PROBLEM, 'Problem', 'problems', FieldType.DIALOG, '80px',
            {
              class: (object) => checkboxTypeByProblem(object.problemStatus),
              tooltip: (object) => object.problemDesc,
              converter: (row, value) => value && value.length
            })
            .setEditor(true)
            .setHistory({logChanges: true, methodForExecution: "executeMasterProblemLogChanges"})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "findByProblemStatus",
              searchType: "list",
              items: BaseColumn.problemFilterItems
            })
        })
        .setSearch({
          searchable: true,
          search: '',
          searchFunction: "findByProblemStatus",
          searchType: "list",
          items: BaseColumn.problemFilterItems
        })
        .setSortable(false),

      new TreeColumn(ColumnIds.MASTER_AIRLINE, 'Airline', '80px',
        {
          row: 'Master',
          column: new TextColumn(9, 'Airline', 'airline', FieldType.TEXT, '80px', {converter: (row, airline) => airline ? airline.name : null})
            .setEditor({
              editable: true,
              cleanable: false,
              select: {
                bindLabel: 'name',
                search: this.airlineService,
                placeholder: this.constants.AIRLINE_SEARCH_PLACEHOLDER
              }
            })
            .setHistory({logChanges: true, field: 'masterShipmentAir.airline'})
            .setSearch({searchable: true, search: '', searchFunction: "findByIndexOf", baseLineSearch: true})
        },
        {
          row: 'Split',
          column: new TextColumn(9, 'Airline', 'airline', FieldType.TEXT, '80px', {converter: (row, airline) => airline ? airline.name : null})
            .setEditor({
              editable: (row) => !row.isVirtual,
              cleanable: false,
              select: {search: this.airlineService, placeholder: this.constants.AIRLINE_SEARCH_PLACEHOLDER}
            })
            .setHistory({logChanges: true})
            .setSearch({searchable: true, search: '', searchFunction: "findByIndexOf", baseLineSearch: true})
        },
        {
          row: 'Order', column: new TextColumn(9, 'Airline', PARENT_REF + '.airline.name', FieldType.TEXT, '80px')
        },
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "findByIndexOf",
        baseLineSearch: true
      }).setSortField('name'),

      new TreeColumn(ColumnIds.MASTER_CARGO_BLD, 'Cargo Bldg', ColumnWidth.CARGO_BLDG,
        {
          row: 'Master',
          column: new BaseColumn(ColumnIds.MASTER_CARGO_BLD, 'Cargo Bldg', 'cargoBuilding', FieldType.ADDRESS, ColumnWidth.ADDRESS, {
            converter: convertMasterCargoBuilding,
            tooltip: m => convertAddress(m.cargoBuilding)
          })
            .setSearch({searchable: true, search: '', searchFunction: "findByNameIndexOf", baseLineSearch: true})
            .setHistory({logChanges: true, field: 'shipment.addressDelivery'})
            .setEditor({
              editable: true,
              select: {
                addressTypes: [AddressType.CARGO_BUILDING],
                placeholder: this.constants.ADDRESS_SEARCH_PLACEHOLDER
              }
            })
        },
        {
          row: 'Split',
          column: new BaseColumn(ColumnIds.MASTER_CARGO_BLD, 'Cargo Bldg', 'cargoBuilding', FieldType.ADDRESS, ColumnWidth.ADDRESS, {tooltip: s => convertAddress(s.cargoBuilding)})
            .setSearch({searchable: true, search: '', searchFunction: "findByNameIndexOf", baseLineSearch: true})
            .setHistory({logChanges: true})
            .setEditor({
              editable: true,
              select: {addressTypes: [AddressType.CFS_LOCATION], placeholder: this.constants.ADDRESS_SEARCH_PLACEHOLDER}
            })
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_CARGO_BLD, 'Cargo Bldg', PARENT_REF + '.shipment.addressDelivery', FieldType.ADDRESS, ColumnWidth.ADDRESS)
            .setEditor(false)
        },
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "findByNameIndexOf",
        baseLineSearch: true
      }).setSortField('name'),
    ]);

    if (config && config.displayCfsLocation) {
      columns = columns.concat([

        new TreeColumn("cfsLocation", 'CFS Location', '100px',
          {row: 'Master', column: ColumnType.MASTER_CFS_LOCATION},
          {row: 'Order', column: ColumnType.ORDER_CFS_LOCATION}
        )
      ]);
    }

    columns = columns.concat([
      new TreeColumn(ColumnIds.MASTER_DELIVERY_LOCATION, 'Delivery Location', '130px',
        {
          row: 'Master',
          column: new BaseColumn(ColumnIds.MASTER_DELIVERY_LOCATION, 'Delivery Location', 'addressDelivery', FieldType.ADDRESS, '130px', {tooltip: m => convertAddress(m.addressDelivery)})
            .setSearch({searchable: true, search: '', searchFunction: "findByNameIndexOf", baseLineSearch: true})
            .setHistory({logChanges: true})
            .setEditor({
              editable: (master) => !master.direct,
              select: {addressTypes: [AddressType.CFS_LOCATION], placeholder: this.constants.ADDRESS_SEARCH_PLACEHOLDER}
            })
        },
        {
          row: 'Order',
          column: new BaseColumn(ColumnIds.ORDER_DELIVERY_LOCATION, 'Delivery Location', 'addressDelivery', FieldType.ADDRESS, '130px',
            {tooltip: o => convertAddress(o.addressDelivery)})
            .setSearch({searchable: true, search: '', searchFunction: "findByNameIndexOf", baseLineSearch: true})
            .setEditor({
              editable: (o) => o.master && !o.master.direct,
              select: {addressTypes: [AddressType.DELIVERY_LOCATION]}
            })
        },
        {
          row: 'Split',
          column: new BaseColumn(ColumnIds.ORDER_DELIVERY_LOCATION, 'Delivery Location', 'addressDelivery', FieldType.ADDRESS, '130px',
            {tooltip: (s) => convertAddress(s.addressDelivery)})
            .setSearch({searchable: true, search: '', searchFunction: "findByNameIndexOf", baseLineSearch: true})
            .setEditor({editable: false, select: {addressTypes: [AddressType.DELIVERY_LOCATION]}})
        },
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "findByNameIndexOf",
        baseLineSearch: true
      }).setSortField('name'),

      new TreeColumn(ColumnIds.MASTER_ARRIVAL_DATE, 'Arrival Date', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new ArrivalDateColumn(ColumnIds.MASTER_ARRIVAL_DATE, 'Arrival Date', {
            actual: 'dateArrivalAct',
            estimated: 'dateArrivalEst'
          }, ColumnWidth.DATE_TIME, null)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor(true)
            .setHistory({
              logChanges: true,
              field: {actual: 'shipment.dateDeliveryActual', estimated: 'shipment.dateDeliveryExpectation'}
            })
        },
        {
          row: 'Order',
          column: new ArrivalDateColumn(ColumnIds.ORDER_ARRIVAL_DATE, 'Arrival Date', {
            actual: 'dateArrivalAct',
            estimated: 'dateArrivalEst'
          }, ColumnWidth.DATE_TIME, null, {
            rowObject: (o) => {/*if (o.id = 450) console.log('===', o, o[PARENT_REF]);*/
              return o[PARENT_REF];
            }
          })
          // .setSearch(false).setEditor(false)
        }
      ).setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"}),

      new TreeColumn(ColumnIds.MASTER_ISC, 'ISC', '50px',
        {
          row: 'Master', column: new DateCheckboxColumn(ColumnIds.MASTER_ISC, 'ISC', 'dateISCPaid', '50px')
            .setEditor({editable: true})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "isSelectedValue",
              searchType: "list",
              items: BaseColumn.ynFilterItems
            })
            .setHistory({logChanges: true})
        },
        {
          row: 'Order', column: new DateCheckboxColumn(ColumnIds.ORDER_ISC, 'ISC', 'dateISCPaid', '50px')
            .setEditor({editable: o => !o.nonAMS}).setHidden(o => o.nonAMS)
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "isSelectedValue",
              searchType: "list",
              items: BaseColumn.ynFilterItems
            })
        }
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "isSelectedValue",
        searchType: "list",
        items: BaseColumn.ynFilterItems
      }),

      new TreeColumn(ColumnIds.MASTER_LFD, 'LFD', '45px',
        {
          row: 'Master',
          column: new DateColumn(ColumnIds.MASTER_LFD, 'Last Free Day', 'dateLastFreeDay', '45px', null, {class: masterDateLastFreeDayClass})
            .setSearch({searchable: true, searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: true, cleanable: false})
            .setHistory({logChanges: true})
        },
        {
          row: 'Order',
          column: new DateColumn(ColumnIds.ORDER_LFD, 'Last Free Day', 'dateLastFreeDay', '45px', null, {
            rowObject: (o) => o[PARENT_REF],
            class: (o) => masterDateLastFreeDayClass(o)
          })
        },
      ).setSearch({searchable: true, searchFunction: "isTheSameDay", searchType: "time"}),

      new TreeColumn(ColumnIds.MASTER_DELIVERY_APPROVAL, 'D/O', '50px',
        {
          row: 'Master',
          column: new CheckboxColumn(ColumnIds.MASTER_DELIVERY_APPROVAL, 'D/O', 'deliveryApproval', FieldType.BOOLEAN, '50px')
          // .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "isSelectedValue",
              searchType: "list",
              items: BaseColumn.ynFilterItems
            })
            .setEditor(true)
            .setHistory({logChanges: true, field: 'someOrder.deliveryApproval'})
        },
        {
          row: 'Order',
          column: new CheckboxColumn(ColumnIds.MASTER_DELIVERY_APPROVAL, 'D/O', 'deliveryApproval', FieldType.BOOLEAN, '50px')
            .setEditor(true)
            .setSortable(true)
            .setHistory({logChanges: true})
        }
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "isSelectedValue",
        searchType: "list",
        items: BaseColumn.ynFilterItems
      })
        .setSortable(true),

      /*      new TreeColumn(ColumnIds.MASTER_PICKUP_DATE, 'Pick Up', ColumnWidth.DATE_TIME,
              {row: 'Master', column: new DateTimeColumn(ColumnIds.MASTER_PICKUP_DATE, 'Pick Up', {actual:'datePickupActual', estimated: 'datePickupEstimated'}, ColumnWidth.DATE_TIME)
                  .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
                  .setEditor(true)
                  .setHistory({logChanges: true})},
              {row: 'Split', column: new DateTimeColumn(ColumnIds.MASTER_PICKUP_DATE, 'Pick Up', {actual:'datePickupAct', estimated: 'datePickupEst'}, ColumnWidth.DATE_TIME)
                  .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
                  .setEditor({editable: (row)=>!row.isVirtual})
                  .setHistory({logChanges: true})},
              {row:'Order', column: new DateTimeColumn(ColumnIds.ORDER_PICKUP_DATE, 'Pick Up', {actual:'datePickupAct', estimated: 'datePickupEst'}, ColumnWidth.DATE_TIME)
                  .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
                  .setEditor(true).setHistory({logChanges: true})},
            ).setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"}), */

      new TreeColumn(ColumnIds.MASTER_PICKUP_DATE, 'Pick Up', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new DateTimeColumn(ColumnIds.MASTER_PICKUP_DATE, 'Pick Up', {
            actual: 'datePickupActual',
            estimated: 'datePickupEstimated'
          }, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: true, external: true})
            .setHistory({logChanges: true})
        },
        {
          row: 'Split',
          column: new DateTimeColumn(ColumnIds.MASTER_PICKUP_DATE, 'Pick Up', {
            actual: 'datePickupAct',
            estimated: 'datePickupEst'
          }, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: true, external: true})
            .setHistory({logChanges: true,
              className: Shipment.JAVA_CLASS_NAME,
              useLinkParamsBasedOnColumn: true,
              field: {actual: 'datePickUpActual', estimated: 'datePickUpExpectation'},
              useId: "shipment"
              })
        },
        {
          row: 'Order',
          column: new DateTimeColumn(ColumnIds.ORDER_PICKUP_DATE, 'Pick Up', {
            actual: 'datePickupAct',
            estimated: 'datePickupEst'
          }, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor(true).setHistory({logChanges: true})
        },
      ).setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"}),


      new TreeColumn(ColumnIds.MASTER_DATE_CFS_IN, 'CFS In', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new DateTimeColumn(ColumnIds.MASTER_DATE_CFS_IN, 'CFS In', {
            actual: 'dateCfsInAct',
            estimated: 'dateCfsInEst'
          }, ColumnWidth.DATE_TIME, null)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: false/*m=>!m.noCfs*/}).setHidden(m => m.noCfs)
            .setHistory({
              logChanges: true,
              field: {actual: 'dateCfsInActOrder.dateCfsInAct', estimated: 'dateCfsInEstOrder.dateCfsInEst'}
            })
        },
        {
          row: 'Order', column: ColumnType.ORDER_CFS_IN
        },
        {
          row: 'Split',
          column: new DateTimeColumn(ColumnIds.MASTER_DATE_CFS_IN, 'CFS In', 'dateDeliveryAct', ColumnWidth.DATE_TIME, null, null).setEditor({editable: (row) => !row.isVirtual}).setSearch(true)
            .setEditor(false /* temp for now */).setHidden(s => s.noCfs)
        }
      ).setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"}),

      new TreeColumn(ColumnIds.MASTER_DISPATCHED_STATUS, 'DS', '50px',
        {
          row: 'Master',
          column: new CheckboxColumn(ColumnIds.MASTER_DISPATCHED_STATUS, 'DS', 'dispatchedStatus', FieldType.BOOLEAN, '50px')
          // .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setSearch({
              searchable: true,
              search: '',
              searchFunction: "isSelectedValue",
              searchType: "list",
              items: BaseColumn.ynFilterItems
            })
            .setEditor(true)
            .setHistory({logChanges: true, field: 'someOrder.dispatchedStatus'})
        },
        {
          row: 'Order',
          column: new CheckboxColumn(ColumnIds.MASTER_DISPATCHED_STATUS, 'DS', 'dispatchedStatus', FieldType.BOOLEAN, '50px')
            .setEditor(true)
            .setSortable(true)
            .setHistory({logChanges: true})
        }
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "isSelectedValue",
        searchType: "list",
        items: BaseColumn.ynFilterItems
      })
        .setSortable(true),


      new TreeColumn(ColumnIds.MASTER_DATE_CFS_OUT, 'CFS Out', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new DateTimeColumn(ColumnIds.MASTER_DATE_CFS_OUT, 'CFS Out', {
            actual: 'dateCfsOutAct',
            estimated: 'dateCfsOutEst'
          }, ColumnWidth.DATE_TIME, null)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: (m: Master) => !this.masterService.isDisableMaster(m)}).setHidden(m => m.noCfs)
            .setHistory({
              logChanges: true,
              field: {actual: 'dateCfsOutActOrder.dateCfsOutAct', estimated: 'dateCfsOutEstOrder.dateCfsOutEst'}
            })
        },
        {
          row: 'Order',
          column: new DateTimeColumn(ColumnIds.ORDER_DATE_CFS_OUT, 'CFS Out', {
            actual: 'dateCfsOutAct',
            estimated: 'dateCfsOutEst'
          }, ColumnWidth.DATE_TIME, null, {})
            .setEditor({editable: (o: Order) => (!o.noCfs && !this.masterService.isDisableOrder(o))}).setHidden(o => o.noCfs)
            .setSearch(false).setHistory({
              logChanges: true,
              field: {actual: 'dateCfsOutAct', estimated: 'dateCfsOutEst'}
            })
        },
      ).setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"}),

      new TreeColumn(ColumnIds.MASTER_DATE_CFS_FREE_TIME, 'CFS LFD', ColumnWidth.DATE,
        {
          row: 'Master',
          column: new DateColumn(ColumnIds.MASTER_DATE_CFS_FREE_TIME, 'CFS LFD', 'dateCfsFreeTime', ColumnWidth.DATE, null, {class: masterDateCFSFreeDayClass})
            .setHandlers({converter: () => ''})
            .setSearch({searchable: true, searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: false, cleanable: false})
            .setHistory({logChanges: true})
        },
        {
          row: 'Order',
          column: new DateColumn(ColumnIds.ORDER_DATE_CFS_FREE_TIME, 'CFS LFD', 'dateCfsFreeTime', ColumnWidth.DATE, null, {
            class: (o) => orderDateCFSFreeDayClass(o)
          })
            .setEditor({editable: true, cleanable: false})
            .setHistory({logChanges: true})
        }),

      new TreeColumn(ColumnIds.MASTER_DELIVERY_DATE, 'Delivery', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new DateTimeColumn(ColumnIds.MASTER_DELIVERY_DATE, 'Delivery', {
            actual: 'dateDeliveryActual',
            estimated: 'dateDeliveryEstimated'
          }, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor(false)
            .setHistory({logChanges: true})
        },
        {
          row: 'Split',
          column: new TextColumn(ColumnIds.MASTER_DELIVERY_DATE, 'Delivery', null, FieldType.TEXT, ColumnWidth.DATE_TIME, {converter: () => OmsConstants.EMPTY_VALUE}, null)
        },
        {
          row: 'Order',
          column: new DateTimeColumn(ColumnIds.ORDER_DELIVERY_DATE, 'Delivery', {
            actual: 'dateDeliveryAct',
            estimated: 'dateDeliveryReq'
          }, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: (o: Order) => (!this.masterService.isDisableOrder(o))})
            .setHistory({logChanges: true, field: {actual: 'dateDeliveryAct', estimated: 'dateDeliveryReq'}})
        },
      ).setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"}),

      new TreeColumn(ColumnIds.MASTER_DATE_BILLED, 'Billed On', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new DateTimeColumn(ColumnIds.MASTER_DATE_BILLED, 'Billed On', 'dateBilled', ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor(false)
            .setHistory({logChanges: true, field: 'dateBilled'})
        },
        {
          row: 'Split',
          column: new TextColumn(ColumnIds.MASTER_DATE_BILLED, 'Billed On', null, FieldType.TEXT, ColumnWidth.DATE_TIME, {converter: () => OmsConstants.EMPTY_VALUE}, null)
        },
        {
          row: 'Order',
          column: new DateTimeColumn(ColumnIds.ORDER_BILLED, 'Billed On', 'dateBilled', ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor(false)
            .setHistory({logChanges: true, field: 'dateBilled'})
        },
      ).setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"}),

    ]);

    if (config && config.isCarrier) {
      columns = columns.concat([
        new TreeColumn('carrier', 'Carrier', '110px',
          {
            row: 'Order', column: ColumnType.CARRIER
              .setEditor({
                editable: (order: Order) => (!this.masterService.isDisableOrder(order)),
                cleanable: false,
                select: {
                  bindLabel: 'name',
                  search: this.carrierService,
                  createNewHandler: config.isCanCreateCarrier ? () => this.createCarrierDialog() : null
                }
              })
          },
        ).setSearch({
          searchable: true,
          search: '',
          searchFunction: 'findByIndexOf',
          baseLineSearch: true
        }).setSortField('name'),
      ]);
    }

    columns = columns.concat([

      new TreeColumn(ColumnIds.MASTER_PDF, 'DOC', ColumnWidth.BUTTON,
        {
          row: 'Master', column: new ActionsColumn(ColumnIds.MASTER_PDF, 'DOC', ColumnWidth.BUTTON)
        },
        {
          row: 'Order', column: new ActionsColumn(ColumnIds.MASTER_PDF, 'DOC', ColumnWidth.BUTTON)
        }
      ).setSearch(false).setSortable(false),

      new TreeColumn(ColumnIds.MASTER_FLIGHT, 'Flight', '60px',
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_FLIGHT, 'Flight', 'flightNumber', FieldType.TEXT, ColumnWidth.FLIGHT)
            .setEditor({editable: true, cleanable: false})
            .setHistory({logChanges: true, field: 'masterShipmentAir.flightNumber'})
            .setSearch({searchable: true, searchFunction: "findByIndexOf", baseLineSearch: true})
        },
        {
          row: 'Split',
          column: new TextColumn(ColumnIds.MASTER_FLIGHT, 'Flight', 'flightNumber', FieldType.TEXT, ColumnWidth.FLIGHT)
            .setEditor({editable: true, cleanable: false})
            .setHistory({logChanges: true, field: 'flightNumber'})
            .setSearch({searchable: true, searchFunction: "findByIndexOf", baseLineSearch: true})
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.MASTER_FLIGHT, 'Flight', PARENT_REF + '.flightNumber', FieldType.TEXT, ColumnWidth.FLIGHT)
        },
      ).setSearch({searchable: false, searchFunction: "findByIndexOf", baseLineSearch: true}),

      new TreeColumn(ColumnIds.MASTER_VOLUME, 'CFT', '60px',
        {
          row: 'Master', column: new VolumeColumn(ColumnIds.MASTER_VOLUME, 'CFT', 'volume', FieldType.TEXT, '70px')
            .setSearch(false)
            .setEditor({
              editable: (row) => row instanceof Master && row.isSingleOrder,
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.volumeMask},
              placeholder: this.constants.volumePlaceholder
            })
            .setHistory({logChanges: true, field: 'volume'})
        },
        {
          row: 'Split', column: new VolumeColumn(ColumnIds.MASTER_VOLUME, 'CFT', 'volume', FieldType.TEXT, '70px')
            .setSearch(false)
            .setEditor({
              editable: (row) => row instanceof RecoveryOrder ? !row.isVirtual : true,
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.volumeMask},
              placeholder: this.constants.volumePlaceholder
            })
            .setHistory({logChanges: true, field: 'volume'})
        },
        {
          row: 'Order', column: new VolumeColumn(ColumnIds.ORDER_VOLUME, 'CFT', 'volume', FieldType.TEXT, '70px')
            .setSearch(false)
            .setEditor({
              editable: (row) => row instanceof Order,
              cleanable: false,
              clearMask: true,
              mask: {mask: this.constants.volumeMask},
              placeholder: this.constants.volumePlaceholder
            })
            .setHistory({logChanges: true, field: 'volume'})
        },
      ).setAlign('end').setSearch(false),

      new TreeColumn(ColumnIds.MASTER_CBP_STATUS, 'CBP Status', '80px',
/*        {
          row: 'MasterLine',
          column: new TextColumn(ColumnIds.MASTER_CBP_STATUS, 'CBP Status', 'cbpStatus', FieldType.DIALOG, '120px', {converter: (master, value) => master.nonAMS ? 'NON-AMS' : value})
            .setEditor({editable: true})
            .setHistory({logChanges: true})
            .setSearch({searchable: true, search: ''})
        },*/
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_CBP_STATUS, 'CBP Status', 'cbpStatus', FieldType.DIALOG, '120px', {converter: (master, value) => master.nonAMS ? 'NON-AMS' : value})
            .setEditor({editable: (master) => !master.nonAMS})
            .setHistory({logChanges: true, logConverter: true})
            .setSearch({searchable: true, search: ''})
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_CBP_STATUS, 'CBP Status', 'cbpStatus', FieldType.DIALOG, '120px')
            .setEditor({editable: true})
            .setHistory({logChanges: true})
            .setSearch({searchable: true, search: ''})
        },
      ).setSearch({searchable: true, search: ''}),

      // * Create Date Column
      new TreeColumn(ColumnIds.MASTER_DATE_CREATED, 'Created Date', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new DateTimeColumn(ColumnIds.MASTER_DATE_CREATED, 'Created Date', 'dateCreated', ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: false, cleanable: false})
            .setHistory({logChanges: true})
        },
        {
          row: 'Order',
          column: new DateTimeColumn(ColumnIds.ORDER_DATE_CREATED, 'Created Date', 'dateCreated', ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: false, cleanable: false})
        }
      ).setSearch({
        searchable: true,
        search: '',
        searchFunction: "isTheSameDay",
        searchType: "time"
      }).setSortField('dateCreated'),

      new TreeColumn(ColumnIds.MASTER_CREATED_BY, 'Created By', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_CREATED_BY, 'Created By', 'createdBy', FieldType.TEXT, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true})
            .setEditor({editable: false, cleanable: false})
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_CREATED_BY, 'Created By', 'createdBy', FieldType.TEXT, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: false, cleanable: false})
        }
      ).setSearch({searchable: true}).setSortField('createdBy'),

      new TreeColumn(ColumnIds.MASTER_REQUESTED_BY, 'Requested By', ColumnWidth.DATE_TIME,
        {
          row: 'Master',
          column: new TextColumn(ColumnIds.MASTER_REQUESTED_BY, 'Requested By', 'requestedBy', FieldType.TEXT, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true})
            .setEditor({editable: false, cleanable: false})
        },
        {
          row: 'Order',
          column: new TextColumn(ColumnIds.ORDER_REQUESTED_BY, 'Requested By', 'requestedBy', FieldType.TEXT, ColumnWidth.DATE_TIME)
            .setSearch({searchable: true, search: '', searchFunction: "isTheSameDay", searchType: "time"})
            .setEditor({editable: true, cleanable: false})
        }
      ).setSearch({searchable: true}).setSortField('requestedBy'),

      new TreeColumn('dateUpdated', 'Updated', ColumnWidth.DATE_TIME,
        {
          row: 'Master', column: ColumnType.LAST_UPDATED_DATE
        }, {
          row: 'Split', column: ColumnType.LAST_UPDATED_DATE
        }, {
          row: 'Order', column: ColumnType.LAST_UPDATED_DATE
        }
      ).setSearch({searchable: true, searchFunction: "isTheSameDay", searchType: "time"}),

      new TreeColumn(ColumnIds.MASTER_AIRLINE, 'Dispatched Time', '140px',
        {
          row: 'Master',
          column: new TextColumn('dispOrRcvdTimestamps', 'Dispatched Time', 'dispOrRcvdTimestamps', FieldType.TEXT, '140px')
            .setHidden(true)
        },
        {
          row: 'Split',
          column: new TextColumn("dispOrRcvdTimestamps", 'Dispatched Time', 'dispOrRcvdTimestamps', FieldType.TEXT, '140px')
        },
        {
          row: 'Order', column: new TextColumn('dispOrRcvdTimestamps', 'Dispatched Time', 'dispOrRcvdTimestamps', FieldType.TEXT, '140px')
            .setHidden(true)
        },
      ).setSearch({searchable: false}),
    ]);


    if (config && config.isArchived) {
      columns = columns.concat([
        new TreeColumn('dateDeleted', 'Deleted', ColumnWidth.DATE_TIME,
          {
            row: 'Master', column: ColumnType.DELETED_DATE
          }, {
            row: 'Split', column: ColumnType.DELETED_DATE
          }, {
            row: 'Order', column: ColumnType.DELETED_DATE
          }
        ).setSearch({searchable: true, searchFunction: "isTheSameDay", searchType: "time"}),
      ]);
    }

    if (config && config.displayPO) {
      columns = columns.concat([
        new TreeColumn('purchaseOrder', 'PO', '150px',
          {
            row: 'Order', column: ColumnType.ORDER_PO
          }
        ),
      ]);
    }

    return columns;
  }

  private createCarrierDialog(): Observable<Carrier> {
    const dialogRef = this.dialog.open<CarrierDialogComponent, any, { res: Carrier, isEdit: boolean }>(CarrierDialogComponent, {
      width: 'auto'
    });
    dialogRef.componentInstance.carrier = null;
    dialogRef.componentInstance.displayInHouse = false;
    return dialogRef.afterClosed()
      .pipe(map((response) => response.res));
  }

  getMasterListColumns() {
    this.masterListColumns.next(this.masterDefaultColumns);
  }

  getMasterOrdersColumns() {
    this.masterOrdersColumns.next(this.masterOrdersDefaultColumns);
  }

  getMasterLoadsColumns() {
    this.masterLoadsColumns.next(this.masterLoadsDefaultColumns);
  }

  getOrderPartsColumns() {
    this.orderPartsColumns.next(this.orderPartsDefaultColumns);
  }

  getRecoveryLoadColumns() {
    this.recoveryLoadColumns.next(this.recoveryLoadDefaultColumns);
  }

  getAmsDataColumns() {
    this.asmDataColumns.next(this.asmDataDefaultColumns);
  }

  getMasterFsrColumns() {
    this.masterFsrColumns.next(this.masterFsrDefaultColumns);
  }

  getUldLinkedColumns() {
    this.uldLinkedColumns.next(this.uldLinkedDefaultColumns);
  }

  getDispatchAndRecoveryLogsColumns() {
    this.dispatchAndRecoveryLogsColumns.next(this.dispatchAndRecoveryLogsDefaultColumns);
  }

  buildOrderConfig(tabInfo: TabInfo, statuses: MasterStatus[], hasBillingApproveRole: boolean, modes: any[], isCanCreateCarrier: boolean): OrderColumnsConfig {
    let isFCLMode = modes && (modes.indexOf(7) !== -1 || modes.indexOf(8) !== -1);

    return  {
      statuses: statuses,
      displayJjsLfd: tabInfo === TabsType.WHSE,
      displayFF: tabInfo === TabsType.CFS || tabInfo === TabsType.ALL_ORDERS,
      displayCfsIn: tabInfo === TabsType.CFS || tabInfo === TabsType.WHSE,
      displayCfsLfd: tabInfo === TabsType.CFS || tabInfo === TabsType.WHSE,
      displayCfsOut: tabInfo !== TabsType.CFS,
      displayDelivery: tabInfo !== TabsType.CFS && tabInfo !== TabsType.PENDING,
      displayCarrier: tabInfo !== TabsType.CFS,
      displayDriver: tabInfo !== TabsType.CFS && tabInfo !== TabsType.PENDING && tabInfo !== TabsType.BILLING_ORDER && tabInfo !== TabsType.ARCHIVED && tabInfo !== TabsType.ORDERS_DELETED && tabInfo !== TabsType.DELIVERY_ORDER,
      displayTruckAndTrailer: tabInfo === TabsType.DELIVERY,
      displayDO: tabInfo !== TabsType.ALL_ORDERS,
      displayDateBilled: tabInfo === TabsType.ARCHIVED_ORDER || tabInfo === TabsType.DELIVERY || (tabInfo === TabsType.ACTIVE_ORDER && !isFCLMode) || tabInfo === TabsType.DELIVERY_ORDER || tabInfo === TabsType.BILLING_ORDER
        || tabInfo === TabsType.ARCHIVED,
      displayPO: true, // tabInfo === TabsType.CFS || tabInfo === TabsType.WHSE,
      jjsToOrderEditor: TabsType.ORDER_TABS.includes(tabInfo),
      canChangeDateBilled: hasBillingApproveRole,
      isActiveTab: tabInfo === TabsType.PENDING || tabInfo === TabsType.ACTIVE_ORDER || tabInfo === TabsType.DELIVERY_ORDER,
      isArchived: tabInfo === TabsType.MASTERS_DELETED || tabInfo === TabsType.MASTERS_DELETED,
      displayRequestedDeliveryDate: tabInfo === TabsType.PENDING,
      isFCL: isFCLMode,
      isCanCreateCarrier: isCanCreateCarrier
    };
  }

  public buildOrderColumnsByTab(tabInfo: TabInfo, isCanCreateCarrier: boolean): BaseColumn[] {
    let hasBillingApproveRole = this.authService.hasBillingApprove();
    let items = tabInfo.statuses ? MasterStatus.items.filter(item => tabInfo.statuses.includes(item.id)) : [];
    let config = this.buildOrderConfig(tabInfo, items, hasBillingApproveRole, [], isCanCreateCarrier);
    return this.createOrderDefaultColumns(config);
  }


  public openManifestFromMasterList(row) {
    if (!isNullOrUndefined(row.shipment)) {
      this.shipmentFromMasterList$.next(row.shipment.id);
    }
  }
  public openManifestFromMasterEdit(row) {
    if (!isNullOrUndefined(row.shipment)) {
      this.shipmentFromMasterEdit$.next(row.shipment.id);
    }
  }
}


export interface OrderColumnsConfig {
  displayJjsLfd: boolean;
  displayFF: boolean;
  jjsToOrderEditor: boolean;
  displayCfsIn: boolean;
  displayCfsLfd: boolean;
  displayCfsOut: boolean;
  displayDelivery: boolean;
  displayCarrier: boolean;
  isCanCreateCarrier: boolean;
  displayDriver: boolean;
  displayTruckAndTrailer: boolean;
  displayDateBilled: boolean;
  displayDO: boolean;
  displayPO: boolean;
  displayRequestedDeliveryDate: boolean;
  canChangeDateBilled: boolean;
  isActiveTab: boolean;
  isArchived: boolean;
  statuses: MasterStatus[];
  isFCL: boolean;
}

export interface MasterColumnsConfig {
  displayCfsLocation: boolean;
  displayPO: boolean;
  isCarrier: boolean;
  isCanCreateCarrier: boolean;
  isArchived: boolean;
  statuses: {
    available: MasterStatusId[] /*| ((any) => MasterStatus[] )*/;
    selected: MasterStatus[]
  };
}
