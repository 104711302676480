var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UldLinked } from "../models/uld-linked";
import { BaseEntityService } from "../../../services/base/base-entity.service";
import { RestService } from "../../../services/rest.service";
import { HttpUtilsService } from "../../../services/http-utils.service";
var UldLinkedService = /** @class */ (function (_super) {
    __extends(UldLinkedService, _super);
    function UldLinkedService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/ulds-linked/';
        _this.classType = UldLinked;
        return _this;
    }
    return UldLinkedService;
}(BaseEntityService));
export { UldLinkedService };
