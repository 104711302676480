var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AddressService } from '../../../services';
import { Address } from '../../../modules/shared/models';
import { AddressDialogComponent } from '../../../modules/shared/components/address-dialog';
import { MatDialog } from '@angular/material';
import { asArray } from '../../../_helpers/utils';
import { isNullOrUndefined } from 'util';
import { OmsDialogsService } from '../oms-dialogs';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { Size } from '../../../common/oms-types';
import { BaseInputSelectComponent } from '../../../modules/shared/components/base/base-input/base-input-select.component';
import { NgModel } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgSelectSearchParams } from '../../../modules/settings/util/ng-select-search-params';
import { convertAddress } from "../../../modules/shared/services/oms-converters.service";
import { FilterSearchColumn } from '../../../modules/shared/models/filter.search.column';
import { Logger } from "../../../_helpers/logger";
import { KeyCode } from "../../../modules/shared/components/common/input/ng-select-types/ng-select.types";
var AddressSearchComponent = /** @class */ (function (_super) {
    __extends(AddressSearchComponent, _super);
    // @Input() customerFn: ()=>Customer;   //Specific for searching Customer Related addresses; Currently used for Delivery Locations
    function AddressSearchComponent(validators, asyncValidators, addressService, dialog, alerts, dialogs) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.addressService = addressService;
        _this.dialog = dialog;
        _this.alerts = alerts;
        _this.dialogs = dialogs;
        _this.separateSearch = false; // experimental
        _this.size = Size.DEFAULT;
        _this.addressChange = new EventEmitter();
        _this.allowCreate = true;
        _this.allowClear = true;
        _this.showTooltip = true;
        _this.isInvalid = false;
        _this.identifier = 'address_search_' + AddressSearchComponent_1.initial_id++;
        _this.Size = Size;
        _this.showAllAddresses = false;
        _this.iconClass = 'fa fa-map-marker';
        _this.addressService.addressPopUpEvent.subscribe(function (data) {
        });
        return _this;
    }
    AddressSearchComponent_1 = AddressSearchComponent;
    AddressSearchComponent.prototype.hideAddressSearch = function () {
        this.addressService.hideAddressPopUp();
    };
    AddressSearchComponent.prototype.isControlInvalid = function () {
        // const control = this.form.controls[controlName];
        // const result = control.invalid && control.touched;
        return false;
    };
    AddressSearchComponent.prototype.onOpen = function () {
        var _this = this;
        if (this.separateSearch) {
            setTimeout(function () { _this.searchInput.nativeElement.focus(); }, 10);
        }
    };
    AddressSearchComponent.prototype.openCreateAddressDialog = function () {
        var _this = this;
        this.ngSelect.close();
        var address = new Address();
        address.types = this.addressTypes;
        var dialogRef = this.dialog.open(AddressDialogComponent, {
            width: 'auto',
            data: { address: address, disabledTypes: this.addressTypes }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res) {
                _this.address = res.res;
                _this.changeAddress(res.res);
            }
        });
    };
    AddressSearchComponent.prototype.changeAddress = function (address) {
        this.isOpen = false;
        this.addressChange.emit(address);
    };
    AddressSearchComponent.prototype.ngOnInit = function () {
        this.addressSearch = new NgSelectSearchParams(this.addressService, 'name');
        this.addressSearch.setOperation('or');
        this.addressSearch.setSearchIsActive(!this.showAllAddresses);
        this.addressTypes = asArray(this.addressTypes);
        this.updateSearchByAddressType();
    };
    Object.defineProperty(AddressSearchComponent.prototype, "allowShowAll", {
        get: function () {
            return !isNullOrUndefined(this.addressTypes);
        },
        enumerable: true,
        configurable: true
    });
    AddressSearchComponent.prototype.search = function () {
    };
    AddressSearchComponent.prototype.ngOnChanges = function (changes) {
        if (changes.count) {
            this.addressSearch.request.pageSize = this.count;
        }
        if (changes.showAllAddresses) {
            this.addressSearch.setSearchIsActive(!this.showAllAddresses);
        }
        if (changes.addressTypes) {
            this.updateSearchByAddressType();
        }
    };
    AddressSearchComponent.prototype.updateSearchByAddressType = function () {
        var _this = this;
        if (!this.addressSearch) {
            return;
        }
        this.addressSearch.request.filterByColumns = (this.addressTypes || []).map(function (type) {
            return new FilterSearchColumn(_this.getFieldName(type), 'true');
        });
    };
    AddressSearchComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    AddressSearchComponent.prototype.getState = function (address) {
        if (isNullOrUndefined(address.usaState)) {
            return "";
        }
        return ", " + address.usaState.name;
    };
    AddressSearchComponent.prototype.getWithComa = function (data) {
        return isNullOrUndefined(data) ? "" : ", " + data;
    };
    AddressSearchComponent.prototype.getFieldName = function (num) {
        var arr = ['cargoBuilding', 'cfsLocation', 'cfs3plLocation', 'pickupLocation', 'deliveryLocation'];
        return arr[num];
    };
    AddressSearchComponent.prototype.getTooltipValue = function (address) {
        return this.showTooltip ? convertAddress(address) : null;
    };
    AddressSearchComponent.prototype.ngOnDestroy = function () {
        this.addressSearch.destroy();
    };
    AddressSearchComponent.prototype.onKeyDown = function (event) {
        if (event.keyCode === KeyCode.Tab) {
            if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value) {
                this.addressChange.emit(this.ngSelect.itemsList.markedItem.value);
            }
            this.ngSelect.filterValue = null;
            this.isOpen = false;
        }
    };
    var AddressSearchComponent_1;
    AddressSearchComponent.initial_id = 0;
    AddressSearchComponent = AddressSearchComponent_1 = __decorate([
        Logger({}),
        __metadata("design:paramtypes", [Array,
            Array,
            AddressService,
            MatDialog,
            OmsAlertsService,
            OmsDialogsService])
    ], AddressSearchComponent);
    return AddressSearchComponent;
}(BaseInputSelectComponent));
export { AddressSearchComponent };
