var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Address, Driver, Load, Order, Trailer, Truck, User } from '..';
import { BaseEntity } from '../base-entity';
import { Exclude, Type } from 'class-transformer';
import { OrderPart } from "./order-part";
import { convertManifestNumber, convertMasterNumber, convertMawbNumber } from "../../services/oms-converters.service";
import { Carrier } from "../carrier";
import { absent } from "../../../../_helpers/utils";
import { Status } from "./status";
var ɵ0 = function () { return Address; }, ɵ1 = function () { return OrderPart; }, ɵ2 = function () { return Date; }, ɵ3 = function () { return User; }, ɵ4 = function () { return Load; }, ɵ5 = function () { return Carrier; }, ɵ6 = function () { return Carrier; }, ɵ7 = function () { return Trailer; }, ɵ8 = function () { return Driver; };
var Receiving = /** @class */ (function (_super) {
    __extends(Receiving, _super);
    function Receiving(master) {
        var _this = _super.call(this) || this;
        _this.parts = [];
        if (master) {
            _this.masterId = master.id;
            _this.mawb = master.mawbNumber;
            _this.fillReceiving(master);
        }
        return _this;
    }
    Receiving.buildReceiving = function (master) {
        var receiving = new Receiving();
        receiving.fillReceiving(master);
        return receiving;
    };
    Receiving.buildReceivingWarehouse = function (orders) {
        var receiving = new Receiving();
        receiving.fillWarehouseReceiving(orders);
        console.log('RECEIVING', receiving);
        return receiving;
    };
    Receiving.prototype.fromOrderWarehouse = function (o, masterReceiving) {
        var _this = this;
        var part = new OrderPart();
        part.order = {
            id: o.id,
            hawb: o.hawb,
            pieces: o.pieces,
            hu: o.hu,
            ref3: o.ref3,
            customerRef: o.customerRef,
            purchaseOrder: o.purchaseOrder,
            addressFrom: o.addressRouteFrom,
            addressTo: o.addressDelivery,
            mode: o.genericMode
        };
        part.masterReceiving = masterReceiving;
        part.masterId = o.masterId;
        part.loadId = o.routeInLoadId;
        //    console.warn(">>>>", o.pcsReceived, o.huReceived, o.id);
        part.pieces = part.pcsProcessed = o.pcsReceived || 0;
        part.hu = part.huProcessed = o.huReceived || 0;
        part.open = true;
        //    part.weightProcessed = o.weight;
        part.orderNumber = o.routeInOrderNumber;
        part.address = o.addressWhse;
        part.status = o.receivingStatus; // */ o.routeInProblem ? Status.NOT_COMPLETED : (o.pcsReceived >= o.pieces ? Status.COMPLETED : Status.UNDEFINED);
        part.routeProblem = o.routeInProblem;
        part.sealNumber = o.routeInSealNumber;
        part.dispatchId = o.routeInId;
        part.shipmentId = o.routeInShipmentId;
        if (o.routeIn) {
            this.sealNumber = o.routeInSealNumber;
            this.driver = o.routeIn.driver;
            this.carrier = o.routeIn.carrier;
            this.truck = o.routeIn.truck;
            this.trailer = o.routeIn.trailer;
        }
        if (o.receiving && o.receiving.length) {
            part.receivings = [];
            o.receiving.forEach(function (ow) {
                ow.routeInProblem = o.routeInProblem;
                part.receivings.push(_this.fromOrderWarehouse(ow, true));
            });
        }
        console.log('PREPARED', part, "OF", o);
        return part;
    };
    Object.defineProperty(Receiving.prototype, "modified", {
        get: function () {
            return this.parts.some(function (part) { return part.modified; });
        },
        enumerable: true,
        configurable: true
    });
    Receiving.prototype.getTotalPiecesProcessed = function () {
        return this.parts.aggregate(function (acc, rp) { return acc + ~~rp.pcsProcessed; }, 0);
    };
    Receiving.prototype.getTotalHuProcessed = function () {
        return this.parts.aggregate(function (acc, rp) { return acc + ~~rp.huProcessed; }, 0);
    };
    Receiving.prototype.getOrderPart = function (order) {
        return this.parts.find(function (part) { return part.order.id === order.id; });
    };
    Receiving.prototype.fillReceiving = function (master) {
        var _this = this;
        master.orders.forEach(function (o) {
            var part = new OrderPart();
            part.masterId = master.id;
            part.masterReceiving = true;
            part.open = true;
            part.address = o.addressCfs;
            part.order = {
                id: o.id,
                hawb: o.hawb,
                pieces: o.pieces,
                hu: o.hu,
                customerRef: o.customerRef,
                purchaseOrder: o.purchaseOrder,
                ref3: o.ref3,
                addressFrom: o.addressCfs,
                addressTo: o.addressDelivery,
                mode: o.genericMode
            };
            part.pieces = part.pcsProcessed = o.pcsReceived || 0;
            part.hu = part.huProcessed = o.huReceived || 0;
            part.status = o.pieces > 0 && o.pieces <= o.pcsReceived ? Status.COMPLETED : Status.UNDEFINED;
            _this.parts.push(part);
        });
    };
    Receiving.prototype.addPart = function (o) {
        var part = this.fromOrderWarehouse(o, absent(o.routeInId));
        console.log("ADDING PART", part);
        this.parts.push(part);
        return part;
    };
    Receiving.prototype.fillWarehouseReceiving = function (orders) {
        var _this = this;
        orders.forEach(function (o) { return _this.addPart(o); });
    };
    Object.defineProperty(Receiving.prototype, "label", {
        get: function () {
            if (this.routeId) {
                return convertManifestNumber(this.routeId);
            }
            if (this.masterId) {
                return this.mawb ? 'MAWB#:' + convertMawbNumber(this.mawb) : convertMasterNumber(this.masterId);
            }
            return 'N/A';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Receiving.prototype, "isOwnCarrier", {
        get: function () {
            return !this.carrier || this.carrier.isOwnCarrier;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Address)
    ], Receiving.prototype, "address", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Array)
    ], Receiving.prototype, "parts", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Date)
    ], Receiving.prototype, "date", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", User)
    ], Receiving.prototype, "user", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", Load)
    ], Receiving.prototype, "load", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Carrier)
    ], Receiving.prototype, "carrier", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Truck)
    ], Receiving.prototype, "truck", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", Trailer)
    ], Receiving.prototype, "trailer", void 0);
    __decorate([
        Type(ɵ8),
        __metadata("design:type", Driver)
    ], Receiving.prototype, "driver", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Receiving.prototype, "modified", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Order]),
        __metadata("design:returntype", OrderPart)
    ], Receiving.prototype, "getOrderPart", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Receiving.prototype, "label", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Receiving.prototype, "isOwnCarrier", null);
    return Receiving;
}(BaseEntity));
export { Receiving };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
