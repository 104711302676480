import {BaseDeletable} from './base-deletable';
import {Type} from 'class-transformer';
import {Driver} from "./driver";
import {User} from "./user";

export class ChatRecord extends BaseDeletable {
  text: string;
  @Type(() => Driver)
  driver: Driver;
  @Type(() => User)
  sendByUser: User;
  @Type(() => Driver)
  sendByDriver: Driver;
  @Type(() => Date)
  driverAcknowledge: Date;
  @Type(() => Date)
  jjsUserAcknowledge: Date;
}
