﻿<div class="top-bar navbar-fixed-top">
  <div class="container">
    <div class="clearfix" style="text-align: center; min-height: 37px;"><!--style="display: flex; height: 33px;"-->

      <!-- navbar-toggle -->
      <button type="button" class="btn btn-default navbar-toggle collapsed" style="margin: auto;"
              data-toggle="collapse" data-target="#main-navbar-collapse" aria-expanded="false">
        <i class="fa fa-chevron-circle-down"></i>
      </button>

      <a id="mainMenuButton" (click)="toggleLeftSideBar()" class="pull-left toggle-sidebar-collapse" *ifRole="'ROLE_ADMIN'">
        <i class="fa fa-bars"></i></a>

      <div class="top-bar-left wide-screen-only">

        <a [routerLink]="['../']" type="button" class="btn btn-default navbar-toggle collapsed" style="margin: auto;"
                *ngIf="navigationService.narrowScreen">
          <i class="fa fa-home"></i>
        </a>

        <ng-content select="[main-toolbar]"></ng-content>

        <ol class="breadcrumb" *ngIf="!navigationService.narrowScreen">
          <li *ngFor="let breadcrumb of breadcrumbs; let first=first; let last = last;" [ngClass]="{'active': last}">
            <a [routerLink]="['/home']" *ngIf="first">
              <i class="fa fa-home" ></i>
            </a>
            <a [routerLink]="[breadcrumb.url, breadcrumb.params]">
              {{breadcrumb.label}}&nbsp;
            </a>
          </li>
        </ol>

      </div>


      <div class="logo">
        <div class="main-header" style="margin: 0 auto; display: flex;">
          <h2>JJS</h2>
          <em>OMS</em>
        </div>
      </div>

      <div class="navbar-collapse collapse" id="main-navbar-collapse" style="margin-left:auto;">
        <ul class="nav navbar-nav">

          <!--Dropdown menu item example
          <li class="dropdown">
            <a href="#" class="btn btn-link dropdown-toggle" data-toggle="dropdown"><i class="fa fa-dashboard fa-fw"></i>Dashboard <i class="fa fa-caret-down"></i></a>
            <ul class="dropdown-menu">
              <li><a href="index.html">Dashboard v1</a></li>
              <li><a href="index-dashboard-v2.html">Dashboard v2</a></li>
              <li><a href="index-dashboard-v3.html">Dashboard v3</a></li>
            </ul>
          </li>
          -->


          <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
            <a class="btn btn-link" [routerLink]="'/home/masters'">
              <span>AI</span>
            </a>
          </li>

          <!--ng-container *restricted>
            <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
              <a class="btn btn-link" [routerLink]="'/air-export/list'">
                <span>AE-OLD</span>
              </a>
            </li>
          </ng-container-->

          <ng-container *restricted>
            <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
              <a class="btn btn-link" [routerLink]="'/dispatch/air-export'">
                <span>AE</span>
              </a>
            </li>
          </ng-container>


          <ng-container *restricted>
            <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
              <a class="btn btn-link" [routerLink]="'/fcl/list'">
                <span>FCL</span>
              </a>
            </li>
          </ng-container>

          <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
            <a class="btn btn-link" [routerLink]="'/dispatch/shipments'" >
              <span>Dispatch</span>
            </a>
          </li>

          <ng-container *restricted>
            <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
              <a class="btn btn-link" [routerLink]="'/home/order-list'" >
                <span>Orders</span>
              </a>
            </li>
          </ng-container>

          <ng-container *restricted>
            <li *ifRole="['ROLE_WAREHOUSE']">
              <a class="btn btn-link" [routerLink]="'/warehouse/warehouse-list'" >
                <span>Warehouse</span>
              </a>
            </li>
          </ng-container>


          <ng-container *ifRole="'ROLE_ADMIN'">
            <li *ifRole="'ROLE_QA'">
              <a class="btn btn-link" [routerLink]="'/dispatch/planning'" >
                <span>Planning</span>
              </a>
            </li>
          </ng-container>

          <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
            <a class="btn btn-link" [routerLink]="'/ams/status-list'" target="_blank">
              <span>AMS</span>
            </a>
          </li>

          <ng-container *restricted>
            <li *ifRole="['ROLE_ADMIN']">
              <a class="btn btn-link" [routerLink]="'/ams/master-fsr-list'" target="_blank">
                <span>FSR</span>
              </a>
            </li>
          </ng-container>

          <li *ifRole="['ROLE_ADMIN', 'ROLE_USER']">
            <a class="btn btn-link" [routerLink]="'/dispatch/driver-routes'" target="_blank">
              <span>OFD</span>
            </a>
          </li>

          <ng-container *restricted>
            <li *ifRole="['ROLE_ADMIN', 'ROLE_USER', 'ROLE_WAREHOUSE']">
              <a class="btn btn-link" [routerLink]="'/warehouse/multiple-order'" >
                <span>Multiple Order</span>
              </a>
            </li>
          </ng-container>
        </ul>

        <div class="pull-right right" >
          <div class="top-bar-right less-wide-screen-only">
            <a class="btn btn-link" (click)="authService.logOut()">
              <i class="fas fa-power-off" style="font-weight: bold; color: #ff5800; font-size: 18px;"></i>
              <span class="text" style="color: white;">&emsp;Logout</span>
            </a>
          </div>

          <!-- search box -->
          <!--div class="searchbox">
            <div id="tour-searchbox" class="input-group">
              <input type="search" class="form-control" placeholder="Search">
              <span class="input-group-btn">
                    <button class="btn btn-default" type="button"><i class="fa fa-search"></i></button>
                  </span>
            </div>
          </div-->
          <!-- end search box -->

          <!-- top-bar-right -->
          <div class="top-bar-right">


            <div class="notifications">
              <ul>
                <li class="notification-item inbox">
                  <div class="btn-group">
                    <a class="btn btn-link" (click)="copyUrl()" tooltip="Copy URL to Clipboard" placement="left">
                      <i class="fa fa-clipboard"></i>
                    </a>

                    <a *ifRole="['ROLE_ADMIN', 'ROLE_USER']"
                       class="btn btn-link" [routerLink]="'/ams/uld-list'" target="_blank" tooltip="ULD" placement="left">
                      <i class="fa fa-box-open"></i>
                    </a>
                  </div>
                </li>

                <li class="notification-item inbox">
                  <div class="btn-group">


                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                      <i class="fa fa-user"></i><!--span class="count">2</span-->
                      <span class="wide-screen-only">&ensp;{{currentUser?.fullName}}&ensp;</span>
                      <span class="caret"></span>
                      <!--span class="circle"></span-->
                    </a>
                    <ul class="dropdown-menu" role="menu" style="background-color: #3a5b5b !important;">
                      <li class="notification-header">
                        <em>{{currentUser?.email}}</em>
                      </li>
                      <li><a href="#"><i class="fa fa-user"></i><span class="text">&emsp;Profile</span></a></li>
                      <!-- li><a routerLink="/settings"><i class="fa fa-cog"></i><span class="text">&emsp;Settings</span></a></li-->

                      <li>
                        <a (click)="timeFormat.onChange(!timeFormat.value)">
                          <i class="fas fa-clock"></i>
                          <span class="text">&emsp;Time Format:&emsp;</span>
                          <oms-checkbox #timeFormat style="float: right;"
                                        [checked]="userPreferences.getTimeFormat12() | async"
                                        (checkedChange)="userPreferences.setTimeFormat12($event)"
                                        [label]="userPreferences.getTimeFormatLabel()">
                          </oms-checkbox>
                        </a>
                      </li>

                      <li><a (click)="authService.logOut()">
                        <i class="fas fa-power-off" style="font-weight: bold; color: #ff5800; font-size: 18px;"></i>
                        <span class="text">&emsp;Logout</span></a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>


          </div>
          <!-- end top-bar-right -->

        </div>
      </div>

    </div>
  </div>
  <!-- /container -->
</div>
