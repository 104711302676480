var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject, Subject } from 'rxjs';
import { Address, AddressType, Customer } from '../models';
import { HttpUtilsService } from '../../../services/http-utils.service';
import { RestService } from '../../../services/rest.service';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { plainToClass } from 'class-transformer';
import { OmsAlertsService } from '../components/oms-alerts/oms-alerts.service';
import { BaseEntityService } from '../../../services/base/base-entity.service';
import { AddressSearchService } from './address-search.service';
import { DialogType, ModalResult, OmsDialogsService } from "../../../components/common/oms-dialogs";
import * as i0 from "@angular/core";
import * as i1 from "../../../services/rest.service";
import * as i2 from "../../../services/http-utils.service";
import * as i3 from "../../../components/common/oms-dialogs/oms-dialogs.service";
import * as i4 from "../components/oms-alerts/oms-alerts.service";
var AddressService = /** @class */ (function (_super) {
    __extends(AddressService, _super);
    function AddressService(httpRestService, httpUtils, dialogs, alerts) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.dialogs = dialogs;
        _this.alerts = alerts;
        _this.apiUrl = '/oms/address/';
        _this.classType = Address;
        _this.addressPopUpEvent = new Subject();
        _this.addressesSubject = new BehaviorSubject([]);
        _this.customerDeliveryLocations = new Map();
        return _this;
    }
    AddressService.prototype.hideAddressPopUp = function () {
        this.addressPopUpEvent.next({
            show: false,
            address: null
        });
    };
    AddressService.prototype.getAllAddresses = function () {
        var _this = this;
        this.httpRestService.get(this.apiUrl + 'getAllAddresses').subscribe(function (items) {
            _this.addressesSubject.next(plainToClass(Address, items));
        });
    };
    AddressService.prototype.getByType = function (search, types, count) {
        var params = new HttpParams()
            .set('search', search)
            .set('count', !isNullOrUndefined(count) ? count.toString() : '');
        return this.httpRestService.put(this.apiUrl + 'searchByType', types, this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    AddressService.prototype.setAddressTypes = function (addressId, types) {
        var params = new HttpParams()
            .set('addressId', addressId.toString());
        return this.httpRestService.put(this.apiUrl + 'setAddressTypes', types, this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    AddressService.prototype.getAirportWarehouse = function (airportId, airlineId) {
        var params = new HttpParams()
            .set('airport', airportId.toString())
            .set('airline', airlineId.toString());
        var headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
        return this.httpRestService.getWithOptions(this.apiUrl + 'searchAirportWarehouse', headers, params)
            .pipe(map(function (a) { return plainToClass(Address, a); }))
            .toPromise();
    };
    AddressService.prototype.updateAddressTypes = function (address, types) {
        var _this = this;
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm', 'Selected Address does not match requested type (' + Address.addressDescription(types) + ').\nWould you like to update selected address?', [{ caption: "Update", result: ModalResult.YES, class: 'btn-primary' },
            { caption: "Cancel", result: ModalResult.CANCEL, class: 'btn-danger' }])
            .then(function (result) {
            if (result.result === ModalResult.YES) {
                _this.setAddressTypes(address.id, types).
                    then(function () { return _this.alerts.info("Selected Address has updated as " + Address.addressDescription(types), 2000); });
            }
        });
    };
    AddressService.prototype.getNewSearchService = function (types, count) {
        return new AddressSearchService(this, types, count);
    };
    AddressService.prototype.getCustomerDeliveryLocations = function (customer) {
        var _this = this;
        var addresses = !customer || customer.isNew() ? [] : this.customerDeliveryLocations.get(customer.id);
        if (addresses) {
            //Found local, don't query server
            return new Promise(function (resolve) { resolve(addresses); });
        }
        else {
            //Not found, query server for list
            var params = new HttpParams().set('customerId', customer.id.toString());
            return this.httpRestService.put(this.apiUrl + 'getCustomerDeliveryLocations', null, this.httpUtils.getHTTPHeaders(), params).toPromise()
                //chaining storing received array into the local map
                .then(function (array) {
                var addresses = plainToClass(Address, array);
                //Store received address array to local map
                _this.customerDeliveryLocations.set(customer.id, addresses);
                return addresses;
            });
        }
    };
    AddressService.prototype.setCustomerDeliveryLocation = function (customer, address) {
        if (customer && address) {
            var addresses_1 = this.customerDeliveryLocations.get(customer.id);
            if (!addresses_1) {
                addresses_1 = [];
                this.customerDeliveryLocations.set(customer.id, addresses_1);
            }
            if (!addresses_1.hasEquals(address)) {
                var params = new HttpParams()
                    .set('customerId', customer.id.toString())
                    .set('addressId', address.id.toString());
                return this.httpRestService.put(this.apiUrl + 'setCustomerDeliveryLocation', null, this.httpUtils.getHTTPHeaders(), params)
                    .subscribe(function () { return addresses_1.push(address); });
            }
        }
    };
    AddressService.prototype.deleteCustomerDeliveryLocation = function (customer, address) {
        if (customer && !customer.isNew()) {
            var addresses = this.customerDeliveryLocations.get(customer.id);
            if (addresses && addresses.length > 0)
                addresses.removeAll(address);
            var params = new HttpParams()
                .set('customerId', customer.id.toString())
                .set('addressId', address.id.toString());
            return this.httpRestService.put(this.apiUrl + 'deleteCustomerDeliveryLocation', null, this.httpUtils.getHTTPHeaders(), params)
                .subscribe(function () { });
        }
    };
    AddressService.prototype.getCfsLocationsByAddress = function (addressCfsId) {
        var params = new HttpParams()
            .set('addressCfsId', addressCfsId.toString());
        return this.httpRestService.getWithOptions(this.apiUrl + 'getCfsLocationsByAddress', this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    AddressService.prototype.fillStateAndCity = function () {
        return this.httpRestService.get(this.apiUrl + 'fillStateAndCity');
    };
    AddressService.ngInjectableDef = i0.defineInjectable({ factory: function AddressService_Factory() { return new AddressService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService), i0.inject(i3.OmsDialogsService), i0.inject(i4.OmsAlertsService)); }, token: AddressService, providedIn: "root" });
    return AddressService;
}(BaseEntityService));
export { AddressService };
