var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Logger } from "../../../../../../../_helpers/logger";
import { take, takeUntil } from "rxjs/operators";
import { DataTableComponent } from "../../data-table.component";
import { GenericTableCell } from "../mawb-table-cell/generic-table-cell";
var EditingTableCellComponent = /** @class */ (function (_super) {
    __extends(EditingTableCellComponent, _super);
    function EditingTableCellComponent(table, cdr) {
        var _this = _super.call(this) || this;
        _this.table = table;
        _this.cdr = cdr;
        return _this;
    }
    EditingTableCellComponent.prototype.ngOnChanges = function (changes) {
        if (changes.row || changes.column) {
            this.updateObject();
        }
    };
    EditingTableCellComponent.prototype.updateObject = function () {
        _super.prototype.updateObject.call(this);
        this.rowObject = this.column.rowObject(this.row);
        this.rawValue = this.column.getValue(this.rowObject);
        this.value = this.column.convert(this.rowObject, this.rawValue);
        this.localValue = this.value;
        this.cdr.markForCheck();
    };
    EditingTableCellComponent.prototype.ngOnInit = function () {
        this.updateObject();
    };
    EditingTableCellComponent.prototype.editError = function (error) {
        console.log('Error', error);
        this.error = error;
        this.table.editErrorEvent.emit(error);
    };
    EditingTableCellComponent.prototype.doneEdit = function () {
        var _this = this;
        console.log('Done Edit:', this.localValue);
        try {
            var newValue = this.convertBack(this.localValue);
            this.table.updateColumnValue(this.rowObject, this.column, newValue, this.column.field)
                .pipe(takeUntil(this.destroy$))
                .pipe(take(1))
                .subscribe(function (object) {
                _this.row = object;
                _this.updateObject();
            });
            this.error = undefined;
        }
        catch (error) {
            this.editError(error);
        }
        finally {
            this.cdr.markForCheck();
        }
    };
    EditingTableCellComponent.prototype.onKeyDown = function (event) {
        if (event.key === 'Enter') {
            this.doneEdit();
            event.preventDefault();
        }
        else if (event.key === 'Escape') {
            event.preventDefault();
        }
    };
    EditingTableCellComponent.prototype.onBlurEditor = function () {
        if (this.localValue !== this.value) {
            this.doneEdit();
        }
    };
    EditingTableCellComponent.prototype.convertBack = function (value) {
        return this.column.convertBack(value);
    };
    EditingTableCellComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        delete this.table;
    };
    EditingTableCellComponent = __decorate([
        Logger({
            name: 'EditingTableCellComponent'
        }),
        __metadata("design:paramtypes", [DataTableComponent,
            ChangeDetectorRef])
    ], EditingTableCellComponent);
    return EditingTableCellComponent;
}(GenericTableCell));
export { EditingTableCellComponent };
