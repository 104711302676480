﻿import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services';

@Injectable()
export class RedirectRoleAuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let redirects = route.data.redirects as RedirectConfig[];
    let currentUser = this.authService.getCurrentUser();
    if (currentUser) {
      for (let i = 0; i < redirects.length; i++) {
        if (currentUser.hasAnyRoles(redirects[i].roles)) {
          this.router.navigate([redirects[i].redirectTo]);
          return true;
        }
      }
      this.router.navigate(['/home']);
      return true;
    }

    this.router.navigate(['/login']);
    return true;
  }
}

interface RedirectConfig {
  redirectTo: string;
  roles: string[];
}
