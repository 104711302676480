var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OmsConstants, WEIGHT_DECIMAL_DIGITS } from '../../../common/oms-constants.service';
import { isNullOrUndefined } from 'util';
import { getPendingStatus, PendingStatus } from '../../../common/oms-date-time.utils';
import { equals, isEmptyString, toFixed } from '../../../_helpers/utils';
import * as moment from "moment-timezone";
import { Address, LoadTypes, MasterStatus, Order, Split } from '../models';
import { DispatchLoadType } from "../models/load/dispatch-load-type";
import { OrderModes } from "../models/order/order-mode";
import { NumberUtils } from '../../../_helpers/number.utils';
import { WeightUnits } from "../../../common/oms-unit-types";
import { Action } from "../../../common/oms-types";
import { UserUtil } from "../../settings/util/user.util";
import { UserRoleType } from "../models/user-role.type";
import { DomSanitizer } from "@angular/platform-browser";
import * as i0 from "@angular/core";
var MawbNumberPipe = /** @class */ (function () {
    function MawbNumberPipe() {
    }
    MawbNumberPipe.prototype.transform = function (value, args) {
        return convertMawbNumber(value);
    };
    return MawbNumberPipe;
}());
export { MawbNumberPipe };
var MasterNumberPipe = /** @class */ (function () {
    function MasterNumberPipe() {
    }
    MasterNumberPipe.prototype.transform = function (value, args) {
        return convertMasterNumber(value);
    };
    return MasterNumberPipe;
}());
export { MasterNumberPipe };
/*@Pipe({name: 'master_link'})
export class MasterLinkPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return getMasterLink(value);
  }
}*/
/***
 * arguments:
 * isRecovery: Replace J with R
 * ifEmpty: display value if empty order id
 */
var OrderNumberPipe = /** @class */ (function () {
    function OrderNumberPipe() {
    }
    OrderNumberPipe.prototype.transform = function (value, args) {
        return !value && args && args.ifEmpty ? args.ifEmpty : convertOrderNumber(value, args && args.isRecovery);
    };
    return OrderNumberPipe;
}());
export { OrderNumberPipe };
var SafeHtmlPipe = /** @class */ (function () {
    function SafeHtmlPipe(sanitized) {
        this.sanitized = sanitized;
    }
    SafeHtmlPipe.prototype.transform = function (value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    };
    return SafeHtmlPipe;
}());
export { SafeHtmlPipe };
var SafeUrlPipe = /** @class */ (function () {
    function SafeUrlPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SafeUrlPipe.prototype.transform = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    return SafeUrlPipe;
}());
export { SafeUrlPipe };
var OmsDatePipe = /** @class */ (function (_super) {
    __extends(OmsDatePipe, _super);
    function OmsDatePipe() {
        return _super.call(this, 'en-US') || this;
    }
    OmsDatePipe.prototype.transform = function (value, args) {
        if (isNullOrUndefined(value)) {
            return null;
        }
        if (args && args.format) {
            return moment.tz(value, OmsConstants.ETC_ZONE).format(args.format);
        }
        return args && args.time ?
            moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_FORMAT) :
            moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT);
    };
    return OmsDatePipe;
}(DatePipe));
export { OmsDatePipe };
var OmsDateTimePipe = /** @class */ (function (_super) {
    __extends(OmsDateTimePipe, _super);
    function OmsDateTimePipe() {
        return _super.call(this, 'en-US') || this;
    }
    OmsDateTimePipe.prototype.transform = function (value, args) {
        if (isNullOrUndefined(value)) {
            return null;
        }
        return moment.tz(value, OmsConstants.ETC_ZONE).format(args && args.showYear ? OmsConstants.MOMENT_DATE_YEAR_TIME_FORMAT :
            OmsConstants.MOMENT_DATE_TIME_FORMAT);
    };
    return OmsDateTimePipe;
}(DatePipe));
export { OmsDateTimePipe };
var OmsDateTimeZonePipe = /** @class */ (function (_super) {
    __extends(OmsDateTimeZonePipe, _super);
    function OmsDateTimeZonePipe() {
        return _super.call(this, 'en-US') || this;
    }
    OmsDateTimeZonePipe.prototype.transform = function (value, args) {
        if (isNullOrUndefined(value)) {
            return null;
        }
        return moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_ZONE_FORMAT);
    };
    return OmsDateTimeZonePipe;
}(DatePipe));
export { OmsDateTimeZonePipe };
var OmsWeightPipe = /** @class */ (function (_super) {
    __extends(OmsWeightPipe, _super);
    function OmsWeightPipe() {
        return _super.call(this, 'en-US') || this;
    }
    OmsWeightPipe.prototype.transform = function (value, args) {
        if (isNullOrUndefined(value)) {
            return "";
        }
        return convertWeight(value);
    };
    return OmsWeightPipe;
}(DatePipe));
export { OmsWeightPipe };
var HighlightSearch = /** @class */ (function () {
    function HighlightSearch() {
    }
    HighlightSearch.prototype.transform = function (value, searchText, rawValue) {
        /*    if (!args) {return value;}
            var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
            return value.replace(re, "<p>$&</p>"); */
        if (searchText && value) {
            value += "";
            var startIndex = value.toLowerCase().indexOf(searchText.toLowerCase());
            if (startIndex !== -1) {
                var endLength = searchText.length;
                var matchingString = value.substr(startIndex, endLength);
                return value.replace(matchingString, "<mark>" + matchingString + "</mark>");
            }
            var subValue = this.findSubValue(value, searchText, rawValue);
            if (subValue) {
                return value.replace(subValue, "<mark>" + subValue + "</mark>");
            }
        }
        return value;
    };
    HighlightSearch.prototype.findSubValue = function (value, searchText, rawValue) {
        if (!rawValue || typeof rawValue !== 'string') {
            return null;
        }
        var startIndex = rawValue.toLowerCase().indexOf(searchText.toLowerCase());
        if (startIndex !== -1) {
            var regStr = '';
            for (var i = 0; i < searchText.length - 1; i++) {
                regStr += searchText[i] + '[ -]?';
            }
            regStr += searchText[searchText.length - 1];
            var reg = new RegExp(regStr, 'mig');
            var match = value.match(reg);
            if (match && match.length) {
                return match[0];
            }
        }
        return null;
    };
    return HighlightSearch;
}());
export { HighlightSearch };
var insertAt = function (str, sub, pos) { return "" + str.slice(0, pos) + sub + str.slice(pos); };
var ɵ0 = insertAt;
var CNT_CHAR_VALUES = { A: 10, B: 12, C: 13, D: 14, E: 15, F: 16, G: 17, H: 18, I: 19, J: 20, K: 21, L: 23, M: 24,
    N: 25, O: 26, P: 27, Q: 28, R: 29, S: 30, T: 31, U: 32, V: 34, W: 35, X: 36, Y: 37, Z: 38 };
var POSITION_MULTIPLIER = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];
export function convertAction(action) {
    console.log('Action>', typeof action);
    if (isNullOrUndefined(action)) {
        console.log(1);
        return null;
    }
    switch (action) {
        case Action.CREATED:
            console.log(2);
            return 'Created';
        case Action.UPDATED: return 'Updated';
        case Action.DELETED: return 'Deleted';
    }
    console.log(3);
}
export function convertDate(date) {
    return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT) : null;
}
export function convertDateWithoutYear(date) {
    return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT_WITHOUT_YEAR) : null;
}
export function convertDateTimeWithYear(date) {
    return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_YEAR_TIME_FORMAT) : null;
}
export function convertDateTime(date) {
    return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_FORMAT) : null;
}
export function convertToStandardDateTime(date, ifEmpty) {
    return date ? moment.tz(date, OmsConstants.ETC_ZONE).format('YYYY-MM-DDTHH:mm:ss') : ifEmpty;
}
export function convertToStandardDateOnly(date, ifEmpty) {
    return date ? moment.tz(date, OmsConstants.ETC_ZONE).format('YYYY-MM-DDT00:00:00') : ifEmpty;
}
export function convertToLongDateTime(date, ifEmpty) {
    return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.LONG_DATE_TIME_FORMAT) : ifEmpty;
}
export function convertMawbNumber(input) {
    //  return validMAWB(input)  ? insertAt(insertAt(input, '-', 3), ' ', 8) : input;
    if (!input || /[a-zA-Z]/g.test(input)) {
        return input;
    }
    var test = input.replace(/[^\d+$]/g, '');
    if (test.length === 11 && /^\d+$/.test(test)) {
        return insertAt(insertAt(test, '-', 3), ' ', 8);
    }
    else {
        return input;
    }
}
export function convertSubString(object, field, start, end) {
    var value = safelyGetter(object, field, null);
    if (value) {
        return value.substring(start, end);
    }
    return 'N/A';
}
export function convertContainerNumber(input) {
    return input ? insertAt(insertAt(input, ' ', 4), '-', 11) : input;
}
export function convertBracketed(value) {
    return NumberUtils.isNumber(value) ? (value === 0 ? null : '(' + value + ')') : value;
}
export function convertMawbStatus(status) {
    return isNullOrUndefined(status) ? null : MasterStatus.getLabel(status);
}
export function convertMawbStatusShort(status) {
    return isNullOrUndefined(status) ? null : MasterStatus.getName(status);
}
export function convertMawbPublicStatus(status) {
    return isNullOrUndefined(status) ? null : MasterStatus.getPublicLabel(status);
}
export function convertDispatchLoadStatus(type) {
    return isNullOrUndefined(type) ? null : DispatchLoadType.getLabel(type);
}
export function addWeekdays(date, days) {
    var d = moment(date); // use a clone
    while (days > 0) {
        d = d.add(1, 'days');
        // decrease "days" only if it's a weekday.
        if (d.isoWeekday() !== 6 && d.isoWeekday() !== 7) {
            days -= 1;
        }
    }
    return d.toDate();
}
/*
export function convertKgToUnits(value: number, units: MeasureUnit, digits:number = 1) {
  return isNullOrUndefined(value) ? value : units.coeff * value);
}

export function convertUnitsToKg(value: number, units: MeasureUnit, digits:number = 1) {
  return isNullOrUndefined(value) ? value : myFixed(value / units.coeff, digits);
}
*/
// const round = (value, digits) => {return Math.round(value * 100) / 100};
export function convertWeight(value, digits, def) {
    if (digits === void 0) { digits = WEIGHT_DECIMAL_DIGITS; }
    return isNullOrUndefined(value) ? def : toFixed(value * OmsConstants.DEFAULT_WEIGHT_UNITS.coeff, digits).toString();
}
export function convertVolume(value, digits, def) {
    if (digits === void 0) { digits = 2; }
    return isNullOrUndefined(value) ? def : toFixed(value * OmsConstants.DEFAULT_VOLUME_UNITS.coeff, digits).toString();
}
export function convertAmount(value, def) {
    console.log('AMOUNT', value);
    return isNullOrUndefined(value) ? def : toFixed(value, 2).toString();
}
export function convertLoadType(value) {
    if (!value) {
        return null;
    }
    return LoadTypes.prefixOf(value);
    /*  switch (value) {
        case 'RECOVERY': return "RLD";
        case 'DELIVERY': return "DLD";
        case 'X-DOCK': return "XLD";
      } */
}
export function convertLoadTypeNumber(value, loadNumber) {
    if (!value) {
        return null;
    }
    switch (value) {
        case 'RECOVERY': return "RLD#" + loadNumber;
        case 'DELIVERY': return "DLD#" + loadNumber;
    }
}
export function padZeros(id, n) {
    return id ? (id + '').padStart(n, '0') : id;
}
export function convertOrderNumber(id, isRecovery) {
    var pad = "000000";
    return isNullOrUndefined(id) ? 'NEW' : (isRecovery ? 'R' : 'J') + (pad + id).slice(-pad.length);
}
export function convertRecoveryOrderNumber(id, item) {
    var pad = "000000";
    return isNullOrUndefined(id) ? (item instanceof Split ? item.label : 'RLD') : 'R' + (pad + id).slice(-pad.length);
}
export function convertLoadNumber(id) {
    var pad = "000000";
    return isNullOrUndefined(id) ? 'NEW LOAD' : 'LD' + (pad + id).slice(-pad.length);
}
export function convertMasterNumber(id) {
    var pad = "000000";
    return isNullOrUndefined(id) ? 'NEW MASTER' : 'M' + (pad + id).slice(-pad.length);
}
export function convertManifestNumber(id) {
    var pad = "00000";
    return isNullOrUndefined(id) ? null : 'RT#' + (pad + id).slice(-pad.length);
}
export function convertOrderRef3(mode, ref3) {
    return OrderModes.isAir(mode) || OrderModes.isRecovery(mode) ? convertMawbNumber(ref3) : ref3;
}
export function convertOrderRef2(mode, ref2) {
    return OrderModes.isContainerMode(mode) ? convertContainerNumber(ref2) : ref2;
}
export function getOrderEditorLink(orderId, relative) {
    if (relative === void 0) { relative = true; }
    return relative ?
        { path: ['order'], params: { id: orderId } } : // Relative to Current Path
        { path: ['/home', 'order'], params: { id: orderId } }; // for New Window
}
export function getOrderLink(order) {
    if (order instanceof Order) {
        var haveClientRole = UserUtil.getCurrentUser().hasAnyRoles([UserRoleType.ROLE_CLIENT]);
        if (haveClientRole) {
            return order && !order.isNew() ? { path: ['/client', 'orders', 'order'], params: { id: order.id } } : null;
        }
        return order && !order.isNew() ? { path: ['/home', 'order'], params: { id: order.id } } : null;
    }
    else {
        return order ? { path: ['/home', 'order'], params: { id: order } } : null;
    }
}
export function getMasterLink(masterId) {
    return masterId ? { path: ['/home', 'master'], params: { queryParams: { id: masterId } } } : null;
}
export function getPendingStatusClass(status) {
    if (!isNullOrUndefined(status)) {
        switch (status) {
            case PendingStatus.AT_RISK: return 'date-at-risk';
            case PendingStatus.PENDING: return 'date-pending';
            case PendingStatus.IMPENDING: return 'date-impending';
            case PendingStatus.PAST_DUE: return 'date-past-due';
        }
    }
    return null;
}
export function masterDateLastFreeDayClass(master) {
    // depends on Actual Pickup date
    if (isNullOrUndefined(master) || (!isNullOrUndefined(master.cargoBuilding) && master.cargoBuilding.cfs3plLocation) || !isNullOrUndefined(master.datePickupActual)) {
        return null;
    }
    return getPendingStatusClass(getPendingStatus(master.dateLastFreeDay));
}
export function master1CByAmsStatusesClass(master) {
    if (master.orders.every(function (order) { return order.holdStatus; })) {
        return "date-unchecked";
    }
    return master.orders.every(function (order) { return order.isPiecesInAMS; }) ? "fullInAms" : "";
}
export function order1FCByAmsStatusesClass(order) {
    return order.isPiecesInAMS ? "fullInAms" : "";
}
export function order1CByAmsStatusesClass(order) {
    if (!isNullOrUndefined(order.date1CUnchecked) || order.holdStatus) {
        return "date-unchecked";
    }
    return order.isPiecesInAMS ? "fullInAms" : "";
}
export function master1FByAmsStatusesClass(master) {
    var style = '';
    if (master.cbpStatusPiecesNotValid) {
        style += ' fullInAms';
    }
    if (master.cbpFsc10WithoutHawb) {
        style += ' background-yellow';
    }
    return style;
}
export function masterDatePickUpClass(master) {
    // depends on Actual Pickup date
    if (isNullOrUndefined(master) || isNullOrUndefined(master.cargoBuilding) || master.cargoBuilding.cfs3plLocation || isNullOrUndefined(master.datePickupActual)) {
        return null;
    }
    return getPendingStatusClass(getPendingStatus(master.datePickupActual));
}
export function masterDateCFSFreeDayClass(master) {
    // depends on Actual Pickup date
    if (isNullOrUndefined(master)) {
        return null;
    }
    return getPendingStatusClass(getPendingStatus(master.dateCfsFreeTime));
}
export function orderDateCFSFreeDayClass(order) {
    // depends on Actual Pickup date
    if (isNullOrUndefined(order)) {
        return null;
    }
    return getPendingStatusClass(getPendingStatus(order.dateCfsFreeTime));
}
export function validMAWB(mawb, onInvalid) {
    var invalid = getInvalidMAWBMessage(mawb);
    if (invalid && onInvalid) {
        onInvalid(invalid);
    }
    return !invalid;
}
export function getInvalidMAWBMessage(mawb) {
    mawb = isEmptyString(mawb) ? mawb : mawb.trim().replace(/\D/g, '');
    if (isEmptyString(mawb) || mawb.length !== 11 || isNaN(Number(mawb))) {
        return 'MAWB must be 11 digits';
    }
    var m = Number(mawb.slice(3, 10)) % 7;
    if (Number(mawb[10]) !== m) {
        return 'MAWB last digit must be ' + m;
    }
    return null;
}
export function validCNT(cnt, orderMode, onInvalid) {
    var invalid = getInvalidCNTMessage(cnt, orderMode);
    if (invalid && onInvalid) {
        onInvalid(invalid);
    }
    return !invalid;
}
export function getInvalidCNTMessage(cnt, orderMode) {
    if (isEmptyString(cnt) || cnt.length !== 11) {
        return 'CNT must be 11 characters';
    }
    var lastCharacter = cnt.charAt(3);
    if (lastCharacter.toUpperCase() !== "U") {
        return "Prefix must finished with 'U' example:'OOLU'";
    }
    var result = 0;
    for (var i = 0; i < 4; i++) {
        var char = cnt.charAt(i);
        result += Number(CNT_CHAR_VALUES[char]) * POSITION_MULTIPLIER[i];
    }
    for (var i = 4; i < cnt.length - 1; i++) {
        var char = cnt.charAt(i);
        result += Number(char) * POSITION_MULTIPLIER[i];
    }
    var resultDivided = Math.floor(result / 11);
    var resultDividedAndMultiplied = resultDivided * 11;
    var delta = result - resultDividedAndMultiplied;
    delta = delta % 10;
    var validation = Number(cnt.charAt(cnt.length - 1));
    if (validation !== delta) {
        return "Incorrect validation character, correct one = " + delta;
    }
    return null;
}
export function validUld(uld, onInvalid) {
    /*  if (isEmptyString(uld) || uld.length < 9 || uld.length > 10) {
        if (onInvalid) onInvalid('ULD must be 9-10 length');
        return false;
      }
    
      let uldPattern = /^[(a-zA-Z)]{3}[(0-9)]{4,5}[(a-zA-Z)]{2}$/;
      if (!uldPattern.test(uld)) {
        let example = uld.length == 9 ? 'AAA1234ZZ' : 'AAA12345ZZ';
        if (onInvalid) onInvalid('ULD Should be in format: ' + example);
        return false;
      }*/
    return true;
}
export function composeMawbTrackLink(mawb) {
    //  return validMAWB(mawb) ? `https://connect.track-trace.com/for/jjstransportation/aircargo/&${mawb.slice(0, 3)}&${mawb.slice(3, 11)}&/action,direct,Track!` : undefined;
    return validMAWB(mawb) ? "https://connect.track-trace.com/for/jjstransportation/aircargo/" + mawb + "/action,direct" : undefined;
}
export function convertAddress(a) {
    return isNullOrUndefined(a) ? '' : a.fullAddress;
    //    a.name + '\n'+
    //    a.addressLines + '\n' +
    //    a.city + getState(a) + getWithComa(a.postCode);
}
export function fsrStatusTooltip(value) {
    return "BKD: Booked for the given flight\n" +
        "RCS: Received from shipper or agent\n" +
        "MAN: Manifested for the specific flight\n" +
        "DEP: Departed on a specific flight\n" +
        "TFD: Transferred to another airline\n" +
        "RCT: Received from another airline\n" +
        "RCF: Received from a given flight\n" +
        "NFD: Arrived at destination and the consignee or agent has been informed\n" +
        "AWD: Arrival documents delivered to the consignee or agent\n" +
        "TRM: To be transferred to another airline\n" +
        "CCD: Cleared by Customs\n" +
        "DLV: Delivered to the consignee or agent\n" +
        "DIS: Discrepancy";
}
export function convertAddressForGPS(a) {
    return isNullOrUndefined(a) ? '' :
        (isNullOrUndefined(a.addressLines) ? "" : a.addressLines + '\n') +
            a.city + getState(a) + getWithComa(a.postCode);
}
export function getState(address) {
    if (isNullOrUndefined(address.usaState)) {
        return "";
    }
    return ", " + address.usaState.name;
}
export function getWithComa(data) {
    return isNullOrUndefined(data) ? "" : ", " + data;
}
export function convertMasterCargoBuilding(m, cargo) {
    var airportAbbr = isNullOrUndefined(m.airport) ? '' : m.airport.iataCode;
    var showAs = isNullOrUndefined(cargo) ? '' : (cargo.showAs || cargo.name).toLocaleUpperCase() + ':';
    var address = new Address();
    // address.id = this.shipment.addressDelivery.id;
    return showAs + airportAbbr;
}
export function convertValueFromArray(array, getter, converter, ifEmpty, ifMultiple) {
    if (ifEmpty === void 0) { ifEmpty = OmsConstants.EMPTY_VALUE; }
    if (ifMultiple === void 0) { ifMultiple = OmsConstants.MULTIPLE_VALUE; }
    if (!array || !array.length) {
        return ifEmpty;
    }
    var result = null;
    var multiple = array.some(function (item) {
        var value = getter ? getter(item) : item;
        if (isNullOrUndefined(result)) {
            result = value;
            return false;
        }
        else {
            return !equals(result, value);
        }
    });
    return multiple ? ifMultiple : isNullOrUndefined(result) ? ifEmpty : converter ? converter(result) : result;
}
export function valueOrNA(value) {
    return isNullOrUndefined(value) ? OmsConstants.EMPTY_VALUE : value;
}
export function valueOrNAFixed(value) {
    return toFixed(WeightUnits.fromDefault(value, OmsConstants.DEFAULT_WEIGHT_UNITS), OmsConstants.DEFAULT_WEIGHT_UNITS.digits) || OmsConstants.EMPTY_VALUE;
}
export function valueOrMultiple(value, converter) {
    if (isNullOrUndefined(value)) {
        return OmsConstants.EMPTY_VALUE;
    }
    if (typeof value === 'string') {
        if (value === '') {
            return OmsConstants.EMPTY_VALUE;
        }
        var arr = value.split('|').filter(function (s) { return s.length > 0; }).unique();
        var l = arr.length;
        return l === 0 ? OmsConstants.EMPTY_VALUE : l > 1 ? OmsConstants.MULTIPLE_VALUE : converter(arr[0]);
    }
    return converter(value);
}
export function valueOrMultipleValues(value, converter) {
    return isNullOrUndefined(value) ? null : typeof value === 'string' && value.indexOf('|') > 0 ?
        value.split('|').unique().map(function (s) { return converter(s); }).join('\n') : converter(value);
}
export function safelyGetter(item, fields, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 'N/A'; }
    var value = fields
        .split('.')
        .reduce(function (result, fieldName) { return result && result[fieldName]; }, item);
    return value || defaultValue;
}
var OmsConverters = /** @class */ (function () {
    function OmsConverters() {
        this.convertMawbNumber = convertMawbNumber;
        this.convertAction = convertAction;
        //  convertMasterNumber(m:Master):string {return convertMasterNumber(m.id);}
        this.convertMasterNumber = function (m) { return convertMasterNumber(m.id); };
    }
    OmsConverters.prototype.convertDate = function (date) { return convertDate(date); };
    OmsConverters.prototype.convertDateTime = function (date) { return convertDateTime(date); };
    OmsConverters.ngInjectableDef = i0.defineInjectable({ factory: function OmsConverters_Factory() { return new OmsConverters(); }, token: OmsConverters, providedIn: "root" });
    return OmsConverters;
}());
export { OmsConverters };
export { ɵ0 };
