import {Type} from "class-transformer";
import {BaseDeletable} from "./base-deletable";
import {Airport} from "./air";

export class MasterFsr extends BaseDeletable  {
  masterId: number;
  mawbNumber: string;
  @Type(() => Airport)
  originAirport: Airport;
  @Type(() => Airport)
  destinationAirport: Airport;
  flightNo: string;
  eventLoc: string;
  status: string;

  @Type(() => Date)
  statusDate: Date;
  @Type(() => Date)
  arrivalDate: Date;
  @Type(() => Date)
  arrivalDateEst: Date;
  @Type(() => Date)
  departureDate: Date;
  @Type(() => Date)
  departureDateEst: Date;
  inboundFileName: string;
  @Type(() => Date)
  messageDateTime: Date;
  inSystem: boolean;

  eventFrom: string;
  eventTo: string;
  eventAt: string;
  airline: string;

  @Type(() => Date)
  docsDeliveredAtDestination: Date;

  @Type(() => Date)
  deliveryTime: Date;

  eventPieces: number;
  totalPieces: number;
  eventWeight: number;
  totalWeight: number;
  partial: boolean;
  explanation: string;
  subCode: string;
}
