import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Trailer} from '../../../modules/shared/models/trailer';
import {StringInputComponent} from '../../../modules/shared/components/common/input/string-input/string-input.component';

@Component({
  selector: 'app-trailer-dialog',
  templateUrl: './trailer-dialog.component.html',
  styleUrls: ['./trailer-dialog.component.scss']
})
export class TrailerDialogComponent {
  public readonly: boolean = false;
  public trailer: Trailer;
  @ViewChild('strNumber') input: StringInputComponent;

  constructor(
    public dialogRef: MatDialogRef<TrailerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.readonly = data.readonly;
    this.trailer = data.trailer;
  }

  onOk(event) {
    if (this.input.isInvalid || !this.trailer.number || this.trailer.number.length==0)  {
      this.input.touchControl();
      event.canClose = false;
    }
  }

}
