import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Observable} from "rxjs/index";
import {RestService} from "../../../services/rest.service";
import {Region} from "../models/address/region";
import {HttpUtilsService} from "../../../services/http-utils.service";

const API_URL = "/oms/region/";
@Injectable()
export class RegionService {

  public regionsSubject = new Subject<Region[]>();

  constructor(private httpRestService: RestService, private httpUtils: HttpUtilsService) { }

  getAllCountries() {
    this.httpRestService.get<Region[]>(API_URL + 'getAllRegions').subscribe(items => {
      this.regionsSubject.next(items);
    });
  }

  create(item: Region): Observable<Region> {
    return this.httpRestService.post<Region>(API_URL + 'create', item);
  }

  update(item: Region): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(API_URL + 'update', item, { headers: httpHeader });
  }

  delete(id: number): Observable<Region> {
    return this.httpRestService.delete<Region>(API_URL + 'delete', id);
  }
}
