import {Labeled} from '../../../common/oms-types';
import {BaseDeletable} from './base-deletable';

export class Truck extends BaseDeletable implements Labeled {
  public number: string;

  get label(): string {
    return name;
  }
}
