import {ChangeDetectorRef, Component, OnInit, Input, OnDestroy} from '@angular/core';
import { MatDialog } from '@angular/material';
import { AbstractEntityListComponent } from "../../../../modules/settings/pages/abstract-entity-list.component";
import { CbpStatus } from "../../../../modules/shared/models/cbp.status";
import { BaseColumn } from "../../../../modules/shared/components/base/data-table/columns/column-types";
import { OmsAlertsService } from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { OmsDialogsService } from "../../oms-dialogs";
import { CbpStatusService } from "../../../../modules/shared/services/stb-status/cbp.status.service";
import { CbpStatusDefaultColumnsService } from "../../../../common/cbp-status-default-columns.service";
import { FileUploadService } from "../../../../services/file.upload.service";
import { FilterPageRequest } from "../../../../modules/shared/models/filter.page.request";
import { FilterSearchColumn } from "../../../../modules/shared/models/filter.search.column";
import { SearchSortRequest } from "../../../../modules/shared/models/search.sort.request";
import {Subscription} from "rxjs";

@Component({
  selector: 'oms-cbp-status-table',
  templateUrl: './cbp-status-table.component.html',
  styleUrls: ['./cbp-status-table.component.scss']
})
export class CbpStatusTableComponent extends AbstractEntityListComponent<CbpStatus> implements OnInit, OnDestroy {

  @Input() objectType: 'MASTER' | 'ORDER';
  @Input() objectId: number;
  @Input() uscsFsnStatus: string;

  public specificRowClassHandler = [
    {
      field: "inSystem",
      value: "false",
      class: "table-row-missed"
    }
  ];

  cbpStatuses: CbpStatus[] = [];
  columns: BaseColumn[];

  defaultSortColumn: string = "transactionDate";

  private sub: Subscription[] = [];

  constructor(private dialog: MatDialog,
              protected cdr: ChangeDetectorRef,
              protected alerts: OmsAlertsService,
              protected dialogs: OmsDialogsService,
              public cbpStatusService: CbpStatusService,
              private cbpStatusDefaultColumnsService: CbpStatusDefaultColumnsService,
              protected fileUploadService: FileUploadService) {
    super(cdr, alerts, cbpStatusService, dialogs, fileUploadService);
  }


  ngOnInit() {
    this.columns = this.cbpStatusDefaultColumnsService.getCbpColumns();

    switch (this.objectType) {
      case "MASTER":
        this.sub.push(this.cbpStatusService.findAllByMasterId(this.objectId, this.uscsFsnStatus).subscribe(
          (cbpStatuses) => this.cbpStatuses = cbpStatuses));
        break;
      case "ORDER":
        this.sub.push(this.cbpStatusService.findAllByOrderId(this.objectId).subscribe(
          (cbpStatuses) => this.cbpStatuses = cbpStatuses));
        break;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sub.forEach((s) => s.unsubscribe());
  }

  getTitle(): string {
    return 'CBP Statuses';
  }

  createDefaultRequest(searchField: string): FilterPageRequest {
    let filterByMaster = new FilterSearchColumn(searchField, '' + this.objectId, null, null);
    let filterByColumns = [filterByMaster];
    if (this.uscsFsnStatus) {
      filterByColumns.push(new FilterSearchColumn('hasPart', 'yes', null, null));
      filterByColumns.push(new FilterSearchColumn('uscsFsnStatus', this.uscsFsnStatus, null, null));
    }
    return new FilterPageRequest(1, 999, '', new SearchSortRequest(this.defaultSortColumn, true), filterByColumns);
  }

  public generateExcel() {
    let searchField = "";
    switch (this.objectType) {
      case "MASTER":
        searchField = "master.id";
        break;
      case "ORDER":
        searchField = "order.id";
        break;
    }
    this.request = this.createDefaultRequest(searchField);
    this.downloadExcel();
  }

  public getRowClass(row: CbpStatus): string {
    if (row.hawb && !row.order) {
      return 'warning';
    }
    return '';
  }
}
