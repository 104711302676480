import {Component, EventEmitter, Inject, Input, Optional, Output, ViewChild} from '@angular/core';
import {BaseInputComponent} from '../../../base/base-input/base-input.component';
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {conformToMask} from 'text-mask-core/dist/textMaskCore';
import {isFunction} from 'util';
import {animations} from '../../../base/base-input/animations';
import {isNullOrUndefined} from 'util';
import {Size} from '../../../../../../common/oms-types';
import {
  HistoryInformationConfig,
  HistoryInformationDialogComponent
} from "../../../../../../components/common/history-information-dialog";
import {MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'oms-string-input',
  templateUrl: './string-input.component.html',
  styleUrls: ['./string-input.component.scss'],
  animations,
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: StringInputComponent, multi: true}],
})


export class StringInputComponent extends BaseInputComponent<string> {
  private static NO_MASK = {mask: false};

  Size = Size;
  @Input()size: Size = Size.DEFAULT;

  @Input('input-mask') set mask(value: any) {this.composeMask(value); } // text-mask value
  @Input('clean-mask') cleanMask: boolean; // Keep only alphanumerical values (remove mask)
  @Input('number') isNumber: boolean = false;
  @Input('readonly') readonly: boolean = false;
  @Input('show-label') showLabel: boolean = true;
  @Input() history: HistoryInformationConfig;
  @Input() multiline: boolean | number = false;
  @Input() labelInline: boolean = false;
  @Input() invalidValue: boolean = false;


  @Input()inputType: string = 'text';
  @ViewChild(NgModel) model: NgModel;
  @Output('changing') changingEvent = new EventEmitter();
//  @ViewChild("input", { read: ViewContainerRef }) public input;

  public _mask: any = StringInputComponent.NO_MASK;
  private _internalValue: any;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private dialog: MatDialog
  ) {
    super(validators, asyncValidators);
  }

  private composeMask(inputMask): any {
//    if (this._mask)
//      return this._mask;

    if (isNullOrUndefined(inputMask)) {
      this._mask = StringInputComponent.NO_MASK;
    } else if (isFunction(inputMask)) {
      this._mask = {mask: inputMask};
    } else if (inputMask.mask) {
      this._mask = inputMask;
    } else {
      this._mask = {
          mask: inputMask,
          guide: true,
          showMask: !this.placeholder
        };
    }

    return this._mask;
  }

  get internal() {
    if (isNullOrUndefined(this._internalValue)) {
      this._internalValue = this.value;
    }
    return this._internalValue;
  }

  set internal(value) {
    if (this._internalValue !== value) {
      this._internalValue = value;
      super.setValue(this.cleanup(value));
      this.changingEvent.emit(this.value);
    }
  }

  setValue(value) {
    this._internalValue = value;
    super.setValue(this._internalValue);
  }

  private cleanup(s: string): any {
    if (this._mask) {
      // remove _ guide before passing to the model
      if (this._mask.mask) {
        s = conformToMask(s, this._mask.mask, {guide: false}).conformedValue;
      }

      // keep only alphanumerical values and '.' for number support
      if (this.cleanMask || this.isNumber) {
        s = s.replace(/[^0-9A-Za-z.]/g, '');
      }
    }
    return  this.isNumber ? ( s ? Number(s) : null) : s;
  }

  public onShowHistory() {
    if (this.history) {
      this.dialog.open(HistoryInformationDialogComponent, {
        width: '80%',
        data: {
          logParams: [this.history], convertByBaseType: true
        }
      });
    }
  }

}

