/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oms-maps-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/services/oms-converters.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./oms-maps-page.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../services/oms-maps.service";
var styles_OmsMapsPageComponent = [i0.styles];
var RenderType_OmsMapsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OmsMapsPageComponent, data: {} });
export { RenderType_OmsMapsPageComponent as RenderType_OmsMapsPageComponent };
function View_OmsMapsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "embed", [["height", "100%"], ["type", "text/html"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.getMapLink())); _ck(_v, 0, 0, currVal_0); }); }
export function View_OmsMapsPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeUrlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsMapsPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.omsMapsOptions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OmsMapsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-maps-page", [], null, null, null, View_OmsMapsPageComponent_0, RenderType_OmsMapsPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.OmsMapsPageComponent, [i6.ActivatedRoute, i1.ChangeDetectorRef, i7.OmsMapsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OmsMapsPageComponentNgFactory = i1.ɵccf("oms-maps-page", i5.OmsMapsPageComponent, View_OmsMapsPageComponent_Host_0, {}, {}, []);
export { OmsMapsPageComponentNgFactory as OmsMapsPageComponentNgFactory };
