/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./doc-center-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../oms-dialogs/base-dialog/base-dialog.component.ngfactory";
import * as i4 from "ng-drag-drop/src/directives/draggable.directive";
import * as i5 from "ng-drag-drop/src/services/ng-drag-drop.service";
import * as i6 from "../../oms-dialogs/base-dialog/base-dialog.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "ngx-spinner";
import * as i9 from "../doc-center.component.ngfactory";
import * as i10 from "../doc-center.component";
import * as i11 from "@angular/forms";
import * as i12 from "@angular/router";
import * as i13 from "../../../../services/document-type.service";
import * as i14 from "../../../../services/file.upload.service";
import * as i15 from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import * as i16 from "./doc-center-dialog.component";
var styles_DocCenterDialogComponent = [i0.styles];
var RenderType_DocCenterDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocCenterDialogComponent, data: {} });
export { RenderType_DocCenterDialogComponent as RenderType_DocCenterDialogComponent };
function View_DocCenterDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "active": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 15).changeTab("history") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-clock"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["History"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, (i1.ɵnov(_v.parent, 15).activeTab === "history")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DocCenterDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { docCenter: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "oms-base-dialog", [["icon", "fa fa-file"]], [[8, "draggable", 0], [8, "className", 0]], [[null, "cancel"], [null, "dragstart"], [null, "dragend"], [null, "mousedown"], [null, "touchstart"], ["document", "keydown.escape"], ["window", "popstate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dragStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).dragEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).mousedown($event) !== false);
        ad = (pd_2 && ad);
    } if (("touchstart" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).mousedown($event) !== false);
        ad = (pd_3 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).onEscape($event) !== false);
        ad = (pd_4 && ad);
    } if (("window:popstate" === en)) {
        var pd_5 = (i1.ɵnov(_v, 3).onPopState($event) !== false);
        ad = (pd_5 && ad);
    } if (("cancel" === en)) {
        var pd_6 = (_co.onCancel($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i3.View_BaseDialogComponent_0, i3.RenderType_BaseDialogComponent)), i1.ɵdid(2, 212992, null, 0, i4.Draggable, [i1.ElementRef, i1.Renderer2, i5.NgDragDropService, i1.NgZone], null, null), i1.ɵdid(3, 4440064, null, 0, i6.BaseDialogComponent, [i7.MAT_DIALOG_DATA, i8.NgxSpinnerService, i7.MatDialogRef], { height: [0, "height"], width: [1, "width"], draggable: [2, "draggable"], sizable: [3, "sizable"], no_padding: [4, "no_padding"], _icon: [5, "_icon"], _title: [6, "_title"], storePosition: [7, "storePosition"], showFooter: [8, "showFooter"], flexContent: [9, "flexContent"], loading: [10, "loading"], btnOk: [11, "btnOk"], btnCancel: [12, "btnCancel"] }, { eventCancel: "cancel" }), (_l()(), i1.ɵeld(4, 0, null, 0, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "ul", [["class", "nav nav-pills nav-pills-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "active": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).changeTab("documents") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Documents"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocCenterDialogComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, 1, 1, "oms-doc-center", [], [[8, "className", 0]], [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onEscape($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_DocCenterComponent_0, i9.RenderType_DocCenterComponent)), i1.ɵdid(15, 114688, [[1, 4], ["docCenter", 4]], 0, i10.DocCenterComponent, [i11.FormBuilder, i7.MatDialog, i1.ChangeDetectorRef, i12.ActivatedRoute, i13.DocumentTypeService, i14.FileUploadService, i15.OmsAlertsService], { isVisibleHistory: [0, "isVisibleHistory"], docTypes: [1, "docTypes"], id: [2, "id"], readonly: [3, "readonly"], objectType: [4, "objectType"], showHeader: [5, "showHeader"], latestActive: [6, "latestActive"], ffg: [7, "ffg"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = "85vh"; var currVal_3 = "85vw"; var currVal_4 = false; var currVal_5 = true; var currVal_6 = true; var currVal_7 = "fa fa-file"; var currVal_8 = i1.ɵnov(_v, 15).getPageLabel(); var currVal_9 = false; var currVal_10 = true; var currVal_11 = true; var currVal_12 = i1.ɵnov(_v, 15).viewLoading; var currVal_13 = undefined; var currVal_14 = _co.getButtonCancel(); _ck(_v, 3, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_15 = _ck(_v, 8, 0, (i1.ɵnov(_v, 15).activeTab === "documents")); _ck(_v, 7, 0, currVal_15); var currVal_16 = _co.showHistory; _ck(_v, 13, 0, currVal_16); var currVal_18 = _co.showHistory; var currVal_19 = _co.config.docTypes; var currVal_20 = _co.config.id; var currVal_21 = _co.config.readonly; var currVal_22 = _co.config.objectType; var currVal_23 = false; var currVal_24 = _co.config.latestActive; var currVal_25 = _co.config.ffg; _ck(_v, 15, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dragEnabled; var currVal_1 = i1.ɵnov(_v, 3).class; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_17 = i1.ɵnov(_v, 15).class; _ck(_v, 14, 0, currVal_17); }); }
export function View_DocCenterDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-doc-center-dialog", [], [[8, "className", 0]], null, null, View_DocCenterDialogComponent_0, RenderType_DocCenterDialogComponent)), i1.ɵdid(1, 114688, null, 0, i16.DocCenterDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA, i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var DocCenterDialogComponentNgFactory = i1.ɵccf("oms-doc-center-dialog", i16.DocCenterDialogComponent, View_DocCenterDialogComponent_Host_0, { isVisibleHistory: "isVisibleHistory" }, {}, []);
export { DocCenterDialogComponentNgFactory as DocCenterDialogComponentNgFactory };
