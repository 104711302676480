import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { isNullOrUndefined } from "util";
var StickyDirective = /** @class */ (function () {
    function StickyDirective(host) {
        this.host = host;
        this.sticky = true;
        this.direction = 'left';
        this.stickyColumnEvent = new EventEmitter(false);
    }
    StickyDirective.prototype.ngOnInit = function () {
        if (!isNullOrUndefined(this.params && this.params.position)) {
            this._left = this.params.position;
        }
        else {
            this._left = 0; // this.host.nativeElement.offsetLeft;
            if (this._left > 0) {
                this._left--;
            }
            if (this.params && this.params.index) {
                this.stickyColumnEvent.emit({ index: this.params.index, position: this._left });
            }
        }
    };
    Object.defineProperty(StickyDirective.prototype, "left", {
        /*
          ngOnDestroy() { }
          ngAfterViewInit(): void { } */
        get: function () {
            return this._left; // = this._left || this.host.nativeElement.clientLeft;
        },
        enumerable: true,
        configurable: true
    });
    return StickyDirective;
}());
export { StickyDirective };
