var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { RestService } from "../../../../services/rest.service";
import { HttpUtilsService } from "../../../../services/http-utils.service";
import { plainToClass } from "class-transformer";
import { BaseEntityService } from "../../../../services/base/base-entity.service";
import { MawbTrack } from "../../models/mawb.track";
var MawbTrackService = /** @class */ (function (_super) {
    __extends(MawbTrackService, _super);
    function MawbTrackService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/mawb-track/';
        _this.classType = MawbTrack;
        _this.asmDataSubject = new Subject();
        return _this;
    }
    MawbTrackService.prototype.findAllByMasterId = function (id) {
        var _this = this;
        this.httpRestService.get(this.apiUrl + 'find-by-id', id).subscribe(function (masterAsmData) {
            _this.asmDataSubject.next(plainToClass(MawbTrack, masterAsmData));
        });
    };
    return MawbTrackService;
}(BaseEntityService));
export { MawbTrackService };
