import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import {OmsConstants, WEIGHT_DECIMAL_DIGITS} from '../../../common/oms-constants.service';
import { isNullOrUndefined } from 'util';
import { getPendingStatus, PendingStatus } from '../../../common/oms-date-time.utils';
import { equals, isEmptyString, toFixed } from '../../../_helpers/utils';
import * as moment from "moment-timezone";
import { Address, LoadTypes, Master, MasterStatus, MasterStatusId, Order, Split } from '../models';
import { DispatchLoadType, DispatchLoadTypeId } from "../models/load/dispatch-load-type";
import { OrderMode, OrderModes } from "../models/order/order-mode";
import { NumberUtils } from '../../../_helpers/number.utils';
import { WeightUnits } from "../../../common/oms-unit-types";
import {Action, HyperLink} from "../../../common/oms-types";
import { UserUtil } from "../../settings/util/user.util";
import { UserRoleType } from "../models/user-role.type";
import {DomSanitizer} from "@angular/platform-browser";



@Pipe({name: 'mawb_number'})
export class MawbNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return convertMawbNumber(value);
  }
}

@Pipe({name: 'master_number'})
export class MasterNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return convertMasterNumber(value);
  }
}

/*@Pipe({name: 'master_link'})
export class MasterLinkPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return getMasterLink(value);
  }
}*/

/***
 * arguments:
 * isRecovery: Replace J with R
 * ifEmpty: display value if empty order id
 */

@Pipe({name: 'order_number'})
export class OrderNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return !value && args && args.ifEmpty ?  args.ifEmpty : convertOrderNumber(value, args && args.isRecovery);
  }
}
@Pipe({ name: 'safe_html' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'safe_url' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({name: 'oms_date'})
export class OmsDatePipe extends DatePipe implements PipeTransform {
  constructor() { super('en-US'); }
  transform(value: any, args?: any): any {
    if (isNullOrUndefined(value)) {
      return null;
    }

    if (args && args.format) {
      return moment.tz(value, OmsConstants.ETC_ZONE).format(args.format);
    }

    return args && args.time ?
      moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_FORMAT) :
      moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT);
  }
}

@Pipe({name: 'oms_date_time'})
export class OmsDateTimePipe extends DatePipe implements PipeTransform {
  constructor() { super('en-US'); }

  transform(value: any, args?: any): any {
    if (isNullOrUndefined(value)) {
      return null;
    }

    return moment.tz(value, OmsConstants.ETC_ZONE).format(
      args && args.showYear ? OmsConstants.MOMENT_DATE_YEAR_TIME_FORMAT :
          OmsConstants.MOMENT_DATE_TIME_FORMAT);
  }
}

@Pipe({name: 'oms_date_time_zone'})
export class OmsDateTimeZonePipe extends DatePipe implements PipeTransform {
  constructor() { super('en-US'); }

  transform(value: any, args?: any): any {
    if (isNullOrUndefined(value)) {
      return null;
    }
    return moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_ZONE_FORMAT);
  }
}

@Pipe({name: 'oms_weight'})
export class OmsWeightPipe extends DatePipe implements PipeTransform {
  constructor() { super('en-US'); }

  transform(value: any, args?: any): any {
    if (isNullOrUndefined(value)) {
      return "";
    }

    return convertWeight(value);
  }
}

@Pipe({name: 'highlight'})
export class HighlightSearch implements PipeTransform {
  transform(value: string, searchText: string, rawValue?: string): string {
/*    if (!args) {return value;}
    var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    return value.replace(re, "<p>$&</p>"); */

    if (searchText && value) {
      value += "";
      let startIndex = value.toLowerCase().indexOf(searchText.toLowerCase());
      if (startIndex !== -1) {
        let endLength = searchText.length;
        let matchingString = value.substr(startIndex, endLength);
        return value.replace(matchingString, "<mark>" + matchingString + "</mark>");
      }
      let subValue = this.findSubValue(value, searchText, rawValue);
      if (subValue) {
        return value.replace(subValue, "<mark>" + subValue + "</mark>");
      }
    }
    return value;
  }

  findSubValue(value: string, searchText: string, rawValue?: string) {
    if (!rawValue || typeof rawValue !== 'string') {
      return null;
    }
    let startIndex = rawValue.toLowerCase().indexOf(searchText.toLowerCase());
    if (startIndex !== -1) {
      let regStr = '';
      for (let i = 0; i < searchText.length - 1; i++) {
        regStr += searchText[i] + '[ -]?';
      }
      regStr += searchText[searchText.length - 1];
      let reg = new RegExp(regStr, 'mig');
      let match = value.match(reg);
      if (match && match.length) {
        return match[0];
      }
    }
    return null;
  }
}


const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

const CNT_CHAR_VALUES = {A: 10, B: 12, C: 13, D: 14, E: 15, F: 16, G: 17, H: 18, I: 19, J: 20, K: 21, L: 23, M: 24,
  N: 25, O: 26, P: 27, Q: 28, R: 29, S: 30, T: 31, U: 32, V: 34, W: 35, X: 36, Y: 37, Z: 38};

const POSITION_MULTIPLIER = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];

export function convertAction(action: Action): string {
  console.log('Action>', typeof action);

  if (isNullOrUndefined(action)) {
    console.log(1);
    return null;
  }

  switch (action) {
    case Action.CREATED:
      console.log(2);
      return 'Created';
    case Action.UPDATED: return 'Updated';
    case Action.DELETED: return 'Deleted';
  }
  console.log(3);
}

export function convertDate(date: Date): string {
  return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT) : null;
}

export function convertDateWithoutYear(date: Date): string {
  return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT_WITHOUT_YEAR) : null;
}

export function convertDateTimeWithYear(date: Date): string {
  return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_YEAR_TIME_FORMAT) : null;
}

export function convertDateTime(date: Date): string {
  return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_FORMAT) : null;
}

export function convertToStandardDateTime(date: Date, ifEmpty?: string): string {
  return date ? moment.tz(date, OmsConstants.ETC_ZONE).format('YYYY-MM-DDTHH:mm:ss') : ifEmpty;
}

export function convertToStandardDateOnly(date: Date, ifEmpty?: string): string {
  return date ? moment.tz(date, OmsConstants.ETC_ZONE).format('YYYY-MM-DDT00:00:00') : ifEmpty;
}

export function convertToLongDateTime(date: Date, ifEmpty?: string): string {
  return date ? moment.tz(date, OmsConstants.ETC_ZONE).format(OmsConstants.LONG_DATE_TIME_FORMAT) : ifEmpty;
}

export function convertMawbNumber(input: string): string {
//  return validMAWB(input)  ? insertAt(insertAt(input, '-', 3), ' ', 8) : input;
  if (!input || /[a-zA-Z]/g.test(input)) {
    return input;
  }

  let test = input.replace(/[^\d+$]/g, '');
  if (test.length === 11 && /^\d+$/.test(test)) {
    return insertAt(insertAt(test, '-', 3), ' ', 8);
  } else {
    return input;
  }
}

export function convertSubString(object: any, field: string, start: number, end: number): string {
  let value = safelyGetter(object, field, null);
  if (value) {
    return value.substring(start, end);
  }
  return 'N/A';
}

export function convertContainerNumber(input: string): string {
  return input ? insertAt(insertAt(input, ' ', 4), '-', 11) : input;
}

export function convertBracketed(value): string {
  return NumberUtils.isNumber(value) ? (  value === 0 ? null : '(' + value + ')' ) : value;
}

export function convertMawbStatus(status: MasterStatusId): string {
  return isNullOrUndefined(status) ? null : MasterStatus.getLabel(status);
}

export function convertMawbStatusShort(status: MasterStatusId): string {
  return isNullOrUndefined(status) ? null : MasterStatus.getName(status);
}

export function convertMawbPublicStatus(status: MasterStatusId): string {
  return isNullOrUndefined(status) ? null : MasterStatus.getPublicLabel(status);
}

export function convertDispatchLoadStatus(type: DispatchLoadTypeId): string {
  return isNullOrUndefined(type) ? null : DispatchLoadType.getLabel(type);
}

export function addWeekdays(date: Date, days: number): Date {
  let d = moment(date); // use a clone
  while (days > 0) {
    d = d.add(1, 'days');
    // decrease "days" only if it's a weekday.
    if (d.isoWeekday() !== 6 && d.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return d.toDate();
}

/*
export function convertKgToUnits(value: number, units: MeasureUnit, digits:number = 1) {
  return isNullOrUndefined(value) ? value : units.coeff * value);
}

export function convertUnitsToKg(value: number, units: MeasureUnit, digits:number = 1) {
  return isNullOrUndefined(value) ? value : myFixed(value / units.coeff, digits);
}
*/

// const round = (value, digits) => {return Math.round(value * 100) / 100};


export function convertWeight(value: number, digits: number = WEIGHT_DECIMAL_DIGITS, def?: string): string {
  return isNullOrUndefined(value) ? def : toFixed(value * OmsConstants.DEFAULT_WEIGHT_UNITS.coeff, digits).toString();
}

export function convertVolume(value: number, digits: number = 2, def?: string): string {
  return isNullOrUndefined(value) ? def : toFixed(value * OmsConstants.DEFAULT_VOLUME_UNITS.coeff, digits).toString();
}

export function convertAmount(value: number, def?: string): string {
  console.log('AMOUNT', value);
  return isNullOrUndefined(value) ? def : toFixed(value, 2).toString();
}


export function convertLoadType(value) {
  if (!value) {
    return null;
  }
  return LoadTypes.prefixOf(value);

/*  switch (value) {
    case 'RECOVERY': return "RLD";
    case 'DELIVERY': return "DLD";
    case 'X-DOCK': return "XLD";
  } */
}

export function convertLoadTypeNumber(value, loadNumber: number) {
  if (!value) {
    return null;
  }

  switch (value) {
    case 'RECOVERY': return `RLD#${loadNumber}`;
    case 'DELIVERY': return `DLD#${loadNumber}`;
  }
}

export function padZeros(id: any, n: number): string {
  return id ? (id + '').padStart(n, '0') : id;
}

export function convertOrderNumber(id: number, isRecovery?: boolean): string {
  const pad = "000000";
  return isNullOrUndefined(id) ? 'NEW' : (isRecovery ? 'R' : 'J') + (pad + id).slice(-pad.length);
}

export function convertRecoveryOrderNumber(id: number, item?: any): string {
  const pad = "000000";
  return isNullOrUndefined(id) ? (item instanceof Split ? item.label : 'RLD') : 'R' + (pad + id).slice(-pad.length);
}

export function convertLoadNumber(id: number): string {
  const pad = "000000";
  return isNullOrUndefined(id) ? 'NEW LOAD' : 'LD' + (pad + id).slice(-pad.length);
}

export function convertMasterNumber(id: number): string {
  const pad = "000000";
  return isNullOrUndefined(id) ? 'NEW MASTER' : 'M' + (pad + id).slice(-pad.length);
}

export function convertManifestNumber(id: number): string {
  const pad = "00000";
  return isNullOrUndefined(id) ? null : 'RT#' + (pad + id).slice(-pad.length);
}

export function convertOrderRef3(mode: OrderMode, ref3: string): string {
  return OrderModes.isAir(mode) || OrderModes.isRecovery(mode) ? convertMawbNumber(ref3) : ref3;
}

export function convertOrderRef2(mode: OrderMode, ref2: string): string {
  return OrderModes.isContainerMode(mode) ? convertContainerNumber(ref2) : ref2;
}

export function getOrderEditorLink(orderId: number, relative: boolean = true): HyperLink {
  return relative ?
    {path: ['order'], params: {id: orderId}} :          // Relative to Current Path
    {path: ['/home', 'order'], params: {id: orderId}};  // for New Window
}

export function getOrderLink(order: Order | number): HyperLink {
  if (order instanceof Order) {
    let haveClientRole = UserUtil.getCurrentUser().hasAnyRoles([UserRoleType.ROLE_CLIENT]);
    if (haveClientRole) {
      return order && !order.isNew() ? {path: ['/client', 'orders', 'order'], params: {id: order.id}} : null;
    }
    return order && !order.isNew() ? {path: ['/home', 'order'], params: {id: order.id}} : null;
  } else  {
    return order ? {path: ['/home', 'order'], params: {id: order}} : null;
  }
}

export function getMasterLink(masterId: number): HyperLink {
  return masterId ? {path: ['/home', 'master'], params: {queryParams: {id: masterId}}} : null;
}

export function getPendingStatusClass(status: PendingStatus): string {
  if (!isNullOrUndefined(status)) {
    switch (status) {
      case PendingStatus.AT_RISK: return 'date-at-risk';
      case PendingStatus.PENDING: return 'date-pending';
      case PendingStatus.IMPENDING: return 'date-impending';
      case PendingStatus.PAST_DUE: return 'date-past-due';
    }
  }
  return null;
}

export function masterDateLastFreeDayClass(master: Master): string {
  // depends on Actual Pickup date
  if (isNullOrUndefined(master) || (!isNullOrUndefined(master.cargoBuilding) && master.cargoBuilding.cfs3plLocation) || !isNullOrUndefined(master.datePickupActual)) {
    return null;
  }

  return getPendingStatusClass(getPendingStatus(master.dateLastFreeDay));
}

export function master1CByAmsStatusesClass(master: Master): string {
  if (master.orders.every(order => order.holdStatus)) {
    return "date-unchecked";
  }
  return master.orders.every(order => order.isPiecesInAMS) ? "fullInAms" : "";
}

export function order1FCByAmsStatusesClass(order: Order): string {
  return order.isPiecesInAMS ? "fullInAms" : "";
}

export function order1CByAmsStatusesClass(order: Order): string {
  if (!isNullOrUndefined(order.date1CUnchecked) || order.holdStatus) {
    return "date-unchecked";
  }
  return order.isPiecesInAMS ? "fullInAms" : "";
}

export function master1FByAmsStatusesClass(master: Master): string {
  let style = '';
  if (master.cbpStatusPiecesNotValid) {
    style += ' fullInAms';
  }
  if (master.cbpFsc10WithoutHawb) {
    style += ' background-yellow';
  }
  return style;
}

export function masterDatePickUpClass(master: Master): string {
  // depends on Actual Pickup date
  if (isNullOrUndefined(master) || isNullOrUndefined(master.cargoBuilding) ||  master.cargoBuilding.cfs3plLocation || isNullOrUndefined(master.datePickupActual)) {
    return null;
  }

  return getPendingStatusClass(getPendingStatus(master.datePickupActual));
}

export function masterDateCFSFreeDayClass(master: Master): string {
  // depends on Actual Pickup date
  if (isNullOrUndefined(master)) {
    return null;
  }

  return getPendingStatusClass(getPendingStatus(master.dateCfsFreeTime));
}

export function orderDateCFSFreeDayClass(order: Order): string {
  // depends on Actual Pickup date
  if (isNullOrUndefined(order)) {
    return null;
  }

  return getPendingStatusClass(getPendingStatus(order.dateCfsFreeTime));
}

export function validMAWB(mawb: string, onInvalid?: (error: string) => void): boolean {
  let invalid = getInvalidMAWBMessage(mawb);
  if (invalid && onInvalid) {
    onInvalid(invalid);
  }
  return !invalid;
}

export function getInvalidMAWBMessage(mawb: string): string {
  mawb = isEmptyString(mawb) ? mawb : mawb.trim().replace(/\D/g, '');
  if (isEmptyString(mawb) || mawb.length !== 11 || isNaN(Number(mawb))) {
    return 'MAWB must be 11 digits';
  }

  const m = Number(mawb.slice(3, 10)) % 7;
  if (Number(mawb[10]) !== m) {
    return 'MAWB last digit must be ' + m;
  }

  return null;
}

export function validCNT(cnt: string, orderMode: OrderMode, onInvalid?: (error: string) => void): boolean {
  let invalid = getInvalidCNTMessage(cnt, orderMode);
  if (invalid && onInvalid) {
    onInvalid(invalid);
  }
  return !invalid;
}

export function getInvalidCNTMessage(cnt: string, orderMode: OrderMode): string {
  if (isEmptyString(cnt) || cnt.length !== 11) {
    return 'CNT must be 11 characters';
  }

  let lastCharacter = cnt.charAt(3);
  if (lastCharacter.toUpperCase() !== "U") {
    return "Prefix must finished with 'U' example:'OOLU'";
  }

  let result = 0;
  for (let i = 0; i < 4; i++) {
    let char = cnt.charAt(i);
    result += Number(CNT_CHAR_VALUES[char]) * POSITION_MULTIPLIER[i];
  }

  for (let i = 4; i < cnt.length - 1; i++) {
    let char = cnt.charAt(i);
    result += Number(char) * POSITION_MULTIPLIER[i];
  }

  let resultDivided = Math.floor(result / 11);
  let resultDividedAndMultiplied = resultDivided * 11;
  let delta = result - resultDividedAndMultiplied;
  delta = delta % 10;
  let validation = Number(cnt.charAt(cnt.length - 1));

  if (validation !== delta) {
    return "Incorrect validation character, correct one = " + delta;
  }

  return null;
}

export function validUld(uld: string, onInvalid?: (error) => void): boolean {
/*  if (isEmptyString(uld) || uld.length < 9 || uld.length > 10) {
    if (onInvalid) onInvalid('ULD must be 9-10 length');
    return false;
  }

  let uldPattern = /^[(a-zA-Z)]{3}[(0-9)]{4,5}[(a-zA-Z)]{2}$/;
  if (!uldPattern.test(uld)) {
    let example = uld.length == 9 ? 'AAA1234ZZ' : 'AAA12345ZZ';
    if (onInvalid) onInvalid('ULD Should be in format: ' + example);
    return false;
  }*/

  return true;
}

export function composeMawbTrackLink(mawb: string) {
//  return validMAWB(mawb) ? `https://connect.track-trace.com/for/jjstransportation/aircargo/&${mawb.slice(0, 3)}&${mawb.slice(3, 11)}&/action,direct,Track!` : undefined;
  return validMAWB(mawb) ? `https://connect.track-trace.com/for/jjstransportation/aircargo/${mawb}/action,direct` : undefined;

}

export function convertAddress(a: Address): string {
  return isNullOrUndefined(a) ? '' : a.fullAddress;
//    a.name + '\n'+
//    a.addressLines + '\n' +
//    a.city + getState(a) + getWithComa(a.postCode);
}

export function fsrStatusTooltip(value): string {
  return "BKD: Booked for the given flight\n" +
    "RCS: Received from shipper or agent\n" +
    "MAN: Manifested for the specific flight\n" +
    "DEP: Departed on a specific flight\n" +
    "TFD: Transferred to another airline\n" +
    "RCT: Received from another airline\n" +
    "RCF: Received from a given flight\n" +
    "NFD: Arrived at destination and the consignee or agent has been informed\n" +
    "AWD: Arrival documents delivered to the consignee or agent\n" +
    "TRM: To be transferred to another airline\n" +
    "CCD: Cleared by Customs\n" +
    "DLV: Delivered to the consignee or agent\n" +
    "DIS: Discrepancy";
}

export function convertAddressForGPS(a: Address): string {
  return isNullOrUndefined(a) ? '' :
    (isNullOrUndefined(a.addressLines) ? "" : a.addressLines + '\n') +
    a.city + getState(a) + getWithComa(a.postCode);
}

export function getState(address: Address) {
  if (isNullOrUndefined(address.usaState)) {
    return "";
  }
  return ", " + address.usaState.name;
}

export function getWithComa(data: any) {
  return isNullOrUndefined(data) ? "" : ", " + data;
}

export function convertMasterCargoBuilding(m: Master, cargo: Address): string {

  let airportAbbr = isNullOrUndefined(m.airport) ? '' : m.airport.iataCode;
  let showAs = isNullOrUndefined(cargo) ? '' : (cargo.showAs || cargo.name).toLocaleUpperCase() + ':';
  let address = new Address();
  // address.id = this.shipment.addressDelivery.id;
  return showAs + airportAbbr;
}

export function convertValueFromArray(array: any[], getter: (item) => any, converter?: (value) => string, ifEmpty: any = OmsConstants.EMPTY_VALUE, ifMultiple: any = OmsConstants.MULTIPLE_VALUE): string {
  if (!array || !array.length) {
    return ifEmpty;
  }

  let result = null;
  let multiple: boolean = array.some((item) => {
    const value = getter ? getter(item) : item;

    if (isNullOrUndefined(result)) {
      result = value;
      return false;
    } else {
      return !equals(result, value);
    }

  });

  return multiple ? ifMultiple : isNullOrUndefined(result) ? ifEmpty : converter ? converter(result) : result;

}

export function valueOrNA(value) {
  return isNullOrUndefined(value) ? OmsConstants.EMPTY_VALUE : value;
}

export function valueOrNAFixed(value) {
  return toFixed(WeightUnits.fromDefault(value, OmsConstants.DEFAULT_WEIGHT_UNITS), OmsConstants.DEFAULT_WEIGHT_UNITS.digits) || OmsConstants.EMPTY_VALUE;
}

export function valueOrMultiple(value, converter: (any) => string) {
  if (isNullOrUndefined(value)) {
    return OmsConstants.EMPTY_VALUE;
  }

  if (typeof value === 'string') {
    if (value === '') {
      return OmsConstants.EMPTY_VALUE;
    }

    let arr = value.split('|').filter((s) => s.length > 0).unique();
    let l = arr.length;

    return l === 0 ? OmsConstants.EMPTY_VALUE : l > 1 ? OmsConstants.MULTIPLE_VALUE : converter(arr[0]);
  }

  return converter(value);
}

export function valueOrMultipleValues(value, converter: (any) => string) {
  return isNullOrUndefined(value) ? null : typeof value === 'string' && value.indexOf('|') > 0 ?
      value.split('|').unique().map((s) => converter(s)).join('\n') : converter(value);
}


export function safelyGetter(item: any, fields: string, defaultValue = 'N/A'): string {
  let value = fields
    .split('.')
    .reduce((result, fieldName) => result && result[fieldName], item);
  return value || defaultValue;
}

@Injectable({providedIn: 'root'})
export class OmsConverters {
  convertMawbNumber = convertMawbNumber;
  convertAction = convertAction;

  convertDate(date: Date): string {return convertDate(date); }
  convertDateTime(date: Date): string {return convertDateTime(date); }
//  convertMasterNumber(m:Master):string {return convertMasterNumber(m.id);}

  convertMasterNumber = (m: Master) => convertMasterNumber(m.id);
}
