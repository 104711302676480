<oms-base-dialog [readonly]="readonly" [title]="'Dispatch&hellip;'" [icon]="'fa fa-truck'" (ok)="onOk($event)" [width]="'650px'">

  <div class="row">
    <div class="form-group col-xs-6">
        <oms-date-input [disabled]="readonly" [label]="'Est. Pickup Date'" [(ngModel)]="disp.datePickupEst" [cleanable]="false" [show-checkbox]="false" required></oms-date-input>
        <oms-pieces-input [readonly]="readonly" [label]="'PCS'" [(ngModel)]="disp.pcs" required [minCount]="1"></oms-pieces-input>
        <oms-pieces-input [readonly]="readonly" [label]="'HU'" [(ngModel)]="disp.hu" required></oms-pieces-input>
    </div>
    <div class="form-group col-xs-6">
      <oms-custom-search label="Driver" required
                         [(ngModel)] = "disp.driver"
                         (create)="onCreateDriver($event)"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="fullName"
                         [allowCreate]="true"
                         [service]="driverService">

        <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.firstName}} {{item.lastName}}</div>
        </ng-template>
      </oms-custom-search>

      <oms-custom-search label="Truck #" required
                         [(ngModel)] = "disp.truck"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         (create)="onCreateTruck($event)"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="truckService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.number}}</div>
        </ng-template-->
      </oms-custom-search>

      <oms-custom-search label="Trailer #"
                         [(ngModel)] = "disp.trailer"
                         (create)="onCreateTrailer($event)"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="trailerService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.number}}</div>
        </ng-template-->
      </oms-custom-search>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-xs-6">
      <oms-address-search
        [size]="Size.SMALL" [iconClass]="'fa fa-industry'"
        [(address)]="disp.fromAddress"
        [label]="disp.nonAMS ? 'Pick Up' : 'Cargo Bldg'"
        [addressTypes]="[AddressType.CARGO_BUILDING, AddressType.CFS_3PL_LOCATION]"
        (addressChange)="onPickupLocationChange($event)">

        <oms-checkbox label="NON-AMS" [(checked)]="nonAMS" class="control-checkbox"></oms-checkbox>
      </oms-address-search>
    </div>


    <div class="form-group col-xs-6">
      <oms-address-search [size]="Size.SMALL" [iconClass]="'fa fa-industry'"
                          [(address)]="disp.toAddress"
                          [addressTypes]="masterRecoveryToAddressTypes"
                          [items]="masterAddressesRecoveryToDefault"
                          [label]="disp.direct ? 'Delivery' : 'CFS Location'"
                          placeholder="select from List or create...">
        <oms-checkbox label="DIRECT" [(checked)]="direct" class="control-checkbox"></oms-checkbox>
      </oms-address-search>
    </div>
  </div>

  <div class="row" *ngIf="cancelEnable">
    <hr/>
    <div class="btn btn-lg btn-danger" (click)="onCancelDispatch()" style="width: 100%" >Cancel Dispatch</div>
  </div>

  <ng-container widget-footer>
    <oms-checkbox style="display: block; padding-top: 5px" label="Generate PTT" [(checked)]="data.generatePtt" ></oms-checkbox>
  </ng-container>
</oms-base-dialog>


