﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget" [ngClass]="{ '_loading' : viewLoading, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">
    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-industry"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>


    <form class="widget-content" [formGroup]="form">
      <div class="form-row">
        <div class="input-group">
          <label class="control-label">Customer Name</label>
          <input class="form-control" placeholder="Customer Name" formControlName="name" />
          <div *ngIf="isControlInvalid('name')" class="invalid-feedback">Customer Name is required</div>
        </div>
      </div>
    </form>

    <div class="widget-footer right">
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="viewLoading">OK</button>
      <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
    </div>

  </div>
</perfect-scrollbar>
