import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {OmsConstants} from '../../../../../../common/oms-constants.service';
import {ValueAccessorBase} from '../../../base/value-accessor';
import {MeasureUnit, CurrencyUnits} from '../../../../../../common/oms-unit-types';
import {checkNumber} from '../../../../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {Size} from '../../../../../../common/oms-types';
import {HistoryInformationConfig} from "../../../../../../components/common/history-information-dialog";

@Component({
  selector: 'oms-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.css'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: CurrencyInputComponent, multi: true}]
})
export class CurrencyInputComponent extends ValueAccessorBase<number> {
  Size = Size;

  @Input() label: string = 'Amount';
  @Input() size: Size = Size.DEFAULT;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() forceChangeUnits: boolean = false;
  @Input() history: HistoryInformationConfig;

  public units: MeasureUnit = this._constants.currencyUnits.default;
  private inputValue: number; // value in visible units

  constructor(private cdr: ChangeDetectorRef, public _constants: OmsConstants) {
    super();
  }

  private toDisplay(value) {return checkNumber(CurrencyUnits.fromDefault(value, this.units)); }
  private toDefault(value) {return checkNumber(CurrencyUnits.toDefault(value, this.units)); }


  get internal() {
    if (isNullOrUndefined(this.inputValue)) {
      this.inputValue = this.toDisplay(super.getValue());
    }
    return this.inputValue;
  }

  set internal(value) {
    if (this.inputValue !== value) {
      this.inputValue = value;
      super.setValue(this.toDefault(value));
    }
  }

  onUnitsChanged(event) {
    if (this.forceChangeUnits/* this.disabled || this.readonly*/) {
      // Assume that user looks at value - convert input value to selected units
      this.inputValue = this.toDisplay(super.getValue());
      this.cdr.detectChanges();
    } else {
      super.setValue(this.toDefault(this.inputValue));
      this.cdr.detectChanges();
    }
  }

  protected setValue(value: number) {
    this.inputValue = this.toDisplay(value);
    this.innerValue = value;
    this.cdr.detectChanges();
  }


}
