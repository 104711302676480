<perfect-scrollbar style="max-height: 90vh; overflow-y: scroll;">
  <form class="sb-form" *ngIf="uldForm" [formGroup]="uldForm">
    <div class="oms-modal widget">
      <div class="widget-header">
        <h3><i class="fa fa-box-open"></i>
          {{getTitle()}}
        </h3>
        <div class="btn-group widget-header-toolbar">
          <a class="btn-borderless btn-remove" (click)="closeDialog()">
            <i class="fa fa-times dialog-header-button"></i>
          </a>
        </div>
      </div>

      <div class="widget-content" style="align-content: start; min-height: 300px">

        <div class="form-row">
          <div class="col-md-6">
            <oms-string-input [label]="'ULD Count:'" formControlName="uldsCount" [disabled]="isCountAutomated()" (change)="changeUldCount()" numbersOnly="true"></oms-string-input>
          </div>
        </div>

        <div class="form-row" formArrayName="ulds">
          <div class="col-md-11">
            <label class="control-label">ULD Numbers:</label>
          </div>
          <div *ngFor="let item of formData.controls; let i = index;" [formGroupName]="i" class="form-row" >

            <div class="col-md-11">
              <div class="form-group">
                <div class="input-group" style="width: 100%">
                <input type="text" title="" class="form-control"
                       (change)="changeULDNumber()"
                       formControlName="value">
                  <validation class="error-message-uld" *ngIf="isControlInvalid(i)" [messages]="getUldErrorMessage(i)"></validation>
                </div>
              </div>
            </div>
            <div class="col-md-1" *ngIf="isDisplayCheckbox"
              style="padding-left: 0; padding-right: 0">
              <div class="form-group">
                <div class="input-group" style="width: 100%">
                  <oms-checkbox style="padding: 5px; display: block" [checked]="isSelectedUld(item.value.uld)" (checkedChange)="changeSelectedUld(item.value.uld, $event)"></oms-checkbox>
                </div>
              </div>
            </div>
            <!--<div class="col-md-1">
              <span class="delete-button" (click)="deleteItem(i)">
                <i class="fa fa-trash"></i>
              </span>
            </div>-->
          </div>
          <!--<div class="col-md-12">
            <button class="sb-form__combo-add" (click)="addULD()">
              <i class="fa fa-box-open"></i>Add ULD
            </button>
          </div>-->
        </div>
      </div>


      <div class="widget-footer">
        <div></div>

        <div class="dialog-btns">
          <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="false" *ngIf="!readonly"><i class="fa fa-save"></i>Save</button>
          <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</perfect-scrollbar>
