import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {Logger} from "../../../../../../../_helpers/logger";
import {InplaceEditorSettings} from "../../columns/column-types";
import {isNullOrUndefined} from "util";
import {isEmptyString} from "../../../../../../../_helpers/utils";
import {DataTableComponent} from "../../data-table.component";
import {
  composeMawbTrackLink,
  convertAddress,
  convertLoadNumber,
  convertMawbNumber,
  convertOrderNumber,
  getInvalidMAWBMessage,
  validMAWB
} from "../../../../../services/oms-converters.service";
import {DriverLoad, DriverOrderDispatch} from "../../../../../models/dispatch/driver-order-dispatch";
import {GenericTableCell} from "../mawb-table-cell/generic-table-cell";
import {Address, LoadType, LoadTypes, Order} from "../../../../../models";
import {ArrayUtils} from "../../../../../../../_helpers/array.utils";
import {ContextMenuService} from "ngx-contextmenu";
import {ManifestItem} from "../../../../../models/manifest/manifest-item";
import {Manifest} from "../../../../../models/manifest/manifest";
import {DispatchService} from "../../../../../services/dispatch/dispatch.service";
import {ManifestItemContextMenuComponent} from "../../../../../../dispatch/pages/manifest-create/manifest-item-context-menu/manifest-item-context-menu.component";
import {DriverRoutesLoadsTableData} from "./driver-routes-loads-table-data";
import {tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material";
import {NotesCenterDialogComponent} from "../../../../../../../components/common/notes-center-dialog";
import {OmsConstants} from "../../../../../../../common/oms-constants.service";
import {DateTimeService} from "../../../../../../../services/date-time.service";
import {OmsDialogsService} from "../../../../../../../components/common/oms-dialogs";

@Component({
  selector: 'driver-routes-loads-table-cell',
  templateUrl: './driver-routes-loads-table-cell.component.html',
  styleUrls: ['./driver-routes-loads-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({
  name: 'DriverRoutesLoadsTableCellComponent'
})
export class DriverRoutesLoadsTableCellComponent extends GenericTableCell<DriverOrderDispatch, DriverRoutesLoadsTableData> implements OnInit, OnDestroy, OnChanges {

  @ViewChild('textInput') textInput: ElementRef;
  @ViewChild('menu') menu: ManifestItemContextMenuComponent;

  public editing: boolean = false;

  public localValue: any;
  public error;

  private editOnFocus = false;

  private closeOnBlur: boolean = true;
  value: string;
  empty: boolean;
  editable: boolean;
  public display: any;
  rawValue: any;
  iconClass: string;
  hasIcon: boolean;
  isHidden: boolean;
  tooltip: string;
  placeholder: string;
  editor: InplaceEditorSettings;
  public rowObject: any;
  cellClass: string;
  cellButtonLink: string;
  cellButtonTooltip: string;
  cellButtonIcon: string;

  displayLoads: DriverLoad[] = [];
  manifest: Manifest = null;
  manifestItem: ManifestItem = null;

  constructor(
    @Inject(DataTableComponent) public table: DataTableComponent,
    private cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    private dispatchService: DispatchService,
    private dialog: MatDialog,
    private dialogs: OmsDialogsService,
    private dateTimeService: DateTimeService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
//    console.log('CHANGE CELL', changes);
    if (changes.column) {
      this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
    }
    if (changes.row || changes.column || changes.search) {
      this.updateObject();
    }
  }

  public updateObject() {
    super.updateObject();

    this.rowObject = this.column.rowObject(this.row);
    this.rawValue = this.column.getValue(this.rowObject);
    this.editable = this.column.editable(this.row);
    this.value = this.convert(this.rowObject, this.rawValue);
    this.empty = isNullOrUndefined(this.rawValue);
    this.iconClass = this.column.getIconClass(this.rowObject, this.rawValue);
    this.cellClass = this.column.getClass(this.rowObject, this.rawValue) || '';

    this.hasIcon = !isNullOrUndefined(this.iconClass);
    this.tooltip = this.column.getTooltip(this.rowObject);

    const editor = this.editor;
    this.placeholder = typeof editor.placeholder === 'function' ? editor.placeholder(this.row, this.rawValue) : editor.placeholder || '';

    this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);

    this.display = this.column.editable && isEmptyString(this.value) ? this.table.emptyValue : this.value;

    this.cellButtonIcon = validMAWB(this.rawValue) ? 'fa fa-up-right-from-square' : 'fa fa-exclamation-triangle red-font';
    this.cellButtonTooltip = getInvalidMAWBMessage(this.rawValue) || 'Track MAWB';
    this.cellButtonLink = composeMawbTrackLink(this.rawValue);

    this.displayLoads = (this.row ? ArrayUtils.removeDuplicate(this.row.loads, l => l.dispatchItemId) : [])
      .sort((l1, l2) => l1.dispatchItemOrderNumber - l2.dispatchItemOrderNumber);

    this.cdr.markForCheck();
  }

  private convert(rowObject, rawValue): string {
    // return this.column.convert(rowObject, rawValue);
    return convertMawbNumber(rawValue);
  }


  ngOnInit() {
    this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
    this.updateObject();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getTooltipForLoad(load: DriverLoad): string {
    let tooltip = LoadTypes.prefixOf(load.loadType) + " load, ";
    let dependItems = this.row.loads.filter(l => l.dispatchItemId === load.dispatchItemId);
    tooltip += dependItems.map(item => convertOrderNumber(item.orderId)).join(", ") + "\n";
    tooltip += "PCS: " + dependItems.reduce((total, i) => total + i.pcs, 0) + "\n";
    tooltip += "Weight: " + dependItems.reduce((total, i) => total + i.weight, 0) + " kg\n";
    tooltip += "Customer: " + (load.customer ? load.customer.name : "undefined") + "\n";
    tooltip += "To: " + (load.loadType === LoadType.PICKUP
      ? convertAddress(load.addressPickup)
      : convertAddress(load.addressDelivery));
    return tooltip;
  }

  public getFormatTime(date: Date): string {
    return this.dateTimeService.utcToNYFormat(date, OmsConstants.MOMENT_TIME_SHORT_FORMAT);
  }

  public onContextMenu($event: MouseEvent, item: DriverLoad) {
    this.receiveManifest(item)
      .subscribe((manifest) => {
        this.contextMenuService.show.next({
          contextMenu: this.menu.contextMenu,
          event: $event,
          item: null
        });
        this.cdr.markForCheck();
      });

    $event.preventDefault();
    $event.stopPropagation();
  }

  public onLeftClick(item: DriverLoad) {
    this.receiveManifest(item)
      .subscribe();
  }

  private receiveManifest(item: DriverLoad): Observable<Manifest> {
    this.manifest = null;
    this.manifestItem = null;
    return this.dispatchService.get(item.dispatchId)
      .pipe(tap(manifest => {
        this.manifest = manifest;
        this.manifestItem = manifest.items.find(mi => mi.id === item.dispatchItemId);
        this.cdr.markForCheck();
      }));
  }

  onManifestChange(manifest: Manifest): void {
    if (this.data && this.data.manifestChanged) {
      this.data.manifestChanged(manifest);
      this.cdr.markForCheck();
    }
  }

  onLoading(loading: boolean): void {
    if (this.data && this.data.loading) {
      this.data.loading(loading);
      this.cdr.markForCheck();
    }
  }

  onRefresh(): void {
    if (this.data && this.data.refresh) {
      this.data.refresh();
      this.cdr.markForCheck();
    }
  }

  convertOrderNumber = (orderId, isRecovery?) => convertOrderNumber(orderId, isRecovery);
  convertLoadNumber = (id) => convertLoadNumber(id);

  convertMawb(mawb: string): string {
    return convertMawbNumber(mawb);
  }

  public getDisplayAddressName(add: Address): string {
    if (!add) {
      return "";
    }
    return add.label + "/" + (add.city || "") + "/" + (add.usaState ? add.usaState.uspsCode : "");
  }

  public openDocuments(order: Order): void {
    this.dialogs.openDocCenterWindow({id: order.id, objectType: 'ORDER'});
  }

  public openComCenter(order: Order): void {
    const dialogRef = this.dialog.open(NotesCenterDialogComponent, {
      width: '80%',
      data: {id: order.id, objectType: 'ORDER', noteTypeId: 0}
    });
  }
}
