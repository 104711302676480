/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-file-download.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-file-download.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/file.upload.service";
var styles_PageFileDownloadComponent = [i0.styles];
var RenderType_PageFileDownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageFileDownloadComponent, data: {} });
export { RenderType_PageFileDownloadComponent as RenderType_PageFileDownloadComponent };
export function View_PageFileDownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "wrapper full-page-wrapper page-auth page-login text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "inner-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "center-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File download should start automatically. "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["if not, please click "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["following link"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ref; _ck(_v, 7, 0, currVal_0); }); }
export function View_PageFileDownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-page-file-download", [], null, null, null, View_PageFileDownloadComponent_0, RenderType_PageFileDownloadComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageFileDownloadComponent, [i3.ActivatedRoute, i4.FileUploadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageFileDownloadComponentNgFactory = i1.ɵccf("oms-page-file-download", i2.PageFileDownloadComponent, View_PageFileDownloadComponent_Host_0, {}, {}, []);
export { PageFileDownloadComponentNgFactory as PageFileDownloadComponentNgFactory };
