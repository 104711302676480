/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/tooltip";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "ngx-bootstrap/positioning";
import * as i5 from "./footer.component";
import * as i6 from "../../../../../services/auth.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" (", ")\u00A0", ""])), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Built: ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version.hash; var currVal_1 = _co.environment; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.dateStr; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.resolution; _ck(_v, 4, 0, currVal_3); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { footer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["footer", 1]], null, 6, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["JJS OMS \u00A9 2018"])), (_l()(), i1.ɵand(0, [["versionInfo", 2]], null, 0, null, View_FooterComponent_1)), (_l()(), i1.ɵeld(5, 16777216, null, null, 2, "div", [["class", "right"]], [[1, "aria-describedby", 0]], null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { placement: [0, "placement"], container: [1, "container"], htmlContent: [2, "htmlContent"] }, null), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = "top"; var currVal_2 = "body"; var currVal_3 = i1.ɵnov(_v, 4); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).ariaDescribedby; _ck(_v, 5, 0, currVal_0); var currVal_4 = _co.versionName; _ck(_v, 7, 0, currVal_4); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 245760, null, 0, i5.FooterComponent, [i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("oms-footer", i5.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
