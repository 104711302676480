import {BaseInputComponent} from "./base-input.component";
import {HostListener, Inject, Optional, ViewChild} from "@angular/core";
import {NgSelectComponent} from "@ng-select/ng-select";
import {isNullOrUndefined} from "util";
import {KeyCode} from "../../common/input/ng-select-types/ng-select.types";
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgModel} from "@angular/forms";


export abstract class BaseInputSelectComponent<T> extends BaseInputComponent<T> {
  @ViewChild(NgModel) model: NgModel;
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  isOpen: boolean = false;


  protected constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }


  public _handleOutsideClick($event: any): void {
    if (this.isOpen && this.isOutsideClick($event)) {
      this.isOpen = false;
    }
    if(isNullOrUndefined(this.innerValue)){
      this.ngSelect.filterValue = null;
    }
  }

  differentIdentifier(element): boolean {
    if (element.getAttribute("ng-reflect-append-to") === "body") {
      return false;
    }
    return element.getAttribute("ng-reflect-append-to") !== "#" + this.identifier;
  }

  toggleOpen($event) {
    let selectPanel = $event.target.closest("#" + this.identifier) && !$event.target.closest("ng-dropdown-panel");
    if (selectPanel) {
      this.isOpen = !this.isOpen;
    }
  }

  isOutsideClick($event: any): boolean {
    let dropdownPanel = $event.target.closest("ng-dropdown-panel");
    return isNullOrUndefined(dropdownPanel) || this.differentIdentifier(dropdownPanel);
  }

  @HostListener('keyup', ['$event'])
  handleKeyDown($event: KeyboardEvent) {
    if (KeyCode[$event.which]) {
      switch ($event.which) {
        case KeyCode.Space:
          this.isOpen = true;
          break;
        case KeyCode.Enter:
          if (this.ngSelect.openOnEnter && !this.ngSelect.hasValue) {
            this.isOpen = true;
          }
          break;
        case KeyCode.Tab:
          this.isOpen = !this.isOpen;
          break;
        case KeyCode.Esc:
          this.isOpen = !this.isOpen;
          break;
      }
    }
  }
}



