import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { AuthService, OrdersService } from '../../../services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MasterService } from '../../../services/master/master.service';
import { Master, MasterAir } from '../../../modules/shared/models';
import { convertMasterNumber, convertMawbNumber } from '../../../modules/shared/services/oms-converters.service';
import { isNullOrUndefined } from 'util';
import { MasterImportAirComponent } from "./master-import-air/master-import-air.component";
import { WebSocketService } from '../../../services/websocet/web.socket.service';
import { MasterLineService } from '../../../services/master/master-line.service';
import { LocationBackService } from '../../../services/location-back.service';
import { DialogType, ModalResult, OmsDialogsService } from "../../../components/common/oms-dialogs";
import { UserService } from "../../../modules/shared/services/user.service";
import { assigned } from "../../../_helpers/utils";
import { Manifest } from "../../../modules/shared/models/manifest/manifest";
import { DispatchService } from "../../../modules/shared/services/dispatch/dispatch.service";
import { DispatchUtils } from "../../../_helpers/dispatch-utils";
import { NgxSpinnerService } from "ngx-spinner";
import { OmsColumns } from "../../../common/oms-columns.service";
import { MasterProcessor } from "../../../common/master-processor";
var MasterEditorComponent = /** @class */ (function () {
    /*
      public masterSubject: Subject<Master> = new Subject<Master>();
    */
    function MasterEditorComponent(alerts, dialogs, cdr, webSocketService, location, authService, route, orderService, masterService, masterLineService, locationBackService, userService, dispatchService, dispatchUtils, spinner, _columnsService, masterProcessor) {
        this.alerts = alerts;
        this.dialogs = dialogs;
        this.cdr = cdr;
        this.webSocketService = webSocketService;
        this.location = location;
        this.authService = authService;
        this.route = route;
        this.orderService = orderService;
        this.masterService = masterService;
        this.masterLineService = masterLineService;
        this.locationBackService = locationBackService;
        this.userService = userService;
        this.dispatchService = dispatchService;
        this.dispatchUtils = dispatchUtils;
        this.spinner = spinner;
        this._columnsService = _columnsService;
        this.masterProcessor = masterProcessor;
        this.webSocketEventName = '/data-table/masters';
        this.refreshLoading = false;
        this.clientUser = false;
        this.showRefreshStatus = false;
        this.sub = [];
        this.isDeleteAvailable = false;
        this.widthFirstPanel = 100;
        this.widthSecondPanel = 0;
        this.prevWidthFirstPanel = 35;
        this.prevWidthSecondPanel = 65;
        this.manifestForRightPanel = new Manifest();
        this.orderDispatchLoads = [];
    }
    MasterEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.isClientOnly.subscribe(function (clientUser) { return _this.clientUser = clientUser; });
        this.userService.getCurrentUser()
            .subscribe(function (user) {
            _this.isDeleteAvailable = user.canDeleteOrder;
            _this.sub.push(_this.route.queryParams.subscribe(function (params) {
                var id = ~~params['id'];
                if (id) {
                    _this.loadMaster(id);
                }
                else {
                    _this.newMaster(params['new']);
                }
            }));
        });
        this.sub.push(this.masterService.refreshDataRequired.subscribe(function (master) {
            if (_this.master.id === master.id) {
                _this.loadMaster(master.id);
            }
        }));
        this.sub.push(this.webSocketService.stompClientSubject.subscribe(function (stompClient) {
            if (!isNullOrUndefined(stompClient)) {
                var that_1 = _this;
                _this.socketSub = stompClient.subscribe(_this.webSocketEventName, function (message) {
                    var messageObject = JSON.parse(message.body);
                    if (messageObject.operation === 'UPDATE') {
                        if (that_1.master.id === messageObject.id) {
                            // !!!              that.refreshLoading = false;
                            // !!!              that.loadMaster(that.master.id);
                            //              console.log('UPDATE', messageObject.field, messageObject.updatedValue);
                            //              that.master = MasterService.afterLoad(plainToClass(Master, <Object>messageObject.updatedValue));
                            //              console.log('UPDATE2', that.master);
                            //              that.cdr.detectChanges();
                        }
                    }
                    if (messageObject.operation === 'UPDATE_FIELD') {
                        if (that_1.master.id === messageObject.id) {
                            console.log('UPDATE_FIELD', messageObject.field, messageObject.updatedValue);
                            that_1.master[messageObject.field] = messageObject.updatedValue;
                            that_1.cdr.detectChanges();
                        }
                    }
                });
            }
        }));
        this.openManifestFromShipment();
        this.openManifestFromOrder();
    };
    MasterEditorComponent.prototype.initCanDeleteOrder = function () {
        this.isDeleteAvailable = this.authService.canDeleteOrder();
    };
    Object.defineProperty(MasterEditorComponent.prototype, "title", {
        get: function () {
            return !this.master ? null : (this.master.isNew() ? "New Master" : convertMasterNumber(this.master.id));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterEditorComponent.prototype, "subTitle", {
        get: function () {
            return assigned(this.master) ? (assigned(this.master.dateDeleted) ? 'Air Import (DELETED)' : 'Air Import') : "N/A";
        },
        enumerable: true,
        configurable: true
    });
    MasterEditorComponent.prototype.onSave = function (back) {
        this.childContent.onSave(back);
    };
    MasterEditorComponent.prototype.masterChanged = function (master) {
        this.loadMaster(master.id);
        //    this.master = master;
    };
    MasterEditorComponent.prototype.masterUpdated = function (master) {
        this.master = master;
        this.cdr.detectChanges();
    };
    MasterEditorComponent.prototype.loadMaster = function (id) {
        var _this = this;
        console.log('Loading Master ', id);
        this.masterService.getMaster(id, !this.isDeleteAvailable)
            .then(function (master) {
            if (!master.masterAir) {
                master.masterAir = new MasterAir();
                master.masterAir.master = master;
                console.log('Restored 1', master);
            }
            console.log('Master Loaded ', master);
            _this.masterUpdated(master);
            if (_this.refreshLoading) {
                _this.alerts.info("Refreshed");
            }
            _this.refreshLoading = false;
        }, function (error) {
            _this.alerts.danger(error);
            _this.refreshLoading = false;
        });
    };
    MasterEditorComponent.prototype.newMaster = function (type) {
        this.master = Master.newMaster(type);
    };
    MasterEditorComponent.prototype.refresh = function () {
        this.refreshLoading = true;
        this.loadMaster(this.master.id);
    };
    MasterEditorComponent.prototype.ngOnDestroy = function () {
        this.sub.forEach(function (item) { return item.unsubscribe(); });
        if (this.socketSub) {
            this.socketSub.unsubscribe();
        }
    };
    MasterEditorComponent.prototype.onRestore = function () {
        var _this = this;
        if (!this.master || !this.master.isDeleted()) {
            return;
        }
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Restore Master?', 'Master: ' + this.master.masterNumber + ' ' + convertMawbNumber(this.master.mawbNumber) + '\nwill be restored. Continue?')
            .then(function (result) {
            if (result.result === ModalResult.YES) {
                _this.masterService.restore(_this.master.id)
                    .then(function () {
                    _this.alerts.info("Master " + _this.master.masterNumber + " was restored.");
                    _this.loadMaster(_this.master.id);
                    // this.masterLineService.refreshByLastPageRequest();
                    // this.locationBackService.back$.next('delete');
                })
                    .catch(function (error) { return _this.alerts.danger(error); });
            }
        });
    };
    MasterEditorComponent.prototype.onDelete = function () {
        var _this = this;
        if (!this.master || this.master.isNew()) {
            return;
        }
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Delete Master?', 'Master: ' + this.master.masterNumber + ' ' + convertMawbNumber(this.master.mawbNumber) + '\nwill be deleted. Continue?').then(function (result) {
            if (result.result === ModalResult.YES) {
                _this.masterService.softDelete(_this.master.id)
                    .then(function () {
                    _this.alerts.info("Master " + _this.master.masterNumber + " was deleted.");
                    _this.loadMaster(_this.master.id);
                    //            this.location.back();
                    //            this.masterLineService.refreshByLastPageRequest();
                    //            this.locationBackService.back$.next('delete');
                })
                    .catch(function (error) { return _this.alerts.danger(error); });
            }
        });
    };
    MasterEditorComponent.prototype.changePrev = function (first, second) {
        if (first <= 90) {
            this.prevWidthFirstPanel = first;
            this.prevWidthSecondPanel = second;
        }
    };
    MasterEditorComponent.prototype.dragEnd = function (event) {
        this.widthFirstPanel = event.sizes[0];
        this.widthSecondPanel = event.sizes[1];
        this.changePrev(event.sizes[0], event.sizes[1]);
    };
    MasterEditorComponent.prototype.onManifestChange = function (manifest) {
        var items = this.orderDispatchLoads.filter(function (dispatchDto) { return manifest.items.some(function (manifestItem) { return manifestItem.hasOrder(dispatchDto.orderId); }); });
        this.setSelected(items);
        this.updateRightPanel();
    };
    MasterEditorComponent.prototype.onDispatchListSelectionChange = function (list) {
        var _this = this;
        this.orderDispatchLoads = list;
        list.forEach(function (od) {
            var created = _this.manifestForRightPanel.items.find(function (mi) { return mi.orders.some(function (o) { return o.id === od.orderId; }); });
            if (!created) {
                //   this.setOrderDispatchValuesFromOrder(od);
                _this.dispatchUtils.manifestAddOrderDispatch(_this.manifestForRightPanel, od);
            }
        });
        // remove from new Items only
        var manifestItems = this.manifestForRightPanel.items.filter(function (i) { return i.isNew(); });
        manifestItems.forEach(function (mi) {
            mi.orders.forEach(function (o) {
                var selected = list.find(function (od) { return od.orderId === o.id; });
                if (!selected) {
                    _this.dispatchUtils.manifestDeleteOrder(_this.manifestForRightPanel, o);
                }
            });
        });
        this.setDispatchListToRightPanel(this.manifestForRightPanel.items.slice());
    };
    MasterEditorComponent.prototype.setDispatchListToRightPanel = function (items) {
        this.manifestForRightPanel.items = items;
        this.manifestForRightPanel = Object.assign(new Manifest(), this.manifestForRightPanel);
        this.updateRightPanel();
    };
    MasterEditorComponent.prototype.setSelected = function (items) {
        this.orderDispatchLoads = items;
    };
    MasterEditorComponent.prototype.updateRightPanel = function () {
        var list = this.manifestForRightPanel.items;
        if (!list.length && this.widthFirstPanel < 100) {
            this.changePrev(this.widthFirstPanel, this.widthSecondPanel);
            this.widthFirstPanel = 100;
            this.widthSecondPanel = 0;
        }
        else if (list.length && this.widthFirstPanel === 100) {
            this.widthFirstPanel = this.prevWidthFirstPanel;
            this.widthSecondPanel = this.prevWidthSecondPanel;
        }
    };
    MasterEditorComponent.prototype.onCreatedEvent = function (manifest) {
        this.manifestForRightPanel = new Manifest();
        this.setSelected([]);
        this.setDispatchListToRightPanel([]);
        this.refresh();
    };
    MasterEditorComponent.prototype.openManifestFromShipment = function () {
        var _this = this;
        this._columnsService.shipmentFromMasterEdit$.subscribe(function (id) {
            if (id) {
                _this.dispatchService.findOrderDispatchesFromShipment(id).then(function (od) {
                    if (od) {
                        _this.openManifest(od.id);
                    }
                });
            }
        });
    };
    MasterEditorComponent.prototype.openManifest = function (dispatchId) {
        var _this = this;
        if (dispatchId) {
            this.spinner.show();
            console.log('openManifest');
            this.dispatchService.get(dispatchId)
                .subscribe(function (manifest) {
                _this.spinner.hide();
                console.log('manifest received', manifest);
                manifest.items.forEach(function (mi) {
                    mi.loadTypeOriginal = mi.loadType;
                    mi.orders.forEach(function (o) {
                        mi.loadTypeOriginal = mi.loadType;
                        o.info.legAddressDelivery = mi.addressDelivery;
                    });
                });
                _this.manifestForRightPanel = manifest;
                _this.updateRightPanel();
                _this.cdr.detectChanges();
            }, function (error) {
                _this.spinner.hide();
                _this.alerts.error(error, 'Error open Manifest');
            });
        }
    };
    MasterEditorComponent.prototype.openManifestFromOrder = function () {
        var _this = this;
        if (this.masterProcessor) {
            this.masterProcessor.orderFromMasterEditor$.subscribe(function (orderId) {
                if (orderId) {
                    _this.ordersChangeSelect([orderId]);
                }
            });
        }
    };
    MasterEditorComponent.prototype.ordersChangeSelect = function (ids) {
        var _this = this;
        this.spinner.show();
        this.dispatchService.findDispatchesForOrders(ids).then(function (list) {
            _this.spinner.hide();
            _this.onDispatchListSelectionChange(list);
            _this.cdr.detectChanges();
        }).catch(function (error) {
            _this.spinner.hide();
            _this.alerts.error(error);
        });
    };
    return MasterEditorComponent;
}());
export { MasterEditorComponent };
