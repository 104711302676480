import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";
import {Shipment} from "../modules/shared/models";

const API_URL = "/oms/shipment/";
@Injectable()
export class ShipmentService {

  public shipmentsSubject = new Subject<Shipment[]>();

  constructor(private httpRestService: RestService, private httpUtils: HttpUtilsService) { }

  getAllShipments() {
    this.httpRestService.get<Shipment[]>(API_URL + 'getAllShipments').subscribe(items => {
      this.shipmentsSubject.next(items);
    });
  }

  create(item: Shipment): Observable<Shipment> {
    return this.httpRestService.post<Shipment>(API_URL + 'create', item);
  }

  update(item: Shipment): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(API_URL + 'update', item, { headers: httpHeader });
  }

  delete(id: number): Observable<Shipment> {
    return this.httpRestService.delete<Shipment>(API_URL + 'delete', id);
  }
}
