import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";

@Injectable({
  providedIn: 'root'
})

export class GlobalSearchService {
  public subject: BehaviorSubject<String> = new BehaviorSubject<String>(undefined);

  constructor() { }

  public search(text: String) {
    this.subject.next(text);
  }

  public clear() {
    this.subject.next(undefined);
  }

  get text() {
    return this.subject.value;
  }

  set text(text: String) {
    this.search(text);
  }
}
