﻿import { NgModule} from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SidebarModule } from 'ng-sidebar';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule
} from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from "@angular/material/icon";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import {ErrorInterceptor, AuthGuard } from './_helpers';
import {FilterService} from './services';
import { TestPageComponent } from './pages/test-page';
import { LoginComponent } from './pages/login';
import { RegisterComponent } from './pages/register';
import {OrdersService} from './services';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {OrderFiltersComponent} from './pages/orders/order-filters';
import {OrderActionsComponent} from './pages/orders/order-actions';
import {NavigationService} from './services';
import {OrderFilterComponent} from './components/common/order-filter';
import {ContextMenuModule} from 'ngx-contextmenu';
import { NgDragDropModule } from 'ng-drag-drop';
import {AccountService} from './services';
import {RestService} from './services/rest.service';
import {ExportAirComponent} from './pages/air/export';
import {ImportAirComponent} from './pages/air/import';
import {CustomerService} from './services/customer.service';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {CustomerDialogComponent} from './components/common/customer-dialog/customer.dialog.component';
import {HttpUtilsService} from './services/http-utils.service';
import {MatDialogModule} from '@angular/material/dialog';
import {AirlineWarehouseService} from './services/air/airline.warehouse.service';
import {AirportService} from './services/air/airport.service';
import {MasterAirService} from './services/master/master.air.service';
import {MasterService} from './services/master/master.service';
import {NgxMaskModule} from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import 'reflect-metadata';
import 'es6-shim';
import {ShipmentContentService} from './services/shipment.content.service';
import {ShipmentService} from "./services/shipment.service";
import {LoadDialogComponent} from "./components/dialogs/load-dialog";
import {NumbersOnlyDirective} from "./_helpers/numbers-only.directive";
import {FileUploadModule} from "ng2-file-upload";
import {FileUploadService} from "./services/file.upload.service";
import {
  ContainerSizes, CurrencyUnits,
  DocumentTypeUnits,
  NoteType,
  PackagingUnits,
  VolumeUnits,
  WeightUnits
} from './common/oms-unit-types';
import {NgSelectModule} from '@ng-select/ng-select';
import {FileUploadDialogComponent} from './components/common/file-upload-dialog';
import { MastersListComponent } from './pages/masters/masters-list/masters-list.component';
import {ToolbarComponent} from './components/common/toolbar/toolbar.component';
import {ComCenterComponent} from "./components/common/com-center";
import {NgxSummernoteModule} from "ngx-summernote";
import { OmsAccordionPanelComponent } from './components/common/oms-accordion-panel/oms-accordion-panel.component';
import {ComCenterService} from "./services/com-center.service";
import {MasterStatusService} from './modules/shared/models';
import {CustomerGroupService} from "./services/customer.group.service";
import {CustomerGroupDialogComponent} from "./components/common/customer-group-dialog";
import {CustomerGroupSearchComponent} from "./components/common/customer-group-search";
import {DataTableService} from "./services/data-table.service";
import {NotesCenterComponent} from "./components/common/notes-center";
import {NotesCenterDialogComponent} from "./components/common/notes-center-dialog";
import {WebSocketService} from "./services/websocet/web.socket.service";
import {DndModule} from "ng2-dnd";
import {ColorSearchSelectComponent} from "./components/common/color-search-select/color.search.select.component";
import {StickyDirective} from './modules/shared/components/base/data-table/columns/sticky-directive';
import {NoteCenterTypeFilterPype} from "./_helpers/note.center.type.filter.pype";
import {LogChangesService} from 'src/app/services/logchanges/log.changes.service';
import {HistoryInformationDialogComponent} from './components/common/history-information-dialog';
import { ProblemDialogComponent } from './components/common/problem-dialog/problem-dialog.component';
import { ConfirmDialogComponent } from './components/common/oms-dialogs/confirm-dialog/confirm-dialog.component';
import {CustomFilterDialogComponent} from "./components/common/custom-filter-dialog";
import {CommonFiltersComponent} from './components/common/common-filters';
import {NgxSpinnerModule} from "ngx-spinner";
import {ProblemsHistoryInformationDialogComponent} from './components/common/problems-history-information-dialog';
// import { ReceivingComponent } from './pages/receiving/receiving.component';
import { LoadDispatchDialogComponent } from './components/dialogs/load-dispatch-dialog/load-dispatch-dialog.component';
import {MasterProcessor} from './common/master-processor';
import {BnNgIdleService} from "bn-ng-idle";
import {AuthInterseptor} from "./services/auth.interseptor";
import {FreightForwarderService} from "./services/freight.forwarder.service";
import {FreightForwarderDialogComponent} from "./components/common/freight-forwarder-dialog";
import {MasterPttCreatorDialogComponent} from "./components/common/master-ptt-creator-dialog";
import {PickupComponent} from "./pages/pickup/pickup.component";
import {TruckService} from './services/truck-service';
import { TruckDialogComponent } from './components/dialogs/truck-dialog/truck-dialog.component';
import { TrailerDialogComponent } from './components/dialogs/trailer-dialog/trailer-dialog.component';
import {TrailerService} from './services/trailer-service';
import {DriverService} from './services/driver-service';
import {MasterLineService} from './services/master/master-line.service';
import {ClickOutsideModule} from "ng-click-outside";
import {SharedModule} from "./modules/shared/shared.module";
import {AgmCoreModule} from "@agm/core";
import {IfRoleDirective} from './_helpers/if-role.directive';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {CbpStatusDialogComponent} from './components/common/cbp-status-dialog/cbp-status-dialog.component';
import {MastersRecoveryListComponent} from './pages/masters/masters-recovery-list/masters-recovery-list.component';
import {MastersDispatchDialogComponent} from './components/dialogs/masters-dispatch-dialog/masters-dispatch-dialog.component';
import {ReceivingService} from './services/receiving.service';
import { InputTextDialogComponent } from './components/common/oms-dialogs/input-text-dialog/input-text-dialog.component';
import {OrdersDispatchDialogComponent} from './components/dialogs/orders-dispatch-dialog/orders-dispatch-dialog.component';
import {DriverDialogComponent} from './components/dialogs/driver-dialog/driver-dialog.component';
import {CfsLocationDialogComponent} from './components/dialogs/cfs-location-dialog/cfs-location-dialog.component';
import {LocationBackService} from './services/location-back.service';
import {DispatchAndRecoveryService} from './modules/shared/services/logs/dispatch-and-recovery/dispatch.and.recovery.service';
import {RecoveryAndDispatchLogDialogComponent} from './components/common/recovery-and-dispatch-log-dialog/recovery-and-dispatch-log-dialog.component';
import {MawbTrackDialogComponent} from './components/common/mawb-track-dialog/mawb-track-dialog.component';
import { ReceivingLogDialog } from './components/dialogs/logs/receiving-log-dialog/receiving-log-dialog.component';
import {CbpQuickViewStatusDialogComponent} from './components/common/cbp-quick-view-status-dialog/cbp-quick-view-status-dialog.component';
import { LoadPickupDialogComponent } from './components/dialogs/load-pickup-dialog/load-pickup-dialog.component';
import {ModalPositionCache} from './components/common/oms-dialogs/utils/modal-position-cache';
import {DispatchRouteDialogComponent} from "./modules/dispatch/components/dispatch-route-dialog/dispatch-route-dialog.component";
import {AngularSplitModule} from "angular-split";
import {ReportService} from "./services/report.service";
import {ReportDialogComponent} from "./components/dialogs/report-dialog/report-dialog.component";
import {DateTimeService} from "./services/date-time.service";
import {DriverGpsService} from "./services/driver-gps-service";
import {AddressNewService} from "./services/address-new-service";
import {UldService} from "./services/uld.service";
import {BsDatepickerModule, DatepickerModule} from "ngx-bootstrap/datepicker";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {TabsModule} from "ngx-bootstrap/tabs";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {TooltipConfig, TooltipModule} from "ngx-bootstrap/tooltip";
import {AlertModule} from "ngx-bootstrap/alert";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {PopoverModule} from "ngx-bootstrap/popover";
import {FreightForwarderGroupService} from "./services/freight.forwarder.group.service";
import {IndeterminateDirective} from "./_helpers/indeterminate.directive";
import {DispatchColumnsService} from "./modules/dispatch/pages/dispatch-planning/dispatch-columns.service";
import { OrdersReportFiltersComponent } from './components/dialogs/report-dialog/filters/orders-report-filters/orders-report-filters.component';
import { RoutesReportFiltersComponent } from './components/dialogs/report-dialog/filters/routes-report-filters/routes-report-filters.component';
import { MastersReportFiltersComponent } from './components/dialogs/report-dialog/filters/masters-report-filters/masters-report-filters.component';
import { ReportFilterComponent } from './components/dialogs/report-dialog/filters/report-filter/report-filter.component';
import {OrderWarehouseService} from "./services/order-warehouse.service";
import {RestrictedDirective} from "./_helpers/restricted.directive";
import {PagePermissionDeniedComponent} from "./pages/page-permission-denied/page-permission-denied.component";
import {RedirectRoleAuthGuard} from "./_helpers/redirect-role-auth.guard";
import {OrderNotificationService} from "./services/order-notification/order-notification.service";
import {OrderNotificationAlertComponent} from "./modules/shared/components/oms-alerts/order-notification-alert/order-notification-alert.component";
import {OmsAlertsService} from "./modules/shared/components/oms-alerts/oms-alerts.service";
import {ReasonCodeDialogComponent} from "./components/dialogs/reason-code-dialog/reason-code-dialog.component";
import {ReasonCodeService} from "./services/reason-code-service";
import {StatusInfoComponent} from "./pages/status-info/status-info.component";
import {AlertDialogComponent} from "./components/common/oms-dialogs/alert-dialog/alert-dialog.component";
import {VideoDialogComponent} from "./components/dialogs/video-dialog/video-dialog.component";
import {WebcamModule} from "ngx-webcam";
import { CbpStatusTableComponent } from "./components/common/cbp-status-dialog/cbp-status-table/cbp-status-table.component";
import { PageFileDownloadComponent } from './pages/page-file-download/page-file-download.component';
import {WebDriversService} from "./services/web-drivers.service";
import {SignaturePadDialogComponent} from "./components/dialogs/signature-pad-dialog/signature-pad-dialog.component";
import {SignaturePadModule} from "angular2-signaturepad";
import {BillingService} from "./services/billing.service";
import {WindowComponent} from './components/common/window/window.component';
import {ManifestCreatePageComponent} from "./modules/dispatch/pages/manifest-create-page/manifest-create-page.component";
import {OmsMapsPageComponent} from "./modules/dispatch/pages/oms-maps-page/oms-maps-page.component";
import {tooltipConfig} from "./common/oms-constants.service";
import {WINDOW_PROVIDERS} from "./common/window.providers";
import { PendingRecoveryReportFiltersComponent } from './components/dialogs/report-dialog/filters/pending-recovery-report-filters/pending-recovery-report-filters.component';
import {PublicApiService} from "./services/public-api.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
    imports: [
      BrowserModule,
      PerfectScrollbarModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      MatIconModule,
      BrowserAnimationsModule,
      OwlDateTimeModule,
      NgSelectModule,
      ClickOutsideModule,
      OwlNativeDateTimeModule,
      MatMenuModule,
      MatStepperModule,
      MatDialogModule,
      MatTabsModule,
      NgxSpinnerModule,
      MatProgressBarModule,
      AngularSplitModule.forRoot(),
      routing,
      TextMaskModule,
      NgxMaskModule.forRoot(),
      AccordionModule.forRoot(),
      CollapseModule.forRoot(),
      BsDatepickerModule.forRoot(),
      BsDropdownModule.forRoot(), TimepickerModule.forRoot(), DatepickerModule.forRoot(),
      TooltipModule.forRoot(),
      TabsModule.forRoot(), AlertModule.forRoot(),
      ContextMenuModule.forRoot(),
      NgDragDropModule.forRoot(),
      SidebarModule.forRoot(),
      PopoverModule.forRoot(),
      PaginationModule.forRoot(),
      DndModule.forRoot(),
      FileUploadModule,
      NgxSummernoteModule,
      SharedModule,
      WebcamModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatOptionModule,
//      WarehouseModule,
      SignaturePadModule,
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyCiTdsf8e2dm-lk5oAdNjnIAPG319tKviE',
        language: 'en',
        libraries: ['places']
      })

    ],
    exports: [StickyDirective, IfRoleDirective, RestrictedDirective, NumbersOnlyDirective, IndeterminateDirective],
    entryComponents: [
      FileUploadDialogComponent,
      LoadDialogComponent,
      LoadDispatchDialogComponent,
      LoadPickupDialogComponent,
      MastersDispatchDialogComponent,
      OrdersDispatchDialogComponent,
      HistoryInformationDialogComponent,
      ProblemsHistoryInformationDialogComponent,
      CustomerDialogComponent,
      FreightForwarderDialogComponent,
      MasterPttCreatorDialogComponent,
      NotesCenterDialogComponent,
      ProblemDialogComponent,
      CbpStatusDialogComponent,
      CbpQuickViewStatusDialogComponent,
      MawbTrackDialogComponent,
      DispatchRouteDialogComponent,
      RecoveryAndDispatchLogDialogComponent,
      ReceivingLogDialog,
      ConfirmDialogComponent,
      InputTextDialogComponent,
      CustomerGroupDialogComponent,
      CustomFilterDialogComponent,
      TruckDialogComponent,
      TrailerDialogComponent,
      DriverDialogComponent,
      CfsLocationDialogComponent,
      ReportDialogComponent,
      OrderNotificationAlertComponent,
      ReasonCodeDialogComponent,
      AlertDialogComponent,
      VideoDialogComponent,
      SignaturePadDialogComponent
    ],
    declarations: [
      AppComponent,
      OrderFiltersComponent,
      OrderActionsComponent,
      CustomFilterDialogComponent,
      OrderFilterComponent,
      LoginComponent, PageNotFoundComponent, ManifestCreatePageComponent, OmsMapsPageComponent,
      PagePermissionDeniedComponent,
      RegisterComponent,
      FileUploadDialogComponent,
      LoadDialogComponent,
      LoadDispatchDialogComponent,
      LoadPickupDialogComponent,
      MastersDispatchDialogComponent,
      OrdersDispatchDialogComponent,
      HistoryInformationDialogComponent,
      ProblemsHistoryInformationDialogComponent,
      MasterPttCreatorDialogComponent,
      NotesCenterDialogComponent,
      ProblemDialogComponent,
      CustomerGroupDialogComponent,
      MastersRecoveryListComponent, // todo remove
      ComCenterComponent,
      NotesCenterComponent,
      ExportAirComponent,
      ImportAirComponent,

      TestPageComponent,
      NumbersOnlyDirective,
      IndeterminateDirective,
      CustomerGroupSearchComponent,
      ColorSearchSelectComponent,
      CommonFiltersComponent,
      StickyDirective,
      NoteCenterTypeFilterPype,

      MastersListComponent, ToolbarComponent, OmsAccordionPanelComponent,
      ProblemDialogComponent,
      CbpStatusDialogComponent,
      CbpStatusTableComponent,
      CbpQuickViewStatusDialogComponent,
      MawbTrackDialogComponent,
      RecoveryAndDispatchLogDialogComponent,
      ConfirmDialogComponent,
//      ReceivingComponent,
      ReceivingLogDialog,
      PickupComponent,
      LoadDispatchDialogComponent ,
      MastersDispatchDialogComponent,
      DispatchRouteDialogComponent,
      OrdersDispatchDialogComponent,
      TruckDialogComponent,
      TrailerDialogComponent,
      DriverDialogComponent,
      CfsLocationDialogComponent,
      InputTextDialogComponent,
      LoadPickupDialogComponent,
      ReportDialogComponent,
      OrdersReportFiltersComponent,
      RoutesReportFiltersComponent,
      MastersReportFiltersComponent,
      PendingRecoveryReportFiltersComponent,
      ReportFilterComponent,
      OrderNotificationAlertComponent,
      ReasonCodeDialogComponent,
      OrderNotificationAlertComponent,
      StatusInfoComponent,
      AlertDialogComponent,
      PageFileDownloadComponent,
      VideoDialogComponent,
      SignaturePadDialogComponent,
      WindowComponent
    ],
    providers: [WINDOW_PROVIDERS,
      { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
      { provide: TooltipConfig, useFactory: () => Object.assign(new TooltipConfig(), tooltipConfig)},
      MasterStatusService,
      WeightUnits, VolumeUnits, CurrencyUnits, PackagingUnits, ContainerSizes, DocumentTypeUnits, NoteType,
      AuthGuard,
      RedirectRoleAuthGuard,
      // AuthService,
      OmsAlertsService,
      OrdersService,
      PublicApiService,
      OrderWarehouseService,
      AirlineWarehouseService,
      AirportService,
      MasterAirService,
      UldService,
      MasterService,
      MasterLineService,
      TruckService,
      TrailerService,
      DriverService,
      ReportService,
      DriverGpsService,
      AddressNewService,
      MasterProcessor,
      ModalPositionCache,
      FilterService,
      CustomerService,
      ShipmentContentService,
      HttpUtilsService,
      AccountService,
      NavigationService,
      ShipmentService,
      FileUploadService,
      ComCenterService,
      DataTableService,
      CustomerGroupService,
      FreightForwarderGroupService,
      RestService,
      LogChangesService,
      DispatchAndRecoveryService,
      WebSocketService,
      BnNgIdleService,
      DispatchColumnsService,
      FreightForwarderService,
      ReceivingService,
      StickyDirective,
      DateTimeService,
      LocationBackService,
      OrderNotificationService,
      ReasonCodeService,
      WebDriversService,
      BillingService,
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterseptor, multi: true },
      { provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
          hasBackdrop: true,
          panelClass: 'm-mat-dialog-container__wrapper',
          height: 'auto',
          width: '900px'
        }
      },

      // provider used to create fake backend
    ],
    schemas: [],
    bootstrap: [AppComponent]
})

export class AppModule { }
