import {BaseEntity} from '../base-entity';
import {Type} from 'class-transformer';

export class CustomsClearance {
  masterId: number;
  orderId: number;
  @Type(() => Date)
  date1F: Date;
  @Type(() => Date)
  date1C:Date;
}
