import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {RestService} from '../rest.service';
import {HttpUtilsService} from '../http-utils.service';
import {AirlineWarehouse} from '../../modules/shared/models';
import {BaseEntityService} from '../base/base-entity.service';

@Injectable()
export class AirlineWarehouseService extends BaseEntityService<AirlineWarehouse> {

  protected apiUrl = '/oms/airline-warehouse/';
  protected classType = AirlineWarehouse;

  public airlineWarehousesSubject = new Subject<AirlineWarehouse[]>();

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }
}
