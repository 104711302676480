<oms-base-page [title]="'Air Import'" [subtitle]="'Master List'" [showHeader]="false" class="masters-list">
  <!--<ng-container page-header>
    <common-filters [columns]="columns"></common-filters>
  </ng-container>-->
  <as-split unit="percent" direction="horizontal" [gutterDblClickDuration]="10" (dragEnd)="dragEnd($event)">
    <as-split-area [size]="widthFirstPanel">

      <ng-container page-body>
        <div class="widget widget-table">

          <nav #toolbar class="navbar navbar-default masters-list__header">

            <ul class="nav nav-tabs nav-tabs-custom-colored tabs-iconized masters-list__nav-tabs">
              <ng-container *ngFor="let tab of availableTabs">
                <li
                  class="masters-list__nav-tabs__item"
                  (click)="onSelectTab(tab)"
                  *ifRole="tab.access"
                  [ngClass]="{'active': selectedTab === tab}">
                  <a data-toggle="tab" class="masters-list__nav-tabs__item__text">
                    <i *ngIf="tab.icon" [class]="tab.icon"></i> {{tab.label}}
                    <span class="search-btn-refresh-circle" *ngIf="eventList(tab) > 0">{{eventList(tab)}}</span>
                    <!--span class="search-btn-refresh-circle" *ngIf="tab.isLoading"><i class="fa fa-refresh fa-spin"></i></span-->
                  </a>
                </li>
              </ng-container>

            </ul>

            <div class="masters-list__status"
                 *ngIf="isSupportDispatch()">

              <button type="button" class="btn btn-sm btn-default" (click)="dispatch()" [disabled]="!dispatchAllowed">
                <i class="fa fa-truck"></i>Dispatch&hellip;
              </button>
            </div>

            <div class="masters-list__status" *ngIf="activeTable?.tabInfo">

              <button type="button" class="btn btn-sm btn-default masters-list__status__item"
                      *ngFor="let status of activeTable.availableStatuses"
                      [ngClass]="{'_active': activeTable.selectedStatuses.includes(status)}"
                      [popover]="status.label"
                      (click)="activeTable.onChangeStatus(status)">
                <span>{{status.shortName}}</span>
              </button>

              <ng-container *ngIf="activeTable?.tabInfo?.tabFilters">
                <ng-container *ngFor="let filter of activeTable.tabInfo.tabFilters">
                  <button type="button" class="btn btn-sm btn-default masters-list__status__item"
                          *ngIf="!filter.btnHidden"
                          [ngClass]="{'_active': activeTable.selectedFilters.includes(filter)}"
                          [popover]="filter.description"
                          (click)="activeTable.toggleTabFilter(filter)">
                    <span>{{filter.label}}</span>
                  </button>
                </ng-container>
              </ng-container>

            </div>

            <div class="masters-list__filter" *ngIf="activeTable">
              <!-- -->
              <div class="input-group-btn">
                <button type="button" class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown" tabindex="-1" aria-expanded="false"
                        [popover]="'Additional Filters&hellip;'">
                  <i class="fa fa-filter"></i>
                  <span class="caret right"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu pull-right" role="menu">
                  <li *ngIf="activeTable.tabInfo !== TabsType.ACTIVE"><a>
                    <oms-checkbox [label]="'Direct'"  [(checked)]="activeTable.showDirectMasters"
                                  (checkedChange)="activeTable.refresh(true);"></oms-checkbox>
                    </a>
                  </li>
                  <li><a>
                    <oms-checkbox [label]="'AMS'" [(checked)]="activeTable.showAmsMasters"
                                  (checkedChange)="activeTable.showNonAmsMasters = false; activeTable.refresh(true);"></oms-checkbox>
                  </a>
                  </li>
                  <li><a>
                    <oms-checkbox [label]="'Non AMS'"
                                  [(checked)]="activeTable.showNonAmsMasters"
                                  (checkedChange)="activeTable.showAmsMasters = false; activeTable.refresh(true);"></oms-checkbox>
                  </a>
                  </li>
                  <li><a>
                  <oms-checkbox *ngIf="showPendingRecoveryFilter" [label]="'Pending Recoveries'" [popover]="'Pending Recoveries'"
                                [(checked)]="activeTable.showPendingRecoveriesMasters"
                                (checkedChange)="activeTable.refresh(true);"></oms-checkbox>
                  </a>
                  </li>
                  <li><a>
                  <oms-checkbox *ngIf="activeTable.tabInfo !== TabsType.ACTIVE" [label]="'Cargo Bldg'"
                                [(checked)]="activeTable.showCargoBldg"
                                (checkedChange)="activeTable.show3rdPartyCFS = false; activeTable.refresh(true);"></oms-checkbox>
                  </a>
                  </li>
                  <li><a>
                  <oms-checkbox  [label]="'3rd party CFS'"
                                 [(checked)]="activeTable.show3rdPartyCFS"
                                 (checkedChange)="activeTable.showCargoBldg = false; activeTable.refresh(true);"></oms-checkbox>
                  </a>
                  </li>
                </ul>
              </div>
<!--            -->
<!--
              <div class="form-group">
                <oms-checkbox *ngIf="activeTable.tabInfo !== TabsType.ACTIVE" [label]="'Direct'"
                              [(checked)]="activeTable.showDirectMasters"
                              (checkedChange)="activeTable.refresh(true);"></oms-checkbox>
                <oms-checkbox [label]="'AMS'" [(checked)]="activeTable.showAmsMasters"
                              (checkedChange)="activeTable.showNonAmsMasters = false; activeTable.refresh(true);"></oms-checkbox>
                <oms-checkbox *ngIf="showPendingRecoveryFilter" [label]="'PR'" [popover]="'Pending recoveries'"
                              [(checked)]="activeTable.showPendingRecoveriesMasters"
                              (checkedChange)="activeTable.refresh(true);"></oms-checkbox>
                <oms-checkbox *ngIf="activeTable.tabInfo !== TabsType.ACTIVE" [label]="'Non AMS'"
                              [(checked)]="activeTable.showNonAmsMasters"
                              (checkedChange)="activeTable.showAmsMasters = false; activeTable.refresh(true);"></oms-checkbox>
                <oms-checkbox *ngIf="activeTable.tabInfo !== TabsType.ACTIVE" [label]="'Cargo Bldg'"
                              [(checked)]="activeTable.showCargoBldg"
                              (checkedChange)="activeTable.show3rdPartyCFS = false; activeTable.refresh(true);"></oms-checkbox>
                <oms-checkbox  [label]="'3rd party CFS'"
                              [(checked)]="activeTable.show3rdPartyCFS"
                              (checkedChange)="activeTable.showCargoBldg = false; activeTable.refresh(true);"></oms-checkbox>
              </div>
-->
            </div>

            <div class="container-fluid masters-list__search-panel">
              <!--div class="collapse navbar-collapse" id="bs-example-navbar-collapse"-->
              <form class="navbar-form navbar-left masters-list__search-panel__form">

                <div class="btn-toolbar" style="display: inline-block; vertical-align: middle;"
                     *ngIf="!clientUser && activeTable && activeTable.tabInfo !== TabsType.ACTIVE">

                  <!--button type="button" class="btn btn-sm btn-default" (click)="dispatch()" [disabled]="!dispatchAllowed" >
                    <i class="fa fa-truck"></i>Dispatch&hellip;
                  </button-->

                  <div class="btn-group">
                    <button type="button" class="btn btn-sm btn-default dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"
                            [popover]="'New Master&hellip;'">
                      <i class="glyphicon glyphicon-plus"></i><span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <!--IN SAME  Window
                                  <li><a [routerLink]="['./master', {new:'AIR_IMPORT'}]"><i class="fa fa-plane"></i>&nbsp;Import Air<i class="glyphicon glyphicon-import pull-right"></i></a></li>
                      -->
                      <li><a [target]="'_blank'" [routerLink]="['/home/master', {new:'AIR_IMPORT'}]"><i
                        class="fa fa-plane"></i>&nbsp;Import Air<i
                        class="glyphicon glyphicon-import pull-right"></i></a></li>
                    </ul>

                    <button type="button" [disabled]="!activeTable?.selected.length"
                            [hidden]="!showDeleted"
                            class="btn btn-sm btn-primary" (click)="activeTable?.onRestoreSelected()">
                      <i class="fa fa-undo"></i>Restore Selected&hellip;
                    </button>
                  </div>

                </div>

                <div class="form-group">
                  <div class="input-group input-group-sm" *ngIf="activeTable">
                    <input type="text" class="form-control"
                           #searchInput
                           [ngModel]="activeTable.getSearchText()"
                           [ngModelOptions]="{standalone: true}"
                           placeholder="Search"/>
                    <div class="input-group-btn">

                      <button type="submit" class="btn btn-default" (click)="searchChanged(searchInput.value)">
                        <i class="glyphicon glyphicon-search"></i>
                      </button>

                      <button class="btn btn-clean btn-clear-input"
                              *ngIf="searchInput.value"
                              (click)="searchChanged('');"
                              style="right: 40px;"><i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>


                <!--<div class="form-group">
                  <oms-color-search-select [selected]="colorStatusFilter" (colorChanged)="searchColorChanged($event)"></oms-color-search-select>
                </div>-->


              </form>

              <ul class="nav navbar-nav navbar-right masters-list__search-panel__buttons-panel">
                <li [popover]="'Copy to Clipboard'" (click)="copyDataToClipboard()" container="body" placement="bottom">
                  <a><i class="fa fa-clipboard"></i></a>
                </li>

                <!--li tooltip="Copy Manifest to Clipboard" (click)="copyDataToClipboardManifest()" container="body" placement="bottom">
                  <a><i class="fa fa-clipboard"></i> Manifest</a>
                </li-->

                <li [popover]="'Toggle Filters'" (click)="toggleSearchableFilter()" container="body" placement="bottom">
                  <a><i class="fa fa-filter action-icon"></i></a>
                </li>

                <li [popover]="'Clear Filters'" (click)="clearFilters()" container="body" placement="bottom">
                  <a class="btn btn-link font-bold fa-filter-remove" [ngClass]="{'active': isTableFilterNotClear()}">
                    <i class="fa fa-filter"></i>
                    <i class="fa fa-remove"></i>
                  </a>
                </li>


                <li class="dropdown">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                     aria-expanded="false">
                    <i class="fa fa-download"></i>&nbsp;Download<span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu">
                    <!--
                                    <li><a [href]="buildDownloadUrl('.xls')" target="_blank">Excel<i class="pull-right fa fa-file-excel-o"></i></a></li>
                    -->
                    <li><a (click)="downloadExcel()" target="_blank">MAWB list<i
                      class="pull-right fa fa-file-excel-o"></i></a></li>
                    <li><a (click)="downloadExcelPendingRecoveries()" target="_blank">Pending Rec<i
                      class="pull-right fa fa-file-excel-o"></i></a></li>
                    <li><a (click)="downloadInventory()" target="_blank">Inventory list<i
                      class="pull-right fa fa-file-excel-o"></i></a></li>
                    <li><a (click)="downloadNominationExcel()" target="_blank">Nomination<i
                      class="pull-right fa fa-file-excel-o"></i></a></li>
                    <li><a (click)="downloadMastersManifest()" target="_blank">Manifest<i
                      class="pull-right fa fa-file-excel-o"></i></a></li>
                    <!--<li><a [href]="buildDownloadUrl('.pdf')" target="_blank">PDF<i class="pull-right fa fa-file-pdf-o"></i></a></li>-->

                    <li [popover]="'Copy Manifest to Clipboard'" container="body">
                      <a (click)="copyDataToClipboardManifest()" target="_blank">Copy Manifest<i
                        class="pull-right fa fa-clipboard"></i></a>
                    </li>
                  </ul>
                </li>

              </ul>
              <!--/div><-- /.navbar-collapse -->
            </div><!-- /.container-fluid -->
          </nav>


          <div #content class="widget-content">

            <ul class="nav navbar-nav masters-list__reload-button masters-list__search-panel__buttons-panel">
              <li [popover]="'Refresh'" [ngClass]="{'need-refresh': activeTable?.isDirty}" (click)="activeTable.refresh()"
                  container="body" placement="bottom" style="height: auto;">
                <a style="padding: 0;"><i class="fa fa-refresh action-icon"></i></a>
              </li>
            </ul>

            <!--ngx-spinner
              bdOpacity = "0.5"
              bdColor = "#333"
              size = "medium"
              color = "#fff"
              type = "pacman"
              [fullScreen] = "true">
              <p style="color: white">Loading...</p>
            </ngx-spinner-->

            <!--<button *ngFor="let tab of availableTabs" (click)="activeTabName = tab.filterName">{{tab.label}}</button>-->
            <ng-container *ngFor="let tab of availableTabs">

              <app-orders-table
                *ngIf="tab.isOrder"
                #mastersTables
                [toolbar]="toolbar"
                [menu]="contextMenu"
                (cell-right-click)="onCellRightClick($event)"
                (cell-click)="onCellClick($event)"
                [colorStatusFilter]="colorStatusFilter"
                [isVisible]="selectedTab === tab"
                [tabInfo]="tab"></app-orders-table>
              <app-masters-table
                *ngIf="!tab.isOrder"
                #mastersTables
                [toolbar]="toolbar"
                [menu]="contextMenu"
                [modes]="[MasterMode.AIR_IMPORT]"
                (cell-right-click)="onCellRightClick($event)"
                (cell-click)="onCellClick($event)"
                [colorStatusFilter]="colorStatusFilter"
                [isVisible]="selectedTab === tab"
                [tabInfo]="tab"></app-masters-table>
            </ng-container>

            <context-menu #mastersMenu>
              <!--ng-template contextMenuItem let-item [enabled]="false">
                <div class="widget-header">
                  <h3><i class="fa fa-info-circle"></i>{{item.mawbNumber}}</h3>
                </div>
              </ng-template-->

              <!--ng-template contextMenuItem let-item (execute)="toggleMasterDirect($event)">
                <oms-checkbox label="Direct" [checked]="item.direct"></oms-checkbox>
              </ng-template-->

              <ng-template contextMenuItem let-item (execute)="toggleMasterDirect($event)">
                <oms-checkbox label="Direct" [checked]="item.row.direct"></oms-checkbox>
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="toggleMasterNonAms($event)">
                <oms-checkbox label="NON-AMS" [checked]="item.row.nonAMS"></oms-checkbox>
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="showAsmDataDialog($event.item.row)">
                <i class="fa fa-bullhorn" style="margin-right: 15px;"></i>MAWB TRACK
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>


              <ng-template contextMenuItem let-item (execute)="recalculateMasterStatus($event.item.row)">
                <i class="fa fa-refresh" style="margin-right: 15px;"></i>Recalculate Status
              </ng-template>


              <ng-template contextMenuItem divider="true"></ng-template>

              <!--ng-template contextMenuItem [subMenu]="saySubMenu" *ifRole="'ROLE_ADMIN'">
                <i class="fa fa-truck"></i>NON-AMS
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="showMessage($event.item.name + ' said: ' + $event.item.otherProperty)">
                Bye, {{item?.name}}
              </ng-template>
              <ng-template-- contextMenuItem passive="true">
                Input something: <input type="text">
              </ng-template-->

              <ng-template contextMenuItem let-item (execute)="dispatchMastersRecovery($event.item.row)">
                <i class="fa fa-truck" style="margin-right: 15px;"></i>{{dispatchMastersMenuLabel(null)}}
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="openMultipleOrders($event.item.row)">
                <i class="fa fa-plus" style="margin-right: 15px;"></i>Send to Multiple Orders
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="pickupMaster($event.item.row)">
                <i class="fa fa-arrow-circle-o-up" style="margin-right: 15px;"></i>Pick Up&hellip;
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="receive($event.item.row)">
                <i class="fa fa-arrow-circle-o-down" style="margin-right: 15px;"></i>Receive&hellip;
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem [subMenu]="masterLogsSubMenu">
                <i class="fa fa-history" style="margin-right: 15px;"></i>Logs&hellip;
              </ng-template>

              <context-menu #masterLogsSubMenu>
                <ng-template contextMenuItem let-item (execute)="dispatchAndRecoveryLogs($event.item.row, 'MASTER')">
                  <i class="fa fa-history" style="margin-right: 15px;"></i>Dispatch Recovery Log
                </ng-template>

                <ng-template contextMenuItem let-item (execute)="showReceivingLog($event.item.row)">
                  <i class="fa fa-history" style="margin-right: 15px;"></i>Receiving Log
                </ng-template>
              </context-menu>


              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="sendMail($event.item.row)">
                <i class="fas fa-envelope" style="margin-right: 15px;"></i>Send Mail
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="sendNominationMail($event.item.row)">
                <i class="fa fa-plus" style="margin-right: 15px;"></i>Nomination email
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="generateBOL(false, null)"
                           [enabled]="selectedMasters.length">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Create BOL
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="printBOL(null)" [enabled]="selectedMasters.length">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Print BOL
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="generateBOL(true, null)"
                           [enabled]="selectedMasters.length === 1">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Create and Print
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="addFlagMaster($event.item.row)">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Notifications
              </ng-template>
              <ng-template contextMenuItem let-item (execute)="removeFlagMaster($event.item.row)">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Clear Notifications
              </ng-template>

            </context-menu>

            <context-menu #ordersMenu>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="recalculateOrderStatus($event.item.row)">
                <i class="fa fa-refresh" style="margin-right: 15px;"></i>Recalculate Status
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="sendMailForOrder($event.item.row)">
                <i class="fas fa-envelope" style="margin-right: 15px;"></i>Send Mail
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="generateBOL(false, $event.item.row)">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Create BOL
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="printBOL($event.item.row)">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Print BOL
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="generateBOL(true, $event.item.row)">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Create and Print
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="openCbpStatusNotificationEmail($event.item.row)">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>CBP status Notification
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="addFlag([$event.item.row])">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Notifications
              </ng-template>
              <ng-template contextMenuItem let-item (execute)="removeFlag([$event.item.row])">
                <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Clear Notifications
              </ng-template>
            </context-menu>

            <context-menu #splitsMenu>

              <ng-template contextMenuItem let-item (execute)="pickupLoad($event.item.row)">
                <i class="fa fa-arrow-circle-o-up" style="margin-right: 15px;"></i>Pick Up&hellip;
              </ng-template>

              <ng-template contextMenuItem let-item (execute)="receive($event.item.row)">
                <i class="fa fa-arrow-circle-o-down" style="margin-right: 15px;"></i>Receive&hellip;
              </ng-template>

              <ng-template contextMenuItem divider="true"></ng-template>

              <ng-template contextMenuItem let-item (execute)="dispatchAndRecoveryLogs($event.item.row, 'SPLIT')">
                <i class="fa fa-history" style="margin-right: 15px;"></i>Dispatch/Recovery Logs
              </ng-template>
            </context-menu>
          </div>

        </div>
      </ng-container>

    </as-split-area>

    <as-split-area [size]="widthSecondPanel">
      <manifest-create-panel [(manifest)]="manifestForRightPanel" [mergeToExisting]="true"
                             (manifestChange)="onManifestChange($event)"
                             (selectedChange)="onDispatchListSelectionChange($event)"
                             (created)="onCreatedEvent($event)"></manifest-create-panel>
    </as-split-area>
  </as-split>
</oms-base-page>
