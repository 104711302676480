/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./base-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../components/common/slide-panel/slide-panel.component.ngfactory";
import * as i3 from "../../../../../components/common/slide-panel/slide-panel.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../components/common/oms-spinner/oms-spinner.component.ngfactory";
import * as i7 from "../../../../../components/common/oms-spinner/oms-spinner.component";
import * as i8 from "ngx-spinner";
import * as i9 from "./base-page.component";
import * as i10 from "@angular/platform-browser";
var styles_BasePageComponent = [i0.styles];
var RenderType_BasePageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BasePageComponent, data: {} });
export { RenderType_BasePageComponent as RenderType_BasePageComponent };
function View_BasePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row main-header-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "main-header col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-md-9 main-header-right"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.subtitle; _ck(_v, 5, 0, currVal_1); }); }
export function View_BasePageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainContent: 0 }), i1.ɵqud(402653184, 2, { footer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 15, "oms-slide-panel", [], null, null, null, i2.View_SlidePanelComponent_0, i2.RenderType_SlidePanelComponent)), i1.ɵdid(3, 245760, null, 0, i3.SlidePanelComponent, [i1.ChangeDetectorRef, i4.Router], { activePane: [0, "activePane"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 10, "span", [["leftPane", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BasePageComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["mainContent", 1]], null, 6, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "oms-spinner", [], null, null, null, i6.View_OmsSpinnerComponent_0, i6.RenderType_OmsSpinnerComponent)), i1.ɵdid(10, 573440, null, 0, i7.OmsSpinnerComponent, [i8.NgxSpinnerService], { show: [0, "show"], fullScreen: [1, "fullScreen"] }, null), i1.ɵncd(null, 1), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(13, 0, [[2, 0], ["footer", 1]], null, 1, "div", [["class", "main-content-bottom if-not-empty"], ["id", "footer"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkSize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 3), (_l()(), i1.ɵeld(15, 0, null, 1, 2, "span", [["rightPane", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(17, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.displayChildPage ? "right" : "left"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showHeader; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.loading; var currVal_3 = true; _ck(_v, 10, 0, currVal_2, currVal_3); _ck(_v, 17, 0); }, null); }
export function View_BasePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-base-page", [], null, [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onEscape($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BasePageComponent_0, RenderType_BasePageComponent)), i1.ɵdid(1, 638976, null, 0, i9.BasePageComponent, [i4.Router, i4.ActivatedRoute, i8.NgxSpinnerService, i10.Title, i4.ActivatedRoute, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BasePageComponentNgFactory = i1.ɵccf("oms-base-page", i9.BasePageComponent, View_BasePageComponent_Host_0, { title: "title", subtitle: "subtitle", showHeader: "showHeader", loading: "loading", backOnEscape: "backOnEscape" }, {}, ["[page-header]", "*", "[page-body]", "[page-bottom]"]);
export { BasePageComponentNgFactory as BasePageComponentNgFactory };
