var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { RestService } from '../../../../services/rest.service';
import { HttpUtilsService } from '../../../../services/http-utils.service';
import { FilterPageRequest } from '../../models/filter.page.request';
import { classToPlain, plainToClass, Type } from 'class-transformer';
import { OrderDispatch } from '../../models/dispatch/order-dispatch';
import { Manifest } from '../../models/manifest/manifest';
import { BaseEntityService } from '../../../../services/base/base-entity.service';
import { map } from 'rxjs/operators';
import { Address, Order } from "../../models";
import { DriverOrderDispatch } from '../../models/dispatch/driver-order-dispatch';
import { DriverRoute } from "../../models/dispatch/driver-route";
import { FilterSearchColumn } from "../../models/filter.search.column";
import * as moment from "moment-timezone";
import { SearchRange } from "../../models/search.range";
import { SearchSortRequest } from "../../models/search.sort.request";
import { OmsConstants } from "../../../../common/oms-constants.service";
import { HttpParams } from "@angular/common/http";
import { assigned } from "../../../../_helpers/utils";
var ɵ0 = function () { return Manifest; }, ɵ1 = function () { return Manifest; };
var ManifestSplitResult = /** @class */ (function () {
    function ManifestSplitResult() {
    }
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Manifest)
    ], ManifestSplitResult.prototype, "source", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Manifest)
    ], ManifestSplitResult.prototype, "target", void 0);
    return ManifestSplitResult;
}());
export { ManifestSplitResult };
var DispatchService = /** @class */ (function (_super) {
    __extends(DispatchService, _super);
    function DispatchService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/dispatch/';
        _this.classType = Manifest;
        return _this;
    }
    DispatchService.prototype.get = function (id, active) {
        if (active === void 0) { active = true; }
        console.warn('GET DISPATCH CALL');
        return _super.prototype.get.call(this, id, active);
    };
    DispatchService.prototype.getAddressWarehouseDefault = function () {
        return this.httpRestService.getWithHeader(this.apiUrl + "getAddressWarehouseDefault", this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (address) { return plainToClass(Address, address); });
    };
    DispatchService.prototype.getAddressFCLWarehouseDefault = function () {
        return this.httpRestService.getWithHeader(this.apiUrl + "getAddressFCLWarehouseDefault", this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (address) { return plainToClass(Address, address); });
    };
    DispatchService.prototype.getOrderAddressDelivery = function (orderId) {
        return this.httpRestService.getWithHeader(this.apiUrl + "getOrderAddressDelivery/" + orderId, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (address) { return plainToClass(Address, address); });
    };
    DispatchService.prototype.getAddressDefault = function (orderId) {
        return this.httpRestService.getWithHeader(this.apiUrl + "getAddressDefault/" + orderId, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (address) { return plainToClass(Address, address); });
    };
    DispatchService.prototype.findAllToDispatch = function (filterPageRequest) {
        this.lastPageRequest = filterPageRequest;
        return this.httpRestService.postWithHeader(this.apiUrl + 'findAllToDispatch', this.httpUtils.getHTTPHeaders(), filterPageRequest)
            .pipe(map(function (page) {
            page.content = plainToClass(OrderDispatch, page.content);
            return page;
        }));
    };
    DispatchService.prototype.findDispatchedLoads = function (filterPageRequest) {
        return this.findDispatchedLoadsObs(filterPageRequest).toPromise();
    };
    DispatchService.prototype.findDispatchedLoadsObs = function (filterPageRequest) {
        this.lastPageRequest = filterPageRequest;
        return this.httpRestService.postWithHeader(this.apiUrl + 'findDispatchedLoads', this.httpUtils.getHTTPHeaders(), filterPageRequest)
            .pipe(map(function (page) {
            page.content = plainToClass(OrderDispatch, page.content);
            return page;
        }));
    };
    DispatchService.prototype.findOrderDispatches = function (orderId) {
        return this.httpRestService.post(this.apiUrl + 'findOrderDispatches/' + orderId, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (list) { return plainToClass(OrderDispatch, list); });
    };
    DispatchService.prototype.findOrderDispatchesFromShipment = function (shipmentId) {
        return this.httpRestService.post(this.apiUrl + 'findOrderDispatchesFromShipment/' + shipmentId, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (od) { return plainToClass(OrderDispatch, od); });
    };
    DispatchService.prototype.findOrdersToDispatchFromWarehouses = function (orderIds, addressPickupIds) {
        return this.httpRestService.post(this.apiUrl + 'findOrdersToDispatchFromWarehouses/' + orderIds + '/' + addressPickupIds, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (list) { return plainToClass(OrderDispatch, list); });
    };
    DispatchService.prototype.findDispatchesForOrders = function (orderIds) {
        return this.httpRestService.post(this.apiUrl + 'findDispatchesForOrders/' + orderIds, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (list) { return plainToClass(OrderDispatch, list); });
    };
    DispatchService.prototype.findDispatchedLoadsByDriver = function (driverOrderDispatch) {
        var filterByDriver = new FilterSearchColumn('driverId', driverOrderDispatch.driver.id + '')
            .setFullMatchValue(true);
        var startDay = moment.tz(driverOrderDispatch.dateDispatchedFor, OmsConstants.ETC_ZONE)
            .set({ h: 0, m: 0, s: 0, ms: 0 })
            .utc();
        var range = new SearchRange(startDay.format(DispatchService.FULL_MOMENT_FORMAT), startDay.format(DispatchService.FULL_MOMENT_FORMAT), false);
        var filterByDate = new FilterSearchColumn('dateDispatchedFor', null, null, range);
        var request = new FilterPageRequest(1, 20, '', new SearchSortRequest('dispatchId', true), [filterByDriver, filterByDate]);
        request.addNamedCondition('ACTIVE');
        return this.findDispatchedLoadsObs(request);
    };
    DispatchService.prototype.findDispatchedLoadsForDriver = function (filterPageRequest) {
        this.lastPageRequest = filterPageRequest;
        return this.httpRestService.postWithHeader(this.apiUrl + 'findDispatchedLoadsForDriver', this.httpUtils.getHTTPHeaders(), filterPageRequest)
            .pipe(map(function (page) {
            page.content = plainToClass(DriverOrderDispatch, page.content);
            return page;
        }));
    };
    DispatchService.prototype.validateOrderDispatch = function (item) {
        return this.httpRestService.post(this.apiUrl + 'validateOrderDispatch', classToPlain(item));
    };
    DispatchService.prototype.dispatchOrders = function (dispatch, isSendEmail, isPlanning) {
        if (isPlanning === void 0) { isPlanning = false; }
        console.log('DISPATCH', dispatch);
        return this.httpRestService.post(this.apiUrl + 'dispatchOrders', classToPlain(dispatch), { params: { isSendEmail: isSendEmail, isPlanning: isPlanning } }).pipe(map(function (manifest) { return plainToClass(Manifest, manifest); }));
    };
    DispatchService.prototype.splitManifest = function (dispatchId, items, newDate, newDriver) {
        var params = { dispatch: dispatchId };
        if (items && items.length) {
            params.items = items;
        }
        if (newDriver) {
            params.driver = newDriver.id;
        }
        if (assigned(newDate)) {
            params.date = newDate;
        }
        return this.httpRestService.post(this.apiUrl + 'split-manifest', undefined, { params: this.httpUtils.getHTTPParams(params) })
            .pipe(map(function (result) { return plainToClass(ManifestSplitResult, result); }));
    };
    DispatchService.prototype.splitManifestByTrailers = function (dispatch) {
        return this.httpRestService.post(this.apiUrl + 'split-manifest-by-trailers', classToPlain(dispatch)).pipe(map(function (manifest) { return plainToClass(Manifest, manifest); }));
    };
    DispatchService.prototype.cancelOrdersDispatch = function (dispatchId, modes) {
        return modes && modes.length > 0 ?
            this.httpRestService.post(this.apiUrl + 'cancelOrdersDispatch/' + dispatchId + '/modes/' + modes) :
            this.httpRestService.post(this.apiUrl + 'cancelOrdersDispatch/' + dispatchId);
    };
    DispatchService.prototype.updateOrderDispatch = function (item) {
        return this.httpRestService.put(this.apiUrl + "updateOrderDispatch", classToPlain(item), { headers: this.httpUtils.getHTTPHeaders() })
            .pipe(map(function (order) { return plainToClass(Order, order); }));
    };
    DispatchService.prototype.updateOrderDeliveryDate = function (orderIds, dateDelivery, actual) {
        console.log('sent', dateDelivery, 'ISO:', dateDelivery.toISOString());
        var params = new HttpParams().set('orderIds', orderIds.join(','))
            .set('deliveryDate', encodeURI(dateDelivery.toISOString()))
            .set('actual', '' + actual);
        return this.httpRestService.put(this.apiUrl + "updateOrderDeliveryDate", [], this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (item) { return plainToClass(Order, item); }));
    };
    DispatchService.prototype.updateDispatchFields = function (dispatchId, dispatchItemId, field, value) {
        var body = {};
        body[field] = value;
        return this.httpRestService.put(this.apiUrl + 'update/' + dispatchId + '/' + dispatchItemId, body, {})
            .pipe(map(function (manifest) { return plainToClass(Manifest, manifest); }));
    };
    DispatchService.prototype.validateRemoveShipmentItem = function (dispatchItemId) {
        return this.httpRestService.get(this.apiUrl + 'validate-remove', dispatchItemId)
            .pipe(map(function (response) { return response.validationMessage; }));
    };
    DispatchService.prototype.validateRemoveOrder = function (orderId, dispatchItemId) {
        return this.httpRestService.getWithOptions(this.apiUrl + 'validate-remove-order/' + orderId, null, { dispatchItemId: dispatchItemId })
            .pipe(map(function (response) { return response.validationMessage; }));
    };
    DispatchService.prototype.getLoadDeliveryAddressForOrder = function (orderId, loadType, orderMode) {
        console.log('getLoadDeliveryAddressForOrder', loadType, orderMode);
        return this.httpRestService.getWithHeader(this.apiUrl + "getLoadDeliveryAddressForOrder/" + orderId + "/" + loadType, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (address) { return plainToClass(Address, address); });
        /*
        
            return new Promise<Address>((resolve, reject) => {
              if (orderMode === OrderMode.RECOV) {
                switch (loadType) {
                  case LoadType.RECOVERY:
                  case LoadType.DIRECT:
                }
              } else {
                switch (loadType) {
                  case LoadType.X_DOCK:
                  case LoadType.PICKUP:
                    return this.getAddressDefault(orderId);
        
                  case LoadType.DELIVERY:
                  case LoadType.DIRECT:
                    return this.getOrderAddressDelivery(orderId);
                }
              }
        
              reject();
            });
        
         */
    };
    DispatchService.prototype.getDriverRoutes = function (date, request) {
        var url = this.apiUrl + "getDriverRoutes/" + date.toUTCString();
        console.log('URL', url, 'FILTER', request);
        return this.httpRestService.postWithHeader(url, this.httpUtils.getHTTPHeaders(), request)
            .pipe(map(function (routes) { return plainToClass(DriverRoute, routes); }))
            .toPromise();
    };
    DispatchService.prototype.getDriverManifestOnDate = function (date, driver) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!driver) {
                resolve(null);
            }
            else {
                _this.httpRestService.getWithHeader(_this.apiUrl + "getDriverManifestOnDate/" + date + "/" + driver.id, _this.httpUtils.getHTTPHeaders())
                    //            .pipe(map(manifest=>plainToClass(Manifest, manifest)))
                    .subscribe(function (next) { console.log('RECEIVED', next); resolve(plainToClass(Manifest, next)); }, function (error) { return reject(error); });
            }
        });
    };
    DispatchService.prototype.approveManifest = function (id) {
        return this.httpRestService.getWithHeader(this.apiUrl + "approve/" + id, this.httpUtils.getHTTPHeaders())
            .pipe(map(function (manifest) { return plainToClass(Manifest, manifest); })).toPromise();
    };
    DispatchService.prototype.moveManifestItemToDispatch = function (manifestItemId, newDispatchId) {
        return this.httpRestService.getWithHeader(this.apiUrl + "moveManifestItemToDispatch/" + manifestItemId + "/" + (newDispatchId || ''), this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (manifest) { return plainToClass(Manifest, manifest); });
    };
    DispatchService.prototype.mergeManifests = function (sourceDispatchId, targetDispatchId) {
        return this.httpRestService.getWithHeader(this.apiUrl + "mergeManifests/" + sourceDispatchId + "/" + targetDispatchId, this.httpUtils.getHTTPHeaders())
            .toPromise().then(function (manifest) { return plainToClass(Manifest, manifest); });
    };
    DispatchService.prototype.getLastDeliveryLoad = function (orderId) {
        return this.httpRestService.getWithHeader(this.apiUrl + "lastDeliveryLoad/" + orderId, this.httpUtils.getHTTPHeaders());
    };
    /***
     * Move Manifest Items or single Orders from the Source Manifest to the Target Manifest
     * @param source: Source Manifest
     * @param target: Target Manifest. If null then new manifest will be created
     * @return Observable with Success/Error result
     */
    DispatchService.prototype.redispatch = function (source, target) {
        var sourceId = source.id;
        var targetId = target ? target.id : undefined;
        var url = this.apiUrl + "redispatch/";
        var body = {
            source: classToPlain(source),
            target: classToPlain(target),
        };
        return this.httpRestService.post(url, body, { headers: this.httpUtils.getHTTPHeaders(), withCredentials: true })
            .toPromise().then(function (m) { return plainToClass(Manifest, m); });
    };
    DispatchService.prototype.routeDriverUpdate = function (driverId, dispatchItemIds, action, actualDate) {
        var url = this.apiUrl + "route/" + dispatchItemIds + "/driver/" + driverId + "/update/" + action;
        var params = new HttpParams();
        if (assigned(actualDate)) {
            params = params.set('actualDate', encodeURI(actualDate.toISOString()));
        }
        return this.httpRestService.put(url, null, this.httpUtils.getHTTPHeaders(), params)
            .toPromise()
            .then(function (manifest) { return plainToClass(Manifest, manifest); });
    };
    DispatchService.prototype.routeDriverUpdateCancel = function (dispatchItemIds) {
        var url = this.apiUrl + "route/" + dispatchItemIds + "/update/cancel";
        return this.httpRestService.put(url, null, this.httpUtils.getHTTPHeaders())
            .toPromise()
            .then(function (manifest) { return plainToClass(Manifest, manifest); });
    };
    DispatchService.prototype.getDriverRoutesOnDate = function (driverId, date) {
        return this.httpRestService.getWithHeader(this.apiUrl + "route/driver/" + driverId + "/routeDate/" + date.toUTCString(), this.httpUtils.getHTTPHeaders()).toPromise().then(function (manifest) { return plainToClass(Manifest, manifest); });
    };
    DispatchService.prototype.generateBOLs = function (dispatchIds) {
        var params = new HttpParams().set('dispatchIds', dispatchIds.join(','));
        return this.httpRestService.put(this.apiUrl + "generateBOLs", [], this.httpUtils.getHTTPHeaders(), params);
    };
    DispatchService.prototype.generateManifest = function (manifestId) {
        return this.httpRestService.buildUrl(this.apiUrl + "generateManifest", manifestId);
    };
    DispatchService.prototype.updateDriver = function (dispatchId, driver) {
        return this.httpRestService.post(this.apiUrl + "update/driver/" + dispatchId, driver, { headers: this.httpUtils.getHTTPHeaders() });
    };
    DispatchService.prototype.updateTruck = function (dispatchId, truck) {
        return this.httpRestService.post(this.apiUrl + "update/truck/" + dispatchId, truck, { headers: this.httpUtils.getHTTPHeaders() });
    };
    DispatchService.prototype.updateTrailer = function (dispatchId, trailer) {
        return this.httpRestService.post(this.apiUrl + "update/trailer/" + dispatchId, trailer, { headers: this.httpUtils.getHTTPHeaders() });
    };
    DispatchService.prototype.updateCarrier = function (dispatchId, carrier) {
        return this.httpRestService.post(this.apiUrl + "update/carrier/" + dispatchId, carrier, { headers: this.httpUtils.getHTTPHeaders() });
    };
    DispatchService.prototype.updateSeal = function (dispatchId, seal) {
        return this.httpRestService.post(this.apiUrl + "update/seal/" + dispatchId, seal, { headers: this.httpUtils.getHTTPHeaders() });
    };
    DispatchService.prototype.buildManifestJsonDownloadUrl = function (dispatchId) {
        return this.httpRestService.buildUrl(this.apiUrl, 'get-manifest-json', dispatchId);
    };
    DispatchService.FULL_MOMENT_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
    return DispatchService;
}(BaseEntityService));
export { DispatchService };
export { ɵ0, ɵ1 };
