import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {copyToClipboard} from '../../../../../_helpers/utils';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
  last: boolean;
}

@Component({
  selector: 'oms-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  private static ROUTE_DATA_BREADCRUMB: string = "label";

  public breadcrumbs: IBreadcrumb[] = [];

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivatedRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
  public static getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {

    // get the child routes
    let children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (let child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      /*      //get the route's URL segment
            let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

            //append route URL to URL
            url += `/${routeURL}`;*/

      let routeURL = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL.length === 0) {
        routeURL = route.snapshot.url.map(segment => segment.path).join('/');
        if (routeURL.length === 0) {
          routeURL = "home";
        }
      }
      url += `/${routeURL}`;

      // add breadcrumb
      let breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[this.ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        last: child.snapshot.children.length > 0,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router ) {
  }

  ngOnInit() {
    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbs = BreadcrumbsComponent.getBreadcrumbs(this.activatedRoute.root);
    });
    this.breadcrumbs = BreadcrumbsComponent.getBreadcrumbs(this.activatedRoute.root);
  }

  public copyUrl() {
    copyToClipboard(window.location.href);
  }
}
