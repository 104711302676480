var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { plainToClass } from "class-transformer";
import { ChatRecord } from "../modules/shared/models/chat.record";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "./http-utils.service";
var ChatService = /** @class */ (function (_super) {
    __extends(ChatService, _super);
    function ChatService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/chat-records/';
        _this.classType = ChatRecord;
        return _this;
    }
    ChatService.prototype.findAllByDriverId = function (driverId) {
        var _this = this;
        return new Promise(function (result, reject) {
            _this.httpRestService.getWithHeader(_this.apiUrl + "driver/" + driverId + "/records", _this.httpUtils.getHTTPHeaders())
                .subscribe(function (items) {
                result(items.map(function (item) { return plainToClass(_this.classType, item); }));
            }, function (error) { return reject(error); });
        });
    };
    ChatService.ngInjectableDef = i0.defineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}(BaseEntityService));
export { ChatService };
