import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {RestService} from "./rest.service";
import {Subject} from "rxjs";
import {ObjectDocuments} from "../modules/shared/models/documents/object.documents";
import {UploadedFiles} from "../modules/shared/models/documents/uploaded.files";
import {HttpUtilsService} from "./http-utils.service";
import {DocumentType} from "../modules/shared/models/documents/document-type";
import {downloadFile, encryptNumber} from "../_helpers/utils";
import {plainToClass} from "class-transformer";
import {OmsObjectType} from "../common/oms-types";
import {map} from "rxjs/operators";

const FILE_API_URL = "/file/";
const PUBLIC_API_URL = '/public-api/';


@Injectable()
export class FileUploadService {

  public filesList = new Subject<any[]>();
  public filesRemovedList = new Subject<any[]>();

  constructor(
    private http: HttpClient,
    private restService: RestService,
    public httpUtils: HttpUtilsService) {

  }

  /*** @deprecated ***/
  uploadDocuments(objectDocuments: ObjectDocuments, files: FileList): Observable<HttpEvent<{}> | HttpResponse<ObjectDocuments[]>> {
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i], files[i]['name']);
    }
    formData.append("objectDocuments", JSON.stringify(objectDocuments));
    return this.restService.requestQuery('POST', FILE_API_URL + 'uploadDocuments', formData, {responseType: "json"});
  }

  uploadMultipleDocuments(documentType: DocumentType, objectType: 'ORDER' | 'MASTER', ids: number | number[], files: FileList, data?: Object): Observable<HttpEvent<{}> | HttpResponse<ObjectDocuments[]>> {
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i], files[i]['name']);
    }
    formData.append("documentTypeId", documentType.id + '');
    formData.append("objectType", objectType);

    if (data) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
    }

    console.log('FormData', formData);

    return this.restService.requestQuery('POST', FILE_API_URL + `upload-multiple-documents/${ids}`, formData, {responseType: "json"});
  }


  deleteDocument(objectDocuments: ObjectDocuments): Observable<any> {
    return this.restService.post( FILE_API_URL + 'deleteDocument', objectDocuments);
  }

  restoreDocument(objectDocuments: ObjectDocuments): Observable<any> {
    return this.restService.post( FILE_API_URL + 'restoreDocument', objectDocuments);
  }

  validateIfFilesExist(objectDocuments: ObjectDocuments, files: FileList) {
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("fileNames", files.item(i)['name']);
    }
    formData.append("objectDocuments", JSON.stringify(objectDocuments));
    return this.restService.post<any>(FILE_API_URL + 'validateIfFilesExist', formData);
  }

  public buildOrderPodDownloadUrl(orderId: number) {
    return this.restService.buildUrl(PUBLIC_API_URL + 'order/pod', encodeURIComponent(encryptNumber(orderId)));
  }

  public buildMasterPodDownloadUrl(masterId: number) {
    return this.restService.buildUrl(PUBLIC_API_URL + 'master/pod', encodeURIComponent(encryptNumber(masterId)));
  }

  public buildDownloadFileUrl(id: number, objectType?: 'ORDER' | 'MASTER') {
    return this.restService.buildUrl(FILE_API_URL,  'download') +
      this.restService.encodeQueryData({id: id, type: objectType});
  }

  public buildStreamFileUrl(file: UploadedFiles | number) {
    let fileId =  typeof file === 'number' ? file : file.id;
    return this.restService.buildUrl(FILE_API_URL, 'stream', fileId);
  }

  findAllById(id: number, objectType: OmsObjectType, active: boolean, docTypes?: string[], ffg?: string, latestActive?: boolean) {
    return this.findAllByIdObs(id, objectType, active, docTypes, ffg, latestActive)
      .subscribe(
        (objectDocuments) => {
          let documents = plainToClass(ObjectDocuments, objectDocuments);
        if (active) {
          this.filesList.next(documents);
        } else {
          this.filesRemovedList.next(documents);
        }
    });
  }

  findAllByIdObs(id: number, objectType: OmsObjectType, active: boolean, docTypes?: string[], ffg?: string, latestActive?: boolean): Observable<ObjectDocuments[]> {
    let headers: HttpHeaders = this.httpUtils.getHTTPHeaders();
    let params: HttpParams = this.httpUtils.getHTTPParams({objectType: objectType, active: active});

    if (docTypes && docTypes.length) {
      params = params.append('docTypes', docTypes.join(","));
    }

    if (ffg) {
      params = params.append('ffg', ffg);
    }

    if (latestActive) {
      params = params.append('latest', 'true');
    }

    return this.restService.getWithOptions<ObjectDocuments[]>(FILE_API_URL + `findAllById/${id}`, headers, params)
      .pipe(map((items) => plainToClass(ObjectDocuments, <ObjectDocuments[]>items)));
  }


  updateDocumentTypeById(id: number, objectDocumentType: string, documentType) {
    return this.restService.get<ObjectDocuments[]>(FILE_API_URL + 'updateDocumentType', id, objectDocumentType, documentType);
  }

  downloadHistory(id: number, objectDocumentType: string) {
    return this.restService.get<ObjectDocuments[]>(FILE_API_URL + 'downloadDocumentsHistory', id, objectDocumentType).subscribe(res => {
      const fileURL = this.buildShowUrl(res);
      window.open(fileURL, '_blank');
    });
  }

  buildShowUrl(generatedDocumentInfo) {
    return this.buildStreamFileUrl(generatedDocumentInfo.id);
  }

  public download(file: UploadedFiles | number, objectType: 'ORDER' | 'MASTER') {
    let documentId =  typeof file === 'number' ? file : file.id;

    let url = this.buildDownloadFileUrl(documentId, objectType);
    console.log("DOWNLOAD URL", url);
    downloadFile(url);
  }

  public stream(file: UploadedFiles | number): Observable<Blob> {
    let documentId =  typeof file === 'number' ? file : file.id;

    const url = `${this.restService.serverUrl}${FILE_API_URL}stream/${documentId}/`;
    const headers: HttpHeaders = new HttpHeaders()
//      .append('content-type', 'application/pdf')
//      .append('content-type', 'image/jpg')
//      .append('Accept', 'application/pdf')
//      .append('Accept', 'image/jpg')
      .append('responseType', 'blob')
      .append('observe', 'response');
    return this.http.get(url, {responseType: 'blob', headers: headers, withCredentials: true});
  }


  /***
  public findOrderLastPodDocById(id: number, active: boolean) {
    return this.restService.get<ObjectDocuments>(PUBLIC_API_URL + 'order/findLastPodDocById', id, active)
      .subscribe((objectDocuments) => {
        if (objectDocuments) {
          this.download(objectDocuments.id, 'ORDER');
        }
    });
  }
   ***/

  public downloadLastActiveOrderDocByOrderId(orderId: number, documentType) {
    return this.restService.get<ObjectDocuments>(PUBLIC_API_URL + 'order/findLastActiveOrderDocByOrderId', orderId, documentType)
      .subscribe((objectDocuments) => {
        if (objectDocuments) {
          this.download(objectDocuments.id, 'ORDER');
        }
      });
  }

  public downloadLastActiveMasterDocByOrderId(orderId: number, documentType) {
    return this.restService.get<ObjectDocuments>(PUBLIC_API_URL + 'order/findLastActiveMasterDocByOrderId', orderId, documentType)
      .subscribe((objectDocuments) => {
        if (objectDocuments) {
          this.download(objectDocuments.id, 'MASTER');
        }
      });
  }


  public getActiveOrderDocCountByOrderId(orderId: number, documentType): Observable<number> {
    return this.restService.get<number>(PUBLIC_API_URL + 'order/getActiveOrderDocCountByOrderId', orderId, documentType);
  }

  public getActiveMasterDocCountByOrderId(orderId: number, documentType): Observable<number> {
    return this.restService.get<number>(PUBLIC_API_URL + 'order/getActiveMasterDocCountByOrderId', orderId, documentType);
  }



}
