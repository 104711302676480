var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { Driver } from '../modules/shared/models';
import { DriverDialogComponent } from '../components/dialogs/driver-dialog/driver-dialog.component';
import { ModalResult, OmsDialogsService } from "../components/common/oms-dialogs";
import { OmsAlertsService } from "../modules/shared/components/oms-alerts/oms-alerts.service";
import { StringUtil } from "../_helpers/string.util";
import { tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../components/common/oms-dialogs/oms-dialogs.service";
import * as i2 from "./rest.service";
import * as i3 from "./http-utils.service";
import * as i4 from "../modules/shared/components/oms-alerts/oms-alerts.service";
var DriverService = /** @class */ (function (_super) {
    __extends(DriverService, _super);
    function DriverService(dialogs, httpRestService, httpUtils, alerts) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.dialogs = dialogs;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.alerts = alerts;
        _this.apiUrl = '/oms/driver/';
        _this.classType = Driver;
        return _this;
    }
    DriverService.prototype.createEditAndSave = function (driver, readonly) {
        return this.createEditAndSaveForAdmin(driver, false, readonly);
    };
    DriverService.prototype.createEditAndSaveForAdmin = function (driver, adminEdit, readonly) {
        var _this = this;
        return new Promise(function (success, reject) {
            _this.dialogs.openDialog(DriverDialogComponent, { driver: driver, readonly: readonly, adminEdit: adminEdit })
                .then(function (result) {
                if (result.result === ModalResult.OK) {
                    _this.save(driver).subscribe(function (saved) { return success(saved); }, function (error) {
                        reject(error === 'DataIntegrityViolation' ? 'Can\'t create new Driver: \n Driver ' + driver.fullName + ' already exists!' : error);
                    });
                }
            })
                .catch(function (error) { return reject(error); });
        });
    };
    DriverService.prototype.downloadAggregatedDrivers = function () {
        var _this = this;
        var uuid = StringUtil.generateUuid();
        this.alerts.process('Generating Excel...', '', function () { return _this.getProgress(uuid); }, 5000);
        return this.httpRestService.post(this.apiUrl + 'download-aggregated-drivers-excel', null, { params: { active: true, uuid: uuid } })
            .pipe(tap(function (response) { return response; }, function (error) {
            _this.alerts.error(error, "Error Excel Generation:");
        }));
    };
    DriverService.ngInjectableDef = i0.defineInjectable({ factory: function DriverService_Factory() { return new DriverService(i0.inject(i1.OmsDialogsService), i0.inject(i2.RestService), i0.inject(i3.HttpUtilsService), i0.inject(i4.OmsAlertsService)); }, token: DriverService, providedIn: "root" });
    return DriverService;
}(BaseEntityService));
export { DriverService };
