import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, KeyValueDiffers, } from '@angular/core';
import { MasterService } from '../../../../services/master/master.service';
import { ColumnIds, OmsColumns } from '../../../../common/oms-columns.service';
import { OmsAlertsService } from '../../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { AddressService, AuthService, OrdersService } from '../../../../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterProcessor } from '../../../../common/master-processor';
import { FileUploadService } from '../../../../services/file.upload.service';
import { MasterLineService } from '../../../../services/master/master-line.service';
import { FilterSearchColumn } from '../../../../modules/shared/models/filter.search.column';
import { AbstractMasterTable } from '../abstract-master-table';
import { BaseColumn } from '../../../../modules/shared/components/base/data-table/columns/column-types';
import { FilterPageRequest } from '../../../../modules/shared/models/filter.page.request';
import { Observable } from 'rxjs';
import { PageResult } from '../../../../modules/shared/models/query-models/page-result';
import { ColorItem } from '../../../../components/common/color-search-select/color-item';
import { SearchSortRequest } from '../../../../modules/shared/models/search.sort.request';
import { TabInfo, TabsType } from '../tabs-type';
import { convertMasterNumber, convertMawbNumber } from '../../../../modules/shared/services/oms-converters.service';
import { DialogType, ModalResult, OmsDialogsService } from "../../../../components/common/oms-dialogs";
import { NumberUtils } from '../../../../_helpers/number.utils';
import {Master, Order, RecoveryOrder} from "../../../../modules/shared/models";
import { UserService } from "../../../../modules/shared/services/user.service";
import {assigned} from "../../../../_helpers/utils";

@Component({
  selector: 'app-masters-table',
  templateUrl: './masters-table.component.html',
  styleUrls: ['./masters-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MastersTableComponent extends AbstractMasterTable<Master> {

  @Input() public tabInfo: TabInfo;
  @Input() public modes: string[];
  @Input() public isVisible: boolean;
  @Input() public toolbar: HTMLDivElement;
  @Input() public colorStatusFilter: ColorItem;

  public filterPageRequest: FilterPageRequest = new FilterPageRequest(1, 100, null, new SearchSortRequest('9', true), []);

  constructor(
    protected valueDiffers: KeyValueDiffers,
    protected cdr: ChangeDetectorRef,
    protected alerts: OmsAlertsService,
    protected masterProcessor: MasterProcessor,
    protected _columnsService: OmsColumns,
    protected addressService: AddressService,
    protected _masterService: MasterService,
    protected _masterLineService: MasterLineService,
    public spinner: NgxSpinnerService,
    protected fileUploadService: FileUploadService,
    protected _ordersService: OrdersService,
    protected dialogsService: OmsDialogsService,
    protected userService: UserService,
    protected authService: AuthService) {
    super(valueDiffers, cdr, alerts, masterProcessor, addressService, _masterService, fileUploadService, _ordersService, spinner, dialogsService, userService, authService);
  }

  protected loadItemsByFilter(request: FilterPageRequest): Observable<PageResult<Master>> {
    request.modesFilter = this.modes;
    if (assigned(request.filterByColumns)) {
      request.filterByColumns.forEach((filterByColumn) => {
        filterByColumn.value = filterByColumn.value != null ? filterByColumn.value.trim() : null;
      });
    }
    return this._masterLineService.getMastersByFilterSearchRequest(request);
  }

  protected createColumns(isCanCreateCarrier: boolean): BaseColumn[] {
    return this._columnsService.createMasterDefaultColumns({
      isCarrier: (this.tabInfo !== TabsType.RECOVERY && this.tabInfo !== TabsType.RECEIVE),
      isArchived: this.tabInfo === TabsType.MASTERS_DELETED || this.tabInfo === TabsType.MASTERS_DELETED,
      displayCfsLocation: this.tabInfo === TabsType.ACTIVE || this.tabInfo === TabsType.ARCHIVED,
      displayPO: this.tabInfo === TabsType.ACTIVE,
      isCanCreateCarrier: isCanCreateCarrier,
      statuses: {available: this.tabInfo.statuses, selected: []}
    });
  }

  applyFilter(filterPageRequest?: FilterPageRequest): void {
    // Apply additional filters

    this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_COLOR_STATUS, this.colorStatusFilter ? ('' + this.colorStatusFilter.status) : null);
    this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_DIRECT, this.showDirectMasters ? '1' : null);
    this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_PENDING_RECOVERIES, this.showPendingRecoveriesMasters ? '1' : null);

    if (this.showNonAmsMasters) {
      this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_NON_AMS, '1');
    } else if (this.showAmsMasters) {
      this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_NON_AMS, '0');
    } else {
      this.removeFilterValue(this.filterPageRequest, ColumnIds.MASTER_NON_AMS);
    }

    this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_IS_CARGO, this.showCargoBldg ? '1' : null);
    this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_IS_3D_PARTY_CFS, this.show3rdPartyCFS ? '1' : null);

    if (this.availableStatuses.length) {
      let statusColumn = this.filterPageRequest.filterByColumns.find(column => column.field === 'status' || column.field === ColumnIds.MASTER_STATUS);
      const statusesIds = this.selectedStatuses.map(selectedStatus => '' + selectedStatus.id);
      if (!statusColumn) {
        this.filterPageRequest.filterByColumns.push(new FilterSearchColumn(ColumnIds.MASTER_STATUS, null, statusesIds, null));
      } else {
        statusColumn.multipleValues = statusesIds;
      }
    }
  }

  onRestoreSelected() {
    if (this.selected.length > 0) {
      this.dialogsService.confirm(DialogType.CONFIRMATION, 'Restore Masters', `Selected ${this.selected.length} Master(s) will be restored.\nContinue?`)
        .then((result) => {
          if (result.result === ModalResult.YES) {
            const arr: number[] = [];
            let info = '';
            this.selected.forEach(m => {
              arr.push(m.id);
              info += '\n' + convertMasterNumber(m.id) + ' ' + convertMawbNumber(m.mawbNumber);
            });
            this._masterService.restore(arr)
              .then(() => {
                this.selected.clear();
                this.refresh();
                this.alerts.info(info + ' restored.', 3000);
              }).catch(e => {
              this.alerts.danger(e);
            });
          }
        });
    }
  }

  onDeleteSelected() {
    if (this.selected.length > 0) {
      this.dialogsService.confirm(DialogType.CONFIRMATION, 'Delete Masters', `Selected ${this.selected.length} Master(s) will be deleted.\nContinue?`)
        .then((result) => {
          if (result.result === ModalResult.YES) {
            const arr: number[] = [];
            let info = '';
            this.selected.forEach(m => {
              arr.push(m.id);
              info += '\n' + convertMasterNumber(m.id) + ' ' + convertMawbNumber(m.mawbNumber);
            });
            this._masterService.softDelete(arr)
              .then(() => {
                this.selected.clear();
                this.refresh();
                this.alerts.info(info + ' deleted.', 3000);
              }).catch(e => {
              this.alerts.danger(e);
            });
          }
        });
    }
  }

  public updateTotalValues(items: Master[]) {
    let totalPcs = 0;
    let totalHu = 0;
    let totalWeight = 0;
    let totalUld = 0;
    for (let i = 0; i < items.length; i++) {
      if (NumberUtils.isNumber(items[i].pieces)) {
        totalPcs += items[i].pieces;
      }
      if (NumberUtils.isNumber(items[i].hu_)) {
        totalHu += items[i].hu_;
      }
      if (NumberUtils.isNumber(items[i].weight)) {
        totalWeight += items[i].weight;
      }
      if (NumberUtils.isNumber(items[i].uldCount)) {
        totalUld += items[i].uldCount;
      }
    }
    this.totalPcs = totalPcs;
    this.totalHu = totalHu;
    this.totalWeight = totalWeight;
    this.totalUld = totalUld;
  }

  public getRowClass(row: Master | Order | RecoveryOrder): string {
    if (row instanceof RecoveryOrder) {
      return row.getRouteStatusClass();
    }

    if (row instanceof Master && row.cbpWithoutHawb) {
      return 'warning';
    }
    return '';
  }
}
