import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Alert, OmsAlertsService } from './oms-alerts.service';
import { Observable, of } from 'rxjs';
import { copyToClipboard } from '../../../../_helpers/utils';
import { map, switchMap, takeUntil, tap } from "rxjs/operators";
import { AbstractComponent } from "../../../../common/component/abstract.component";
import { AuthService } from "../../../../services";
import {UserRoleType} from "../../models/user-role.type";

@Component({
  selector: 'oms-alerts',
  templateUrl: './oms-alerts.component.html',
  styleUrls: ['./oms-alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OmsAlertsComponent extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('messagesPanel') messagesPanel: ElementRef;
  @Input() showNotifications: boolean = true;

  alerts$: Observable<Alert[]>;
  displayMessage: boolean = false;
  messages: Alert[];
  isOpen: boolean = false;
  hasNew: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private alertsService: OmsAlertsService,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.initMessages();
    this.hasNew = JSON.parse(localStorage.getItem(OmsAlertsService.ALERT_HAS_NEW_STORAGE_KEY)) || false;
    this.alerts$ = this.alertsService.alertsSubject.pipe(tap(() => this.cdr.markForCheck()));
  }

  private initMessages() {
    this.authService.user$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(user => user && !user.hasRole(UserRoleType.ROLE_EXTERNAL_WAREHOUSE)),
        switchMap(noExternalUser => {
          this.displayMessage = noExternalUser;
          return this.displayMessage
            ? this.alertsService.messagesSubject
              .pipe(tap((messages) => {
                if (!this.isOpen && !!this.messages && this.messages.length) {
                  this.setHasNew(true);
                }
                const addedMessage = !this.messages || this.messages.length < messages.length;
                this.messages = messages;
                if (addedMessage) {
                  this.scrollTop();
                }
              }))
            : of([]);
        })
      )
      .subscribe(messages => {
        this.cdr.markForCheck();
      });
  }

  private scrollTop() {
    const el = this.messagesPanel && this.messagesPanel.nativeElement;
    if (!el) {
      return;
    }
    el.scrollTop = 0;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
  }

  onAlertClosed(alert: Alert): void {
    this.alertsService.remove(alert);
  }

  onMessageClosed(alert: Alert): void {
    this.alertsService.removeMessage(alert);
  }

  copyToClipboard(message, stack: any) {
    let s: string = message + '\n\n';
    if (typeof stack === 'string') {
      s += stack;
    } else if (stack.length) {
      stack.forEach(e => s += e.className + "." + e.methodName + '(): ' + e.fileName + ':' + e.lineNumber + '\n');
    }

    copyToClipboard(s);
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.setHasNew(false);
      this.scrollTop();
    }
  }

  setHasNew(value: boolean): void {
    this.hasNew = value;
    localStorage.setItem(OmsAlertsService.ALERT_HAS_NEW_STORAGE_KEY, JSON.stringify(value));
  }
}
