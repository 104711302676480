import { ChangeDetectorRef, OnInit } from '@angular/core';
import { OmsConstants } from '../../common/oms-constants.service';
import { Master } from '../../modules/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../services/master/master.service';
import { convertMawbNumber } from '../../modules/shared/services/oms-converters.service';
import { OmsAlertsService } from '../../modules/shared/components/oms-alerts/oms-alerts.service';
import { plainToClass } from 'class-transformer';
import { subtractHours } from '../../common/oms-date-time.utils';
import { isNullOrUndefined } from 'util';
import { Size } from '../../common/oms-types';
import { checkNumber } from '../../_helpers/utils';
import { ReceivingService } from '../../services/receiving.service';
var PickupComponent = /** @class */ (function () {
    function PickupComponent(cdr, constants, route, alerts, router, receivingService, masterService) {
        this.cdr = cdr;
        this.constants = constants;
        this.route = route;
        this.alerts = alerts;
        this.router = router;
        this.receivingService = receivingService;
        this.masterService = masterService;
        this.Size = Size;
        this.isUpdate = false;
    }
    PickupComponent.prototype.changed = function () {
        this.cdr.markForCheck();
    };
    PickupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            var masterId = +params['master'];
            var loadId = +params['load'];
            if (masterId) {
                _this.masterService.getMaster(masterId)
                    .then(function (master) {
                    _this.setMaster(master, loadId);
                });
            }
        });
    };
    PickupComponent.prototype.setMaster = function (master, loadId) {
        this.selectedSplit = null;
        this.master = master;
        this.isUpdate = false;
        if (this.master) {
            this.splits = this.master.splits.filter(function (split) { return split.isDispatched; });
            if (loadId) {
                this.selectedSplit = this.splits.find(function (split) { return split.loadId === loadId; });
            }
            else {
                this.selectedSplit = this.splits.length > 0 ? this.splits[0] : null;
            }
        }
        this.changed();
    };
    Object.defineProperty(PickupComponent.prototype, "parts", {
        get: function () {
            var _this = this;
            return this.master ? this.master.parts.filter(function (part) { return _this.selectedSplit && part.loadId === _this.selectedSplit.loadId; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickupComponent.prototype, "mawbNumber", {
        get: function () {
            return this.master ? convertMawbNumber(this.master.mawbNumber) : null;
        },
        enumerable: true,
        configurable: true
    });
    PickupComponent.prototype.onUrlChange = function () {
        this.router.navigate([], { relativeTo: this.route, queryParams: { master: this.master.id, load: this.selectedSplit.loadId } });
    };
    Object.defineProperty(PickupComponent.prototype, "multipleLoads", {
        get: function () {
            return this.master && this.master.splits.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    PickupComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.selectedSplit) {
            var dateDeliveryActual_1 = new Date();
            var datePickupActual_1 = this.selectedSplit.shipment.datePickUpExpectation || this.selectedSplit.shipment.datePickUpActual || subtractHours(dateDeliveryActual_1, 4);
            var parts = this.parts;
            if (parts.length > 0) {
                var data_1 = {
                    masterId: this.master.id,
                    loadId: this.selectedSplit.loadId,
                    pickupDate: datePickupActual_1,
                    deliveryDate: dateDeliveryActual_1,
                    loadPieces: this.selectedSplit.pcsReceived,
                    loadWeight: this.selectedSplit.weightReceived,
                    loadShipmentId: this.selectedSplit.shipment.id,
                    parts: []
                };
                parts.forEach(function (part) {
                    data_1.parts.push({
                        orderId: part.orderId,
                        shipmentContentId: part.content.id,
                        pieces: part.pieces,
                        weight: part.weight,
                        hu: part.hu
                    });
                });
                this.receivingService.updateMapping(data_1)
                    .then(function () {
                    _this.selectedSplit.shipment.dateDeliveryActual = dateDeliveryActual_1;
                    _this.selectedSplit.shipment.datePickUpActual = datePickupActual_1;
                    _this.isUpdate = false;
                    _this.cdr.markForCheck();
                    _this.alerts.success("Receiving Data is Stored successfully.");
                })
                    .catch(function (error) {
                    _this.cdr.markForCheck();
                    _this.alerts.danger("Error saving data: " + error);
                });
            }
        }
    };
    Object.defineProperty(PickupComponent.prototype, "allowSubmit", {
        get: function () {
            return this.selectedSplit && (isNullOrUndefined(this.selectedSplit.dateDeliveryAct) || this.isUpdate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickupComponent.prototype, "totalReceivedPieces", {
        get: function () {
            return this.selectedSplit ? this.selectedSplit.pcsReceived : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickupComponent.prototype, "totalReceivedWeight", {
        get: function () {
            return this.selectedSplit ? this.selectedSplit.weightReceived : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickupComponent.prototype, "totalReceivedHu", {
        get: function () {
            return this.selectedSplit ? this.selectedSplit.huReceived : null;
        },
        enumerable: true,
        configurable: true
    });
    PickupComponent.prototype.onMawbSearch = function (mawb) {
        var _this = this;
        if (mawb && mawb.length >= 4) {
            this.masterService.searchByMawb(mawb, 3).toPromise().then(function (masters) {
                _this.setMaster(masters.numberOfElements > 0 ? plainToClass(Master, masters.content[0]) : null);
            }).catch(function (error) {
                _this.setMaster(null);
                console.error(error);
            });
        }
    };
    Object.defineProperty(PickupComponent.prototype, "noMaster", {
        get: function () {
            return isNullOrUndefined(this.master);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickupComponent.prototype, "noDispatched", {
        get: function () {
            return !this.noMaster && (!this.parts || this.parts.length === 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickupComponent.prototype, "masterPieces", {
        get: function () {
            return this.master ? this.master.pieces || null : null;
        },
        enumerable: true,
        configurable: true
    });
    PickupComponent.prototype.cleanUp = function (value) {
        return checkNumber(value);
    };
    return PickupComponent;
}());
export { PickupComponent };
