import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ReceivingService} from '../../../../services/receiving.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {BaseDialogComponent} from '../../../common/oms-dialogs/base-dialog/base-dialog.component';
import {Subject} from 'rxjs';
import {Load, Master} from '../../../../modules/shared/models';
import {OmsConverters} from '../../../../modules/shared/services/oms-converters.service';
import {DialogResult} from "../../../common/oms-dialogs";
import {Receiving} from "../../../../modules/shared/models/warehouse/receiving";


interface ReceivingLogDialogData {
  master: Master;
  load?: Load;
}

@Component({
  selector: 'app-receiving-log-dialog',
  templateUrl: './receiving-log-dialog.component.html',
  styleUrls: ['./receiving-log-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceivingLogDialog implements OnInit {
  public list: Subject<Receiving[]> = new Subject<Receiving[]>();
  public master: Master;
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReceivingLogDialogData,
    public cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<BaseDialogComponent, DialogResult<any>>,
    public converters: OmsConverters,
    private receivingService: ReceivingService) {
    this.master = data.master;
  }

  ngOnInit() {
    if (this.data.master) {
      this.loading = true;
      this.receivingService.getAllByMasterId(this.data.master.id)
        .then(receivings => {
          this.loading = false;
//          console.log('Receivings', receivings);
          this.list.next(receivings);
          this.cdr.markForCheck();
        })
        .catch(error => {
          this.loading = false;
        });
    }

  }

  rldOf(load: Load): string {
    let split = load ? this.master.splits.find((s) => s.loadId === load.id) : null;
    return split ? 'RLD#' + split.label : 'N/A';
  }

  driverName(load: Load): string {
    let split = load ? this.master.splits.find((s) => s.loadId === load.id) : null;
    return split ? split.driver ? split.driver.fullName : 'N/A' : 'N/A';
  }

  truckNumber(load: Load): string {
    let split = load ? this.master.splits.find((s) => s.loadId === load.id) : null;
    return split ? split.truck ? split.truck.number : 'N/A' : 'N/A';
  }

  trailerNumber(load: Load): string {
    let split = load ? this.master.splits.find((s) => s.loadId === load.id) : null;
    return split ? split.trailer ? split.trailer.number : 'N/A' : 'N/A';
  }


}
