<tr [ngStyle]="rowStyles" [ngClass]="rowClassesDef" [class]="rowClass" [style.height.px]="rowHeight"
    (contextmenu)="table.onRowContextMenu($event, row)"
    draggable [dragEnabled]="!!dragScope" [dragScope]="dragScope" [dragData]="row"
    (dblclick)="table.onRowDoubleClick(row)"
    droppable [dropEnabled]="!!dropScope" [dropScope]="dropScope" [dragOverClass]="'row-droppable-over'" [dragHintClass]="'row-droppable-hint'" (onDrop)="table.onRowDrop($event, row)">

  <span class="sticky-columns">

    <td *ngIf="tree" style="min-width: 15px; max-width: 15px;">
      <span *ngIf="!isLeaf" [ngClass]="{'fa fa-plus-square': !expanded, 'far fa-minus-square':expanded}"
            [style.cursor]="'pointer'" (click)="table.toggleExpanded(row)">
      </span>
    </td >

    <td style="width: 25px; min-width: 25px;" scope="row" *ngIf="table.selectable && table.checkboxes" class="data-table-cell">
      <span class="fancy-checkbox custom-bgcolor-green" (click)="selectedRow($event)" *ngIf="selectable && table.multiSelect">
        <input type="checkbox" value="" [attr.checked]="selected ? '' : null" title="">
        <span><i></i></span>
      </span>

      <span class="fancy-radio custom-bgcolor-green" (click)="selectedRow($event)" *ngIf="selectable && !table.multiSelect">
        <input type="radio"  value="" [attr.checked]="selected ? '' : null" title="">
        <span><i></i></span>
      </span>
    </td>

    <ng-container *ngIf="!!template; else stickyColumnsList">
      <ng-container *ngTemplateOutlet="template; context:{row: row}"></ng-container>
    </ng-container>

    <ng-template #stickyColumnsList>
      <ng-container *ngFor="let column of table.stickyColumns; trackBy: trackByRowObject; let i = index">
        <td *ngIf="column.visible" [style.minWidth]="column.width" [style.maxWidth]="column.stretch ? column.maxWidth : column.maxWidth || column.width"
            [ngClass]="{'active':column.editing}"
            (dblclick)="table.onCellDoubleClick(row, column)"
            (contextmenu)="table.onCellContextMenu($event, row, column)"
            [style.text-align]="table.columnByRow(column, row).align">
          <span oms-data-table-cell #cells class="data-table-cell" [column]="table.columnByRow(column, row)" [row]="row" ></span>
        </td>
      </ng-container>
    </ng-template>
  </span>

  <ng-template [ngIf]="!template">
    <ng-container *ngFor="let column of table.otherColumns; trackBy: trackByRowObject; let i = index">
      <td *ngIf="column.visible" [style.minWidth]="column.width" [style.maxWidth]="column.stretch ? column.maxWidth : column.maxWidth || column.width"
          [ngClass]="{'active':column.editing}"
          (dblclick)="table.onCellDoubleClick(row, column)"
          (click)="onRowClick(row, $event)"
          (contextmenu)="table.onCellContextMenu($event, row, column)"
          [style.text-align]="table.columnByRow(column, row).align">
        <span oms-data-table-cell #cells class="data-table-cell" [column]="table.columnByRow(column, row)" [row]="row"></span>
      </td>
    </ng-container>
  </ng-template>
</tr>

<ng-container *ngIf="tree && expanded">
  <ng-container *ngFor="let child of ((tree.children(row) | async) | sort_children : table.sortedByColumn : !table.sortedAsc) as items; let i=index;">
    <oms-data-table-row [odd]="odd"
                        [row]="child"
                        [parent-row]="row"
                        [index]="i"
                        [top-offset]="getChildRowOffset(i, items)"
                        [tree]="tree"
                        [selectable]="tree.childrenSelectable && !!tree.childrenSelectable(row)"
                        [selected]="table.isSelectedRow(child)"
                        (selectedChange)="table.toggleSelected(child, $event)"
                        [expandedItems]="expandedItems"></oms-data-table-row>
  </ng-container>
</ng-container>
