import { Injectable } from '@angular/core';
import {BaseEntityService} from "../../../services/base/base-entity.service";
import {RestService} from "../../../services/rest.service";
import {HttpUtilsService} from "../../../services/http-utils.service";
import {UserRole} from "../models/user-role";

@Injectable({  providedIn: 'root' })
export class UserRoleService extends BaseEntityService<UserRole> {

  protected apiUrl = "/api/user-roles/";
  protected classType = UserRole;


  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }
}
