import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {OrderDocuments} from "../../../../modules/shared/models/documents/order.documents";
import {BehaviorSubject, Subject} from "rxjs";
import {FileUploadService} from "../../../../services/file.upload.service";
import {OmsAlertsService} from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {OmsDialogsService} from "../../oms-dialogs";
import {extractFileExt} from "../../../../_helpers/string.util";
import {DomSanitizer} from "@angular/platform-browser";
import {PdfViewerComponent} from "ng2-pdf-viewer/dist/pdf-viewer.component";

@Component({
  selector: 'oms-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit, OnChanges {
  @Input() public documents: OrderDocuments[];
  @Input() public active: OrderDocuments;
  @Output() public activeChange: EventEmitter<OrderDocuments> = new EventEmitter<OrderDocuments>();
  @Input() public fitToPage: boolean = true;
  @Output() public fitToPageChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('image') image: ElementRef;
  @ViewChild(PdfViewerComponent)
  private pdfComponent: PdfViewerComponent;

  readonly source: Subject<Uint8Array> = new BehaviorSubject<Uint8Array>(undefined);

  public disabled: boolean = false;
  public loading: boolean = false;
  public error;
  public data: Blob;

  public rotates: number[] = [0, 90, 180, 270];
  public rotate: number = 0;

  constructor(
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
//    private dialogs: OmsDialogsService,
    private alerts: OmsAlertsService,
    private fileUploadService: FileUploadService
  ) { }


  private getData() {
    if (this.active) {
      this.loading = true;
      this.fileUploadService.stream(this.active.uploadedFiles)
        .subscribe(
          (result: Blob) => {
            this.data = result;

            new Response(result).arrayBuffer()
              .then((buffer) => {
                this.loading = false;

                this.source.next(undefined);
                this.source.next(new Uint8Array(buffer));
                this.error = undefined;
                this.cdr.markForCheck();
              }).catch((error) => {
                this.loading = false;
                this.data = undefined;
                this.cdr.markForCheck();
                this.error = 'Failed to load document: ' + error;
//              this.dialogs.openErrorDialog(error, 'Error Loading Document');
            });
          }, (error) => {
            this.loading = false;
            this.cdr.markForCheck();
            this.data = undefined;
            this.error = 'Failed to load document: ' + error;
//            this.dialogs.openErrorDialog(error, 'Error Loading Document');
          });
    }
  }

  private initializeView() {
    this.rotate = 0;
    this.data = undefined;
    this.source.next(undefined);

    if (!this.fitToPage) {
      this.fitToPage = true;
      this.fitToPageChange.emit(this.fitToPage);
    }

    if (this.active) {
      this.getData();
    } else {
      this.disabled = false;
    }
  }

  ngOnInit() {
  }

  onCarouselEvent(event) {
    console.log('CAROUSEL', event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active) {
      this.initializeView();
    }
    console.log('CHANGES', changes);
  }

  public next() {
    if (this.active) {
      this.active = this.documents.next(this.active, true);
      this.activeChange.emit(this.active);
      this.initializeView();
    }
  }

  public previous() {
    if (this.active) {
      this.active = this.documents.prev(this.active, true);
      this.activeChange.emit(this.active);
      this.initializeView();
    }
  }

  public get isPdf(): boolean {
    return !this.loading && !this.error && this.active && this.active.uploadedFiles.name &&
      this.active.uploadedFiles.name.toLowerCase().endsWith('.pdf');
  }

  public get isImage(): boolean {
    return !this.error && this.active && this.active.uploadedFiles.name &&
      ['jpg', 'jpeg', 'png', 'bmp', 'svg']
        .includes(extractFileExt(this.active.uploadedFiles.name.toLowerCase()));
  }

  public get dataUrl(): any {
    return this.active && /*this.domSanitizer.bypassSecurityTrustResourceUrl(*/this.fileUploadService.buildStreamFileUrl(this.active.uploadedFiles); /*);*/
  }


  public isFirst(): boolean {
    return this.documents && this.documents.first() === this.active;
  }

  public isLast(): boolean {
    return this.documents && this.documents.last() === this.active;
  }

  public setActive(document) {
    this.active = document;
    this.activeChange.emit(document);
    this.getData();
  }

  get landscape() {
    return this.image && this.image.nativeElement.naturalHeight < this.image.nativeElement.naturalWidth;
  }

  public print() {

    let landscape = this.landscape;
    const css = this.image ?
      `@media print {
          @page { size: ${landscape ? 'landscape' : 'portrait'}; }
          img {
               width : ${landscape ? 'auto' : '100%'};
               height : ${landscape ? '100%' : 'auto'};
          }
      }` :

      `@media print {
          @page { size: auto; }
      }`;


    if ( this.data ) {
      let file = window.URL.createObjectURL(this.data);
      let iframe = document.createElement('iframe');
      iframe.onload = function() {
//        let css = '@page { size: auto; }',
        let doc = iframe.contentWindow.document,
          head = doc.head || doc.getElementsByTagName('head')[0],
          style = doc.createElement('style');

        style.type = 'text/css';
        style.media = 'print';
        style.appendChild(doc.createTextNode(css));
        head.appendChild(style);
//            console.log('DOCUMENT', iframe.contentWindow.document);
      };
      iframe.style.display = 'none';
      iframe.src = file;

      document.body.appendChild(iframe);
      iframe.contentWindow.print();


    }


/*    this.fileUploadService.stream(this.active.uploadedFiles)
      .subscribe(
        (result: Blob) => {
          // `blob` response
          let file = window.URL.createObjectURL(result);
//          console.log(file);



          let iframe = document.createElement('iframe');
          iframe.onload = function() {
            let css = '@page { size: auto; }',
              doc = iframe.contentWindow.document,
              head = doc.head || doc.getElementsByTagName('head')[0],
              style = doc.createElement('style');

            style.type = 'text/css';
            style.media = 'print';
            style.appendChild(doc.createTextNode(css));
            head.appendChild(style);
//            console.log('DOCUMENT', iframe.contentWindow.document);
          };
          iframe.style.display = 'none';
          iframe.src = file;

          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        });

 */

  }

  public rotateLeft() {
    this.rotate = this.rotates.prev(this.rotate, true);
  }

  public rotateRight() {
    this.rotate = this.rotates.next(this.rotate, true);
  }



}
