<oms-string-input [number]="true" [show-label]="showLabel" [label]="label" [placeholder]="_constants.piecesPlaceholder" [size]="size" [readonly]="readonly" [disabled]="disabled"
      [input-mask]="_constants.piecesMask" [(ngModel)] = "internal">

  <ng-container input-before>
    <ng-content select="[input-before]">
    </ng-content>
  </ng-container>

  <ng-container group-before>
    <ng-content select="[group-before]">
    </ng-content>
  </ng-container>


  <span input-after class="input-group-btn" *ngIf="showButton">
    <oms-select-button [disabled]="disabled || unitsDisabled" [(selected)]="units"
                       (selectedChange)="onUnitsChanged($event)" [items]="containers ? _constants.containerSizes.getAllLabels() : _constants.packagingUnits.getAllLabels()">

    </oms-select-button>
  </span>
</oms-string-input>
