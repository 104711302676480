import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Uld } from '../../../modules/shared/models';
import { DriverService } from '../../../services/driver-service';
import { TrailerService } from '../../../services/trailer-service';
import { TruckService } from '../../../services/truck-service';
import { Size } from '../../../common/oms-types';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { MasterService } from '../../../services/master/master.service';
import { ModalResult, OmsDialogsService } from "../../common/oms-dialogs";
import { AuthService } from "../../../services";
import { UserRoleType } from "../../../modules/shared/models/user-role.type";
/**
 * @deprecated
 */
var LoadPickupDialogComponent = /** @class */ (function () {
    function LoadPickupDialogComponent(auth, dialogs, cdr, data, dialogRef, masterService, truckService, trailerService, driverService, alerts) {
        var _this = this;
        this.auth = auth;
        this.dialogs = dialogs;
        this.cdr = cdr;
        this.data = data;
        this.dialogRef = dialogRef;
        this.masterService = masterService;
        this.truckService = truckService;
        this.trailerService = trailerService;
        this.driverService = driverService;
        this.alerts = alerts;
        this.loadsPickedUp = [];
        this.Size = Size;
        this.returned = [];
        this.pickedUp = [];
        this.loading = true;
        this.visible = true;
        this.forceUpdate = false;
        // Total Picked Up by Prior Recoveries
        this.pcsPickedUpTotal = 0;
        this.huPickedUpTotal = 0;
        this.noCamera = false;
        this.loading = true;
        //    this.visible = false;
        console.log('DATA', this.data);
        this.isInternalUser = auth.isUserInRoles([UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER]);
        this.isDriver = auth.isUserInRole(UserRoleType.ROLE_DRIVER);
        this.masterService.getMaster(data.masterId)
            .then(function (master) {
            _this.loading = false;
            //          this.visible = true;
            console.log('MASTER>', master);
            _this.master = master;
            _this.ulds = master.masterAirUld;
            while (_this.ulds.length < 12) {
                _this.ulds.push(new Uld());
            }
            _this.ulds.forEach(function (uld) {
                if (uld.isPickedUp) {
                    _this.pickedUp.push(uld);
                }
                if (uld.isReturned) {
                    _this.returned.push(uld);
                }
            });
            console.log('ORDER ID', _this.data.orderId, 'Picked', _this.pickedUp, 'Returned', _this.returned, _this.ulds);
            _this.loads = _this.prepareLoads(master.splits, master.ulds).sort(function (a, b) { return _this.byPickupDate(a, b); });
            _this.loadsPickedUp = _this.loads.filter(function (ld) { return ld.isPickedUp; });
            _this.setSelected(_this.loads.find(function (ld) { return _this.data.orderId ? ld.orderId === _this.data.orderId : !ld.isPickedUp; }) || _this.loads.first());
        }).catch(function (error) {
            _this.loading = false;
            _this.alerts.error(error);
            _this.dialogRef.close({ result: ModalResult.CANCEL });
            //        this.visible = true;
        });
    }
    LoadPickupDialogComponent.prototype.compareDate = function (a, b) {
        if (!a) {
            return !b ? 0 : -1;
        }
        else if (!b) {
            return 1;
        }
        return a.getTime() - b.getTime();
    };
    LoadPickupDialogComponent.prototype.byPickupDate = function (a, b) {
        return this.compareDate(a && a.datePickupAct, b && b.datePickupAct);
    };
    LoadPickupDialogComponent.prototype.getUldsPickedUp = function (ld) {
        return ld.ulds.filter(function (u) { return u.isPickedUp && u.pickedUpByOrderId === ld.orderId; });
    };
    LoadPickupDialogComponent.prototype.getUldsToPickUp = function (ld) {
        return ld.ulds.filter(function (u) { return !u.isPickedUp || (u.pickedUpByOrderId === ld.orderId); });
    };
    LoadPickupDialogComponent.prototype.getRecoveredUlds = function (split, ulds, splits) {
        return ulds.map(function (u) {
            var ur = split.uldsRecovered && split.uldsRecovered.find(function (u1) { return u1.uld.id === u.id; });
            var pickedUpByOrder;
            var pcsPickedUp;
            var huPickedUp;
            if (!!u.datePickupAct) {
                // uld is already recovered with Order
                pickedUpByOrder = splits.find(function (ro) { return !!ro.uldsRecovered.find(function (u1) { return u1.uld.id === u.id; }); });
                if (pickedUpByOrder) {
                    var recovered = pickedUpByOrder.uldsRecovered.find(function (ur1) { return ur1.uld.id === u.id; });
                    huPickedUp = recovered && recovered.hu;
                    pcsPickedUp = recovered && recovered.pcs;
                }
            }
            return {
                id: u.id,
                uldNumber: u.uldNumber,
                datePickupAct: u.datePickupAct,
                isPickedUp: !!u.datePickupAct,
                pcsPickedUp: pcsPickedUp,
                huPickedUp: huPickedUp,
                pickedUpByOrderId: pickedUpByOrder && pickedUpByOrder.orderId,
                pcs: ur && ur.pcs || undefined,
                hu: ur && ur.hu || undefined,
            };
        });
    };
    LoadPickupDialogComponent.prototype.prepareLoads = function (splits, ulds) {
        var _this = this;
        var result = [];
        this.pcsPickedUpTotal = 0;
        this.huPickedUpTotal = 0;
        splits.forEach(function (split) {
            var uldsRecovered = _this.getRecoveredUlds(split, ulds, splits);
            var pcsDispatched = split.pieces; // Initially Dispatched PCS
            var huDispatched = split.hu; // Initially dispatched HU
            var pcsUld = 0;
            uldsRecovered.forEach(function (n) { return pcsUld += ~~n.pcs; }); // Total PCS Picked Up from ULDs
            var huUld = 0;
            uldsRecovered.forEach(function (n) { return huUld += ~~n.hu; }); // Total HU Picked Up from ULDs
            var isPickedUp = !!split.datePickupAct;
            var huPickedUp = isPickedUp ? split.hu : undefined;
            var pcsPickedUp = isPickedUp ? split.pieces : undefined;
            if (isPickedUp) {
                _this.pcsPickedUpTotal += ~~pcsPickedUp;
                _this.huPickedUpTotal += ~~huPickedUp;
            }
            var pcsLoose = isPickedUp ? ~~pcsPickedUp - pcsUld : undefined;
            var huLoose = isPickedUp ? ~~huPickedUp - huUld : undefined;
            var load = {
                split: split,
                loadId: split.loadId,
                orderId: split.orderId,
                dateDispatched: split.dateDispatched,
                datePickupEst: split.datePickupEst,
                datePickupAct: split.datePickupAct || new Date(),
                isPickedUp: isPickedUp,
                driverDispatched: split.driver,
                driverPickedUp: split.driver,
                truckDispatched: split.truck,
                truckPickedUp: split.truck,
                trailerDispatched: split.trailer,
                trailerPickedUp: split.trailer,
                pcsDispatched: pcsDispatched,
                huDispatched: huDispatched,
                pcsPickedUp: pcsPickedUp,
                huPickedUp: huPickedUp,
                pcsLoose: pcsLoose,
                huLoose: huLoose,
                ulds: uldsRecovered
            };
            console.log('PREPARED', load);
            result.push(load);
        });
        return result;
    };
    LoadPickupDialogComponent.prototype.validate = function () {
        var _this = this;
        return new Promise(function (confirm, reject) {
            if (!_this.selectedLoad) {
                reject("No Load Selected");
            }
            if (_this.selectedLoad.huLoose && _this.selectedLoad.huLoose > ~~_this.selectedLoad.pcsLoose) {
                reject('Loose HU must be less or equal to Loose PCS');
                return;
            }
            _this.selectedLoad.ulds.forEach(function (u) {
                if (u.hu && u.hu > ~~u.pcs) {
                    reject('ULD HU must be less or equal to ULD PCS');
                    return;
                }
                if (u.pcs && !u.uldNumber) {
                    reject('ULD Number is missing');
                    return;
                }
            });
            if (!_this.selectedLoad || !_this.selectedLoad.datePickupAct) {
                reject('Actual Pickup Date must be selected');
            }
            else if (!_this.selectedLoad.driverPickedUp) {
                reject('Picked Up Driver must be selected');
            }
            else {
                var pcsTotal = _this.getTotalPieces(_this.selectedLoad);
                //        let huTotal = this.getTotalHu(this.selectedLoad);
                if (!pcsTotal) {
                    reject('Picked Up PCS must be greater than zero');
                }
                else {
                    confirm();
                }
            }
        });
    };
    LoadPickupDialogComponent.prototype.isSelected = function (split) {
        return split === this.selectedLoad;
    };
    LoadPickupDialogComponent.prototype.setSelected = function (split) {
        this.selectedLoad = split;
    };
    LoadPickupDialogComponent.prototype.isEstimated = function (split) {
        return !split.datePickupAct && !!split.datePickupEst;
    };
    LoadPickupDialogComponent.prototype.ngOnInit = function () {
    };
    LoadPickupDialogComponent.prototype.onSubmit = function ($event) {
        var _this = this;
        $event.canClose = false;
        if (!this.selectedLoad.huLoose && this.selectedLoad.pcsLoose) {
            this.selectedLoad.huLoose = this.selectedLoad.pcsLoose;
        }
        this.selectedLoad.ulds.forEach(function (u) {
            if (!u.hu && u.pcs) {
                u.hu = u.pcs;
            }
        });
        this.validate()
            .then(function () {
            _this.ulds.forEach(function (uld) {
                if (_this.isPickedUp(uld)) {
                    uld.datePickupAct = _this.selectedLoad.datePickupAct;
                }
                if (_this.isReturned(uld)) {
                    uld.dateReturnAct = _this.selectedLoad.datePickupAct;
                }
            });
            _this.data.pickup = [{
                    orderId: _this.selectedLoad.orderId,
                    datePickupAct: _this.selectedLoad.datePickupAct,
                    driver: _this.selectedLoad.driverPickedUp,
                    truck: _this.selectedLoad.truckPickedUp,
                    trailer: _this.selectedLoad.trailerPickedUp,
                    pcs: _this.getTotalPieces(_this.selectedLoad),
                    hu: _this.getTotalHu(_this.selectedLoad),
                    uld: _this.selectedLoad.ulds
                }];
            /*this.loads.forEach(ld=>{
              this.data.pickup.push({
                id: ld.loadId,
                datePickupAct: ld.datePickupAct,
                driver: ld.driverPickedUp,
                truck: ld.truckPickedUp,
                trailer: ld.trailerPickedUp,
                pcs: ld.pcsPickedUp,
                hu:ld.huPickedUp,
                uld:[{id: 10, result: 'PickedUp'}]
              })
            }); */
            _this.dialogRef.close({ result: ModalResult.OK, data: _this.data });
        })
            .catch(function (error) {
            _this.alerts.danger(error);
        });
    };
    LoadPickupDialogComponent.prototype.setReturned = function (uld, value) {
        if (value) {
            this.returned.push(uld);
        }
        else {
            uld.dateReturnAct = undefined;
            this.returned.removeAll(function (item) { return item === uld; });
        }
    };
    LoadPickupDialogComponent.prototype.isPickedUp = function (uld) {
        return this.pickedUp.includes(uld);
    };
    LoadPickupDialogComponent.prototype.isReturned = function (uld) {
        return this.returned.includes(uld);
    };
    LoadPickupDialogComponent.prototype.getTotalHu = function (load) {
        var hu = 0;
        load.ulds.forEach(function (uld) { return hu += ~~uld.hu; });
        return hu + ~~load.huLoose;
    };
    LoadPickupDialogComponent.prototype.getTotalPieces = function (load) {
        var pcs = 0;
        load.ulds.forEach(function (uld) { return pcs += ~~uld.pcs; });
        return pcs + ~~load.pcsLoose;
    };
    LoadPickupDialogComponent.prototype.canUpdateLoad = function () {
        return this.selectedLoad && (!this.selectedLoad.isPickedUp || this.forceUpdate);
    };
    LoadPickupDialogComponent.prototype.canUpdateUld = function (uld) {
        return this.canUpdateLoad() && (!uld.isPickedUp || (uld.pickedUpByOrderId === this.selectedLoad.orderId));
    };
    LoadPickupDialogComponent.prototype.openCamera = function () {
        this.dialogs.openCameraDialog({
            orderId: this.selectedLoad && this.selectedLoad.orderId,
            masterId: this.master && this.master.id,
            docTypeList: ['POD']
        });
    };
    LoadPickupDialogComponent.prototype.openDocCenter = function () {
        this.dialogs.openDocCenterWindow({ objectType: 'ORDER', id: this.selectedLoad.orderId }, true);
    };
    Object.defineProperty(LoadPickupDialogComponent.prototype, "showDetails", {
        get: function () {
            return !this.isDriver && this.isInternalUser;
        },
        enumerable: true,
        configurable: true
    });
    return LoadPickupDialogComponent;
}());
export { LoadPickupDialogComponent };
