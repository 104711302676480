var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, KeyValueDiffers, SimpleChanges, } from '@angular/core';
import { MasterStatus, MasterStatusId, Order } from '../../../../modules/shared/models';
import { MasterService } from '../../../../services/master/master.service';
import { OmsColumns } from '../../../../common/oms-columns.service';
import { OmsAlertsService } from '../../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { AddressService, AuthService, OrdersService } from '../../../../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterProcessor } from '../../../../common/master-processor';
import { FileUploadService } from '../../../../services/file.upload.service';
import { FilterSearchColumn } from '../../../../modules/shared/models/filter.search.column';
import { AbstractMasterTable } from '../abstract-master-table';
import { FilterPageRequest } from '../../../../modules/shared/models/filter.page.request';
import { ColorItem } from '../../../../components/common/color-search-select/color-item';
import { SearchSortRequest } from '../../../../modules/shared/models/search.sort.request';
import { OmsDialogsService } from '../../../../components/common/oms-dialogs';
import { NumberUtils } from '../../../../_helpers/number.utils';
import { isNullOrUndefined } from "util";
import { UserService } from "../../../../modules/shared/services/user.service";
var TabFilter = /** @class */ (function () {
    function TabFilter() {
        this.btnHidden = false;
    }
    return TabFilter;
}());
export { TabFilter };
var OrdersTableComponent = /** @class */ (function (_super) {
    __extends(OrdersTableComponent, _super);
    function OrdersTableComponent(valueDiffers, cdr, alerts, masterProcessor, _columnsService, addressService, _masterService, spinner, fileUploadService, _ordersService, dialogsService, userService, authService) {
        var _this = _super.call(this, valueDiffers, cdr, alerts, masterProcessor, addressService, _masterService, fileUploadService, _ordersService, spinner, dialogsService, userService, authService) || this;
        _this.valueDiffers = valueDiffers;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.masterProcessor = masterProcessor;
        _this._columnsService = _columnsService;
        _this.addressService = addressService;
        _this._masterService = _masterService;
        _this.spinner = spinner;
        _this.fileUploadService = fileUploadService;
        _this._ordersService = _ordersService;
        _this.dialogsService = dialogsService;
        _this.userService = userService;
        _this.authService = authService;
        _this.namedCondition = 'NOT_GENERIC';
        _this.baseColumns = [];
        _this.filterPageRequest = new FilterPageRequest(1, 100, null, new SearchSortRequest('id', false), []);
        return _this;
    }
    OrdersTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes.baseColumns && this.baseColumns && this.baseColumns.length) {
            this.columns = this.baseColumns;
        }
        else if (changes.columnFunction && this.columnFunction) {
            var user = this.authService.getCurrentUser();
            this.columns = this.createColumns(user.canCreateCarrier);
        }
        _super.prototype.ngOnChanges.call(this, changes);
    };
    OrdersTableComponent.prototype.loadItemsByFilter = function (request) {
        request.modesFilter = this.modes;
        return this._ordersService.findByFilters(request, this.active);
    };
    OrdersTableComponent.prototype.createColumns = function (isCanCreateCarrier) {
        var _this = this;
        var hasBillingApproveRole = this.authService.hasBillingApprove();
        var items = this.tabInfo.statuses ? MasterStatus.items.filter(function (item) { return _this.tabInfo.statuses.includes(item.id); }) : [];
        var config = this._columnsService.buildOrderConfig(this.tabInfo, items, hasBillingApproveRole, this.modes, isCanCreateCarrier);
        return isNullOrUndefined(this.columnFunction)
            ? this._columnsService.buildOrderColumnsByTab(this.tabInfo, isCanCreateCarrier)
            : this._columnsService[this.columnFunction](config);
    };
    OrdersTableComponent.prototype.applyFilter = function (filterPageRequest) {
        if (this.showNonAmsMasters) {
            this.changeFilterValue(this.filterPageRequest, 'shipmentContents.shipment.masterShipments.master.nonAMS', '1');
        }
        else if (this.showAmsMasters) {
            this.changeFilterValue(this.filterPageRequest, 'shipmentContents.shipment.masterShipments.master.nonAMS', '0');
        }
        else {
            this.removeFilterValue(this.filterPageRequest, 'shipmentContents.shipment.masterShipments.master.nonAMS');
        }
        if (this.namedCondition) {
            filterPageRequest.addNamedCondition(this.namedCondition);
        }
        if (this.availableStatuses.length) {
            var statusColumn = this.filterPageRequest.filterByColumns.find(function (column) { return column.field === 'status'; });
            var statusesIds = this.selectedStatuses.map(function (selectedStatus) { return '' + selectedStatus.id; });
            if (!statusColumn) {
                this.filterPageRequest.filterByColumns.push(new FilterSearchColumn('status', null, statusesIds, null));
            }
            else {
                statusColumn.multipleValues = statusesIds;
            }
        }
    };
    OrdersTableComponent.prototype.onRestoreSelected = function () {
    };
    OrdersTableComponent.prototype.updateTotalValues = function (items) {
        var totalPcs = 0;
        var totalHu = 0;
        var totalWeight = 0;
        for (var i = 0; i < items.length; i++) {
            if (NumberUtils.isNumber(items[i].pieces)) {
                totalPcs += items[i].pieces;
            }
            if (NumberUtils.isNumber(items[i].hu_)) {
                totalHu += items[i].hu_;
            }
            if (NumberUtils.isNumber(items[i].weight)) {
                totalWeight += items[i].weight;
            }
        }
        this.totalPcs = totalPcs;
        this.totalHu = totalHu;
        this.totalWeight = totalWeight;
    };
    OrdersTableComponent.prototype.resetRequest = function () {
        this.filterPageRequest = new FilterPageRequest(1, 100, null, new SearchSortRequest('id', false), []);
    };
    OrdersTableComponent.prototype.getDefaultSortableColumn = function () {
        return (this.filterPageRequest ? String(this.filterPageRequest.sort.field) : null) || "id";
    };
    return OrdersTableComponent;
}(AbstractMasterTable));
export { OrdersTableComponent };
