var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isNullOrUndefined } from 'util';
import { WEIGHT_DECIMAL_DIGITS } from "./oms-constants.service";
var MeasureUnit = /** @class */ (function () {
    function MeasureUnit(id, label, coeff, digits, description) {
        if (description === void 0) { description = ''; }
        this.id = id;
        this.label = label;
        this.coeff = coeff;
        this.digits = digits;
        this.description = description;
    }
    return MeasureUnit;
}());
export { MeasureUnit };
var MeasureUnits = /** @class */ (function () {
    function MeasureUnits(units) {
        this.units = units;
    }
    MeasureUnits.toDefault = function (value, unit) {
        return isNullOrUndefined(value) ? value : value / unit.coeff;
    };
    MeasureUnits.fromDefault = function (value, unit) {
        return isNullOrUndefined(value) ? value : value * unit.coeff;
    };
    MeasureUnits.prototype.getAll = function () {
        return this.units;
    };
    MeasureUnits.prototype.getAllLabels = function () {
        return this.units.map(function (u) { return u.label; });
    };
    MeasureUnits.prototype.get = function (id) {
        for (var _i = 0, _a = this.units; _i < _a.length; _i++) {
            var unit = _a[_i];
            if (unit.id === id) {
                return unit;
            }
        }
        return null;
    };
    return MeasureUnits;
}());
export { MeasureUnits };
var WeightUnits = /** @class */ (function (_super) {
    __extends(WeightUnits, _super);
    function WeightUnits() {
        return _super.call(this, [WeightUnits.WEIGHT_KG, WeightUnits.WEIGHT_LBS]) || this;
    }
    Object.defineProperty(WeightUnits.prototype, "default", {
        get: function () {
            return WeightUnits.WEIGHT_KG;
        },
        enumerable: true,
        configurable: true
    });
    WeightUnits.WEIGHT_KG = new MeasureUnit(0, 'KG', 1, WEIGHT_DECIMAL_DIGITS);
    WeightUnits.WEIGHT_LBS = new MeasureUnit(1, 'LBS', 2.20462, WEIGHT_DECIMAL_DIGITS);
    return WeightUnits;
}(MeasureUnits));
export { WeightUnits };
var VolumeUnits = /** @class */ (function (_super) {
    __extends(VolumeUnits, _super);
    function VolumeUnits() {
        return _super.call(this, [VolumeUnits.VOLUME_CBM, VolumeUnits.VOLUME_CFT]) || this;
    }
    Object.defineProperty(VolumeUnits.prototype, "default", {
        get: function () {
            return VolumeUnits.VOLUME_CFT;
        },
        enumerable: true,
        configurable: true
    });
    VolumeUnits.VOLUME_CBM = new MeasureUnit(0, 'CBM', 1, 1);
    VolumeUnits.VOLUME_CFT = new MeasureUnit(1, 'CFT', 35.315, 1);
    return VolumeUnits;
}(MeasureUnits));
export { VolumeUnits };
var CurrencyUnits = /** @class */ (function (_super) {
    __extends(CurrencyUnits, _super);
    function CurrencyUnits() {
        return _super.call(this, [CurrencyUnits.CURRENCY_USD]) || this;
    }
    Object.defineProperty(CurrencyUnits.prototype, "default", {
        get: function () {
            return CurrencyUnits.CURRENCY_USD;
        },
        enumerable: true,
        configurable: true
    });
    CurrencyUnits.CURRENCY_USD = new MeasureUnit(0, 'USD', 1, 1);
    return CurrencyUnits;
}(MeasureUnits));
export { CurrencyUnits };
var PackagingUnits = /** @class */ (function (_super) {
    __extends(PackagingUnits, _super);
    function PackagingUnits() {
        return _super.call(this, [
            new MeasureUnit(0, 'PCS', 1, 0, 'Pieces'),
            new MeasureUnit(1, 'CTNS', 1, 0, 'Cartons'),
            new MeasureUnit(2, 'PLTS', 1, 0, 'Pallets'),
            new MeasureUnit(3, 'DRM', 1, 0, 'Drums')
        ]) || this;
    }
    Object.defineProperty(PackagingUnits.prototype, "default", {
        get: function () {
            return this.get(0);
        },
        enumerable: true,
        configurable: true
    });
    return PackagingUnits;
}(MeasureUnits));
export { PackagingUnits };
var ContainerSizes = /** @class */ (function (_super) {
    __extends(ContainerSizes, _super);
    function ContainerSizes() {
        return _super.call(this, [
            new MeasureUnit(0, "20'", 1, 0, '20'),
            new MeasureUnit(1, "40'", 1, 0, '40'),
            new MeasureUnit(2, "45'", 1, 0, '45'),
        ]) || this;
    }
    Object.defineProperty(ContainerSizes.prototype, "default", {
        get: function () {
            return this.get(0);
        },
        enumerable: true,
        configurable: true
    });
    return ContainerSizes;
}(MeasureUnits));
export { ContainerSizes };
/** @deprecated  to search and destroy in code**/
var DocumentTypeUnits = /** @class */ (function (_super) {
    __extends(DocumentTypeUnits, _super);
    function DocumentTypeUnits() {
        return _super.call(this, [
            new MeasureUnit(0, 'MANIFEST', 1, 0, 'Manifest'),
            new MeasureUnit(1, 'IRREGULARITY', 1, 0, 'Irregularity Report'),
            new MeasureUnit(2, 'BOL', 1, 0, 'Bill Of Lading'),
            new MeasureUnit(3, 'MAWB', 1, 0, 'Master Airway Bill'),
            new MeasureUnit(4, 'POD', 1, 0, 'Proof Of Delivery'),
            new MeasureUnit(6, 'RECOVERY_ORDER', 1, 0, 'Recovery Order'),
            new MeasureUnit(7, 'CHECKED_MANIFEST', 1, 0, 'Checked Manifest'),
            new MeasureUnit(8, 'PTT', 1, 0, 'PTT'),
            new MeasureUnit(9, 'ISC', 1, 0, 'ISC'),
            new MeasureUnit(10, 'HAWB', 1, 0, 'HAWB'),
            new MeasureUnit(11, 'EMAIL', 1, 0, 'EMAIL'),
            new MeasureUnit(12, 'PMC_RECEIPTS', 1, 0, 'PMC_RECEIPTS'),
            new MeasureUnit(5, 'OTHER', 1, 0, 'Other')
        ]) || this;
    }
    Object.defineProperty(DocumentTypeUnits.prototype, "default", {
        get: function () {
            return this.get(0);
        },
        enumerable: true,
        configurable: true
    });
    return DocumentTypeUnits;
}(MeasureUnits));
export { DocumentTypeUnits };
var NoteType = /** @class */ (function (_super) {
    __extends(NoteType, _super);
    function NoteType() {
        return _super.call(this, [
            new MeasureUnit(0, 'DEFAULT', 1, 0, 'default'),
            new MeasureUnit(1, 'OVERAGE', 1, 0, 'overage'),
            new MeasureUnit(2, 'SHORTAGE', 1, 0, 'shortage'),
            new MeasureUnit(3, 'DAMAGE', 1, 0, 'damage'),
            new MeasureUnit(4, 'HAZARDOUS', 1, 0, 'hazardous'),
            new MeasureUnit(5, 'OSD', 1, 0, 'osd'),
        ]) || this;
    }
    Object.defineProperty(NoteType.prototype, "default", {
        get: function () {
            return this.get(0);
        },
        enumerable: true,
        configurable: true
    });
    return NoteType;
}(MeasureUnits));
export { NoteType };
