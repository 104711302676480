var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { RestService } from "./rest.service";
import { HttpUtilsService } from "./http-utils.service";
import { BaseEntityService } from './base/base-entity.service';
import { FreightForwarderGroup } from "../modules/shared/models/freight.forwarder.group";
var FreightForwarderGroupService = /** @class */ (function (_super) {
    __extends(FreightForwarderGroupService, _super);
    function FreightForwarderGroupService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/freight-forwarder-group/';
        _this.classType = FreightForwarderGroup;
        _this.freightForwarderGroupSubject = new Subject();
        _this.freightForwarderGroupPopUpEvent = new Subject();
        return _this;
    }
    FreightForwarderGroupService.prototype.showFreightForwarderGroupPopUp = function (item) {
        this.freightForwarderGroupPopUpEvent.next({
            show: true,
            freightForwarder: item
        });
    };
    FreightForwarderGroupService.prototype.hideFreightForwarderGroupPopUp = function () {
        this.freightForwarderGroupPopUpEvent.next({
            show: false,
            freightForwarder: null
        });
    };
    FreightForwarderGroupService.prototype.getAllFreightForwarderGroups = function () {
        var _this = this;
        this.findAll().then(function (items) { return _this.freightForwarderGroupSubject.next(items); });
    };
    return FreightForwarderGroupService;
}(BaseEntityService));
export { FreightForwarderGroupService };
