﻿<div class="wrapper full-page-wrapper page-auth page-login text-center">
  <div class="inner-page">

    <div class="login-box center-block">

      <alert [dismissible]="true" [type]="'danger'" *ngIf="!!warning" (onClosed)="warning = null">
        <p><strong>Warning!</strong></p>{{warning}}
      </alert>

      <form [formGroup]="loginForm" #f="ngForm" novalidate class="form-horizontal" >


        <p class="title">Use your username</p>
        <div class="form-group">
          <label for="username" class="control-label sr-only">Username</label>
          <div class="col-sm-12">
            <div class="input-group">
              <input type="text" formControlName="username" placeholder="username" id="username" #username class="form-control" required>
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
            </div>
  <!--
            <div *ngIf="f.submitted && !username.valid" class="help-block">an valid email is required</div>
  -->
          </div>
        </div>
        <label for="password" class="control-label sr-only">Password</label>
        <div class="form-group">
          <div class="col-sm-12">
            <div class="input-group">
              <input type="password" formControlName="password" #password placeholder="password" id="password" class="form-control">
              <span class="input-group-addon"><i class="fa fa-lock"></i></span>
            </div>
  <!--
            <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
  -->
          </div>
        </div>
        <label class="fancy-checkbox remember-me custom-bgcolor-green">
          <input name="remember-me" type="checkbox" formControlName="rememberMe">
          <span>Remember me next time</span>
        </label>
        <button class="btn btn-custom-primary btn-lg btn-block btn-auth" (click)="onSubmit()"><i class="fa fa-arrow-circle-o-right"></i> Login</button>



      </form>
      <div class="links">
        <p><a href="#">Forgot Username or Password?</a></p>
      </div>
    </div>
  </div>
</div>
