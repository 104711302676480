var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { ensureDate, getPendingStatus } from '../../../../../common/oms-date-time.utils';
import { Logger } from "../../../../../_helpers/logger";
export var OBJECT_ROW_ID = '__ROW_ID__';
export var PARENT_REF = '__PARENT__';
export var SELECTED_FLAG = '__SELECTED__';
export var EXPANDED_FLAG = '__EXPANDED__';
var TableFilterPipe = /** @class */ (function () {
    function TableFilterPipe() {
    }
    TableFilterPipe.prototype.transform = function (array, options, columns, tree) {
        var _this = this;
        //    array = this.filterItems(array, options, columns, tree);
        if (array) {
            array.forEach(function (item) { _this.initChildrenWithParent(item, tree); });
        }
        options.total = array ? array.length : 0;
        return array;
    };
    TableFilterPipe.prototype.initChildrenWithParent = function (value, tree) {
        var _this = this;
        if (tree) {
            var children = tree.children(value);
            if (children) {
                children.forEach(function (child) {
                    child[PARENT_REF] = value;
                    _this.initChildrenWithParent(child, tree);
                });
            }
        }
    };
    /*
      private isSearchByColumns(columns: BaseColumn[]) {
        let isByColumnSearch = false;
        columns.forEach((column) => {
          isByColumnSearch = isByColumnSearch || !(isNullOrUndefined(column.search.search) || column.search.search.toString().trim().length === 0);
        });
    
        return isByColumnSearch;
      }
     */
    /*private filterItems(items: Array<any>, options: FilterOptions, columns: BaseColumn[], tree: TreeModel):Array<any> {
      return items ? items.filter(
        master => {
          let isByColumnSearch = this.isSearchByColumns(columns);
  
          if (!isByColumnSearch && (isNullOrUndefined(options.search) || options.search.trim().length == 0) && isNullOrUndefined(options.colorItem)) {
            return true;
          }
  
  
          let found = true;
          let colorFound = true;
          if (isByColumnSearch) {
            columns.forEach(column => {
              if (column.search.searchable && !isEmptyString(column.search.search)) {
                found = this.search(master, column.search.search, column, tree) && found;
              }
            });
          } else {
            found = isEmptyString(options.search);
            colorFound = isNullOrUndefined(options.colorItem);
            if (!colorFound) {
              let status = options.colorItem.status;
              colorFound = master.isMasterInPendingStatus(status);
            }
          }
  
          return found && colorFound;
        }) : items;
    }*/
    /*private search(object: any, search: string, column: BaseColumn, tree: TreeModel): boolean {
      if (tree && this.searchChildren(object, search, column, tree))
        return true;
  
      let value = column.getValue(object);
      return this[column.search.searchFunction](value, search);
    }
  
    private searchChildren(object: any, search:string, column: BaseColumn, tree: TreeModel):boolean {
      if (tree) {
        let children: any[] = tree.children(object);
        if (children && children.length > 0) {
          for (let child of children) {
            if (this.search(child, search, column, tree))
              return true;
          }
        }
      }
      return false;
    }*/
    TableFilterPipe.prototype.findByNameIndexOf = function (item, searchInput) {
        return isNullOrUndefined(item) ? false : this.findByIndexOf(item.name, searchInput);
    };
    TableFilterPipe.prototype.findByIndexOf = function (item, searchInput) {
        return !isNullOrUndefined(item) && item.indexOf && item.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
    };
    TableFilterPipe.prototype.findByProblemStatus = function (item, searchInput) {
        if (!isNullOrUndefined(item)) {
            if (searchInput === "1" && item.length === 0) {
                return true;
            }
            else if (searchInput === "0") {
                var notResolvedItems = item.filter(function (problem) {
                    return !problem.isResolved;
                });
                return notResolvedItems.length > 0;
            }
            else if (searchInput === "2" && item.length > 0) {
                var notResolvedItems = item.filter(function (problem) {
                    return !problem.isResolved;
                });
                return notResolvedItems.length === 0;
            }
        }
        return false;
    };
    TableFilterPipe.prototype.findCustomerByNameIndexOf = function (item, searchInput) {
        return !isNullOrUndefined(item) && this.findByIndexOf(item.name, searchInput);
    };
    TableFilterPipe.prototype.numberEquals = function (item, searchInput) {
        return !isNullOrUndefined(item) && item === searchInput;
    };
    TableFilterPipe.prototype.itemNumberEquals = function (item, searchInput) {
        return !isNullOrUndefined(item) && searchInput.indexOf(item) !== -1;
    };
    TableFilterPipe.prototype.isTheSameDay = function (item, searchInput) {
        if (!isNullOrUndefined(item)) {
            //      console.log('item', item, 'search', searchInput);
            return ensureDate(item).toDateString() === searchInput.toDateString();
        }
        return false;
    };
    TableFilterPipe.prototype.searchDayByColor = function (item, pendingStatus) {
        var itemStatus = getPendingStatus(item);
        return itemStatus === pendingStatus;
    };
    TableFilterPipe.prototype.isSelectedValue = function (item, searchInput) {
        if (searchInput === '0') {
            return !isNullOrUndefined(item);
        }
        else if (searchInput === '1') {
            return isNullOrUndefined(item);
        }
        return true;
    };
    TableFilterPipe.prototype.findByFirst3OrLast4Chars = function (item, searchInput) {
        if (!isNullOrUndefined(item) && item.length > 10) {
            var itemText = item.toLowerCase();
            return itemText.substring(0, 3).startsWith(searchInput) || itemText.substring(item.length - 4, item.length).startsWith(searchInput);
        }
        return false;
    };
    TableFilterPipe = __decorate([
        Logger({ name: 'TableFilterPipe' })
    ], TableFilterPipe);
    return TableFilterPipe;
}());
export { TableFilterPipe };
var TableSortPipe = /** @class */ (function () {
    function TableSortPipe() {
    }
    TableSortPipe.prototype.transform = function (array, column, reverse, count, selectionOrder) {
        //    selectionOrder = selectionOrder || reverse? 'asc' : 'desc';
        var _this = this;
        //    console.log('sort by',column, 'selectionOrder:', selectionOrder, 'reverse:', reverse);
        if (array) {
            array.sort(function (a, b) {
                if (selectionOrder && selectionOrder.length > 0) {
                    var order = _this.sortValues(!!(a && a[SELECTED_FLAG]), !!(b && b[SELECTED_FLAG]), selectionOrder === 'asc');
                    if (order !== 0) {
                        return order;
                    }
                }
                if (!column) {
                    return 0;
                }
                //        console.log('compare', a, 'vs', b);
                var v1 = column ? column.getValue(a) : a; // this.getFieldValue(a, column.field, column);
                var v2 = column ? column.getValue(b) : b; // this.getFieldValue(b, column.field, column);
                //        if (isNullOrUndefined(v1)) { return isNullOrUndefined(v2) ? -1 : 1; }
                //        if (isNullOrUndefined(v2)) { return 1; }
                return column.sortField ?
                    _this.sortValues(v1[column.sortField], v2[column.sortField], reverse) :
                    _this.sortValues(v1, v2, reverse);
            });
        }
        if (count) {
            count(array ? array.length : 0);
        }
        return array;
    };
    TableSortPipe.prototype.sortValues = function (v1, v2, reverse) {
        if (isNullOrUndefined(v1)) {
            return isNullOrUndefined(v2) ? 0 : reverse ? 1 : -1;
        }
        if (isNullOrUndefined(v2)) {
            return reverse ? -1 : 1;
        }
        if (typeof v1 === 'string' && typeof v2 === 'string') {
            return v1.localeCompare(v2, 'en', { 'sensitivity': 'base' }) * (reverse ? -1 : 1);
        }
        if (v1 < v2) {
            return reverse ? 1 : -1;
        }
        if (v1 > v2) {
            return reverse ? -1 : 1;
        }
        return 0;
    };
    TableSortPipe = __decorate([
        Logger({ name: 'TableSortPipe' })
    ], TableSortPipe);
    return TableSortPipe;
}());
export { TableSortPipe };
var TableSortPipeChildren = /** @class */ (function (_super) {
    __extends(TableSortPipeChildren, _super);
    function TableSortPipeChildren() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableSortPipeChildren.prototype.transform = function (array, column, reverse, count) {
        /* todo Make controlled sorting of different type of Children inside Parent not mixing
        Currently sorting of child item is disabled.*/
        return array; // super.transform(array, column, reverse, count);
    };
    return TableSortPipeChildren;
}(TableSortPipe));
export { TableSortPipeChildren };
var TablePagingPipe = /** @class */ (function () {
    function TablePagingPipe() {
    }
    TablePagingPipe.prototype.transform = function (array, options) {
        if (array) {
            //      array.forEach((item)=>{this.initChildrenWithParent(item)});
            if (options.enabled) {
                if (isNullOrUndefined(options.currentPage)) {
                    options.currentPage = 1;
                }
                var startIndex = (options.currentPage - 1) * options.pageSize;
                var endIndex = Math.min(startIndex + options.pageSize - 1, array.length - 1);
                array = array.slice(startIndex, endIndex + 1);
            }
        }
        return array;
    };
    return TablePagingPipe;
}());
export { TablePagingPipe };
