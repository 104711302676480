var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { RestService } from "../../../../services/rest.service";
import { HttpUtilsService } from "../../../../services/http-utils.service";
import { plainToClass } from "class-transformer";
import { BaseEntityService } from "../../../../services/base/base-entity.service";
import { MasterFsr } from "../../models/master.fsr";
var MasterFsrService = /** @class */ (function (_super) {
    __extends(MasterFsrService, _super);
    function MasterFsrService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/master-fsr/';
        _this.classType = MasterFsr;
        _this.masterFsrSubject = new Subject();
        return _this;
    }
    MasterFsrService.prototype.findAllByMasterId = function (id) {
        var _this = this;
        this.httpRestService.get(this.apiUrl + 'find-by-id', id).subscribe(function (masterFsrData) {
            _this.masterFsrSubject.next(plainToClass(MasterFsr, masterFsrData));
        });
    };
    return MasterFsrService;
}(BaseEntityService));
export { MasterFsrService };
