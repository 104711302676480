﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget">
    <mat-spinner class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-history"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <div class="widget-content table-responsive">
      <div class="form-row" style="overflow-y: auto; max-height: 500px">
        <div *ngFor="let objectLogChange of objectLogChanges" style="border: 1px solid gray;  margin-bottom: 20px;">
          <table class="table table-hover table condensed">
            <thead>
            <tr>
              <th><a>Problem</a></th>
              <th><a>Initial Value</a></th>
              <th><a>Created At</a></th>
              <th><a>Created By</a></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{objectLogChange.object.description}}</td>
              <td>{{getInitialValue(objectLogChange.object.dateResolved, objectLogChange.logChanges)}}</td>
              <td>{{objectLogChange.object.createdAt | oms_date_time}}</td>
              <td>{{objectLogChange.object.systemUserName}}</td>
            </tr>
            </tbody>
          </table>
          <table class="table table-hover table condensed">
            <thead>
            <tr>
              <th><a>Old Value</a></th>
              <th><a>New Value</a></th>
              <th><a>Changes Date</a></th>
              <th><a>Changed By</a></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let log of objectLogChange.logChanges">
              <td>{{resolvedText(log.oldObjectValue)}}</td>
              <td>{{resolvedText(log.newObjectValue)}}</td>
              <td>{{log.createdAt | oms_date_time}}</td>
              <td>{{log.systemUserName}}</td>
            </tr>
            </tbody>
          </table>
          </div>
        </div>
    </div>

    <div class="widget-footer right">
      <button type="button" class="btn btn-default" (click)="closeDialog()">Ok</button>
    </div>

  </div>
</perfect-scrollbar>
