import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {Driver} from "../modules/shared/models";

@Injectable({providedIn: 'root'})
export class DriverNewService extends BaseEntityService<Driver> {
  apiUrl = '/oms/driver/';
  classType = Driver;

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }
}
