import {Directive} from '@angular/core';
import {NG_VALIDATORS, AbstractControl, Validator, ValidationErrors} from '@angular/forms';
import {validMAWB} from '../../modules/shared/services/oms-converters.service';

@Directive({
  selector: '[mawb-number][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MawbNumberValidator, multi: true }]
})
export class MawbNumberValidator implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {  // the [required] validator will check presence, not us
      return null;
    }

    const value = control.value.replace(/[-_ ]/g, '');
    let error;
    return validMAWB(value, (msg) => {error = {mawb: msg}; }) ? null : error;


/*    // Example of how to do asynchronous validation
    return Observable.create(obs => {
      setImmediate(() => {
        obs.next({hexadecimal: 'Please enter a hexadecimal value (alphanumeric, 0-9 and A-F)'});
        obs.complete();
      });
    }); */
  }
}
