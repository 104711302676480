<oms-base-dialog
        [loading]="loading"
        [show-footer]="true" [show-label]="false"
        [width]="'auto'" [max-width]="'95vw'"
        [height]="'85vh'" [btnOk]="undefined" [btnCancel]="undefined"
        [store-position]="false" [draggable]="false" [sizable]="false">

  <div widget-header class="page-label">
    <h3><i class="fa fa-file-pdf"></i>{{current?.documentType.description}}</h3>
    <em>{{current?.uploadedFiles.name}}</em>
  </div>


  <div id="outerContainer">
    <div class="pdf-container">
      <pdf-viewer
        [src]="source | async"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="true"
        [zoom]="1"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true">
      </pdf-viewer>
    </div>
  </div>

  <ng-container widget-footer>
    <button class="btn btn-primary" (click)="download()"><i class="fa fa-file-download"></i>Download</button>
  </ng-container>

  <ng-container widget-footer-buttons>
    <button class="btn btn-primary" (click)="previous()"><i class="fa fa-arrow-left" *ngIf="multipleDocuments"></i></button>
    <button class="btn btn-primary" (click)="next()"><i class="fa fa-arrow-right" *ngIf="multipleDocuments"></i></button>
  </ng-container>

</oms-base-dialog>
