var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { LoadTypes, MasterStatus, Order } from '../../../modules/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, OrdersService } from '../../../services';
import { Location } from '@angular/common';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { MasterService } from '../../../services/master/master.service';
import { OrderMode, OrderModes } from '../../../modules/shared/models/order/order-mode';
import { DispatchService } from '../../../modules/shared/services/dispatch/dispatch.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderDispatch } from '../../../modules/shared/models/dispatch/order-dispatch';
import { DateTimeColumn, FieldType, TextColumn } from '../../../modules/shared/components/base/data-table/columns/column-types';
import { ColumnType, downloadManifest } from '../../../common/column-type';
import { convertManifestNumber } from '../../../modules/shared/services/oms-converters.service';
import { FileUploadService } from "../../../services/file.upload.service";
import { ColumnWidth } from "../../../common/column-width";
import { DialogType, ModalResult, OmsDialogsService } from "../../../components/common/oms-dialogs";
import { MatDialog } from '@angular/material';
import { takeUntil } from "rxjs/operators";
import { AbstractComponent } from "../../../common/component/abstract.component";
import { Manifest } from "../../../modules/shared/models/manifest/manifest";
import { DispatchUtils } from "../../../_helpers/dispatch-utils";
var OrderEditorComponent = /** @class */ (function (_super) {
    __extends(OrderEditorComponent, _super);
    function OrderEditorComponent(cdr, spinner, alerts, location, route, router, dispatchService, ordersService, authService, masterService, dialogs, file, dispatchUtils, dialog) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this.spinner = spinner;
        _this.alerts = alerts;
        _this.location = location;
        _this.route = route;
        _this.router = router;
        _this.dispatchService = dispatchService;
        _this.ordersService = ordersService;
        _this.authService = authService;
        _this.masterService = masterService;
        _this.dialogs = dialogs;
        _this.file = file;
        _this.dispatchUtils = dispatchUtils;
        _this.dialog = dialog;
        //  public recoveringMasterId: number;
        _this.sub = [];
        _this.masterNotEditable = false;
        _this.loadColumns = [];
        _this.isDisabled = false;
        _this.isReadMode = false;
        _this.widthFirstPanel = 100;
        _this.widthSecondPanel = 0;
        _this.prevWidthFirstPanel = 35;
        _this.prevWidthSecondPanel = 65;
        _this.manifestForRightPanel = new Manifest();
        _this.orderDispatchLoads = [];
        _this.authService.isReadMode()
            .pipe(takeUntil(_this.unsubscribe$))
            .subscribe(function (isReadMode) { return _this.isReadMode = isReadMode; });
        return _this;
    }
    OrderEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        var columns = this.createLoadColumns();
        if (this.isReadMode) {
            columns = columns.map(function (c) { return c.clone().setEditor(false); });
            columns[0].setButton(null);
        }
        this.loadColumns = columns;
        this.sub.push(this.route.queryParams.subscribe(function (params) {
            var id = ~~params['id'];
            var newType = params['new'];
            if (!!newType && id) {
                _this.masterNotEditable = true;
                _this.getMasterAndCreateNewOrder(id);
            }
            else if (id) {
                _this.loadOrder(id);
                _this.loadOrderDispatches(id);
            }
            else {
                _this.newOrder(newType);
            }
        }));
        /*    this.snapshotParams = this.route.snapshot.params;
            this.sub.push(this.route.parent.params.subscribe(
              params => {
        
                let id = ~~this.snapshotParams['id'];
                let masterId = params['id'];
                let newType = this.snapshotParams['new'];
                this.type = newType;
                if (masterId && newType) {
                  this.masterNotEditable = true;
                  this.getMasterAndCreateNewOrder(masterId);
                } else if (id) {
                  this.loadOrder(id);
                  this.loadOrderDispatches(id);
                } else {
                  this.newOrder(this.snapshotParams['new']);
                }
              })
            );
         */
    };
    OrderEditorComponent.prototype.createLoadColumns = function () {
        var _this = this;
        return [
            ColumnType.DISPATCHED_MANIFEST_ID.clone()
                .setButton({
                icon: 'fa fa-download',
                tooltip: 'Download Manifest',
                onClick: function (row) { return downloadManifest(_this.file, row); },
                newPage: true
            })
                .setSearch(false)
                .setHandlers({
                converter: function (row, id) { return convertManifestNumber(id); },
                onClick: function (row) { return _this.openManifest(row.dispatchId); }
            })
                .setWidth('150px'),
            ColumnType.DISPATCHED_ADDRESS_PICKUP_NAME.clone()
                .setSearch(false)
                .setWidth('150px'),
            ColumnType.DISPATCHED_ADDRESS_DELIVERY_NAME.clone()
                .setSearch(false)
                .setWidth('150px'),
            new DateTimeColumn('dateOnSitePickup', 'On Site Pickup', 'dateOnSitePickup', ColumnWidth.DATE_TIME)
                .setEditor(true)
                .setSearch(false)
                .setWidth('150px'),
            new DateTimeColumn('datePickup', 'Pick Up', {
                actual: 'datePickupAct',
                estimated: 'datePickupEst'
            }, ColumnWidth.DATE_TIME)
                .setEditor(true)
                .setSearch(false)
                .setWidth('150px'),
            new DateTimeColumn('dateOnSiteDelivery', 'On Site Delivery', 'dateOnSiteDelivery', ColumnWidth.DATE_TIME)
                .setEditor(true)
                .setSearch(false)
                .setWidth('150px'),
            new DateTimeColumn('dateDelivery', 'Delivery', {
                actual: 'dateDeliveryAct',
                estimated: 'dateDeliveryEst'
            }, ColumnWidth.DATE_TIME)
                .setEditor(true)
                .setSearch(false)
                .setWidth('150px'),
            new TextColumn('driver', 'Driver', 'driver.fullName', FieldType.TEXT, '150px', {
                class: function () { return 'driver'; }
            }).setSearch(false),
            ColumnType.DISPATCHED_CREATED_BY.clone()
                .setSearch(false)
                .setWidth('150px'),
            ColumnType.DISPATCHED_DATE_CREATED.clone()
                .setSearch(false)
                .setWidth('150px'),
            new TextColumn('loadType', 'Load Type', 'loadType', FieldType.TEXT, '150px', {
                converter: function (row, value) { return LoadTypes.labelOf(value); }
            }).setSearch(false),
            new TextColumn('status', 'Load Status', function (od) { return od.getRouteStatus(); }, FieldType.TEXT, '150px', {}).setSearch(false),
        ];
    };
    OrderEditorComponent.prototype.loadOrderDispatches = function (orderId) {
        var _this = this;
        this.dispatchService.findOrderDispatches(orderId).then(function (list) {
            _this.loads = list;
            _this.cdr.markForCheck();
        });
        /*    forkJoin([
              this.dispatchService.findDispatchedLoadsObs(this.createLoadRequest(orderId, ViewMode.ACTIVE)),
              this.dispatchService.findDispatchedLoadsObs(this.createLoadRequest(orderId, ViewMode.COMPLETED)),
              this.dispatchService.findOrderDispatches(orderId)
            ])
              .subscribe(responses => {
                this.loads = responses[0].content.concat(responses[1].content).concat(responses[2].content);
                this.cdr.markForCheck();
              }); */
    };
    /*
      private createLoadRequest(orderId: number, viewMode: ViewMode) {
        let filterByColumn = new FilterSearchColumn('orderId', '' + orderId);
        let request = new FilterPageRequest(1, 100, '',
          new SearchSortRequest('orderId', true), [filterByColumn]);
        request.addNamedCondition(viewMode);
        return request;
      }
    
     */
    OrderEditorComponent.prototype.getMasterAndCreateNewOrder = function (id) {
        var _this = this;
        this.masterService.getMaster(id).then(function (master) {
            _this.order = Order.createOrderByMaster(master);
        }, function (error) {
            _this.alerts.danger(error);
        });
    };
    OrderEditorComponent.prototype.loadOrder = function (id) {
        var _this = this;
        this.ordersService.getOrder(id)
            .then(function (order) {
            console.log("Loaded Order", order);
            _this.order = order;
            if (order && order.isRecovery) {
                _this.ordersService.getMasterInfoByRecoveryOrder(id).subscribe(function (info) {
                    _this.order.data.recoveringMasterId = info && info.id;
                });
            }
            _this.cdr.detectChanges();
        })
            .catch(function (error) {
            if (error.status === 403) {
                _this.router.navigate(['/404'], { replaceUrl: true });
            }
            else {
                _this.alerts.danger(error);
            }
        });
    };
    OrderEditorComponent.prototype.newOrder = function (type) {
        switch (type) {
            case 'generic':
                this.order = Order.newGenericOrder();
                break;
            case 'AIR_EXPORT':
                this.order = Order.newGenericOrder(OrderMode.AEDIR);
                break;
            case 'FCL':
                this.order = Order.newGenericOrder(OrderMode.FCLEX);
                break;
            default:
                this.order = Order.newOrder(type);
        }
    };
    Object.defineProperty(OrderEditorComponent.prototype, "title", {
        get: function () {
            return !this.order ? null : (this.order.isNew() ? 'New Order' : this.order.orderNumber);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderEditorComponent.prototype, "orderStatus", {
        get: function () {
            return status = this.order && MasterStatus.getLabel(this.order.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderEditorComponent.prototype, "orderMode", {
        get: function () {
            return (this.order && this.order.isGeneric) ? OrderModes.labelOf(this.order.genericMode) : 'Air Import';
        },
        enumerable: true,
        configurable: true
    });
    OrderEditorComponent.prototype.ngOnDestroy = function () {
        this.sub.forEach(function (s) { return s.unsubscribe(); });
    };
    OrderEditorComponent.prototype.onSave = function () {
        var _this = this;
        if (!this.order.pieces && this.order.hu) {
            this.order.pieces = this.order.hu;
        }
        if (!this.order.hu && this.order.pieces) {
            this.order.hu = this.order.pieces;
        }
        if (!this.order.pieces) {
            this.alerts.error('PCS should be more then 0', 'Error');
            return true;
        }
        if (typeof this.order.pieces === 'number' && typeof this.order.hu === 'number' && this.order.hu > this.order.pieces) {
            this.alerts.error('HU should be less then PCS', 'Error');
            return true;
        }
        this.dispatchService.validateOrderDispatch(this.order).subscribe(function (res) {
            if (res && res.warnings) {
                _this.displayWarnings(res.warnings)
                    .then(function (confirm) {
                    if (confirm) {
                        _this.saveOrder();
                    }
                });
            }
            else {
                _this.saveOrder();
            }
        });
    };
    OrderEditorComponent.prototype.displayWarnings = function (warnings) {
        var _this = this;
        if (!warnings.length) {
            return Promise.resolve(true);
        }
        var ws = warnings.slice();
        var message = ws.shift();
        return this.dialogs.confirm(DialogType.CONFIRMATION, 'Order save?', message).then(function (result) {
            switch (result.result) {
                case ModalResult.YES: {
                    return _this.displayWarnings(ws);
                }
            }
            return Promise.resolve(false);
        });
    };
    OrderEditorComponent.prototype.saveOrder = function () {
        var _this = this;
        if (this.order.isNew()) {
            this.spinner.show();
            this.ordersService.create(this.order).toPromise().then(function (order) {
                _this.spinner.hide();
                _this.onSuccess(order);
                _this.order = order;
                _this.loadOrderDispatches(order.id);
                _this.alerts.success('New order ' + order.orderNumber + ' has been created', 5000);
                _this.cdr.markForCheck();
            }, function (error) {
                _this.spinner.hide().then();
                _this.alerts.error(error);
            });
        }
        else {
            this.spinner.show();
            this.dispatchService.updateOrderDispatch(this.order).toPromise().then(function (order) {
                _this.spinner.hide();
                _this.onSuccess(order);
                _this.order = order;
                _this.loadOrderDispatches(order.id);
                _this.alerts.success('Order ' + order.orderNumber + ' has been updated', 5000);
                _this.cdr.markForCheck();
            }, function (error) {
                _this.spinner.hide();
                _this.alerts.error(error);
            });
        }
    };
    OrderEditorComponent.prototype.onSuccess = function (order) {
        if (this.masterNotEditable) {
            this.masterService.refreshDataRequired.next(order.shipmentContents[0].shipment.master);
        }
    };
    OrderEditorComponent.prototype.updateField = function (event) {
        //    let dispatchId = event.row.dispatchId;
        //    let dispatchShipmentId = event.row.dispatchShipmentId;
        this.isDisabled = true;
        this.cdr.markForCheck();
        var shipment = this.order.shipmentContents.map(function (sc) { return sc.shipment; }).find(function (s) { return s.id === event.row.shipmentId; });
        if (shipment) {
            switch (event.field) {
                case 'datePickupAct':
                    shipment.datePickUpActual = event.newValue;
                    break;
                case 'datePickupEst':
                    shipment.datePickUpExpectation = event.newValue;
                    break;
                case 'dateDeliveryAct':
                    shipment.dateDeliveryActual = event.newValue;
                    break;
                case 'dateDeliveryEst':
                    shipment.dateDeliveryExpectation = event.newValue;
                    break;
                case 'dateOnSitePickup':
                    shipment.dateOnSitePickup = event.newValue;
                    break;
                case 'dateOnSiteDelivery':
                    shipment.dateOnSiteDelivery = event.newValue;
                    break;
            }
        }
        this.isDisabled = false;
        this.cdr.markForCheck();
    };
    /*
      private findManifestItem(dispatch: Manifest, order: Order, orderDispatch: OrderDispatch): ManifestItem {
        return dispatch.items.find(i => {
          return i.hasOrder(order.id) && this.equals(orderDispatch.addressPickup, i.addressPickUp) && this.equals(orderDispatch.addressDelivery, i.addressDelivery);
        });
      }
    
     */
    /*
      private equals(a1: BaseEntity, a2: BaseEntity) {
        if (a1 == null && a2 == null) {
          return true;
        }
        if (a1 == null || a2 == null) {
          return false;
        }
        return a1.id === a2.id;
      }
    
     */
    OrderEditorComponent.prototype.getRowClass = function (row) {
        var result = 'row-dispatch';
        if (!(row instanceof OrderDispatch)) {
            return result;
        }
        if (row.dispatchCancelled) {
            result += ' dispatch-cancelled';
        }
        if (row.isDispatched) {
            result += row.isConfirmed ? ' confirmed' : ' not-confirmed';
        }
        if (row.isCompleted()) {
            result += ' success';
        }
        else if (row.isCompletedWithProblem()) {
            result += ' warning';
        }
        else if (row.isNotCompleted()) {
            result += ' danger';
        }
        return result;
    };
    OrderEditorComponent.prototype.changePrev = function (first, second) {
        if (first <= 90) {
            this.prevWidthFirstPanel = first;
            this.prevWidthSecondPanel = second;
        }
    };
    OrderEditorComponent.prototype.dragEnd = function (event) {
        this.widthFirstPanel = event.sizes[0];
        this.widthSecondPanel = event.sizes[1];
        this.changePrev(event.sizes[0], event.sizes[1]);
    };
    OrderEditorComponent.prototype.onManifestChange = function (manifest) {
        if (manifest != null && manifest.id > 0) {
            console.log('CHANGED', manifest);
            this.loadOrder(this.order.id);
            this.loadOrderDispatches(this.order.id);
            var items = this.orderDispatchLoads.filter(function (dispatchDto) { return manifest.items.some(function (manifestItem) { return manifestItem.hasOrder(dispatchDto.orderId); }); });
            this.setSelected(items);
            this.updateRightPanel();
        }
    };
    OrderEditorComponent.prototype.onDispatchListSelectionChange = function (list) {
        var _this = this;
        this.orderDispatchLoads = list;
        list.forEach(function (od) {
            var created = _this.manifestForRightPanel.items.find(function (mi) { return mi.orders.some(function (o) { return o.id === od.orderId; }); });
            if (!created) {
                //   this.setOrderDispatchValuesFromOrder(od);
                _this.dispatchUtils.manifestAddOrderDispatch(_this.manifestForRightPanel, od);
            }
        });
        // remove from new Items only
        var manifestItems = this.manifestForRightPanel.items.filter(function (i) { return i.isNew(); });
        manifestItems.forEach(function (mi) {
            mi.orders.forEach(function (o) {
                var selected = list.find(function (od) { return od.orderId === o.id; });
                if (!selected) {
                    _this.dispatchUtils.manifestDeleteOrder(_this.manifestForRightPanel, o);
                }
            });
        });
        this.setDispatchListToRightPanel(this.manifestForRightPanel.items.slice());
    };
    OrderEditorComponent.prototype.setDispatchListToRightPanel = function (items) {
        this.manifestForRightPanel.items = items;
        this.manifestForRightPanel = Object.assign(new Manifest(), this.manifestForRightPanel);
        this.updateRightPanel();
    };
    OrderEditorComponent.prototype.setSelected = function (items) {
        this.orderDispatchLoads = items;
    };
    OrderEditorComponent.prototype.updateRightPanel = function () {
        var list = this.manifestForRightPanel.items;
        if (!list.length && this.widthFirstPanel < 100) {
            this.changePrev(this.widthFirstPanel, this.widthSecondPanel);
            this.widthFirstPanel = 100;
            this.widthSecondPanel = 0;
        }
        else if (list.length && this.widthFirstPanel === 100) {
            this.widthFirstPanel = this.prevWidthFirstPanel;
            this.widthSecondPanel = this.prevWidthSecondPanel;
        }
    };
    OrderEditorComponent.prototype.onCreatedEvent = function (manifest) {
        this.manifestForRightPanel = new Manifest();
        this.setSelected([]);
        this.setDispatchListToRightPanel([]);
    };
    OrderEditorComponent.prototype.openManifest = function (dispatchId) {
        var _this = this;
        if (dispatchId) {
            this.spinner.show();
            console.log('openManifest');
            this.dispatchService.get(dispatchId)
                .subscribe(function (manifest) {
                _this.spinner.hide();
                console.log('manifest received', manifest);
                manifest.items.forEach(function (mi) {
                    mi.loadTypeOriginal = mi.loadType;
                    mi.orders.forEach(function (o) {
                        mi.loadTypeOriginal = mi.loadType;
                        o.info.legAddressDelivery = mi.addressDelivery;
                    });
                });
                _this.manifestForRightPanel = manifest;
                _this.updateRightPanel();
                _this.cdr.detectChanges();
            }, function (error) {
                _this.spinner.hide();
                _this.alerts.error(error, 'Error open Manifest');
            });
        }
    };
    OrderEditorComponent.prototype.onDispatch = function () {
        var _this = this;
        if (this.order && this.order.id) {
            //      this.spinner.show();
            this.dispatchService.findDispatchesForOrders([this.order.id])
                .then(function (list) {
                //        this.spinner.hide();
                _this.onDispatchListSelectionChange(list);
                if (_this.manifestForRightPanel) {
                    _this.manifestForRightPanel.carrier = _this.order.carrier;
                }
                _this.cdr.detectChanges();
            })
                .catch(function (error) {
                //        this.spinner.hide();
                _this.alerts.error(error);
            });
        }
    };
    return OrderEditorComponent;
}(AbstractComponent));
export { OrderEditorComponent };
