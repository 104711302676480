import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogContainer, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators/takeUntil';
import 'rxjs/add/observable/fromEvent';
import { ModalPositionCache } from './modal-position-cache';
import { take } from 'rxjs/operators/take';
var DialogDraggableTitleDirective = /** @class */ (function () {
    function DialogDraggableTitleDirective(matDialogRef, container, positionCache, cdr) {
        this.matDialogRef = matDialogRef;
        this.container = container;
        this.positionCache = positionCache;
        this.cdr = cdr;
        this.active = true;
        this.keepPosition = true;
    }
    DialogDraggableTitleDirective.clamp = function (value, max, min) {
        return Math.min(Math.max(min, value), max);
    };
    Object.defineProperty(DialogDraggableTitleDirective.prototype, "draggable_class", {
        get: function () {
            return this.active;
        },
        enumerable: true,
        configurable: true
    });
    DialogDraggableTitleDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.keepPosition) {
            var dialogType_1 = this.matDialogRef.componentInstance.constructor;
            var cachedValue = this.positionCache.get(dialogType_1);
            this.offset = cachedValue || this._getOffset();
            this._updatePosition(this.offset.y, this.offset.x);
            this.cdr.detectChanges();
            this.matDialogRef.beforeClose().pipe(take(1))
                .subscribe(function () { return _this.positionCache.set(dialogType_1, _this.offset); });
        }
        else {
            this.offset = this._getOffset();
        }
    };
    DialogDraggableTitleDirective.prototype.ngOnInit = function () {
    };
    DialogDraggableTitleDirective.prototype.onMouseDown = function (event) {
        var _this = this;
        if (this.active && event.button === 0) {
            this.mouseStart = { x: event.pageX, y: event.pageY };
            this.mouseDelta = { x: 0, y: 0 };
            var mouseup$ = Observable.fromEvent(document, 'mouseup');
            this._subscription = mouseup$.subscribe(function () { return _this.onMouseUp(); });
            var mousemove$ = Observable.fromEvent(document, 'mousemove')
                .pipe(takeUntil(mouseup$))
                .subscribe(function (e) { return _this.onMouseMove(e); });
            this._subscription.add(mousemove$);
        }
    };
    DialogDraggableTitleDirective.prototype.onMouseMove = function (event) {
        if (this.active && this._subscription) {
            this.mouseDelta = {
                x: (event.pageX - this.mouseStart.x),
                y: (event.pageY - this.mouseStart.y),
            };
            this.mouseStart = { x: event.pageX, y: event.pageY };
            this.offset.x += this.mouseDelta.x;
            this.offset.y += this.mouseDelta.y;
            this._updatePosition(this.offset.y, this.offset.x);
        }
    };
    DialogDraggableTitleDirective.prototype.onMouseUp = function () {
        if (this.active) {
            if (this._subscription) {
                this._subscription.unsubscribe();
                this._subscription = undefined;
            }
            /*      if (this.mouseDelta) {
                    this.offset.x += this.mouseDelta.x;
                    this.offset.y += this.mouseDelta.y;
                  } */
        }
    };
    DialogDraggableTitleDirective.prototype._updatePosition = function (top, left) {
        var box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
        this.matDialogRef.updatePosition({
            top: DialogDraggableTitleDirective.clamp(top, window.innerHeight - box.height, 40) + 'px',
            left: DialogDraggableTitleDirective.clamp(left, window.innerWidth - box.width, 0) + 'px',
        });
    };
    DialogDraggableTitleDirective.prototype._getOffset = function () {
        var box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
        return {
            x: box.left + pageXOffset,
            y: box.top + pageYOffset
        };
    };
    return DialogDraggableTitleDirective;
}());
export { DialogDraggableTitleDirective };
