/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./left-sidebar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../_helpers/if-role.directive";
import * as i7 from "../../../../../services/auth.service";
import * as i8 from "ng-click-outside/lib/click-outside.directive";
import * as i9 from "../../../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i10 from "ngx-perfect-scrollbar";
import * as i11 from "./left-sidebar.component";
import * as i12 from "../../../../../services/navigation.service";
import * as i13 from "../../../services/left.menu.config.service";
var styles_LeftSidebarComponent = [i0.styles];
var RenderType_LeftSidebarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LeftSidebarComponent, data: {} });
export { RenderType_LeftSidebarComponent as RenderType_LeftSidebarComponent };
function View_LeftSidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.item.svg, ""); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_LeftSidebarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.item.iconClass, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_LeftSidebarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [["class", "toggle-icon"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "fa fa-angle-down": 0, "fa fa-angle-left": 1 })], function (_ck, _v) { var currVal_0 = "toggle-icon"; var currVal_1 = _ck(_v, 2, 0, _v.parent.parent.context.open, !_v.parent.parent.context.open); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_LeftSidebarComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LeftSidebarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LeftSidebarComponent_8)), i1.ɵdid(2, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { item: 0, open: 1, level: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit, _co.isOpen(_v.context.$implicit), (_v.parent.parent.parent.context.level + 1)); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_LeftSidebarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "sub-menu"], ["role", "menu"]], [[8, "id", 0], [1, "aria-expanded", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_7)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.item.children; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.item.dataTarget, ""); var currVal_1 = _v.parent.parent.context.open; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LeftSidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [["class", "dropdown"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "active": 0, "open": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "a", [["class", "js-sub-menu-toggle"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleOpen($event, _v.parent.context.item) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "text"], ["style", "opacity: 1;"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_5)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_6)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown"; var currVal_1 = _ck(_v, 2, 0, _co.isActive(_v.parent.context.item), _v.parent.context.open); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.item.href; _ck(_v, 4, 0, currVal_4); var currVal_5 = _v.parent.context.item.svg; _ck(_v, 6, 0, currVal_5); var currVal_6 = _v.parent.context.item.iconClass; _ck(_v, 8, 0, currVal_6); var currVal_8 = (_v.parent.context.item.children && _v.parent.context.item.children.length); _ck(_v, 12, 0, currVal_8); var currVal_9 = (_v.parent.context.item.children && _v.parent.context.item.children.length); _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 4).target; var currVal_3 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_7 = _v.parent.context.item.label; _ck(_v, 10, 0, currVal_7); }); }
function View_LeftSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_2)), i1.ɵdid(1, 212992, null, 0, i6.IfRoleDirective, [i1.TemplateRef, i1.ViewContainerRef, i7.AuthService], { role: [0, "role"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.item.role; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LeftSidebarComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "i", [["class", "sidebar-tool-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.toggleLeftSideBarFloating() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "fas fa-thumbtack"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "fa-rotate-90": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-thumbtack"; var currVal_1 = _ck(_v, 3, 0, _co.floating); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_LeftSidebarComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LeftSidebarComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LeftSidebarComponent_11)), i1.ɵdid(2, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { item: 0, open: 1, level: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit, _co.isOpen(_v.context.$implicit), 1); var currVal_1 = i1.ɵnov(_v.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LeftSidebarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { panel: 0 }), (_l()(), i1.ɵand(0, [["menuItem", 2]], null, 0, null, View_LeftSidebarComponent_1)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["panel", 1]], null, 15, "div", [["class", "left-sidebar"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "clickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.enter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.leave() !== false);
        ad = (pd_1 && ad);
    } if (("clickOutside" === en)) {
        var pd_2 = (_co.outside() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "sidebar-hide-left": 0, "sidebar-float": 1, "sidebar-float-active": 2, "maximized": 3, "minified": 4 }), i1.ɵdid(5, 737280, null, 0, i8.ClickOutsideDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "sidebar-toolbar sidebar-minified"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i", [["class", "sidebar-tool-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.toggleLeftSideBarMinified() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "fas fa-arrows-alt-h"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_9)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 6, "perfect-scrollbar", [["class", "sidebar-scroll"]], [[4, "overflow", null], [2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i9.View_PerfectScrollbarComponent_0, i9.RenderType_PerfectScrollbarComponent)), i1.ɵdid(12, 507904, null, 0, i10.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], { disabled: [0, "disabled"], config: [1, "config"] }, null), i1.ɵpod(13, { suppressScrollX: 0 }), (_l()(), i1.ɵeld(14, 0, null, 0, 3, "nav", [["class", "main-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "ul", [["class", "main-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_10)), i1.ɵdid(17, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "left-sidebar"; var currVal_1 = _ck(_v, 4, 0, !_co.visibility, _co.floating, (_co.floating && _co.visibility), !_co.minified, _co.minified); _ck(_v, 3, 0, currVal_0, currVal_1); _ck(_v, 5, 0); var currVal_2 = _co.navigationService.wideScreen; _ck(_v, 10, 0, currVal_2); var currVal_6 = _co.minified; var currVal_7 = _ck(_v, 13, 0, false); _ck(_v, 12, 0, currVal_6, currVal_7); var currVal_8 = _co.menuItems; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.minified ? "visible" : "inherit"); var currVal_4 = i1.ɵnov(_v, 12).autoPropagation; var currVal_5 = i1.ɵnov(_v, 12).scrollIndicators; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_LeftSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "left-sidebar", [], null, null, null, View_LeftSidebarComponent_0, RenderType_LeftSidebarComponent)), i1.ɵdid(1, 376832, null, 0, i11.LeftSidebarComponent, [i1.ChangeDetectorRef, i12.NavigationService, i5.Router, i13.LeftMenuConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftSidebarComponentNgFactory = i1.ɵccf("left-sidebar", i11.LeftSidebarComponent, View_LeftSidebarComponent_Host_0, { visibility: "visibility", minified: "minified", floating: "floating" }, { onItemOpen: "on-item-open" }, []);
export { LeftSidebarComponentNgFactory as LeftSidebarComponentNgFactory };
