import {BaseDeletable} from "../base-deletable";
import {Labeled} from "../../../../common/oms-types";
import {Type} from "class-transformer";
import {Customer} from "../customer";
import {FreightForwarder} from "../freight.forwarder";
import {FreightForwarderGroup} from "../freight.forwarder.group";
import {OrderModeItem} from "../order/order-mode-item";

export class UserVisibilityFilter extends BaseDeletable implements Labeled {
  public name: string;

  @Type(() => Customer)
  public customers: Customer[] = [];
  @Type(() => FreightForwarder)
  public freightForwarders: FreightForwarder[] = [];
  @Type(() => FreightForwarderGroup)
  public freightForwarderGroups: FreightForwarderGroup[] = [];
  modes: OrderModeItem[] = [];

  get label(): string {
    return name;
  }
}
