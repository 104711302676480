import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {AbstractComponent} from "../../../../../common/component/abstract.component";
import {NgSelectSearchParams} from "../../../../settings/util/ng-select-search-params";
import {OmsAlertsService} from "../../../../shared/components/oms-alerts/oms-alerts.service";
import {BaseEntity} from "../../../../shared/models/base-entity";

@Component({
  selector: "oms-select-entity-dialog",
  templateUrl: "select-entity-dialog.component.html",
  styleUrls: ["./select-entity-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SelectEntityDialogComponent<T extends BaseEntity = any> extends AbstractComponent implements OnInit {

  @Input() label: string = "";
  @Input() entity: T;
  @Input() selectSearch: NgSelectSearchParams<T>;

  constructor(public dialogRef: MatDialogRef<SelectEntityDialogComponent<T>>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public alerts: OmsAlertsService) {
    super();
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close(this.entity);
  }

}
