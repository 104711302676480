import {BaseDeletable} from './base-deletable';
import {Exclude, Type} from 'class-transformer';
import {Address, Country, User} from "./";
import {Labeled} from "../../../common/oms-types";
import {DriverLicense} from "./driver-license";
import {DriverInsurance} from "./driver-insurance";

export class Driver extends BaseDeletable implements Labeled {

  @Type(() => User)
  user: User;

  firstName: string;
  lastName: string;

  username: string;
  pin: number;
  employeeId: string;
  @Type(() => Date)
  hireDate: Date;
  @Type(() => Date)
  leaveDate: Date;

  @Type(() => Address)
  homeBase: Address;
  @Type(() => DriverLicense)
  license: DriverLicense;
  @Type(() => DriverInsurance)
  insurance: DriverInsurance;
  hosRuleType: string;

  @Exclude()
  get fullName(): string {
    return this.user ? this.user.fullName : (this.firstName + ' ' + this.lastName);
  }

  @Exclude()
  get label(): string {
    return this.fullName;
  }
}
