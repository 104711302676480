import { ElementRef, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { convertESTToLocal, convertLocalDateToEST, ensureDate, minDate, } from '../../../../../../common/oms-date-time.utils';
import { convertToStandardDateOnly, convertToStandardDateTime } from '../../../../services/oms-converters.service';
import { isNullOrUndefined } from "util";
import { UserPreferencesService } from "../../../../../../services/user-preferences.service";
var DateTimePopupComponent = /** @class */ (function () {
    function DateTimePopupComponent(userPreferences) {
        this.userPreferences = userPreferences;
        this.showHeader = true;
        this.timeFormat12 = true;
        this.valueChange = new EventEmitter();
        this.estimatedChange = new EventEmitter();
        this.appendTo = 'body';
        this.showPopup = false;
        this.showPopupChange = new EventEmitter();
        this.showDate = true;
        this.showTime = true;
        this.showWeeks = false;
        this.datepickerMode = 'day';
        this.initDate = null;
        this.minDate = null;
        this.maxDate = null;
        this.dateDisabled = [];
        this.estimation = false;
        this.activeTab = 'actual';
        this.isOpening = false;
        this.isDropUp = false;
        this.today = new Date();
        this.sub$ = [];
        this.tempLocalActual = null;
        this.tempLocalEstimated = null;
        this.actualDateInTheFuture = false;
        this.sub$.push(userPreferences.getTimeFormat12().subscribe(function (format) {
        }));
    }
    Object.defineProperty(DateTimePopupComponent.prototype, "position", {
        set: function (value) {
            this.left = value.left;
            this.top = value.top;
            this.bottom = value.bottom;
            this.right = value.right;
        },
        enumerable: true,
        configurable: true
    });
    DateTimePopupComponent.prototype.ngOnDestroy = function () {
        this.sub$.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(DateTimePopupComponent.prototype, "estValue", {
        get: function () {
            return this.estimation && this.activeTab === 'estimated' ? this.tempLocalEstimated : this.tempLocalActual;
        },
        set: function (value) {
            if (this.estimation && this.activeTab === 'estimated') {
                this.tempLocalEstimated = value;
            }
            else {
                this.tempLocalActual = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePopupComponent.prototype, "viewTimeValue", {
        get: function () {
            return convertToStandardDateTime(this.estValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePopupComponent.prototype, "viewDateValue", {
        get: function () {
            return convertToStandardDateOnly(this.estValue);
        },
        enumerable: true,
        configurable: true
    });
    DateTimePopupComponent.prototype.ngOnChanges = function (changes) {
        //    console.log('CHANGES', changes);
        var _this = this;
        if (!this.nowButton) {
            this.nowButton = { show: true, label: 'Now', cssClass: 'btn btn-secondary btn-sm' };
        }
        if (!this.clearButton) {
            this.clearButton = { show: true, label: 'Clear', cssClass: 'btn btn-secondary btn-sm' };
        }
        if (!this.closeButton) {
            this.closeButton = { show: true, label: 'Close', cssClass: 'btn btn-secondary btn-sm' };
        }
        // user maybe typing a value into an input box, so would come in as string
        this.tempLocalActual = /*this.localActual =*/ ensureDate(this.value) || this.localToEst(new Date()); // this.localToEst(new Date());
        this.tempLocalEstimated = /*this.localEstimated =*/ ensureDate(this.estimated) || this.localToEst(new Date());
        //    this.tempLocalActual = this.localActual;
        //    this.tempLocalEstimated = this.localEstimated;
        this.actualDateInTheFuture = false;
        // toggle if open
        if (changes.showPopup) {
            this.showPopup = changes.showPopup.currentValue === true;
            if (this.showPopup) {
                setTimeout(function () { return _this.focus(); }, 100);
            }
        }
    };
    DateTimePopupComponent.prototype.onOpenChange = function () {
        var _this = this;
        console.log(this.dropdown);
        this.isOpening = true;
        setTimeout(function () { return _this.isOpening = false; }, 250);
    };
    DateTimePopupComponent.prototype.onNow = function () {
        this.applyDate(this.localToEst(new Date()), true);
    };
    DateTimePopupComponent.prototype.localToEst = function (date) {
        var convertedDate = isNullOrUndefined(date) ? date :
            new Date(this.showTime ?
                convertToStandardDateTime(date) :
                convertToStandardDateOnly(date));
        //    console.log('CONVERTED', date, '->', convertedDate);
        return convertLocalDateToEST(convertedDate);
    };
    DateTimePopupComponent.prototype.onClear = function () {
        this.applyDate(null, true);
    };
    DateTimePopupComponent.prototype.isInFuture = function (date) {
        //    console.log('CHECKING DATE IN FUTURE', date, this.estValue);
        return isNullOrUndefined(date) ? false : date > new Date(); // !moment(this.maxDateInESTFunc).isSameOrAfter(date);
    };
    DateTimePopupComponent.prototype.onClose = function (apply) {
        if (this.showPopup) {
            if (apply) {
                if (this.activeTab === 'actual') {
                    if (this.estimation && this.isInFuture(this.tempLocalActual)) {
                        this.actualDateInTheFuture = true;
                        return;
                    }
                    //          console.log("ACT", this.tempLocalActual);
                    this.valueChange.emit(this.tempLocalActual);
                }
                if (this.activeTab === 'estimated') {
                    //          console.log("EST", this.tempLocalEstimated);
                    this.estimatedChange.emit(this.tempLocalEstimated);
                }
            }
            this.showPopup = false;
            this.showPopupChange.emit(false);
        }
    };
    DateTimePopupComponent.prototype.applyDate = function (date, forceClose) {
        //    console.log('APPLY---', date);
        this.actualDateInTheFuture = false;
        this.estValue = date;
        if (!this.isOpening) {
            if (this.showDate && !this.showTime || forceClose) {
                this.onClose(true);
            }
        }
    };
    DateTimePopupComponent.prototype.onTimePickerChange = function (date) {
        if (!this.isOpening) {
            var currentDate = this.estValue;
            if (isNullOrUndefined(currentDate)) {
                currentDate = date;
            }
            else {
                currentDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                currentDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
            }
            this.applyDate(convertLocalDateToEST(currentDate), false);
        }
    };
    DateTimePopupComponent.prototype.onDatePickerChange = function (date) {
        if (!this.isOpening) {
            var currentTime = this.estValue;
            if (isNullOrUndefined(currentTime)) {
                currentTime = date;
            }
            else {
                if (this.showTime) {
                    var t = convertESTToLocal(currentTime);
                    currentTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                    currentTime.setHours(t.getHours(), t.getMinutes(), t.getSeconds());
                }
                else {
                    currentTime = date;
                }
            }
            this.applyDate(convertLocalDateToEST(currentTime), !this.showTime);
        }
    };
    DateTimePopupComponent.prototype.focus = function () {
        if (this.btnClose) {
            this.btnClose.nativeElement.focus();
        }
    };
    Object.defineProperty(DateTimePopupComponent.prototype, "isEstimated", {
        get: function () {
            return this.activeTab === 'estimated';
        },
        set: function (value) {
            this.activeTab = value ? 'estimated' : 'actual';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePopupComponent.prototype, "isActual", {
        get: function () {
            return this.activeTab === 'actual';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePopupComponent.prototype, "getMaxDate", {
        get: function () {
            return this.estimation ? (this.isActual ? minDate(this.maxDate, this.today) : this.maxDate) : this.maxDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePopupComponent.prototype, "popupStyles", {
        get: function () {
            var styles = {};
            styles['top'] = isNullOrUndefined(this.top) ? 'auto' : this.top + 'px';
            styles['left'] = isNullOrUndefined(this.left) ? 'auto' : this.left + 'px';
            styles['right'] = isNullOrUndefined(this.right) ? 'auto' : this.right + 'px';
            styles['bottom'] = isNullOrUndefined(this.bottom) ? 'auto' : this.bottom + 'px';
            return styles;
        },
        enumerable: true,
        configurable: true
    });
    return DateTimePopupComponent;
}());
export { DateTimePopupComponent };
