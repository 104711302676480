var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isNullOrUndefined, isUndefined } from 'util';
import { convertDateTime, convertToLongDateTime, getPendingStatusClass, } from '../../../../services/oms-converters.service';
import { OmsConstants } from '../../../../../../common/oms-constants.service';
import { convertToEST, daysDiff, getPendingStatusEstimated, isBefore, maxETCDateFromNow, PendingStatus } from '../../../../../../common/oms-date-time.utils';
import { VolumeUnits, WeightUnits } from '../../../../../../common/oms-unit-types';
import { toFixed } from '../../../../../../_helpers/utils';
import * as moment from 'moment-timezone';
import { ColumnSettings, LoadType, Master, Order } from '../../../../models';
import { of, Subject } from 'rxjs';
import { Logger } from "../../../../../../_helpers/logger";
import { NgSelectSearchParams } from "../../../../../settings/util/ng-select-search-params";
import { PageResult } from "../../../../models/query-models/page-result";
// todo combine with CellDisplayType
export var FieldType;
(function (FieldType) {
    FieldType[FieldType["TEXT"] = 0] = "TEXT";
    FieldType[FieldType["DATE"] = 1] = "DATE";
    FieldType[FieldType["DATETIME"] = 2] = "DATETIME";
    FieldType[FieldType["ACTIONS"] = 3] = "ACTIONS";
    FieldType[FieldType["ADDRESS"] = 4] = "ADDRESS";
    FieldType[FieldType["BOOLEAN"] = 5] = "BOOLEAN";
    /** @Deprecated **/
    FieldType[FieldType["DIALOG"] = 6] = "DIALOG";
    FieldType[FieldType["FILL"] = 7] = "FILL";
})(FieldType || (FieldType = {}));
export var CellDisplayType;
(function (CellDisplayType) {
    CellDisplayType[CellDisplayType["DEFAULT"] = 0] = "DEFAULT";
    CellDisplayType[CellDisplayType["LINK"] = 1] = "LINK";
    //  NEW_WINDOW_LINK,
    //  TARGET,
    CellDisplayType[CellDisplayType["DIALOG"] = 2] = "DIALOG";
    CellDisplayType[CellDisplayType["ACTIONS"] = 3] = "ACTIONS";
    CellDisplayType[CellDisplayType["CHECKBOX"] = 4] = "CHECKBOX";
    CellDisplayType[CellDisplayType["TEXTBOX"] = 5] = "TEXTBOX";
    CellDisplayType[CellDisplayType["SELECT"] = 6] = "SELECT";
    //  BUTTON,
    CellDisplayType[CellDisplayType["FILL"] = 7] = "FILL";
    CellDisplayType[CellDisplayType["CUSTOM"] = 8] = "CUSTOM";
})(CellDisplayType || (CellDisplayType = {}));
var BaseColumn = /** @class */ (function (_super) {
    __extends(BaseColumn, _super);
    function BaseColumn(fieldName, label, field, fieldType, width, handlers, search) {
        var _this = _super.call(this, fieldName, label, field, width) || this;
        _this.handlers = { editor: { editable: false, cleanable: true, mask: { mask: false }, clearMask: false } };
        _this.searchChanged$ = new Subject();
        _this.fieldType = fieldType;
        _this.setSearch(search);
        _this.setHandlers(handlers);
        _this.onInit();
        return _this;
    }
    BaseColumn_1 = BaseColumn;
    BaseColumn.getColorItems = function () {
        var items = [];
        items.push({ status: PendingStatus.IMPENDING, class: 'date-impending-background' });
        /* items.push({status:PendingStatus.AT_RISK, class: "date-at-risk-background"});*/
        items.push({ status: PendingStatus.PAST_DUE, class: 'date-past-due-background' });
        return items;
    };
    BaseColumn.prototype.onInit = function () { };
    Object.defineProperty(BaseColumn.prototype, "isExternalEditor", {
        get: function () {
            return this.handlers.editor && this.handlers.editor.external;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseColumn.prototype, "isDialog", {
        get: function () {
            return this.fieldType === FieldType.DIALOG;
        },
        enumerable: true,
        configurable: true
    });
    BaseColumn.prototype.rowObject = function (row) {
        return (this.handlers.rowObject) ? this.handlers.rowObject(row) : row;
    };
    BaseColumn.prototype.setSortField = function (sortField) {
        _super.prototype.setSortField.call(this, sortField);
        return this;
    };
    BaseColumn.prototype.setAlign = function (align) {
        this.align = align;
        return this;
    };
    BaseColumn.prototype.setSearch = function (search) {
        if (!isNullOrUndefined(search)) {
            if (typeof search === 'string') {
                this.search = { searchable: true, searchType: search };
            }
            else if (typeof search === 'boolean') {
                this.search = { searchable: search, searchType: 'text' };
            }
            else {
                this.search = search;
                if (this.search && this.search.searchable && !this.search.searchType) {
                    this.search.searchType = 'text';
                }
                this.initHeadSearch();
            }
        }
        return this;
    };
    BaseColumn.prototype.setOnClick = function (handler) {
        this.handlers.onClick = handler;
        return this;
    };
    BaseColumn.prototype.setItems = function (items) {
        this.search.items = items;
        this.searchChanged$.next();
    };
    BaseColumn.prototype.setSelectedItems = function (items) {
        this.search.search = this.getIdsForSelected(items);
        this.search.selected = items;
        this.search['model'] = items;
        this.searchChanged$.next();
    };
    BaseColumn.prototype.getSelectedItems = function () {
        return this.search.selected || [];
    };
    BaseColumn.prototype.getIdsForSelected = function (items) {
        if (!items || !items.length) {
            return [];
        }
        if (!isNullOrUndefined(items[0].id)) {
            return items.map(function (item) { return item.id; });
        }
        return items;
    };
    BaseColumn.prototype.initHeadSearch = function () {
        var _this = this;
        if (this.search && this.search.searchable && this.search.displayFilter) {
            this.ngSelect = new NgSelectSearchParams(function (text) {
                if (typeof _this.search.items === "function") {
                    return _this.search.items(text);
                }
                if (Array.isArray(_this.search.items)) {
                    return of(PageResult.fromArray(_this.search.items));
                }
                return of(PageResult.fromArray([]));
            });
        }
        else {
            this.ngSelect = null;
        }
    };
    BaseColumn.prototype.doSearch = function (search) {
        console.log('DO_SEARCH', search);
        if (this.ngSelect && this.ngSelect.input$) {
            this.ngSelect.input$.next(search);
        }
    };
    Object.defineProperty(BaseColumn.prototype, "searchText", {
        get: function () {
            // @ts-ignore
            return this.parent && this.parent.searchText ||
                this.search && this.search.searchable && this.search.searchType === 'text' && this.search.search ||
                undefined;
        },
        enumerable: true,
        configurable: true
    });
    BaseColumn.prototype.setHistory = function (history) {
        if (!isNullOrUndefined(history)) {
            if (typeof history === 'boolean') {
                this.history = { logChanges: history };
            }
            else {
                this.history = history;
            }
        }
        return this;
    };
    // todo deep copy
    BaseColumn.prototype.setHandlers = function (handlers) {
        if (handlers) {
            if (handlers.rowObject) {
                this.rowObject = handlers.rowObject;
            }
            if (handlers.getter) {
                this.handlers.getter = handlers.getter;
            }
            if (handlers.converter) {
                this.handlers.converter = handlers.converter;
            }
            if (handlers.link) {
                this.handlers.link = handlers.link;
            }
            if (handlers.onClick) {
                this.handlers.onClick = handlers.onClick;
            }
            if (handlers.tooltip) {
                this.handlers.tooltip = handlers.tooltip;
            }
            if (handlers.info) {
                this.handlers.info = handlers.info;
            }
            if (handlers.icon) {
                this.handlers.icon = handlers.icon;
            }
            if (handlers.hidden) {
                this.handlers.hidden = handlers.hidden;
            }
            if (handlers.class) {
                this.handlers.class = handlers.class;
            }
            if (handlers.sorted) {
                this.handlers.sorted = handlers.sorted;
            }
            if (handlers.searchBy) {
                this.handlers.searchBy = handlers.searchBy;
            }
            if (handlers.params) {
                this.handlers.params = handlers.params;
            }
            if (handlers.header) {
                var header = this.handlers.header || {};
                if (!isNullOrUndefined(handlers.header.icon)) {
                    header.icon = handlers.header.icon;
                }
                if (!isNullOrUndefined(handlers.header.tooltip)) {
                    header.tooltip = handlers.header.tooltip;
                }
                if (!isNullOrUndefined(handlers.header.select)) {
                    header.select = handlers.header.select;
                }
                if (!isNullOrUndefined(handlers.header.selected)) {
                    header.selected = handlers.header.selected;
                }
                if (!isNullOrUndefined(handlers.header.selectLabel)) {
                    header.selectLabel = handlers.header.selectLabel;
                }
                if (!isNullOrUndefined(handlers.header.displaySelectedLabel)) {
                    header.displaySelectedLabel = handlers.header.displaySelectedLabel;
                }
                this.handlers.header = header;
            }
            this.setEditor(handlers.editor);
        }
        return this;
    };
    BaseColumn.prototype.setEditor = function (editor) {
        if (!isNullOrUndefined(editor)) {
            if (typeof editor === 'boolean') {
                this.handlers.editor.editable = editor;
            }
            else {
                if (!isNullOrUndefined(editor.editable)) {
                    this.handlers.editor.editable = editor.editable;
                }
                if (!isNullOrUndefined(editor.cleanable)) {
                    this.handlers.editor.cleanable = editor.cleanable;
                }
                if (editor.placeholder) {
                    this.handlers.editor.placeholder = editor.placeholder;
                }
                if (editor.mask) {
                    this.handlers.editor.mask = editor.mask;
                }
                if (!isNullOrUndefined(editor.clearMask)) {
                    this.handlers.editor.clearMask = editor.clearMask;
                }
                if (editor.select) {
                    this.handlers.editor.select = editor.select;
                }
                if (editor.showTracking) {
                    this.handlers.editor.showTracking = editor.showTracking;
                }
                if (editor.converter) {
                    this.handlers.editor.converter = editor.converter;
                }
                if (editor.postUpdate) {
                    this.handlers.editor.postUpdate = editor.postUpdate;
                }
                // if (editor.searchBy) this.handlers.editor.searchBy = editor.searchBy;
                if (editor.external) {
                    this.handlers.editor.external = editor.external;
                }
            }
        }
        return this;
    };
    BaseColumn.prototype.setButton = function (button) {
        this.handlers.button = button;
        return this;
    };
    BaseColumn.prototype.setHidden = function (hidden) {
        this.handlers.hidden = hidden;
        return this;
    };
    BaseColumn.prototype.setInfo = function (info) {
        this.handlers.info = info;
        return this;
    };
    BaseColumn.prototype.setHiddenColumn = function (hiddenColumn) {
        this.handlers.hiddenColumn = hiddenColumn;
        return this;
    };
    BaseColumn.prototype.setWidth = function (width) {
        this.width = width;
        return this;
    };
    BaseColumn.prototype.setMaxWidth = function (maxWidth) {
        this.maxWidth = maxWidth;
        return this;
    };
    Object.defineProperty(BaseColumn.prototype, "headerLabelOfSelected", {
        /*  public static getProblemsFiler(){
          return [{id:0, label: "Problems"}, {id:1, label: "No Problem"}, {id:2, label: "Resolved Problems"}];
        }*/
        get: function () {
            return this.isHeaderSelectable() && this.handlers.header.selectLabel && this.handlers.header.selected ?
                this.handlers.header.selected()[this.handlers.header.selectLabel] : null;
        },
        enumerable: true,
        configurable: true
    });
    BaseColumn.prototype.editable = function (row) {
        return !isNullOrUndefined(this.handlers.editor) && (typeof this.handlers.editor.editable === 'function' ? this.handlers.editor.editable(row) : this.handlers.editor.editable);
    };
    BaseColumn.prototype.showTracking = function (row) {
        return !isNullOrUndefined(this.handlers.editor) && (typeof this.handlers.editor.showTracking === 'function' ? this.handlers.editor.showTracking(row) : this.handlers.editor.showTracking);
    };
    BaseColumn.prototype.getDisplayType = function (row, value) {
        switch (this.fieldType) {
            case FieldType.BOOLEAN:
                return CellDisplayType.CHECKBOX;
            case FieldType.TEXT:
                return CellDisplayType.SELECT;
            case FieldType.FILL:
                return CellDisplayType.FILL;
            default:
                return CellDisplayType.DEFAULT;
        }
    };
    BaseColumn.prototype.isHeaderSelectable = function () {
        return this.handlers.header && !isNullOrUndefined(this.handlers.header.select);
    };
    BaseColumn.prototype.getFieldValue = function (object, path) {
        if (!object || !path || !path.length) {
            return object;
        }
        else {
            return this.getFieldValue(object[path.pop()], path);
        }
    };
    BaseColumn.prototype.setFieldValue = function (object, path, newValue) {
        if (object && path) {
            if (path.length > 1) {
                this.setFieldValue(object[path.pop()], path, newValue);
            }
            else {
                object[path.pop()] = newValue;
            }
        }
    };
    BaseColumn.prototype.getValue = function (object, field) {
        if (this.handlers.getter) {
            return this.handlers.getter(object);
        }
        if (isNullOrUndefined(field)) {
            field = this.field;
        }
        if (isNullOrUndefined(field)) {
            return null;
        }
        if (object && (typeof field === 'string')) {
            var path = field.split('.').reverse();
            return this.getFieldValue(object, path);
        }
        if (object && (typeof field === 'function')) {
            return field(object);
        }
    };
    Object.defineProperty(BaseColumn.prototype, "postUpdate", {
        get: function () {
            return this.handlers.editor && this.handlers.editor.postUpdate;
        },
        enumerable: true,
        configurable: true
    });
    BaseColumn.prototype.setValue = function (object, value, field) {
        if (isNullOrUndefined(field)) {
            field = this.field;
        }
        if (object && typeof field === 'string') {
            var path = field.split('.').reverse();
            this.setFieldValue(object, path, value);
        }
    };
    BaseColumn.prototype.getClass = function (row, value) {
        if (this.handlers && this.handlers.class) {
            return this.handlers.class(row, value);
        }
        return null;
    };
    BaseColumn.prototype.getIconClass = function (row, value) {
        return this.handlers.icon ? this.handlers.icon(row, value) : null;
    };
    BaseColumn.prototype.isHidden = function (row, value) {
        return typeof this.handlers.hidden === 'function' ? this.handlers.hidden(row, value) : this.handlers.hidden;
    };
    BaseColumn.prototype.isHiddenColumn = function () {
        return this.handlers.hiddenColumn;
    };
    BaseColumn.prototype.getHeaderIconClass = function () {
        if (this.handlers.header && this.handlers.header.icon) {
            return this.handlers.header.icon;
        }
    };
    Object.defineProperty(BaseColumn.prototype, "headerTooltip", {
        get: function () {
            return this.handlers.header ? this.handlers.header.tooltip : undefined;
        },
        enumerable: true,
        configurable: true
    });
    BaseColumn.prototype.getTooltip = function (object) {
        return this.handlers.tooltip ? this.handlers.tooltip(object) : null;
    };
    BaseColumn.prototype.getInfo = function (row, value) {
        return typeof this.handlers.info === "function" ? this.handlers.info(row, value) : this.handlers.info;
    };
    BaseColumn.prototype.convert = function (row, value) {
        if (this.handlers && this.handlers.converter) {
            return this.handlers.converter(row, value);
        }
        else {
            if (isNullOrUndefined(value)) {
                return 'N/A';
            }
            switch (this.fieldType) {
                case FieldType.ADDRESS:
                    return value.name;
                case FieldType.DATE:
                    return moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT);
                case FieldType.DATETIME:
                    return moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_FORMAT);
                default:
                    return value;
            }
        }
    };
    BaseColumn.prototype.convertBack = function (value) {
        return (this.handlers.editor && this.handlers.editor.converter) ?
            this.handlers.editor.converter(value) : value;
    };
    BaseColumn.prototype.clone = function () {
        var column = this;
        var clone;
        if (column instanceof TreeColumn) {
            clone = new TreeColumn(this.field, this.label, this.width);
        }
        else if (column instanceof HyperLinkColumn) {
            clone = new HyperLinkColumn(this.field, this.label, this.field, this.fieldType, this.width, this.handlers);
        }
        else if (column instanceof DateCheckboxColumn) {
            clone = new DateCheckboxColumn(this.field, this.label, this.field, this.width, this.handlers);
        }
        else if (column instanceof BaseColumn_1) {
            clone = new BaseColumn_1(this.field, this.label, this.field, this.fieldType, this.width, this.handlers);
        }
        else {
            console.error('Not implemented clone for {}.', column);
            return null;
        }
        clone = Object.assign(clone, column);
        if (clone.map) {
            clone.map = Object.assign(new Map(), clone.map || {});
        }
        if (clone.handlers) {
            clone.handlers = Object.assign({}, clone.handlers || {});
        }
        if (clone.handlers && clone.handlers.editor) {
            clone.handlers.editor = Object.assign({}, clone.handlers.editor || {});
        }
        if (clone.search && clone.search.displayFilter) {
            clone.initHeadSearch();
        }
        return clone;
    };
    var BaseColumn_1;
    BaseColumn.selectFilterItems = [{ id: 0, label: "Selected" }, { id: 1, label: "Not Selected" }];
    BaseColumn.ynFilterItems = [{ id: 0, label: "Yes" }, { id: 1, label: "No" }];
    BaseColumn.YES_NO_FILTERS = [{ id: 1, label: 'Yes' }, { id: 0, label: 'No' }];
    BaseColumn.POD_YES_NO_FILTERS = [{ id: 1, label: 'POD Yes' }, { id: 0, label: 'POD No' }];
    BaseColumn.POD_OR_Signed_PTT_YES_NO_FILTERS = [{ id: 'POD YES', label: 'POD Yes' }, { id: 'POD NO', label: 'POD No' }, { id: 'SIGNED_PTT YES', label: 'Signed_PTT Yes' }, { id: 'SIGNED_PTT NO', label: 'Signed_PTT No' }];
    BaseColumn.EMPTY_FILL_FILTERS = [{ id: 'N/A', label: 'Empty' }, { id: 'NOT_NULL', label: 'Not empty' }];
    BaseColumn.problemFilterItems = [{ id: 0, label: "Problems" }, { id: 1, label: "No Problem" }, { id: 2, label: "Resolved Problems" }];
    BaseColumn.loadTypeFilterItems = [{ id: 1, label: "Recovery" }, { id: 3, label: 'Pickup' }, { id: 5, label: "X-Dock" }, { id: 2, label: "Delivery" }, { id: 4, label: "Direct" }];
    BaseColumn = BaseColumn_1 = __decorate([
        Logger({
            name: 'BaseColumn'
        }),
        __metadata("design:paramtypes", [Object, String, Object, Object, Object, Object, Object])
    ], BaseColumn);
    return BaseColumn;
}(ColumnSettings));
export { BaseColumn };
var TextColumn = /** @class */ (function (_super) {
    __extends(TextColumn, _super);
    function TextColumn(id, label, field, fieldType, width, handlers, search, align) {
        var _this = _super.call(this, id, label, field, fieldType, width, handlers, search) || this;
        if (!isNullOrUndefined(align)) {
            _this.align = align;
        }
        return _this;
    }
    return TextColumn;
}(BaseColumn));
export { TextColumn };
var ActionsColumn = /** @class */ (function (_super) {
    __extends(ActionsColumn, _super);
    function ActionsColumn(id, label, width) {
        return _super.call(this, id, label, null, FieldType.ACTIONS, width, null, null) || this;
    }
    ActionsColumn.prototype.getDisplayType = function (row, value) { return CellDisplayType.ACTIONS; };
    return ActionsColumn;
}(BaseColumn));
export { ActionsColumn };
var HyperLinkColumn = /** @class */ (function (_super) {
    __extends(HyperLinkColumn, _super);
    function HyperLinkColumn(id, label, field, fieldType, width, handlers, search, align, link) {
        var _this = _super.call(this, id, label, field, fieldType, width, handlers, search, align) || this;
        _this.newWindow = true; // if newWindow === auto then detects it if Ctrl Key is in the Mouse Event
        if (link) {
            _this.handlers.link = link;
        }
        return _this;
    }
    HyperLinkColumn.prototype.getDisplayType = function (row, value) {
        return CellDisplayType.LINK;
    };
    HyperLinkColumn.prototype.setHyperLink = function (link) {
        this.handlers.link = link;
        return this;
    };
    HyperLinkColumn.prototype.getHyperLink = function (row, value, newWindow) {
        return this.handlers.link ? this.handlers.link(row, value, newWindow) : null;
    };
    HyperLinkColumn.prototype.setNewWindow = function (newWindow) {
        this.newWindow = newWindow;
        return this;
    };
    HyperLinkColumn.prototype.convertToBaseColumn = function () {
        var column = new BaseColumn(this.id, this.label, this.field, this.fieldType, this.width, this.handlers);
        return column;
    };
    return HyperLinkColumn;
}(TextColumn));
export { HyperLinkColumn };
/*
export class HyperLinkTargetColumn extends TextColumn {
  public getDisplayType(row?:any, value?:any): CellDisplayType {return CellDisplayType.TARGET}

  constructor(id: number, label: string, field: string, fieldType: FieldType, width, handlers?:ColumnHandlers, search?, align?, link?: (value: any)=> any) {
    super(id, label, field, fieldType, width, handlers, search, align);
    if (link)
      this.handlers.link = link;
  }

  public getHyperLink(row: any, value:any): any {
    return this.handlers.link ? this.handlers.link(row, value) : [];
  };
}
*/
var DialogLinkColumn = /** @class */ (function (_super) {
    __extends(DialogLinkColumn, _super);
    function DialogLinkColumn(id, label, field, fieldType, width, handlers, search, align, link) {
        return _super.call(this, id, label, field, fieldType, width, handlers, search, align, link) || this;
    }
    DialogLinkColumn.prototype.getDisplayType = function (row, value) { return CellDisplayType.DIALOG; };
    return DialogLinkColumn;
}(HyperLinkColumn));
export { DialogLinkColumn };
var CustomColumn = /** @class */ (function (_super) {
    __extends(CustomColumn, _super);
    function CustomColumn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomColumn.prototype.getDisplayType = function (row, value) {
        return CellDisplayType.CUSTOM;
    };
    CustomColumn.prototype.setComponent = function (component, data) {
        this.component = component;
        this.data = data;
        return this;
    };
    return CustomColumn;
}(BaseColumn));
export { CustomColumn };
var CheckboxColumn = /** @class */ (function (_super) {
    __extends(CheckboxColumn, _super);
    function CheckboxColumn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckboxColumn.prototype.onInit = function () {
        _super.prototype.onInit.call(this);
        this.align = 'center';
    };
    CheckboxColumn.prototype.getDisplayType = function (row, value) {
        return CellDisplayType.CHECKBOX;
    };
    return CheckboxColumn;
}(BaseColumn));
export { CheckboxColumn };
var DateCheckboxColumn = /** @class */ (function (_super) {
    __extends(DateCheckboxColumn, _super);
    function DateCheckboxColumn(id, label, field, width, handlers) {
        var _this = _super.call(this, id, label, field, FieldType.DATETIME, width, handlers) || this;
        if (isNullOrUndefined(_this.handlers.converter)) {
            _this.handlers.converter = function (row, value) { return !isNullOrUndefined(value); };
        }
        if (isNullOrUndefined(_this.handlers.tooltip)) {
            _this.handlers.tooltip = function (value) { return convertDateTime(_this.getValue(value)); };
        }
        return _this;
    }
    return DateCheckboxColumn;
}(CheckboxColumn));
export { DateCheckboxColumn };
var WeightColumn = /** @class */ (function (_super) {
    __extends(WeightColumn, _super);
    function WeightColumn(id, label, field, fieldType, width, handlers, search) {
        var _this = _super.call(this, id, label, field, fieldType, width, handlers, search) || this;
        _this.units = OmsConstants.DEFAULT_WEIGHT_UNITS;
        _this.label = '';
        _this.handlers.header = { select: [WeightUnits.WEIGHT_LBS, WeightUnits.WEIGHT_KG], selectLabel: 'label', selected: function () { return _this.units; }, apply: function (units) { return _this.units = units; }, displaySelectedLabel: true };
        return _this;
    }
    WeightColumn.prototype.onInit = function () {
        _super.prototype.onInit.call(this);
        this.align = 'end';
    };
    WeightColumn.prototype.getDigits = function () {
        return !isNullOrUndefined(this.handlers.params) && !isNullOrUndefined(this.handlers.params.digits) ? this.handlers.params.digits : this.units.digits;
    };
    WeightColumn.prototype.convert = function (row, value) {
        return toFixed(WeightUnits.fromDefault(value, this.units), this.getDigits()) || OmsConstants.EMPTY_VALUE;
    };
    WeightColumn.prototype.convertBack = function (value) {
        return WeightUnits.toDefault(value, this.units);
    };
    return WeightColumn;
}(BaseColumn));
export { WeightColumn };
var VolumeColumn = /** @class */ (function (_super) {
    __extends(VolumeColumn, _super);
    function VolumeColumn(id, label, field, fieldType, width, handlers, search) {
        var _this = _super.call(this, id, label, field, fieldType, width, handlers, search) || this;
        _this.units = OmsConstants.DEFAULT_VOLUME_UNITS;
        _this.label = '';
        _this.handlers.header = { select: [VolumeUnits.VOLUME_CFT, VolumeUnits.VOLUME_CBM], selectLabel: 'label', selected: function () { return _this.units; }, apply: function (units) { return _this.units = units; }, displaySelectedLabel: true };
        return _this;
    }
    VolumeColumn.prototype.onInit = function () {
        _super.prototype.onInit.call(this);
        this.align = 'end';
    };
    VolumeColumn.prototype.getDigits = function () {
        return !isNullOrUndefined(this.handlers.params) && !isNullOrUndefined(this.handlers.params.digits) ? this.handlers.params.digits : this.units.digits;
    };
    VolumeColumn.prototype.convert = function (row, value) {
        return toFixed(VolumeUnits.fromDefault(value, this.units), this.getDigits()) || OmsConstants.EMPTY_VALUE;
    };
    VolumeColumn.prototype.convertBack = function (value) {
        return VolumeUnits.toDefault(value, this.units);
    };
    return VolumeColumn;
}(BaseColumn));
export { VolumeColumn };
var LoadTypeColumn = /** @class */ (function (_super) {
    __extends(LoadTypeColumn, _super);
    function LoadTypeColumn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoadTypeColumn.prototype.onInit = function () {
        this.align = 'center';
        this.handlers.icon = function (load) {
            if (isNullOrUndefined(load.loadType)) {
                return null;
            }
            switch (load.loadType) {
                case LoadType.RECOVERY: return 'fa fa-long-arrow-up';
                case LoadType.DELIVERY: return 'fa fa-long-arrow-down';
                default: return 'fa fa-arrows-h';
            }
        };
        this.handlers.converter = function () { return null; };
        this.handlers.tooltip = function (load) {
            if (isNullOrUndefined(load)) {
                return null;
            }
            switch (load.loadType) {
                case LoadType.RECOVERY: return 'Recovery Load';
                case LoadType.DELIVERY: return 'Delivery Load';
                default: return '';
            }
        };
    };
    return LoadTypeColumn;
}(BaseColumn));
export { LoadTypeColumn };
var DateTimeColumn = /** @class */ (function (_super) {
    __extends(DateTimeColumn, _super);
    function DateTimeColumn(id, label, field, width, search, handlers) {
        var _this = _super.call(this, id, label, typeof field === 'string' ? { actual: field } : field, FieldType.DATETIME, width, handlers, search) || this;
        _this.setSearch({ searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time' });
        return _this;
    }
    Object.defineProperty(DateTimeColumn.prototype, "estimation", {
        get: function () { return !isUndefined(this.field.estimated); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeColumn.prototype, "actualField", {
        get: function () { return this.field.actual || this.field; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeColumn.prototype, "estimatedField", {
        get: function () { return this.field.estimated; },
        enumerable: true,
        configurable: true
    });
    DateTimeColumn.prototype.getActual = function (object) {
        return this.field ? _super.prototype.getValue.call(this, object, this.field.actual) : undefined;
    };
    DateTimeColumn.prototype.getEstimated = function (object) {
        return this.estimation ? _super.prototype.getValue.call(this, object, this.field.estimated) : undefined;
    };
    DateTimeColumn.prototype.getTooltip = function (object) {
        return _super.prototype.getTooltip.call(this, object) || this.estimation ?
            'EST: ' + convertToLongDateTime(this.getEstimated(object), OmsConstants.EMPTY_VALUE) + '\n' +
                'ACT: ' + convertToLongDateTime(this.getActual(object), OmsConstants.EMPTY_VALUE) :
            convertToLongDateTime(this.getActual(object), OmsConstants.EMPTY_VALUE);
    };
    DateTimeColumn.prototype.getClass = function (row, value) {
        var cls = _super.prototype.getClass.call(this, row, value) || '';
        //    //if (!isNullOrUndefined(cls)) {
        //      console.log('estimated', cls, value);
        //      return cls;
        //    }
        if (!this.estimation) {
            return cls;
        }
        if (!isNullOrUndefined(this.getActual(row))) {
            var cssClass = 'date-actual';
            return cls + ' ' + cssClass + ' ' + getPendingStatusClass(getPendingStatusEstimated(this.getEstimated(row))) + '-estimated';
        }
        if (!isNullOrUndefined(this.getEstimated(row))) {
            var result = 'date-estimated';
            var pendingClass = getPendingStatusClass(getPendingStatusEstimated(this.getEstimated(row)));
            if (!isNullOrUndefined(pendingClass)) {
                result += ' ' + pendingClass;
            }
            return cls + ' ' + result;
        }
        return cls;
    };
    DateTimeColumn.prototype.getIconClass = function (object) {
        /*    if (typeof this.field === 'string')
              return null;
        
            if (!isNullOrUndefined(this.getActual(object)))
              return 'fa fa-check-circle';
        
            if (!isNullOrUndefined(this.getEstimated(object)))
              return 'fa fa-angle-double-right'; */
        return null;
    };
    DateTimeColumn.prototype.getValue = function (object, field) {
        return field ? _super.prototype.getValue.call(this, object, field) : (this.getActual(object) || this.getEstimated(object));
    };
    return DateTimeColumn;
}(BaseColumn));
export { DateTimeColumn };
var ArrivalDateColumn = /** @class */ (function (_super) {
    __extends(ArrivalDateColumn, _super);
    function ArrivalDateColumn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ArrivalDateColumn.prototype.getClass = function (object) {
        var masterDateArrivalAct = object instanceof Order ? object.dateArrivalAct : (object instanceof Master ? object.dateArrivalAct : null);
        var masterDateArrivalEst = object instanceof Order ? object.dateArrivalEst : (object instanceof Master ? object.dateArrivalEst : null);
        var datePickupAct = object instanceof Order ? object.datePickupAct : (object instanceof Master ? object.datePickupActual : null);
        var datePickupEst = object instanceof Order ? object.datePickupEst : (object instanceof Master ? object.datePickupEstimated : null);
        var master = object instanceof Order ? object.master : object;
        var skipImpendingWarning = isNullOrUndefined(master) || (!isNullOrUndefined(master.cargoBuilding) && master.cargoBuilding.cfs3plLocation);
        var result = '';
        var nowEST = maxETCDateFromNow();
        if (!isNullOrUndefined(masterDateArrivalAct)) {
            result = 'date-actual';
            if (isNullOrUndefined(datePickupAct) && !skipImpendingWarning) {
                var days = daysDiff(nowEST, convertToEST(masterDateArrivalAct));
                if (days === 0) {
                    result += ' date-impending';
                }
                else if (days < 0) {
                    result += ' date-past-due';
                }
                if (!isNullOrUndefined(masterDateArrivalEst)) {
                    var convertedEst = convertToEST(masterDateArrivalEst);
                    var beforeEst = isBefore(nowEST, convertedEst);
                    var diff = daysDiff(nowEST, convertedEst);
                    if (beforeEst && diff === 0) {
                        result += ' date-impending-estimated';
                    }
                    else if (!beforeEst) {
                        result += ' date-past-due-estimated';
                    }
                }
            }
        }
        else if (!isNullOrUndefined(masterDateArrivalEst)) {
            result = 'date-estimated';
            if (isNullOrUndefined(datePickupAct) && !skipImpendingWarning) { // Matters until Actual Pickup Date is set
                var convertedEst = convertToEST(masterDateArrivalEst);
                var beforeEst = isBefore(nowEST, convertedEst);
                var days = daysDiff(nowEST, convertedEst);
                if (beforeEst && days === 0) {
                    result += ' date-impending';
                }
                else if (!beforeEst) {
                    result += ' date-past-due';
                }
            }
        }
        return result;
    };
    return ArrivalDateColumn;
}(DateTimeColumn));
export { ArrivalDateColumn };
var DateColumn = /** @class */ (function (_super) {
    __extends(DateColumn, _super);
    function DateColumn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateColumn.prototype.onInit = function () {
        _super.prototype.onInit.call(this);
        this.fieldType = FieldType.DATE;
    };
    return DateColumn;
}(DateTimeColumn));
export { DateColumn };
var TreeColumn = /** @class */ (function (_super) {
    __extends(TreeColumn, _super);
    function TreeColumn(id, label, width) {
        var columns = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            columns[_i - 3] = arguments[_i];
        }
        var _this = _super.call(this, id, label, null, null, width, { converter: function (row, value) { return isNullOrUndefined(value) ? null : value; } }) || this;
        _this.map = new Map();
        columns.forEach(function (column) {
            _this.map.set(column.row, column.column);
            column.column.parent = _this;
        });
        return _this;
        /*    for (let column of columns) {
              this.map.set(column.row, column.column);
            }*/
    }
    TreeColumn.prototype.byRow = function (row) {
        var column = isNullOrUndefined(row) ? null : this.map.get(row.rowId);
        return isNullOrUndefined(column) ? this : column;
    };
    TreeColumn.prototype.getValue = function (row, field) {
        var column = isNullOrUndefined(row) ? null : this.map.get(row.rowId);
        return isNullOrUndefined(column) ? _super.prototype.getValue.call(this, row, field) : column.getValue(row, field);
    };
    TreeColumn.prototype.convert = function (row, value) {
        var column = isNullOrUndefined(row) ? null : this.map.get(row.rowId);
        return isNullOrUndefined(column) ? _super.prototype.convert.call(this, row, value) : column.convert(row, value);
    };
    TreeColumn.prototype.setColumns = function () {
        var _this = this;
        var columns = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            columns[_i] = arguments[_i];
        }
        columns.forEach(function (column) { return _this.map.set(column.row, column.column); });
        return this;
    };
    TreeColumn.prototype.clone = function () {
        return _super.prototype.clone.call(this);
    };
    return TreeColumn;
}(BaseColumn));
export { TreeColumn };
