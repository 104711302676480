import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RestService } from '../rest.service';
import { HttpUtilsService } from '../http-utils.service';
import { map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { classToPlain, plainToClass } from 'class-transformer';
import { OmsAlertsService } from '../../modules/shared/components/oms-alerts/oms-alerts.service';
import {
  Driver,
  Load,
  Master,
  MasterStatusId,
  Order,
  RecoveryOrder,
  Trailer,
  Truck
} from '../../modules/shared/models';
import { FilterPageRequest } from '../../modules/shared/models/filter.page.request';
import { PageResult } from '../../modules/shared/models/query-models/page-result';
import {
  MasterManifestExcelRequest,
  OrdersDeliveryManifestRequest
} from '../../modules/shared/models/master.manifest.excel.request';
import { Carrier } from '../../modules/shared/models/carrier';
import { MasterPtt } from './master-ptt';
import { BaseEntityService } from "../base/base-entity.service";
import { StringUtil } from "../../_helpers/string.util";
import { LoadDispatchDto, LoadPickupDto } from "../../common/oms-types";
import {isNullOrUndefined} from "util";
import {assigned} from "../../_helpers/utils";


@Injectable()
export class MasterService extends BaseEntityService<Master> {
  protected apiUrl = '/oms/master/';
  protected classType = Master;

//  public mastersSubject = new Subject<Master[]>();

  public refreshDataRequired = new Subject<Master>();
  public mastersPageSubject = new Subject<PageResult<Master>>();
  private lastPageRequest: FilterPageRequest;
  public newMasterCreated = new BehaviorSubject<boolean>(false);

  constructor(
    private alerts: OmsAlertsService,
//    private http: HttpClient,
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public getMastersByFilterSearchRequest(filterPageRequest: FilterPageRequest, onError: (error) => void) {
    console.time('Page Request');
    this.lastPageRequest = filterPageRequest;
    this.httpRestService.postWithHeader<PageResult<Master>>(this.apiUrl + 'findMastersByFilters', this.httpUtils.getHTTPHeaders(), filterPageRequest).subscribe(page => {
      console.timeEnd('Page Request');
      console.time('Page plainToClass');
      page.content = plainToClass(Master, page.content).map(master => this.afterLoad(master));
      console.timeEnd('Page plainToClass');
      console.time('Subject Next');
      this.mastersPageSubject.next(page);
      console.timeEnd('Subject Next');
    }, error => {
      onError(error);
    });
  }

  public refreshByLastPageRequest() {
    this.getMastersByFilterSearchRequest(this.lastPageRequest, () => { });
  }


  public getMaster(id: number, activeOnly: boolean = true): Promise<Master> {
    let params = this.httpUtils.getHTTPParams({active: activeOnly});
    return this.httpRestService.getWithOptions(this.apiUrl + 'getMaster/' + id, this.httpUtils.getHTTPHeaders(), params) // .get<Master>(API_URL + 'getMaster', id)
      .pipe(
        map((master) => {
        console.log('LOADED: ', master);
        return this.afterLoad(plainToClass(Master, master));
      }))
      .toPromise();
  }

  public getMastersByRecoveryOrderId(id: number, activeOnly: boolean = true): Promise<Master[]> {
    let params = this.httpUtils.getHTTPParams({active: activeOnly});
    return this.httpRestService.getWithOptions<any[]>(this.apiUrl + 'get-by-recovery-order/' + id,
      this.httpUtils.getHTTPHeaders(), params) // .get<Master>(API_URL + 'getMaster', id)
      .pipe(
        map((objects) => plainToClass(Master, objects)),
        map((masters) => masters.map((master) => this.afterLoad(master)))
      )
//      .pipe(map((masters) => masters.map(master => this.afterLoad(master))))
      .toPromise();
  }


  public updateMaster(id: number, fields: {}): Promise<Master> {
    return this.updateMasterObs(id, fields).toPromise();
  }

  public updateMasterObs(id: number, fields: {}): Observable<Master> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<Master>(this.apiUrl + 'updateMaster/' + id, fields, httpHeader)
      .pipe(map((master) => this.afterLoad(plainToClass(Master, master))));
  }

  public updateMasterProblems(id: number, problems): Promise<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(this.apiUrl + 'updateMasterProblems/' + id, problems, httpHeader).toPromise();
  }

  public createMasterPTT(item: MasterPtt): Observable<MasterPtt> {
    return this.httpRestService.post<any>(this.apiUrl + 'createMasterPTT', item);
  }

  public downloadMasters(filterPageRequest: FilterPageRequest, alerts: OmsAlertsService): Observable<any> {
    let uuid = StringUtil.generateUuid();
    alerts.process('Generating Excel...', '', () => this.getProgress(uuid), 5000);
    return this.httpRestService.post<any>(this.apiUrl + 'downloadMasters', filterPageRequest, {
      params: {uuid: uuid}
    }).pipe(tap(() => {}, error => {alerts.error(error, "Error Excel Generation:"); }));
  }

  public mastersByIDs(masterIDs: number[]): Subject<any[]> {
  let result = new Subject<Master[]>();
    this.httpRestService.post<any>(this.apiUrl + 'mastersByIDs', masterIDs)
      .subscribe((items) => {
        result.next(plainToClass(Master, items).map(master => this.afterLoad(master)));
      });
    return result;
  }

  public downloadNominationMasters(masterIDs: number[]): Observable<any> {
    return this.httpRestService.post<any>(this.apiUrl + 'downloadNominationMasters', masterIDs);
  }

  public downloadMastersManifest(filterPageRequest: FilterPageRequest): Observable<any> {
    return this.httpRestService.post<any>(this.apiUrl + 'downloadMastersManifest', filterPageRequest);
  }

  public downloadMastersManifestByManifest(masterManifestExcelRequest: MasterManifestExcelRequest): Observable<any> {
    return this.httpRestService.post<any>(this.apiUrl + 'downloadMastersManifestByMasterRequest', masterManifestExcelRequest);
  }

  public downloadOrdersDeliveryManifest(request: OrdersDeliveryManifestRequest): Observable<any> {
    return this.httpRestService.post<any>(this.apiUrl + 'downloadOrdersDeliveryManifest', request);
  }

  public buildDownloadUrl(fileType: string) {
    return this.httpRestService.buildUrlForDownload(this.apiUrl + 'downloadMastersInformation', fileType);
  }

  public buildDownloadMasterUrl(fileType: string, id: number) {
    return this.httpRestService.buildUrlForDownload(this.apiUrl + 'downloadMaster/' + id, fileType);
  }

  public validateRecoveryLoadPieces(master: Master, split: RecoveryOrder, pcsRecovered?: number): boolean {
    if (pcsRecovered <= 0) {
      this.alerts.danger('Recovery count should be greater than 0', 2000);
      return false;
    }

    let num = master.pieces || 0;
    master.splits.forEach(s => {
      num -= s.isVirtual ? 0 : s.pcsToRecover || 0;
    });

    if (num < 0) {
      this.alerts.danger('Recovery count should not exceed total number of pieces', 2000);
      return false;
    }
    return true;
  }

  public _updateMaster(master: Master, update: (master: Master) => boolean): Promise<Master> {
    return new Promise((resolve, reject) => {
      this.getMaster(master.id)
        .then((m) => {
          if (update(m)) {
            this.update(m).toPromise()
            .then((m1) => resolve(this.afterLoad(plainToClass(Master, m1))))
            .catch(error => reject(error));
          } else {
            reject();
          }
        })
        .catch(error => reject(error));
    });
  }

  public searchByMawb(mawb: string, count?: number): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const params = new HttpParams().set('by', mawb);
    if (count) { params.set('count', count.toString()); }
    return this.httpRestService.getWithOptions<any>(this.apiUrl + 'searchByMawb', httpHeader, params);
  }

  public cancelLoadDispatch(masterId: number, loadId: number, orderId: number): Promise<Master> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const params = this.httpUtils.getHTTPParams({orderId});
    return this.httpRestService.put<Master>(this.apiUrl + 'cancelLoadDispatch/' + masterId + '=' + loadId, null, httpHeader, params)
      .pipe(map((master) => this.afterLoad(plainToClass(Master, master))))
      .toPromise();
  }

  public dispatchLoadRecovery(master: Master, data: LoadDispatchDto, save: boolean): Promise<Master> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    let params = new HttpParams()
      .set('masterId', master.id.toString())
      .set('save', save ? '1' : '0');

    return this.httpRestService.put<Master>(this.apiUrl + 'dispatchLoadRecovery',
      classToPlain(data, {enableCircularCheck: true}), httpHeader, params)
      .pipe(map((m) => this.afterLoad(plainToClass(Master, m))))
      .toPromise();
  }

  public updateLoadDate(master: Master, load: Load, field: string, date: Date): Promise<Master> {
//    console.log('date', date, typeof date);
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const params = new HttpParams()
      .set('masterId', master.id.toString())
      .set('loadId', load.id.toString())
      .set('field', field)
      .set('date', date ? date.toJSON() : null);

    return this.httpRestService.put<Master>(this.apiUrl + 'updateLoadDate', '', httpHeader, params)
      .pipe(map((m) => this.afterLoad(plainToClass(Master, m))))
      .toPromise();
  }

  public updateOrderDate(master: Master, order: Order, field: string, date: Date): Promise<Master> {
    console.log('date', date, typeof date);
    const httpHeader = this.httpUtils.getHTTPHeaders();
    let params = new HttpParams()
      .set('orderId', order.id.toString())
      .set('field', field)
      .set('date', date ? date.toJSON() : null);
    if (master) {
      params = params.set('masterId', master.id.toString());
    }

    return this.httpRestService.put<Master>(this.apiUrl + 'updateOrderDate', '', httpHeader, params)
      .pipe(map((m) => this.afterLoad(plainToClass(Master, m))))
      .toPromise();
  }

  public updateAirline(masterShipmentAirId: number, airlineId: number): Promise<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(this.apiUrl + 'updateMasterAirline/' + masterShipmentAirId + '=' + airlineId, null, httpHeader)
      .toPromise();
  }

  public updateFlightNumber(masterShipmentAirId: number, flightNumber: string): Promise<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(this.apiUrl + 'updateMasterFlightNumber/' + masterShipmentAirId + '=' + flightNumber, null, httpHeader)
      .toPromise();
  }

  public dispatchMastersRecovery(masterIds: number[], date: Date, driver?: Driver, truck?: Truck, trailer?: Trailer, pieces?: number, hu?: number): Promise<Master[]> {
    let params = new HttpParams();

    if (driver) { params = params.set('driver', driver.id.toString()); }
    if (truck) { params = params.set('truck', truck.id.toString()); }
    if (trailer) { params = params.set('trailer', trailer.id.toString()); }
    if (date) { params = params.set('date', date.toJSON()); }
    if (pieces > 0) { params = params.set('pieces', pieces.toString()); }
    if (hu > 0 ) { params = params.set('hu', hu.toString()); }

    return this.httpRestService.put<Master[]>(this.apiUrl + 'dispatchMastersRecovery', masterIds, this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((masters) => plainToClass(Master, masters).map((m) => this.afterLoad(m))))
      .toPromise();
//      .then((response) => response.map(m => Master.afterLoad(plainToClass(Master, <Object>m))));
  }

  public dispatchOrdersDelivery(orderIds: number[], date: Date, driver?: Driver, truck?: Truck, trailer?: Trailer, carrier?: Carrier): Promise<Order[]> {
    let params = new HttpParams();

    if (driver) { params = params.set('driver', driver.id.toString()); }
    if (truck) { params = params.set('truck', truck.id.toString()); }
    if (trailer) { params = params.set('trailer', trailer.id.toString()); }
    if (carrier) { params = params.set('carrierId', carrier.id.toString()); }
    if (date) { params = params.set('date', date.toJSON()); }

    return this.httpRestService.put<Order[]>(this.apiUrl + 'dispatchOrdersDelivery', orderIds, this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((orders) => plainToClass(Order, orders)))
      .toPromise();
  }

  public pickupMasterRecovery(master: Master, loads: LoadPickupDto[], masterId?: number): Promise<Master> {
    let masterID = assigned(master) ? master.id.toString() : masterId.toString();
    let params = new HttpParams().set('master', masterID);
    return this.httpRestService.put<Master>(this.apiUrl + 'pickupMasterRecovery', classToPlain(<Object[]>loads), this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((m) => this.afterLoad(plainToClass(Master, m))))
      .toPromise();
  }

  public cancelMastersRecovery(masterIds: number[]): Promise<Master[]> {
    let params = new HttpParams();

    return this.httpRestService.put<Master[]>(this.apiUrl + 'cancelMastersRecovery', masterIds, this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((masters) => plainToClass(Master, masters).map((master) => this.afterLoad(master))))
      .toPromise();
  }

  public isDisableMaster(master: Master): boolean {
    if (!master.nonAMS) {
      return master.orders.some(order => this.isDisableOrder(order));
    }
    return false;
  }

  public isDisableOrder(order: Order): boolean {
    if (!order.nonAMS) {
      return order.status <= MasterStatusId.ONHAND_COMPLETE_PENDING_1C;
    }
    return false;
  }

}
