﻿import {Component, ElementRef, Inject, Input, OnInit, Optional} from '@angular/core';
import {FilterContainer, FilterCriteria} from '../../../modules/shared/models';
import {Filter} from '../../../modules/shared/models';
import {isNullOrUndefined} from 'util';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, Validators} from "@angular/forms";
import {BaseColumn} from "../../../modules/shared/components/base/data-table/columns/column-types";


@Component({templateUrl: 'custom-filter-dialog.component.html',
            styleUrls: ['./custom-filter-dialog.component.scss'],
            selector: 'oms-custom-filter-dialog'})
export class CustomFilterDialogComponent implements OnInit {
  form: FormGroup;

  hasFormErrors: boolean = false;
  viewLoading: boolean = false;
  loadingAfterSubmit: boolean = false;
  filterContainer: FilterContainer;
  selectedTab = 0;
  selectedFilter: Filter;
  columnsSettings: BaseColumn[];
  column: BaseColumn;
  selectedListValue: any = {value: null, condition: ""};


  constructor(public dialogRef: MatDialogRef<CustomFilterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.viewLoading = true;

    this.columnsSettings = this.data.columnsSettings.filter(column => {return column.search.searchable});
    this.filterContainer = FilterContainer.createDefault();
    this.createForm();
    this.viewLoading = false;

  }

  createForm() {
    this.form = this.fb.group({
      name: [this.filterContainer.name, Validators.required],
    });
  }

  /*constructor(private filterService: FilterService, private accountService: AccountService) {
     this.filterService.filterPopUpEvent.subscribe(data => {
       this.isShown = data.show;
       this.filterContainer = data.filterContainer;
     });
     this.accountService.columnsSettingsSubject.subscribe(columnsSettings => {
       this.columnsSettings = columnsSettings;
     });

  }*/

  addFilter(event){
    let filter = this.filterContainer.filters.filter(filter => {return filter.fieldId == event.id});
    if (isNullOrUndefined(filter) || filter.length == 0) {
      this.selectedFilter = Filter.createDefault(event);
      this.filterContainer.filters.unshift(this.selectedFilter);
    } else {
      this.selectedFilter = filter[0];
    }
  }

  deleteFilter(filter: Filter){
    this.filterContainer.filters = this.filterContainer.filters.filter(filterFromContainer => {return filterFromContainer.fieldId != filter.fieldId});
    if(this.selectedFilter.fieldId == filter.fieldId){
      this.selectedFilter = null;
    }
  }


  addListFilterCriteriaValue() {
    const filter = this.getActiveFilter();
    let filterCriteria = new FilterCriteria();
    filterCriteria.value =  this.selectedListValue.value;
    filterCriteria.condition =  this.selectedListValue.condition;
    filter.filterCriterias.push(filterCriteria);
  }

  listValueChanged(value){
    this.selectedListValue.value = value;
  }

  listConditionChanged(value){
    this.selectedListValue.condition = value ? "include" : "exclude";
  }

  selectTab(filter: Filter, selectedTab: number) {
    this.selectedTab = selectedTab;
    this.selectedFilter = filter;
  }

  selectFilter(filter: Filter){
    this.selectedFilter = filter;
    this.column = this.columnsSettings.find(column => column.id == filter.fieldId);
  }

  getActiveFilter() {
    if (!this.selectedFilter && this.filterContainer.filters && this.filterContainer.filters.length > 0) {
      this.selectedFilter = this.filterContainer.filters[0];
      return this.selectedFilter;
    } else if (this.filterContainer.filters && this.filterContainer.filters.length > 0) {
      return this.filterContainer.filters[this.selectedTab];
    }
  }

  deleteFilterValue(selectedFilter, filterValue, index) {
    selectedFilter.filterCriterias.splice(index, 1);
  }




  getTitle(): string {
    return this.filterContainer.isNew() ? 'Create Filter' : 'Edit Filter';
  }


  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;
    const controls = this.form.controls;
    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const filterContainer = this.prepare();
    this.save(filterContainer);
  }

  prepare(): FilterContainer {
  /*  const controls = this.form.controls;
    const _address = new Address();
    _address.id = this.address.id;
    _address.name = controls['name'].value;
    _address.country = controls['country'].value;
    _address.city = controls['city'].value['name'];
    _address.addressLines = controls['address'].value;
    _address.postCode = controls['postCode'].value;
    _address.province = controls['province'].value;
    _address.usaState = controls['usaState'].value;*/

    return null;
  }

  save(_filterContainer: FilterContainer) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
/*    this.addressService.create(_address).subscribe(res => {
      this.viewLoading = false;
      this.dialogRef.close({
        res,
        isEdit: false
      });
    });*/
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }




}
