var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Exclude, Type } from "class-transformer";
export var ScheduleType;
(function (ScheduleType) {
    ScheduleType[ScheduleType["DAILY"] = 0] = "DAILY";
    ScheduleType[ScheduleType["WEEKLY"] = 1] = "WEEKLY";
    ScheduleType[ScheduleType["MONTHLY"] = 2] = "MONTHLY";
    ScheduleType[ScheduleType["HOURLY"] = 3] = "HOURLY";
})(ScheduleType || (ScheduleType = {}));
var ɵ0 = function () { return Date; }, ɵ1 = function () { return Date; };
var Schedule = /** @class */ (function () {
    function Schedule() {
        this.scheduleType = ScheduleType.DAILY;
        this.hourPeriod = 0;
        this.dayNumber = 1;
        this.weekNumber = 1;
        this.weekDays = [false, false, false, false, false, false, false];
        this.monthlyType = 0;
        this.monthNumber = 1;
        this.dayOfMonthNumber = 1;
        this.dayOfWeekInMonthNumber = 0;
        this.dayOfWeek = 1;
        this.hour = 0;
        this.minute = 0;
        this.timeZone = "America/New_York";
        this.dateFrom = new Date();
    }
    Object.defineProperty(Schedule.prototype, "isHourly", {
        get: function () {
            return this.scheduleType === ScheduleType.HOURLY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Schedule.prototype, "isDaily", {
        get: function () {
            return this.scheduleType === ScheduleType.DAILY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Schedule.prototype, "isWeekly", {
        get: function () {
            return this.scheduleType === ScheduleType.WEEKLY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Schedule.prototype, "isMonthly", {
        get: function () {
            return this.scheduleType === ScheduleType.MONTHLY;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Date)
    ], Schedule.prototype, "dateFrom", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Date)
    ], Schedule.prototype, "dateTo", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Schedule.prototype, "isHourly", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Schedule.prototype, "isDaily", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Schedule.prototype, "isWeekly", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Schedule.prototype, "isMonthly", null);
    return Schedule;
}());
export { Schedule };
export { ɵ0, ɵ1 };
