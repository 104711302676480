var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isNullOrUndefined } from 'util';
import { Type } from "class-transformer";
var ɵ0 = function () { return Date; }, ɵ1 = function () { return Date; };
var BaseEntity = /** @class */ (function () {
    function BaseEntity(id) {
        this.id = id;
    }
    BaseEntity.idOf = function (entity) {
        return entity instanceof BaseEntity ? entity.id : null;
    };
    BaseEntity.prototype.isNew = function () {
        return isNullOrUndefined(this.id);
    };
    BaseEntity.prototype.equals = function (object) {
        return object === this || (!this.isNew() && object instanceof BaseEntity && object.id === this.id);
    };
    BaseEntity.prototype.removeCircularDependency = function () {
        var keys = Object.keys(this);
        this.circularDependencyRemove(keys, this);
    };
    BaseEntity.prototype.circularDependencyRemove = function (keys, parent) {
        var _this = this;
        keys.forEach(function (key) {
            var fieldValue = _this[key];
            if (fieldValue instanceof Array) {
                fieldValue.forEach(function (fieldValueItem) {
                    if (fieldValueItem.circularDependencyRemove) {
                        fieldValueItem.circularDependencyRemove(Object.keys(fieldValueItem), parent);
                    }
                });
            }
            else if (!isNullOrUndefined(fieldValue)) {
                if (fieldValue === parent) {
                    _this[key] = null;
                }
                else {
                    if (fieldValue.circularDependencyRemove) {
                        _this[key] = fieldValue.circularDependencyRemove(Object.keys(fieldValue), _this);
                    }
                }
            }
        });
        return this;
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Date)
    ], BaseEntity.prototype, "dateCreated", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Date)
    ], BaseEntity.prototype, "dateUpdated", void 0);
    return BaseEntity;
}());
export { BaseEntity };
export { ɵ0, ɵ1 };
