/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dynamic-injector.component";
var styles_DynamicInjectorComponent = [];
var RenderType_DynamicInjectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DynamicInjectorComponent, data: {} });
export { RenderType_DynamicInjectorComponent as RenderType_DynamicInjectorComponent };
export function View_DynamicInjectorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { target: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["target", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_DynamicInjectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "oms-dynamic-injector", [], null, null, null, View_DynamicInjectorComponent_0, RenderType_DynamicInjectorComponent)), i0.ɵdid(1, 704512, null, 0, i1.DynamicInjectorComponent, [i0.ComponentFactoryResolver, i0.Injector], null, null)], null, null); }
var DynamicInjectorComponentNgFactory = i0.ɵccf("oms-dynamic-injector", i1.DynamicInjectorComponent, View_DynamicInjectorComponent_Host_0, { component: "component", data: "data" }, { close: "close" }, []);
export { DynamicInjectorComponentNgFactory as DynamicInjectorComponentNgFactory };
