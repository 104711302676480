import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {OmsConstants} from '../../../common/oms-constants.service';
import {Uld} from '../../../modules/shared/models';
import {isNullOrUndefined} from 'util';
import {range} from 'rxjs';
import {UldNumberValidator} from '../../../common/validators/uld-number.validator';
import {MasterService} from '../../../services/master/master.service';
import {OrdersService} from '../../../services';
import {UldService} from '../../../services/uld.service';


@Component({
  selector: 'oms-ulds-dialog',
  templateUrl: './ulds-dialog.component.html',
  styleUrls: ['./ulds-dialog.component.scss']
})
export class UldsDialogComponent implements OnInit {
  uldForm: FormGroup;

  public readonly: boolean = false;
  uldsCount: number;
  public ulds: Uld[] = [];
  public dependUlds: Uld[] = [];
  uldCountNotAutomated;
  isDisplayCheckbox: boolean = false;

  constructor(public dialogRef: MatDialogRef<UldsDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              private data: UldsDialogInputData,
              private fb: FormBuilder,
              public _constants: OmsConstants,
              public ordersService: OrdersService,
              private masterService: MasterService,
              private uldService: UldService) {
    this.data = data;
    this.readonly = data.readonly;
    if (data.masterId) {
      this.masterService.getMaster(data.masterId)
        .then(master => {
          this.uldsCount = master.uldCount;
          this.ulds = master.ulds;
          this.createForm();
        });
    } else if (data.orderId) {
      this.ordersService.getOrder(data.orderId)
        .then(order => {
          if (order.master) {
            this.isDisplayCheckbox = true;
            this.uldsCount = order.master.uldCount;
            this.ulds = order.master.ulds;
            this.dependUlds = order.ulds;
          } else {
            this.uldsCount = order.uldCount;
            this.ulds = order.ulds;
          }
          this.createForm();
        });
    }
  }

  createForm() {
    let uldCount = this.getUldCount();
    this.uldForm = this.fb.group({
      uldsCount: uldCount,
      ulds: this.fb.array(this.getChildItemsFields(this.ulds, uldCount))
    });
  }

  getUldCount(): number {
    if (this.isDisplayCheckbox) {
      return this.uldsCount || 0;
    }
    return this.uldsCount || 8;
  }

  getChildItemsFields(items: Uld[], uldCount: number): any[] {
    const itemFormGroups = [];
    if (!isNullOrUndefined(items) && items.length > 0) {
      items.forEach(item => {
        const fieldSet = {
          value: new FormControl({
            value: item.uldNumber,
            disabled: this.isDisplayCheckbox
          }, UldNumberValidator.validateULDNumber),
          id: item.id,
          uld: item
        };
        itemFormGroups.push(this.fb.group(fieldSet));
      });
      if (itemFormGroups.length < uldCount) {
        range(0, uldCount - itemFormGroups.length).subscribe((item) => {
          const fieldSet = {
            value: new FormControl(null, UldNumberValidator.validateULDNumber),
            id: null
          };
          itemFormGroups.push(this.fb.group(fieldSet));
        });
      }
    } else {
      range(0, uldCount).subscribe(item => {
        const fieldSet = {
          value: new FormControl(null, UldNumberValidator.validateULDNumber),
          id: null
        };
        itemFormGroups.push(this.fb.group(fieldSet));
      });
    }
    return itemFormGroups;
  }

  ngOnInit() {
  }

  isSelectedUld(uld: Uld): boolean {
    return this.dependUlds.some(dUld => dUld.id === uld.id);
  }

  changeSelectedUld(uld: Uld, value: boolean): void {
    if (value) {
      this.dependUlds.push(uld);
    } else {
      this.dependUlds = this.dependUlds.filter(dUld => dUld.id !== uld.id);
    }
  }

  get formData() {
    return <FormArray>this.uldForm.get('ulds');
  }

  getTitle(): string {
    return 'ULDs';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  prepare(): Uld[] {
    const controls = this.uldForm.controls;
    let uldsValues: any[] = controls['ulds'].value;
    let ulds = [];
    uldsValues.forEach(uld => {
      if (!isNullOrUndefined(uld.value)) {
        let masterAIrUld = new Uld();
        masterAIrUld.id = uld.id;
        masterAIrUld.uldNumber = uld.value;
        ulds.push(masterAIrUld);
      }
    });
    this.uldsCount = controls['uldsCount'].value;
    this.ulds = ulds;
    return this.ulds;
  }

  isCountAutomated() {
    return this.isDisplayCheckbox;
  }

  get notEmptyULDsLength() {

    const controls = this.uldForm.controls;
    let uldsValues: any[] = controls['ulds'].value;
    let ulds = [];
    uldsValues.forEach(uld => {
      let masterAIrUld = new Uld();
      masterAIrUld.id = uld.id;
      masterAIrUld.uldNumber = uld.value;
      ulds.push(masterAIrUld);
    });


    let notEmptyULD = ulds.filter(uld => {
      return (!isNullOrUndefined(uld.uldNumber) && uld.uldNumber.trim().length > 0);
    });
    return notEmptyULD.length;
  }

  changeULDNumber() {
    let notEmptyUldLength = this.notEmptyULDsLength;

    if (notEmptyUldLength > 0) {
      const controls = this.uldForm.controls;
      this.uldCountNotAutomated = controls['uldsCount'].value;
      controls['uldsCount'].setValue(notEmptyUldLength);
    } else {
      const controls = this.uldForm.controls;
      controls['uldsCount'].setValue(this.uldCountNotAutomated);
    }
  }

  changeUldCount() {
    let uldCount = +this.uldForm.get('uldsCount').value;
    let uldFields = this.uldForm.get('ulds') as FormArray;
    let controls = uldFields.controls.slice(0, uldCount);

    if (controls.length < uldCount) {
      range(controls.length, uldCount - 1).subscribe(item => {
        const fieldSet = {
          value: null,
          id: null
        };
        controls.push(this.fb.group(fieldSet));
      });
    }

    this.uldForm.setControl('ulds', this.fb.array(controls));
  }

  isControlInvalid(index: number): boolean {
    const formArray: FormArray = <FormArray>this.uldForm.get('ulds');
    let control = formArray.controls[index];
    return control.invalid && control.touched;
  }

  getUldErrorMessage(index: number): string[] {
    const formArray: FormArray = <FormArray>this.uldForm.get('ulds');
    let control: FormGroup = formArray.controls[index] as FormGroup;
    return [control.controls.value.errors.uld];
  }

  onSubmit() {

    const controls = this.uldForm.controls;
    /** check form */
    if (this.uldForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      return;
    }

    let ulds = this.isDisplayCheckbox ? this.dependUlds : this.prepare();

    this.uldService.updateULDs(ulds, this.data.masterId, this.data.orderId).subscribe(data => {
      this.dialogRef.close(ulds);
    });

    /*if (!isNullOrUndefined(this.masterAirId)) {


      let masterAir = new MasterAir();
      masterAir.id = this.masterAirId;
      masterAir.uldCount = this.uldsCount;
      masterAir.uldAirs = ulds;

    } else {
      this.dialogRef.close({
          ULDs: ulds,
          uldCount: this.uldsCount
        }
      );
    }*/
  }
}

export interface UldsDialogInputData {
  readonly: boolean;
  masterId?: number;
  orderId?: number;
}
