import {FilterCriteria} from './filter.criteria';
import {BaseEntity} from "./base-entity";
import {BaseColumn} from "../components/base/data-table/columns/column-types";

export class Filter extends BaseEntity {
  field: string | ((obj) => string);
  label: string;
  fieldId: number | string;
  filterCriterias: FilterCriteria[];


  public static createDefault(column: BaseColumn): Filter {
    let filter = new Filter();
    filter.field = column.field;
    filter.label = column.label;
    filter.fieldId = column.id;
    filter.filterCriterias = [];
    return filter;
  }
}
