import {RestService} from '../rest.service';
import {HttpUtilsService} from '../http-utils.service';
import {Observable} from 'rxjs';
import {classToPlain} from 'class-transformer';

export abstract class BaseService {
  protected abstract apiUrl;

  protected constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
  }

  protected put<T>(method: string, data?: T, params?: object): Observable<any> {
    return this.httpRestService.put<any>(this.apiUrl + method,
      data ? classToPlain(data, {enableCircularCheck: true}) : null,
      this.httpUtils.getHTTPHeaders(),
      this.httpUtils.getHTTPParams(params));
  }

  protected get<T>(method: string, params?: object): Observable<any> {
    return this.httpRestService.getWithOptions<any>(this.apiUrl + method,
      this.httpUtils.getHTTPHeaders(),
      this.httpUtils.getHTTPParams(params));
  }

}
