var AccessPage = /** @class */ (function () {
    function AccessPage(id, label) {
        this.id = id;
        this.label = label;
    }
    Object.defineProperty(AccessPage.prototype, "path", {
        get: function () {
            return this.id;
        },
        enumerable: true,
        configurable: true
    });
    return AccessPage;
}());
export { AccessPage };
export var AccessPageType;
(function (AccessPageType) {
    AccessPageType["WAREHOUSE_LIST"] = "/warehouse/warehouse-list";
    AccessPageType["WAREHOUSE_UPDATE"] = "/warehouse/update";
    AccessPageType["WAREHOUSE_MULTIPLE_ORDER"] = "/warehouse/multiple-order";
    AccessPageType["WEB_DRIVERS"] = "/webdrivers";
})(AccessPageType || (AccessPageType = {}));
export var AccessPages;
(function (AccessPages) {
    var pages = [
        new AccessPage(AccessPageType.WAREHOUSE_LIST, "Warehouse List"),
        new AccessPage(AccessPageType.WAREHOUSE_UPDATE, "Warehouse Update"),
        new AccessPage(AccessPageType.WAREHOUSE_MULTIPLE_ORDER, "Warehouse Multiple Order"),
        new AccessPage(AccessPageType.WEB_DRIVERS, "Web Drivers")
    ];
    function values() {
        return pages.map(function (p) { return new AccessPage(p.id, p.label); });
    }
    AccessPages.values = values;
    function label(accessPage) {
        var page = pages.find(function (p) { return p.path === accessPage; });
        return page ? page.label : null;
    }
    AccessPages.label = label;
})(AccessPages || (AccessPages = {}));
