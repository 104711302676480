﻿<ng-template #menuItem let-item="item" let-level="level" let-open="open">
  <li class="dropdown" [ngClass]="{'active':isActive(item), 'open':open}" *ifRole="item.role">
    <a [routerLink]="item.href" class="js-sub-menu-toggle"  (click)="toggleOpen($event, item)">
      <mat-icon svgIcon="{{item.svg}}" *ngIf="item.svg"></mat-icon>
      <i class="{{item.iconClass}}" *ngIf="item.iconClass"></i>
      <span class="text" style="opacity: 1;">{{item.label}}</span>
      <i class="toggle-icon" [ngClass]="{'fa fa-angle-down':open, 'fa fa-angle-left':!open}" *ngIf="item.children && item.children.length"></i>
    </a>
    <ul class="sub-menu" role="menu" id="{{item.dataTarget}}" *ngIf="item.children && item.children.length" [attr.aria-expanded]="open">
      <ng-container *ngFor="let child of item.children; let i=index;">
        <ng-container *ngTemplateOutlet="menuItem;context:{item:child, open: isOpen(child), level:level+1}"></ng-container>
      </ng-container>
    </ul>
  </li>
</ng-template>

<div #panel class="left-sidebar" (mouseenter)="enter()" (mouseleave)="leave()" (clickOutside)="outside()"
     [ngClass]="{
        'sidebar-hide-left':!visibility,
        'sidebar-float': floating,
        'sidebar-float-active': floating && visibility,
        'maximized': !minified,
        'minified': minified}">

  <div class="sidebar-toolbar sidebar-minified">
    <i class="sidebar-tool-btn" (click)="navigationService.toggleLeftSideBarMinified()">
      <span class="fas fa-arrows-alt-h" ></span>
    </i>
    <i class="sidebar-tool-btn" (click)="navigationService.toggleLeftSideBarFloating()" *ngIf="navigationService.wideScreen">
      <span class="fas fa-thumbtack" [ngClass]="{'fa-rotate-90': floating}"></span>
    </i>
  </div>

  <!-- main-nav -->
  <perfect-scrollbar [disabled]="minified" [config]="{suppressScrollX: false}" class="sidebar-scroll" [style.overflow]="minified ? 'visible' : 'inherit'">
    <nav class="main-nav">
      <ul class="main-menu">
        <ng-container *ngFor="let item of menuItems;">
          <ng-container *ngTemplateOutlet="menuItem;context:{item:item, open:isOpen(item), level:1}"></ng-container>
        </ng-container>
      </ul>
    </nav>
    <!-- /main-nav -->
  </perfect-scrollbar>
</div>


