var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { CfsLocation } from '../modules/shared/models/address';
import { CfsLocationDialogComponent } from '../components/dialogs/cfs-location-dialog/cfs-location-dialog.component';
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { ModalResult, OmsDialogsService } from "../components/common/oms-dialogs";
import * as i0 from "@angular/core";
import * as i1 from "../components/common/oms-dialogs/oms-dialogs.service";
import * as i2 from "./rest.service";
import * as i3 from "./http-utils.service";
var CfsLocationService = /** @class */ (function (_super) {
    __extends(CfsLocationService, _super);
    function CfsLocationService(dialogs, httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.dialogs = dialogs;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/cfs-location/';
        _this.classType = CfsLocation;
        return _this;
    }
    CfsLocationService.prototype.edit = function (location, save, readonly) {
        var _this = this;
        return new Promise(function (success, reject) {
            _this.dialogs.openDialog(CfsLocationDialogComponent, { location: location, readonly: readonly })
                .then(function (result) {
                if (result.result === ModalResult.OK) {
                    //            console.log('Edited', location);
                    if (save) {
                        _this.save(location).subscribe(function (saved) { return success(saved); }, function (error) { return reject(error); });
                    }
                    else {
                        success(location);
                    }
                }
            })
                .catch(function (error) { return reject(error); });
        });
    };
    CfsLocationService.prototype.getLocationsByAddress = function (addressId) {
        var _this = this;
        return this.httpRestService.getWithOptions(this.apiUrl, this.httpUtils.getHTTPHeaders(), { addressId: addressId })
            .pipe(map(function (items) {
            return items.map(function (item) { return plainToClass(_this.classType, item); });
        }));
    };
    CfsLocationService.ngInjectableDef = i0.defineInjectable({ factory: function CfsLocationService_Factory() { return new CfsLocationService(i0.inject(i1.OmsDialogsService), i0.inject(i2.RestService), i0.inject(i3.HttpUtilsService)); }, token: CfsLocationService, providedIn: "root" });
    return CfsLocationService;
}(BaseEntityService));
export { CfsLocationService };
