import {Driver} from "../driver";
import {Exclude, Type} from "class-transformer";
import {OrderDispatch} from "./order-dispatch";

export class DriverRoute {
  public dispatchId: number;
  public manifestDocumentId: number;
  @Type(()=>Driver)
  public driver: Driver;
  @Type(()=>Date)
  public dateApprovedOn: Date;
  @Type(()=>Date)
  public dateRouteCompleted:Date;
  @Type(()=>OrderDispatch)
  public orders: OrderDispatch[];

  @Exclude()
  public get isNew():boolean {
    return !this.dispatchId;
  }

  @Exclude()
  public get isAssigned():boolean {
    return !!this.driver;
  }

  @Exclude()
  public get isApproved() {
    return !!this.dateApprovedOn;
  }

  @Exclude()
  public get isCompleted():boolean {
    return !!this.dateRouteCompleted;
  }

}
