import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {Labeled} from './oms-types';
import {WEIGHT_DECIMAL_DIGITS} from "./oms-constants.service";

export class MeasureUnit implements Labeled {
  constructor(
    public id: number,
    public label: string,
    public coeff: number,
    public digits: number,
    public description: string = '') {}
}

export abstract class MeasureUnits {
  protected constructor(protected units: MeasureUnit[]) {}


  public static toDefault(value: number, unit: MeasureUnit): number {
    return isNullOrUndefined(value) ? value : value / unit.coeff;
  }

  public static fromDefault(value: number, unit: MeasureUnit): number {
    return isNullOrUndefined(value) ? value : value * unit.coeff;
  }

  getAll() {
    return this.units;
  }

  getAllLabels() {
    return this.units.map((u) => u.label);
  }

  // todo: make dependence to User Account Settings
  abstract get default(): MeasureUnit;

  get(id): MeasureUnit {
    for (let unit of this.units) {
      if (unit.id === id) {
        return unit;
      }
    }
    return null;
  }
}


@Injectable()
export class WeightUnits extends MeasureUnits {

  public static WEIGHT_KG = new MeasureUnit(0, 'KG', 1, WEIGHT_DECIMAL_DIGITS);
  public static WEIGHT_LBS = new MeasureUnit(1, 'LBS', 2.20462, WEIGHT_DECIMAL_DIGITS);

  public get default(): MeasureUnit {
    return WeightUnits.WEIGHT_KG;
  }

  constructor() {
    super([WeightUnits.WEIGHT_KG, WeightUnits.WEIGHT_LBS]);
  }
}

@Injectable()
export class VolumeUnits extends MeasureUnits {

  public static VOLUME_CBM = new MeasureUnit(0, 'CBM', 1, 1);
  public static VOLUME_CFT = new MeasureUnit(1, 'CFT', 35.315, 1);

  public get default(): MeasureUnit {
    return VolumeUnits.VOLUME_CFT;
  }

  constructor() {
    super([VolumeUnits.VOLUME_CBM, VolumeUnits.VOLUME_CFT]);
  }
}

@Injectable()
export class CurrencyUnits extends MeasureUnits {
  public static CURRENCY_USD = new MeasureUnit(0, 'USD', 1, 1);

  public get default(): MeasureUnit {
    return CurrencyUnits.CURRENCY_USD;
  }

  constructor() {
    super([CurrencyUnits.CURRENCY_USD]);
  }
}


@Injectable()
export class PackagingUnits extends MeasureUnits {

  public get default(): MeasureUnit {
    return this.get(0);
  }

  constructor() {
    super([
      new MeasureUnit(0, 'PCS', 1, 0, 'Pieces'),
      new MeasureUnit(1, 'CTNS', 1, 0, 'Cartons'),
      new MeasureUnit(2, 'PLTS', 1, 0, 'Pallets'),
      new MeasureUnit(3, 'DRM', 1, 0, 'Drums')
    ]);
  }
}

@Injectable()
export class ContainerSizes extends MeasureUnits {

  public get default(): MeasureUnit {
    return this.get(0);
  }

  constructor() {
    super([
      new MeasureUnit(0, "20'", 1, 0, '20'),
      new MeasureUnit(1, "40'", 1, 0, '40'),
      new MeasureUnit(2, "45'", 1, 0, '45'),
    ]);
  }
}

/** @deprecated  to search and destroy in code**/
@Injectable()
export class DocumentTypeUnits extends MeasureUnits {

  public get default(): MeasureUnit {
    return this.get(0);
  }

  constructor() {
    super([
      new MeasureUnit(0, 'MANIFEST', 1, 0, 'Manifest'),
      new MeasureUnit(1, 'IRREGULARITY', 1, 0, 'Irregularity Report'),
      new MeasureUnit(2, 'BOL', 1, 0, 'Bill Of Lading'),
      new MeasureUnit(3, 'MAWB', 1, 0, 'Master Airway Bill'),
      new MeasureUnit(4, 'POD', 1, 0, 'Proof Of Delivery'),
      new MeasureUnit(6, 'RECOVERY_ORDER', 1, 0, 'Recovery Order'),
      new MeasureUnit(7, 'CHECKED_MANIFEST', 1, 0, 'Checked Manifest'),
      new MeasureUnit(8, 'PTT', 1, 0, 'PTT'),
      new MeasureUnit(9, 'ISC', 1, 0, 'ISC'),
      new MeasureUnit(10, 'HAWB', 1, 0, 'HAWB'),
      new MeasureUnit(11, 'EMAIL', 1, 0, 'EMAIL'),
      new MeasureUnit(12, 'PMC_RECEIPTS', 1, 0, 'PMC_RECEIPTS'),
      new MeasureUnit(5, 'OTHER', 1, 0, 'Other')
    ]);
  }
}


@Injectable()
export class NoteType extends MeasureUnits {

  public get default(): MeasureUnit {
    return this.get(0);
  }

  constructor() {
    super([
      new MeasureUnit(0, 'DEFAULT', 1, 0, 'default'),
      new MeasureUnit(1, 'OVERAGE', 1, 0, 'overage'),
      new MeasureUnit(2, 'SHORTAGE', 1, 0, 'shortage'),
      new MeasureUnit(3, 'DAMAGE', 1, 0, 'damage'),
      new MeasureUnit(4, 'HAZARDOUS', 1, 0, 'hazardous'),
      new MeasureUnit(5, 'OSD', 1, 0, 'osd'),
    ]);
  }
}
