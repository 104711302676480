﻿import {Component, HostBinding, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {DocCenterComponent, DocCenterConfig} from "../doc-center.component";
import {ButtonConfig, DialogCloseEvent} from "../../oms-dialogs";

@Component({
  templateUrl: 'doc-center-dialog.component.html',
  styleUrls: ['./doc-center-dialog.component.scss'],
  selector: 'oms-doc-center-dialog'
})
export class DocCenterDialogComponent implements OnInit {

  @HostBinding('class') class = 'doc-center';

  @Input() isVisibleHistory: boolean = true;
  progress: { percentage: number } = {percentage: 0};
  @ViewChild(DocCenterComponent) docCenter: DocCenterComponent;

  config: DocCenterConfig;


  constructor(public dialogRef: MatDialogRef<DocCenterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog) {

    console.log('CONFIG', data.config);
    this.config = data.config;
  }

  ngOnInit() {
  }

  getTitle(): string {
    return 'Document Center';
  }

  closeDialog() {
  }

  get showHistory(): boolean {
    return this.config && !this.config.readonly;
  }

  public onCancel(event: DialogCloseEvent) {
    if (this.docCenter && this.docCenter.isPreview()) {
      event.canClose = false;
      this.docCenter.changeTab('documents');
    } else {
      this.dialogRef.close();
    }
  }

  getButtonCancel(): ButtonConfig {
    if (this.docCenter && this.docCenter.isPreview()) {
      return {icon: 'fa fa-times', caption: 'Back'};
    } else {
      return {icon: 'fa fa-times', caption: 'Close'};
    }
  }

}
