import {Component, Input} from '@angular/core';
import {animations} from './animations';

@Component({
  selector: 'validation',
  animations,
  template: `
    <div class="validation invalid-feedback" [@flyInOut]="state">
      <div class="message" *ngFor="let message of messages">{{message}}</div>
    </div>
  `,
  styles: [`
    :host {
      position: relative;
      z-index: 9999;
    }
    .validation {
      overflow: hidden;
    }
    .validation .message{
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `]
})
export class ValidationComponent {
  @Input() messages: Array<string>;
  get state() {
    return this.messages && this.messages.length > 0 ? 'in' : 'out';
  }
}
