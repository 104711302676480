import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  DoCheck,
  ElementRef, Inject, Injector,
  Input,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import {debounceTime, switchMap, take, takeUntil} from 'rxjs/operators';
import {LogChangesService} from '../../../../../../services/logchanges/log.changes.service';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {
  BaseColumn,
  CellButtonOptions,
  CellDisplayType,
  CustomColumn,
  DateTimeColumn,
  DateValue,
  FieldType,
  HyperLinkColumn,
  InplaceEditorSettings
} from '../columns/column-types';
import {AddressService} from '../../../../../../services/index';
import {ProblemDialogComponent} from '../../../../../../components/common/problem-dialog/problem-dialog.component';
import {MatDialog} from '@angular/material';
import {MasterPttCreatorDialogComponent} from '../../../../../../components/common/master-ptt-creator-dialog';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ProblemsHistoryInformationDialogComponent} from '../../../../../../components/common/problems-history-information-dialog';
import {Master, Address, AddressType, Order} from '../../../../models';
import {AddressDialogComponent} from '../../../address-dialog';
import {DataTableComponent} from '../data-table.component';
import {MasterService} from '../../../../../../services/master/master.service';
import {HistoryInformationDialogComponent} from '../../../../../../components/common/history-information-dialog';
import {isFunction, isNullOrUndefined} from 'util';
import {conformToMask} from 'text-mask-core/dist/textMaskCore';
import {absent, assigned, isEmptyString} from '../../../../../../_helpers/utils';
import {ColumnIds} from '../../../../../../common/column-ids';
import {AddressSearchService} from '../../../../services/address-search.service';
import {
  UldsDialogComponent,
  UldsDialogInputData
} from '../../../../../../components/common/ulds-dialog/ulds-dialog.component';
import {CbpStatusDialogComponent} from '../../../../../../components/common/cbp-status-dialog/cbp-status-dialog.component';
import {PageResult} from '../../../../models/query-models/page-result';
import {Logger} from '../../../../../../_helpers/logger';
import {ActivatedRoute, Router} from "@angular/router";
import {CbpQuickViewStatusDialogComponent} from "../../../../../../components/common/cbp-quick-view-status-dialog/cbp-quick-view-status-dialog.component";
import {GenericTableCell} from "./mawb-table-cell/generic-table-cell";
import {OmsDialogsService} from "../../../../../../components/common/oms-dialogs";
import {ISearchItemsFunction} from "../../../../../settings/util/search-items.function";
import {BaseEntityService} from "../../../../../../services/base/base-entity.service";
import {KeyCode} from "../../../common/input/ng-select-types/ng-select.types";
import {HyperLink} from "../../../../../../common/oms-types";

let editOnFocus = false;


@Component({
  selector: 'oms-data-table-cell, [oms-data-table-cell]',
  templateUrl: './data-table-cell.component.html',
  styleUrls: ['./data-table-cell.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush

})
@Logger({name: 'DataTableCell'})
export class DataTableCellComponent<C extends GenericTableCell<T>, T> implements OnInit, OnDestroy, DoCheck, OnChanges, AfterViewInit {
  DisplayType = CellDisplayType;
  FieldType = FieldType;

  public assigned = assigned;

  @ViewChild('target', {read: ViewContainerRef}) target: ViewContainerRef;
  @ViewChild('textInput') textInput: ElementRef;
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  @Input() column: BaseColumn;
  @Input() row: any;
  @Input() order: number;

//  @Input() public table: DataTableComponent;

  public editing: boolean = false;

  private searchInitialized: boolean = false;
  private differ;
  private selectInput: string;
  public selectItems: any[];
  public selectItemsSubject: Subject<any[]> = new Subject<any[]>();
  public addressSearchService: AddressSearchService;
  public addressTypes: AddressType[];
  public addressForceSelectAll: boolean;
  searchSubjectText: BehaviorSubject<string> = new BehaviorSubject('');
  private isViewInitialized: boolean = false;
  value: string;
  empty: boolean;
  editable: boolean;
  public display: any;
  rawValue: any;
  newWindow: boolean;
  hyperLink: string | any[];
  clickHandler: (row, value: any) => void;
  cellButtonClickHandler: (row, value: any) => void;
//  targetHyperLink: string;
  hasIcon: boolean;

  isHidden: boolean;
  tooltip: string;
  info: string;
  iconClass: string;
  cellButtonLink: any;
  cellButtonHidden: boolean;
  cellButtonTooltip: any;
  cellButtonIcon: any;
  private closeOnBlur: boolean = true;
  placeholder: string;
  editor: InplaceEditorSettings;
  isSelectable: boolean;
  cellDisplayType: CellDisplayType;
  private rowObject: any;
  cellClass: string;


  public localValue: any;
  public selected: any;
  public error;

  displayType: CellDisplayType;

  private cellComponentRef: ComponentRef<C>;
  private unsubscribe$ = new Subject<void>();

  public searchText: string;

  constructor(
    @Inject(DataTableComponent) public table: DataTableComponent,

    private differs: KeyValueDiffers,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private logChangesService: LogChangesService,
    private addressService: AddressService,
    public masterService: MasterService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialog: MatDialog,
    public dialogs: OmsDialogsService,
    private injector: Injector) {
      this.differ = differs.find({}).create();
  }


  ngOnChanges(changes: SimpleChanges) {
    let search = this.column.searchText  || this.table && this.table.filter && this.table.filter.search || undefined;
    let triggered = false;
    if (this.searchText !== search) {
      this.searchText = search;
      if (this.cellComponentRef) {
        if (this.cellComponentRef.instance.search !== search) {
          this.cellComponentRef.instance.row = this.row;
          this.cellComponentRef.instance.search = search;
          this.cellComponentRef.instance.updateObject();
          this.cellComponentRef.changeDetectorRef.detectChanges();
          triggered = true;
        }
      } else {
        this.updateObject();
      }
    }

    if (changes.column) {
      this.isSelectable = (this.column.handlers) && !isNullOrUndefined(this.column.handlers.editor) && !isNullOrUndefined(this.selectOptions);
      this.editor = (this.column.handlers && this.column.handlers.editor) ? this.column.handlers.editor : {};
      this.cellDisplayType = this.isSelectable ? CellDisplayType.SELECT : CellDisplayType.TEXTBOX;
    }
    if (changes.row || changes.column) {
      if (this.cellComponentRef) {
        if (!triggered) {
          this.cellComponentRef.instance.row = this.row;
          this.cellComponentRef.instance.search = search;
          this.cellComponentRef.instance.updateObject();
          this.cellComponentRef.changeDetectorRef.detectChanges();
          triggered = true;
        }
      } else {
        this.updateObject();
      }
    }
  }

  public reRender() {
    this.updateObject();
    this.cdr.markForCheck();
  }

  private updateObject() {
    this.rowObject = this.column.rowObject(this.row);

    this.rawValue = this.column.getValue(this.rowObject);
    this.editable = this.column.editable(this.rowObject);
    this.value = this.column.convert(this.rowObject, this.rawValue);
    this.empty = isNullOrUndefined(this.rawValue);

//    this.newWindow = this.column instanceof HyperLinkColumn && this.column.newWindow;
//    this.hyperLink = this.column instanceof HyperLinkColumn && this.column.handlers.link ? (<HyperLinkColumn>this.column).getHyperLink(this.rowObject, this.rawValue) : [];
//    this.targetHyperLink = this.getDownloadPdfLink(this.rowObject) as string;

    this.clickHandler = this.column.handlers.onClick;

    this.iconClass = this.column.getIconClass(this.rowObject, this.rawValue);
    this.displayType = this.column.getDisplayType(this.rowObject, this.rawValue);

    const cls = this.column.getClass(this.rowObject, this.rawValue);
    this.cellClass = isNullOrUndefined(cls) || cls.length === 0 ? 'data-table-cell' : 'data-table-cell ' + cls;

    this.hasIcon = !isNullOrUndefined(this.column.getIconClass(this.rowObject, this.rawValue));
    this.tooltip = this.column.getTooltip(this.rowObject);
    this.info = this.column.getInfo(this.rowObject, this.rawValue);


    const editor = this.editor;
    if (editor) {
      this.placeholder = (typeof editor.placeholder === 'function') ? editor.placeholder(this.row, this.rawValue) : editor.placeholder || '';
    }

    this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);

    this.display = this.column.editable && isEmptyString(this.value) ? this.table.emptyValue : this.value;

    this.cellButtonHidden = this.cellButton ? (typeof this.cellButton.hidden === 'function' ? this.cellButton.hidden(this.rowObject, this.rawValue) : !!this.cellButton.hidden) : undefined;
    this.cellButtonLink = this.cellButton ? (typeof this.cellButton.link === 'function' ? this.cellButton.link(this.rowObject, this.rawValue) : this.cellButton.link) : undefined;
    this.cellButtonTooltip = this.cellButton ? (typeof this.cellButton.tooltip === 'function' ? this.cellButton.tooltip(this.rowObject, this.rawValue) : this.cellButton.tooltip) : undefined;
    this.cellButtonIcon = this.cellButton ? (typeof this.cellButton.icon === 'function' ? this.cellButton.icon(this.rowObject, this.rawValue) : this.cellButton.icon) : undefined;
    this.cellButtonClickHandler = this.cellButton ? this.column.handlers.button.onClick : undefined;

  }

  editColumnValue(ref: ElementRef, event?: MouseEvent) {
    if (this.column.editable(this.row)) {

      if (this.column.isDialog) {
        this.openDialog(ref, event);
      } else

      if (this.column.isExternalEditor) {
        this.table.doCellClick(event, this.row, this.column);
      } else

      // Date column
      if (this.column.fieldType === FieldType.DATETIME || this.column.fieldType === FieldType.DATE) {
        let editTime: boolean = this.column.fieldType === FieldType.DATETIME;

        let actualDate;
        let estimatedDate;

        if (this.column instanceof DateTimeColumn) {
          actualDate = this.column.getActual(this.rowObject);
          estimatedDate = this.column.getEstimated(this.rowObject);
        } else {
          actualDate = this.column.getValue(this.rowObject);
        }

        this.table.editDateTime(this.column.handlers.editor.cleanable, actualDate, estimatedDate, editTime, event)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
          (newValue: DateValue) => {
            try {
              if (this.column instanceof DateTimeColumn) {
                this.table.updateColumnValue(this.rowObject, this.column, newValue.actual, this.column.actualField)
                  .pipe(takeUntil(this.unsubscribe$), take(1))
                  .subscribe(() => this.updateObject());
                this.table.updateColumnValue(this.rowObject, this.column, newValue.estimated, this.column.estimatedField)
                  .pipe(takeUntil(this.unsubscribe$), take(1))
                  .subscribe(() => this.updateObject());
              } else {
                this.table.updateColumnValue(this.rowObject, this.column, newValue.actual, this.column.field);
              }
              this.error = undefined;
            } catch (error) {
              this.editError(error);
            } finally {
              this.editing = false;
              this.cdr.markForCheck();
              this.updateObject();
            }
          }, () => {
            this.editing = false;
          });
      } else

      // CHECKBOX
      if (this.column.fieldType === FieldType.BOOLEAN) {
        let oldValue = this.value || false;
        try {
          this.table.updateColumnValue(this.rowObject, this.column, !oldValue, this.column.field);
          this.error = undefined;
        } catch (error) {
          this.editError(this.error);
        } finally {
          this.editing = false;
          this.cdr.markForCheck();
          this.updateObject();
        }
      } else {

        // Textbox or Select edit
        this.startEdit();
      }
    }
  }

  openDialog(ref: ElementRef, event?: MouseEvent) {
    switch (this.column.id) {
      case ColumnIds.MASTER_PROBLEM:
        this.dialogs.openDialog(ProblemDialogComponent, // {}) .open(ProblemDialogComponent, {
//          width: '500px',
          /*data: */{
            id: this.row.id,
            objectType: this.row.rowId.toUpperCase(),
            object: this.row,
            readonly: this.table.readonly
          }).then();
//        );
        break;
      case ColumnIds.MASTER_DOCS:
        this.dialogs.openObjectDocCenterDialog(this.row, this.table.readonly);
        break;
      case ColumnIds.MASTER_OSD:
        this.dialogs.openObjectComCenterDialog(this.row, 5, this.table.readonly);
        break;
      case ColumnIds.MASTER_HAZ:
        this.dialogs.openObjectComCenterDialog(this.row, 4, this.table.readonly);
        break;
      case ColumnIds.MASTER_COM:
        this.dialogs.openObjectComCenterDialog(this.row, 0, this.table.readonly);
        break;

      case ColumnIds.MASTER_PDF:
        this.dialog.open(MasterPttCreatorDialogComponent, {width: '90%', data: {master: this.row}});
        break;
      case ColumnIds.MASTER_GENERATE_PTT:
        this.dialog.open(MasterPttCreatorDialogComponent, {width: '90%', data: {master: this.row}});
        break;
      case ColumnIds.MASTER_CBP_STATUS:
        this.dialog.open(CbpStatusDialogComponent, {
          width: '80%',
          data: {id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: this.table.readonly}
        });
        break;
      case ColumnIds.MASTER_AIR_ULD_COUNT:
        if (this.row instanceof Master) {
          this.dialog.open(UldsDialogComponent, {
            width: '30%',
            data: {
              readonly: this.table.readonly,
              masterId: this.row.id
            } as UldsDialogInputData
          });
        }
        if (this.row instanceof Order) {
          this.dialog.open(UldsDialogComponent, {
            width: '30%',
            data: {
              readonly: this.table.readonly,
              orderId: this.row.id
            } as UldsDialogInputData
          });
        }
        break;
    }
  }


  private isEmptyHyperLink(value: HyperLink): boolean {
    return absent(value) || (value.path !== 'string' && value['length'] === 0 || !value.path);
  }

  onCellClick(ref, event) {
    // [routerLink]="hyperLink" [queryParamsHandling]="assigned(hyperLink) ? 'preserve': ''" [target]="(newWindow && hyperLink && hyperLink.length) ? '_blank' : null"
    if (this.clickHandler) {
      this.clickHandler(this.row, this.rawValue);
    } else {
      if (this.column instanceof HyperLinkColumn) {
        let openInNewWindow: boolean = this.column.newWindow === 'auto' ? event.ctrlKey : this.column.newWindow;

        let hyperLink: HyperLink = this.column.getHyperLink(this.rowObject, this.rawValue, openInNewWindow);
        if (!this.isEmptyHyperLink(hyperLink)) {
          let commands: string[] = typeof hyperLink.path === 'string' ? [hyperLink.path] : hyperLink.path;

          if (openInNewWindow) {
            // Open in New Window
            let url: string = this.router.createUrlTree(commands, {queryParams: hyperLink.params}).toString();
            window.open(url, '_blank');
          } else {
            // Navigate in Current window
            this.router.navigate(commands, {queryParamsHandling: "preserve", relativeTo: this.route}).then();
          }
        }
      }
    }

  }

  onCellButtonClick(ref, event) {
    if (this.cellButtonClickHandler) {
      this.cellButtonClickHandler(this.row, this.rawValue);
    }
  }

  generateMasterMDF(ref: ElementRef, event?: MouseEvent) {
    this.dialog.open(MasterPttCreatorDialogComponent, {width: '90%', data: {master: this.row}});
  }


  ngOnInit() {
    this.isSelectable = (this.column.handlers && this.column.handlers.editor) && !isNullOrUndefined(this.selectOptions);
    this.initSearch();
  }


  ngAfterViewInit(): void {
    if (this.isViewInitialized) {
      return;
    }
    this.isViewInitialized = true;

    if (this.column instanceof CustomColumn && this.column.component) {

      if (this.target) {
        this.target.clear();
        if (this.cellComponentRef) { this.cellComponentRef.destroy(); }
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.column.component);
        this.cellComponentRef = this.target.createComponent(componentFactory, 0, this.injector);
        this.cellComponentRef.instance.column = this.column;
        this.cellComponentRef.instance.table = this.table;
        this.cellComponentRef.instance.row = this.row;
        this.cellComponentRef.instance.search = this.searchText;
        this.cellComponentRef.instance.data = this.column.data;
        this.cellComponentRef.changeDetectorRef.detectChanges();
      }
    }
  }

/*  getDownloadPdfLink(row) {
    if (row instanceof Master) {
      return this.masterService.buildDownloadMasterUrl('.pdf', row.id);
    }

    if (row instanceof Order) {
      return ['order', {id: row.id}];
    }
  } */


  get headerIconClass(): string {
    return this.column.getHeaderIconClass();
  }


  private startEdit() {
    this.editing = true;

    this.localValue = isNullOrUndefined(this.rawValue) ? null : this.value; // !Important start edit with converted value

    if (this.isSelectable) {
      this.selected = this.convertToSelectable(this.rawValue);
      this.selectItems = this.column.handlers.editor.select.items;
      if (this.selectItemsSubject) {
        this.selectItemsSubject.next(this.selectItems);
      }
    }

    // Important: do on timeout after editors initialized!
    setTimeout(() => {
      if (this.isSelectable) {
        this.ngSelect.focus();
        this.ngSelect.open();
      } else {
        if (this.textInput) {
          this.textInput.nativeElement.focus();
          this.textInput.nativeElement.select();
        }
      }
      this.cdr.markForCheck();

    });
    this.cdr.markForCheck();
  }

  public cancelEdit() {
    if (this.editing) {
      this.editing = false;
      this.error = undefined;
      this.cdr.markForCheck();
    }
  }

  private editError(error) {
    this.error = error;
    this.table.editErrorEvent.emit(error);
  }

  public doneEdit() {
    try {
      let newValue = this.convertBack(this.localValue);
      this.table.updateColumnValue(this.rowObject, this.column, newValue, this.column.field)
        .pipe(take(1))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(object => {
          this.row = object;
          this.updateObject();
        });
      this.error = undefined;
    } catch (error) {
      this.editError(error);
    } finally {
      this.editing = false;
      this.cdr.markForCheck();
    }
  }

  onKeyDown(event) {
    if (this.editing) {
      if (event.key === 'Enter') {
        this.doneEdit();
        event.preventDefault();
      } else if (event.key === 'Escape') {
        this.cancelEdit();
        event.preventDefault();
      } else if (event.keyCode === KeyCode.Tab) {
        if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value) {
          this.localValue = this.ngSelect.itemsList.markedItem.value;
        }
        this.doneEdit();
        event.preventDefault();
      }
    }
  }


  onClear() {
    this.closeOnBlur = false;
    this.localValue = null;
    this.textInput.nativeElement.focus();
    this.cdr.markForCheck();
  }

  onBlurEditor() {
    editOnFocus = true;
    this.closeOnBlur = true;
    if (this.isSelectable) {
      this.localValue = this.convertBack(this.selected);
    }

    setTimeout(() => {
      if (this.closeOnBlur) { this.doneEdit(); }
      editOnFocus = false;
    });
  }


  isAddressColumn(): boolean {
    return this.column.fieldType === FieldType.ADDRESS;
  }

  getSearchItems(): ISearchItemsFunction<T> {
    let search = this.isSelectable ? this.selectOptions.search : null;
    if (!search) {
      return null;
    }
    if (isFunction(search)) {
      return search as ISearchItemsFunction<T>;
    }
    let baseEntityService = search as BaseEntityService<any>;
    return (searchText: string, ...addArgs: any) => baseEntityService.findByObs(searchText || '', 100, true);
  }


  get isMultiSelect(): boolean {
    return false;
  }


  /*get cellClass():string {
    const cls = this.column.getClass(this.rowObject, this.rawValue);
    return isNullOrUndefined(cls) || cls.length == 0 ? 'data-table-cell' : 'data-table-cell '+cls;
  }*/

  onDoubleClick(event) {
    if (this.editing) {
      event.stopPropagation();
    }
  }


  private cleanupMask(s: string, mask, clearMask: boolean): any {
    // remove _ guide before passing to the model
    if (mask && mask.mask) {
      s = conformToMask(s, mask.mask, {guide: false}).conformedValue;
    }

    // keep only alpha-numerical values and '.' for number support
    if (clearMask) {
      s = s.replace(/[^0-9A-Za-z.]/g, '');
    }

    return s; // this.isNumber ? ( s ? Number(s) : null): s;
  }

  private convertBack(value: any): any {
    if (typeof value === 'string') {
      value = this.cleanupMask(value, this.column.handlers.editor.mask, this.column.handlers.editor.clearMask);
    }

    return this.column.convertBack(value);
  }

  onSelectItem() {
    this.localValue = this.convertBack(this.selected);
    this.doneEdit();

    /*    if (this.isAddressColumn() && this.addressTypes && this.localValue instanceof Address) {
          if (!this.localValue.supportsAny(this.addressTypes)) {
            this.addressService.updateAddressTypes(this.localValue, this.addressTypes);
          }
        } */
  }

  isSelected(item): boolean {
    return item.id === this.selected;
  }

  onSelectKeyUp(event: KeyboardEvent) {
    const search = (<HTMLInputElement>event.target).value.trim();
    if (search !== this.selectInput) {
      this.selectInput = search;
      this.searchSubjectText.next(search);
      // this.doSearch();
    }
  }

  onSelectOpen() {
    if (this.isSelectable) {
      this.initSearch();

      if (this.selectItemsSubject && this.column.handlers.editor.select.items) {
        this.selectItemsSubject.next(this.column.handlers.editor.select.items);
      }
      this.searchSubjectText.next(this.selectInput);
    }
  }


  initSearch() {
    if (this.searchInitialized) {
      return;
    }

    this.searchInitialized = true;

    if (this.isSelectable) {
      this.addressTypes = this.column.handlers.editor.select.addressTypes;

      if (this.isAddressColumn()) {
        this.addressSearchService = this.addressService.getNewSearchService(this.addressTypes, 15);
        this.selectItemsSubject = this.addressSearchService.subjAddresses;
      } else {
        this.selectItemsSubject = new Subject<any[]>();
      }
    }

/*    if (this.column.handlers && this.column.handlers.editor && this.column.handlers.editor.searchBy) {
      this.searchSubjectText.pipe(
        debounceTime(300),
        switchMap(search => search ? this.column.handlers.editor.searchBy(search, this.rowObject, this.addressForceSelectAll) : of([]) )
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(items => this.selectItemsSubject.next(items));
      // TODO deprecated. use  this.editor.searchBy
    }

    else*/

    if (this.addressSearchService) {
      this.searchSubjectText.pipe(
        debounceTime(300),
        switchMap(search => search ? this.searchServiceFunction(search) : of([]) )
      ).pipe(takeUntil(this.unsubscribe$))
        .subscribe(items => this.addressSearchService.subjAddresses.next(items));
    } else

    if (this.getSearchItems()) {
      this.searchSubjectText.pipe(
        debounceTime(300),
        switchMap(search => search ? this.getSearchItems()(search || '', 15) : of(PageResult.buildEmpty()))
      ).pipe(takeUntil(this.unsubscribe$))
        .subscribe(response => this.selectItemsSubject.next(response.content));
    }
  }

  searchServiceFunction(search): Promise<Address[]> {
    if (this.column.field === 'addressDelivery' && this.rowObject instanceof Order) {
      return this.addressSearchService.searchCustomerDeliveryLocationsPromise(search || '', this.rowObject.customer, 15, this.addressForceSelectAll);
    } else {
      return this.addressSearchService.searchPromise(search || '', 15, this.addressForceSelectAll);
    }
  }

  get selectOptions() {
    return this.column.handlers.editor.select;
  }

  convertToSelectable(value: any): any {
    return this.isSelectable && this.selectOptions.converter ? this.selectOptions.converter(value) : value;
  }

  searchSelectable(search: string, obj: any): boolean {
    return true;
//    return (this.selectable && this.selectOptions.searchFn) ? this.selectOptions.searchFn(search, obj) : true;
  }

  onFocusColumn(cell, event) {
    if (editOnFocus) {
      this.editColumnValue(cell, event);
    }
  }


  onRightClick(event: Event) {
    if (this.column.history.logChanges) {
      event.stopPropagation();
      let columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;

      let params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField, this.column);

      if (this.column.history.methodForExecution) {
        this[this.column.history.methodForExecution](params);
      } else {
        this.executeLogChanges(params, this.column.history.logConverter);
      }
    } else if (this.column.history.logChangesWithCbpStatuses) {
      let columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;
      if (typeof columnField !== "string") {
        let fieldKeys = Object.keys(columnField);
        columnField = columnField[fieldKeys[0]];
      }
      let params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField);

      this.dialog.open(CbpQuickViewStatusDialogComponent, { width: '80%', height: '70%', data: { id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: true,
          logParams: params, column: this.column, convertByBaseType: this.column.history.logConverter} });
    }

    return false;
  }

  executeMasterProblemLogChanges(params: any[]) {
    this.logChangesService.getMasterProblemsInfo(params[0].id).then(objectLogChanges => {
      this.dialog.open(ProblemsHistoryInformationDialogComponent, {
        width: '80%',
        data: {objectLogChanges: objectLogChanges, column: this.column, convertByBaseType: true}
      });
    });
  }

  executeLogChanges(logParams: any[], convertByBaseType) {
    this.dialogs.openDialog(HistoryInformationDialogComponent,
      {logParams: logParams, column: this.column, convertByBaseType: convertByBaseType},
      {}).then();
/*      .open(HistoryInformationDialogComponent, {
      width: '80%',
      data: {logParams: logParams, column: this.column, convertByBaseType: convertByBaseType}
    }); */
  }

  onCreateNewItem(event) {
    if (this.column.fieldType === FieldType.ADDRESS) {
      this.onCreateNewAddress(event);
    } else {
      this.selectOptions.createNewHandler(this.row)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res) {
            this.selected = res;
            this.onSelectItem();
          } else {
            this.cancelEdit();
          }
        });
    }
  }

  // Used only for  column.fieldType === FieldType.ADDRESS
  onCreateNewAddress(event) {
    let address = new Address();
    address.types = this.addressTypes;

    const dialogRef = this.dialog.open(AddressDialogComponent, {
      width: 'auto',
      data: {address: address, disabledTypes: this.addressTypes}
    });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (res) {
          this.selected = res.res;
          this.onSelectItem();
        } else {
          this.cancelEdit();
        }
      });
  }

  selectGroupBy(item) {
    return item instanceof Address ? (item.top ? 'Top Addresses' : 'Other') : null;
  }

  onRemoveFromTop(event: MouseEvent, item: Address) {
    event.stopPropagation();
    if (this.column.field === 'addressDelivery' && this.rowObject instanceof Order) {
      this.addressService.deleteCustomerDeliveryLocation(this.rowObject.customer, item);
      // this.doSearch();
      this.searchSubjectText.next(this.selectInput);
    }
  }

  ngOnDestroy(): void {
    this.table = undefined;
    this.cancelEdit();
    if (this.target) {
      this.target.clear();
    }
    if (this.cellComponentRef) {
      this.cellComponentRef.destroy();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngDoCheck(): void {
    /*    if (this.differ.diff({
              value:this.rawValue,
              hidden: this.column.isHidden(this.row, this.rawValue),
              editable: this.column && this.column.editable(this.row)
        })) {
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }*/
  }


  get cellButton(): CellButtonOptions {
    return this.column.handlers.button;
  }


  get cellButtonRefTarget(): any {
    return this.cellButton && this.cellButton.newPage ? '_blank' : undefined;
  }

  public refresh() {
    // this.cdr.markForCheck();
  }

  getState(address: Address) {
    if (isNullOrUndefined(address.usaState)) {
      return "";
    }
    return ", " + address.usaState.name;
  }

  getWithComa(data: any) {
    return isNullOrUndefined(data) ? "" : ", " + data;
  }

  isNotNull() {
    return !isNullOrUndefined(this.rawValue) && this.value !== "N/A";
  }
}
