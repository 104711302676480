import {Labeled} from "../../../../common/oms-types";
import {Type} from "class-transformer";
import {ComCenter} from "../comcenter/com.center";
import {isNullOrUndefined} from "util";
import {ProblemType} from "./problem-type";

export class Problem implements Labeled {
  id: number;
  problemType: ProblemType;
  description: string;
  @Type(() => Date)
  dateResolved: Date;
  systemUserName: string;
  comCenter: ComCenter;
  createdAt: Date;

  constructor(problemType: ProblemType, description) {
    this.problemType = problemType;
    this.description = description;
  }

  get label() {
    return this.description;
  }

  get isResolved(): boolean {
    return !isNullOrUndefined(this.dateResolved);
  }

  set isResolved(value: boolean) {
    this.dateResolved = value ? new Date() : null;
//    console.log(this);
  }

}
