import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {RestService} from "../../../../services/rest.service";
import {HttpUtilsService} from "../../../../services/http-utils.service";
import {plainToClass} from "class-transformer";
import {BaseEntityService} from "../../../../services/base/base-entity.service";
import {MasterFsr} from "../../models/master.fsr";

@Injectable()
export class MasterFsrService extends BaseEntityService<MasterFsr> {
  apiUrl = '/oms/master-fsr/';
  classType = MasterFsr;

  public masterFsrSubject = new Subject<MasterFsr[]>();

  public constructor(public httpRestService: RestService,  public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  findAllByMasterId(id) {
    this.httpRestService.get<MasterFsr[]>(this.apiUrl + 'find-by-id', id).subscribe(masterFsrData => {
      this.masterFsrSubject.next(plainToClass(MasterFsr, masterFsrData as Object[]));
    });
  }
}
