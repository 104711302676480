<as-split unit="percent" direction="horizontal" [gutterDblClickDuration]="10" (dragEnd)="dragEnd($event)">
  <as-split-area [size]="widthFirstPanel">
    <oms-base-page [title]="title" [subtitle]="subTitle">


      <ng-container page-header>
        <div class="btn-toolbar pull-left">
          <a *ngIf="master && !master.isNew()" class="refresh" (click)="refresh()">
            <i class="fa fa-refresh refresh-button" [ngClass]="{'refresh-button-loading':refreshLoading}"></i>
          </a>
        </div>
        <div class="btn-toolbar pull-left main-toolbar-actions">
          <button class="btn btn-primary" (click)="onSave(false)" *ngIf="!clientUser">Save</button>
          <button class="btn btn-primary" (click)="onSave(true)" *ngIf="!clientUser">Save & Back</button>
          <button class="btn btn-primary" (click)="location.back();">Back</button>

          <button style="margin-left: 30px" *ngIf="isDeleteAvailable && master && !master.isNew() && !master.isDeleted()"
                  class="btn btn-primary" (click)="onDelete();" [disabled]="!master || master.isNew()">
            <i class="fa fa-trash"></i>Delete&hellip;
          </button>

          <button style="margin-left: 30px" *ngIf="isDeleteAvailable && master && master.isDeleted()" class="btn btn-warning"
                  (click)="onRestore();" [disabled]="!master || master.isNew()">
            <i class="fa fa-refresh"></i>Restore&hellip;
          </button>

        </div>
      </ng-container>

      <ng-container page-body>
        <perfect-scrollbar style="max-height: 80vh;" [config]="{suppressScrollX: true}">
          <oms-master-import-air [master]="master" [readonly]="clientUser"
                                 (masterChangeEvent)="masterChanged($event)"
                                 (masterUpdatedEvent)="masterUpdated($event)"
                                 *ngIf="master"></oms-master-import-air>
        </perfect-scrollbar>
      </ng-container>

      <ng-container page-bottom>
        <div class="btn-toolbar pull-right bottom-navigation-buttons">
          <button class="btn btn-primary" (click)="onSave(false)" *ngIf="!clientUser">Save</button>
          <button class="btn btn-primary" (click)="onSave(true)" *ngIf="!clientUser">Save & Back</button>
          <button class="btn btn-primary" (click)="location.back();">Back</button>

          <button style="margin-left: 30px" *ngIf="isDeleteAvailable" class="btn btn-primary" (click)="onDelete();"
                  [disabled]="!master || master.isNew()">
            <i class="fa fa-trash"></i>Delete&hellip;
          </button>
        </div>
      </ng-container>

    </oms-base-page>
  </as-split-area>

  <as-split-area [size]="widthSecondPanel">
    <manifest-create-panel [(manifest)]="manifestForRightPanel" [mergeToExisting]="true"
                           (manifestChange)="onManifestChange($event)"
                           (selectedChange)="onDispatchListSelectionChange($event)"
                           (created)="onCreatedEvent($event)"></manifest-create-panel>
  </as-split-area>
</as-split>
