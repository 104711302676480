import {Filter} from './filter';
import {BaseEntity} from "./base-entity";

export class FilterContainer extends BaseEntity {
  name: string;
  filters: Filter[];

  public static createDefault(): FilterContainer {
    let filterContainer = new FilterContainer();
    filterContainer.filters = [];
    return filterContainer;
  }
}
