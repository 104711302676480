import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  KeyValueDiffers,
  SimpleChanges,
} from '@angular/core';
import {MasterStatus, MasterStatusId, Order, User} from '../../../../modules/shared/models';
import {MasterService} from '../../../../services/master/master.service';
import {OmsColumns} from '../../../../common/oms-columns.service';
import {OmsAlertsService} from '../../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {AddressService, AuthService, OrdersService} from '../../../../services';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterProcessor} from '../../../../common/master-processor';
import {FileUploadService} from '../../../../services/file.upload.service';
import {FilterSearchColumn} from '../../../../modules/shared/models/filter.search.column';
import {AbstractMasterTable} from '../abstract-master-table';
import {BaseColumn} from '../../../../modules/shared/components/base/data-table/columns/column-types';
import {FilterPageRequest} from '../../../../modules/shared/models/filter.page.request';
import {PageResult} from '../../../../modules/shared/models/query-models/page-result';
import {Observable} from 'rxjs';
import {ColorItem} from '../../../../components/common/color-search-select/color-item';
import {SearchSortRequest} from '../../../../modules/shared/models/search.sort.request';
import {TabInfo} from '../tabs-type';
import {OmsDialogsService} from '../../../../components/common/oms-dialogs';
import {NumberUtils} from '../../../../_helpers/number.utils';
import {isNullOrUndefined} from "util";
import {UserService} from "../../../../modules/shared/services/user.service";
import {LoggedUser} from "../../../../modules/shared/models/logged-user";
import { UserRoleType } from "../../../../modules/shared/models/user-role.type";

export class TabFilter {
  id: string;
  label: string;
  additionalConditions?: string[];
  statuses: MasterStatusId[];
  btnHidden?: boolean = false;
  description?: string;
}

export interface ExtendedMasterStatus extends MasterStatus {
  shortName: string;
}

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersTableComponent extends AbstractMasterTable<Order> {

  @Input() public tabInfo: TabInfo;
  @Input() public isVisible: boolean;
  @Input() columnFunction: string;
//  @Input() public mastersMenu: ContextMenuComponent;
  @Input() public modes: string[];
  @Input() public toolbar: HTMLDivElement;
  @Input() public colorStatusFilter: ColorItem;
  @Input() public namedCondition: string = 'NOT_GENERIC';
  @Input() public baseColumns: BaseColumn[] = [];

  public filterPageRequest: FilterPageRequest = new FilterPageRequest(1, 100, null, new SearchSortRequest('id', false), []);


  constructor(
    protected valueDiffers: KeyValueDiffers,
    protected cdr: ChangeDetectorRef,
    protected alerts: OmsAlertsService,
    protected masterProcessor: MasterProcessor,
    protected _columnsService: OmsColumns,
    protected addressService: AddressService,
    protected _masterService: MasterService,
    public spinner: NgxSpinnerService,
    protected fileUploadService: FileUploadService,
    protected _ordersService: OrdersService,
    protected dialogsService: OmsDialogsService,
    protected userService: UserService,
    protected authService: AuthService) {
    super(valueDiffers, cdr, alerts, masterProcessor, addressService, _masterService, fileUploadService, _ordersService, spinner, dialogsService, userService, authService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.baseColumns && this.baseColumns && this.baseColumns.length) {
      this.columns = this.baseColumns;
    } else if (changes.columnFunction && this.columnFunction) {
      let user: LoggedUser = this.authService.getCurrentUser();
      this.columns = this.createColumns(user.canCreateCarrier);
    }
    super.ngOnChanges(changes);
  }

  protected loadItemsByFilter(request: FilterPageRequest): Observable<PageResult<Order>> {
    request.modesFilter = this.modes;
    return this._ordersService.findByFilters(request, this.active);
  }

  protected createColumns(isCanCreateCarrier: boolean): BaseColumn[] {
    let hasBillingApproveRole = this.authService.hasBillingApprove();
    let items = this.tabInfo.statuses ? MasterStatus.items.filter(item => this.tabInfo.statuses.includes(item.id)) : [];
    let config = this._columnsService.buildOrderConfig(this.tabInfo, items, hasBillingApproveRole, this.modes, isCanCreateCarrier);
    return isNullOrUndefined(this.columnFunction)
      ? this._columnsService.buildOrderColumnsByTab(this.tabInfo, isCanCreateCarrier)
      : this._columnsService[this.columnFunction](config);
  }

  applyFilter(filterPageRequest?: FilterPageRequest): void {
    if (this.showNonAmsMasters) {
      this.changeFilterValue(this.filterPageRequest, 'shipmentContents.shipment.masterShipments.master.nonAMS', '1');
    } else if (this.showAmsMasters) {
      this.changeFilterValue(this.filterPageRequest, 'shipmentContents.shipment.masterShipments.master.nonAMS', '0');
    } else {
      this.removeFilterValue(this.filterPageRequest, 'shipmentContents.shipment.masterShipments.master.nonAMS');
    }

    if (this.namedCondition) {
      filterPageRequest.addNamedCondition(this.namedCondition);
    }
    if (this.availableStatuses.length) {
      let statusColumn = this.filterPageRequest.filterByColumns.find(column => column.field === 'status');
      const statusesIds = this.selectedStatuses.map(selectedStatus => '' + selectedStatus.id);
      if (!statusColumn) {
        this.filterPageRequest.filterByColumns.push(new FilterSearchColumn('status', null, statusesIds, null));
      } else {
        statusColumn.multipleValues = statusesIds;
      }
    }
  }

  onRestoreSelected() {
  }

  public updateTotalValues(items: Order[]) {
    let totalPcs = 0;
    let totalHu = 0;
    let totalWeight = 0;
    for (let i = 0; i < items.length; i++) {
      if (NumberUtils.isNumber(items[i].pieces)) {
        totalPcs += items[i].pieces;
      }
      if (NumberUtils.isNumber(items[i].hu_)) {
        totalHu += items[i].hu_;
      }
      if (NumberUtils.isNumber(items[i].weight)) {
        totalWeight += items[i].weight;
      }
    }
    this.totalPcs = totalPcs;
    this.totalHu = totalHu;
    this.totalWeight = totalWeight;
  }

  resetRequest() {
    this.filterPageRequest = new FilterPageRequest(1, 100, null, new SearchSortRequest('id', false), []);
  }

  getDefaultSortableColumn(): string {
    return (this.filterPageRequest ? String(this.filterPageRequest.sort.field) : null) || "id";
  }
}
