var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CustomerService } from '../../../services/customer.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Address, Customer } from '../../../modules/shared/models';
import { CustomerGroupService } from '../../../services/customer.group.service';
import { NgSelectSearchParams } from '../../../modules/settings/util/ng-select-search-params';
import { CustomerGroup } from '../../../modules/shared/models/customer.group';
import { AddressService } from '../../../modules/shared/services/address.service';
import { AbstractEntityDialogComponent } from '../../../modules/settings/pages/abstract-entity-dialog.component';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { flatMap } from 'rxjs/operators';
import { CustomerEmail } from "../../../modules/shared/models/customer-email";
import { isNullOrUndefined } from "util";
var CustomerDialogComponent = /** @class */ (function (_super) {
    __extends(CustomerDialogComponent, _super);
    function CustomerDialogComponent(dialogRef, data, fb, customerService, customerGroupService, addressService, alerts) {
        var _this = _super.call(this, dialogRef, customerService, alerts) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.fb = fb;
        _this.customerService = customerService;
        _this.customerGroupService = customerGroupService;
        _this.addressService = addressService;
        _this.alerts = alerts;
        _this.customer = new Customer();
        _this.emails = Object.values(CustomerEmail);
        return _this;
    }
    CustomerDialogComponent.prototype.ngOnInit = function () {
        this.viewLoading = true;
        this.customerGroupSearch = new NgSelectSearchParams(this.customerGroupService);
        this.addressSearch = new NgSelectSearchParams(this.addressService);
        this.customer = Object.assign(this.customer, this.data.customer);
        this.createForm();
        this.viewLoading = false;
    };
    CustomerDialogComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            name: [this.customer.name, Validators.required],
            address: [this.customer.address],
            customerGroup: [this.customer.customerGroup, Validators.required],
            email: [this.customer.emails],
            createBOL: [this.customer.createBOL],
            sendPODToEmails: [this.customer.sendPODToEmails]
        });
    };
    CustomerDialogComponent.prototype.getTitle = function () {
        if (this.customer.id > 0) {
            return 'Edit Customer';
        }
        return 'New Customer';
    };
    CustomerDialogComponent.prototype.prepare = function () {
        var controls = this.form.controls;
        var _customer = this.customer;
        _customer.name = controls['name'].value;
        _customer.customerGroup = controls['customerGroup'].value;
        _customer.address = controls['address'].value;
        var emailValues = controls['email'].value;
        if (emailValues) {
            var updateCustomerEmails_1 = _customer.emails;
            _customer.emails = [];
            emailValues.forEach(function (emailValue, index) {
                if (!isNullOrUndefined(emailValue)) {
                    if (updateCustomerEmails_1 && updateCustomerEmails_1.length > index) {
                        var customerEmail = updateCustomerEmails_1[index];
                        customerEmail.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
                        _customer.emails.push(customerEmail);
                    }
                    else {
                        var email = new CustomerEmail();
                        email.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
                        _customer.emails.push(email);
                    }
                }
            });
        }
        else {
            _customer.emails = [];
        }
        _customer.createBOL = controls['createBOL'].value;
        _customer.sendPODToEmails = controls['sendPODToEmails'].value;
        return _customer;
    };
    CustomerDialogComponent.prototype.update = function (item) {
        var _this = this;
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        console.log("UPDATE", item);
        var cg;
        if (typeof item.customerGroup === 'string') {
            cg = new CustomerGroup();
            cg.name = item.customerGroup;
        }
        else
            cg = Object.assign(new CustomerGroup(), item.customerGroup);
        //    let cg: CustomerGroup = typeof item.customerGroup === 'string' ? {name: item.customerGroup} : Object.assign(new CustomerGroup(), item.customerGroup);
        this.customerGroupService.save(cg)
            .pipe(flatMap(function (customerGroup) {
            item.customerGroup = customerGroup;
            return _this.customerService.save(item);
        }))
            .subscribe(function (res) {
            _this.viewLoading = false;
            _this.dialogRef.close({ res: res, isEdit: !item.isNew() });
        }, function (error) {
            _this.viewLoading = false;
            _this.alerts.danger(error);
        });
    };
    return CustomerDialogComponent;
}(AbstractEntityDialogComponent));
export { CustomerDialogComponent };
