import {Injectable} from '@angular/core';
import {BaseService} from './base/base.service';
import {RestService} from './rest.service';
import {HttpUtilsService} from './http-utils.service';
import {plainToClass} from 'class-transformer';
import {Receiving} from "../modules/shared/models/warehouse/receiving";
import {HttpParams} from "@angular/common/http";


@Injectable()
export class ReceivingService extends BaseService {
  protected apiUrl = '/oms/receiving/';

  public constructor(public httpRestService: RestService,  public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

/*
  public update(data: Receiving): Promise<Master | any> {
    return this.put('update', data).toPromise().then((response) => plainToClass(Master, <Object>response));
  }
*/

  public updateMapping(data): Promise<any> {
    return this.put('updateMapping', data).toPromise();
  }

  public getAllByMasterId(masterId: number): Promise<any> {
    return this.get('getAllByMasterId/' + masterId).toPromise().then((response) => plainToClass(Receiving, <Object[]>response));
  }

  /*
  public savePictureByOrderId(id: number, imageAsData: string[]): Promise<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(this.apiUrl + 'savePicture/' + id, imageAsData, httpHeader).toPromise();
  }
   */

  savePicture(orderId: number, masterId: number, docType: string, imageAsData: string[]): Promise<any> {
    let params = new HttpParams();
      if (orderId) {
        params = params.set('orderId', orderId.toString());
      }
      if (masterId) {
        params = params.set('masterId', masterId.toString());
      }
    params = params.set('docType', docType);

    return this.httpRestService.put<any>(this.apiUrl + `savePicture`, imageAsData, this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

}
