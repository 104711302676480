var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { FormBuilder, FormGroup, NgModel } from '@angular/forms';
import { BaseInputComponent } from '../../../modules/shared/components/base/base-input/base-input.component';
import { isNullOrUndefined } from "util";
import { convertDateTime, convertMawbNumber } from "../../../modules/shared/services/oms-converters.service";
import { MasterService } from "../../../services/master/master.service";
import { Size } from '../../../common/oms-types';
import { FileUploadService } from "../../../services/file.upload.service";
import { Router } from "@angular/router";
import { toFixed } from '../../../_helpers/utils';
import { UldNumberValidator } from "../../../common/validators/uld-number.validator";
import { OmsConstants } from "../../../common/oms-constants.service";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
var MasterPttCreatorDialogComponent = /** @class */ (function (_super) {
    __extends(MasterPttCreatorDialogComponent, _super);
    /*
      subCustomer: boolean = false;
    */
    function MasterPttCreatorDialogComponent(dialogRef, validators, asyncValidators, data, fb, alerts, masterService, router, fileUploadService) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.fb = fb;
        _this.alerts = alerts;
        _this.masterService = masterService;
        _this.router = router;
        _this.fileUploadService = fileUploadService;
        _this.readonly = false;
        _this.Size = Size;
        _this.isSarcona = false;
        _this.hasFormErrors = false;
        _this.loadingAfterSubmit = false;
        return _this;
    }
    MasterPttCreatorDialogComponent.prototype.ngOnInit = function () {
        this.master = this.data.master;
        this.readonly = this.data.readonly;
        this.load = this.data.load;
        this.pcs = this.data.pcs || this.data.master.pieces;
        this.weight = this.data.weight || this.data.master.weight;
        this.isSarcona = !!this.master.addressCfs && (this.master.addressCfs.id === OmsConstants.SARCONA_CFS_ID);
        this.createForm();
        this.initCfsProperties(this.isSarcona);
        if (this.data.generatePTTAndClose) {
            this.onSubmit();
        }
    };
    Object.defineProperty(MasterPttCreatorDialogComponent.prototype, "driverName", {
        get: function () {
            return this.load && this.load.driver ? this.load.driver.fullName : "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterPttCreatorDialogComponent.prototype, "truckNumber", {
        get: function () {
            return this.load && this.load.truck ? " TRK#:" + this.load.truck.number : "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterPttCreatorDialogComponent.prototype, "trailerNumber", {
        get: function () {
            return this.load && this.load.trailer ? " TRL#:" + this.load.trailer.number : "";
        },
        enumerable: true,
        configurable: true
    });
    MasterPttCreatorDialogComponent.prototype.initCfsProperties = function (isSarcona) {
        var cfsProperties = OmsConstants.CFS_PROPERTIES[isSarcona ? 1 : 0];
        var controls = this.form.controls;
        controls['containerSectionName'].setValue(cfsProperties.contactName);
        controls['address1'].setValue(cfsProperties.address1);
        controls['address2'].setValue(cfsProperties.address2);
        controls['address3'].setValue(cfsProperties.address3);
    };
    MasterPttCreatorDialogComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            forwarder: [this.getFieldIfObjectNotNull(this.master.freightForwarder, ["name"])],
            customer: [this.master.customerNameBasedOnOrders],
            other: [this.driverName + this.truckNumber + this.trailerNumber],
            airportOfDestination: [this.getFieldIfObjectNotNull(this.master.airport, ["iataCode"])],
            airline: [this.getFieldIfObjectNotNull(this.master.airline, ["name"])],
            flight: [this.master.flightNumber],
            arrivalDate: [convertDateTime(this.arrivalOrEstDate)],
            airportOfOrigin: [this.getFieldIfObjectNotNull(this.master.masterAir, ["originalAirport", "iataCode"])],
            containerSectionName: [""],
            address1: [""],
            address2: [""],
            address3: [""],
            mawb: [convertMawbNumber(this.getFieldIfObjectNotNull(this.master, ["masterAir", "mawbNumber"]))],
            totalAMSPieces: [this.master.pieces],
            totalKG: [toFixed(this.master.weight, 1)],
            countOfHawbs: [this.master.orders.length],
            issuer: [""],
            comment: [""],
            /*receivedAtAirlineConfirmation: [""],
            receivedAtCfsConfirmation: [""],*/
            recoveryAtPersonName: [""],
            recoveryAtTrackAndTrailer: [""],
            recoveryAtDateAndTime: [""],
            recoveryAtPieces: [""],
            recoveryAtNotation: [""],
            receivedAtPersonName: [""],
            receivedAtTrackAndTrailer: [""],
            receivedAtDateAndTime: [""],
            receivedAtPieces: [""],
            receivedAtNotation: [""],
        });
        for (var i = 0; i < MasterPttCreatorDialogComponent.MAX_ULD_FIELDS; i++) {
            var uldValue = this.getULDFromList(this.master.ulds, i);
            this.form.setControl('uldInfo' + (i + 1), this.fb.control(uldValue, UldNumberValidator.validateULDNumber));
        }
    };
    MasterPttCreatorDialogComponent.prototype.getULDFromList = function (ulds, index) {
        if (ulds.length > index) {
            return ulds[index].uldNumber;
        }
        return '';
    };
    Object.defineProperty(MasterPttCreatorDialogComponent.prototype, "arrivalOrEstDate", {
        get: function () {
            var arrivalDateAct = this.master.dateArrivalAct;
            return !isNullOrUndefined(arrivalDateAct) ? arrivalDateAct : this.master.dateArrivalEst;
        },
        enumerable: true,
        configurable: true
    });
    MasterPttCreatorDialogComponent.prototype.getFieldIfObjectNotNull = function (object, items) {
        if (isNullOrUndefined(object)) {
            return "";
        }
        if (items.length > 1) {
            return this.getFieldIfObjectNotNull(object[items.shift()], items);
        }
        return object[items[0]];
    };
    MasterPttCreatorDialogComponent.prototype.getTitle = function () {
        return 'Master PTT Creator';
    };
    MasterPttCreatorDialogComponent.prototype.isControlInvalid = function (controlName) {
        var control = this.form.controls[controlName];
        return control.invalid && control.touched;
    };
    MasterPttCreatorDialogComponent.prototype.onSubmit = function () {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        var controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(function (controlName) {
                return controls[controlName].markAsTouched();
            });
            this.hasFormErrors = true;
            return;
        }
        var masterPTT = this.prepare();
        console.log('PREPARE PTT', masterPTT);
        this.create(masterPTT, true);
    };
    MasterPttCreatorDialogComponent.prototype.prepare = function () {
        var controls = this.form.controls;
        var masterPTT = {};
        masterPTT.id = this.master.id;
        masterPTT.forwarder = controls['forwarder'].value;
        masterPTT.customer = controls['customer'].value;
        masterPTT.other = controls['other'].value;
        masterPTT.airportOfDestination = controls['airportOfDestination'].value;
        masterPTT.airline = controls['airline'].value;
        masterPTT.flight = controls['flight'].value;
        masterPTT.arrivalDate = controls['arrivalDate'].value;
        masterPTT.airportOfOrigin = controls['airportOfOrigin'].value;
        masterPTT.address1 = controls['address1'].value;
        masterPTT.address2 = controls['address2'].value;
        masterPTT.address3 = controls['address3'].value;
        masterPTT.isSarcona = this.isSarcona;
        var uldNumbers = [];
        for (var i = 0; i < MasterPttCreatorDialogComponent.MAX_ULD_FIELDS; i++) {
            var uldValue = controls['uldInfo' + (i + 1)].value;
            if (uldValue) {
                uldNumbers.push(uldValue);
            }
        }
        masterPTT.uldNumbers = uldNumbers;
        masterPTT.mawb = controls['mawb'].value;
        masterPTT.totalAMSPieces = controls['totalAMSPieces'].value;
        masterPTT.totalKG = controls['totalKG'].value;
        masterPTT.countOfHawbs = controls['countOfHawbs'].value;
        masterPTT.issuer = controls['issuer'].value;
        masterPTT.comment = controls['comment'].value;
        masterPTT.containerSectionName = controls['containerSectionName'].value;
        var receivedAtAirlineConfirmation = {};
        receivedAtAirlineConfirmation.personName = controls['recoveryAtPersonName'].value;
        receivedAtAirlineConfirmation.trackAndTrailer = controls['recoveryAtTrackAndTrailer'].value;
        receivedAtAirlineConfirmation.dateAndTime = controls['recoveryAtDateAndTime'].value;
        receivedAtAirlineConfirmation.pieces = controls['recoveryAtPieces'].value;
        receivedAtAirlineConfirmation.notation = controls['recoveryAtNotation'].value;
        masterPTT.receivedAtAirlineConfirmation = receivedAtAirlineConfirmation;
        var receivedAtCfsConfirmation = {};
        receivedAtCfsConfirmation.personName = controls['receivedAtPersonName'].value;
        receivedAtCfsConfirmation.trackAndTrailer = controls['receivedAtTrackAndTrailer'].value;
        receivedAtCfsConfirmation.dateAndTime = controls['receivedAtDateAndTime'].value;
        receivedAtCfsConfirmation.pieces = controls['receivedAtPieces'].value;
        receivedAtCfsConfirmation.notation = controls['receivedAtNotation'].value;
        masterPTT.receivedAtCfsConfirmation = receivedAtCfsConfirmation;
        return masterPTT;
    };
    MasterPttCreatorDialogComponent.prototype.create = function (masterPPT, closeOnSuccess) {
        var _this = this;
        this.loadingAfterSubmit = true;
        this.loadingMessage = "Generating Document...";
        this.masterService.createMasterPTT(masterPPT).subscribe(function (res) {
            _this.loadingMessage = undefined;
            _this.generatedDocumentInfo = res;
            var fileURL = _this.buildShowUrl(_this.generatedDocumentInfo);
            window.open(fileURL, '_blank');
            if (closeOnSuccess) {
                _this.dialogRef.close({ res: res, isEdit: false });
            }
        }, function (error) {
            _this.loadingMessage = undefined;
            _this.alerts.error(error);
        });
    };
    MasterPttCreatorDialogComponent.prototype.buildDownloadUrl = function (generatedDocumentInfo) {
        return this.fileUploadService.buildDownloadFileUrl(generatedDocumentInfo.id);
    };
    MasterPttCreatorDialogComponent.prototype.buildShowUrl = function (generatedDocumentInfo) {
        return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
    };
    MasterPttCreatorDialogComponent.prototype.buildMasterUrl = function () {
        this.dialogRef.close();
        this.router.navigate(['/home/masters/master', { id: this.master.id }]).then();
    };
    MasterPttCreatorDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    MasterPttCreatorDialogComponent.prototype.getErrorMessage = function (controlName) {
        var control = this.form.controls[controlName];
        return control.errors.uld;
    };
    MasterPttCreatorDialogComponent.MAX_ULD_FIELDS = 16;
    return MasterPttCreatorDialogComponent;
}(BaseInputComponent));
export { MasterPttCreatorDialogComponent };
