import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from "@angular/router";
import {WizardHeader} from "../menu/wizard.header";

@Injectable()
export class WizardHeaderConfigService {
  public configModel: WizardHeader = new WizardHeader();
  public onMenuUpdated$: Subject<WizardHeader> = new BehaviorSubject<WizardHeader>(this.configModel.config);

  constructor(private router: Router) { }

}
