var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataTableComponent } from '../../../shared/components/base/data-table/data-table.component';
import { OmsAlertsService } from '../../../shared/components/oms-alerts/oms-alerts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterPageRequest } from '../../../shared/models/filter.page.request';
import { OrderDispatch } from '../../../shared/models/dispatch/order-dispatch';
import { DispatchService } from '../../../shared/services/dispatch/dispatch.service';
import { OmsDialogsService } from '../../../../components/common/oms-dialogs';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadService } from '../../../../services/file.upload.service';
import { ColumnType } from "../../../../common/column-type";
import { OrdersService } from '../../../../services';
import { MatDialog } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { AddressMapInfoDialogComponent } from '../../../shared/components/address-map-info-dialog/address-map-info-dialog.component';
import { NumberUtils } from '../../../../_helpers/number.utils';
import { take, takeUntil, tap } from 'rxjs/operators';
import { DriverService } from "../../../../services/driver-service";
import { Subject } from "rxjs";
import { UserService } from "../../../shared/services/user.service";
import { FilterSearchColumn } from "../../../shared/models/filter.search.column";
import { AbstractComponent } from "../../../../common/component/abstract.component";
import { SearchSortRequest } from "../../../shared/models/search.sort.request";
import { ShipmentListColumns } from "./shipment-list-columns";
import { ArrayUtils } from "../../../../_helpers/array.utils";
import { convertManifestNumber } from "../../../shared/services/oms-converters.service";
import { SearchRange } from "../../../shared/models/search.range";
import * as moment from "moment-timezone";
import { OmsConstants } from "../../../../common/oms-constants.service";
export var ViewMode;
(function (ViewMode) {
    ViewMode["DISPATCH"] = "DISPATCH";
    ViewMode["STAGING"] = "STAGING";
    ViewMode["ACTIVE"] = "ACTIVE";
    ViewMode["COMPLETED"] = "COMPLETED";
    ViewMode["DRIVER_ROUTES"] = "DRIVER_ROUTES";
    ViewMode["REMOVED_ORDERS"] = "REMOVED_ORDERS";
    /*** @deprecated ***/
    ViewMode["ALL_ORDERS"] = "ALL_ORDERS";
    /*** @deprecated ***/
    ViewMode["AE_ACTIVE"] = "AE_ACTIVE";
    ViewMode["ORDER_ACTIVE"] = "ORDER_ACTIVE";
    //  ORDER_DELIVERED = 'ORDER_DELIVERED', // todo check usage to remove
    //  ORDER_BILLED = 'ORDER_BILLED', // todo check usage to remove
    //  ALL = 'ALL' // todo check usage to remove
})(ViewMode || (ViewMode = {}));
var ShipmentListComponent = /** @class */ (function (_super) {
    __extends(ShipmentListComponent, _super);
    function ShipmentListComponent(route, router, dialog, dialogs, cdr, alerts, dispatchService, spinner, file, ordersService, driverService, userService) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.router = router;
        _this.dialog = dialog;
        _this.dialogs = dialogs;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.dispatchService = dispatchService;
        _this.spinner = spinner;
        _this.file = file;
        _this.ordersService = ordersService;
        _this.driverService = driverService;
        _this.userService = userService;
        _this.items = [];
        _this.orderModes = [];
        _this.selected = [];
        _this.searchColumns = [];
        _this.isVisibleTable = true;
        _this.selectedChange = new EventEmitter();
        _this.clickDispatch = new EventEmitter();
        _this.pageRequest = false;
        _this.activeSearchFilterCondition = {};
        _this.filter = { total: 0, search: '', colorItem: null };
        _this.totalPcs = 0;
        _this.totalHu = 0;
        _this.totalWeight = 0;
        _this.totalUld = 0;
        _this.paging = { enabled: true, pageSize: 100, pageSizes: [10, 20, 50, 100, 200, 500, 1000] };
        _this.request = new FilterPageRequest(1, 100, null, new SearchSortRequest('id', false), []);
        _this.dispatchSort = { field: 'orderId', order: 'desc' };
        _this.activeSort = { field: 'dispatchId', order: 'desc' };
        _this.completedSort = { field: 'dispatchId', order: 'desc' };
        _this.driverRoutesSort = { field: 'dateDispatchedFor', order: 'desc' };
        _this.refresh$ = new Subject();
        _this.DISPATCH_COLUMNS = [
            ShipmentListColumns.ORDER_MODE,
            ShipmentListColumns.HOT,
            ShipmentListColumns.ORDER_ID,
            ShipmentListColumns.DOC_CENTER(_this.ordersService, _this.dialogs),
            ShipmentListColumns.COM_CENTER(function (r) { return _this.onComCenterOpen(r); }),
            ShipmentListColumns.ORDER_COD,
            ShipmentListColumns.ORDER_1C,
            ShipmentListColumns.DELIVERY_APPOINTMENT_REQUIRED,
            ShipmentListColumns.REQUESTED_DELIVERY_DATE,
            ShipmentListColumns.CUSTOMER_REF,
            ShipmentListColumns.ORDER_PO,
            ShipmentListColumns.HAWB,
            ShipmentListColumns.MAWB(function (r) { return _this.openMasterEditor(r); }),
            ShipmentListColumns.CUSTOMER_NAME,
            ShipmentListColumns.FREIGHT_FORWARDER_NAME,
            ShipmentListColumns.PCS,
            ShipmentListColumns.HU,
            ShipmentListColumns.WEIGHT,
            ShipmentListColumns.ULD(_this.dialog),
            ShipmentListColumns.ADDRESS_PICKUP_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_PICKUP_STATE,
            ShipmentListColumns.ADDRESS_PICKUP_CITY,
            ShipmentListColumns.ADDRESS_PICKUP_ZIP,
            ShipmentListColumns.ADDRESS_DELIVERY_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_DELIVERY_STATE,
            ShipmentListColumns.ADDRESS_DELIVERY_CITY,
            ShipmentListColumns.ADDRESS_DELIVERY_ZIP,
            ColumnType.CREATED_BY,
            ColumnType.DATE_CREATED
        ];
        _this.STAGING_COLUMNS = [
            ShipmentListColumns.LOAD_TYPE,
            ShipmentListColumns.ORDER_MODE,
            ShipmentListColumns.ORDER_ID,
            ShipmentListColumns.DISPATCHED_DATE,
            ShipmentListColumns.STAGING_MANIFEST_ID(_this.dispatchService, function (r) { return _this.openManifestForm(r); }),
            //    ShipmentListColumns.DRIVER_NAME(this.userService, r => this.onDriverLocationOpen(r)),
            //    ShipmentListColumns.TRUCK_NUMBER,
            ShipmentListColumns.TRAILER_NUMBER,
            ShipmentListColumns.DOC_CENTER(_this.ordersService, _this.dialogs),
            ShipmentListColumns.COM_CENTER(function (r) { return _this.onComCenterOpen(r); }),
            ShipmentListColumns.ORDER_COD,
            ShipmentListColumns.REQUESTED_DELIVERY_DATE,
            ShipmentListColumns.CUSTOMER_REF,
            ShipmentListColumns.ORDER_PO,
            ShipmentListColumns.HAWB,
            ShipmentListColumns.MAWB(function (r) { return _this.openMasterEditor(r); }),
            ShipmentListColumns.CUSTOMER_NAME,
            ShipmentListColumns.FREIGHT_FORWARDER_NAME,
            ShipmentListColumns.PCS,
            ShipmentListColumns.HU,
            ShipmentListColumns.WEIGHT,
            ShipmentListColumns.ULD(_this.dialog),
            ShipmentListColumns.PROBLEM,
            ShipmentListColumns.ADDRESS_PICKUP_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_PICKUP_STATE,
            ShipmentListColumns.ADDRESS_PICKUP_CITY,
            ShipmentListColumns.ADDRESS_PICKUP_ZIP,
            ShipmentListColumns.ADDRESS_DELIVERY_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_DELIVERY_STATE,
            ShipmentListColumns.ADDRESS_DELIVERY_CITY,
            ShipmentListColumns.ADDRESS_DELIVERY_ZIP,
            ShipmentListColumns.DATE_PICKUP,
            ShipmentListColumns.DATE_DELIVERY,
            ShipmentListColumns.COMMENTS,
            ShipmentListColumns.CREATED_BY,
            ShipmentListColumns.DATE_CREATED,
            ShipmentListColumns.ORDER_CREATED_BY,
            ShipmentListColumns.ORDER_DATE_CREATED
        ];
        _this.ACTIVE_COLUMNS = [
            ShipmentListColumns.LOAD_TYPE,
            ShipmentListColumns.ORDER_MODE,
            ShipmentListColumns.ORDER_ID,
            _this.dispatchDateColumn = ShipmentListColumns.DISPATCHED_DATE,
            ShipmentListColumns.MANIFEST_ID(_this.dispatchService, function (r) { return _this.openManifestForm(r); }),
            ShipmentListColumns.DRIVER_NAME(_this.userService, function (r) { return _this.onDriverLocationOpen(r); }),
            ShipmentListColumns.TRUCK_NUMBER,
            ShipmentListColumns.TRAILER_NUMBER,
            ShipmentListColumns.DOC_CENTER(_this.ordersService, _this.dialogs),
            ShipmentListColumns.COM_CENTER(function (r) { return _this.onComCenterOpen(r); }),
            ShipmentListColumns.ORDER_COD,
            ShipmentListColumns.REQUESTED_DELIVERY_DATE,
            ShipmentListColumns.CUSTOMER_REF,
            ShipmentListColumns.ORDER_PO,
            ShipmentListColumns.HAWB,
            ShipmentListColumns.MAWB(function (r) { return _this.openMasterEditor(r); }),
            ShipmentListColumns.CUSTOMER_NAME,
            ShipmentListColumns.FREIGHT_FORWARDER_NAME,
            ShipmentListColumns.PCS,
            ShipmentListColumns.HU,
            ShipmentListColumns.WEIGHT,
            ShipmentListColumns.ULD(_this.dialog),
            ShipmentListColumns.PROBLEM,
            ShipmentListColumns.ADDRESS_PICKUP_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_PICKUP_STATE,
            ShipmentListColumns.ADDRESS_PICKUP_CITY,
            ShipmentListColumns.ADDRESS_PICKUP_ZIP,
            ShipmentListColumns.ADDRESS_DELIVERY_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_DELIVERY_STATE,
            ShipmentListColumns.ADDRESS_DELIVERY_CITY,
            ShipmentListColumns.ADDRESS_DELIVERY_ZIP,
            ShipmentListColumns.DATE_PICKUP,
            ShipmentListColumns.DATE_DELIVERY,
            ShipmentListColumns.COMMENTS,
            ShipmentListColumns.CREATED_BY,
            ShipmentListColumns.DATE_CREATED,
            ShipmentListColumns.ORDER_CREATED_BY,
            ShipmentListColumns.ORDER_DATE_CREATED
        ];
        _this.COMPLETED_COLUMNS = [
            ShipmentListColumns.LOAD_TYPE,
            ShipmentListColumns.ORDER_MODE,
            ShipmentListColumns.ORDER_ID,
            ShipmentListColumns.DISPATCHED_DATE,
            ShipmentListColumns.MANIFEST_ID(_this.dispatchService, function (r) { return _this.openManifestForm(r); }),
            ShipmentListColumns.DOC_CENTER_FOR_COMPLETED(_this.ordersService, _this.dialogs),
            ShipmentListColumns.COM_CENTER(function (r) { return _this.onComCenterOpen(r); }),
            ShipmentListColumns.DRIVER_NAME(_this.userService, function (r) { return _this.onDriverLocationOpen(r); }),
            ShipmentListColumns.TRUCK_NUMBER,
            ShipmentListColumns.TRAILER_NUMBER,
            ShipmentListColumns.ORDER_COD,
            ShipmentListColumns.REQUESTED_DELIVERY_DATE,
            ShipmentListColumns.CUSTOMER_REF,
            ShipmentListColumns.ORDER_PO,
            ShipmentListColumns.HAWB,
            ShipmentListColumns.MAWB(function (r) { return _this.openMasterEditor(r); }),
            ShipmentListColumns.CUSTOMER_NAME,
            ShipmentListColumns.FREIGHT_FORWARDER_NAME,
            ShipmentListColumns.PCS,
            ShipmentListColumns.HU,
            ShipmentListColumns.WEIGHT,
            ShipmentListColumns.ULD(_this.dialog),
            ShipmentListColumns.PROBLEM,
            ShipmentListColumns.ADDRESS_PICKUP_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_PICKUP_STATE,
            ShipmentListColumns.ADDRESS_PICKUP_CITY,
            ShipmentListColumns.ADDRESS_PICKUP_ZIP,
            ShipmentListColumns.ADDRESS_DELIVERY_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_DELIVERY_STATE,
            ShipmentListColumns.ADDRESS_DELIVERY_CITY,
            ShipmentListColumns.ADDRESS_DELIVERY_ZIP,
            ShipmentListColumns.DATE_PICKUP,
            ShipmentListColumns.DATE_DELIVERY,
            ShipmentListColumns.COMMENTS,
            ShipmentListColumns.CREATED_BY,
            ShipmentListColumns.DATE_CREATED,
            ShipmentListColumns.ORDER_CREATED_BY,
            ShipmentListColumns.ORDER_DATE_CREATED,
            ShipmentListColumns.DISPATCH_LOAD_STATUS
        ];
        _this.ORDER_ACTIVE = [
            ShipmentListColumns.ORDER_ID,
            ShipmentListColumns.DOC_CENTER(_this.ordersService, _this.dialogs),
            ShipmentListColumns.COM_CENTER(function (r) { return _this.onComCenterOpen(r); }),
            ShipmentListColumns.MAWB(function (r) { return _this.openMasterEditor(r); }),
            ShipmentListColumns.HAWB,
            ShipmentListColumns.CUSTOMER_REF,
            ShipmentListColumns.ORDER_PO,
            ShipmentListColumns.CUSTOMER_NAME,
            ShipmentListColumns.ULD(_this.dialog),
            ShipmentListColumns.PCS,
            ShipmentListColumns.HU,
            ShipmentListColumns.WEIGHT,
            ShipmentListColumns.ADDRESS_PICKUP_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_PICKUP_STATE,
            ShipmentListColumns.ADDRESS_PICKUP_CITY,
            ShipmentListColumns.ADDRESS_PICKUP_ZIP,
            ShipmentListColumns.ADDRESS_DELIVERY_NAME(function (r) { return _this.addressInfoPopup(r.addressDelivery); }),
            ShipmentListColumns.ADDRESS_DELIVERY_STATE,
            ShipmentListColumns.ADDRESS_DELIVERY_CITY,
            ShipmentListColumns.ADDRESS_DELIVERY_ZIP,
            ShipmentListColumns.ORDER_DATE_CREATED,
            ShipmentListColumns.ORDER_CREATED_BY,
        ];
        _this.DRIVER_ROUTES_COLUMNS = [
            ShipmentListColumns.ROUTE_DATE,
            ShipmentListColumns.DRIVER_NAME(_this.userService, function (r) { return _this.onDriverLocationOpen(r); }),
            ShipmentListColumns.DRIVER_MANIFEST_ID(function (r) { return _this.openManifestFormByDriver(r); }),
            ShipmentListColumns.DRIVER_ROUTE_LOADS
        ];
        _this.AE_ACTIVE_COLUMNS = _this.createAeActiveColumns();
        _this.groupedByDispatchId = function (items) {
            var grouped = items.reduce(function (total, item) {
                if (item.dispatchId) {
                    total[item.dispatchId] = total[item.dispatchId] || [];
                    total[item.dispatchId].push(item);
                }
                return total;
            }, {});
            return Object.keys(grouped).map(function (dispatchId) {
                return {
                    children: grouped[dispatchId]
                };
            });
        };
        return _this;
    }
    /*
      public static ViewModes() {
        return ViewMode;
      }
     */
    ShipmentListComponent.prototype.createAeActiveColumns = function () {
        var columns = this.ACTIVE_COLUMNS.filter(function (c) { return ![
            ShipmentListColumns.ORDER_MODE,
            ShipmentListColumns.ADDRESS_PICKUP_STATE,
            ShipmentListColumns.ADDRESS_PICKUP_CITY,
            ShipmentListColumns.ADDRESS_PICKUP_ZIP,
            ShipmentListColumns.ADDRESS_DELIVERY_STATE,
            ShipmentListColumns.ADDRESS_DELIVERY_CITY,
            ShipmentListColumns.ADDRESS_DELIVERY_ZIP
        ].includes(c); });
        columns.splice(14, 0, ColumnType.ORDER_CHASSIS);
        return columns;
    };
    ShipmentListComponent.prototype.isSelectable = function () {
        return this.viewMode === ViewMode.DISPATCH || this.viewMode === ViewMode.ORDER_ACTIVE;
    };
    Object.defineProperty(ShipmentListComponent.prototype, "columns", {
        get: function () {
            switch (this.viewMode) {
                case ViewMode.DISPATCH:
                    return this.DISPATCH_COLUMNS;
                case ViewMode.STAGING:
                    return this.STAGING_COLUMNS;
                case ViewMode.ACTIVE:
                    return this.ACTIVE_COLUMNS;
                case ViewMode.AE_ACTIVE:
                    return this.AE_ACTIVE_COLUMNS;
                case ViewMode.COMPLETED:
                    return this.COMPLETED_COLUMNS;
                case ViewMode.DRIVER_ROUTES:
                    return this.DRIVER_ROUTES_COLUMNS;
                case ViewMode.ORDER_ACTIVE:
                    return this.ORDER_ACTIVE;
            }
            console.error('Columns for View mode not defined. ' + this.viewMode);
            return [];
        },
        enumerable: true,
        configurable: true
    });
    ShipmentListComponent.prototype.ngOnInit = function () {
        //    this.updateDispatchDate();
    };
    // note Removed by Request 0001472: DISPATCH/AE/FCL UI: Remove Default filter for manifest date.
    ShipmentListComponent.prototype.updateDispatchDate = function () {
        if (this.viewMode === ViewMode.ACTIVE || this.viewMode === ViewMode.AE_ACTIVE) {
            var startDay = moment.tz(new Date(), OmsConstants.ETC_ZONE)
                .set({ h: 0, m: 0, s: 0, ms: 0 })
                .utc();
            var range = new SearchRange(startDay.format(DispatchService.FULL_MOMENT_FORMAT), startDay.format(DispatchService.FULL_MOMENT_FORMAT), false);
            this.dispatchDateColumn.search.search = range;
        }
    };
    ShipmentListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewMode && this.request) {
            this.request.sort.field = this.defaultColumnForSort();
        }
        if (changes.viewMode) {
            this.updateTreeModel(this.viewMode);
            this.items = [];
            this.cdr.markForCheck();
            this.cdr.detectChanges();
        }
    };
    ShipmentListComponent.prototype.updateTreeModel = function (viewMode) {
        // if (viewMode === ViewMode.DRIVER_ROUTES) {
        //   this.treeModel = new DriverRouteTreeModel(this.dispatchService);
        // } else {
        this.treeModel = null;
        // }
    };
    ShipmentListComponent.prototype.onChangeSelected = function (selected) {
        this.selectedChange.emit(selected.slice());
    };
    ShipmentListComponent.prototype.updateTotalValues = function (selectedDispatch) {
        var totalPcs = 0;
        var totalHu = 0;
        var totalWeight = 0;
        var totalUld = 0;
        for (var i = 0; i < selectedDispatch.length; i++) {
            if (NumberUtils.isNumber(selectedDispatch[i].pieces)) {
                totalPcs += selectedDispatch[i].pieces;
            }
            if (NumberUtils.isNumber(selectedDispatch[i].hu)) {
                totalHu += selectedDispatch[i].hu;
            }
            if (NumberUtils.isNumber(selectedDispatch[i].weight)) {
                totalWeight += selectedDispatch[i].weight;
            }
            if (NumberUtils.isNumber(selectedDispatch[i].uldCount)) {
                totalUld += selectedDispatch[i].uldCount;
            }
        }
        this.totalPcs = totalPcs;
        this.totalHu = totalHu;
        this.totalWeight = totalWeight;
        this.totalUld = totalUld;
        this.cdr.markForCheck();
    };
    Object.defineProperty(ShipmentListComponent.prototype, "editEnabled", {
        /*
          ngAfterViewInit(): void {
        //    this.table.buildSearchRequest();
        //    this.onSearch(null);
          }
        
         */
        get: function () {
            return this.selected && this.selected.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    ShipmentListComponent.prototype.editSelected = function () {
        if (this.editEnabled) {
        }
    };
    // Update Row Field
    ShipmentListComponent.prototype.onUpdate = function (event) {
        var _this = this;
        //    console.log('update:', event);
        var fields = {};
        fields[event.field] = event.newValue;
        this.ordersService.updateOrderOld(event.row.orderId, fields)
            .then(function () {
            event.row[event.field] = event.newValue;
            event.row = Object.assign(new OrderDispatch(), event.row);
            event.row['fakeTrackId'] = null;
            _this.items = _this.items.slice();
            _this.items.replaceAll(event.row, event.row);
            _this.table.refresh([event.row]);
            _this.cdr.markForCheck();
        })
            .catch(function (error) { return _this.onUpdateFieldError(error, event); });
    };
    ShipmentListComponent.prototype.onUpdateFieldError = function (error, updateEvent) {
        if (updateEvent) {
            updateEvent.cancel();
        }
        if (typeof error === 'string' && error.includes('UNIQUE KEY')) {
            error = 'Duplicated value not allowed: ' + updateEvent.newValue;
        }
        //    console.log('Update Field Error', updateEvent, error);
        this.alerts.danger(error);
    };
    ShipmentListComponent.prototype.sortBySelected = function () {
        if (this.viewMode === 'DISPATCH') {
            return 'desc';
        }
        return '';
    };
    ShipmentListComponent.prototype.defaultColumnForSort = function () {
        if (this.viewMode === 'DISPATCH') {
            return null;
        }
        return 'dispatchId';
    };
    ShipmentListComponent.prototype.updateData = function (filterPageRequest) {
        var subject = new Subject();
        this.updateList(filterPageRequest).subscribe(function () { return subject.next(); });
        return subject;
    };
    ShipmentListComponent.prototype.updateList = function (request) {
        var _this = this;
        //    this.items = [];
        if (this.treeModel && this.treeModel.clear) {
            this.treeModel.clear();
        }
        if (this.table) { // is not visible
            this.table.afterUpdate();
        }
        this.cdr.markForCheck();
        this.spinner.show().then();
        this.request = request || this.request;
        this.request.replaceColumns(this.searchColumns);
        this.request.findByOccurs = this.getSearchText();
        if (this.table) {
            this.table.filter.search = this.getSearchText();
        }
        this.request.sort.field = this.request.sort.field || this.defaultColumnForSort();
        var namedCondition = this.viewMode === ViewMode.AE_ACTIVE ? ViewMode.ACTIVE : this.viewMode;
        this.request.addNamedCondition(namedCondition);
        if (this.table) {
            this.table.beforeUpdate();
        }
        this.pageRequest = true;
        this.request.modesFilter = [];
        if (this.orderModes && this.orderModes.length > 0) {
            this.orderModes.forEach(function (mode) {
                _this.request.modesFilter.push(mode.toString());
            });
        }
        var range = this.dispatchDateColumn.search.search;
        if (this.viewMode === ViewMode.AE_ACTIVE && range instanceof SearchRange) {
            var filterByDate = new FilterSearchColumn('dateDispatchedFor', null, null, range);
            this.request.filterByColumns.push(filterByDate);
        }
        return this.callRequestByViewMode()
            .pipe(tap(function (page) {
            console.log('Page received', page);
            _this.items = page.content;
            if (_this.treeModel && _this.treeModel.clear) {
                _this.treeModel.clear();
            }
            _this.updateTotalValues(_this.items);
            _this.paging.total = page.totalElements;
            //        console.log("Page>", this.items.length);
            if (_this.table) {
                _this.table.afterUpdate();
            }
            _this.spinner.hide().then();
            _this.pageRequest = false;
            _this.cdr.markForCheck();
            _this.cdr.detectChanges();
            _this.refresh$.next();
        }, function (error) {
            if (_this.table) {
                _this.table.afterUpdate();
            }
            _this.spinner.hide().then();
            _this.pageRequest = false;
            _this.cdr.markForCheck();
            _this.cdr.detectChanges();
            _this.alerts.error(error);
            _this.refresh$.error(error);
        }));
    };
    ShipmentListComponent.prototype.callRequestByViewMode = function () {
        if (this.viewMode === ViewMode.DISPATCH) {
            return this.dispatchService.findAllToDispatch(this.request);
        }
        else if (this.viewMode === ViewMode.DRIVER_ROUTES) {
            return this.dispatchService.findDispatchedLoadsForDriver(this.request);
        }
        return this.dispatchService.findDispatchedLoadsObs(this.request);
    };
    ShipmentListComponent.prototype.openManifestFormByDriver = function (dr) {
        var _this = this;
        this.dispatchService.findDispatchedLoadsByDriver(dr)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (response) {
            if (response.content.length) {
                _this.openManifestForm(response.content[0]);
            }
        });
    };
    ShipmentListComponent.prototype.openManifestForm = function (dispatch) {
        // this.dialog.open(ManifestDialogComponent, {data:{manifest: manifest}});
        // this.dialogs.openDialog(ManifestDialogComponent, {manifest: manifest, });
        // console.log('Show Manifest', manifest);
        this.clickDispatch.emit(dispatch);
    };
    ShipmentListComponent.prototype.addressInfoPopup = function (address) {
        if (!isNullOrUndefined(address)) {
            this.dialog.open(AddressMapInfoDialogComponent, { width: '50%', data: { address: address, searchByAddress: true } });
        }
    };
    ShipmentListComponent.prototype.openMasterEditor = function (dispatch) {
        if (dispatch.masterId) {
            this.router.navigate(['dispatch/shipments/master', { id: dispatch.masterId }]).then();
        }
    };
    ShipmentListComponent.prototype.refresh = function () {
        this.table.buildSearchRequest();
        this.cdr.markForCheck();
        return this.refresh$.pipe(take(1));
    };
    ShipmentListComponent.prototype.getRowClass = function (row) {
        if (this.viewMode === ViewMode.DRIVER_ROUTES) {
            return '';
        }
        var result = 'row-dispatch';
        if (!(row instanceof OrderDispatch)) {
            return result;
        }
        /*    if (row.isRecovery) {
              if (row.isPickedUp) {
                result += ' success';
              }
            } else { */
        if (row.dispatchCancelled) {
            result += ' dispatch-cancelled';
        }
        if (row.isDispatched) {
            result += row.isConfirmed ? ' confirmed' : ' not-confirmed';
        }
        if (row.isNotCompleted()) {
            result += ' danger';
        }
        if (row.isCompletedWithProblem()) {
            result += ' warning';
        }
        if (row.isCompleted()) {
            result += ' success';
        }
        //      result += row.isDelivered ?
        //        (row.hasProblem ? ' warning' : (this.viewMode === ViewMode.DISPATCH ? '' : ' success')) :
        //        (row.hasProblem ? ' danger' : '');
        //    }
        return result;
    };
    ShipmentListComponent.prototype.onComCenterOpen = function (row) {
        this.dialogs.openComCenterDialog(row.orderId, 'ORDER');
    };
    ShipmentListComponent.prototype.onDriverLocationOpen = function (row) {
        this.dialogs.openDriverLocationDialog(row.driver);
    };
    Object.defineProperty(ShipmentListComponent.prototype, "sort", {
        get: function () {
            switch (this.viewMode) {
                case ViewMode.DISPATCH:
                    return this.dispatchSort;
                case ViewMode.ACTIVE:
                    return this.activeSort;
                case ViewMode.AE_ACTIVE:
                    return this.activeSort;
                case ViewMode.COMPLETED:
                    return this.completedSort;
                case ViewMode.DRIVER_ROUTES:
                    return this.driverRoutesSort;
                case ViewMode.ORDER_ACTIVE:
                    return this.activeSort;
            }
        },
        set: function (value) {
            //    console.log('Sort Changed', this.viewMode, value);
            switch (this.viewMode) {
                case ViewMode.DISPATCH:
                    this.dispatchSort = value;
                    break;
                case ViewMode.ACTIVE:
                    this.activeSort = value;
                    break;
                case ViewMode.AE_ACTIVE:
                    this.activeSort = value;
                    break;
                case ViewMode.COMPLETED:
                    this.completedSort = value;
                    break;
                case ViewMode.DRIVER_ROUTES:
                    this.driverRoutesSort = value;
                    break;
                case ViewMode.ORDER_ACTIVE:
                    this.activeSort = value;
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    ShipmentListComponent.prototype.setSearchText = function (text) {
        this.filter.search = text;
        this.filter = Object.assign({}, this.filter);
    };
    ShipmentListComponent.prototype.changeSearchText = function (searchText) {
        this.setSearchText(searchText);
        this.request.findByOccurs = searchText;
        if (this.table) {
            this.table.changeSearchText(searchText);
        }
    };
    Object.defineProperty(ShipmentListComponent.prototype, "filterPageRequest", {
        get: function () {
            return this.request;
        },
        enumerable: true,
        configurable: true
    });
    ShipmentListComponent.prototype.getSearchText = function () {
        return this.filter.search;
    };
    /*  public getChassisOrUld(row: OrderDispatch): string {
        if (OrderModes.isFCL(row.orderMode)) {
          return row.chassis;
        } else {
          return row.uldCount;
        }
      }
     */
    ShipmentListComponent.prototype.getDisplayForRowHeader = function (row) {
        if (!row.children.length) {
            return null;
        }
        var customerNames = row.children
            .filter(function (item) { return item.customer && item.customer.name; })
            .map(function (item) { return item.customer.name; });
        customerNames = ArrayUtils.removeDuplicate(customerNames, function (c) { return c; });
        var displayDispatch = convertManifestNumber(row.children[0].dispatchId);
        var trailer = !isNullOrUndefined(row.children[0].trailer) && !isNullOrUndefined(row.children[0].trailer.number) ? row.children[0].trailer.number : 'N/A';
        var header = displayDispatch + (customerNames.length ? (' - ' + customerNames.join(', ')) : '');
        if (ViewMode.STAGING === this.viewMode) {
            header = header + ' - Trailer:' + trailer;
        }
        return header;
    };
    ShipmentListComponent.prototype.getGroupedFunc = function () {
        if (this.viewMode !== ViewMode.STAGING && this.viewMode !== ViewMode.ACTIVE) {
            return null;
        }
        return this.groupedByDispatchId;
    };
    return ShipmentListComponent;
}(AbstractComponent));
export { ShipmentListComponent };
