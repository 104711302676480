import { BehaviorSubject } from "rxjs/BehaviorSubject";
import * as i0 from "@angular/core";
var GlobalSearchService = /** @class */ (function () {
    function GlobalSearchService() {
        this.subject = new BehaviorSubject(undefined);
    }
    GlobalSearchService.prototype.search = function (text) {
        this.subject.next(text);
    };
    GlobalSearchService.prototype.clear = function () {
        this.subject.next(undefined);
    };
    Object.defineProperty(GlobalSearchService.prototype, "text", {
        get: function () {
            return this.subject.value;
        },
        set: function (text) {
            this.search(text);
        },
        enumerable: true,
        configurable: true
    });
    GlobalSearchService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalSearchService_Factory() { return new GlobalSearchService(); }, token: GlobalSearchService, providedIn: "root" });
    return GlobalSearchService;
}());
export { GlobalSearchService };
