var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from "../../../services/base/base-entity.service";
import { RestService } from "../../../services/rest.service";
import { HttpUtilsService } from "../../../services/http-utils.service";
import { UserRole } from "../models/user-role";
import * as i0 from "@angular/core";
import * as i1 from "../../../services/rest.service";
import * as i2 from "../../../services/http-utils.service";
var UserRoleService = /** @class */ (function (_super) {
    __extends(UserRoleService, _super);
    function UserRoleService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = "/api/user-roles/";
        _this.classType = UserRole;
        return _this;
    }
    UserRoleService.ngInjectableDef = i0.defineInjectable({ factory: function UserRoleService_Factory() { return new UserRoleService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: UserRoleService, providedIn: "root" });
    return UserRoleService;
}(BaseEntityService));
export { UserRoleService };
