import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Customer, Master, Order} from '../modules/shared/models';
import {RestService} from './rest.service';
import {HttpUtilsService} from './http-utils.service';
import {classToPlain, plainToClass} from 'class-transformer';
import {map, tap} from 'rxjs/operators';
import {BaseEntityService} from './base/base-entity.service';
import {HttpEvent} from "@angular/common/http";
import {FilterPageRequest} from "../modules/shared/models/filter.page.request";
import {PageResult} from "../modules/shared/models/query-models/page-result";

export interface RefByOrdersInfo {
  ref: string;
  orders: number[];
}


@Injectable()
export class OrdersService extends BaseEntityService<Order> {

  public apiUrl = '/oms/order/';
  public classType = Order;

  public filesList = new Subject<any[]>();

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }


  /***@deprecated***/
  getOrder(id: number): Promise<Order> {
    return this.httpRestService.get<Order>(this.apiUrl + 'getOrder', id)
      .pipe(map((order) => {
        console.log('Raw Order', order);
        return this.afterLoad(plainToClass(Order, order));
      }))
      .toPromise();
  }

  getMasterInfoByRecoveryOrder(id: number): Observable<any> {
    return this.httpRestService.get<any>(this.apiUrl + 'getMasterInfoByRecoveryOrder', id);
//      .pipe(map((order) => this.afterLoad(plainToClass(Order, order))));
  }

  public splitOrder(id: number, pcs: number, hu: number, weight: number): Promise<Order> {
    return this.httpRestService.get<Order>(this.apiUrl + 'splitOrder', id, pcs, hu, weight)
      .pipe(map((orders) => this.afterLoad(plainToClass(Order, orders))))
      .toPromise();
  }

  buildDownloadUrl(fileType: string) {
    return this.httpRestService.buildUrlForDownload(this.apiUrl + 'downloadOrdersInformation', fileType);
  }

  saveAll(orders: Order[]): Promise<Order[]> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<Order[]>(this.apiUrl + 'saveAll', orders, {headers: httpHeader})
      .pipe(map((o) => plainToClass(Order, o).map((item) => this.afterLoad(item))))
      .toPromise();
  }

  updateOrderOld(id: number, fields: {}): Promise<Master | Order> {
    return this.updateOrder(id, fields)
      .then(res => {
        return res[1] || res[0];
      });
  }

  updateOrder(id: number, fields: {}): Promise<[Order, Master]> {
    return this.updateOrderObs(id, fields).toPromise();
  }

  updateOrderObs(id: number, fields: {}): Observable<[Order, Master]> {
    console.log('Update Order', id, fields);
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<Object>(this.apiUrl + 'updateOrder/' + id, fields, httpHeader)
      .pipe(map((object) => {
        const isSingleOrder = object && (object['id'] === id);
        if (isSingleOrder) {
          return [this.afterLoad(plainToClass(Order, object) as Order), null] as [Order, Master];
        }
        const master = this.afterLoad(plainToClass(Master, object) as Master);
        const order = master.orders.find(o => o.id === id);
        return [order, master] as [Order, Master];
      }));
  }

  uploadOrdersExpeditors(files: FileList): Observable<HttpEvent<{}>> {
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files.item(i), files.item(i)['name']);
    }
    return this.httpRestService.requestQuery('POST', '/endpoint/import/orders/expeditors', formData);
  }

  generateBOLs(ordersIds: number[]): Observable<any> {
    return  this.httpRestService.post<any>(this.apiUrl + 'generateBOLs', ordersIds);
  }

  printBOLs(ordersIds: number[]): Observable<any> {
    return  this.httpRestService.post<any>(this.apiUrl + 'printBOLs', ordersIds);
  }

  createOrders(orders: Order[]): Promise<Order[]> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<Order[]>(this.apiUrl + 'createOrders',  classToPlain(orders as any[]), {headers: httpHeader})
      .pipe(map((o) => plainToClass(Order, o).map((item) => this.afterLoad(item))))
      .toPromise();
  }

  automateOrders(orders: Order[]): Promise<Order[]> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<Order[]>(this.apiUrl + 'automateOrders',  classToPlain(orders as any[]), {headers: httpHeader})
      .pipe(map((o) => plainToClass(Order, o).map((item) => this.afterLoad(item))))
      .toPromise();
  }

  validateAIOrders(orders: Order[]): Promise<Order[]> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<Order[]>(this.apiUrl + 'validateAIOrders',  classToPlain(orders as any[]), {headers: httpHeader})
      .pipe(map((o) => plainToClass(Order, o).map((item) => this.afterLoad(item))))
      .toPromise();
  }


  public findExistingRefs(refs: string[]): Promise<RefByOrdersInfo[]> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<Object>(this.apiUrl + 'find-existing-refs',  refs, {headers: httpHeader})
      .pipe(map((o) => Object.keys(o).map((ref) => ({ref: ref, orders: o[ref]}))))
      .toPromise();
  }

  openBase64PDF(response) {
    let data = response.bytes;
    let fileName = "BOL.pdf";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], {type: 'application/pdf'});
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else { // much easier if not IE
      let myWindow = window.open("", "", "");
      if (myWindow) {
        let myWindowBody = myWindow.document.body;
        let link = document.createElement('a');
        link.innerHTML = 'Download as PDF';
        link.download = fileName;
        link.href = 'data:application/octet-stream;base64,' + data;
        myWindowBody.append(link);

        let obj = document.createElement('object');
        obj.style.width = '100%';
        obj.style.height = '100%';
        obj.type = 'application/pdf';
        obj.data = 'data:application/pdf;base64,' + data;
        myWindowBody.append(obj);
      }
    }
  }

  public findCustomersOfSelectedOrders(search: string, filterPageRequest: FilterPageRequest): Observable<PageResult<Customer>> {
    return this.httpRestService.post<PageResult<Customer>>(this.apiUrl + 'customers_of_selected', filterPageRequest, {params: {search: search}})
      .pipe(tap((page) => {
          page.content = plainToClass(Customer, page.content);
          console.log('Found Customers', page.content);
      }));
}


}
