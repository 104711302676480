<perfect-scrollbar style="max-height: 90vh;">
  <div #toolbar class="oms-modal widget">
    <div class="widget-header">
      <h3><i class="fa fa-info"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <div class="form-row" style="align-content: start; min-height: 300px">
      <oms-data-table #table
                      [striped]="true"
                      [condensed]="true"
                      [selectable]="false"
                      [searchable]="false"
                      [multi-select]="false"
                      [checkboxes]="true"
                      [fixed]="false"
                      [fixed-columns]="3"
                      [columns]="columns"
                      [defaultColumnForSort]="defaultSortColumn"
                      [data]="dispatchAndRecoveryLogs">
      </oms-data-table>
    </div>


    <div class="widget-footer">
      <div></div>

      <div class="dialog-btns">
        <button type="button" class="btn btn-default" (click)="generateExcel()">Generate Excel</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div>
  </div>
</perfect-scrollbar>
