import { ChangeDetectorRef, OnInit } from '@angular/core';
import { DocumentPreviewComponent } from "./document-preview/document-preview.component";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ObjectDocuments } from "../../../modules/shared/models/documents/object.documents";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { UploadedFiles } from "../../../modules/shared/models/documents/uploaded.files";
import { OmsObjectType } from "../../../common/oms-types";
import { DocumentType } from "../../../modules/shared/models/documents/document-type";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { DocumentTypeService } from "../../../services/document-type.service";
import { FileUploadService } from "../../../services/file.upload.service";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { isNullOrUndefined } from "util";
import { copyToClipboard, downloadFile, sendEmail } from "../../../_helpers/utils";
import { FileUploadDialogComponent } from "../file-upload-dialog";
import { MasterPttCreatorDialogComponent } from "../master-ptt-creator-dialog";
import { convertMasterNumber, convertOrderNumber } from "../../../modules/shared/services/oms-converters.service";
import { KeyCode } from "../../../modules/shared/components/common/input/ng-select-types/ng-select.types";
var DocCenterComponent = /** @class */ (function () {
    function DocCenterComponent(fb, dialog, cdr, route, documentTypeService, fileUploadService, alertsService) {
        var _this = this;
        this.fb = fb;
        this.dialog = dialog;
        this.cdr = cdr;
        this.route = route;
        this.documentTypeService = documentTypeService;
        this.fileUploadService = fileUploadService;
        this.alertsService = alertsService;
        this.class = 'doc-center';
        this.isVisibleHistory = true;
        this.readonly = false;
        this.showHeader = true;
        this.latestActive = false;
        this.allowFilesDrop = function (event) {
            return !this.readonly;
            /*    event.preventDefault();
                const {dataTransfer} = event;
                return dataTransfer && dataTransfer.items && (dataTransfer.items.length > 0) && (dataTransfer.items[0].kind === 'file'); */
        }.bind(this);
        this.documents = [];
        this.removed = [];
        this.fitToPage = true;
        this.objectDocuments = new ObjectDocuments();
        this.uploadedFiles = new UploadedFiles();
        this.activeTab = 'documents';
        this.hasFormErrors = false;
        this.viewLoading = false;
        this.loadingAfterSubmit = false;
        this.showProgressBar = false;
        this.mappedObject = null;
        this.documentTypes = [];
        this.progress = { percentage: 0 };
        this.fileUploadService.filesList.subscribe(function (orderDocumentsList) {
            console.log('RECEIVED', orderDocumentsList);
            _this.documents = orderDocumentsList;
            _this.cdr.markForCheck();
        });
        this.fileUploadService.filesRemovedList.subscribe(function (orderDocumentsList) {
            console.log('REMOVED', orderDocumentsList);
            _this.removed = orderDocumentsList;
        });
    }
    DocCenterComponent.prototype.loadDocuments = function () {
        this.fileUploadService.findAllById(this.id, this.objectType, this.activeTab === 'documents', this.docTypes, this.ffg, this.latestActive);
    };
    DocCenterComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.readonly) {
            this.documentTypeService.findAllObs({ field: 'name', order: 'asc' })
                .subscribe(function (types) { return _this.documentTypes = DocumentType.sort(types); });
        }
        this.loadDocuments();
    };
    DocCenterComponent.prototype.getTitle = function () {
        return 'Document Center';
    };
    DocCenterComponent.prototype.getSelectedFileName = function () {
        return !isNullOrUndefined(this.selectedFiles) && this.selectedFiles.length > 0 ? this.selectedFiles[0].name : '';
    };
    DocCenterComponent.prototype.validateForm = function () {
        this.hasFormErrors = this.documentTypeEmpty || this.selectedFilesEmpty;
        /*    if (this.documentTypeEmpty) {
              this.hasFormErrors = true;
            } else {
              this.hasFormErrors = !!this.selectedFilesEmpty;
            } */
    };
    Object.defineProperty(DocCenterComponent.prototype, "selectedFilesEmpty", {
        get: function () {
            return isNullOrUndefined(this.selectedFiles);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocCenterComponent.prototype, "documentTypeEmpty", {
        get: function () {
            return isNullOrUndefined(this.documentType);
        },
        enumerable: true,
        configurable: true
    });
    DocCenterComponent.prototype.onSubmit = function () {
        var _this = this;
        this.validateForm();
        if (this.hasFormErrors) {
            return;
        }
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        this.progress.percentage = 0;
        this.showProgressBar = true;
        this.objectDocuments.objectId = this.id;
        this.objectDocuments.objectType = this.objectType;
        this.objectDocuments.uploadedFiles = this.uploadedFiles;
        this.fileUploadService.validateIfFilesExist(this.objectDocuments, this.selectedFiles).subscribe(function (event) {
            if (!event.fileExist) {
                if (_this.saveDocuments) {
                    _this.uploadDocuments();
                }
                else {
                    _this.uploadDocument();
                }
            }
            else {
                _this.alertsService.warning('File Exist');
            }
        });
    };
    DocCenterComponent.prototype.uploadDocument = function () {
        var _this = this;
        this.fileUploadService.uploadDocuments(this.objectDocuments, this.selectedFiles).subscribe(function (event) {
            if (event.type === HttpEventType.UploadProgress) {
                _this.progress.percentage = Math.round(100 * event.loaded / event.total);
            }
            else if (event instanceof HttpResponse) {
                _this.loadDocuments();
                _this.progress.percentage = 0;
                _this.selectedFiles = undefined;
                _this.showProgressBar = false;
            }
        }, function (error) {
            _this.alertsService.error(error, 'File Was Not Uploaded');
        });
    };
    DocCenterComponent.prototype.uploadDocuments = function () {
        var _this = this;
        this.saveDocuments(this.objectDocuments, this.selectedFiles).subscribe(function (events) {
            var progresses = events.filter(function (event) { return event.type === HttpEventType.UploadProgress; });
            var responses = events.filter(function (event) { return event.type !== HttpEventType.UploadProgress && event instanceof HttpResponse; });
            if (progresses.length) {
                var loaded = progresses.reduce(function (total, item) { return total + item.loaded; }, 0);
                var totalMb = progresses.reduce(function (total, item) { return total + item.total; }, 0);
                _this.progress.percentage = Math.round(100 * loaded / totalMb);
            }
            else {
                _this.loadDocuments();
                _this.progress.percentage = 0;
                _this.selectedFiles = undefined;
                _this.showProgressBar = false;
            }
        }, function (error) {
            _this.alertsService.error(error, 'File Was Not Uploaded');
        });
    };
    DocCenterComponent.prototype.onUnitsChanged = function (event) {
        this.documentType = event;
        this.objectDocuments.documentType = event;
    };
    /*
      selectFile(event) {
        this.progress.percentage = 0;
        this.showProgressBar = false;
        this.selectedFiles = event.target.files;
      }
     */
    DocCenterComponent.prototype.buildDownloadUrl = function (orderDocument) {
        return this.fileUploadService.buildDownloadFileUrl(orderDocument.id, this.objectType);
    };
    DocCenterComponent.prototype.downloadLastPodDocument = function () {
        downloadFile(this.getLastPodDocumentUrl());
    };
    DocCenterComponent.prototype.getLastPodDocumentUrl = function () {
        switch (this.objectType) {
            case "ORDER":
                return this.fileUploadService.buildOrderPodDownloadUrl(this.id);
            case "MASTER":
                return this.fileUploadService.buildMasterPodDownloadUrl(this.id);
        }
    };
    DocCenterComponent.prototype.copyPodDownloadUrl = function () {
        copyToClipboard(this.getLastPodDocumentUrl());
        this.alertsService.info('POD Download Link copied to the Clipboard');
    };
    DocCenterComponent.prototype.sendPodDownloadUrl = function () {
        sendEmail('POD Download Link', this.getLastPodDocumentUrl(), '');
    };
    DocCenterComponent.prototype.hasPOD = function () {
        return this.documents && !!this.documents.find(function (od) { return od.isPOD(); });
    };
    DocCenterComponent.prototype.removeDocument = function (objectDocuments) {
        var _this = this;
        objectDocuments.objectType = this.objectType;
        objectDocuments.objectId = this.id;
        this.fileUploadService.deleteDocument(objectDocuments).subscribe(function () {
            _this.loadDocuments();
        });
        // this.fileUploadService.buildDownloadFileByIdUrl(orderDocument.id);
    };
    DocCenterComponent.prototype.restoreDocument = function (objectDocuments) {
        var _this = this;
        if (this.isModified(objectDocuments)) {
            objectDocuments.objectType = this.objectType;
            objectDocuments.objectId = this.id;
            this.fileUploadService.restoreDocument(objectDocuments).subscribe(function () {
                _this.loadDocuments();
            });
        }
    };
    DocCenterComponent.prototype.onFilesDrop = function (files) {
        var _this = this;
        if (!this.readonly) {
            this.dialog.open(FileUploadDialogComponent, {
                disableClose: true,
                width: 'auto',
                data: { id: this.id, objectType: this.objectType, noDescription: true, files: files }
            })
                .afterClosed().subscribe(function () {
                _this.loadDocuments();
            });
        }
    };
    DocCenterComponent.prototype.getActionType = function (orderDocument) {
        return isNullOrUndefined(orderDocument.dateDeleted) ? isNullOrUndefined(orderDocument.previousDocumentType) ? 'Added' : 'Modified' : 'Deleted';
    };
    DocCenterComponent.prototype.getRestoreIcon = function (orderDocument) {
        switch (this.getActionType(orderDocument)) {
            case 'Deleted': return 'fas fa-trash-restore-alt';
            case 'Modified': return 'fas fa-undo-alt';
        }
    };
    DocCenterComponent.prototype.getRestoreLabel = function (orderDocument) {
        switch (this.getActionType(orderDocument)) {
            case 'Deleted': return 'Restore Document';
            case 'Modified': return 'Undo Change';
        }
    };
    DocCenterComponent.prototype.drop = function (event) {
        event.preventDefault();
        if (event.dataTransfer && event.dataTransfer.files) {
            this.selectedFiles = event.dataTransfer.files;
        }
    };
    DocCenterComponent.prototype.onChangedType = function (id, event) {
        var _this = this;
        if (event) {
            this.viewLoading = true;
            this.fileUploadService.updateDocumentTypeById(id, this.objectType, event.type.toUpperCase()).subscribe(function () {
                _this.viewLoading = false;
                _this.loadDocuments();
            }, function (error) {
                _this.viewLoading = false;
                _this.alertsService.error(error, 'Unable change document type');
                _this.loadDocuments();
            });
        }
    };
    DocCenterComponent.prototype.isModified = function (orderDocument) {
        return !(isNullOrUndefined(orderDocument.dateDeleted) && isNullOrUndefined(orderDocument.previousDocumentType));
    };
    DocCenterComponent.prototype.changeTab = function (tab) {
        if (tab !== 'history' || this.isVisibleHistory) {
            this.activeTab = tab;
            this.loadDocuments();
        }
    };
    DocCenterComponent.prototype.generateMasterMDF = function () {
        this.dialog.open(MasterPttCreatorDialogComponent, { width: '90%', data: { master: this.mappedObject } });
    };
    DocCenterComponent.prototype.downloadHistory = function () {
        this.fileUploadService.downloadHistory(this.id, this.objectType);
    };
    DocCenterComponent.prototype.changeTypeDisabled = function (orderDocument) {
        return orderDocument.documentType.name === 'PTT' && isNullOrUndefined(orderDocument.previousDocumentType);
    };
    DocCenterComponent.prototype.openFileUploadDialog = function () {
        var _this = this;
        this.dialog.open(FileUploadDialogComponent, { disableClose: true, width: 'auto', data: { id: this.id, objectType: this.objectType, noDescription: true } })
            .afterClosed().subscribe(function () {
            _this.loadDocuments();
        });
    };
    DocCenterComponent.prototype.getFileTypeClass = function (document) {
        var ext = document.uploadedFiles.name.split('\.').last();
        switch (ext) {
            case 'pdf':
                return 'fa fa-file-pdf';
            case 'doc':
            case 'docx':
                return 'fa fa-file-text';
            case 'xls':
            case 'xlsx':
                return 'fa fa-file-excel';
            case 'jpg':
            case 'png':
            case 'bmp':
                return 'fa fa-file-image';
            default:
                return 'fa fa-file';
        }
    };
    DocCenterComponent.prototype.onPreview = function (document) {
        this.activeDocument = document;
        this.activeTab = 'preview';
        this.cdr.detectChanges();
        /*    if (document) {
              if (document.uploadedFiles.name.endsWith('.pdf')) {
                this.dialog.open(DocumentPreviewDialogComponent, {
                  disableClose: true, width: 'auto',
                  data: {document: document, documents: this.documents}
                });
              } else {
                this.alertsService.warning('No preview supported for File Type');
              }
            } */
    };
    DocCenterComponent.prototype.onDragStart = function (event, document) {
        var url = this.fileUploadService.buildDownloadFileUrl(document.id, this.objectType);
        var name = document.uploadedFiles.name;
        event.dataTransfer.effectAllowed = "copy";
        event.dataTransfer.setData('DownloadURL', "application/octet-stream:" + name + ":" + url);
    };
    DocCenterComponent.prototype.download = function (document) {
        if (document) {
            this.fileUploadService.download(document.id, this.objectType);
        }
    };
    DocCenterComponent.prototype.onEscape = function (event) {
        if (this.activeTab !== 'documents') {
            this.activeTab = 'documents';
            event.preventDefault();
            event.stopPropagation();
        }
    };
    DocCenterComponent.prototype.getPageLabel = function () {
        if (!this.id) {
            return 'Document Center';
        }
        return this.objectType === 'MASTER' ? convertMasterNumber(this.id) : convertOrderNumber(this.id);
        // this.activeTab === 'preview' && this.preview && this.activeDocument ? this.activeDocument.documentType.description :
        // ;
    };
    DocCenterComponent.prototype.onKeyDown = function (event, id) {
        var _this = this;
        if (event.keyCode === KeyCode.Tab) {
            if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value && this.ngSelect.itemsList.markedItem.value) {
                var documentType = this.ngSelect.itemsList.markedItem.value;
                this.fileUploadService.updateDocumentTypeById(id, this.objectType, documentType.type).subscribe(function () {
                    _this.loadDocuments();
                });
            }
        }
    };
    DocCenterComponent.prototype.isPreview = function () {
        return this.activeTab === 'preview';
    };
    return DocCenterComponent;
}());
export { DocCenterComponent };
