import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Size} from '../../../../../../common/oms-types';
import {animations} from "../../../base/base-input/animations";
import {BaseInputComponent} from "../../../base/base-input/base-input.component";
import {Logger} from "../../../../../../_helpers/logger";
import {getOffset} from "../../../../../../_helpers/utils";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'oms-date-input',
  animations,
  styleUrls: ['./date-input.component.scss'],
  templateUrl: './date-input.component.html',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: DateInputComponent, multi: true}],
  changeDetection: ChangeDetectionStrategy.OnPush // todo LAST FREE DATE
})
@Logger({})
export class DateInputComponent extends BaseInputComponent<Date> {
  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input: ElementRef;
  @Output('changing') changingEvent = new EventEmitter();

  @Input() estimation: boolean = false;
  @Input() estimated: Date;
  @Output() estimatedChange = new EventEmitter();
  @Input() inline: boolean = false;

  Size = Size;
  @Input() size: Size = Size.DEFAULT;
  @Input() time: boolean = true;
  @Input('display-format') displayFormat: string;
  @Input() public minDate: Date = null;
  @Input() public maxDate: Date = null;
  @Input('show-checkbox') showCheckbox: boolean = true;
  @Input('show-switch') showSwitch: boolean = false;
  @Input('show-button') showButton: boolean = true;
  @Input() showLabel: boolean = true;
  @Input('show-clear-button') showClearButton: boolean = false;
  @Input() cleanable: boolean = true;
  public isEdit: boolean = false;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  onCbClick() {
    if (this.value) {
      this.value = null;
      this.changingEvent.emit(event);
    } else {
      this.isEdit = true;
    }
  }

  public get popupTop(): number {
    let isOnTop = this.input && getOffset(this.input.nativeElement).top > window.outerHeight / 2;
    return isOnTop ? undefined : this.input.nativeElement.offsetHeight;
  }

  public get popupBottom(): number {
    let isOnTop = getOffset(this.input.nativeElement).top > window.outerHeight / 2;
    return isOnTop ? 0 : undefined;
  }

  get displayValue() {
    return this.value || this.estimated;
  }

  get estimatedOnly(): boolean {
    return this.estimation && isNullOrUndefined(this.value) && !isNullOrUndefined(this.estimated);
  }

  onEstimatedDateChanged(event) {
//    console.log('EST CHANGE', event, this.model);
    this.estimatedChange.emit(event);
  }

  onActualDateChanged(event) {
//    console.log('ACT CHANGE', event);
    this.changingEvent.emit(event);
  }

  onClear(isActual: boolean) {
    if (isActual) {
      this.value = null;
      this.onActualDateChanged(this.value);
    } else {
      this.estimated = null;
      this.onEstimatedDateChanged(this.estimated);
    }
  }
}
