import {Shipment} from "./shipment";
import {Order} from "./order";
import {Exclude, Type} from 'class-transformer';
import {BaseEntity} from "./base-entity";
import {isNullOrUndefined} from "util";
import {Load} from './load';

export class ShipmentContent extends BaseEntity {
  shipment_uid: number;
  order_uid: number;

  @Type(() => Shipment)
  shipment: Shipment;
  @Type(() => Order)
  order: Order;

  pieces: number;
  volume: number;
  weight: number;
  hu: number;

  get isArrived(): boolean {
    let arrived: boolean = !isNullOrUndefined(this.shipment) && !isNullOrUndefined(this.shipment.dateDeliveryActual) &&  this.shipment.dateDeliveryActual <= new Date();
    return arrived; // this.shipment && this.shipment.dateDeliveryActual <= new Date();
  }

  @Exclude()
  get isDelivered(): boolean {
    return this.shipment && this.shipment.isDelivered;
  }

  @Exclude()
  get isPickedUp(): boolean {
    return this.shipment && this.shipment.isPickedUp;
  }

  @Exclude()
  get load(): Load {
    return this.shipment ? this.shipment.load : null;
  }

}
