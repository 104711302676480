﻿<footer #footer class="footer">
  <span class="center">JJS OMS &copy; 2018</span>

  <ng-template #versionInfo>
    ({{version.hash}})&nbsp;{{environment}}<br/>
    Built: {{dateStr}} <br/>
    {{resolution}}
  </ng-template>
  <div class="right" [tooltipHtml]="versionInfo" [container]="'body'" [placement]="'top'">
    {{versionName}}&emsp;
  </div>
</footer>
