﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget" style="width: 350px"
       [ngClass]="{ '_loading' : viewLoading, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">
    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-industry"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>


    <form class="widget-content" [formGroup]="form" style="min-height: 300px;">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Name</label>
            <input type="text"
                   class="form-control"
                   placeholder="Name"
                   formControlName="name"
                   [ngClass]="{'form-control-invalid':isControlInvalid('name')}"/>
            <div *ngIf="isControlInvalid('name')" class="invalid-feedback">Name is mandatory</div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Email</label>
            <ng-select
              appendTo="body"
              placeholder="Email"
              formControlName="email"
              [items]="emails"
              [addTag]="true"
              [multiple]="true"
              [addTagText]="'Create new customer email'"
              [ngClass]="{'form-control-invalid':isControlInvalid('email')}">
            </ng-select>
            <div *ngIf="isControlInvalid('email')" class="invalid-feedback">Email is mandatory</div>
          </div>
        </div>
      </div>

      <div class="form-row col-md-12">
        <div class="form-group">
          <label class="control-label">Customer Group</label>
          <ng-select
            appendTo="body"
            bindLabel="name"
            [items]="customerGroupSearch.items$ | async"
            [loading]="customerGroupSearch.loading"
            [typeahead]="customerGroupSearch.input$"
            [addTag]="true"
            [addTagText]="'Create new customer group'"
            formControlName="customerGroup"
            [ngClass]="{'form-control-invalid':isControlInvalid('customerGroup')}"
            placeholder="Customer Group">
          </ng-select>
          <div *ngIf="isControlInvalid('customerGroup')" class="invalid-feedback">Customer Group is mandatory</div>
        </div>
      </div>

      <div class="form-row col-md-12">
        <div class="form-group">
          <label class="control-label">Address</label>
          <ng-select
            appendTo="body"
            bindLabel="name"
            [items]="addressSearch.items$ | async"
            [loading]="addressSearch.loading"
            [typeahead]="addressSearch.input$"
            formControlName="address"
            [ngClass]="{'form-control-invalid':isControlInvalid('address')}"
            placeholder="Address">
          </ng-select>
          <div *ngIf="isControlInvalid('address')" class="invalid-feedback">Address is mandatory</div>
        </div>
      </div>

      <div class="form-row col-md-12">
        <div class="form-input">
          <label class="fancy-checkbox _green">
            <input type="checkbox" formControlName="createBOL">
            <span>Generate BOL on order create</span>
          </label>
        </div>
      </div>

      <div class="form-row col-md-12">
        <div class="form-input">
          <label class="fancy-checkbox _green">
            <input type="checkbox" formControlName="sendPODToEmails">
            <span>Send POD to emails</span>
          </label>
        </div>
      </div>
    </form>

    <div class="widget-footer right">
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="viewLoading">OK</button>
      <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
    </div>

  </div>
</perfect-scrollbar>
