import {
  ChangeDetectorRef,
  Component,
  HostBinding, HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {UploadedFiles} from '../../../../modules/shared/models/documents/uploaded.files';
import {HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http';
import {ObjectDocuments} from '../../../../modules/shared/models/documents/object.documents';
import {FileUploadService} from '../../../../services/file.upload.service';
import {isNullOrUndefined} from 'util';
import {OmsAlertsService} from '../../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {OrderDocuments} from '../../../../modules/shared/models/documents/order.documents';
import {MasterPttCreatorDialogComponent} from '../../master-ptt-creator-dialog';
import {DocumentTypeService} from '../../../../services/document-type.service';
import {DocumentType} from '../../../../modules/shared/models/documents/document-type';
import {Observable} from "rxjs";
import {FileUploadDialogComponent} from "../../file-upload-dialog";
import {OmsDialogsService} from "../../oms-dialogs";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {DocumentPreviewComponent} from "../document-preview/document-preview.component";
import {convertMasterNumber, convertOrderNumber} from "../../../../modules/shared/services/oms-converters.service";
import {KeyCode} from "../../../../modules/shared/components/common/input/ng-select-types/ng-select.types";
import {NgSelectComponent} from "@ng-select/ng-select";
import {copyToClipboard, downloadFile, sendEmail} from "../../../../_helpers/utils";
import {OmsObjectType} from "../../../../common/oms-types";
import {Carrier} from "../../../../modules/shared/models/carrier";
import {CarrierService} from "../../../../modules/shared/services/carrier.service";


export type DocCenterTab = 'documents' | 'history' | 'preview';

@Component({
  templateUrl: 'doc-center-page.component.html',
  styleUrls: ['./doc-center-page.component.scss'],
  selector: 'oms-doc-center-page',
//  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocCenterPageComponent implements OnInit {

  @HostBinding('class') class = 'doc-center';
  @ViewChild('preview') preview: DocumentPreviewComponent;
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  @Input() isVisibleHistory: boolean = true;
  @Input() saveDocuments: (objectDocuments: ObjectDocuments, files: FileList) => Observable<HttpEvent<{}>[]>;

  id: number;
  public readonly: boolean = false;

  documents: OrderDocuments[] = [];
  removed: OrderDocuments[] = [];

  public fitToPage: boolean = true;

  objectDocuments = new ObjectDocuments();
  uploadedFiles = new UploadedFiles();
  objectType: OmsObjectType;
  carrier: Carrier;

  activeTab: DocCenterTab = 'documents';
  activeDocument: OrderDocuments;

  selectedFiles: FileList;

  hasFormErrors = false;
  viewLoading = false;
  documentType: DocumentType;
  loadingAfterSubmit = false;
  showProgressBar: boolean = false;
  mappedObject: any = null;

  documentTypes: DocumentType[] = [];
  progress: { percentage: number } = {percentage: 0};

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private dialogs: OmsDialogsService,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute,
              private documentTypeService: DocumentTypeService,
              private fileUploadService: FileUploadService,
              public alertsService: OmsAlertsService,
              public carrierService: CarrierService) {

    this.readonly = false; // data.readonly;

    this.fileUploadService.filesList.subscribe((orderDocumentsList) => {
      console.log('RECEIVED', orderDocumentsList);
      this.documents = orderDocumentsList;
      this.cdr.markForCheck();
    });

    this.fileUploadService.filesRemovedList.subscribe((orderDocumentsList) => {
      console.log('REMOVED', orderDocumentsList);
      this.removed = orderDocumentsList;
    });

    this.documentTypeService.findAllObs({field: 'name', order: 'asc'})
      .subscribe((types) => this.documentTypes = DocumentType.sort(types));

    this.route.queryParams.subscribe((params) => {
      this.id = undefined;

      if (params && params.order) {
        this.id = params.order;
        this.objectType = 'ORDER';
//        carrierService.getOrderCarrier(this.id).subscribe({next: (carrier) => this.carrier = carrier});
      } else if (params && params.master) {
        this.id = params.master;
        this.objectType = 'MASTER';
//        carrierService.getMasterCarrier(this.id).subscribe({next: (carrier) => this.carrier = carrier});
      }

      this.loadDocuments();

    });
  }

  private loadDocuments() {
    console.log('LOAD DOCUMENTS');

    if (this.id) {
      if (this.objectType === 'ORDER') {
        this.carrierService.getOrderCarrier(this.id).subscribe({next: (carrier) => this.carrier = carrier});
      }

//      this.fileUploadService.findAllById(this.id, this.objectType, true);
      this.fileUploadService.findAllById(this.id, this.objectType, this.activeTab === 'documents');
    }
  }

  ngOnInit() {
    this.viewLoading = true;
    this.updateList();
    this.viewLoading = false;
  }

  private updateList(items?: OrderDocuments[]) {
    if (this.id) {
    } else if (items) {
      items.forEach((i) => this.documents.push(i));
    }
  }

  getTitle(): string {
    return 'Document Center';
  }

  getSelectedFileName() {
    return !isNullOrUndefined(this.selectedFiles) && this.selectedFiles.length > 0 ? this.selectedFiles[0].name : '';
  }

  validateForm() {
    this.hasFormErrors = this.documentTypeEmpty || this.selectedFilesEmpty;
    /*    if (this.documentTypeEmpty) {
          this.hasFormErrors = true;
        } else {
          this.hasFormErrors = !!this.selectedFilesEmpty;
        } */
  }

  get selectedFilesEmpty(): boolean {
    return isNullOrUndefined(this.selectedFiles);
  }

  get documentTypeEmpty(): boolean {
    return isNullOrUndefined(this.documentType);
  }


  onSubmit() {

    this.validateForm();
    if (this.hasFormErrors) {
      return;
    }

    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;

    this.progress.percentage = 0;
    this.showProgressBar = true;

    this.objectDocuments.objectId = this.id;
    this.objectDocuments.objectType = this.objectType;
    this.objectDocuments.uploadedFiles = this.uploadedFiles;
    this.fileUploadService.validateIfFilesExist(this.objectDocuments, this.selectedFiles).subscribe((event) => {
      if (!event.fileExist) {
        if (this.saveDocuments) {
          this.uploadDocuments();
        } else {
          this.uploadDocument();
        }
      } else {
        this.alertsService.warning('File Exist');
      }
    });
  }

  private uploadDocument() {
    this.fileUploadService.uploadDocuments(this.objectDocuments, this.selectedFiles).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress.percentage = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.updateList();
        this.progress.percentage = 0;
        this.selectedFiles = undefined;
        this.showProgressBar = false;
      }
    }, (error) => {
      this.alertsService.error(error, 'File Was Not Uploaded');
    });
  }

  private uploadDocuments() {
    this.saveDocuments(this.objectDocuments, this.selectedFiles).subscribe((events) => {
      let progresses = events.filter(event => event.type === HttpEventType.UploadProgress) as HttpProgressEvent[];
      let responses = events.filter(event => event.type !== HttpEventType.UploadProgress && event instanceof HttpResponse) as HttpResponse<OrderDocuments[]>[];
      if (progresses.length) {
        let loaded = progresses.reduce((total, item) => total + item.loaded, 0);
        let totalMb = progresses.reduce((total, item) => total + item.total, 0);
        this.progress.percentage = Math.round(100 * loaded / totalMb);
      } else {
        this.updateList(responses[0].body);
        this.progress.percentage = 0;
        this.selectedFiles = undefined;
        this.showProgressBar = false;
      }
    }, error => {
      this.alertsService.error(error, 'File Was Not Uploaded');
    });
  }

  onUnitsChanged(event: DocumentType) {
    this.documentType = event;
    this.objectDocuments.documentType = event;
  }

/*
  selectFile(event) {
    this.progress.percentage = 0;
    this.showProgressBar = false;
    this.selectedFiles = event.target.files;
  }
 */

  buildDownloadUrl(orderDocument) {
    return this.fileUploadService.buildDownloadFileUrl(orderDocument.id, this.objectType);
  }

  downloadLastPodDocument() {
    downloadFile(this.getLastPodDocumentUrl());
  }

  getLastPodDocumentUrl(): string {
    switch (this.objectType) {
      case "ORDER":
        return this.fileUploadService.buildOrderPodDownloadUrl(this.id);
      case "MASTER":
        return this.fileUploadService.buildMasterPodDownloadUrl(this.id);
    }

  }

  copyPodDownloadUrl() {
    copyToClipboard(this.getLastPodDocumentUrl());
    this.alertsService.info('POD Download Link copied to the Clipboard');
  }

  sendPodDownloadUrl() {
    sendEmail('POD Download Link', this.getLastPodDocumentUrl(), '');
  }

  public hasPOD(): boolean {
    return this.documents && !!this.documents.find((od) => od.isPOD());
  }


  removeDocument(objectDocuments) {
    objectDocuments.objectType = this.objectType;
    objectDocuments.objectId = this.id;
    this.fileUploadService.deleteDocument(objectDocuments).subscribe(() => {
      this.loadDocuments();
    });
    // this.fileUploadService.buildDownloadFileByIdUrl(orderDocument.id);
  }

  restoreDocument(objectDocuments) {
    if (this.isModified(objectDocuments)) {
      objectDocuments.objectType = this.objectType;
      objectDocuments.objectId = this.id;
      this.fileUploadService.restoreDocument(objectDocuments).subscribe(() => {
        this.loadDocuments();
      });
    }
  }


  public allowFilesDrop: (event: DragEvent) => boolean = function(event: DragEvent) {
    return true;
    /*    event.preventDefault();
        const {dataTransfer} = event;
        return dataTransfer && dataTransfer.items && (dataTransfer.items.length > 0) && (dataTransfer.items[0].kind === 'file'); */
  };

  public onFilesDrop(files) {
    this.dialog.open(FileUploadDialogComponent,
      { disableClose: true, width: 'auto', data: {id: this.id, objectType: this.objectType, noDescription: true, files: files, carrier: this.getCarrier()} })
      .afterClosed().subscribe(() => {
      this.loadDocuments();
    });
  }

  getActionType(orderDocument: OrderDocuments) {
    return isNullOrUndefined(orderDocument.dateDeleted) ? isNullOrUndefined(orderDocument.previousDocumentType) ? 'Added' : 'Modified' : 'Deleted';
  }

  getRestoreIcon(orderDocument: OrderDocuments) {
    switch (this.getActionType(orderDocument)) {
      case 'Deleted': return 'fas fa-trash-restore-alt';
      case 'Modified': return 'fas fa-undo-alt';
    }
  }

  getRestoreLabel(orderDocument: OrderDocuments) {
    switch (this.getActionType(orderDocument)) {
      case 'Deleted': return 'Restore Document';
      case 'Modified': return 'Undo Change';
    }
  }


  drop(event) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files) {
      this.selectedFiles = event.dataTransfer.files;
    }
  }

  onChangedType(id, event) {
    if (event) {
      this.viewLoading = true;
      this.fileUploadService.updateDocumentTypeById(id, this.objectType, (<string>event.type).toUpperCase()).subscribe(
        () => {
          this.viewLoading = false;
          this.loadDocuments();
        },
        error => {
          this.viewLoading = false;
          this.alertsService.error(error, 'Unable change document type');
          this.loadDocuments();
        });
    }
  }

  isModified(orderDocument: OrderDocuments) {
    return !(isNullOrUndefined(orderDocument.dateDeleted) && isNullOrUndefined(orderDocument.previousDocumentType));
  }

  changeTab(tab) {
    this.activeTab = tab;
    this.loadDocuments();
  }

  generateMasterMDF() {
    this.dialog.open(MasterPttCreatorDialogComponent, {width: '90%', data: {master: this.mappedObject}});
  }

  downloadHistory() {
    this.fileUploadService.downloadHistory(this.id, this.objectType);
  }

  changeTypeDisabled(orderDocument: OrderDocuments) {
    return orderDocument.documentType.name === 'PTT' && isNullOrUndefined(orderDocument.previousDocumentType);
  }

  public openFileUploadDialog() {
    this.dialog.open(FileUploadDialogComponent,
      { disableClose: true, width: 'auto', data: {id: this.id, objectType: this.objectType, noDescription: true, carrier: this.getCarrier()} })
      .afterClosed().subscribe(() => {
      this.loadDocuments();
    });
  }

  public getFileTypeClass(document: OrderDocuments) {
    let ext = document.uploadedFiles.name.split('\.').last();
    switch (ext) {
      case 'pdf':
        return 'fa fa-file-pdf';

      case 'doc':
      case 'docx':
        return 'fa fa-file-text';

      case 'xls':
      case 'xlsx':
        return 'fa fa-file-excel';

      case 'jpg':
      case 'png':
      case 'bmp':
        return 'fa fa-file-image';

      default:
        return 'fa fa-file';
    }

  }

  public onPreview(document: OrderDocuments) {
    this.activeDocument = document;
    this.activeTab = 'preview';
    this.cdr.detectChanges();
/*    if (document) {
      if (document.uploadedFiles.name.endsWith('.pdf')) {
        this.dialog.open(DocumentPreviewDialogComponent, {
          disableClose: true, width: 'auto',
          data: {document: document, documents: this.documents}
        });
      } else {
        this.alertsService.warning('No preview supported for File Type');
      }
    } */
  }

  onDragStart(event: DragEvent, document: OrderDocuments) {
    const url = this.fileUploadService.buildDownloadFileUrl(document.id, this.objectType);
    const name = document.uploadedFiles.name;

    event.dataTransfer.effectAllowed = "copy";
    event.dataTransfer.setData('DownloadURL', `application/octet-stream:${name}:${url}`);
  }

  public download(document: OrderDocuments) {
    if (document) {
        this.fileUploadService.download(document.id, this.objectType);
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent) {
    this.activeTab = 'documents';
  }

  getPageLabel() {
    if (!this.id) {
      return 'Document Center';
    }

    return this.objectType === 'MASTER' ? convertMasterNumber(this.id) : convertOrderNumber(this.id);
      // this.activeTab === 'preview' && this.preview && this.activeDocument ? this.activeDocument.documentType.description :
      // ;
  }

  onKeyDown(event, id) {
    if (event.keyCode === KeyCode.Tab) {
      if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value && this.ngSelect.itemsList.markedItem.value) {
        let documentType = this.ngSelect.itemsList.markedItem.value as DocumentType;
        this.fileUploadService.updateDocumentTypeById(id, this.objectType, documentType.type).subscribe(() => {
          this.loadDocuments();
        });
      }
    }
  }

  public getCarrier(): Carrier {
    return this.carrier;
  }

}
