import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Truck} from '../../../modules/shared/models/truck';
import {StringInputComponent} from '../../../modules/shared/components/common/input/string-input/string-input.component';

@Component({
  selector: 'app-truck-dialog',
  templateUrl: './truck-dialog.component.html',
  styleUrls: ['./truck-dialog.component.scss']
})
export class TruckDialogComponent {
  public truck: Truck;
  public readonly: boolean = false;
  @ViewChild('strNumber') input: StringInputComponent;

  constructor(
      public dialogRef: MatDialogRef<TruckDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
    this.readonly = data.readonly;
    this.truck = data.truck;
  }


  onOk(event) {
    if (this.input.isInvalid || !this.truck.number || this.truck.number.length==0)  {
      this.input.touchControl();
      event.canClose = false;
    }
  }


}
