import { EventEmitter, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from "./auth.service";
import { UserRoleType } from "../modules/shared/models/user-role.type";
var NavigationService = /** @class */ (function () {
    function NavigationService(http, rendererFactory, authService) {
        var _this = this;
        this.http = http;
        this.authService = authService;
        this.leftSideBarVisible = false;
        this.leftSideBarMinified = false;
        this._leftSideBarFloating = false;
        this.NARROW_WIDTH = 768; // from bootstrap Medium-Width css parameters
        this.MEDIUM_WIDTH = 992; // from bootstrap Medium-Width css parameters
        this.leftSideBarVisibility = new BehaviorSubject(this.leftSideBarVisible);
        this.leftSideBarMinify = new BehaviorSubject(this.leftSideBarMinified);
        this.leftSideBarFloat = new BehaviorSubject(this.leftSideBarFloating);
        this.screenSizeChange = new EventEmitter();
        this.leftSideBarVisible = localStorage.getItem('leftSideBarVisible') === 'true';
        this.leftSideBarMinified = localStorage.getItem('leftSideBarMinified') === 'true';
        this._leftSideBarFloating = localStorage.getItem('leftSideBarFloating') === 'true';
        this.authService.user$.subscribe(function () { return _this.updateState(); });
        this.calcScreenWidth(window.innerWidth);
        this.renderer = rendererFactory.createRenderer(null, null);
        this.renderer.listen(window, 'resize', function () {
            var wasWideScreen = _this.wideScreen;
            var wasNarrowScreen = _this.narrowScreen;
            var wasMediumScreen = _this.mediumScreen;
            _this.calcScreenWidth(window.innerWidth);
            if (wasMediumScreen !== _this.mediumScreen || wasNarrowScreen !== _this.narrowScreen || _this.wideScreen !== wasWideScreen) {
                _this.screenSizeChange.emit();
                _this.leftSideBarFloat.next(_this.leftSideBarFloating);
            }
        });
    }
    NavigationService.prototype.updateState = function () {
        var _this = this;
        this.authService.currentUserHasRole(UserRoleType.ROLE_ADMIN)
            .subscribe(function (haveAdminRole) {
            _this.leftSideBarVisible = localStorage.getItem('leftSideBarVisible') === 'true' && haveAdminRole;
            _this.leftSideBarMinified = localStorage.getItem('leftSideBarMinified') === 'true' || !haveAdminRole;
            _this._leftSideBarFloating = localStorage.getItem('leftSideBarFloating') === 'true' && haveAdminRole;
        });
    };
    NavigationService.prototype.calcScreenWidth = function (windowWidth) {
        this.narrowScreen = windowWidth < this.NARROW_WIDTH;
        this.wideScreen = windowWidth >= this.MEDIUM_WIDTH;
        this.mediumScreen = !this.narrowScreen && !this.wideScreen;
    };
    Object.defineProperty(NavigationService.prototype, "leftSideBarFloating", {
        get: function () {
            return this.narrowScreen || this._leftSideBarFloating;
        },
        enumerable: true,
        configurable: true
    });
    NavigationService.prototype.toggleLeftSideBar = function () {
        if (this.leftSideBarVisible) {
            this.hideLeftSideBar();
        }
        else {
            this.showLeftSideBar();
        }
    };
    NavigationService.prototype.hideLeftSideBar = function () {
        var _this = this;
        setTimeout(function () {
            _this.leftSideBarVisible = false;
            localStorage.setItem('leftSideBarVisible', _this.leftSideBarVisible.toString());
            _this.leftSideBarVisibility.next(_this.leftSideBarVisible);
        });
    };
    NavigationService.prototype.showLeftSideBar = function () {
        var _this = this;
        setTimeout(function () {
            _this.leftSideBarVisible = true;
            localStorage.setItem('leftSideBarVisible', _this.leftSideBarVisible.toString());
            _this.leftSideBarVisibility.next(_this.leftSideBarVisible);
        });
    };
    NavigationService.prototype.toggleLeftSideBarMinified = function () {
        this.leftSideBarMinified = !this.leftSideBarMinified;
        localStorage.setItem('leftSideBarMinified', this.leftSideBarMinified.toString());
        this.leftSideBarMinify.next(this.leftSideBarMinified);
    };
    NavigationService.prototype.toggleLeftSideBarFloating = function () {
        if (this.wideScreen) {
            this._leftSideBarFloating = !this._leftSideBarFloating;
            localStorage.setItem('leftSideBarFloating', this._leftSideBarFloating.toString());
            this.leftSideBarFloat.next(this._leftSideBarFloating);
        }
    };
    return NavigationService;
}());
export { NavigationService };
