var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from "../../../services/base/base-entity.service";
import { User } from "../models";
import { RestService } from "../../../services/rest.service";
import { HttpUtilsService } from "../../../services/http-utils.service";
import { classToPlain, plainToClass } from 'class-transformer';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from "../../../services";
import * as i0 from "@angular/core";
import * as i1 from "../../../services/rest.service";
import * as i2 from "../../../services/http-utils.service";
import * as i3 from "../../../services/auth.service";
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService(httpRestService, httpUtils, authService) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.authService = authService;
        _this.apiUrl = "/api/users/";
        _this.classType = User;
        _this.currentUser$ = new ReplaySubject(1);
        _this.authService.user$.subscribe(function (loggedUser) {
            if (loggedUser) {
                _this.currentUser$.next(loggedUser);
            }
            else {
                // this.currentUser$.next(null);
            }
        });
        return _this;
    }
    UserService.prototype.getCurrentUser = function () {
        return this.currentUser$.asObservable();
    };
    UserService.prototype.findUserByDriverId = function (driverId, active) {
        var _this = this;
        if (active === void 0) { active = true; }
        var params = this.httpUtils.getHTTPParams({});
        return this.httpRestService.getWithOptions(this.apiUrl + 'findUserByDriverId/' + driverId, this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (item) { return plainToClass(_this.classType, item); }));
    };
    UserService.prototype.findUsersByRoles = function (roles, active, searchText) {
        var _this = this;
        if (active === void 0) { active = true; }
        if (searchText === void 0) { searchText = ''; }
        var params = this.httpUtils.getHTTPParams({
            roles: roles,
            searchText: searchText,
            active: active
        });
        return this.httpRestService.getWithOptions(this.apiUrl + 'findUsersByRoles', this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (res) {
            res.content = res.content.map(function (item) { return plainToClass(_this.classType, item); });
            return res;
        }));
    };
    UserService.prototype.create = function (item) {
        var _this = this;
        return this.httpRestService.post(this.apiUrl + 'createDto', classToPlain(item))
            .pipe(map(function (response) { return plainToClass(_this.classType, response); }));
    };
    UserService.prototype.update = function (item) {
        var _this = this;
        return this.httpRestService.put(this.apiUrl + 'updateDto', classToPlain(item), { headers: this.httpUtils.getHTTPHeaders() })
            .pipe(map(function (response) { return plainToClass(_this.classType, response); }));
    };
    UserService.prototype.getExternalDriver = function () {
        return this.httpRestService.getWithHeader(this.apiUrl + 'get-external-driver', this.httpUtils.getHTTPHeaders());
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService), i0.inject(i3.AuthService)); }, token: UserService, providedIn: "root" });
    return UserService;
}(BaseEntityService));
export { UserService };
