import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input, OnDestroy,
  OnInit, Renderer2,
  ViewChild
} from '@angular/core';
import {AuthService, NavigationService} from '../../../../services';
import {LeftSidebarComponent} from './left-sidebar';
import {FooterComponent} from './footer';
import {Subscription} from 'rxjs';
import {PopoverConfig} from "ngx-bootstrap";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'oms-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  public currentUser: any;
  @Input() breadcrumbs: boolean = true;
  @Input() notifications: boolean = true;

  sub: Subscription[] = [];

  @ViewChild('leftSideBar') leftSideBar: LeftSidebarComponent;
  @ViewChild('mainContentWrapper') contentWrapper: ElementRef<HTMLElement>;
  @ViewChild('footer') footer: FooterComponent;

  constructor(
    private route: ActivatedRoute,
    public popoverConfig: PopoverConfig,
    private cdr: ChangeDetectorRef,
    public authService: AuthService,
    public navigationService: NavigationService,
    private renderer: Renderer2) {

//    this.popoverConfig.outsideClick = true;
    this.popoverConfig.container = 'body';
    this.popoverConfig.adaptivePosition = false;
    this.popoverConfig.triggers = 'hover';

    if (route.snapshot.data.hasOwnProperty('notifications')) {
      this.notifications = route.snapshot.data['notifications'];
    }
  }

  ngOnInit() {
    this.calculateContentHeight();
    this.renderer.addClass(document.body, "ontop-nav");
    this.sub.push(this.navigationService.leftSideBarFloat.subscribe((float) => {
      if (float) {
        this.renderer.addClass(document.body, 'sidebar-float');
      } else {
        this.renderer.removeClass(document.body, 'sidebar-float');
      }
    }));

    // why does not it works on subscribe to Behaviour Subject? need following lines:
    if (this.navigationService.leftSideBarFloating) {
      this.renderer.addClass(document.body, 'sidebar-float');
    }
  }

  // Calculate page content height to be aligned to the footer's top
  private calculateContentHeight() {
//    if (this.leftSideBar)
//      this.contentWrapper.nativeElement.style.setProperty('min-height', this.leftSideBar.getBottom()+'px');
    let space: number = this.contentWrapper.nativeElement.getBoundingClientRect().top + this.footer.getHeight();
//    console.log('calculateContentHeight', space, window.innerHeight);
    this.contentWrapper.nativeElement.style.setProperty('height', window.innerHeight - space + 'px');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateContentHeight();
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub) => sub.unsubscribe());
  }

  onMenuItemOpen(event) {
    this.calculateContentHeight();
    console.log('onMenuItemOpen', event);
  }

}
