var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgModel, } from '@angular/forms';
import { ElementBase } from '../../element-base';
var FormSelectComponent = /** @class */ (function (_super) {
    __extends(FormSelectComponent, _super);
    function FormSelectComponent(validators, asyncValidators) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.identifier = "form-select-" + identifier++;
        return _this;
    }
    Object.defineProperty(FormSelectComponent.prototype, "invalidPublic", {
        get: function () {
            return this.invalid;
        },
        enumerable: true,
        configurable: true
    });
    return FormSelectComponent;
}(ElementBase));
export { FormSelectComponent };
var identifier = 0;
