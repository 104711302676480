<!--oms-widget [flex]="true"-->

<!--/oms-widget-->
<oms-spinner [show]="loading" >
  <div id="outerContainer" style="height: 100%;">


    <div class="carousel-container">
      <div class="carousel-cells">
        <perfect-scrollbar [config]="{suppressScrollX: false}" *ngIf="isPdf">
          <div class="pdf-container">
            <pdf-viewer #pdf (after-load-complete)="pdf.updateSize()"
              [src]="source | async"
              [rotation]="rotate"
              [original-size]="true"
              [show-all]="true"
              [fit-to-page]="fitToPage"
              [zoom]="1"
              [stick-to-page]="true"
              [render-text]="false"
              [external-link-target]="'blank'"
              [autoresize]="true">
            </pdf-viewer>
          <!--embed type="application/pdf"  [src]="dataUrl"-->
          </div>
        </perfect-scrollbar>


        <div class="image-container" *ngIf="isImage">
          <perfect-scrollbar [config]="{suppressScrollY: false, suppressScrollX: false}">
          <img #image class="image-preview" [alt]="active?.uploadedFiles.name" [title]="'filename.ext'" [src]="dataUrl"
               [ngStyle]="{'transform': 'rotate(' + rotate + 'deg)',
                'height': image?.height<image?.width ? 'auto' : '100%',
                'width': image?.height<image?.width ? '100%' : 'auto'}">
          </perfect-scrollbar>
        </div>

        <div class="preview-error" *ngIf="error">
          <div class="error-text">
          {{error}}
          </div>
        </div>
      </div>


      <div class="carousel-dots" >
        <div class="carousel-dot" *ngFor="let doc of documents" [ngClass]="{'carousel-dot-active': doc === active}" (click)="setActive(doc)">
        </div>
      </div>

      <div class="document-info" *ngIf="!!active">
        <h4 style="float: left; margin-left: 10px">{{active?.documentType?.description}}</h4>
        <em style="line-height: 2.5em;">{{active?.uploadedFiles?.name}}</em>
      </div>


    </div>

    <div class="carousel-arrows">
      <div class="carousel-arrow carousel-arrow-prev" [ngClass]="{'carousel-arrow-disabled': isFirst()}" (click)="previous()"></div>
      <div class="carousel-arrow carousel-arrow-next" [ngClass]="{'carousel-arrow-disabled': isLast()}" (click)="next()"></div>
    </div>


    <!--div class="viewer-error" *ngIf="error">
      {{error}}
    </div -->

  </div>
</oms-spinner>
