var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { Truck } from '../modules/shared/models';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { TruckDialogComponent } from '../components/dialogs/truck-dialog/truck-dialog.component';
import { ModalResult, OmsDialogsService } from "../components/common/oms-dialogs";
var TruckService = /** @class */ (function (_super) {
    __extends(TruckService, _super);
    function TruckService(dialogs, httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.dialogs = dialogs;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/truck/';
        _this.classType = Truck;
        return _this;
    }
    TruckService.prototype.createEditAndSave = function (truck, readonly) {
        var _this = this;
        return new Promise(function (success, reject) {
            _this.dialogs.openDialog(TruckDialogComponent, { truck: truck, readonly: readonly })
                .then(function (result) {
                if (result.result === ModalResult.OK) {
                    _this.save(truck).subscribe(function (saved) { return success(saved); }, function (error) { return reject(error === 'DataIntegrityViolation' ? 'Can\'t create new Truck: \n Truck ' + truck.number + ' already exists!' : error); });
                }
            })
                .catch(function (error) { return reject(error); });
        });
    };
    return TruckService;
}(BaseEntityService));
export { TruckService };
