import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {RestService} from '../../../services/rest.service';
import {HttpUtilsService} from '../../../services/http-utils.service';
import {City} from '../models';
import {BaseEntityService} from '../../../services/base/base-entity.service';

const API_URL = '/oms/city/';

@Injectable()
export class CityService extends BaseEntityService<City> {

  apiUrl = API_URL;
  classType = City;
  public citiesSubject = new Subject<City[]>();

  public constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  getAllCitiesByCountryId(countryId: number): Observable<City[]> {
    return new Observable((obs) => {
      this.httpRestService.get<City[]>(API_URL + 'getAllCitiesByCountryId', countryId).subscribe(items => {
        this.citiesSubject.next(items);
        obs.next(items);
        obs.complete();
      });
    });
  }
}
