var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Driver } from "../driver";
import { Exclude, Type } from "class-transformer";
import { OrderDispatch } from "./order-dispatch";
var ɵ0 = function () { return Driver; }, ɵ1 = function () { return Date; }, ɵ2 = function () { return Date; }, ɵ3 = function () { return OrderDispatch; };
var DriverRoute = /** @class */ (function () {
    function DriverRoute() {
    }
    Object.defineProperty(DriverRoute.prototype, "isNew", {
        get: function () {
            return !this.dispatchId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DriverRoute.prototype, "isAssigned", {
        get: function () {
            return !!this.driver;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DriverRoute.prototype, "isApproved", {
        get: function () {
            return !!this.dateApprovedOn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DriverRoute.prototype, "isCompleted", {
        get: function () {
            return !!this.dateRouteCompleted;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Driver)
    ], DriverRoute.prototype, "driver", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Date)
    ], DriverRoute.prototype, "dateApprovedOn", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Date)
    ], DriverRoute.prototype, "dateRouteCompleted", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Array)
    ], DriverRoute.prototype, "orders", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DriverRoute.prototype, "isNew", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DriverRoute.prototype, "isAssigned", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DriverRoute.prototype, "isApproved", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DriverRoute.prototype, "isCompleted", null);
    return DriverRoute;
}());
export { DriverRoute };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
