﻿import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder} from "@angular/forms";
import {LogChanges} from "../../../modules/shared/models/logchanges/log.changes";
import {BaseColumn} from "../../../modules/shared/components/base/data-table/columns/column-types";
import {
  convertDateTimeWithYear,
  OmsDateTimePipe
} from "../../../modules/shared/services/oms-converters.service";
import {LogChangesService} from "../../../services/logchanges/log.changes.service";
import {MasterStatus, MasterStatusId} from "../../../modules/shared/models";
import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {StringUtil} from "../../../_helpers/string.util";


export interface HistoryInformationConfig {
  id: number;
  field: string;
  className: string;
  key?: string;
  converter?: (any) => string;
}

@Component({
  templateUrl: 'history-information.dialog.component.html',
  styleUrls: ['./history-information.dialog.component.scss'],
  selector: 'oms-history-information.dialog',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryInformationDialogComponent implements OnInit {

  logChanges: LogChanges[] = [];
  column: BaseColumn;
  convertByBaseType: boolean = false;
  converter: (any) => string;
  omsDatePipe: OmsDateTimePipe = new OmsDateTimePipe;
  logParams: any[];
  selectedParameter: string;
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<HistoryInformationDialogComponent>,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private alerts: OmsAlertsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private logChangesService: LogChangesService) {

  }

  ngOnInit() {
    this.isLoading = true;
    console.log('LOG', this.data);
    this.logParams = this.data.logParams;
    this.selectedParameter = this.logParams && this.logParams[0].key;
    this.converter = this.logParams && this.logParams[0].converter;
    this.logChangesService.getLogChanges(this.logParams[0].id, this.logParams[0].className, this.logParams[0].field)
      .then(logChanges => {
        this.logChanges = logChanges;
        this.isLoading = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }).catch((error) => {
        this.isLoading = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        this.alerts.error(error);
      });
    this.column = this.data.column;
    this.convertByBaseType = this.data.convertByBaseType;
  }

  public convertFieldName(field: string): string {
    return StringUtil.readableFromCamelCase(field);
  }

  convertValue(value: any, logChanges: LogChanges) {
    if (logChanges.valueType === 'com.oms.entity.master.MasterStatus') {
      if (value && value.id) {
        return MasterStatus.getLabel(value.id);
      }
      return MasterStatus.getLabel(MasterStatusId.NEW);
    } else if (logChanges.valueType === 'java.util.Date') {
      let date = new Date(value);
      return convertDateTimeWithYear(date);
    } if (this.column) {
      console.log('0');
      return this.column.convert(null, value);
    } else if (this.converter) {
      console.log('1');
      return this.converter(value);
    } else {
      return this.getConvertedValueByLogChanges(value, logChanges);
    }
  }

  getConvertedValueByLogChanges(value: any, logChanges: LogChanges) {
    if (value && (logChanges.valueType.indexOf("Date") > -1 || logChanges.valueType.indexOf("Timestamp") > -1)) {
      let date = new Date(value);
      return this.omsDatePipe.transform(date);
    }
    return value;
  }

  getTitle(): string {
    return 'History Information';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeLogField(param: any) {
    this.selectedParameter = param.key;
    this.isLoading = true;
    this.logChangesService.getLogChanges(param.id, param.className, param.field).then(logChanges => {
      this.logChanges = logChanges;
      this.isLoading = false;
      this.cdr.markForCheck();
    });
  }
}
