import { Injectable } from '@angular/core';
import {RestService} from "../rest.service";
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {HttpClient} from '@angular/common/http';
import {LogChanges} from "../../modules/shared/models/logchanges/log.changes";
import {ObjectLogChanges} from "../../modules/shared/models/logchanges/object.log.changes";
import {BaseColumn} from "../../modules/shared/components/base/data-table/columns/column-types";
import {assigned} from "../../_helpers/utils";

const API_URL = "/oms/logchanges/";
@Injectable()
export class LogChangesService {

  constructor(
    private http: HttpClient,
    private httpRestService: RestService) {

  }

  getLogChanges(id: number, className: string, field?: string): Promise<LogChanges[]> {
    return (assigned(field) ? this.httpRestService.get<LogChanges[]>(API_URL + 'getLogsForField', id, className, field) :
      this.httpRestService.get<LogChanges[]>(API_URL + 'getLogsForObject', id, className))
      .pipe<LogChanges[]>(map((o) => plainToClass(LogChanges, o)))
      .toPromise();
  }

  getLogChangesForObject(id: number, className: string): Promise<LogChanges[]> {
    return this.httpRestService.get<LogChanges[]>(API_URL + 'getLogsForObject', id, className)
      .pipe<LogChanges[]>(map((o) => plainToClass(LogChanges, o)))
      .toPromise();
  }

  getMasterProblemsInfo(id: number): Promise<ObjectLogChanges[]> {
    return this.httpRestService.get<ObjectLogChanges[]>(API_URL + 'getMasterProblemsInfo', id)
      .pipe<ObjectLogChanges[]>(map((o) => plainToClass(ObjectLogChanges, o)))
      .toPromise();
  }


  getRequiredLogCenterParameters(target: any, columnField, column?: BaseColumn) {
    let result = [];
    if (typeof columnField !== "string") {
      let fieldKeys = Object.keys(columnField);
      fieldKeys.forEach((key) => result.push(this.getLogParams(target, columnField[key], key, column)));
    } else {
      result.push(this.getLogParams(target, columnField, "default", column));
    }
    return result;
  }

  getLogParams(target: any, columnField, key, column?: BaseColumn) {
    let response: any = {};
    let splittedField = columnField.split(".");
    if (splittedField.length === 1) {
      response.className = target.javaClassName;
      response.id = target.id;
      response.field = columnField;
    } else {
      response.field = splittedField.pop();
      let fieldObject = this.findFieldObject(target, splittedField);
      response.className = fieldObject.javaClassName;
      response.id = fieldObject.id;
    }
    response.key = key;

    if (column && column.history && column.history.useLinkParamsBasedOnColumn) {
      response.className = column.history.className;
      response.id = target[column.history.useId].id;
    }

    return response;
  }

  findFieldObject(target, fields) {
    let field = fields.shift();
    let newTarget = target[field];

    return fields.length === 0 ? newTarget : this.findFieldObject(newTarget, fields);
  }
}
