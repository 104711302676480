import {Injectable} from '@angular/core';
import {Labeled} from '../../../../common/oms-types';
import {TransformationType} from 'class-transformer';

export enum MasterStatusId {
  NEW = 0,
  ACKNOWLEDGED = 1,
  FLIGHT_ARRIVED = 2,
  RECOVERY_DISPATCHED = 3,
  PROBLEM_RECOVERY = 4,

  RECOVERED_PENDING_ONHAND = 5,
  ONHAND_COMPLETE_PENDING_1C = 6,
  ONHAND_COMPLETE_READY_FOR_DISPATCH = 7,
  DISPATCHED_FOR_JJS_DELIVERY = 8,
  PROBLEM = 9,

  PICKED_UP_FROM_JJS = 10,
  DELIVERED_PENDING_POD = 11,
  ORDER_COMPLETE_READY_TO_BILL = 12,

  CLOSED = 13,
  VOIDED = 14
}

export function transformMasterStatusId(value: any, obj: any, transformationType: TransformationType): any {
  switch (transformationType) {
    case TransformationType.PLAIN_TO_CLASS: if (!value) { console.warn('1 no status of object:', obj); } return value ? value.id : undefined;
    case TransformationType.CLASS_TO_PLAIN: return {id: value};
    default: return value;
  }
}

export function transformMasterStatus(value: any, obj: any, transformationType: TransformationType): any {
  switch (transformationType) {
    case TransformationType.PLAIN_TO_CLASS: if (!value) { console.warn('2 no status of object:', obj); }return value ? MasterStatus.get(value.id) : undefined;
    case TransformationType.CLASS_TO_PLAIN: return {id: value ? value.id : undefined};
    default: return value;
  }
}


export class MasterStatus implements Labeled {
  public static items: MasterStatus[] = [];
  public static VOIDED: MasterStatus;
  private static map: Map<MasterStatusId, MasterStatus> = new Map<MasterStatusId, MasterStatus>();

  id: MasterStatusId;
  name: string;
  label: string;
  warning: boolean;
  publicLabel: string;

  constructor(id: MasterStatusId, label: string, name: string, publicLabel?: string, warning?: boolean) {
    this.label = label;
    this.name = name;
    this.id = id;
    this.warning = warning;
    this.publicLabel = publicLabel;
  }

  public static getLabel(id: MasterStatusId): string {
    return MasterStatus.map.has(id) ?  this.map.get(id).label : null;
  }

  public static getName(id: MasterStatusId): string {
    return MasterStatus.map.has(id) ?  this.map.get(id).name : null;
  }

  public static get(id: MasterStatusId): MasterStatus {
    return this.map.has(id) ?  this.map.get(id) : null;
  }

  public static clone(status: MasterStatus): MasterStatus {
    return status ? new MasterStatus(status.id, status.label, status.name, status.publicLabel, status.warning) : null;
  }

  public static getPublicLabel(id: MasterStatusId): string {
    return MasterStatus.map.has(id) ?  this.map.get(id).publicLabel : null;
  }

/*
  public static getCfsStatuses(): MasterStatus[] {
    let cfsIds = this.getCfsStatusesIds();
    return MasterStatus.items.filter(item => {
      return cfsIds.includes(item.id);
    });
  } */

  public static get cfsStatusesIds(): MasterStatusId[] {
    return [
      MasterStatusId.ONHAND_COMPLETE_PENDING_1C
    ];
  }

  public static get whseStatusesIds(): MasterStatusId[] {
    return [
      MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH,
      MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY,
      MasterStatusId.PROBLEM
    ];
  }

/*
  public static getDeliveryStatuses(): MasterStatus[] {
    let ids = this.getDeliveryStatusesIds();
    return MasterStatus.items.filter(item => {
      return ids.includes(item.id);
    });
  }
*/

  public static get deliveryStatusesIds(): MasterStatusId[] {
    return [MasterStatusId.PICKED_UP_FROM_JJS];
  }

  public static get allStatusesIds(): MasterStatusId[] {
    return MasterStatus.items.map(item => item.id);
  }

  static initialize() {
    MasterStatus.items.push(

      // 1. Recovery Stages: RECOVERY TAB
      new MasterStatus(MasterStatusId.NEW, '1.a. New', '1.a.', 'Pending recovery'),
      new MasterStatus(MasterStatusId.ACKNOWLEDGED, '1.b. Acknowledged', '1.b.', 'Pending recovery'),
      new MasterStatus(MasterStatusId.FLIGHT_ARRIVED, '1.c. Flight Arrived', '1.c.', 'Pending recovery'),
      new MasterStatus(MasterStatusId.RECOVERY_DISPATCHED, '1.d. Recovery Dispatched', '1.d.', 'Pending recovery'),
      new MasterStatus(MasterStatusId.PROBLEM_RECOVERY, '1.e. Problem Recovery', '1.e.', 'Pending recovery'),

      // 2. CFS and Delivery:  CFS & DELIVERY TAB

      new MasterStatus(MasterStatusId.RECOVERED_PENDING_ONHAND, '2.a. Recovered Pending OnHand', '2.a.', 'Recovered, pending on hand'),
      new MasterStatus(MasterStatusId.ONHAND_COMPLETE_PENDING_1C, '2.b. OnHand Complete Pending 1C', '2.b.', 'On hand, pending customs'),
      new MasterStatus(MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH, '2.c. On Hand Complete Ready for Dispatch', '2.c.', 'On hand, ready for dispatch (released)'),
      new MasterStatus(MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY, '2.d. Dispatched for JJS Delivery', '2.d.', 'Out for Delivery'),
      new MasterStatus(MasterStatusId.PROBLEM, '2.e. Problem', '2.e.', ''),

      // 3. Billing

      new MasterStatus(MasterStatusId.PICKED_UP_FROM_JJS, '3.a. CFS Out', '3.a.', 'Delivered'),
      new MasterStatus(MasterStatusId.DELIVERED_PENDING_POD, '3.b. Delivered/picked up pending POD', '3.b.', 'Delivered'),
      new MasterStatus(MasterStatusId.ORDER_COMPLETE_READY_TO_BILL, '3.c. Ready to bill', '3.c.', 'Delivered'),

      // 4. Closed (all)

      new MasterStatus(MasterStatusId.CLOSED, '4.a. Closed', '4.a.', 'Delivered'),
    );
    MasterStatus.items.forEach((status) => {MasterStatus.map.set(status.id, status); });

    MasterStatus.VOIDED = new MasterStatus(MasterStatusId.VOIDED, '8. Voided', '8.', 'Delivered', true);
    MasterStatus.map.set(MasterStatus.VOIDED.id, MasterStatus.VOIDED);
  }
}

MasterStatus.initialize();

@Injectable()
export class MasterStatusService {
  public getLabel(id: MasterStatusId): string {return MasterStatus.getLabel(id); }
  get items(): MasterStatus[] { return MasterStatus.items; }
}


