import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {OmsColumns} from "../../../common/oms-columns.service";
import {BaseColumn} from "../../../modules/shared/components/base/data-table/columns/column-types";
import {AbstractEntityListComponent} from "../../../modules/settings/pages/abstract-entity-list.component";
import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {OmsDialogsService} from "../oms-dialogs/oms-dialogs.service";
import {FileUploadService} from '../../../services/file.upload.service';
import {FilterPageRequest} from "../../../modules/shared/models/filter.page.request";
import {SearchSortRequest} from "../../../modules/shared/models/search.sort.request";
import {FilterSearchColumn} from "../../../modules/shared/models/filter.search.column";
import {MawbTrack} from "../../../modules/shared/models/mawb.track";
import {MawbTrackService} from "../../../modules/shared/services/mawb-track/mawb.track.service";

@Component({
  selector: 'oms-mawb-track-dialog',
  templateUrl: './mawb-track-dialog.component.html',
  styleUrls: ['./mawb-track-dialog.component.scss']
})
export class MawbTrackDialogComponent extends AbstractEntityListComponent<MawbTrack> implements OnInit {

  public specificRowClassHandler = [
    {
      field: "inSystem",
      value: "false",
      class: "table-row-missed"
    }
  ];

  mawbTracks: MawbTrack[] = [];
  columns: BaseColumn[];

  defaultSortColumn: string = "id";

  constructor(public dialogRef: MatDialogRef<MawbTrackDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              protected cdr: ChangeDetectorRef,
              protected alerts: OmsAlertsService,
              protected dialogs: OmsDialogsService,
              public mawbTrackService: MawbTrackService,
              private omsColumns: OmsColumns,
              protected fileUploadService: FileUploadService) {
    super(cdr, alerts, mawbTrackService, dialogs, fileUploadService);
    this.mawbTrackService.asmDataSubject.subscribe(mawbTrack => {
      this.mawbTracks = mawbTrack;
    });
  }


  ngOnInit() {
    this.omsColumns.asmDataColumns.subscribe(columns => {
      this.columns = columns;
    });
    this.omsColumns.getAmsDataColumns();
    this.mawbTrackService.findAllByMasterId(this.data.id);
  }

  getTitle():string {
    return 'ASM Data';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  generateExcel() {
    let searchField = "masterId";
    let filterPageRequest =  this.createDefaultRequest(searchField);
    this.request = filterPageRequest;
    this.downloadExcel();
    this.dialogRef.close();
  }

  createDefaultRequest(searchField: string): FilterPageRequest {
    let filterSearchColumn = new FilterSearchColumn(searchField, this.data.id, null, null);
    return new FilterPageRequest(1, 999, '', new SearchSortRequest(searchField, true), [filterSearchColumn]);
  }
}
