var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from '../../../services/base/base-entity.service';
import { RestService } from '../../../services/rest.service';
import { HttpUtilsService } from '../../../services/http-utils.service';
import { UserVisibilityFilter } from "../models/uservisibilityfilter/user-visibility-filter";
import { classToPlain, plainToClass } from "class-transformer";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../../../services/rest.service";
import * as i2 from "../../../services/http-utils.service";
var UserVisibilityService = /** @class */ (function (_super) {
    __extends(UserVisibilityService, _super);
    function UserVisibilityService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/api/user-visibility-filters/';
        _this.classType = UserVisibilityFilter;
        return _this;
    }
    UserVisibilityService.prototype.create = function (item) {
        var _this = this;
        return this.httpRestService.post(this.apiUrl + 'createDto', classToPlain(item))
            .pipe(map(function (response) { return plainToClass(_this.classType, response); }));
    };
    UserVisibilityService.prototype.update = function (item) {
        var _this = this;
        return this.httpRestService.put(this.apiUrl + 'updateDto', classToPlain(item), { headers: this.httpUtils.getHTTPHeaders() })
            .pipe(map(function (response) { return plainToClass(_this.classType, response); }));
    };
    UserVisibilityService.ngInjectableDef = i0.defineInjectable({ factory: function UserVisibilityService_Factory() { return new UserVisibilityService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: UserVisibilityService, providedIn: "root" });
    return UserVisibilityService;
}(BaseEntityService));
export { UserVisibilityService };
