var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BaseInputComponent } from '../base/base-input/base-input.component';
var OmsCheckboxComponent = /** @class */ (function (_super) {
    __extends(OmsCheckboxComponent, _super);
    function OmsCheckboxComponent(validators, asyncValidators) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.indeterminate = false;
        _this.type = 'default';
        _this.mark = 'check';
        _this.condensed = false;
        _this.checkedChange = new EventEmitter();
        return _this;
    }
    OmsCheckboxComponent.prototype.onChange = function (event) {
        this.value = event;
        this.checkedChange.emit(event);
    };
    OmsCheckboxComponent.prototype.writeValue = function (value) {
        this.checked = value;
        _super.prototype.writeValue.call(this, value);
    };
    return OmsCheckboxComponent;
}(BaseInputComponent));
export { OmsCheckboxComponent };
