import {BaseEntity} from "../base-entity";
import {Exclude, Transform, Type} from "class-transformer";
import {Address, Customer, FreightForwarder, LoadType, Order, Shipment, Trailer} from '..';
import {PackagingUnits} from "../../../../common/oms-unit-types";
import {OrderDispatch} from "../dispatch/order-dispatch";
import {DispatchShipmentUld} from './dispatch-shipment-uld';
import {transformLoadType} from "../load";
import {isNullOrUndefined} from "util";
import {OrderMode, OrderModes} from "../order/order-mode";
import {assigned} from "../../../../_helpers/utils";

export class ManifestItem extends BaseEntity {
  /* deprecated */ masterId: number;
  /* deprecated */ orderId: number;
  shipmentId: number;

  @Exclude()
  selected: boolean;

  /* deprecated */ mawb: string;
  /* deprecated */ hawb: string;

  customerRef: string;
  @Type(() => Customer)
  customer: Customer;
  @Type(() => FreightForwarder)
  freightForwarder: FreightForwarder;

  @Type(() => Address)
  addressPickUp: Address;

  @Type(() => Address)
  addressDelivery: Address;

  hu: number;
  @Type(() => PackagingUnits)
  hu_units: PackagingUnits;
  pieces: number;
  @Type(() => PackagingUnits)
  pieces_units: PackagingUnits;
  weight: number;

  @Type(() => Shipment)
  shipment: Shipment;

  @Type(() => Trailer)
  trailer: Trailer;

  orderNumber: number;
  @Type(() => DispatchShipmentUld)
  dispatchShipmentUlds: DispatchShipmentUld[] = [];
  comments: string;
  uldCount: number;

  @Type(() => Order)
  orders: Order[] = [];

  @Transform((v, o, tt) => transformLoadType(v, o, tt))
  public loadType: LoadType = LoadType.DELIVERY;

  @Exclude()
  public loadTypeOriginal: LoadType = LoadType.DELIVERY;
  @Exclude()
  public loadTypePreviousLeg: LoadType;

  private static suggestLoadType(orderMode: OrderMode, previousLeg: LoadType): LoadType {
//    console.log('SUGGEST>>', orderMode, previousLeg);
    if (isNullOrUndefined(orderMode)) {
      // Air Import
      return LoadType.DELIVERY;
    }

    if (OrderModes.isRecovery(orderMode)) {
      return LoadType.RECOVERY;
    }

    if (OrderModes.isAirExport(orderMode)) {
      return LoadType.DELIVERY;
    }

    return !previousLeg ? LoadType.PICKUP : LoadType.DELIVERY;
  }


  public static composeDispatchComments(o: Order| OrderDispatch): string {
      let s = (o.dispatchNotes || '').toLocaleUpperCase();

      if (o.hot) {
        s = 'URGENT ** ' + s;
      }

      if (o.cod) {
        s = 'COD * ' + s;
      }

      return s;
  }

  public static createFromDispatchDto(dispatch: OrderDispatch, deliveryAddress: Address): ManifestItem {
    console.log('CREATE FROM DispatchDto', dispatch, deliveryAddress);
    let item: ManifestItem = new ManifestItem();

    item.shipmentId = dispatch.dispatchShipmentId;
    item.addressPickUp = dispatch.addressPickup;
    item.addressDelivery = dispatch.addressDelivery;

    item.masterId = dispatch.masterId;
    item.orderId = dispatch.orderId;
    item.mawb = dispatch.mawb;
    item.hawb = dispatch.hawb;
    item.hu = dispatch.hu;
    item.pieces = dispatch.pieces;
    item.weight = dispatch.weight;
    item.customer = dispatch.customer;
    item.customerRef = dispatch.customerRef;

    item.comments = this.composeDispatchComments(dispatch);
    item.dispatchShipmentUlds = [];
    item.uldCount = dispatch.uldCount;

    console.log('>>>', dispatch.dispatchId, dispatch.loadType);
    item.loadTypePreviousLeg = dispatch.dispatchId ? dispatch.loadType : null;
    item.loadTypeOriginal = item.loadType = this.suggestLoadType(dispatch.orderMode, item.loadTypePreviousLeg);
    console.log('SUGGEST >>>', item.loadType);

    let o: Order = Order.fromOrderDispatch(dispatch);
    o.info.legAddressDelivery = deliveryAddress;
    item.orders.push(o);

//    console.log('createFromDispatchDto', dispatch, item);
    return item;
  }


  public static createFromOrder(order: Order): ManifestItem {
    console.log('CREATE FROM ORDER', order);
    let item: ManifestItem = new ManifestItem();

    item.shipmentId =  order.shipmentContents.map(shipmentContent => shipmentContent.shipment.id).first();
    item.addressPickUp = order.addressCfs;
    item.addressDelivery = order.addressDelivery;

    item.masterId = order.masterID;
    item.orderId = order.id;
    item.mawb = order.mawb;
    item.hawb = order.hawb;
    item.hu = order.hu;
    item.pieces = order.pieces;
    item.weight = order.weight;
    item.customer = order.customer;
    item.customerRef = order.customerRef;

    item.comments = undefined;
    item.dispatchShipmentUlds = [];
    item.uldCount = order.uldCount;

    item.loadTypePreviousLeg =  LoadType.PICKUP;
    item.loadTypeOriginal = item.loadType = this.suggestLoadType(order.genericMode, item.loadTypePreviousLeg); // ([LoadType.PICKUP, LoadType.X_DOCK].includes(item.loadTypePreviousLeg) ? LoadType.DELIVERY : LoadType.PICKUP);

    order.info.legAddressDelivery = order.addressDelivery;
    item.orders.push(order);
    return item;
  }

  equalsWithDispatchDto(dispatch: OrderDispatch) {
    return this.masterId === dispatch.masterId && this.orderId === dispatch.orderId;
  }

  hasOrder(orderId: number): boolean {
    return this.orders.some((o) => o.id === orderId);
  }

  @Exclude()
  get isSingleOrder(): boolean {
    return this.orders && this.orders.length === 1;
  }

  @Exclude()
  get consolidated(): boolean {
    return this.orders && this.orders.length > 1;
  }

  @Exclude()
  get mixedRefs(): boolean {
    let refs = this.orders.map((o) => o.customerRef).filter((s, index, array) => !s || array.indexOf(s) === index);
    return refs.length !== 1;
//    let refs = this.orders.map(o=>o.customerRef).filter(ref=>!!ref).duplicates();
//    return refs.length>1;
  }

  @Exclude()
  get mixedDelivery(): boolean {
    return !this.orders.asUniqueValue((o) => o.info.legAddressDelivery && o.info.legAddressDelivery.id);
  }

  @Exclude()
  public hasAirImport(): boolean {
    return assigned(this.orders.find((o) => OrderModes.isAirImport(o.genericMode) ));
  }

  @Exclude()
  get isSelectedPartially(): boolean {
    let i = 0;
    this.orders.forEach((o) => { if (o.selected) { i++; } });
    return i > 0 && i < this.orders.length;
  }

  @Exclude()
  get isSelectedFully(): boolean {
    return !this.orders.find((o) => !o.selected);
  }

  @Exclude()
  get hasSelected(): boolean {
    return !isNullOrUndefined(this.orders.find((o) => o.selected));
  }

  public update() {
    this.pieces = this.weight = this.hu = 0;
    this.orders.forEach((o) => {
      this.pieces += +o.pieces;
      this.hu += +o.hu;
      this.weight += +o.weight;
    });
  }

  public updateAddressDelivery() {
    this.addressDelivery = this.orders.asUniqueValue((o) => o.info.legAddressDelivery);
  }

  @Exclude()
  public get isRecovery(): boolean {
    return this.loadType === LoadType.RECOVERY;
  }

  @Exclude()
  public get isDirect(): boolean {
    return this.loadType === LoadType.DIRECT;
  }


  @Exclude()
  public isNotCompleted(): boolean {
    return this.shipment && this.shipment.isNotCompleted();
  }

  @Exclude()
  public isCompleted(): boolean {
    return this.shipment && this.shipment.isCompleted();
  }

  @Exclude()
  public isCompletedWithProblem(): boolean {
    return this.shipment && this.shipment.isCompletedWithProblem();
  }

  @Exclude()
  public isUpdatedByDriver(): boolean {
    return this.shipment &&
      (this.shipment.hasProblem || assigned(this.shipment.isRecovery() ? this.shipment.datePickUpActual : this.shipment.dateDeliveryActual));
  }

  @Exclude()
  public isRecoveryDirect(): boolean {
    return this.isDirect && this.orders.hasEquals((o) => o.isRecovery);
  }

  get rld(): Order {
    return this.orders.find((o) => o.isRecovery);
  }


}
