var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { NgModel } from '@angular/forms';
import { Size } from '../../../../../../common/oms-types';
import { BaseInputSelectComponent } from "../../../base/base-input/base-input-select.component";
import { Logger } from "../../../../../../_helpers/logger";
import { isNullOrUndefined } from "util";
import { KeyCode } from "../ng-select-types/ng-select.types";
var CustomSearchComponent = /** @class */ (function (_super) {
    __extends(CustomSearchComponent, _super);
    function CustomSearchComponent(validators, asyncValidators, cdr) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.cdr = cdr;
        _this.Size = Size;
        _this.changingEvent = new EventEmitter();
        _this.searchingEvent = new EventEmitter();
        _this.createEvent = new EventEmitter();
        _this.clearEvent = new EventEmitter();
        _this.size = Size.DEFAULT;
        _this.customSearch = false;
        _this.multiple = false;
        _this.bindLabel = 'label';
        _this.readonly = false;
        _this.allowCreate = false;
        _this.allowClear = true;
        _this.maxWidth = false;
        _this.autoWidth = true;
        _this.autoHeight = false;
        _this.showLabel = true;
        _this.inplace = false;
        _this.inline = false;
        _this.count = 15;
        _this.loading = false;
        _this.searchable = true;
        _this.appendTo = "body";
        _this.invalidValue = false;
        _this.isChanged = false;
        _this.searchSubject = new Subject();
        _this.doneTypingTimeout = 500;
        _this.unsubscribe$ = new Subject();
        _this.defaultSearchFn = function (search, item) {
            return _this.searchFn ? _this.searchFn(search, item) : true;
        };
        return _this;
    }
    CustomSearchComponent_1 = CustomSearchComponent;
    CustomSearchComponent.prototype.onChanged = function (item) {
        if (!this.multiple) {
            this.onSearch('');
            this.isOpen = false;
        }
        this.changingEvent.emit(item);
        if (!item) {
            this.clearEvent.emit();
        }
    };
    CustomSearchComponent.prototype.setValue = function (value) {
        _super.prototype.setValue.call(this, value);
    };
    CustomSearchComponent.prototype.toggleOpen = function (event) {
        _super.prototype.toggleOpen.call(this, event);
        if (this.isOpen) {
            this.onOpen();
        }
    };
    CustomSearchComponent.prototype.onOpen = function () {
        var _this = this;
        if (this.multiple) {
            setTimeout(function () {
                _this.search.nativeElement.focus();
            }, 10);
        }
        this.isChanged = true;
        if (typeof this._items === 'function') {
            var search = !isNullOrUndefined(this.innerValue) ? this.labelOf(this.innerValue) : this.ngSelect.filterValue;
            this.onSearch(search);
        }
        else {
            this.onSearch("");
        }
    };
    CustomSearchComponent.prototype.onSearch = function (search) {
        var _this = this;
        search = search || '';
        var isChanged = this._input !== search || this.isChanged;
        this._input = search;
        this.isChanged = false;
        if (this.searchable && isChanged) {
            this.isOpen = true;
            this.searchingEvent.emit(search);
            if (!this.customSearch) {
                //        this.searchSubject.next([]);
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(function () {
                    if (_this._items && typeof _this._items === 'function') {
                        _this._items(_this._input || '').subscribe(function (res) {
                            _this.searchSubject.next(res.content);
                        });
                    }
                    else if (_this.service) {
                        var sort = typeof _this.sortBy === 'string' ? { field: _this.sortBy } : _this.sortBy;
                        _this.service.findBy(_this._input || '', _this.count, true, sort).then(function (items) {
                            _this.searchSubject.next(items.content);
                        });
                    }
                    else if (_this._items && Array.isArray(_this._items)) {
                        _this.searchSubject.next(_this._items.filter(function (s) {
                            if (search === '') {
                                return true;
                            }
                            else {
                                var label = _this.bindLabel ? s[_this.bindLabel] : s.toString();
                                label = label && label.toUpperCase();
                                return label.indexOf(search.toUpperCase()) >= 0;
                            }
                        }));
                    }
                }, this.doneTypingTimeout);
            }
        }
    };
    /*  private initSearch(){
        const results$ = this.searchSubjectText.pipe(
    //      debounceTime(300),
          switchMap(search => {
            return this.service.findBy(search || '', this.count)
          }
          ))
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(response=>this.searchSubject.next(response.content));
      }
     */
    CustomSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this._items && this._items['length']) {
            setTimeout(function () {
                _this.searchSubject.next(_this._items);
            });
        }
    };
    CustomSearchComponent.prototype.onCreate = function () {
        this.isOpen = false;
        this.createEvent.emit(this._input);
    };
    CustomSearchComponent.prototype.onSearchInput = function (search) {
        if (this.searchable) {
            this.ngSelect.filter(search);
        }
    };
    CustomSearchComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    CustomSearchComponent.prototype.labelOf = function (item) {
        return item ? (this.bindLabel ? item[this.bindLabel] : (item['label'] || item)) : undefined;
    };
    CustomSearchComponent.prototype.ngOnChanges = function (changes) {
        if (changes._items && Array.isArray(this._items)) {
            this.searchSubject.next(this._items);
        }
    };
    CustomSearchComponent.prototype.onKeyDown = function (event) {
        if (event.keyCode === KeyCode.Tab) {
            if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value) {
                this.setValue(this.ngSelect.itemsList.markedItem.value);
            }
            this.ngSelect.filterValue = null;
            this.isOpen = false;
        }
    };
    CustomSearchComponent.prototype.onSearchExit = function () {
        this.ngSelect.filterValue = null;
        this.isOpen = false;
    };
    Object.defineProperty(CustomSearchComponent.prototype, "isAllSelected", {
        get: function () {
            return this.ngSelect.items && this.ngSelect.items.length && (this.ngSelect.selectedItems.length === this.ngSelect.items.length);
        },
        set: function (all) {
            if (all) {
            }
            else {
                this.ngSelect.handleClearClick();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomSearchComponent.prototype, "isPartiallySelected", {
        get: function () {
            return this.ngSelect.items && this.ngSelect.items.length && (this.ngSelect.selectedItems.length > 0 && this.ngSelect.selectedItems.length < this.ngSelect.items.length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomSearchComponent.prototype, "searchCheckboxHint", {
        get: function () {
            return this.ngSelect.selectedItems.length ? 'Clear Selection' : 'Select All';
        },
        enumerable: true,
        configurable: true
    });
    CustomSearchComponent.prototype.searchCheckboxClick = function (value) {
        var _this = this;
        if (this.ngSelect.selectedItems.length) {
            this.ngSelect.handleClearClick();
        }
        else {
            setTimeout(function () {
                _this.ngSelect.itemsList.items.forEach(function (o) {
                    _this.ngSelect.select(o);
                });
                _this.cdr.markForCheck();
                _this.cdr.detectChanges();
            });
        }
    };
    var CustomSearchComponent_1;
    CustomSearchComponent = CustomSearchComponent_1 = __decorate([
        Logger(),
        __metadata("design:paramtypes", [Array,
            Array,
            ChangeDetectorRef])
    ], CustomSearchComponent);
    return CustomSearchComponent;
}(BaseInputSelectComponent));
export { CustomSearchComponent };
