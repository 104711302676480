export enum DialogType {
  SUCCESS,
  CONFIRMATION,
  INFORMATION,
  WARNING,
  ERROR
}

export interface DialogResult<D> {
  result: ModalResult;
  data?: D;
}

export interface DialogCloseEvent {
  canClose: boolean;
  result: DialogResult<any>;
}

export interface ButtonConfig {
  caption: string;
  result?: ModalResult;
  class?: string;
  icon?: string;
  handler?: () => any;
}

export type BtnType = ButtonConfig | ModalResult;

export enum ModalResult {
  OK= 'OK',
  YES= 'Yes',
  NO= 'No',
  CANCEL= 'Cancel',
  // custom actions
  DELETE = 'Delete'
}

export const BUTTON_NEXT: ButtonConfig = {result: ModalResult.OK, caption: 'Next', icon: 'fa fa-arrow-right'};
export const BUTTONS_YES_NO: BtnType[] = [{result: ModalResult.YES, caption: 'Yes'}, {result: ModalResult.NO, caption: 'No'}];
export const BUTTONS_OK_CANCEL: BtnType[] = [{result: ModalResult.OK, caption: 'OK'}, {result: ModalResult.CANCEL, caption: 'Cancel'}];
export const BUTTONS_CONTINUE_CANCEL: BtnType[] = [{result: ModalResult.OK, caption: 'Continue'}, {result: ModalResult.CANCEL, caption: 'Cancel'}];
export const BUTTONS_CONFIRM_CANCEL: BtnType[] = [{result: ModalResult.OK, caption: 'Confirm', icon: 'fas fa-check'}, {result: ModalResult.CANCEL, caption: 'Cancel'}];

