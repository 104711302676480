/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manifest-item-context-menu.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-contextmenu";
import * as i3 from "../../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./manifest-item-context-menu.component";
import * as i6 from "../../../../../services/date-time.service";
import * as i7 from "../../../../../components/common/oms-dialogs/oms-dialogs.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../shared/components/oms-alerts/oms-alerts.service";
import * as i10 from "../../../../../services/orders.service";
import * as i11 from "../../../../../services/truck-service";
import * as i12 from "../../../../../services/trailer-service";
import * as i13 from "../../../../../services/driver-service";
import * as i14 from "../../../../shared/services/dispatch/dispatch.service";
import * as i15 from "../../../../../_helpers/dispatch-utils";
import * as i16 from "../../../../shared/services/user.service";
import * as i17 from "../../../../../services/auth.service";
var styles_ManifestItemContextMenuComponent = [i0.styles];
var RenderType_ManifestItemContextMenuComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ManifestItemContextMenuComponent, data: {} });
export { RenderType_ManifestItemContextMenuComponent as RenderType_ManifestItemContextMenuComponent };
function View_ManifestItemContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuHeader; _ck(_v, 1, 0, currVal_0); }); }
function View_ManifestItemContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-clone"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Split\u2026"]))], null, null); }
function View_ManifestItemContextMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ManifestItemContextMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-object-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Consolidate"]))], null, null); }
function View_ManifestItemContextMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-object-ungroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deconsolidate"]))], null, null); }
function View_ManifestItemContextMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ManifestItemContextMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-object-ungroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create BOL"]))], null, null); }
function View_ManifestItemContextMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-object-ungroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Print BOL"]))], null, null); }
function View_ManifestItemContextMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-object-ungroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create and Print"]))], null, null); }
function View_ManifestItemContextMenuComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ManifestItemContextMenuComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-user-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Redispatch\u2026"]))], null, null); }
function View_ManifestItemContextMenuComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ManifestItemContextMenuComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-check-square-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SelectAll"]))], null, null); }
function View_ManifestItemContextMenuComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-square-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clear Selection"]))], null, null); }
function View_ManifestItemContextMenuComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ManifestItemContextMenuComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-truck"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Driver Updates\u2026\u00A0"]))], null, null); }
function View_ManifestItemContextMenuComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_17)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { subMenu: [0, "subMenu"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 37); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ManifestItemContextMenuComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Completed\u2026"]))], null, null); }
function View_ManifestItemContextMenuComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not Completed\u2026"]))], null, null); }
function View_ManifestItemContextMenuComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ManifestItemContextMenuComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-undo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel Update"]))], null, null); }
export function View_ManifestItemContextMenuComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { contextMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 45, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(2, 180224, [[1, 4], ["contextMenu", 4]], 1, i2.ContextMenuComponent, [i2.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i2.ɵc]], { disabled: [0, "disabled"] }, null), i1.ɵqud(603979776, 2, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_1)), i1.ɵdid(5, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { passive: [0, "passive"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.splitOrder(_co.selectedOneOrder) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_2)), i1.ɵdid(7, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { enabled: [0, "enabled"] }, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_3)), i1.ɵdid(9, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.consolidate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_4)), i1.ɵdid(11, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.unconsolidate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_5)), i1.ɵdid(13, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_6)), i1.ɵdid(15, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.generateBOL(false, false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_7)), i1.ɵdid(17, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.generateBOL(true, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_8)), i1.ɵdid(19, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.generateBOL(true, false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_9)), i1.ɵdid(21, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { enabled: [0, "enabled"] }, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_10)), i1.ɵdid(23, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.redispatchSelected(_co.manifestItem) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_11)), i1.ɵdid(25, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_12)), i1.ɵdid(27, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"], visible: [1, "visible"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.selectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_13)), i1.ɵdid(29, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { visible: [0, "visible"] }, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.unselect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_14)), i1.ɵdid(31, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { visible: [0, "visible"] }, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_15)), i1.ɵdid(33, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManifestItemContextMenuComponent_16)), i1.ɵdid(35, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(36, 0, null, null, 10, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(37, 180224, [["submenuDriverActions", 4]], 1, i2.ContextMenuComponent, [i2.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i2.ɵc]], null, null), i1.ɵqud(603979776, 3, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.driverAction("COMPLETED") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_18)), i1.ɵdid(40, 16384, [[3, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.driverAction("NOT_COMPLETED") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_19)), i1.ɵdid(42, 16384, [[3, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ManifestItemContextMenuComponent_20)), i1.ɵdid(44, 16384, [[3, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.driverActionCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ManifestItemContextMenuComponent_21)), i1.ɵdid(46, 16384, [[3, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 2, 0, currVal_0); var currVal_1 = "true"; _ck(_v, 5, 0, currVal_1); var currVal_2 = !!_co.selectedOneOrder; _ck(_v, 7, 0, currVal_2); var currVal_3 = "true"; _ck(_v, 9, 0, currVal_3); var currVal_4 = "true"; _ck(_v, 15, 0, currVal_4); var currVal_5 = !!_co.selectedOneOrder; _ck(_v, 21, 0, currVal_5); var currVal_6 = "true"; _ck(_v, 23, 0, currVal_6); var currVal_7 = "true"; var currVal_8 = _co.isVisibleSelect; _ck(_v, 27, 0, currVal_7, currVal_8); var currVal_9 = _co.isVisibleSelect; _ck(_v, 29, 0, currVal_9); var currVal_10 = _co.isVisibleSelect; _ck(_v, 31, 0, currVal_10); var currVal_11 = "true"; _ck(_v, 33, 0, currVal_11); var currVal_12 = _co.canDriverUpdate; _ck(_v, 35, 0, currVal_12); var currVal_13 = "true"; _ck(_v, 44, 0, currVal_13); }, null); }
export function View_ManifestItemContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "manifest-item-context-menu", [], null, null, null, View_ManifestItemContextMenuComponent_0, RenderType_ManifestItemContextMenuComponent)), i1.ɵdid(1, 638976, null, 0, i5.ManifestItemContextMenuComponent, [i6.DateTimeService, i1.ChangeDetectorRef, i7.OmsDialogsService, i8.MatDialog, i9.OmsAlertsService, i10.OrdersService, i11.TruckService, i12.TrailerService, i13.DriverService, i14.DispatchService, i15.DispatchUtils, i16.UserService, i17.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManifestItemContextMenuComponentNgFactory = i1.ɵccf("manifest-item-context-menu", i5.ManifestItemContextMenuComponent, View_ManifestItemContextMenuComponent_Host_0, { manifest: "manifest", manifestItem: "manifestItem", items: "items", isVisibleSelect: "isVisibleSelect", selectedOrder: "selectedOrder" }, { manifestChange: "manifestChange", loadingChange: "loadingChange", refreshChange: "refreshChange" }, []);
export { ManifestItemContextMenuComponentNgFactory as ManifestItemContextMenuComponentNgFactory };
