import {
  convertDate,
  convertMawbNumber,
  convertMawbStatusShort,
  safelyGetter
} from '../modules/shared/services/oms-converters.service';
import {Master, Order} from '../modules/shared/models';

export class CopyToClipboardItem<T> {
  constructor(
    public label: string,
    public width: number,
    public value?: (item: T) => string
  ) {
  }
}

export class CopyToClipboardColumnsType {

  public static createMasterLineColumns(): CopyToClipboardItem<Master>[] {
    return [
      new CopyToClipboardItem('MAWB/H', 20, (masterLine) => convertMawbNumber(safelyGetter(masterLine, 'masterAir.mawbNumber')) + '  (' + masterLine.orders.length + ')'),
//      new CopyToClipboardItem<Master>('Customer Ref', 15, (masterLine) => masterLine.customerRef),
      new CopyToClipboardItem<Master>('Customer', 20, (masterLine) => masterLine.customerNameBasedOnOrders),
      new CopyToClipboardItem<Master>('Forwarder', 20,  (masterLine) => safelyGetter(masterLine, 'freightForwarder.name')),
      new CopyToClipboardItem<Master>('PCS', 10, (masterLine) => String(masterLine.pieces)),
      new CopyToClipboardItem<Master>('Location', 25, (masterLine) => safelyGetter(masterLine, 'addressCfs.name')),
      new CopyToClipboardItem<Master>('Arrival Date', 15, (masterLine) => convertDate(masterLine.dateArrivalAct || masterLine.dateArrivalEst)),
      new CopyToClipboardItem<Master>('CFS in Date', 15, (masterLine) => convertDate(masterLine.dateCfsInAct || masterLine.dateCfsInEst)),
//      new CopyToClipboardItem<Master>('CFS LFD', 15,(masterLine) => convertDateTime(masterLine.dateCfsFreeTime)),
      new CopyToClipboardItem<Master>('Status', 20, (masterLine) => convertMawbStatusShort(masterLine.status))
    ];
  }

  public static createOrderColumns(): CopyToClipboardItem<Order>[] {
    return [
      new CopyToClipboardItem('MAWB#', 15,  (order) => order.mawb),
      new CopyToClipboardItem('HAWB#', 15,  (order) => order.hawb),
//      new CopyToClipboardItem('Customer Ref', 15, (order) => order.customerRef),
      new CopyToClipboardItem('Customer', 20, (order) => order.customerName),
      new CopyToClipboardItem('Forwarder', 20,  (order) => safelyGetter(order, 'freightForwarder.name')),
      new CopyToClipboardItem('PCS', 10, (order) => String(order.pieces)),
      new CopyToClipboardItem('Location', 15, (order) => safelyGetter(order, 'cfsLocation.name')),
      new CopyToClipboardItem('Arrival Date', 15, (order) => convertDate(order.dateArrivalAct || order.dateArrivalEst)),
      new CopyToClipboardItem('CFS in Date', 15, (order) => convertDate(order.dateCfsInAct || order.dateCfsInEst)),
//      new CopyToClipboardItem('CFS LFD', 15,(order) => convertDateTime(order.dateCfsFreeTime)),
      new CopyToClipboardItem('Status', 25, (order) => convertMawbStatusShort(order.status))
    ];
  }

  public static createManifestMasterLineColumns(): CopyToClipboardItem<Master>[] {
    return [
      new CopyToClipboardItem('MAWB/H', 20, (masterLine) => convertMawbNumber(safelyGetter(masterLine, 'masterAir.mawbNumber')) + '  (' + masterLine.orders.length + ')'),
      new CopyToClipboardItem('Forwarder', 20, (masterLine) => safelyGetter(masterLine, 'freightForwarder.name')),
      new CopyToClipboardItem('PCS', 10, (masterLine) => String(masterLine.pieces)),
      new CopyToClipboardItem('KG', 10, (masterLine) => String(masterLine.weight)
      ),
    ];
  }
}
