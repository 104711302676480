import {BehaviorSubject, concat, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {BaseEntityService} from '../../../services/base/base-entity.service';
import {BaseEntity} from '../../shared/models';
import {FilterPageRequest} from '../../shared/models/filter.page.request';
import {SearchSortRequest} from '../../shared/models/search.sort.request';
import {FilterSearchColumn} from '../../shared/models/filter.search.column';
import {ISearchItemsFunction} from "./search-items.function";

export class NgSelectSearchParams<T extends BaseEntity> {
  items$: Observable<T[]>;
  input$ = new BehaviorSubject<string>('');
  loading = false;
  displayProperties: { name: string, getValue: (item: T) => string }[] = [];
  request: FilterPageRequest;
  isActive: boolean = true;
  changeRequest: (request: FilterPageRequest, term: string) => FilterPageRequest;

  selected: any;

  constructor(private baseEntityService: BaseEntityService<T> | ISearchItemsFunction<T>, sortBy?: string | number) {
    if (baseEntityService instanceof BaseEntityService) {
      this.request = new FilterPageRequest(1, 20, '', new SearchSortRequest(sortBy, true), []);
      this.changeRequest = this.createDefaultChangeRequest();
    }
    this.initLoadItems();
  }

  public initLoadItems() {
    this.items$ = concat(
      of([]), // default items
      this.input$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((term) => {
          this.loading = true;
          if (this.baseEntityService instanceof BaseEntityService) {
            this.request = this.changeRequest(this.request, term);
          }
        }),
        switchMap(term => (this.baseEntityService instanceof BaseEntityService
          ? (term ? this.baseEntityService.findByFilters(this.request, this.isActive) : of({content: []}))
          : this.baseEntityService(term)).pipe(
          map(response => response.content),
          map(items => {
            this.displayProperties.forEach(display => {
              items.forEach(item => {
                item[display.name] = display.getValue(item);
              });
            });
            return items;
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.loading = false)
        ))
      )
    );
  }

  public setOperation(operation: 'or' | 'and') {
    this.request.operation = operation;
  }

  public setSearchIsActive(isActive: boolean) {
    this.isActive = isActive;
  }

  setSearchByFunc(changeRequest: (request: FilterPageRequest, term: string) => FilterPageRequest): void {
    this.changeRequest = changeRequest;
  }

  setSearchBy(fieldName: string): void {
    this.changeRequest = (request: FilterPageRequest, term: string) => {
      const column = request.filterByColumns.find(columnItem => columnItem.field === fieldName);
      if (column) {
        column.value = term;
      } else {
        request.filterByColumns.push(new FilterSearchColumn(fieldName, term));
      }
      return request;
    };
  }

  createDefaultChangeRequest(): (request: FilterPageRequest, term: string) => FilterPageRequest {
    return (request: FilterPageRequest, term: string) => {
      request.findByOccurs = term;
      return request;
    };
  }

  addDisplayProperty(name: string, getValue: (item: T) => string) {
    this.displayProperties.push({name: name, getValue: getValue});
  }

  destroy() {
    if (this.input$) {
      this.input$.complete();
      this.input$.unsubscribe();
    }
    this.items$ = null;
  }
}
