import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TruckService } from '../../../services/truck-service';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { TrailerService } from '../../../services/trailer-service';
import { DriverService } from '../../../services/driver-service';
import { AddressService } from '../../../services';
import { Size } from '../../../common/oms-types';
import { Truck } from '../../../modules/shared/models';
import { Trailer } from '../../../modules/shared/models';
import { Driver } from '../../../modules/shared/models';
import { convertManifestNumber, OmsConverters } from '../../../modules/shared/services/oms-converters.service';
import { NgSelectSearchParams } from '../../../modules/settings/util/ng-select-search-params';
import { CarrierService } from '../../../modules/shared/services/carrier.service';
import { DialogType, ModalResult, OmsDialogsService } from "../../common/oms-dialogs";
import { DispatchService } from "../../../modules/shared/services/dispatch/dispatch.service";
import { equals } from "../../../_helpers/utils";
import { sameDate } from "../../../common/oms-date-time.utils";
var OrdersDispatchDialogComponent = /** @class */ (function () {
    function OrdersDispatchDialogComponent(data, converter, cdr, dialogRef, addressService, dialogs, alerts, truckService, trailerService, driverService, dispatchService, carrierService) {
        this.data = data;
        this.converter = converter;
        this.cdr = cdr;
        this.dialogRef = dialogRef;
        this.addressService = addressService;
        this.dialogs = dialogs;
        this.alerts = alerts;
        this.truckService = truckService;
        this.trailerService = trailerService;
        this.driverService = driverService;
        this.dispatchService = dispatchService;
        this.carrierService = carrierService;
        this.Size = Size;
        this.readonly = false;
        this.loading = false;
        this.initialized = false;
        //    this.addressSearch = this.addressService.getNewSearchService([], 15);
        this.tempTrailer = this.data.trailer;
        this.tempTruck = this.data.truck;
    }
    OrdersDispatchDialogComponent.prototype.ngOnInit = function () {
        this.carrierSearch = new NgSelectSearchParams(this.carrierService);
        //    this.carrierService.getDefaultCarrier().subscribe(carrier=>{this.data.carrier = carrier});
    };
    OrdersDispatchDialogComponent.prototype.ngAfterViewInit = function () {
        this.initialized = true;
    };
    Object.defineProperty(OrdersDispatchDialogComponent.prototype, "cancelEnable", {
        get: function () {
            return false; // !this.readonly && !this.data.split.isVirtual/* && !this.data.split.isReceived*/;
        },
        enumerable: true,
        configurable: true
    });
    OrdersDispatchDialogComponent.prototype.onCancelDispatch = function () {
        var _this = this;
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Cancel Dispatch?', 'The load will be deleted and all related data will be lost.\nContinue?').then(function (result) {
            switch (result.result) {
                case ModalResult.YES: {
                    _this.dialogRef.close({ result: ModalResult.DELETE });
                }
            }
        });
    };
    OrdersDispatchDialogComponent.prototype.onOk = function (event) {
        event.canClose = this.validate();
    };
    OrdersDispatchDialogComponent.prototype.onCreateTruck = function (number) {
        var _this = this;
        var truck = new Truck();
        truck.number = number;
        this.truckService.createEditAndSave(truck, this.readonly)
            .then(function (t) { _this.data.truck = t; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    OrdersDispatchDialogComponent.prototype.onCreateTrailer = function (number) {
        var _this = this;
        var trailer = new Trailer();
        trailer.number = number;
        this.trailerService.createEditAndSave(trailer, this.readonly)
            .then(function (t) { _this.data.trailer = t; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    OrdersDispatchDialogComponent.prototype.onCreateDriver = function (event) {
        var _this = this;
        var driver = new Driver();
        this.driverService.createEditAndSave(driver, this.readonly)
            .then(function (dr) { _this.data.driver = dr; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    OrdersDispatchDialogComponent.prototype.allowDispatch = function (order) {
        return this.data.delivery ? order.nonAMS || !!order.date1C : true;
    };
    OrdersDispatchDialogComponent.prototype.validate = function () {
        var _this = this;
        var o = this.data.orders.filter(function (order) { return !_this.allowDispatch(order); });
        if (o.length > 0) {
            this.alerts.warning('All AMS orders must have 1C');
            return false;
        }
        return true;
    };
    OrdersDispatchDialogComponent.prototype.checkMerge = function () {
        var _this = this;
        if (this.initialized && this.data.driver && this.data.date) {
            if (this.data.manifestOriginal &&
                equals(this.data.manifestOriginal.driver, this.data.driver) &&
                sameDate(this.data.manifestOriginal.dateDispatchedFor, this.data.date)) {
                this.data.manifestToMerge = null;
                this.data.trailer = this.tempTrailer;
                this.data.truck = this.tempTruck;
            }
            else {
                this.loading = true;
                this.dispatchService.getDriverManifestOnDate(this.data.date, this.data.driver)
                    .then(function (manifest) {
                    if (manifest && _this.data.manifestOriginal && _this.data.manifestOriginal.id !== manifest.id) {
                        if (!_this.data.manifestToMerge) {
                            _this.tempTruck = _this.data.truck;
                            _this.tempTrailer = _this.data.trailer;
                        }
                        _this.data.manifestToMerge = manifest;
                        _this.data.truck = manifest.truck;
                        _this.data.trailer = manifest.trailer;
                    }
                    else {
                        if (_this.data.manifestToMerge) {
                            _this.data.manifestToMerge = null;
                            _this.data.trailer = _this.tempTrailer;
                            _this.data.truck = _this.tempTruck;
                        }
                    }
                    _this.loading = false;
                })
                    .catch(function (error) {
                    _this.alerts.error(error);
                    _this.loading = false;
                });
            }
        }
    };
    OrdersDispatchDialogComponent.prototype.onDriverChange = function (event) {
        console.log('Driver Changed', event);
        this.checkMerge();
    };
    OrdersDispatchDialogComponent.prototype.onDateChange = function (event) {
        console.log('Date Changed', event);
        this.checkMerge();
    };
    Object.defineProperty(OrdersDispatchDialogComponent.prototype, "info", {
        get: function () {
            if (this.data.manifestOriginal &&
                sameDate(this.data.manifestOriginal.dateDispatchedFor, this.data.date) &&
                equals(this.data.manifestOriginal.driver, this.data.driver)) {
                return "Select another Date or Driver";
            }
            if (this.data.manifestToMerge) {
                return "Existing " + convertManifestNumber(this.data.manifestToMerge.id) + " found to Merge";
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    return OrdersDispatchDialogComponent;
}());
export { OrdersDispatchDialogComponent };
