var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Address, Customer, Driver } from '..';
import { Type } from 'class-transformer';
var ɵ0 = function () { return DriverLoad; }, ɵ1 = function () { return Driver; }, ɵ2 = function () { return Date; };
var DriverOrderDispatch = /** @class */ (function () {
    function DriverOrderDispatch() {
        this.rowId = DriverOrderDispatch.ROW_ID;
        this.loads = [];
    }
    DriverOrderDispatch.ROW_ID = 'DriverOrderDispatch';
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Array)
    ], DriverOrderDispatch.prototype, "loads", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Driver)
    ], DriverOrderDispatch.prototype, "driver", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Date)
    ], DriverOrderDispatch.prototype, "dateDispatchedFor", void 0);
    return DriverOrderDispatch;
}());
export { DriverOrderDispatch };
var ɵ3 = function () { return Address; }, ɵ4 = function () { return Address; }, ɵ5 = function () { return Customer; }, ɵ6 = function () { return Date; };
var DriverLoad = /** @class */ (function () {
    function DriverLoad() {
    }
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Address)
    ], DriverLoad.prototype, "addressDelivery", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", Address)
    ], DriverLoad.prototype, "addressPickup", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Customer)
    ], DriverLoad.prototype, "customer", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Date)
    ], DriverLoad.prototype, "dateDeliveryAct", void 0);
    return DriverLoad;
}());
export { DriverLoad };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
