import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterOptions} from "./filter-options";
import {FilterSearchColumn} from "../../../../../modules/shared/models/filter.search.column";
import {isNullOrUndefined} from "util";
import {FilterPageRequest} from "../../../../../modules/shared/models/filter.page.request";

@Component({
  selector: 'oms-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {

  @Input() options: FilterOptions;
  @Input() filters: FilterPageRequest;
  @Output() searchChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  compareById(item1: any, item2: any) {
    if (!item1 || !item2) {
      return false;
    }

    // tslint:disable-next-line:triple-equals
    return item1.id == item2.id;
  }


  private updateFilters(options) {
    if (this.filters) {
      let columnFilter = FilterSearchColumn.fromSearchOptions(options.search, options.field);
      this.filters.removeColumnFilter(options.field);
      if (columnFilter) {
        this.filters.filterByColumns.push(columnFilter);
      }
    }
  }

  searchChanged() {
    this.updateFilters(this.options);
    this.searchChange.emit();
  }

  onSearch(options, value) {
    if (options.search && options.search.searchable) {
      options.search.search = value;
      this.searchChanged();
    }
  }

  clearInput(options) {
    options.search.search = "";
    this.searchChanged();
  }



  onSelectSearchItem(event, options, multiple) {
    if (multiple && !isNullOrUndefined(event)) {
      options.search.search = event.map(item => item.id);
    } else {
      options.search.search = isNullOrUndefined(event) ? '' : event.id;
    }

    this.updateFilters(options);
  }


}
