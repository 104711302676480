var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { Address } from "../modules/shared/models/address";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "./http-utils.service";
var AddressNewService = /** @class */ (function (_super) {
    __extends(AddressNewService, _super);
    function AddressNewService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/address/';
        _this.classType = Address;
        return _this;
    }
    AddressNewService.ngInjectableDef = i0.defineInjectable({ factory: function AddressNewService_Factory() { return new AddressNewService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: AddressNewService, providedIn: "root" });
    return AddressNewService;
}(BaseEntityService));
export { AddressNewService };
