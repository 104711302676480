import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {OmsMapsService} from "../../../../services/oms-maps.service";
import {ActivatedRoute} from "@angular/router";
import {generateUid} from "../../../../_helpers/utils";

@Component({
  selector: 'oms-maps-page',
  templateUrl: './oms-maps-page.component.html',
  styleUrls: ['./oms-maps-page.component.scss'],
//  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OmsMapsPageComponent implements OnInit {

  public omsMapsOptions: any;
  public routeExternalId: number;
  public mapUrlUid = generateUid();

  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private omsMaps: OmsMapsService) {

    this.omsMaps.getOmsMapsOptions()
      .subscribe((options) => this.omsMapsOptions = options);

    route.queryParams.subscribe((params) => {
      if (params.id) {
        this.routeExternalId = Number(params.id);
        this.cdr.markForCheck();
      }
    });
  }

  ngOnInit() {
  }


  public getMapLink(): string {
    if (!this.omsMapsOptions) {
      return undefined;
    }

    let params = [
      't=' + this.omsMapsOptions.token,
      'uid=' + this.mapUrlUid
    ];
    if (this.routeExternalId) {
      params.push('id=' + this.routeExternalId);
    }

    params.push('sd=0');

    return this.omsMapsOptions.url + '/api/map/manifest?' + params.join('&');
  }


}
