import {Address, Customer, Driver} from '..';
import {Type} from 'class-transformer';
import {RowObject} from '../../components/base/data-table/columns/column-types';

export class DriverOrderDispatch implements RowObject {
  public static readonly ROW_ID = 'DriverOrderDispatch';
  rowId = DriverOrderDispatch.ROW_ID;

  @Type(() => DriverLoad)
  loads: DriverLoad[] = [];

  @Type(() => Driver)
  driver: Driver;

  @Type(() => Date)
  dateDispatchedFor: Date;

  routeExternalId: number;
  count: number;
  openedCount: number;
  deliveryActCount: number;
  notCompletedCount: number;
  completedCount: number;
  completedWithProblemCount: number;
  piecesTotal: number;
  weightTotal: number;
}

export class DriverLoad {
  @Type(() => Address)
  addressDelivery: Address;
  @Type(() => Address)
  addressPickup: Address;
  @Type(() => Customer)
  customer: Customer;
  customerRef: string;
  dispatchId: number;
  dispatchItemId: number;
  mawb: string;
  orderId: number;
  orderMode: string;
  @Type(() => Date)
  dateDeliveryAct: Date;

  notCompletedCount: number;
  completedCount: number;
  completedWithProblemCount: number;
  loadType: number;
  dispatchItemOrderNumber: number;
  pcs: number;
  weight: number;
}
