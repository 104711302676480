/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./master-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./master-import-air/master-import-air.component.ngfactory";
import * as i4 from "./master-import-air/master-import-air.component";
import * as i5 from "../../../common/oms-constants.service";
import * as i6 from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import * as i7 from "../../../modules/shared/models/master/master-status";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../components/common/oms-dialogs/oms-dialogs.service";
import * as i10 from "../../../services/logchanges/log.changes.service";
import * as i11 from "../../../services/customer.service";
import * as i12 from "../../../services/freight.forwarder.service";
import * as i13 from "../../../modules/shared/services/address.service";
import * as i14 from "../../../services/billing.service";
import * as i15 from "../../../common/oms-columns.service";
import * as i16 from "../../../services/air/airline.service";
import * as i17 from "../../../services/websocet/web.socket.service";
import * as i18 from "../../../services/master/master.service";
import * as i19 from "../../../common/master-processor";
import * as i20 from "../../../services/orders.service";
import * as i21 from "@angular/router";
import * as i22 from "../../../services/auth.service";
import * as i23 from "../../../../../node_modules/angular-split/angular-split.ngfactory";
import * as i24 from "angular-split";
import * as i25 from "../../../modules/shared/components/base/base-page/base-page.component.ngfactory";
import * as i26 from "../../../modules/shared/components/base/base-page/base-page.component";
import * as i27 from "ngx-spinner";
import * as i28 from "@angular/platform-browser";
import * as i29 from "../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i30 from "ngx-perfect-scrollbar";
import * as i31 from "../../../modules/dispatch/pages/manifest-create-panel/manifest-create-panel.component.ngfactory";
import * as i32 from "../../../modules/dispatch/pages/manifest-create-panel/manifest-create-panel.component";
import * as i33 from "../../../services/truck-service";
import * as i34 from "../../../services/trailer-service";
import * as i35 from "../../../services/driver-service";
import * as i36 from "../../../modules/shared/services/dispatch/dispatch.service";
import * as i37 from "./master-editor.component";
import * as i38 from "../../../services/master/master-line.service";
import * as i39 from "../../../services/location-back.service";
import * as i40 from "../../../modules/shared/services/user.service";
import * as i41 from "../../../_helpers/dispatch-utils";
var styles_MasterEditorComponent = [i0.styles];
var RenderType_MasterEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MasterEditorComponent, data: {} });
export { RenderType_MasterEditorComponent as RenderType_MasterEditorComponent };
function View_MasterEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "refresh"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "fa fa-refresh refresh-button"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "refresh-button-loading": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa fa-refresh refresh-button"; var currVal_1 = _ck(_v, 3, 0, _co.refreshLoading); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MasterEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, null); }
function View_MasterEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save & Back"]))], null, null); }
function View_MasterEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["style", "margin-left: 30px"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete\u2026 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.master || _co.master.isNew()); _ck(_v, 0, 0, currVal_0); }); }
function View_MasterEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-warning"], ["style", "margin-left: 30px"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRestore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Restore\u2026 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.master || _co.master.isNew()); _ck(_v, 0, 0, currVal_0); }); }
function View_MasterEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-master-import-air", [], null, [[null, "masterChangeEvent"], [null, "masterUpdatedEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("masterChangeEvent" === en)) {
        var pd_0 = (_co.masterChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("masterUpdatedEvent" === en)) {
        var pd_1 = (_co.masterUpdated($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MasterImportAirComponent_0, i3.RenderType_MasterImportAirComponent)), i1.ɵdid(1, 245760, [[1, 4]], 0, i4.MasterImportAirComponent, [i5.OmsConstants, i6.OmsAlertsService, i7.MasterStatusService, i2.Location, i1.ChangeDetectorRef, i8.MatDialog, i9.OmsDialogsService, i10.LogChangesService, i11.CustomerService, i12.FreightForwarderService, i13.AddressService, i14.BillingService, i15.OmsColumns, i16.AirlineService, i6.OmsAlertsService, i17.WebSocketService, i18.MasterService, i19.MasterProcessor, i20.OrdersService, i21.Router, i22.AuthService], { master: [0, "master"], readonly: [1, "readonly"] }, { masterChangeEvent: "masterChangeEvent", masterUpdatedEvent: "masterUpdatedEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.master; var currVal_1 = _co.clientUser; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MasterEditorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, null); }
function View_MasterEditorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save & Back"]))], null, null); }
function View_MasterEditorComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["style", "margin-left: 30px"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete\u2026 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.master || _co.master.isNew()); _ck(_v, 0, 0, currVal_0); }); }
export function View_MasterEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { childContent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 40, "as-split", [["direction", "horizontal"], ["unit", "percent"]], null, [[null, "dragEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragEnd" === en)) {
        var pd_0 = (_co.dragEnd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i23.View_SplitComponent_0, i23.RenderType_SplitComponent)), i1.ɵdid(2, 4374528, null, 0, i24.SplitComponent, [i1.NgZone, i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { direction: [0, "direction"], unit: [1, "unit"], gutterDblClickDuration: [2, "gutterDblClickDuration"] }, { dragEnd: "dragEnd" }), (_l()(), i1.ɵeld(3, 0, null, 0, 34, "as-split-area", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i24.SplitAreaDirective, [i1.NgZone, i1.ElementRef, i1.Renderer2, i24.SplitComponent], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 32, "oms-base-page", [], null, [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onEscape($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i25.View_BasePageComponent_0, i25.RenderType_BasePageComponent)), i1.ɵdid(6, 638976, null, 0, i26.BasePageComponent, [i21.Router, i21.ActivatedRoute, i27.NgxSpinnerService, i28.Title, i21.ActivatedRoute, i1.ChangeDetectorRef], { title: [0, "title"], subtitle: [1, "subtitle"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "btn-toolbar pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 10, "div", [["class", "btn-toolbar pull-left main-toolbar-actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.location.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_4)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_5)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, 2, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "perfect-scrollbar", [["style", "max-height: 80vh;"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i29.View_PerfectScrollbarComponent_0, i29.RenderType_PerfectScrollbarComponent)), i1.ɵdid(24, 507904, null, 0, i30.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], { config: [0, "config"] }, null), i1.ɵpod(25, { suppressScrollX: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MasterEditorComponent_6)), i1.ɵdid(27, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(28, 0, null, 3, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 8, "div", [["class", "btn-toolbar pull-right bottom-navigation-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_7)), i1.ɵdid(31, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_8)), i1.ɵdid(33, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.location.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterEditorComponent_9)), i1.ɵdid(37, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(38, 0, null, 0, 3, "as-split-area", [], null, null, null, null, null)), i1.ɵdid(39, 212992, null, 0, i24.SplitAreaDirective, [i1.NgZone, i1.ElementRef, i1.Renderer2, i24.SplitComponent], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(40, 0, null, null, 1, "manifest-create-panel", [], null, [[null, "manifestChange"], [null, "selectedChange"], [null, "created"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("manifestChange" === en)) {
        var pd_0 = ((_co.manifestForRightPanel = $event) !== false);
        ad = (pd_0 && ad);
    } if (("manifestChange" === en)) {
        var pd_1 = (_co.onManifestChange($event) !== false);
        ad = (pd_1 && ad);
    } if (("selectedChange" === en)) {
        var pd_2 = (_co.onDispatchListSelectionChange($event) !== false);
        ad = (pd_2 && ad);
    } if (("created" === en)) {
        var pd_3 = (_co.onCreatedEvent($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i31.View_ManifestCreatePanelComponent_0, i31.RenderType_ManifestCreatePanelComponent)), i1.ɵdid(41, 638976, null, 0, i32.ManifestCreatePanelComponent, [i1.ChangeDetectorRef, i8.MatDialog, i27.NgxSpinnerService, i9.OmsDialogsService, i6.OmsAlertsService, i33.TruckService, i34.TrailerService, i35.DriverService, i36.DispatchService, i9.OmsDialogsService], { mergeToExisting: [0, "mergeToExisting"], manifest: [1, "manifest"] }, { manifestChange: "manifestChange", selectedChange: "selectedChange", created: "created" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "horizontal"; var currVal_1 = "percent"; var currVal_2 = 10; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.widthFirstPanel; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.title; var currVal_5 = _co.subTitle; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = (_co.master && !_co.master.isNew()); _ck(_v, 10, 0, currVal_6); var currVal_7 = !_co.clientUser; _ck(_v, 13, 0, currVal_7); var currVal_8 = !_co.clientUser; _ck(_v, 15, 0, currVal_8); var currVal_9 = (((_co.isDeleteAvailable && _co.master) && !_co.master.isNew()) && !_co.master.isDeleted()); _ck(_v, 19, 0, currVal_9); var currVal_10 = ((_co.isDeleteAvailable && _co.master) && _co.master.isDeleted()); _ck(_v, 21, 0, currVal_10); var currVal_13 = _ck(_v, 25, 0, true); _ck(_v, 24, 0, currVal_13); var currVal_14 = _co.master; _ck(_v, 27, 0, currVal_14); var currVal_15 = !_co.clientUser; _ck(_v, 31, 0, currVal_15); var currVal_16 = !_co.clientUser; _ck(_v, 33, 0, currVal_16); var currVal_17 = _co.isDeleteAvailable; _ck(_v, 37, 0, currVal_17); var currVal_18 = _co.widthSecondPanel; _ck(_v, 39, 0, currVal_18); var currVal_19 = true; var currVal_20 = _co.manifestForRightPanel; _ck(_v, 41, 0, currVal_19, currVal_20); }, function (_ck, _v) { var currVal_11 = i1.ɵnov(_v, 24).autoPropagation; var currVal_12 = i1.ɵnov(_v, 24).scrollIndicators; _ck(_v, 23, 0, currVal_11, currVal_12); }); }
export function View_MasterEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-master-editor", [], null, null, null, View_MasterEditorComponent_0, RenderType_MasterEditorComponent)), i1.ɵdid(1, 245760, null, 0, i37.MasterEditorComponent, [i6.OmsAlertsService, i9.OmsDialogsService, i1.ChangeDetectorRef, i17.WebSocketService, i2.Location, i22.AuthService, i21.ActivatedRoute, i20.OrdersService, i18.MasterService, i38.MasterLineService, i39.LocationBackService, i40.UserService, i36.DispatchService, i41.DispatchUtils, i27.NgxSpinnerService, i15.OmsColumns, i19.MasterProcessor], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MasterEditorComponentNgFactory = i1.ɵccf("oms-master-editor", i37.MasterEditorComponent, View_MasterEditorComponent_Host_0, {}, {}, []);
export { MasterEditorComponentNgFactory as MasterEditorComponentNgFactory };
