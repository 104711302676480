var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseInputComponent } from "./base-input.component";
import { NgSelectComponent } from "@ng-select/ng-select";
import { isNullOrUndefined } from "util";
import { KeyCode } from "../../common/input/ng-select-types/ng-select.types";
import { NgModel } from "@angular/forms";
var BaseInputSelectComponent = /** @class */ (function (_super) {
    __extends(BaseInputSelectComponent, _super);
    function BaseInputSelectComponent(validators, asyncValidators) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.isOpen = false;
        return _this;
    }
    BaseInputSelectComponent.prototype._handleOutsideClick = function ($event) {
        if (this.isOpen && this.isOutsideClick($event)) {
            this.isOpen = false;
        }
        if (isNullOrUndefined(this.innerValue)) {
            this.ngSelect.filterValue = null;
        }
    };
    BaseInputSelectComponent.prototype.differentIdentifier = function (element) {
        if (element.getAttribute("ng-reflect-append-to") === "body") {
            return false;
        }
        return element.getAttribute("ng-reflect-append-to") !== "#" + this.identifier;
    };
    BaseInputSelectComponent.prototype.toggleOpen = function ($event) {
        var selectPanel = $event.target.closest("#" + this.identifier) && !$event.target.closest("ng-dropdown-panel");
        if (selectPanel) {
            this.isOpen = !this.isOpen;
        }
    };
    BaseInputSelectComponent.prototype.isOutsideClick = function ($event) {
        var dropdownPanel = $event.target.closest("ng-dropdown-panel");
        return isNullOrUndefined(dropdownPanel) || this.differentIdentifier(dropdownPanel);
    };
    BaseInputSelectComponent.prototype.handleKeyDown = function ($event) {
        if (KeyCode[$event.which]) {
            switch ($event.which) {
                case KeyCode.Space:
                    this.isOpen = true;
                    break;
                case KeyCode.Enter:
                    if (this.ngSelect.openOnEnter && !this.ngSelect.hasValue) {
                        this.isOpen = true;
                    }
                    break;
                case KeyCode.Tab:
                    this.isOpen = !this.isOpen;
                    break;
                case KeyCode.Esc:
                    this.isOpen = !this.isOpen;
                    break;
            }
        }
    };
    return BaseInputSelectComponent;
}(BaseInputComponent));
export { BaseInputSelectComponent };
