<!-- STATIC MODE -->
<ng-template [ngIf]="!editing" [ngIfElse]="inEdit">
  <a class="editable-text editable editable-click"
     *ngIf="enabled"
     tooltip="{{tooltipText}}"
     container="body"
     (click)="onClickText()"
     (focus)="onFocusText()">{{value || 'N/A'}}</a>
  <label class="editable-text"
         *ngIf="!enabled"
         tooltip="{{tooltipText}}"
         container="body">{{value || 'N/A'}}</label>
</ng-template>

<!-- EDIT MODE -->
<ng-template #inEdit>

  <div class="input-group input-group-sm input-group-inplace" style="width: 100%; min-width: 20px"
       (clickOutside)="clickOutside()"  [clickOutsideEnabled]="editing" [delayClickOutsideInit]="true">
    <input #textInput type="text" class="form-control" (blur)="onBlurEditor($event)"
           [type]="type" *ngIf="!custom"
           data-toggle="dropdown" aria-expanded="true"
           [ngModelOptions]="{standalone: true}"
           [(ngModel)]="currentValue" (keydown)="onKeyDown($event)">

    <ng-template [ngTemplateOutlet]="customTemplate"></ng-template>

  </div>

</ng-template>
