import {Component, Injectable, OnInit} from '@angular/core';
import {WebcamImage} from "ngx-webcam";
import {Location} from '@angular/common';

@Injectable()
export class CameraData {
  images: WebcamImage[] = [];
  documentType: string = 'AAA';
  title: string;
  docTypes: string[];
  onSubmit: () => void;
}

@Component({
  selector: 'oms-camera-page',
  templateUrl: './document-camera-page.component.html',
  styleUrls: ['./document-camera-page.component.scss']
})
export class DocumentCameraPageComponent implements OnInit {

  constructor(
    public data: CameraData,
    public location: Location) {

  }

  ngOnInit() {
    console.log('INIT', this.data);
  }
}
