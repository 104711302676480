var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { DriverGps } from "../modules/shared/models/driver.gps";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "./http-utils.service";
var DriverGpsService = /** @class */ (function (_super) {
    __extends(DriverGpsService, _super);
    function DriverGpsService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/driver-gps/';
        _this.classType = DriverGps;
        return _this;
    }
    DriverGpsService.prototype.getCurrentLocation = function (driverId) {
        var _this = this;
        return this.httpRestService.get(this.apiUrl + 'driver/' + driverId)
            .pipe(map(function (item) { return plainToClass(_this.classType, item); }));
    };
    DriverGpsService.ngInjectableDef = i0.defineInjectable({ factory: function DriverGpsService_Factory() { return new DriverGpsService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: DriverGpsService, providedIn: "root" });
    return DriverGpsService;
}(BaseEntityService));
export { DriverGpsService };
