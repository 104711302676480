import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CbpStatusTableComponent } from "./cbp-status-table/cbp-status-table.component";

@Component({
  selector: 'oms-cbp-status-dialog',
  templateUrl: './cbp-status-dialog.component.html',
  styleUrls: ['./cbp-status-dialog.component.scss']
})
export class CbpStatusDialogComponent {

  public static readonly FILTER_BY_STATUS = '1F';

  @ViewChild(CbpStatusTableComponent) cbpStatusTableComponent: CbpStatusTableComponent;

  constructor(public dialogRef: MatDialogRef<CbpStatusDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  getTitle(): string {
    return 'CBP Statuses';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  generateExcel() {
    this.cbpStatusTableComponent.generateExcel();
    this.dialogRef.close();
  }
}
