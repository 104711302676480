import {Injectable} from '@angular/core';
import {createNumberMask, emailMask} from 'text-mask-addons/dist/textMaskAddons';
import {ContainerSizes, CurrencyUnits, PackagingUnits, VolumeUnits, WeightUnits} from './oms-unit-types';

/*
 * Documentation: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 * create number mask addon:  https://github.com/text-mask/text-mask/tree/master/addons#createnumbermask
 */


export const WEIGHT_DECIMAL_DIGITS = 0;
export const tooltipConfig = { container: 'body', delay: 1000 };


@Injectable()
export class OmsConstants {
  public static mawbMask = {mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/], keepCharPositions: false};
  public static mawbPlaceholder = '___-____ ____';

  public static contNumberMask = {mask: [/[A-Z,a-z]/, /[A-Z,a-z]/, /[A-Z,a-z]/, /[A-Z,a-z]/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/], pipe: (value: string) => value.toUpperCase(), keepCharPositions: false};
  public static contNumberPlaceholder = '____ ______-_';

  public static hawbMask = {mask: [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/], keepCharPositions: false};
  public static hawbPlaceholder = '___________';

  public static phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public static emailMask = emailMask;

  //  @Inject(LOCALE_ID) private _locale: string;
  public static DEFAULT_LOCALE = 'en-US';
  public static DATE_FORMAT = 'MM/dd/yyyy';
  public static MOMENT_DATE_FORMAT = 'MM/DD';
  public static MOMENT_DATE_FORMAT_WITHOUT_YEAR = 'MM/DD';
  public static DEFAULT_TIME_ZONE = "EST";
  public static DATE_TIME_FORMAT = 'MM/dd/yyyy h:mma z';
  public static LONG_DATE_TIME_FORMAT = 'MMM D YYYY h:mmA';
  public static MOMENT_DATE_TIME_FORMAT = 'MM/DD  hh:mmA';
  public static MOMENT_DATE_YEAR_TIME_FORMAT = 'MM/DD/YYYY  hh:mmA';
  public static MOMENT_DATE_TIME_ZONE_FORMAT = 'MM/DD  hh:mmA z';
//  public static MOMENT_TIME_FORMAT = 'hh:mmA';
  public static MOMENT_TIME_SHORT_FORMAT = 'HH:mm';
//  public static STANDART_DATE_TIME_FORMAT = 'yyyy-MM-ddTHH:mm:ss';
//  public static MOMENT_STANDART_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  public static DEFAULT_WEIGHT_UNITS = WeightUnits.WEIGHT_KG;
  public static DEFAULT_VOLUME_UNITS = VolumeUnits.VOLUME_CFT;

  public static EMPTY_VALUE = 'N/A';
  public static MULTIPLE_VALUE = 'multiple';
  public static VARIOUS_VALUE = 'various';

  public static ETC_ZONE = 'America/New_York';
  public static UTC_ZONE = 'Europe/London';

  public static SARCONA_CFS_ID = 7680;

  public static CFS_PROPERTIES = [{
    contactName: 'Ed Teta',
    address1: 'JJS Transportation & Distribution Co., Inc',
    address2: '145 Hook Creek Blvd',
    address3: 'Valley Stream NY 11581',
    sarcona: false,
  }, {
    contactName: 'Marlice Moore',
    address1: 'Sarcona Management Inc',
    address2: '10 North Avenue East',
    address3: 'Elizabeth, NJ 07201',
    sarcona: true,
  }];


  public ADDRESS_SEARCH_PLACEHOLDER = 'Enter Name and select';
  public AIRLINE_SEARCH_PLACEHOLDER = 'Select Airline';

  public CUSTOMER_REF_LENGTH = 20;

  constructor(
    public weightUnits: WeightUnits,
    public volumeUnits: VolumeUnits,
    public currencyUnits: CurrencyUnits,
    public packagingUnits: PackagingUnits,
    public containerSizes: ContainerSizes) {
  }

// *** just testing
  public dateMask = {
    mask: [/\d/, /\d/, '-', /[A-Z]/i, /[a-z]/i, /[a-z]/i, '-', /\d/, /\d/, /\d/, /\d/],
    pipe: (value: string) => value.toLowerCase(),      // function(conformedValue:string) {return conformedValue.toUpperCase()},
    keepCharPositions: false
  };
 // */

  public zipCodeMask = {
      mask: function (value: string) {
        value = value.replace(/[\W_]/g, '');
        if (value.length > 5) {
          return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        }
        return [/\d/, /\d/, /\d/, /\d/, /\d/];
      },
      guide: true
  };

  public piecesPlaceholder = '00';
  public piecesMask = createNumberMask({
    prefix: '',
    suffix: ''
  });

  get mawbMask() {
    return OmsConstants.mawbMask;
  }

  get mawbPlaceholder() {
    return OmsConstants.mawbPlaceholder;
  }

  public doubleMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true
  });

  public weightPlaceholder = '0.00';
  public weightMask = createNumberMask({
       prefix: '',
       suffix: '',
       allowDecimal: true,
       decimalLimit: 2
  });

  public volumePlaceholder = '0.00';
  public volumeMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2
  });

  public currencyPlaceholder = '0.00';
  public currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2
  });

}

