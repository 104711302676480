var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { BaseEntity } from "../base-entity";
import { Exclude, Type } from "class-transformer";
import { isNullOrUndefined } from "util";
import { Address, CfsLocation } from "../address";
import { Status } from "./status";
var ɵ0 = function () { return Address; }, ɵ1 = function () { return CfsLocation; };
var OrderPart = /** @class */ (function (_super) {
    __extends(OrderPart, _super);
    function OrderPart() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.status = Status.UNDEFINED;
        _this.receivings = [];
        _this.modified = false;
        return _this;
    }
    Object.defineProperty(OrderPart.prototype, "warnings", {
        get: function () {
            return this.overage || this.shortage || this.damage || (!isNullOrUndefined(this.comments) && this.comments.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    OrderPart.prototype.isHuGreaterThanPcs = function () {
        return ~~this.huProcessed > ~~this.pcsProcessed;
    };
    OrderPart.prototype.mustHaveHu = function () {
        return this.status !== Status.UNDEFINED && this.pcsProcessed > 0 && !~~this.huProcessed;
    };
    OrderPart.prototype.isHuInvalid = function () {
        return this.mustHaveHu() || this.isHuGreaterThanPcs();
    };
    OrderPart.prototype.isProcessedAny = function () {
        return ~~this.pcsProcessed < ~~this.order.pieces;
    };
    OrderPart.prototype.isProcessedInFull = function () {
        return ~~this.order.pieces <= ~~this.pcsProcessed;
    };
    Object.defineProperty(OrderPart.prototype, "processedInFull", {
        get: function () {
            return ~~this.order.pieces <= ~~this.pcsProcessed;
        },
        enumerable: true,
        configurable: true
    });
    OrderPart.prototype.isProcessedOver = function () {
        return ~~this.order.pieces < ~~this.pcsProcessed;
    };
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean)
    ], OrderPart.prototype, "open", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Number)
    ], OrderPart.prototype, "orderNumber", void 0);
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Address)
    ], OrderPart.prototype, "address", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", CfsLocation)
    ], OrderPart.prototype, "cfsLocation", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean)
    ], OrderPart.prototype, "modified", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderPart.prototype, "warnings", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderPart.prototype, "processedInFull", null);
    return OrderPart;
}(BaseEntity));
export { OrderPart };
export { ɵ0, ɵ1 };
