/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editable-cell.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/tooltip";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "ngx-bootstrap/positioning";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "ng-click-outside/lib/click-outside.directive";
import * as i8 from "./editable-cell.component";
var styles_EditableCellComponent = [i0.styles];
var RenderType_EditableCellComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_EditableCellComponent, data: {} });
export { RenderType_EditableCellComponent as RenderType_EditableCellComponent };
function View_EditableCellComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "a", [["class", "editable-text editable editable-click"], ["container", "body"]], [[1, "aria-describedby", 0]], [[null, "click"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickText() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.onFocusText() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { tooltip: [0, "tooltip"], container: [1, "container"] }, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.tooltipText, ""); var currVal_2 = "body"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).ariaDescribedby; _ck(_v, 0, 0, currVal_0); var currVal_3 = (_co.value || "N/A"); _ck(_v, 2, 0, currVal_3); }); }
function View_EditableCellComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "label", [["class", "editable-text"], ["container", "body"]], [[1, "aria-describedby", 0]], null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { tooltip: [0, "tooltip"], container: [1, "container"] }, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.tooltipText, ""); var currVal_2 = "body"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).ariaDescribedby; _ck(_v, 0, 0, currVal_0); var currVal_3 = (_co.value || "N/A"); _ck(_v, 2, 0, currVal_3); }); }
function View_EditableCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableCellComponent_2)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableCellComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enabled; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.enabled; _ck(_v, 3, 0, currVal_1); }, null); }
function View_EditableCellComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["textInput", 1]], null, 6, "input", [["aria-expanded", "true"], ["class", "form-control"], ["data-toggle", "dropdown"], ["type", "text"]], [[8, "type", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "blur"], [null, "ngModelChange"], [null, "keydown"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (_co.onBlurEditor($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.currentValue = $event) !== false);
        ad = (pd_5 && ad);
    } if (("keydown" === en)) {
        var pd_6 = (_co.onKeyDown($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i6.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, { update: "ngModelChange" }), i1.ɵpod(4, { standalone: 0 }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.currentValue; var currVal_9 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_EditableCellComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_EditableCellComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "input-group input-group-sm input-group-inplace"], ["style", "width: 100%; min-width: 20px"]], null, [[null, "clickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOutside" === en)) {
        var pd_0 = (_co.clickOutside() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 737280, null, 0, i7.ClickOutsideDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { clickOutsideEnabled: [0, "clickOutsideEnabled"], delayClickOutsideInit: [1, "delayClickOutsideInit"] }, { clickOutside: "clickOutside" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableCellComponent_5)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableCellComponent_6)), i1.ɵdid(5, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editing; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.custom; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.customTemplate; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_EditableCellComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { textInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableCellComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["inEdit", 2]], null, 0, null, View_EditableCellComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EditableCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "editable-cell", [], null, null, null, View_EditableCellComponent_0, RenderType_EditableCellComponent)), i1.ɵdid(1, 638976, null, 1, i8.EditableCellComponent, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { customTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditableCellComponentNgFactory = i1.ɵccf("editable-cell", i8.EditableCellComponent, View_EditableCellComponent_Host_0, { custom: "custom", value: "value", type: "type", enabled: "enabled", tooltipText: "tooltipText" }, { valueChange: "valueChange" }, []);
export { EditableCellComponentNgFactory as EditableCellComponentNgFactory };
