import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../services';

@Directive({selector: '[ifRole]'})
export class IfRoleDirective implements OnInit, OnDestroy {
  user$: Subscription;
  @Input("ifRole") role: string | string[];

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.user$ = this.authService.user$.subscribe((user) => {
      if (user) {

        if (typeof this.role === 'string' ? user.hasRole(this.role) : (!this.role || !this.role['length'] || user.hasAnyRoles(this.role))) {
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.user$) {
      this.user$.unsubscribe();
    }
  }
}
