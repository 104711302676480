export enum UserRoleType {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_USER = 'ROLE_USER',
  ROLE_WAREHOUSE = 'ROLE_WAREHOUSE',
  ROLE_EXTERNAL_WAREHOUSE = 'ROLE_EXTERNAL_WAREHOUSE',
  ROLE_QA = 'ROLE_QA',
  ROLE_DRIVER = 'ROLE_DRIVER',
  ROLE_AMS = 'ROLE_AMS',
  ROLE_CUSTOMER = 'ROLE_CUSTOMER'
}
