/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert-dialog.component";
import * as i4 from "@angular/material/dialog";
var styles_AlertDialogComponent = [i0.styles];
var RenderType_AlertDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertDialogComponent, data: {} });
export { RenderType_AlertDialogComponent as RenderType_AlertDialogComponent };
function View_AlertDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h4", [["style", "margin-top: -10px; margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["\u00A0", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClass; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dialogCaption; _ck(_v, 3, 0, currVal_1); }); }
function View_AlertDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"], ["style", "margin: 15px 0 0 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "btn-toolbar pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick(_co.button) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.button == null) ? null : _co.button.icon), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.button == null) ? null : _co.button.caption); _ck(_v, 4, 0, currVal_1); }); }
export function View_AlertDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertDialogComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertDialogComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert-dialog"; var currVal_1 = _co.dialogClass; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.mode; _ck(_v, 3, 0, currVal_2); var currVal_4 = !!_co.button; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.message; _ck(_v, 4, 0, currVal_3); }); }
export function View_AlertDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-alert-dialog", [], null, null, null, View_AlertDialogComponent_0, RenderType_AlertDialogComponent)), i1.ɵdid(1, 4243456, null, 0, i3.AlertDialogComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], null, null); }
var AlertDialogComponentNgFactory = i1.ɵccf("oms-alert-dialog", i3.AlertDialogComponent, View_AlertDialogComponent_Host_0, {}, {}, []);
export { AlertDialogComponentNgFactory as AlertDialogComponentNgFactory };
