import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {FilterContainer} from '../modules/shared/models';
import {RestService} from "./rest.service";
@Injectable()
export class FilterService {
  constructor(private restService: RestService) {}
  public filterPopUpEvent = new Subject<any>();

  public customFilterContainers = new Subject<FilterContainer[]>();

  private createFilterContainerURL = "/filters/create-filter-container";

  public showFilterPopUp(filterContainer: FilterContainer = null) {
    this.filterPopUpEvent.next({
      show: true,
      filterContainer: filterContainer
    });
  }

  initFilterContainers() {
    const customFilters: FilterContainer[] = [];
/*    customFilters.push(new FilterContainer(12, 'Some Name', []));
    customFilters.push(new FilterContainer(11, 'Some adsdasd', []));
    customFilters.push(new FilterContainer(16, 'Some zxcz', []));
    customFilters.push(new FilterContainer(18, 'Some fdfsdf', []));
    customFilters.push(new FilterContainer(19, 'Some asdfsdf', [])); */
    this.customFilterContainers.next(customFilters);
 }

 public createFilter(filterContainer: FilterContainer) {
    this.restService.post(this.createFilterContainerURL, filterContainer).subscribe();
 }


  public hideFilterPopUp() {
    this.filterPopUpEvent.next({
      show: false,
      filterContainer: null
    });
  }
}
