<oms-data-table #table
                [striped]="true"
                [condensed]="true"
                [selectable]="false"
                [searchable]="false"
                [multi-select]="false"
                [checkboxes]="true"
                [fixed]="false"
                [fixed-columns]="6"
                [columns]="columns"
                [defaultColumnForSort]="defaultSortColumn"
                [data]="cbpStatuses"
                [specificRowClassHandler]="specificRowClassHandler"
                [row-class]="getRowClass.bind(this)">
</oms-data-table>
