/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-battery-table-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/popover";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "ngx-bootstrap/positioning";
import * as i6 from "./date-battery-table-cell.component";
import * as i7 from "../../data-table.component";
import * as i8 from "ngx-contextmenu";
import * as i9 from "../../../../../services/dispatch/dispatch.service";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../../../../services/date-time.service";
import * as i12 from "../../../../../../../services/logchanges/log.changes.service";
import * as i13 from "../../../../../../../services/orders.service";
import * as i14 from "../../../../../../../services/master/master.service";
var styles_DateBatteryTableCellComponent = [i0.styles];
var RenderType_DateBatteryTableCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateBatteryTableCellComponent, data: {} });
export { RenderType_DateBatteryTableCellComponent as RenderType_DateBatteryTableCellComponent };
function View_DateBatteryTableCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.iconClass, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_DateBatteryTableCellComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { textInput: 0 }), i1.ɵqud(402653184, 2, { menu: 0 }), (_l()(), i1.ɵeld(2, 16777216, null, null, 5, "span", [["class", "no-wrap"], ["container", "body"]], [[4, "cursor", null], [8, "hidden", 0]], [[null, "click"], [null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCellClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("contextmenu" === en)) {
        var pd_1 = (_co.onRightClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "editable-empty": 0 }), i1.ɵdid(5, 212992, null, 0, i3.PopoverDirective, [i3.PopoverConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i5.PositioningService], { popover: [0, "popover"], container: [1, "container"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateBatteryTableCellComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.cellClass; var currVal_3 = _ck(_v, 4, 0, (_co.editable && _co.empty)); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.tooltip; var currVal_5 = "body"; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = _co.hasIcon; _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isReadOnly() ? "default" : "pointer"); var currVal_1 = _co.isHidden; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_DateBatteryTableCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DateBatteryTableCellComponent_0, RenderType_DateBatteryTableCellComponent)), i1.ɵdid(1, 770048, null, 0, i6.DateBatteryTableCellComponent, [i7.DataTableComponent, i1.ChangeDetectorRef, i8.ContextMenuService, i9.DispatchService, i10.MatDialog, i11.DateTimeService, i12.LogChangesService, i13.OrdersService, i14.MasterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateBatteryTableCellComponentNgFactory = i1.ɵccf("ng-component", i6.DateBatteryTableCellComponent, View_DateBatteryTableCellComponent_Host_0, { row: "row", data: "data", column: "column", table: "table", search: "search" }, {}, []);
export { DateBatteryTableCellComponentNgFactory as DateBatteryTableCellComponentNgFactory };
