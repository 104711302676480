import 'reflect-metadata';
import {isObject} from "../_helpers/utils";

const _afterLoad = Symbol('AfterLoad');
const _beforeSave = Symbol('BeforeSave');

export function AfterLoad(data?: any) {
  return registerProperty(_afterLoad, data);
}

export function BeforeSave() {
  return registerProperty(_beforeSave);
}

export function onAfterLoad<T>(o: T): T {
  if (isObject(o)) {
    iterateRegisteredProperties(_afterLoad, o, (property: Property) => o[property.key]());
  }
  return o;
}

export function onBeforeSave<T>(o: T): T {
  if (isObject(o)) {
    iterateRegisteredProperties(_beforeSave, o, (property: Property) => o[property.key]());
  }
  return o;
}

interface Property {
  key: string;
  descriptor: PropertyDescriptor;
  data?: any;
}

function registerProperty(metadataKey: any, data?: any): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => void {
  return (target: object, propertyKey: string, descriptor: PropertyDescriptor) => {
    let properties: Property[] = Reflect.getMetadata(metadataKey, target);

    let property: Property = {key: propertyKey, descriptor: descriptor, data: data};

    if (properties) {
      properties.push(property);
    } else {
      properties = [property];
      Reflect.defineMetadata(metadataKey, properties, target);
    }

  };

}

function iterateRegisteredProperties(metadataKey: any, origin: any, proc: (property: Property) => void) {
  const properties: Property[] = Reflect.getMetadata(metadataKey, origin);
  if (properties) {
    properties.forEach((property) => proc(property));
  }
}
