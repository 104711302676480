import { DefaultLoggerConfiguration } from './default-logger.configuration';
import { isDevMode, NgZone } from '@angular/core';
import { ArrayUtils } from "../array.utils";
var statistics;
var ngZone;
var excludeLogging = [
    // new RegExp(/ngDoCheck/),
    // new RegExp(/ngOnChanges/),
    // new RegExp(/trackByRowObject/),
    // new RegExp(/ngAfterViewInit/),
    // new RegExp(/trackFn/),
    new RegExp(/DataTableRowComponent/),
    new RegExp(/DataTableComponent/),
    new RegExp(/DataTableCell/),
    new RegExp(/BaseColumn/),
    new RegExp(/MawbTableCellComponent/),
    new RegExp(/DataTableRowComponent.isChild/),
    new RegExp(/DataTableComponent.sortedBy/),
    new RegExp(/DataTableComponent.sortedAsc/),
    new RegExp(/DataTableComponent.columnByRow/),
    new RegExp(/DataTableComponent.isLeaf/),
    new RegExp(/DataTableComponent.getRowHeight/),
    new RegExp(/DataTableComponent.getRowDragScope/),
    new RegExp(/DataTableComponent.getRowClass/),
    new RegExp(/DataTableComponent.isClearTableInputs/),
    new RegExp(/DataTableComponent.recalculateDims/),
    new RegExp(/DataTableComponent.scrollHeight/),
    new RegExp(/DataTableComponent.isSelectedRow/),
    new RegExp(/DataTableComponent.rowCount/),
    new RegExp(/DataTableComponent.hasData/),
    new RegExp(/DataTableComponent.noData/),
    new RegExp(/DataTableComponent.onMouseMove/),
    new RegExp(/DataTableComponent.isSelectedPartially/),
    new RegExp(/DataTableComponent.isSelectedAll/),
    new RegExp(/DataTableComponent.getHeaderSearchItems/),
    new RegExp(/DataTableComponent.vertScrollBarWidth/),
    new RegExp(/DataTableCell.ngOnInit/),
    new RegExp(/DataTableCell.getDownloadPdfLink/),
    new RegExp(/DataTableCell.initSearch/),
    new RegExp(/DataTableCell.cellButton/),
    new RegExp(/DataTableCell.updateObject/),
    new RegExp(/DataTableCell.selectOptions/),
    new RegExp(/DataTableCell.searchService/),
];
var includeLogging = [
// new RegExp(/DataTable/)
];
export function Logger(inputOptions) {
    var options = new DefaultLoggerConfiguration();
    if (inputOptions) {
        Object.assign(options, inputOptions);
    }
    if (!DefaultLoggerConfiguration.isGlobalLoggingTurnOn || !options.isTurnOn || (!options.isLoggingEachMethod && !options.isDisplayStatistics)) {
        return function (target) {
            return target;
        };
    }
    if (!ngZone) {
        initStatistics();
    }
    return function (target) {
        var className = options.name || target.name;
        var fields = getAllProperties(target);
        var _loop_1 = function (i) {
            var propertyName = fields[i];
            var path = className + '.' + propertyName;
            if (!isLoggingMethod(path)) {
                return "continue";
            }
            var descriptor = Object.getOwnPropertyDescriptor(target.prototype, propertyName);
            if (descriptor && descriptor.get) {
                var getter_1 = descriptor.get;
                Object.defineProperty(target.prototype, propertyName, {
                    get: function () {
                        return wrapMethod(this, arguments, getter_1, path, options);
                    }
                });
            }
            else {
                var propertyValue_1 = target.prototype[propertyName];
                var isMethod = propertyValue_1 instanceof Function;
                if (!isMethod)
                    return "continue";
                target.prototype[propertyName] = function () {
                    return wrapMethod(this, arguments, propertyValue_1, path, options);
                };
            }
        };
        for (var i = 0; i < fields.length; i++) {
            _loop_1(i);
        }
        return target;
    };
    function wrapMethod(context, args, method, path, options) {
        var t1 = 0;
        var t2 = 0;
        var res;
        if (options.isDisplayStatistics || (options.isLoggingEachMethod && options.isLoggingTime)) {
            t1 = window.performance.now();
            res = method.apply(context, args);
            t2 = window.performance.now();
        }
        else {
            res = method.apply(context, args);
        }
        if (options.isDisplayStatistics) {
            if (!statistics[path]) {
                statistics[path] = {
                    time: 0,
                    times: 0
                };
            }
            var statistic = statistics[path];
            statistic.time += (t2 - t1);
            statistic.times += 1;
        }
        if (options.isLoggingEachMethod) {
            var message = path;
            var args_1 = [];
            if (options.isLoggingResult) {
                message += ', result=%o';
                args_1.push(res);
            }
            if (options.isLoggingTime) {
                message += ', time=%o ms';
                args_1.push(Math.round((t2 - t1) * 1000) / 1000);
            }
            console.log.apply(console, [message].concat(args_1));
        }
        return res;
    }
    function isLoggingMethod(methodName) {
        if (includeLogging.length) {
            var isIncluded = includeLogging.some(function (pat) { return pat.test(methodName); });
            if (!isIncluded) {
                return false;
            }
        }
        if (excludeLogging.length) {
            var isExcluded = excludeLogging.some(function (pat) { return pat.test(methodName); });
            if (isExcluded) {
                return false;
            }
        }
        return true;
    }
    function getAllProperties(obj) {
        var props = [];
        if (options.isLoggingParentMethod && obj.prototype && obj.prototype.prototype) {
            for (var prop in obj.prototype.prototype) {
                props.push(prop);
            }
        }
        for (var prop in obj.prototype) {
            props.push(prop);
        }
        return ArrayUtils.removeDuplicate(props, function (p) { return p; });
    }
}
function initStatistics() {
    ngZone = new NgZone({ enableLongStackTrace: isDevMode() });
    statistics = {};
    ngZone.runOutsideAngular(function () {
        setInterval(function () {
            var displayStatistics = [];
            for (var timeElement in statistics) {
                displayStatistics.push({
                    name: timeElement,
                    time: statistics[timeElement].time,
                    times: statistics[timeElement].times,
                    avg: statistics[timeElement].time / statistics[timeElement].times
                });
            }
            displayStatistics = displayStatistics
                .sort(function (v1, v2) { return v2.time - v1.time; })
                .filter(function (v, index) { return index < 10; });
            if (displayStatistics.length) {
                console.log('statistics - %o', displayStatistics);
            }
            statistics = {};
        }, DefaultLoggerConfiguration.displayStatisticsInterval || 20000);
    });
}
