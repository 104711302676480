export class AccessPage {
  constructor(public id: string,
              public label: string) {
  }

  public get path(): string {
    return this.id;
  }
}

export enum AccessPageType {
  WAREHOUSE_LIST = '/warehouse/warehouse-list',
  WAREHOUSE_UPDATE = '/warehouse/update',
  WAREHOUSE_MULTIPLE_ORDER = '/warehouse/multiple-order',
  WEB_DRIVERS = '/webdrivers'
}

export namespace AccessPages {
  const pages: AccessPage[] = [
    new AccessPage(AccessPageType.WAREHOUSE_LIST, "Warehouse List"),
    new AccessPage(AccessPageType.WAREHOUSE_UPDATE, "Warehouse Update"),
    new AccessPage(AccessPageType.WAREHOUSE_MULTIPLE_ORDER, "Warehouse Multiple Order"),
    new AccessPage(AccessPageType.WEB_DRIVERS, "Web Drivers")
  ];

  export function values(): AccessPage[] {
    return pages.map(p => new AccessPage(p.id, p.label));
  }

  export function label(accessPage: AccessPageType | string): string {
    const page = pages.find(p => p.path === accessPage);
    return page ? page.label : null;
  }

}
