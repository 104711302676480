import {ShipmentContent} from '../shipment.content';
import {Airline, Driver, Order, Trailer, Truck, UldRecovery, User} from '..';
import {Load} from '../load';
import {Master} from './master';
import {Shipment} from '../shipment';
import {toFixed} from '../../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {Labeled} from '../../../../common/oms-types';
import {RowObject} from '../../components/base/data-table/columns/column-types';
import {convertDateTime, convertRecoveryOrderNumber} from "../../services/oms-converters.service";
import {Exclude} from "class-transformer";
import {Address} from "../address";

export class RecoveryOrder implements RowObject, Labeled {
  public rowId = 'Split';
  label: string;
  public shipment: Shipment;
  public load: Load;
  public uldsRecovered: UldRecovery[] = [];

  constructor (
    public master: Master,
    public order: Order) {

    this.label = convertRecoveryOrderNumber(this.order && this.order.id);

    this.shipment = order &&  order.shipmentContents && order.shipmentContents.length ? order.shipmentContents[0].shipment : null;
    this.load = this.shipment && this.shipment.load;
    this.uldsRecovered = order && order.uldsRecovered;
  }

  get documents(): number {return this.order.documents; }
  get overageNotes(): number { return this.order.overageNotes; }
  get shortageNotes(): number { return this.order.shortageNotes; }
  get damageNotes(): number { return this.order.damageNotes; }
  get hazardousNotes(): number { return this.order.hazardousNotes; }
  get defaultNotes(): number { return this.order.defaultNotes; }
  get podDocuments(): number { return this.order.podDocuments; }
  get isOSD() { return this.order.isOSD; }

  get isVirtual(): boolean {
    return isNullOrUndefined(this.shipment);
  }

  @Exclude()
  get isRecovery(): boolean {
    return true;
  }

  @Exclude()
  get isRecovered(): boolean {
    return !this.isVirtual && this.shipment.isDelivered;
  }

  @Exclude()
  get isPickedUp(): boolean {
    return !this.isVirtual && this.shipment.isPickedUp;
  }

  @Exclude()
  get isDispatched(): boolean {
    return !this.isVirtual && this.shipment.isDispatched;
  }

  @Exclude()
  get isStaging(): boolean {
    return !this.isVirtual && this.shipment.isStaging;
  }

  get loadId() {
    return this.load ? this.load.id : null;
  }

  get masterId() {
    return this.master.id;
  }


  get orderId(): number {
    return this.order ? this.order.id : null;
  }

  get number(): string {
    return convertRecoveryOrderNumber(this.orderId);
  }


  get driver(): Driver {
    return this.load ? this.load.driver : null;
  }

  get truck(): Truck {
    return this.load ? this.load.truck : null;
  }

  get trailer(): Trailer {
    return this.load ? this.load.trailer : null;
  }

  get dateDispatched(): Date {
    return this.shipment && this.shipment.dispatchedForRecovery;
  }

  get dispatchedByUser(): User {
    return this.shipment && this.shipment.dispatchedByUser;
  }

  get pieces(): number {
    return this.order.pieces;
  }

  get hu(): number {
    return this.order.hu;
  }


  set pieces(value: number) {
    this.order.pieces = value;
  }

  get dateDeliveryAct(): Date {
    return this.shipment ? this.shipment.dateDeliveryActual : null;
  }

  set dateDeliveryAct(value: Date) {
    if (this.shipment) { this.shipment.dateDeliveryActual = value; }
  }

  // Sum of Received at CFS per HAWB
  get pcsReceived(): number {
    return this.order && this.order.pcsReceived;
  }

  get pcsDispatched(): number {
    return this.shipment && this.shipment.pieces;
  }

  // Sum of Received at CFS per HAWB
  get weightReceived(): number {
    return 0; // toFixed(this.contents.aggregate((acc, sc) => acc + +sc.weight, 0), 2) || null;
  }

  get huReceived(): number {
    return 0; // this.contents.aggregate((acc, sc) => acc + +sc.hu, 0);
  }

  get huToRecover(): number {
    return this.order && this.order.hu;
  }

  get pcsToRecover(): number {
    return this.order && this.order.pieces;
  }

  get cargoBuilding(): Address {
    return this.master.cargoBuilding;
  }

  set cargoBuilding(address: Address) {
    this.master.cargoBuilding = address;
  }

  get addressDelivery(): Address {
    return this.isVirtual ? this.master.addressCfs : this.shipment.addressDelivery;
  }

  get addressPickup(): Address {
    return this.isVirtual ? this.master.cargoBuilding : this.shipment.addressPickUp;
  }

  get airline(): Airline {
    return this.master.airline;
  }

  get flightNumber(): string {
    return this.master.flightNumber;
  }

  get datePickupAct(): Date {
    return this.isVirtual ? null : this.shipment.datePickUpActual;
  }

  set datePickupAct(value: Date) {
    if (!this.isVirtual) { this.shipment.datePickUpActual = value; }
  }

  get datePickupEst(): Date {
    return this.isVirtual ? null : this.shipment.datePickUpExpectation;
  }

  set datePickupEst(value: Date) {
    if (!this.isVirtual) { this.shipment.datePickUpExpectation = value; }
  }

  get dispatchedOn() {
    return this.load && this.load.driver ? this.load.driver.fullName : undefined;

  }

  get dispatchedTooltip() {
    return this.load && this.load.driver ? this.dispatchedTooltipBase + "\nDispatched On: " + this.load.driver.fullName : this.dispatchedTooltipBase;
  }

  get dispatchedTooltipBase() {
    return this.shipment ?
      (
        (isNullOrUndefined(this.shipment.dispatchedByUser) ? "" : "Dispatched By: " + this.shipment.dispatchedByUser.fullName + "\n") +
        (isNullOrUndefined(this.shipment.dispatchedForRecovery) ? "" : "Dispatched At: " + convertDateTime(this.shipment.dispatchedForRecovery)))
      : "";
  }

  hasProblem() {
    return this.shipment && this.shipment.hasProblem;
  }

  public isCompleted(): boolean {
    return !this.isVirtual  && this.shipment.isPickedUp && !this.shipment.hasProblem;
  }

  public isCompletedWithProblem(): boolean {
    return !this.isVirtual  && this.shipment.isPickedUp && this.shipment.hasProblem;
  }

  public isNotCompleted(): boolean {
    return this.shipment  && !this.shipment.isPickedUp && this.shipment.hasProblem;
  }

  public isConfirmed(): boolean {
    return this.shipment && !isNullOrUndefined(this.shipment.dateConfirmedOn);
  }

  getRouteStatus(): string {
//    return this.shipment ? this.shipment.dateConfirmedOn+"" : 'aaa';
    if (this.isCompleted()) {
      return 'Completed';
    } else if (this.isCompletedWithProblem()) {
      return 'Completed with problem';
    } else if (this.isNotCompleted()) {
      return 'Not Completed';
    } else if (this.isConfirmed()) {
      return 'Confirmed';
    }  else if (this.isStaging) {
      return 'Staging';
    } else if (this.isDispatched) {
      return 'Dispatched';
    } else {
      return "Pending";
    }
  }

  @Exclude()
  public getRouteStatusClass(): string {
    switch (this.getRouteStatus()) {
      case 'Completed':
        return 'success';
      case 'Not Completed':
        return 'danger';
      case 'Dispatched':
        return 'load-dispatched';
    }
  }
}

export class Split extends RecoveryOrder {
  public rowId = 'Split';
  splitNumber: number;

  contents: ShipmentContent[] = [];
  pcsEstimated: number;
  huEstimated: number;

  constructor(master: Master, splitNumber: number) {
    super(master, null);
    this.master = master;
    this.splitNumber = splitNumber;
//    let mawb = this.master.mawbNumber ? this.master.mawbNumber.slice(7, 11) : '';
    this.label = 'RLD-' + this.splitNumber;
  }

  get rowClass(): string {
    return this.isVirtual ? 'virtual' : null;
  }


  get dateUpdated() {
    return this.master.dateUpdated;
  }


  isNew() {
    return this.load && this.load.isNew();
  }

  get mawbNumber(): string {
    return this.master.mawbNumber;
  }

  get customerNameBasedOnOrders(): String {
    return this.master.customerNameBasedOnOrders;
  }

  get hu(): number {
    return this.isVirtual ? this.huEstimated : this.shipment.hu;
  }

  set hu(value: number) {
    this.huEstimated = value;
    if (this.shipment) {
      this.shipment.hu = value;
    }
  }

  get pieces(): number {
    return this.isVirtual ? this.pcsEstimated : this.shipment.pieces;
  }

  set pieces(value: number) {
    this.pcsEstimated = value;
    if (this.shipment) {
      this.shipment.pieces = value;
    }
  }

  // Sum of Received at CFS per HAWB
  get pcsReceived(): number {
    return this.contents.aggregate((acc, sc) => acc + ~~sc.pieces, 0) || null;
  }

  get pcsDispatched(): number {
    return this.isDispatched ? this.shipment.pieces : 0;
//    return this.contents.aggregate((acc, sc)=>acc + (sc.shipment.datePickUpExpectation ?  ~~sc.shipment.pieces : 0), 0) || null;
  }

  // Sum of Received at CFS per HAWB
  get weightReceived(): number {
    return toFixed(this.contents.aggregate((acc, sc) => acc + +sc.weight, 0), 2) || null;
  }

  get huReceived(): number {
    return toFixed(this.contents.aggregate((acc, sc) => acc + +sc.hu, 0), 2) || null;
  }

  get pcsToRecover(): number {
    return this.pcsReceived || this.pieces;
  }

  set pcsToRecover(value: number) {
    this.pieces = value;
  }

  get huToRecover(): number {
    return this.huReceived || this.hu;
  }

  set huToRecover(value: number) {
    this.hu = value;
  }

  get volume(): number {
    return this.shipment ? this.shipment.volume : null;
  }

  set volume(value: number) {
    this.shipment.volume = value;
  }

  get weight(): number {
    return this.shipment ? this.shipment.weight : null;
  }

  set weight(value: number) {
    this.shipment.weight = value;
  }

  get dispOrRcvdTimestamps(): string {
    return this.isVirtual || isNullOrUndefined(this.shipment) ? null : (this.isPickedUp ? "CMPL: " : "DISP: ") + convertDateTime(this.shipment.dispatchedForRecovery);
  }

  get addressDelivery(): Address {
    return this.isVirtual ? null : this.shipment.addressDelivery;
  }

  get addressPickup(): Address {
    return this.isVirtual ? null : this.shipment.addressPickUp;
  }


  get isAssigned(): boolean {
    return !this.isVirtual && this.pcsToRecover > 0;
  }

  get noCfs(): boolean {
    return this.master && this.master.noCfs;
  }

  get dateArrivalAct(): Date {
    return this.isVirtual ? null : this.master.dateArrivalAct;
  }

  set dateArrivalAct(value: Date) {
    if (!this.isVirtual) { this.master.dateArrivalAct = value; }
  }

  get dateArrivalEst(): Date {
    return this.isVirtual ? null : this.master.dateArrivalEst;
  }

  set dateArrivalEst(value: Date) {
    if (!this.isVirtual) { this.master.dateArrivalEst = value; }
  }

/*
  contentOf(order:Order): ShipmentContent {
    return this.contents.findFirst(sc=>sc.order.equals(order));
  }
 */



}
