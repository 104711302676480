import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {Trailer} from '../modules/shared/models';
import {TrailerDialogComponent} from '../components/dialogs/trailer-dialog/trailer-dialog.component';
import {DialogResult, ModalResult, OmsDialogsService} from "../components/common/oms-dialogs";

@Injectable()
export class TrailerService extends BaseEntityService<Trailer> {
  apiUrl = '/oms/trailer/';
  classType = Trailer;

  public constructor(
    public dialogs: OmsDialogsService,
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public createEditAndSave(trailer: Trailer, readonly?: boolean): Promise<Trailer> {
    return new Promise<Trailer>((success, reject) => {
      this.dialogs.openDialog(TrailerDialogComponent, {trailer: trailer, readonly: readonly})
        .then((result: DialogResult<any>) => {
          if (result.result === ModalResult.OK) {
            this.save(trailer).subscribe(
              saved => success(saved),
              error => reject(error === 'DataIntegrityViolation' ? 'Can\'t create new Trailer: \n Trailer ' + trailer.number + ' already exists!' : error));
          }
        })
        .catch(error => reject(error));
    });
  }
}
