﻿import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {FormBuilder} from "@angular/forms";
import {LogChanges} from "../../../modules/shared/models/logchanges/log.changes";
import {OmsDateTimePipe} from "../../../modules/shared/services/oms-converters.service";
import {ObjectLogChanges} from "../../../modules/shared/models/logchanges/object.log.changes";
import {isNullOrUndefined} from "util";


@Component({templateUrl: 'problems-history-information.dialog.component.html',
            styleUrls: ['./problems-history-information.dialog.component.scss'],
            selector: 'oms-problems-history-information.dialog',
            changeDetection: ChangeDetectionStrategy.OnPush})
export class ProblemsHistoryInformationDialogComponent implements OnInit {

  objectLogChanges: ObjectLogChanges[];
  omsdatePipe: OmsDateTimePipe = new OmsDateTimePipe;

  constructor(public dialogRef: MatDialogRef<ProblemsHistoryInformationDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.objectLogChanges = this.data.objectLogChanges;
  }

  convertValue(value: any, logChanges: LogChanges) {
      return this.getConvertedValueByLogChanges(value, logChanges);
  }

  resolvedText(value: any) {
     if (isNullOrUndefined(value)) {
       return "Not Resolved";
     } else {
       return "Resolved";
     }
  }

  getInitialValue(value, logChanges: LogChanges[]) {
    if (logChanges.length === 0) {
      return this.resolvedText(value);
    } else {
      return this.resolvedText(logChanges[0].oldObjectValue);
    }
  }


  getConvertedValueByLogChanges(value: any, logChanges: LogChanges) {
    if (value && (logChanges.valueType.indexOf("Date") > -1 || logChanges.valueType.indexOf("Timestamp") > -1)) {
      let date = new Date(value);
      return this.omsdatePipe.transform(date);
    }
    return value;
  }



  getTitle(): string {
    return 'History Information';
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
