import {Input} from '@angular/core';
import {ValueAccessorBase} from '../value-accessor';
import {AsyncValidatorArray, message, validate, ValidationResult, ValidatorArray} from './validate';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {NgModel, ValidatorFn} from '@angular/forms';


export abstract class BaseInputComponent<T> extends ValueAccessorBase<T> {
  private static current_id = 0;

  @Input() public label: string;
  @Input() public iconClass: string;
  @Input() public header: string;
  @Input() public disabled: boolean = false;
  @Input() public placeholder: string = '';

  public isInvalid: boolean;
  public identifier = `oms-input-${BaseInputComponent.current_id++}`;
  public abstract model: NgModel;

  protected constructor(
    private validators: ValidatorArray,
    private asyncValidators: AsyncValidatorArray,
  ) {
    super();
  }

  public addValidator(validator: ValidatorFn) {
    this.validators.push(validator);
  }

  public validate(): Observable<ValidationResult> {
    return this.model ? validate(this.validators, this.asyncValidators)(this.model.control) : of(null);
  }

  public get isTouched(): boolean {
    return this.model && this.model.control.touched;
  }

  public touchControl() {
    this.model.control.markAsTouched();
  }

  public get invalid(): Observable<boolean> {
    return this.validate().pipe(map((v => this.isInvalid = Object.keys(v || {}).length > 0)));
  }

  public get failures(): Observable<Array<string>> {
    return this.validate().pipe(map((v => Object.keys(v).map(k => message(v, k)))));
  }

  public reset() {
    this.model.control.reset();
  }
}



