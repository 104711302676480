import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Airline} from '../../modules/shared/models/air/airline';
import {BaseEntityService} from '../base/base-entity.service';
import {RestService} from '../rest.service';
import {HttpUtilsService} from '../http-utils.service';

@Injectable({providedIn: 'root'})
export class AirlineService extends BaseEntityService<Airline> {
  apiUrl = '/oms/airline/';
  classType = Airline;

  public constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  getByPrefix(awbPrefix: string): Observable<Airline> {
    return this.httpRestService.get<Airline>(this.apiUrl + 'getByPrefix', awbPrefix);
  }


}
