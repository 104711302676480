import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValueAccessorBase} from '../../../base/value-accessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'oms-text-area-input',
  styleUrls: ['./selectable-text-area-input.component.scss'],
  templateUrl: './selectable-text-area-input.component.html',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: SelectableTextAreaInputComponent, multi: true}],
})
export class SelectableTextAreaInputComponent extends ValueAccessorBase<Date> implements OnInit, OnChanges {

  @Input() label: string;
  @Input() hasToggle: boolean = true;

  public isEdit: boolean = false;

  constructor() {
    super();
  }

  onCbClick() {
    if (this.value) {
      this.value = null;
    } else {
      this.isEdit = true;
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasToggle && !this.hasToggle) {
      this.isEdit = true;
    }
  }

}
