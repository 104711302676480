import { OnInit } from '@angular/core';
import { FilterPageRequest } from "../../../../../modules/shared/models/filter.page.request";
import { LoadTypes } from "../../../../../modules/shared/models";
import { CustomerService } from "../../../../../services/customer.service";
import { FreightForwarderService } from "../../../../../services/freight.forwarder.service";
import { FreightForwarderGroupService } from "../../../../../services/freight.forwarder.group.service";
import { NgSelectSearchParams } from "../../../../../modules/settings/util/ng-select-search-params";
import { OrderModes } from "../../../../../modules/shared/models/order/order-mode";
var RoutesReportFiltersComponent = /** @class */ (function () {
    function RoutesReportFiltersComponent(freightForwarderGroupService, customerService, freightForwarderService) {
        this.freightForwarderGroupService = freightForwarderGroupService;
        this.customerService = customerService;
        this.freightForwarderService = freightForwarderService;
        this.freightForwarder = [];
        this.orderModes = [];
        this.loadTypes = [];
        this.freightForwarderGroup = [];
        this.allOrderModes = OrderModes.filterItems;
        this.allLoadTypes = LoadTypes.filterItems;
        this.filterYes = { id: 1, label: 'Yes' };
        this.filterNo = { id: 0, label: 'No' };
        this.ffgSearchParams = new NgSelectSearchParams(freightForwarderService, 'name');
    }
    RoutesReportFiltersComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('filters', this.filters);
        if (this.filters) {
            for (var _i = 0, _a = this.filters.filterByColumns; _i < _a.length; _i++) {
                var filter = _a[_i];
                if (filter.field === 'customerId') {
                    this.customerService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (customer) { return _this.customer = customer; });
                }
                if (filter.field === 'freightForwarderId') {
                    if (filter.multipleValues) {
                        this.freightForwarderService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (ff) { return _this.freightForwarder = ff; });
                    }
                }
                if (filter.field === 'freightForwarderGroupId') {
                    this.freightForwarderGroupService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (ffg) { return _this.freightForwarderGroup = ffg; });
                }
                if (filter.field === 'addressDeliveryName') {
                    this.deliveryLocation = filter.value;
                }
                if (filter.field === 'orderMode') {
                    this.orderModes = filter.values.map(function (s) { return OrderModes.filterItems.find(function (item) { return ('' + item.id) === s; }); });
                }
                if (filter.field === 'loadType') {
                    this.loadTypes = filter.values.map(function (s) { return LoadTypes.filterItems.find(function (item) { return ('' + item.id) === s; }); });
                }
            }
        }
    };
    RoutesReportFiltersComponent.prototype.onCustomerChange = function () {
        if (this.customer && this.customer.length) {
            this.filters.addColumnFilter('customerId', this.customer.map(function (c) { return c.id; }));
        }
        else {
            this.filters.removeColumnFilter('customerId');
        }
    };
    RoutesReportFiltersComponent.prototype.onFreightForwarderChange = function () {
        if (this.freightForwarder && this.freightForwarder.length) {
            this.filters.addColumnFilter('freightForwarderId', this.freightForwarder.map(function (ff) { return ff.id; }));
        }
        else {
            this.filters.removeColumnFilter('freightForwarderId');
        }
        console.log('>>>', this.filters);
    };
    RoutesReportFiltersComponent.prototype.onFreightForwarderGroupChange = function () {
        if (this.freightForwarderGroup && this.freightForwarderGroup.length) {
            this.filters.addColumnFilter('freightForwarderGroupId', this.freightForwarderGroup.map(function (ffg) { return ffg.id; }));
        }
        else {
            this.filters.removeColumnFilter('freightForwarderGroupId');
        }
    };
    RoutesReportFiltersComponent.prototype.onOrderModeChange = function () {
        if (this.orderModes && this.orderModes.length) {
            //      console.log('SELECTED', this.orderModes);
            this.filters.addColumnFilter('orderMode', this.orderModes.map(function (mode) { return '' + mode.id || ''; }));
        }
        else {
            this.filters.removeColumnFilter('orderMode');
        }
    };
    RoutesReportFiltersComponent.prototype.onLoadTypeChange = function () {
        if (this.loadTypes && this.loadTypes.length) {
            //      console.log('SELECTED', this.loadTypes);
            this.filters.addColumnFilter('loadType', this.loadTypes.map(function (type) { return '' + type.id || ''; }));
        }
        else {
            this.filters.removeColumnFilter('loadType');
        }
    };
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "noRecovery", {
        get: function () {
            return this.filters.hasNamedCondition("NO_RECOVERY");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("NO_RECOVERY");
            }
            else {
                this.filters.removeNamedCondition("NO_RECOVERY");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "activeOnly", {
        get: function () {
            return this.filters.hasNamedCondition("ACTIVE_ONLY");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("ACTIVE_ONLY");
            }
            else {
                this.filters.removeNamedCondition("ACTIVE_ONLY");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "noArchived60", {
        get: function () {
            return this.filters.hasNamedCondition("NO_ARCHIVED_OVER_60");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("NO_ARCHIVED_OVER_60");
            }
            else {
                this.filters.removeNamedCondition("NO_ARCHIVED_OVER_60");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "updatedSincePrevious", {
        get: function () {
            return this.filters.hasNamedCondition("UPDATED_SINCE_PREVIOUS");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("UPDATED_SINCE_PREVIOUS");
            }
            else {
                this.filters.removeNamedCondition("UPDATED_SINCE_PREVIOUS");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "updatedYtd", {
        get: function () {
            return this.filters.hasNamedCondition("UPDATED_YTD");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("UPDATED_YTD");
            }
            else {
                this.filters.removeNamedCondition("UPDATED_YTD");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "deliveredSincePrevious", {
        get: function () {
            return this.filters.hasNamedCondition("DELIVERED_SINCE_PREVIOUS");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("DELIVERED_SINCE_PREVIOUS");
            }
            else {
                this.filters.removeNamedCondition("DELIVERED_SINCE_PREVIOUS");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "deliveredYtd", {
        get: function () {
            return this.filters.hasNamedCondition("DELIVERED_YTD");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("DELIVERED_YTD");
            }
            else {
                this.filters.removeNamedCondition("DELIVERED_YTD");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesReportFiltersComponent.prototype, "billed", {
        get: function () {
            if (this.filters.hasNamedCondition('BILLED')) {
                return this.filterYes;
            }
            else if (this.filters.hasNamedCondition('NOT_BILLED')) {
                return this.filterNo;
            }
        },
        set: function (value) {
            this.filters.removeNamedConditions('NOT_BILLED', 'BILLED');
            switch (value && value.id) {
                case 0:
                    this.filters.addNamedCondition('NOT_BILLED');
                    break;
                case 1:
                    this.filters.addNamedCondition('BILLED');
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    RoutesReportFiltersComponent.prototype.onDeliveryLocationChange = function (event) {
        this.deliveryLocation = event ? event : undefined;
        if (this.deliveryLocation) {
            this.filters.addColumnFilter('addressDeliveryName', this.deliveryLocation);
        }
        else {
            this.filters.removeColumnFilter('addressDeliveryName');
        }
    };
    return RoutesReportFiltersComponent;
}());
export { RoutesReportFiltersComponent };
