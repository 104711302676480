<oms-base-dialog [loading]="loading" [visible]="visible" [icon]="'fa fa-truck'" (ok)="onSubmit($event)"
                 [can-ok]="canUpdateLoad()" [store-position]="false"
                 [width]="'95vw'" [min-width]="'150px'" [max-width]="'800px'" [min-height]="'400px'" [height]="'90vh'"
                 [btnOk]="{caption: 'Submit', icon:'fas fa-paper-plane'}"
                 [flex-content]="true"
                 [btnCancel]="undefined"
                 [title]="selectedLoad?.orderId | order_number : {isRecovery: true}">


  <!-- CONTENT -->

    <div class="row" *ngIf="showDetails">
      <div class="form-group no-margin no-padding input-group-sm col-xs-1">
        <label class="control-label"></label>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-5">
        <label class="control-label">Dispatched</label>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-6">
        <label class="control-label">Picked Up</label>
      </div>
    </div>

    <!-- Dispatched By -->
    <div class="row" *ngIf="showDetails">
      <div class="form-group no-margin no-padding input-group-sm col-xs-1 text-right">
        <!--label class="control-label"></label-->
      </div>

      <div class="form-group no-margin input-group-sm col-xs-5">
        <oms-string-input [show-label]="false" [ngModel] = "(selectedLoad.split.dispatchedByUser?.fullNameWithEmail) || 'N/A'" [size]="Size.SMALL" [disabled]="true">
          <span group-before>By</span>
        </oms-string-input>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-6">
        <oms-date-input [disabled]="true" [label]="'Pickup Date'" [show-button]="true" [showLabel]="false"
                        [size]="Size.SMALL"
                        [ngModel]="selectedLoad.dateDispatched" [cleanable]="false"
                        [show-checkbox]="false">
          <span group-before>Date</span>
        </oms-date-input>

      </div>

    </div>


    <!-- PICK UP DATE -->
    <div class="row" >
      <div class="form-group no-margin no-padding input-group-sm text-right" [ngClass]="{'col-xs-1': showDetails, 'col-xs-6': !showDetails}">
        <label class="control-label">Date</label>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-5" *ngIf="showDetails">
        <!--label class="control-label">{{isEstimated(load) ? 'Estimated Pick Up' : 'Actual Pick Up'}}</label-->
        <input class="form-control" [value]="(selectedLoad.datePickupEst | oms_date_time) || 'N/A'" readonly disabled>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-6">
        <oms-date-input [label]="'Pickup Date'" [show-button]="true" [showLabel]="false"
                        [size]="Size.SMALL" placeholder="Pickup Date"
                        [(ngModel)]="selectedLoad.datePickupAct" [cleanable]="false" [disabled]="!canUpdateLoad()"
                        [show-checkbox]="false" required>
          <!--span group-before>Date</span-->
        </oms-date-input>

      </div>
    </div>

    <!-- DRIVER -->
    <div class="row" *ngIf="showDetails">
      <div class="form-group no-margin no-padding input-group-sm col-xs-1 text-right">
        <label class="control-label">Driver</label>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-5">
        <!--label class="control-label">Dispatched to Driver</label-->
        <input class="form-control" [value]="(selectedLoad.driverDispatched?.fullName) || 'N/A'" readonly disabled>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-6">
        <oms-custom-search label="Picked up by Driver"  [showLabel]="false" [(ngModel)] = "selectedLoad.driverPickedUp" [allowClear]="false"
                           [size]="Size.SMALL" [disabled]="!canUpdateLoad()"
                           placeholder="Driver"
                           bindLabel="fullName" [allowCreate]="false" [service]="driverService">

          <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">{{item.firstName}} {{item.lastName}}</div>
          </ng-template>

          <!--span group-before>Driver</span-->

        </oms-custom-search>

      </div>
    </div>

    <!-- TRUCK -->
    <div class="row" *ngIf="showDetails">
      <div class="form-group no-margin no-padding input-group-sm col-xs-1 text-right">
        <label class="control-label">Truck</label>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-5">
        <!--label class="control-label">Dispatched to Truck</label-->
        <input class="form-control" [value]="(selectedLoad.truckDispatched?.number) || 'N/A'" readonly disabled>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-6">
        <oms-custom-search label="Actual Truck" [(ngModel)] = "selectedLoad.truckPickedUp" [allowClear]="false" [showLabel]="false"
                           [size]="Size.SMALL" [disabled]="!canUpdateLoad()"
                           (create)="onCreateTruck($event)"
                           placeholder="Truck"
                           bindLabel="number" [allowCreate]="false" [service]="truckService">

          <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">{{item.number}}</div>
          </ng-template-->
          <!--span group-before>Truck</span-->

        </oms-custom-search>

      </div>
    </div>

    <!-- TRAILER -->
    <div class="row" *ngIf="showDetails">
      <div class="form-group no-margin no-padding input-group-lg col-xs-1 text-right">
        <label class="control-label">Trailer</label>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-5">
        <!--label class="control-label">Dispatched to Trailer</label-->
        <input class="form-control" [value]="(selectedLoad.trailerDispatched?.number) || 'N/A'" readonly disabled>
      </div>

      <div class="form-group no-margin input-group-sm col-xs-6">
        <oms-custom-search label="Actual Trailer" [(ngModel)] = "selectedLoad.trailerPickedUp" [allowClear]="false" [showLabel]="false"
                           [size]="Size.SMALL" [disabled]="!canUpdateLoad()"
                           (create)="onCreateTrailer($event)"  bindLabel="number"
                           placeholder="Trailer"
                           [allowCreate]="true" [service]="trailerService">

          <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">{{item.number}}</div>
          </ng-template-->
          <!--span group-before>Trailer</span-->

        </oms-custom-search>
      </div>
    </div>

  <div class="row" *ngIf="showDetails">
    <!-- MAWB TOTALS -->
    <div class="form-row col-xs-6 no-padding">
      <div class="col col-lg-12">
        <label class="control-label">MAWB Totals</label>
      </div>
      <div class="form-row">
        <div class="col col-md-6">
          <oms-pieces-input [size]="Size.SMALL" [label]="'HU Picked Up'" [ngModel]="master.hu || 'N/A'"
                            [disabled]="true" [show-label]="false" [showButton]="false">
            <span group-before>HU</span>
          </oms-pieces-input>
        </div>

        <div class="col col-md-6">
          <oms-pieces-input [size]="Size.SMALL" [label]="'PCS Picked Up'" [ngModel]="master.pieces"
                            [disabled]="true" [show-label]="false" [showButton]="false">
            <span group-before>PCS</span>
          </oms-pieces-input>
        </div>
      </div>
    </div>

    <!-- Dispatched -->
    <div class="form-row col-xs-6 no-padding" *ngIf="showDetails">
      <div class="col col-lg-12">
        <label class="control-label">Dispatched</label>
      </div>
      <div class="form-row">
        <div class="col col-xs-6">
          <oms-pieces-input [size]="Size.SMALL" [label]="'HU Picked Up'" [ngModel]="selectedLoad.huDispatched || 'N/A'"
                            [disabled]="true" [show-label]="false" [showButton]="false">
            <span group-before>HU</span>
          </oms-pieces-input>
        </div>

        <div class="col col-xs-6">
          <oms-pieces-input [size]="Size.SMALL" [label]="'PCS Picked Up'" [ngModel]="selectedLoad.pcsDispatched"
                            [disabled]="true" [show-label]="false" [showButton]="false">
            <span group-before>PCS</span>
          </oms-pieces-input>
        </div>
      </div>
    </div>

  </div>

  <div class="row">
    <!-- Prior Recoveries -->

<!--
    <div class="form-row col-xs-6 no-padding">
      <div class="col col-lg-12">
        <label class="control-label">Prior Recoveries</label>
      </div>
      <div class="form-row">
        <div class="col col-md-6">
          <oms-pieces-input [size]="Size.SMALL" [ngModel]="huPickedUpTotal"
                            [disabled]="true" [show-label]="false" [showButton]="false">
            <span group-before>HU</span>
          </oms-pieces-input>
        </div>

        <div class="col col-md-6">
          <oms-pieces-input [size]="Size.SMALL" [ngModel]="pcsPickedUpTotal" [disabled]="true" [show-label]="false" [showButton]="false">
            <span group-before>PCS</span>
          </oms-pieces-input>
        </div>
      </div>
    </div>
-->

    <!-- Loose Picked Up -->
<!--
    <div class="form-row no-padding" [ngClass]="{'col-xs-12': !showDetails, 'col-xs-6': showDetails}" *ngIf="showDetails">
      <div class="col col-md-12" *ngIf="showDetails">
        <label class="control-label">Loose</label>
      </div>
      <div class="form-row">
        <div class="col col-xs-6">
          <oms-pieces-input [size]="Size.SMALL" [label]="'HU Picked Up'" [(ngModel)]="selectedLoad.huLoose"
                            [disabled]="!canUpdateLoad()" [show-label]="false" [showButton]="false">
            <span group-before>HU</span>
          </oms-pieces-input>
        </div>

        <div class="col col-xs-6">
          <oms-pieces-input [size]="Size.SMALL" [label]="'PCS Picked Up'" [(ngModel)]="selectedLoad.pcsLoose" (change)="selectedLoad.huLoose = selectedLoad.huLoose || selectedLoad.pcsLoose"
                            [disabled]="!canUpdateLoad()" [show-label]="false" [showButton]="false" required>
            <span group-before>PCS</span>
          </oms-pieces-input>
        </div>
      </div>
    </div>
-->
  </div>

  <div class="row row-flex">
    <table class="table table-condensed table-striped table-bordered no-margin">
      <thead>
      <tr>
        <th>ULD #</th>
        <th style="width: 80px">Skids</th>
        <th style="width: 80px">Pieces</th>
        <th style="width: 80px; text-align: center" class="wide-screen-only">Picked Up</th>
        <th class="col-date">Date</th>
      </tr>
      </thead>
    </table>
    <perfect-scrollbar>

      <table class="table table-condensed table-striped table-bordered no-margin">

        <thead *ngIf="loadsPickedUp && loadsPickedUp.length">
          <tr class="previous-data">
            <td colspan="10" style="text-align: center; font-weight: bold;">Previously Picked Up</td>
          </tr>
        </thead>

        <tbody>
          <!-- Previously picked up Loads -->
          <ng-container *ngFor="let load of loadsPickedUp">
            <tr class="previous-data">
              <td class="input-group-sm cell-label cell-label-right">
                <span>Loose</span>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <!--
                <input [disabled]="true" type="number" class="form-control no-arrows"
                       [min]="0"  [(ngModel)]="load.huLoose" width="100%"/>
                       -->
                <span>{{load.huLoose}}</span>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <!--
                <input [disabled]="true" type="number" class="form-control no-arrows"
                       [min]="0" [(ngModel)]="load.pcsLoose" width="100%"/>
                       -->
                <span>{{load.pcsLoose}}</span>
              </td>

              <!-- Recovery Order -->
              <td style="width: 80px;" class="cell-label cell-label-center wide-screen-only"
                  [popover]="load.datePickupAct | oms_date_time" [container]="'body'">
                <span>{{load.orderId | order_number : {isRecovery: true} }}</span>
              </td>

                  <!-- Date -->
              <td class="col-date cell-label cell-label-center">
                <span>{{load.datePickupAct | oms_date }}</span>
              </td>


            </tr>
            <tr class="previous-data" *ngFor="let uld of getUldsPickedUp(load)">
              <td class="input-group-sm">
                <!--
                <input class="form-control" [disabled]="!canUpdateUld(uld)" [(ngModel)]="uld.uldNumber" width="100%"/>
                -->
                <span>{{uld.uldNumber}}</span>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <!--
                <input *ngIf="canUpdateUld(uld)" type="number" class="form-control no-arrows" [min]="0" [disabled]="!canUpdateUld(uld)" [(ngModel)]="uld.hu" width="100%"/>
                <span *ngIf="!canUpdateUld(uld)">{{uld.huPickedUp}}</span>
                -->
                <span >{{uld.huPickedUp}}</span>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <!--
                <input *ngIf="canUpdateUld(uld)" type="number" class="form-control no-arrows" [min]="0"
                       [disabled]="!canUpdateUld(uld)" [(ngModel)]="uld.pcs" (change)="uld.hu = uld.hu || uld.pcs" width="100%"/>
                <span *ngIf="!canUpdateUld(uld)">{{uld.pcsPickedUp}}</span>
                -->
                <span >{{uld.pcsPickedUp}}</span>
              </td>

              <td style="width: 80px;" class="cell-label cell-label-center wide-screen-only"
                  [popover]="uld.datePickupAct | oms_date_time" [container]="'body'">
                <span *ngIf="uld.isPickedUp">
                  {{uld.pickedUpByOrderId | order_number : {isRecovery: true, ifEmpty: ' '} }}
                </span>
              </td>

              <!-- Date -->
              <td style="vertical-align: middle;" class="col-date">
                <span *ngIf="uld.isPickedUp">{{uld.datePickupAct | oms_date}}</span>
              </td>

            </tr>
          </ng-container>

          <tr class="previous-data" *ngIf="loadsPickedUp && loadsPickedUp.length">
            <th style="text-align: right">Total</th>
            <th style="width: 80px; text-align: right">{{huPickedUpTotal}}</th>
            <th style="width: 80px; text-align: right">{{pcsPickedUpTotal}}</th>
            <th style="width: 80px; text-align: center" class="wide-screen-only"></th>
            <th class="col-date"></th>
          </tr>

          <ng-container *ngIf="canUpdateLoad()">
            <!-- Loose Pickup Row -->
            <tr>
              <td class="input-group-sm cell-label cell-label-right">
                <span>Loose</span>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <input [disabled]="!canUpdateLoad()" type="number" class="form-control no-arrows"
                       [min]="0"  [(ngModel)]="selectedLoad.huLoose" width="100%"/>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <input [disabled]="!canUpdateLoad()" type="number" class="form-control no-arrows"
                       [min]="0" [(ngModel)]="selectedLoad.pcsLoose"
                       (change)="selectedLoad.huLoose = selectedLoad.huLoose || selectedLoad.pcsLoose" width="100%"/>
              </td>


              <!-- Recovery Order -->
              <td style="width: 80px;" class="cell-label cell-label-center wide-screen-only">
              </td>

              <!-- Date -->
              <td  class="col-date cell-label cell-label-center">
              </td>

            </tr>

            <!-- ULD Pick Up Rows -->
            <tr *ngFor="let uld of getUldsToPickUp(selectedLoad)">
              <td class="input-group-sm">
                <input class="form-control" [disabled]="!canUpdateUld(uld)" [(ngModel)]="uld.uldNumber" width="100%"/>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <input *ngIf="canUpdateUld(uld)" type="number" class="form-control no-arrows" [min]="0" [disabled]="!canUpdateUld(uld)" [(ngModel)]="uld.hu" width="100%"/>
                <span *ngIf="!canUpdateUld(uld)">{{uld.huPickedUp}}</span>
              </td>

              <td class="input-group-sm" style="width: 80px; vertical-align: middle; text-align: center;">
                <input *ngIf="canUpdateUld(uld)" type="number" class="form-control no-arrows" [min]="0" [disabled]="!canUpdateUld(uld)" [(ngModel)]="uld.pcs" (change)="uld.hu = uld.hu || uld.pcs" width="100%"/>
                <span *ngIf="!canUpdateUld(uld)">{{uld.pcsPickedUp}}</span>
              </td>

              <td style="width: 80px;" class="cell-label cell-label-center wide-screen-only"
                  [popover]="uld.datePickupAct | oms_date_time" [container]="'body'">
                <span *ngIf="uld.isPickedUp">
                  {{uld.pickedUpByOrderId | order_number : {isRecovery: true, ifEmpty: ' '} }}
                </span>
              </td>

              <!-- Date -->
              <td class="col-date cell-label">
                <span *ngIf="uld.isPickedUp">{{uld.datePickupAct | oms_date}}</span>
              </td>

            </tr>
          </ng-container>

        </tbody>
      </table>
    </perfect-scrollbar>
    <table class="table table-condensed table-striped table-bordered no-margin">
      <tfoot>
        <tr>
          <th style="text-align: right">Picked Up:</th>
          <th style="width: 80px; text-align: right">{{getTotalHu(selectedLoad)}}</th>
          <th style="width: 80px; text-align: right">{{getTotalPieces(selectedLoad)}}</th>
          <th style="width: 80px; text-align: center" class="wide-screen-only"></th>
          <th class="col-date"></th>
        </tr>
      </tfoot>
    </table>
  </div>

  <!-- FOOTER -->

  <ng-container widget-footer>
    <oms-toggle-switch [label]="'Update'" *ngIf="isInternalUser && selectedLoad?.isPickedUp" [(ngModel)]="forceUpdate">
    </oms-toggle-switch>
  </ng-container>

  <ng-container widget-footer-buttons>
    <button class="btn btn-default" (click)="openDocCenter()">
      <i class="fas fa-file-text"></i>
      <span>Doc Center</span>
    </button>

    <button class="btn btn-default" (click)="openCamera()" *ngIf="!data.noCamera">
      <i class="fas fa-camera"></i>
      <span>Camera</span>
    </button>
  </ng-container>

</oms-base-dialog>
