import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {Address} from "../modules/shared/models/address";

@Injectable({providedIn: 'root'})
export class AddressNewService extends BaseEntityService<Address> {
  protected apiUrl = '/oms/address/';
  protected classType = Address;

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }
}
