import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ButtonConfig} from "../oms-dialogs.types";

export type AlertMode = 'success' | 'info' | 'warning' | 'danger';

export interface AlertDialogConfig {
  message?: string;
  caption?: string;
  timeout?: number;
  mode?: AlertMode;
  button?: ButtonConfig;
}


@Component({
  selector: 'oms-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements AfterViewInit {
  public message: string;
  public mode: AlertMode = 'info';
  public caption;
  public timeout: number;
  public button: ButtonConfig;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogConfig) {

    if (data) {
      this.message = data.message || this.message;
      this.mode = data.mode || 'info';
      this.caption = data.caption;
      this.timeout = data.timeout;
      this.button = data.button;
    }
  }

  public get dialogCaption(): string {
    if (this.caption) {
      return this.caption;
    }

    switch (this.mode) {
      case 'success': return 'Success';
      case 'info': return 'Information';
      case 'warning': return 'Warning';
      case 'danger': return 'Error';
    }
  }

  public get iconClass(): string {
    switch (this.mode) {
      case 'success': return 'fa fa-check-circle';
      case 'info': return 'fa fa-info-circle';
      case 'warning': return 'fa fa-exclamation-triangle';
      case 'danger': return 'fa fa-times-circle';
    }
  }

  public get dialogClass() {
    switch (this.mode) {
      case 'success': return 'alert alert-success';
      case 'info': return 'alert alert-info';
      case 'warning': return 'alert alert-warning';
      case 'danger': return 'alert alert-danger';
    }
  }

  ngAfterViewInit(): void {
    if (this.timeout > 0) {
      setTimeout(() => this.dialogRef.close(), this.timeout);
    }
  }

  public onButtonClick(button: ButtonConfig) {
    if (!!button.handler) {
      button.handler();
    } else {
      this.dialogRef.close(button.result);
    }
  }

}
