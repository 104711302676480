import { RestService } from "./rest.service";
import { HttpUtilsService } from "./http-utils.service";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "./http-utils.service";
var OmsMapsService = /** @class */ (function () {
    function OmsMapsService(httpRestService, httpUtils) {
        this.httpRestService = httpRestService;
        this.httpUtils = httpUtils;
        this.apiUrl = '/oms/oms-maps-integration/';
    }
    OmsMapsService.prototype.getOmsMapsOptions = function () {
        return this.httpRestService
            .getWithHeader(this.apiUrl + 'get-maps-options', this.httpUtils.getHTTPHeaders());
    };
    OmsMapsService.ngInjectableDef = i0.defineInjectable({ factory: function OmsMapsService_Factory() { return new OmsMapsService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: OmsMapsService, providedIn: "root" });
    return OmsMapsService;
}());
export { OmsMapsService };
