var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { OmsAlertsService } from './oms-alerts.service';
import { of } from 'rxjs';
import { copyToClipboard } from '../../../../_helpers/utils';
import { map, switchMap, takeUntil, tap } from "rxjs/operators";
import { AbstractComponent } from "../../../../common/component/abstract.component";
import { AuthService } from "../../../../services";
import { UserRoleType } from "../../models/user-role.type";
var OmsAlertsComponent = /** @class */ (function (_super) {
    __extends(OmsAlertsComponent, _super);
    function OmsAlertsComponent(cdr, alertsService, authService) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this.alertsService = alertsService;
        _this.authService = authService;
        _this.showNotifications = true;
        _this.displayMessage = false;
        _this.isOpen = false;
        _this.hasNew = false;
        return _this;
    }
    OmsAlertsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initMessages();
        this.hasNew = JSON.parse(localStorage.getItem(OmsAlertsService.ALERT_HAS_NEW_STORAGE_KEY)) || false;
        this.alerts$ = this.alertsService.alertsSubject.pipe(tap(function () { return _this.cdr.markForCheck(); }));
    };
    OmsAlertsComponent.prototype.initMessages = function () {
        var _this = this;
        this.authService.user$
            .pipe(takeUntil(this.unsubscribe$), map(function (user) { return user && !user.hasRole(UserRoleType.ROLE_EXTERNAL_WAREHOUSE); }), switchMap(function (noExternalUser) {
            _this.displayMessage = noExternalUser;
            return _this.displayMessage
                ? _this.alertsService.messagesSubject
                    .pipe(tap(function (messages) {
                    if (!_this.isOpen && !!_this.messages && _this.messages.length) {
                        _this.setHasNew(true);
                    }
                    var addedMessage = !_this.messages || _this.messages.length < messages.length;
                    _this.messages = messages;
                    if (addedMessage) {
                        _this.scrollTop();
                    }
                }))
                : of([]);
        }))
            .subscribe(function (messages) {
            _this.cdr.markForCheck();
        });
    };
    OmsAlertsComponent.prototype.scrollTop = function () {
        var el = this.messagesPanel && this.messagesPanel.nativeElement;
        if (!el) {
            return;
        }
        el.scrollTop = 0;
        this.cdr.markForCheck();
    };
    OmsAlertsComponent.prototype.ngOnDestroy = function () {
    };
    OmsAlertsComponent.prototype.onAlertClosed = function (alert) {
        this.alertsService.remove(alert);
    };
    OmsAlertsComponent.prototype.onMessageClosed = function (alert) {
        this.alertsService.removeMessage(alert);
    };
    OmsAlertsComponent.prototype.copyToClipboard = function (message, stack) {
        var s = message + '\n\n';
        if (typeof stack === 'string') {
            s += stack;
        }
        else if (stack.length) {
            stack.forEach(function (e) { return s += e.className + "." + e.methodName + '(): ' + e.fileName + ':' + e.lineNumber + '\n'; });
        }
        copyToClipboard(s);
    };
    OmsAlertsComponent.prototype.toggleOpen = function () {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.setHasNew(false);
            this.scrollTop();
        }
    };
    OmsAlertsComponent.prototype.setHasNew = function (value) {
        this.hasNew = value;
        localStorage.setItem(OmsAlertsService.ALERT_HAS_NEW_STORAGE_KEY, JSON.stringify(value));
    };
    return OmsAlertsComponent;
}(AbstractComponent));
export { OmsAlertsComponent };
