<div class="onoffswitch"
     [ngClass]="{'yesno': type === 'yes-no', 'marks': marks, 'yes_empty': type === 'yes-empty', 'no_empty': type === 'no-empty' }">

  <input [id]="identifier" type="checkbox"  name="onoffswitch" class="onoffswitch-checkbox"
         [(ngModel)]="value" (ngModelChange)="onChange($event)"
         [style.cursor]="disabled ? 'not-allowed' : 'pointer'"
         [disabled]="disabled">

  <label class="onoffswitch-label" [attr.for]="identifier ? identifier : null" [style.cursor]="disabled ? 'not-allowed' : 'pointer'">
    <span class="onoffswitch-inner"></span>
    <span class="onoffswitch-switch"></span>
  </label>
  <label *ngIf="label && showLabel" [attr.for]="identifier" [style.cursor]="disabled ? 'not-allowed' : 'pointer'" [ngClass]="{'disabled': disabled}">{{label}}</label>
</div>
