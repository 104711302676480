import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {BaseInputComponent} from "../../../base/base-input/base-input.component";
import {animations} from "../../../base/base-input/animations";

@Component({
  selector: 'oms-max-count-input',
  styleUrls: ['./max-count-input.component.scss'],
  templateUrl: './max-count-input.component.html',
  animations,
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: MaxCountInputComponent, multi: true}],
})

export class MaxCountInputComponent extends BaseInputComponent<string> implements OnInit, OnDestroy {
  @ViewChild(NgModel) model: NgModel;
  @Output('changing') changingEvent = new EventEmitter();
  @ViewChild("input", { read: ViewContainerRef }) public input;
  @Input() maxCount: string;
  @Input() typeOfParcel: string;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  onKeyUp(event) {
    this.changingEvent.emit(event);
  }

  get invalidPublic() {
    return this.invalid;
  }

  ngOnInit() {
/*    if (this.inputMask) {
      setTimeout(() => {
        this.maskedInputController = textMask.maskInput({
          inputElement: this.input.element.nativeElement,
          mask: this.inputMask,
          placeholderChar: this.placeholderChar,
        });
      });
    } */
  }

  ngOnDestroy() {
/*    if (this.maskedInputController)
      this.maskedInputController.destroy(); */
  }

}

