import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {GenericTableCell} from "./generic-table-cell";
import {Logger} from "../../../../../../../_helpers/logger";
import {InplaceEditorSettings} from "../../columns/column-types";
import {isNullOrUndefined} from "util";
import {isEmptyString} from "../../../../../../../_helpers/utils";
import {take, takeUntil} from "rxjs/operators";
import {DataTableComponent} from "../../data-table.component";
import {Router} from "@angular/router";
import {MasterService} from "../../../../../../../services/master/master.service";
import {MatDialog} from "@angular/material";
import {conformToMask} from "text-mask-core/dist/textMaskCore";
import {HistoryInformationDialogComponent} from "../../../../../../../components/common/history-information-dialog";
import {LogChangesService} from "../../../../../../../services/logchanges/log.changes.service";
import {
  composeMawbTrackLink,
  convertMawbNumber,
  getInvalidMAWBMessage,
  validMAWB
} from "../../../../../services/oms-converters.service";
import {CbpStatusDialogComponent} from "../../../../../../../components/common/cbp-status-dialog/cbp-status-dialog.component";
import {Master} from "../../../../../models";

@Component({
  selector: 'mawb-table-cell',
  templateUrl: './mawb-table-cell.component.html',
  styleUrls: ['./mawb-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({
  name: 'MawbTableCellComponent'
})
export class MawbTableCellComponent extends GenericTableCell<Master> implements OnInit, OnDestroy, OnChanges {

  @ViewChild('textInput') textInput: ElementRef;

  public editing: boolean = false;

  public localValue: any;
  public error;

  private editOnFocus = false;

  private closeOnBlur: boolean = true;
  value: string;
  empty: boolean;
  editable: boolean;
  showTracking: boolean;
  public display: any;
  rawValue: any;
  iconClass: string;
  hasIcon: boolean;
  isHidden: boolean;
  tooltip: string;
  placeholder: string;
  editor: InplaceEditorSettings;
  public rowObject: any;
  cellClass: string;
  cellButtonLink: string;
  cellButtonTooltip: string;
  cellButtonIcon: string;

  constructor(
    @Inject(DataTableComponent) public table: DataTableComponent,
    private cdr: ChangeDetectorRef,
    private logChangesService: LogChangesService,
    private router: Router,
    public masterService: MasterService,
    public dialog: MatDialog) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
//    console.log('CHANGE CELL', changes);
    if (changes.column) {
      this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
    }
    if (changes.row || changes.column || changes.search) {
      this.updateObject();
    }
  }

  public updateObject() {
    super.updateObject();

    this.rowObject = this.column.rowObject(this.row);
    this.rawValue = this.column.getValue(this.rowObject);
    this.editable = this.column.editable(this.row);
    this.value = this.convert(this.rowObject, this.rawValue);
    this.empty = isNullOrUndefined(this.rawValue);
    this.iconClass = this.column.getIconClass(this.rowObject, this.rawValue);
    this.cellClass = this.column.getClass(this.rowObject, this.rawValue) || '';

    this.showTracking = this.column.showTracking(this.row);

    this.hasIcon = !isNullOrUndefined(this.iconClass);
    this.tooltip = this.column.getTooltip(this.rowObject);

    const editor = this.editor;
    this.placeholder = typeof editor.placeholder === 'function' ? editor.placeholder(this.row, this.rawValue) : editor.placeholder || '';

    this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);

    this.display = this.column.editable && isEmptyString(this.value) ? this.table.emptyValue : this.value;

    this.cellButtonIcon = validMAWB(this.rawValue) ? 'fa fa-up-right-from-square' : 'fa fa-exclamation-triangle red-font';
    this.cellButtonTooltip = getInvalidMAWBMessage(this.rawValue) || 'Track MAWB';
    this.cellButtonLink = composeMawbTrackLink(this.rawValue);

    this.cdr.markForCheck();
  }

  private convert(rowObject, rawValue): string {
    // return this.column.convert(rowObject, rawValue);
    return convertMawbNumber(rawValue);
  }

  editColumnValue(ref: ElementRef, event?: MouseEvent) {
    if (this.column.editable(this.row)) {
      if (this.column.isDialog) {
        this.openDialog(ref, event);
      } else {

        // Textbox or Select edit
        this.startEdit();
      }
    }
  }

  openDialog(ref?: ElementRef, event?: MouseEvent) {
    this.dialog.open(CbpStatusDialogComponent, {
      width: '80%',
      data: {id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: this.table.readonly, uscsFsnStatus: CbpStatusDialogComponent.FILTER_BY_STATUS}
    });
  }


  ngOnInit() {
    this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
    this.updateObject();
  }

  private startEdit() {
    console.log('Start Edit', this.column);
//    if (this.table.editing && this.table.editing.error)
//      return;

    this.editing = true;
//    this.table.editing = this;

    this.localValue = isNullOrUndefined(this.rawValue) ? null : this.value; // !Important start edit with converted value
//    console.log('Local', this.localValue);

    // Important: do on timeout after editors initialized!
    setTimeout(() => {
      if (this.textInput) {
        this.textInput.nativeElement.focus();
        this.textInput.nativeElement.select();
      }
      this.cdr.markForCheck();

    });
    this.cdr.markForCheck();
  }

  public cancelEdit() {
    if (this.editing) {
      this.editing = false;
//      this.table.editing = undefined;
      this.error = undefined;
      this.cdr.markForCheck();
    }
  }

  private editError(error) {
    console.log('Error', error);
    this.error = error;
    this.table.editErrorEvent.emit(error);
  }

  public doneEdit() {
    console.log('Done Edit:', this.localValue);
    try {
      let newValue = this.convertBack(this.localValue);
      this.table.updateColumnValue(this.rowObject, this.column, newValue, this.column.field)
        .pipe(takeUntil(this.destroy$))
        .pipe(take(1))
        .subscribe(object => {
          this.row = object;
          this.updateObject();
        });
      this.error = undefined;
    } catch (error) {
      this.editError(error);
    } finally {
      this.editing = false;
      this.cdr.markForCheck();
    }
  }

  onKeyDown(event) {
    if (this.editing) {
      if (event.key === 'Enter') {
        this.doneEdit();
        event.preventDefault();
      } else if (event.key === 'Escape') {
        this.cancelEdit();
        event.preventDefault();
      }
    }
  }


  onClear() {
    this.closeOnBlur = false;
    this.localValue = null;
    this.textInput.nativeElement.focus();
    this.cdr.markForCheck();
  }

  onBlurEditor() {
    this.editOnFocus = true;
    this.closeOnBlur = true;

    setTimeout(() => {
      if (this.closeOnBlur) { this.doneEdit(); }
      this.editOnFocus = false;
    });
  }

  onDoubleClick(event) {
    if (this.editing) {
      event.stopPropagation();
    }
  }


  private cleanupMask(s: string, mask, clearMask: boolean): any {
    // remove _ guide before passing to the model
    if (mask && mask.mask) {
      s = conformToMask(s, mask.mask, {guide: false}).conformedValue;
    }

    // keep only alpha-numerical values and '.' for number support
    if (clearMask) {
      s = s.replace(/[^0-9A-Za-z.]/g, '');
    }

    return s; // this.isNumber ? ( s ? Number(s) : null): s;
  }

  private convertBack(value: any): any {
    if (typeof value === 'string') {
      value = this.cleanupMask(value, this.column.handlers.editor.mask, this.column.handlers.editor.clearMask);
    }

    return this.column.convertBack(value);
  }

  onFocusColumn(cell, event) {
    if (this.editOnFocus) {
      this.editColumnValue(cell, event);
    }
  }

  onRightClick() {
    if (this.column.history.logChanges) {
      let columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;

      let params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField);

      if (this.column.history.methodForExecution) {
        this[this.column.history.methodForExecution](params);
      } else {
        this.executeLogChanges(params, this.column.history.logConverter);
      }
    }
    return false;
  }

  executeLogChanges(logParams: any[], convertByBaseType) {
    this.dialog.open(HistoryInformationDialogComponent, {
      width: '80%',
      data: {logParams: logParams, column: this.column, convertByBaseType: convertByBaseType}
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cancelEdit();
    super.ngOnDestroy();
  }
}
