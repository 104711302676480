<oms-base-dialog [readonly]="readonly" [title]="'Dispatch&hellip;'" [icon]="'fa fa-truck'" (ok)="onOk($event)" [width]="'650px'">

  <div class="row">
    <div class="form-group col-xs-6">
      <oms-date-input [disabled]="readonly" [label]="'Est. Pickup Date'" [(ngModel)]="data.date" [cleanable]="false" [show-checkbox]="false" required></oms-date-input>
      <oms-custom-search label="Truck #"
                         [(ngModel)] = "data.truck"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         (create)="onCreateTruck($event)"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="truckService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.number}}</div>
        </ng-template-->
      </oms-custom-search>
    </div>
    <div class="form-group col-xs-6">
      <oms-custom-search label="Driver" required
                         [(ngModel)] = "data.driver"
                         (create)="onCreateDriver()"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="fullName"
                         [allowCreate]="true"
                         [service]="driverService">

        <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.firstName}} {{item.lastName}}</div>
        </ng-template>
      </oms-custom-search>
      <oms-custom-search label="Trailer #"
                         [(ngModel)] = "data.trailer"
                         (create)="onCreateTrailer($event)"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="trailerService">
      </oms-custom-search>
    </div>
  </div>

  <div class="row">
    <div class="table-responsive" style="max-height: 250px; overflow-y: auto;">
      <table class="table">
        <thead>
          <tr>
            <th><a>#</a></th>
            <th><a>MAWB</a></th>
            <th><a>HAWB</a></th>
            <th><a>From</a></th>
            <th><a>To</a></th>
            <th><a>Hu</a></th>
            <th><a>Pieces</a></th>
            <th><a>WEIGHT</a></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dispatchDto of data.dispatchDtos, let i = index">
            <td>{{i+1}}</td>
            <td>{{dispatchDto.mawb}}</td>
            <td>{{dispatchDto.hawb}}</td>
            <td>{{dispatchDto.addressPickup?.name}}</td>
            <td>{{dispatchDto.addressDelivery?.name}}</td>
            <td>{{dispatchDto.hu}}</td>
            <td>{{dispatchDto.pieces}}</td>
            <td>{{dispatchDto.weight}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-container widget-footer>
    <div class="btn btn-danger" (click)="onCancelDispatch()">Cancel Dispatch</div>
  </ng-container>
</oms-base-dialog>


