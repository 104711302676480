import { RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login';
import { RegisterComponent } from './pages/register';
import { AuthGuard } from './_helpers';
import { HomeComponent } from './modules/shared/pages/home/home.component';
import { TestPageComponent } from './pages/test-page';
import { OrderEditorComponent } from './pages/orders/order-editor/order-editor.component';
import { MastersListComponent } from './pages/masters/masters-list/masters-list.component';
import { MasterEditorComponent } from './pages/masters/master-editor/master-editor.component';
import { PickupComponent } from "./pages/pickup/pickup.component";
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { OrdersListComponent } from "./modules/logs/pages/orders-list/orders-list.component";
import { StatusInfoComponent } from "./pages/status-info/status-info.component";
import { PagePermissionDeniedComponent } from "./pages/page-permission-denied/page-permission-denied.component";
import { RedirectRoleAuthGuard } from "./_helpers/redirect-role-auth.guard";
import { UserRoleType } from "./modules/shared/models/user-role.type";
import { PageFileDownloadComponent } from "./pages/page-file-download/page-file-download.component";
import { ManifestCreatePageComponent } from "./modules/dispatch/pages/manifest-create-page/manifest-create-page.component";
import { OmsMapsPageComponent } from "./modules/dispatch/pages/oms-maps-page/oms-maps-page.component";
import { DocCenterPageComponent } from "./components/common/doc-center/doc-center-page/doc-center-page.component";
export var routingConfiguration = {
    paramsInheritanceStrategy: 'always'
};
var ɵ0 = { label: 'Page Not Found', roles: [UserRoleType.ROLE_CLIENT, UserRoleType.ROLE_ADMIN] };
var pageNotFound = { path: '404', component: PageNotFoundComponent, data: ɵ0 };
var ɵ1 = { label: 'Permission denied' };
var pagePermissionDenied = { path: '403', component: PagePermissionDeniedComponent, data: ɵ1 };
var ɵ2 = { label: 'Document Center', roles: [UserRoleType.ROLE_CLIENT, UserRoleType.ROLE_ADMIN] };
var pageDocCenter = { path: 'doc-center', component: DocCenterPageComponent, data: ɵ2 };
var ɵ3 = { label: 'Login' };
var pageLogin = { path: 'login', component: LoginComponent, data: ɵ3 };
var ɵ4 = { label: 'Manifest' };
var pageManifest = { path: 'manifest', component: ManifestCreatePageComponent, data: ɵ4 };
var ɵ5 = { label: 'Map' };
var pageOmsMaps = { path: 'map', component: OmsMapsPageComponent, data: ɵ5 };
var ɵ6 = { label: 'Register' };
var pageRegisterUser = { path: 'register', component: RegisterComponent, data: ɵ6 };
var ɵ7 = {
    label: 'Driver/Dispatch pick up',
    roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]
};
/*
const pageReceiving: Route = {
    path: 'receiving',
    component: ReceivingComponent,
    canActivate: [AuthGuard],
    data: {label: 'Receiving', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]}
};
 */
var pagePickUp = {
    path: 'pickup',
    component: PickupComponent,
    canActivate: [AuthGuard],
    data: ɵ7
};
var ɵ8 = { label: 'Order', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
/*const pageSettings: Route = {path: 'settings', data: {label: 'Settings'}, canActivate: [AuthGuard],
  children: [
    {path: 'users', component: UserListComponent, canActivate: [AuthGuard], data:{label: 'Users'}},
    {path: 'addresses', component: AddressListComponent, canActivate: [AuthGuard], data:{label: 'Addresses'}},
    {path: 'customers', component: CustomerListComponent, canActivate: [AuthGuard], data:{label: 'Customers'}},
    { path: '**', redirectTo: 'settings' }
  ]};*/
var pageOrderEdit = {
    path: 'order',
    component: OrderEditorComponent,
    canActivate: [AuthGuard],
    data: ɵ8
};
var ɵ9 = { label: 'Master', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
var pageMasterEdit = {
    path: 'master',
    component: MasterEditorComponent,
    canActivate: [AuthGuard],
    data: ɵ9,
    children: [
        pageOrderEdit,
        //        pageReceiving,
        pagePickUp
    ]
};
var ɵ10 = { label: 'Air Import List', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
var pageMasterList = {
    path: 'masters',
    component: MastersListComponent,
    canActivate: [AuthGuard],
    data: ɵ10,
    children: [
        pageMasterEdit,
        pageOrderEdit,
        //        pageReceiving,
        pagePickUp,
        { path: '**', redirectTo: 'masters' }
    ]
};
var ɵ11 = { label: 'Order List', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
/*
const pageOrderList: Route = {
  path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: {label: 'Order List'},
  children: [
    pageOrderEdit,
    {path: '**', redirectTo: 'orders'}]
};
 */
var pageOrderList2 = {
    path: 'order-list',
    component: OrdersListComponent,
    data: ɵ11,
    canActivate: [AuthGuard],
    children: [
        pageOrderEdit,
        { path: '**', redirectTo: 'order-list' }
    ]
};
var ɵ12 = { label: 'Test Page' };
var pageTest = {
    path: 'test',
    component: TestPageComponent,
    canActivate: [AuthGuard],
    data: ɵ12
};
var ɵ13 = { label: 'File Download' };
var pageFileDownload = {
    path: 'download',
    component: PageFileDownloadComponent,
    canActivate: [AuthGuard],
    data: ɵ13
};
var ɵ14 = { label: 'Home', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
var pageHome = {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: ɵ14,
    children: [
        //        pageReceiving,
        pageNotFound,
        pagePickUp,
        pageMasterList,
        pageMasterEdit,
        pageOrderEdit,
        pageOrderList2,
        pageTest,
        { path: '**', redirectTo: 'masters' }
    ]
};
var ɵ15 = { roles: [UserRoleType.ROLE_ADMIN] };
var settingsRoutes = {
    path: 'settings',
    data: ɵ15,
    canActivate: [AuthGuard],
    loadChildren: ('./modules/settings/settings.module#SettingsModule')
};
var ɵ16 = { roles: [UserRoleType.ROLE_DRIVER] };
var webDriversRoutes = {
    path: 'webdrivers',
    data: ɵ16,
    canActivate: [AuthGuard],
    loadChildren: ('./modules/web-drivers/web-drivers.module#WebDriversModule')
};
var ɵ17 = { roles: [UserRoleType.ROLE_ADMIN] };
var logsRoutes = {
    path: 'logs',
    data: ɵ17,
    canActivate: [AuthGuard],
    loadChildren: ('./modules/logs/logs.module#LogsModule')
};
var ɵ18 = { roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
var shipmentRoutes = {
    path: 'dispatch',
    canActivate: [AuthGuard],
    loadChildren: ('./modules/dispatch/dispatch.module#DispatchModule'),
    data: ɵ18
};
var ɵ19 = { restricted: true, roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
var airExportRoutes = {
    path: 'air-export',
    data: ɵ19,
    canActivate: [AuthGuard],
    loadChildren: ('./modules/air-export/air-export.module#AirExportModule')
};
var ɵ20 = { restricted: true, roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
var fclRoutes = {
    path: 'fcl',
    data: ɵ20,
    canActivate: [AuthGuard],
    loadChildren: ('./modules/fcl/fcl.module#FclModule')
};
var ɵ21 = { roles: [UserRoleType.ROLE_AMS, UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER] };
var stbStatusRoutes = {
    path: 'ams',
    canActivate: [AuthGuard],
    data: ɵ21,
    loadChildren: ('./modules/cbp-status/cbp-status.module#CbpStatusModule')
};
var ɵ22 = { label: 'CFS Status Info', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER] };
/** old implementation
const statusInfoRoutes: Route = {
    path: 'cfsstatusinfo',
    component: StatusInfoComponent,
    data: {label: 'CFS Status Info', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]}
};
**/
/*
function pathMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  if (segments.length > 2) {
    if (segments[2].path.match(/([\w|]+[\d]+)/)) {
      let posParams = {'id': segments[2]};
      return {consumed: segments, posParams: posParams};
    } else {
      return null;
    }
  } else {
    return null;
  }
}
 */
var cfsStatusRoutes = {
    path: 'cfs-status/:id',
    //  matcher: <any> pathMatcher,
    component: StatusInfoComponent,
    data: ɵ22,
    children: [pageDocCenter]
};
var ɵ23 = { roles: [UserRoleType.ROLE_CLIENT, UserRoleType.ROLE_ADMIN] };
var clientModule = {
    path: 'client',
    canActivate: [AuthGuard],
    data: ɵ23,
    loadChildren: ('./modules/client/client.module#ClientModule')
};
var ɵ24 = { roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER, UserRoleType.ROLE_WAREHOUSE, UserRoleType.ROLE_EXTERNAL_WAREHOUSE] };
var warehouseRoutes = {
    path: 'warehouse',
    data: ɵ24,
    canActivate: [AuthGuard],
    loadChildren: ('./modules/warehouse/warehouse.module#WarehouseModule')
};
var ɵ25 = {
    redirects: [
        { redirectTo: 'home', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER] },
        { redirectTo: 'client', roles: [UserRoleType.ROLE_CLIENT] },
        { redirectTo: 'ams', roles: [UserRoleType.ROLE_AMS] }
    ]
};
var appRoutes = [
    pageLogin,
    pageHome,
    pagePermissionDenied,
    pageNotFound,
    pageDocCenter,
    pageFileDownload,
    //    pageReceiving,
    pageRegisterUser,
    pageManifest,
    pageOmsMaps,
    settingsRoutes,
    airExportRoutes,
    fclRoutes,
    logsRoutes,
    stbStatusRoutes,
    shipmentRoutes,
    //    statusInfoRoutes,
    cfsStatusRoutes,
    clientModule,
    warehouseRoutes,
    webDriversRoutes,
    {
        path: '**', canActivate: [RedirectRoleAuthGuard], component: HomeComponent,
        data: ɵ25
    },
];
export var routing = RouterModule.forRoot(appRoutes, routingConfiguration);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25 };
