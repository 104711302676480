<oms-base-dialog
  [readonly]="readonly"
  [loading]="loading"
  [title]="'Dispatch&hellip;'" [icon]="'fa fa-truck'" (ok)="onOk($event)" [width]="'650px'">

  <!--
  Selected:   {{data.date | oms_date_time_zone}}<br/>
  Dispatched: {{data.manifestOriginal?.dateDispatchedFor | oms_date_time_zone}}<br/>
  Found:      {{data.manifestToMerge?.dateDispatchedFor | oms_date_time_zone}}<br/>
  -->

  <div class="row">
    <div class="form-group col-xs-6">
      <oms-custom-search label="Carrier"
                         *ngIf="!data.noCarrier"
                         [(ngModel)] = "data.carrier"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         [items]="carrierSearch.items$ | async"
                         [loading]="carrierSearch.loading"
                         [typeahead]="carrierSearch.input$"
                         bindLabel="name"
                         [allowCreate]="true">
      </oms-custom-search>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-xs-6">
      <oms-date-input [disabled]="readonly" [label]="'Est. Pickup Date'"
                      [(ngModel)]="data.date"
                      (ngModelChange)="onDateChange($event)"
                      [cleanable]="false"
                      [time]="false"
                      [show-checkbox]="false" required>

      </oms-date-input>

      <oms-custom-search label="Truck #"
                         [(ngModel)] = "data.truck"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         (create)="onCreateTruck($event)"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="truckService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.number}}</div>
        </ng-template-->
      </oms-custom-search>
    </div>
    <div class="form-group col-xs-6">
      <oms-custom-search label="Driver" required
                         [(ngModel)] = "data.driver"
                         (ngModelChange)="onDriverChange($event)"
                         (create)="onCreateDriver($event)"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="fullName"
                         [allowCreate]="true"
                         [service]="driverService">

        <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.firstName}} {{item.lastName}}</div>
        </ng-template>
      </oms-custom-search>
      <oms-custom-search label="Trailer #"
                         [(ngModel)] = "data.trailer"
                         (create)="onCreateTrailer($event)"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="trailerService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.number}}</div>
        </ng-template-->
      </oms-custom-search>
    </div>
  </div>

  <div class="row">
    <!--div *ngFor="let master of data.masters">
      <div>{{master.mawbNumber}}</div>
      <div>{{master.cargoBuilding?.name}}</div>
      <div>{{master.addressDelivery?.name}}</div>
    </div-->

    <div class="table-responsive" style="max-height: 250px; overflow-y: auto;">
      <table class="table">
        <thead>
          <tr>
            <th><a>#</a></th>
            <th><a>O#</a></th>
            <th><a>HAWB</a></th>
            <th><a>Pick Up</a></th>
            <th><a>Delivery</a></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of data.orders, let i = index">
            <td>{{i+1}}</td>
            <td>{{order.documentNumber}}</td>
            <td>{{order.hawb}}</td>
            <td>{{order.master?.addressCfs?.name}}</td>
            <td>{{order.addressDelivery?.name}}</td>
            <td><span *ngIf="!allowDispatch(order)" tooltip="No 1C for AMS Order"><i class="fa fa-exclamation-triangle red-font">&nbsp;</i></span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!--div class="row" *ngIf="cancelEnable">
    <hr/>
    <div class="btn btn-lg btn-danger" (click)="onCancelDispatch()" style="width: 100%" >Cancel Dispatch</div>
  </div-->

  <ng-container widget-footer>
    <label *ngIf="!!info" class="alert alert-info info-label"><i class="fa fa-info-circle"></i>&nbsp;{{info}}</label>
    <!--div class="btn btn-danger" (click)="onCancelDispatch()">Cancel Dispatch</div-->
    <!--oms-checkbox style="display: block; padding-top: 5px" label="Generate PTT" [(checked)]="data.generatePtt" ></oms-checkbox-->
  </ng-container>
</oms-base-dialog>


