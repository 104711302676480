﻿<oms-base-dialog [loading]="isLoading" [icon]="'fas fa-history'" [min-width]="'800px'" [width]="'1200px'" (ok)="closeDialog()"
                 [no-padding]="true" [btnOk]="{caption: 'Close'}" [btnCancel]="undefined"
                 [title]="column?.label || 'History Information'">

  <span widget-header>
      <ul class="nav nav-tabs" *ngIf="logParams.length > 1" style="margin-top: 6px">
        <li *ngFor="let param of logParams" (click)="changeLogField(param)"
            [ngClass]="{'active': selectedParameter == param.key}">
          <a data-toggle="tab">{{param.key | titlecase}}</a>
        </li>
      </ul>
  </span>

  <div class="widget-content table-responsive widget-content_no-padding">
    <div class="form-row" style="overflow-y: auto; max-height: 500px; min-height: 300px;">
      <table class="table table-hover table condensed">
        <thead>
        <tr>
          <th style="width: 110px"><a>Changed On</a></th>
          <th><a>Field</a></th>
          <th><a>Old Value</a></th>
          <th><a>New Value</a></th>
          <th style="width: 150px"><a>Changed By</a></th>
          <!--<th>Remove</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let log of logChanges">
          <td>{{log.createdAt | oms_date_time}}</td>
          <td>{{convertFieldName(log.field)}}</td>
          <td>{{convertValue(log.oldObjectValue, log)}}</td>
          <td>{{convertValue(log.newObjectValue, log)}}</td>
          <td>{{log.systemUserName}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</oms-base-dialog>
