import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderDocuments } from "../../../../modules/shared/models/documents/order.documents";
import { BehaviorSubject } from "rxjs";
import { FileUploadService } from "../../../../services/file.upload.service";
import { OmsAlertsService } from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { extractFileExt } from "../../../../_helpers/string.util";
import { DomSanitizer } from "@angular/platform-browser";
import { PdfViewerComponent } from "ng2-pdf-viewer/dist/pdf-viewer.component";
var DocumentPreviewComponent = /** @class */ (function () {
    function DocumentPreviewComponent(domSanitizer, cdr, 
    //    private dialogs: OmsDialogsService,
    alerts, fileUploadService) {
        this.domSanitizer = domSanitizer;
        this.cdr = cdr;
        this.alerts = alerts;
        this.fileUploadService = fileUploadService;
        this.activeChange = new EventEmitter();
        this.fitToPage = true;
        this.fitToPageChange = new EventEmitter();
        this.source = new BehaviorSubject(undefined);
        this.disabled = false;
        this.loading = false;
        this.rotates = [0, 90, 180, 270];
        this.rotate = 0;
    }
    DocumentPreviewComponent.prototype.getData = function () {
        var _this = this;
        if (this.active) {
            this.loading = true;
            this.fileUploadService.stream(this.active.uploadedFiles)
                .subscribe(function (result) {
                _this.data = result;
                new Response(result).arrayBuffer()
                    .then(function (buffer) {
                    _this.loading = false;
                    _this.source.next(undefined);
                    _this.source.next(new Uint8Array(buffer));
                    _this.error = undefined;
                    _this.cdr.markForCheck();
                }).catch(function (error) {
                    _this.loading = false;
                    _this.data = undefined;
                    _this.cdr.markForCheck();
                    _this.error = 'Failed to load document: ' + error;
                    //              this.dialogs.openErrorDialog(error, 'Error Loading Document');
                });
            }, function (error) {
                _this.loading = false;
                _this.cdr.markForCheck();
                _this.data = undefined;
                _this.error = 'Failed to load document: ' + error;
                //            this.dialogs.openErrorDialog(error, 'Error Loading Document');
            });
        }
    };
    DocumentPreviewComponent.prototype.initializeView = function () {
        this.rotate = 0;
        this.data = undefined;
        this.source.next(undefined);
        if (!this.fitToPage) {
            this.fitToPage = true;
            this.fitToPageChange.emit(this.fitToPage);
        }
        if (this.active) {
            this.getData();
        }
        else {
            this.disabled = false;
        }
    };
    DocumentPreviewComponent.prototype.ngOnInit = function () {
    };
    DocumentPreviewComponent.prototype.onCarouselEvent = function (event) {
        console.log('CAROUSEL', event);
    };
    DocumentPreviewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.active) {
            this.initializeView();
        }
        console.log('CHANGES', changes);
    };
    DocumentPreviewComponent.prototype.next = function () {
        if (this.active) {
            this.active = this.documents.next(this.active, true);
            this.activeChange.emit(this.active);
            this.initializeView();
        }
    };
    DocumentPreviewComponent.prototype.previous = function () {
        if (this.active) {
            this.active = this.documents.prev(this.active, true);
            this.activeChange.emit(this.active);
            this.initializeView();
        }
    };
    Object.defineProperty(DocumentPreviewComponent.prototype, "isPdf", {
        get: function () {
            return !this.loading && !this.error && this.active && this.active.uploadedFiles.name &&
                this.active.uploadedFiles.name.toLowerCase().endsWith('.pdf');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentPreviewComponent.prototype, "isImage", {
        get: function () {
            return !this.error && this.active && this.active.uploadedFiles.name &&
                ['jpg', 'jpeg', 'png', 'bmp', 'svg']
                    .includes(extractFileExt(this.active.uploadedFiles.name.toLowerCase()));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentPreviewComponent.prototype, "dataUrl", {
        get: function () {
            return this.active && /*this.domSanitizer.bypassSecurityTrustResourceUrl(*/ this.fileUploadService.buildStreamFileUrl(this.active.uploadedFiles); /*);*/
        },
        enumerable: true,
        configurable: true
    });
    DocumentPreviewComponent.prototype.isFirst = function () {
        return this.documents && this.documents.first() === this.active;
    };
    DocumentPreviewComponent.prototype.isLast = function () {
        return this.documents && this.documents.last() === this.active;
    };
    DocumentPreviewComponent.prototype.setActive = function (document) {
        this.active = document;
        this.activeChange.emit(document);
        this.getData();
    };
    Object.defineProperty(DocumentPreviewComponent.prototype, "landscape", {
        get: function () {
            return this.image && this.image.nativeElement.naturalHeight < this.image.nativeElement.naturalWidth;
        },
        enumerable: true,
        configurable: true
    });
    DocumentPreviewComponent.prototype.print = function () {
        var landscape = this.landscape;
        var css = this.image ?
            "@media print {\n          @page { size: " + (landscape ? 'landscape' : 'portrait') + "; }\n          img {\n               width : " + (landscape ? 'auto' : '100%') + ";\n               height : " + (landscape ? '100%' : 'auto') + ";\n          }\n      }" :
            "@media print {\n          @page { size: auto; }\n      }";
        if (this.data) {
            var file = window.URL.createObjectURL(this.data);
            var iframe_1 = document.createElement('iframe');
            iframe_1.onload = function () {
                //        let css = '@page { size: auto; }',
                var doc = iframe_1.contentWindow.document, head = doc.head || doc.getElementsByTagName('head')[0], style = doc.createElement('style');
                style.type = 'text/css';
                style.media = 'print';
                style.appendChild(doc.createTextNode(css));
                head.appendChild(style);
                //            console.log('DOCUMENT', iframe.contentWindow.document);
            };
            iframe_1.style.display = 'none';
            iframe_1.src = file;
            document.body.appendChild(iframe_1);
            iframe_1.contentWindow.print();
        }
        /*    this.fileUploadService.stream(this.active.uploadedFiles)
              .subscribe(
                (result: Blob) => {
                  // `blob` response
                  let file = window.URL.createObjectURL(result);
        //          console.log(file);
        
        
        
                  let iframe = document.createElement('iframe');
                  iframe.onload = function() {
                    let css = '@page { size: auto; }',
                      doc = iframe.contentWindow.document,
                      head = doc.head || doc.getElementsByTagName('head')[0],
                      style = doc.createElement('style');
        
                    style.type = 'text/css';
                    style.media = 'print';
                    style.appendChild(doc.createTextNode(css));
                    head.appendChild(style);
        //            console.log('DOCUMENT', iframe.contentWindow.document);
                  };
                  iframe.style.display = 'none';
                  iframe.src = file;
        
                  document.body.appendChild(iframe);
                  iframe.contentWindow.print();
                });
        
         */
    };
    DocumentPreviewComponent.prototype.rotateLeft = function () {
        this.rotate = this.rotates.prev(this.rotate, true);
    };
    DocumentPreviewComponent.prototype.rotateRight = function () {
        this.rotate = this.rotates.next(this.rotate, true);
    };
    return DocumentPreviewComponent;
}());
export { DocumentPreviewComponent };
