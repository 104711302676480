import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, Pipe, PipeTransform,
  QueryList, ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {OmsConstants} from '../../../../common/oms-constants.service';
import {
  Airline,
  Airport, Customer, FreightForwarder, Load, LoadType,
  Master,
  Uld, MasterShipment,
  MasterShipmentAir,
  MasterStatus, MasterStatusService, Order,
  Part,
  Shipment,
  ShipmentContent, ColumnSettings, CustomerGroup,
  Split, RecoveryOrder, MasterAir
} from '../../../../modules/shared/models';
import {AirlineService} from '../../../../services/air/airline.service';
import {ColumnIds, OmsColumns} from '../../../../common/oms-columns.service';
import {Subject, Subscription} from 'rxjs';
import {add, subtract} from 'ngx-bootstrap/chronos';
import {CustomerService} from '../../../../services/customer.service';
import {AddressService, AuthService, OrdersService} from '../../../../services';
import {isNullOrUndefined} from 'util';
import {StringInputComponent} from "../../../../modules/shared/components/common/input/string-input/string-input.component";
import {DateInputComponent} from "../../../../modules/shared/components/common/input/date-input/date-input.component";
import {MasterService} from "../../../../services/master/master.service";
import {OmsAlertsService} from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {Location} from '@angular/common';
import {convertMawbNumber} from '../../../../modules/shared/services/oms-converters.service';
import {NotesCenterComponent} from "../../../../components/common/notes-center";
import {NotesCenterDialogComponent} from "../../../../components/common/notes-center-dialog";
import {MatDialog} from "@angular/material";
import * as Dates from '../../../../common/oms-date-time.utils';
import {WebSocketService} from "../../../../services/websocet/web.socket.service";
import {FieldUpdateEvent} from '../../../../modules/shared/components/base/data-table/data-table.utils';
import {HistoryInformationDialogComponent} from "../../../../components/common/history-information-dialog";
import {LogChangesService} from "../../../../services/logchanges/log.changes.service";
import {BaseColumn} from "../../../../modules/shared/components/base/data-table/columns/column-types";
import {CustomerSearchComponent} from "../../../../components/common/customer-search";
import {assigned, equals} from '../../../../_helpers/utils';
import {Router} from "@angular/router";
import {maxDate} from '../../../../common/oms-date-time.utils';
import {Size} from '../../../../common/oms-types';
import {MasterProcessor} from '../../../../common/master-processor';
import {FreightForwarderService} from "../../../../services/freight.forwarder.service";
import {CustomSearchComponent} from "../../../../modules/shared/components/common/input/custom-search/custom-search.component";
import {FreightForwarderSearchComponent} from "../../../../components/common/freight-forwarder-search/freight-forwarder-search.component";
import {FreightForwarderDialogComponent} from '../../../../components/common/freight-forwarder-dialog';
import {DataTableComponent} from '../../../../modules/shared/components/base/data-table/data-table.component';
import {DialogType, ModalResult, OmsDialogsService} from "../../../../components/common/oms-dialogs";
import {
  UldsDialogComponent,
  UldsDialogInputData
} from "../../../../components/common/ulds-dialog/ulds-dialog.component";
import {SplitOrderDialogComponent} from "../../../../modules/dispatch/pages/manifest-create/split-order-dialog/split-order-dialog.component";
import {Address, AddressType} from "../../../../modules/shared/models";
import {BillingInfoListDialogComponent} from "../../../orders/order-editor/billing-info-list-dialog/billing-info-list-dialog.component";
import {Invoice} from "../../../../modules/shared/models/invoice";
import {BillingService} from "../../../../services/billing.service";
import {takeUntil} from "rxjs/operators";
import {LoggedUser} from "../../../../modules/shared/models/logged-user";
import {
  OrderSubscriptionDialogComponent,
  OrderSubscriptionDialogInput
} from "../../../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";


@Pipe({name: 'loadColumnsFilter', pure: false})
export class LoadTableColumnsFilter implements PipeTransform {
  transform(array: Array<any>, noCfs: boolean): Array<any> {
    return noCfs ? array.filter((c: BaseColumn) => c.id !== ColumnIds.LOAD_PCS_RCVD) : array;
  }
}

@Pipe({name: 'orderColumnsFilter', pure: false})
export class OrderTableColumnsFilter implements PipeTransform {
  transform(array: Array<any>, nonAMS: boolean): Array<any> {
    return nonAMS ? array.filter((c: BaseColumn) => c.id !== ColumnIds.ORDER_1C && c.id !== ColumnIds.ORDER_1F) : array;
  }
}

@Pipe({name: 'partsBySelectedLoad', pure: true})
export class PartsBySelectedLoadFilter implements PipeTransform {
  transform(array: Array<any>, partsByLoad: boolean, selected: Split[]): Array<any> {
    return array.filter((part: Part) => this.showLoadPart(part, partsByLoad, selected));
  }

  showLoadPart(part: Part, partsByLoad: boolean, selected: Split[]): boolean {
    return !partsByLoad || selected.length === 0 || !isNullOrUndefined(selected.find((split) => split.splitNumber === part.splitNumber));
  }
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'oms-master-import-air',
  templateUrl: './master-import-air.component.html',
  styleUrls: ['./master-import-air.component.scss'],
//  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterImportAirComponent implements OnInit, OnDestroy {
  @Input() master: Master;
  @Input() readonly: boolean = false;
  @ViewChild('ordersTable') ordersTable: DataTableComponent;
  public loads: Load[];

  AddressType = AddressType;

  submitting: boolean = false;
  columnIds = ColumnIds;
  public invoices: Invoice[] = [];
  unsubscribe$ = new Subject<void>();


  @ViewChild(NotesCenterComponent) notesCenter: NotesCenterComponent;
  @ViewChildren(StringInputComponent) stringInputs: QueryList<StringInputComponent>;
  @ViewChildren(CustomerSearchComponent) customerSearchInputs: QueryList<CustomerSearchComponent>;
  @ViewChildren(FreightForwarderSearchComponent) freightForwarderSearchInputs: QueryList<FreightForwarderSearchComponent>;
  @ViewChildren(CustomSearchComponent) customSearchComponentInputs: QueryList<CustomSearchComponent>;
  @ViewChildren(DateInputComponent) dateInputs: QueryList<DateInputComponent>;

  webSocketEventName: string = '/data-table/masters';
  @Output() masterChangeEvent = new EventEmitter();
  @Output() masterUpdatedEvent: EventEmitter<Master> = new EventEmitter<Master>();

  Size = Size;
  public masterOrdersColumns: ColumnSettings[];
  public masterLoadsColumns: ColumnSettings[];
  private masterDefaultColumns: BaseColumn[];

  public orderPartsColumns: ColumnSettings[];
  public recoveryLoadColumns: ColumnSettings[];

  private sub: Subscription[] = [];
  private orderLoads: Map<Order, Load[]> = new Map<Order, Load[]>();
  private loadContents: Map<Load, ShipmentContent> = new Map<Load, ShipmentContent>();
  public selectedOrders: Order[] = [];
  public selectedParts: Part[] = [];
  public selectedLoads: RecoveryOrder[] = [];
  public loadsByOrders: boolean = false;
  public partsByLoad: boolean = false;
  public user: LoggedUser;

  isDeleteAvailable: boolean = false;
  customerGroup: CustomerGroup;

  constructor(
    public _constants: OmsConstants,
    public _alerts: OmsAlertsService,
    public _statuses: MasterStatusService,
    public _location: Location,

    private cdr: ChangeDetectorRef,

    public dialog: MatDialog,
    public dialogs: OmsDialogsService,

    private logChangesService: LogChangesService,
    public customerService: CustomerService,
    public freightForwarderService: FreightForwarderService,
//    public customerGroupService: CustomerGroupService,
    public addressService: AddressService,
    public billingService: BillingService,

    public _columns: OmsColumns,
//    public airportService: AirportService,
    public airlineService: AirlineService,
    public alerts: OmsAlertsService,
    private webSocketService: WebSocketService,
    private masterService: MasterService,
    private masterProcessor: MasterProcessor,
    private orderService: OrdersService,
    private router: Router,
    private authService: AuthService) {
    this.customerService.getAllCustomers();
    this.freightForwarderService.getAllFreightForwarders();
//    this.customerGroupService.getAllCustomerGroups();
//    this.addressService.getAllAddresses();
  }


  public get orderParts(): Part[] {
    return this.master.parts;
  }

  public get recoveryLoads(): RecoveryOrder[] {
    return this.master.splits;
  }

  public get orders(): Order[] {
    return this.master.orders;
  }

  private initCanDeleteOrder() {
    this.isDeleteAvailable = this.authService.canDeleteOrder();
  }

  public get loadItems(): Load[] {
    if (this.loadsByOrders) {
      let loads: Load[] = [];

      if (this.selectedOrders && this.selectedOrders.length > 0) {
//        return this.orderLoads.get(this.selectedOrders.values().next().value);
        this.selectedOrders.forEach((order) => {
          let ld: Load[] = this.orderLoads.get(order);
          if (ld) {
            ld.forEach((load) => loads.push(load));
            // todo: why does not work???  loads.concat(ld);
          }
        });
      }
      return loads;
    } else {
      return this.loads;
    }
  }

  private prepareLoads(master: Master) {
    this.orderLoads.clear();
    this.loadContents.clear();
    this.loads = [];
  }

  ngOnInit() {
    if (this.master && !assigned(this.master.masterAir)) {
        this.master.masterAir = new MasterAir();
        this.master.masterAir.master = this.master;
        console.log('Restored MasterAir', this.master);
    }

    this.prepareLoads(this.master);

    this.authService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => this.user = user);

    this.billingService.getInvoicesByMasterId(this.master.id)
      .subscribe((invoices) => this.invoices = invoices);

    this.sub.push(this._columns.recoveryLoadColumns.subscribe((columns) => {this.recoveryLoadColumns = columns; }));
    this._columns.getRecoveryLoadColumns();

    this.sub.push(this._columns.orderPartsColumns.subscribe((columns) => {this.orderPartsColumns = columns; }));
    this._columns.getOrderPartsColumns();

    this.sub.push(this._columns.masterOrdersColumns.subscribe((columns) => {this.masterOrdersColumns = columns; }));
    this._columns.getMasterOrdersColumns();

    this.sub.push(this._columns.masterLoadsColumns.subscribe((columns) => {this.masterLoadsColumns = columns; }));
    this._columns.getMasterLoadsColumns();

    this.sub.push(this._columns.masterListColumns.subscribe((columns) => {this.masterDefaultColumns = columns; }));
    this._columns.getMasterListColumns();
    this.initCanDeleteOrder();
  }

  changeMawbEvent(master, event) {
    if (master && master.masterAir) {

      for (let order of master.orders) {
        if (order.isNew()) {
          order.hawb = master.masterAir.mawbNumber;
        }
      }

      if (master.masterAir.mawbNumber.length === 11) {
        let awbPrefix = master.masterAir.mawbNumber.substr(0, 3);
        /*this.masterAirService.findByMawbNumber(master.masterAir.mawbNumber).subscribe(masterAir => {
          if (isNullOrUndefined(masterAir)) {
            this.masterExist = false;
            master.master.id = null;
            master.shipment.id = null;
            master.masterAir.id = null;
          } else {
            this.masterExist = true;
            master.master.id = masterAir.master.id;
            master.shipment = masterAir.master.shipment;
            master.masterAir.flightNumber = masterAir.flightNumber;
            master.masterAir.id = masterAir.id;
          }
        }); */

        this.airlineService.getByPrefix(awbPrefix).subscribe(airline => {
          this.masterAirline = airline;
//          this.checkWarehouse();
          this.cdr.detectChanges();
        });
      }
    }
  }


  onCustomerChange(master: Master, event) {
    let old: FreightForwarder = master.freightForwarder;
    master.freightForwarder = event;

/*    if (master.freightForwarder) {
      master.orders.forEach(order => {
        if (!order.customer || equals(order.customer, old))
        order.customer = master.freightForwarder})
    }*/
  }

  onCustomerGroupChange(master, event) {
    this.customerGroup = event;
    if (isNullOrUndefined(this.customerGroup)) {
      if (!isNullOrUndefined(master.customer) && this.customerGroup !== master.customer.customerGroup) {
        master.customer = null;
      }
      this.customerService.getAllCustomers();
    } else {
      this.customerService.getAllCustomersByCustomerGroup(this.customerGroup.id);
    }
  }

  ngOnDestroy(): void {
    this.sub.forEach(item => {item.unsubscribe(); });
  }

  get masterDateArrivalAct(): Date {
    return this.master.dateArrivalAct;
  }

  get masterDateArrivalEst(): Date {
    return this.master.dateArrivalEst;
  }

  set masterDateArrivalEst(date: Date) {
    this.master.dateArrivalEst = date;
    if (isNullOrUndefined(this.master.dateArrivalAct)) {
      this.master.updateLastFreeDay();
    }
  }

  set masterDateArrivalAct(date: Date) {
    let changed = !Dates.sameDateTime(date, this.master.dateArrivalAct || this.master.dateArrivalEst);
    this.master.dateArrivalAct = date;
    if (changed) {
      this.master.updateLastFreeDay();
    }
  }

  get deliveryApproval() {
    let deliveryApproval = true;
    this.master.orders.forEach(order => {
      deliveryApproval = deliveryApproval && order.deliveryApproval;
    });
    return deliveryApproval;
  }

  set deliveryApproval(deliveryApproval: boolean) {
    this.master.orders.forEach(order => {
      order.deliveryApproval = deliveryApproval;
    });
  }

  get customer(): Customer {
    return this.master.customer;
  }

  set customer(customer: Customer) {
    this.master.customer = this.customer;
    for (let order of this.master.orders) {
      if (order.isNew()) {
        order.customer = customer;
      }
    }
  }

  get dateCfsInAct(): Date {
    return this.master.dateCfsInAct;
  }

  set dateCfsInAct(date: Date) {
    if (this.master.dateCfsInAct !== date) {
      this.master.dateCfsInAct = date;
    }
  }

  get dateCfsOutAct(): Date {
    return this.master.dateCfsOutAct;
  }

  set dateCfsOutAct(date: Date) {
    if (this.master.dateCfsOutAct !== date) {
      this.master.dateCfsOutAct = date;
    }
  }


  get freightForwarder(): FreightForwarder {
    return this.master.freightForwarder;
  }

  set freightForwarder(freightForwarder: FreightForwarder) {
    this.master.freightForwarder = freightForwarder;
/*    for (let order of this.master.orders) {
      if (order.isNew()) {
        order.customer = customer;
      }
    }*/
  }

  get masterUldCount(): number {
    return this.master.uldCount;
  }

  set masterUldCount(value: number) {
    this.master.uldCount = value;
  }

  get masterAirUlds(): Uld[] {
    return this.master.masterAirUld;
  }

  set masterAirUlds(uldAirs: Uld[]) {
    this.master.masterAirUld = uldAirs;
  }

  get masterHu(): number {
    return this.master.hu;
  }

  set masterHu(value: number) {
    this.master.hu = value;
  }

  get masterPieces(): number {
    return this.master.pieces;
  }

  get masterPiecesReceived(): number {
    return this.master.pcsReceived;
  }


  set masterPieces(pieces: number) {
    this.master.pieces = pieces;
    this.resetLoads();
  }

  get masterWeight(): number {
    return this.master.weight;
  }

  set masterWeight(weight: number) {
      this.master.weight = weight;
  }

  get masterVolume(): number {
    return this.master.volume;
  }

  set masterVolume(volume: number) {
    this.master.shipment.volume = volume;
    for (let order of this.master.orders) {
      if (order.isNew()) {
        order.volume = volume;
      }
    }
  }

  get masterPickupDate(): Date {
    return this.master.datePickupActual;
  }

  set masterPickupDate(date: Date) {
    let changed = !Dates.sameDateTime(date, this.master.datePickupActual || this.master.datePickupEstimated);
    this.master.datePickupActual = date;
    if (changed) {
      this.master.updateArrivalDay();
    }
  }

  get masterPickupDateEstimated(): Date {
    return this.master.datePickupEstimated;
  }

  set masterPickupDateEstimated(date: Date) {
    this.master.datePickupEstimated = date;
  }


  get masterDeliveryDate(): Date {
    return this.master.dateDeliveryActual;
  }

  set masterDeliveryDate(date: Date) {
    this.master.dateDeliveryActual = date;
  }

  get masterDeliveryDateEstimated(): Date {
    let date: Date = this.master.dateDeliveryEstimated;
    if (isNullOrUndefined(date)) {
      for (let load of this.loads) {
        if (load.isDelivery) {
          let content: ShipmentContent = this.loadContents.get(load);
          if (!date || date < content.shipment.dateDeliveryExpectation) {
            date = content.shipment.dateDeliveryExpectation;
          }
        }
      }
    }
    return date;
  }

  set masterDeliveryDateEstimated(date: Date) {
    this.master.dateDeliveryEstimated = date;
    let dateCfsPickup = isNullOrUndefined(date) ? null : subtract(new Date(date), 2, 'hours');

    for (let order of this.master.orders) {
//      order.dateDeliveryEst = date;
      let loads = this.orderLoads.get(order);
      if (loads) {
        for (let load of loads) {
          if (load.isDelivery) {
            load.shipment.dateDeliveryExpectation = isNullOrUndefined(date) ? null : maxDate(load.shipment.dateDeliveryExpectation, date);
            load.shipment.datePickUpExpectation = dateCfsPickup;
          }
        }
      }
    }
//    this.accountStatus(this.master);
  }


  onCargoBuildingChange(address) {
    this.nonAMS = address && address.cfs3plLocation;

    for (let load of this.loads) {
      if (load.loadType === LoadType.RECOVERY) {
        load.shipment.addressPickUp = address;
      }
    }
  }

  public onSave(back?: boolean) {
    if (this.submitting) {
      return;
    }

    if (!this.validateFields()) {
      this.submitting = true;


    let saving = this.master;
    console.log('Save Master', saving);

    if (this.master.isNew()) {
      this.masterService.newMasterCreated.next(true);
      this.masterService.create(this.master).subscribe(
        (master) => {
//          this.master = master;
//          this.prepareLoads(this.master);
//          this.master.orders.forEach((o) => {o.master = this.master; });
//          this.masterService.refreshByLastPageRequest(); // getAllMasters(); //todo
          this.alerts.success('New Master Created: ' + master.masterNumber);
          this.masterChangeEvent.emit(master);
//          this.router.navigate(['home/masters/master', {id: master.id}], {replaceUrl: true});
          this.submitting = false;

          if (back) {
            this._location.back();
          }
          /*this.parent.masterSubject.next(this.master);*/
        },
        error => {
          this.submitting = false; // todo
          if (error === 'DataIntegrityViolation') {
            error = 'MAWB ' + convertMawbNumber(this.master.mawbNumber) + ' already exists';
          }
          this.alerts.danger(error);
        });
    } else {
      console.log('UPDATE', this.master);
      this.masterService.update(this.master).subscribe(
        (master) => {
          this.submitting = false;

//          this.master = plainToClass(Master, master);
//          this.prepareLoads(this.master);
//          this.parent.loadMaster(this.master.id);


          this.masterChangeEvent.emit(this.master);
          this.alerts.success('Master Updated: ' + this.master.masterNumber);

          if (back) {
            this._location.back();
          }

        },
       (error) => {
          this.submitting = false; // todo
          if (error === 'DataIntegrityViolation') {
            error = 'MAWB ' + convertMawbNumber(this.master.mawbNumber) + ' already exists';
          }

          this.alerts.danger(error);
        });
    }
    }
  }


  validateFields() {
    let invalid = false;
    this.stringInputs.forEach(input => {
      input.touchControl();
      input.invalid.subscribe(res => {
        invalid = invalid || res;
      });
    });

    this.freightForwarderSearchInputs.forEach(input => {
      input.touchControl();
      input.invalid.subscribe(res => {
        invalid = invalid || res;
      });
    });

    this.customSearchComponentInputs.forEach(input => {
      input.touchControl();
      input.invalid.subscribe(res => {
        invalid = invalid || res;
      });
    });

    this.dateInputs.forEach(input => {
      input.touchControl();
      input.invalid.subscribe(res => {
        invalid = invalid || res;
      });
    });
    return invalid;
  }

  get masterStatus(): MasterStatus {
    return MasterStatus.get(this.master.status);
  }

  set masterStatus(status: MasterStatus) {
    this.master.status = status ? status.id : null;
//    if (this.master.orders)
//      this.master.orders.forEach((order)=>order.status = status ? status.id : null)
  }


  setMasterStatus(item: MasterStatus) {
    this.master.status = item.id;
  }

  onSaveNote() {
    this._alerts.success('Changes are saved');
  }

  openNoteCenter(noteTypeId) {
    const dialogRef = this.dialog.open(NotesCenterDialogComponent, { width: '80%', data: { id: this.master.id, objectType: 'MASTER', noteTypeId: noteTypeId, readonly: this.readonly} });
  }

  openBillingDialog() {
    this.dialogs.openDialog(BillingInfoListDialogComponent, {
      object: this.master,
      invoices: this.invoices,
      isReadMode: this.readonly
    }, {}).then();
  }

  openUldDialog() {
    const dialogRef = this.dialog.open(UldsDialogComponent, {
      width: '30%', data:
        {
          masterId: this.master.id,
          readonly: this.readonly
        } as UldsDialogInputData
    });
  }

  openDocCenter() {
// old way dialog:
//    this.dialog.open(DocCenterDialogComponent, { width: '80%', data: { id: this.master.id, object: this.master, objectType: 'MASTER', readonly: this.readonly } });
// New Way - window:
    this.dialogs.openDocCenterDialog({id: this.master.id, objectType: 'MASTER'});
  }

  onOrderFieldUpdate(event: FieldUpdateEvent) {

    if (event.field === 'dateDeliveryAct') {
      this.masterService.updateOrderDate(this.master, event.row, 'dateDeliveryAct', event.newValue)
        .then(master => {this.masterUpdatedEvent.emit(master); });
    }

    if (event.field === 'pieces') {if (this.master.updatePieces()) {this.resetLoads(); } else { event.cancel(); }}
    if (event.field === 'weight') {if (this.master.updateWeight()) { this.cdr.markForCheck(); } else { event.cancel(); }}
    if (event.field === 'addressDelivery') {
      if (event.row instanceof Order && !isNullOrUndefined(event.newValue)) {
        this.addressService.setCustomerDeliveryLocation(event.row.customer, event.newValue);
      }
    }
  }

  get lfdDisabled() {
    return isNullOrUndefined(this.master.dateArrivalEst) && isNullOrUndefined(this.master.dateArrivalAct);
  }

  get lfdCleanable() {
    return false;
  }

  get lfdMinDate(): Date {
    return Dates.minDate(this.master.dateArrivalEst, this.master.dateArrivalAct);
  }

  checkWarehouse(ms: MasterShipment) {
//    this.master.masterShipments.forEach(ms=>{
      let airport = ms.airport;
      let airline = ms.airline;
      if (airport && airline) {
        this.addressService.getAirportWarehouse(airport.id, airline.id).then( (warehouse) => {ms.shipment.addressDelivery = warehouse; });
      }
//    });
  }

  get masterAirline(): Airline {
    return this.master.airline;
/*    if (this.master.isSingleSplit)
      return this.master.singleSplit.masterShipmentAir.airline;
    else return null; */
  }

  set masterAirline(airline: Airline) {
    if (!equals(this.masterAirline, airline)) {
      this.master.masterShipments.forEach(ms => {
        let old = this.masterAirline;
        ms.airline = airline;
        if (!equals(old, airline)) {
          this.checkWarehouse(ms);
        }
      });
    }
  }


  get masterAirport(): Airport {
    return this.master.airport;
  }

  set masterAirport(airport: Airport) {
    if (!equals(this.master.airport, airport)) {
      this.master.masterShipments.forEach(ms => {
        let old = ms.airport;
        ms.airport = airport;
        if (!equals(old, airport)) {
          this.checkWarehouse(ms);
        }
      });
    }
  }

  get masterCargoBuilding(): Address {
    return this.master.cargoBuilding;
  }

  set masterCargoBuilding(address: Address) {
    this.master.cargoBuilding = address;
  }

  resetLoads() {
    this.master.reset();
    this.selectedLoads = [];
    this.cdr.markForCheck();
  }

  get masterOriginalAirport(): Airport {
    return this.master.masterAir && this.master.masterAir.originalAirport;
  }

  set masterOriginalAirport(airport: Airport) {
    this.master.masterAir.originalAirport = airport;
  }

  addOrder() {
    let order: Order = Order.createOrderByMaster(this.master);
    this.master.orders.push(order);
    this.ordersTable.refresh(this.master.orders);

    this.prepareLoads(this.master);
    this.master.masterShipments.forEach(ms => {this.checkWarehouse(ms); });
    this.selectedOrders = [order];

    this.resetLoads();
  }

  splitOrder() {
    let order = this.selectedOrders[0];
    this.dialogs.openDialog(SplitOrderDialogComponent, {
      order: order,
      onOk: (newPcs: number, newHu: number, newWeight: number, newOrder: Order) => {
        let copyOrder = Object.assign(new Order(), order);
        this.master.orders.replaceAll(copyOrder, copyOrder);
        this.master.orders.push(newOrder);
        this.ordersTable.refresh(this.master.orders);
        this.resetLoads();
        this.cdr.markForCheck();
      }
    }).then();
  }

  isDisabledSplitOrder(): boolean {
    return this.selectedOrders && this.selectedOrders.length !== 1;
  }

  get allowDeleteOrder(): boolean {
    return this.selectedOrders && this.selectedOrders.length > 0;
  }

  get allowDeletePart(): boolean {
    return this.selectedParts && this.selectedParts.length > 0;
  }

  splitOrderPart() {
    let ms: MasterShipment = new MasterShipment();
    this.master.masterShipments.push(ms);
    ms.master = this.master;
    ms.masterShipmentAir = new MasterShipmentAir();
    ms.shipment = new Shipment();

    this.selectedParts.forEach(part => {
      let sc: ShipmentContent = new ShipmentContent();
      sc.shipment = ms.shipment;
      sc.order = part.order;
      sc.order.shipmentContents.push(sc);
    });

    this.resetLoads();
  }

  deleteOrder() {
    if (this.selectedOrders && this.selectedOrders.length > 0) {
      this.dialogs.confirm(DialogType.CONFIRMATION, 'Delete Order(s) ?',
        'Are you sure you want to delete selected Order(s)?',
        [{result: ModalResult.YES, caption: 'Delete'}, ModalResult.CANCEL])
        .then(res => {
          switch (res.result) {
            case ModalResult.YES: {

              this.selectedOrders.forEach(order => {
                if (order.isNew()) {
                  this.master.orders.removeAll(order);
                  this.ordersTable.refresh(this.master.orders);
                  this.resetLoads();
                } else {
                  this.orderService.delete(order.id).toPromise()
                    .then(() => {
                      this.master.orders.removeAll(order);
                      this.ordersTable.refresh(this.master.orders);
                      this.resetLoads();
                    })
                    .catch(error => this.alerts.error(error, "Error deleting Order"));
                }
              });
              this.selectedOrders.clear();
            }
          }
        });

        this.resetLoads();
        this.cdr.markForCheck();
    }
  }


  onMawbActualDeliveryChange(date) {
    let dateCfsPickup = isNullOrUndefined(date) ? null : subtract(new Date(date), 2, 'hours');

    for (let order of this.master.orders) {
      order.dateDeliveryAct = date;
      let loads = this.orderLoads.get(order);
      if (loads) {
        for (let load of loads) {
          if (load.isDelivery) {
            load.shipment.dateDeliveryActual = isNullOrUndefined(date) ? null : maxDate(load.shipment.dateDeliveryActual, date);
            load.shipment.datePickUpActual = dateCfsPickup;
          }
        }
      }
    }
  }

  onMawbEstimatedDeliveryChange(date) {
    let dateCfsPickup = isNullOrUndefined(date) ? null : subtract(new Date(date), 2, 'hours');

    for (let order of this.master.orders) {
      order.dateDeliveryReq = date;
      let loads = this.orderLoads.get(order);
      if (loads) {
        for (let load of loads) {
          if (load.isDelivery) {
            load.shipment.dateDeliveryExpectation = isNullOrUndefined(date) ? null : maxDate(load.shipment.dateDeliveryExpectation, date);
            load.shipment.datePickUpExpectation = dateCfsPickup;
          }
        }
      }
    }
  }

  onMawbActualPickupChange(date) {
    let dateCfsDelivery = isNullOrUndefined(date) ? null : add(new Date(date), 2, 'hours');
    for (let order of this.master.orders) {
      let loads = this.orderLoads.get(order);
      if (loads) {
        for (let load of loads) {
          if (load.isRecovery) {
            load.shipment.datePickUpActual = isNullOrUndefined(date) ? null : maxDate(load.shipment.datePickUpActual, date);
            load.shipment.dateDeliveryActual = dateCfsDelivery;
          }
        }
      }
    }
  }

  onMawbEstimatedPickupChange(date) {
    let dateCfsDelivery = isNullOrUndefined(date) ? null : add(new Date(date), 2, 'hours');
    for (let order of this.master.orders) {
      let loads = this.orderLoads.get(order);
      if (loads) {
        for (let load of loads) {
          if (load.isRecovery) {
            load.shipment.datePickUpExpectation = isNullOrUndefined(date) ? null : maxDate(load.shipment.datePickUpExpectation, date);
            load.shipment.dateDeliveryExpectation = dateCfsDelivery;
          }
        }
      }
    }
  }


  onRightClick(field, event, columnId: number) {
    event.stopPropagation();
    event.preventDefault();
    let columns = this.getColumnById(columnId);
    let column = columns.length > 0 ? this.getActualColumn(columns[0]) : null;
    if (!isNullOrUndefined(column) && column.history.logChanges) {
      let columnField = isNullOrUndefined(column.history.field) ? column.field : column.history.field;
      let params = this.logChangesService.getRequiredLogCenterParameters(this.master, columnField);
      this.dialog.open(HistoryInformationDialogComponent, { width: '80%', data: {logParams: params, column: column}});
    }
  }

  getActualColumn(column) {
    if (column.map) {
      return column.map.get("Master");
    }
    return column;
  }

  getColumnById(columnId: number) {
    return this.masterDefaultColumns.filter((c) => c.id === columnId);
  }

  get customerNameLabel() {
    let customers = [];
    this.master.orders.forEach(order => {
      if (!isNullOrUndefined(order.customer) && !this.isCustomerExist(customers, order.customer)) {
        customers.push(order.customer);
      }
    });
    return customers.length === 1 ? {label: customers[0].name, warning: false} : {label: "Various", warning: true} ;
  }

  isCustomerExist(customers: Customer[], customer: Customer) {
    return customers.find(customerFromList => {
      return customerFromList.id === customer.id;
    });
  }

  get masterFlightNumber(): string {
    return this.master.flightNumber;
  }

  set masterFlightNumber(flightNumber: string) {
    this.master.flightNumber = flightNumber;
  }

  onUpdatePartField(event: FieldUpdateEvent) {
    let part: Part = <Part> event.row;
    switch (event.field) {
      case 'pieces':
        let total: number = part.content.shipment.pieces;
        let received: number = part.content.shipment.shipmentContents.aggregate((acc, sc) => acc + ~~sc.pieces, 0);


        this.resetLoads();
        break;
    }
  }

  onUpdateLoadField(event: FieldUpdateEvent) {
    let split: Split = <Split> event.row;
    switch (event.field) {

      case 'isDispatched':
        if (split.load) {
          this.masterProcessor.dispatchRecoveryLoad(split, false, this.readonly)
            .then(result => {console.log('DISPATCH RESULT>', result); });
        } else {
          this.masterProcessor.openNewManifestFromMasterEditor(split.order);
        }
        break;
      case 'datePickupEst':
        if (this.masterService.validateRecoveryLoadPieces(this.master, split, event.newValue)) {
          if (split.isVirtual) {
            this.master.addRecoveryLoad(split.pieces, split.hu, event.newValue);
          } // Make Recovery load Actual;
          this.resetLoads();
        } else {
          event.cancel();
        }
        break;
      case 'pcsToRecover':
        if (this.masterService.validateRecoveryLoadPieces(this.master, split, event.newValue)) {
          if (split.isVirtual) {
            this.master.addRecoveryLoad(event.newValue, null);
          } // Make Recovery load Actual;
          this.resetLoads();
        } else {
          event.cancel();
        }

        break;
     }
  }

  onUpdateMasterPieces() {
    this.resetLoads();
//    this.master.reset();
  }

  get masterDate1F(): Date {
    return this.master.date1F;
  }

  set masterDate1F(value: Date) {
    if (!equals(value, this.master.date1F)) {
      this.master.date1F = value;
    }
  }

  get masterDateDilled(): Date {
    return this.master && this.master.dateBilled;
  }

  set masterDateBilled(date: Date) {
    if (this.master && !equals(date, this.master.dateBilled)) {
      this.master.dateBilled = date;
    }
  }

  get masterOrders(): Order[] {
    return this.master ? this.master.orders : undefined;
  }

  get direct(): boolean {
    return this.master.direct;
  }

  set direct(value: boolean) {
    this.master.direct = value;
    if (this.master.direct) {
      this.master.addressCfs = null;
      this.master.dateCfsInAct = null;
      this.master.dateCfsOutAct = null;
    }
    this.ordersTable.refresh();
  }


  get nonAMS(): boolean {
    return this.master.nonAMS;
  }

  set nonAMS(value: boolean) {
    this.master.nonAMS = value;
  }

  public onFreightForwarderCreate(event) {
    let freightForwarder = new FreightForwarder();
    const dialogRef = this.dialog.open(FreightForwarderDialogComponent, { width: 'auto', data: { freightForwarder: freightForwarder} });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.master.freightForwarder = res.res;
      }
    });
  }

  isDeliveryDateDisabled(): boolean {
    return this.readonly || this.masterService.isDisableMaster(this.master);
  }

  get cfsDisabled(): boolean {
    return this.readonly || this.master.direct || this.masterService.isDisableMaster(this.master);
  }

  get pickupLocationLabel() {
    return this.master.nonAMS ? 'Pick Up' : 'Cargo Bldg';
  }

  get masterRecoveryToAddressTypes() {
    return this.master.direct ? [AddressType.DELIVERY_LOCATION] : [AddressType.CFS_LOCATION];
  }

  get masterAddressRecoveryTo(): Address {
    return this.master.addressDelivery;
  }

  set masterAddressRecoveryTo(address: Address) {
    this.master.addressDelivery = address;
    this.cdr.detectChanges();
  }

  get masterAddressesRecoveryToDefault(): Address[] | undefined {
    return this.master.direct ? this.master.orders.unique(o => o.addressDelivery) : undefined;
  }

  public openSubscriptionDialog() {
    this.dialog.open<OrderSubscriptionDialogComponent, OrderSubscriptionDialogInput, MasterStatus[]>(OrderSubscriptionDialogComponent,
      {width: 'auto', data: {orders: [...this.orders]}})
      .afterClosed().subscribe((res) => { });
  }

  public getRldRowStatusClass(order: RecoveryOrder): string {
    console.log('TEST', order);
    return order && order.getRouteStatusClass();
  }

  public onShowHistory() {
    let logChanges = {
      className: new Master().javaClassName,
      converter: (value) => value ? value.toString() : OmsConstants.EMPTY_VALUE,
      id: this.master.id
    };

    console.log('LOG', logChanges);
    this.dialogs.openDialog<HistoryInformationDialogComponent, any>(HistoryInformationDialogComponent, {
      logParams: [logChanges], column: null, convertByBaseType: null
    }).then();
  }

}
