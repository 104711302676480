import {Country} from '../address/country';
import {Type} from 'class-transformer';
import {Labeled} from '../../../../common/oms-types';
import {BaseDeletable} from '../base-deletable';

export class Airport extends BaseDeletable implements Labeled {
  name: string;
  city: string;
  iataCode: string;
  icaoCode: string;
  latitude: number;
  longitude: number;
  departure: boolean;
  arrival: boolean;

  @Type(() => Country)
  country: Country;

  toString(): string {
    return this.id + ' ' + name;
  }

  get fullName() {
    return this.name + (this.city ? (', ' + this.city) : '') + (this.country ? (', ' + this.country.name) : '');
  }

  get label(): string {
    return '(' + this.iataCode + ') ' + this.name;
  }

}
