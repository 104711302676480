import {Labeled} from '../../../common/oms-types';
import {BaseDeletable} from './base-deletable';

export class Carrier extends BaseDeletable implements Labeled {
  public name: string;
  public scacCode: string;
  public inHouse: boolean;

  get label(): string {
    return name;
  }

  get isOwnCarrier(): boolean {
    return this.id === 1;
  }
}
