var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { ModalResult, OmsDialogsService } from "../components/common/oms-dialogs";
import { ReasonCode } from "../modules/shared/models/reason-code";
import { ReasonCodeDialogComponent } from "../components/dialogs/reason-code-dialog/reason-code-dialog.component";
var ReasonCodeService = /** @class */ (function (_super) {
    __extends(ReasonCodeService, _super);
    function ReasonCodeService(dialogs, httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.dialogs = dialogs;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/reason-code/';
        _this.classType = ReasonCode;
        return _this;
    }
    ReasonCodeService.prototype.createEditAndSave = function (reasonCode, readonly) {
        var _this = this;
        return new Promise(function (success, reject) {
            _this.dialogs.openDialog(ReasonCodeDialogComponent, { reasonCode: reasonCode, readonly: readonly })
                .then(function (result) {
                if (result.result === ModalResult.OK) {
                    _this.save(reasonCode).subscribe(function (saved) { return success(saved); }, function (error) { return reject(error === 'DataIntegrityViolation' ? 'Can\'t create new ReasonCode: \n ReasonCode ' + reasonCode.name + ' already exists!' : error); });
                }
            })
                .catch(function (error) { return reject(error); });
        });
    };
    return ReasonCodeService;
}(BaseEntityService));
export { ReasonCodeService };
