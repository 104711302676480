<!--perfect-scrollbar style="max-height: 90vh;"-->
<div class="widget-split-area">
<div class="row">
  <div class="col-md-8">

    <oms-widget label="Master" [description]="">
      <ng-container widget-header *ngIf="master && !master.isNew()">
        <span style="width: 100%; display: flex; justify-content: center;">
          <h3>
            <a [routerLink]="['/home', 'masters']" [queryParams]="{tab: 'active', search: master.masterAir.mawbNumber}" target="_blank">
              <i class="widget-header-actions-icon fas fa-external-link-alt"></i>
              Show in Masters
            </a>
          </h3>
        </span>
      </ng-container>

      <ng-container widget-toolbar *ngIf="master && !master.isNew()" style="margin: 0">
        <span class="widget-header-actions">
        <a (click)="openBillingDialog()" *ngIf="!master.isNew() && user?.canShowBillInfo"
           [popover]="'Billing Information'" container="body" placement="bottom"
           [ngClass]="{'green': invoices.length}">
          <i class="widget-header-actions-icon fa fa-dollar-sign"></i>
        </a>
        <a (click)="openSubscriptionDialog()"
           [popover]="'Order(s) Notifications'" [container]="'body'">
          <i class="widget-header-actions-icon fa fa-bell" *ngIf="!master.isNew()"></i>
        </a>
        <a (click)="openNoteCenter(5)" [popover]="'OSD'" container="body" placement="bottom">
          <i class="widget-header-actions-icon fa fa-exclamation-triangle" [ngClass]="{'green':master.osdNotes > 0}"></i>
        </a>
        <a (click)="openNoteCenter(4)" [popover]="'Hazardous'" container="body" placement="bottom">
          <i class="widget-header-actions-icon fa fa-exclamation-circle" [ngClass]="{'green':master.hazardousNotes > 0}"></i>
        </a>
        <a (click)="openUldDialog()" [popover]="'ULD'" container="body" placement="bottom">
          <i class="widget-header-actions-icon fa fa-box-open" [ngClass]="{'green':master.uldCount > 0}"></i>
        </a>
        <a (click)="openDocCenter()" [popover]="'Documents'" container="body" placement="bottom">
          <i class="widget-header-actions-icon fa fa-paperclip" [ngClass]="{'green':master.documents > 0}"></i>
        </a>
        <a (click)="openNoteCenter(0)" [popover]="'Comments'" container="body" placement="bottom">
          <i class="widget-header-actions-icon fa fa-comments" [ngClass]="{'green':master.defaultNotes > 0}"></i>
        </a>

        <a (click)="onShowHistory()" [popover]="'Show History Log'" [container]="'body'" placement="bottom">
          <i class="widget-header-actions-icon fas fa-history"></i>
        </a>
      </span>
      </ng-container>

      <div class="widget-content">
        <div class="row">
          <!--Customer Group -->
         <!-- <oms-customer-group-search
            [size]="Size.SMALL"
            [(ngModel)]="customerGroup"
            (changing)="onCustomerGroupChange(master, $event)"
            [label]="'Customer Group'"
            [placeholder]="'select from List or create...'">
          </oms-customer-group-search>-->
          <!--Sub - Customer -->
          <div class="col-md-3">
            <oms-custom-search required label="FF"
                               [disabled]="readonly"
                               [service]="freightForwarderService" [(ngModel)]="freightForwarder"
                               [size]="Size.SMALL"
                               [allowCreate]="true"
                               (create)="onFreightForwarderCreate($event)"
                               (contextmenu) = "onRightClick(master.freightForwarder, $event, columnIds.MASTER_FREIGHT_FORWARDER);">
            </oms-custom-search>

            <!--oms-freight-forwarder-search  required
                                           [disabled]="readonly"
                                  [size]="Size.SMALL"
                                  [(ngModel)]="freightForwarder"
                                  (contextmenu) = "onRightClick(master.freightForwarder, $event, columnIds.MASTER_FREIGHT_FORWARDER);"
                                  (changing)="onCustomerChange(master, $event)"
                                  [label]="'FF'"
                                  [placeholder]="'select from List or create...'">
            </oms-freight-forwarder-search-->
          </div>

          <!--Customer Ref -->
          <div class="col-md-2">
            <oms-string-input [readonly]="readonly"
                              [size]="Size.SMALL" label="FF Ref"
                              [(ngModel)]="master.freightForwarderRef"
                              (contextmenu) = "onRightClick(master.freightForwarderRef, $event, columnIds.MASTER_FREIGHT_FORWARDER_REF);"
                              [maxlength]="_constants.CUSTOMER_REF_LENGTH"></oms-string-input>
          </div>
          <!-- MAWB Status -->
          <div class="col-md-2">
            <oms-custom-search required
              [label]="'MAWB Status'"
                               [disabled]="readonly"
              [size]="Size.SMALL"
              [allowClear]="false"
              [allowCreate]="false"
              (contextmenu) = "onRightClick(master.masterStatus, $event, columnIds.MASTER_STATUS);"
              [(ngModel)]="masterStatus"
              [items]="_statuses.items"
              [autoHeight]="false"
              [autoWidth]="false"
              placeholder="enter name and select&hellip;">
            </oms-custom-search>
          </div>

          <div class="col-md-2">
            <oms-pieces-input label="MHU" required [readonly]="readonly"
                              (contextmenu) = "onRightClick(master.shipment.hu, $event, columnIds.MASTER_HU);"
                              [(ngModel)]="masterHu" [size]="Size.SMALL"></oms-pieces-input>
          </div>

          <div class="col-md-3">
            <oms-airport-search
              [disabled]="readonly"
              [size]="Size.SMALL"
              [departure]="true"
              [(ngModel)]="masterOriginalAirport"
              [label]="'Origin Airport'">
            </oms-airport-search>
          </div>
        </div>

        <div class="row">
          <!-- MAWB -->
          <div class="col-md-2">
            <oms-string-input [size]="Size.SMALL" mawb-number required
                              [readonly]="readonly"
                              header="#"
                              [label]="'MAWB#'"
                              (contextmenu) = "onRightClick(master.masterAir.mawbNumber, $event, columnIds.MASTER_MAWB);"
                              [placeholder]="_constants.mawbPlaceholder" [input-mask]="_constants.mawbMask" [clean-mask]="true"
                              (changing)="changeMawbEvent(master, $event);"
                              [(ngModel)]="master.mawbNumber">
            </oms-string-input>
          </div>
          <!--Airline -->
          <div class="col-md-3">
            <oms-string-input [readonly]="readonly" [size]="Size.SMALL" [label]="'Airline'" [iconClass]="'fa fa-plane'" [disabled]="true"
                              (contextmenu) = "onRightClick(masterAirline?.name, $event, columnIds.MASTER_AIRLINE);"
                              [ngModel]="masterAirline?.name"></oms-string-input>
          </div>
          <!-- Flight -->
          <div class="col-md-1">
            <oms-string-input [readonly]="readonly"
                               [size]="Size.SMALL"
                               [label]="'Flight #'"
                               placeholder="Flight #"
                               (contextmenu) = "onRightClick(masterFlightNumber, $event, columnIds.MASTER_FLIGHT);"
                               [ngModel]="masterFlightNumber" (ngModelChange)="master.flightNumber = $event">

            </oms-string-input>
          </div>
          <!-- Airport -->
          <div class="col-md-2">
            <oms-airport-search
              required=""
              [arrival]="true"
              [disabled]="readonly"
              [size]="Size.SMALL"
              [(ngModel)]="masterAirport"
              [label]="'Destination Airport'">
            </oms-airport-search>
          </div>
          <!-- Cargo Building -->
          <div class="col-md-4">
            <oms-address-search
                                [disabled]="readonly"
                                [size]="Size.SMALL" [iconClass]="'fa fa-industry'"
                                [(address)]="masterCargoBuilding"
                                (contextmenu) = "onRightClick(masterCargoBuilding, $event, columnIds.MASTER_CARGO_BLD);"
                                [label]="pickupLocationLabel"
                                [addressTypes]="[AddressType.CFS_3PL_LOCATION, AddressType.CARGO_BUILDING]"
                                (addressChange)="onCargoBuildingChange($event)">
              <oms-checkbox label="NON-AMS" [(checked)]="nonAMS" class="control-checkbox"></oms-checkbox>
            </oms-address-search>
          </div>
        </div>

        <div class="row">
          <!-- ULD -->
          <div class="col-md-2">
            <oms-uld-input label="ULD" [readonly]="readonly"
                           [(ULDs)]="masterAirUlds"
                           [rootId]="master.id"
                           [objectType]="'Master'"
                           (contextmenu) = "onRightClick(master.uldCount, $event, columnIds.MASTER_HU);"
                           [(ngModel)]="masterUldCount" [size]="Size.SMALL"></oms-uld-input>
          </div>
          <!-- PCS -->
          <div class="col-md-2">
            <oms-pieces-input label="MAWB AMS PCS" required (ngModelChange)="onUpdateMasterPieces()" [readonly]="readonly"
                              (contextmenu) = "onRightClick(masterPieces, $event, columnIds.MASTER_PCS);"
                              [(ngModel)]="masterPieces" [size]="Size.SMALL" [disabled]="master.isMultipleOrders"></oms-pieces-input>
          </div>
          <!-- PCS RCVD -->
          <div class="col-md-2">
            <oms-pieces-input label="MAWB AMS PCS RCVD" [readonly]="readonly"
                              [ngModel]="masterPiecesReceived" [size]="Size.SMALL" [disabled]="true"></oms-pieces-input>
          </div>

          <!-- WGHT -->
          <div class="col-md-2">
            <oms-weight-input label="MAWB WEIGHT" [readonly]="readonly"
                              (contextmenu) = "onRightClick(masterWeight, $event, columnIds.MASTER_WEIGHT);"
                              [forceChangeUnits]="true" [(ngModel)]="masterWeight" [size]="Size.SMALL" [disabled]="master.isMultipleOrders"></oms-weight-input>
          </div>
          <!-- VOLUME -->
          <!--div class="col-md-2">
            <oms-volume-input label="MAWB VOLUME"
                              (contextmenu) = "onRightClick(masterVolume, $event, columnIds.MASTER_VOLUME);"
                              [forceChangeUnits]="true" [(ngModel)]="masterVolume" [size]="Size.SMALL"></oms-volume-input>
          </div-->

          <!-- MASTER RECOVERY TO: CFS Location OR Delivery Location if DIRECT-->
          <div class="col-md-4">
            <oms-address-search [size]="Size.SMALL" [iconClass]="'fa fa-industry'"
                                [(address)]="masterAddressRecoveryTo"
                                (contextmenu) = "onRightClick(master.addressCfs, $event, columnIds.MASTER_DELIVERY_LOCATION);"
                                [addressTypes]="masterRecoveryToAddressTypes"
                                [items]="masterAddressesRecoveryToDefault"
                                [label]="master.direct ? 'Delivery' : 'CFS Location'"
                                placeholder="select from List or create...">
              <oms-checkbox label="DIRECT" [(checked)]="direct" class="control-checkbox"></oms-checkbox>
            </oms-address-search>
          </div>

        </div>


<!--
        <div class="row">
          <div class="col-md-3">
            <oms-date-input [size]="Size.SMALL" label="Arrival Date"
                            [show-checkbox]="false" [show-switch]="false"
                            (ngModelChange)="onArrivalDateChange(master, $event)"
                            [estimation]="true"
                            [(ngModel)]="master.dateArrival" [(estimated)] = "master.dateArrivalEst"></oms-date-input>
          </div>
          <div class="col-md-3">
            <oms-date-input [size]="Size.SMALL" [time]="false" label="Last Free Day" [show-switch]="false" [show-checkbox]="false" [(ngModel)]="master.dateLastFreeDay"></oms-date-input>
          </div>

          <div class="col-md-3">
            <oms-date-input [size]="Size.SMALL" label="MAWB Pick Up" [show-switch]="false" [show-checkbox]="false"
                            [(ngModel)]="masterPickupDate" [estimation]="true" [(estimated)]="masterPickupDateEstimated">

            </oms-date-input>
          </div>

          <div class="col-md-3">
            <oms-date-input [size]="Size.SMALL" label="MAWB Delivery" [show-switch]="false" [show-checkbox]="false"
                            [(ngModel)]="masterDeliveryDate" [estimation]="true" [(estimated)]="masterDeliveryDateEstimated">
            </oms-date-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <oms-date-input [size]="Size.SMALL" label="1F" [show-switch]="true" [show-checkbox]="false" [(ngModel)]="master.date1F"></oms-date-input>
          </div>
          <div class="col-md-4">
            <oms-date-input [size]="Size.SMALL" label="ISC Paid" [show-switch]="true" [show-checkbox]="false" [(ngModel)]="master.dateISCPaid"></oms-date-input>
          </div>
        </div>

-->
      </div>
    </oms-widget>

  </div>

  <div class="col-md-4">
    <oms-widget label="Dates" iconClass="fa fa-calendar">
      <div class="row">
        <!-- Created date -->
        <div class="col-md-4">
          <oms-date-input [disabled]="true"
                          [size]="Size.SMALL" label="Created Date"
                          [show-checkbox]="false" [show-switch]="false"
                          [(ngModel)]="master.dateCreated"></oms-date-input>
        </div>
        <!-- Arrival date -->
        <div class="col-md-4">
          <oms-date-input [disabled]="readonly" [size]="Size.SMALL" label="Arrival Date"
                          [show-checkbox]="false" [show-switch]="false"
                          [estimation]="true"
                          [(ngModel)]="masterDateArrivalAct"
                          (ngModelChange)="resetLoads()"
                          (contextmenu) = "onRightClick(masterDateArrivalAct, $event, columnIds.MASTER_ARRIVAL_DATE);"
                          [(estimated)] = "masterDateArrivalEst"></oms-date-input>
        </div>
        <!-- Last Free Day -->
        <div class="col-md-4">
          <oms-date-input [disabled]="readonly" [size]="Size.SMALL" [time]="false" label="Last Free Day"
                          [show-switch]="false"
                          [show-checkbox]="false"
                          (contextmenu) = "onRightClick(master.dateLastFreeDay, $event, columnIds.MASTER_LFD);"
                          [cleanable]="lfdCleanable"
                          [disabled]="lfdDisabled"
                          [minDate]="lfdMinDate"
                          [ngModel]="master.dateLastFreeDay"></oms-date-input>
        </div>
      </div>
      <div class="row">
        <!-- Pickup Date -->
        <div class="col-md-4">
          <oms-date-input [disabled]="readonly" [size]="Size.SMALL" label="MAWB Pick Up" [show-switch]="false" [show-checkbox]="false"
                          [(ngModel)]="masterPickupDate"
                          (contextmenu) = "onRightClick(master.datePickupActual, $event, columnIds.MASTER_PICKUP_DATE);"
                          (changing)="onMawbActualPickupChange($event)"
                          (estimatedChange)="onMawbEstimatedPickupChange($event)"
                          [estimation]="true" [(estimated)]="masterPickupDateEstimated">

          </oms-date-input>
        </div>

        <!-- CFS In Date -->
        <div class="col-md-4">
          <oms-date-input [disabled]="cfsDisabled" [size]="Size.SMALL"
                          (contextmenu) = "onRightClick(master.dateCfsInAct, $event, columnIds.MASTER_DATE_CFS_IN);"
                          label="CFS In" [show-switch]="false" [show-checkbox]="false" [(ngModel)]="dateCfsInAct">
          </oms-date-input>
        </div>
        <!-- CFS Out -->
        <div class="col-md-4">
          <oms-date-input [disabled]="cfsDisabled"
                          [size]="Size.SMALL"
                          label="CFS Out"
                          (contextmenu) = "onRightClick(master.dateCfsOutAct, $event, columnIds.MASTER_DATE_CFS_OUT);"
                          [show-switch]="false"
                          [show-checkbox]="false"
                          [(ngModel)]="dateCfsOutAct"></oms-date-input>
        </div>
      </div>
      <div class="row">
        <!-- Delivery Date -->
        <div class="col-md-6">
          <oms-date-input [disabled]="isDeliveryDateDisabled()"
                          ident="Delivery"
                          [size]="Size.SMALL"
                          label="MAWB Delivery"
                          [show-switch]="false"
                          [show-checkbox]="true"
                          [(ngModel)]="masterDeliveryDate"
                          (changing)="onMawbActualDeliveryChange($event)"
                          (contextmenu) = "onRightClick(master.dateDeliveryActual, $event, columnIds.MASTER_DELIVERY_DATE);"
                          (estimatedChange)="onMawbEstimatedDeliveryChange($event)"
                          [estimation]="true"
                          [(estimated)]="masterDeliveryDateEstimated">
          </oms-date-input>
        </div>

        <!-- Date 1F -->
        <div class="col-md-6">
          <oms-date-input [disabled]="nonAMS" [size]="Size.SMALL" label="1F"
                          (contextmenu) = "onRightClick(master.date1F, $event, columnIds.MASTER_1F);"
                          [show-switch]="false" [show-checkbox]="true" [(ngModel)]="masterDate1F"></oms-date-input>
        </div>

        <!-- ISC Paid -->
        <div class="col-md-6">
          <oms-date-input [disabled]="readonly" [size]="Size.SMALL" label="ISC Paid"
                          (contextmenu) = "onRightClick(master.dateISCPaid, $event, columnIds.MASTER_ISC);"
                          [show-switch]="false" [show-checkbox]="true" [(ngModel)]="master.dateISCPaid"></oms-date-input>
        </div>

        <!-- Date Billed -->
        <div class="col-md-6">
          <oms-date-input [size]="Size.SMALL" label="Billed"
                          (contextmenu) = "onRightClick(master.dateBilled, $event, columnIds.MASTER_DATE_BILLED);"
                          [show-switch]="false" [show-checkbox]="true" [(ngModel)]="master.dateBilled"></oms-date-input>
        </div>

      </div>
    </oms-widget>
  </div>


</div>

<div class="row">
  <div class="col-md-12">
    <div class="widget">

      <div class="widget-content">
        <!-- LOADS PANEL-->
        <oms-widget [collapsible]="true" [collapsed]="true" label="Recovery Loads" iconClass="fa fa-truck" [no-padding]="true">
          <span widget-toolbar>
            <div class="control-inline toolbar-item-group" style="padding-top: 5px; color: #868686">
              <label class="control-label" style="cursor: pointer "><i class="fa fa-truck"></i>&nbsp;By Load
                <div class="control-inline onoffswitch" >
                  <input name="onoffswitch" class="onoffswitch-checkbox" id="switch" [(ngModel)]="partsByLoad" type="checkbox">
                  <label class="onoffswitch-label" for="switch">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </label>
            </div>
          </span>

          <div class="row">
            <!-- Splits -->
            <oms-widget class="col-xs-7" [no-padding]="true" [showHeader]="false">
              <oms-data-table [hover]="false" [fixed]="false" [selected]="selectedLoads"
                              [searchable]="false" (update)="onUpdateLoadField($event)"
                              [checkboxes]="true"
                              [data]="recoveryLoads" [row-class]="getRldRowStatusClass.bind(this)"
                              [columns]="recoveryLoadColumns | loadColumnsFilter: cfsDisabled"
                              [selectable]="true" [condensed]="true" [multi-select]="true">
              </oms-data-table>
            </oms-widget>

            <!--Load Contents per Order-->
            <!--oms-widget class="col-xs-5" [no-padding]="true" [showHeader]="false">
                  <oms-data-table [fixed]="false"  [selected]="selectedParts"  [searchable]="false" (update)="onUpdatePartField($event)"
                                  [data]="orderParts | partsBySelectedLoad: partsByLoad : selectedLoads" [columns]="orderPartsColumns" [selectable]="true" [condensed]="true" [multi-select]="true">
                  </oms-data-table>
            </oms-widget-->
          </div>
        </oms-widget>

        <!-- ORDERS PANEL -->
        <oms-widget [collapsible]="true" [collapsed]="false" label="Orders" iconClass="fa fa-edit" [no-padding]="true">
          <span widget-toolbar>
            <button [disabled]="isDisabledSplitOrder()" class="btn btn-sm btn-default" (click)="splitOrder()"><i class="fa fa-clone"></i>Split...</button>
            <button class="btn btn-sm btn-default" (click)="addOrder()"><i class="fa fa-plus"></i>Add Order</button>
            <button *ngIf="isDeleteAvailable" [disabled]="!allowDeleteOrder" class="btn btn-sm btn-default" (click)="deleteOrder()"><i class="fa fa-trash"></i>Delete Selected</button>
          </span>
          <oms-data-table #ordersTable [condensed]="true" [multi-select]="true" (update)="onOrderFieldUpdate($event)" [checkboxes]="true"
                          [searchable]="false" [data]="masterOrders" [selected]="selectedOrders"
                          [columns]="masterOrdersColumns | orderColumnsFilter: nonAMS" [selectable]="true" [debug]="true">
          </oms-data-table>
        </oms-widget>

      </div>
    </div>
  </div>
</div>
</div>
<!--/perfect-scrollbar-->
