import { Pipe, PipeTransform } from '@angular/core';
import {NoteType} from "../common/oms-unit-types";
import {isNullOrUndefined} from "util";
import {ObjectComCenter} from "../modules/shared/models/comcenter/object.com.center";

@Pipe({name: 'noteCenterTypeFilterPype', pure: false})
export class NoteCenterTypeFilterPype implements PipeTransform {
  constructor(public noteType: NoteType) {}
  transform(items: ObjectComCenter[], noteTypeId: number): any {
    let label = this.getLabelArray(noteTypeId);
    return isNullOrUndefined(items) ? [] : items.filter(item => label.indexOf(item.comCenter.noteType) !== -1);
  }

  getLabelArray(noteTypeId) {
    switch (noteTypeId) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        return [this.noteType.get(noteTypeId).label];
      case 5:
        return [this.noteType.get(1).label, this.noteType.get(2).label, this.noteType.get(3).label];

    }
  }
}
