var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BaseInputComponent } from "../../../base/base-input/base-input.component";
var MaxCountInputComponent = /** @class */ (function (_super) {
    __extends(MaxCountInputComponent, _super);
    function MaxCountInputComponent(validators, asyncValidators) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.changingEvent = new EventEmitter();
        return _this;
    }
    MaxCountInputComponent.prototype.onKeyUp = function (event) {
        this.changingEvent.emit(event);
    };
    Object.defineProperty(MaxCountInputComponent.prototype, "invalidPublic", {
        get: function () {
            return this.invalid;
        },
        enumerable: true,
        configurable: true
    });
    MaxCountInputComponent.prototype.ngOnInit = function () {
        /*    if (this.inputMask) {
              setTimeout(() => {
                this.maskedInputController = textMask.maskInput({
                  inputElement: this.input.element.nativeElement,
                  mask: this.inputMask,
                  placeholderChar: this.placeholderChar,
                });
              });
            } */
    };
    MaxCountInputComponent.prototype.ngOnDestroy = function () {
        /*    if (this.maskedInputController)
              this.maskedInputController.destroy(); */
    };
    return MaxCountInputComponent;
}(BaseInputComponent));
export { MaxCountInputComponent };
