<!-- Customer -->

<div class="row">
  <div class="col-xs-6">
    <oms-custom-search [multiple] = "true" [searchable]="true" [service]="customerService" label="Customer"
                       [(ngModel)]="customer" [sort-by]="'name'" (changing)="onCustomerChange()">
      <ng-container group-before>
        <!--mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon-->
        <i class="fa fa-user"></i>
      </ng-container>
    </oms-custom-search>
  </div>

  <div class="col-xs-6">
<!--    Delivery Location
    <ng-select [multiple]="true" [searchable]="true" [items]="selected$ | async" [(ngModel)]="selected" (keydown)="onKeyPress($event)"></ng-select>
-->
    <oms-string-input label="Delivery Location" [(ngModel)]="deliveryLocation" (changing)="onDeliveryLocationChange($event)"></oms-string-input>
  </div>

</div>

<!-- Freight Forwarder -->
<div class="row">
  <div class="col-xs-6">
    <oms-custom-search  [multiple] = "true" [searchable]="true" [service]="freightForwarderService" label="F.Forwarder"
                       [(ngModel)]="freightForwarder" [sort-by]="'name'" (changing)="onFreightForwarderChange()">
      <ng-container group-before>
        <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
      </ng-container>
    </oms-custom-search>
  </div>

  <div class="col-xs-6">
    <oms-custom-search [multiple] = "true" [searchable]="true" [service]="freightForwarderGroupService" label="F.Forwarder Group"
                       [(ngModel)]="freightForwarderGroup" [sort-by]="'name'" (changing)="onFreightForwarderGroupChange()">
      <ng-container group-before>
        <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
      </ng-container>
    </oms-custom-search>

  </div>
</div>

<div class="row">
  <div class="col-xs-5">
    <oms-custom-search  [multiple] = "true" [searchable]="false" [items]="allOrderModes" label="Order Modes"
                        [(ngModel)]="orderModes" [sort-by]="'id'" (changing)="onOrderModeChange()">
      <ng-container group-before>
        <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
      </ng-container>
    </oms-custom-search>
  </div>


  <div class="col-xs-4">
    <oms-custom-search [multiple] = "true" [searchable]="false" [items]="allLoadTypes" label="Load Types"
                       [(ngModel)]="loadTypes" [sort-by]="'id'" (changing)="onLoadTypeChange()">
      <ng-container group-before>
        <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
      </ng-container>
    </oms-custom-search>
  </div>

  <div class="col-xs-3">
      <oms-custom-search [(ngModel)]="billed" [inline]="false" [multiple]="false" [label]="'Billed'"
               [items]="[filterYes, filterNo]">
      </oms-custom-search>
  </div>
</div>

<hr/>

<div class="row">
  <div class="col-md-6 no-padding">
    <oms-checkbox style="display: block;" label="Active Routes Only" [(checked)]="activeOnly"></oms-checkbox>
    <oms-checkbox style="display: block;" label="Exclude Recovery loads" [(checked)]="noRecovery"></oms-checkbox>
    <oms-checkbox style="display: block;" label="Exclude records not updated since 60 days rolling" [(checked)]="noArchived60"></oms-checkbox>
  </div>
  <div class="col-md-6 no-padding">
    <oms-checkbox style="display: block;" label="Dispatched or Updated since Previous Report" [(checked)]="updatedSincePrevious"></oms-checkbox>
    <oms-checkbox style="display: block;" label="Dispatched or Updated in Current Year (YTD)" [(checked)]="updatedYtd"></oms-checkbox>
    <oms-checkbox style="display: block;" label="Delivered since Previous Report" [(checked)]="deliveredSincePrevious"></oms-checkbox>
    <oms-checkbox style="display: block;" label="Delivered in Current Year (YTD)" [(checked)]="deliveredYtd"></oms-checkbox>
  </div>
</div>

  <!--div class="row">
  </div>
  <div class="row">
  </div>
  <div class="row">
  </div>
  <div class="row">
  </div>
  <div class="row">
  </div>
  <div class="row">
  </div>
  <div class="row">
  </div-->

  <!--div class="row">
    <div class="col-xs-4">
      <oms-checkbox label="Exclude Recovery loads" [(checked)]="noRecovery"></oms-checkbox>
    </div>
    <div class="col-xs-4">
      <oms-checkbox label="Exclude records not updated since 60 days rolling" [(checked)]="noArchived60"></oms-checkbox>
    </div>
    <div class="col-xs-4">
      <oms-checkbox label="Dispatched or Updated since Previous Report" [(checked)]="sincePrevious"></oms-checkbox>
    </div>
  </div-->

<!-- TEST-->
<!--
TEST
<oms-custom-search *ifRole="'ROLE_QA'"
  [searchable]="true"
  [label]="testFilter.label"
  [bindLabel]="undefined"
  [multiple]="testFilter.multiple"
  [items]="testFilter.items$ | async"
  [customSearch] = "true"
  (searching)="testFilter.onSearch($event)"

  [(ngModel)]="testFilter.value" (changing)="testFilter.onSelect($event)">

</oms-custom-search>
-->
