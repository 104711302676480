import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {TruckDialogComponent} from '../truck-dialog/truck-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {StringInputComponent} from '../../../modules/shared/components/common/input/string-input/string-input.component';
import {CfsLocation} from '../../../modules/shared/models/address/cfs-location';
import {isEmptyString} from '../../../_helpers/utils';

@Component({
  selector: 'app-driver-dialog',
  templateUrl: './cfs-location-dialog.component.html',
  styleUrls: ['./cfs-location-dialog.component.scss']
})
export class CfsLocationDialogComponent implements OnInit, AfterViewInit {

  public cfsLocation: CfsLocation;
  hasFormErrors: boolean = false;
  viewLoading: boolean = false;
  loadingAfterSubmit: boolean = false;
  public readonly: boolean = false;
  @ViewChildren(StringInputComponent) inputs: QueryList<StringInputComponent>;

  constructor(
    public dialogRef: MatDialogRef<TruckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.readonly = data.readonly;
  }

  onOk(event) {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;

    if (isEmptyString(this.cfsLocation.name) || this.cfsLocation.name.length>30) {
      this.hasFormErrors = true;
      event.canClose = false;
    }
  }

  ngOnInit(): void {
    this.cfsLocation = this.data.location;
  }

  ngAfterViewInit(): void {
  }
}
