import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AbstractComponent } from "../../../../../common/component/abstract.component";
import { MasterStatus, MasterStatusId, Order } from "../../../../shared/models";
import { OrderSubscriptionService } from "../../../../../services/order-subscription/order-subscription.service";
import { OrderSubscription } from "../../../../shared/models/order/order-subscription";
import { OmsAlertsService } from "../../../../shared/components/oms-alerts/oms-alerts.service";
import { forkJoin } from "rxjs";
import { OrderSubscriptionStatus } from "../../../../../services/order-subscription/order-subscription-status";
import { isNullOrUndefined } from "util";
import { OrderModes } from "../../../../shared/models/order/order-mode";
import { ArrayUtils } from "../../../../../_helpers/array.utils";
import {StringUtil} from "../../../../../_helpers/string.util";
import {openMailTo} from "../../../../../_helpers/utils";
import {OmsDialogsService} from "../../../../../components/common/oms-dialogs";

@Component({
  selector: "oms-order-subscription-dialog",
  templateUrl: "order-subscription-dialog.component.html",
  styleUrls: ["./order-subscription-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderSubscriptionDialogComponent extends AbstractComponent implements OnInit {

  statusesColumns: (MasterStatus & CheckboxModel)[][] = [];
  addStatuses: (OrderSubscriptionStatus & CheckboxModel)[] = [];

  private orderSubscriptions: OrderSubscription[] = [];
  public customSubscriptions: OrderSubscription[] = [];

  comment: string = "";
  customEmail: string = "";
  needCustomEmail: boolean = false;
  isPublic: boolean = false;
  placeholder: string = "";

  viewLoading: boolean = false;

  constructor(private dialogRef: MatDialogRef<OrderSubscriptionDialogComponent, MasterStatus[]>,
              @Inject(MAT_DIALOG_DATA) public data: OrderSubscriptionDialogInput,
              private cdr: ChangeDetectorRef,
              private orderSubscriptionService: OrderSubscriptionService,
              private dialogs: OmsDialogsService,
              private alerts: OmsAlertsService) {
    super();
  }

  private getAiStatuses(): MasterStatus[] {
    return MasterStatus.items.filter(s => s.id !== MasterStatusId.CLOSED);
  }

  private getNonAiStatuses(): MasterStatus[] {
    return [
      MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH,
      MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY,
      MasterStatusId.PROBLEM,
      MasterStatusId.PICKED_UP_FROM_JJS,
      MasterStatusId.DELIVERED_PENDING_POD,
      MasterStatusId.ORDER_COMPLETE_READY_TO_BILL
    ].map(id => MasterStatus.get(id));
  }

  ngOnInit() {
    this.needCustomEmail = this.data.needCustomEmail;
    this.isPublic = this.data.isPublic || false;
    this.load();
  }

  private load() {
    this.viewLoading = true;
    this.statusesColumns = [];
    this.addStatuses = [];

    forkJoin([
      this.orderSubscriptionService.findByOrderIds(this.data.orders.map((o) => o.id)),
      this.orderSubscriptionService.findCustomSubscriptionsByOrderIds(this.data.orders.map((o) => o.id)),
      this.orderSubscriptionService.getAvailableStatuses()
    ])
      .subscribe((responses) => {
        this.orderSubscriptions = responses[0];
        this.customSubscriptions = responses[1];
        this.initStatuses(responses[2]);
        this.initNameField();

        console.log('SUBSCRIPTIONS', this.orderSubscriptions);

        this.getAllStatuses().forEach(status => {
          const originalStatus = MasterStatus.items.find((s) => s.id === status.id);
          if (this.orderSubscriptions.length === this.data.orders.length
            && this.orderSubscriptions.every((os) => os.statuses.includes(originalStatus))) {
            status.selected = true;
          } else if (this.orderSubscriptions.every((os) => !os.statuses.includes(originalStatus))) {
            status.selected = false;
          }
        });
        this.addStatuses.forEach((status) => {
          if (this.orderSubscriptions.length === this.data.orders.length
            && this.orderSubscriptions.every((os) => os.additionalStatuses.some((as) => as.id === status.id))) {
            status.selected = true;
          } else if (this.orderSubscriptions.every((os) => !os.additionalStatuses.some((as) => as.id === status.id))) {
            status.selected = false;
          }
        });
        this.viewLoading = false;
        this.cdr.markForCheck();
      }, (error) => {
        this.viewLoading = false;
      });
  }

  private initStatuses(orderSubscriptionStatuses: OrderSubscriptionStatus[]): void {
    const hasAiOrder = this.data.orders.some(o => OrderModes.isAirImport(o.genericMode));
    const statuses = hasAiOrder ? this.getAiStatuses() : this.getNonAiStatuses();
    let chunk = Math.ceil(statuses.length / 3);
    for (let i = 0; i < 3; i++) {
      const columns = statuses.slice(i * chunk, (i + 1) * chunk).map(s => MasterStatus.clone(s));
      this.statusesColumns.push(columns);
    }
    if (hasAiOrder) {
      this.addStatuses = orderSubscriptionStatuses;
    }
  }

  private initNameField(): void {
    const hasComment = this.orderSubscriptions.some(os => !!os.comment);
    const comments = this.orderSubscriptions.map(os => os.comment).filter(c => !!c);
    const hasDifferentComment = ArrayUtils.removeDuplicate(comments, c => c).length > 1;
    if ((this.data.orders.length !== this.orderSubscriptions.length && hasComment) || hasDifferentComment) {
      this.placeholder = 'Various';
      return;
    }
    if (comments.length) {
      this.comment = comments[0];
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSave() {
    this.viewLoading = true;
    let ors = this.data.orders.map(order => {
      let orderSubscription = this.orderSubscriptions.find(os => os.order.id === order.id);
      if (!orderSubscription) {
        orderSubscription = {order: order, statuses: []} as OrderSubscription;
      }
      const indeterminateStatuses = this.getIndeterminateStatus();
      const statusIds = orderSubscription.statuses.map(s => s.id);
      orderSubscription.statuses = this.getAvailableStatusByOrder(order, [
        ...this.getSelectedStatus(),
        ...indeterminateStatuses.filter(indeterminateStatus => statusIds.includes(indeterminateStatus.id))
      ]);
      orderSubscription.additionalStatuses = this.addStatuses.filter(s => s.selected);
      if (this.comment) {
        orderSubscription.comment = this.comment;
      }
      return orderSubscription;
    }) as OrderSubscription[];

    let emails = StringUtil.split(this.customEmail).concat(this.customSubscriptions.map(os => os.customEmail)).filter(s => !!s).unique().join(", ");
    console.log('Custom Emails', emails);
    this.orderSubscriptionService.saveOrderSubscription(ors, emails)
      .subscribe(
      (a) => {
        this.viewLoading = false;
        this.alerts.info("Flag added", 20000);
        this.dialogRef.close(this.getAllStatuses());
      },
      (error) => {
        this.viewLoading = false;
        this.alerts.error("Error on subscribe", error);
      });
  }

  private getAvailableStatusByOrder(order: Order, statuses: MasterStatus[]): MasterStatus[] {
    if (OrderModes.isAirImport(order.genericMode)) {
      return statuses;
    }
    const listIds = this.getNonAiStatuses().map(s => s.id);
    return statuses.filter(s => listIds.includes(s.id));
  }

  isSelectAll(): boolean {
    return this.getAllStatuses().length === this.getSelectedStatus().length
      && this.addStatuses.every((s) => s.selected);
  }

  isSelectStatus(status: CheckboxModel): boolean {
    return status.selected;
  }

  isIndeterminateStatus(status: CheckboxModel): boolean {
    return isNullOrUndefined(status.selected);
  }

  isIndeterminateSelectAll(): boolean {
    const isSelectAll = this.isSelectAll();
    const isNoSelectAll = this.getAllStatuses().every(s => !s.selected) && this.addStatuses.every(s => !s.selected);
    return !isSelectAll && !isNoSelectAll;
  }

  onSelectAll(): void {
    const isSelectAll = this.isSelectAll();
    this.getAllStatuses().forEach(s => s.selected = !isSelectAll);
    this.addStatuses.forEach(s => s.selected = !isSelectAll);
  }

  onStatusChange(value: boolean, status: CheckboxModel): void {
    status.selected = value;
  }

  getIndeterminateStatus(): (MasterStatus & CheckboxModel)[] {
    return this.getAllStatuses().filter((s) => this.isIndeterminateStatus(s));
  }

  getSelectedStatus(): (MasterStatus & CheckboxModel)[] {
    return this.getAllStatuses().filter((s) => s.selected);
  }

  getStatusLabel(status: MasterStatus): string {
    if (status.id === MasterStatusId.DELIVERED_PENDING_POD) {
      return "3.b. Delivered, pending POD";
    } else if (status.id === MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH) {
      return "2.c. On Hand, Ready for Dispatch";
    }
    return status.label;
  }

  private getAllStatuses(): (MasterStatus & CheckboxModel)[] {
    return this.statusesColumns.reduce((total, column) => total.concat(column), []);
  }

  public canSave(): boolean {
    return (!this.needCustomEmail || this.customEmail) &&
      (this.getSelectedStatus().length > 0 || this.getIndeterminateStatus().length > 0);
  }

  public removeRecipient(rec) {
    this.customSubscriptions.removeAll(rec);
  }

  public sendEmail(rec?: OrderSubscription) {
    let subject: string = 'REF: ' + this.orderSubscriptions
      .map((os) => os.order)
      .map((o) => o.customerRef || o.hawb)
      .join(', ');
    let to = rec ? rec.customEmail : this.customSubscriptions.map(cs => cs.customEmail).join(', ');

/*    let text;
    let subscription = rec || this.customSubscriptions.first();
    if (subscription && subscription.order && subscription.order.freightForwarder && subscription.order.freightForwarder.freightForwarderGroup) {
      let url = buildCfsStatusPageUrl(subscription.order.freightForwarder.freightForwarderGroup.id, subscription.order.id);
      text = 'Track the progress of your shipment here:%0D%0A' + url;
    }

 */
    openMailTo(to, subject, []);
  }

  public addCustomEmails() {
    if (this.customEmail) {
      let ids = this.data.orders.map((o) => o.id);

      this.viewLoading = true;
      console.log("Adding Email", this.customEmail);
      this.orderSubscriptionService.addOrderCustomSubscriptions(ids, this.customEmail)
        .subscribe( (subscriptions) => {
          let added: string[] = subscriptions.map((s) => s.customEmail);
          if (added.length > 0) {
            this.dialogs.openInfoDialog(`${added.length} Email(s) successfully subscribed:\n` + added.join("\n")).then();
          }
//          this.customSubscriptions = subscriptions;
          this.customEmail = '';
          this.viewLoading = false;
          this.load();
//          this.cdr.markForCheck();
        }, (error) => {
          console.error('ERROR subscribe', error);
          this.viewLoading = false;
          this.dialogs.openErrorDialog("Server Internal Error", "Error subscribe emails").then();
          this.cdr.markForCheck();
        });
    }
  }
}

export interface OrderSubscriptionDialogInput {
  orders: Order[];
  needCustomEmail?: boolean;
  isPublic?: boolean;
}

interface CheckboxModel {
  selected?: boolean;
}
