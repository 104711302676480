import { OnInit } from '@angular/core';
import { FilterPageRequest } from "../../../../../modules/shared/models/filter.page.request";
import { MasterStatus } from "../../../../../modules/shared/models";
import { BaseColumn } from "../../../../../modules/shared/components/base/data-table/columns/column-types";
import { isNullOrUndefined } from 'util';
import { CustomerService } from "../../../../../services/customer.service";
import { FreightForwarderService } from "../../../../../services/freight.forwarder.service";
var MastersReportFiltersComponent = /** @class */ (function () {
    function MastersReportFiltersComponent(customerService, freightForwarderService) {
        this.customerService = customerService;
        this.freightForwarderService = freightForwarderService;
        this.filterOptions = [];
        this.freightForwarder = [];
        this.filterYes = { id: 1, label: 'Yes' };
        this.filterNo = { id: 0, label: 'No' };
        this.filterOptions = [
            { label: "Master Status",
                field: '9',
                search: {
                    searchable: true,
                    searchType: "list",
                    items: MasterStatus.items,
                    multiple: true,
                }
            },
            { label: "Non AMS",
                field: "nonAMS",
                search: {
                    searchable: true,
                    searchFunction: "isSelectedValue",
                    searchType: "list",
                    multiple: false,
                    items: BaseColumn.YES_NO_FILTERS
                }
            },
            { label: "CFS Location",
                field: "cfsLocation",
                search: {
                    searchable: true,
                    searchFunction: "isSelectedValue",
                    searchType: "text",
                    multiple: true,
                }
            },
        ];
    }
    MastersReportFiltersComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('Loaded', this.filters);
        if (this.filters) {
            this.filters.filterByColumns.forEach(function (filter, index, array) {
                if (filter) {
                    if (filter.field === 'customerId') {
                        _this.customerService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (customer) { return _this.customer = customer; });
                    }
                    else if (filter.field === 'freightForwarderId') {
                        if (filter.multipleValues) {
                            _this.freightForwarderService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (ff) { return _this.freightForwarder = ff; });
                        }
                    }
                    else {
                        // not found or deprecated filter - to remove
                        //            array.splice(index, 1);
                    }
                }
            });
            var _loop_1 = function (options) {
                var filter = this_1.filters.getColumnFilter(options.field);
                if (filter) {
                    //          console.log('Found Filter', options.field, '->', filter);
                    if (!isNullOrUndefined(filter.value)) {
                        options.search.search = filter.value;
                        if (options.search.items) {
                            options.search.selected = options.search.items.find(function (item) { return item.id === ~~filter.value; });
                        }
                    }
                    else if (filter.multipleValues) {
                        if (options.search.items) {
                            options.search.selected =
                                filter.multipleValues.map(function (id) { return options.search.items.find(function (item) { return item.id === ~~id; }); });
                        }
                    }
                }
            };
            var this_1 = this;
            //      console.log('FILTER OPTIONS', this.filterOptions, 'FILTERS', this.filters);
            for (var _i = 0, _a = this.filterOptions; _i < _a.length; _i++) {
                var options = _a[_i];
                _loop_1(options);
            }
        }
    };
    Object.defineProperty(MastersReportFiltersComponent.prototype, "updatedYtd", {
        get: function () {
            return this.filters.hasNamedCondition("UPDATED_YTD");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("UPDATED_YTD");
            }
            else {
                this.filters.removeNamedCondition("UPDATED_YTD");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MastersReportFiltersComponent.prototype, "mastersOnly", {
        get: function () {
            return this.filters.hasNamedCondition("MASTERS_ONLY");
        },
        set: function (value) {
            if (value) {
                this.filters.addNamedCondition("MASTERS_ONLY");
            }
            else {
                this.filters.removeNamedCondition("MASTERS_ONLY");
            }
        },
        enumerable: true,
        configurable: true
    });
    MastersReportFiltersComponent.prototype.onCustomerChange = function () {
        if (this.customer && this.customer.length) {
            this.filters.addColumnFilter('customerId', this.customer.map(function (c) { return c.id; }));
        }
        else {
            this.filters.removeColumnFilter('customerId');
        }
    };
    MastersReportFiltersComponent.prototype.onFreightForwarderChange = function () {
        if (this.freightForwarder && this.freightForwarder.length) {
            this.filters.addColumnFilter('freightForwarderId', this.freightForwarder.map(function (ff) { return ff.id; }));
        }
        else {
            this.filters.removeColumnFilter('freightForwarderId');
        }
        console.log('>>>', this.filters);
    };
    Object.defineProperty(MastersReportFiltersComponent.prototype, "mastersBilled", {
        get: function () {
            if (this.filters.hasNamedCondition('BILLED')) {
                return this.filterYes;
            }
            if (this.filters.hasNamedCondition('NOT_BILLED')) {
                return this.filterNo;
            }
            return undefined;
        },
        set: function (value) {
            this.filters.removeNamedConditions('NOT_BILLED', 'BILLED');
            switch (value && value.id) {
                case 0:
                    this.filters.addNamedCondition('NOT_BILLED');
                    break;
                case 1:
                    this.filters.addNamedCondition('BILLED');
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    return MastersReportFiltersComponent;
}());
export { MastersReportFiltersComponent };
