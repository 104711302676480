<!--div class="manifest-container"-->
<oms-spinner [show]="loading">
  <div class="widget widget-flex no-border no-margin" style="display: flex; flex-direction: column; height: 100%;">
      <div class="widget-header double-height">
        <h3><i class="svg svg-check-list"></i>Manifest {{manifestNumber}}</h3>


        <div class="oms-modal btn-group widget-header-toolbar" *ngIf="canClose">
          <a class="btn-borderless btn-remove" (click)="clearDispatch()">
            <i class="fa fa-times dialog-header-button"></i>
          </a>
        </div>

        <div class="btn-group widget-header-toolbar">
          <button type="button" class="btn btn-primary btn-sm btn-ajax"
                  [disabled]="isLoadingSave"
                  (click)="saveAndCloseDispatch(false)">
            <i *ngIf="isLoadingSave" class="fa fa-spinner fa-spin"></i>
            <i *ngIf="!isLoadingSave" class="fa fa-truck"></i>
            <span>Save {{isLoadingSave?'...':''}}</span>
          </button>
          <button type="button" class="btn btn-primary btn-sm btn-ajax"
                  [disabled]="isLoadingSave"
                  (click)="saveAndCloseDispatch(true)">
            <i *ngIf="isLoadingSave" class="fa fa-spinner fa-spin"></i>
            <i *ngIf="!isLoadingSave" class="fa fa-truck"></i>
            <span>Save/Close {{isLoadingSave?'...':''}}</span>
          </button>
        </div>

        <div class="btn-group widget-header-toolbar">
          <div class="col-md-6">
            <div class="vertical-checkbox">
              <span class="control-label">EMail</span>
              <oms-toggle-switch  [showLabel]="false" label="Email" class="danger" [(ngModel)]="isSendEmail"></oms-toggle-switch>
            </div>
          </div>
          <div class="col-md-6">
            <div class="vertical-checkbox">
              <span class="control-label">Staging</span>
              <oms-toggle-switch [showLabel]="false" label="Staging" type="yes-no" class="glowing-lime"
                                 [(ngModel)]="isStaging"></oms-toggle-switch>
            </div>
          </div>
        </div>

        <div class="btn-group widget-header-toolbar left">

          <button type="button" class="btn btn-sm btn-danger" *ngIf="isDisplayDelete"
                  [disabled]="isLoadingDelete"
                  (click)="removeDispatch()">
            <i *ngIf="!isLoadingDelete" class="fa fa-trash"></i>
            <i *ngIf="isLoadingDelete" class="fa fa-spinner fa-spin"></i>
            <span>Delete&hellip;</span>
          </button>

          <button type="button" class="btn btn-sm btn-primary" *ngIf="allowAddShipments && isDisplayAddShipments"
                  (click)="addShipments()">
            <i class="fa fa-plus"></i>
            <span>Add&hellip;</span>
          </button>

<!--
          <button type="button" class="btn btn-sm" (click)="clearDispatch()">
            <i class="fa fa-ban"></i><span>Close</span>
          </button>

          <button type="button" class="btn btn-sm" (click)="consolidate()">
            <i class="fa fa-object-group"></i><span>Consol</span>
          </button>

          <button type="button" class="btn btn-sm" (click)="unconsolidate()">
            <i class="fa fa-object-ungroup"></i><span>Deconsol</span>
          </button>

          <button type="button" class="btn btn-sm" (click)="splitOrder(selectedOne)" [disabled]="!selectedOne">
            <i class="fa fa-clone"></i><span>Split&hellip;</span>
          </button>
-->
          <div class="input-group-btn" style="font-size: 16px;">
            <!--button type="button" class="btn btn-sm btn-warning" tabindex="-1">Action</button-->
            <button type="button" class="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-bars"></i>More&hellip;
              <span class="caret"></span>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu pull-right" role="menu">
              <li *ngIf="allowAddShipments && isDisplayAddShipments"><a (click)="addShipments()"><i class="fa fa-plus"></i><span>Add Orders&hellip;</span></a></li>
              <li class="divider" *ngIf="allowAddShipments && isDisplayAddShipments"></li>
              <li><a (click)="manifestContent.selectedOneOrder && splitOrder(manifestContent.selectedOneOrder)" [class.disabled]="!manifestContent.selectedOneOrder"><i class="fa fa-clone"></i><span>Split&hellip;</span></a></li>
              <li class="divider"></li>
              <li><a (click)="manifestContent.consolidate()"><i class="fa fa-object-group"></i><span>Consolidate&hellip;</span></a></li>
              <li><a (click)="manifestContent.unconsolidate()"><i class="fa fa-object-ungroup"></i><span>Deconsolidate&hellip;</span></a></li>
              <li><a (click)="manifestContent.redispatchSelected()" [class.disabled]="!manifestContent.selectedSome"><i class="fa fa-user-plus"></i><span>Redispatch Selected&hellip;</span></a></li>
              <li class="divider" *ifRole="'ROLE_QA'"></li>
              <li *ifRole="'ROLE_QA'"><a (click)="downloadManifestJson()"><i class="fa fa-download"></i><span>Download Manifest&hellip;</span></a></li>
              <li class="divider"></li>
              <li><a [class.disabled]="!isDisplayDelete" (click)="isDisplayDelete && removeDispatch()"><i class="fa fa-trash"></i><span>Delete Manifest&hellip;</span></a></li>
         <!--
              <li class="divider"></li>
              <li><a (click)="clearDispatch()"><i class="fa fa-times"></i><span>Close Editor</span></a></li>
              -->
            </ul>
          </div>

        </div>
      </div>

      <!--div class="manifest-container__content"-->
        <manifest-create-content
          [order-modes]="orderModes"
          [(loading)]="loading"
          [mergeToExisting]="mergeToExisting"
          [isCreateFromWarehouse]="isCreateFromWarehouse"
          [manifest]="manifest"
          [selected]="selected"
          (manifestChange)="onManifestChange($event)"
          (selectedChange)="selectedChange.emit($event)"
          (removeItems)="removeItems.emit($event)"
          (created)="created.emit($event)"

          [staging]="isStaging"
          (stagingChange)="onStagingChange($event)"
          (refresh)="onRefresh($event)">
        </manifest-create-content>
      </div>
</oms-spinner>

    <!--/div-->
<!--/div-->
