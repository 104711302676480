<oms-alerts [showNotifications]="false"></oms-alerts>
<oms-widget (oms-drop-zone)="onFilesDrop($event)" [allowDrop]="allowFilesDrop" [label]="getPageLabel()" iconClass="fa fa-file"
            [flex]="true" [no-padding]="true" [loading]="viewLoading" [showFooter]="true">

  <!-- HEADER -->
  <!--div widget-header class="page-label">
    <h3><i class="fa fa-file-pdf"></i>{{activeDocument?.documentType.description}}</h3>
    <em>{{activeDocument?.uploadedFiles.name}}</em>
  </div-->

  <ng-container widget-header>
    <ul class="nav nav-pills nav-pills-sm">
      <li [ngClass]="{'active': activeTab === 'documents'}"><a (click)="changeTab('documents')"><i class="fa fa-file"></i>Documents</a></li>
      <li [ngClass]="{'active': activeTab === 'history'}"><a (click)="changeTab('history')"><i class="fa fa-clock"></i>History</a></li>
    </ul>

    <span style="float: right;" class="btn-toolbar">
      <button *ngIf="activeTab === 'preview'" type="button" class="btn btn-sm btn-primary" (click)="activeTab='documents'">
        <i class="fa fa-chevron-left"></i><span class="medium-screen-only">Back&nbsp;</span>
      </button>
    </span>
  </ng-container>

  <oms-tabs [(active)]="activeTab" [external-buttons]="true" content-class="no-padding">
    <ng-template oms-tab tab-id="documents" tab-class="no-padding">
      <div class="col-xs-12 col-sm-12 col-lg-12">
        <table class="table table-hover table-condensed doc-table">
          <colgroup>
            <col class="col-icon">
            <col class="col-file">
            <col class="col-type">
            <col class="col-date">
            <col class="col-user">
            <col class="col-delete" *ngIf="!readonly">
          </colgroup>

          <tbody>
          <tr *ngFor="let orderDocument of documents" draggable (dragstart)="onDragStart($event, orderDocument)">
            <td class="col-icon">
              <a (click)="onPreview(orderDocument)" ><i [class]="getFileTypeClass(orderDocument)"></i></a>
            </td>
            <td class="col-file">
              <a (click)="onPreview(orderDocument)">{{orderDocument.fileName}}</a>
              <span class="col-user">{{orderDocument.createdBy}}</span>
              <span class="col-date">{{orderDocument.uploadedFiles.dateUpload | oms_date_time:{showYear: true} }}</span>
            </td>
            <td class="col-type">
              <ng-select class="form-control input-sm" [appendTo]="'body'"
                         disabled="{{changeTypeDisabled(orderDocument)}}"
                         [clearable]="true"
                         bindLabel="name"
                         [items]="documentTypes"
                         (change)="onChangedType(orderDocument.id, $event)"
                         [(ngModel)]="orderDocument.documentType.name"
                         (keydown)="onKeyDown($event, orderDocument.id)"
                         placeholder="Document Type">
              </ng-select>
            </td>
            <td class="col-date">{{orderDocument.uploadedFiles.dateUpload | oms_date_time: {showYear: true} }}</td>
            <td class="col-user">{{orderDocument.createdBy}}</td>
            <td class="col-delete" *ngIf="!readonly">
              <a (click)="removeDocument(orderDocument)">
                <i class="fa fa-trash"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </ng-template>

    <ng-template oms-tab tab-id="history" tab-class="no-padding">
      <div class="col-xs-12 col-sm-12 col-lg-12">
        <table class="table table-hover table condensed doc-table">

          <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Previous Type</th>
            <th>Date Uploaded</th>
            <th>User Name</th>
            <th>Updated/Deleted Date</th>
            <th>Action Type</th>
            <th>Updated/Deleted By</th>
            <th *ifRole="'ROLE_ADMIN'">Restore</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let orderDocument of removed">
            <td><a [href]="buildDownloadUrl(orderDocument)">{{orderDocument.uploadedFiles.name}}</a></td>
            <td>{{orderDocument.documentType.name}}</td>
            <td>{{orderDocument.previousDocumentType?.name}}</td>
            <td>{{orderDocument.uploadedFiles.dateUpload | oms_date_time:{showYear: true} }}</td>
            <td>{{orderDocument.createdBy}}</td>
            <td>{{orderDocument.dateUpdated | oms_date_time:{showYear: true} }}</td>
            <td>{{getActionType(orderDocument)}}</td>
            <td>{{orderDocument.updatedBy}}</td>

            <td class="col-restore" *ifRole="'ROLE_ADMIN'">
              <a (click)="restoreDocument(orderDocument)" [tooltip]="getRestoreLabel(orderDocument)" *ngIf="isModified(orderDocument)">
                <i [class]="getRestoreIcon(orderDocument)"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>

    <ng-template oms-tab tab-id="preview" tab-class="no-padding">
      <oms-document-preview #preview [documents]="documents" [(active)]="activeDocument" [(fitToPage)]="fitToPage">
      </oms-document-preview>
    </ng-template>
  </oms-tabs>


  <ng-container widget-footer>
    <div class="btn-toolbar" style="width: 100%">
      <button *ngIf="activeTab === 'documents' || activeTab === 'preview'" type="button" class="btn btn-primary" (click)="openFileUploadDialog()">
        <i class="fa fa-upload"></i>Upload&hellip;
      </button>

      <button *ngIf="activeTab === 'preview'" type="button" class="btn btn-primary" (click)="download(activeDocument)">
        <i class="fa fa-download"></i>Download&hellip;
      </button>

      <button *ngIf="activeTab === 'documents' && objectType == 'MASTER'" type="button" class="btn btn-primary" (click)="generateMasterMDF()">
        <i class="fa fa-paperclip"></i><span class="medium-screen-only">Generate&nbsp;</span>PTT
      </button>

      <button *ngIf="activeTab === 'history' && isVisibleHistory" type="button" class="btn btn-primary" (click)="downloadHistory()">
        <i class="fa fa-download"></i>Download History
      </button>

      <div class="btn-group dropup pull-right" *ngIf="activeTab === 'documents' && hasPOD()">
        <a role="button" [href]="getLastPodDocumentUrl()" target="_top" type="button" class="btn btn-primary">
          <i class="fa fa-download"></i>POD
        </a>
        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-external-link-alt"></i>
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu">
          <li><a (click)="copyPodDownloadUrl()" style="padding: 5px 10px"><i class="fa fa-copy"></i><span style="left: 15px;">Copy Link</span></a></li>
          <li><a (click)="sendPodDownloadUrl()" style="padding: 5px 10px" ><i class="fa fa-envelope"></i><span style="left: 15px;">Send Link&hellip;</span></a></li>
        </div>
      </div>

      <span style="float: right" class="btn-toolbar" *ngIf="activeTab === 'preview'">

        <button type="button" class="btn btn-primary" (click)="preview.rotateLeft()">
          <i class="fa fa-undo"></i>
        </button>

        <button type="button" class="btn btn-primary" (click)="preview.rotateRight()" style="margin-right: 50px;">
          <i class="fa fa-redo"></i>
        </button>

        <button type="button" class="btn btn-primary" (click)="fitToPage = !fitToPage" style="margin-right: 50px;">
          <i class="fa" [ngClass]="{'fa-expand': !fitToPage, 'fa-search': fitToPage}"></i>
        </button>

        <button type="button" class="btn btn-primary" (click)="preview.previous()">
          <i class="fa fa-chevron-left"></i>
        </button>

        <button type="button" class="btn btn-primary" (click)="preview.next()">
          <i class="fa fa-chevron-right"></i>
        </button>

        <button type="button" class="btn btn-warning" (click)="preview.print()" [disabled]="!preview || !preview.data">
          <i class="fa fa-print"></i>
        </button>

      </span>

    </div>
  </ng-container>

</oms-widget>
