var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, EventEmitter, IterableDiffer, IterableDiffers, KeyValueDiffer, KeyValueDiffers, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, TemplateRef, } from '@angular/core';
import { DataTableComponent } from '../data-table.component';
import { Subject } from 'rxjs';
import { PARENT_REF } from '../data-table.utils';
import { Logger } from '../../../../../../_helpers/logger';
import { takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "util";
var DataTableRowComponent = /** @class */ (function () {
    function DataTableRowComponent(table, cdr, differs, iterableDiffers) {
        this.table = table;
        this.cdr = cdr;
        this.differs = differs;
        this.iterableDiffers = iterableDiffers;
        this.topOffset = 0;
        this.specificRowClassHandler = null; // todo refactor
        this.selectable = false;
        this.expandedItems = [];
        this.rowDraggable = false;
        this.selectedChange = new EventEmitter();
        this.rowStyles = {};
        this.unsubscribe$ = new Subject();
        this.columnsDiffer = this.iterableDiffers.find([]).create(null);
        this.rowDiffer = differs.find({}).create();
    }
    Object.defineProperty(DataTableRowComponent.prototype, "isChild", {
        get: function () {
            return !!this.parentRow;
        },
        enumerable: true,
        configurable: true
    });
    DataTableRowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dragScope = this.table.getRowDragScope(this.row);
        this.dropScope = this.table.getRowDropScope(this.row);
        this.table.expandEvent
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function () { return _this.cdr.markForCheck(); });
    };
    DataTableRowComponent.prototype.ngOnChanges = function (changes) {
        if (changes.row || changes.parentRow) {
            if (!isNullOrUndefined(this.parentRow)) {
                this.row[PARENT_REF] = this.parentRow;
            }
            this.rowClass = this.table.getRowClass(this.row);
            this.isLeaf = this.table.isLeaf(this.row);
        }
        if (changes.row || changes.parentRow || changes.tree || changes.expandedItems) {
            var isTree = !isNullOrUndefined(this.tree);
            this.expanded = isTree ? this.table.isExpanded(this.row) : false;
        }
        if (changes.specificRowClassHandler || changes.row || changes.parentRow) {
            this.specificRowClass = this.generateSpecificRowClass();
        }
        if (changes.odd || changes.expanded || changes.row || changes.parentRow || changes.selected || changes.expandedItems) {
            this.updateRowClassesDef();
        }
        if (changes.topOffset) {
            this.updateRowStyles();
        }
        if (changes.row || changes.parentRow) {
            this.rowHeight = this.table.getRowHeight(this.row);
        }
    };
    DataTableRowComponent.prototype.selectedRow = function (event) {
        if (this.selectable) {
            this.selectedChange.emit(event);
        }
    };
    DataTableRowComponent.prototype.generateSpecificRowClass = function () {
        var _this = this;
        if (isNullOrUndefined(this.specificRowClassHandler)) {
            return null;
        }
        else {
            var specificClass = this.specificRowClassHandler.find(function (handler) {
                return _this.row[handler.field].toString() === handler.value;
            });
            return isNullOrUndefined(specificClass) ? '' : specificClass.class;
        }
    };
    DataTableRowComponent.prototype.updateRowClassesDef = function () {
        var classes = {
            'row-archived': this.row && !isNullOrUndefined(this.row.dateDeleted),
            'data-table-row': true,
            'info': this.selected,
            'table-row-odd': this.odd,
            'table-row-even': !this.odd,
            'child-row': this.isChild,
            'expanded': this.isChild || this.expanded
        };
        if (!isNullOrUndefined(this.specificRowClass)) {
            classes[this.specificRowClass] = true;
        }
        this.rowClassesDef = classes;
    };
    DataTableRowComponent.prototype.ngOnDestroy = function () {
        this.table = undefined;
        this.tree = undefined;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    Object.defineProperty(DataTableRowComponent.prototype, "top", {
        /*  get rowClassesDef() {
            let classes = {
              'row-archived' : this.row && !isNullOrUndefined(this.row.dateDeleted),
              'data-table-row': true,
              'info': this.table.isSelected(this.row),
              'table-row-odd': this.odd,
              'table-row-even':!this.odd,
              'child-row':this.isChild,
              'expanded': this.isChild || this.expanded
            };
      
            if(!isNullOrUndefined(this.specificRowClass)){
              classes[this.specificRowClass] = true;
            }
      
            return classes;
          }*/
        get: function () {
            return this.isChild ? (this.topOffset || 0) : (this.table.virtualization ? this.table.rowHeightsCache.query(this.table.getRowIndex(this.row) - 1) : 0);
        },
        enumerable: true,
        configurable: true
    });
    DataTableRowComponent.prototype.getRowStyles = function (row, parent) {
        return this.rowStyles;
    };
    DataTableRowComponent.prototype.updateRowStyles = function () {
        var styles = {};
        if (this.table.virtualization) {
            //      const idx = this.table.getRowIndex(this.row);
            //      let top = this.table.rowHeightsCache.query(idx - 1);
            //      styles['height'] = this.table.rowHeight + 'px';
            styles['position'] = 'absolute';
            styles['transform'] = "translate3d(0px, " + this.top + "px, 0px)";
        }
        this.rowStyles = styles;
    };
    DataTableRowComponent.prototype.getChildRowOffset = function (index, items) {
        var offset = this.isChild ? this.topOffset || 0 : (this.table.virtualization ? this.table.rowHeightsCache.query(this.table.getRowIndex(this.row) - 1) : 0);
        offset += this.table.getRowHeight(this.row);
        if (!this.table.isLeaf(this.row)) {
            for (var i = 0; i < index; i++) {
                offset += this.table.getRowHeight(items[i]);
            }
        }
        return offset;
    };
    DataTableRowComponent.prototype.trackByRowObject = function (index, item) {
        return item && item.id ? item.id : index;
    };
    DataTableRowComponent.prototype.onRowClick = function (row, event) {
        if (this.selectable) {
            this.table.onRowSelect(row, event);
        }
        else if (this.parentRow) {
            this.table.onRowSelect(this.parentRow, event);
        }
    };
    DataTableRowComponent.prototype.reRender = function () {
        this.cells.forEach(function (cell) { return cell.reRender(); });
        this.cdr.markForCheck();
    };
    DataTableRowComponent = __decorate([
        Logger({
            name: 'DataTableRowComponent'
        }),
        __metadata("design:paramtypes", [DataTableComponent,
            ChangeDetectorRef,
            KeyValueDiffers,
            IterableDiffers])
    ], DataTableRowComponent);
    return DataTableRowComponent;
}());
export { DataTableRowComponent };
