import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestService} from '../../../../services/rest.service';
import {HttpUtilsService} from '../../../../services/http-utils.service';
import {FilterPageRequest} from '../../models/filter.page.request';
import {PageResult} from '../../models/query-models/page-result';
import {classToPlain, plainToClass, Type} from 'class-transformer';
import {OrderDispatch} from '../../models/dispatch/order-dispatch';
import {Manifest} from '../../models/manifest/manifest';
import {BaseEntityService} from '../../../../services/base/base-entity.service';
import {map} from 'rxjs/operators';
import {Address, Driver, Load, LoadType, Order, Trailer, Truck} from "../../models";
import {DriverOrderDispatch} from '../../models/dispatch/driver-order-dispatch';
import {DriverRoute} from "../../models/dispatch/driver-route";
import {FilterSearchColumn} from "../../models/filter.search.column";
import * as moment from "moment-timezone";
import {SearchRange} from "../../models/search.range";
import {SearchSortRequest} from "../../models/search.sort.request";
import {OmsConstants} from "../../../../common/oms-constants.service";
import {OrderMode} from "../../models/order/order-mode";
import {HttpParams} from "@angular/common/http";
import {assigned} from "../../../../_helpers/utils";
import {Carrier} from "../../models/carrier";

export type DriverAction = 'COMPLETED' | 'COMPLETED_PROBLEM' | 'NOT_COMPLETED';


export class ManifestSplitResult {
  @Type(() => Manifest)
  public source: Manifest;
  @Type(() => Manifest)
  public target: Manifest;
}


@Injectable()
export class DispatchService extends BaseEntityService<Manifest> {
  public static readonly FULL_MOMENT_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

  protected apiUrl = '/oms/dispatch/';
  protected classType = Manifest;

  lastPageRequest;

  constructor(
    public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  get(id: number, active: boolean = true): Observable<Manifest> {
    console.warn('GET DISPATCH CALL');
    return super.get(id, active);
  }


  getAddressWarehouseDefault(): Promise<any> {
    return this.httpRestService.getWithHeader<Address>(`${this.apiUrl}getAddressWarehouseDefault`, this.httpUtils.getHTTPHeaders())
      .toPromise().then((address) => plainToClass(Address, address));
  }

  getAddressFCLWarehouseDefault(): Promise<any> {
    return this.httpRestService.getWithHeader<Address>(`${this.apiUrl}getAddressFCLWarehouseDefault`, this.httpUtils.getHTTPHeaders())
      .toPromise().then((address) => plainToClass(Address, address));
  }

  getOrderAddressDelivery(orderId: number): Promise<any> {
    return this.httpRestService.getWithHeader<Address>(`${this.apiUrl}getOrderAddressDelivery/${orderId}`, this.httpUtils.getHTTPHeaders())
      .toPromise().then((address) => plainToClass(Address, address));
  }

  getAddressDefault(orderId: number): Promise<any> {
    return this.httpRestService.getWithHeader<Address>(`${this.apiUrl}getAddressDefault/${orderId}`, this.httpUtils.getHTTPHeaders())
      .toPromise().then((address) => plainToClass(Address, address));
  }

  findAllToDispatch(filterPageRequest: FilterPageRequest): Observable<PageResult<OrderDispatch>> {
    this.lastPageRequest = filterPageRequest;
    return this.httpRestService.postWithHeader<PageResult<OrderDispatch>>(this.apiUrl + 'findAllToDispatch', this.httpUtils.getHTTPHeaders(), filterPageRequest)
      .pipe(map((page ) => {
        page.content = plainToClass(OrderDispatch, page.content);
        return page;
      }));
  }

  findDispatchedLoads(filterPageRequest: FilterPageRequest): Promise<PageResult<OrderDispatch>> {
    return this.findDispatchedLoadsObs(filterPageRequest).toPromise();
  }

  findDispatchedLoadsObs(filterPageRequest: FilterPageRequest): Observable<PageResult<OrderDispatch>> {
    this.lastPageRequest = filterPageRequest;
    return this.httpRestService.postWithHeader<PageResult<OrderDispatch>>(this.apiUrl + 'findDispatchedLoads', this.httpUtils.getHTTPHeaders(), filterPageRequest)
      .pipe(map(page => {
        page.content = plainToClass(OrderDispatch, page.content);
        return page;
      }));
  }

  findOrderDispatches(orderId: number): Promise<OrderDispatch[]> {
    return this.httpRestService.post<OrderDispatch[]>(this.apiUrl + 'findOrderDispatches/' + orderId, this.httpUtils.getHTTPHeaders())
      .toPromise().then((list) => plainToClass(OrderDispatch, list));
  }

  findOrderDispatchesFromShipment(shipmentId: number): Promise<OrderDispatch> {
    return this.httpRestService.post<OrderDispatch>(this.apiUrl + 'findOrderDispatchesFromShipment/' + shipmentId, this.httpUtils.getHTTPHeaders())
      .toPromise().then((od) => plainToClass(OrderDispatch, od));
  }

  findOrdersToDispatchFromWarehouses(orderIds: number[], addressPickupIds: number[]):  Promise<OrderDispatch[]> {
    return this.httpRestService.post<OrderDispatch[]>(this.apiUrl + 'findOrdersToDispatchFromWarehouses/' + orderIds + '/'  + addressPickupIds , this.httpUtils.getHTTPHeaders())
      .toPromise().then((list) => plainToClass(OrderDispatch, list));
  }

  findDispatchesForOrders(orderIds: number[]):  Promise<OrderDispatch[]> {
    return this.httpRestService.post<OrderDispatch[]>(this.apiUrl + 'findDispatchesForOrders/' + orderIds , this.httpUtils.getHTTPHeaders())
      .toPromise().then((list) => plainToClass(OrderDispatch, list));
  }

  findDispatchedLoadsByDriver(driverOrderDispatch: DriverOrderDispatch): Observable<PageResult<OrderDispatch>> {
    let filterByDriver = new FilterSearchColumn('driverId', driverOrderDispatch.driver.id + '')
      .setFullMatchValue(true);

    let startDay = moment.tz(driverOrderDispatch.dateDispatchedFor, OmsConstants.ETC_ZONE)
      .set({h: 0, m: 0, s: 0, ms: 0})
      .utc();

    let range = new SearchRange(startDay.format(DispatchService.FULL_MOMENT_FORMAT), startDay.format(DispatchService.FULL_MOMENT_FORMAT), false);

    let filterByDate = new FilterSearchColumn('dateDispatchedFor', null, null, range);
    let request = new FilterPageRequest(1, 20, '', new SearchSortRequest('dispatchId', true), [filterByDriver, filterByDate]);
    request.addNamedCondition('ACTIVE');
    return this.findDispatchedLoadsObs(request);
  }

  findDispatchedLoadsForDriver(filterPageRequest: FilterPageRequest): Observable<PageResult<DriverOrderDispatch>> {
    this.lastPageRequest = filterPageRequest;
    return this.httpRestService.postWithHeader<PageResult<DriverOrderDispatch>>(this.apiUrl + 'findDispatchedLoadsForDriver', this.httpUtils.getHTTPHeaders(), filterPageRequest)
      .pipe(map(page => {
        page.content = plainToClass(DriverOrderDispatch, page.content as Object[]);
        return page;
      }));
  }

  validateOrderDispatch(item: Order): Observable<{ warnings: string[] }> {
    return this.httpRestService.post<{ warnings: string[] }>(this.apiUrl + 'validateOrderDispatch', classToPlain(item));
  }

  public dispatchOrders(dispatch: Manifest, isSendEmail: boolean, isPlanning: boolean = false): Observable<Manifest> {
    console.log('DISPATCH', dispatch);
    return this.httpRestService.post<Manifest>(this.apiUrl + 'dispatchOrders', classToPlain(dispatch),
      {params: {isSendEmail, isPlanning}}).pipe(map((manifest) => plainToClass(Manifest, manifest)));
  }


  public splitManifest(dispatchId: number, items: number[], newDate?: Date, newDriver?: Driver): Observable<ManifestSplitResult> {
    let params: any = {dispatch: dispatchId};
    if (items && items.length) { params.items = items; }
    if (newDriver) {params.driver = newDriver.id; }
    if (assigned(newDate)) {params.date = newDate; }

    return this.httpRestService.post<ManifestSplitResult>(this.apiUrl + 'split-manifest', undefined,
      {params: this.httpUtils.getHTTPParams(params)})
      .pipe(map((result) => plainToClass(ManifestSplitResult, result)));
  }

  public splitManifestByTrailers(dispatch: Manifest): Observable<Manifest[]> {
    return this.httpRestService.post<Manifest[]>(this.apiUrl + 'split-manifest-by-trailers', classToPlain(dispatch)).pipe(map((manifest) => plainToClass(Manifest, manifest)));
  }

  public cancelOrdersDispatch(dispatchId: number, modes?: OrderMode[]): Observable<any> {
    return modes && modes.length > 0 ?
      this.httpRestService.post(this.apiUrl + 'cancelOrdersDispatch/' + dispatchId + '/modes/' + modes) :
      this.httpRestService.post(this.apiUrl + 'cancelOrdersDispatch/' + dispatchId);
  }

  public updateOrderDispatch(item: Order): Observable<Order> {
    return this.httpRestService.put<Order>(this.apiUrl + `updateOrderDispatch`, classToPlain(item), {headers: this.httpUtils.getHTTPHeaders()})
      .pipe(map((order) => plainToClass(Order, order)));
  }

  public updateOrderDeliveryDate(orderIds: number[], dateDelivery: Date, actual: boolean): Observable<Order[]> {
    console.log('sent', dateDelivery, 'ISO:', dateDelivery.toISOString());
    let params = new HttpParams().set('orderIds', orderIds.join(','))
      .set('deliveryDate', encodeURI(dateDelivery.toISOString()))
      .set('actual', '' + actual);
    return this.httpRestService.put<Order[]>(this.apiUrl + `updateOrderDeliveryDate`, [], this.httpUtils.getHTTPHeaders(), params)
      .pipe(map(item => plainToClass(Order, item as Order[])));
  }

  updateDispatchFields(dispatchId: number, dispatchItemId: number, field: string, value: any): Observable<Manifest> {
    let body = {};
    body[field] = value;
    return this.httpRestService.put<Manifest>(this.apiUrl + 'update/' + dispatchId + '/' + dispatchItemId, body, {})
      .pipe(map((manifest) => plainToClass(Manifest, manifest)));
  }

  validateRemoveShipmentItem(dispatchItemId: number): Observable<string> {
    return this.httpRestService.get<{validationMessage: string}>(this.apiUrl + 'validate-remove', dispatchItemId)
      .pipe(map(response => response.validationMessage));
  }

  validateRemoveOrder(orderId: number, dispatchItemId: number): Observable<string> {
    return this.httpRestService.getWithOptions<{validationMessage: string}>(this.apiUrl + 'validate-remove-order/' + orderId, null, {dispatchItemId})
      .pipe(map(response => response.validationMessage));
  }

  public getLoadDeliveryAddressForOrder(orderId: number, loadType: LoadType, orderMode: OrderMode): Promise<Address> {
    console.log('getLoadDeliveryAddressForOrder', loadType, orderMode);

    return this.httpRestService.getWithHeader<Address>(`${this.apiUrl}getLoadDeliveryAddressForOrder/${orderId}/${loadType}`, this.httpUtils.getHTTPHeaders())
      .toPromise().then((address) => plainToClass(Address, address));
/*

    return new Promise<Address>((resolve, reject) => {
      if (orderMode === OrderMode.RECOV) {
        switch (loadType) {
          case LoadType.RECOVERY:
          case LoadType.DIRECT:
        }
      } else {
        switch (loadType) {
          case LoadType.X_DOCK:
          case LoadType.PICKUP:
            return this.getAddressDefault(orderId);

          case LoadType.DELIVERY:
          case LoadType.DIRECT:
            return this.getOrderAddressDelivery(orderId);
        }
      }

      reject();
    });

 */
  }

  public getDriverRoutes(date: Date, request: FilterPageRequest): Promise<DriverRoute[]> {
    let url = `${this.apiUrl}getDriverRoutes/${date.toUTCString()}`;
    console.log('URL', url, 'FILTER', request);

    return this.httpRestService.postWithHeader<DriverRoute[]>(url,
      this.httpUtils.getHTTPHeaders(), request)
      .pipe(map((routes ) => plainToClass(DriverRoute, routes)))
      .toPromise();
  }

  public getDriverManifestOnDate(date: Date, driver: Driver): Promise<Manifest|null> {
    return new Promise<Manifest|null>((resolve, reject) => {
      if (!driver) {
        resolve(null);
      } else {
          this.httpRestService.getWithHeader<Manifest>(`${this.apiUrl}getDriverManifestOnDate/${date}/${driver.id}`, this.httpUtils.getHTTPHeaders())
//            .pipe(map(manifest=>plainToClass(Manifest, manifest)))
            .subscribe(next => {console.log('RECEIVED', next); resolve(plainToClass(Manifest, next)); }, error => reject(error) );
      }
    });
  }


  public approveManifest(id: number): Promise<any> {
    return this.httpRestService.getWithHeader<any>(`${this.apiUrl}approve/${id}`, this.httpUtils.getHTTPHeaders())
      .pipe(map(manifest => plainToClass(Manifest, manifest))).toPromise();
  }

  public moveManifestItemToDispatch(manifestItemId: number, newDispatchId: number): Promise<Manifest> {
    return this.httpRestService.getWithHeader<Manifest>(`${this.apiUrl}moveManifestItemToDispatch/${manifestItemId}/${newDispatchId || ''}`, this.httpUtils.getHTTPHeaders())
      .toPromise().then(manifest => plainToClass(Manifest, manifest));
  }

  public mergeManifests(sourceDispatchId: number, targetDispatchId: number): Promise<Manifest> {
    return this.httpRestService.getWithHeader<Manifest>(`${this.apiUrl}mergeManifests/${sourceDispatchId}/${targetDispatchId}`, this.httpUtils.getHTTPHeaders())
      .toPromise().then(manifest => plainToClass(Manifest, manifest));
  }

  public getLastDeliveryLoad(orderId: number): Observable<Load> {
    return this.httpRestService.getWithHeader<Load>(`${this.apiUrl}lastDeliveryLoad/${orderId}`, this.httpUtils.getHTTPHeaders());
  }

  /***
   * Move Manifest Items or single Orders from the Source Manifest to the Target Manifest
   * @param source: Source Manifest
   * @param target: Target Manifest. If null then new manifest will be created
   * @return Observable with Success/Error result
   */
  public redispatch(source: Manifest, target: Manifest): Promise<Manifest> {
    let sourceId = source.id;
    let targetId = target ? target.id : undefined;
    let url = `${this.apiUrl}redispatch/`;
    let body = {
      source: classToPlain(source),
      target: classToPlain(target),
    };

    return this.httpRestService.post<Manifest>(url, body, {headers: this.httpUtils.getHTTPHeaders(), withCredentials: true})
      .toPromise().then(m => plainToClass(Manifest, m));
  }

  public routeDriverUpdate(driverId: number, dispatchItemIds: number | number[], action: DriverAction, actualDate?: Date): Promise<any> {
    let url = `${this.apiUrl}route/${dispatchItemIds}/driver/${driverId}/update/${action}`;


    let params = new HttpParams();
    if (assigned(actualDate)) {
      params = params.set('actualDate', encodeURI(actualDate.toISOString()));
    }

    return this.httpRestService.put<Manifest>(url, null, this.httpUtils.getHTTPHeaders(), params)
      .toPromise()
      .then((manifest ) => plainToClass(Manifest, manifest));
  }

  public routeDriverUpdateCancel(dispatchItemIds: number | number[]): Promise<any> {
    let url = `${this.apiUrl}route/${dispatchItemIds}/update/cancel`;

    return this.httpRestService.put<Manifest>(url, null, this.httpUtils.getHTTPHeaders())
      .toPromise()
      .then((manifest ) => plainToClass(Manifest, manifest));
  }

  public getDriverRoutesOnDate(driverId: number, date: Date): Promise<any> {
    return this.httpRestService.getWithHeader<Manifest[]>(`${this.apiUrl}route/driver/${driverId}/routeDate/${date.toUTCString()}`,
      this.httpUtils.getHTTPHeaders()).toPromise().then((manifest ) => plainToClass(Manifest, manifest));
  }

  generateBOLs(dispatchIds: number[]): Observable<any> {
    let params = new HttpParams().set('dispatchIds', dispatchIds.join(','));
    return this.httpRestService.put<any>(this.apiUrl + `generateBOLs`, [], this.httpUtils.getHTTPHeaders(), params);
  }

  public generateManifest(manifestId: number): string {
    return this.httpRestService.buildUrl(this.apiUrl + "generateManifest", manifestId);
  }

  public updateDriver(dispatchId: number, driver: Driver): Observable<Carrier> {
    return this.httpRestService.post(`${this.apiUrl}update/driver/${dispatchId}`, driver,
      {headers: this.httpUtils.getHTTPHeaders()});
  }

  public updateTruck(dispatchId: number, truck: Truck): Observable<any> {
    return this.httpRestService.post(`${this.apiUrl}update/truck/${dispatchId}`, truck,
      {headers: this.httpUtils.getHTTPHeaders()});
  }

  public updateTrailer(dispatchId: number, trailer: Trailer): Observable<any> {
    return this.httpRestService.post(`${this.apiUrl}update/trailer/${dispatchId}`, trailer,
      {headers: this.httpUtils.getHTTPHeaders()});
  }

  public updateCarrier(dispatchId: number, carrier: Carrier): Observable<Driver> {
    return this.httpRestService.post(`${this.apiUrl}update/carrier/${dispatchId}`, carrier,
      {headers: this.httpUtils.getHTTPHeaders()});
  }


  public updateSeal(dispatchId: number, seal: string): Observable<any> {
    return this.httpRestService.post(`${this.apiUrl}update/seal/${dispatchId}`, seal,
      {headers: this.httpUtils.getHTTPHeaders()});
  }

  public buildManifestJsonDownloadUrl(dispatchId: number) {
    return this.httpRestService.buildUrl(this.apiUrl, 'get-manifest-json', dispatchId);
  }



}
