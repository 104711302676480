import {AbstractControl, FormGroup, FormArray} from '@angular/forms';

export class FormUtils {

  public static getControlErrorMessage(control: AbstractControl, fieldName: string): string {
    if (!control.touched || !control.errors) {
      return null;
    }
    if (control.errors.required) {
      return fieldName + ' is required';
    }
    if (control.errors.minlength) {
      return fieldName + ' must be at least ' + control.errors.minlength.requiredLength + ' characters';
    }
    if (control.errors.maxlength) {
      return fieldName + ' must be more than ' + control.errors.minlength.requiredLength + ' characters';
    }
    if (control.errors.max) {
      return fieldName + ' maximum number can be ' + control.errors.max.max;
    }
    if (control.errors.min) {
      return fieldName + ' minimum number can be ' + control.errors.min.min;
    }
    return null;
  }

  public static markAsTouch(control: FormGroup | FormArray): void {
    const controls = control.controls;
    Object.keys(controls).forEach((controlName) => {
      controls[controlName].markAsTouched();
      if (controls[controlName] instanceof FormGroup || controls[controlName] instanceof FormArray) {
        FormUtils.markAsTouch(controls[controlName]);
      }
    });
  }
}
