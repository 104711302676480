import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injector, TemplateRef, } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ComponentPortal, DomPortalHost, TemplatePortal } from '@angular/cdk/portal';
import { InputTextDialogComponent } from './input-text-dialog/input-text-dialog.component';
import { NotesCenterDialogComponent } from "../notes-center-dialog";
import { BUTTONS_YES_NO } from "./oms-dialogs.types";
import { AddressMapInfoDialogComponent } from "../../../modules/shared/components/address-map-info-dialog/address-map-info-dialog.component";
import { Master, Order, RecoveryOrder } from "../../../modules/shared/models";
import { ChatDialogComponent } from "../../../modules/shared/components/chat-dialog/chat-dialog.component";
import { messageListen, popupCenter } from "../../../_helpers/utils";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { VideoDialogComponent } from "../../dialogs/video-dialog/video-dialog.component";
import { DocCenterDialogComponent } from "../doc-center/doc-center-dialog";
import { DocCenterConfig } from "../doc-center/doc-center.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export var PageOpenMode;
(function (PageOpenMode) {
    PageOpenMode[PageOpenMode["NEW_TAB"] = 0] = "NEW_TAB";
    PageOpenMode[PageOpenMode["NEW_WINDOW"] = 1] = "NEW_WINDOW";
    PageOpenMode[PageOpenMode["POPUP_DIALOG"] = 2] = "POPUP_DIALOG";
    PageOpenMode[PageOpenMode["CHILD_PAGE"] = 3] = "CHILD_PAGE";
})(PageOpenMode || (PageOpenMode = {}));
var OmsDialogsService = /** @class */ (function () {
    function OmsDialogsService(dialog, componentFactoryResolver, applicationRef, injector) {
        this.dialog = dialog;
        this.componentFactoryResolver = componentFactoryResolver;
        this.applicationRef = applicationRef;
        this.injector = injector;
    }
    OmsDialogsService.prototype.confirm = function (type, title, message, buttons, options) {
        var _this = this;
        if (buttons === void 0) { buttons = BUTTONS_YES_NO; }
        return new Promise(function (resolve, reject) {
            var dialogRef = _this.dialog.open(ConfirmDialogComponent, { disableClose: true, maxWidth: (options ? options.maxWidth : undefined), width: (options ? options.width : undefined) || 'auto', data: { type: type, title: title, message: message, buttons: buttons } });
            dialogRef.afterClosed().subscribe(function (res) { return resolve(res); }, function (error) { return reject(error); });
        });
    };
    OmsDialogsService.prototype.inputText = function (icon, title, message, text, rows) {
        return this.openDialog(InputTextDialogComponent, { title: title, icon: icon, message: message, text: text, rows: rows });
    };
    OmsDialogsService.prototype.inputInteger = function (icon, title, message, n) {
        return this.openDialog(InputTextDialogComponent, { title: title, icon: icon, message: message, text: n, rows: 1 });
    };
    OmsDialogsService.prototype.openInfoDialog = function (message, config) {
        config = config || {};
        config.message = message;
        return this.openDialog(AlertDialogComponent, config);
    };
    OmsDialogsService.prototype.openErrorDialog = function (message, caption, timeout) {
        return this.openInfoDialog(message, { mode: 'danger', caption: caption, timeout: timeout });
    };
    OmsDialogsService.prototype.openDialog = function (component, data, params) {
        var config = {
            panelClass: 'oms-dialog-container',
            width: params && params.width || 'auto',
            data: data
        };
        var ref = this.dialog.open(component, config);
        // Custom close handler
        if (params && params.handler) {
            ref['oldClose'] = ref.close;
            ref.close = function (res) {
                params.handler(res)
                    .then(function (r) { return ref['oldClose'](r); }) // call old .close()
                    .catch(function () { }); // prevent close
            };
        }
        return ref.afterClosed().toPromise();
        //    });
    };
    /*  public openComponentWindow<T, D>(component: ComponentType<T>, data: D, width?: string): Promise<DialogResult<D>> {
        const portal = new ComponentPortal<T>(component);
        const externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200');
    
        const host = new DomPortalHost(
          externalWindow.document.body,
          this.componentFactoryResolver,
          this.applicationRef,
          this.injector
        );
    
        if (is)
        host.attachComponentPortal(portal);
      }*/
    /**
     * @deprecated
     */
    OmsDialogsService.prototype.openWindow = function (component, data, width) {
        var isTemplate = component instanceof TemplateRef;
        var portal = isTemplate ?
            new TemplatePortal(component, null) :
            new ComponentPortal(component);
        var externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200');
        externalWindow.document.write('<html><head><title>Print it!</title><style type="text/css">.pin-bg { background: pink; width:255px; height: 20px;}</style></head><body onblur="self.focus()"></body>');
        var host = new DomPortalHost(externalWindow.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
        var ref = isTemplate ?
            host.attachTemplatePortal(portal) :
            host.attachComponentPortal(portal);
        return ref;
        /*    return new Promise<DialogResult<D>>((resolve, reject) => {
              this.dialog.open(component, {panelClass: 'oms-dialog-container', hasBackdrop: false, width: width || 'auto', data: data})
                .afterClosed().subscribe((next) => resolve(next), (error) => reject(error));
            }); */
    };
    OmsDialogsService.prototype.objectType = function (row) {
        if (row instanceof Master) {
            return { type: 'MASTER', obj: row };
        }
        if (row instanceof Order) {
            return { type: 'ORDER', obj: row };
        }
        if (row instanceof RecoveryOrder) {
            return { type: 'ORDER', obj: row.order };
        }
        return undefined;
    };
    OmsDialogsService.prototype.openComCenterDialog = function (id, object, noteType, readonly) {
        if (noteType === void 0) { noteType = 0; }
        if (readonly === void 0) { readonly = false; }
        this.dialog.open(NotesCenterDialogComponent, { width: '80%', data: { id: id, objectType: object, noteTypeId: noteType, readonly: readonly } });
    };
    OmsDialogsService.prototype.openObjectComCenterDialog = function (object, noteType, readonly) {
        if (noteType === void 0) { noteType = 0; }
        if (readonly === void 0) { readonly = false; }
        var o = this.objectType(object);
        if (o) {
            this.openComCenterDialog(o.obj.id, o.type, noteType, readonly);
        }
    };
    OmsDialogsService.prototype.openDocCenterWindow = function (config, newTab) {
        var _this = this;
        if (newTab === void 0) { newTab = false; }
        var type = config.objectType === 'ORDER' ? 'order' : 'master';
        var url = "/doc-center?" + type + "=" + config.id;
        if (newTab) {
            window.open(url, '_blank');
        }
        else {
            if (this.docCenterWindow && this.docCenterWindow.window) {
                this.docCenterWindow.focus();
                var temp_1 = this.docCenterWindow;
                this.docCenterWindow.location.assign(url);
                setTimeout(function () {
                    _this.docCenterWindow = temp_1;
                    _this.docCenterWindow.addEventListener('unload', function () { return _this.docCenterWindow = undefined; });
                });
            }
            else {
                this.docCenterWindow = popupCenter(url, 'Doc Center', 1024, 800);
                this.docCenterWindow.addEventListener('unload', function () { return _this.docCenterWindow = undefined; });
            }
        }
    };
    OmsDialogsService.prototype.openDocCenterDialog = function (options) {
        this.openDialog(DocCenterDialogComponent, { config: options }, {}).then();
    };
    OmsDialogsService.prototype.openObjectDocCenterDialog = function (object, readonly) {
        if (readonly === void 0) { readonly = false; }
        var o = this.objectType(object);
        if (o) {
            this.openDocCenterWindow({ id: o.obj.id, objectType: o.type, readonly: readonly });
        }
    };
    OmsDialogsService.prototype.openDriverLocationDialog = function (driver) {
        this.dialog.open(AddressMapInfoDialogComponent, { width: '50%', data: { driver: driver, searchByAddress: false } });
    };
    OmsDialogsService.prototype.openChatDialog = function (driver) {
        this.dialog.open(ChatDialogComponent, { width: '70%', data: { driver: driver } });
    };
    OmsDialogsService.prototype.openManifestWindow = function (id, readonly, handler) {
        var _this = this;
        if (readonly === void 0) { readonly = false; }
        var url = "/manifest?id=" + id;
        if (this.manifestWindow && this.manifestWindow.window) {
            console.log('focus', this.manifestWindow);
            this.manifestWindow.focus();
            var temp_2 = this.manifestWindow;
            this.manifestWindow.location.assign(url);
            setTimeout(function () {
                _this.manifestWindow = temp_2;
                messageListen(handler);
                _this.manifestWindow.addEventListener('unload', function () {
                    _this.manifestWindow = undefined;
                });
            });
        }
        else {
            this.manifestWindow = popupCenter(url, 'Manifest', 1024, 800);
            messageListen(handler);
            this.manifestWindow.addEventListener('unload', function () {
                _this.manifestWindow = undefined;
            });
        }
    };
    OmsDialogsService.prototype.openMapsWindow = function (externalRouteId) {
        var _this = this;
        var url = "/map?id=" + externalRouteId;
        if (externalRouteId) {
            if (this.mapsWindow && this.mapsWindow.window) {
                this.mapsWindow.focus();
                var temp_3 = this.mapsWindow;
                this.mapsWindow.location.assign(url);
                setTimeout(function () {
                    _this.mapsWindow = temp_3;
                    _this.mapsWindow.addEventListener('unload', function () {
                        _this.mapsWindow = undefined;
                    });
                });
            }
            else {
                this.mapsWindow = popupCenter(url, 'Map', 1024, 800);
                this.mapsWindow.addEventListener('unload', function () {
                    _this.mapsWindow = undefined;
                });
            }
        }
    };
    OmsDialogsService.prototype.openCameraDialog = function (data) {
        var dialogRef = this.dialog.open(VideoDialogComponent, { width: 'auto', data: data });
        var obs = dialogRef.afterClosed();
        obs.subscribe(function (res) {
            if (res) { }
        });
        return obs;
    };
    OmsDialogsService.ngInjectableDef = i0.defineInjectable({ factory: function OmsDialogsService_Factory() { return new OmsDialogsService(i0.inject(i1.MatDialog), i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.ApplicationRef), i0.inject(i0.INJECTOR)); }, token: OmsDialogsService, providedIn: "root" });
    return OmsDialogsService;
}());
export { OmsDialogsService };
