var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService, OrdersService } from "../../../../services";
import { MasterStatus, Order } from "../../../../modules/shared/models";
import { OrderMode, OrderModes } from "../../../../modules/shared/models/order/order-mode";
import { OmsConstants } from "../../../../common/oms-constants.service";
import { CustomerService } from "../../../../services/customer.service";
import { FreightForwarderService } from "../../../../services/freight.forwarder.service";
import { FileUploadService } from "../../../../services/file.upload.service";
import { NotesCenterDialogComponent } from "../../../../components/common/notes-center-dialog";
import { MatDialog } from "@angular/material";
import { UldsDialogComponent } from "../../../../components/common/ulds-dialog/ulds-dialog.component";
import { isNullOrUndefined } from "util";
import { Size } from "../../../../common/oms-types";
import { HistoryInformationDialogComponent } from "../../../../components/common/history-information-dialog";
import { MasterService } from "../../../../services/master/master.service";
import { OrderSubscriptionDialogComponent } from "../../../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import { takeUntil } from "rxjs/operators";
import { AbstractComponent } from "../../../../common/component/abstract.component";
import { BillingInfoListDialogComponent } from "../billing-info-list-dialog/billing-info-list-dialog.component";
import { OmsDialogsService } from "../../../../components/common/oms-dialogs";
import { BillingService } from "../../../../services/billing.service";
import { buildCfsStatusPageUrl, copyToClipboard, sendEmail } from "../../../../_helpers/utils";
import { OmsAlertsService } from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { convertOrderNumber } from "../../../../modules/shared/services/oms-converters.service";
var GenericOrderFormComponent = /** @class */ (function (_super) {
    __extends(GenericOrderFormComponent, _super);
    function GenericOrderFormComponent(file, freightForwarderService, customerService, cdr, authService, dialog, dialogs, masterService, billingService, alerts, ordersService) {
        var _this = _super.call(this) || this;
        _this.file = file;
        _this.freightForwarderService = freightForwarderService;
        _this.customerService = customerService;
        _this.cdr = cdr;
        _this.authService = authService;
        _this.dialog = dialog;
        _this.dialogs = dialogs;
        _this.masterService = masterService;
        _this.billingService = billingService;
        _this.alerts = alerts;
        _this.ordersService = ordersService;
        _this.isBillingDisabled = true;
        _this.modes = OrderModes;
        _this.orderModeValue = OrderModes.values;
        _this.statusName = '';
        _this.Size = Size;
        _this.isReadMode = false;
        _this.canCreateCarrier = false;
        _this.canShowBillingInfo = false;
        _this.invoices = [];
        _this.authService.isReadMode()
            .pipe(takeUntil(_this.unsubscribe$))
            .subscribe(function (isReadMode) { return _this.isReadMode = isReadMode; });
        return _this;
    }
    GenericOrderFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (isNullOrUndefined(this.type)) {
            this.orderModeValue = OrderModes.values;
        }
        else if ('AIR_EXPORT' === this.type) {
            this.orderModeValue = [OrderMode.AEDIR];
        }
        else if ('FCL' === this.type) {
            this.orderModeValue = [OrderMode.FCLEX, OrderMode.FCLIM];
        }
        this.billingService.getInvoicesByOrderId(this.order.id)
            .subscribe(function (invoices) { return _this.invoices = invoices; });
        this.authService.user$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (user) {
            _this.isBillingDisabled = !(user && user.billingApprove);
            _this.canCreateCarrier = user && user.canCreateCarrier;
            _this.canShowBillingInfo = user && user.canShowBillInfo;
        });
        this.onModeChange();
    };
    GenericOrderFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.order) {
            this.statusName = MasterStatus.getLabel(this.order.status);
        }
    };
    GenericOrderFormComponent.prototype.onModeChange = function () {
        if (this.isFCL) {
            this.pieces = 1;
        }
        this.checkHu();
        this.cdr.markForCheck();
    };
    GenericOrderFormComponent.prototype.checkHu = function () {
        if (this.isFCL) {
            this.hu = this.pieces;
            this.huUnits = this.pcsUnits;
        }
    };
    Object.defineProperty(GenericOrderFormComponent.prototype, "addressPickup", {
        get: function () {
            return this.order && this.order.direct ? this.order.addressCargoBuilding : this.order.addressCfs;
        },
        set: function (value) {
            if (this.order) {
                this.order.addressCfs = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "addressDelivery", {
        get: function () {
            return this.order && this.order.addressDelivery;
        },
        set: function (value) {
            if (this.order) {
                this.order.addressDelivery = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref1", {
        get: function () {
            return this.order && this.order.customerRef;
        },
        set: function (value) {
            if (this.order) {
                this.order.customerRef = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref2", {
        get: function () {
            return this.order && this.order.hawb;
        },
        set: function (value) {
            if (this.order) {
                this.order.hawb = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref3", {
        get: function () {
            return this.order && this.order.ref3;
        },
        set: function (value) {
            if (this.order) {
                this.order.ref3 = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    GenericOrderFormComponent.prototype.onRightClick = function (isDelivery) {
        var logChanges = isDelivery
            ? [{
                    className: new Order().javaClassName,
                    field: 'dateDeliveryAct',
                    id: this.order.id,
                    key: 'actual'
                }, {
                    className: new Order().javaClassName,
                    field: 'dateDeliveryReq',
                    id: this.order.id,
                    key: 'estimated'
                }]
            : [{
                    className: new Order().javaClassName,
                    field: 'datePickupAct',
                    id: this.order.id,
                    key: 'actual'
                }, {
                    className: new Order().javaClassName,
                    field: 'datePickupEst',
                    id: this.order.id,
                    key: 'estimated'
                }];
        console.log('LOG', logChanges);
        this.executeLogChanges(logChanges);
        return false;
    };
    GenericOrderFormComponent.prototype.executeLogChanges = function (logChanges) {
        this.dialogs.openDialog(HistoryInformationDialogComponent, {
            logParams: logChanges, column: null, convertByBaseType: null
        });
    };
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref1Label", {
        get: function () {
            return 'REF';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "actualMode", {
        get: function () {
            return this.order ? (this.order.genericMode || (this.order.nonAMS ? OrderMode.AIDOM : OrderMode.AICFS)) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref2Label", {
        get: function () {
            switch (this.actualMode) {
                case OrderMode.AICFS:
                case OrderMode.AIDOM:
                case OrderMode.AEMSS:
                case OrderMode.AEDIR:
                    return "HAWB";
                case OrderMode.FCLEX:
                case OrderMode.FCLIM:
                    return "CNT";
                default:
                    return "REF2";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref3Label", {
        get: function () {
            switch (this.actualMode) {
                case OrderMode.AICFS:
                case OrderMode.AIDOM:
                case OrderMode.AEMSS:
                case OrderMode.AEDIR:
                    return "MAWB";
                case OrderMode.FCLEX:
                    return "BOOKING#";
                case OrderMode.FCLIM:
                    return "MBL";
                default:
                    return "REF3";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "isGeneric", {
        /*  getShipment(): Shipment {
            if (this.shipment)
              return this.shipment;
      
            if (this.order && this.dispatch) {
              this.dispatchItem =  this.dispatch.items.findFirst(di=>di.hasOrder(this.order.id));
              if (this.dispatchItem)
                this.shipment =  this.order.shipmentContents.map(sc=>sc.shipment).findFirst(s=>s.id === this.dispatchItem.shipmentId);
            }
      
            return this.shipment;
          } */
        get: function () {
            return this.order && !!this.order.genericMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "isRecovery", {
        get: function () {
            return this.order && !!this.order.isRecovery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "nonGenericModeLabel", {
        get: function () {
            return this.order && !this.order.isGeneric ? OrderModes.labelOf(OrderMode.AICFS) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "genericMode", {
        get: function () {
            return this.order && this.order.genericMode;
        },
        set: function (mode) {
            if (this.order) {
                this.order.genericMode = mode;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "isFCL", {
        get: function () {
            return OrderModes.isFCL(this.actualMode);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "isContainerMode", {
        get: function () {
            return OrderModes.isContainerMode(this.actualMode);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "pieces", {
        get: function () {
            return this.order && this.order.pieces;
        },
        set: function (value) {
            if (this.order) {
                this.order.pieces = value;
                this.checkHu();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "hu", {
        get: function () {
            return this.order && this.order.hu;
        },
        set: function (value) {
            if (this.order) {
                this.order.hu = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "weight", {
        get: function () {
            return this.order && this.order.weight;
        },
        set: function (value) {
            this.order.weight = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "customer", {
        get: function () {
            return this.order && this.order.customer;
        },
        set: function (value) {
            if (this.order) {
                this.order.customer = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref2Placeholder", {
        get: function () {
            return OrderModes.isContainerMode(this.actualMode) ? OmsConstants.contNumberPlaceholder : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref3Placeholder", {
        get: function () {
            switch (this.actualMode) {
                case OrderMode.AICFS:
                case OrderMode.AIDOM:
                case OrderMode.AEMSS:
                case OrderMode.AEDIR:
                    return OmsConstants.mawbPlaceholder;
                default:
                    return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref2Mask", {
        get: function () {
            return OrderModes.isContainerMode(this.actualMode) ? OmsConstants.contNumberMask : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "ref3Mask", {
        get: function () {
            switch (this.actualMode) {
                case OrderMode.AICFS:
                case OrderMode.AIDOM:
                case OrderMode.AEMSS:
                case OrderMode.AEDIR:
                    return OmsConstants.mawbMask;
                default:
                    return undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "datePickUpAct", {
        get: function () {
            var s = this.shipment;
            return s && s.datePickUpExpectation;
        },
        enumerable: true,
        configurable: true
    });
    GenericOrderFormComponent.prototype.openUldDialog = function () {
        this.dialog.open(UldsDialogComponent, {
            width: '30%', data: {
                orderId: this.order.id,
                readonly: false
            }
        });
    };
    GenericOrderFormComponent.prototype.openNoteCenter = function (noteTypeId) {
        this.dialog.open(NotesCenterDialogComponent, {
            width: '80%',
            data: { id: this.order.id, objectType: 'ORDER', noteTypeId: noteTypeId, readonly: false }
        });
    };
    GenericOrderFormComponent.prototype.openDocCenter = function () {
        this.dialogs.openDocCenterWindow({ id: this.order.id, objectType: 'ORDER' });
    };
    Object.defineProperty(GenericOrderFormComponent.prototype, "huUnits", {
        get: function () {
            return this.order && this.order.huUnits;
        },
        set: function (value) {
            if (this.order) {
                this.order.huUnits = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "pcsUnits", {
        get: function () {
            return this.order && this.order.pcsUnits;
        },
        set: function (value) {
            console.log('SET', value);
            if (this.order) {
                this.order.pcsUnits = value;
                this.checkHu();
            }
        },
        enumerable: true,
        configurable: true
    });
    GenericOrderFormComponent.prototype.isAIOrder = function () {
        return !this.order.genericMode || this.order.genericMode === OrderMode.AIDOM || this.order.genericMode === OrderMode.AICFS;
    };
    Object.defineProperty(GenericOrderFormComponent.prototype, "chassis", {
        get: function () {
            return this.order && this.order.chassis;
        },
        set: function (value) {
            this.order.chassis = value;
        },
        enumerable: true,
        configurable: true
    });
    GenericOrderFormComponent.prototype.openOrderSubscriptionDialog = function () {
        this.dialog.open(OrderSubscriptionDialogComponent, { width: 'auto', data: { orders: [this.order] } })
            .afterClosed().subscribe(function (res) { });
    };
    GenericOrderFormComponent.prototype.openBillingDialog = function () {
        this.dialogs.openDialog(BillingInfoListDialogComponent, {
            object: this.order,
            invoices: this.invoices,
            isReadMode: this.isReadMode
        }, {}).then();
    };
    Object.defineProperty(GenericOrderFormComponent.prototype, "masterId", {
        /*  public get hasMaster(): boolean {
            return !!this.order && !!this.order.master;
          }
        
          public get masterNumber(): string {
            return this.hasMaster ? this.order.master.masterNumber : undefined;
          }
        
          public get hasMasterLink(): boolean {
            return !!this.order && (!!this.order.master || !!this.order.data.recoveringMasterId );
          }
        
          public getMasterLink(masterId: number): any {
            return masterId && getMasterLinkExternal(masterId);
          }
        */
        get: function () {
            return this.order && (this.order.master ? this.order.master.id : this.order.data.recoveringMasterId);
        },
        enumerable: true,
        configurable: true
    });
    GenericOrderFormComponent.prototype.hasStatusInfoPageLink = function () {
        return !!(this.order && this.order.freightForwarder && this.order.freightForwarder.freightForwarderGroup);
    };
    GenericOrderFormComponent.prototype.getStatusInfoPageLink = function () {
        return buildCfsStatusPageUrl(this.order.freightForwarder.freightForwarderGroup.id, this.order.id);
    };
    GenericOrderFormComponent.prototype.copyStatusInfoUrl = function () {
        var url = this.getStatusInfoPageLink();
        copyToClipboard(url);
        this.alerts.info('Status Info Page Link copied to the Clipboard');
    };
    GenericOrderFormComponent.prototype.sendStatusInfoUrl = function () {
        var url = this.getStatusInfoPageLink();
        sendEmail(convertOrderNumber(this.order.id) + " Status Ingo Page", url);
    };
    Object.defineProperty(GenericOrderFormComponent.prototype, "isCarrierRequired", {
        get: function () {
            return this.order &&
                !OrderModes.isRecovery(this.order.genericMode);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "isRddRequired", {
        get: function () {
            return this.order &&
                !OrderModes.isRecovery(this.order.genericMode) &&
                OrderModes.isAirExportDirect(this.order.genericMode) &&
                this.order.carrier && this.order.carrier.isOwnCarrier;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericOrderFormComponent.prototype, "isCustomerRequired", {
        get: function () {
            return this.order &&
                !OrderModes.isRecovery(this.order.genericMode);
        },
        enumerable: true,
        configurable: true
    });
    GenericOrderFormComponent.prototype.onShowHistory = function () {
        var logChanges = {
            className: new Order().javaClassName,
            converter: function (value) { return value ? value.toString() : OmsConstants.EMPTY_VALUE; },
            id: this.order.id
        };
        console.log('LOG', logChanges);
        this.executeLogChanges([logChanges]);
    };
    GenericOrderFormComponent.prototype.onShowQuoteHistory = function (event) {
        event.stopPropagation();
        var logChanges = {
            className: new Order().javaClassName,
            field: 'quote',
            converter: function (value) { return value ? value.toString() : OmsConstants.EMPTY_VALUE; },
            id: this.order.id
        };
        console.log('LOG', logChanges);
        this.executeLogChanges([logChanges]);
        return false;
    };
    return GenericOrderFormComponent;
}(AbstractComponent));
export { GenericOrderFormComponent };
