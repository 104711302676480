import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'oms-widget',
  templateUrl: './oms-widget.component.html',
  styleUrls: ['./oms-widget.component.scss'],
//  encapsulation: ViewEncapsulation.None
})

export class OmsWidgetComponent implements OnInit {
  static initial_id = 0;
  @HostBinding('class') class = 'oms-widget';

  @Input() collapsible: boolean = false;
  @Input() collapsed: boolean = false;
  @Output('on-collapse') onCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() label: string;
  @Input() description: string;
  @Input() showHeader: boolean = true;
  @Input() showFooter: boolean = false;
  @Input() showToolbar: boolean = true;
  @Input() flex: boolean = false;
  @Input('header-left') headerLeft: boolean = false;
  @Input() loading: boolean = false;
  @Input('no-padding') noPadding: boolean = false;
  @Input('show-label') showLabel: boolean = true;
  @Input('min-height') minHeight;
  @Input('min-width') minWidth;
  @Input('height') @HostBinding('style.height') height;
  @Input('width') @HostBinding('style.width') width;

  @Input() iconClass: string;
  @Input() contentClass: string;
  @ViewChild('widgetContent') widgetContent: ElementRef<HTMLElement>;



  constructor(public cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  public toggleCollapsed() {
    if (this.collapsible) {
      this.collapsed = !this.collapsed;
      this.onCollapse.emit(this.collapsed);
      this.cdr.markForCheck();
    }
  }

}
