var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { OmsConstants } from '../../../../../../common/oms-constants.service';
import { ValueAccessorBase } from '../../../base/value-accessor';
import { checkNumber } from '../../../../../../_helpers/utils';
import { isNullOrUndefined } from 'util';
import { Size } from '../../../../../../common/oms-types';
var PiecesInputComponent = /** @class */ (function (_super) {
    __extends(PiecesInputComponent, _super);
    function PiecesInputComponent(cdr, _constants) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this._constants = _constants;
        _this.Size = Size;
        _this.label = 'Quantity';
        _this.size = Size.DEFAULT;
        _this.readonly = false;
        _this.disabled = false;
        _this.unitsDisabled = false;
        _this.showButton = true;
        _this.showLabel = true;
        _this.containers = false;
        _this.units = _this._constants.packagingUnits.default.label;
        _this.unitsChange = new EventEmitter();
        return _this;
    }
    PiecesInputComponent.prototype.toDisplay = function (value) { return checkNumber(value); };
    PiecesInputComponent.prototype.toDefault = function (value) { return checkNumber(value); };
    Object.defineProperty(PiecesInputComponent.prototype, "internal", {
        get: function () {
            if (isNullOrUndefined(this.inputValue)) {
                this.inputValue = this.toDisplay(_super.prototype.getValue.call(this));
            }
            return this.inputValue;
        },
        set: function (value) {
            if (value !== this.inputValue) {
                this.inputValue = value;
                _super.prototype.setValue.call(this, this.toDefault(value));
            }
        },
        enumerable: true,
        configurable: true
    });
    PiecesInputComponent.prototype.onUnitsChanged = function (event) {
        //    console.log('Units changed ', event);
        _super.prototype.setValue.call(this, this.toDefault(this.inputValue));
        this.unitsChange.next(this.units);
        this.cdr.detectChanges();
    };
    PiecesInputComponent.prototype.setValue = function (value) {
        this.inputValue = this.toDisplay(value);
        //    super.setValue(value);
        this.cdr.detectChanges();
    };
    PiecesInputComponent.prototype.ngOnChanges = function (changes) {
        if (changes['containers']) {
            console.log('Changes', changes);
            var items = this.containers ? this._constants.containerSizes.getAllLabels() : this._constants.packagingUnits.getAllLabels();
            if (items && items.length && !items.includes(this.units)) {
                this.units = items[0];
            }
            this.onUnitsChanged(this.units);
            //        this.unitsChange.next(this.units);
            this.cdr.markForCheck();
        }
    };
    return PiecesInputComponent;
}(ValueAccessorBase));
export { PiecesInputComponent };
