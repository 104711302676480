﻿import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {OmsAlertsService} from '../oms-alerts/oms-alerts.service';
import {Driver} from "../../models";
import {DriverGpsService} from "../../../../services/driver-gps-service";
import {isNullOrUndefined} from "util";
import {MapsAPILoader} from "@agm/core";
import {convertAddressForGPS} from "../../services/oms-converters.service";
import {AddressNewService} from "../../../../services/address-new-service";
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderRequest = google.maps.GeocoderRequest;
import GeocoderStatus = google.maps.GeocoderStatus;


@Component({
  templateUrl: 'address-map-info-dialog.component.html',
  styleUrls: ['./address-map-info-dialog.component.scss'],
  selector: 'oms-address-map-info-dialog'
})

export class AddressMapInfoDialogComponent implements OnInit {
  viewLoading = false;

  latitude: number;
  longitude: number;
  address: string;
  addressId: number;
  searchByAddress: boolean;
  driver: Driver;
  geoCoder: google.maps.Geocoder;


  constructor(public dialogRef: MatDialogRef<AddressMapInfoDialogComponent>,
              private mapsAPILoader: MapsAPILoader,
              public dialog: MatDialog,

              @Inject(MAT_DIALOG_DATA) public data: any,
              public driverGpsService: DriverGpsService,
              public addressService: AddressNewService,
              public alerts: OmsAlertsService) {

  }

  ngOnInit() {
    this.searchByAddress = this.data.searchByAddress;

    if (this.searchByAddress) {
      this.addressId = this.data.address.id;
      this.addressService.get(this.addressId).subscribe((addr) => {
        this.address = convertAddressForGPS(addr);
        this.mapsAPILoader.load().then(() => {
          this.geoCoder = new  google.maps.Geocoder;
          let geocoderRequest: GeocoderRequest = {address: this.address};
          let geocode_result = this.geoCoder.geocode(geocoderRequest, this.parseGeoResults.bind(this));
        });
      });
    } else {
      this.driver = this.data.driver;
      this.driverGpsService.getCurrentLocation(this.driver.id).subscribe((driverGps) => {
        this.latitude = driverGps.latitude;
        this.longitude = driverGps.longitude;
      });
    }


  }

  parseGeoResults(results: GeocoderResult[], status: GeocoderStatus) {
    this.latitude = results[0]['geometry']['location']['lat']();
    this.longitude = results[0]['geometry']['location']['lng']();
  }

  showMap() {
    return !isNullOrUndefined(this.latitude) && !isNullOrUndefined(this.longitude);
  }

  getTitle(): string {
    return this.searchByAddress ? this.address : this.driver.fullName + ' Location';
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
