<oms-base-dialog [readonly]="readonly" [title]="'Dispatch&hellip;'" [icon]="'fa fa-truck'" (ok)="onOk($event)" [width]="'650px'">

  <div class="row">
    <div class="form-group col-xs-6">
      <oms-date-input [disabled]="readonly" [label]="'Est. Pickup Date'" [(ngModel)]="data.date" [cleanable]="false" [show-checkbox]="false" required></oms-date-input>
      <oms-custom-search label="Truck #"
                         [(ngModel)] = "data.truck"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         (create)="onCreateTruck($event)"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="truckService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.number}}</div>
        </ng-template-->
      </oms-custom-search>
    </div>
    <div class="form-group col-xs-6">
      <oms-custom-search #driverInput label="Driver" required
                         [(ngModel)] = "data.driver"
                         (create)="onCreateDriver()"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="fullName"
                         [allowCreate]="true"
                         [service]="driverService">

        <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.firstName}} {{item.lastName}}</div>
        </ng-template>
      </oms-custom-search>
      <oms-custom-search label="Trailer #"
                         [(ngModel)] = "data.trailer"
                         (create)="onCreateTrailer($event)"
                         [readonly]="readonly"
                         [disabled]="readonly"
                         bindLabel="number"
                         [allowCreate]="true"
                         [service]="trailerService">

        <!--ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{item.number}}</div>
        </ng-template-->
      </oms-custom-search>
    </div>
  </div>

  <div class="row" *ngIf="!isMultiple">

    <div class="form-group col-xs-6">
      <oms-pieces-input label="PCS" required [(ngModel)] = "data.pieces" [readonly]="readonly" [disabled]="readonly">
      </oms-pieces-input>
    </div>

    <div class="form-group col-xs-6">
      <oms-pieces-input label="HU" required [readonly]="readonly" [disabled]="readonly" [(ngModel)]="data.hu">
      </oms-pieces-input>
    </div>

  </div>

  <div class="row">
    <!--div *ngFor="let master of data.masters">
      <div>{{master.mawbNumber}}</div>
      <div>{{master.cargoBuilding?.name}}</div>
      <div>{{master.addressDelivery?.name}}</div>
    </div-->

    <div class="table-responsive" style="max-height: 250px; overflow-y: auto;">
      <table class="table">
        <thead>
          <tr>
            <th><a>#</a></th>
            <th><a>M#</a></th>
            <th><a>MAWB</a></th>
            <th><a>Pick Up</a></th>
            <th><a>Delivery</a></th>
            <th></th>
            <!--th><a>Pieces</a></th-->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let master of data.masters, let i = index">
            <td>{{i+1}}</td>
            <td>{{converter.convertMasterNumber(master)}}</td>
            <td>{{converter.convertMawbNumber(master.mawbNumber)}}</td>
            <td>{{master.cargoBuilding?.name}}</td>
            <td>{{master.addressDelivery?.name}}</td>
            <!--td>{{master.pcsToRecover}}</td-->
            <td><span *ngIf="!allowDispatch(master)" tooltip="No 1C for AMS Master"><i class="fa fa-exclamation-triangle red-font">&nbsp;</i></span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!--div class="row" *ngIf="cancelEnable">
    <hr/>
    <div class="btn btn-lg btn-danger" (click)="onCancelDispatch()" style="width: 100%" >Cancel Dispatch</div>
  </div-->

  <ng-container widget-footer>
    <div class="btn btn-danger" (click)="onCancelDispatch()">Cancel Dispatch</div>
    <!--oms-checkbox style="display: block; padding-top: 5px" label="Generate PTT" [(checked)]="data.generatePtt" ></oms-checkbox-->
  </ng-container>
</oms-base-dialog>


