import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RestService} from './rest.service';
import {plainToClass} from 'class-transformer';
import {Router} from '@angular/router';
import {UserUtil} from "../modules/settings/util/user.util";
import {WebSocketService} from "./websocet/web.socket.service";
import {LoggedUser} from "../modules/shared/models/logged-user";
import {map} from "rxjs/operators";
import {UserRoleType} from "../modules/shared/models/user-role.type";

@Injectable({providedIn: 'root'})
export class AuthService {
  public user$ = new BehaviorSubject<LoggedUser>(null);
  public isClientOnly = new BehaviorSubject<boolean>(false);

  constructor(private restService: RestService,
              private router: Router,
              private http: HttpClient,
              private webSocketService: WebSocketService) {
    this.user$.next(UserUtil.getCurrentUser());
  }

  public logIn(username: string, password: string, returnUrl?: string): Promise<any> {
    return new Promise<any>((success, reject) => {
      const base64Credential: string = btoa(username + ':' + password);
      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Basic ' + base64Credential,
        'withCredentials': 'true'
      });
      this.restService.getWithHeader<LoggedUser>('/account/login', headers).subscribe(
        (data) => {
          console.log('RECEIVED', data);
          this.onUserInfo(data);
          this.router.navigateByUrl(returnUrl || '/').then();
          success(data);
        }, e => {
          reject(e);
        }
      );
    });
  }

  logOut(returnUrl?: string): Promise<any> {
//    console.log('log out...', returnUrl);
    return new Promise<any>((success, reject) => {
      this.restService.get('/account/logout').subscribe(
        () => {
          this.onUserInfo(null);
          if (!returnUrl || !returnUrl.includes('login')) {
            this.router.navigate(['/login'], {queryParams: {returnUrl: returnUrl}}).then();
          }
          success();
        }, (e) => {
          reject(e);
        });
    });
  }

  private onUserInfo(userInfo: LoggedUser) {
    const logged = userInfo ? plainToClass(LoggedUser, userInfo) : null;
    localStorage.setItem("currentUser", JSON.stringify(logged));
    if (logged) {
      this.webSocketService.connect();
    } else {
      this.webSocketService.disconnect();
    }
    this.user$.next(logged);
    this.initIsClientOnlyUser();
  }

  getUserInfo(): void {
//    console.log('get User Info...');
    this.restService.get<LoggedUser>('/account/getUserInfo').subscribe((user) => {
      console.log('User Data', user);
      this.onUserInfo(user);
    });
  }

  isRestricted(): boolean {
    let user = this.user$.getValue();
    return user && !user.haveAiDispatchAccess;
  }

  isUserInRole(role: UserRoleType): boolean {
    let user = this.user$.getValue();
    return user && user.hasRole(role);
  }

  isUserInRoles(roles: UserRoleType[]): boolean {
    return roles && !!roles.find((role) => this.isUserInRole(role));
  }

  hasBillingApprove(): boolean {
    let user = this.user$.getValue();
    return user && user.billingApprove;
  }

  private initIsClientOnlyUser(): void {
    const isClientOnly = this.isUserInRole(UserRoleType.ROLE_CLIENT)
      && !this.isUserInRole(UserRoleType.ROLE_ADMIN)
      && !this.isUserInRole(UserRoleType.ROLE_QA);
    this.isClientOnly.next(isClientOnly);
  }

  currentUserHasRole(userRole: UserRoleType): Observable<boolean> {
    return this.user$.pipe(
      map((user) => user && user.hasRole(userRole))
    );
  }

  isReadMode(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => user && user.userRoles && user.userRoles.length === 1 && user.hasRole(UserRoleType.ROLE_CUSTOMER))
    );
  }

  public getCurrentUser(): LoggedUser {
    return this.user$.getValue();
  }

  public canDeleteOrder(): boolean {
    let user = this.user$.getValue();
    return user && user.canDeleteOrder;
  }
}
