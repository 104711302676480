import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";
import {Invoice} from "../modules/shared/models/invoice";

@Injectable()
export class BillingService {
  apiUrl = '/oms/billing/';

  constructor(public httpRestService: RestService,
              public httpUtils: HttpUtilsService) {
  }

  public getInvoicesByOrderId(orderId: number): Observable<Invoice[]> {
    return this.httpRestService.get<Invoice[]>(`${this.apiUrl}order/${orderId}/invoice-list`);
  }

  public getInvoicesByMasterId(masterId: number): Observable<Invoice[]> {
    return this.httpRestService.get<Invoice[]>(`${this.apiUrl}master/${masterId}/invoice-list`);
  }


}
