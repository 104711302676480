<oms-columns-configuration></oms-columns-configuration>
<!-- Column Search template -->
<ng-template #columnFilter let-column="column">
  <span dropdown container="body" style="display: flex;" class="input-group input-group-sm" *ngIf="column.search?.searchable && column.visible && (column.search.searchType == null || column.search.searchType == 'text'|| column.search.searchType == 'boolean')">
      <input dropdownToggle class="form-control input-sm inplace" placeholder="Search..." type="text" [(ngModel)]="column.search.search"
             aria-controls="column-search-input-menu" (ngModelChange)="searchChanged()" [readonly]="searchDisabled">

      <ul *dropdownMenu id="column-search-input-menu" class="dropdown-menu" role="menu">
        <!--li class="dropdown-header">Filter:</li-->
        <li *ngIf="column.search?.searchType === 'boolean'"><a (click)="onSearch(column, 'YES')">Yes <em style="float: right;">(Checked)</em></a></li>
        <li *ngIf="column.search?.searchType === 'boolean'"><a (click)="onSearch(column, 'NO')">No <em style="float: right;">(Unchecked)</em></a></li>

        <li class="divider if-not-first-child"></li>

        <li><a (click)="onSearch(column, 'N/A')">Search N/A</a></li>
        <li><a (click)="onSearch(column, null)">Clear</a></li>
      </ul>

      <span class="input-group-btn">
        <button class="btn btn-clean btn-clear-input" (click)="clearInput(column)" *ngIf="column.search.search && column.search.search.length">
          <i class="fas fa-backspace"></i>
        </button>
      </span>
  </span>

  <!-- CUSTOM SEARCH LIST -->
  <mat-select *ngIf="column.search?.searchable && (column.search?.searchType != null && column.search?.searchType == 'list-multi')"
              #matSelectSearches class="form-control input-sm inplace filters"
              [id]="column.field"
              [multiple]="column.search.multiple"
              [(ngModel)]="column.search.selected"
              (selectionChange)="onSelectSearchItemById($event.value, column, column.search.multiple, false)">
    <mat-select-trigger>
      <span *ngIf="column.search.selected?.length == 1" class="example-additional-selection">
         {{column.search.selected ? column.search.selected[0]?.label || column.search.selected[0] : ''}}
      </span>

      <span *ngIf="column.search.selected?.length > 1" class="example-additional-selection">
        {{column.search.selected?.length+' selected'}}
      </span>

      <span *ngIf="column.search.selected?.length > 0" class="ng-clear-wrapper mat-select-clear" title="Clear all" (click)="clearSelected($event, column)">
        <span aria-hidden="true">×</span>
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let item of getHeaderSearchItems(column) | async" [value]="item"><a><label class="checkbox-mat">{{item?.label || item}}</label></a></mat-option>
  </mat-select>

  <ng-select #selectSearches *ngIf="column.search?.searchable && !column.search.displayFilter && (column.search?.searchType != null && column.search?.searchType == 'list')"
             class="form-control input-sm inplace filters"

             [id]="column.field"
             [labelForId]="column.field"
             [clearable]="true"
             [items]="getHeaderSearchItems(column) | async"
             (change)="onSelectSearchItem($event, column, column.search.multiple)"
             (clear)="onSelectSearchItem($event, column, column.search.multiple)"
             [(ngModel)]="column.search.model"
             [multiple]="column.search.multiple"
             [compareWith]="compareById"
             [searchable]="false"
             [closeOnSelect]="!column.search.multiple"

             appendTo="body"
             style="position: relative;">
    <ng-template ng-multi-label-tmp let-items="items">
      <div class="multi-selected-items">{{items.length>1 ? items.length+' selected' : items[0]?.label || items[0]}}</div>
    </ng-template>

    <!--ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!!labelTemplate">
      <ng-container *ngTemplateOutlet="labelTemplate; context:{item: item, clear: clear}"></ng-container>
    </ng-template-->

    <ng-template ng-option-tmp let-item="item">
      <li><a><label [ngClass]="{'radio': !column.search.multiple, 'checkbox': column.search.multiple}">{{item?.label || item}}</label></a></li>
    </ng-template>
  </ng-select>

  <ng-select #selectSearches *ngIf="column.search?.searchable && column.search.displayFilter && (column.search?.searchType != null && column.search?.searchType == 'list')"
             class="form-control input-sm inplace filters"

             [id]="column.field"
             [labelForId]="column.field"
             [clearable]="true"
             [items]="column.ngSelect.items$ | async"
             [loading]="column.ngSelect.loading"
             [typeahead]="column.ngSelect.input$"
             (change)="onSelectSearchItem($event, column, column.search.multiple)"
             (clear)="onSelectSearchItem($event, column, column.search.multiple)"
             (close)="column.ngSelect.input$.next('')"
             [(ngModel)]="column.search.model"
             [multiple]="column.search.multiple"
             [compareWith]="compareById"
             [searchable]="false"
             [closeOnSelect]="!column.search.multiple"
             (open)="onOpen(column)"

             appendTo="body"
             style="position: relative;">

    <ng-template ng-header-tmp>
      <span class="form-group">
        <input #searchInput style="width: 100%; line-height: 24px" class="form-control" type="text" (input)="column.doSearch($event.target.value)"/>
      </span>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items">
      <div class="multi-selected-items">{{items.length>1 ? items.length+' selected' : items[0]?.label || items[0]}}</div>
    </ng-template>

    <!--ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!!labelTemplate">
      <ng-container *ngTemplateOutlet="labelTemplate; context:{item: item, clear: clear}"></ng-container>
    </ng-template-->

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <li><a>
        <label [ngClass]="{'radio': !column.search.multiple, 'checkbox': column.search.multiple}"
               [ngOptionHighlight]="search">{{item?.label || item}}
        </label>
      </a></li>
    </ng-template>

  </ng-select>

  <!--ng-select #selectSearches [clearable]="true" *ngIf="column.search?.searchable && (column.search.searchType != null && column.search.searchType == 'list-color')"
             class="form-control input-sm inplace"
             [items]="column.search.items"
             (change)="onSelectColorSearchItem($event, column)"
             (clear)="onSelectColorSearchItem($event, column)"
             appendTo="body"
             style="position: relative;">

    <ng-template ng-label-tmp let-item="item">
      <div [class]="item.class">&nbsp;</div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <div [class]="item.class">&nbsp;</div>
    </ng-template>

  </ng-select-->

  <!-- DATE RANGE -->
  <oms-date-range-input (changing)="dateRangeSearchChanged($event, column)"
                        [showNaButton]="true" (onNaButton)="onSearch(column, 'N/A')"
                        class="inplace"
                        [size]="Size.SMALL" *ngIf="column.search?.searchable && (column.search.searchType != null && column.search.searchType == 'time')"
                        [time]="false"
                        [show-button]="false"
                        [show-clear-button]="true"
                        [ngModel]="column.search.search">
  </oms-date-range-input>

  <!-- DATE todo enable -->
  <!--oms-date-input (changing)="dateSearchChanged($event, column)"
                  class="inplace"
                  [size]="Size.SMALL" *ngIf="column.search.searchable && (column.search.searchType != null && column.search.searchType == 'time')"
                  [time]="false"
                  [show-switch]="false"
                  [show-checkbox]="false"
                  [show-button]="false"
                  [show-clear-button]="true"
                  (ngModelChanged)="searchChanged(true);"
                  [(ngModel)]="column.search.search">
  </oms-date-input-->
</ng-template>


<!-- Table Header -->
<div #headerDiv style="overflow: hidden;" [style.overflow-x]="noData ? 'auto': 'hidden'" class="table-scroller" [ngClass]="{'scrolled': scrollLeft}">
  <table #header class="table table-header-only data-table" [ngClass]="tableClassesDef">

    <thead class="data-table-scroll" style="width: 100%">
      <tr droppable [dropScope]="'column'" (onDrop)="onItemDrop($event)">
        <span class="sticky-columns">
          <th *ngIf="isTree" style="max-width: 15px; min-width: 15px;"></th>
          <th style="width: 25px; min-width: 25px" *ngIf="selectable && checkboxes" class="data-table-cell">
            <!-- Table Global Checkbox -->
              <!--span class="fancy-checkbox custom-bgcolor-green">
                <input type="checkbox" value="" checked class="table-checkbox" *ngIf="multiSelect" title="">
                <span></span>
              </span-->
              <!-- -->
            <div style="padding: 4px; vertical-align: middle; display: table-cell">
              <a container="body" dropdown="" style="white-space: nowrap; overflow-x: hidden;" (click)="loopSelectionSort()">
                <i class="fa fa-check-square"></i>
                <span class="sortable {{sortBySelected}}"></span>
              </a>
            </div>
          </th>

          <ng-container *ngFor="let column of stickyColumns; trackBy: trackFn; let i = index">
            <th oms-data-table-column
                *ngIf="column.visible"
                [style.minWidth]="column.width"
                [style.maxWidth]="column.stretch ? column.maxWidth : column.maxWidth || column.width"
                [attr.data-index]="i"
                [baseColumn]="column"
                [order]="i+1"
                [style.textAlign]="column.align">
            </th>
          </ng-container>

        </span>
        <ng-container *ngFor="let column of otherColumns; trackBy: trackFn; let i = index">
          <th oms-data-table-column
              *ngIf="column.visible"
              [style.minWidth]="column.width"
              [style.maxWidth]="column.stretch ? column.maxWidth : column.maxWidth || column.width"
              [attr.data-index]="i"
              [baseColumn]="column"
              [order]="i+1"
              [style.textAlign]="column.align">
          </th>
        </ng-container>

        <!-- scrollbar placeholder -->
        <th [style.minWidth.px]="vertScrollBarWidth" [style.maxWidth.px]="vertScrollBarWidth" style="border-left: none;" *ngIf="vertScrollbarVisible"></th>
      </tr>

      <tr class="row-filter" [hidden]="!searchable">

        <span class="sticky-columns">
          <th class="table-expand-column" *ngIf="isTree" (dblclick)="afterUpdate()" style="display: inline-block; max-width: 15px; min-width: 15px;">
            <div style="margin: auto; height: 5px; position: relative; top: 7px" (click)="toggleExpandCollapse()">
              <i class="fa fa-up-down" style="cursor: pointer; font-size: 19px">
              </i>
            </div>
          </th>

          <th dropdown container="body" style="width: 25px; min-width: 25px;" *ngIf="selectable && checkboxes" class="data-table-cell">
            <!--button type="button" class="btn btn-clean" tooltip="Clear Filters" (click)="clearTableInputs()" container="body"><i class="fa fa-trash"></i></button-->
            <!-- Table Global Checkbox -->
              <span class="fancy-checkbox custom-bgcolor-green" style="top: 5px; position: relative;" *ngIf="!multiSelect">
                <input type="checkbox" [checked]="isSelectedPartially" class="table-checkbox"  >
                <span (click)="clearSelection()"></span>
              </span>

              <span dropdownToggle aria-controls="row-selection-menu" *ngIf="multiSelect"
                class="fancy-checkbox custom-bgcolor-green" style="top: 5px; position: relative;">
                <input type="checkbox" [attr.checked]="isSelectedAll? '' : null"
                       [ngClass]="{'indeterminate':isSelectedPartially}"
                       class="table-checkbox" >
                <span></span>
                <span *ngIf="selected.length" class="badge green-bg selected-counter">{{selected.length}}</span>

                <ul *dropdownMenu id="row-selection-menu" class="dropdown-menu" role="menu">
                  <!--li class="dropdown-header">Filter:</li-->
                  <li><a (click)="selectAll()">Select All</a></li>
                  <li><a (click)="clearSelection()">Clear Selected</a></li>

                  <li class="divider if-not-first-child"></li>

                  <li [ngClass]="{'active':sortBySelected==='asc'}"><a (click)="sortBySelected='asc'">Selected First</a></li>
                  <li [ngClass]="{'active':sortBySelected==='desc'}"><a (click)="sortBySelected='desc'">Selected Last</a></li>
                  <li [ngClass]="{'active':sortBySelected===''}"><a (click)="sortBySelected=''">Show Ordered</a></li>

                  <!--li class="divider if-not-first-child"></li>

                  <li><a>Hide Selected</a></li>
                  <li><a>Show Selected Only</a></li>

                  <li class="divider if-not-first-child"></li>

                  <li><a>Show All</a></li-->
                </ul>

              </span>
            <!-- -->

          </th>

          <th [style.minWidth]="column.width"
              [style.maxWidth]="column.stretch ? column.maxWidth : column.maxWidth || column.width"
              *ngFor="let column of stickyColumns; trackBy: trackFn; let i = index"
              [attr.data-index]="i"
              [hidden]="!column.visible">
            <ng-container *ngTemplateOutlet="columnFilter;context:{column:column}"></ng-container>
          </th>
        </span>

        <th [style.minWidth]="column.width"
            [style.maxWidth]="column.stretch ? column.maxWidth : column.maxWidth || column.width"
            *ngFor="let column of otherColumns; trackBy: trackFn; let i = index"
            [attr.data-index]="i"
            [hidden]="!column.visible">
          <ng-container *ngTemplateOutlet="columnFilter;context:{column:column}"></ng-container>
        </th>

        <!-- scrollbar placeholder -->
        <th [style.minWidth.px]="vertScrollBarWidth" [style.maxWidth.px]="vertScrollBarWidth" style="border-left: none;" *ngIf="vertScrollbarVisible"></th>
      </tr>
    </thead>

  </table>
</div>

<oms-date-time-popup #dateTimePopup
                     [(value)]="editableActualDate" (valueChange)="onDateTimeEdited($event, true)"
                     [(estimated)]="editableEstimatedDate" (estimatedChange)="onDateTimeEdited($event, false)"
                     appendTo="body"
                     [showDate]="true" [showTime]="true"
                     [clearButton]="{show: true, label:'Clear', cssClass:'btn btn-danger'}"
                     [nowButton]="{show: true, label:'Now', cssClass:'btn btn-primary'}"
                     [closeButton]="{show: true, label:'Select', cssClass:'btn btn-primary'}"
                     [(showPopup)]="showDateTimePopup"></oms-date-time-popup>

<!-- Table Body -->
<!--
droppable [dropEnabled] = "!!tableDropScope" [dropScope]="tableDropScope"
-->

<div #bodyDiv  class="table-scroller" style="overflow: auto; position: relative; flex: 1; height: 100%;"
     droppable [dropEnabled] = "!!tableDropScope" [dropScope]="tableDropScope" (onDrop)="onTableDrop($event)" [dragOverClass]="'table-droppable-over'" [dragHintClass]="'table-droppable-hint'"
     [perfectScrollbar]="{suppressScrollX: false, minScrollbarLength: 50}" [disabled]="!usePerfectScrollBar"
     (psScrollX)="headerDiv.scrollLeft = $event.target.scrollLeft;"
     (scroll)="scrollLeft = headerDiv.scrollLeft = bodyDiv.scrollLeft;"
     [ngClass]="{'scrolled': scrollLeft}"
    (mousemove)="onMouseMove($event)" (mousedown)="onContentMouseDown($event)" (mouseup)="onMouseUp($event)">

  <table #table class="table table-body-only data-table" [ngClass]="tableClassesDef">
    <tbody style="width: 100%"
           class="data-table-scroll" oms-scroller *ngIf="hasData" [scrollbarV]="true" [scrollbarH]="false" [scrollHeight]="scrollHeight" (scroll)="onBodyScroll($event)">

        <!-- for virtualization: *ngFor="let row of updater.items; let i = index"-->
        <ng-container *ngFor="let row of updater.subject | async | sort : sortedByColumn : !sortedAsc : null : sortBySelected; trackBy: trackFn; let i = index">
          <div *ngIf="row.children">

            <ng-container *ngIf="rowHeaderTemplate">
              <div class="sticky-columns">
                <ng-container *ngTemplateOutlet="rowHeaderTemplate; context: {row: row}"></ng-container>
              </div>
            </ng-container>

            <ng-container *ngFor="let child of row.children; let childIndex = index">
              <oms-data-table-row #rows
                                  [odd]="(i+childIndex)%2"
                                  [top-offset]="10"
                                  [index]="i"
                                  [row]="child"
                                  [tree]="treeModel"
                                  [row-draggable]="rowDraggable"
                                  [specificRowClassHandler]="specificRowClassHandler"
                                  [selectable]="selectable"
                                  [selected]="isSelectedRow(child)"
                                  (selectedChange)="toggleSelected(child, $event)"
                                  [expandedItems]="expanded">

                <ng-template #template let-row="row" *ngIf="!!rowTemplate">
                  <ng-container *ngTemplateOutlet="rowTemplate; context:{row: child}"></ng-container>
                </ng-template>

              </oms-data-table-row>
            </ng-container>

          </div>
          <oms-data-table-row #rows
                              *ngIf="!row.children"
                              [odd]="i%2"
                              [top-offset]="10"
                              [index]="i"
                              [row]="row"
                              [tree]="treeModel"
                              [row-draggable]="rowDraggable"
                              [specificRowClassHandler]="specificRowClassHandler"
                              [selectable]="selectable"
                              [selected]="isSelectedRow(row)"
                              (selectedChange)="toggleSelected(row, $event)"
                              [expandedItems]="expanded">

            <ng-template #template let-row="row" *ngIf="!!rowTemplate">
              <ng-container *ngTemplateOutlet="rowTemplate; context:{row: row}"></ng-container>
            </ng-template>

          </oms-data-table-row>

        </ng-container>
    </tbody>
  </table>
</div>

<footer #footer class="table-footer widget-footer btn-toolbar if-not-empty" *ngIf="!noFooter">

  <!-- Page Size Selector
  <ul class="pagination pagination-sm">
    <li class="page-item" *ngFor="let item of [50, 100]; " [class.active]="item === paging.pageSize">
      <a class="page-link" (click)="setPageSize(item)">{{item}}</a>
    </li>
  </ul-->

  <pagination #pagination
              *ngIf="paging && paging.enabled"
              [hidden]="true"
    class="pagination-sm pull-right"
    (pageChanged)="setPage($event.page)"
    [ngModel]="paging.currentPage"
    [totalItems]="paging?.total"
    [itemsPerPage]="paging?.pageSize"
    [maxSize]="10"
    [boundaryLinks]="true">
  </pagination>

  <ul class="paging-body" *ngIf="paging && paging.enabled && pagination">
    <li class="paging-item paging-button" (click)="pagination.page = 1" [popover]="'First Page'" container="body">
      <a class="fa fa-angle-double-left"></a>
    </li>

    <li class="paging-item paging-button" (click)="pagination.page = pagination.page - 1" [popover]="'Previous Page'" container="body">
      <a class="fa fa-angle-left"></a>
    </li>

    <li class="paging-item" *ngIf="!simplePaging">
      <div>
        Page
        <input id="input" #inputPage class="ui-pg-input" size="2" maxlength="5"
               [value]="pagination.page"
               role="textbox" type="text"
               (focus)="inputPage.select()"
               (blur)="onSetPageNumber($event)" (keydown)="onSetPageNumber($event)">
        of {{pagination.totalPages}}
      </div>
    </li>

    <li class="paging-item" *ngIf="simplePaging">
      <div>
        {{pagination.page}}&nbsp;/&nbsp;{{pagination.totalPages}}
      </div>
    </li>


    <li class="paging-item paging-button" (click)="pagination.page = pagination.page + 1" [popover]="'Next Page'" container="body">
      <a class="fa fa-angle-right"></a>
    </li>

    <li class="paging-item paging-button" (click)="pagination.page = pagination.totalPages" [popover]="'Last Page'" container="body">
      <a class="fa fa-angle-double-right"></a>
    </li>

<!-- Split Parts
  </ul>

  <ul class="paging-body">
-->

      <li class="paging-item" *ngIf="!simplePaging">
        <div>
          <select role="listbox" [(ngModel)]="paging.pageSize" (ngModelChange)="setPageSize($event)" [popover]="'Set Page size'" container="body" *ngIf="paging.pageSizes">
            <option *ngFor="let item of paging.pageSizes || [50, 100];" role="option" [attr.value]="item">{{item}}</option>
          </select>
          <span *ngIf="!paging.pageSizes">{{paging.pageSize}}</span>
          Rows per Page
        </div>
      </li>


      <ng-container *ngIf="!simplePaging && totalTmp">
        <ng-container *ngTemplateOutlet="totalTmp; context: {paging: paging}"></ng-container>
      </ng-container>

      <li *ngIf="!simplePaging && !totalTmp" class="paging-item">
        <div>
          Total: {{paging?.total}}
        </div>
      </li>

    </ul>


</footer>
