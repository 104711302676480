import {MenuItemConfig} from "../services/left.menu.config.service";
import { UserRoleType } from "../models/user-role.type";

export class LeftMenu {
  public config: MenuItemConfig[] = [
    {label: 'Home', href: '/home', iconClass: 'fas fa-home'},
    /*{label: "Dispatch", svg: "icon-route", /!*dataTarget: "Dispatch", *!/href: "/dispatch/shipments",
/!*        children: [
        {label: "Shipment List", svg: "icon-truck-arrow", href: "/dispatch/shipments"},
        {label: "Manifest List", svg: "icon-check-list", href: "/dispatch/manifests"},
      ]*!/},*/

    /*
          {label: 'Receiving', href:'/home/receiving', iconClass:'fa-truck'},
    */
    /*     {label: "New Master", iconClass: "fa-plus-circle", dataTarget: "newMaster",
           children: [
             {label: "Air", iconClass: "fa-plane", dataTarget: "masterAir",
               children:[
                 {label: "Import", iconClass: "fa-sign-in", href:['/home/master', {new:'AIR_IMPORT'}]},
   //                {label: "Export", iconClass: "fa-sign-out", href:'/home/master'}
               ]},
   //            {label: "Ocean", iconClass: "fa-ship", href: "#"}
           ]},*/
    /*      {label: "AMS", iconClass: "fa-bullhorn", dataTarget: "CBPStatus",
            children: [
              {label: 'AMS STATUS', iconClass: 'fa-bullhorn', href: '/ams/status-list'},
              {label: 'MAWB TRACK', iconClass: 'fa-bullhorn', href: '/ams/mawb-track-list'},
              {label: 'Master FSR', iconClass: 'fa-bullhorn', href: '/ams/master-fsr-list'},
              {label: 'Uld List', iconClass: 'fa-bullhorn', href: '/ams/uld-list'}
            ]},*/
    {label: 'Orders', svg: 'icon-check-list', dataTarget: 'Orders', href: '/home/order-list'},
    {
      label: "Warehouse", svg: "icon-warehouse", dataTarget: "Warehouse", role: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_WAREHOUSE],
      children: [
        {label: 'Warehouse List', svg: 'icon-warehouse', href: '/warehouse/warehouse-list'},
        {label: 'Receive/Load Out', iconClass: 'fas fa-truck', href: '/warehouse/update'},
        {label: 'Multiple Order', iconClass: 'fa fa-plus', href: '/warehouse/multiple-order'}
      ],
    },
    {
      label: "Web Drivers", svg: "icon-truck-arrow", dataTarget: "webDrivers", href: '/webdrivers', role: [UserRoleType.ROLE_DRIVER],
    },
    {
      label: "Logs", iconClass: "fas fa-history", dataTarget: "logs", role: UserRoleType.ROLE_ADMIN,
      children: [
        {label: 'Dispatch', iconClass: 'fas fa-truck', href: '/logs/dispatch-logs-list'}
      ]
    },

    {
      label: 'Settings', iconClass: 'fas fa-cogs', dataTarget: 'Settings', role: UserRoleType.ROLE_ADMIN,
      children: [
        {label: 'Users', iconClass: 'fas fa-users', href: '/settings/users'},
        {label: 'User profiles', iconClass: 'fa-users', href: '/settings/user-profiles'},
        {label: 'Reports', svg: 'icon-check-list', href: '/settings/reports'},
        {label: 'Document Type', iconClass: 'fas fa-book', href: '/settings/document-type'},
//          {label: 'Carriers', iconClass: 'fas fa-truck', href: '/settings/carriers'},
        {label: 'User visibility filters', iconClass: 'fas fa-filter', href: '/settings/user-visibility-filters'},
        {label: 'Special Services', iconClass: 'fas fa-cog', href: '/settings/special-services'},

        {label: 'Address Book', iconClass: 'fas fa-map-marked-alt', href: '/settings/address-book'},
        {label: 'Automation', iconClass: 'fas fa-bolt', href: '/settings/automation',},

        {label: 'Transportation', iconClass: 'fas fa-truck', href: '/settings/transportation'},
        {label: 'Equipment type', iconClass: 'fas fa-truck', href: '/settings/equipment-types'},
        {label: 'Equipment', iconClass: 'fas fa-truck', href: '/settings/equipments'},

      ]
    }
  ];

  constructor() {
  }
}
