import {LoggerConfiguration} from './';

export class DefaultLoggerConfiguration implements LoggerConfiguration {
  /**
   * Turn off logging for all system
   */
  public static readonly isGlobalLoggingTurnOn = false;

  /**
   * Statistics display interval in milliseconds
   */
  public static readonly displayStatisticsInterval = 30000;

  name = null;
  isTurnOn = true;
  isLoggingEachMethod = true;
  isLoggingResult = false;
  isLoggingTime = false;
  isLoggingParentMethod = false;
  isDisplayStatistics = false;
}
