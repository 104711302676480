import {Labeled} from "../../../../common/oms-types";
import {Exclude} from "class-transformer";
import {BaseEntity} from "../base-entity";


export class DocumentType extends BaseEntity implements Labeled {
  public static IMPORTANCE_ORDER: string[] = ['POD', 'POP_POR', 'Signed_PTT'];

  name: string;
  description: string;
  type: string;
//  coefficient: number;
//  digits: number;


  public static sort(types: DocumentType[]): DocumentType[] {
    let result: DocumentType[] = [];
    if (types) {
      this.IMPORTANCE_ORDER.forEach((typeName) => result.push(types.find((type) => type.type === typeName)));
      result = result.concat(types.filter((type) => !this.IMPORTANCE_ORDER.hasEquals(type.type)));
    }
    console.log('Types', types, 'Sorted', result);
    return result;
  }

  @Exclude()
  public get label(): string {
    return name;
  }

  @Exclude()
  public get isPOD(): boolean {
    return this.type === 'POD';
  }
}
