import { DispatchService } from "../modules/shared/services/dispatch/dispatch.service";
import { ColumnIds } from './column-ids';
import { ColumnWidth } from './column-width';
import {
  BaseColumn,
  CheckboxColumn,
  CustomColumn,
  DateCheckboxColumn,
  DateColumn,
  DateTimeColumn,
  DialogLinkColumn,
  FieldType,
  HyperLinkColumn,
  TextColumn,
  WeightColumn
} from '../modules/shared/components/base/data-table/columns/column-types';
import { Carrier } from '../modules/shared/models/carrier';
import { isNullOrUndefined } from 'util';
import {
  AddressType,
  Customer,
  Driver,
  LoadType,
  Master,
  MasterShipment,
  MasterStatus,
  Order,
  Part,
  RecoveryOrder,
} from '../modules/shared/models';
import {
  composeMawbTrackLink,
  convertAddress,
  convertMasterNumber,
  convertMawbNumber,
  convertMawbPublicStatus,
  convertMawbStatus,
  convertOrderNumber,
  convertRecoveryOrderNumber,
  getOrderEditorLink,
  getOrderLink,
  order1CByAmsStatusesClass,
  order1FCByAmsStatusesClass, validMAWB
} from '../modules/shared/services/oms-converters.service';
import {
//  checkboxTypeByProblem,
  fillIndicatorClass,
  getJjsLfd,
  getStorageDue,
  getWeightReceived,
  iconFillStatus
} from "./oms-columns.utils";
import { OrderDispatch } from "../modules/shared/models/dispatch/order-dispatch";
import { FileUploadService } from "../services/file.upload.service";
import { MasterService } from "../services/master/master.service";
import { OrdersService } from "../services";
import {OmsDialogsService} from "../components/common/oms-dialogs";
import { MatDialog } from '@angular/material';
import { MasterLineService } from "../services/master/master-line.service";
import { OrderModes } from "../modules/shared/models/order/order-mode";
import { ISearchItemsFunction } from "../modules/settings/util/search-items.function";
import { MawbTableCellComponent } from "../modules/shared/components/base/data-table/data-table-cell/mawb-table-cell/mawb-table-cell.component";
import { OmsConstants } from "./oms-constants.service";
import { Observable } from "rxjs";
import { PageResult } from "../modules/shared/models/query-models/page-result";
import { CustomerService } from "../services/customer.service";
import { DateBatteryTableCellComponent } from "../modules/shared/components/base/data-table/data-table-cell/date-battery-table-cell/date-battery-table-cell.component";
import {DocCenterConfig} from "../components/common/doc-center/doc-center.component";
import {EMPTY_LINK, HyperLink} from "./oms-types";

export class ColumnType {

  public static SPLIT_DISPATCH_COLUMN = new CheckboxColumn(14, 'Dispatch', 'isDispatched', FieldType.BOOLEAN, '120px')
    .setAlign('start').setSortField('splitNumber')
    .setEditor({
      editable: true/*(split:Split) => !split.isReceived || !split.isDispatched*/,
      placeholder: (split: RecoveryOrder) => split.getRouteStatus(),
      postUpdate: true
    });

/*
  public static readonly PROBLEM = new CheckboxColumn(ColumnIds.MASTER_PROBLEM, 'Prob', 'problems', FieldType.DIALOG, '80px',
    {
      class: (object) => {
        return checkboxTypeByProblem(object.problemStatus);
      }, tooltip: (object) => object.problemDesc, converter: (row, value) => {
        return value && value.length;
      }
    })
    .setEditor(true)
    .setHistory({logChanges: true, methodForExecution: 'executeMasterProblemLogChanges'})
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.selectFilterItems
    });
 */

  public static readonly ORDER_CFS_LAST_FREE_DAY = ((statuses: MasterStatus[] | ISearchItemsFunction<MasterStatus>) => new DateColumn('dateCfsFreeTime', 'CFS LFD', 'dateCfsFreeTime', ColumnWidth.DATE, null)
    .setEditor(true)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time', items: statuses})
    .setHistory({logChanges: true}));

  public static readonly ORDER_DELIVERY_DRIVER: BaseColumn = new TextColumn('deliveryDriver', 'Driver', 'deliveryDriver', FieldType.TEXT, '120px')
    .setEditor({editable: false})
    .setSearch({searchable: true, search: '', searchFunction: '', baseLineSearch: true});

  public static readonly ORDER_RCVD = new TextColumn(ColumnIds.ORDER_FILL_STATUS, 'RCVD', 'fillStatus', FieldType.TEXT, '50px',
    {
      converter: (row: Order) => (row instanceof Order && !OrderModes.isAirImport(row.genericMode)) ? '-' : null,
      tooltip: (row) => row.fillStatusDescription,
      icon: iconFillStatus,
      class: fillIndicatorClass
    }, null, 'center').setEditor(false);

  public static readonly CARRIER = new BaseColumn('carrier.name', 'Carrier', 'carrier', FieldType.TEXT, '110px')
    .setHandlers({converter: (row, carrier: Carrier) => carrier && carrier.name})
    .setEditor({editable: true, cleanable: true});

  public static readonly ORDER_1C = new DateCheckboxColumn('hasDate1C', '1C', 'date1C', '50px', {class: (o) => order1CByAmsStatusesClass(o)})
    .setEditor({editable: o => !o.nonAMS})
    .setHidden(o => o.nonAMS)
    .setHistory({logChangesWithCbpStatuses: true})
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS
    });

  public static readonly DATE_DO = new DateCheckboxColumn('hasDateDO', 'D/O', 'deliveryApproval', '50px')
    .setEditor(true)
    .setSortable(true)
    .setHistory({logChanges: true})
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS
    });

  public static readonly DATE_BILLED = new DateCheckboxColumn('hasDateBilled', 'Billed', 'dateBilled', '50px')
    .setEditor(false)
    .setSortable(true)
    .setHistory({logChanges: true})
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS
    });

  public static readonly LAST_UPDATED_DATE = new DateColumn('dateUpdated', 'Updated', 'dateUpdated', ColumnWidth.DATE, null)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'});

  public static readonly LAST_UPDATED_DATE_TIME = new DateTimeColumn('dateUpdated', 'Updated', 'dateUpdated', ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'});

  public static readonly UPDATED_BY = new TextColumn('updatedBy', 'Updated By', 'updatedBy', FieldType.TEXT, ColumnWidth.DATE_TIME)
    .setSearch({searchable: true})
    .setEditor({editable: false, cleanable: false});

  public static readonly DELETED_DATE = new DateColumn('dateDeleted', 'Deleted', 'dateDeleted', ColumnWidth.DATE, null)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'});

  public static readonly ORDER_1F = new DateCheckboxColumn('hasDate1F', '1F', 'date1F', '50px', {
    class: (o) => order1FCByAmsStatusesClass(o)
  })
    .setEditor({editable: o => !o.nonAMS}).setHidden(o => o.nonAMS)
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS
    }).setHistory({logChangesWithCbpStatuses: true});

  public static readonly MASTER_1F_BATTERY = new CustomColumn(ColumnIds.MASTER_1F, '1F', 'date1F', FieldType.TEXT, '25px')
    .setComponent(DateBatteryTableCellComponent, {is1F: true})
    .setHidden(m => m.nonAMS)
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: "isSelectedValue",
      searchType: "list",
      items: BaseColumn.YES_NO_FILTERS
    })
    .setHistory({logChangesWithCbpStatuses: true, field: 'firstShipment.date1F'});

  public static readonly ORDER_1F_BATTERY = new CustomColumn('hasDate1F', '1F', 'date1F', FieldType.TEXT, '50px')
    .setComponent(DateBatteryTableCellComponent, {is1F: true, readonly: true})
    .setHidden(o => o.nonAMS)
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS
    }).setHistory({logChangesWithCbpStatuses: true});

  public static readonly MASTER_1C_BATTERY = new CustomColumn(ColumnIds.MASTER_1C, '1C', 'date1C', FieldType.DATE, '25px')
    .setComponent(DateBatteryTableCellComponent, {is1F: false, readonly: true})
    .setHidden(m => m.nonAMS)
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: "isSelectedValue",
      searchType: "list",
      items: BaseColumn.ynFilterItems
    })
    .setHistory({logChangesWithCbpStatuses: true, logConverter: true});

  public static readonly ORDER_1C_BATTERY = new CustomColumn(ColumnIds.ORDER_1C, '1C', 'date1C', FieldType.TEXT, '25px')
    .setComponent(DateBatteryTableCellComponent, {is1F: false})
    .setHidden(o => o.nonAMS)
    .setHistory({logChangesWithCbpStatuses: true})
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: "isSelectedValue",
      searchType: "list",
      items: BaseColumn.ynFilterItems
    });

  public static readonly ORDER_CFS_IN = new DateTimeColumn('dateCfsInAct', 'CFS In', {
    actual: 'dateCfsInAct',
    estimated: 'dateCfsInEst'
  }, ColumnWidth.DATE_TIME, null, {})
    .setEditor(false/*{editable: o => !o.noCfs}*/).setHidden(o => o.noCfs)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
    .setHistory({logChanges: true, field: {actual: 'dateCfsInAct', estimated: 'dateCfsInEst'}});

  public static readonly FREIGHT_FORWARDER = new BaseColumn('freightForwarder.name', 'FF', 'freightForwarder.name', FieldType.TEXT, '100px',
    null)
    .setSearch({searchable: true})
    .setHistory({logChanges: true});

  public static readonly FREIGHT_FORWARDER_REF = new TextColumn(ColumnIds.MASTER_FREIGHT_FORWARDER_REF, 'FF Ref', 'freightForwarderRef', FieldType.TEXT, '150px',
    null)
    .setSearch({searchable: true})
    .setVisible(false)
    .setHistory({logChanges: true});

  public static readonly ORDER_TRUCK = new TextColumn('truck.number', 'Truck', 'truck.number', FieldType.TEXT, '100px',
    null);

  public static readonly ORDER_TRAILER = new TextColumn('trailer.number', 'Trailer', 'trailer.number', FieldType.TEXT, '100px',
    null);

  public static readonly ORDER_JJS_LFD = new BaseColumn('', 'JJS LFR', (order) => getJjsLfd(order), FieldType.TEXT, '60px');

  public static readonly ORDER_JJS_STORAGE_DUE = new BaseColumn('', 'Storage', (order) => getStorageDue(order), FieldType.TEXT, '60px')
    .setAlign("end");

  public static readonly MASTER_CFS_LOCATION = new BaseColumn('masterShipments.shipment.shipmentContents.order.cfsLocation.name', 'CFS Location', master => getMasterCfsLocation(master), FieldType.TEXT, '100px');

  public static readonly ORDER_CFS_LOCATION = new BaseColumn('addressCfs.name', 'CFS Location', 'addressCfs.name', FieldType.TEXT, '80px');

  public static readonly ORDER_WHSE = new BaseColumn('addressCfs.name', 'WHSE', 'addressCfs.name', FieldType.TEXT, '80px');

  public static readonly ORDER_WHSE_LOC = new BaseColumn('cfsLocation.name', 'WHSE Loc', 'cfsLocation.name', FieldType.TEXT, '60px');

  public static readonly MASTER_JJSM = new HyperLinkColumn(ColumnIds.MASTER_ID, 'M#', 'id', FieldType.TEXT, ColumnWidth.MASTER_NUMBER,
    {
//      icon: (row) => row instanceof RecoveryOrder ? null : null,
      sorted: {asc: false}, getter: getDocumentNumber, link: getDocumentLink
    }, null, 'end')
    .setNewWindow(true)
    .setSearch({searchable: true, searchFunction: 'findByIndexOf', baseLineSearch: true});

  public static readonly CREATED_BY = new TextColumn('createdBy', 'Created By', 'createdBy', FieldType.TEXT, ColumnWidth.DATE_TIME)
    .setSearch({searchable: true})
    .setEditor({editable: false, cleanable: false});

  public static readonly REQUESTED_BY = new TextColumn('requestedBy', 'Requested By', 'requestedBy', FieldType.TEXT, ColumnWidth.DATE_TIME)
    .setSearch({searchable: true})
    .setEditor(false);
 //   .setEditor({editable: true, cleanable: false});

  public static readonly DATE_CREATED = new DateTimeColumn('dateCreated', 'Created Date', 'dateCreated', ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'});


  public static readonly ORDER_PO = new TextColumn('purchaseOrder', 'PO Number', 'purchaseOrder', FieldType.TEXT, '150px',
    null)
    .setEditor({editable: true});

  public static readonly DISPATCHED_MANIFEST_ID = new HyperLinkColumn('dispatchId', 'Manifest#', 'dispatchId', FieldType.TEXT, '100px');

  public static readonly DISPATCHED_ADDRESS_PICKUP_NAME = new HyperLinkColumn('addressPickupName', 'Fm Location', 'addressPickup.name', FieldType.TEXT, ColumnWidth.NAME,
    {tooltip: o => convertAddress(o.addressPickup)})
    .setStretch(false);

  public static readonly DISPATCHED_ADDRESS_DELIVERY_NAME = new HyperLinkColumn('addressDeliveryName', 'To Location', 'addressDelivery.name', FieldType.TEXT, ColumnWidth.NAME,
    {tooltip: o => convertAddress(o.addressDelivery)})
    .setStretch(false);

  public static readonly DISPATCHED_DATE_PICKUP = new DateTimeColumn('datePickup', 'Pick Up', {
    actual: 'datePickupAct',
    estimated: 'datePickupEst'
  }, ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'}).setEditor(false);

  public static readonly DISPATCHED_DATE_DELIVERY = new DateTimeColumn('dateDelivery', 'Delivery', {
    actual: 'dateDeliveryAct',
    estimated: 'dateDeliveryEst'
  }, ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'}).setEditor(false);

  public static readonly DISPATCHED_DATE_CREATED = new BaseColumn('dateCreated', 'Manifested Date', 'dateCreated', FieldType.DATETIME, ColumnWidth.DATE_TIME);
  public static readonly DISPATCHED_CREATED_BY = new TextColumn('createdBy', 'Manifested By', 'createdBy', FieldType.TEXT, ColumnWidth.DATE_TIME);

  public static readonly ORDER_FF = new TextColumn('freightForwarder.name', 'Forwarder', 'freightForwarder', FieldType.TEXT, '70px', {converter: (row, value) => value ? value.name : "N/A"}, null)
    .setEditor({editable: false})
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: "findCustomerByNameIndexOf",
      baseLineSearch: true
    })
    .setSortField('name');


  public static readonly ORDER_NUMBER = ((newTab: boolean | 'auto') =>
      new HyperLinkColumn('id', 'J#', 'id', FieldType.TEXT, '60px',
        {
//          icon: (row) => row instanceof RecoveryOrder ? null : null,
          sorted: {asc: false},
          getter: getDocumentNumber,
          link: (order, row, newWindow) => getOrderEditorLink(order.id, !newWindow)
        }, null, 'end')
        .setNewWindow(newTab)
        .setSearch({searchable: true, searchFunction: 'findByIndexOf', baseLineSearch: true})
  );


  /**
   * @deprecated
   */
  public static readonly ORDER_JJSM = ((jjsToOrderEditor: boolean) =>
      new HyperLinkColumn('id', jjsToOrderEditor ? 'J#' : 'M#', 'id', FieldType.TEXT, ColumnWidth.MASTER_NUMBER,
        {
//          icon: (row) => row instanceof RecoveryOrder ? null : null,
          sorted: {asc: false},
          getter: getDocumentNumber,
          link: (order) => jjsToOrderEditor ? getOrderLink(order) : getMasterLink(order.masterId)
        }, null, 'end')
        .setNewWindow(true)
        .setSearch({searchable: true, searchFunction: 'findByIndexOf', baseLineSearch: true})
  );

  /**
   * @deprecated
   * use ORDER_DOC_CENTER
   */
  public static readonly ORDER_DOCS = new DialogLinkColumn(ColumnIds.MASTER_DOCS, '', 'documents', FieldType.TEXT, '23px', {
    header: {
      noarrows: true,
      icon: 'fa fa-paperclip'
    }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
      return value ? '' : 'fa fa-paperclip';
    }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
  }).setSearch(false).setEditor(false);

  public static readonly ORDER_OSD = new DialogLinkColumn(ColumnIds.MASTER_OSD, '', 'isOSD', FieldType.TEXT, '15px', {
    header: {
      noarrows: true,
      icon: 'fa fa-exclamation-triangle'
    }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
      return value ? '' : 'fa fa-exclamation-triangle';
    }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
  }).setSearch(false);

  public static readonly ORDER_HAZ = new DialogLinkColumn(ColumnIds.MASTER_HAZ, '', 'hazardousNotes', FieldType.TEXT, '15px', {
    header: {
      noarrows: true,
      icon: 'fa fa-exclamation-circle'
    }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
      return value ? '' : 'fa fa-exclamation-circle';
    }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
  }).setSearch(false);

  public static readonly ORDER_COM = new DialogLinkColumn(ColumnIds.MASTER_COM, '', 'defaultNotes', FieldType.TEXT, '15px', {
    header: {
      noarrows: true,
      icon: 'fa fa-comments'
    }, converter: (row, value) => isNullOrUndefined(value) || value === 0 ? '' : value, icon: (row, value) => {
      return value ? '' : 'fa fa-comments';
    }, link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
  }).setSearch(false);

  public static readonly ORDER_MAWB = ((showTrackButton: boolean) => new BaseColumn('ref3',
    'MAWB#', 'mawb', FieldType.TEXT, '120px',
    {
      converter: (row, value) => convertMawbNumber(value)
    })
    .setButton(showTrackButton ? {
          link: (row, value) => composeMawbTrackLink(value),
          newPage: true,
          icon: (row, value) => validMAWB(value) ? 'fas fa-external-link-alt' : undefined,
          tooltip: 'Track MAWB'
    } : undefined));

  public static readonly ORDER_HAWB = ((isFCL: boolean) => new BaseColumn('hawb', isFCL ? "CNTR#" : "HAWB#", 'hawb', FieldType.TEXT, '110px')
    .setHistory(true));

  public static readonly ORDER_CUSTOMER = ((customerService: CustomerService) =>
    new BaseColumn('customer.id', 'Customer', 'customer.name', FieldType.TEXT, '120px',
      {tooltip: (r) => r.customer ? r.customer.name : 'N/A'})
    .setSearch({
      searchable: true,
      searchType: 'list',
      multiple: true,
      displayFilter: true,
      items: (text: string) => searchCustomer(customerService, text)
    }));

  public static readonly ORDER_CUSTOMER_SEARCH = ((search: (s: string) => Observable<PageResult<Customer>>) =>
    new BaseColumn('customer.id', 'Customer', 'customer.name', FieldType.TEXT, '120px',
      {tooltip: (r) => r.customer ? r.customer.name : 'N/A'})
      .setSearch({
        searchable: true,
        searchType: 'list',
        multiple: true,
        displayFilter: true,
        items: (text: string) => {
          console.log('COLUMN SEARCH: ', text);
          return search(text);
        }
      }));

  public static readonly ORDER_CUSTOMER_REF = new BaseColumn('customerRef', 'Customer Ref', 'customerRef', FieldType.TEXT, '130px')
    .setSortField('customerRef')
    .setEditor(true)
    .setHistory(true);

  public static readonly ORDER_HU = new TextColumn(ColumnIds.ORDER_HU, 'HU', 'hu', FieldType.TEXT, '30px', null, null, 'end')
    .setSearch(false);

  public static readonly ORDER_PCS = ((piecesMask: string, piecesPlaceholder: string, isFcl: boolean = false) =>
    new BaseColumn(ColumnIds.ORDER_PCS, isFcl ? 'CNT' : 'PCS', 'pieces', FieldType.TEXT, '50px', {
      converter: (row, value) => isFcl || OrderModes.isFCL(row.genericMode) ? row.pcsUnits : value
    })
      .setAlign('end')
      .setSearch(false)
      .setHistory({logChanges: true, field: 'pieces'}));

  public static readonly ORDER_WEIGHT_KG = new WeightColumn(ColumnIds.MASTER_WEIGHT, 'KG', 'weight', FieldType.TEXT, '60px')
    .setSearch(false)
    .setEditor(false)
    .setHistory({logChanges: true, field: 'weight'});

  public static readonly ORDER_STATUS = ((statuses: MasterStatus[] | ISearchItemsFunction<MasterStatus>, isPublic: boolean = false) => new TextColumn('status', 'Status', 'status', FieldType.TEXT, '70px',
    {
      sorted: {asc: true},
      converter: (row, value) => isPublic ? convertMawbPublicStatus(value) : convertMawbStatus(value),
    })
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'itemNumberEquals',
      searchType: 'list-multi',
      items: statuses,
      multiple: true
    })
    .setHistory({logChanges: true, field: 'firstShipment.status'}));

  public static readonly PUBLIC_ORDER_STATUS = ((statuses: MasterStatus[] | ISearchItemsFunction<MasterStatus>, tooltip: string) => new TextColumn('status', 'Status', 'status', FieldType.TEXT, '70px',
    {
      header: {tooltip:  tooltip},
      sorted: {asc: true},
      converter: (row, value) => convertMawbPublicStatus(value),
    })
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'itemNumberEquals',
      searchType: 'list-multi',
      items: statuses,
      multiple: true
    })
    .setHistory({logChanges: true, field: 'firstShipment.status'}));

  public static readonly ORDER_DELIVERY_LOCATION = new HyperLinkColumn('addressDelivery.name', 'Delivery Location', 'addressDelivery', FieldType.ADDRESS, ColumnWidth.ADDRESS)
    .setHandlers({tooltip: o => convertAddress(o.addressDelivery)})
    .setSearch({
      searchable: true,
      searchType: 'text',
      search: '',
      searchFunction: 'findByNameIndexOf',
      baseLineSearch: true
    })
    .setEditor({
      editable: true,
      select: {addressTypes: [AddressType.DELIVERY_LOCATION]}
    })
    .setSortField('name');


  public static readonly ORDER_CFS_OUT_DATE = ((masterService: MasterService) => new DateTimeColumn('dateCfsOutAct', 'CFS Out', {
    actual: 'dateCfsOutAct',
    estimated: 'dateCfsOutEst'
  }, ColumnWidth.DATE_TIME, null, {})
    .setEditor(false)
    // .setEditor({editable: (o: Order) => (!o.noCfs && !masterService.isDisableOrder(o))}).setHidden(o => o.noCfs)
    .setSearch(false).setHistory({
      logChanges: true,
      field: {actual: 'dateCfsOutAct', estimated: 'dateCfsOutEst'}
    }));

  public static readonly ORDER_PICKUP_DATE = ((masterService: MasterService) =>
    new DateTimeColumn('datePickupAct', 'Pick up',
      {
      actual: 'datePickupAct',
      estimated: 'datePickupEst'
  }, ColumnWidth.DATE_TIME, null, {})
    .setEditor(false)
   // .setEditor({editable: (o: Order) => (!o.noCfs && !masterService.isDisableOrder(o))}).setHidden(o => o.noCfs)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
    .setHistory({
      logChanges: true,
      field: {actual: 'dateCfsOutAct', estimated: 'dateCfsOutEst'}
    }));

  public static readonly ORDER_DATE_DOCS_TO_WAREHOUSE = (() => new DateTimeColumn('dateDocsToWarehouse', 'Docs to WHSE',
    'dateDocsToWarehouse', ColumnWidth.DATE_TIME, null, {})
    .setEditor(false)
    // .setEditor({editable: (o: Order) => (!o.noCfs && !masterService.isDisableOrder(o))}).setHidden(o => o.noCfs)
    .setSearch(false)
    .setHistory({
      logChanges: true,
      field: 'dateDocsToWarehouse'
    }));


  public static readonly ORDER_DATE_DELIVERY_REQUESTED = new DateTimeColumn('requestedDeliveryDate', 'RDD', 'requestedDeliveryDate', ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
    .setHistory({field: 'requestedDeliveryDate', logChanges: true});

  public static readonly ORDER_DELIVERY_DATE = (() => new DateTimeColumn('dateDelivery', 'Delivery', {
    actual: 'dateDeliveryAct',
    estimated: 'dateDeliveryReq'
  }, ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
    .setEditor(false)
   // .setEditor({editable: (o: Order) => !masterService.isDisableOrder(o)})
    .setHistory({logChanges: true, field: {actual: 'dateDeliveryAct', estimated: 'dateDeliveryReq'}}));


  public static readonly ORDER_PICKUP_ADDRESS = new HyperLinkColumn('pickupAddress', 'Pick up', (o) => getOrderPickupLocation(o), FieldType.TEXT, '150px')
    .setHandlers({tooltip: o => convertAddress(o.addressCfs)});


  public static readonly ORDER_CUSTOM_REF3 = ((isFcl: boolean) => new CustomColumn('ref3', isFcl ? "MBL" : "MAWB#", 'ref3', FieldType.TEXT, isFcl ? '150px' : '120px')
    .setComponent(MawbTableCellComponent)
    .setEditor({
      editable: false,
      mask: OmsConstants.mawbMask,
      clearMask: true,
      showTracking: true,
      placeholder: OmsConstants.mawbPlaceholder,
      cleanable: false
    })
    .setHistory({logChanges: true})
    .setSearch({searchable: true, search: '', searchFunction: "findByFirst3OrLast4Chars", baseLineSearch: true}));

  public static readonly ORDER_REF3 = ((isFcl: boolean) => new BaseColumn('ref3', isFcl ? "MBL" : "MAWB#",
    'ref3', FieldType.TEXT, isFcl ? '150px' : '120px', {converter: (row, value) => convertMawbNumber(value)})
    .setHistory(true));

  public static readonly ORDER_CHASSIS = new TextColumn("chassis", 'Chassis', 'chassis', FieldType.TEXT, '120px', null, null, 'end')
    .setSearch({searchable: true, searchFunction: 'findByIndexOf', baseLineSearch: true})
    .setHistory(false);

  public static readonly ORDER_LOCATION_FROM = new BaseColumn('addressCfs.name', 'From', 'addressCfs', FieldType.ADDRESS, ColumnWidth.ADDRESS)
    .setSearch({searchable: true, search: '', searchFunction: 'findByNameIndexOf', baseLineSearch: true})
    .setEditor({
      editable: true,
      select: {addressTypes: [AddressType.DELIVERY_LOCATION]}
    })
    .setSortField('name')
    .setHistory(true);

  public static readonly ORDER_LOCATION_TO = new BaseColumn('addressDelivery.name', 'To', 'addressDelivery', FieldType.ADDRESS, ColumnWidth.ADDRESS)
    .setSearch({searchable: true, search: '', searchFunction: 'findByNameIndexOf', baseLineSearch: true})
    .setEditor({
      editable: true,
      select: {addressTypes: [AddressType.CFS_LOCATION]}
    })
    .setSortField('name')
    .setHistory(true);

  public static readonly DISPATCH_DOC_CENTER = ((ordersService: OrdersService, dialogs: OmsDialogsService) => new HyperLinkColumn('docCount', '', 'docCount', FieldType.TEXT, '25px', {
    header: {noarrows: true, icon: 'fa fa-paperclip'},
    converter: (row, value) => value || '',
    onClick: (row: OrderDispatch) => dialogs.openDocCenterWindow({id: row.orderId, objectType: 'ORDER'}),
    icon: (row, value) => {
      let res = '';
      if (!value) {
        res += 'fa fa-paperclip emptyValueIcon';
      }
      if (!row.hasPodDocuments && row.loadType === LoadType.DELIVERY) {
        res += ' warning';
      }
      return res;
    },
  })
    .setSortable(false)
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'list',
      searchType: 'list',
      items: BaseColumn.POD_YES_NO_FILTERS,
      field: 'hasPodDocuments'
    })
    .setEditor(false));

  public static readonly ORDER_DOC_CENTER = ((dialog: boolean, ordersService: OrdersService, dialogs: OmsDialogsService, onConfig?: (config: DocCenterConfig) => void) => new HyperLinkColumn('documents', '', 'documents', FieldType.TEXT, '25px', {
    header: {noarrows: true, icon: 'fa fa-paperclip'},
    converter: (row, value) => value || '',

    onClick: (row: Order) => {
        let config: DocCenterConfig = {id: row.id, objectType: 'ORDER'};

        if (onConfig) {
          onConfig(config);
        }

        if (dialog) {
          dialogs.openDocCenterDialog(config);
        } else {
          dialogs.openDocCenterWindow(config);
        }},

    icon: (row: Order, value: number) => value ? '' : 'fa fa-paperclip emptyValueIcon'})

    .setSortable(true)
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'list',
      searchType: 'list',
      items: BaseColumn.POD_YES_NO_FILTERS,
      field: 'podDocuments'
    })
    .setEditor(false));


  public static readonly MASTER_DOC_CENTER = ((masterService: MasterLineService, dialog: MatDialog, dialogs: OmsDialogsService) =>
    new HyperLinkColumn('documents', '', 'documents', FieldType.TEXT, '25px', {
      header: {noarrows: true, icon: 'fa fa-paperclip'},
      converter: (row, value) => value || '',
      onClick: (row: Master | Order | RecoveryOrder) => {
        dialogs.openObjectDocCenterDialog(row);
      },
      icon: (row: Order, value: number) => !value ? 'fa fa-paperclip emptyValueIcon' : ''
    })
      .setSortable(true)
      .setSearch({
        searchable: true,
        search: '',
        searchFunction: 'list',
        searchType: 'list',
        items: BaseColumn.POD_OR_Signed_PTT_YES_NO_FILTERS,
        field: 'podOrSignedPTTDocuments'
      })
      .setEditor(false));

  public static readonly ORDER_MODE = new TextColumn('orderMode', 'Mode', 'orderMode', FieldType.TEXT, '50px',
    {converter: (row, value) => OrderModes.nameOf(value), tooltip: (row) => OrderModes.labelOf(row.orderMode)})
    .setSearch({searchable: true, searchType: 'list', items: OrderModes.filterItems, multiple: true});

  public static readonly GENERIC_MODE = new TextColumn('genericMode', 'Mode', 'genericMode', FieldType.TEXT, '50px',
    {converter: (row, value) => OrderModes.nameOf(value), tooltip: (row) => OrderModes.labelOf(row.genericMode)})
    .setSearch({searchable: true, searchType: 'list', items: OrderModes.filterItems, multiple: true});

  public static readonly EMPTY_COLUMN = new TextColumn('', '', '', FieldType.TEXT, '50px',
    {converter: (row, value) => value ? '' : ''});
  public static readonly ORDER_HU_RECEIVED = new TextColumn('huReceived', 'R.HU', 'huReceived', FieldType.TEXT, '50px', null, null, 'end')
    .setSearch(false);
  public static readonly ORDER_WEIGHT_KG_RECEIVED = new WeightColumn('', 'R.KG', (order) => getWeightReceived(order), FieldType.TEXT, '60px')
    .setSearch(false)
    .setEditor(true)
    .setHistory({logChanges: true, field: 'weight'});

  public static readonly ORDER_PCS_RECEIVED = ((piecesMask: string, piecesPlaceholder: string) => new BaseColumn('pcsReceived', 'R.PCS', 'pcsReceived', FieldType.TEXT, '50px')
    .setAlign('end')
    .setSearch(false)
    .setEditor({
      editable: true,
      cleanable: false,
      clearMask: true,
      mask: {mask: piecesMask},
      placeholder: piecesPlaceholder
    })
    .setHistory({logChanges: true, field: 'pcsReceived'}));

  public static readonly ORDER_LAST_DOC = ((field: string, fileUploadService: FileUploadService, label: string, documentType: string) =>
    new HyperLinkColumn(field, label, field, FieldType.TEXT, '25px',
    {
      header: {noarrows: true, icon: 'fa fa-paperclip'},
      converter: (row, value) => value ? '' : '',
      onClick: (row: Order) => downloadLastOrderDocument(row, fileUploadService, documentType),
      icon: (row: Order, value: number) => value ? 'fas fa-download' : 'fas fa-download emptyValueIcon',
    })
    .setSortable(true)
    .setSearch(false)
    .setEditor(false));

  public static readonly MASTER_LAST_DOC = ((field: string, fileUploadService: FileUploadService, label: string, documentType: string) =>
    new HyperLinkColumn(field, label, field, FieldType.TEXT, '25px',
      {
        header: {noarrows: true, icon: 'fa fa-paperclip'},
        converter: (row, value) => value ? '' : '',
        onClick: (row: Order) => downloadLastMasterDocument(row, fileUploadService, documentType),
        icon: (row: Order, value: number) => value ? 'fas fa-download' : 'fas fa-download emptyValueIcon',
      })
      .setSortable(true)
      .setSearch(false)
      .setEditor(false));

  /*this.dialog.open(DocCenterDialogComponent, {
          width: '80%',
          data: {
            id: this.rowObject.id,
            object: this.rowObject,
            objectType: this.rowObject.rowId.toUpperCase(),
            readonly: this.table.readonly
          }
        });*/
}


function getOrderPickupLocation(order: Order): string {
  let add = order.direct ? order.addressCargoBuilding : order.addressCfs;
  if (add) {
    return add.name;
  }
  return null;
}

let getMasterLink = function (masterId): HyperLink {
  return {path: ['master'], params: {id: masterId}};
};

function getMasterCfsLocation(master: Master): string {
  let items = master.orders
    .map(order => getOrderCfsLocation(order))
    .filter(cfsLocation => !!cfsLocation);
  if (!items.length) {
    return null;
  }
  if (items.some(item => item !== items[0])) {
    return 'Various';
  }
  return items[0];
}

function getOrderCfsLocation(order: Order): string {
  if (order && order.addressCfs) {
    return order.addressCfs.name;
  }
  return null;
}

function searchCustomer(customerService: CustomerService, searchText: string): Observable<PageResult<Customer>> {
  return customerService.findByObs(searchText, 100, true);
}

function downloadLastOrderDocument(order: Order, fileUploadService: FileUploadService, docType: String) {
  fileUploadService.downloadLastActiveOrderDocByOrderId(order.id, docType);
}

function downloadLastMasterDocument(order: Order, fileUploadService: FileUploadService, docType: String) {
  fileUploadService.downloadLastActiveMasterDocByOrderId(order.id, docType);
}

export function getDocumentNumber(value) {
  if (value instanceof Master) {
    return convertMasterNumber(value.id);
  }

  if (value instanceof MasterShipment) {
    return convertMasterNumber(value.master.id)/*+'-'+value.splitNumber*/;
  }
  if (value instanceof Order) {
    return convertOrderNumber(value.id, value.isRecovery);
  }

  if (value instanceof Part) {
    return convertOrderNumber(value.id);
  }

  if (value instanceof RecoveryOrder) {
    return convertRecoveryOrderNumber(value.orderId, value);
  }

  return value && value.label;
}

export function getDocumentLink(row): HyperLink {
  if (row instanceof Master) {
    return {path: ['/home/master'], params: {id: row.id}};
  }

  if (row instanceof Order) {
    return {path: ['/home/order'], params: {id: row.id}};
  }

  if (row instanceof Part) {
    return {path: ['order'], params: {id: row.id}};
  }

  if (row instanceof RecoveryOrder) {
    console.log('URL: ', row);
    return {path: ['/warehouse', 'update'], params: {mode: 'receiving', ids: 'M' + row.masterId, rld: row.loadId}};
  }

}

export function generateManifest(service: DispatchService, item: OrderDispatch) {
  window.open(service.generateManifest(item.dispatchId), '_blank');
}

export function downloadManifest(file: FileUploadService, item: OrderDispatch) {
  window.open(file.buildDownloadFileUrl(item.manifestDocument.id), '_blank');
}
