var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RestService } from './rest.service';
import { HttpUtilsService } from './http-utils.service';
import { BaseEntityService } from './base/base-entity.service';
import { classToPlain, plainToClass } from "class-transformer";
import { map } from "rxjs/operators";
import { ErrorObservable } from "rxjs-compat/observable/ErrorObservable";
import { OrderWarehouse } from "../modules/shared/models/warehouse/order.warehouse";
import { Master } from "../modules/shared/models";
var OrderWarehouseService = /** @class */ (function (_super) {
    __extends(OrderWarehouseService, _super);
    function OrderWarehouseService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/order-warehouse/';
        _this.classType = OrderWarehouse;
        return _this;
    }
    /** @deprecated **/
    OrderWarehouseService.prototype.receiveOrders = function (data) {
        console.log('RECEIVE CALL', data);
        var sent = classToPlain(data, { enableCircularCheck: true });
        return this.httpRestService.put(this.apiUrl + 'receive', sent, this.httpUtils.getHTTPHeaders());
    };
    /** @deprecated **/
    OrderWarehouseService.prototype.loadOutOrders = function (data) {
        return this.httpRestService.put(this.apiUrl + 'loadout', classToPlain(data, { enableCircularCheck: true }), this.httpUtils.getHTTPHeaders());
    };
    /** @deprecated **/
    OrderWarehouseService.prototype.getOrdersToReceive = function (routeIds) {
        return (!routeIds || !routeIds['length']) ?
            ErrorObservable.create('No Inbound Routes provided') :
            this.httpRestService.getWithHeader(this.apiUrl + ("orders-to-receive/" + routeIds), this.httpUtils.getHTTPHeaders()).pipe(map(function (ows) { return plainToClass(OrderWarehouse, ows); }));
    };
    /** @deprecated **/
    OrderWarehouseService.prototype.getOrdersToLoadOut = function (routeIds) {
        return (!routeIds || !routeIds['length']) ?
            ErrorObservable.create('No Outbound Routes provided') :
            this.httpRestService.getWithHeader(this.apiUrl + ("orders-to-loadout/" + routeIds), this.httpUtils.getHTTPHeaders()).pipe(map(function (ows) { return plainToClass(OrderWarehouse, ows); }));
    };
    /***
     * Search Order Warehouse parts by
     * @param recoveryOrderId optional - to get Orders recovering by Recovery Load
     * @param ids array of elements:
     *    XXXXXX : Route ID
     *    MXXXXX : Master ID
     *    XXXX   : MAWB last 4 digits
     */
    OrderWarehouseService.prototype.getOrdersAtWarehouse = function (ids, recoveryLoadId) {
        var params = this.httpUtils.getHTTPParams({});
        if (!!recoveryLoadId) {
            params = params.append('rld', recoveryLoadId.toString());
        }
        return this.httpRestService.getWithOptions(this.apiUrl + ("orders-at-warehouse/" + ids), this.httpUtils.getHTTPHeaders(), params).pipe(map(function (ows) { return plainToClass(OrderWarehouse, ows); }));
    };
    OrderWarehouseService.prototype.getMasterReceivings = function (id, activeOnly, recoveryOrderId) {
        var _this = this;
        if (activeOnly === void 0) { activeOnly = true; }
        var params = this.httpUtils.getHTTPParams({ active: activeOnly });
        if (recoveryOrderId) {
            params = params.append('recoveryOrderId', recoveryOrderId.toString());
        }
        return this.httpRestService.getWithOptions(this.apiUrl + 'get-master/' + id, this.httpUtils.getHTTPHeaders(), params) // .get<Master>(API_URL + 'getMaster', id)
            .pipe(map(function (master) { return _this.afterLoad(plainToClass(Master, master)); }))
            .toPromise();
    };
    OrderWarehouseService.prototype.getMastersToReceive = function (mawb) {
        return this.httpRestService.getWithHeader(this.apiUrl + ("masters-to-receive/" + mawb), this.httpUtils.getHTTPHeaders()) /*.pipe(map((ows) => plainToClass(OrderWarehouse, ows)))*/;
    };
    OrderWarehouseService.prototype.savePictureByOrderId = function (id, docType, imageAsData) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = this.httpUtils.getHTTPParams({ docType: docType });
        return this.httpRestService.put(this.apiUrl + 'savePictureToOrder/' + id, imageAsData, httpHeader, params).toPromise();
    };
    OrderWarehouseService.prototype.savePictureByMasterId = function (id, docType, imageAsData) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = this.httpUtils.getHTTPParams({ docType: docType });
        return this.httpRestService.put(this.apiUrl + 'savePictureToMaster/' + id, imageAsData, httpHeader, params).toPromise();
    };
    OrderWarehouseService.prototype.receiveRoute = function (routeId, part) {
        return this.httpRestService.put(this.apiUrl + "receive-route", classToPlain(part, { enableCircularCheck: true }), this.httpUtils.getHTTPHeaders(), { id: routeId });
    };
    OrderWarehouseService.prototype.loadOutRoute = function (routeId, part) {
        return this.httpRestService.put(this.apiUrl + "load-out-route", classToPlain(part, { enableCircularCheck: true }), this.httpUtils.getHTTPHeaders(), { id: routeId });
    };
    OrderWarehouseService.prototype.receiveMaster = function (part) {
        return this.httpRestService.put(this.apiUrl + "receive-master", classToPlain(part, { enableCircularCheck: true }), this.httpUtils.getHTTPHeaders());
    };
    return OrderWarehouseService;
}(BaseEntityService));
export { OrderWarehouseService };
