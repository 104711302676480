import {Directive, Input} from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator} from '@angular/forms';
import {ValidationErrors} from '@angular/forms/src/directives/validators';
import {validCNT} from '../../modules/shared/services/oms-converters.service';
import {OrderMode} from "../../modules/shared/models/order/order-mode";

@Directive({
  selector: '[cnt-number][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CntNumberValidator, multi: true }]
})
export class CntNumberValidator implements Validator {
  @Input() orderMode: OrderMode;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.needValidate() || !control.value) {  // the [required] validator will check presence, not us
      return null;
    }

    const value = control.value.replace(/[-_ ]/g, '');
    let error;
    return validCNT(value, this.orderMode, (msg) => error = {cnt: msg}) ? null : error;
  }

  needValidate() {
    return OrderMode.FCLEX === this.orderMode ||
      OrderMode.FCLIM === this.orderMode;
  }
}
