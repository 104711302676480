﻿<div class="widget-content" *ngIf="visible">
  <div class="wizard-wrapper">
    <div class="wizard">
      <ul class="steps">
        <li *ngFor="let header of currentHeaders.steps; let i = index;" [ngClass]="isActive(header.href)?'active':''" [routerLink]="header.href">
          <span class="badge badge-info">{{i+1}}</span>{{header.label}}<span class="chevron"></span>
        </li>
      </ul>
    </div>
  </div>
</div>
