import {BaseEntity} from '../../shared/models';
import {FieldUpdateEvent, PagingOptions} from '../../shared/components/base/data-table/data-table.utils';
import {ChangeDetectorRef, Input, ViewChild} from '@angular/core';
import {DataTableComponent} from '../../shared/components/base/data-table/data-table.component';
import {FilterPageRequest} from '../../shared/models/filter.page.request';
import {SearchSortRequest} from '../../shared/models/search.sort.request';
import {BaseEntityService} from '../../../services/base/base-entity.service';
import {OmsAlertsService} from '../../shared/components/oms-alerts/oms-alerts.service';
import {BaseDeletable} from '../../shared/models/base-deletable';
import {FileUploadService} from '../../../services/file.upload.service';
import {DialogType, ModalResult, OmsDialogsService} from "../../../components/common/oms-dialogs";
import {share} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {FilterSearchColumn} from "../../shared/models/filter.search.column";
import {AbstractComponent} from "../../../common/component/abstract.component";
import {Subject} from "rxjs/Subject";

export abstract class AbstractEntityListComponent<T extends BaseEntity> extends AbstractComponent {

  public total: number;
  @Input()
  public items: T[] = [];
  public selected: T[] = [];

  public paging: PagingOptions = {enabled: true, pageSize: 100, pageSizes: [10, 20, 50, 100, 200, 500]};
  public showArchived = false;

  @ViewChild('table') table: DataTableComponent;

  request: FilterPageRequest;
  private baseFilterColumns: FilterSearchColumn[] = [];
  private tableFilterColumns: FilterSearchColumn[] = [];
  items$: Subject<T[]> = new Subject();

  protected constructor(
    protected cdr: ChangeDetectorRef,
    protected alerts: OmsAlertsService,
    protected itemService: BaseEntityService<T>,
    protected dialogs: OmsDialogsService,
    protected fileUploadService: FileUploadService,
    protected spinner?: NgxSpinnerService,
  ) {
    super();
  }

  createDefaultRequest(sortField: string, sortedAsc = true): FilterPageRequest {
    return new FilterPageRequest(1, this.paging.pageSize, '', new SearchSortRequest(sortField, sortedAsc), []);
  }

  applySearchText() {
    this.table.changeSearchText(this.request.findByOccurs, 0);
  }

  public updateItems(select?: any): Observable<any> {
    if (this.spinner) {
      this.spinner.show().then();
    }
    let obs = this.itemService.findByFilters(this.request, !this.showArchived).pipe(share());
    obs.subscribe((response) => {
        this.paging.total = response.totalElements;
        this.items = response.content;
        console.log('RESPONSE', this.items);
        this.items$.next(this.items);
        if (select) {
          this.selected = [select];
        }

        if (this.spinner) {
          this.spinner.hide().then();
        }
        this.cdr.detectChanges();
      }, error => {
        this.alerts.danger(error);
        if (this.spinner) {
          this.spinner.hide().then();
        }
      });
    return obs;
  }

  protected getViewMode(): string {
    return null;
  }

  public downloadExcel() {
    this.itemService.downloadExcel(this.request, this.alerts, !this.showArchived, this.getViewMode())
      .subscribe((res) => {
        const fileURL = this.buildShowUrl(res);
        window.open(fileURL, '_blank');
      });
  }

  public downloadExcelByFilter(filterType: string) {
    this.itemService.downloadExcel(this.request, this.alerts, !this.showArchived, filterType)
      .subscribe((res) => {
        const fileURL = this.buildShowUrl(res);
        window.open(fileURL, '_blank');
      });
  }

  buildShowUrl(generatedDocumentInfo) {
    return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
  }

  // noinspection JSUnusedLocalSymbols
  onShowArchived(event?) {
    console.log("ON SHOW ACTIVE");
    this.updateItems();
  }

  onSearch(request: FilterPageRequest) {
    this.tableFilterColumns = request.filterByColumns;
    this.request.sort = request.sort;
    this.request.filterByColumns = this.baseFilterColumns.concat(request.filterByColumns);
    this.request.pageNumber = request.pageNumber;
    this.request.pageSize = request.pageSize;
    this.updateItems();
  }

  get noSelected(): boolean {
    return this.selected.length === 0;
  }

  get singleSelected(): boolean {
    return this.selected.length === 1;
  }

  get multiSelected(): boolean {
    return this.selected.length > 1;
  }

  get objectSelected(): boolean {
    return this.selected.length > 0;
  }

  get allowRestore(): boolean {
    return this.selected.length > 0 && !this.selected.some(item => {
      if (item instanceof BaseDeletable) {
        return !item.deleted;
      }
      return false;
    });
  }

  // Update Row Field
  onUpdate(event: FieldUpdateEvent) {
    this.updateItems();
  }

  deleteSelected() {
    if (!this.noSelected) {
      this.dialogs.confirm(DialogType.CONFIRMATION, 'Archive items?',
        `Are you sure you want archive ${this.selected.length} selected items?`)
        .then(result => {
          if (result.result === ModalResult.YES) {
            this.itemService
              .softDelete(this.selected.map(item => item.id))
              .then(() => {
                this.updateItems();
              });
          }
        });
    }
  }

  restoreSelected() {
    const promises = this.selected
      .filter(item => {
        if (item instanceof BaseDeletable) {
          return item.deleted;
        }
        return false;
      })
      .map(customer => this.itemService.restore(customer.id));
    Promise.all(promises)
      .then(() => this.updateItems());
  }

  public changeSearchText(searchText: string, debounceTime: number = 1500) {
    if (this.table) {
      this.table.changeSearchText(this.request.findByOccurs, debounceTime);
    } else {
      this.updateItems();
    }
  }

  public clearAllColumns(): void {
    this.request.findByOccurs = '';
    this.table.clearTableInputs();
  }

  public isTableFilterNotClear(): boolean {
    return !this.table.isClearTableInputs();
  }

  public setBaseFilterColumns(baseFilterColumns: FilterSearchColumn[]) {
    this.baseFilterColumns = baseFilterColumns;
    this.request.filterByColumns = baseFilterColumns.concat(this.tableFilterColumns);
  }

  public update(item: T) {
    this.items.replaceAll(item, item);
  }

  public refreshSelected(): Promise<T[]> {
    return new Promise<T[]>(
      (confirm, reject) => {
        let ids = this.selected.map(i => i.id).filter((id) => id > 0);

        this.itemService.getMany(ids).subscribe(
          (result) => {
            this.selected = result;
            this.table.refresh(result);
            this.cdr.markForCheck();
            confirm(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
