import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {AuthService, OrdersService} from "../../../../services";
import {
  Address,
  Customer,
  FreightForwarder,
  MasterStatus,
  Order,
  Shipment
} from "../../../../modules/shared/models";
import {OrderMode, OrderModes} from "../../../../modules/shared/models/order/order-mode";
import {OmsConstants} from "../../../../common/oms-constants.service";
import {CustomerService} from "../../../../services/customer.service";
import {FreightForwarderService} from "../../../../services/freight.forwarder.service";
import {FileUploadService} from "../../../../services/file.upload.service";
import {NotesCenterDialogComponent} from "../../../../components/common/notes-center-dialog";
import {MatDialog} from "@angular/material";
import {
  UldsDialogComponent,
  UldsDialogInputData
} from "../../../../components/common/ulds-dialog/ulds-dialog.component";
import {isNullOrUndefined} from "util";
import {Size} from "../../../../common/oms-types";
import {HistoryInformationDialogComponent} from "../../../../components/common/history-information-dialog";
import {ColumnType} from "../../../../common/column-type";
import {MasterService} from "../../../../services/master/master.service";
import {
  OrderSubscriptionDialogComponent,
  OrderSubscriptionDialogInput
} from "../../../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import {takeUntil} from "rxjs/operators";
import {AbstractComponent} from "../../../../common/component/abstract.component";
import {BillingInfoListDialogComponent} from "../billing-info-list-dialog/billing-info-list-dialog.component";
import {OmsDialogsService} from "../../../../components/common/oms-dialogs";
import {Invoice} from "../../../../modules/shared/models/invoice";
import {BillingService} from "../../../../services/billing.service";
import {buildCfsStatusPageUrl, copyToClipboard, sendEmail} from "../../../../_helpers/utils";
import {OmsAlertsService} from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {convertOrderNumber} from "../../../../modules/shared/services/oms-converters.service";

@Component({
  selector: 'oms-generic-order',
  templateUrl: './generic-order-form.component.html',
  styleUrls: ['./generic-order-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericOrderFormComponent extends AbstractComponent implements OnInit, OnChanges {

  @Input() public order: Order;
  @Input() public type: string;


  public freightForwarder: FreightForwarder;
  public isBillingDisabled: boolean = true;

  public notesCount: number;
  public docCount: number;
  public modes = OrderModes;
  public orderModeValue = OrderModes.values;
  public shipment: Shipment;

  statusName: string = '';
  Size = Size;
  isReadMode: boolean = false;
  canCreateCarrier: boolean = false;
  canShowBillingInfo: boolean = false;
  public invoices: Invoice[] = [];
  public ColumnType: ColumnType;

  constructor(
    public file: FileUploadService,
    public freightForwarderService: FreightForwarderService,
    public customerService: CustomerService,
    public cdr: ChangeDetectorRef,
    public authService: AuthService,
    public dialog: MatDialog,
    public dialogs: OmsDialogsService,
    private masterService: MasterService,
    private billingService: BillingService,
    private alerts: OmsAlertsService,
    private ordersService: OrdersService
  ) {
    super();
    this.authService.isReadMode()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isReadMode) => this.isReadMode = isReadMode);
  }

  ngOnInit() {
    if (isNullOrUndefined(this.type)) {
      this.orderModeValue = OrderModes.values;
    } else if ('AIR_EXPORT' === this.type) {
      this.orderModeValue = [OrderMode.AEDIR];
    } else if ('FCL' === this.type) {
      this.orderModeValue = [OrderMode.FCLEX, OrderMode.FCLIM];
    }

    this.billingService.getInvoicesByOrderId(this.order.id)
      .subscribe((invoices) => this.invoices = invoices);

    this.authService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.isBillingDisabled = !(user && user.billingApprove);
        this.canCreateCarrier = user && user.canCreateCarrier;
        this.canShowBillingInfo = user && user.canShowBillInfo;
      });

    this.onModeChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.order) {
      this.statusName = MasterStatus.getLabel(this.order.status);
    }
  }

  onModeChange() {
    if (this.isFCL) {
      this.pieces = 1;
    }

    this.checkHu();
    this.cdr.markForCheck();
  }

  checkHu() {
    if (this.isFCL) {
      this.hu = this.pieces;
      this.huUnits = this.pcsUnits;
    }
  }


  get addressPickup(): Address {
    return this.order && this.order.direct ? this.order.addressCargoBuilding : this.order.addressCfs;
  }

  set addressPickup(value: Address) {
    if (this.order) {
      this.order.addressCfs = value;
    }
  }

  get addressDelivery(): Address {
    return this.order && this.order.addressDelivery;
  }

  set addressDelivery(value: Address) {
    if (this.order) {
      this.order.addressDelivery = value;
    }
  }

  get ref1(): string {
    return this.order && this.order.customerRef;
  }

  set ref1(value: string) {
    if (this.order) {
      this.order.customerRef = value;
    }
  }

  get ref2(): string {
    return this.order && this.order.hawb;
  }

  set ref2(value: string) {
    if (this.order) {
      this.order.hawb = value;
    }
  }


  get ref3(): string {
    return this.order && this.order.ref3;
  }

  set ref3(value: string) {
    if (this.order) {
      this.order.ref3 = value;
    }
  }

  onRightClick(isDelivery: boolean) {
    let logChanges = isDelivery
      ? [{
        className: new Order().javaClassName,
        field: 'dateDeliveryAct',
        id: this.order.id,
        key: 'actual'
      }, {
        className: new Order().javaClassName,
        field: 'dateDeliveryReq',
        id: this.order.id,
        key: 'estimated'
      }]
      : [{
        className: new Order().javaClassName,
        field: 'datePickupAct',
        id: this.order.id,
        key: 'actual'
      }, {
        className: new Order().javaClassName,
        field: 'datePickupEst',
        id: this.order.id,
        key: 'estimated'
      }];

    console.log('LOG', logChanges);
    this.executeLogChanges(logChanges);
    return false;
  }

  executeLogChanges(logChanges: any[]) {
    this.dialogs.openDialog<HistoryInformationDialogComponent, any>(HistoryInformationDialogComponent, {
        logParams: logChanges, column: null, convertByBaseType: null
    });
  }

  get ref1Label(): string {
    return 'REF';
  }

  get actualMode() {
    return this.order ? (this.order.genericMode || (this.order.nonAMS ? OrderMode.AIDOM : OrderMode.AICFS)) : null;
  }

  get ref2Label(): string {
    switch (this.actualMode) {
      case OrderMode.AICFS:
      case OrderMode.AIDOM:
      case OrderMode.AEMSS:
      case OrderMode.AEDIR:
        return "HAWB";

      case OrderMode.FCLEX:
      case OrderMode.FCLIM:
        return "CNT";

      default:
        return "REF2";
    }
  }

  get ref3Label(): string {
    switch (this.actualMode) {
      case OrderMode.AICFS:
      case OrderMode.AIDOM:
      case OrderMode.AEMSS:
      case OrderMode.AEDIR:
        return "MAWB";
      case OrderMode.FCLEX:
        return "BOOKING#";
      case OrderMode.FCLIM:
        return "MBL";
      default:
        return "REF3";
    }
  }

  /*  getShipment(): Shipment {
      if (this.shipment)
        return this.shipment;

      if (this.order && this.dispatch) {
        this.dispatchItem =  this.dispatch.items.findFirst(di=>di.hasOrder(this.order.id));
        if (this.dispatchItem)
          this.shipment =  this.order.shipmentContents.map(sc=>sc.shipment).findFirst(s=>s.id === this.dispatchItem.shipmentId);
      }

      return this.shipment;
    } */

  get isGeneric(): boolean {
    return this.order && !!this.order.genericMode;
  }

  get isRecovery(): boolean {
    return this.order && !!this.order.isRecovery;
  }


  get nonGenericModeLabel() {
    return this.order && !this.order.isGeneric ? OrderModes.labelOf(OrderMode.AICFS) : null;
  }

  get genericMode(): OrderMode {
    return this.order && this.order.genericMode;
  }

  set genericMode(mode: OrderMode) {
    if (this.order) {
      this.order.genericMode = mode;
    }
  }

  get isFCL(): boolean {
    return OrderModes.isFCL(this.actualMode);
  }

  get isContainerMode(): boolean {
    return OrderModes.isContainerMode(this.actualMode);
  }


  get pieces(): number {
    return this.order && this.order.pieces;
  }

  set pieces(value: number) {
    if (this.order) {
      this.order.pieces = value;
      this.checkHu();
    }
  }


  get hu(): number {
    return this.order && this.order.hu;
  }

  set hu(value: number) {
    if (this.order) {
      this.order.hu = value;
    }
  }


  get weight(): number {
    return this.order && this.order.weight;
  }

  set weight(value: number) {
    this.order.weight = value;
  }

  get customer(): Customer {
    return this.order && this.order.customer;
  }

  set customer(value: Customer) {
    if (this.order) {
      this.order.customer = value;
    }
  }



  get ref2Placeholder() {
    return OrderModes.isContainerMode(this.actualMode) ? OmsConstants.contNumberPlaceholder : '';
  }

  get ref3Placeholder() {
    switch (this.actualMode) {
      case OrderMode.AICFS:
      case OrderMode.AIDOM:
      case OrderMode.AEMSS:
      case OrderMode.AEDIR:
        return OmsConstants.mawbPlaceholder;
      default:
        return '';
    }
  }

  get ref2Mask() {
    return OrderModes.isContainerMode(this.actualMode) ? OmsConstants.contNumberMask : undefined;
  }

  get ref3Mask() {
    switch (this.actualMode) {
      case OrderMode.AICFS:
      case OrderMode.AIDOM:
      case OrderMode.AEMSS:
      case OrderMode.AEDIR:
        return OmsConstants.mawbMask;

      default:
        return undefined;
    }
  }

  get datePickUpAct() {
    let s = this.shipment;
    return s && s.datePickUpExpectation;
  }

  openUldDialog() {
    this.dialog.open(UldsDialogComponent, {
      width: '30%', data:
        {
          orderId: this.order.id,
          readonly: false
        } as UldsDialogInputData
    });
  }

  openNoteCenter(noteTypeId) {
    this.dialog.open(NotesCenterDialogComponent, {
      width: '80%',
      data: {id: this.order.id, objectType: 'ORDER', noteTypeId: noteTypeId, readonly: false}
    });
  }

  openDocCenter() {
    this.dialogs.openDocCenterWindow({id: this.order.id, objectType: 'ORDER'});
  }

  get huUnits(): string {
    return this.order && this.order.huUnits;
  }

  get pcsUnits(): string {
    return this.order && this.order.pcsUnits;
  }

  set pcsUnits(value: string) {
    console.log('SET', value);
    if (this.order) {
      this.order.pcsUnits = value;
      this.checkHu();
    }
  }

  set huUnits(value: string) {
    if (this.order) {
      this.order.huUnits = value;
    }
  }

  isAIOrder(): boolean {
    return !this.order.genericMode || this.order.genericMode === OrderMode.AIDOM || this.order.genericMode === OrderMode.AICFS;
  }

  get chassis() {
    return this.order && this.order.chassis;
  }


  set chassis(value: string) {
    this.order.chassis = value;
  }

  openOrderSubscriptionDialog() {
    this.dialog.open<OrderSubscriptionDialogComponent, OrderSubscriptionDialogInput, MasterStatus[]>(OrderSubscriptionDialogComponent,
      {width: 'auto', data: {orders: [this.order]}})
      .afterClosed().subscribe((res) => { });
  }

  openBillingDialog() {
    this.dialogs.openDialog(BillingInfoListDialogComponent, {
      object: this.order,
      invoices: this.invoices,
      isReadMode: this.isReadMode
      }, {}).then();
  }

/*  public get hasMaster(): boolean {
    return !!this.order && !!this.order.master;
  }

  public get masterNumber(): string {
    return this.hasMaster ? this.order.master.masterNumber : undefined;
  }

  public get hasMasterLink(): boolean {
    return !!this.order && (!!this.order.master || !!this.order.data.recoveringMasterId );
  }

  public getMasterLink(masterId: number): any {
    return masterId && getMasterLinkExternal(masterId);
  }
*/

  public get masterId() {
    return this.order && (this.order.master ? this.order.master.id : this.order.data.recoveringMasterId );
  }

  public hasStatusInfoPageLink(): boolean {
    return !!(this.order && this.order.freightForwarder && this.order.freightForwarder.freightForwarderGroup);
  }

  public getStatusInfoPageLink() {
    return buildCfsStatusPageUrl(this.order.freightForwarder.freightForwarderGroup.id, this.order.id);
  }

  copyStatusInfoUrl() {
    let url = this.getStatusInfoPageLink();
    copyToClipboard(url);
    this.alerts.info('Status Info Page Link copied to the Clipboard');
  }

  sendStatusInfoUrl() {
    let url = this.getStatusInfoPageLink();
    sendEmail(`${convertOrderNumber(this.order.id)} Status Ingo Page`, url);
  }

  public get isCarrierRequired(): boolean {
    return this.order &&
      !OrderModes.isRecovery(this.order.genericMode);
  }

  public get isRddRequired(): boolean {
    return this.order &&
      !OrderModes.isRecovery(this.order.genericMode) &&
      OrderModes.isAirExportDirect(this.order.genericMode) &&
      this.order.carrier && this.order.carrier.isOwnCarrier;
  }

  public get isCustomerRequired(): boolean {
    return this.order &&
      !OrderModes.isRecovery(this.order.genericMode);
  }

  public onShowHistory() {
    let logChanges = {
      className: new Order().javaClassName,
      converter: (value) => value ? value.toString() : OmsConstants.EMPTY_VALUE,
      id: this.order.id
    };

    console.log('LOG', logChanges);

    this.executeLogChanges([logChanges]);
  }

  public onShowQuoteHistory(event: MouseEvent) {
    event.stopPropagation();
    let logChanges = {
      className: new Order().javaClassName,
      field: 'quote',
      converter: (value) => value ? value.toString() : OmsConstants.EMPTY_VALUE,
      id: this.order.id
    };

    console.log('LOG', logChanges);
    this.executeLogChanges([logChanges]);
    return false;
  }
}
