import {Exclude, Type} from 'class-transformer';
import {PackagingUnits} from '../../../common/oms-unit-types';
import {BaseEntity} from './base-entity';
import {Address} from './address';
import {Load, LoadType} from './load';
import {ShipmentContent} from './shipment.content';
import {isNullOrUndefined} from 'util';
import {User} from './user';
import {Master} from "./master/master";
import {absent, assigned} from "../../../_helpers/utils";

export class Shipment extends BaseEntity {
  public static JAVA_CLASS_NAME: string = 'com.oms.entity.Shipment';

  public javaClassName: string = 'com.oms.entity.Shipment';
  public uid: number;

  @Type(() => Date)
  datePickUpExpectation: Date;
  @Type(() => Date)
  datePickUpActual: Date;
  @Type(() => Date)
  dateDeliveryExpectation: Date;

  @Type(() => Date)
  dateDeliveryActual: Date;
  @Type(() => Date)
  dateConfirmedOn: Date;          // route confirmed by Driver
  @Type(() => Date)
  dateCompletedOn: Date;          // route completed by Driver


  @Type(() => Date)
  dispatchedForRecovery: Date;
  @Type(() => User)
  dispatchedByUser: User;

  hu: number;
  @Type(() => PackagingUnits)
  hu_units: PackagingUnits;
  pieces: number;
  @Type(() => PackagingUnits)
  pieces_units: PackagingUnits;
  weight;
  number;
  volume: number;
  hasProblem: boolean;

  @Type(() => Address)
  addressPickUp: Address;
  @Type(() => Address)
  addressDelivery: Address;
  @Type(() => Master)
  master: Master;
  @Type(() => Load)
  load: Load;
  @Type(() => ShipmentContent)
  shipmentContents: ShipmentContent[] = [];

  @Type(() => Date)
  dateOnSitePickup: Date;
  @Type(() => Date)
  dateOnSiteDelivery: Date;


  @Exclude()
  get isDelivered(): boolean {
    return !isNullOrUndefined(this.dateDeliveryActual);
  }

  @Exclude()
  get isPickedUp(): boolean {
    return !isNullOrUndefined(this.datePickUpActual);
  }

  @Exclude()
  get isDispatched(): boolean {
    return !isNullOrUndefined(this.load) && (!isNullOrUndefined(this.datePickUpExpectation) || !isNullOrUndefined(this.datePickUpActual));
  }

  @Exclude()
  get isStaging(): boolean {
    return this.load && assigned(this.datePickUpExpectation) && absent(this.load.driver) && absent(this.datePickUpActual);
  }

  public isRecovery(): boolean {
    return this.load && this.load.loadType === LoadType.RECOVERY;
  }

  public isCompleted(): boolean {
    return (this.isRecovery() ? this.isPickedUp : this.isDelivered) && !this.hasProblem;
  }

  public isCompletedWithProblem(): boolean {
    return (this.isRecovery() ? this.isPickedUp : this.isDelivered) && this.hasProblem;
  }

  public isNotCompleted(): boolean {
    return !(this.isRecovery() ? this.isPickedUp : this.isDelivered) && this.hasProblem;
  }


}
