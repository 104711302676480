var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Country } from './country';
import { UsaState } from './usa-state';
import { Type, Exclude } from 'class-transformer';
import { separatedValuesString } from '../../../../_helpers/utils';
import { BaseDeletable } from '../base-deletable';
export var AddressType;
(function (AddressType) {
    AddressType[AddressType["CARGO_BUILDING"] = 0] = "CARGO_BUILDING";
    AddressType[AddressType["CFS_LOCATION"] = 1] = "CFS_LOCATION";
    AddressType[AddressType["CFS_3PL_LOCATION"] = 2] = "CFS_3PL_LOCATION";
    AddressType[AddressType["PICKUP_LOCATION"] = 3] = "PICKUP_LOCATION";
    AddressType[AddressType["DELIVERY_LOCATION"] = 4] = "DELIVERY_LOCATION";
    AddressType[AddressType["WHSE_BUILDING"] = 5] = "WHSE_BUILDING";
    AddressType[AddressType["HOME_BASE"] = 6] = "HOME_BASE";
})(AddressType || (AddressType = {}));
var addressTypeFieldsMap = new Map([
    [AddressType.CARGO_BUILDING, 'cargoBuilding'],
    [AddressType.CFS_LOCATION, 'cfsLocation'],
    [AddressType.CFS_3PL_LOCATION, 'cfs3plLocation'],
    [AddressType.PICKUP_LOCATION, 'pickupLocation'],
    [AddressType.DELIVERY_LOCATION, 'deliveryLocation'],
    [AddressType.WHSE_BUILDING, 'whseBuilding'],
    [AddressType.HOME_BASE, 'homeBase'],
]);
var addressTypeDescriptionMap = new Map([
    [AddressType.CARGO_BUILDING, 'Cargo Building'],
    [AddressType.CFS_LOCATION, 'CFS Location'],
    [AddressType.CFS_3PL_LOCATION, 'CFS 3PL Location'],
    [AddressType.PICKUP_LOCATION, 'Pickup Location'],
    [AddressType.DELIVERY_LOCATION, 'Delivery Location'],
    [AddressType.WHSE_BUILDING, 'WHSE'],
    [AddressType.HOME_BASE, 'Homebase'],
]);
var ɵ0 = function () { return Country; }, ɵ1 = function () { return UsaState; };
var Address = /** @class */ (function (_super) {
    __extends(Address, _super);
    function Address() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.top = false; // for displaying as most frequently used or default
        return _this;
    }
    Address.addressDescription = function (addressTypes) {
        return separatedValuesString(addressTypes, function (type) { return addressTypeDescriptionMap.get(type); });
    };
    Address.sameAddress = function (a1, a2) {
        return (a1 && a1.id) === (a2 && a2.id);
    };
    Object.defineProperty(Address.prototype, "isUsa", {
        get: function () {
            return this.country && this.country.isUsa;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Address.prototype, "types", {
        set: function (types) {
            var _this = this;
            if (types) {
                types.forEach(function (type) { return _this[addressTypeFieldsMap.get(type)] = true; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Address.prototype.supportsAny = function (types) {
        var _this = this;
        return types && types.length > 0 ? types.some(function (type) { return _this[addressTypeFieldsMap.get(type)]; }) : true;
    };
    Address.prototype.supportsAll = function (types) {
        var _this = this;
        var supports = true;
        if (types && types.length > 0) {
            types.some(function (type) {
                if (!_this[addressTypeFieldsMap.get(type)]) {
                    supports = false;
                }
                return !supports;
            });
        }
        return supports;
    };
    Object.defineProperty(Address.prototype, "label", {
        get: function () {
            return this.name || (this.addressLines && this.addressLines.split("\n", 1)[0]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Address.prototype, "fullAddress", {
        get: function () {
            var result = this.name + '\n';
            result += this.addressLines ? this.addressLines + '\n' : '';
            result += this.city + ', ' + (this.usaState && this.usaState.uspsCode || this.province || '') + ', ' + this.postCode || '';
            result += this.country ? ',\n' + this.country.name : '';
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Address.prototype, "fullAddressNameAndLines", {
        get: function () {
            var result = this.name + ', ' + '\n';
            result += this.addressLines ? this.addressLines + '\n' : '';
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Address.prototype.getCityStateZip = function () {
        return [this.city, this.usaState && this.usaState.uspsCode, this.postCode].join(', ');
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Country)
    ], Address.prototype, "country", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", UsaState)
    ], Address.prototype, "usaState", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Address.prototype, "label", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Address.prototype, "fullAddress", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Address.prototype, "fullAddressNameAndLines", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", String)
    ], Address.prototype, "getCityStateZip", null);
    return Address;
}(BaseDeletable));
export { Address };
export { ɵ0, ɵ1 };
