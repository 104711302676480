var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { Customer, Master, Order } from '../modules/shared/models';
import { RestService } from './rest.service';
import { HttpUtilsService } from './http-utils.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { map, tap } from 'rxjs/operators';
import { BaseEntityService } from './base/base-entity.service';
var OrdersService = /** @class */ (function (_super) {
    __extends(OrdersService, _super);
    function OrdersService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/order/';
        _this.classType = Order;
        _this.filesList = new Subject();
        return _this;
    }
    /***@deprecated***/
    OrdersService.prototype.getOrder = function (id) {
        var _this = this;
        return this.httpRestService.get(this.apiUrl + 'getOrder', id)
            .pipe(map(function (order) {
            console.log('Raw Order', order);
            return _this.afterLoad(plainToClass(Order, order));
        }))
            .toPromise();
    };
    OrdersService.prototype.getMasterInfoByRecoveryOrder = function (id) {
        return this.httpRestService.get(this.apiUrl + 'getMasterInfoByRecoveryOrder', id);
        //      .pipe(map((order) => this.afterLoad(plainToClass(Order, order))));
    };
    OrdersService.prototype.splitOrder = function (id, pcs, hu, weight) {
        var _this = this;
        return this.httpRestService.get(this.apiUrl + 'splitOrder', id, pcs, hu, weight)
            .pipe(map(function (orders) { return _this.afterLoad(plainToClass(Order, orders)); }))
            .toPromise();
    };
    OrdersService.prototype.buildDownloadUrl = function (fileType) {
        return this.httpRestService.buildUrlForDownload(this.apiUrl + 'downloadOrdersInformation', fileType);
    };
    OrdersService.prototype.saveAll = function (orders) {
        var _this = this;
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'saveAll', orders, { headers: httpHeader })
            .pipe(map(function (o) { return plainToClass(Order, o).map(function (item) { return _this.afterLoad(item); }); }))
            .toPromise();
    };
    OrdersService.prototype.updateOrderOld = function (id, fields) {
        return this.updateOrder(id, fields)
            .then(function (res) {
            return res[1] || res[0];
        });
    };
    OrdersService.prototype.updateOrder = function (id, fields) {
        return this.updateOrderObs(id, fields).toPromise();
    };
    OrdersService.prototype.updateOrderObs = function (id, fields) {
        var _this = this;
        console.log('Update Order', id, fields);
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'updateOrder/' + id, fields, httpHeader)
            .pipe(map(function (object) {
            var isSingleOrder = object && (object['id'] === id);
            if (isSingleOrder) {
                return [_this.afterLoad(plainToClass(Order, object)), null];
            }
            var master = _this.afterLoad(plainToClass(Master, object));
            var order = master.orders.find(function (o) { return o.id === id; });
            return [order, master];
        }));
    };
    OrdersService.prototype.uploadOrdersExpeditors = function (files) {
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append("files", files.item(i), files.item(i)['name']);
        }
        return this.httpRestService.requestQuery('POST', '/endpoint/import/orders/expeditors', formData);
    };
    OrdersService.prototype.generateBOLs = function (ordersIds) {
        return this.httpRestService.post(this.apiUrl + 'generateBOLs', ordersIds);
    };
    OrdersService.prototype.printBOLs = function (ordersIds) {
        return this.httpRestService.post(this.apiUrl + 'printBOLs', ordersIds);
    };
    OrdersService.prototype.createOrders = function (orders) {
        var _this = this;
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'createOrders', classToPlain(orders), { headers: httpHeader })
            .pipe(map(function (o) { return plainToClass(Order, o).map(function (item) { return _this.afterLoad(item); }); }))
            .toPromise();
    };
    OrdersService.prototype.automateOrders = function (orders) {
        var _this = this;
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'automateOrders', classToPlain(orders), { headers: httpHeader })
            .pipe(map(function (o) { return plainToClass(Order, o).map(function (item) { return _this.afterLoad(item); }); }))
            .toPromise();
    };
    OrdersService.prototype.validateAIOrders = function (orders) {
        var _this = this;
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'validateAIOrders', classToPlain(orders), { headers: httpHeader })
            .pipe(map(function (o) { return plainToClass(Order, o).map(function (item) { return _this.afterLoad(item); }); }))
            .toPromise();
    };
    OrdersService.prototype.findExistingRefs = function (refs) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'find-existing-refs', refs, { headers: httpHeader })
            .pipe(map(function (o) { return Object.keys(o).map(function (ref) { return ({ ref: ref, orders: o[ref] }); }); }))
            .toPromise();
    };
    OrdersService.prototype.openBase64PDF = function (response) {
        var data = response.bytes;
        var fileName = "BOL.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
            var byteCharacters = atob(data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], { type: 'application/pdf' });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else { // much easier if not IE
            var myWindow = window.open("", "", "");
            if (myWindow) {
                var myWindowBody = myWindow.document.body;
                var link = document.createElement('a');
                link.innerHTML = 'Download as PDF';
                link.download = fileName;
                link.href = 'data:application/octet-stream;base64,' + data;
                myWindowBody.append(link);
                var obj = document.createElement('object');
                obj.style.width = '100%';
                obj.style.height = '100%';
                obj.type = 'application/pdf';
                obj.data = 'data:application/pdf;base64,' + data;
                myWindowBody.append(obj);
            }
        }
    };
    OrdersService.prototype.findCustomersOfSelectedOrders = function (search, filterPageRequest) {
        return this.httpRestService.post(this.apiUrl + 'customers_of_selected', filterPageRequest, { params: { search: search } })
            .pipe(tap(function (page) {
            page.content = plainToClass(Customer, page.content);
            console.log('Found Customers', page.content);
        }));
    };
    return OrdersService;
}(BaseEntityService));
export { OrdersService };
