import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Observable} from "rxjs/index";
import {RestService} from "../rest.service";
import {HttpUtilsService} from "../http-utils.service";
import {MasterAir} from "../../modules/shared/models";

const API_URL = "/oms/master-air/";
@Injectable()
export class MasterAirService {

  public masterAirsSubject = new Subject<MasterAir[]>();

  constructor(private httpRestService: RestService, private httpUtils: HttpUtilsService) { }

  getAllMasterAirs() {
    this.httpRestService.get<MasterAir[]>(API_URL + 'getAllMasterAirs').subscribe(items => {
      this.masterAirsSubject.next(items);
    });
  }

  create(item: MasterAir): Observable<MasterAir> {
    return this.httpRestService.post<MasterAir>(API_URL + 'create', item);
  }

  update(item: MasterAir): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(API_URL + 'update', item, { headers: httpHeader });
  }

  delete(id: number): Observable<MasterAir> {
    return this.httpRestService.delete<MasterAir>(API_URL + 'delete', id);
  }

  findByMawbNumber(mawbNumber: string){
    return this.httpRestService.get<MasterAir>(API_URL + 'findByMawbNumber', mawbNumber);

  }

  updateULDs(item: MasterAir): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.put<any>(API_URL + 'updateULDs', item, { headers: httpHeader });
  }

}
