import { TransformationType } from 'class-transformer';
export var MasterStatusId;
(function (MasterStatusId) {
    MasterStatusId[MasterStatusId["NEW"] = 0] = "NEW";
    MasterStatusId[MasterStatusId["ACKNOWLEDGED"] = 1] = "ACKNOWLEDGED";
    MasterStatusId[MasterStatusId["FLIGHT_ARRIVED"] = 2] = "FLIGHT_ARRIVED";
    MasterStatusId[MasterStatusId["RECOVERY_DISPATCHED"] = 3] = "RECOVERY_DISPATCHED";
    MasterStatusId[MasterStatusId["PROBLEM_RECOVERY"] = 4] = "PROBLEM_RECOVERY";
    MasterStatusId[MasterStatusId["RECOVERED_PENDING_ONHAND"] = 5] = "RECOVERED_PENDING_ONHAND";
    MasterStatusId[MasterStatusId["ONHAND_COMPLETE_PENDING_1C"] = 6] = "ONHAND_COMPLETE_PENDING_1C";
    MasterStatusId[MasterStatusId["ONHAND_COMPLETE_READY_FOR_DISPATCH"] = 7] = "ONHAND_COMPLETE_READY_FOR_DISPATCH";
    MasterStatusId[MasterStatusId["DISPATCHED_FOR_JJS_DELIVERY"] = 8] = "DISPATCHED_FOR_JJS_DELIVERY";
    MasterStatusId[MasterStatusId["PROBLEM"] = 9] = "PROBLEM";
    MasterStatusId[MasterStatusId["PICKED_UP_FROM_JJS"] = 10] = "PICKED_UP_FROM_JJS";
    MasterStatusId[MasterStatusId["DELIVERED_PENDING_POD"] = 11] = "DELIVERED_PENDING_POD";
    MasterStatusId[MasterStatusId["ORDER_COMPLETE_READY_TO_BILL"] = 12] = "ORDER_COMPLETE_READY_TO_BILL";
    MasterStatusId[MasterStatusId["CLOSED"] = 13] = "CLOSED";
    MasterStatusId[MasterStatusId["VOIDED"] = 14] = "VOIDED";
})(MasterStatusId || (MasterStatusId = {}));
export function transformMasterStatusId(value, obj, transformationType) {
    switch (transformationType) {
        case TransformationType.PLAIN_TO_CLASS:
            if (!value) {
                console.warn('1 no status of object:', obj);
            }
            return value ? value.id : undefined;
        case TransformationType.CLASS_TO_PLAIN: return { id: value };
        default: return value;
    }
}
export function transformMasterStatus(value, obj, transformationType) {
    switch (transformationType) {
        case TransformationType.PLAIN_TO_CLASS:
            if (!value) {
                console.warn('2 no status of object:', obj);
            }
            return value ? MasterStatus.get(value.id) : undefined;
        case TransformationType.CLASS_TO_PLAIN: return { id: value ? value.id : undefined };
        default: return value;
    }
}
var MasterStatus = /** @class */ (function () {
    function MasterStatus(id, label, name, publicLabel, warning) {
        this.label = label;
        this.name = name;
        this.id = id;
        this.warning = warning;
        this.publicLabel = publicLabel;
    }
    MasterStatus.getLabel = function (id) {
        return MasterStatus.map.has(id) ? this.map.get(id).label : null;
    };
    MasterStatus.getName = function (id) {
        return MasterStatus.map.has(id) ? this.map.get(id).name : null;
    };
    MasterStatus.get = function (id) {
        return this.map.has(id) ? this.map.get(id) : null;
    };
    MasterStatus.clone = function (status) {
        return status ? new MasterStatus(status.id, status.label, status.name, status.publicLabel, status.warning) : null;
    };
    MasterStatus.getPublicLabel = function (id) {
        return MasterStatus.map.has(id) ? this.map.get(id).publicLabel : null;
    };
    Object.defineProperty(MasterStatus, "cfsStatusesIds", {
        /*
          public static getCfsStatuses(): MasterStatus[] {
            let cfsIds = this.getCfsStatusesIds();
            return MasterStatus.items.filter(item => {
              return cfsIds.includes(item.id);
            });
          } */
        get: function () {
            return [
                MasterStatusId.ONHAND_COMPLETE_PENDING_1C
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterStatus, "whseStatusesIds", {
        get: function () {
            return [
                MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH,
                MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY,
                MasterStatusId.PROBLEM
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterStatus, "deliveryStatusesIds", {
        /*
          public static getDeliveryStatuses(): MasterStatus[] {
            let ids = this.getDeliveryStatusesIds();
            return MasterStatus.items.filter(item => {
              return ids.includes(item.id);
            });
          }
        */
        get: function () {
            return [MasterStatusId.PICKED_UP_FROM_JJS];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterStatus, "allStatusesIds", {
        get: function () {
            return MasterStatus.items.map(function (item) { return item.id; });
        },
        enumerable: true,
        configurable: true
    });
    MasterStatus.initialize = function () {
        MasterStatus.items.push(
        // 1. Recovery Stages: RECOVERY TAB
        new MasterStatus(MasterStatusId.NEW, '1.a. New', '1.a.', 'Pending recovery'), new MasterStatus(MasterStatusId.ACKNOWLEDGED, '1.b. Acknowledged', '1.b.', 'Pending recovery'), new MasterStatus(MasterStatusId.FLIGHT_ARRIVED, '1.c. Flight Arrived', '1.c.', 'Pending recovery'), new MasterStatus(MasterStatusId.RECOVERY_DISPATCHED, '1.d. Recovery Dispatched', '1.d.', 'Pending recovery'), new MasterStatus(MasterStatusId.PROBLEM_RECOVERY, '1.e. Problem Recovery', '1.e.', 'Pending recovery'), 
        // 2. CFS and Delivery:  CFS & DELIVERY TAB
        new MasterStatus(MasterStatusId.RECOVERED_PENDING_ONHAND, '2.a. Recovered Pending OnHand', '2.a.', 'Recovered, pending on hand'), new MasterStatus(MasterStatusId.ONHAND_COMPLETE_PENDING_1C, '2.b. OnHand Complete Pending 1C', '2.b.', 'On hand, pending customs'), new MasterStatus(MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH, '2.c. On Hand Complete Ready for Dispatch', '2.c.', 'On hand, ready for dispatch (released)'), new MasterStatus(MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY, '2.d. Dispatched for JJS Delivery', '2.d.', 'Out for Delivery'), new MasterStatus(MasterStatusId.PROBLEM, '2.e. Problem', '2.e.', ''), 
        // 3. Billing
        new MasterStatus(MasterStatusId.PICKED_UP_FROM_JJS, '3.a. CFS Out', '3.a.', 'Delivered'), new MasterStatus(MasterStatusId.DELIVERED_PENDING_POD, '3.b. Delivered/picked up pending POD', '3.b.', 'Delivered'), new MasterStatus(MasterStatusId.ORDER_COMPLETE_READY_TO_BILL, '3.c. Ready to bill', '3.c.', 'Delivered'), 
        // 4. Closed (all)
        new MasterStatus(MasterStatusId.CLOSED, '4.a. Closed', '4.a.', 'Delivered'));
        MasterStatus.items.forEach(function (status) { MasterStatus.map.set(status.id, status); });
        MasterStatus.VOIDED = new MasterStatus(MasterStatusId.VOIDED, '8. Voided', '8.', 'Delivered', true);
        MasterStatus.map.set(MasterStatus.VOIDED.id, MasterStatus.VOIDED);
    };
    MasterStatus.items = [];
    MasterStatus.map = new Map();
    return MasterStatus;
}());
export { MasterStatus };
MasterStatus.initialize();
var MasterStatusService = /** @class */ (function () {
    function MasterStatusService() {
    }
    MasterStatusService.prototype.getLabel = function (id) { return MasterStatus.getLabel(id); };
    Object.defineProperty(MasterStatusService.prototype, "items", {
        get: function () { return MasterStatus.items; },
        enumerable: true,
        configurable: true
    });
    return MasterStatusService;
}());
export { MasterStatusService };
