﻿import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService, NavigationService} from './services';
import {MatIconRegistry} from "@angular/material";
import {DomSanitizer} from "@angular/platform-browser";
import {BnNgIdleService} from "bn-ng-idle";
import {Router} from "@angular/router";
import {plainToClass} from "class-transformer";
import {OrderNotification} from "./modules/shared/models/order/order-notification";
import {OrderNotificationAlertComponent} from "./modules/shared/components/oms-alerts/order-notification-alert/order-notification-alert.component";
import {WebSocketService} from "./services/websocet/web.socket.service";
import {OmsAlertsService} from "./modules/shared/components/oms-alerts/oms-alerts.service";
import {OrderNotificationService} from "./services/order-notification/order-notification.service";

@Component({
  selector: 'oms-app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private navigationService: NavigationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private bnIdle: BnNgIdleService,
    private router: Router,
    private webSocketService: WebSocketService,
    private omsAlertsService: OmsAlertsService,
    private orderNotificationService: OrderNotificationService,
    private cdr: ChangeDetectorRef) {

    this.matIconRegistry.addSvgIcon('icon-warehouse', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/factory-stock-house.svg'));
    this.matIconRegistry.addSvgIcon('icon-route', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/route.svg'));
    this.matIconRegistry.addSvgIcon('icon-barcode', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/barcode.svg'));
    this.matIconRegistry.addSvgIcon('icon-check-list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/check-list.svg'));
    this.matIconRegistry.addSvgIcon('icon-truck-arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/truck-arrow.svg'));

    this.authService.getUserInfo();
  }

  ngOnInit(): void {
    this.bnIdle.startWatching(60 * 120).subscribe((res) => {
      let currentUser = this.authService.getCurrentUser();
      if (res && currentUser) {
        this.authService.logOut(this.router.url).then(() => {
          this.bnIdle.stopTimer();
        });
      }
    });

    this.initSubscribe();
    this.changedUserHandler();
  }

  initSubscribe() {
    this.webSocketService.subscribeByCurrentUser("/oms-event/order-notification")
      .subscribe((message) => {
        const notification = plainToClass(OrderNotification, JSON.parse(message.body) as OrderNotification);
        this.displayMessage([notification]);
        this.cdr.detectChanges();
      });
  }

  private changedUserHandler() {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.orderNotificationService.getUnreadOrderNotifications().subscribe(orderNotifications => {
          this.displayMessage(orderNotifications);
        });
      }
    });
  }

  private displayMessage(orderNotifications: OrderNotification[]): void {
    this.omsAlertsService.showMessages(orderNotifications.map(on => ({
      componentType: OrderNotificationAlertComponent,
      componentData: on,
      timeout: 99999999,
      onClose: () => {
        this.orderNotificationService.readOrderNotification(on.id).subscribe();
      }
    })));
  }
}
