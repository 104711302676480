import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { InplaceEditorSettings, DateValue } from "../../columns/column-types";
import { isNullOrUndefined } from "util";
import { DataTableComponent } from "../../data-table.component";
import { GenericTableCell } from "../mawb-table-cell/generic-table-cell";
import { Order, Master } from "../../../../../models";
import { ContextMenuService } from "ngx-contextmenu";
import { DispatchService } from "../../../../../services/dispatch/dispatch.service";
import { ManifestItemContextMenuComponent } from "../../../../../../dispatch/pages/manifest-create/manifest-item-context-menu/manifest-item-context-menu.component";
import { MatDialog } from "@angular/material";
import { OmsConstants } from "../../../../../../../common/oms-constants.service";
import { DateTimeService } from "../../../../../../../services/date-time.service";
import { DateBatteryTableData } from "./date-battery-table-data";
import { CbpStatusUtils } from "../../../../../services/stb-status/cbp-status.utils";
import { CbpQuickViewStatusDialogComponent } from "../../../../../../../components/common/cbp-quick-view-status-dialog/cbp-quick-view-status-dialog.component";
import { LogChangesService } from "../../../../../../../services/logchanges/log.changes.service";
import { switchMap, map, tap } from "rxjs/operators";
import { OrdersService } from "../../../../../../../services";
import { MasterService } from "../../../../../../../services/master/master.service";
import { UserUtil } from "../../../../../../settings/util/user.util";
import { UserRoleType } from "../../../../../models/user-role.type";

@Component({
//  selector: 'date-battery-table-cell',
  templateUrl: './date-battery-table-cell.component.html',
  styleUrls: ['./date-battery-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateBatteryTableCellComponent extends GenericTableCell<(Master | Order), DateBatteryTableData> implements OnInit, OnDestroy, OnChanges {

  @ViewChild('textInput') textInput: ElementRef;
  @ViewChild('menu') menu: ManifestItemContextMenuComponent;

  public editing: boolean = false;

  public error;
  value: string;
  empty: boolean;
  editable: boolean;
  public display: any;
  rawValue: any;
  iconClass: string;
  hasIcon: boolean;
  isHidden: boolean;
  tooltip: string;
  placeholder: string;
  editor: InplaceEditorSettings;
  public rowObject: Master | Order;
  cellClass: string;

  constructor(
    @Inject(DataTableComponent) public table: DataTableComponent,
    private cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    private dispatchService: DispatchService,
    private dialog: MatDialog,
    private dateTimeService: DateTimeService,
    private logChangesService: LogChangesService,
    private orderService: OrdersService,
    private masterService: MasterService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
//    console.log('CHANGE CELL', changes);
    if (changes.column) {
      this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
    }
    if (changes.row || changes.column || changes.search) {
      this.updateObject();
    }
  }

  public updateObject() {
    super.updateObject();

    this.rowObject = this.getRowObject(this.row);
    this.rawValue = this.column.getValue(this.rowObject);
    this.editable = this.column.editable(this.row);
    this.empty = isNullOrUndefined(this.rawValue);
    this.iconClass = this.getIconClass(this.rowObject, this.rawValue);
    this.cellClass = this.getClass(this.rowObject, this.rawValue);

    this.hasIcon = !isNullOrUndefined(this.iconClass);
    this.tooltip = this.getTooltip(this.rowObject);
    this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);

    this.cdr.markForCheck();
  }

  getTooltip(rowObject: Master | Order): string {
    const order = rowObject instanceof Order ? rowObject : null;
    const master = rowObject instanceof Master ? rowObject : order.master;
    return CbpStatusUtils.getMasterOrder1F1CTooltip(master, order, this.data.is1F);
  }

  getIconClass(rowObject, rawValue): string {
    const order = rowObject instanceof Order ? rowObject : null;
    const master = rowObject instanceof Master ? rowObject : order.master;
    return CbpStatusUtils.getBatteryIcon(master, order, this.data.is1F);
  }

  getClass(rowObject, rawValue): string {
    const order = rowObject instanceof Order ? rowObject : null;
    const master = rowObject instanceof Master ? rowObject : order.master;
    return CbpStatusUtils.getBatteryColor(master, order, this.data.is1F);
  }

  ngOnInit() {
    this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
    this.updateObject();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public getFormatTime(date: Date): string {
    return this.dateTimeService.utcToNYFormat(date, OmsConstants.MOMENT_TIME_SHORT_FORMAT);
  }

  public isReadOnly(): boolean {
    return this.data.readonly || !UserUtil.getCurrentUser().hasRole(UserRoleType.ROLE_AMS);
  }

  onCellClick($event): void {
    if (this.isReadOnly()) {
      return;
    }
    const order = this.rowObject instanceof Order ? this.rowObject : null;
    const master = this.rowObject instanceof Master ? this.rowObject : order.master;

    const initialDate = CbpStatusUtils.getDateSet(master, order, this.data.is1F);
    this.table.editDateTime(this.column.handlers.editor.cleanable, initialDate, null, false, $event)
      .pipe(switchMap((newValue: DateValue) => {
        const fieldName = this.data.is1F ? 'date1F' : 'date1C';
        if (order) {
          return this.orderService.updateOrderObs(order.id, {[fieldName]: newValue.actual})
            .pipe(
              tap((orderMaster) => Object.assign(this.row, orderMaster[0])),
              map(() => newValue));
        } else {
          return this.masterService.updateMasterObs(master.id, {[fieldName]: newValue.actual})
            .pipe(
              tap((orderMaster) => Object.assign(this.row, orderMaster)),
              map(() => newValue));
        }
      }))
      .subscribe(() => {
        // this.table.updateColumnValue(this.rowObject, this.column, newValue.actual, this.column.field);
        this.cdr.markForCheck();
        this.updateObject();
      });
  }

  onRightClick() {
    let columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;
    if (typeof columnField !== "string") {
      let fieldKeys = Object.keys(columnField);
      columnField = columnField[fieldKeys[0]];
    }
    let params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField);

    this.dialog.open(CbpQuickViewStatusDialogComponent, {
      width: '80%', height: '70%', data: {
        id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: true,
        logParams: params, column: this.column, convertByBaseType: this.column.history.logConverter
      }
    });
    return false;
  }
}
