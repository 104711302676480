import {BaseDeletable} from '../base-deletable';

export class Airline extends BaseDeletable {
  name: string;
  icaoCode: string;
  awbPrefix: number;
  iataCode: string;
  country: string;

  get label() {
    return this.awbPrefix + ' - ' + this.name + ' (' + this.iataCode + ')';
  }
}
