var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ValueAccessorBase } from './value-accessor';
import { message, validate, } from './base-input/validate';
import { map } from "rxjs/operators";
var ElementBase = /** @class */ (function (_super) {
    __extends(ElementBase, _super);
    function ElementBase(validators, asyncValidators) {
        var _this = _super.call(this) || this;
        _this.validators = validators;
        _this.asyncValidators = asyncValidators;
        return _this;
    }
    ElementBase.prototype.validate = function () {
        return validate(this.validators, this.asyncValidators)(this.model.control);
    };
    Object.defineProperty(ElementBase.prototype, "invalid", {
        get: function () {
            return this.validate().pipe(map((function (v) { return Object.keys(v || {}).length > 0; })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementBase.prototype, "failures", {
        get: function () {
            return this.validate().pipe(map((function (v) { return Object.keys(v).map(function (k) { return message(v, k); }); })));
        },
        enumerable: true,
        configurable: true
    });
    return ElementBase;
}(ValueAccessorBase));
export { ElementBase };
