import {Directive, Input} from '@angular/core';

import {
  NG_VALIDATORS,
  AbstractControl, Validator,
} from '@angular/forms';

@Directive({
  selector: '[minCount][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinCountValidator, multi: true }
  ]
})
export class MinCountValidator implements Validator {
  @Input() minCount: number;

  validate(control: AbstractControl): {[validator: string]: string} {
    if (!control.value) {
      return null;
    }

    const value = typeof control.value === "string" ? Number(control.value.replace(/[^\d.-]/g, '')) : control.value;
    if (this.minCount <= value) {
      return null;
    }

    return {'minCount': 'The minimal value is ' + this.minCount};
  }
}
