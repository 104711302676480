import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnInit, } from '@angular/core';
import { DriverService } from '../../services/driver-service';
import { RowHeightCache } from '../../modules/shared/components/base/data-table/utils/row-height-cache';
import { User } from "../../modules/shared/models";
import { map } from "rxjs/operators";
import { PageResult } from "../../modules/shared/models/query-models/page-result";
import { UserService } from "../../modules/shared/services/user.service";
import { DispatchService } from "../../modules/shared/services/dispatch/dispatch.service";
import { OmsAlertsService } from "../../modules/shared/components/oms-alerts/oms-alerts.service";
import { assigned } from "../../_helpers/utils";
import { ActivatedRoute, Router } from "@angular/router";
import { Size } from "../../common/oms-types";
import { UserRoleType } from "../../modules/shared/models/user-role.type";
var TestPageComponent = /** @class */ (function () {
    function TestPageComponent(router, route, cdr, alerts, driverService, dispatchService, userService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.cdr = cdr;
        this.alerts = alerts;
        this.driverService = driverService;
        this.dispatchService = dispatchService;
        this.userService = userService;
        this.date = new Date();
        this.virtualization = true;
        this.fixed = true;
        this.scrollbarV = true;
        this.scrollbarH = false;
        this.rowHeight = 40;
        this.tabVisible = true;
        this.items = [];
        this.headers = [];
        this.selectDrivers = [];
        this.Size = Size;
        this.expanded = [];
        this._pageSize = 20;
        this.indexes = {};
        this.temp = [];
        this.offsetY = 0;
        this.rowIndexes = new Map();
        this.rowExpansions = new Map();
        this.rowHeightsCache = new RowHeightCache();
        this.activeTab = 'test2';
        this.config = {
            wheelSpeed: 1,
            handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
            suppressScrollX: false,
            suppressScrollY: false,
            useBothWheelAxes: false
        };
        this.page = new EventEmitter();
        this.scroll = new EventEmitter();
        this.detailToggle = new EventEmitter();
        this.searchDrivers = function (searchText) {
            return _this.userService.findUsersByRoles([UserRoleType.ROLE_DRIVER], true, searchText).pipe(map(function (res) {
                return PageResult.fromArray(res.content.filter(function (user) { return user.driver; }).map(function (user) { return user.driver; }));
            }));
        };
        /**
         * Get the height of the detail row.
         */
        this.getDetailRowHeight = function (row, index) {
            if (!_this.rowDetail) {
                return 0;
            }
            var rowHeight = _this.rowDetail.rowHeight;
            return typeof rowHeight === 'function' ? rowHeight(row, index) : rowHeight;
        };
        this.route.queryParams.subscribe(function (params) {
            _this.activeTab = params.tab;
        });
    }
    Object.defineProperty(TestPageComponent.prototype, "pageSize", {
        get: function () {
            return this._pageSize;
        },
        set: function (val) {
            this._pageSize = val;
            this.recalcLayout();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TestPageComponent.prototype, "rows", {
        get: function () {
            return this._rows;
        },
        set: function (val) {
            this._rows = val;
            this.rowExpansions.clear();
            this.recalcLayout();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TestPageComponent.prototype, "rowCount", {
        get: function () {
            return this._rows ? this._rows.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TestPageComponent.prototype, "offset", {
        get: function () {
            return this._offset;
        },
        set: function (val) {
            this._offset = val;
            this.recalcLayout();
        },
        enumerable: true,
        configurable: true
    });
    TestPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.driverService.findAll().then(function (items) { _this.rows = items; });
        this.refresh();
    };
    TestPageComponent.prototype.refresh = function () {
        var _this = this;
        console.log('Routes Request', this.driver, this.date);
        if (assigned(this.driver) && assigned(this.date)) {
            this.dispatchService.getDriverRoutesOnDate(this.driver.driver.id, this.date)
                .then(function (manifests) {
                console.log('MANIFESTS', manifests);
                _this.routes = manifests.flatMap(function (m) { return m.items; });
                //          this.cdr.markForCheck();
            })
                .catch(function (error) { _this.alerts.error(error); });
        }
    };
    Object.defineProperty(TestPageComponent.prototype, "bodyHeight", {
        get: function () {
            return this._bodyHeight;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Updates the page given a direction.
     */
    TestPageComponent.prototype.updatePage = function (direction) {
        var offset = this.indexes.first / this.pageSize;
        if (direction === 'up') {
            offset = Math.ceil(offset);
        }
        else if (direction === 'down') {
            offset = Math.floor(offset);
        }
        if (direction !== undefined && !isNaN(offset)) {
            this.page.emit({ offset: offset });
        }
    };
    /**
     * Updates the rows in the view port
     */
    TestPageComponent.prototype.updateRows = function () {
        var _a = this.indexes, first = _a.first, last = _a.last;
        console.log('indexes', this.indexes);
        var rowIndex = first;
        var idx = 0;
        var temp = [];
        this.rowIndexes.clear();
        // if grouprowsby has been specified treat row paging
        // parameters as group paging parameters ie if limit 10 has been
        // specified treat it as 10 groups rather than 10 rows
        if (this.groupedRows) {
            var maxRowsPerGroup = 3;
            // if there is only one group set the maximum number of
            // rows per group the same as the total number of rows
            if (this.groupedRows.length === 1) {
                maxRowsPerGroup = this.groupedRows[0].value.length;
            }
            while (rowIndex < last && rowIndex < this.groupedRows.length) {
                // Add the groups into this page
                var group = this.groupedRows[rowIndex];
                temp[idx] = group;
                idx++;
                // Group index in this context
                rowIndex++;
            }
        }
        else {
            while (rowIndex < last && rowIndex < this.rowCount) {
                var row = this.rows[rowIndex];
                if (row) {
                    this.rowIndexes.set(row, rowIndex);
                    temp[idx] = row;
                }
                idx++;
                rowIndex++;
            }
        }
        this.temp = temp;
    };
    /**
     * Recalculates the table
     */
    TestPageComponent.prototype.recalcLayout = function () {
        this.refreshRowHeightCache();
        this.updateIndexes();
        this.updateRows();
    };
    /**
     * Refreshes the full Row Height cache.  Should be used
     * when the entire row array state has changed.
     */
    TestPageComponent.prototype.refreshRowHeightCache = function () {
        if (!this.scrollbarV || (this.scrollbarV && !this.virtualization)) {
            return;
        }
        // clear the previous row height cache if already present.
        // this is useful during sorts, filters where the state of the
        // rows array is changed.
        this.rowHeightsCache.clearCache();
        // Initialize the tree only if there are rows inside the tree.
        if (this.rows && this.rows.length) {
            this.rowHeightsCache.initCache({
                rows: this.rows,
                rowHeight: this.rowHeight,
                detailRowHeight: this.getDetailRowHeight,
                externalVirtual: this.scrollbarV && this.externalPaging,
                rowCount: this.rowCount,
                rowIndexes: this.rowIndexes,
                rowExpansions: this.rowExpansions
            });
            console.log('refreshRowHeightCache>>>', this.rowHeightsCache.treeArray);
        }
    };
    /**
     * Updates the index of the rows in the viewport
     */
    TestPageComponent.prototype.updateIndexes = function () {
        var first = 0;
        var last = 0;
        if (this.scrollbarV) {
            if (this.virtualization) {
                // Calculation of the first and last indexes will be based on where the
                // scrollY position would be at.  The last index would be the one
                // that shows up inside the view port the last.
                var height = this.bodyHeight; // parseInt(this.bodyHeight, 0);
                first = this.rowHeightsCache.getRowIndex(this.offsetY);
                last = this.rowHeightsCache.getRowIndex(height + this.offsetY) + 1;
                console.log('update indexes', first, last, height, this.offsetY);
            }
            else {
                // If virtual rows are not needed
                // We render all in one go
                first = 0;
                last = this.rowCount;
            }
        }
        else {
            // The server is handling paging and will pass an array that begins with the
            // element at a specified offset.  first should always be 0 with external paging.
            if (!this.externalPaging) {
                first = Math.max(this.offset * this.pageSize, 0);
            }
            last = Math.min(first + this.pageSize, this.rowCount);
        }
        this.indexes = { first: first, last: last };
    };
    /**
     * Body was scrolled, this is mainly useful for
     * when a user is server-side pagination via virtual scroll.
     */
    TestPageComponent.prototype.onBodyScroll = function (event) {
        var scrollYPos = event.scrollYPos;
        var scrollXPos = event.scrollXPos;
        // if scroll change, trigger update
        // this is mainly used for header cell positions
        if (this.offsetY !== scrollYPos || this.offsetX !== scrollXPos) {
            this.scroll.emit({
                offsetY: scrollYPos,
                offsetX: scrollXPos
            });
        }
        this.offsetY = scrollYPos;
        this.offsetX = scrollXPos;
        this.updateIndexes();
        this.updatePage(event.direction);
        this.updateRows();
    };
    Object.defineProperty(TestPageComponent.prototype, "scrollHeight", {
        /**
         * Property that would calculate the height of scroll bar
         * based on the row heights cache for virtual scroll and virtualization. Other scenarios
         * calculate scroll height automatically (as height will be undefined).
         */
        get: function () {
            if (this.scrollbarV && this.virtualization && this.rowCount) {
                return this.rowHeightsCache.query(this.rowCount - 1);
            }
            // avoid TS7030: Not all code paths return a value.
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    TestPageComponent.prototype.getRowStyles = function (row) {
        var styles = {};
        if (this.scrollbarV && this.virtualization) {
            var idx = this.getRowIndex(row);
            var top_1 = this.rowHeightsCache.query(idx - 1); // *(index+this.indexes.first);
            styles['height'] = this.rowHeight + 'px';
            styles['position'] = 'absolute';
            styles['transform'] = 'translate3d(0px, ' + top_1 + 'px, 0px)';
        }
        return styles;
    };
    /*  getRowsStyles(rows: any): any {
        const styles = {};
    
        // only add styles for the group if there is a group
        if (this.groupedRows) {
          styles['width'] = this.columnGroupWidths.total;
        }
    
        if (this.scrollbarV && this.virtualization) {
          let idx = 0;
    
          if (this.groupedRows) {
            // Get the latest row rowindex in a group
            const row = rows[rows.length - 1];
            idx = row ? this.getRowIndex(row) : 0;
          } else {
            idx = this.getRowIndex(rows);
          }
    
          // const pos = idx * rowHeight;
          // The position of this row would be the sum of all row heights
          // until the previous row position.
          const pos = this.rowHeightsCache.query(idx - 1);
    
          translateXY(styles, 0, pos);
        }
    
        return styles;
      }  */
    /**
     * Gets the index for the view port
     */
    TestPageComponent.prototype.getAdjustedViewPortIndex = function () {
        // Capture the row index of the first row that is visible on the viewport.
        // If the scroll bar is just below the row which is highlighted then make that as the
        // first index.
        var viewPortFirstRowIndex = this.indexes.first;
        if (this.scrollbarV && this.virtualization) {
            var offsetScroll = this.rowHeightsCache.query(viewPortFirstRowIndex - 1);
            return offsetScroll <= this.offsetY ? viewPortFirstRowIndex - 1 : viewPortFirstRowIndex;
        }
        return viewPortFirstRowIndex;
    };
    TestPageComponent.prototype.toggleExpanded = function (row, event) {
        if (this.expanded.hasEquals(row)) {
            this.expanded.removeAll(row);
        }
        else {
            this.expanded.push(row);
        }
    };
    /**
     * Toggle the Expansion of the row i.e. if the row is expanded then it will
     * collapse and vice versa.   Note that the expanded status is stored as
     * a part of the row object itself as we have to preserve the expanded row
     * status in case of sorting and filtering of the row set.
     */
    TestPageComponent.prototype.toggleRowExpansion = function (row) {
        // Capture the row index of the first row that is visible on the viewport.
        var viewPortFirstRowIndex = this.getAdjustedViewPortIndex();
        var expanded = this.rowExpansions.get(row);
        // If the detailRowHeight is auto --> only in case of non-virtualized scroll
        if (this.scrollbarV && this.virtualization) {
            var detailRowHeight = this.getDetailRowHeight(row) * (expanded ? -1 : 1);
            // const idx = this.rowIndexes.get(row) || 0;
            var idx = this.getRowIndex(row);
            this.rowHeightsCache.update(idx, detailRowHeight);
        }
        // Update the toggled row and update thive nevere heights in the cache.
        expanded = expanded ^= 1;
        this.rowExpansions.set(row, expanded);
        this.detailToggle.emit({
            rows: [row],
            currentIndex: viewPortFirstRowIndex
        });
    };
    /**
     * Gets the row index given a row
     */
    TestPageComponent.prototype.getRowIndex = function (row) {
        return this.rowIndexes.get(row) || 0;
    };
    /**
     * Recalculates the dimensions of the table size.
     * Internally calls the page size and row count calcs too.
     *
     */
    TestPageComponent.prototype.recalculateDims = function () {
        //    const dims = this.dimensionsHelper.getDimensions(this.element);
        //    this._innerWidth = Math.floor(dims.width);
        if (this.scrollbarV) {
            //      let height = dims.height;
            //      if (this.headerHeight) height = height - this.headerHeight;
            //      if (this.footerHeight) height = height - this.footerHeight;
            this._bodyHeight = this.body.nativeElement.getBoundingClientRect().height;
            console.log('height', this._bodyHeight);
        }
        //    this.recalculatePages();
    };
    /**
     * Recalc's the sizes of the grid.
     *
     * Updated automatically on changes to:
     *
     *  - Columns
     *  - Rows
     *  - Paging related
     *
     * Also can be manually invoked or upon window resize.
     */
    TestPageComponent.prototype.recalculate = function () {
        this.recalculateDims();
        //    this.recalculateColumns();
    };
    /**
     * Window resize handler to update sizes.
     */
    TestPageComponent.prototype.onWindowResize = function () {
        this.recalculate();
    };
    /**
     * Lifecycle hook that is called after a component's
     * view has been fully initialized.
     */
    TestPageComponent.prototype.ngAfterViewInit = function () {
        //    if (!this.externalSorting) {
        //      this.sortInternalRows();
        //    }
        var _this = this;
        // this has to be done to prevent the change detection
        // tree from freaking out because we are readjusting
        if (typeof requestAnimationFrame === 'undefined') {
            return;
        }
        requestAnimationFrame(function () {
            _this.recalculate();
            /*      // emit page for virtual server-side kickoff
                  if (this.externalPaging && this.scrollbarV) {
                    this.page.emit({
                      count: this.count,
                      pageSize: this.pageSize,
                      limit: this.limit,
                      offset: 0
                    });
                  }*/
        });
    };
    TestPageComponent.prototype.ngOnChanges = function (changes) {
        console.log('Changes', changes);
        //    this.refresh();
    };
    TestPageComponent.prototype.changeQueryParams = function () {
        var queryParams = { tab: this.activeTab };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
        }).then();
    };
    TestPageComponent.prototype.onActiveTabChange = function (active) {
        this.activeTab = active;
        this.changeQueryParams();
    };
    return TestPageComponent;
}());
export { TestPageComponent };
