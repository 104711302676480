import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {OmsConstants} from '../../../common/oms-constants.service';
import {isEmptyString} from '../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {Master} from '../../../modules/shared/models';
import {MasterService} from '../../../services/master/master.service';
import {plainToClass} from 'class-transformer';
import {ProblemType, Problem, ProblemDescriptor} from "../../../modules/shared/models";

@Component({
  selector: 'oms-problem-dialog',
  templateUrl: './problem-dialog.component.html',
  styleUrls: ['./problem-dialog.component.scss']
})
export class ProblemDialogComponent implements OnInit {
  public newProblemDescription: string;
  public newProblemType: any;
  public readonly: boolean = false;
  @ViewChild('textInput') input: ElementRef<HTMLInputElement>;

  public allProblems: ProblemDescriptor[] = [
    {id: ProblemType.OVERAGE, label: 'Overage'},
    {id: ProblemType.SHORTAGE, label: 'Shortage'},
    {id: ProblemType.DAMAGE, label: 'Damage'},
    {id: ProblemType.AMS_PCS, label: 'AMS Pieces'},
    {id: ProblemType.CUSTOMS_1C, label: 'Customs (1C)'},
    {id: ProblemType.CUSTOMS_1F, label: 'Customs (1F)'},
    {id: ProblemType.ISC_NOT_PAID, label: 'ISC Not Paid'},
    {id: ProblemType.NOT_BROKEN_DOWN, label: 'Not Broken Down Yet at Airline'},
    {id: ProblemType.DRIVER_PICK_UP, label: 'Driver pick up'},
    {id: ProblemType.CUSTOM, label: 'Other...'}
  ];

  public problems: Problem[] = [];

  constructor(public dialogRef: MatDialogRef<ProblemDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: any,
              public masterService: MasterService,
              public _constants: OmsConstants,
              public alertsService: OmsAlertsService) {
    this.readonly = data.readonly;
    if (data.object instanceof Master) {
      this.problems = data.object.problems;
    }
  }

  ngOnInit() {
  }

  getTitle(): string {
    return this.data.object instanceof Master ?
      `Master ${this.data.object.masterNumber} Problems` :
      'Problems';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSelectProblem(desc: ProblemDescriptor) {
    this.newProblemType = desc.id;
    this.newProblemDescription = desc.label;
    console.log(this.input);
//    this.input.nativeElement.value = desc.label;
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.select();
    });
  }

  addProblem() {
    if (!isEmptyString(this.newProblemDescription)) {
      if (isNullOrUndefined(this.newProblemType)) {
        this.newProblemType = ProblemType.CUSTOM;
      }

      let problem: Problem = new Problem(this.newProblemType, this.newProblemDescription);

      if (isNullOrUndefined(this.problems)) {
        this.problems = [];
      }

      this.problems.push(problem);
      this.newProblemType = null;
      this.newProblemDescription = null;
    }
  }

  onSubmit() {
//    this.validateForm();
//    if (this.hasFormErrors) {
//      return;
//    }

    if (this.data.object instanceof Master) {
//      let set: Set<Problem> = new Set<Problem>(this.problems);
//      console.log(set);
      this.masterService.updateMasterProblems(this.data.id, this.problems).then((problems) => {
        this.data.object.problems = plainToClass(Problem, problems);
        this.closeDialog();
      }).catch((error) => {
        console.error(error);
        this.alertsService.danger(error);
      });
    }
  }

  onInputKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !isEmptyString(this.newProblemDescription)) {
      this.addProblem();
    }
  }

  getProblemNoteTooltip(problem: Problem) {
    return problem && problem.comCenter && problem.comCenter.text;
  }

  disableAddProblem() {
    return isEmptyString(this.newProblemDescription);
  }

}
