import { ChangeDetectorRef, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
// import {Location} from '@angular/common';
var BasePageComponent = /** @class */ (function () {
    function BasePageComponent(router, route, 
    //    private location: Location,
    spinner, titleService, activatedRoute, cdr) {
        //    console.log('ACTIVATED', route.snapshot.toString());
        this.router = router;
        this.route = route;
        this.spinner = spinner;
        this.titleService = titleService;
        this.activatedRoute = activatedRoute;
        this.cdr = cdr;
        this.showHeader = true;
        //  @Input() scrollable: boolean = false;
        this.loading = false;
        this.backOnEscape = false;
    }
    Object.defineProperty(BasePageComponent.prototype, "displayChildPage", {
        get: function () {
            var children = this.activatedRoute.snapshot.children;
            return children && children.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    BasePageComponent.prototype.ngOnInit = function () {
        var _this = this;
        var node = document.querySelector('#footer');
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) { return console.log(mutation); });
            _this.checkSize();
        });
        observer.observe(node, {
            attributes: true,
            childList: true,
            characterData: true
        });
    };
    BasePageComponent.prototype.checkSize = function () {
        //    console.log('resize');
        //    if (this.mainContent.nativeElement instanceof HTMLDivElement) {
        if (this.footer.nativeElement instanceof HTMLDivElement) {
            this.mainContent.nativeElement.style.setProperty('padding-bottom', this.footer.nativeElement.clientHeight /* .offsetHeight*/ + 'px');
            this.cdr.markForCheck();
        }
        //    }
    };
    BasePageComponent.prototype.getTitle = function () {
        return this.title;
    };
    BasePageComponent.prototype.getSubTitle = function () {
        return this.subtitle;
    };
    BasePageComponent.prototype.ngOnChanges = function (changes) {
        if (changes.title) {
            console.log('TITLE', this.title, changes);
            this.titleService.setTitle(this.title);
        }
    };
    BasePageComponent.prototype.onEscape = function (event) {
        if (this.backOnEscape) {
            event.stopImmediatePropagation();
            this.goBack();
        }
    };
    BasePageComponent.prototype.goBack = function () {
        // this is working solution
        this.router.navigateByUrl(this.router.url.substr(0, this.router.url.lastIndexOf('/'))).then();
        //    this.router.navigate(['..'], { relativeTo: this.route}).then();
        //    this.location.back();
    };
    return BasePageComponent;
}());
export { BasePageComponent };
