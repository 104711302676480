import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {AuthService, OrdersService} from '../../../services';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {MasterService} from '../../../services/master/master.service';
import {Master, MasterAir} from '../../../modules/shared/models';
import {convertMasterNumber, convertMawbNumber} from '../../../modules/shared/services/oms-converters.service';
import {Subscription} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {MasterImportAirComponent} from "./master-import-air/master-import-air.component";
import {JsSocketSubscription, WebSocketService} from '../../../services/websocet/web.socket.service';
import {MasterLineService} from '../../../services/master/master-line.service';
import {LocationBackService} from '../../../services/location-back.service';
import {DialogType, ModalResult, OmsDialogsService} from "../../../components/common/oms-dialogs";
import {UserService} from "../../../modules/shared/services/user.service";
import {assigned} from "../../../_helpers/utils";
import {Manifest} from "../../../modules/shared/models/manifest/manifest";
import {OrderDispatch} from "../../../modules/shared/models/dispatch/order-dispatch";
import {ManifestItem} from "../../../modules/shared/models/manifest/manifest-item";
import {DispatchService} from "../../../modules/shared/services/dispatch/dispatch.service";
import {DispatchUtils} from "../../../_helpers/dispatch-utils";
import {NgxSpinnerService} from "ngx-spinner";
import {OmsColumns} from "../../../common/oms-columns.service";
import {MasterProcessor} from "../../../common/master-processor";

@Component({
  selector: 'oms-master-editor',
  templateUrl: './master-editor.component.html',
  styleUrls: ['./master-editor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterEditorComponent implements OnInit, OnDestroy {
  private webSocketEventName: string = '/data-table/masters';

  @ViewChild(MasterImportAirComponent)
  private childContent: MasterImportAirComponent;
  refreshLoading: boolean = false;
  clientUser: boolean = false;
  showRefreshStatus: boolean = false;
  sub: Subscription[] = [];

  public master: Master;
  private socketSub: JsSocketSubscription;
  isDeleteAvailable: boolean = false;

  public widthFirstPanel = 100;
  public widthSecondPanel = 0;
  public prevWidthFirstPanel = 35;
  public prevWidthSecondPanel = 65;
  public manifestForRightPanel: Manifest = new Manifest();

  public orderDispatchLoads: OrderDispatch[] = [];
/*
  public masterSubject: Subject<Master> = new Subject<Master>();
*/

  constructor( public alerts: OmsAlertsService,
               public dialogs: OmsDialogsService,
               private cdr: ChangeDetectorRef,
               private webSocketService: WebSocketService,
               public location: Location,
               private authService: AuthService,
               private route: ActivatedRoute,
               private orderService: OrdersService,
               private masterService: MasterService,
               private masterLineService: MasterLineService,
               private locationBackService: LocationBackService,
               private userService: UserService,
               private dispatchService: DispatchService,
               private dispatchUtils: DispatchUtils,
               private spinner: NgxSpinnerService,
               private _columnsService: OmsColumns,
               private masterProcessor: MasterProcessor) {

  }

  ngOnInit() {
    this.authService.isClientOnly.subscribe(clientUser => this.clientUser = clientUser);

    this.userService.getCurrentUser()
      .subscribe((user) => {
        this.isDeleteAvailable = user.canDeleteOrder;

        this.sub.push(this.route.queryParams.subscribe((params) => {
          let id = ~~params['id'];
          if (id) {
            this.loadMaster(id);
          } else {
            this.newMaster(params['new']);
          }
        }));
      });



    this.sub.push(this.masterService.refreshDataRequired.subscribe(master => {
      if (this.master.id === master.id) {
        this.loadMaster(master.id);
      }
    }));

    this.sub.push(this.webSocketService.stompClientSubject.subscribe(stompClient => {
      if (!isNullOrUndefined(stompClient)) {
        let that = this;
        this.socketSub = stompClient.subscribe(this.webSocketEventName, function (message) {
          let messageObject = JSON.parse(message.body);

          if (messageObject.operation === 'UPDATE') {
            if (that.master.id === messageObject.id) {
// !!!              that.refreshLoading = false;
// !!!              that.loadMaster(that.master.id);

//              console.log('UPDATE', messageObject.field, messageObject.updatedValue);
//              that.master = MasterService.afterLoad(plainToClass(Master, <Object>messageObject.updatedValue));
//              console.log('UPDATE2', that.master);
//              that.cdr.detectChanges();
            }
          }
          if (messageObject.operation === 'UPDATE_FIELD') {
            if (that.master.id === messageObject.id) {
              console.log('UPDATE_FIELD', messageObject.field, messageObject.updatedValue);
              that.master[messageObject.field] = messageObject.updatedValue;
              that.cdr.detectChanges();
            }
          }
        });
      }
    }));

    this.openManifestFromShipment();
    this.openManifestFromOrder();
  }

  private initCanDeleteOrder() {
    this.isDeleteAvailable = this.authService.canDeleteOrder();
  }

  public get title(): string {
    return !this.master ? null : (this.master.isNew() ? "New Master" : convertMasterNumber(this.master.id));
  }

  public get subTitle(): string {
    return assigned(this.master) ? (assigned(this.master.dateDeleted) ? 'Air Import (DELETED)' : 'Air Import') : "N/A";
  }

  public onSave(back?: boolean) {
     this.childContent.onSave(back);
  }

  public masterChanged(master) {
    this.loadMaster(master.id);
//    this.master = master;
  }

  public masterUpdated(master) {
    this.master = master;
    this.cdr.detectChanges();
  }

  public loadMaster(id: number) {
    console.log('Loading Master ', id);
    this.masterService.getMaster(id, !this.isDeleteAvailable)
      .then((master) => {
        if (!master.masterAir) {
          master.masterAir = new MasterAir();
          master.masterAir.master = master;
          console.log('Restored 1', master);
        }
          console.log('Master Loaded ', master);
        this.masterUpdated(master);

        if (this.refreshLoading) {
          this.alerts.info("Refreshed");
        }

        this.refreshLoading = false;
      },
      error => {
        this.alerts.danger(error);
        this.refreshLoading = false;
      });
  }

  private newMaster(type) {
    this.master = Master.newMaster(type);
  }

  public refresh() {
    this.refreshLoading = true;
    this.loadMaster(this.master.id);
  }

  ngOnDestroy(): void {
    this.sub.forEach((item) => item.unsubscribe());
    if (this.socketSub) { this.socketSub.unsubscribe(); }
  }

  onRestore() {
    if (!this.master || !this.master.isDeleted()) {
      return;
    }

    this.dialogs.confirm(DialogType.CONFIRMATION, 'Restore Master?', 'Master: ' + this.master.masterNumber + ' ' + convertMawbNumber(this.master.mawbNumber) + '\nwill be restored. Continue?' )
      .then((result) => {
        if (result.result === ModalResult.YES) {
          this.masterService.restore(this.master.id)
          .then(() => {
            this.alerts.info(`Master ${this.master.masterNumber} was restored.`);
            this.loadMaster(this.master.id);
            // this.masterLineService.refreshByLastPageRequest();
            // this.locationBackService.back$.next('delete');
          })
          .catch((error) => this.alerts.danger(error));
      }
    });
  }

  onDelete() {
    if (!this.master || this.master.isNew()) {
      return;
    }

    this.dialogs.confirm(DialogType.CONFIRMATION, 'Delete Master?', 'Master: ' + this.master.masterNumber + ' ' + convertMawbNumber(this.master.mawbNumber) + '\nwill be deleted. Continue?' ).then((result) => {
      if (result.result === ModalResult.YES) {
        this.masterService.softDelete(this.master.id)
          .then(() => {
            this.alerts.info(`Master ${this.master.masterNumber} was deleted.`);
            this.loadMaster(this.master.id);
//            this.location.back();
//            this.masterLineService.refreshByLastPageRequest();
//            this.locationBackService.back$.next('delete');
          })
          .catch((error) => this.alerts.danger(error));
      }
    });
  }

  changePrev(first: number, second: number): void {
    if (first <= 90) {
      this.prevWidthFirstPanel = first;
      this.prevWidthSecondPanel = second;
    }
  }

  dragEnd(event): void {
    this.widthFirstPanel = event.sizes[0];
    this.widthSecondPanel = event.sizes[1];
    this.changePrev(event.sizes[0], event.sizes[1]);
  }

  onManifestChange(manifest: Manifest): void {
    let items = this.orderDispatchLoads.filter(dispatchDto => manifest.items.some(manifestItem => manifestItem.hasOrder(dispatchDto.orderId)));
    this.setSelected(items);
    this.updateRightPanel();
  }

  onDispatchListSelectionChange(list: OrderDispatch[]): void {
    this.orderDispatchLoads = list;
    list.forEach((od: OrderDispatch) => {
      let created: ManifestItem = this.manifestForRightPanel.items.find((mi) => mi.orders.some(o => o.id === od.orderId));
      if (!created) {
        //   this.setOrderDispatchValuesFromOrder(od);
        this.dispatchUtils.manifestAddOrderDispatch(this.manifestForRightPanel, od);
      }
    });

    // remove from new Items only
    const manifestItems = this.manifestForRightPanel.items.filter((i) => i.isNew());
    manifestItems.forEach((mi) => {
      mi.orders.forEach((o) => {
        let selected = list.find((od) => od.orderId === o.id);
        if (!selected) {
          this.dispatchUtils.manifestDeleteOrder(this.manifestForRightPanel, o);
        }
      });
    });

    this.setDispatchListToRightPanel([...this.manifestForRightPanel.items]);
  }

  private setDispatchListToRightPanel(items: ManifestItem[]): void {
    this.manifestForRightPanel.items = items;
    this.manifestForRightPanel = Object.assign(new Manifest(), this.manifestForRightPanel);
    this.updateRightPanel();
  }


  private setSelected(items: OrderDispatch[]): void {
    this.orderDispatchLoads = items;
  }

  private updateRightPanel() {
    let list = this.manifestForRightPanel.items;
    if (!list.length && this.widthFirstPanel < 100) {
      this.changePrev(this.widthFirstPanel, this.widthSecondPanel);
      this.widthFirstPanel = 100;
      this.widthSecondPanel = 0;
    } else if (list.length && this.widthFirstPanel === 100) {
      this.widthFirstPanel = this.prevWidthFirstPanel;
      this.widthSecondPanel = this.prevWidthSecondPanel;
    }
  }

  onCreatedEvent(manifest: Manifest): void {
    this.manifestForRightPanel = new Manifest();
    this.setSelected([]);
    this.setDispatchListToRightPanel([]);
    this.refresh();
  }

  openManifestFromShipment(){
    this._columnsService.shipmentFromMasterEdit$.subscribe((id) => {
      if (id) {
        this.dispatchService.findOrderDispatchesFromShipment(id).then((od) => {
          if(od){
            this.openManifest(od.id);
          }
        })
      }
    });
  }

  openManifest(dispatchId: number) {
    if (dispatchId) {
      this.spinner.show();
      console.log('openManifest');
      this.dispatchService.get(dispatchId)
        .subscribe(manifest => {
          this.spinner.hide();
          console.log('manifest received', manifest);
          manifest.items.forEach(mi => {
            mi.loadTypeOriginal = mi.loadType;
            mi.orders.forEach(o => {
              mi.loadTypeOriginal = mi.loadType;
              o.info.legAddressDelivery = mi.addressDelivery;
            });
          });
          this.manifestForRightPanel = manifest;
          this.updateRightPanel();
          this.cdr.detectChanges();
        }, error => {
          this.spinner.hide();
          this.alerts.error(error, 'Error open Manifest');
        });
    }
  }

  openManifestFromOrder(){
    if(this.masterProcessor){
      this.masterProcessor.orderFromMasterEditor$.subscribe((orderId) => {
        if (orderId) {
          this.ordersChangeSelect([orderId]);
        }
      });
    }
  }

  ordersChangeSelect(ids: number[]) {
    this.spinner.show();
    this.dispatchService.findDispatchesForOrders(ids).then((list) => {
      this.spinner.hide();
      this.onDispatchListSelectionChange(list);
      this.cdr.detectChanges();
    }).catch((error) => {
      this.spinner.hide();
      this.alerts.error(error);
    });
  }

}
