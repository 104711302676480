﻿import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {animations} from '../../../base/base-input/animations';
import {SearchService, Size, Sort} from '../../../../../../common/oms-types';
import {BaseInputSelectComponent} from "../../../base/base-input/base-input-select.component";
import {ISearchItemsFunction} from "../../../../../settings/util/search-items.function";
import {Logger} from "../../../../../../_helpers/logger";
import {isNullOrUndefined} from "util";
import {KeyCode} from "../ng-select-types/ng-select.types";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  selector: 'oms-custom-search',
  animations,
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: CustomSearchComponent, multi: true}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger()
export class CustomSearchComponent<T = any> extends BaseInputSelectComponent<T> implements OnInit, OnDestroy, OnChanges {
  Size = Size;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('search') search: ElementRef;
  @Output('changing') changingEvent = new EventEmitter();
  @Output('searching') searchingEvent = new EventEmitter<string>();
  @Output('create') createEvent = new EventEmitter();
  @Output('clear') clearEvent = new EventEmitter();
  @ContentChild('labelTemplate', {read: TemplateRef}) labelTemplate: TemplateRef<any>;
  @ContentChild('optionTemplate', {read: TemplateRef}) optionTemplate: TemplateRef<any>;

  @Input() size: Size = Size.DEFAULT;
  @Input('sort-by') sortBy: string | Sort;

  @Input() customSearch: boolean = false;
  @Input() multiple: boolean = false;
  @Input() bindLabel: string = 'label';
  @Input() bindValue: string;
  @Input() readonly: boolean = false;
  @Input() tooltip: string;
  @Input() allowCreate: boolean = false;
  @Input() allowClear: boolean = true;
  @Input() maxWidth: boolean = false;
  @Input() autoWidth: boolean = true;
  @Input() autoHeight: boolean = false;
  @Input() searchFn: (search: string, obj: any) => boolean;
  @Input() service: SearchService<any>;
  @Input() showLabel: boolean = true;
  @Input() inplace: boolean = false;
  @Input() inline: boolean = false;
  @Input() count: number = 15;
  @Input() loading: boolean = false;
  @Input() typeahead: Subject<string>;
  @Input() searchable: boolean = true;
  @Input('items') _items: ISearchItemsFunction<T> | any[];
  @Input() appendTo: string = "body";
  @Input() invalidValue: boolean = false;

//  searchSubjectText: BehaviorSubject<string> = new BehaviorSubject('');

  _input: string;
  isChanged: boolean = false;
  public searchSubject: Subject<any[]> = new Subject<any[]>();
  private typingTimer;
  private doneTypingTimeout = 500;
  private unsubscribe$ = new Subject<void>();

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    public cdr: ChangeDetectorRef) {
    super(validators, asyncValidators);
  }

  public defaultSearchFn = (search: string, item: any) => {
    return this.searchFn ? this.searchFn(search, item) : true;
  }

  onChanged(item: any) {
    if (!this.multiple) {
      this.onSearch('');
      this.isOpen = false;
    }
    this.changingEvent.emit(item);
    if (!item) {
      this.clearEvent.emit();
    }
  }

  setValue(value) {
    super.setValue(value);
  }

  toggleOpen(event) {
    super.toggleOpen(event);
    if (this.isOpen) {
      this.onOpen();
    }
  }

  onOpen() {
    if (this.multiple) {
      setTimeout(() => {
        this.search.nativeElement.focus();
      }, 10);
    }
    this.isChanged = true;
    if (typeof this._items === 'function') {
      let search = !isNullOrUndefined(this.innerValue) ? this.labelOf(this.innerValue) : this.ngSelect.filterValue;
      this.onSearch(search);
    } else {
      this.onSearch("");
    }
  }

  onSearch(search: string): void {
    search = search || '';
    let isChanged = this._input !== search || this.isChanged;
    this._input = search;
    this.isChanged = false;
    if (this.searchable && isChanged) {
      this.isOpen = true;
      this.searchingEvent.emit(search);

      if (!this.customSearch) {

//        this.searchSubject.next([]);
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {

          if (this._items && typeof this._items === 'function') {
            this._items(this._input || '').subscribe(res => {
              this.searchSubject.next(res.content);
            });
          } else if (this.service) {
            let sort: Sort = typeof this.sortBy === 'string' ? {field: this.sortBy} : this.sortBy;
            this.service.findBy(this._input || '', this.count, true, sort).then(items => {
              this.searchSubject.next(items.content);
            });
          } else if (this._items && Array.isArray(this._items)) {
            this.searchSubject.next(this._items.filter((s) => {
              if (search === '') {
                return true;
              } else {
                let label: string = this.bindLabel ? s[this.bindLabel] : s.toString();
                label = label && label.toUpperCase();
                return label.indexOf(search.toUpperCase()) >= 0;
              }
            }));
          }
        }, this.doneTypingTimeout);
      }
    }
  }

  /*  private initSearch(){
      const results$ = this.searchSubjectText.pipe(
  //      debounceTime(300),
        switchMap(search => {
          return this.service.findBy(search || '', this.count)
        }
        ))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(response=>this.searchSubject.next(response.content));
    }
   */

  ngOnInit() {
    if (this._items && this._items['length']) {
      setTimeout(() => {
        this.searchSubject.next(this._items as any[]);
      });
    }
  }

  onCreate() {
    this.isOpen = false;
    this.createEvent.emit(this._input);
  }

  onSearchInput(search: string) {
    if (this.searchable) {
      this.ngSelect.filter(search);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  labelOf(item: any): string {
    return item ? (this.bindLabel ? item[this.bindLabel] : (item['label'] || item)) : undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes._items && Array.isArray(this._items)) {
      this.searchSubject.next(this._items);
    }
  }

  onKeyDown(event) {
    if (event.keyCode === KeyCode.Tab) {
      if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value) {
        this.setValue(this.ngSelect.itemsList.markedItem.value);
      }
      this.ngSelect.filterValue = null;
      this.isOpen = false;
    }
  }

  onSearchExit() {
    this.ngSelect.filterValue = null;
    this.isOpen = false;
  }

  public get isAllSelected(): boolean {
    return this.ngSelect.items && this.ngSelect.items.length && (this.ngSelect.selectedItems.length === this.ngSelect.items.length);
  }

  public set isAllSelected(all: boolean) {
    if (all) {

    } else {
      this.ngSelect.handleClearClick();
    }
  }


  public get isPartiallySelected(): boolean {
    return this.ngSelect.items && this.ngSelect.items.length && (this.ngSelect.selectedItems.length > 0 && this.ngSelect.selectedItems.length < this.ngSelect.items.length);
  }

  public get searchCheckboxHint(): string {
    return this.ngSelect.selectedItems.length ? 'Clear Selection' : 'Select All';
  }

  public searchCheckboxClick(value) {
    if (this.ngSelect.selectedItems.length) {
      this.ngSelect.handleClearClick();
    } else {
      setTimeout(() => {
        this.ngSelect.itemsList.items.forEach((o) => {
          this.ngSelect.select(o);
        });
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      });

    }
  }

}
