var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isNullOrUndefined } from 'util';
import { Type, Exclude, TransformationType, Transform } from 'class-transformer';
import { BaseEntity } from './base-entity';
import { Shipment } from './shipment';
import { Driver } from './driver';
import { Truck } from './truck';
import { Trailer } from './trailer';
export var LoadType;
(function (LoadType) {
    LoadType[LoadType["UNDEFINED"] = 0] = "UNDEFINED";
    LoadType[LoadType["RECOVERY"] = 1] = "RECOVERY";
    LoadType[LoadType["DELIVERY"] = 2] = "DELIVERY";
    LoadType[LoadType["PICKUP"] = 3] = "PICKUP";
    LoadType[LoadType["DIRECT"] = 4] = "DIRECT";
    LoadType[LoadType["X_DOCK"] = 5] = "X_DOCK";
})(LoadType || (LoadType = {}));
export var LoadTypes;
(function (LoadTypes) {
    var labels = new Map([
        [LoadType.UNDEFINED, 'Not Specified'],
        [LoadType.RECOVERY, 'Recovery'],
        [LoadType.DELIVERY, 'Delivery'],
        [LoadType.PICKUP, 'Pick Up'],
        [LoadType.DIRECT, 'Direct'],
        [LoadType.X_DOCK, 'X-Dock']
    ]);
    var prefixes = new Map([
        [LoadType.UNDEFINED, ''],
        [LoadType.RECOVERY, 'RLD'],
        [LoadType.DELIVERY, 'DLD'],
        [LoadType.PICKUP, 'PLD'],
        [LoadType.DIRECT, 'DIR'],
        [LoadType.X_DOCK, 'XLD']
    ]);
    LoadTypes.values = Object.keys(LoadType).filter(function (v) { return isNaN(Number(v)); }).map(function (key) { return valueOf(key); });
    function valueOf(key) { return LoadType[key]; }
    LoadTypes.valueOf = valueOf;
    function nameOf(value) { return value ? LoadType[value] : null; }
    LoadTypes.nameOf = nameOf;
    function labelOf(value) { return labels.get(value); }
    LoadTypes.labelOf = labelOf;
    function prefixOf(value) { return prefixes.get(value); }
    LoadTypes.prefixOf = prefixOf;
    LoadTypes.filterItems = LoadTypes.values.filter(function (v) { return v !== LoadType.UNDEFINED; }).map(function (v) { return ({ id: v, label: labelOf(v), name: nameOf(v) }); });
})(LoadTypes || (LoadTypes = {}));
export function transformLoadType(value, obj, transformationType) {
    switch (transformationType) {
        case TransformationType.PLAIN_TO_CLASS: return value ? value.id : undefined;
        case TransformationType.CLASS_TO_PLAIN: return { id: value };
        default: return value;
    }
}
var ɵ0 = function () { return Shipment; }, ɵ1 = function () { return Driver; }, ɵ2 = function () { return Truck; }, ɵ3 = function () { return Trailer; }, ɵ4 = function (v, o, tt) { return transformLoadType(v, o, tt); };
var Load = /** @class */ (function (_super) {
    __extends(Load, _super);
    function Load() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.javaClassName = 'com.oms.entity.load.Load';
        _this.loadType = LoadType.UNDEFINED;
        return _this;
    }
    Object.defineProperty(Load.prototype, "isDelivery", {
        get: function () {
            return this.loadType === LoadType.DELIVERY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Load.prototype, "isRecovery", {
        get: function () {
            return this.loadType === LoadType.RECOVERY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Load.prototype, "isPickedUp", {
        get: function () {
            return this.shipment && !isNullOrUndefined(this.shipment.datePickUpActual);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Load.prototype, "isDelivered", {
        get: function () {
            return this.shipment && !isNullOrUndefined(this.shipment.dateDeliveryActual);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Load.prototype, "driverName", {
        get: function () {
            return this.driver ? this.driver.fullName : null;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Shipment)
    ], Load.prototype, "shipment", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Driver)
    ], Load.prototype, "driver", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Truck)
    ], Load.prototype, "truck", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Trailer)
    ], Load.prototype, "trailer", void 0);
    __decorate([
        Transform(ɵ4),
        __metadata("design:type", Number)
    ], Load.prototype, "loadType", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Load.prototype, "isDelivery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Load.prototype, "isRecovery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Load.prototype, "isPickedUp", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Load.prototype, "isDelivered", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Load.prototype, "driverName", null);
    return Load;
}(BaseEntity));
export { Load };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
