import {ControlValueAccessor} from '@angular/forms';


export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
  protected innerValue: T;

  private changed: ((value: T) => void)[] = [];
  private touched: (() => void)[] = [];

  protected getValue(): T {
    return this.innerValue;
  }

  private absent(value: any): boolean {
    return value === undefined || value === null;
  }

  private sameValue(v1, v2): boolean {
    return this.absent(v1) && this.absent(v2) || v1 === v2;
  }

  protected setValue(value: T) {
    if (!this.sameValue(this.innerValue, value)) {
      this.innerValue = value;
      this.changed.forEach((f) => f(value));
    }
  }

  get value(): T {
    return this.getValue();
  }

  set value(value: T) {
    this.setValue(value);
  }

  writeValue(value: T) {
    this.setValue(value);
  }

  registerOnChange(fn: (value: T) => void) {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  touch() {
    this.touched.forEach(f => f());
  }
}
