import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseEntityService} from "../../../../../services/base/base-entity.service";
import {RestService} from "../../../../../services/rest.service";
import {HttpUtilsService} from "../../../../../services/http-utils.service";
import {plainToClass} from "class-transformer";
import {DispatchAndRecoveryLog} from "../../../models/dispatch.and.recovery.log";


@Injectable()
export class DispatchAndRecoveryService extends BaseEntityService<DispatchAndRecoveryLog> {
  apiUrl = '/oms/log/dispatch-and-recovery/';
  classType = DispatchAndRecoveryLog;

  public dispatchAndRecoveryLogSubject = new Subject<DispatchAndRecoveryLog[]>();

  public constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  findAllByMasterId(id: number): void {
    this.httpRestService.get<DispatchAndRecoveryLog[]>(this.apiUrl + 'find-all-by-master-id', id).subscribe(dispatchAndRecoveryLog => {
      this.dispatchAndRecoveryLogSubject.next(plainToClass(DispatchAndRecoveryLog, dispatchAndRecoveryLog as Object[]));
    });
  }

  findAllByShipmentId(id: number): void {
    this.httpRestService.get<DispatchAndRecoveryLog[]>(this.apiUrl + 'find-all-by-shipment-id', id).subscribe(dispatchAndRecoveryLog => {
      this.dispatchAndRecoveryLogSubject.next(plainToClass(DispatchAndRecoveryLog, dispatchAndRecoveryLog as Object[]));
    });
  }

  findAllByOrderId(orderId: number, shipmentId: number): void {
    this.httpRestService.getWithOptions<DispatchAndRecoveryLog[]>(this.apiUrl + 'find-all-by-order-id/' + orderId, null, {shipmentId: shipmentId})
      .subscribe(dispatchAndRecoveryLog => {
        this.dispatchAndRecoveryLogSubject.next(plainToClass(DispatchAndRecoveryLog, dispatchAndRecoveryLog as Object[]));
      });
  }
}
