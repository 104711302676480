﻿import {BaseDeletable} from './base-deletable';
import {Customer} from './customer';
import {Address} from './address/address';
import {Type} from 'class-transformer';

export class CustomerDeliveryLocation extends BaseDeletable {

  @Type(() => Customer)
  customer: Customer;
  @Type(() => Address)
  address: Address;
}
