var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Address, Customer, Driver, FreightForwarder, LoadType, MasterStatus, Trailer, transformLoadType, transformMasterStatus, } from '..';
import { Exclude, Transform, Type } from 'class-transformer';
import { UploadedFiles } from "../documents/uploaded.files";
import { isNullOrUndefined } from "util";
import { OrderMode, transformOrderMode } from "../order/order-mode";
import { Status, transformStatus } from "../warehouse/status";
var ɵ0 = function () { return Customer; }, ɵ1 = function () { return FreightForwarder; }, ɵ2 = function () { return Driver; }, ɵ3 = function (v, o, tt) { return transformOrderMode(v, o, tt); }, ɵ4 = function (v, o, tt) { return transformStatus(v, o, tt); }, ɵ5 = function () { return Address; }, ɵ6 = function () { return Address; }, ɵ7 = function () { return UploadedFiles; }, ɵ8 = function (v, o, tt) { return transformLoadType(v, o, tt); }, ɵ9 = function () { return Date; }, ɵ10 = function () { return Date; }, ɵ11 = function () { return Date; }, ɵ12 = function () { return Date; }, ɵ13 = function () { return Date; }, ɵ14 = function () { return Date; }, ɵ15 = function () { return Date; }, ɵ16 = function () { return Date; }, ɵ17 = function (v, o, tt) { return transformMasterStatus(v, o, tt); }, ɵ18 = function () { return Date; }, ɵ19 = function () { return Date; }, ɵ20 = function () { return Date; }, ɵ21 = function () { return Trailer; };
var OrderDispatch = /** @class */ (function () {
    function OrderDispatch() {
        // className? TODO
        this.rowId = OrderDispatch.ROW_ID;
    }
    Object.defineProperty(OrderDispatch.prototype, "isRouteCompleted", {
        get: function () {
            return !isNullOrUndefined(this.dateRouteCompleted);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDispatch.prototype, "isDelivered", {
        get: function () {
            return this.dispatchId && !!this.dateDeliveryAct;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDispatch.prototype, "isPickedUp", {
        get: function () {
            return this.dispatchId && !!this.datePickupAct;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDispatch.prototype, "isDispatched", {
        get: function () {
            return !isNullOrUndefined(this.dispatchShipmentId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDispatch.prototype, "isConfirmed", {
        get: function () {
            return !isNullOrUndefined(this.dateConfirmedOn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDispatch.prototype, "isRecovery", {
        get: function () {
            return this.loadType === LoadType.RECOVERY;
        },
        enumerable: true,
        configurable: true
    });
    OrderDispatch.prototype.trackBy = function () {
        return '' + this.dispatchId + this.orderId + this.shipmentId;
    };
    OrderDispatch.prototype.isNotCompleted = function () {
        return this.hasProblem && !(this.isRecovery ? this.isPickedUp : this.isDelivered);
    };
    OrderDispatch.prototype.isCompleted = function () {
        return !this.hasProblem && (this.isRecovery ? this.isPickedUp : this.isDelivered);
    };
    OrderDispatch.prototype.isCompletedWithProblem = function () {
        return this.hasProblem && (this.isRecovery ? this.isPickedUp : this.isDelivered);
    };
    OrderDispatch.prototype.getStatusName = function () {
        if (this.isCompleted()) {
            return "Completed";
        }
        else if (this.isCompletedWithProblem()) {
            return "Completed with problem";
        }
        else if (this.isNotCompleted()) {
            return "Not Completed";
        }
        return null;
    };
    OrderDispatch.prototype.getRouteStatus = function () {
        var statusName = this.getStatusName();
        if (statusName) {
            return statusName;
        }
        else if (this.isConfirmed) {
            return "Confirmed";
        }
        else {
            return "Dispatched";
        }
    };
    OrderDispatch.ROW_ID = 'OrderDispatch';
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Customer)
    ], OrderDispatch.prototype, "customer", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", FreightForwarder)
    ], OrderDispatch.prototype, "freightForwarder", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Driver)
    ], OrderDispatch.prototype, "driver", void 0);
    __decorate([
        Transform(ɵ3),
        __metadata("design:type", Number)
    ], OrderDispatch.prototype, "orderMode", void 0);
    __decorate([
        Transform(ɵ4),
        __metadata("design:type", Number)
    ], OrderDispatch.prototype, "loadOutStatus", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Address)
    ], OrderDispatch.prototype, "addressPickup", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Address)
    ], OrderDispatch.prototype, "addressDelivery", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", UploadedFiles)
    ], OrderDispatch.prototype, "manifestDocument", void 0);
    __decorate([
        Transform(ɵ8),
        __metadata("design:type", Number)
    ], OrderDispatch.prototype, "loadType", void 0);
    __decorate([
        Type(ɵ9),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateCreated", void 0);
    __decorate([
        Type(ɵ10),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateDispatchedFor", void 0);
    __decorate([
        Type(ɵ11),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "datePickupEst", void 0);
    __decorate([
        Type(ɵ12),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "datePickupAct", void 0);
    __decorate([
        Type(ɵ13),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateDeliveryEst", void 0);
    __decorate([
        Type(ɵ14),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateDeliveryAct", void 0);
    __decorate([
        Type(ɵ15),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateRouteCompleted", void 0);
    __decorate([
        Type(ɵ16),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateConfirmedOn", void 0);
    __decorate([
        Transform(ɵ17),
        __metadata("design:type", MasterStatus)
    ], OrderDispatch.prototype, "orderStatus", void 0);
    __decorate([
        Type(ɵ18),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "requestedDeliveryDate", void 0);
    __decorate([
        Type(ɵ19),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateOnSitePickup", void 0);
    __decorate([
        Type(ɵ20),
        __metadata("design:type", Date)
    ], OrderDispatch.prototype, "dateOnSiteDelivery", void 0);
    __decorate([
        Type(ɵ21),
        __metadata("design:type", Trailer)
    ], OrderDispatch.prototype, "trailer", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderDispatch.prototype, "isRouteCompleted", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderDispatch.prototype, "isDelivered", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderDispatch.prototype, "isPickedUp", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderDispatch.prototype, "isDispatched", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderDispatch.prototype, "isConfirmed", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], OrderDispatch.prototype, "isRecovery", null);
    return OrderDispatch;
}());
export { OrderDispatch };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21 };
