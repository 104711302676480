import { clamp, toFixed } from '../../../_helpers/utils';
export var FillStatus;
(function (FillStatus) {
    FillStatus[FillStatus["NONE"] = 0] = "NONE";
    FillStatus[FillStatus["EMPTY"] = 1] = "EMPTY";
    FillStatus[FillStatus["HALF"] = 2] = "HALF";
    FillStatus[FillStatus["FULL"] = 3] = "FULL";
    FillStatus[FillStatus["OVER"] = 4] = "OVER";
})(FillStatus || (FillStatus = {}));
function toClass(status) {
    switch (status) {
        case FillStatus.NONE: return '';
        case FillStatus.EMPTY: return 'empty';
        case FillStatus.HALF: return 'half';
        case FillStatus.FULL: return 'full';
        case FillStatus.OVER: return 'over';
    }
}
var FillIndicatorComponent = /** @class */ (function () {
    function FillIndicatorComponent() {
    }
    Object.defineProperty(FillIndicatorComponent.prototype, "fillClass", {
        get: function () {
            return typeof this.fill === 'number' ? '' : this.fill;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FillIndicatorComponent.prototype, "fillPercents", {
        get: function () {
            return typeof this.fill === 'number' ? toFixed(clamp(this.fill, 0, 100), 0) + '%' : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FillIndicatorComponent.prototype, "isFull", {
        get: function () {
            return typeof this.fill === 'number' && this.fill == 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FillIndicatorComponent.prototype, "isOver", {
        get: function () {
            return typeof this.fill === 'number' && this.fill > 100;
        },
        enumerable: true,
        configurable: true
    });
    return FillIndicatorComponent;
}());
export { FillIndicatorComponent };
