import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { OmsAlertsService } from '../oms-alerts/oms-alerts.service';
import { DriverNewService } from "../../../../services/driver-new-service";
import { ChatService } from "../../../../services/chat-service";
import { ChatRecord } from "../../models/chat.record";
var ChatDialogComponent = /** @class */ (function () {
    function ChatDialogComponent(dialogRef, data, fb, driverService, chatService, alerts) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.driverService = driverService;
        this.chatService = chatService;
        this.alerts = alerts;
        this._message = '';
        this.hasFormErrors = false;
        this.chatRecords = [];
        this.drivers = [];
        this.showEmojiPicker = false;
    }
    ChatDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.driverService.findAll().then(function (drivers) {
            _this.drivers = drivers;
            _this.currentDriver = drivers[0];
            _this.chatService.findAllByDriverId(_this.currentDriver.id).then(function (chatRecords) {
                _this.chatRecords = chatRecords;
                _this.scrollToBottom();
            });
        });
    };
    ChatDialogComponent.prototype.ngAfterViewChecked = function () {
        microlink('.link-preview');
    };
    ChatDialogComponent.prototype.changeCurrentDriver = function (driver) {
        var _this = this;
        this.currentDriver = driver;
        this.chatService.findAllByDriverId(this.currentDriver.id).then(function (chatRecords) {
            _this.chatRecords = chatRecords;
            _this.scrollToBottom();
        });
    };
    Object.defineProperty(ChatDialogComponent.prototype, "message", {
        get: function () {
            return this._message;
        },
        set: function (value) {
            this._message = value;
        },
        enumerable: true,
        configurable: true
    });
    ChatDialogComponent.prototype.toggleEmojiPicker = function () {
        this.showEmojiPicker = !this.showEmojiPicker;
    };
    ChatDialogComponent.prototype.addEmoji = function (event) {
        var message = this.message;
        var text = "" + message + event.emoji.native;
        this.message = text;
        this.showEmojiPicker = false;
    };
    ChatDialogComponent.prototype.sendMessage = function () {
        var chatRecord = new ChatRecord();
        chatRecord.driver = this.currentDriver;
        chatRecord.text = this.message;
        chatRecord.jjsUserAcknowledge = new Date;
        this.chatService.save(chatRecord).subscribe();
        this.message = '';
    };
    ChatDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    ChatDialogComponent.prototype.isCurrentDriver = function (driver) {
        return this.currentDriver.id === driver.id;
    };
    ChatDialogComponent.prototype.scrollToBottom = function () {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        }
        catch (err) {
        }
    };
    return ChatDialogComponent;
}());
export { ChatDialogComponent };
