import {LoadStatusFilterType} from "./load-status-filter-type";

export class LoadStatusFilterChoose {
  type: LoadStatusFilterType;
  selected: boolean = false;

  constructor(type: LoadStatusFilterType, selected: boolean) {
    this.type = type;
    this.selected = selected;
  }
}
