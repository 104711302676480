import {Injectable} from '@angular/core';
import {BaseEntityService} from "../../../services/base/base-entity.service";
import {Driver, User} from "../models";
import {RestService} from "../../../services/rest.service";
import {HttpUtilsService} from "../../../services/http-utils.service";
import {classToPlain, plainToClass} from 'class-transformer';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {PageResult} from "../models/query-models/page-result";
import {AuthService} from "../../../services";
import { LoggedUser } from "../models/logged-user";

@Injectable({providedIn: 'root'})
export class UserService extends BaseEntityService<User> {

  protected apiUrl = "/api/users/";
  protected classType = User;

  public currentUser$ = new ReplaySubject<LoggedUser>(1);

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService,
    private authService: AuthService) {
    super(httpRestService, httpUtils);
    this.authService.user$.subscribe((loggedUser) => {
      if (loggedUser) {
        this.currentUser$.next(loggedUser);
      } else {
        // this.currentUser$.next(null);
      }
    });
  }

  getCurrentUser(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  findUserByDriverId(driverId: number, active: boolean = true): Observable<User> {
    const params = this.httpUtils.getHTTPParams({});
    return this.httpRestService.getWithOptions<User>(this.apiUrl + 'findUserByDriverId/' + driverId, this.httpUtils.getHTTPHeaders(), params)
      .pipe(map(item => plainToClass(this.classType, item)));
  }

  findUsersByRoles(roles: string[], active: boolean = true, searchText: string = ''): Observable<PageResult<User>> {
    const params = this.httpUtils.getHTTPParams({
      roles: roles,
      searchText: searchText,
      active: active
    });
    return this.httpRestService.getWithOptions<PageResult<User>>(this.apiUrl + 'findUsersByRoles', this.httpUtils.getHTTPHeaders(), params)
      .pipe(map(res => {
        res.content = res.content.map(item => plainToClass(this.classType, item));
        return res;
      }));
  }

  create(item: User): Observable<User> {
    return this.httpRestService.post<User>(this.apiUrl + 'createDto', classToPlain(item))
      .pipe(map(response => plainToClass(this.classType, response)));
  }


  update(item: User): Observable<User> {
    return this.httpRestService.put<User>(this.apiUrl + 'updateDto', classToPlain(item), {headers: this.httpUtils.getHTTPHeaders()})
      .pipe(map(response => plainToClass(this.classType, response)));
  }

  getExternalDriver(): Observable<Driver>  {
    return this.httpRestService.getWithHeader<Driver>(this.apiUrl + 'get-external-driver', this.httpUtils.getHTTPHeaders());
  }

}
