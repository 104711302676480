var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { Size } from '../../../../../../common/oms-types';
import { convertDate, convertDateWithoutYear } from "../../../../services/oms-converters.service";
import { BaseInputComponent } from "../../../base/base-input/base-input.component";
import { SearchRange } from "../../../../models/search.range";
var DateRangeInputComponent = /** @class */ (function (_super) {
    __extends(DateRangeInputComponent, _super);
    function DateRangeInputComponent(validators, asyncValidators, cdr) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.cdr = cdr;
        _this.changingEvent = new EventEmitter();
        _this.Size = Size;
        _this.size = Size.DEFAULT;
        //  @Input() label:string;
        _this.time = true;
        _this.minDate = null;
        _this.maxDate = null;
        _this.showButton = true;
        _this.showClearButton = false;
        _this.cleanable = true;
        _this.showNaButton = false;
        _this.onNaButton = new EventEmitter();
        _this.fromDate = null;
        _this.toDate = null;
        _this.est = false;
        _this.isEdit = false;
        return _this;
    }
    DateRangeInputComponent.prototype.onCbClick = function () {
        if (this.fromDate) {
            this.fromDate = null;
        }
        else {
            this.isEdit = true;
        }
    };
    DateRangeInputComponent.prototype.changeEventTrigger = function (event) {
        this.value = undefined;
        this.fromDate = event.from;
        this.toDate = event.to;
        this.est = isNullOrUndefined(event.est) ? false : event.est;
        this.changingEvent.emit(new SearchRange(event.from, event.to, this.est));
    };
    DateRangeInputComponent.prototype.onPopupShow = function (event) {
    };
    DateRangeInputComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(DateRangeInputComponent.prototype, "displayValue", {
        get: function () {
            return typeof this.value === 'string' ? this.value :
                this.valueIsNotEmpty(this.fromDate) && this.valueIsNotEmpty(this.toDate) ?
                    this.displayFromTo : this.valueIsNotEmpty(this.fromDate) ? convertDate(this.fromDate) : '';
        },
        enumerable: true,
        configurable: true
    });
    DateRangeInputComponent.prototype.valueIsNotEmpty = function (value) {
        return !isNullOrUndefined(value) && value.toString().length > 0;
    };
    Object.defineProperty(DateRangeInputComponent.prototype, "displayFromTo", {
        get: function () {
            return convertDateWithoutYear(this.fromDate) + " - " + convertDateWithoutYear(this.toDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "realLeftPosition", {
        get: function () {
            return this.isEdit ? this.input.nativeElement.getBoundingClientRect().left : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "realTopPosition", {
        get: function () {
            return this.isEdit ? this.input.nativeElement.offsetHeight : 0;
        },
        enumerable: true,
        configurable: true
    });
    DateRangeInputComponent.prototype.isDisplayClearButton = function () {
        if (!this.showClearButton || !this.cleanable) {
            return false;
        }
        var v = this.value;
        return v && !v.isEmpty;
    };
    DateRangeInputComponent.prototype.onNaButtonClick = function (event) {
        this.value = 'N/A';
        this.fromDate = undefined;
        this.toDate = undefined;
        this.onNaButton.emit(event);
    };
    DateRangeInputComponent.prototype.writeValue = function (value) {
        _super.prototype.writeValue.call(this, value);
        if (value) {
            this.fromDate = value.from;
            this.toDate = value.to;
            this.est = isNullOrUndefined(value.est) ? false : value.est;
        }
        else {
            this.fromDate = null;
            this.toDate = null;
            this.est = false;
        }
    };
    return DateRangeInputComponent;
}(BaseInputComponent));
export { DateRangeInputComponent };
