import {EventEmitter, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from "./auth.service";
import { UserRoleType } from "../modules/shared/models/user-role.type";

@Injectable()
export class NavigationService {
  public leftSideBarVisible: boolean = false;
  public leftSideBarMinified: boolean = false;

  private _leftSideBarFloating: boolean = false;
  private readonly renderer: Renderer2;
  private readonly NARROW_WIDTH: number = 768; // from bootstrap Medium-Width css parameters
  private readonly MEDIUM_WIDTH: number = 992; // from bootstrap Medium-Width css parameters


  public narrowScreen: boolean; // for narrow screens (mobile devices) use floating mode only
  public mediumScreen: boolean;
  public wideScreen: boolean;

  public leftSideBarVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.leftSideBarVisible);
  public leftSideBarMinify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.leftSideBarMinified);
  public leftSideBarFloat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.leftSideBarFloating);
  public screenSizeChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient, rendererFactory: RendererFactory2,
              private authService: AuthService) {
    this.leftSideBarVisible = localStorage.getItem('leftSideBarVisible') === 'true';
    this.leftSideBarMinified = localStorage.getItem('leftSideBarMinified') === 'true';
    this._leftSideBarFloating = localStorage.getItem('leftSideBarFloating') === 'true';

    this.authService.user$.subscribe(() => this.updateState());
    this.calcScreenWidth(window.innerWidth);

    this.renderer = rendererFactory.createRenderer(null, null);
    this.renderer.listen(window, 'resize',
      () => {
        let wasWideScreen = this.wideScreen;
        let wasNarrowScreen = this.narrowScreen;
        let wasMediumScreen = this.mediumScreen;

        this.calcScreenWidth(window.innerWidth);
        if (wasMediumScreen !== this.mediumScreen || wasNarrowScreen !== this.narrowScreen || this.wideScreen !== wasWideScreen) {
          this.screenSizeChange.emit();
          this.leftSideBarFloat.next(this.leftSideBarFloating);
        }
      });
  }

  private updateState() {
    this.authService.currentUserHasRole(UserRoleType.ROLE_ADMIN)
      .subscribe(haveAdminRole => {
        this.leftSideBarVisible = localStorage.getItem('leftSideBarVisible') === 'true' && haveAdminRole;
        this.leftSideBarMinified = localStorage.getItem('leftSideBarMinified') === 'true' || !haveAdminRole;
        this._leftSideBarFloating = localStorage.getItem('leftSideBarFloating') === 'true' && haveAdminRole;
      });
  }

  private calcScreenWidth(windowWidth: number) {
    this.narrowScreen = windowWidth < this.NARROW_WIDTH;
    this.wideScreen = windowWidth >= this.MEDIUM_WIDTH;
    this.mediumScreen = !this.narrowScreen && !this.wideScreen;
  }

  public get leftSideBarFloating(): boolean {
    return this.narrowScreen || this._leftSideBarFloating;
  }

  public toggleLeftSideBar() {
    if (this.leftSideBarVisible) {
      this.hideLeftSideBar();
    } else {
      this.showLeftSideBar();
    }
  }

  public hideLeftSideBar() {
    setTimeout(() => {
      this.leftSideBarVisible = false;
      localStorage.setItem('leftSideBarVisible', this.leftSideBarVisible.toString());
      this.leftSideBarVisibility.next(this.leftSideBarVisible);
    });
  }

  public showLeftSideBar() {
    setTimeout(() => { // need timeout to resolve LeftSidebar (clickOutside) conflict with MainMenu button
      this.leftSideBarVisible = true;
      localStorage.setItem('leftSideBarVisible', this.leftSideBarVisible.toString());
      this.leftSideBarVisibility.next(this.leftSideBarVisible);
    });
  }

  public toggleLeftSideBarMinified() {
    this.leftSideBarMinified = !this.leftSideBarMinified;
    localStorage.setItem('leftSideBarMinified', this.leftSideBarMinified.toString());
    this.leftSideBarMinify.next(this.leftSideBarMinified);
  }

  public toggleLeftSideBarFloating() {
    if (this.wideScreen) {
      this._leftSideBarFloating = !this._leftSideBarFloating;
      localStorage.setItem('leftSideBarFloating', this._leftSideBarFloating.toString());
      this.leftSideBarFloat.next(this._leftSideBarFloating);
    }
  }
}
