<!-- WRAPPER -->
<div id="wrapper" class="wrapper">
  <ngx-spinner bdColor = "#33333366" size = "medium" color = "#fff" type = "pacman" [fullScreen] = "true">
    <p style="color: white">Loading...</p>
  </ngx-spinner>


  <oms-alerts [showNotifications]="notifications"></oms-alerts>
  <oms-header [currentUser]="authService.user$ | async"></oms-header>
  <wizard-header></wizard-header>
  <left-sidebar #leftSideBar *ifRole="'ROLE_ADMIN'"
                (on-item-open)="onMenuItemOpen($event)"
                [visibility]="navigationService.leftSideBarVisible"
                [minified]="navigationService.leftSideBarMinified"
                [floating]="navigationService.leftSideBarFloating">
  </left-sidebar>

  <!-- main app container -->
  <div #mainContentWrapper id="main-content-wrapper" class="content-wrapper"
       [ngClass]="{
          'expanded': navigationService.leftSideBarVisible && navigationService.leftSideBarMinified,
          'expanded-full': !navigationService.leftSideBarVisible || navigationService.leftSideBarFloating}"
       ngClass="getUserConfiguration() == null ? login">
    <!--oms-breadcrumbs *ngIf="breadcrumbs"></oms-breadcrumbs-->
    <router-outlet></router-outlet>
  </div>
  <oms-footer #footer></oms-footer>

</div>

