import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'oms-input-text-dialog',
  templateUrl: './input-text-dialog.component.html',
  styleUrls: ['./input-text-dialog.component.scss']
})
export class InputTextDialogComponent implements AfterViewInit {
  public text: string;
  @ViewChild('input') input: ElementRef<HTMLTextAreaElement>;

  constructor(
    public dialogRef: MatDialogRef<InputTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = data.text;
  }

  onOk(event) {
    this.data.text = this.text;
  }

  ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

}
