import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Master, Order, RecoveryOrder } from '../../../modules/shared/models';
import { MasterService } from '../../../services/master/master.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnIds } from '../../../common/column-ids';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { isNullOrUndefined } from 'util';
import { AuthService, OrdersService } from '../../../services';
import { SELECTED_FLAG } from '../../../modules/shared/components/base/data-table/data-table.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterProcessor } from '../../../common/master-processor';
import { FileUploadService } from '../../../services/file.upload.service';
import { WebSocketService } from '../../../services/websocet/web.socket.service';
import { ClipboardCopyService } from '../../../modules/shared/services/clipboard.copy.service';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { TabsType } from './tabs-type';
import { MasterManifestExcelRequest } from '../../../modules/shared/models/master.manifest.excel.request';
import { LocationBackService } from '../../../services/location-back.service';
import { CopyToClipboardColumnsType } from '../../../common/copy-to-clipboard-orders';
import { RecoveryAndDispatchLogDialogComponent } from "../../../components/common/recovery-and-dispatch-log-dialog/recovery-and-dispatch-log-dialog.component";
import { MatDialog } from "@angular/material";
import { MawbTrackDialogComponent } from "../../../components/common/mawb-track-dialog/mawb-track-dialog.component";
import { ReceivingLogDialog } from '../../../components/dialogs/logs/receiving-log-dialog/receiving-log-dialog.component';
import { convertMawbNumber, valueOrNA, valueOrNAFixed } from "../../../modules/shared/services/oms-converters.service";
import { of, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { FilterSearchColumn } from "../../../modules/shared/models/filter.search.column";
import { MasterMode } from "../../../modules/shared/models/master/master-mode";
import { OrderSubscriptionDialogComponent } from "../../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import { OrderSubscriptionService } from "../../../services/order-subscription/order-subscription.service";
import { CbpStatusService } from "../../../modules/shared/services/stb-status/cbp.status.service";
import { MasterLineService } from "../../../services/master/master-line.service";
import { Manifest } from "../../../modules/shared/models/manifest/manifest";
import { DispatchService } from "../../../modules/shared/services/dispatch/dispatch.service";
import { DispatchUtils } from "../../../_helpers/dispatch-utils";
import { OmsColumns } from "../../../common/oms-columns.service";
import { UserUtil } from "../../../modules/settings/util/user.util";
import { assigned, openMailTo } from "../../../_helpers/utils";
var MastersListComponent = /** @class */ (function () {
    function MastersListComponent(cdr, router, route, dialog, alerts, masterProcessor, authService, _masterService, _masterLineService, spinner, fileUploadService, webSocketService, ordersService, clipboardCopyService, locationBackService, orderSubscriptionService, cbpStatusService, dispatchService, dispatchUtils, _columnsService) {
        var _this = this;
        this.cdr = cdr;
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.alerts = alerts;
        this.masterProcessor = masterProcessor;
        this.authService = authService;
        this._masterService = _masterService;
        this._masterLineService = _masterLineService;
        this.spinner = spinner;
        this.fileUploadService = fileUploadService;
        this.webSocketService = webSocketService;
        this.ordersService = ordersService;
        this.clipboardCopyService = clipboardCopyService;
        this.locationBackService = locationBackService;
        this.orderSubscriptionService = orderSubscriptionService;
        this.cbpStatusService = cbpStatusService;
        this.dispatchService = dispatchService;
        this.dispatchUtils = dispatchUtils;
        this._columnsService = _columnsService;
        this.TabsType = TabsType;
        this.MasterMode = MasterMode;
        this.clientUser = false;
        this.availableTabs = [];
        this.webSocketEventName = '/oms-event/master-update'; // '/data-table/masters';
        this.activeSearchFilterCondition = {};
        this.recoveryMasters = [];
        this.cfsanddeliveryMasters = [];
        this.billingMasters = [];
        this.closedMasters = [];
        this.allMasters = [];
        this.pendingMasters = [];
        this.deletedMasters = [];
        this.countHolder = {};
        this.unsubscribe$ = new Subject();
        this.widthFirstPanel = 100;
        this.widthSecondPanel = 0;
        this.prevWidthFirstPanel = 35;
        this.prevWidthSecondPanel = 65;
        this.manifestForRightPanel = new Manifest();
        this.orderDispatchLoads = [];
        this.contextMenu = function (row, column) {
            if (column && (column.id === ColumnIds.MASTER_ID || column.id === 'id')) {
                if (row instanceof Master) {
                    return _this.mastersMenu;
                }
                if (row instanceof Order) {
                    return _this.ordersMenu;
                }
                if (row instanceof RecoveryOrder) {
                    return _this.splitsMenu;
                }
            }
            return null;
        };
    }
    MastersListComponent.prototype.updatePathParams = function () {
        var params = {
            tab: (this.selectedTab || this.availableTabs[0]).id,
        };
        var search = this.getSearchText();
        if (search) {
            params['search'] = search;
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
        }).then();
    };
    MastersListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.availableTabs = TabsType.allAirImportTabs;
        this.currentUser = UserUtil.getCurrentUser();
        this.initWebSocket();
        this.locationBackService.back$.pipe(takeUntil(this.unsubscribe$)).subscribe(function (value) {
            if (value === 'delete') {
                _this.activeTable.updateData();
            }
        });
        this.route.queryParams.subscribe(function (params) {
            console.log('Receive Params');
            var tab = params.tab;
            var search = params.search;
            if (search) {
                setTimeout(function () { return _this.searchChanged(search); }, 500);
            }
            _this.selectedTab = _this.availableTabs.find(function (t) { return t.id === tab; });
            if (!_this.selectedTab) {
                _this.selectedTab = _this.availableTabs[0];
                _this.updatePathParams();
            }
            _this.switchTab();
        });
        this.openManifestFromShipment();
    };
    MastersListComponent.prototype.ngAfterViewInit = function () {
        this.activeTable = this.getActiveTab();
        this.changeStatusFilterSearch(this.selectedTab /*acavailableTabs[0]*/);
        this.openManifestFromOrder();
    };
    MastersListComponent.prototype.switchTab = function () {
        var _this = this;
        this.activeTable = this.getActiveTab();
        this.countHolder[this.selectedTab.filterName] = 0;
        if (this.activeTable && this.activeTable.isDirty) {
            this.activeTable.spinner.show();
            this.activeTable.updateData().pipe(takeUntil(this.unsubscribe$))
                .subscribe(function () { return _this.activeTable.spinner.hide(); }, function (error) {
                _this.activeTable.spinner.hide().then();
                _this.alerts.error(error);
            });
        }
        this.openManifestFromOrder();
    };
    MastersListComponent.prototype.onSelectTab = function (tabInfo) {
        this.selectedTab = tabInfo;
        this.updatePathParams();
        this.switchTab();
    };
    MastersListComponent.prototype.eventList = function (activeTab) {
        return this.countHolder[activeTab.filterName] || 0;
    };
    MastersListComponent.prototype.initWebSocket = function () {
        var _this = this;
        this.webSocketService.stompClientSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(function (stompClient) {
            if (!isNullOrUndefined(stompClient)) {
                _this.socketSub = stompClient.subscribe(_this.webSocketEventName, function (message) {
                    var messageObject = JSON.parse(message.body);
                    console.log('MESSAGE', messageObject);
                    var statusId = messageObject.masterStatus.id;
                    //            let master = onAfterLoad(plainToClass(Master, messageObject.updatedValue as Master));
                    if (messageObject.operation === 'CREATE') {
                        if (!_this.isMasterCreatedInSelectedTab(statusId)) {
                            _this.availableTabs
                                .filter(function (tab) { return tab.statuses && tab.statuses.indexOf(statusId) > -1; })
                                .forEach(function (tab) {
                                _this.countHolder[tab.filterName] = _this.countHolder[tab.filterName] || 0;
                                _this.countHolder[tab.filterName]++;
                            });
                            _this.changed();
                        }
                    }
                    else if (messageObject.operation === 'UPDATE') {
                        console.log('UPDATE', messageObject);
                        _this.availableTabs
                            .filter(function (tab) { return tab.statuses && tab.statuses.indexOf(statusId) > -1; })
                            .forEach(function (tab) {
                            var table = _this.tabs.find(function (t) { return t.tabInfo.filterName === tab.filterName; });
                            if (table) {
                                table.isDirty = true;
                            }
                        });
                        _this.changed();
                        /*              // todo Objects are different for every tab, need check object type and refresh all tabs
                        
                                      let activeTab = this.getActiveTab();
                                      if (activeTab) {
                                        console.log('UPDATED', master);
                                        activeTab.updateItems([master]);
                                      }
                        
                         */
                    }
                });
            }
        });
    };
    MastersListComponent.prototype.isMasterCreatedInSelectedTab = function (statusId) {
        //    const statusId = master.status;
        var newMasterCreated = this._masterService.newMasterCreated.getValue();
        if (newMasterCreated) {
            this._masterService.newMasterCreated.next(false);
            if (this.selectedTab.statuses && this.selectedTab.statuses.indexOf(statusId) > -1) {
                this.countHolder[this.selectedTab.filterName] = 0;
                return true;
            }
        }
        return false;
    };
    MastersListComponent.prototype.downloadExcel = function () {
        var _this = this;
        var table = this.getActiveTab();
        if (table) {
            (table.tabInfo.isOrder
                ? this.ordersService.downloadExcel(table.filterPageRequest, this.alerts)
                : this._masterLineService.downloadExcel(table.filterPageRequest, this.alerts))
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(function (res) {
                var fileURL = _this.buildShowUrl(res);
                window.open(fileURL, '_blank');
            });
        }
    };
    MastersListComponent.prototype.downloadExcelPendingRecoveries = function () {
        var _this = this;
        var table = this.getActiveTab();
        if (table) {
            table.filterPageRequest.filterByColumns.push(new FilterSearchColumn(ColumnIds.MASTER_PENDING_RECOVERIES, '1', null, null));
            (table.tabInfo.isOrder
                ? this.ordersService.downloadExcel(table.filterPageRequest, this.alerts)
                : this._masterService.downloadMasters(table.filterPageRequest, this.alerts))
                .subscribe(function (res) {
                var fileURL = _this.buildShowUrl(res);
                window.open(fileURL, '_blank');
            });
        }
    };
    MastersListComponent.prototype.downloadInventory = function () {
        var _this = this;
        var table = this.getActiveTab();
        if (table) {
            var statusFilters = TabsType.CFS.tabFilters.concat(TabsType.WHSE.tabFilters);
            table.filterPageRequest.statusFilters = statusFilters;
            (table.tabInfo.isOrder
                ? this.ordersService.downloadExcel(table.filterPageRequest, this.alerts)
                : this._masterService.downloadMasters(table.filterPageRequest, this.alerts))
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(function (res) {
                var fileURL = _this.buildShowUrl(res);
                window.open(fileURL, '_blank');
            });
        }
    };
    MastersListComponent.prototype.toggleSearchableFilter = function () {
        this.getActiveTab().searchable = !this.getActiveTab().searchable;
    };
    MastersListComponent.prototype.clearFilters = function () {
        this.getActiveTab().table.clearTableInputs();
    };
    MastersListComponent.prototype.isTableFilterNotClear = function () {
        var tab = this.getActiveTab();
        if (!tab || !tab.table) {
            return false;
        }
        return !tab.table.isClearTableInputs();
    };
    MastersListComponent.prototype.downloadNominationExcel = function () {
        var _this = this;
        var selectedMasters = this.selectedMasters;
        var selectedMasterIds = selectedMasters.map(function (item) { return item.id; });
        this._masterService.downloadNominationMasters(selectedMasterIds).pipe(takeUntil(this.unsubscribe$)).subscribe(function (res) {
            var fileURL = _this.buildShowUrl(res);
            window.open(fileURL, '_blank');
        });
    };
    MastersListComponent.prototype.downloadMastersManifest = function () {
        var _this = this;
        this._masterService.downloadMastersManifest(this.getActiveTab().filterPageRequest).pipe(takeUntil(this.unsubscribe$)).subscribe(function (res) {
            var fileURL = _this.buildShowUrl(res);
            window.open(fileURL, '_blank');
        });
    };
    MastersListComponent.prototype.buildShowUrl = function (generatedDocumentInfo) {
        return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
    };
    MastersListComponent.prototype.changeStatusFilterSearch = function (activeTab) {
        if (activeTab.statuses) {
            this.activeSearchFilterCondition[ColumnIds.MASTER_STATUS] = activeTab.statuses;
        }
        // for start digest
        this.activeSearchFilterCondition = Object.assign({}, this.activeSearchFilterCondition);
        this.selectedTab = activeTab;
        this.countHolder[activeTab.filterName] = 0;
        this.updateData();
    };
    Object.defineProperty(MastersListComponent.prototype, "defaultSortableColumn", {
        get: function () {
            return ColumnIds.MASTER_STATUS;
        },
        enumerable: true,
        configurable: true
    });
    MastersListComponent.prototype.changed = function () {
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    };
    MastersListComponent.prototype.refresh = function () {
        this.updateData();
    };
    MastersListComponent.prototype.updateData = function () {
        this.callSync(this.tabs.toArray());
    };
    MastersListComponent.prototype.callSync = function (items) {
        var _this = this;
        if (items.length === 0) {
            return;
        }
        var findSelectedTab = items.find(function (item) { return item.tabInfo === _this.selectedTab; });
        var index = findSelectedTab ? items.indexOf(findSelectedTab) : 0;
        var tabToUpdate = findSelectedTab || items[0];
        if (tabToUpdate === findSelectedTab || tabToUpdate.tabInfo.preLoad) {
            setTimeout(function () {
                tabToUpdate.updateData().pipe(takeUntil(_this.unsubscribe$)).subscribe(function () {
                    items.splice(index, 1);
                    _this.callSync(items);
                });
            }, 500);
        }
        else {
            tabToUpdate.isDirty = true;
            items.splice(index, 1);
            this.callSync(items);
        }
    };
    MastersListComponent.prototype.getSearchText = function () {
        var tab = this.tabs.find(function (t) { return assigned(t.getSearchText()); });
        return tab && tab.getSearchText();
    };
    MastersListComponent.prototype.searchChanged = function (text) {
        this.tabs.forEach(function (tab) { return tab.changeSearchText(text, 0); });
        this.updatePathParams();
        //    this.updateData();
    };
    MastersListComponent.prototype.searchColorChanged = function (event) {
        this.colorStatusFilter = event;
        this.tabs.forEach(function (table) {
            table.colorStatusFilter = event;
        });
        this.updateData();
    };
    MastersListComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        if (this.socketSub) {
            this.socketSub.unsubscribe();
        }
    };
    /*
      showMaster(data: Object) {
        // this.table.toggleExpanded(data);
      }
  
      onSelectMaster(event) {
      }
     */
    MastersListComponent.prototype.buildDownloadUrl = function (fileType) {
        return this._masterService.buildDownloadUrl(fileType);
    };
    MastersListComponent.prototype.onUpdateFieldError = function (error, updateEvent) {
        if (updateEvent) {
            updateEvent.cancel();
        }
        if (typeof error === 'string' && error.includes('UNIQUE KEY')) {
            error = 'Duplicated value not allowed: ' + updateEvent.newValue;
        }
        //    console.log('Update Field Error', updateEvent, error);
        this.alerts.danger(error);
    };
    MastersListComponent.prototype.children = function (parent) {
        if (parent instanceof Master) {
            return of(parent.splits.concat(parent.orders));
        }
        return of([]);
    };
    MastersListComponent.prototype.isLeaf = function (parent) {
        return !(parent instanceof Master);
    };
    MastersListComponent.prototype.onResize = function (event) {
        this.cdr.markForCheck();
        //    this.calculateContentHeight();
    };
    Object.defineProperty(MastersListComponent.prototype, "showDeleted", {
        get: function () {
            return this.selectedTab && this.selectedTab === TabsType.MASTERS_DELETED;
        },
        enumerable: true,
        configurable: true
    });
    MastersListComponent.prototype.copyDataToClipboard = function () {
        var _this = this;
        var activeTable = this.getActiveTab();
        var columns = activeTable.tabInfo.isOrder
            ? CopyToClipboardColumnsType.createOrderColumns()
            : CopyToClipboardColumnsType.createMasterLineColumns();
        var fileName = activeTable.tabInfo.isOrder
            ? 'orders'
            : 'masters';
        if (activeTable.tabInfo.isOrder) {
            this.clipboardCopyService.prepareItemsForCopyToClipboard(activeTable.items, columns, fileName);
        }
        else {
            var subject = this._masterService.mastersByIDs(activeTable.items.map(function (master) { return master.id; }));
            subject
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(function (items) {
                _this.clipboardCopyService.prepareItemsForCopyToClipboard(items, columns, fileName);
            });
        }
        // this.clipboardCopyService.parseDataAndCopyToClipboard(parsedMasters);
    };
    MastersListComponent.prototype.copyDataToClipboardManifest = function () {
        var _this = this;
        var masters = this.getActiveTab().items;
        var masterIds = masters.map(function (master) { return master.id; });
        var masterManifestExcelRequest = new MasterManifestExcelRequest(masterIds, null, null, null, null, true);
        this._masterService.downloadMastersManifestByManifest(masterManifestExcelRequest).pipe(takeUntil(this.unsubscribe$)).subscribe(function (res) {
            var fileURL = _this.buildShowUrl(res);
            window.open(fileURL, '_blank');
        });
    };
    MastersListComponent.prototype.getActiveTab = function () {
        var _this = this;
        return this.tabs ? this.tabs.find(function (table) { return table.tabInfo.filterName === _this.selectedTab.filterName; }) : null;
    };
    MastersListComponent.prototype.getTab = function (tab) {
        return this.tabs ? this.tabs.find(function (table) { return table.tabInfo.filterName === tab.filterName; }) : null;
    };
    MastersListComponent.prototype.onCellRightClick = function (event) {
        if (event.column.id === ColumnIds.MASTER_FILL_STATUS || event.column.id === ColumnIds.ORDER_FILL_STATUS) {
            console.log('Right Click Handler', event);
            this.showReceivingLog(event.row);
            event.handled = true;
        }
    };
    MastersListComponent.prototype.openDispatchAndRecoveryLogs = function (obj, type) {
        var masterId = type === "MASTER" ? obj.id : obj.masterId;
        var shipmentId = type === "SPLIT" ? obj.shipment.id : null;
        var orderId = type === "SPLIT" ? obj.orderId : null;
        var instance = this.dialog.open(RecoveryAndDispatchLogDialogComponent, {
            width: '68%'
        });
        instance.componentInstance.masterId = masterId;
        instance.componentInstance.shipmentId = shipmentId;
        instance.componentInstance.orderId = orderId;
        instance.componentInstance.objectType = type;
    };
    MastersListComponent.prototype.onCellClick = function (event) {
        if (event.column.id === ColumnIds.MASTER_PICKUP_DATE || event.column.id === ColumnIds.ORDER_PICKUP_DATE) {
            if (event.row instanceof Master) {
                //        this.pickupMaster(event.row);
                this.openDispatchAndRecoveryLogs(event.row, 'MASTER');
            }
            else if (event.row instanceof RecoveryOrder) {
                this.pickupLoad(event.row);
            }
        }
    };
    MastersListComponent.prototype.pickupMaster = function (master) {
        var _this = this;
        this.masterProcessor.updateMasterRecoveryPickUp(master)
            .then(function (m) {
            console.log('PICKUP RESULT>', m);
            _this.updateMaster(m);
        })
            .catch(function (error) { return _this.alerts.error(error, 'Error Pickup'); });
    };
    MastersListComponent.prototype.pickupLoad = function (order) {
        var _this = this;
        this.masterProcessor.updateMasterRecoveryPickUp(order.master, order)
            .then(function (master) {
            console.log('PICKUP RESULT>', master);
            _this.updateMaster(master);
        })
            .catch(function (error) { return _this.alerts.error(error, 'Error Pickup'); });
    };
    MastersListComponent.prototype.toggleMasterDirect = function (e) {
        var _this = this;
        var master = e.item.row;
        var direct = !master.direct;
        if (direct) {
            if (master.orders.asUniqueValue(function (o) { return o.addressDelivery; }, 'mixed') === 'mixed') {
                this.alerts.warning('Delivery Locations of Orders must be equal!', 3000);
                return;
            }
        }
        this._masterService._updateMaster(master, function (m) {
            _this.masterProcessor.setDirect(m, direct);
            return true;
        })
            .then(function () {
            _this.masterProcessor.setDirect(master, direct);
            _this.updateData();
        });
    };
    MastersListComponent.prototype.toggleMasterNonAms = function (e) {
        var master = e.item.row;
        this._masterService._updateMaster(master, function (m) {
            m.nonAMS = !m.nonAMS;
            return true;
        }).then(function () {
            master.nonAMS = !master.nonAMS;
        });
    };
    Object.defineProperty(MastersListComponent.prototype, "selectedMasters", {
        get: function () {
            var table = this.getActiveTab();
            if (table) {
                return table.selected.filter(function (o) {
                    return o instanceof Master;
                });
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MastersListComponent.prototype, "dispatchAllowed", {
        get: function () {
            var table = this.getActiveTab();
            return table && [TabsType.CFS.filterName, TabsType.RECOVERY.filterName, TabsType.WHSE.filterName].includes(table.tabInfo.filterName) ? table.selected.length > 0 : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MastersListComponent.prototype, "manifestAllowed", {
        get: function () {
            var table = this.getActiveTab();
            return table && [TabsType.CFS.filterName, TabsType.RECOVERY.filterName].includes(table.tabInfo.filterName) ? table.selected.length > 0 : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MastersListComponent.prototype, "showPendingRecoveryFilter", {
        get: function () {
            return this.activeTable.tabInfo === TabsType.ACTIVE || this.activeTable.tabInfo === TabsType.RECOVERY;
        },
        enumerable: true,
        configurable: true
    });
    MastersListComponent.prototype.dispatch = function () {
        var table = this.getActiveTab();
        if (table) {
            switch (table.tabInfo.filterName) {
                case TabsType.CFS.filterName:
                    this.dispatchOrdersDelivery(undefined, table);
                    break;
                case TabsType.WHSE.filterName:
                    this.dispatchOrdersDelivery(undefined, table);
                    break;
                case TabsType.RECOVERY.filterName:
                    this.openManifestFromMasters();
                    break;
            }
        }
    };
    MastersListComponent.prototype.dispatchOrdersDelivery = function (order, table) {
        var _this = this;
        var orders = table.selected.slice();
        if (orders.length === 0 && order) {
            orders.push(order);
        }
        if (orders.length > 0) {
            this.masterProcessor.dispatchOrdersDelivery(orders)
                .catch(function (error) { return _this.alerts.danger(error); })
                .then(function (os) {
                console.log('Dispatched', os);
                table.selected.clear();
                table.updateData();
                //          this.activeTable.updateData(false);
                //          this.changed();
            });
        }
    };
    MastersListComponent.prototype.dispatchMastersRecovery = function (master) {
        var _this = this;
        console.log('HERE');
        var recovery = this.getTab(TabsType.RECOVERY);
        var masters = recovery ? recovery.selected.slice() : [];
        if (masters.length === 0 && master) {
            masters.push(master);
        }
        if (masters.length > 0) {
            this.masterProcessor.dispatchMastersRecovery(masters)
                .catch(function (error) { return _this.alerts.danger(error); })
                .then(function (ms) {
                var table = _this.getActiveTab().table;
                table.selected.clear();
                //        masters.forEach(m=>this._masterService.getMaster(m.id).then(master=>this.table.refresh([master])));
                table.refresh(ms);
                _this.changed();
            });
        }
    };
    MastersListComponent.prototype.openMultipleOrders = function (master) {
        var recovery = this.getTab(TabsType.RECOVERY);
        var masters = recovery ? recovery.selected.slice() : [];
        if (masters.length === 0 && master) {
            masters.push(master);
        }
        var ids = [];
        if (masters.length > 0) {
            masters.forEach(function (m) { ids = ids.concat(m.orders.map(function (oi) { return oi.id; })); });
            localStorage.setItem('multipleOrders' + this.currentUser.id, ids.toString());
            window.open('/warehouse/multiple-order', '_blank');
        }
    };
    MastersListComponent.prototype.receive = function (object) {
        //    console.log('Receive', object, this.router);
        this.router.navigate(['receiving',
            { master: object instanceof Master ? object.id : object.masterId }], { relativeTo: this.route });
    };
    MastersListComponent.prototype.dispatchMastersMenuLabel = function (master) {
        var recovery = this.getTab(TabsType.RECOVERY);
        return (!recovery || recovery.selected.length === 0) ? 'Dispatch Recovery...' : 'Dispatch ' + recovery.selected.length + ' Master(s)...';
    };
    MastersListComponent.prototype.isSupportDispatch = function () {
        return this.selectedTab !== TabsType.CFS && this.selectedTab !== TabsType.ACTIVE;
    };
    MastersListComponent.prototype.dispatchAndRecoveryLogs = function (obj, type) {
        var masterId = type === "MASTER" ? obj.id : obj.masterId;
        var shipmentId = type === "SPLIT" ? obj.shipment.id : null;
        var orderId = type === "SPLIT" ? obj.orderId : null;
        var instance = this.dialog.open(RecoveryAndDispatchLogDialogComponent, {
            width: '68%'
        });
        instance.componentInstance.masterId = masterId;
        instance.componentInstance.shipmentId = shipmentId;
        instance.componentInstance.orderId = orderId;
        instance.componentInstance.objectType = type;
    };
    MastersListComponent.prototype.showReceivingLog = function (obj) {
        var master;
        var load;
        if (obj instanceof Master) {
            master = obj;
        }
        else if (obj instanceof Order) {
            master = obj.master;
        }
        else if (obj instanceof RecoveryOrder) {
            master = obj.master;
            load = obj.load;
        }
        this.dialog.open(ReceivingLogDialog, { data: { master: master, load: load }, width: 'auto;' });
    };
    MastersListComponent.prototype.sendMail = function (master) {
        var email = this.getEmail(master) || '';
        var subject = (convertMawbNumber(master.mawbNumber) || '');
        subject += isNullOrUndefined(master.freightForwarderRef) || master.freightForwarderRef.length === 0 ? '' : '  REF ' + (master.freightForwarderRef || '');
        openMailTo(email, subject, [], '');
    };
    MastersListComponent.prototype.sendNominationMail = function (master) {
        var selectedMails = [];
        selectedMails.push(master);
        this.activeTable.selected.forEach(function (item) {
            if (item.id !== master.id) {
                selectedMails.push(item);
            }
        });
        var body = "Hello,\nPlease nominate to JJS TRANSPORTATION - Firms code: E804 as follows:\n\n\n";
        var subject = selectedMails.length === 1 ? convertMawbNumber(master.mawbNumber) + " E804 nomination request" :
            "Multiple MAWBs E804 nomination request";
        selectedMails.forEach(function (m) {
            body += convertMawbNumber(m.mawbNumber) + "\t\t";
            body += valueOrNA(m.pieces) + " PCS       \t";
            body += valueOrNAFixed(m.weight) + " KG       \t";
            body += m.orders.length + " HAWBS\n\n";
        });
        body += "Thank you,\n\n";
        body += this.getEmailFooter();
        var email = this.getEmail(master) || '';
        openMailTo(email, subject, ['ams@jjstransportation.com'], body);
    };
    MastersListComponent.prototype.openCbpStatusNotificationEmail = function (order) {
        var _this = this;
        this.cbpStatusService.findAllByOrderId(order.id)
            .subscribe(function (items) {
            var email = _this.getEmailFromOrder(order) || '';
            if (!email && order.master) {
                email = _this.getEmail(order.master) || '';
            }
            var cbpStatus = _this.collectUniqCbpStatuses(items).join(", ");
            var hawb = order.hawb || '';
            var mawb = order.master ? convertMawbNumber(order.master.mawbNumber) : '';
            var ref = order.customerRef || '';
            var subject = "CBP status Notification " + mawb + ' ' + hawb + ' ' + ref;
            var body = "Hello,\n\n" +
                "We received a " + cbpStatus + " from US Customs for " + mawb + " - " + hawb + ".\n" +
                "Please update clearance status urgently.\n\n" +
                "Thank you.\n\n" +
                _this.getEmailFooter();
            openMailTo(email, subject, ['ams@jjstransportation.com'], body);
        });
    };
    MastersListComponent.prototype.collectUniqCbpStatuses = function (items) {
        var availableStatuses = ['1A', '1R', '1S', '1E', '1H', '2H', '3H', '4A'];
        var allStatuses = items.map(function (i) { return i.uscsFsnStatus; }).filter(function (s) { return !!s; }).map(function (s) { return s.toLowerCase(); });
        return availableStatuses.filter(function (s) { return allStatuses.includes(s.toLowerCase()); });
    };
    MastersListComponent.prototype.getEmailFooter = function () {
        var loggedUser = this.authService.user$.getValue();
        var content = loggedUser.firstName + " " + loggedUser.lastName + "\n";
        content += "AMS Team\n";
        content += "JJS Transportation & Distribution Co, Inc\n";
        content += "145 Hook Creek Blvd.\n";
        content += "Valley Stream, NY 11581\n";
        content += "Tel: (516)-256-1561\n";
        content += "www.jjstransportation.com\n";
        return content;
    };
    MastersListComponent.prototype.getFieldIfObjectNotNull = function (object, items) {
        if (isNullOrUndefined(object)) {
            return "";
        }
        if (items.length > 1) {
            return this.getFieldIfObjectNotNull(object[items.shift()], items);
        }
        return object[items[0]];
    };
    MastersListComponent.prototype.sendMailForOrder = function (order) {
        var email = this.getEmailFromOrder(order) || '';
        var subject = isNullOrUndefined(order.master) ? convertMawbNumber(order.mawb || '') : convertMawbNumber(order.master.mawbNumber || '');
        subject += isNullOrUndefined(order.hawb) ? '' : '  HAWB ' + (order.hawb);
        subject += isNullOrUndefined(order.customerRef) ? '' : '  Ref ' + (order.customerRef);
        openMailTo(email, subject, [], "");
    };
    MastersListComponent.prototype.getEmail = function (master) {
        for (var i = 0; i < master.orders.length; i++) {
            var email = this.getEmailFromOrder(master.orders[i]);
            if (email) {
                return email;
            }
        }
        return null;
    };
    MastersListComponent.prototype.getEmailFromOrder = function (order) {
        if (order.customer && order.customer.emails) {
            for (var i = 0; i < order.customer.emails.length; i++) {
                var email = order.customer.emails[i];
                if (email && email.email) {
                    return email.email;
                }
            }
        }
        return null;
    };
    MastersListComponent.prototype.showAsmDataDialog = function (obj) {
        this.dialog.open(MawbTrackDialogComponent, { width: '80%', data: { id: obj.id } });
    };
    MastersListComponent.prototype.updateMaster = function (master) {
        this.tabs.forEach(function (tab) { return tab.updateItems([master]); });
        this.changed();
    };
    MastersListComponent.prototype.updateObject = function (object, fields) {
        var _this = this;
        if (object instanceof Master) {
            return this._masterService.updateMaster(object.id, fields)
                .then(function (master) { return _this.updateMaster(master); });
        }
        else if (object instanceof Order) {
            return this.ordersService.updateOrderOld(object.id, fields)
                .then(function (obj) {
                var order = obj instanceof Master ? obj.orders.find(function (o) { return o.id === object.id; }) : object;
                if (order) {
                    order['cfsLocation'] = object['cfsLocation'];
                    _this.tabs.forEach(function (tab) { return tab.updateItems([order]); });
                    order.mawb = object.mawb;
                }
            });
        }
    };
    MastersListComponent.prototype.recalculateOrderStatus = function (order) {
        console.log('recalculate', order);
        this.updateObject(order, {}).then();
    };
    MastersListComponent.prototype.recalculateMasterStatus = function (master) {
        this.updateObject(master, {}).then();
    };
    MastersListComponent.prototype.generateBOL = function (isOpenPDF, order) {
        var _this = this;
        var ids = [];
        if (!isNullOrUndefined(order)) {
            ids = ids.concat(order.id);
        }
        else {
            var selectedMasters = this.selectedMasters;
            selectedMasters.forEach(function (master) {
                ids = ids.concat(master.orders.map(function (oi) { return oi.id; }));
            });
        }
        this.ordersService.generateBOLs(ids).subscribe(function (response) {
            _this.generateBOLOpenPDF(response, isOpenPDF);
        });
    };
    MastersListComponent.prototype.printBOL = function (order) {
        var _this = this;
        var ids = [];
        if (!isNullOrUndefined(order)) {
            ids = ids.concat(order.id);
        }
        else {
            var selectedMasters = this.selectedMasters;
            selectedMasters.forEach(function (master) {
                ids = ids.concat(master.orders.map(function (oi) { return oi.id; }));
            });
        }
        this.ordersService.printBOLs(ids).subscribe(function (response) {
            _this.findBOLOpenPDF(response);
        });
    };
    MastersListComponent.prototype.findBOLOpenPDF = function (response) {
        if (!isNullOrUndefined(response)) {
            this.ordersService.openBase64PDF(response);
            this.alerts.success('Find BOLs successful', 5000);
        }
        else {
            this.alerts.error('No BOL available');
        }
    };
    MastersListComponent.prototype.generateBOLOpenPDF = function (response, isOpenPDF) {
        if (!isNullOrUndefined(response)) {
            if (isOpenPDF) {
                this.ordersService.openBase64PDF(response);
            }
            this.alerts.success('Generate BOL successful', 5000);
        }
        else {
            this.alerts.error('Error generate BOL');
        }
    };
    MastersListComponent.prototype.addFlagMaster = function (master) {
        this.addFlag(master.orders);
    };
    MastersListComponent.prototype.removeFlagMaster = function (master) {
        this.removeFlag(master.orders);
    };
    MastersListComponent.prototype.addFlag = function (orders) {
        var dialogRef = this.dialog.open(OrderSubscriptionDialogComponent, { width: 'auto', data: { orders: orders } });
        dialogRef.afterClosed().subscribe(function (res) {
        });
    };
    MastersListComponent.prototype.removeFlag = function (orders) {
        var _this = this;
        this.orderSubscriptionService.deleteByOrders(orders.map(function (o) { return o.id; }))
            .subscribe(function () { return _this.alerts.info("Flag removed", 20000); });
    };
    MastersListComponent.prototype.changePrev = function (first, second) {
        if (first <= 90) {
            this.prevWidthFirstPanel = first;
            this.prevWidthSecondPanel = second;
        }
    };
    MastersListComponent.prototype.dragEnd = function (event) {
        this.widthFirstPanel = event.sizes[0];
        this.widthSecondPanel = event.sizes[1];
        this.changePrev(event.sizes[0], event.sizes[1]);
    };
    MastersListComponent.prototype.onManifestChange = function (manifest) {
        var items = this.orderDispatchLoads.filter(function (dispatchDto) { return manifest.items.some(function (manifestItem) { return manifestItem.hasOrder(dispatchDto.orderId); }); });
        this.setSelected(items);
        this.updateRightPanel();
    };
    MastersListComponent.prototype.onDispatchListSelectionChange = function (list) {
        var _this = this;
        this.orderDispatchLoads = list;
        list.forEach(function (od) {
            var created = _this.manifestForRightPanel.items.find(function (mi) { return mi.orders.some(function (o) { return o.id === od.orderId; }); });
            if (!created) {
                //   this.setOrderDispatchValuesFromOrder(od);
                _this.dispatchUtils.manifestAddOrderDispatch(_this.manifestForRightPanel, od);
            }
        });
        // remove from new Items only
        var manifestItems = this.manifestForRightPanel.items.filter(function (i) { return i.isNew(); });
        manifestItems.forEach(function (mi) {
            mi.orders.forEach(function (o) {
                var selected = list.find(function (od) { return od.orderId === o.id; });
                if (!selected) {
                    _this.dispatchUtils.manifestDeleteOrder(_this.manifestForRightPanel, o);
                }
            });
        });
        this.setDispatchListToRightPanel(this.manifestForRightPanel.items.slice());
    };
    MastersListComponent.prototype.setDispatchListToRightPanel = function (items) {
        this.manifestForRightPanel.items = items;
        this.manifestForRightPanel = Object.assign(new Manifest(), this.manifestForRightPanel);
        this.updateRightPanel();
    };
    MastersListComponent.prototype.setSelected = function (items) {
        this.orderDispatchLoads = items;
    };
    MastersListComponent.prototype.updateRightPanel = function () {
        var list = this.manifestForRightPanel.items;
        if (!list.length && this.widthFirstPanel < 100) {
            this.changePrev(this.widthFirstPanel, this.widthSecondPanel);
            this.widthFirstPanel = 100;
            this.widthSecondPanel = 0;
        }
        else if (list.length && this.widthFirstPanel === 100) {
            this.widthFirstPanel = this.prevWidthFirstPanel;
            this.widthSecondPanel = this.prevWidthSecondPanel;
        }
    };
    MastersListComponent.prototype.onCreatedEvent = function (manifest) {
        this.manifestForRightPanel = new Manifest();
        this.setSelected([]);
        this.setDispatchListToRightPanel([]);
        this.clearSelection();
        this.refresh();
    };
    MastersListComponent.prototype.openManifestFromShipment = function () {
        var _this = this;
        this._columnsService.shipmentFromMasterList$.subscribe(function (id) {
            if (id) {
                _this.spinner.show();
                _this.dispatchService.findOrderDispatchesFromShipment(id).then(function (od) {
                    if (od) {
                        _this.openManifest(od.id);
                    }
                }).catch(function (error) {
                    _this.spinner.hide();
                    _this.alerts.error(error, 'Error open Manifest');
                });
            }
        });
    };
    MastersListComponent.prototype.openManifestFromOrder = function () {
        var _this = this;
        if (this.activeTable) {
            this.activeTable.orderFromMasterList$.subscribe(function (orderId) {
                if (orderId) {
                    _this.ordersChangeSelect([orderId]);
                }
            });
        }
    };
    MastersListComponent.prototype.openManifestFromMasters = function (master) {
        var recovery = this.getTab(TabsType.RECOVERY);
        var masters = recovery ? recovery.selected.slice() : [];
        if (masters.length === 0 && master) {
            masters.push(master);
        }
        if (masters.length > 0) {
            var ids = masters.flatMap(function (m) { return m.splits; })
                .filter(function (ro) { return !ro.isDispatched; })
                .map(function (ro) { return ro.order.id; })
                .unique();
            /*      let orders: Order[] = [];
                  masters.forEach((m) => {
                    let recoveryOrders = m.splits.filter(ro => !ro.load || !ro.load.isRecovery).map((ro) => ro.order);
                    orders = orders.concat(recoveryOrders);
                  });
                  let ids: number[] = orders.map((ow) => ow.id).unique();
            */
            this.ordersChangeSelect(ids);
        }
    };
    MastersListComponent.prototype.ordersChangeSelect = function (ids) {
        var _this = this;
        if (ids && ids.length) {
            this.spinner.show();
            console.log('findDispatchesForOrders', ids);
            this.dispatchService.findDispatchesForOrders(ids).then(function (list) {
                console.log('Found Dispatches', list);
                _this.spinner.hide();
                _this.onDispatchListSelectionChange(list);
                _this.cdr.detectChanges();
            }).catch(function (error) {
                _this.spinner.hide();
                _this.alerts.error(error);
            });
        }
    };
    MastersListComponent.prototype.openManifest = function (dispatchId) {
        var _this = this;
        if (dispatchId) {
            console.log('openManifest');
            this.dispatchService.get(dispatchId)
                .subscribe(function (manifest) {
                _this.spinner.hide();
                console.log('manifest received', manifest);
                manifest.items.forEach(function (mi) {
                    mi.loadTypeOriginal = mi.loadType;
                    mi.orders.forEach(function (o) {
                        mi.loadTypeOriginal = mi.loadType;
                        o.info.legAddressDelivery = mi.addressDelivery;
                    });
                });
                _this.manifestForRightPanel = manifest;
                _this.updateRightPanel();
                _this.cdr.detectChanges();
            }, function (error) {
                _this.spinner.hide();
                _this.alerts.error(error, 'Error open Manifest');
            });
        }
    };
    MastersListComponent.prototype.clearSelection = function () {
        var table = this.getActiveTab();
        if (table) {
            table.selected.forEach(function (item) { return delete item[SELECTED_FLAG]; });
            table.selected.clear();
            table.selectedChange.emit(table.selected);
        }
    };
    return MastersListComponent;
}());
export { MastersListComponent };
