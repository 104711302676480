/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-preview-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../base-dialog/base-dialog.component.ngfactory";
import * as i3 from "ng-drag-drop/src/directives/draggable.directive";
import * as i4 from "ng-drag-drop/src/services/ng-drag-drop.service";
import * as i5 from "../base-dialog/base-dialog.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "ngx-spinner";
import * as i8 from "../../../../../../node_modules/ng2-pdf-viewer/dist/pdf-viewer.component.ngfactory";
import * as i9 from "ng2-pdf-viewer/dist/pdf-viewer.component";
import * as i10 from "@angular/common";
import * as i11 from "./document-preview-dialog.component";
import * as i12 from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import * as i13 from "../../../../services/file.upload.service";
var styles_DocumentPreviewDialogComponent = [i0.styles];
var RenderType_DocumentPreviewDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentPreviewDialogComponent, data: {} });
export { RenderType_DocumentPreviewDialogComponent as RenderType_DocumentPreviewDialogComponent };
function View_DocumentPreviewDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-arrow-left"]], null, null, null, null, null))], null, null); }
function View_DocumentPreviewDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-arrow-right"]], null, null, null, null, null))], null, null); }
export function View_DocumentPreviewDialogComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "oms-base-dialog", [], [[8, "draggable", 0], [8, "className", 0]], [[null, "dragstart"], [null, "dragend"], [null, "mousedown"], [null, "touchstart"], ["document", "keydown.escape"], ["window", "popstate"]], function (_v, en, $event) { var ad = true; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dragStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).dragEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).mousedown($event) !== false);
        ad = (pd_2 && ad);
    } if (("touchstart" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).mousedown($event) !== false);
        ad = (pd_3 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2).onEscape($event) !== false);
        ad = (pd_4 && ad);
    } if (("window:popstate" === en)) {
        var pd_5 = (i1.ɵnov(_v, 2).onPopState($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i2.View_BaseDialogComponent_0, i2.RenderType_BaseDialogComponent)), i1.ɵdid(1, 212992, null, 0, i3.Draggable, [i1.ElementRef, i1.Renderer2, i4.NgDragDropService, i1.NgZone], null, null), i1.ɵdid(2, 4440064, null, 0, i5.BaseDialogComponent, [i6.MAT_DIALOG_DATA, i7.NgxSpinnerService, i6.MatDialogRef], { height: [0, "height"], width: [1, "width"], maxWidth: [2, "maxWidth"], draggable: [3, "draggable"], sizable: [4, "sizable"], storePosition: [5, "storePosition"], showLabel: [6, "showLabel"], showFooter: [7, "showFooter"], loading: [8, "loading"], btnOk: [9, "btnOk"], btnCancel: [10, "btnCancel"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "div", [["class", "page-label"], ["widget-header", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-file-pdf"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, 1, 4, "div", [["id", "outerContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "pdf-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "pdf-viewer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onPageResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_PdfViewerComponent_0, i8.RenderType_PdfViewerComponent)), i1.ɵdid(12, 638976, null, 0, i9.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], renderText: [1, "renderText"], originalSize: [2, "originalSize"], showAll: [3, "showAll"], stickToPage: [4, "stickToPage"], zoom: [5, "zoom"], rotation: [6, "rotation"], externalLinkTarget: [7, "externalLinkTarget"], autoresize: [8, "autoresize"], fitToPage: [9, "fitToPage"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, 2, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-file-download"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"])), (_l()(), i1.ɵeld(18, 0, null, 3, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previous() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentPreviewDialogComponent_1)), i1.ɵdid(21, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentPreviewDialogComponent_2)), i1.ɵdid(24, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = "85vh"; var currVal_3 = "auto"; var currVal_4 = "95vw"; var currVal_5 = false; var currVal_6 = false; var currVal_7 = false; var currVal_8 = false; var currVal_9 = true; var currVal_10 = _co.loading; var currVal_11 = undefined; var currVal_12 = undefined; _ck(_v, 2, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); var currVal_15 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.source)); var currVal_16 = true; var currVal_17 = false; var currVal_18 = true; var currVal_19 = false; var currVal_20 = 1; var currVal_21 = 0; var currVal_22 = "blank"; var currVal_23 = true; var currVal_24 = true; _ck(_v, 12, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24); var currVal_25 = _co.multipleDocuments; _ck(_v, 21, 0, currVal_25); var currVal_26 = _co.multipleDocuments; _ck(_v, 24, 0, currVal_26); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).dragEnabled; var currVal_1 = i1.ɵnov(_v, 2).class; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_13 = ((_co.current == null) ? null : _co.current.documentType.description); _ck(_v, 6, 0, currVal_13); var currVal_14 = ((_co.current == null) ? null : _co.current.uploadedFiles.name); _ck(_v, 8, 0, currVal_14); }); }
export function View_DocumentPreviewDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-document-preview-dialog", [], null, null, null, View_DocumentPreviewDialogComponent_0, RenderType_DocumentPreviewDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.DocumentPreviewDialogComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA, i1.ChangeDetectorRef, i12.OmsAlertsService, i13.FileUploadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentPreviewDialogComponentNgFactory = i1.ɵccf("oms-document-preview-dialog", i11.DocumentPreviewDialogComponent, View_DocumentPreviewDialogComponent_Host_0, {}, {}, []);
export { DocumentPreviewDialogComponentNgFactory as DocumentPreviewDialogComponentNgFactory };
