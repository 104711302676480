﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget"
       [ngClass]="{ '_loading' : viewLoading }">

    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-map-marker"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <div class="content-container">
      <agm-map *ngIf="showMap()"  [latitude]="latitude" [longitude]="longitude" class="content-container" [zoom]="8">
        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
      </agm-map>
    </div>

    <div class="widget-footer">
      <div></div>
      <div class="dialog-btns">
        <button type="button" class="btn btn-primary" (click)="closeDialog()">OK</button>
      </div>
    </div>

  </div>
</perfect-scrollbar>
