/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cbp-status-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../modules/shared/components/base/data-table/data-table.component.ngfactory";
import * as i3 from "../../../../modules/shared/components/base/data-table/data-table.component";
import * as i4 from "ngx-contextmenu";
import * as i5 from "../../../../services/account.service";
import * as i6 from "../../../../services/data-table.service";
import * as i7 from "./cbp-status-table.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import * as i10 from "../../oms-dialogs/oms-dialogs.service";
import * as i11 from "../../../../modules/shared/services/stb-status/cbp.status.service";
import * as i12 from "../../../../common/cbp-status-default-columns.service";
import * as i13 from "../../../../services/file.upload.service";
var styles_CbpStatusTableComponent = [i0.styles];
var RenderType_CbpStatusTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbpStatusTableComponent, data: {} });
export { RenderType_CbpStatusTableComponent as RenderType_CbpStatusTableComponent };
export function View_CbpStatusTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { table: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "oms-data-table", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DataTableComponent_0, i2.RenderType_DataTableComponent)), i1.ɵdid(2, 5226496, [[1, 4], ["table", 4]], 3, i3.DataTableComponent, [i1.ElementRef, i4.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, i5.AccountService, i6.DataTableService], { fixedColumns: [0, "fixedColumns"], specificRowClassHandler: [1, "specificRowClassHandler"], striped: [2, "striped"], rowClass: [3, "rowClass"], selectable: [4, "selectable"], checkboxes: [5, "checkboxes"], multiSelect: [6, "multiSelect"], fixed: [7, "fixed"], condensed: [8, "condensed"], searchable: [9, "searchable"], columns: [10, "columns"], originalData: [11, "originalData"], defaultColumnForSort: [12, "defaultColumnForSort"] }, null), i1.ɵqud(335544320, 2, { totalTmp: 0 }), i1.ɵqud(335544320, 3, { rowHeaderTemplate: 0 }), i1.ɵqud(335544320, 4, { rowTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = 6; var currVal_1 = _co.specificRowClassHandler; var currVal_2 = true; var currVal_3 = _co.getRowClass.bind(_co); var currVal_4 = false; var currVal_5 = true; var currVal_6 = false; var currVal_7 = false; var currVal_8 = true; var currVal_9 = false; var currVal_10 = _co.columns; var currVal_11 = _co.cbpStatuses; var currVal_12 = _co.defaultSortColumn; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
export function View_CbpStatusTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-cbp-status-table", [], null, null, null, View_CbpStatusTableComponent_0, RenderType_CbpStatusTableComponent)), i1.ɵdid(1, 245760, null, 0, i7.CbpStatusTableComponent, [i8.MatDialog, i1.ChangeDetectorRef, i9.OmsAlertsService, i10.OmsDialogsService, i11.CbpStatusService, i12.CbpStatusDefaultColumnsService, i13.FileUploadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbpStatusTableComponentNgFactory = i1.ɵccf("oms-cbp-status-table", i7.CbpStatusTableComponent, View_CbpStatusTableComponent_Host_0, { items: "items", objectType: "objectType", objectId: "objectId", uscsFsnStatus: "uscsFsnStatus" }, {}, []);
export { CbpStatusTableComponentNgFactory as CbpStatusTableComponentNgFactory };
