var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgSelectSearchParams } from "../../../../../../settings/util/ng-select-search-params";
import { CarrierService } from "../../../../../services/carrier.service";
import { BaseInputComponent } from "../../../../base/base-input/base-input.component";
import { NgModel } from "@angular/forms";
import { CarrierDialogComponent } from "../../../../../../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import { MatDialog } from "@angular/material/dialog";
var CarrierSearchComponent = /** @class */ (function (_super) {
    __extends(CarrierSearchComponent, _super);
    function CarrierSearchComponent(validators, asyncValidators, carrierService, dialog) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.carrierService = carrierService;
        _this.dialog = dialog;
        _this.canCreateNew = false;
        _this.label = 'Carrier';
        return _this;
    }
    CarrierSearchComponent.prototype.ngOnInit = function () {
        this.carrierSearch = new NgSelectSearchParams(this.carrierService);
    };
    CarrierSearchComponent.prototype.openCreateCarrier = function () {
        var _this = this;
        var dialogRef = this.dialog.open(CarrierDialogComponent, {
            width: 'auto'
        });
        dialogRef.componentInstance.carrier = null;
        dialogRef.componentInstance.displayInHouse = false;
        return dialogRef.afterClosed()
            .subscribe(function (response) {
            _this.value = response.res;
        });
    };
    return CarrierSearchComponent;
}(BaseInputComponent));
export { CarrierSearchComponent };
