var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { BaseEntityService } from "../../../../../services/base/base-entity.service";
import { RestService } from "../../../../../services/rest.service";
import { HttpUtilsService } from "../../../../../services/http-utils.service";
import { plainToClass } from "class-transformer";
import { DispatchAndRecoveryLog } from "../../../models/dispatch.and.recovery.log";
var DispatchAndRecoveryService = /** @class */ (function (_super) {
    __extends(DispatchAndRecoveryService, _super);
    function DispatchAndRecoveryService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/log/dispatch-and-recovery/';
        _this.classType = DispatchAndRecoveryLog;
        _this.dispatchAndRecoveryLogSubject = new Subject();
        return _this;
    }
    DispatchAndRecoveryService.prototype.findAllByMasterId = function (id) {
        var _this = this;
        this.httpRestService.get(this.apiUrl + 'find-all-by-master-id', id).subscribe(function (dispatchAndRecoveryLog) {
            _this.dispatchAndRecoveryLogSubject.next(plainToClass(DispatchAndRecoveryLog, dispatchAndRecoveryLog));
        });
    };
    DispatchAndRecoveryService.prototype.findAllByShipmentId = function (id) {
        var _this = this;
        this.httpRestService.get(this.apiUrl + 'find-all-by-shipment-id', id).subscribe(function (dispatchAndRecoveryLog) {
            _this.dispatchAndRecoveryLogSubject.next(plainToClass(DispatchAndRecoveryLog, dispatchAndRecoveryLog));
        });
    };
    DispatchAndRecoveryService.prototype.findAllByOrderId = function (orderId, shipmentId) {
        var _this = this;
        this.httpRestService.getWithOptions(this.apiUrl + 'find-all-by-order-id/' + orderId, null, { shipmentId: shipmentId })
            .subscribe(function (dispatchAndRecoveryLog) {
            _this.dispatchAndRecoveryLogSubject.next(plainToClass(DispatchAndRecoveryLog, dispatchAndRecoveryLog));
        });
    };
    return DispatchAndRecoveryService;
}(BaseEntityService));
export { DispatchAndRecoveryService };
