import {UploadedFiles} from './uploaded.files';
import {Type} from 'class-transformer';
import {DocumentType} from './document-type';
import {BaseDeletable} from "../base-deletable";

export class ObjectDocuments extends BaseDeletable {
  @Type(() => DocumentType)
  documentType: DocumentType;
  documentRef: string;
  readonly fileName: string;
  objectId: number;
  @Type(() => UploadedFiles)
  uploadedFiles: UploadedFiles;
  objectType: string;

  public isPOD(): boolean {
    return this.documentType && this.documentType.isPOD;
  }

}
