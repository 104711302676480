import {ChangeDetectorRef, Component, OnInit} from '@angular/core';

import {OrdersService} from '../../services';
import {OmsAlertsService} from "../../modules/shared/components/oms-alerts/oms-alerts.service";
import {FileUploadService} from "../../services/file.upload.service";
import {OmsDialogsService} from "../../components/common/oms-dialogs";
import {OmsConstants} from "../../common/oms-constants.service";
import {MatDialog} from "@angular/material/dialog";
import {AbstractEntityListComponent} from "../../modules/settings/pages/abstract-entity-list.component";
import {Master, MasterStatus, MasterStatusId, Order} from "../../modules/shared/models";
import {
  BaseColumn,
  FieldType,
  HyperLinkColumn
} from "../../modules/shared/components/base/data-table/columns/column-types";
import {UserService} from "../../modules/shared/services/user.service";
import {ColumnType} from "../../common/column-type";
import {PageResult} from "../../modules/shared/models/query-models/page-result";
import {plainToClass} from "class-transformer";
import {convertMawbNumber} from "../../modules/shared/services/oms-converters.service";
import {ActivatedRoute} from "@angular/router";
import {FreightForwarderGroupService} from "../../services/freight.forwarder.group.service";
import {PublicApiService} from "../../services/public-api.service";
import {NgxSpinnerService} from "ngx-spinner";
import {PagingOptions} from "../../modules/shared/components/base/data-table/data-table.utils";
import {PopoverConfig} from "ngx-bootstrap";
import {
  OrderSubscriptionDialogComponent,
  OrderSubscriptionDialogInput
} from "../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import {forkJoin, Observable} from "rxjs";
import {map, tap} from "rxjs/operators";

@Component({
  templateUrl: 'status-info.component.html',
  styleUrls: ['./status-info.component.scss']
})

export class StatusInfoComponent extends AbstractEntityListComponent<Order> implements OnInit {

//  private orderId: number;

  columns: BaseColumn[];
  public orders: Order[] = [];
  public searchHAWB: string = '';
  public searchRef3: string = '';
  public searchMAWB: string = '';
  public found: Master[] = null;
  public search_active: Master;
  public isEnterClick: boolean = false;
  public loading: boolean = true;
  private ffg; // Freight Forwarder Group Identifier
  public paging: PagingOptions = {enabled: false, pageSize: 1000, pageSizes: [10, 20, 50, 100, 200, 500]};
  public docTypes = ['POD', 'JJS_ON_HAND', 'CHECKED_MANIFEST'];


  convertMawbNumber = convertMawbNumber;

  constructor(
    public popoverConfig: PopoverConfig,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    protected alerts: OmsAlertsService,
    private  publicApi: PublicApiService,
    private ordersService: OrdersService,
    private userService: UserService,
    protected dialogs: OmsDialogsService,
    protected fileUploadService: FileUploadService,
    private ffgService: FreightForwarderGroupService,
    private constants: OmsConstants,
    public spinner: NgxSpinnerService,
  ) {
    super(cdr, alerts, ordersService, dialogs, fileUploadService, spinner);
//    this.popoverConfig.outsideClick = true;
    this.popoverConfig.container = 'body';
    this.popoverConfig.adaptivePosition = false;
    this.popoverConfig.triggers = 'hover';

  }

  ngOnInit() {
    this.columns = this.buildColumns();
    this.route.params.subscribe((params) => {
      this.ffg = params['id'];
      let id = atob(this.ffg).split('#')[1];
      if (id) {
        this.findOrdersById();
      }
    });
  }

  public getSearchText(text: string): string {
    switch (text) {
      case 'HAWB': return this.searchHAWB;
      case 'MAWB': return this.searchHAWB;
      case 'REF3': return this.searchRef3;
    }
  }

  searchChanged(text: string, isClear: boolean) {
    if (!this.isEnterClick) {
      this.refresh(text, isClear);
    }
  }

  private prepareOrders(orders: Order[]) {
    this.orders = [];
    const orders$: Observable<number>[] = [];

    orders.forEach((order) => {

      order.documents = 0;

      let a = this.fileUploadService.findAllByIdObs(order.id, 'ORDER', true, this.docTypes, this.ffg, true)
        .pipe(map((d) => d.length));

      orders$.push(a.pipe(tap((i) => {
        order.documents = i;
      })));


      orders$.push(this.fileUploadService.getActiveMasterDocCountByOrderId(order.id, "CHECKED_MANIFEST")
        .pipe(tap((i) => {
          order.manifestDocuments = i;
        })));
    });

    forkJoin(orders$).subscribe(() => {
      this.orders = orders;
      this.changed();
    });
  }

  refresh(text: string, isClear: boolean) {
    if (text === 'HAWB' &&  this.searchHAWB.trim().length > 4) {
      this.findOrdersByHawb();
    } else if (text === 'REF3' &&  this.searchRef3.trim().length > 4) {
      this.findOrdersByRef3();
    } else if (text === 'MAWB' &&  this.searchMAWB.trim().length === 11) {
      this.findMastersByMawb();
    } else if (!isClear) {
      if (text === 'HAWB' || text === 'REF3') {
        this.dialogs.openInfoDialog('Value must be at least 5 characters').then();
      } else {
        this.dialogs.openInfoDialog('Value must be at least 11 digits').then();
      }
      this.orders = null;
      this.changed();
    }
  }


  findOrdersById() {
    this.orders = [];
    this.publicApi.orderInfoById(this.ffg).then((orders) => {
      if (orders && orders.length > 0) {
         this.prepareOrders(orders);
      } else {
        this.dialogs.openInfoDialog('Order not found').then();
      }
    }).catch(() => {
      this.dialogs.openInfoDialog('Order not found').then();
    });
    this.resetSearch();
    this.changed();
  }


  findOrdersByRef3() {
    this.orders = [];
    let ref3 = this.searchRef3.trim();
    this.publicApi.orderInfoByRef3(ref3, this.ffg).then((orders) => {
      if (orders && orders.length > 0) {
        this.prepareOrders(orders);
      } else {
        this.dialogs.openInfoDialog('Order with REF3 ' + ref3 + ' not found').then();
      }
    }).catch(() => {
      this.dialogs.openInfoDialog('Order with REF3  ' + ref3 + ' not found').then();
    });
    this.resetSearch();
    this.changed();
  }

  findOrdersByHawb() {
    this.orders = [];
    this.spinner.show().then();
    let hAWB = this.searchHAWB.trim();
    this.publicApi.orderInfoByHawb(hAWB, this.ffg)
      .then((orders) => {
        this.spinner.hide().then();
        if (orders && orders.length > 0) {
          this.prepareOrders(orders);
        } else {
          this.dialogs.openInfoDialog('Order with REF/HAWB/PO ' + hAWB + ' not found').then();
        }
      })
      .catch(() => {
        this.spinner.hide().then();
        this.dialogs.openInfoDialog('Order with REF/HAWB/PO  ' + hAWB + ' not found').then();
      });
    this.resetSearch();
    this.changed();
  }

  findMastersByMawb() {
    let mAWB = this.searchMAWB.trim();
    this.spinner.show().then();
    this.publicApi.searchMastersByMawb(mAWB, this.ffg).toPromise()
      .then((masters: PageResult<Master>) => {
        this.spinner.hide().then();
        if (masters.numberOfElements > 1) {
          this.selectMaster(plainToClass(Master, <Object[]>masters.content));
        } else if (masters.numberOfElements === 1) {
          this.findOrdersByMaster(masters.content[0]);
        } else {
          this.orders = null;
          this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
          this.resetSearch();
        }
      })
      .catch(() => {
        this.spinner.hide().then();

        this.orders = null;
        this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
        this.resetSearch();
      });
    this.changed();
  }

  findOrdersByMaster(master: Master) {
    let mAWB = this.searchMAWB.trim();
    this.spinner.show().then();
    this.orders = [];

    this.publicApi.orderInfoByMaster(master.id, this.ffg)
      .then((orders) => {
        this.spinner.hide().then();
        if (orders && orders.length > 0) {
          this.prepareOrders(orders);
          this.changed();

        } else {
          this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
        }
      })
      .catch(() => {
        this.spinner.hide().then();
        this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
      });
    this.resetSearch();
  }

  /*
  setMaster(master: Master) {
    this.findOrdersByMaster(master);
    this.changed();
  }
   */

  getStatus(): MasterStatus[] {
    return MasterStatus.items.filter(ms =>  ms.id === MasterStatusId.ONHAND_COMPLETE_PENDING_1C ||  ms.id === MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH);
  }

  changed() {
    setTimeout(() => this.cdr.markForCheck());
    setTimeout(() => this.isEnterClick = false);
  }

  selectMaster(masters: Master[]) {
    this.found = [...masters];
    this.changed();
  }

  public resetSearch() {
    this.searchHAWB = '';
    this.searchMAWB = '';
    this.found = null;
    this.search_active = null;
  }

  public onSearchKey(event, text: string) {
    if (event && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
      this.isEnterClick = true;
      this.refresh(text, false);
    }
  }

  private subscribeEvents(order) {
    const dialogRef = this.dialog.open<OrderSubscriptionDialogComponent, OrderSubscriptionDialogInput, MasterStatus[]>(OrderSubscriptionDialogComponent,
      {width: 'auto', data: {orders: [order], isPublic: true, needCustomEmail: true}});
    dialogRef.afterClosed().subscribe(() => { });
  }


  private buildColumns(): BaseColumn[] {
    let columns: BaseColumn[] = [];
    let tooltip = "Pending recovery\n" +
      "Recovered, pending on hand\n" +
      "On hand, pending customs\n" +
      "On hand, ready for dispatch (released)\n" +
      "Out for Delivery\n" +
      "Delivered";

    columns = columns.concat([
      ColumnType.ORDER_MAWB(false)
        .setEditor(false).setAlign('start').setWidth('150px'),
        ColumnType.ORDER_HAWB(false)
        .setEditor(false).setAlign('start').setWidth('150px'),
      ColumnType.ORDER_PCS(this.constants.piecesMask, this.constants.piecesPlaceholder)
        .setEditor(false).setAlign('end').setWidth('60px'),
      ColumnType.EMPTY_COLUMN
        .setEditor(false).setSearch(false).setSortable(false).setAlign('end'),
      ColumnType.ORDER_WEIGHT_KG
        .setEditor(false).setSearch(false).setAlign('end').setWidth('80px'),
      ColumnType.EMPTY_COLUMN
        .setEditor(false).setSearch(false).setSortable(false).setAlign('end'),
      ColumnType.ORDER_CFS_LAST_FREE_DAY(this.getStatus())
        .setEditor(false).setSearch(false).setAlign('center').setWidth('150px'),
      ColumnType.LAST_UPDATED_DATE_TIME
        .setEditor(false).setSortable(false).setSearch(false).setWidth('150px').setAlign('start'),
      ColumnType.PUBLIC_ORDER_STATUS([], tooltip)
        .setEditor(false).setSortable(false).setSearch(false).setWidth('auto').setAlign('start'),
      new HyperLinkColumn('SUBSCRIBE', '', '', FieldType.TEXT, '120px',
        {
          converter: () => '',
          tooltip: () => 'Subscribe...',
          header: {noarrows: true, icon: 'fa fa-flag', tooltip: 'Subscribe...'},
          icon: () => 'fa fa-flag',
          onClick: (row) => this.subscribeEvents(row)},
      ).setWidth('120px').setAlign('start'),
/*      ColumnType.ORDER_LAST_DOC('jjsOnHandDocuments', this.fileUploadService, 'JJS On Hand', 'JJS_ON_HAND')
        .setWidth('100px').setAlign('center'),
      ColumnType.ORDER_LAST_DOC('podDocuments', this.fileUploadService, 'POD', 'POD')
        .setWidth('100px').setAlign('center'),
      ColumnType.MASTER_LAST_DOC('manifestDocuments', this.fileUploadService, "Manifest", 'CHECKED_MANIFEST')
        .setWidth('100px').setAlign('center'), */
      ColumnType.ORDER_DOC_CENTER(true, this.ordersService, this.dialogs,
        (config) => {
          config.docTypes = this.docTypes;
          config.readonly = true;
          config.ffg = this.ffg;
          config.latestActive = true;
      })
    ]);
    return columns;
  }
}
