<!--<div cdkDrag
     cdkDragLockAxis="x"
     [cdkDragFreeDragPosition]="dragPosition"
     (cdkDragEnded)="dragEnded($event)"
     class="gritter-item-wrapper">-->
<bs-alert
  [type]="'local'"
  [dismissible]="false">
  <button (click)="onMessageClosed()"
          [tooltip]="'delete'"
          class="close" type="button">
    <span aria-hidden="true">×</span>
    <span class="sr-only">Close</span>
  </button>
  <div class="order-notification-alert">
    <div class="order-notification-alert__title">
      <div [tooltip]="'move to the bottom of the list'" (click)="onMoveToEnd()" class="order-notification-alert__move-down">
        <i class="fa fa-chevron-down"></i>
      </div>
      <a class="order-notification-alert__link" [routerLink]="['/home/order', {id:data.order.id}]"
         target="_blank">{{orderLink}}</a>&nbsp;
      <div class="order-notification-alert__editable-text">
        <a *ngIf="!isEdit"
           class="order-notification-alert__editable-second-line editable editable-click"
           (click)="isEdit=true">{{secondLine}}</a>

        <input *ngIf="isEdit" type="text" class="form-control" (blur)="onBlurEditor()" title=""
               data-toggle="dropdown" aria-expanded="true"
               [attr.readonly]=""
               [placeholder]="'comment'"
               [maxlength]="20"
               [(ngModel)]="comment" (keydown)="onKeyDown($event)">
      </div>
    </div>
    <div class="order-notification-alert__status">{{cbpStatus ? cbpStatus : thirdLine}}</div>
    <div class="order-notification-alert__description">{{firstLine}}</div>
  </div>
</bs-alert>
<!--</div>-->
