import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {convertLocalDateToEST, ensureDate} from '../../../../../../common/oms-date-time.utils';
import {isNullOrUndefined} from 'util';


export interface IDatetimePopupButtonOptions {
  show: boolean;
  label: string;
  cssClass: string;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'oms-date-time-range-popup',
  templateUrl: './date-time-range-popup.component.html',
  styleUrls: ['date-time-range-popup.component.scss']
})

export class DateTimeRangePopupComponent implements OnChanges {
  @Input() public left: number;
  @Input() public top: number;
  @Input() public bottom: number;
  @Input() public right: number;
  @Output('onCustomButton') onCustomButton: EventEmitter<any> = new EventEmitter<any>();

  @Input() set position(value: { left: number, top: number, right?: number, bottom?: number }) {
    this.left = value.left;
    this.top = value.top;
    this.bottom = value.bottom;
    this.right = value.right;
  }

  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('btnClose') btnClose: ElementRef;
  @ViewChild('dropdown') public dropdown: BsDropdownDirective;

  @Input() public valueFromDate: Date | string;
  @Input() public valueToDate: Date | string;
  @Output() public valueChange = new EventEmitter();

  @Input() public appendTo: string = 'body';
  @Input() public showPopup = false;
  @Output() public showPopupChange = new EventEmitter();
  @Input() public showDate = true;
  @Input() public showWeeks = false;
  @Input() public datepickerMode = 'day';
  @Input() public minDate: Date = null;
  @Input() public maxDate: Date = null;
  @Input() public dateDisabled: any[] = [];
  @Input() public nowButton: IDatetimePopupButtonOptions;
  @Input() public estButton: IDatetimePopupButtonOptions;
  @Input() public clearButton: IDatetimePopupButtonOptions;
  @Input() public closeButton: IDatetimePopupButtonOptions;
  @Input() public customButton: IDatetimePopupButtonOptions;

  public fromDate: Date = null;
  public toDate: Date = null;
  public isOpening: boolean = false;
  public isDropUp: boolean = false;
  @Input() public est: boolean = false;
  popupStyles = null;

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.nowButton) {
      this.nowButton = {show: true, label: 'Now', cssClass: 'btn btn-secondary btn-sm'};
    }
    if (!this.clearButton) {
      this.clearButton = {show: true, label: 'Clear', cssClass: 'btn btn-secondary btn-sm'};
    }
    if (!this.closeButton) {
      this.closeButton = {show: true, label: 'Close', cssClass: 'btn btn-secondary btn-sm'};
    }

    // user maybe typing a value into an input box, so would come in as string
    this.fromDate = ensureDate(this.valueFromDate) || new Date();
    this.toDate = this.toDate || ensureDate(this.valueToDate);

    if (changes.left || changes.right || changes.top || changes.bottom || changes.position) {
      this.updatePopupStyles();
    }

    // toggle if open
    if (changes.showPopup) {
      this.showPopup = changes.showPopup.currentValue === true;
      if (this.showPopup) {
        setTimeout(() => {
          this.focus();
        }, 100);
      }
    }
  }

  public onOpenChange() {
    this.isOpening = true;
    setTimeout(() => this.isOpening = false, 250);
  }

  public onClear() {
    this.onPickerFromDateChange(null, false);
    this.onPickerToDateChange(null, false);
    this.tryClosePopup();
  }

  public onCustomButtonClick(event) {
    this.onClose(false);
    this.onCustomButton.emit(event);
  }


  public onClose(apply: boolean) {
    if (this.showPopup) {
      this.showPopup = false;
      this.showPopupChange.emit(false);

      if (apply) {
        let valueFromTo = {from: convertLocalDateToEST(this.fromDate), to: convertLocalDateToEST(this.toDate), est: this.est};
        this.valueChange.emit(valueFromTo);
      }
    }
  }


  public onPickerFromDateChange(data: Date, isClose: boolean = true) {
    this.fromDate = data;
    if (isClose) {
      this.tryClosePopup();
    }
  }

  public onPickerToDateChange(data: Date, isClose: boolean = true) {
    this.toDate = data;
    if (isClose) {
      this.tryClosePopup();
    }
  }

  private tryClosePopup() {
    if (!this.isOpening) {
      if (this.showDate === true && !isNullOrUndefined(this.fromDate) && !isNullOrUndefined(this.toDate)) {
        this.onClose(true);
      }
    }
  }

  private focus() {
    if (this.btnClose) {
      this.btnClose.nativeElement.focus();
    }
  }

/*
  get maxDateInESTFunc() {
    return maxETCDateFromNow();
  }
 */

  private updatePopupStyles(): void {
    let styles = {};
    styles['top'] = isNullOrUndefined(this.top) ? 'auto' : this.top + 'px';
    let windowWidth = window.innerWidth;
    if (this.left + 600 > windowWidth) {
      styles['left'] = windowWidth - (this.left + 600) + 'px'; // extra right
    } else if (this.left < 233) {
      styles['left'] = -this.left + 'px'; // extra left
    } else {
      styles['left'] = '-233px';
    }
    styles['right'] = isNullOrUndefined(this.right) ? 'auto' : this.right + 'px';
    styles['bottom'] = isNullOrUndefined(this.bottom) ? 'auto' : this.bottom + 'px';
    this.popupStyles = styles;
  }

}
