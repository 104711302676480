import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Manifest} from "../../../shared/models/manifest/manifest";
import {DispatchService} from "../../../shared/services/dispatch/dispatch.service";
import {messageBroadcast} from "../../../../_helpers/utils";

@Component({
  selector: 'oms-manifest-create-page',
  templateUrl: './manifest-create-page.component.html',
  styleUrls: ['./manifest-create-page.component.scss']
})
export class ManifestCreatePageComponent implements OnInit {

  public manifest: Manifest;
  public loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private dispatchService: DispatchService) {
        route.queryParams.subscribe((params) => {
          let manifestId: number = Number(params['id']);
          if (manifestId && (!this.manifest || this.manifest.id !== manifestId)) {
            this.loadManifest(manifestId);
          }
        });
  }

  public loadManifest(id: number) {
    this.loading = true;
    this.dispatchService.get(id).subscribe(
      (manifest) => {
        this.loading = false;
        this.manifest = manifest;
      },
      (error) => {
        this.loading = false;
      });
  }

  public onManifestCreated(manifest) {
    console.log('Created!!!');
    messageBroadcast( {command: 'manifest-created', id: this.manifest.id, routeExternalId: this.manifest.externalId});
  }

  public onManifestDeleted(manifestId: number) {
    console.log('Deleted!!!', manifestId);
    messageBroadcast( {command: 'manifest-deleted', id: manifestId, routeExternalId: this.manifest.externalId});
    window.close();
  }


  ngOnInit() {
  }

}
