import {BaseEntity} from "../base-entity";
import {RowObject, TrackByObject} from "../../components/base/data-table/columns/column-types";
import {Comparable} from "../../../../common/oms-types";
import {Transform, Type} from "class-transformer";
import {Order} from "../order";
import {User} from "../user";
import {MasterStatus, MasterStatusId, transformMasterStatus} from "..";
import {CbpStatus} from "../cbp.status";

export class OrderNotification extends BaseEntity implements RowObject, Comparable<OrderNotification>, TrackByObject {
  public static readonly ROW_ID = 'OrderNotification';
  rowId = OrderNotification.ROW_ID;

  id: number;
  @Type(() => Order)
  order: Order;
  @Type(() => User)
  user: User;
  @Transform((v, o, tt) => transformMasterStatus(v, o, tt))
  status: MasterStatus;
  @Type(() => Date)
  dateCreated: Date;
  comment: string;
  orderNumber: number;

  cbpStatus?: CbpStatus;

  trackBy(): any {
    return this.id;
  }

}
