import {Component, Input, OnInit} from '@angular/core';
import {FilterPageRequest} from "../../../../../modules/shared/models/filter.page.request";
import {Customer, FreightForwarder, LoadTypes} from "../../../../../modules/shared/models";
import {CustomerService} from "../../../../../services/customer.service";
import {FreightForwarderService} from "../../../../../services/freight.forwarder.service";
import {FreightForwarderGroup} from "../../../../../modules/shared/models/freight.forwarder.group";
import {FreightForwarderGroupService} from "../../../../../services/freight.forwarder.group.service";
import {NgSelectSearchParams} from "../../../../../modules/settings/util/ng-select-search-params";
import {OrderModes} from "../../../../../modules/shared/models/order/order-mode";
import {OrderModeItem} from "../../../../../modules/shared/models/order/order-mode-item";


@Component({
  selector: 'oms-routes-report-filters',
  templateUrl: './routes-report-filters.component.html',
  styleUrls: ['./routes-report-filters.component.scss']
})
export class RoutesReportFiltersComponent implements OnInit {

  @Input() public filters: FilterPageRequest;

  public customer: Customer[];
  public freightForwarder: FreightForwarder[] = [];
  public orderModes: OrderModeItem[] = [];
  public loadTypes = [];
  public freightForwarderGroup: FreightForwarderGroup[] = [];
  public deliveryLocation: string;
  public allOrderModes = OrderModes.filterItems;
  public allLoadTypes =  LoadTypes.filterItems;

  public filterYes = {id: 1, label: 'Yes'};
  public filterNo = {id: 0, label: 'No'};



  public ffgSearchParams: NgSelectSearchParams<FreightForwarder>;

  constructor(
    public freightForwarderGroupService: FreightForwarderGroupService,
    public customerService: CustomerService,
    public freightForwarderService: FreightForwarderService) {

    this.ffgSearchParams = new NgSelectSearchParams<FreightForwarder>(freightForwarderService, 'name');

  }

  ngOnInit() {
    console.log('filters', this.filters);

    if (this.filters) {
      for (let filter of this.filters.filterByColumns) {

        if (filter.field === 'customerId') {
          this.customerService.getMany(filter.values.map(s => Number(s))).subscribe(
              (customer) => this.customer = customer
          );
        }

        if (filter.field === 'freightForwarderId') {
          if (filter.multipleValues) {
            this.freightForwarderService.getMany(filter.values.map(s => Number(s))).subscribe(
                (ff) => this.freightForwarder = ff
            );
          }
        }

        if (filter.field === 'freightForwarderGroupId') {
          this.freightForwarderGroupService.getMany(filter.values.map(s => Number(s))).subscribe(
              (ffg) => this.freightForwarderGroup = ffg
          );
        }

        if (filter.field === 'addressDeliveryName') {
          this.deliveryLocation = filter.value;
        }

        if (filter.field === 'orderMode') {
          this.orderModes = filter.values.map( (s) => OrderModes.filterItems.find((item) => ('' + item.id) === s));
        }

        if (filter.field === 'loadType') {
          this.loadTypes = filter.values.map( (s) => LoadTypes.filterItems.find((item) => ('' + item.id) === s));
        }
      }
    }
  }

  onCustomerChange() {
    if (this.customer && this.customer.length) {
      this.filters.addColumnFilter('customerId', this.customer.map((c) => c.id));
    } else {
      this.filters.removeColumnFilter('customerId');
    }
  }

  onFreightForwarderChange() {
    if (this.freightForwarder && this.freightForwarder.length) {
      this.filters.addColumnFilter('freightForwarderId', this.freightForwarder.map((ff) => ff.id));
    } else {
      this.filters.removeColumnFilter('freightForwarderId');
    }
    console.log('>>>', this.filters);
  }

  public onFreightForwarderGroupChange() {
    if (this.freightForwarderGroup && this.freightForwarderGroup.length) {
      this.filters.addColumnFilter('freightForwarderGroupId', this.freightForwarderGroup.map((ffg) => ffg.id));
    } else {
      this.filters.removeColumnFilter('freightForwarderGroupId');
    }
  }

  public onOrderModeChange() {
    if (this.orderModes && this.orderModes.length) {
//      console.log('SELECTED', this.orderModes);
      this.filters.addColumnFilter('orderMode', this.orderModes.map((mode) => '' + mode.id || ''));
    } else {
      this.filters.removeColumnFilter('orderMode');
    }
  }

  public onLoadTypeChange() {
    if (this.loadTypes && this.loadTypes.length) {
//      console.log('SELECTED', this.loadTypes);
      this.filters.addColumnFilter('loadType', this.loadTypes.map((type) => '' + type.id || ''));
    } else {
      this.filters.removeColumnFilter('loadType');
    }
  }


  get noRecovery(): boolean {
    return this.filters.hasNamedCondition("NO_RECOVERY");
  }

  set noRecovery(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("NO_RECOVERY");
    } else {
      this.filters.removeNamedCondition("NO_RECOVERY");
    }
  }

  get activeOnly(): boolean {
    return this.filters.hasNamedCondition("ACTIVE_ONLY");
  }

  set activeOnly(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("ACTIVE_ONLY");
    } else {
      this.filters.removeNamedCondition("ACTIVE_ONLY");
    }
  }

  get noArchived60(): boolean {
    return this.filters.hasNamedCondition("NO_ARCHIVED_OVER_60");
  }

  set noArchived60(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("NO_ARCHIVED_OVER_60");
    } else {
      this.filters.removeNamedCondition("NO_ARCHIVED_OVER_60");
    }
  }

  get updatedSincePrevious(): boolean {
    return this.filters.hasNamedCondition("UPDATED_SINCE_PREVIOUS");
  }

  set updatedSincePrevious(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("UPDATED_SINCE_PREVIOUS");
    } else {
      this.filters.removeNamedCondition("UPDATED_SINCE_PREVIOUS");
    }
  }

  get updatedYtd(): boolean {
    return this.filters.hasNamedCondition("UPDATED_YTD");
  }

  set updatedYtd(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("UPDATED_YTD");
    } else {
      this.filters.removeNamedCondition("UPDATED_YTD");
    }
  }


  get deliveredSincePrevious(): boolean {
    return this.filters.hasNamedCondition("DELIVERED_SINCE_PREVIOUS");
  }

  set deliveredSincePrevious(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("DELIVERED_SINCE_PREVIOUS");
    } else {
      this.filters.removeNamedCondition("DELIVERED_SINCE_PREVIOUS");
    }
  }


  get deliveredYtd(): boolean {
    return this.filters.hasNamedCondition("DELIVERED_YTD");
  }

  set deliveredYtd(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("DELIVERED_YTD");
    } else {
      this.filters.removeNamedCondition("DELIVERED_YTD");
    }
  }


  get billed() {
    if (this.filters.hasNamedCondition('BILLED')) {
      return this.filterYes;
    } else if (this.filters.hasNamedCondition('NOT_BILLED')) {
      return this.filterNo;
    }
  }

  set billed(value) {
    this.filters.removeNamedConditions('NOT_BILLED', 'BILLED');
    switch (value && value.id) {
      case 0:
        this.filters.addNamedCondition('NOT_BILLED');
        break;
      case 1:
        this.filters.addNamedCondition('BILLED');
        break;
    }
  }

  onDeliveryLocationChange(event) {
    this.deliveryLocation = event ? event : undefined;
    if (this.deliveryLocation) {
      this.filters.addColumnFilter('addressDeliveryName', this.deliveryLocation);
    } else {
      this.filters.removeColumnFilter('addressDeliveryName');
    }
  }


/*  public searchFFGroup(searchText: string): Observable<PageResult<FreightForwarderGroup>> {
    let request = new FilterPageRequest(1, 9999, searchText, new SearchSortRequest('name', true), []);
    return this.freightForwarderGroupService.findByFilters(request, true).pipe(tap((response) => {
      response.content.forEach(group => driver['label'] = group.name);
    }));
  } */

/*
  public selected: string[] = ['aaa', 'bbb'];
  public selected$: Subject<string[]> = new BehaviorSubject<string[]>(this.selected);
  public onKeyPress(event) {
    if (event.key === 'Enter') {
      this.selected.push(event.target.value);
      this.selected$.next(this.selected)
      console.log(this.selected);
    }
  } */

}
