<oms-custom-search [label]="label"
                   [(ngModel)]="value"
                   [disabled]="disabled"
                   [items]="carrierSearch.items$ | async"
                   [loading]="carrierSearch.loading"
                   [typeahead]="carrierSearch.input$"
                   bindLabel="name"
                   [allowCreate]="canCreateNew"
                   (create)="openCreateCarrier()">
</oms-custom-search>
