<div class="widget" [ngClass]="{'widget-flex': flex}">
  <div class="widget-header" *ngIf="showHeader" (click)="toggleCollapsed()"
       [ngClass]="{'widget-header-left': headerLeft}"
       [attr.aria-controls]="'collapse'" [style.cursor]="collapsible ? 'pointer' : 'inherit'">

    <span *ngIf="showLabel">
      <h3>
        <i class="fa" [ngClass]="collapsed ? 'fa-plus-square-o':'fa-minus-square'" *ngIf="collapsible"></i>
        <i class="{{iconClass}}" *ngIf="iconClass"></i>
        {{label}}
      </h3>
      <em>{{description}}</em>
    </span>

    <ng-content select="[widget-header]"></ng-content>

    <div class="btn-group widget-header-toolbar if-not-empty" (click)="$event.stopPropagation()" *ngIf="showToolbar">
      <ng-content select="[widget-toolbar]"></ng-content>
      <!--a href="#" title="Focus" class="btn-borderless btn-focus"><i class="fa fa-eye"></i></a>
      <a title="Expand/Collapse" class="btn-borderless btn-toggle-expand"><i class="fa fa-chevron-up"></i></a>
      <a href="#" title="Remove" class="btn-borderless btn-remove"><i class="fa fa-times"></i></a-->
    </div>
  </div>

  <div id="collapse" [collapse]="collapsible && collapsed" [attr.aria-expanded]="!collapsed">
    <div class="widget-content" [class]="contentClass" [ngClass]="{'no-padding':noPadding}">
      <ng-content></ng-content>
    </div>

  </div>


  <div class="widget-footer if-not-empty" *ngIf="showFooter">
    <ng-content select="[widget-footer]"></ng-content>
  </div>

</div>

