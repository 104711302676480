import { Input, OnDestroy } from "@angular/core";
import { CustomColumn } from "../../columns/column-types";
import { Subject } from "rxjs";
import { DataTableComponent } from "../../data-table.component";

export abstract class GenericTableCell<T, D = any> implements OnDestroy {

  @Input() public row: T;
  @Input() public data?: D;
  @Input() public column: CustomColumn<T>;
  @Input() public table: DataTableComponent;
  @Input() public search: string;

  protected destroy$ = new Subject<void>();

  public updateObject() {
  }

  public getRowObject(row: T) {
    return this.column.rowObject(row);
  }

  public getTooltip(rowObject) {
    return this.column.getTooltip(rowObject);
  }

  public getIconClass(rowObject, rawValue) {
    return this.column.getIconClass(rowObject, rawValue);
  }

  public getClass(rowObject, rawValue) {
    return this.column.getClass(rowObject, rawValue) || '';
  }

  ngOnDestroy(): void {
    delete this.column;
    delete this.table;
    this.destroy$.next();
    this.destroy$.complete();
  }

}
