import {Address} from './address/address';
import {BaseDeletable} from './base-deletable';
import {Type} from "class-transformer";
import {FreightForwarderGroup} from "./freight.forwarder.group";
import {Labeled} from "../../../common/oms-types";
import {FreightForwarderEmail} from "./freight.forwarder-email";

export class FreightForwarder extends BaseDeletable implements Labeled {
  name: string;
  address: Address;
  @Type(() => FreightForwarderGroup)
  freightForwarderGroup: FreightForwarderGroup;
  @Type(() => FreightForwarderEmail)
  emails: FreightForwarderEmail[] = [];
  sendPODToEmails: boolean;

  get label() {
    return this.name;
  }
}
