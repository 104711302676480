/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./weight-input.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../string-input/string-input.component.ngfactory";
import * as i3 from "../string-input/string-input.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../select-button/select-button.component.ngfactory";
import * as i7 from "../../select-button/select-button.component";
import * as i8 from "./weight-input.component";
import * as i9 from "../../../../../../common/oms-constants.service";
var styles_WeightInputComponent = [i0.styles];
var RenderType_WeightInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeightInputComponent, data: {} });
export { RenderType_WeightInputComponent as RenderType_WeightInputComponent };
export function View_WeightInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "oms-string-input", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.internal = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StringInputComponent_0, i2.RenderType_StringInputComponent)), i1.ɵdid(1, 49152, null, 0, i3.StringInputComponent, [[2, i4.NG_VALIDATORS], [2, i4.NG_ASYNC_VALIDATORS], i5.MatDialog], { label: [0, "label"], disabled: [1, "disabled"], placeholder: [2, "placeholder"], size: [3, "size"], mask: [4, "mask"], isNumber: [5, "isNumber"], readonly: [6, "readonly"], showLabel: [7, "showLabel"], invalidValue: [8, "invalidValue"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.StringInputComponent]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(6, 0, null, 1, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, 2, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(10, 0, null, 3, 2, "span", [["class", "input-group-btn"], ["input-after", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "oms-select-button", [], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = ((_co.units = $event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedChange" === en)) {
        var pd_1 = (_co.onUnitsChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_SelectButtonComponent_0, i6.RenderType_SelectButtonComponent)), i1.ɵdid(12, 49152, null, 0, i7.SelectButtonComponent, [], { selected: [0, "selected"], items: [1, "items"], toggle: [2, "toggle"] }, { selectedChange: "selectedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.label; var currVal_8 = _co.disabled; var currVal_9 = _co._constants.weightPlaceholder; var currVal_10 = _co.size; var currVal_11 = _co._constants.weightMask; var currVal_12 = true; var currVal_13 = _co.readonly; var currVal_14 = _co.showLabel; var currVal_15 = _co.isDisplayError(); _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = _co.disabled; var currVal_17 = _co.internal; _ck(_v, 3, 0, currVal_16, currVal_17); var currVal_18 = _co.units; var currVal_19 = _co._constants.weightUnits.getAll(); var currVal_20 = true; _ck(_v, 12, 0, currVal_18, currVal_19, currVal_20); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_WeightInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-weight-input", [], null, null, null, View_WeightInputComponent_0, RenderType_WeightInputComponent)), i1.ɵdid(1, 49152, null, 0, i8.WeightInputComponent, [[8, null], i1.ChangeDetectorRef, i9.OmsConstants], null, null)], null, null); }
var WeightInputComponentNgFactory = i1.ɵccf("oms-weight-input", i8.WeightInputComponent, View_WeightInputComponent_Host_0, { label: "label", size: "size", readonly: "readonly", disabled: "disabled", forceChangeUnits: "forceChangeUnits", showLabel: "show-label" }, {}, ["[input-before]", "[group-before]"]);
export { WeightInputComponentNgFactory as WeightInputComponentNgFactory };
