import {Component, Input, OnInit} from '@angular/core';
import {FilterPageRequest} from "../../../../../modules/shared/models/filter.page.request";
import {Customer, FreightForwarder, MasterStatus} from "../../../../../modules/shared/models";
import {FilterOptions} from "../report-filter/filter-options";
import {BaseColumn} from "../../../../../modules/shared/components/base/data-table/columns/column-types";
import {isNullOrUndefined} from 'util';
import {CustomerService} from "../../../../../services/customer.service";
import {FreightForwarderService} from "../../../../../services/freight.forwarder.service";

@Component({
  selector: 'oms-masters-report-filters',
  templateUrl: './masters-report-filters.component.html',
  styleUrls: ['./masters-report-filters.component.scss']
})
export class MastersReportFiltersComponent implements OnInit {

  public filterOptions: FilterOptions[] = [];
  public customer: Customer[];
  public freightForwarder: FreightForwarder[] = [];
  public filterYes = {id: 1, label: 'Yes'};
  public filterNo = {id: 0, label: 'No'};

  @Input() public filters: FilterPageRequest;

  constructor(public customerService: CustomerService,
              public freightForwarderService: FreightForwarderService) {

    this.filterOptions = [
      { label: "Master Status",
        field: '9',
        search: {
          searchable: true,
          searchType: "list",
          items: MasterStatus.items,
          multiple: true,
        }
      },

      { label: "Non AMS",
        field: "nonAMS",
        search: {
          searchable: true,
          searchFunction: "isSelectedValue",
          searchType: "list",
          multiple: false,
          items: BaseColumn.YES_NO_FILTERS
        }
      },

      { label: "CFS Location",
        field: "cfsLocation",
        search: {
          searchable: true,
          searchFunction: "isSelectedValue",
          searchType: "text",
          multiple: true,
        }
      },

    ];
  }


  ngOnInit() {
    console.log('Loaded', this.filters);
    if (this.filters) {
      this.filters.filterByColumns.forEach((filter, index, array) => {
        if (filter) {

          if (filter.field === 'customerId') {
            this.customerService.getMany(filter.values.map(s => Number(s))).subscribe(
              (customer) => this.customer = customer
            );
          } else if (filter.field === 'freightForwarderId') {
            if (filter.multipleValues) {
              this.freightForwarderService.getMany(filter.values.map(s => Number(s))).subscribe(
                (ff) => this.freightForwarder = ff
              );
            }
          } else {
            // not found or deprecated filter - to remove
//            array.splice(index, 1);
          }
        }
      });

//      console.log('FILTER OPTIONS', this.filterOptions, 'FILTERS', this.filters);
      for (let options of this.filterOptions) {
        let filter = this.filters.getColumnFilter(options.field);

        if (filter) {
//          console.log('Found Filter', options.field, '->', filter);
          if (!isNullOrUndefined(filter.value)) {
            options.search.search = filter.value;

            if (options.search.items) {
              options.search.selected = (options.search.items as any[]).find((item) => item.id === ~~filter.value);
            }

          } else if (filter.multipleValues) {
            if (options.search.items) {
              options.search.selected =
                filter.multipleValues.map((id) => (options.search.items as any[]).find((item) => item.id === ~~id));
            }
          }
        }
      }
    }
  }

  get updatedYtd(): boolean {
    return this.filters.hasNamedCondition("UPDATED_YTD");
  }

  set updatedYtd(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("UPDATED_YTD");
    } else {
      this.filters.removeNamedCondition("UPDATED_YTD");
    }
  }

  get mastersOnly(): boolean {
    return this.filters.hasNamedCondition("MASTERS_ONLY");
  }

  set mastersOnly(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("MASTERS_ONLY");
    } else {
      this.filters.removeNamedCondition("MASTERS_ONLY");
    }
  }


  onCustomerChange() {
    if (this.customer && this.customer.length) {
      this.filters.addColumnFilter('customerId', this.customer.map((c) => c.id));
    } else {
      this.filters.removeColumnFilter('customerId');
    }
  }

  onFreightForwarderChange() {
    if (this.freightForwarder && this.freightForwarder.length) {
      this.filters.addColumnFilter('freightForwarderId', this.freightForwarder.map((ff) => ff.id));
    } else {
      this.filters.removeColumnFilter('freightForwarderId');
    }
    console.log('>>>', this.filters);
  }

  public get mastersBilled() {
    if (this.filters.hasNamedCondition('BILLED')) {
      return this.filterYes;
    }

    if (this.filters.hasNamedCondition('NOT_BILLED')) {
      return this.filterNo;
    }

    return undefined;
  }

  public set mastersBilled(value) {
    this.filters.removeNamedConditions('NOT_BILLED', 'BILLED');
    switch (value && value.id) {
      case 0:
        this.filters.addNamedCondition('NOT_BILLED');
        break;
      case 1:
        this.filters.addNamedCondition('BILLED');
        break;
    }
  }


}
