var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, DoCheck, ElementRef, Injector, KeyValueDiffers, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { debounceTime, switchMap, take, takeUntil } from 'rxjs/operators';
import { LogChangesService } from '../../../../../../services/logchanges/log.changes.service';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { BaseColumn, CellDisplayType, CustomColumn, DateTimeColumn, FieldType, HyperLinkColumn } from '../columns/column-types';
import { AddressService } from '../../../../../../services/index';
import { ProblemDialogComponent } from '../../../../../../components/common/problem-dialog/problem-dialog.component';
import { MatDialog } from '@angular/material';
import { MasterPttCreatorDialogComponent } from '../../../../../../components/common/master-ptt-creator-dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ProblemsHistoryInformationDialogComponent } from '../../../../../../components/common/problems-history-information-dialog';
import { Master, Address, Order } from '../../../../models';
import { AddressDialogComponent } from '../../../address-dialog';
import { DataTableComponent } from '../data-table.component';
import { MasterService } from '../../../../../../services/master/master.service';
import { HistoryInformationDialogComponent } from '../../../../../../components/common/history-information-dialog';
import { isFunction, isNullOrUndefined } from 'util';
import { conformToMask } from 'text-mask-core/dist/textMaskCore';
import { absent, assigned, isEmptyString } from '../../../../../../_helpers/utils';
import { ColumnIds } from '../../../../../../common/column-ids';
import { UldsDialogComponent } from '../../../../../../components/common/ulds-dialog/ulds-dialog.component';
import { CbpStatusDialogComponent } from '../../../../../../components/common/cbp-status-dialog/cbp-status-dialog.component';
import { PageResult } from '../../../../models/query-models/page-result';
import { Logger } from '../../../../../../_helpers/logger';
import { ActivatedRoute, Router } from "@angular/router";
import { CbpQuickViewStatusDialogComponent } from "../../../../../../components/common/cbp-quick-view-status-dialog/cbp-quick-view-status-dialog.component";
import { OmsDialogsService } from "../../../../../../components/common/oms-dialogs";
import { KeyCode } from "../../../common/input/ng-select-types/ng-select.types";
var editOnFocus = false;
var DataTableCellComponent = /** @class */ (function () {
    function DataTableCellComponent(table, differs, cdr, router, route, logChangesService, addressService, masterService, componentFactoryResolver, dialog, dialogs, injector) {
        this.table = table;
        this.differs = differs;
        this.cdr = cdr;
        this.router = router;
        this.route = route;
        this.logChangesService = logChangesService;
        this.addressService = addressService;
        this.masterService = masterService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.dialog = dialog;
        this.dialogs = dialogs;
        this.injector = injector;
        this.DisplayType = CellDisplayType;
        this.FieldType = FieldType;
        this.assigned = assigned;
        //  @Input() public table: DataTableComponent;
        this.editing = false;
        this.searchInitialized = false;
        this.selectItemsSubject = new Subject();
        this.searchSubjectText = new BehaviorSubject('');
        this.isViewInitialized = false;
        this.closeOnBlur = true;
        this.unsubscribe$ = new Subject();
        this.differ = differs.find({}).create();
    }
    DataTableCellComponent.prototype.ngOnChanges = function (changes) {
        var search = this.column.searchText || this.table && this.table.filter && this.table.filter.search || undefined;
        var triggered = false;
        if (this.searchText !== search) {
            this.searchText = search;
            if (this.cellComponentRef) {
                if (this.cellComponentRef.instance.search !== search) {
                    this.cellComponentRef.instance.row = this.row;
                    this.cellComponentRef.instance.search = search;
                    this.cellComponentRef.instance.updateObject();
                    this.cellComponentRef.changeDetectorRef.detectChanges();
                    triggered = true;
                }
            }
            else {
                this.updateObject();
            }
        }
        if (changes.column) {
            this.isSelectable = (this.column.handlers) && !isNullOrUndefined(this.column.handlers.editor) && !isNullOrUndefined(this.selectOptions);
            this.editor = (this.column.handlers && this.column.handlers.editor) ? this.column.handlers.editor : {};
            this.cellDisplayType = this.isSelectable ? CellDisplayType.SELECT : CellDisplayType.TEXTBOX;
        }
        if (changes.row || changes.column) {
            if (this.cellComponentRef) {
                if (!triggered) {
                    this.cellComponentRef.instance.row = this.row;
                    this.cellComponentRef.instance.search = search;
                    this.cellComponentRef.instance.updateObject();
                    this.cellComponentRef.changeDetectorRef.detectChanges();
                    triggered = true;
                }
            }
            else {
                this.updateObject();
            }
        }
    };
    DataTableCellComponent.prototype.reRender = function () {
        this.updateObject();
        this.cdr.markForCheck();
    };
    DataTableCellComponent.prototype.updateObject = function () {
        this.rowObject = this.column.rowObject(this.row);
        this.rawValue = this.column.getValue(this.rowObject);
        this.editable = this.column.editable(this.rowObject);
        this.value = this.column.convert(this.rowObject, this.rawValue);
        this.empty = isNullOrUndefined(this.rawValue);
        //    this.newWindow = this.column instanceof HyperLinkColumn && this.column.newWindow;
        //    this.hyperLink = this.column instanceof HyperLinkColumn && this.column.handlers.link ? (<HyperLinkColumn>this.column).getHyperLink(this.rowObject, this.rawValue) : [];
        //    this.targetHyperLink = this.getDownloadPdfLink(this.rowObject) as string;
        this.clickHandler = this.column.handlers.onClick;
        this.iconClass = this.column.getIconClass(this.rowObject, this.rawValue);
        this.displayType = this.column.getDisplayType(this.rowObject, this.rawValue);
        var cls = this.column.getClass(this.rowObject, this.rawValue);
        this.cellClass = isNullOrUndefined(cls) || cls.length === 0 ? 'data-table-cell' : 'data-table-cell ' + cls;
        this.hasIcon = !isNullOrUndefined(this.column.getIconClass(this.rowObject, this.rawValue));
        this.tooltip = this.column.getTooltip(this.rowObject);
        this.info = this.column.getInfo(this.rowObject, this.rawValue);
        var editor = this.editor;
        if (editor) {
            this.placeholder = (typeof editor.placeholder === 'function') ? editor.placeholder(this.row, this.rawValue) : editor.placeholder || '';
        }
        this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);
        this.display = this.column.editable && isEmptyString(this.value) ? this.table.emptyValue : this.value;
        this.cellButtonHidden = this.cellButton ? (typeof this.cellButton.hidden === 'function' ? this.cellButton.hidden(this.rowObject, this.rawValue) : !!this.cellButton.hidden) : undefined;
        this.cellButtonLink = this.cellButton ? (typeof this.cellButton.link === 'function' ? this.cellButton.link(this.rowObject, this.rawValue) : this.cellButton.link) : undefined;
        this.cellButtonTooltip = this.cellButton ? (typeof this.cellButton.tooltip === 'function' ? this.cellButton.tooltip(this.rowObject, this.rawValue) : this.cellButton.tooltip) : undefined;
        this.cellButtonIcon = this.cellButton ? (typeof this.cellButton.icon === 'function' ? this.cellButton.icon(this.rowObject, this.rawValue) : this.cellButton.icon) : undefined;
        this.cellButtonClickHandler = this.cellButton ? this.column.handlers.button.onClick : undefined;
    };
    DataTableCellComponent.prototype.editColumnValue = function (ref, event) {
        var _this = this;
        if (this.column.editable(this.row)) {
            if (this.column.isDialog) {
                this.openDialog(ref, event);
            }
            else if (this.column.isExternalEditor) {
                this.table.doCellClick(event, this.row, this.column);
            }
            else 
            // Date column
            if (this.column.fieldType === FieldType.DATETIME || this.column.fieldType === FieldType.DATE) {
                var editTime = this.column.fieldType === FieldType.DATETIME;
                var actualDate = void 0;
                var estimatedDate = void 0;
                if (this.column instanceof DateTimeColumn) {
                    actualDate = this.column.getActual(this.rowObject);
                    estimatedDate = this.column.getEstimated(this.rowObject);
                }
                else {
                    actualDate = this.column.getValue(this.rowObject);
                }
                this.table.editDateTime(this.column.handlers.editor.cleanable, actualDate, estimatedDate, editTime, event)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(function (newValue) {
                    try {
                        if (_this.column instanceof DateTimeColumn) {
                            _this.table.updateColumnValue(_this.rowObject, _this.column, newValue.actual, _this.column.actualField)
                                .pipe(takeUntil(_this.unsubscribe$), take(1))
                                .subscribe(function () { return _this.updateObject(); });
                            _this.table.updateColumnValue(_this.rowObject, _this.column, newValue.estimated, _this.column.estimatedField)
                                .pipe(takeUntil(_this.unsubscribe$), take(1))
                                .subscribe(function () { return _this.updateObject(); });
                        }
                        else {
                            _this.table.updateColumnValue(_this.rowObject, _this.column, newValue.actual, _this.column.field);
                        }
                        _this.error = undefined;
                    }
                    catch (error) {
                        _this.editError(error);
                    }
                    finally {
                        _this.editing = false;
                        _this.cdr.markForCheck();
                        _this.updateObject();
                    }
                }, function () {
                    _this.editing = false;
                });
            }
            else 
            // CHECKBOX
            if (this.column.fieldType === FieldType.BOOLEAN) {
                var oldValue = this.value || false;
                try {
                    this.table.updateColumnValue(this.rowObject, this.column, !oldValue, this.column.field);
                    this.error = undefined;
                }
                catch (error) {
                    this.editError(this.error);
                }
                finally {
                    this.editing = false;
                    this.cdr.markForCheck();
                    this.updateObject();
                }
            }
            else {
                // Textbox or Select edit
                this.startEdit();
            }
        }
    };
    DataTableCellComponent.prototype.openDialog = function (ref, event) {
        switch (this.column.id) {
            case ColumnIds.MASTER_PROBLEM:
                this.dialogs.openDialog(ProblemDialogComponent, // {}) .open(ProblemDialogComponent, {
                //          width: '500px',
                /*data: */ {
                    id: this.row.id,
                    objectType: this.row.rowId.toUpperCase(),
                    object: this.row,
                    readonly: this.table.readonly
                }).then();
                //        );
                break;
            case ColumnIds.MASTER_DOCS:
                this.dialogs.openObjectDocCenterDialog(this.row, this.table.readonly);
                break;
            case ColumnIds.MASTER_OSD:
                this.dialogs.openObjectComCenterDialog(this.row, 5, this.table.readonly);
                break;
            case ColumnIds.MASTER_HAZ:
                this.dialogs.openObjectComCenterDialog(this.row, 4, this.table.readonly);
                break;
            case ColumnIds.MASTER_COM:
                this.dialogs.openObjectComCenterDialog(this.row, 0, this.table.readonly);
                break;
            case ColumnIds.MASTER_PDF:
                this.dialog.open(MasterPttCreatorDialogComponent, { width: '90%', data: { master: this.row } });
                break;
            case ColumnIds.MASTER_GENERATE_PTT:
                this.dialog.open(MasterPttCreatorDialogComponent, { width: '90%', data: { master: this.row } });
                break;
            case ColumnIds.MASTER_CBP_STATUS:
                this.dialog.open(CbpStatusDialogComponent, {
                    width: '80%',
                    data: { id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: this.table.readonly }
                });
                break;
            case ColumnIds.MASTER_AIR_ULD_COUNT:
                if (this.row instanceof Master) {
                    this.dialog.open(UldsDialogComponent, {
                        width: '30%',
                        data: {
                            readonly: this.table.readonly,
                            masterId: this.row.id
                        }
                    });
                }
                if (this.row instanceof Order) {
                    this.dialog.open(UldsDialogComponent, {
                        width: '30%',
                        data: {
                            readonly: this.table.readonly,
                            orderId: this.row.id
                        }
                    });
                }
                break;
        }
    };
    DataTableCellComponent.prototype.isEmptyHyperLink = function (value) {
        return absent(value) || (value.path !== 'string' && value['length'] === 0 || !value.path);
    };
    DataTableCellComponent.prototype.onCellClick = function (ref, event) {
        // [routerLink]="hyperLink" [queryParamsHandling]="assigned(hyperLink) ? 'preserve': ''" [target]="(newWindow && hyperLink && hyperLink.length) ? '_blank' : null"
        if (this.clickHandler) {
            this.clickHandler(this.row, this.rawValue);
        }
        else {
            if (this.column instanceof HyperLinkColumn) {
                var openInNewWindow = this.column.newWindow === 'auto' ? event.ctrlKey : this.column.newWindow;
                var hyperLink = this.column.getHyperLink(this.rowObject, this.rawValue, openInNewWindow);
                if (!this.isEmptyHyperLink(hyperLink)) {
                    var commands = typeof hyperLink.path === 'string' ? [hyperLink.path] : hyperLink.path;
                    if (openInNewWindow) {
                        // Open in New Window
                        var url = this.router.createUrlTree(commands, { queryParams: hyperLink.params }).toString();
                        window.open(url, '_blank');
                    }
                    else {
                        // Navigate in Current window
                        this.router.navigate(commands, { queryParamsHandling: "preserve", relativeTo: this.route }).then();
                    }
                }
            }
        }
    };
    DataTableCellComponent.prototype.onCellButtonClick = function (ref, event) {
        if (this.cellButtonClickHandler) {
            this.cellButtonClickHandler(this.row, this.rawValue);
        }
    };
    DataTableCellComponent.prototype.generateMasterMDF = function (ref, event) {
        this.dialog.open(MasterPttCreatorDialogComponent, { width: '90%', data: { master: this.row } });
    };
    DataTableCellComponent.prototype.ngOnInit = function () {
        this.isSelectable = (this.column.handlers && this.column.handlers.editor) && !isNullOrUndefined(this.selectOptions);
        this.initSearch();
    };
    DataTableCellComponent.prototype.ngAfterViewInit = function () {
        if (this.isViewInitialized) {
            return;
        }
        this.isViewInitialized = true;
        if (this.column instanceof CustomColumn && this.column.component) {
            if (this.target) {
                this.target.clear();
                if (this.cellComponentRef) {
                    this.cellComponentRef.destroy();
                }
                var componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.column.component);
                this.cellComponentRef = this.target.createComponent(componentFactory, 0, this.injector);
                this.cellComponentRef.instance.column = this.column;
                this.cellComponentRef.instance.table = this.table;
                this.cellComponentRef.instance.row = this.row;
                this.cellComponentRef.instance.search = this.searchText;
                this.cellComponentRef.instance.data = this.column.data;
                this.cellComponentRef.changeDetectorRef.detectChanges();
            }
        }
    };
    Object.defineProperty(DataTableCellComponent.prototype, "headerIconClass", {
        /*  getDownloadPdfLink(row) {
            if (row instanceof Master) {
              return this.masterService.buildDownloadMasterUrl('.pdf', row.id);
            }
        
            if (row instanceof Order) {
              return ['order', {id: row.id}];
            }
          } */
        get: function () {
            return this.column.getHeaderIconClass();
        },
        enumerable: true,
        configurable: true
    });
    DataTableCellComponent.prototype.startEdit = function () {
        var _this = this;
        this.editing = true;
        this.localValue = isNullOrUndefined(this.rawValue) ? null : this.value; // !Important start edit with converted value
        if (this.isSelectable) {
            this.selected = this.convertToSelectable(this.rawValue);
            this.selectItems = this.column.handlers.editor.select.items;
            if (this.selectItemsSubject) {
                this.selectItemsSubject.next(this.selectItems);
            }
        }
        // Important: do on timeout after editors initialized!
        setTimeout(function () {
            if (_this.isSelectable) {
                _this.ngSelect.focus();
                _this.ngSelect.open();
            }
            else {
                if (_this.textInput) {
                    _this.textInput.nativeElement.focus();
                    _this.textInput.nativeElement.select();
                }
            }
            _this.cdr.markForCheck();
        });
        this.cdr.markForCheck();
    };
    DataTableCellComponent.prototype.cancelEdit = function () {
        if (this.editing) {
            this.editing = false;
            this.error = undefined;
            this.cdr.markForCheck();
        }
    };
    DataTableCellComponent.prototype.editError = function (error) {
        this.error = error;
        this.table.editErrorEvent.emit(error);
    };
    DataTableCellComponent.prototype.doneEdit = function () {
        var _this = this;
        try {
            var newValue = this.convertBack(this.localValue);
            this.table.updateColumnValue(this.rowObject, this.column, newValue, this.column.field)
                .pipe(take(1))
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(function (object) {
                _this.row = object;
                _this.updateObject();
            });
            this.error = undefined;
        }
        catch (error) {
            this.editError(error);
        }
        finally {
            this.editing = false;
            this.cdr.markForCheck();
        }
    };
    DataTableCellComponent.prototype.onKeyDown = function (event) {
        if (this.editing) {
            if (event.key === 'Enter') {
                this.doneEdit();
                event.preventDefault();
            }
            else if (event.key === 'Escape') {
                this.cancelEdit();
                event.preventDefault();
            }
            else if (event.keyCode === KeyCode.Tab) {
                if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value) {
                    this.localValue = this.ngSelect.itemsList.markedItem.value;
                }
                this.doneEdit();
                event.preventDefault();
            }
        }
    };
    DataTableCellComponent.prototype.onClear = function () {
        this.closeOnBlur = false;
        this.localValue = null;
        this.textInput.nativeElement.focus();
        this.cdr.markForCheck();
    };
    DataTableCellComponent.prototype.onBlurEditor = function () {
        var _this = this;
        editOnFocus = true;
        this.closeOnBlur = true;
        if (this.isSelectable) {
            this.localValue = this.convertBack(this.selected);
        }
        setTimeout(function () {
            if (_this.closeOnBlur) {
                _this.doneEdit();
            }
            editOnFocus = false;
        });
    };
    DataTableCellComponent.prototype.isAddressColumn = function () {
        return this.column.fieldType === FieldType.ADDRESS;
    };
    DataTableCellComponent.prototype.getSearchItems = function () {
        var search = this.isSelectable ? this.selectOptions.search : null;
        if (!search) {
            return null;
        }
        if (isFunction(search)) {
            return search;
        }
        var baseEntityService = search;
        return function (searchText) {
            var addArgs = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                addArgs[_i - 1] = arguments[_i];
            }
            return baseEntityService.findByObs(searchText || '', 100, true);
        };
    };
    Object.defineProperty(DataTableCellComponent.prototype, "isMultiSelect", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    /*get cellClass():string {
      const cls = this.column.getClass(this.rowObject, this.rawValue);
      return isNullOrUndefined(cls) || cls.length == 0 ? 'data-table-cell' : 'data-table-cell '+cls;
    }*/
    DataTableCellComponent.prototype.onDoubleClick = function (event) {
        if (this.editing) {
            event.stopPropagation();
        }
    };
    DataTableCellComponent.prototype.cleanupMask = function (s, mask, clearMask) {
        // remove _ guide before passing to the model
        if (mask && mask.mask) {
            s = conformToMask(s, mask.mask, { guide: false }).conformedValue;
        }
        // keep only alpha-numerical values and '.' for number support
        if (clearMask) {
            s = s.replace(/[^0-9A-Za-z.]/g, '');
        }
        return s; // this.isNumber ? ( s ? Number(s) : null): s;
    };
    DataTableCellComponent.prototype.convertBack = function (value) {
        if (typeof value === 'string') {
            value = this.cleanupMask(value, this.column.handlers.editor.mask, this.column.handlers.editor.clearMask);
        }
        return this.column.convertBack(value);
    };
    DataTableCellComponent.prototype.onSelectItem = function () {
        this.localValue = this.convertBack(this.selected);
        this.doneEdit();
        /*    if (this.isAddressColumn() && this.addressTypes && this.localValue instanceof Address) {
              if (!this.localValue.supportsAny(this.addressTypes)) {
                this.addressService.updateAddressTypes(this.localValue, this.addressTypes);
              }
            } */
    };
    DataTableCellComponent.prototype.isSelected = function (item) {
        return item.id === this.selected;
    };
    DataTableCellComponent.prototype.onSelectKeyUp = function (event) {
        var search = event.target.value.trim();
        if (search !== this.selectInput) {
            this.selectInput = search;
            this.searchSubjectText.next(search);
            // this.doSearch();
        }
    };
    DataTableCellComponent.prototype.onSelectOpen = function () {
        if (this.isSelectable) {
            this.initSearch();
            if (this.selectItemsSubject && this.column.handlers.editor.select.items) {
                this.selectItemsSubject.next(this.column.handlers.editor.select.items);
            }
            this.searchSubjectText.next(this.selectInput);
        }
    };
    DataTableCellComponent.prototype.initSearch = function () {
        var _this = this;
        if (this.searchInitialized) {
            return;
        }
        this.searchInitialized = true;
        if (this.isSelectable) {
            this.addressTypes = this.column.handlers.editor.select.addressTypes;
            if (this.isAddressColumn()) {
                this.addressSearchService = this.addressService.getNewSearchService(this.addressTypes, 15);
                this.selectItemsSubject = this.addressSearchService.subjAddresses;
            }
            else {
                this.selectItemsSubject = new Subject();
            }
        }
        /*    if (this.column.handlers && this.column.handlers.editor && this.column.handlers.editor.searchBy) {
              this.searchSubjectText.pipe(
                debounceTime(300),
                switchMap(search => search ? this.column.handlers.editor.searchBy(search, this.rowObject, this.addressForceSelectAll) : of([]) )
              ).pipe(takeUntil(this.unsubscribe$)).subscribe(items => this.selectItemsSubject.next(items));
              // TODO deprecated. use  this.editor.searchBy
            }
        
            else*/
        if (this.addressSearchService) {
            this.searchSubjectText.pipe(debounceTime(300), switchMap(function (search) { return search ? _this.searchServiceFunction(search) : of([]); })).pipe(takeUntil(this.unsubscribe$))
                .subscribe(function (items) { return _this.addressSearchService.subjAddresses.next(items); });
        }
        else if (this.getSearchItems()) {
            this.searchSubjectText.pipe(debounceTime(300), switchMap(function (search) { return search ? _this.getSearchItems()(search || '', 15) : of(PageResult.buildEmpty()); })).pipe(takeUntil(this.unsubscribe$))
                .subscribe(function (response) { return _this.selectItemsSubject.next(response.content); });
        }
    };
    DataTableCellComponent.prototype.searchServiceFunction = function (search) {
        if (this.column.field === 'addressDelivery' && this.rowObject instanceof Order) {
            return this.addressSearchService.searchCustomerDeliveryLocationsPromise(search || '', this.rowObject.customer, 15, this.addressForceSelectAll);
        }
        else {
            return this.addressSearchService.searchPromise(search || '', 15, this.addressForceSelectAll);
        }
    };
    Object.defineProperty(DataTableCellComponent.prototype, "selectOptions", {
        get: function () {
            return this.column.handlers.editor.select;
        },
        enumerable: true,
        configurable: true
    });
    DataTableCellComponent.prototype.convertToSelectable = function (value) {
        return this.isSelectable && this.selectOptions.converter ? this.selectOptions.converter(value) : value;
    };
    DataTableCellComponent.prototype.searchSelectable = function (search, obj) {
        return true;
        //    return (this.selectable && this.selectOptions.searchFn) ? this.selectOptions.searchFn(search, obj) : true;
    };
    DataTableCellComponent.prototype.onFocusColumn = function (cell, event) {
        if (editOnFocus) {
            this.editColumnValue(cell, event);
        }
    };
    DataTableCellComponent.prototype.onRightClick = function (event) {
        if (this.column.history.logChanges) {
            event.stopPropagation();
            var columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;
            var params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField, this.column);
            if (this.column.history.methodForExecution) {
                this[this.column.history.methodForExecution](params);
            }
            else {
                this.executeLogChanges(params, this.column.history.logConverter);
            }
        }
        else if (this.column.history.logChangesWithCbpStatuses) {
            var columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;
            if (typeof columnField !== "string") {
                var fieldKeys = Object.keys(columnField);
                columnField = columnField[fieldKeys[0]];
            }
            var params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField);
            this.dialog.open(CbpQuickViewStatusDialogComponent, { width: '80%', height: '70%', data: { id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: true,
                    logParams: params, column: this.column, convertByBaseType: this.column.history.logConverter } });
        }
        return false;
    };
    DataTableCellComponent.prototype.executeMasterProblemLogChanges = function (params) {
        var _this = this;
        this.logChangesService.getMasterProblemsInfo(params[0].id).then(function (objectLogChanges) {
            _this.dialog.open(ProblemsHistoryInformationDialogComponent, {
                width: '80%',
                data: { objectLogChanges: objectLogChanges, column: _this.column, convertByBaseType: true }
            });
        });
    };
    DataTableCellComponent.prototype.executeLogChanges = function (logParams, convertByBaseType) {
        this.dialogs.openDialog(HistoryInformationDialogComponent, { logParams: logParams, column: this.column, convertByBaseType: convertByBaseType }, {}).then();
        /*      .open(HistoryInformationDialogComponent, {
              width: '80%',
              data: {logParams: logParams, column: this.column, convertByBaseType: convertByBaseType}
            }); */
    };
    DataTableCellComponent.prototype.onCreateNewItem = function (event) {
        var _this = this;
        if (this.column.fieldType === FieldType.ADDRESS) {
            this.onCreateNewAddress(event);
        }
        else {
            this.selectOptions.createNewHandler(this.row)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(function (res) {
                if (res) {
                    _this.selected = res;
                    _this.onSelectItem();
                }
                else {
                    _this.cancelEdit();
                }
            });
        }
    };
    // Used only for  column.fieldType === FieldType.ADDRESS
    DataTableCellComponent.prototype.onCreateNewAddress = function (event) {
        var _this = this;
        var address = new Address();
        address.types = this.addressTypes;
        var dialogRef = this.dialog.open(AddressDialogComponent, {
            width: 'auto',
            data: { address: address, disabledTypes: this.addressTypes }
        });
        dialogRef.afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (res) {
            if (res) {
                _this.selected = res.res;
                _this.onSelectItem();
            }
            else {
                _this.cancelEdit();
            }
        });
    };
    DataTableCellComponent.prototype.selectGroupBy = function (item) {
        return item instanceof Address ? (item.top ? 'Top Addresses' : 'Other') : null;
    };
    DataTableCellComponent.prototype.onRemoveFromTop = function (event, item) {
        event.stopPropagation();
        if (this.column.field === 'addressDelivery' && this.rowObject instanceof Order) {
            this.addressService.deleteCustomerDeliveryLocation(this.rowObject.customer, item);
            // this.doSearch();
            this.searchSubjectText.next(this.selectInput);
        }
    };
    DataTableCellComponent.prototype.ngOnDestroy = function () {
        this.table = undefined;
        this.cancelEdit();
        if (this.target) {
            this.target.clear();
        }
        if (this.cellComponentRef) {
            this.cellComponentRef.destroy();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    DataTableCellComponent.prototype.ngDoCheck = function () {
        /*    if (this.differ.diff({
                  value:this.rawValue,
                  hidden: this.column.isHidden(this.row, this.rawValue),
                  editable: this.column && this.column.editable(this.row)
            })) {
              this.cdr.detectChanges();
              this.cdr.markForCheck();
            }*/
    };
    Object.defineProperty(DataTableCellComponent.prototype, "cellButton", {
        get: function () {
            return this.column.handlers.button;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableCellComponent.prototype, "cellButtonRefTarget", {
        get: function () {
            return this.cellButton && this.cellButton.newPage ? '_blank' : undefined;
        },
        enumerable: true,
        configurable: true
    });
    DataTableCellComponent.prototype.refresh = function () {
        // this.cdr.markForCheck();
    };
    DataTableCellComponent.prototype.getState = function (address) {
        if (isNullOrUndefined(address.usaState)) {
            return "";
        }
        return ", " + address.usaState.name;
    };
    DataTableCellComponent.prototype.getWithComa = function (data) {
        return isNullOrUndefined(data) ? "" : ", " + data;
    };
    DataTableCellComponent.prototype.isNotNull = function () {
        return !isNullOrUndefined(this.rawValue) && this.value !== "N/A";
    };
    DataTableCellComponent = __decorate([
        Logger({ name: 'DataTableCell' }),
        __metadata("design:paramtypes", [DataTableComponent,
            KeyValueDiffers,
            ChangeDetectorRef,
            Router,
            ActivatedRoute,
            LogChangesService,
            AddressService,
            MasterService,
            ComponentFactoryResolver,
            MatDialog,
            OmsDialogsService,
            Injector])
    ], DataTableCellComponent);
    return DataTableCellComponent;
}());
export { DataTableCellComponent };
