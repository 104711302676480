import { Injectable } from '@angular/core';
import {HttpEvent} from '@angular/common/http';
import {ReplaySubject, Subject, Observable} from 'rxjs';
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";
import {ObjectComCenter} from "../modules/shared/models/comcenter/object.com.center";

const API_URL = "/comcenter/";
@Injectable()
export class ComCenterService {

  public objectComCenterSubject = new Subject<ObjectComCenter>();
  public objectComCentersSubject = new ReplaySubject<ObjectComCenter[]>(1);

  constructor(private httpRestService: RestService, private httpUtils: HttpUtilsService) { }

  findComCenter(id, objectType) {
     this.httpRestService.get<ObjectComCenter>(API_URL + 'findComCenter', id, objectType).subscribe(objectComCenter => {
        this.objectComCenterSubject.next(objectComCenter);
     });
  }

  findAllById(id: number, objectType: string) {
    return this.httpRestService.get<ObjectComCenter[]>(API_URL + 'findAllById', id, objectType).subscribe(objectDocuments => {
      this.objectComCentersSubject.next(objectDocuments);
    });
  }

  create(item: ObjectComCenter) {
    return this.httpRestService.post<ObjectComCenter>(API_URL + 'create', item).subscribe(objectComCenter => {
        this.objectComCenterSubject.next(objectComCenter);
    });
  }

  createObservable(item: ObjectComCenter): Observable<ObjectComCenter> {
    return this.httpRestService.post<ObjectComCenter>(API_URL + 'create', item);
  }

  createWithDocument(item: ObjectComCenter, files: FileList): Observable<HttpEvent<{}>> {
    let formdata: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formdata.append("files", files.item(i), files.item(i)['name']);
    }
    formdata.append("objectComCenter", JSON.stringify(item));
    return this.httpRestService.requestQuery('POST', API_URL + '/createWithDocument', formdata);
  }

  update(item: ObjectComCenter) {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    this.httpRestService.put<any>(API_URL + 'update', item, { headers: httpHeader })
      .subscribe(objectComCenter => {
        this.objectComCenterSubject.next(objectComCenter);
    });
  }

  delete(id: number, objectType): Observable<ObjectComCenter> {
    return this.httpRestService.delete<ObjectComCenter>(API_URL + 'delete', id, objectType);
  }
}
