/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fill-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./fill-indicator.component";
var styles_FillIndicatorComponent = [i0.styles];
var RenderType_FillIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FillIndicatorComponent, data: {} });
export { RenderType_FillIndicatorComponent as RenderType_FillIndicatorComponent };
export function View_FillIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "label", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "full": 0, "over": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "fill"]], [[4, "height", null]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("fill-indicator custom-bgcolor-green " + _co.fillClass); var currVal_1 = _ck(_v, 2, 0, _co.isFull, _co.isOver); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.fillPercents; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.label; _ck(_v, 5, 0, currVal_3); }); }
export function View_FillIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-fill-indicator", [], null, null, null, View_FillIndicatorComponent_0, RenderType_FillIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i3.FillIndicatorComponent, [], null, null)], null, null); }
var FillIndicatorComponentNgFactory = i1.ɵccf("oms-fill-indicator", i3.FillIndicatorComponent, View_FillIndicatorComponent_Host_0, { fill: "fill", label: "label" }, {}, []);
export { FillIndicatorComponentNgFactory as FillIndicatorComponentNgFactory };
