import { SearchRange } from './search.range';
import { IColumnSearchOptions } from "./column.settings";
import { isNullOrUndefined } from "util";
import { Exclude, Type } from "class-transformer";

export class FilterSearchColumn {
  field: number | string;
  value?: string;
  fullMatchValue?: boolean = false;
  @Type(() => SearchRange)
  range?: SearchRange;
  multipleValues?: string[];

  public static fromSearchOptions(search: IColumnSearchOptions, field?: any): FilterSearchColumn {
    if (search.searchable && !isNullOrUndefined(search.search) && (search.search + "").length > 0) {
      let fieldName = search.field || field;
      if (search.search instanceof Array) {
        return new FilterSearchColumn(fieldName, null, search.search, null);
      } else if (search.search instanceof SearchRange) {
        if (!search.search.isEmpty) {
          return new FilterSearchColumn(fieldName, null, null, search.search);
        }
      } else {
        let searchText = search.search;
        if (search.convertInput) {
          searchText = search.convertInput(searchText);
        }
        return new FilterSearchColumn(fieldName, searchText, null, null);
      }
    }

    return null;
  }

  constructor(field: number | string, value?: string, multipleValues?: string[], range?: SearchRange) {
    this.field = field;
    this.value = value;
    this.multipleValues = multipleValues;
    this.range = range;
  }

  public setFullMatchValue(value: boolean): FilterSearchColumn {
    this.fullMatchValue = value;
    return this;
  }


  @Exclude()
  public get values(): string[] {
    return [this.value, ...this.multipleValues].filter(s => !isNullOrUndefined(s));
  }


}
