/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer.group.dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i8 from "ngx-perfect-scrollbar";
import * as i9 from "@angular/forms";
import * as i10 from "./customer.group.dialog.component";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../../services/customer.group.service";
var styles_CustomerGroupDialogComponent = [i0.styles];
var RenderType_CustomerGroupDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerGroupDialogComponent, data: {} });
export { RenderType_CustomerGroupDialogComponent as RenderType_CustomerGroupDialogComponent };
function View_CustomerGroupDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "sb-spinner mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 40; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CustomerGroupDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer Name is required"]))], null, null); }
export function View_CustomerGroupDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "perfect-scrollbar", [["style", "max-height: 90vh;"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i7.View_PerfectScrollbarComponent_0, i7.RenderType_PerfectScrollbarComponent)), i1.ɵdid(1, 507904, null, 0, i8.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 33, "div", [["class", "oms-modal widget"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "_loading": 0, "m-portlet--body-progress-overlay": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerGroupDialogComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "widget-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-industry"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "btn-group widget-header-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "btn-borderless btn-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-times dialog-header-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 16, "form", [["class", "widget-content"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i9.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(16, 540672, null, 0, i9.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i9.ControlContainer, null, [i9.FormGroupDirective]), i1.ɵdid(18, 16384, null, 0, i9.NgControlStatusGroup, [[4, i9.ControlContainer]], null, null), (_l()(), i1.ɵeld(19, 0, null, null, 11, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 10, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "label", [["class", "control-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer Name"])), (_l()(), i1.ɵeld(23, 0, null, null, 5, "input", [["class", "form-control"], ["formControlName", "name"], ["placeholder", "Customer Name"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 24)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 24)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 16384, null, 0, i9.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i9.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.DefaultValueAccessor]), i1.ɵdid(26, 671744, null, 0, i9.FormControlName, [[3, i9.ControlContainer], [8, null], [8, null], [6, i9.NG_VALUE_ACCESSOR], [2, i9.ɵangular_packages_forms_forms_j]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i9.NgControl, null, [i9.FormControlName]), i1.ɵdid(28, 16384, null, 0, i9.NgControlStatus, [[4, i9.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerGroupDialogComponent_2)), i1.ɵdid(30, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 4, "div", [["class", "widget-footer right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"])), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = "oms-modal widget"; var currVal_3 = _ck(_v, 4, 0, _co.viewLoading, _co.loadingAfterSubmit); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.viewLoading; _ck(_v, 6, 0, currVal_4); var currVal_13 = _co.form; _ck(_v, 16, 0, currVal_13); var currVal_21 = "name"; _ck(_v, 26, 0, currVal_21); var currVal_22 = _co.isControlInvalid("name"); _ck(_v, 30, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).autoPropagation; var currVal_1 = i1.ɵnov(_v, 1).scrollIndicators; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _co.getTitle(); _ck(_v, 10, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 18).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 18).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 18).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 18).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 18).ngClassValid; var currVal_11 = i1.ɵnov(_v, 18).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 18).ngClassPending; _ck(_v, 14, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_14 = i1.ɵnov(_v, 28).ngClassUntouched; var currVal_15 = i1.ɵnov(_v, 28).ngClassTouched; var currVal_16 = i1.ɵnov(_v, 28).ngClassPristine; var currVal_17 = i1.ɵnov(_v, 28).ngClassDirty; var currVal_18 = i1.ɵnov(_v, 28).ngClassValid; var currVal_19 = i1.ɵnov(_v, 28).ngClassInvalid; var currVal_20 = i1.ɵnov(_v, 28).ngClassPending; _ck(_v, 23, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_23 = _co.viewLoading; _ck(_v, 32, 0, currVal_23); }); }
export function View_CustomerGroupDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-customer-group-dialog", [], null, null, null, View_CustomerGroupDialogComponent_0, RenderType_CustomerGroupDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.CustomerGroupDialogComponent, [i11.MatDialogRef, i11.MAT_DIALOG_DATA, i9.FormBuilder, i12.CustomerGroupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerGroupDialogComponentNgFactory = i1.ɵccf("oms-customer-group-dialog", i10.CustomerGroupDialogComponent, View_CustomerGroupDialogComponent_Host_0, {}, {}, []);
export { CustomerGroupDialogComponentNgFactory as CustomerGroupDialogComponentNgFactory };
