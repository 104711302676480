import {Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {Subscription} from "rxjs";
import {AuthService} from "../services";

@Directive({selector: '[restricted]'})
export class RestrictedDirective implements OnInit, OnDestroy {
  cnt: number = 0;

  user$: Subscription;
  constructor( private templateRef: TemplateRef<any>,
               private viewContainer: ViewContainerRef,
               private authService: AuthService) {
  }

  ngOnInit() {
    this.user$ = this.authService.user$.subscribe((user) => {
      if (this.authService.isRestricted()) {
        this.cnt = 0;
        this.viewContainer.clear();
      } else {
        if (this.cnt === 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.cnt++;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.user$) {
      this.user$.unsubscribe();
    }
  }
}
