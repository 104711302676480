var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Uld } from "../modules/shared/models";
import { RestService } from "./rest.service";
import { HttpUtilsService } from "./http-utils.service";
import { BaseEntityService } from "./base/base-entity.service";
var API_URL = "/oms/ulds/";
var UldService = /** @class */ (function (_super) {
    __extends(UldService, _super);
    function UldService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = API_URL;
        _this.classType = Uld;
        return _this;
    }
    UldService.prototype.updateULDs = function (ulds, masterId, orderId) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var ps = {};
        if (masterId) {
            ps.masterId = masterId;
        }
        if (orderId) {
            ps.orderId = orderId;
        }
        var params = this.httpUtils.getHTTPParams(ps);
        return this.httpRestService.put(API_URL + 'update-ulds', ulds, { headers: httpHeader }, params);
    };
    return UldService;
}(BaseEntityService));
export { UldService };
