import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {DialogResult, ModalResult, OmsDialogsService} from "../components/common/oms-dialogs";
import {ReasonCode} from "../modules/shared/models/reason-code";
import {ReasonCodeDialogComponent} from "../components/dialogs/reason-code-dialog/reason-code-dialog.component";

@Injectable()
export class ReasonCodeService extends BaseEntityService<ReasonCode> {
  apiUrl = '/oms/reason-code/';
  classType = ReasonCode;

  public constructor(
    public dialogs: OmsDialogsService,
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public createEditAndSave(reasonCode: ReasonCode, readonly?: boolean): Promise<ReasonCode> {
    return new Promise<ReasonCode>((success, reject) => {
      this.dialogs.openDialog(ReasonCodeDialogComponent, {reasonCode: reasonCode, readonly: readonly})
        .then((result: DialogResult<any>) => {
          if (result.result === ModalResult.OK) {
            this.save(reasonCode).subscribe(
              saved => success(saved),
              error => reject(error === 'DataIntegrityViolation' ? 'Can\'t create new ReasonCode: \n ReasonCode ' + reasonCode.name + ' already exists!' : error));
          }
        })
        .catch(error => reject(error));
    });
  }
}
