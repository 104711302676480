import {Injectable} from '@angular/core';
import {RestService} from './rest.service';
import {HttpUtilsService} from './http-utils.service';
import {BaseEntityService} from './base/base-entity.service';
import {Observable} from "rxjs";
import {classToPlain, plainToClass} from "class-transformer";
import {map} from "rxjs/operators";
import {ErrorObservable} from "rxjs-compat/observable/ErrorObservable";
import {OrderWarehouse} from "../modules/shared/models/warehouse/order.warehouse";
import {Receiving} from "../modules/shared/models/warehouse/receiving";
import {Master} from "../modules/shared/models";
import {OrderPart} from "../modules/shared/models/warehouse/order-part";
import {HttpParams} from "@angular/common/http";

@Injectable()
export class OrderWarehouseService extends BaseEntityService<OrderWarehouse> {

  public apiUrl = '/oms/order-warehouse/';
  public classType = OrderWarehouse;

  constructor(
        public httpRestService: RestService,
        public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  /** @deprecated **/
  public receiveOrders(data: Receiving): Observable<any> {
    console.log('RECEIVE CALL', data);
    let sent = classToPlain(data, {enableCircularCheck: true});
    return this.httpRestService.put<any>(this.apiUrl + 'receive',
      sent,
      this.httpUtils.getHTTPHeaders());
  }

  /** @deprecated **/
  public loadOutOrders(data: Receiving): Observable<any> {
    return this.httpRestService.put<any>(this.apiUrl + 'loadout',
      classToPlain(data, {enableCircularCheck: true}),
      this.httpUtils.getHTTPHeaders());
  }

  /** @deprecated **/
  public getOrdersToReceive(routeIds: number[] | number): Observable<OrderWarehouse[]> {
    return (!routeIds || !routeIds['length']) ?
      ErrorObservable.create('No Inbound Routes provided') :
      this.httpRestService.getWithHeader<OrderWarehouse[]>(this.apiUrl + `orders-to-receive/${routeIds}`,
            this.httpUtils.getHTTPHeaders()).pipe(map((ows) => plainToClass(OrderWarehouse, ows)));
  }

  /** @deprecated **/
  public getOrdersToLoadOut(routeIds: number[] | number): Observable<OrderWarehouse[]> {
    return (!routeIds || !routeIds['length']) ?
      ErrorObservable.create('No Outbound Routes provided') :
      this.httpRestService.getWithHeader<OrderWarehouse[]>(this.apiUrl + `orders-to-loadout/${routeIds}`,
            this.httpUtils.getHTTPHeaders()).pipe(map((ows) => plainToClass(OrderWarehouse, ows)));
  }

  /***
   * Search Order Warehouse parts by
   * @param recoveryOrderId optional - to get Orders recovering by Recovery Load
   * @param ids array of elements:
   *    XXXXXX : Route ID
   *    MXXXXX : Master ID
   *    XXXX   : MAWB last 4 digits
   */
  public getOrdersAtWarehouse(ids: any[] | any, recoveryLoadId?: number): Observable<OrderWarehouse[]> {
    let params: HttpParams = this.httpUtils.getHTTPParams({});
    if (!!recoveryLoadId) {
      params = params.append('rld', recoveryLoadId.toString());
    }
    return this.httpRestService.getWithOptions<OrderWarehouse[]>(
      this.apiUrl + `orders-at-warehouse/${ids}`,
        this.httpUtils.getHTTPHeaders(),
      params).pipe(map((ows) => plainToClass(OrderWarehouse, ows)));
  }

  public getMasterReceivings(id: number, activeOnly: boolean = true, recoveryOrderId?: number): Promise<Master> {
    let params = this.httpUtils.getHTTPParams({active: activeOnly});
    if (recoveryOrderId) {
      params = params.append('recoveryOrderId', recoveryOrderId.toString());
    }
    return this.httpRestService.getWithOptions(this.apiUrl + 'get-master/' + id, this.httpUtils.getHTTPHeaders(), params) // .get<Master>(API_URL + 'getMaster', id)
      .pipe(map((master) => this.afterLoad(plainToClass(Master, master))))
      .toPromise();
  }


  public getMastersToReceive(mawb: string): Observable<any> {
    return this.httpRestService.getWithHeader<any>(this.apiUrl + `masters-to-receive/${mawb}`,
      this.httpUtils.getHTTPHeaders())/*.pipe(map((ows) => plainToClass(OrderWarehouse, ows)))*/;
  }

  public savePictureByOrderId(id: number | number[], docType: string, imageAsData: string[]): Promise<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const params: HttpParams = this.httpUtils.getHTTPParams({docType: docType});
    return this.httpRestService.put<any>(this.apiUrl + 'savePictureToOrder/' + id, imageAsData, httpHeader, params).toPromise();
  }

  public savePictureByMasterId(id: number, docType: string, imageAsData: string[]): Promise<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const params: HttpParams = this.httpUtils.getHTTPParams({docType: docType});
    return this.httpRestService.put<any>(this.apiUrl + 'savePictureToMaster/' + id, imageAsData, httpHeader, params).toPromise();
  }

  public receiveRoute(routeId: number, part: OrderPart): Observable<any> {
    return this.httpRestService.put(`${this.apiUrl}receive-route`,
      classToPlain(part, {enableCircularCheck: true}),
      this.httpUtils.getHTTPHeaders(),
      {id: routeId});
  }

  public loadOutRoute(routeId: number, part: OrderPart): Observable<any> {
    return this.httpRestService.put(`${this.apiUrl}load-out-route`,
      classToPlain(part, {enableCircularCheck: true}),
      this.httpUtils.getHTTPHeaders(),
      {id: routeId});
  }


  public receiveMaster(part: OrderPart): Observable<any> {
    return this.httpRestService.put(`${this.apiUrl}receive-master`,
      classToPlain(part, {enableCircularCheck: true}),
      this.httpUtils.getHTTPHeaders());
  }


}
