import {FilterSearchColumn} from './filter.search.column';
import {SearchSortRequest} from './search.sort.request';
import {TabFilter} from '../../../pages/masters/masters-list/orders-table/orders-table.component';
import {isNullOrUndefined} from 'util';
import {Type} from "class-transformer";
import {assigned, isEmpty} from "../../../_helpers/utils";
import {SearchRange} from "./search.range";


export class FilterPageRequest {
  pageNumber: number;
  pageSize: number;
  findByOccurs: string;
  sort: SearchSortRequest;
  operation: 'and' | 'or' = 'and';
  namedConditions?: string[];      // additional condition names to be handled in Specification on BE
  modesFilter?: string[];
  statusFilters?: TabFilter[];

  @Type(() => FilterSearchColumn)
  filterByColumns: FilterSearchColumn[] = [];

  public static simpleFilters(): FilterPageRequest {
    return new FilterPageRequest(0, 100, '', new SearchSortRequest(''), []);
  }

  constructor(pageNumber: number, pageSize: number, findByOccurs: string, sort: SearchSortRequest, filterByColumns: FilterSearchColumn[]) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.findByOccurs = findByOccurs;
    this.filterByColumns = filterByColumns;
    this.sort = sort;
  }

  public setSort(sort: SearchSortRequest): FilterPageRequest {
    this.sort = sort;
    return this;
  }

  public addNamedCondition(conditionName: string): FilterPageRequest {
    if (!this.namedConditions) {
      this.namedConditions = [];
    }

    if (!!conditionName) {
      this.removeNamedCondition(conditionName.split('|')[0]);
      this.namedConditions.push(conditionName);
    }
    return this;
  }

  public clearNamedConditions() {
      this.namedConditions = [];
  }

  public removeNamedCondition(conditionName: string) {
    if (this.namedConditions) {
      // Remove all conditions starting with Name as it might be formatted like `CONDITION_NAME|CONDITION_PARAMETERS`
      this.namedConditions.removeAll((s) => s && s.startsWith(conditionName));
    }
  }

  public removeNamedConditions(...conditionName: string[]) {
    if (conditionName && conditionName.length) {
      conditionName.forEach((c) => this.removeNamedCondition(c));
    }
  }


  public hasNamedCondition(conditionName: string): boolean {
    return this.namedConditions && assigned(this.namedConditions.find((s) => s && s.startsWith(conditionName)));
  }

  public getNamedCondition(conditionName: string): string {
    return this.namedConditions && this.namedConditions.find((s) => s && s.startsWith(conditionName));
  }


  public anyNamedCondition(conditionNames: string[]): string {
    return this.namedConditions && this.namedConditions.find((s) => conditionNames.hasEquals((c) => c && c.startsWith(s)));
  }


  public getColumnFilter(field: string, canCreate?: boolean): FilterSearchColumn {
    let filter: FilterSearchColumn = this.filterByColumns.find((item) => item.field === field);
    if (!filter && canCreate) {
      filter = new FilterSearchColumn(field);
      this.filterByColumns.push(filter);
    }

    return filter;
  }

  public getColumnSearchMultipleValues(field: string): string[] {
    let filter: FilterSearchColumn = this.getColumnFilter(field);
    return filter ? filter.multipleValues : undefined;
  }

  public setColumnSearchMultipleValues(field: string, values: string[]) {
    if (isEmpty(values)) {
      this.removeColumnFilter(field);
    } else {
      let filter: FilterSearchColumn = this.getColumnFilter(field, true);
      filter.multipleValues = values;

    }
  }

  public addColumnFilter(field: string, value: any): FilterSearchColumn {
    let filter: FilterSearchColumn = this.getColumnFilter(field, true);
    if (Array.isArray(value)) {
      filter.value = undefined;
      filter.multipleValues = value;
    } else {
      filter.value = value;
      filter.multipleValues = undefined;
    }
    return filter;
  }

  public removeColumnFilter(field: string, value?: any) {
    let filter: FilterSearchColumn = this.filterByColumns.find((item) => item.field === field);

    if (filter) {
      if (isNullOrUndefined(value) || filter.value === value) {
        this.filterByColumns.removeAll(filter);
      } else {
        filter.multipleValues.removeAll(value);
        if (filter.multipleValues.length === 0) {
          this.filterByColumns.removeAll(filter);
        }
      }
    }
  }

  public setColumnFilter(field: string, value: string | string[] | SearchRange) {
    if (!value || !value['length']) {
      this.filterByColumns.removeAll((f) => f.field === field);
    } else {
      let filter: FilterSearchColumn = this.getColumnFilter(field, true);

      if (value instanceof SearchRange) {
        filter.range = value;
        filter.multipleValues = undefined;
        filter.value = undefined;
      } else if (value['length']) {
        filter.range = undefined;
        filter.multipleValues = <string[]> value;
        filter.value = undefined;
      } else {
        filter.range = undefined;
        filter.multipleValues = undefined;
        filter.value = <string> value;
      }
    }
  }

  public replaceColumns(columns: FilterSearchColumn[]): void {
    columns.forEach(column => this.replaceColumn(column));
  }

  public replaceColumn(column: FilterSearchColumn): void {
    let allColumns = this.filterByColumns.filter(c => c.field !== column.field);
    allColumns.push(column);
    this.filterByColumns = allColumns;
  }
}
