import {Labeled} from '../../../common/oms-types';
import {BaseDeletable} from './base-deletable';
import {FreightForwarder} from "./freight.forwarder";

export class FreightForwarderEmail extends BaseDeletable implements Labeled {
  email: string;
  freightForwarders: FreightForwarder;
  active: boolean;
  primary: boolean;

  constructor() {
    super();
    this.active = true;
    this.primary = true;
  }

  get label() {
    return this.email;
  }
}
