import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'oms-spinner',
  templateUrl: './oms-spinner.component.html',
  styleUrls: ['./oms-spinner.component.scss']
})
export class OmsSpinnerComponent implements OnChanges {
  @Input('time-out') timeOut: number = 300;
  @Input() name: string = Math.random() + "";
  @Input('message') message: string = 'Loading...';
  @Input() show: boolean = false;
  @Input() fullScreen: boolean = false;
  @ViewChild(NgxSpinnerComponent) spinner: NgxSpinnerComponent;

  constructor(public spinnerService: NgxSpinnerService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show) {
      if (changes.show.currentValue) {
        setTimeout(() => { if (this.show) { this.spinnerService.show(this.name).then(); } }, this.timeOut);
      } else {
        this.spinnerService.hide(this.name).then();
      }
    }
  }

}
