<div dropdown #dropdown="bs-dropdown" container="body" (isOpenChange)="onOpenChange();" (onHidden)="onClose(false)" [dropup]="isDropUp" [isOpen]="showPopup">
  <ul #container class="widget datetime-popup-range dropdown-menu" *dropdownMenu role="menu" (offClick)="onClose(false)" style="z-index: 10000"  [ngStyle]="popupStyles">

    <!--li class="widget-header" *ngIf="estimation">
      <h3><i class="fa fa-calendar"></i>Date</h3>
      <ng-content select="popup-header"></ng-content>

      <ul class="nav nav-tabs pull-right">
        <li [ngClass]="{'active' : activeTab === 'estimated'}"><a (click)="activeTab = 'estimated'">Estimated</a></li>
        <li [ngClass]="{'active' : activeTab === 'actual'}"><a (click)="activeTab = 'actual'">Actual</a></li>
      </ul>
    </li-->

    <li class="widget-content">
      <div class="datepicker-colums">
        <label>From</label>
        <datepicker *ngIf="showDate"
                    [ngModel]="fromDate"
                    (ngModelChange)="onPickerFromDateChange($event, false)"
                    [showWeeks]="showWeeks"
                    [datepickerMode]="datepickerMode"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [dateDisabled]="dateDisabled"></datepicker>
      </div>
      <div class="datepicker-colums">
        <label>To</label>
        <datepicker *ngIf="showDate"
                    [ngModel]="toDate"
                    (ngModelChange)="onPickerToDateChange($event, false)"
                    [showWeeks]="showWeeks"
                    [datepickerMode]="datepickerMode"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [dateDisabled]="dateDisabled"></datepicker>
      </div>
    </li>
    <li class="widget-footer" >
      <span class="btn-group pull-left">
          <button type="button"
                  (click)="onClear();onClose(true);"
                  *ngIf="clearButton && clearButton.show"
                  [ngClass]="clearButton.cssClass">{{ clearButton.label }}</button>

          <button type="button"
                  (click)="onCustomButtonClick($event);onClose(true);"
                  *ngIf="customButton && customButton.show"
                  [ngClass]="customButton.cssClass">{{customButton.label}}</button>
      </span>

      <span [class]="'fancy-checkbox custom-bgcolor-green'" (click)="est=!est;" style="margin: auto 0;">
          <input type="checkbox" [ngModel]="est" class="table-checkbox" title="" >
          <span>EST</span>
      </span>

      <span class="btn-group pull-right" style="display: flex;">
        <button #btnClose type="button"
                autofocus
                (click)="onClose(true)"
                *ngIf="closeButton && closeButton.show"
                [ngClass]="closeButton.cssClass">{{ closeButton.label }}</button>
      </span>
    </li>
  </ul>
</div>
