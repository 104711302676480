<oms-base-page [showHeader]="false">
  <ng-container page-body>
    <div class="wrapper full-page-wrapper page-error text-center">
      <div class="inner-page">
        <h1>
				<span class="clearfix title">
					<span class="number">403</span> <span class="text">Oops! <br/>Access Denied/Forbidden</span>
				</span>
        </h1>
        <p>The page you're trying to access has restricted access, please <a href="#">contact us</a> to report this issue.</p>
        <p>You can also use search form below to find the page you are looking for.</p>
        <div>
          <a href="javascript:history.go(-1)" class="btn btn-custom-primary"><i class="fa fa-arrow-left"></i> Go Back</a>
          &nbsp;
          <a href="#" class="btn btn-primary"><i class="fa fa-home"></i> Home</a>
        </div>
      </div>
    </div>
  </ng-container>
</oms-base-page>
