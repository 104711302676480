import {BaseEntity} from "../base-entity";

export class LogChanges extends BaseEntity {
  objectId: number;
  systemUserName: string;
  createdAt: Date;
  tableName: string;
  field: string;
  valueType: string;
  oldObjectValue: any;
  newObjectValue: any;
}
