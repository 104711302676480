<oms-base-dialog  [height]="'85vh'" [width]="'85vw'" [no-padding]="true"
      [btnCancel]="getButtonCancel()" [btnOk]="undefined" (cancel)="onCancel($event)"
      [loading]="docCenter.viewLoading" [show-footer]="true" icon="fa fa-file" [title]="docCenter.getPageLabel()"
      [flex-content]="true"
      [store-position]="false" [draggable]="false" [sizable]="true">

  <ng-container widget-header>
    <ul class="nav nav-pills nav-pills-sm">
      <li [ngClass]="{'active': docCenter.activeTab === 'documents'}"><a (click)="docCenter.changeTab('documents')"><i class="fa fa-file"></i>Documents</a></li>
      <li *ngIf="showHistory" [ngClass]="{'active': docCenter.activeTab === 'history'}"><a (click)="docCenter.changeTab('history')"><i class="fa fa-clock"></i>History</a></li>
    </ul>
  </ng-container>

  <oms-doc-center #docCenter [isVisibleHistory]="showHistory"
                  [showHeader]="false"
                  [objectType]="config.objectType"
                  [docTypes]="config.docTypes"
                  [id]="config.id"
                  [ffg]="config.ffg"
                  [latestActive]="config.latestActive"
                  [readonly]="config.readonly" >
  </oms-doc-center>

</oms-base-dialog>
