var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Master, MasterShipmentAir, MasterStatusId, transformMasterStatusId } from '../index';
import { Shipment } from '../shipment';
import { Type, Transform } from 'class-transformer';
import { addHours } from '../../../../common/oms-date-time.utils';
import { isNullOrUndefined } from 'util';
import { BaseEntity } from '../base-entity';
var ɵ0 = function () { return Master; }, ɵ1 = function () { return Shipment; }, ɵ2 = function () { return MasterShipmentAir; }, ɵ3 = function () { return Date; }, ɵ4 = function () { return Date; }, ɵ5 = function (v, o, tt) { return transformMasterStatusId(v, o, tt); };
var MasterShipment = /** @class */ (function (_super) {
    __extends(MasterShipment, _super);
    function MasterShipment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rowId = 'MasterShipment';
        _this.javaClassName = 'com.oms.entity.master.MasterShipment';
        _this.status = MasterStatusId.NEW;
        return _this;
    }
    Object.defineProperty(MasterShipment.prototype, "flightNumber", {
        get: function () {
            return this.masterShipmentAir ? this.masterShipmentAir.flightNumber : undefined;
        },
        set: function (value) {
            if (this.masterShipmentAir)
                this.masterShipmentAir.flightNumber = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "airline", {
        get: function () {
            return this.masterShipmentAir ? this.masterShipmentAir.airline : undefined;
        },
        set: function (value) {
            if (this.masterShipmentAir)
                this.masterShipmentAir.airline = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "airport", {
        get: function () {
            return this.masterShipmentAir ? this.masterShipmentAir.airport : undefined;
        },
        set: function (value) {
            if (this.masterShipmentAir)
                this.masterShipmentAir.airport = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "splitNumber", {
        get: function () {
            return this.master.masterShipments.indexOf(this) + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "hu", {
        get: function () {
            return this.shipment ? this.shipment.hu : null;
        },
        set: function (value) {
            if (this.shipment)
                this.shipment.hu = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "pieces", {
        get: function () {
            return this.shipment ? this.shipment.pieces : null;
        },
        set: function (value) {
            if (this.shipment)
                this.shipment.pieces = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "weight", {
        get: function () {
            return this.shipment ? this.shipment.weight : null;
        },
        set: function (value) {
            if (this.shipment)
                this.shipment.weight = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "volume", {
        get: function () {
            return this.shipment ? this.shipment.volume : null;
        },
        set: function (value) {
            if (this.shipment)
                this.shipment.volume = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "dateArrivalAct", {
        get: function () {
            return this.shipment ? this.shipment.dateDeliveryActual : null;
        },
        set: function (date) {
            if (this.shipment)
                this.shipment.dateDeliveryActual = date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterShipment.prototype, "dateArrivalEst", {
        get: function () {
            return this.shipment ? this.shipment.dateDeliveryExpectation : null;
        },
        set: function (date) {
            if (this.shipment)
                this.shipment.dateDeliveryExpectation = date;
        },
        enumerable: true,
        configurable: true
    });
    MasterShipment.prototype.updateLastFreeDay = function () {
        var date = this.dateArrivalAct;
        if (isNullOrUndefined(date)) {
            date = this.dateArrivalEst;
        }
        this.dateLastFreeDay = addHours(date, 24);
        return this.dateLastFreeDay;
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Master)
    ], MasterShipment.prototype, "master", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Shipment)
    ], MasterShipment.prototype, "shipment", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", MasterShipmentAir)
    ], MasterShipment.prototype, "masterShipmentAir", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Date)
    ], MasterShipment.prototype, "dateLastFreeDay", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", Date)
    ], MasterShipment.prototype, "date1F", void 0);
    __decorate([
        Transform(ɵ5),
        __metadata("design:type", Number)
    ], MasterShipment.prototype, "status", void 0);
    return MasterShipment;
}(BaseEntity));
export { MasterShipment };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
