var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicInjectorType } from "../dynamic-injector/dynamic-injector-type";
import { isNullOrUndefined } from "util";
import { OmsConstants } from "../../../../../common/oms-constants.service";
import { MasterStatus } from "../../../models";
import { convertOrderNumber } from "../../../services/oms-converters.service";
import { DateTimeService } from "../../../../../services/date-time.service";
import { OrderNotificationService } from "../../../../../services/order-notification/order-notification.service";
import { OmsAlertsService } from "../oms-alerts.service";
import { switchMap } from "rxjs/operators";
var OrderNotificationAlertComponent = /** @class */ (function (_super) {
    __extends(OrderNotificationAlertComponent, _super);
    function OrderNotificationAlertComponent(cdr, orderNotificationService, dateTimeService, alertsService) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this.orderNotificationService = orderNotificationService;
        _this.dateTimeService = dateTimeService;
        _this.alertsService = alertsService;
        _this.orderLink = "";
        _this.firstLine = "";
        _this.secondLine = "";
        _this.thirdLine = "";
        _this.cbpStatus = null;
        _this.isEdit = false;
        _this.comment = "";
        _this.dragPosition = { x: 0, y: 0 };
        return _this;
    }
    OrderNotificationAlertComponent.prototype.ngOnInit = function () {
        if (this.data) {
            this.updateMessages(this.data);
        }
    };
    OrderNotificationAlertComponent.prototype.ngOnChanges = function (changes) {
        if (changes.data) {
            this.updateMessages(this.data);
        }
    };
    OrderNotificationAlertComponent.prototype.updateMessages = function (orderNotification) {
        var order = orderNotification.order;
        var ref = order.customerRef ? "REF:" + order.customerRef + " " : "";
        var customer = order.customerName ? "CUST:" + order.customerName + " " : "";
        var pcs = isNullOrUndefined(order.pieces) ? "" : "PCS:" + order.pieces;
        var dateCreated = this.dateTimeService.utcToNYFormat(orderNotification.dateCreated, OmsConstants.LONG_DATE_TIME_FORMAT);
        var status = MasterStatus.getLabel(orderNotification.status.id).substr(0, 30);
        this.orderLink = convertOrderNumber(order.id);
        this.firstLine = ref + customer + pcs;
        this.secondLine = (orderNotification.comment || order.customerRef || "name");
        this.thirdLine = status + " " + dateCreated;
        this.comment = orderNotification.comment;
        if (orderNotification.cbpStatus) {
            var cbpStatus = orderNotification.cbpStatus.uscsFsnStatus;
            var date = this.dateTimeService.utcToNYFormat(orderNotification.cbpStatus.fsnSentDate, OmsConstants.LONG_DATE_TIME_FORMAT);
            this.cbpStatus = "Received CBP status " + cbpStatus + " " + date;
        }
    };
    OrderNotificationAlertComponent.prototype.onBlurEditor = function () {
        var _this = this;
        this.isEdit = false;
        this.orderNotificationService.addComment(this.data.id, this.comment)
            .pipe(switchMap(function () {
            _this.data.comment = _this.comment;
            _this.updateMessages(_this.data);
            _this.cdr.markForCheck();
            return _this.orderNotificationService.getUnreadOrderNotifications();
        }))
            .subscribe(function (orderNotifications) {
            _this.displayOrderNotifications(orderNotifications);
            _this.cdr.markForCheck();
        });
    };
    OrderNotificationAlertComponent.prototype.onKeyDown = function (event) {
        if (event.key === 'Enter') {
            this.onBlurEditor();
            event.preventDefault();
        }
        else if (event.key === 'Escape') {
            this.comment = this.data.comment;
            this.isEdit = false;
            event.preventDefault();
        }
    };
    OrderNotificationAlertComponent.prototype.onMessageClosed = function () {
        this.close.emit();
    };
    OrderNotificationAlertComponent.prototype.dragEnded = function (event) {
        var width = event.source.element.nativeElement.offsetWidth;
        if (width / 3 < Math.abs(event.distance.x)) {
            this.onMoveToEnd();
        }
        this.resetPosition();
    };
    OrderNotificationAlertComponent.prototype.onMoveToEnd = function () {
        var _this = this;
        this.orderNotificationService.moveToEnd(this.data.id)
            .subscribe(function (orderNotifications) {
            _this.displayOrderNotifications(orderNotifications);
            _this.cdr.detectChanges();
        });
    };
    OrderNotificationAlertComponent.prototype.displayOrderNotifications = function (orderNotifications) {
        var _this = this;
        this.alertsService.setMessages(orderNotifications.map(function (on) { return ({
            componentType: OrderNotificationAlertComponent,
            componentData: on,
            onClose: function () {
                _this.orderNotificationService.readOrderNotification(on.id).subscribe();
            }
        }); }));
    };
    OrderNotificationAlertComponent.prototype.resetPosition = function () {
        this.dragPosition = { x: 0, y: 0 };
    };
    return OrderNotificationAlertComponent;
}(DynamicInjectorType));
export { OrderNotificationAlertComponent };
