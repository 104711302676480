var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from '../../../services/base/base-entity.service';
import { RestService } from '../../../services/rest.service';
import { HttpUtilsService } from '../../../services/http-utils.service';
import { Carrier } from '../models/carrier';
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import * as i0 from "@angular/core";
import * as i1 from "../../../services/rest.service";
import * as i2 from "../../../services/http-utils.service";
var CarrierService = /** @class */ (function (_super) {
    __extends(CarrierService, _super);
    function CarrierService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/api/carriers/';
        _this.classType = Carrier;
        return _this;
    }
    CarrierService.prototype.getDefaultCarrier = function () {
        var _this = this;
        return this.httpRestService.getWithHeader(this.apiUrl + 'getDefaultCarrier', this.httpUtils.getHTTPHeaders())
            .pipe(map(function (item) { return plainToClass(_this.classType, item); }));
    };
    CarrierService.prototype.getOrderCarrier = function (orderId) {
        var _this = this;
        return this.httpRestService.getWithHeader(this.apiUrl + ("getOrderCarrier/" + orderId), this.httpUtils.getHTTPHeaders())
            .pipe(map(function (item) { return plainToClass(_this.classType, item); }));
    };
    CarrierService.prototype.getMasterCarrier = function (masterId) {
        var _this = this;
        return this.httpRestService.getWithHeader(this.apiUrl + ("getMasterCarrier/" + masterId), this.httpUtils.getHTTPHeaders())
            .pipe(map(function (item) { return plainToClass(_this.classType, item); }));
    };
    CarrierService.ngInjectableDef = i0.defineInjectable({ factory: function CarrierService_Factory() { return new CarrierService(i0.inject(i1.RestService), i0.inject(i2.HttpUtilsService)); }, token: CarrierService, providedIn: "root" });
    return CarrierService;
}(BaseEntityService));
export { CarrierService };
