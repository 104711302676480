<ng-template [ngIf]="!editing" *ngIf="!isHidden">
    <span [tooltip]="tooltip" container="body" class="no-wrap data-table-cell"
          [ngClass]="{'editable-empty':editable && empty}" [class]="cellClass">
    <i [attr.class]="iconClass" *ngIf="hasIcon"></i>
    <span [innerHTML]="value | highlight : search : rawValue"></span>
  </span>
  <div *ngFor="let load of displayLoads; let last = last;"
       class="driver-routes-loads-table-cell__status-item"
       #tooltip="bs-tooltip"
       [tooltip]="getTooltipForLoad(load)"
       (contextmenu)="onContextMenu($event, load)"
       container="body">
    <span class="label driver-routes-loads-table-cell__status-item-cell"
          [ngClass]="{'completed': load.completedCount, 'not-completed': load.notCompletedCount, 'completed-with-problem': load.completedWithProblemCount}"
          [popover]="popoverContent"
          #pop="bs-popover"
          (click)="tooltip.hide();onLeftClick(load)"
          [outsideClick]="true"
          containerClass="popover-table"
          container="body">
        {{getFormatTime(load.dateDeliveryAct)}}
    </span>

    <ng-template #popoverContent>
      <table *ngIf="manifestItem" class="table table-bordered table-order">
        <tr>
          <th>J#</th>
          <th>FF</th>
          <th>Cust</th>
          <th>Ref1</th>
          <th>Ref2</th>
          <th>Ref3</th>
          <th>Hu</th>
          <th>PCS</th>
          <th>KG</th>
          <th>From</th>
          <th>To</th>
          <th>Docs</th>
          <th>Com</th>
        </tr>
        <tr *ngFor="let order of manifestItem.orders">
          <td>
            <a [routerLink]="['/home/order', {id:order.id}]"
               class="data-table-cell"
               target="_blank">
              {{convertOrderNumber(order.id, order.isRecovery)}}
            </a>
          </td>
          <td>{{order.freightForwarder?.name}}</td>
          <td>{{order.customerName}}</td>
          <td>{{order.customerRef}}</td>
          <td>{{order.hawb}}</td>
          <td>{{convertMawb(order.ref3)}}</td>
          <td>{{order.hu}}</td>
          <td>{{order.pieces}}</td>
          <td>{{order.weight | number:'1.0-1'}}</td>
          <td>{{getDisplayAddressName(manifestItem.addressPickUp)}}</td>
          <td>{{getDisplayAddressName(manifestItem.addressDelivery)}}</td>
          <td>
            <a class="data-table-cell"
               (click)="pop.hide();openDocuments(order)">{{order.documents}}
            </a>
          </td>
          <td>
            <a class="data-table-cell"
               (click)="pop.hide();openComCenter(order)">{{order.defaultNotes}}
            </a>
          </td>
        </tr>
      </table>
    </ng-template>
  </div>

</ng-template>

<manifest-item-context-menu #menu [manifest]="manifest"
                            [manifestItem]="manifestItem"
                            [isVisibleSelect]="false"
                            (manifestChange)="onManifestChange($event)"
                            (loadingChange)="onLoading($event)"
                            (refreshChange)="onRefresh()">
</manifest-item-context-menu>
