var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { toFixed } from '../../../../_helpers/utils';
import { isNullOrUndefined } from 'util';
import { convertDateTime, convertRecoveryOrderNumber } from "../../services/oms-converters.service";
import { Exclude } from "class-transformer";
var RecoveryOrder = /** @class */ (function () {
    function RecoveryOrder(master, order) {
        this.master = master;
        this.order = order;
        this.rowId = 'Split';
        this.uldsRecovered = [];
        this.label = convertRecoveryOrderNumber(this.order && this.order.id);
        this.shipment = order && order.shipmentContents && order.shipmentContents.length ? order.shipmentContents[0].shipment : null;
        this.load = this.shipment && this.shipment.load;
        this.uldsRecovered = order && order.uldsRecovered;
    }
    Object.defineProperty(RecoveryOrder.prototype, "documents", {
        get: function () { return this.order.documents; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "overageNotes", {
        get: function () { return this.order.overageNotes; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "shortageNotes", {
        get: function () { return this.order.shortageNotes; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "damageNotes", {
        get: function () { return this.order.damageNotes; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "hazardousNotes", {
        get: function () { return this.order.hazardousNotes; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "defaultNotes", {
        get: function () { return this.order.defaultNotes; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "podDocuments", {
        get: function () { return this.order.podDocuments; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "isOSD", {
        get: function () { return this.order.isOSD; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "isVirtual", {
        get: function () {
            return isNullOrUndefined(this.shipment);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "isRecovery", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "isRecovered", {
        get: function () {
            return !this.isVirtual && this.shipment.isDelivered;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "isPickedUp", {
        get: function () {
            return !this.isVirtual && this.shipment.isPickedUp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "isDispatched", {
        get: function () {
            return !this.isVirtual && this.shipment.isDispatched;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "isStaging", {
        get: function () {
            return !this.isVirtual && this.shipment.isStaging;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "loadId", {
        get: function () {
            return this.load ? this.load.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "masterId", {
        get: function () {
            return this.master.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "orderId", {
        get: function () {
            return this.order ? this.order.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "number", {
        get: function () {
            return convertRecoveryOrderNumber(this.orderId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "driver", {
        get: function () {
            return this.load ? this.load.driver : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "truck", {
        get: function () {
            return this.load ? this.load.truck : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "trailer", {
        get: function () {
            return this.load ? this.load.trailer : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "dateDispatched", {
        get: function () {
            return this.shipment && this.shipment.dispatchedForRecovery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "dispatchedByUser", {
        get: function () {
            return this.shipment && this.shipment.dispatchedByUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "pieces", {
        get: function () {
            return this.order.pieces;
        },
        set: function (value) {
            this.order.pieces = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "hu", {
        get: function () {
            return this.order.hu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "dateDeliveryAct", {
        get: function () {
            return this.shipment ? this.shipment.dateDeliveryActual : null;
        },
        set: function (value) {
            if (this.shipment) {
                this.shipment.dateDeliveryActual = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "pcsReceived", {
        // Sum of Received at CFS per HAWB
        get: function () {
            return this.order && this.order.pcsReceived;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "pcsDispatched", {
        get: function () {
            return this.shipment && this.shipment.pieces;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "weightReceived", {
        // Sum of Received at CFS per HAWB
        get: function () {
            return 0; // toFixed(this.contents.aggregate((acc, sc) => acc + +sc.weight, 0), 2) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "huReceived", {
        get: function () {
            return 0; // this.contents.aggregate((acc, sc) => acc + +sc.hu, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "huToRecover", {
        get: function () {
            return this.order && this.order.hu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "pcsToRecover", {
        get: function () {
            return this.order && this.order.pieces;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "cargoBuilding", {
        get: function () {
            return this.master.cargoBuilding;
        },
        set: function (address) {
            this.master.cargoBuilding = address;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "addressDelivery", {
        get: function () {
            return this.isVirtual ? this.master.addressCfs : this.shipment.addressDelivery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "addressPickup", {
        get: function () {
            return this.isVirtual ? this.master.cargoBuilding : this.shipment.addressPickUp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "airline", {
        get: function () {
            return this.master.airline;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "flightNumber", {
        get: function () {
            return this.master.flightNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "datePickupAct", {
        get: function () {
            return this.isVirtual ? null : this.shipment.datePickUpActual;
        },
        set: function (value) {
            if (!this.isVirtual) {
                this.shipment.datePickUpActual = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "datePickupEst", {
        get: function () {
            return this.isVirtual ? null : this.shipment.datePickUpExpectation;
        },
        set: function (value) {
            if (!this.isVirtual) {
                this.shipment.datePickUpExpectation = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "dispatchedOn", {
        get: function () {
            return this.load && this.load.driver ? this.load.driver.fullName : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "dispatchedTooltip", {
        get: function () {
            return this.load && this.load.driver ? this.dispatchedTooltipBase + "\nDispatched On: " + this.load.driver.fullName : this.dispatchedTooltipBase;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecoveryOrder.prototype, "dispatchedTooltipBase", {
        get: function () {
            return this.shipment ?
                ((isNullOrUndefined(this.shipment.dispatchedByUser) ? "" : "Dispatched By: " + this.shipment.dispatchedByUser.fullName + "\n") +
                    (isNullOrUndefined(this.shipment.dispatchedForRecovery) ? "" : "Dispatched At: " + convertDateTime(this.shipment.dispatchedForRecovery)))
                : "";
        },
        enumerable: true,
        configurable: true
    });
    RecoveryOrder.prototype.hasProblem = function () {
        return this.shipment && this.shipment.hasProblem;
    };
    RecoveryOrder.prototype.isCompleted = function () {
        return !this.isVirtual && this.shipment.isPickedUp && !this.shipment.hasProblem;
    };
    RecoveryOrder.prototype.isCompletedWithProblem = function () {
        return !this.isVirtual && this.shipment.isPickedUp && this.shipment.hasProblem;
    };
    RecoveryOrder.prototype.isNotCompleted = function () {
        return this.shipment && !this.shipment.isPickedUp && this.shipment.hasProblem;
    };
    RecoveryOrder.prototype.isConfirmed = function () {
        return this.shipment && !isNullOrUndefined(this.shipment.dateConfirmedOn);
    };
    RecoveryOrder.prototype.getRouteStatus = function () {
        //    return this.shipment ? this.shipment.dateConfirmedOn+"" : 'aaa';
        if (this.isCompleted()) {
            return 'Completed';
        }
        else if (this.isCompletedWithProblem()) {
            return 'Completed with problem';
        }
        else if (this.isNotCompleted()) {
            return 'Not Completed';
        }
        else if (this.isConfirmed()) {
            return 'Confirmed';
        }
        else if (this.isStaging) {
            return 'Staging';
        }
        else if (this.isDispatched) {
            return 'Dispatched';
        }
        else {
            return "Pending";
        }
    };
    RecoveryOrder.prototype.getRouteStatusClass = function () {
        switch (this.getRouteStatus()) {
            case 'Completed':
                return 'success';
            case 'Not Completed':
                return 'danger';
            case 'Dispatched':
                return 'load-dispatched';
        }
    };
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], RecoveryOrder.prototype, "isRecovery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], RecoveryOrder.prototype, "isRecovered", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], RecoveryOrder.prototype, "isPickedUp", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], RecoveryOrder.prototype, "isDispatched", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], RecoveryOrder.prototype, "isStaging", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", String)
    ], RecoveryOrder.prototype, "getRouteStatusClass", null);
    return RecoveryOrder;
}());
export { RecoveryOrder };
var Split = /** @class */ (function (_super) {
    __extends(Split, _super);
    function Split(master, splitNumber) {
        var _this = _super.call(this, master, null) || this;
        _this.rowId = 'Split';
        _this.contents = [];
        _this.master = master;
        _this.splitNumber = splitNumber;
        //    let mawb = this.master.mawbNumber ? this.master.mawbNumber.slice(7, 11) : '';
        _this.label = 'RLD-' + _this.splitNumber;
        return _this;
    }
    Object.defineProperty(Split.prototype, "rowClass", {
        get: function () {
            return this.isVirtual ? 'virtual' : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "dateUpdated", {
        get: function () {
            return this.master.dateUpdated;
        },
        enumerable: true,
        configurable: true
    });
    Split.prototype.isNew = function () {
        return this.load && this.load.isNew();
    };
    Object.defineProperty(Split.prototype, "mawbNumber", {
        get: function () {
            return this.master.mawbNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "customerNameBasedOnOrders", {
        get: function () {
            return this.master.customerNameBasedOnOrders;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "hu", {
        get: function () {
            return this.isVirtual ? this.huEstimated : this.shipment.hu;
        },
        set: function (value) {
            this.huEstimated = value;
            if (this.shipment) {
                this.shipment.hu = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "pieces", {
        get: function () {
            return this.isVirtual ? this.pcsEstimated : this.shipment.pieces;
        },
        set: function (value) {
            this.pcsEstimated = value;
            if (this.shipment) {
                this.shipment.pieces = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "pcsReceived", {
        // Sum of Received at CFS per HAWB
        get: function () {
            return this.contents.aggregate(function (acc, sc) { return acc + ~~sc.pieces; }, 0) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "pcsDispatched", {
        get: function () {
            return this.isDispatched ? this.shipment.pieces : 0;
            //    return this.contents.aggregate((acc, sc)=>acc + (sc.shipment.datePickUpExpectation ?  ~~sc.shipment.pieces : 0), 0) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "weightReceived", {
        // Sum of Received at CFS per HAWB
        get: function () {
            return toFixed(this.contents.aggregate(function (acc, sc) { return acc + +sc.weight; }, 0), 2) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "huReceived", {
        get: function () {
            return toFixed(this.contents.aggregate(function (acc, sc) { return acc + +sc.hu; }, 0), 2) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "pcsToRecover", {
        get: function () {
            return this.pcsReceived || this.pieces;
        },
        set: function (value) {
            this.pieces = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "huToRecover", {
        get: function () {
            return this.huReceived || this.hu;
        },
        set: function (value) {
            this.hu = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "volume", {
        get: function () {
            return this.shipment ? this.shipment.volume : null;
        },
        set: function (value) {
            this.shipment.volume = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "weight", {
        get: function () {
            return this.shipment ? this.shipment.weight : null;
        },
        set: function (value) {
            this.shipment.weight = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "dispOrRcvdTimestamps", {
        get: function () {
            return this.isVirtual || isNullOrUndefined(this.shipment) ? null : (this.isPickedUp ? "CMPL: " : "DISP: ") + convertDateTime(this.shipment.dispatchedForRecovery);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "addressDelivery", {
        get: function () {
            return this.isVirtual ? null : this.shipment.addressDelivery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "addressPickup", {
        get: function () {
            return this.isVirtual ? null : this.shipment.addressPickUp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "isAssigned", {
        get: function () {
            return !this.isVirtual && this.pcsToRecover > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "noCfs", {
        get: function () {
            return this.master && this.master.noCfs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "dateArrivalAct", {
        get: function () {
            return this.isVirtual ? null : this.master.dateArrivalAct;
        },
        set: function (value) {
            if (!this.isVirtual) {
                this.master.dateArrivalAct = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Split.prototype, "dateArrivalEst", {
        get: function () {
            return this.isVirtual ? null : this.master.dateArrivalEst;
        },
        set: function (value) {
            if (!this.isVirtual) {
                this.master.dateArrivalEst = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    return Split;
}(RecoveryOrder));
export { Split };
