<oms-base-dialog [readonly]="readonly" [title]="'CFS Location'" [icon]="'fa fa-industry'" (ok)="onOk($event)">

    <div class="row-widget">
      <oms-string-input [label]="'Location Name:'" [(ngModel)]="cfsLocation.name" required maxlength="30" ></oms-string-input>
    </div>
    <div class="row-widget">
      <oms-checkbox [label]="'AMS'" [(checked)]="cfsLocation.isAms"></oms-checkbox>
    </div>
    <!--div class="row-widget">
      <oms-string-input [label]="'Last Name:'" formControlName="lastName" required maxlength="50"></oms-string-input>
    </div>
    <div class="row-widget">
      <oms-string-input [label]="'Email:'" formControlName="email" maxlength="80"></oms-string-input>
    </div-->
</oms-base-dialog>
