var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { AbstractComponent } from "../../../../../common/component/abstract.component";
var FilterItemsComponent = /** @class */ (function (_super) {
    __extends(FilterItemsComponent, _super);
    function FilterItemsComponent() {
        var _this = _super.call(this) || this;
        _this.items = [];
        _this.selected = [];
        _this.maxDisplayItems = 10;
        _this.multiple = true;
        _this.delay = 1500;
        _this.change = new EventEmitter();
        _this.selectedChange = new EventEmitter();
        _this.startVisibleItem = 0;
        return _this;
    }
    FilterItemsComponent.prototype.clickByItem = function (item) {
        this.change.emit(item);
        if (this.multiple) {
            if (this.isActive(item)) {
                this.selected = this.selected.filter(function (s) { return s !== item; });
            }
            else {
                this.selected.push(item);
            }
        }
        else if (this.isActive(item)) {
            this.selected = [];
        }
        else {
            this.selected = [item];
        }
        this.selectedEmit();
    };
    FilterItemsComponent.prototype.selectedEmit = function () {
        var _this = this;
        if (!this.delay || this.delay < 0) {
            this.selectedChange.emit(this.selected);
            return;
        }
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(function () {
            _this.selectedChange.emit(_this.selected);
        }, 1500);
    };
    FilterItemsComponent.prototype.isActive = function (item) {
        return this.selected.some(function (s) { return s === item || s.object === item.object; });
    };
    FilterItemsComponent.prototype.isDisabled = function (item) {
        return this.getBoolValue(item, item.disabled, false);
    };
    FilterItemsComponent.prototype.getBoolValue = function (item, field, defaultValue) {
        if (typeof field === 'boolean') {
            return field;
        }
        else if (typeof field === 'function') {
            return field(item, this.items);
        }
        return defaultValue;
    };
    FilterItemsComponent.prototype.prevStep = function () {
        this.startVisibleItem = Math.max(0, this.startVisibleItem - 1);
    };
    FilterItemsComponent.prototype.nextStep = function () {
        this.startVisibleItem = Math.min(this.items.length - this.maxDisplayItems, this.startVisibleItem + 1);
    };
    return FilterItemsComponent;
}(AbstractComponent));
export { FilterItemsComponent };
