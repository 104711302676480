import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  IterableDiffer,
  IterableDiffers,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {AccountService} from '../../../../../services';
import {DateTimePopupComponent} from '../../common/input/date-time-popup/date-time-popup.component';
import {forkJoin, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {BaseColumn, DateValue, TreeColumn} from './columns/column-types';
import {isNullOrUndefined, isUndefined} from 'util';
import {DataTableService} from '../../../../../services/data-table.service';
import {assigned, equals, getParent, ifChanged} from '../../../../../_helpers/utils';
import {NgSelectComponent} from '@ng-select/ng-select';
import {
  FieldUpdateEvent,
  FilterOptions,
  OBJECT_ROW_ID,
  PagingOptions,
  SELECTED_FLAG,
  TreeModel
} from './data-table.utils';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import 'rxjs/add/observable/from';
import {RowHeightCache} from './utils/row-height-cache';
import {RowUpdater} from './utils/row-updater';
import {FilterPageRequest} from '../../../models/filter.page.request';
import {FilterSearchColumn} from '../../../models/filter.search.column';
import {SearchSortRequest} from '../../../models/search.sort.request';
import {ContextMenuComponent, ContextMenuService} from 'ngx-contextmenu';
import {Logger} from "../../../../../_helpers/logger";
import {PaginationComponent} from "ngx-bootstrap/pagination";
import {map, share, switchMap, takeUntil} from 'rxjs/operators';
import {DropEvent} from "ng-drag-drop";
import {DataTableRowComponent} from "./data-table-row/data-table-row.component";
import {Size, Sort} from "../../../../../common/oms-types";
import {cleanSearchString} from "../../../../../_helpers/string.util";


// disabled events for updated columns
// let disabledForNow: string[] = ['addressCfs', 'shipment.addressDelivery', 'customer', 'freightForwarder', 'masterAir.airline'];
let selectionSort = ['', 'asc', 'desc'];

export interface TableParentNode<T = any> {
  children: T[];
}

export type GroupedFunc<T> = (items: T[]) => TableParentNode<T>[];

export interface ExpandEvent {
  row: any;
  expanded: boolean;
}

export interface CellHandleEvent {
  row: any;
  column: BaseColumn;
  handled: boolean;
}

export class DataTableDropEvent extends DropEvent {
  constructor(event: any, data: any, public row: any) {
    super(event, data);
  }
}

@Component({
  selector: 'oms-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({
  name: 'DataTableComponent'
})
export class DataTableComponent implements OnInit , AfterViewInit, DoCheck, OnChanges, OnDestroy {
  Size = Size;
  public showDateTimePopup: boolean = false;
  public stickyColumns: BaseColumn[] = undefined;
  public otherColumns: BaseColumn[] = undefined;
  public resizeColumns: boolean = false;
  public draggableData: boolean = false;
  public vertScrollbarVisible: boolean;
  public sortedByColumn: BaseColumn;

  element: HTMLElement;

  buffer: any[];
  updater: RowUpdater<any> = new RowUpdater<any>(this.trackFn);
  public onRefresh: EventEmitter<any> = new EventEmitter<any>();

  public scrollLeft;
  private dateSubject: Subject<DateValue>;
  public editableActualDate: Date;
  public editableEstimatedDate: Date;

  rowIndexes: any = new Map();
  rowHeightsCache: RowHeightCache = new RowHeightCache();
  indexes: any = {};

  public _bodyHeight: number;
  public _innerWidth: number;
  public _innerHeight: number;

  private offsetX: number = 0;
  private offsetY: number = 0;

  private pressed: boolean;
  private start: Node;
  private startWidth: any;
  private startX: number;

  private mx: number = 0;
  private my: number = 0;
  private sx: number = 0;
  private sy: number = 0;
  private drag: boolean = false;

  private valuesDiffer: KeyValueDiffer<any, any>;
  private dataDiffer: IterableDiffer<any>;
  private selectionDiffer: IterableDiffer<any>;

  private searchTimer;
  private searchTimeout: number = 1000;

  private lastSelected: any;

  tableClassesDef: any;
  isTree: boolean;

  private columnSearchItems = {};
  private searchTextTimer;

  private unsubscribe$ = new Subject<void>();
//  private socketSub: JsSocketSubscription;

  data: (any | TableParentNode)[] = [];

  public psConfig: PerfectScrollbarConfigInterface = {
    wheelSpeed: 1,
    handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
    suppressScrollX: false,
    suppressScrollY: false,
    useBothWheelAxes: false
  };

  @Input() public simplePaging: boolean = false;
  @Input() public filter: FilterOptions = {total: 0, search: '', colorItem: null};
  @Input() sort: Sort = {};
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  @ViewChild('dateTimePopup') public dateTimePopup: DateTimePopupComponent;
  @ViewChild('table') public table: ElementRef;
  @ViewChild('bodyDiv') bodyDiv: ElementRef<HTMLElement>;
  @ViewChild('headerDiv') headerDiv: ElementRef<HTMLElement>;
  @ViewChild('footer') footer: ElementRef<HTMLElement>;
  @ViewChild('pagination') pagination: PaginationComponent;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChildren('selectSearches') selectComponents: QueryList<NgSelectComponent>;

  /**
   * Input templates
   */
  @ContentChild('totalTmp', { read: TemplateRef }) totalTmp: TemplateRef<any>;
  @ContentChild('rowHeaderTemplate', { read: TemplateRef }) rowHeaderTemplate: TemplateRef<any>;
  @ContentChild('rowTemplate', { read: TemplateRef }) rowTemplate: TemplateRef<any>;

  @Input('paging') public paging: PagingOptions = {enabled: false, pageSize: 20};
  @Input('fixed-columns') public fixedColumns: number;
  @Input() contentDrag: boolean = false;
  @Input() readonly: boolean = false;
  @Input() specificRowClassHandler: any[] = null;
  @Input() striped: boolean = false;
  @Input() hover: boolean = true;
  @Input('no-footer') noFooter = false;
  @Input('row-class') rowClass: string | ((row: any) => string);
  @Input('row-draggable') rowDraggable: boolean = false;
  @Input('column-draggable') columnDraggable: boolean = false;
  @Input('object-id') objectId: string | ((row) => any);

  @Input('table-drop-scope') tableDropScope: string | string[] | (() => any);
  @Input('row-drag-scope')rowDragScope: string | string[] | ((row) => string | string[]);
  @Input('row-drop-scope')rowDropScope: string | string[] | ((row) => string | string[]);
  @Output('on-row-drop') rowDropEvent: EventEmitter<DataTableDropEvent> = new EventEmitter<DataTableDropEvent>();
  @Output('on-table-drop') tableDropEvent: EventEmitter<DataTableDropEvent> = new EventEmitter<DataTableDropEvent>();

  @Input() public sortBySelected: 'desc' | 'asc' | '' = '';
  @Input() selectable: boolean = false;
  @Input() checkboxes: boolean = false;
  @Input('multi-select') multiSelect: boolean = false;
  @Input('select-by-row') selectByRow: boolean = true;
  @Input('keep-selected') keepSelected: boolean = false;
  @Input('isSearchWHSE') isSearchWHSE: boolean = false;

  @Input() bordered: boolean = false;
  @Input() fixed: boolean = true;
  @Input() condensed: boolean = false;
  @Input() searchable: boolean = false;
  @Input() grid: boolean = true;

  /*** @deprecated ***/ @Input() height: number;
  @Input() treeModel: TreeModel;

  @Input('webSocketEventName') webSocketEventName: string;
  @Input() columns: BaseColumn[];
  @Input() activeSearchFilterCondition: any;

  @Input() debug: boolean = false;
  @Input() searchDisabled: boolean = false;
  @Input() emptyValue = 'N/A';

  @Input() virtualization: boolean = false; // Experimental!!
  @Input('use-ps') public usePerfectScrollBar: boolean = false; // Experimental!!

  @Input('selected') selected: any[] = [];
  @Input('expanded') expanded: any[] = [];
  @Output('expandedChange') expandedChange: EventEmitter<any[]> = new EventEmitter();

  @Input('data') originalData: any[];
  @Input('groupedFunc') groupedFunc: GroupedFunc<any>;

  @Input() menu: ((row, column?: BaseColumn) => ContextMenuComponent) | ContextMenuComponent;
  @Output('cell-right-click') cellRightClick: EventEmitter<CellHandleEvent> = new EventEmitter<CellHandleEvent>(false); // need event result; return handled = true if event is completely handled. It will prevent Context Menu popup
  @Output('cell-click') cellClick: EventEmitter<CellHandleEvent> = new EventEmitter<CellHandleEvent>(true);

  @Output('expand') expandEvent: EventEmitter<ExpandEvent> = new EventEmitter<ExpandEvent>();
  @Output('update') updateFieldEvent: EventEmitter<FieldUpdateEvent> = new EventEmitter<FieldUpdateEvent>(false /*need result for handle*/);
  @Output('searchCriteriaChanged') searchCriteriaChanged: EventEmitter<FilterPageRequest> = new EventEmitter<FilterPageRequest>(false/* need false to avoid requests mixing */);
  @Output('dataDblClick') dataDblClick = new EventEmitter();
  @Output() page: EventEmitter<any> = new EventEmitter<any>();
  @Output() scroll: EventEmitter<any> = new EventEmitter<any>();
  @Output() detailToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output('on-edit-error') editErrorEvent: EventEmitter<any> = new EventEmitter<any>();

  /*** @deprecated ***/
  @Input('defaultColumnForSort') set defaultColumnForSort(value: any) {this.sort = {field: value, order: 'asc'}; }
  @Output('selected') selectedChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output('pageSizeChange') pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChildren('rows') rowComponents: QueryList<DataTableRowComponent>;

  @Input() rowDetail: any = {rowHeight: 50};
  @Input() externalPaging: boolean;
  @Input() rowHeight: number | ((row?: any) => number) = () => this.condensed ? 28 : 36;


  get hasData(): boolean {
    return !!this.rowCount;
  }

  private calculatePopupOffset(cell: HTMLElement): {left: number, top: number, right?: number, bottom?: number} {
//    console.log('calculatePopupOffset');
    let popupWidth = 300;
    let popupHeight = 450;


    let offset: {left, top, right?, bottom?} = {
      top: cell.getBoundingClientRect().top - this.bodyDiv.nativeElement.getBoundingClientRect().top + cell.offsetHeight,
      left: cell.getBoundingClientRect().left - this.bodyDiv.nativeElement.getBoundingClientRect().left
    };

    if (this.bodyDiv.nativeElement.getBoundingClientRect().left + offset.left + popupWidth > window.screen.width) {
      offset.right = -offset.left;
      offset.left = undefined;
    }

    if (this.bodyDiv.nativeElement.getBoundingClientRect().top + offset.top + popupHeight > window.screen.height ) {
      offset.bottom = -offset.top + cell.offsetHeight;
      offset.top = undefined;
    }



//    console.log('OFFSET:', offset.top);
    return offset;
  }

  public editDateTime(cleanable: boolean, actual: Date, estimated: Date | undefined, showTime: boolean = true, event: MouseEvent): Subject<DateValue> {
    let target = event.target ? event.target : event.srcElement;
    let cell: HTMLElement = getParent(target, HTMLTableCellElement) || target;

    this.dateTimePopup.estimation = !isUndefined(estimated);
    this.editableActualDate = actual || estimated;
    this.editableEstimatedDate = estimated;
    this.dateTimePopup.showTime = showTime;
    this.dateTimePopup.activeTab = (!actual && estimated) ? "estimated" : "actual";
    this.dateTimePopup.clearButton.show = cleanable;

    // adjust popup top position with initialized popup component
    this.dateTimePopup.position = this.calculatePopupOffset(cell);
    this.showDateTimePopup = true;
    this.dateSubject = new Subject<DateValue>();
    return this.dateSubject;
  }

  onDateTimeEdited(event, isActual: boolean) {
    if (this.dateSubject) {
      if (isActual) {
        this.dateSubject.next({actual: event});
      } else {
        this.dateSubject.next({estimated: event});
      }
      this.cdr.markForCheck();
    }
  }


  constructor(
    private hostElement: ElementRef,
    private contextMenuService: ContextMenuService,
    public cdr: ChangeDetectorRef,
    element: ElementRef,
    private iterableDiffers: IterableDiffers,
    private valueDiffers: KeyValueDiffers,
    private renderer: Renderer2,
    private accountService: AccountService,
    public dataTableService: DataTableService) {

    this.element = element.nativeElement;
    // this.columnsDiffer = this.iterableDiffers.find([]).create(null);
    this.dataDiffer = this.iterableDiffers.find([]).create(this.trackByRowObject);
    this.selectionDiffer = this.iterableDiffers.find([]).create(this.trackByRowObject);
    this.valuesDiffer = this.valueDiffers.find({}).create();

    dataTableService.searchInputSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(searchText => {
      this.filter.search = searchText;
      this.buildSearchRequest();
      // this.setPage(1);
      // this.refresh();
    });

    dataTableService.searchColorSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(colorItem => {
      this.filter.colorItem = colorItem;
      //  this.setPage(1);
      //  this.refresh();
    });
  }

  public changeSearchText(searchText: string, debounceTime: number = 1500) {
    if (this.searchTextTimer) {
      clearTimeout(this.searchTextTimer);
      this.searchTextTimer = null;
    }

    this.searchTextTimer = setTimeout(() => {

      this.dataTableService.changeText(cleanSearchString(searchText));
    }, debounceTime);
  }

  public setValue(object: any, value: any, field?) {
    if (object && typeof field === 'string') {
      let path: string[] = field.split('.').reverse();
      this.setFieldValue(object, path, value);
    }
  }

  protected setFieldValue(object: any, path: string[], newValue: any) {
    if (object && path) {
      if (path.length > 1) {
        this.setFieldValue(object[path.pop()], path, newValue);
      } else {
        object[path.pop()] = newValue;
      }
    }
  }

  getHeaderSearchItems(column: BaseColumn): Observable<any[]> {
    return Array.isArray(column.search.items) ?
      of(column.search.items) :
      column.search.items(null).pipe(map((response) => response.content));
  }

  get sortedAsc(): boolean {
    return this.sort ? !this.sort.order || this.sort.order === 'asc' : false;
  }

  get sortedBy(): any {
    return this.sort && this.sort.field;
  }


  // todo remove OMS Masters specific dependency!!
  ngOnInit() {
/*
    if (!isNullOrUndefined(this.webSocketEventName)) {
      this.webSocketService.stompClientSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(stompClient => {
        if (!isNullOrUndefined(stompClient)) {
          let that = this;
          this.socketSub = stompClient.subscribe(this.webSocketEventName, function (message) {
            let messageObject = JSON.parse(message.body);
            if (messageObject.operation === 'UPDATE_FIELD') {
//              console.log('FIELD UPDATE RECEIVED');
              // todo fix updating object and remove check
              if (disabledForNow.indexOf(messageObject.field) < 0) {
                let items = that.data.filter(filteredItem => {
                  return messageObject.id === filteredItem.id;
                });
                if (items.length > 0) {
                  that.setValue(items[0], messageObject.updatedValue, messageObject.field);
                  that.refresh();
                }
              }
            } else if (messageObject.operation === 'CREATE') {
              //  that.data.push( plainToClass(Master, messageObject.updatedValue));
              //  that.refresh();
            } else if (messageObject.operation === 'UPDATE') {
//              console.log('UPDATE RECEIVED');
              let scrollLeft = that.bodyDiv.nativeElement.scrollLeft;
              let master: Master = onAfterLoad(plainToClass(Master, <Object> messageObject.updatedValue));
              that.updater.update([master]);
              that.onRefresh.emit(true);
              // restore left scroll position
              that.bodyDiv.nativeElement.scrollLeft = scrollLeft;
            }
          });
        }
      });
    }
*/
    this.onSortChanged();


  }

  private onSortChanged() {
    if (this.columns) {
      // find specified sortable column
      if (this.sort && this.sort.field) {
        this.sortedByColumn = this.columns.find((column ) => column.sortable && column.id === this.sort.field); // not strict equals
      }

      // Find Default sortable column
      if (!this.sortedByColumn) {
        this.sortedByColumn = this.columns.find((column ) => column.sortable && !!column.handlers.sorted);
        if (this.sortedByColumn) {
          this.sort = {
            field: this.sortedByColumn.id,
            order: this.sortedByColumn.handlers.sorted.asc ? 'asc' : 'desc'
          };
        }
      }

    } else {
      this.sortedByColumn = null;
    }
  }


  clearInput(column) {
    column.search.search = "";
    this.buildSearchRequest();
    //  this.setPage(1);
    this.refresh();
  }

  public clearTableInputs(): void {
    this.filter.search = "";
    this.columns.forEach(column => {
      column.search.search = "";
    });
    this.selectComponents.forEach((item) => {
      item.clearModel();
    });
    this.refresh();
    this.searchChanged(true);
  }

  public isClearTableInputs(): boolean {
    if (this.filter.search) {
      return false;
    }
    if (!this.columns) {
      return true;
    }
    return !this.columns
      .filter(c => c.search.searchable)
      .some(column => {
        return (typeof column.search.search === "string" && !!column.search.search)
          || (Array.isArray(column.search.search) && column.search.search.length !== 0);
      });
  }

  /***@deprecated***/
  changeStatusFilterSearch(statuses: number[], columnId: number) {
    this.columns.forEach(column => {
      column.search.search = "";
    });
    // this.selectComponents.forEach(item => {item.clearModel();});
    let statusColumn = this.columns.filter(column => column.id === columnId);
    // @ts-ignore
    statusColumn[0].search.search = statuses;
    this.buildSearchRequest();
    this.refresh();
  }

  onSelectSearchItem(event, column: BaseColumn, multiple) {
    if (multiple && !isNullOrUndefined(event)) {
      column.search.search = event.map(item => item.id || item);
    } else {
      column.search.search = isNullOrUndefined(event) ? "" : event.id.toString();
    }
    this.searchChanged();
  }

  onSelectSearchItemById(event, column: BaseColumn, multiple: boolean, force) {
    if (multiple && !isNullOrUndefined(event)) {
      column.search.search = event.map(item => item.id);
    } else {
      column.search.search = isNullOrUndefined(event) ? "" : event.id.toString();
    }
    this.searchChanged(force, 3000);
  }

  clearSelected(event, column: BaseColumn) {
    event.preventDefault();
    event.stopPropagation();
    column.search.selected = [];
    this.onSelectSearchItemById([], column, column.search.multiple, true);
  }

  onSelectColorSearchItem(event, column) {
    column.search.search = isNullOrUndefined(event) ? "" : event.status;
    //  this.setPage(1);
    this.refresh();
  }

  dateRangeSearchChanged(event, column, force?: boolean) {
   // column.search.search = !isNullOrUndefined(event) ? event.fromDate : null;
    column.search.search = event;
    this.searchChanged(force);
  }

  dateSearchChanged(event, column) {
    if (isNullOrUndefined(event) || event.length === 0) {
      column.search.search = null;
    } else if (event.length === 10) {
      let newDate = new Date(event);
      if (newDate.toDateString() !== 'Invalid Date') {
        column.search.search = newDate;
      }
    }

    this.buildSearchRequest();
    //  this.setPage(1);
    this.refresh();
  }

  // Manual sort by Column
  public sortBy(column: BaseColumn, asc?: boolean): void {
    if (column) {
      if (!column.sortable) {
        return;
      }

      // Toggle sort direction
      if (isNullOrUndefined(asc)) {
        asc = !this.isSortedAsc(column); // column.id === this.sortedBy ? !this.sortedAsc : true;
      }

      this.sort = {field: column.id, order: asc ? 'asc' : 'desc'};
    } else {
      this.sort = {};
    }

    this.sortChange.next(this.sort);
    this.sortedByColumn = column;
    this.buildSearchRequest();
  }

  isSortedAsc(column: BaseColumn) {
    return this.sort && (column.id === this.sort.field) && this.sort.order === 'asc';
  }

  isSortedDesc(column: BaseColumn) {
    return this.sort && (column.id === this.sort.field) && this.sort.order === 'desc';
//    return column.id === this.sortedBy && !this.sortedAsc;
  }

  sortColumnsByOrderNumber(columns: BaseColumn[], filterByVisibility) {
    if (columns) {
      let result = columns.sort((a: BaseColumn, b: BaseColumn) => {
        if (a.orderNumber < b.orderNumber) {
          return -1;
        } else if (a.orderNumber > b.orderNumber) {
          return 1;
        } else {
          return 0;
        }
      });

      return filterByVisibility ? result.filter(column => column.visible) : result;
    } else {
      return null;
    }
  }



  isExpanded(row): boolean {
    if (!this.isTree || !row) {
      return false;
    }

    let id = this.trackByRowObject(null, row);
    if (id) {
      return !!this.expanded.find((item) => this.trackByRowObject(null, item) === id);

    } else {
      return this.expanded.hasEquals(row);
    }
  }

  getRowClass(row): string {
    let result: string = row && row.rowClass || '';
    if (this.rowClass) {
      result += ' ' + (typeof this.rowClass === 'string' ? this.rowClass : this.rowClass(row));
    }
    return result;
  }

  isLeaf(row): boolean {
    return !this.isTree || this.treeModel.isLeaf(row);
  }

  expand(row) {
    let obs = this.updateRowHeightsCache(row, (r) => this.expanded.push(r));
    this.expandEvent.emit({row: row, expanded: true});
    this.expanded = [...this.expanded];
//    console.log('>>>>>', this.expanded);
    this.expandedChange.emit(this.expanded);
    return obs;
  }

  collapse(row): Observable<any> {
    let obs = this.updateRowHeightsCache(row, (r) => this.expanded.removeAll(r));
    this.expandEvent.emit({row: row, expanded: false});
    this.expanded = [...this.expanded];
//    console.log('<<<<<', this.expanded);
    this.expandedChange.emit(this.expanded);
    return obs;
  }

  updateRowHeightsCache(row: any, onAction: (row: any) => void): Observable<any> {
    let obs = new Observable(subscriber => {
      this.getTotalRowHeight(row)
        .subscribe(oldHeight => {
          onAction(row);
          this.getTotalRowHeight(row)
            .subscribe(newHeight => {
              subscriber.next();
              subscriber.complete();
              if (this.virtualization) {
                this.rowHeightsCache.update(this.getRowIndex(row), newHeight - oldHeight);
              }
            });
        });
    }).pipe(share());
    obs.subscribe(() => {});

    return obs;
  }

  toggleExpanded(row) {
    if (!this.isLeaf(row)) {
      if (this.isExpanded(row)) {
        this.collapse(row);
      } else {
        this.expand(row);
      }
    }
  }


/*  getFocused(): any {
    return this.selected.length ? this.selected[0] : null;
  }
*/
  resizeColumnsEvent() {
    this.resizeColumns = true;
    this.draggableData = false;
  }

  dragColumnsEvent() {
    this.draggableData = true;
    this.resizeColumns = false;
  }

  hideShowColumnsEvent() {
    this.accountService.showHideShowPopUp(this.columns);
  }

  saveChanges() {
    this.resizeColumns = false;
    this.draggableData = false;
//    localStorage.setItem('order.columns', JSON.stringify(this.columns));
  }

  onItemDrop(event) {
    let column: BaseColumn = event.dragData;

    let oldOrderNumber: number = column.orderNumber;
    let newOrderNumber: number = Number(event.nativeEvent.target.closest(".drag-handle").attr("orderNumber"));
    if (newOrderNumber) {
      this.columns.splice(oldOrderNumber - 1, 1);
      this.columns.splice(newOrderNumber - 1, 0, column);
      this.columns.forEach((c, index) => (c.orderNumber = index + 1));
    }

  }

  hideColumn(column: BaseColumn) {
    column.visible = false;
  }

  // resizable table
  public onMouseDown(event: MouseEvent, columnSetting) {
    this.start = event.target as Node;
    this.pressed = true;
    this.startX = event.x;
    this.startWidth = this.start.parentElement.offsetWidth;
    this.initResizableColumns(columnSetting);
  }

  private initResizableColumns(columnSetting) {
    this.renderer.listen('body', 'mousemove', (event) => {
      if (this.pressed) {
        let width = this.startWidth + (event.x - this.startX);
        const parent = this.start.parentElement;
        parent.style.minWidth = width + "px";
        parent.style.maxWidth = width + "px";
      }
    });
    this.renderer.listen('body', 'mouseup', () => {
      if (this.pressed) {
        this.pressed = false;
      }
    });
  }

  onRowDoubleClick(row) {
    this.dataDblClick.emit(row);
  }

  onCellDoubleClick(row, cell) {
    // this.dataDblClick.emit(data);
  }


  // todo make observable
  updateColumnValue<T>(object: T, column: BaseColumn, newValue, field): Subject<T> {
    let subject = new ReplaySubject<T>(1);
    if (!isUndefined(newValue)) {
      let oldValue = column.getValue(object, field);

      if (oldValue !== newValue) {
//        if (!column.postUpdate)
//          column.setValue(object, newValue, field);
        // sending event

        let applied: boolean = false;
        let error;

        this.updateFieldEvent.emit({
          newValue: newValue,
          oldValue: oldValue,
          row: object,
          column: column,
          field: field,

          apply: (value?) => {
            applied = true;
            if (!isUndefined(value)) { newValue = value; }
            column.setValue(object, newValue, field);
            subject.next(object);
            this.cdr.markForCheck();
            this.onRefresh.emit(true);
          },

          cancel: () => {
            applied = true;
            column.setValue(object, oldValue, field);
            subject.next(object);
            this.cdr.markForCheck();
            this.onRefresh.emit(true);
          },

          error: (msg) => {
            error = msg;
          }
        });

        if (error) { // prevent close editor
          throw error;
        }

        if (!applied && !column.postUpdate) {
          column.setValue(object, newValue, field);
          subject.next(object);
          this.cdr.markForCheck();
          this.onRefresh.emit(true);
        }
      }
    } else {
      subject.next(object);
    }
    return subject;
  }

  get vertScrollBarWidth() {
    return 17; // this.bodyDiv.nativeElement.offsetWidth - this.bodyDiv.nativeElement.clientWidth;
  }

//  public get vertScrollbarVisible(): boolean {
//    return this.bodyDiv.nativeElement.scrollHeight > this.bodyDiv.nativeElement.clientHeight;
//  }

/*
  get headerHeight(): number {
    return this._headerHeight || (this._headerHeight = this.headerDiv.nativeElement.offsetHeight);
  }

  get footerHeight(): number {
    return this._footerHeight || (this._footerHeight =  this.footer.nativeElement.offsetHeight);
  }


  get calcHeight(): any {
//    console.log('calc');
    return ~~this.height > 0 ? this.height - this.headerHeight - this.footerHeight : undefined;
  }
  */


  onSearch(column: BaseColumn, value) {
    if (column.search && column.search.searchable) {
      column.search.search = value;
      this.searchChanged(true);
    }
  }

  public setSearch(search: Map<any, any>) {
    this.columns.forEach((column) => {
      if (column.search && column.search.searchable) {
        column.search.search = search.get(column.id);
      }
    });
    this.searchChanged(true);
  }

  public getSearch(): Map<string, any> {
    let result: Map<any, any> = new Map<any, any>();
    this.columns.forEach((column) => {
      if (column.search && column.search.searchable && assigned(column.search.search)) {
        result.set(column.id, column.search.search);
      }
    });

    return result;
  }



  searchChanged(force?: boolean, timeout?: number) {
    clearTimeout(this.searchTimer);

    if (force) {
      this.buildSearchRequest();
      this.refreshAndResetCurrentPage();
    } else {
      this.searchTimer = setTimeout(() => {
        this.buildSearchRequest();
        this.refreshAndResetCurrentPage();
      }, timeout || this.searchTimeout);
    }

  }


  public buildSearchRequest(refreshTable: boolean = true): FilterPageRequest {
    let filterSearchColumn = [];
    if (this.columns) {
      this.columns.forEach(column => {
        let filter = FilterSearchColumn.fromSearchOptions(column.search, column.id);
        if (filter) {
          filterSearchColumn.push(filter);
        }

        /*      if (column.search.searchable && !isNullOrUndefined(column.search.search) && (column.search.search + "").length > 0) {

                let fieldName = column.search.field || column.id;
                // @ts-ignore
                if (column.search.search instanceof Array) {
                  filterSearchColumn.push(new FilterSearchColumn(fieldName, null, column.search.search, null));
                  //@ts-ignore
                } else if(column.search.search instanceof SearchRange) {
                  if(!column.search.search.isEmpty)
                    filterSearchColumn.push(new FilterSearchColumn(fieldName, null, null, column.search.search));
                } else {
                  filterSearchColumn.push(new FilterSearchColumn(fieldName, column.search.search, null, null));
                }
              } */
      });
    }
    if (this.isSearchWHSE) {
      let filterByWHSELocation = new FilterSearchColumn('whseBuilding', 'true');
      filterSearchColumn.push(filterByWHSELocation);
    }
    let page = this.getPage();

    let sort = new SearchSortRequest(this.sort && this.sort.field, this.sort && this.sort.order === 'asc');
    let filterPageRequest = new FilterPageRequest(isNullOrUndefined(page) || page <= 0 ? 1 : page, this.getPageSize(), this.filter.search, sort, filterSearchColumn);
//    console.log('FILTER', this.filter.search);

    if (refreshTable) {
      this.searchCriteriaChanged.emit(filterPageRequest);
    }

    return filterPageRequest;
  }

  public refreshAndResetCurrentPage() {
    // this.setPage(1);
    this.refresh();
  }

  isSelectedRow(row): boolean {
    return row && row[SELECTED_FLAG];
  }

  // update is a part! of items to replace in the data array. Not the all list of items
  public refresh(updateInList?: any[]) {
    if (updateInList && this.data) {
      updateInList.forEach((item) => this.data.replaceAll(item, item));
//      this.updater.update(updateInList);
    }

    // this.cdr.markForCheck();

    // this.cdr.detectChanges();
    /// !!! todo check
    this.recalcLayout();

    this.onRefresh.emit(true);
  }

  setPage(page: number) {
    if (this.paging
      && this.paging.enabled
      && this.paging.currentPage !== page) {
      this.paging.currentPage = page;
      this.buildSearchRequest();
      this.refresh();
    }
  }

  getPage(): number {
    if (this.paging && this.paging.enabled) {
      return this.paging.currentPage;
    }
    return -1;
  }


  /*get isTree(): boolean {
    return !isNullOrUndefined(this.treeModel)
  }*/

  get totalPages() {
    return this.paging && this.paging.enabled ? Math.ceil((this.paging.total || 0) / this.paging.pageSize) : 1;
  }

  onSetPageNumber(event) {
    event.stopPropagation();
    if (this.paging && this.paging.enabled ) {
      if ((event instanceof KeyboardEvent && event.code === 'Enter') || (event instanceof FocusEvent && event.type === 'blur')) {
        let page = ~~(<HTMLInputElement>event.target).value;
        if (page && page !== this.paging.currentPage && page <= this.totalPages /*this.pagination.totalPages*/) {
          this.setPage(page);
        } else {
          (<HTMLInputElement>event.target).value = (this.paging.currentPage || 1) + '';
        }
      }
    }
  }

  setPageSize(pageSize: number) {
    if (this.paging && this.paging.enabled) {
      if (pageSize > this.paging.pageSize) {
        this.paging.currentPage = 1;
      }
      this.paging.pageSize = pageSize;
      this.pageSizeChange.emit(pageSize);
    }
    this.buildSearchRequest();
    this.refresh();
  }

  getPageSize(): number {
    if (this.paging && this.paging.enabled) {
      return this.paging.pageSize;
    }
    return -1;
  }

  columnByRow(column, row?) {
    return column instanceof TreeColumn ? column.byRow(row) : column;
  }


  onContentMouseDown(event: MouseEvent) {
    if (this.contentDrag) {
      this.sx = this.bodyDiv.nativeElement.scrollLeft;
      this.sy = this.bodyDiv.nativeElement.scrollTop;
      this.mx = event.pageX - this.bodyDiv.nativeElement.offsetLeft;
      this.my = event.pageY - this.bodyDiv.nativeElement.offsetTop;
      this.drag = true;
    }
  }

  onMouseMove(event: MouseEvent) {
    if (this.drag) {
      let mx2 = event.pageX - this.bodyDiv.nativeElement.offsetLeft;
      if (this.mx) { this.bodyDiv.nativeElement.scrollLeft = this.sx + this.mx - mx2; }

      let my2 = event.pageY - this.bodyDiv.nativeElement.offsetTop;
      if (this.my) { this.bodyDiv.nativeElement.scrollTop = this.sy + this.my - my2; }
    }
  }

  onMouseUp(event) {
    this.drag = false;
  }

  get noData(): boolean {
    return !this.hasData;
  }

  public beforeUpdate() {
    this.scrollLeft = this.bodyDiv.nativeElement.scrollLeft;
  }

  public afterUpdate() {
    this.bodyDiv.nativeElement.scrollLeft = this.scrollLeft;
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
/*    if (changes.data) {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    } */

    if (changes.originalData || changes.groupedFunc) {
      if (this.groupedFunc) {
        this.data = this.groupedFunc(this.originalData);
      } else {
        this.data = this.originalData;
      }
    }

    if (changes.activeSearchFilterCondition) {
      this.columnSearchItems = {};
      let statusDropdown = (this.selectComponents || []).find(item => item.labelForId === 'status');
      if (statusDropdown && statusDropdown.selected && statusDropdown.selected.length) {
        statusDropdown.clearModel();
      }
    }

    if (changes.sort) {
      this.onSortChanged();
    }


    if (changes.treeModel) {
      this.isTree = !isNullOrUndefined(this.treeModel);
    }


    if (changes.columns || changes.fixedColumns) {
      let innerStickyColumns = (!this.columns || !this.fixedColumns) ? [] : [...this.columns].filter(column => !column.isHiddenColumn()).slice(0, this.fixedColumns);
      this.stickyColumns = this.sortColumnsByOrderNumber(innerStickyColumns, null);
      let innerOtherColumns = (!this.columns || !this.fixedColumns) ? this.columns : [...this.columns].filter(column => !column.isHiddenColumn()).slice(this.fixedColumns);
      this.otherColumns = this.sortColumnsByOrderNumber(innerOtherColumns, null);
    }

    if (changes.striped || changes.selectable || changes.bordered || changes.fixed || changes.condensed) {
      this.tableClassesDef = {
        'table-striped': this.striped,
        'table-hover': this.hover,
        'table-bordered': this.bordered,
        'table-fixed': this.fixed,
        'table-condensed': this.condensed
      };
    }

/*    if (changes.selected || changes.data) {
      this.updateSelectedItems(this.selected, this.data);
    }*/
  }

  // TODO need test
/*  private updateSelectedItems(selected: any[], data: any[]) {
    data.forEach(item => {
      let isSelected = selected.some(selectedItem => selectedItem === item
        || (selectedItem && item && selectedItem.id && item.id && selectedItem.id === item.id));
      if (isSelected) {
        item[SELECTED_FLAG] = true;
      } else {
        delete item[SELECTED_FLAG];
      }
    });
  }*/

  private replaceInSelected(item) {
    if (item) {
      this.selected.forEach((el, index, array) => {
        if (equals(item, el) || this.trackByRowObject(null, el) === this.trackByRowObject(null, item)) {
          array[index] = item;
          item[SELECTED_FLAG] = true;
        }
      });
    }
  }

  ngDoCheck(): void {
    let changed: boolean = false;

    if (this.recalculateDims()) {
      changed = true;
    }

    if (this.valuesDiffer.diff({
      searchable: this.searchable,
      checkboxes: this.checkboxes,
    })) {
      changed = true;
    }

/*    if (this.columnsDiffer.diff(this.columns)) {
      this._stickyColumns = null;
      this._otherColumns = null;
      changed = true;
    }*/

    let dataChanges = this.dataDiffer.diff(this.data);
    let selectionChanges = this.selectionDiffer.diff(this.selected);

    if (dataChanges || selectionChanges) {

      if (selectionChanges) {
        selectionChanges.forEachAddedItem((item) => item.item[SELECTED_FLAG] = true);
        selectionChanges.forEachRemovedItem((item) => delete item.item[SELECTED_FLAG]);
      }

      let pageChanged: boolean = false;
      let itemsChanged: any[] = [];

      if (dataChanges) {
        dataChanges.forEachAddedItem((item) => {pageChanged = true; this.replaceInSelected(item.item); });
        dataChanges.forEachRemovedItem((item) => pageChanged = true);
        dataChanges.forEachIdentityChange((item) => {itemsChanged.push(item.item); this.replaceInSelected(item.item);
          if (this.isExpanded(item.item)) {
            // force expand event if updated row is Expanded
            this.expandEvent.emit({row: item.item, expanded: true});
          }
        });
//        console.log('Update Page Detected', dataChanges, 'page changed', pageChanged, 'items changed', this.data);
      }

      if (itemsChanged.length > 0 || pageChanged || selectionChanges) {
        changed = true;
        if (pageChanged || selectionChanges) {
          this.updateData(selectionChanges && !pageChanged);
        } else if (itemsChanged.length > 0) {
          this.updater.update(itemsChanged);
        }
      }
    }

    if (changed) {
      this.refresh();
//      this.cdr.markForCheck();
    }
  }

  private updateData(forceInstant?: boolean) {

    if (!this.keepSelected) {
      this.selected.forEach((row) => {if (!this.data.hasEquals(row)) { this.selected.removeAll(row); } });
    }

//    this.expanded.forEach(row => {if (!this.data.hasEquals(row)) this.expanded.removeAll(row);});

    // update selected items with new from the item list
    if (this.keepSelected) {
      this.data.forEach((d) => {
        this.selected.replaceAll(d, d, (replaced) => replaced[SELECTED_FLAG] = true);
      });
    }

    if (this.updater) { // in during destroy
      this.updater.setData(
        this.keepSelected ? [...this.selected.filter(item => !this.data.hasEquals(item)), ...this.data] : this.data,
        forceInstant || this.virtualization,
        () => this.recalcLayout()
      );
    }
  }


  /*get tableClassesDef() {
    return {
      'table-striped': this.striped,
      'table-hover': this.selectable,
      'table-bordered': this.bordered,
      'table-fixed': this.fixed,
      'table-condensed': this.condensed
    }
  }*/

  get rows(): any[] {
    return this.data;
  }

//  _rowCount:number;
  get rowCount(): number {
    if (this.virtualization) {
      return this.data ? this.data.length : 0;
    } else {
      return this.updater && this.updater.items ? this.updater.items.length : 0; // this._rowCount || 0;
    }
  }

  /**
   * Updates the rows in the view port
   */
  updateRows(): void {
//    console.time('Update Rows');
    if (this.virtualization) {
      const { first, last } = this.indexes;
//      console.log('indexes', this.indexes);

      let rowIndex = first;
      let idx = 0;
      const temp: any[] = [];

      this.rowIndexes.clear();


      // if grouprowsby has been specified treat row paging
      // parameters as group paging parameters ie if limit 10 has been
      // specified treat it as 10 groups rather than 10 rows
      while (rowIndex < last && rowIndex < this.rowCount) {
        const row = this.rows[rowIndex];

        if (row) {
          this.rowIndexes.set(row, rowIndex);
          temp[idx] = row;
        }

        idx++;
        rowIndex++;
      }

//      console.timeEnd('Update Rows');
      this.updater.setData(temp, this.virtualization);
      this.buffer = temp;
    }
//    this.cdr.detectChanges();
  }

  /**
   * Recalculates the table
   */
  recalcLayout(): void {
    this.vertScrollbarVisible = !this.usePerfectScrollBar && this.bodyDiv.nativeElement.scrollHeight > this.bodyDiv.nativeElement.clientHeight;
    this.refreshRowHeightCache();
    this.updateIndexes();
    this.updateRows();
  }

  /**
   * Refreshes the full Row Height cache.  Should be used
   * when the entire row array state has changed.
   */
  refreshRowHeightCache(): void {
    if (!this.virtualization) { return; }

//    console.log('refreshRowHeightCache');
    // clear the previous row height cache if already present.
    // this is useful during sorts, filters where the state of the
    // rows array is changed.
    this.rowHeightsCache.clearCache();

    // Initialize the tree only if there are rows inside the tree.
    if (this.rows && this.rows.length) {
      this.rowHeightsCache.initCache({
        rows: this.rows,
        rowHeight: this.getTotalRowHeight,
//        detailRowHeight: this.getChildrenRowHeight,
        externalVirtual: this.externalPaging,
        rowCount: this.rowCount,
        rowIndexes: this.rowIndexes,
//        rowExpansions: this.rowExpansions
      });
    }
  }

  /**
   * Updates the index of the rows in the viewport
   */
  updateIndexes(): void {
    let first;
    let last;

    if (this.virtualization) {
      // Calculation of the first and last indexes will be based on where the
      // scrollY position would be at.  The last index would be the one
      // that shows up inside the view port the last.
      const height = this._bodyHeight; // */parseInt(this.bodyHeight, 0);
      first = this.rowHeightsCache.getRowIndex(this.offsetY);
      last = this.rowHeightsCache.getRowIndex(height + this.offsetY) + 1;

    } else {
      // If virtual rows are not needed
      // We render all in one go
      first = 0;
      last = this.rowCount;
    }

    this.indexes = { first, last };
  }

  getChildrenRowHeight = (row: any, index?: any): Observable<number> => {
    if (!row) {
      return of(0);
    }
    let height = 0;
    if (!this.isLeaf(row) && this.isExpanded(row)) {
      let itemsObs = this.treeModel.children(row);
      return itemsObs.pipe(switchMap((items) => {
        return forkJoin(items.map(child => this.getTotalRowHeight(child)))
          .pipe(map((childHeights) => childHeights.reduce((a, b) => a + b, 0)));
      }));
    }
    return of(height);
  }

  getTotalRowHeight = (row: any): Observable<number> => {
    const rowHeight = this.rowHeight;
    let height = typeof(rowHeight) === 'function' ? rowHeight(row) : rowHeight;
    return this.getChildrenRowHeight(row).pipe(map((childHeight) => {
      return height + childHeight;
    }));
  }

  getRowHeight(row: any): number {
    const rowHeight = this.rowHeight;
    return typeof(rowHeight) === 'function' ? rowHeight(row) : rowHeight;
  }

  /**
   * Body was scrolled, this is mainly useful for
   * when a user is server-side pagination via virtual scroll.
   */
  onBodyScroll(event: any): void {
    const scrollYPos: number = event.scrollYPos;
    const scrollXPos: number = event.scrollXPos;

    let scroll = this.offsetY !== scrollYPos;

    // if scroll change, trigger update
    // this is mainly used for header cell positions
    if (this.offsetY !== scrollYPos || this.offsetX !== scrollXPos) {
      this.scroll.emit({
        offsetY: scrollYPos,
        offsetX: scrollXPos
      });
    }
    this.offsetY = scrollYPos;
    this.offsetX = scrollXPos;

    this.updateIndexes();
    if (scroll) {
//    this.updatePage(event.direction);
      this.updateRows();
    }
  }

  /**
   * Property that would calculate the height of scroll bar
   * based on the row heights cache for virtual scroll and virtualization. Other scenarios
   * calculate scroll height automatically (as height will be undefined).
   */
  get scrollHeight(): number | undefined {
    if (this.virtualization && this.rowCount) {
      return this.rowHeightsCache.query(this.rowCount - 1);
    }
    // avoid TS7030: Not all code paths return a value.
    return undefined;
  }



  /**
   * Gets the row index given a row
   */
  getRowIndex(row: any): number {
    return this.rowIndexes.get(row) || 0;
  }


  /**
   * Recalculates the dimensions of the table size.
   * Internally calls the page size and row count calcs too.
   *
   */

  recalculateDims(): boolean {
//    console.time('recalculateDims');
    if (!this.virtualization) {
      return false;
    }

    let changed: boolean = false;
    const dims = this.element.getBoundingClientRect();

    ifChanged(this._innerWidth, Math.floor(dims.width), (v) => {this._innerWidth = v; changed = true; });
    ifChanged(this._innerHeight, Math.floor(dims.height), (v) => {this._innerHeight = v; changed = true; });
    ifChanged(this._bodyHeight, this.bodyDiv.nativeElement.getBoundingClientRect().height, (v) => {this._bodyHeight = v; changed = true; });

//    console.timeEnd('recalculateDims');
    return changed;
  }


  /**
   * Recalc's the sizes of the grid.
   *
   * Updated automatically on changes to:
   *
   *  - Columns
   *  - Rows
   *  - Paging related
   *
   * Also can be manually invoked or upon window resize.
   */
  recalculate(): void {
    this.recalculateDims();
//    this.recalculateColumns();
  }

  /**
   * Window resize handler to update sizes.
   */
  @HostListener('window:resize')
  onWindowResize(): void {
//    console.log('Window Resize');
    this.recalculate();
    this.recalcLayout();
  }

  /**
   * Lifecycle hook that is called after a component's
   * view has been fully initialized.
   */
  ngAfterViewInit(): void {
    // this.buildSearchRequest();

    // this has to be done to prevent the change detection
    // tree from freaking out because we are readjusting
    if (typeof requestAnimationFrame === 'undefined') {
      return;
    }


    requestAnimationFrame(() => {
      this.recalculate();
      this.recalcLayout();
      this.cdr.markForCheck();

      /*      // emit page for virtual server-side kickoff
            if (this.externalPaging && this.scrollbarV) {
              this.page.emit({
                count: this.count,
                pageSize: this.pageSize,
                limit: this.limit,
                offset: 0
              });
            }*/
    });
  }

  compareById(item1: any, item2: any) {
    if (!item1 || !item2) {
      return false;
    }
    return item1['id'] === item2['id'];
  }

  public get trackFn(): (index, item) => any {
    return this.trackByRowObject.bind(this);
  }

  public trackByRowObject(index, item): any {
//    return item[OBJECT_ROW_ID] || (item[OBJECT_ROW_ID] = '10');

    if (!!item && !!item[OBJECT_ROW_ID]) {
      return item[OBJECT_ROW_ID];
    }

    if (!!item) {
      if (!isNullOrUndefined(this.objectId)) {
        if (typeof this.objectId === 'string') {
          item[OBJECT_ROW_ID] = item[this.objectId];
        } else {
          item[OBJECT_ROW_ID] = this.objectId(item);
        }
        return item[OBJECT_ROW_ID];

      } else {
        if (typeof item.trackBy === 'function') {
          item[OBJECT_ROW_ID] = item.trackBy();
        } else if (!!item['id']) {
          item[OBJECT_ROW_ID] = item['id'];
        } else {
          item[OBJECT_ROW_ID] = Math.random();
        }
        return item[OBJECT_ROW_ID];
      }
    } else {
      return index;
    }

  }

  isSelected(row): boolean {
    return this.selectable && row && row[SELECTED_FLAG]; // : false;// this.selected.hasEquals(row) : false;
  }


  get isSelectedAll(): boolean {
//    this.selected.all
    return false;
  }
  get isSelectedPartially(): boolean {
    return this.selected.length > 0;
//    return true;
  }


  toggleSelected(row, event: MouseEvent) {
    event.stopPropagation();

    if (this.selectable) {

      const wasSelected: boolean = this.isSelected(row);
      if (this.multiSelect) {

        if (this.lastSelected && event.shiftKey) {
          let last = this.data.indexOf(this.lastSelected);
          let current = this.data.indexOf(row);

          if (last >= 0 && current >= 0) {
            if (last <= current) {
              for (let i = last + 1; i <= current; i++) {
                this.setSelected(this.data[i], true);
              }
            } else {
              for (let i = last - 1; i >= current; i--) { this.setSelected(this.data[i], true);
              }
            }
          } else {
            this.setSelected(row, true);
          }

        } else {
          this.setSelected(row, !wasSelected);
        }
      } else {
        if (!wasSelected) {
          this.clearSelection();
          this.setSelected(row, !wasSelected);
        }
      }
      this.selectedChange.emit(this.selected);
      this.cdr.markForCheck();

    }
  }

  onRowSelect(row, event: MouseEvent) {
    if (this.selectable && this.selectByRow) {
      let toggle = this.multiSelect && event.ctrlKey;
      let addRange = !toggle && this.multiSelect && event.shiftKey;

      if (addRange) {
        let last = this.data.indexOf(this.lastSelected);
        let current = this.data.indexOf(row);

        if (last >= 0 && current >= 0) {
          if (last <= current) {
            for (let i = last + 1; i <= current; i++) { this.setSelected(this.data[i], true); }
          } else {
            for (let i = last - 1; i >= current; i--) { this.setSelected(this.data[i], true); }
          }
        } else {
          this.setSelected(row, true);
        }

      } else if (toggle) {
          this.setSelected(row, !this.isSelected(row));
      } else {
        this.clearSelection();
        this.setSelected(row, true);
      }

      this.selectedChange.emit(this.selected);
      this.cdr.markForCheck();
    }
  }


  setSelected(item: any, selected: boolean) {
//    console.log('TOGGLE', item, selected);
    if (item) {
      if (selected) {
        item[SELECTED_FLAG] = true;
        this.lastSelected = item;
        if (!this.selected.hasEquals(item)) {
          this.selected.push(item);
        }
      } else {
        delete item[SELECTED_FLAG];
        this.selected.removeAll(item);
      }
    }
  }

  public selectAll() {
    this.data.forEach((item) => this.setSelected(item, true));
    this.selectedChange.emit(this.selected);
  }

  public clearSelection() {
    this.selected.forEach((item) => delete item[SELECTED_FLAG]);
    this.selected.clear();
    this.selectedChange.emit(this.selected);
  }

  loopSelectionSort() {
    this.sortBySelected = selectionSort.next(this.sortBySelected, true) as ('desc' | 'asc' | '');
  }

  private getContextMenu(row, column?: BaseColumn): ContextMenuComponent {
    return typeof this.menu === 'function' ? this.menu(row, column) : this.menu;
  }

  public doCellClick($event: MouseEvent, row: any, column: BaseColumn) {
//    console.log('Cell Click', $event, row, column);
    this.cellClick.emit({row: row, column: column, handled: false});
  }

  public onCellContextMenu($event: MouseEvent, row: any, column: BaseColumn): void {
    // High Priority: Cell Right Click Handler
    const event: CellHandleEvent = {row: row, column: column, handled: false};
    this.cellRightClick.emit(event);
    if (event.handled) {
      return;
    }

    // Lower priority: Cell Context menu
    let menu = this.getContextMenu(row, column);
    if (menu) {
      this.contextMenuService.show.next({
        anchorElement: $event.target,
        // Optional - if unspecified, all context menu components will open
        contextMenu: menu,
        event: <any>$event,
        item: {row: row, column: column},
      });
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  public onRowContextMenu($event: MouseEvent, row: any): void {
    let menu = this.getContextMenu(row);
    if (menu) {
      this.contextMenuService.show.next({
        anchorElement: $event.target,
        // Optional - if unspecified, all context menu components will open
        contextMenu: menu,
        event: <any>$event,
        item: {row: row},
      });
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  toggleExpandCollapse() {
    let rows = this.updater.subject.getValue();
    if (rows) {
      let openRow = rows.find(row => this.isExpanded(row));
      if (openRow) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
    }
  }

  private expandAll(index: number = 0) {
    let rows = this.updater.subject.getValue();
    if (!rows) { return; }
    if (index < rows.length) {
      this.expand(rows[index]).subscribe(() => {
        setTimeout(() => {
          this.expandAll(index + 1);
          this.cdr.markForCheck();
        }, 10);
      });
    }
  }

  private collapseAll(index: number = 0) {
    let rows = this.updater.subject.getValue();
    if (!rows) { return; }
    if (index < rows.length) {
      this.collapse(rows[index]).subscribe(() => {
        // setTimeout(() => {
          this.collapseAll(index + 1);
        // }, 10);
      });
    } else {
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy() {
    delete this.updater;
    this.updater = undefined;

    this.treeModel = undefined;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
//    if (this.socketSub) { this.socketSub.unsubscribe(); }
  }

  public onRowDrop(event: DropEvent, row?: any) {
//    event['row'] = row;
    this.rowDropEvent.next(new DataTableDropEvent(event.nativeEvent, event.dragData, row));
  }

  public onTableDrop(event: DropEvent) {
    this.tableDropEvent.next(new DataTableDropEvent(event.nativeEvent, event.dragData, null));
  }

  public getRowDragScope(row): string | string[] {
    return typeof this.rowDragScope === 'function' ? this.rowDragScope(row) : this.rowDragScope;
  }


  public getRowDropScope(row): string | string[] {
    return typeof this.rowDropScope === 'function' ? this.rowDropScope(row) : this.rowDropScope;
  }

  onOpen(column) {
    console.log('On Open Search Column',  column);
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
        column.ngSelect.initLoadItems();
        column.doSearch('');
      }
    }, 50);
  }

  public reRender() {
    this.rowComponents.forEach((row) => row.reRender());
    this.cdr.markForCheck();
  }
}
