import {OrderMode} from "../order/order-mode";

export enum MasterMode {
  UNDEF = 0, // not specified
  AIR_IMPORT = 1,
  AIR_EXPORT = 2
}

export class MasterModes {
  public static valueOf(key: string):MasterMode {return MasterMode[key]}
}
