var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ValueAccessorBase } from '../value-accessor';
import { message, validate } from './validate';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
var BaseInputComponent = /** @class */ (function (_super) {
    __extends(BaseInputComponent, _super);
    function BaseInputComponent(validators, asyncValidators) {
        var _this = _super.call(this) || this;
        _this.validators = validators;
        _this.asyncValidators = asyncValidators;
        _this.disabled = false;
        _this.placeholder = '';
        _this.identifier = "oms-input-" + BaseInputComponent.current_id++;
        return _this;
    }
    BaseInputComponent.prototype.addValidator = function (validator) {
        this.validators.push(validator);
    };
    BaseInputComponent.prototype.validate = function () {
        return this.model ? validate(this.validators, this.asyncValidators)(this.model.control) : of(null);
    };
    Object.defineProperty(BaseInputComponent.prototype, "isTouched", {
        get: function () {
            return this.model && this.model.control.touched;
        },
        enumerable: true,
        configurable: true
    });
    BaseInputComponent.prototype.touchControl = function () {
        this.model.control.markAsTouched();
    };
    Object.defineProperty(BaseInputComponent.prototype, "invalid", {
        get: function () {
            var _this = this;
            return this.validate().pipe(map((function (v) { return _this.isInvalid = Object.keys(v || {}).length > 0; })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseInputComponent.prototype, "failures", {
        get: function () {
            return this.validate().pipe(map((function (v) { return Object.keys(v).map(function (k) { return message(v, k); }); })));
        },
        enumerable: true,
        configurable: true
    });
    BaseInputComponent.prototype.reset = function () {
        this.model.control.reset();
    };
    BaseInputComponent.current_id = 0;
    return BaseInputComponent;
}(ValueAccessorBase));
export { BaseInputComponent };
