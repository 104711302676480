<span [class]="'alert-dialog'" [ngClass]="dialogClass">
  <h4 style="margin-top: -10px; margin-bottom: 20px;" *ngIf="mode">
    <i [ngClass]="iconClass"></i>&nbsp;{{dialogCaption}}
  </h4>
  {{message}}
  <div class="row" *ngIf="!!button" style="margin: 15px 0 0 0;">
    <div class="btn-toolbar pull-right">
      <a class="btn btn-default" (click)="onButtonClick(button)">
        <i class="{{button?.icon}}"></i>
        {{button?.caption}}
      </a>
    </div>
  </div>
</span>
