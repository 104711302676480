<perfect-scrollbar style="max-height: 90vh;">
  <div #toolbar class="oms-modal widget">
    <div class="widget-header">
      <h3><i class="fa fa-info"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <div class="widget-content" style="align-content: start; min-height: 300px">
      <div class="form-row">
        <!--<div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <th scope="col" class="header">CBP Status</th>
              <th scope="col" class="header">Carrier Code</th>
              <th scope="col" class="header">Originator Code</th>
              <th scope="col" class="header">Flight No</th>
              <th scope="col" class="header">Eta Date</th>
              <th scope="col" class="header">Destination Airport</th>
              <th scope="col" class="header">Transaction Date</th>
              <th scope="col" class="header">Uscs Fsn Entry Type</th>
              <th scope="col" class="header">Fsq Sent Date</th>
              <th scope="col" class="header">Fsq Reference</th>
              <th scope="col" class="header">Transaction Time</th>
              <th scope="col" class="header">Uscs Fsn Entry Number</th>
              <th scope="col" class="header">Fsn Sent Date</th>
              <th scope="col" class="header">Fsn Reference</th>
              <th scope="col" class="header">Total Pieces</th>
              <th scope="col" class="header">Edi Reference Num</th>
              <th scope="col" class="header">Inbound File Name</th>
            </thead>
            <tbody>
              <tr *ngFor="let cbpStatus of cbpStatuses">
                <td>{{cbpStatus.uscsFsnStatus}}</td>
                <td>{{cbpStatus.carrierCode}}</td>
                <td>{{cbpStatus.originatorCode}}</td>
                <td>{{cbpStatus.flightNo}}</td>
                <td>{{cbpStatus.etaDate}}</td>
                <td>{{cbpStatus.destinationAirport}}</td>
                <td>{{cbpStatus.transactionDate}}</td>
                <td>{{cbpStatus.uscsFsnEntryType}}</td>
                <td>{{cbpStatus.fsqSentDate}}</td>
                <td>{{cbpStatus.fsqReference}}</td>
                <td>{{cbpStatus.transactionTime}}</td>
                <td>{{cbpStatus.uscsFsnEntryNumber}}</td>
                <td>{{cbpStatus.fsnSentDate}}</td>
                <td>{{cbpStatus.fsnReference}}</td>
                <td>{{cbpStatus.totalPieces}}</td>
                <td>{{cbpStatus.ediReferenceNum}}</td>
                <td>{{cbpStatus.inboundFileName}}</td>
              </tr>
            </tbody>
          </table>
        </div>-->
        <oms-cbp-status-table [objectId]="data.id"
                              [objectType]="data.objectType"
                              [uscsFsnStatus]="data.uscsFsnStatus">
        </oms-cbp-status-table>
      </div>
    </div>

    <div class="widget-footer">
      <div></div>

      <div class="dialog-btns">
        <button type="button" class="btn btn-default" (click)="generateExcel()">Generate Excel</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div>
  </div>
</perfect-scrollbar>
