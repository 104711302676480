var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { HttpUtilsService } from './http-utils.service';
import { FreightForwarder } from '../modules/shared/models/freight.forwarder';
import { RestService } from './rest.service';
import { BaseEntityService } from './base/base-entity.service';
import { OmsDialogsService } from '../components/common/oms-dialogs/oms-dialogs.service';
var FreightForwarderService = /** @class */ (function (_super) {
    __extends(FreightForwarderService, _super);
    function FreightForwarderService(httpRestService, httpUtils, dialogs) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.dialogs = dialogs;
        _this.apiUrl = '/oms/freight-forwarder/';
        _this.classType = FreightForwarder;
        _this.freightForwarderSubject = new Subject();
        _this.freightForwarderPopUpEvent = new Subject();
        return _this;
    }
    FreightForwarderService.prototype.showFreightForwarderPopUp = function (item) {
        this.freightForwarderPopUpEvent.next({ show: true, freightForwarder: item });
    };
    FreightForwarderService.prototype.hideFreightForwarderPopUp = function () {
        this.freightForwarderPopUpEvent.next({ show: false, freightForwarder: null });
    };
    FreightForwarderService.prototype.getAllFreightForwarders = function () {
        var _this = this;
        this.findAll().then(function (items) {
            _this.freightForwarderSubject.next(items);
        });
    };
    return FreightForwarderService;
}(BaseEntityService));
export { FreightForwarderService };
