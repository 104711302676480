<div class="form-group">
  <label>{{label}}</label>
  <div class="input-group" style="width: 100%">

    <span *ngIf="hasToggle" class="input-group-addon" style="padding: 0; vertical-align: bottom; text-align: -webkit-match-parent; padding-left: 8px;">
        <oms-toggle-switch [(ngModel)]="value || isEdit" (ngModelChange)="value = null"></oms-toggle-switch>
    </span>
  <!--  <span class="input-group-addon">
        <span class="fancy-checkbox custom-bgcolor-green" (click)="onCbClick()">
          <input type="checkbox" [(ngModel)]="value || isEdit" (ngModelChange)="value = null" class="table-checkbox" title="">
          <span></span>
        </span>
    </span>-->


    <textarea [attr.disabled]="value || isEdit? null : ''"  class="form-control" placeholder="Details" [(ngModel)]="value" rows="3"></textarea>

  </div>

</div>

