var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { BaseEntity } from "../base-entity";
import { Exclude, Transform, Type } from "class-transformer";
import { Address, Customer, FreightForwarder, LoadType, Order, Shipment, Trailer } from '..';
import { PackagingUnits } from "../../../../common/oms-unit-types";
import { DispatchShipmentUld } from './dispatch-shipment-uld';
import { transformLoadType } from "../load";
import { isNullOrUndefined } from "util";
import { OrderModes } from "../order/order-mode";
import { assigned } from "../../../../_helpers/utils";
var ɵ0 = function () { return Customer; }, ɵ1 = function () { return FreightForwarder; }, ɵ2 = function () { return Address; }, ɵ3 = function () { return Address; }, ɵ4 = function () { return PackagingUnits; }, ɵ5 = function () { return PackagingUnits; }, ɵ6 = function () { return Shipment; }, ɵ7 = function () { return Trailer; }, ɵ8 = function () { return DispatchShipmentUld; }, ɵ9 = function () { return Order; }, ɵ10 = function (v, o, tt) { return transformLoadType(v, o, tt); };
var ManifestItem = /** @class */ (function (_super) {
    __extends(ManifestItem, _super);
    function ManifestItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dispatchShipmentUlds = [];
        _this.orders = [];
        _this.loadType = LoadType.DELIVERY;
        _this.loadTypeOriginal = LoadType.DELIVERY;
        return _this;
    }
    ManifestItem.suggestLoadType = function (orderMode, previousLeg) {
        //    console.log('SUGGEST>>', orderMode, previousLeg);
        if (isNullOrUndefined(orderMode)) {
            // Air Import
            return LoadType.DELIVERY;
        }
        if (OrderModes.isRecovery(orderMode)) {
            return LoadType.RECOVERY;
        }
        if (OrderModes.isAirExport(orderMode)) {
            return LoadType.DELIVERY;
        }
        return !previousLeg ? LoadType.PICKUP : LoadType.DELIVERY;
    };
    ManifestItem.composeDispatchComments = function (o) {
        var s = (o.dispatchNotes || '').toLocaleUpperCase();
        if (o.hot) {
            s = 'URGENT ** ' + s;
        }
        if (o.cod) {
            s = 'COD * ' + s;
        }
        return s;
    };
    ManifestItem.createFromDispatchDto = function (dispatch, deliveryAddress) {
        console.log('CREATE FROM DispatchDto', dispatch, deliveryAddress);
        var item = new ManifestItem();
        item.shipmentId = dispatch.dispatchShipmentId;
        item.addressPickUp = dispatch.addressPickup;
        item.addressDelivery = dispatch.addressDelivery;
        item.masterId = dispatch.masterId;
        item.orderId = dispatch.orderId;
        item.mawb = dispatch.mawb;
        item.hawb = dispatch.hawb;
        item.hu = dispatch.hu;
        item.pieces = dispatch.pieces;
        item.weight = dispatch.weight;
        item.customer = dispatch.customer;
        item.customerRef = dispatch.customerRef;
        item.comments = this.composeDispatchComments(dispatch);
        item.dispatchShipmentUlds = [];
        item.uldCount = dispatch.uldCount;
        console.log('>>>', dispatch.dispatchId, dispatch.loadType);
        item.loadTypePreviousLeg = dispatch.dispatchId ? dispatch.loadType : null;
        item.loadTypeOriginal = item.loadType = this.suggestLoadType(dispatch.orderMode, item.loadTypePreviousLeg);
        console.log('SUGGEST >>>', item.loadType);
        var o = Order.fromOrderDispatch(dispatch);
        o.info.legAddressDelivery = deliveryAddress;
        item.orders.push(o);
        //    console.log('createFromDispatchDto', dispatch, item);
        return item;
    };
    ManifestItem.createFromOrder = function (order) {
        console.log('CREATE FROM ORDER', order);
        var item = new ManifestItem();
        item.shipmentId = order.shipmentContents.map(function (shipmentContent) { return shipmentContent.shipment.id; }).first();
        item.addressPickUp = order.addressCfs;
        item.addressDelivery = order.addressDelivery;
        item.masterId = order.masterID;
        item.orderId = order.id;
        item.mawb = order.mawb;
        item.hawb = order.hawb;
        item.hu = order.hu;
        item.pieces = order.pieces;
        item.weight = order.weight;
        item.customer = order.customer;
        item.customerRef = order.customerRef;
        item.comments = undefined;
        item.dispatchShipmentUlds = [];
        item.uldCount = order.uldCount;
        item.loadTypePreviousLeg = LoadType.PICKUP;
        item.loadTypeOriginal = item.loadType = this.suggestLoadType(order.genericMode, item.loadTypePreviousLeg); // ([LoadType.PICKUP, LoadType.X_DOCK].includes(item.loadTypePreviousLeg) ? LoadType.DELIVERY : LoadType.PICKUP);
        order.info.legAddressDelivery = order.addressDelivery;
        item.orders.push(order);
        return item;
    };
    ManifestItem.prototype.equalsWithDispatchDto = function (dispatch) {
        return this.masterId === dispatch.masterId && this.orderId === dispatch.orderId;
    };
    ManifestItem.prototype.hasOrder = function (orderId) {
        return this.orders.some(function (o) { return o.id === orderId; });
    };
    Object.defineProperty(ManifestItem.prototype, "isSingleOrder", {
        get: function () {
            return this.orders && this.orders.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestItem.prototype, "consolidated", {
        get: function () {
            return this.orders && this.orders.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestItem.prototype, "mixedRefs", {
        get: function () {
            var refs = this.orders.map(function (o) { return o.customerRef; }).filter(function (s, index, array) { return !s || array.indexOf(s) === index; });
            return refs.length !== 1;
            //    let refs = this.orders.map(o=>o.customerRef).filter(ref=>!!ref).duplicates();
            //    return refs.length>1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestItem.prototype, "mixedDelivery", {
        get: function () {
            return !this.orders.asUniqueValue(function (o) { return o.info.legAddressDelivery && o.info.legAddressDelivery.id; });
        },
        enumerable: true,
        configurable: true
    });
    ManifestItem.prototype.hasAirImport = function () {
        return assigned(this.orders.find(function (o) { return OrderModes.isAirImport(o.genericMode); }));
    };
    Object.defineProperty(ManifestItem.prototype, "isSelectedPartially", {
        get: function () {
            var i = 0;
            this.orders.forEach(function (o) { if (o.selected) {
                i++;
            } });
            return i > 0 && i < this.orders.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestItem.prototype, "isSelectedFully", {
        get: function () {
            return !this.orders.find(function (o) { return !o.selected; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestItem.prototype, "hasSelected", {
        get: function () {
            return !isNullOrUndefined(this.orders.find(function (o) { return o.selected; }));
        },
        enumerable: true,
        configurable: true
    });
    ManifestItem.prototype.update = function () {
        var _this = this;
        this.pieces = this.weight = this.hu = 0;
        this.orders.forEach(function (o) {
            _this.pieces += +o.pieces;
            _this.hu += +o.hu;
            _this.weight += +o.weight;
        });
    };
    ManifestItem.prototype.updateAddressDelivery = function () {
        this.addressDelivery = this.orders.asUniqueValue(function (o) { return o.info.legAddressDelivery; });
    };
    Object.defineProperty(ManifestItem.prototype, "isRecovery", {
        get: function () {
            return this.loadType === LoadType.RECOVERY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestItem.prototype, "isDirect", {
        get: function () {
            return this.loadType === LoadType.DIRECT;
        },
        enumerable: true,
        configurable: true
    });
    ManifestItem.prototype.isNotCompleted = function () {
        return this.shipment && this.shipment.isNotCompleted();
    };
    ManifestItem.prototype.isCompleted = function () {
        return this.shipment && this.shipment.isCompleted();
    };
    ManifestItem.prototype.isCompletedWithProblem = function () {
        return this.shipment && this.shipment.isCompletedWithProblem();
    };
    ManifestItem.prototype.isUpdatedByDriver = function () {
        return this.shipment &&
            (this.shipment.hasProblem || assigned(this.shipment.isRecovery() ? this.shipment.datePickUpActual : this.shipment.dateDeliveryActual));
    };
    ManifestItem.prototype.isRecoveryDirect = function () {
        return this.isDirect && this.orders.hasEquals(function (o) { return o.isRecovery; });
    };
    Object.defineProperty(ManifestItem.prototype, "rld", {
        get: function () {
            return this.orders.find(function (o) { return o.isRecovery; });
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean)
    ], ManifestItem.prototype, "selected", void 0);
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Customer)
    ], ManifestItem.prototype, "customer", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", FreightForwarder)
    ], ManifestItem.prototype, "freightForwarder", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Address)
    ], ManifestItem.prototype, "addressPickUp", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Address)
    ], ManifestItem.prototype, "addressDelivery", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", PackagingUnits)
    ], ManifestItem.prototype, "hu_units", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", PackagingUnits)
    ], ManifestItem.prototype, "pieces_units", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Shipment)
    ], ManifestItem.prototype, "shipment", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", Trailer)
    ], ManifestItem.prototype, "trailer", void 0);
    __decorate([
        Type(ɵ8),
        __metadata("design:type", Array)
    ], ManifestItem.prototype, "dispatchShipmentUlds", void 0);
    __decorate([
        Type(ɵ9),
        __metadata("design:type", Array)
    ], ManifestItem.prototype, "orders", void 0);
    __decorate([
        Transform(ɵ10),
        __metadata("design:type", Number)
    ], ManifestItem.prototype, "loadType", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Number)
    ], ManifestItem.prototype, "loadTypeOriginal", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Number)
    ], ManifestItem.prototype, "loadTypePreviousLeg", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "isSingleOrder", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "consolidated", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "mixedRefs", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "mixedDelivery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], ManifestItem.prototype, "hasAirImport", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "isSelectedPartially", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "isSelectedFully", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "hasSelected", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "isRecovery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ManifestItem.prototype, "isDirect", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], ManifestItem.prototype, "isNotCompleted", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], ManifestItem.prototype, "isCompleted", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], ManifestItem.prototype, "isCompletedWithProblem", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], ManifestItem.prototype, "isUpdatedByDriver", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Boolean)
    ], ManifestItem.prototype, "isRecoveryDirect", null);
    return ManifestItem;
}(BaseEntity));
export { ManifestItem };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
