﻿import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AddressService} from '../../../services';
import {Address, AddressType} from '../../../modules/shared/models';
import {AddressDialogComponent} from '../../../modules/shared/components/address-dialog';
import {MatDialog} from '@angular/material';
import {asArray} from '../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {OmsDialogsService} from '../oms-dialogs';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {Size} from '../../../common/oms-types';
import {BaseInputSelectComponent} from '../../../modules/shared/components/base/base-input/base-input-select.component';
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {NgSelectSearchParams} from '../../../modules/settings/util/ng-select-search-params';
import {convertAddress} from "../../../modules/shared/services/oms-converters.service";
import {FilterSearchColumn} from '../../../modules/shared/models/filter.search.column';
import {Logger} from "../../../_helpers/logger";
import {KeyCode} from "../../../modules/shared/components/common/input/ng-select-types/ng-select.types";

@Component({
  templateUrl: 'address-search.component.html',
  selector: 'oms-address-search',
  styleUrls: ["./address-search.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: AddressSearchComponent, multi: true}],

})
@Logger({})
export class AddressSearchComponent extends BaseInputSelectComponent<any> implements OnInit, OnChanges, OnDestroy {

  static initial_id = 0;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  @ViewChild('searchInput') searchInput: ElementRef;


  @Input() separateSearch: boolean = false;  // experimental
  @Input() size: Size = Size.DEFAULT;
  @Input() address: Address;
  @Output() addressChange = new EventEmitter();
  @Input() addressTypes: AddressType[];
  @Input() items: Address[];
  @Input() allowCreate: boolean = true;
  @Input() allowClear: boolean = true;
  @Input() count: number;
  @Input() showTooltip: boolean = true;
  @Input() isInvalid: boolean = false;

  identifier = 'address_search_' + AddressSearchComponent.initial_id++;
  Size = Size;
  showAllAddresses = false;
  addressSearch: NgSelectSearchParams<Address>;

  // @Input() customerFn: ()=>Customer;   //Specific for searching Customer Related addresses; Currently used for Delivery Locations

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    public addressService: AddressService,
    public dialog: MatDialog,
    public alerts: OmsAlertsService,
    public dialogs: OmsDialogsService) {
    super(validators, asyncValidators);
    this.iconClass = 'fa fa-map-marker';
    this.addressService.addressPopUpEvent.subscribe(data => {
    });
  }

  hideAddressSearch() {
    this.addressService.hideAddressPopUp();
  }

  isControlInvalid(): boolean {
    // const control = this.form.controls[controlName];
    // const result = control.invalid && control.touched;
    return false;
  }

  onOpen() {
    if (this.separateSearch) {
      setTimeout(() => {this.searchInput.nativeElement.focus(); }, 10);
    }
  }

  openCreateAddressDialog() {
    this.ngSelect.close();
    const address = new Address();
    address.types = this.addressTypes;

    const dialogRef = this.dialog.open(AddressDialogComponent, {
      width: 'auto',
      data: {address: address, disabledTypes: this.addressTypes}
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.address = res.res;
        this.changeAddress(res.res);
      }
    });
  }

  changeAddress(address) {
    this.isOpen = false;
    this.addressChange.emit(address);
  }

  ngOnInit() {
    this.addressSearch = new NgSelectSearchParams<Address>(this.addressService, 'name');
    this.addressSearch.setOperation('or');
    this.addressSearch.setSearchIsActive(!this.showAllAddresses);
    this.addressTypes = asArray(this.addressTypes);
    this.updateSearchByAddressType();
  }

  get allowShowAll(): boolean {
    return !isNullOrUndefined(this.addressTypes);
  }

  search() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.count) {
      this.addressSearch.request.pageSize = this.count;
    }
    if (changes.showAllAddresses) {
      this.addressSearch.setSearchIsActive(!this.showAllAddresses);
    }
    if (changes.addressTypes) {
      this.updateSearchByAddressType();
    }
  }

  private updateSearchByAddressType() {
    if (!this.addressSearch) {
      return;
    }

    this.addressSearch.request.filterByColumns = (this.addressTypes || []).map(type => {
      return new FilterSearchColumn(this.getFieldName(type), 'true');
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getState(address: Address) {
    if (isNullOrUndefined(address.usaState)) {
      return "";
    }
    return ", " + address.usaState.name;
  }

  getWithComa(data: any) {
    return isNullOrUndefined(data) ? "" : ", " + data;
  }

  private getFieldName(num: number): string {
    const arr = ['cargoBuilding', 'cfsLocation', 'cfs3plLocation', 'pickupLocation', 'deliveryLocation'];
    return arr[num];
  }

  getTooltipValue(address: Address) {
    return this.showTooltip ? convertAddress(address) : null;
  }

  ngOnDestroy() {
    this.addressSearch.destroy();
  }

  onKeyDown(event) {
    if (event.keyCode === KeyCode.Tab) {
      if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value) {
        this.addressChange.emit(this.ngSelect.itemsList.markedItem.value);
      }
      this.ngSelect.filterValue = null;
      this.isOpen = false;
    }
  }
}
