import { QueryList } from '@angular/core';
import { OrdersService } from "../../../../../services";
import { OmsAlertsService } from "../../../../shared/components/oms-alerts/oms-alerts.service";
import { MatDialogRef } from "@angular/material";
import { NgModel } from "@angular/forms";
import { convertOrderNumber } from "../../../../shared/services/oms-converters.service";
import { assigned } from "../../../../../_helpers/utils";
var SplitOrderDialogComponent = /** @class */ (function () {
    function SplitOrderDialogComponent(orderService, alerts, dialogRef, data) {
        this.orderService = orderService;
        this.alerts = alerts;
        this.dialogRef = dialogRef;
        this.data = data;
        this.query = false;
        this.equalsHu = false;
        this.showWeight = true;
        this.order = data.order;
        this.fnOk = data.onOk;
        this.externalHandler = data.externalHandler;
        if (this.data.order) {
            this.originalPcs = this.pcs = ~~this.order.pieces;
            this.originalHu = this.hu = ~~this.order.hu;
            this.originalWeight = this.weight = ~~this.order.weight;
        }
        else {
            this.originalPcs = this.pcs = this.data.pcs;
            this.originalHu = this.hu = this.data.hu;
            this.originalWeight = this.weight = this.data.weight;
        }
        this.kgPerPiece = this.weight && this.pcs ? this.weight / this.pcs : 0.0;
        if (!this.hu) {
            this.hu = this.pcs;
        }
        this.equalsHu = this.pcs === this.hu;
        if (assigned(data.showWeight)) {
            this.showWeight = data.showWeight;
        }
    }
    /*  validate() {
        return this.inputs.forEach(i=>{
          i.control.markAsTouched();
          if (i.valueAccessor instanceof BaseInputComponent) {
            i.valueAccessor.touchControl();
            i.valueAccessor.validate();
    //        return i.invalid;
          }
    
        });
      } */
    SplitOrderDialogComponent.prototype.onOk = function (event) {
        var _this = this;
        event.canClose = false;
        if (this.externalHandler) {
            this.query = true;
            this.externalHandler(~~this.pcs, ~~this.hu, ~~this.weight).subscribe(function (close) {
                _this.query = false;
                if (close) {
                    _this.dialogRef.close();
                }
            }, function (error) {
                _this.query = false;
                _this.alerts.error(error);
            });
        }
        else {
            this.query = true;
            this.orderService.splitOrder(this.order.id, ~~this.pcs, ~~this.hu, ~~this.weight)
                .then(function (splitted) {
                _this.query = false;
                var newPcs = _this.order.pieces - splitted.pieces;
                var newHu = _this.order.hu - splitted.hu;
                var newWeight = _this.order.weight - splitted.weight;
                _this.order.pieces = newPcs;
                _this.order.hu = newHu;
                _this.order.weight = newWeight;
                _this.fnOk(newPcs, newHu, newWeight, splitted);
                _this.alerts.success(convertOrderNumber(_this.order.id) + " " + _this.order.pieces + " pcs changed to " + newPcs + " pcs;\n" + convertOrderNumber(splitted.id) + " created for " + splitted.pieces + " pcs;", 5000);
                _this.dialogRef.close();
            })
                .catch(function (error) {
                _this.query = false;
                _this.alerts.error(error, "Error Splitting Order:");
            });
        }
    };
    Object.defineProperty(SplitOrderDialogComponent.prototype, "label", {
        get: function () {
            return this.data.label || (this.order ? "Split order " + convertOrderNumber(this.order.id) + "..." : 'Split...');
        },
        enumerable: true,
        configurable: true
    });
    SplitOrderDialogComponent.prototype.onPcsChange = function (pcs) {
        this.weight = pcs * this.kgPerPiece;
        if (this.equalsHu) {
            this.hu = pcs;
        }
    };
    SplitOrderDialogComponent.prototype.onHuChange = function (event) {
        if (this.pcs !== this.hu) {
            this.equalsHu = false;
        }
    };
    return SplitOrderDialogComponent;
}());
export { SplitOrderDialogComponent };
