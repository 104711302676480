import {Injectable} from '@angular/core';
import {Labeled} from '../../../../common/oms-types';

export enum DispatchLoadTypeId {
  RECOVERY=1,
  DELIVERY = 2
}

export class DispatchLoadType implements Labeled {
  public static items: DispatchLoadType[] = [];
  private static map: Map<DispatchLoadTypeId, DispatchLoadType> = new Map<DispatchLoadTypeId, DispatchLoadType>();

  id: DispatchLoadTypeId;
  label: string;
  warning: boolean;

  constructor(id: DispatchLoadTypeId, label:string, warning?:boolean) {
    this.label = label;
    this.id = id;
    this.warning = warning;
  }

  public static getLabel(id: DispatchLoadTypeId): string {
    return DispatchLoadType.map.has(id) ?  this.map.get(id).label : null;
  }

  public static get(id: DispatchLoadTypeId): DispatchLoadType {
    return this.map.has(id) ?  this.map.get(id) : null;
  }


  static initialize() {
    DispatchLoadType.items.push(
      new DispatchLoadType(DispatchLoadTypeId.RECOVERY, '1. Recovery'),
      new DispatchLoadType(DispatchLoadTypeId.DELIVERY, '2. Delivery'),
    );
    DispatchLoadType.items.forEach(status=>{DispatchLoadType.map.set(status.id, status)});
  }
}

DispatchLoadType.initialize();

@Injectable()
export class DispatchLoadTypeService {
  public getLabel(id: DispatchLoadTypeId): string {return DispatchLoadType.getLabel(id);}
  get items(): DispatchLoadType[] { return DispatchLoadType.items;}
}


