﻿import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services';
import {UserRoleType} from "../modules/shared/models/user-role.type";
import {LoggedUser} from "../modules/shared/models/logged-user";
import {AccessPageType} from "../modules/shared/models/access/access-page";

@Injectable()
export class AuthGuard implements CanActivate {

  private static readonly DEFAULT_AVAILABLE_ROLES: UserRoleType[] = [
    UserRoleType.ROLE_ADMIN,
    // UserRoleType.ROLE_USER,
    // UserRoleType.ROLE_WAREHOUSE,
    UserRoleType.ROLE_QA
  ];

  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = this.authService.getCurrentUser();
    if (currentUser) {
      if (route.data.restricted && this.authService.isRestricted()) {
        return false;
      }

      const redirectPath = this.getRedirectPath(currentUser, route, state);
      if (redirectPath) {
        this.router.navigate([redirectPath]);
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
//      this.authService.logOut(state.url);
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }

  private getRedirectPath(currentUser: LoggedUser, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    let availableRoles: string[] = route.data.roles || AuthGuard.DEFAULT_AVAILABLE_ROLES;
    if (currentUser.hasAnyRoles(availableRoles)) {
      return null;
    }
    /*if (currentUser.hasRole(UserRoleType.ROLE_EXTERNAL_WAREHOUSE)
      && currentUser.profile
      && currentUser.profile.resources.length) {
      const currentState = this.getCurrentState(state.url);
      const haveAccess = currentUser.profile.resources.some(r => r.path === currentState);
      if (haveAccess) {
        return null;
      }
      return currentUser.profile.resources[0].path;
    }*/
    const currentState = this.getCurrentState(state.url);
    if (currentUser.hasRole(UserRoleType.ROLE_WAREHOUSE)) {
      if (AccessPageType.WAREHOUSE_LIST === currentState) {
        return null;
      }
      return AccessPageType.WAREHOUSE_LIST;
    }
    if (currentUser.hasRole(UserRoleType.ROLE_EXTERNAL_WAREHOUSE)) {
      if (AccessPageType.WAREHOUSE_UPDATE === currentState) {
        return null;
      }
      return AccessPageType.WAREHOUSE_UPDATE;
    }
    if (currentUser.hasRole(UserRoleType.ROLE_DRIVER)) {
      if (AccessPageType.WEB_DRIVERS === currentState) {
        return null;
      }
      return AccessPageType.WEB_DRIVERS;
    }
    return '/403';
  }

  private getCurrentState(url: string): string {
    let state = url.split(';', 2)[0];
    state = state.split('?', 2)[0];
    return state;
  }
}
