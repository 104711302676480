<ng-container
  *ngIf="isVisible"
  class="masters-table">

  <oms-data-table #mastersTable

                  (update)="onUpdateField($event)"
                  (expand)="onExpandItem($event)"
                  [treeModel]="this"
                  [fixed-columns]="11"
                  [bordered]="false"
                  [striped]="true"
                  [condensed]="true"
                  [selectable]="true"
                  [select-by-row]="false"
                  [keep-selected]="true"
                  [selected]="selected"
                  [searchable]="searchable"
                  [multi-select]="true"
                  [defaultColumnForSort]="getDefaultSortableColumn()"
                  [fixed]="false"
                  [readonly]="clientUser"
                  [columns]="columns"
                  (dataSelectedChange)="onSelectMaster($event)"
                  [checkboxes]="tabInfo?.showCheckboxes"
                  (dataDblClick)="showMaster($event)"
                  [data]="items"
                  (searchCriteriaChanged)="updateData($event)"
                  [activeSearchFilterCondition]="activeSearchFilterCondition"
                  [paging]="paging"
                  (pageSizeChange)="onPageSizeChanged($event)"
                  [searchDisabled]="pageRequest"
                  [menu]="menu"
                  (cell-right-click)="doCellRightClick($event)"
                  (cell-click)="doCellClick($event)"
                  [virtualization]="false"
                  [use-ps]="false"
                  [filter]="filter"
                  [contentDrag]="false"
                  [row-class]="getRowClass.bind(this)">

    <ng-template #totalTmp let-paging="paging">
      <li class="paging-item">
        <div>
          Total: {{paging?.total}}
        </div>
      </li>
      <li class="paging-item">
        <div>
          PCS: {{totalPcs}}
        </div>
      </li>
      <li class="paging-item">
        <div>
          HU: {{totalHu}}
        </div>
      </li>
      <li class="paging-item">
        <div>
          Weight: {{totalWeight | number:'0.0-1'}} KG
        </div>
      </li>
      <li class="paging-item">
        <div>
          ULD: {{totalUld}}
        </div>
      </li>

    </ng-template>
  </oms-data-table>
</ng-container>
