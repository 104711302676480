var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from '../base/base-entity.service';
import { Subject } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { HttpUtilsService } from '../http-utils.service';
import { RestService } from '../rest.service';
import { map, share, tap } from 'rxjs/operators';
import { Master } from "../../modules/shared/models";
var MasterLineService = /** @class */ (function (_super) {
    __extends(MasterLineService, _super);
    function MasterLineService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/master-line/';
        _this.classType = Master;
        _this.mastersPageSubject = new Subject();
        return _this;
    }
    MasterLineService.prototype.getMastersByFilterSearchRequest = function (filterPageRequest, onError, onSuccess) {
        var _this = this;
        if (!filterPageRequest) {
            return;
        }
        //    console.time('Page Request');
        this.lastPageRequest = filterPageRequest;
        return this.httpRestService.postWithHeader(this.apiUrl + 'findByFilters', this.httpUtils.getHTTPHeaders(), filterPageRequest)
            .pipe(share(), map(function (response) {
            //          console.timeEnd('Page Request');
            //          console.time('Page plainToClass');
            try {
                if (response && response.content) {
                    response.content = response.content.map(function (m) {
                        return _this.afterLoad(plainToClass(Master, m));
                    });
                }
            }
            catch (e) {
                console.log('plainToClass error', e);
            }
            // console.timeEnd('Page plainToClass');
            return response;
        }), tap(function (response) {
            //          console.time('Subject Next');
            _this.mastersPageSubject.next(response);
            //          console.timeEnd('Subject Next');
            if (onSuccess) {
                onSuccess(response);
            }
        }, function (error) {
            //          console.timeEnd('Page Request');
            console.log('error', error);
            if (onError) {
                onError(error);
            }
        }));
    };
    MasterLineService.prototype.refreshByLastPageRequest = function () {
        this.getMastersByFilterSearchRequest(this.lastPageRequest);
    };
    return MasterLineService;
}(BaseEntityService));
export { MasterLineService };
