var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { DataTableComponent } from '../../shared/components/base/data-table/data-table.component';
import { FilterPageRequest } from '../../shared/models/filter.page.request';
import { SearchSortRequest } from '../../shared/models/search.sort.request';
import { BaseDeletable } from '../../shared/models/base-deletable';
import { DialogType, ModalResult } from "../../../components/common/oms-dialogs";
import { share } from 'rxjs/operators';
import { AbstractComponent } from "../../../common/component/abstract.component";
import { Subject } from "rxjs/Subject";
var AbstractEntityListComponent = /** @class */ (function (_super) {
    __extends(AbstractEntityListComponent, _super);
    function AbstractEntityListComponent(cdr, alerts, itemService, dialogs, fileUploadService, spinner) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.itemService = itemService;
        _this.dialogs = dialogs;
        _this.fileUploadService = fileUploadService;
        _this.spinner = spinner;
        _this.items = [];
        _this.selected = [];
        _this.paging = { enabled: true, pageSize: 100, pageSizes: [10, 20, 50, 100, 200, 500] };
        _this.showArchived = false;
        _this.baseFilterColumns = [];
        _this.tableFilterColumns = [];
        _this.items$ = new Subject();
        return _this;
    }
    AbstractEntityListComponent.prototype.createDefaultRequest = function (sortField, sortedAsc) {
        if (sortedAsc === void 0) { sortedAsc = true; }
        return new FilterPageRequest(1, this.paging.pageSize, '', new SearchSortRequest(sortField, sortedAsc), []);
    };
    AbstractEntityListComponent.prototype.applySearchText = function () {
        this.table.changeSearchText(this.request.findByOccurs, 0);
    };
    AbstractEntityListComponent.prototype.updateItems = function (select) {
        var _this = this;
        if (this.spinner) {
            this.spinner.show().then();
        }
        var obs = this.itemService.findByFilters(this.request, !this.showArchived).pipe(share());
        obs.subscribe(function (response) {
            _this.paging.total = response.totalElements;
            _this.items = response.content;
            console.log('RESPONSE', _this.items);
            _this.items$.next(_this.items);
            if (select) {
                _this.selected = [select];
            }
            if (_this.spinner) {
                _this.spinner.hide().then();
            }
            _this.cdr.detectChanges();
        }, function (error) {
            _this.alerts.danger(error);
            if (_this.spinner) {
                _this.spinner.hide().then();
            }
        });
        return obs;
    };
    AbstractEntityListComponent.prototype.getViewMode = function () {
        return null;
    };
    AbstractEntityListComponent.prototype.downloadExcel = function () {
        var _this = this;
        this.itemService.downloadExcel(this.request, this.alerts, !this.showArchived, this.getViewMode())
            .subscribe(function (res) {
            var fileURL = _this.buildShowUrl(res);
            window.open(fileURL, '_blank');
        });
    };
    AbstractEntityListComponent.prototype.downloadExcelByFilter = function (filterType) {
        var _this = this;
        this.itemService.downloadExcel(this.request, this.alerts, !this.showArchived, filterType)
            .subscribe(function (res) {
            var fileURL = _this.buildShowUrl(res);
            window.open(fileURL, '_blank');
        });
    };
    AbstractEntityListComponent.prototype.buildShowUrl = function (generatedDocumentInfo) {
        return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
    };
    // noinspection JSUnusedLocalSymbols
    AbstractEntityListComponent.prototype.onShowArchived = function (event) {
        console.log("ON SHOW ACTIVE");
        this.updateItems();
    };
    AbstractEntityListComponent.prototype.onSearch = function (request) {
        this.tableFilterColumns = request.filterByColumns;
        this.request.sort = request.sort;
        this.request.filterByColumns = this.baseFilterColumns.concat(request.filterByColumns);
        this.request.pageNumber = request.pageNumber;
        this.request.pageSize = request.pageSize;
        this.updateItems();
    };
    Object.defineProperty(AbstractEntityListComponent.prototype, "noSelected", {
        get: function () {
            return this.selected.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractEntityListComponent.prototype, "singleSelected", {
        get: function () {
            return this.selected.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractEntityListComponent.prototype, "multiSelected", {
        get: function () {
            return this.selected.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractEntityListComponent.prototype, "objectSelected", {
        get: function () {
            return this.selected.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractEntityListComponent.prototype, "allowRestore", {
        get: function () {
            return this.selected.length > 0 && !this.selected.some(function (item) {
                if (item instanceof BaseDeletable) {
                    return !item.deleted;
                }
                return false;
            });
        },
        enumerable: true,
        configurable: true
    });
    // Update Row Field
    AbstractEntityListComponent.prototype.onUpdate = function (event) {
        this.updateItems();
    };
    AbstractEntityListComponent.prototype.deleteSelected = function () {
        var _this = this;
        if (!this.noSelected) {
            this.dialogs.confirm(DialogType.CONFIRMATION, 'Archive items?', "Are you sure you want archive " + this.selected.length + " selected items?")
                .then(function (result) {
                if (result.result === ModalResult.YES) {
                    _this.itemService
                        .softDelete(_this.selected.map(function (item) { return item.id; }))
                        .then(function () {
                        _this.updateItems();
                    });
                }
            });
        }
    };
    AbstractEntityListComponent.prototype.restoreSelected = function () {
        var _this = this;
        var promises = this.selected
            .filter(function (item) {
            if (item instanceof BaseDeletable) {
                return item.deleted;
            }
            return false;
        })
            .map(function (customer) { return _this.itemService.restore(customer.id); });
        Promise.all(promises)
            .then(function () { return _this.updateItems(); });
    };
    AbstractEntityListComponent.prototype.changeSearchText = function (searchText, debounceTime) {
        if (debounceTime === void 0) { debounceTime = 1500; }
        if (this.table) {
            this.table.changeSearchText(this.request.findByOccurs, debounceTime);
        }
        else {
            this.updateItems();
        }
    };
    AbstractEntityListComponent.prototype.clearAllColumns = function () {
        this.request.findByOccurs = '';
        this.table.clearTableInputs();
    };
    AbstractEntityListComponent.prototype.isTableFilterNotClear = function () {
        return !this.table.isClearTableInputs();
    };
    AbstractEntityListComponent.prototype.setBaseFilterColumns = function (baseFilterColumns) {
        this.baseFilterColumns = baseFilterColumns;
        this.request.filterByColumns = baseFilterColumns.concat(this.tableFilterColumns);
    };
    AbstractEntityListComponent.prototype.update = function (item) {
        this.items.replaceAll(item, item);
    };
    AbstractEntityListComponent.prototype.refreshSelected = function () {
        var _this = this;
        return new Promise(function (confirm, reject) {
            var ids = _this.selected.map(function (i) { return i.id; }).filter(function (id) { return id > 0; });
            _this.itemService.getMany(ids).subscribe(function (result) {
                _this.selected = result;
                _this.table.refresh(result);
                _this.cdr.markForCheck();
                confirm(result);
            }, function (error) {
                reject(error);
            });
        });
    };
    return AbstractEntityListComponent;
}(AbstractComponent));
export { AbstractEntityListComponent };
