var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { OmsAlertsService } from '../../../shared/components/oms-alerts/oms-alerts.service';
import { isNullOrUndefined } from 'util';
import { Manifest } from '../../../shared/models/manifest/manifest';
import { Size } from '../../../../common/oms-types';
import { TruckService } from '../../../../services/truck-service';
import { TrailerService } from '../../../../services/trailer-service';
import { DriverService } from '../../../../services/driver-service';
import { ManifestItem } from '../../../shared/models/manifest/manifest-item';
import { AddressType, Driver, LoadType, LoadTypes, Trailer, Truck } from '../../../shared/models';
import { DispatchService } from '../../../shared/services/dispatch/dispatch.service';
import { convertDateTime, convertLoadNumber, convertManifestNumber, convertMawbNumber, convertOrderNumber, } from '../../../shared/services/oms-converters.service';
import { UldsDialogComponent } from '../../../../components/common/ulds-dialog/ulds-dialog.component';
import { CustomSearchComponent } from '../../../shared/components/common/input/custom-search/custom-search.component';
import { NgModel } from "@angular/forms";
import { BaseInputComponent } from "../../../shared/components/base/base-input/base-input.component";
import { OmsConstants } from "../../../../common/oms-constants.service";
import { SplitOrderDialogComponent } from "./split-order-dialog/split-order-dialog.component";
import { BUTTONS_CONTINUE_CANCEL, DialogType, ModalResult, OmsDialogsService } from "../../../../components/common/oms-dialogs";
import { AuthService, OrdersService } from "../../../../services";
import { DateTimeService } from "../../../../services/date-time.service";
import { UserService } from "../../../shared/services/user.service";
import { map } from 'rxjs/operators';
import { PageResult } from "../../../shared/models/query-models/page-result";
import { OrdersDispatchDialogComponent } from "../../../../components/dialogs/orders-dispatch-dialog/orders-dispatch-dialog.component";
import { DateInputComponent } from "../../../shared/components/common/input/date-input/date-input.component";
import { absent, assigned, notEmptyString } from "../../../../_helpers/utils";
import { ContextMenuService } from "ngx-contextmenu";
import { ManifestItemContextMenuComponent } from "./manifest-item-context-menu/manifest-item-context-menu.component";
import { UserRoleType } from "../../../shared/models/user-role.type";
import { CarrierService } from "../../../shared/services/carrier.service";
import { CarrierDialogComponent } from "../../../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import { DispatchUtils } from "../../../../_helpers/dispatch-utils";
import { Logger } from "../../../../_helpers/logger";
import { AbstractComponent } from "../../../../common/component/abstract.component";
var ManifestCreateContentComponent = /** @class */ (function (_super) {
    __extends(ManifestCreateContentComponent, _super);
    function ManifestCreateContentComponent(dateTime, cdr, dialogs, dialog, alerts, orderService, truckService, trailerService, driverService, dispatchService, carrierService, userService, authService, contextMenuService, dispatchUtils) {
        var _this = _super.call(this) || this;
        _this.dateTime = dateTime;
        _this.cdr = cdr;
        _this.dialogs = dialogs;
        _this.dialog = dialog;
        _this.alerts = alerts;
        _this.orderService = orderService;
        _this.truckService = truckService;
        _this.trailerService = trailerService;
        _this.driverService = driverService;
        _this.dispatchService = dispatchService;
        _this.carrierService = carrierService;
        _this.userService = userService;
        _this.authService = authService;
        _this.contextMenuService = contextMenuService;
        _this.dispatchUtils = dispatchUtils;
        _this.orderModes = [];
        _this.mergeToExisting = true;
        _this.isCreateFromWarehouse = false;
        _this.manifest = new Manifest();
        _this.selected = [];
        _this.selectedChange = new EventEmitter();
        _this.removeItems = new EventEmitter();
        _this.manifestChange = new EventEmitter();
        _this.created = new EventEmitter();
        _this.refresh = new EventEmitter();
        _this.loadingChange = new EventEmitter();
        _this.stagingChange = new EventEmitter();
        _this.LoadTypes = LoadTypes;
        _this.readonly = false;
        _this.items = [];
        _this.originalManifest = null; // for Switching & Merging manifests
        _this.canDeleteOrder = false;
        _this.totalPcs = 0;
        _this.totalHu = 0;
        _this.totalWeight = 0;
        _this.totalUld = 0;
        _this.Size = Size;
        _this.convertOrderNumber = function (orderId, isRecovery) { return convertOrderNumber(orderId, isRecovery); };
        _this.convertLoadNumber = function (id) { return convertLoadNumber(id); };
        return _this;
    }
    Object.defineProperty(ManifestCreateContentComponent.prototype, "staging", {
        set: function (value) { this.setStaging(value); },
        enumerable: true,
        configurable: true
    });
    ManifestCreateContentComponent.prototype.startLoading = function () {
        this.loading = true;
        this.loadingChange.emit(this.loading);
    };
    ManifestCreateContentComponent.prototype.stopLoading = function () {
        this.loading = false;
        this.loadingChange.emit(this.loading);
    };
    ManifestCreateContentComponent.prototype.initCanDeleteOrder = function () {
        this.canDeleteOrder = this.authService.canDeleteOrder();
    };
    ManifestCreateContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initCanDeleteOrder();
        this.searchDrivers = function (searchText) {
            return _this.userService.findUsersByRoles([UserRoleType.ROLE_DRIVER], true, searchText)
                .pipe(map(function (res) {
                console.log('RESULT', res);
                return PageResult.fromArray(res.content.filter(function (user) { return user.driver; }).map(function (user) { return user.driver; }));
            }));
        };
    };
    ManifestCreateContentComponent.prototype.changed = function () {
        var _this = this;
        setTimeout(function () {
            _this.cdr.markForCheck();
            _this.cdr.detectChanges();
        });
    };
    ManifestCreateContentComponent.prototype.setStaging = function (staging) {
        var _this = this;
        console.log('SET STAGING', staging);
        if (this._isStaging !== staging) {
            if (staging) {
                if (!this.manifest.isNew()) {
                    if (this.manifest.allLoadsUpdated()) {
                        // 1. The Route is fully updated: Deny staging
                        this.dialogs.openErrorDialog('Completed orders cannot be moved to Staging', 'Warning', 5000).then();
                        this.stagingChange.emit(false);
                    }
                    else if (this.manifest.someLoadsUpdated()) {
                        // 2. The Route is partially updated: split Manifest
                        var items_1 = this.manifest.getNotUpdatedLoads();
                        var orders = items_1.flatMap(function (mi) { return mi.orders; }).map(function (o) { return convertOrderNumber(o.id); }).join(', ');
                        var itemsToSplit_1 = items_1.map(function (mi) { return mi.id; });
                        this.dialogs.confirm(DialogType.CONFIRMATION, 'confirm', 'The following orders will be moved to a new staging manifest: \n' + orders, BUTTONS_CONTINUE_CANCEL)
                            .then(function (r) {
                            console.log('RESULT', r.result);
                            if (r.result === ModalResult.OK) {
                                console.log('PROCEED', items_1);
                                _this.startLoading();
                                _this.dispatchService.splitManifest(_this.manifest.id, itemsToSplit_1)
                                    .subscribe(function (split) {
                                    _this.stopLoading();
                                    _this.manifest = _this.prepareManifest(split.source);
                                    _this.dialogs.openInfoDialog(convertManifestNumber(split.target.id) + " was added to the STAGING tab", { mode: 'success', caption: 'Success', timeout: 5000 });
                                    _this.updateManifest();
                                    _this.refresh.next();
                                    _this.cdr.markForCheck();
                                }, function (error) {
                                    _this.stopLoading();
                                    _this.alerts.error(error, 'Error Splitting Manifest...');
                                    _this.stagingChange.emit(false);
                                });
                            }
                            else {
                                _this.stagingChange.emit(false);
                            }
                        });
                        this.changed();
                    }
                    else {
                        // 3. The Route is not updated
                        this.manifest.driver = undefined;
                        this.manifest.truck = undefined;
                        this._isStaging = staging;
                        this.stagingChange.emit(staging);
                    }
                }
                else {
                    this.manifest.driver = undefined;
                    this.manifest.truck = undefined;
                    this._isStaging = staging;
                    this.stagingChange.emit(staging);
                }
            }
            else {
                this._isStaging = staging;
                this.stagingChange.emit(staging);
            }
            this.changed();
        }
    };
    ManifestCreateContentComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.selected) {
            this.manifest.items = this.manifest.items.filter(function (manifestItem) {
                var foundItem = _this.selected.find(function (dispatchItem) { return manifestItem.equalsWithDispatchDto(dispatchItem); });
                return !isNullOrUndefined(foundItem);
            });
            //      let manifestItems = this.manifest.items;
            this.selected.forEach(function (dispatchItem) {
                var foundItem = _this.manifest.items.find(function (fi) { return fi.equalsWithDispatchDto(dispatchItem); });
                if (isNullOrUndefined(foundItem)) {
                    console.log('ADDED HERE', foundItem);
                    var mi = ManifestItem.createFromDispatchDto(dispatchItem, dispatchItem.addressDelivery);
                    mi.trailer = _this.manifest.trailer;
                    _this.manifest.items.push(mi);
                    _this.manifest.enumerate();
                }
            });
        }
        if (changes.manifest) {
            if (this.manifest) {
                this.manifest.items.forEach(function (mi) {
                    mi.loadTypeOriginal = mi.loadType;
                    mi.orders.forEach(function (o) {
                        o.info.legAddressDelivery = mi.addressDelivery;
                    });
                });
                var staging = !this.manifest.isNew() && this.manifest.isStaging;
                if (this._isStaging !== staging) {
                    this._isStaging = staging;
                    this.stagingChange.emit(this._isStaging);
                }
            }
        }
        if (changes.selected || changes.manifest) {
            this.updateManifest();
            this.updateTotalValues(this.manifest.items);
        }
        if (changes.manifest && this.manifest) {
            if (!this.manifest.driver && !this.manifest.truck && this.searchComponents) {
                this.searchComponents.forEach(function (c) { return c.reset(); });
            }
        }
    };
    ManifestCreateContentComponent.prototype.onCreateTruck = function (number) {
        var _this = this;
        var truck = new Truck();
        truck.number = number;
        this.startLoading();
        this.truckService.createEditAndSave(truck, this.readonly)
            .then(function (t) {
            _this.stopLoading();
            _this.manifest.truck = t;
            _this.updateManifest();
        })
            .catch(function (error) {
            _this.stopLoading();
            _this.alerts.danger(error);
        });
    };
    ManifestCreateContentComponent.prototype.onCreateTrailer = function (number, container) {
        var _this = this;
        var trailer = new Trailer();
        trailer.number = number;
        this.startLoading();
        this.trailerService.createEditAndSave(trailer, this.readonly)
            .then(function (t) {
            _this.stopLoading();
            container.trailer = t;
            _this.updateManifest();
        })
            .catch(function (error) {
            _this.stopLoading();
            _this.alerts.danger(error);
        });
    };
    ManifestCreateContentComponent.prototype.onCreateDriver = function (ignore) {
        var _this = this;
        var driver = new Driver();
        this.startLoading();
        this.driverService.createEditAndSave(driver, this.readonly)
            .then(function (d) {
            _this.stopLoading();
            _this.manifest.driver = d;
            _this.updateManifest();
        })
            .catch(function (error) {
            _this.stopLoading();
            _this.alerts.danger(error);
        });
    };
    ManifestCreateContentComponent.prototype.onClearCarrier = function () {
        /*    this.carrierService.getDefaultCarrier().subscribe((carrier) => {
              this.manifest.carrier = carrier;
              this.cdr.markForCheck();
              console.log('CARRIER FOUND', carrier);
            });*/
    };
    ManifestCreateContentComponent.prototype.onCreateCarrier = function () {
        var _this = this;
        this.startLoading();
        var dialogRef = this.dialog.open(CarrierDialogComponent, {
            width: 'auto'
        });
        dialogRef.componentInstance.carrier = null;
        dialogRef.componentInstance.displayInHouse = false;
        return dialogRef.afterClosed()
            .subscribe(function (response) {
            _this.stopLoading();
            _this.manifest.carrier = response.res;
            _this.updateManifest();
        }, function (error) {
            _this.stopLoading();
            _this.alerts.danger(error);
        });
    };
    ManifestCreateContentComponent.prototype.onCustomerRefChange = function (manifestItem, value) {
        var _this = this;
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm', 'The REF will be changed in all records;\nContinue?', BUTTONS_CONTINUE_CANCEL).then(function (result) {
            if (result.result === ModalResult.OK) {
                manifestItem.customerRef = value;
                manifestItem.orders.forEach(function (o) { o.customerRef = value; });
                _this.cdr.markForCheck();
                _this.cdr.detectChanges();
            }
            else {
                manifestItem.update();
                _this.cdr.markForCheck();
                _this.cdr.detectChanges();
            }
        });
    };
    ManifestCreateContentComponent.prototype.onPoChange = function (manifestItem, value) {
        var _this = this;
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm', 'The PO will be changed in all records;\nContinue?', BUTTONS_CONTINUE_CANCEL).then(function (result) {
            if (result.result === ModalResult.OK) {
                manifestItem.orders.forEach(function (o) { o.purchaseOrder = value; });
                _this.cdr.markForCheck();
                _this.cdr.detectChanges();
            }
            else {
                manifestItem.update();
                _this.cdr.markForCheck();
                _this.cdr.detectChanges();
            }
        });
    };
    ManifestCreateContentComponent.prototype.onChangeInput = function (manifest, field, value) {
        manifest[field] = +value;
        this.updateTotalValues(this.manifest.items);
    };
    ManifestCreateContentComponent.prototype.convertMawb = function (mawb) {
        return convertMawbNumber(mawb);
    };
    ManifestCreateContentComponent.prototype.updateTotalValues = function (selectedDispatch) {
        var totalPcs = 0;
        var totalHu = 0;
        var totalWeight = 0;
        var totalUld = 0;
        selectedDispatch.forEach(function (d) {
            totalPcs += d.pieces || 0;
            totalHu += d.hu || 0;
            totalWeight += d.weight || 0;
            totalUld += d.dispatchShipmentUlds.length;
        });
        /*
            for (let i = 0; i < selectedDispatch.length; i++) {
              if (NumberUtils.isNumber(selectedDispatch[i].pieces)) {
                totalPcs += selectedDispatch[i].pieces;
              }
              if (NumberUtils.isNumber(selectedDispatch[i].hu)) {
                totalHu += selectedDispatch[i].hu;
              }
              if (NumberUtils.isNumber(selectedDispatch[i].weight)) {
                totalWeight += selectedDispatch[i].weight;
              }
              if (selectedDispatch[i].uldCount) {
                totalUld += selectedDispatch[i].uldCount;
              }
            } */
        this.totalPcs = totalPcs;
        this.totalHu = totalHu;
        this.totalWeight = totalWeight;
        this.totalUld = totalUld;
        this.cdr.markForCheck();
    };
    ManifestCreateContentComponent.prototype.removeManifestItem = function (dispatchItem) {
        var _this = this;
        if (!dispatchItem.isNew()) {
            this.startLoading();
            this.dispatchService.validateRemoveShipmentItem(dispatchItem.id)
                .subscribe(function (value) {
                _this.stopLoading();
                if (value) {
                    _this.alerts.error({ message: value }, 'Remove order', 10000);
                }
                else {
                    _this.removeDispatchItemFromDispatch(dispatchItem);
                }
            });
        }
        else {
            this.removeDispatchItemFromDispatch(dispatchItem);
        }
    };
    ManifestCreateContentComponent.prototype.removeDispatchItemFromDispatch = function (dispatchItem) {
        this.manifest.items = this.manifest.items.filter(function (dispatchDto) { return dispatchItem !== dispatchDto; });
        this.updateManifest();
        // this.selectedChange.emit(items);
        this.removeItems.emit([dispatchItem]);
    };
    ManifestCreateContentComponent.prototype.trackByOrder = function (index, item) {
        if (!item) {
            return null;
        }
        if (item.id) {
            return item.id;
        }
        if (item.hawb) {
            return item.hawb;
        }
        return item;
    };
    ManifestCreateContentComponent.prototype.openUldDialog = function (manifestItem) {
        var _this = this;
        this.dialog.open(UldsDialogComponent, {
            width: '30%',
            data: {
                orderId: manifestItem.orderId
            }
        }).afterClosed().subscribe(function (ulds) {
            if (ulds) {
                manifestItem.uldCount = ulds.length;
                _this.updateTotalValues(_this.manifest.items);
            }
        });
        /*let ulds = manifestItem.dispatchShipmentUlds;
        const dialogRef = this.dialog.open(UldsDialogComponent, {width: '30%', data:
            {uldsCount: ulds.length, ULDs: ulds, readonly: false} as UldsDialogInputData
        });
        dialogRef.afterClosed().subscribe(res => {
          if (!res) {
            return;
          }
          manifestItem.dispatchShipmentUlds = res.ULDs;
          if (!this.manifest.isNew()) {
            let manifestUrlItems = manifestItem.dispatchShipmentUlds.map(uld => {
              let shipmentUld = new DispatchShipmentUld();
              shipmentUld.uldNumber = uld.uldNumber;
              return shipmentUld;
            });
    
            this.dispatchService.updateDispatchFields(this.manifest.id, manifestItem.id, 'ulds', JSON.stringify(manifestUrlItems))
              .subscribe(manifest => {
                this.manifest = manifest;
                this.updateManifest();
              });
          }
          this.updateTotalValues(this.manifest.items);
        });*/
    };
    ManifestCreateContentComponent.prototype.upOrder = function (index) {
        if (index === 0) {
            return;
        }
        this.swapOrder(index, index - 1);
    };
    ManifestCreateContentComponent.prototype.downOrder = function (index) {
        if (index === this.manifest.items.length - 1) {
            return;
        }
        this.swapOrder(index, index + 1);
    };
    ManifestCreateContentComponent.prototype.swapOrder = function (index1, index2) {
        var first = this.manifest.items[index1];
        var second = this.manifest.items[index2];
        var firstNumber = first.orderNumber;
        first.orderNumber = second.orderNumber;
        second.orderNumber = firstNumber;
        this.updateManifest();
    };
    ManifestCreateContentComponent.prototype.clearFields = function () {
        this.manifest.dateDispatchedFor = null;
        this.manifest.driver = null;
        this.manifest.truck = null;
        this.manifest.trailer = null;
        this.manifest.carrier = null;
        this.searchComponents.forEach(function (c) { return c.reset(); });
        this.manifestChange.emit(this.manifest);
    };
    ManifestCreateContentComponent.prototype.updateManifest = function () {
        this.manifest.update(true);
        this.manifestChange.emit(this.manifest);
        this.cdr.markForCheck();
    };
    ManifestCreateContentComponent.prototype.onDropItem = function (target, event) {
        console.log('DROP', event);
        var source = event.dragData;
        var newIndex = target.orderNumber;
        this.manifest.items.removeAll(source);
        this.manifest.items.splice(newIndex, 0, source);
        var i = 0;
        this.manifest.items.forEach(function (mi) { return mi.orderNumber = i++; });
    };
    ManifestCreateContentComponent.prototype.onLoadTypeChange = function (item, loadType) {
        var _this = this;
        var items = this.getSelectedManifestItems(item);
        if (item && !items.includes(item)) {
            items.push(item);
        }
        items.forEach(function (i) {
            console.log("HERE 1");
            i.loadType = loadType;
            i.orders.forEach(function (o) {
                _this.dispatchService.getLoadDeliveryAddressForOrder(o.id, loadType, o.genericMode)
                    .then(function (a) {
                    o.info.legAddressDelivery = a;
                    i.updateAddressDelivery();
                    _this.cdr.markForCheck();
                })
                    .catch(function (error) {
                    i.loadType = i.loadTypeOriginal;
                    _this.cdr.markForCheck();
                    _this.alerts.error(error, "Can't switch Load Type");
                });
            });
        });
    };
    ManifestCreateContentComponent.prototype.canChangeDelivery = function (item) {
        return !!item; // && [LoadType.X_DOCK, LoadType.PICKUP].includes(item.loadType);
    };
    ManifestCreateContentComponent.prototype.canChangeDeliveryForOrder = function (item) {
        return !!item; // && [LoadType.X_DOCK, LoadType.PICKUP].includes(item.loadType);
    };
    ManifestCreateContentComponent.prototype.isFirstLeg = function (item) {
        return !assigned(item.loadTypePreviousLeg);
    };
    ManifestCreateContentComponent.prototype.canChangePickup = function (item) {
        return !!item &&
            !(item.hasAirImport() && this.isFirstLeg(item)); // disable edit From Address for AI first leg
    };
    // todo: refactor it all
    ManifestCreateContentComponent.prototype.getAllowedLoadTypes = function (item) {
        console.log("TEST ITEM", item);
        if (this.isRecoveryDirect(item)) {
            return [LoadType.RECOVERY, LoadType.DIRECT];
        }
        if (item.hasAirImport()) {
            return [LoadType.DIRECT, LoadType.X_DOCK, LoadType.DELIVERY];
        }
        if (item.isNew()) {
            // New dispatch based on previous load type and locations
            if (!item.loadTypePreviousLeg) {
                return item.loadTypeOriginal === LoadType.RECOVERY ? [LoadType.RECOVERY, LoadType.DIRECT] :
                    [LoadType.DIRECT, LoadType.PICKUP, LoadType.X_DOCK, LoadType.DELIVERY];
            }
            switch (item.loadTypePreviousLeg) {
                case LoadType.RECOVERY: return [LoadType.RECOVERY, LoadType.DIRECT];
                //        case LoadType.DIRECT : return [LoadType.DIRECT, LoadType.PICKUP, LoadType.DELIVERY];
                case LoadType.PICKUP: return [LoadType.DIRECT, LoadType.PICKUP, LoadType.X_DOCK, LoadType.DELIVERY];
                //        case LoadType.DELIVERY : return [LoadType.DELIVERY, LoadType.X_DOCK];
                case LoadType.X_DOCK: return [LoadType.X_DOCK, LoadType.DELIVERY];
                default: return [LoadType.PICKUP, LoadType.DELIVERY, LoadType.DIRECT];
            }
        }
        console.log("TEST ITEM", 3);
        // Edit existing
        switch (item && item.loadTypeOriginal) {
            case LoadType.RECOVERY: return [LoadType.RECOVERY, LoadType.DIRECT];
            case LoadType.DIRECT: return [LoadType.PICKUP, LoadType.DIRECT, LoadType.DELIVERY];
            case LoadType.PICKUP: return [LoadType.PICKUP, LoadType.DIRECT, LoadType.DELIVERY];
            case LoadType.DELIVERY: return [LoadType.X_DOCK, LoadType.DELIVERY];
            case LoadType.X_DOCK: return [LoadType.X_DOCK, LoadType.DELIVERY];
            default: return [];
        }
    };
    ManifestCreateContentComponent.prototype.getDeliveryAddressTypes = function (manifestItem) {
        return [AddressType.CFS_LOCATION, AddressType.CFS_3PL_LOCATION, AddressType.DELIVERY_LOCATION];
    };
    ManifestCreateContentComponent.prototype.getPickupAddressTypes = function (manifestItem) {
        return [AddressType.CFS_LOCATION, AddressType.CFS_3PL_LOCATION, AddressType.PICKUP_LOCATION];
    };
    ManifestCreateContentComponent.prototype.validate = function () {
        return this.inputs.some(function (i) {
            i.control.markAsTouched();
            if (i.valueAccessor instanceof BaseInputComponent) {
                i.valueAccessor.touchControl();
                i.valueAccessor.validate();
                return i.invalid;
            }
        });
    };
    /*  public getOrderNumber(item: ManifestItem): string {
        if (item && item.orders) {
          if (item.orders.length === 1) {
            return convertOrderNumber(item.orders[0].id);
          }
          if (item.orders.length > 1) {
            return OmsConstants.MULTIPLE_VALUE;
          }
        }
        return OmsConstants.EMPTY_VALUE;
      } */
    ManifestCreateContentComponent.prototype.getItemMawb = function (item) {
        return item.orders.asUniqueValue(function (i) { return convertMawbNumber(i.mawb); }, OmsConstants.MULTIPLE_VALUE);
    };
    ManifestCreateContentComponent.prototype.getItemHawb = function (item) {
        return item.orders.asUniqueValue(function (i) { return i.hawb; }, OmsConstants.MULTIPLE_VALUE);
    };
    ManifestCreateContentComponent.prototype.getItemRef = function (item) {
        return item.orders.asUniqueValue(function (i) { return i.customerRef; }, OmsConstants.MULTIPLE_VALUE);
    };
    ManifestCreateContentComponent.prototype.getItemPo = function (item) {
        return item.orders.asUniqueValue(function (i) { return i.purchaseOrder; }, OmsConstants.MULTIPLE_VALUE);
    };
    ManifestCreateContentComponent.prototype.toggleManifestItemSelected = function (manifestItem) {
        if (manifestItem.isSelectedFully) {
            manifestItem.orders.forEach(function (o) { return o.selected = false; });
        }
        else {
            manifestItem.orders.forEach(function (o) { return o.selected = true; });
        }
        this.cdr.markForCheck();
    };
    ManifestCreateContentComponent.prototype.toggleManifestSelected = function () {
        if (this.selectedAll) {
            this.unselect();
        }
        else {
            this.selectAll();
        }
        this.cdr.markForCheck();
    };
    ManifestCreateContentComponent.prototype.consolidate = function () {
        var _this = this;
        this.dispatchUtils.consolidateSelectedItems(this.manifest)
            .then(function (result) { if (result) {
            _this.updateManifest();
        } })
            .catch(function (error) { return _this.alerts.error(error); });
    };
    ManifestCreateContentComponent.prototype.unconsolidate = function () {
        var _this = this;
        this.dispatchUtils.unconsolidateSelectedItems(this.manifest)
            .then(function (result) { if (result) {
            _this.updateManifest();
        } })
            .catch(function (error) { return _this.alerts.error(error); });
        this.manifest.items.forEach(function (mi) {
            if (mi.hasSelected && mi.orders.length > 1) {
                var orders = mi.orders.slice();
                orders.forEach(function (o) {
                    if (o.selected && mi.orders.length > 1) {
                        //            console.log('UNCONSOLIDATE', o);
                        mi.orders.removeAll(o);
                        // consolidate            mi.comments = mi.orders.map((o1) => o1.dispatchNotes).filter((s) => assigned(s)).join('\n');
                        if (mi.orders.length === 1) {
                            mi.comments = ManifestItem.composeDispatchComments(mi.orders[0]);
                        }
                        var item = new ManifestItem();
                        item.orders.push(o);
                        item.pieces = o.pieces;
                        item.hu = o.hu;
                        item.weight = o.weight;
                        item.loadType = mi.loadType;
                        item.trailer = mi.trailer;
                        item.addressPickUp = mi.addressPickUp;
                        item.addressDelivery = mi.addressDelivery;
                        item.customer = o.customer;
                        item.customerRef = o.customerRef;
                        item.freightForwarder = o.freightForwarder;
                        item.uldCount = o.uldCount;
                        item.comments = ManifestItem.composeDispatchComments(o);
                        _this.manifest.items.push(item);
                        _this.cdr.markForCheck();
                    }
                });
                _this.updateManifest();
            }
        });
    };
    ManifestCreateContentComponent.prototype.validateManifestItem = function (manifestItem) {
        if (absent(manifestItem.addressPickUp)) {
            return "Pickup Address must be specified";
        }
        if (absent(manifestItem.addressPickUp)) {
            return "Delivery Address must be specified";
        }
        if (manifestItem.consolidated) {
            if (manifestItem.mixedDelivery) {
                return "Mixed Order Delivery Addresses";
            }
        }
        if (manifestItem.loadType === LoadType.DELIVERY) {
            if (!manifestItem.weight) {
                return "Recovery Load must have weight specified";
            }
        }
        //    return null;
    };
    ManifestCreateContentComponent.prototype.validateManifest = function (manifest) {
        if (absent(manifest.dateDispatchedFor) && !this._isStaging) {
            return 'Date is Mandatory unless Manifest is Staging';
        }
        if (absent(manifest.driver) && !this._isStaging && !this.isExternalCarrier()) {
            return 'Driver is Mandatory unless Manifest is Staging';
        }
        // Validate Manifest Items
        var messages = manifest.items.map(this.validateManifestItem).filter(notEmptyString);
        if (messages.length) {
            console.warn('Manifest Items', messages);
            return messages.first();
        }
    };
    ManifestCreateContentComponent.prototype.getManifestItemAddressDeliveryName = function (manifestItem) {
        return manifestItem.orders.asUniqueValue(function (o) { return o.info.legAddressDelivery && o.info.legAddressDelivery.label; }, OmsConstants.MULTIPLE_VALUE, OmsConstants.EMPTY_VALUE);
    };
    ManifestCreateContentComponent.prototype.onManifestItemAddressDeliveryChange = function (manifestItem, address) {
        var _this = this;
        if (manifestItem.loadType === LoadType.DELIVERY) {
            this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm', 'The Delivery Address will be changed in all records;\nContinue?', BUTTONS_CONTINUE_CANCEL).then(function (result) {
                if (result.result === ModalResult.OK) {
                    manifestItem.addressDelivery = address;
                    manifestItem.orders.forEach(function (o) {
                        o.info.legAddressDelivery = address;
                        o.addressDelivery = address;
                    });
                    _this.cdr.markForCheck();
                    _this.cdr.detectChanges();
                }
                else {
                    manifestItem.update();
                    _this.cdr.markForCheck();
                    _this.cdr.detectChanges();
                }
            });
        }
        else {
            manifestItem.addressDelivery = address;
            manifestItem.orders.forEach(function (o) { return o.info.legAddressDelivery = address; });
        }
    };
    ManifestCreateContentComponent.prototype.onOrderAddressDeliveryChange = function (manifestItem, order, address) {
        order.info.legAddressDelivery = address;
        manifestItem.addressDelivery = manifestItem.orders.asUniqueValue(function (o) { return o.info.legAddressDelivery; });
        this.cdr.markForCheck();
    };
    ManifestCreateContentComponent.prototype.selectAll = function () {
        var items = this.getDisplayManifestItems() || [];
        items.forEach(function (mi) {
            mi.orders.forEach(function (o) { o.selected = true; });
        });
    };
    ManifestCreateContentComponent.prototype.unselect = function () {
        this.manifest.items.forEach(function (mi) {
            mi.orders.forEach(function (o) { o.selected = false; });
        });
    };
    Object.defineProperty(ManifestCreateContentComponent.prototype, "selectedSome", {
        get: function () {
            var items = this.getDisplayManifestItems() || [];
            for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                var mi = items_2[_i];
                for (var _a = 0, _b = mi.orders; _a < _b.length; _a++) {
                    var o = _b[_a];
                    if (o.selected) {
                        return true;
                    }
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestCreateContentComponent.prototype, "selectedAll", {
        get: function () {
            var items = this.getDisplayManifestItems() || [];
            for (var _i = 0, items_3 = items; _i < items_3.length; _i++) {
                var mi = items_3[_i];
                for (var _a = 0, _b = mi.orders; _a < _b.length; _a++) {
                    var o = _b[_a];
                    if (!o.selected) {
                        return false;
                    }
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManifestCreateContentComponent.prototype, "selectedOneOrder", {
        get: function () {
            var selected = [];
            this.manifest.items.forEach(function (mi) { return selected.push.apply(selected, mi.orders.filter(function (o) { return o.selected; })); });
            return selected.length === 1 ? selected[0] : null;
        },
        enumerable: true,
        configurable: true
    });
    ManifestCreateContentComponent.prototype.splitOrder = function (order) {
        var _this = this;
        //    const originalPcs = order.pieces;
        this.dialogs.openDialog(SplitOrderDialogComponent, {
            order: order,
            onOk: function (newPcs, newHu, newWeight) {
                order.pieces = newPcs;
                order.hu = newHu;
                order.weight = newWeight;
                var mi = _this.manifest.findItem(order.id);
                mi.update();
                _this.updateManifest();
                _this.refresh.next();
                _this.cdr.markForCheck();
            }
        }).then();
    };
    // Switch to received manifest & add new Items
    ManifestCreateContentComponent.prototype.mergeManifest = function (manifest) {
        var _a, _b;
        console.log('Merge Manifest', this.manifest, 'to', manifest);
        var date = this.manifest && this.manifest.dateDispatchedFor || new Date();
        var driver = this.manifest && this.manifest.driver;
        var truck = this.manifest && this.manifest.truck;
        var trailer = this.manifest && this.manifest.trailer;
        var carrier = this.manifest && this.manifest.carrier;
        var addedItems = this.manifest.items.filter(function (mi) { return mi.isNew(); });
        if (!manifest) {
            if (!addedItems.length) {
                console.log('Nothing to Merge. Just switch Driver or Date');
                //        return;
            }
            else {
                this.manifest = new Manifest();
                this.manifest.dateDispatchedFor = date;
                this.manifest.driver = driver;
                this.manifest.trailer = trailer;
                this.manifest.truck = truck;
                this.manifest.carrier = carrier;
            }
        }
        else {
            if (!this.originalManifest) {
                this.originalManifest = this.manifest;
            }
            this.manifest = manifest;
            if (this.manifest.id !== this.originalManifest.id) {
                var oldItems = this.originalManifest.items.filter(function (mi) { return !mi.isNew(); });
                console.log('OLD', oldItems);
                (_a = this.manifest.items).push.apply(_a, oldItems);
            }
        }
        /*    let num = this.manifest.items.length;
            addedItems.forEach((mi) => {
              this.manifest.items.push(mi);
              mi.orderNumber = num++;
            }); */
        (_b = this.manifest.items).push.apply(_b, addedItems);
        this.updateManifest();
        return this.manifest;
    };
    ManifestCreateContentComponent.prototype.prepareManifest = function (manifest) {
        manifest.items.forEach(function (mi) {
            mi.loadTypeOriginal = mi.loadType;
            mi.orders.forEach(function (o) { o.info.legAddressDelivery = mi.addressDelivery; });
        });
        return manifest;
    };
    ManifestCreateContentComponent.prototype.switchManifest = function (newDate, newDriver) {
        var _this = this;
        this.dispatchService.getDriverManifestOnDate(this.dateTime.utcDateOfMidnight(newDate), newDriver)
            .then(function (manifest) {
            if (!!manifest) {
                _this.dialogs.confirm(DialogType.CONFIRMATION, "Merge to existing Manifest?", "Existing Manifest " + convertManifestNumber(manifest.id) + "\n" +
                    ("for " + manifest.driver.fullName + " on " + convertDateTime(manifest.dateDispatchedFor) + " has found.\n\n") +
                    "Merge selected Items to the found Manifest?", BUTTONS_CONTINUE_CANCEL)
                    .then(function (r) {
                    if (r.result === ModalResult.OK) {
                        _this.mergeManifest(_this.prepareManifest(manifest));
                    }
                    else {
                        _this.refreshData();
                    }
                });
            }
            else {
                _this.manifest.driver = newDriver;
                _this.manifest.dateDispatchedFor = newDate;
            }
        }).catch(function (error) { return _this.alerts.error(error); });
    };
    ManifestCreateContentComponent.prototype.onDriverChange = function (driver) {
        if (this.mergeToExisting && !!driver && !isNullOrUndefined(this.manifest.dateDispatchedFor)) {
            if (driver !== this.manifest.driver) {
                // avoid extra call on initial loading
                this.switchManifest(this.manifest.dateDispatchedFor, driver);
            }
        }
        else {
            this.manifest.driver = driver;
        }
    };
    ManifestCreateContentComponent.prototype.onDateChange = function (date) {
        if (this.mergeToExisting && !!this.manifest.driver && !isNullOrUndefined(date)) {
            if (date !== this.manifest.dateDispatchedFor) {
                // avoid extra call on initial loading
                this.switchManifest(date, this.manifest.driver);
            }
        }
        else {
            this.manifest.dateDispatchedFor = date;
        }
    };
    ManifestCreateContentComponent.prototype.refreshData = function () {
        this.dateInput.writeValue(this.manifest && this.manifest.dateDispatchedFor);
        this.driverInput.writeValue(this.manifest && this.manifest.driver);
        this.cdr.detectChanges();
        this.cdr.markForCheck();
    };
    ManifestCreateContentComponent.prototype.getColorForOrder = function (obj, manifestItem) {
        return '';
        /*
            if (manifestItem.isCompleted()) {
              return '#dff0d8'; // green
            }
        
            else if (manifestItem.isCompletedWithProblem()) {
              return '#fcf8e3'; // yellow
            }
        
            else if (manifestItem.isNotCompleted()) {
              return 'red';
            }
        
            else {
              return '';
            } */
    };
    ManifestCreateContentComponent.prototype.getItemClass = function (manifestItem) {
        return {
            'control-invalid': !!this.validateManifestItem(manifestItem),
            'alert-danger': manifestItem.isNotCompleted(),
            'alert-warning': manifestItem.isCompletedWithProblem(),
            'alert-success': manifestItem.isCompleted(),
        };
    };
    ManifestCreateContentComponent.prototype.getFullAddressText = function (address) {
        return address ? address.fullAddress : undefined;
    };
    ManifestCreateContentComponent.prototype.removeOrder = function (mi, order) {
        var _this = this;
        if (mi.orders.includes(order)) {
            if (mi.orders.length === 1) {
                this.removeManifestItem(mi);
            }
            else {
                this.startLoading();
                this.dispatchService.validateRemoveOrder(order.id, mi.id)
                    .subscribe(function (value) {
                    _this.stopLoading();
                    if (value) {
                        _this.alerts.error({ message: value }, 'Remove order', 10000);
                    }
                    else {
                        mi.orders.removeAll(function (o) { return o === order; });
                        _this.updateManifest();
                    }
                });
            }
        }
    };
    ManifestCreateContentComponent.prototype.getSelectedOrders = function () {
        var orders = [];
        this.manifest.items.forEach(function (mi) { orders = orders.concat(mi.orders.filter(function (o) { return o.selected; })); });
        return orders;
    };
    ManifestCreateContentComponent.prototype.getSelected = function () {
        var items = { items: [], orders: [] };
        //    let selected: ManifestItem[]  = this.getDisplayManifestItems() || [];
        this.manifest.items.forEach(function (mi) {
            if (mi.isSelectedFully) {
                items.items.push(mi);
            }
            else {
                mi.orders.forEach(function (o) {
                    if (o.selected) {
                        items.orders.push(o);
                    }
                });
            }
        });
        return items;
    };
    ManifestCreateContentComponent.prototype.moveSelectedItemsToManifest = function (target) {
        var _this = this;
        var source = Object.assign(new Manifest(), this.manifest);
        console.log('SOURCE', source);
        var items = source.items.slice();
        items.forEach(function (mi) {
            if (mi.isSelectedFully) {
                source.items.removeAll(function (i) { return i.id === mi.id; });
                target.items.push(mi);
                mi.id = undefined;
            }
            else if (mi.isSelectedPartially) {
                var item_1 = Object.assign(new ManifestItem(), mi);
                item_1.id = undefined;
                item_1.orders = [];
                target.items.push(item_1);
                mi.orders.slice().forEach(function (o) {
                    if (o.selected) {
                        mi.orders.removeAll(o);
                        item_1.orders.push(o);
                    }
                });
            }
        });
        source.update(true);
        target.update(true);
        //    return this.dispatchService.redispatch(source, target);
        return this.dispatchService.dispatchOrders(source, false, false)
            .toPromise()
            .then(function (manifest) { return _this.dispatchService.dispatchOrders(target, false, false).toPromise(); });
    };
    ManifestCreateContentComponent.prototype.redispatchSelected = function (current) {
        var _this = this;
        var orders = [];
        var hasNotCompleted = false;
        var notCompleted;
        var items = this.getDisplayManifestItems() || [];
        items.forEach(function (mi) {
            var o = mi.orders.filter(function (o1) { return o1.selected; });
            if (o && o.length) {
                orders = orders.concat(o);
                if (mi.isNotCompleted()) {
                    hasNotCompleted = true;
                    notCompleted = mi;
                }
            }
        });
        if (orders.length === 0 && !!current) {
            orders = current.orders.slice();
            if (current.isNotCompleted()) {
                hasNotCompleted = true;
                notCompleted = current;
            }
        }
        if (this.selectedAll || this.manifest.items.length === 1) {
            this.alerts.warning("Unable to redispatch all Manifest Routes!\nPlease update Date or Driver in the Manifest Form", 5000);
        }
        else if (hasNotCompleted) {
            this.alerts.warning("Not Completed Load " + convertLoadNumber(notCompleted.shipment.id) + " cannot be redispatched", 5000);
        }
        else if (orders.length === 0) {
            this.alerts.warning("No items selected!", 5000);
        }
        else {
            var data = {
                noCarrier: true,
                delivery: false,
                orders: orders,
                date: this.dateTime.utcDateOfMidnight(new Date()),
                driver: null,
                trailer: null,
                truck: null,
                carrier: null,
                manifestOriginal: this.manifest,
            };
            this.dialogs.openDialog(OrdersDispatchDialogComponent, data, {
                handler: function (res) {
                    return new Promise(function (resolve, reject) {
                        if (res.result === ModalResult.OK) {
                            var data1 = res.data;
                            var manifest_1 = data1.manifestToMerge;
                            if (!manifest_1) {
                                // Create new Manifest
                                manifest_1 = new Manifest();
                                manifest_1.driver = data1.driver;
                                manifest_1.dateDispatchedFor = data1.date;
                            }
                            manifest_1.truck = data1.truck;
                            manifest_1.trailer = data1.trailer;
                            manifest_1.carrier = data1.carrier;
                            _this.startLoading();
                            _this.moveSelectedItemsToManifest(manifest_1)
                                .then(function (r) {
                                _this.stopLoading();
                                _this.alerts.success("Selected items moved to " + (manifest_1.isNew() ? 'new' : 'existing') + " Manifest " + convertManifestNumber(r.id), 10000);
                                _this.manifest = _this.prepareManifest(r);
                                _this.updateManifest();
                                _this.refresh.next();
                                _this.cdr.markForCheck();
                                resolve(res); // close dialog
                            })
                                .catch(function (error) {
                                _this.stopLoading();
                                _this.alerts.error(error);
                                reject(error);
                            });
                        }
                        else {
                            // Cancel - allow close;
                            resolve(res);
                        }
                    });
                }
            }).catch(function (error) {
                _this.alerts.error(error);
            });
        }
    };
    ManifestCreateContentComponent.prototype.driverAction = function (manifestItem, action) {
        var _this = this;
        console.log(manifestItem, action);
        if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
            this.alerts.warning("The manifest is not dispatched");
            return;
        }
        var items = this.getSelectedManifestItems(manifestItem);
        if (manifestItem && !items.includes(manifestItem)) {
            items.push(manifestItem);
        }
        items.forEach(function (mi) { mi.orders.forEach(function (o) { return o.selected = !mi.isNew(); }); });
        items = items.filter(function (mi) { return mi.isSelectedFully; });
        if (items.length === 0) {
            this.alerts.warning("All selected items must be dispatched");
            return;
        }
        var ids = items.map(function (mi) { return mi.id; }).filter(function (n) { return assigned(n); });
        var driverId = this.manifest.driver.id;
        this.startLoading();
        this.dispatchService.routeDriverUpdate(driverId, ids, action /* Up to Server date , minDate(this.manifest.dateDispatchedFor, new Date())*/)
            .then(function (manifest) {
            _this.stopLoading();
            _this.alerts.success("Manifest Updated");
            _this.manifest = _this.prepareManifest(manifest);
            _this.updateManifest();
            _this.refresh.next();
            _this.cdr.markForCheck();
        })
            .catch(function (error) {
            _this.stopLoading();
            _this.alerts.error(error);
        });
    };
    ManifestCreateContentComponent.prototype.driverActionCancel = function (manifestItem) {
        var _this = this;
        console.log(manifestItem);
        if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
            this.alerts.warning("The manifest is not dispatched");
            return;
        }
        var items = this.getSelectedManifestItems(manifestItem);
        if (manifestItem && !items.includes(manifestItem)) {
            items.push(manifestItem);
        }
        items.forEach(function (mi) { mi.orders.forEach(function (o) { return o.selected = !mi.isNew(); }); });
        items = items.filter(function (mi) { return mi.isSelectedFully; });
        if (items.length === 0) {
            this.alerts.warning("All selected items must be dispatched");
            return;
        }
        var ids = items.map(function (mi) { return mi.id; }).filter(function (n) { return assigned(n); });
        this.startLoading();
        this.dispatchService.routeDriverUpdateCancel(ids)
            .then(function (manifest) {
            _this.stopLoading();
            _this.alerts.success("Manifest Updated");
            _this.manifest = _this.prepareManifest(manifest);
            _this.updateManifest();
            _this.refresh.next();
            _this.cdr.markForCheck();
        })
            .catch(function (error) {
            _this.stopLoading();
            _this.alerts.error(error);
        });
    };
    ManifestCreateContentComponent.prototype.getSelectedManifestItems = function (current) {
        var selected = [];
        if (this.manifest) {
            this.manifest.items.forEach(function (mi) {
                if (mi.hasSelected) {
                    selected.push(mi);
                }
            });
        }
        return selected.length > 0 ? selected : (current ? [current] : []);
    };
    ManifestCreateContentComponent.prototype.getMenuHeader = function (current) {
        var selected = this.getSelectedManifestItems(current);
        if (selected.length > 1) {
            return 'Multiple Selected';
        }
        else if (selected.length === 1) {
            return this.convertLoadNumber(selected[0].shipment ? selected[0].shipment.id : null);
        }
        else {
            return 'None Selected';
        }
    };
    ManifestCreateContentComponent.prototype.onCommentsInit = function (element) {
        setTimeout(function () {
            element.nativeElement.style.height = '';
            element.nativeElement.style.height = element.nativeElement.scrollHeight + 3 + "px";
        }, 50);
        //    console.log("change", event);
        // onchange='this.style.height = "";this.style.height = this.scrollHeight + 3+ "px"'
    };
    ManifestCreateContentComponent.prototype.generateBOL = function (current, isOpenPDF) {
        var _this = this;
        var isNew = this.manifest.isNew() || !isNullOrUndefined(this.manifest.items.find(function (mi) { return mi.id === undefined; }));
        var items = this.getSelectedManifestItems(current);
        var orderIds = items.filter(function (mi) { return mi.id === undefined; }).flatMap(function (mi) { return mi.orders.map(function (or) { return or.id; }); });
        var manifestIds = items.filter(function (mi) { return mi.id !== undefined; }).map(function (mi) { return mi.id; });
        if (orderIds.length > 0) {
            this.orderService.generateBOLs(orderIds).subscribe(function (response) {
                _this.generateBOLOpenPDF(response, isOpenPDF);
            });
        }
        if (manifestIds.length > 0) {
            this.dispatchService.generateBOLs(manifestIds).subscribe(function (response) {
                _this.generateBOLOpenPDF(response, isOpenPDF);
            });
        }
    };
    ManifestCreateContentComponent.prototype.generateBOLOpenPDF = function (response, isOpenPDF) {
        if (!isNullOrUndefined(response)) {
            if (isOpenPDF) {
                var data = response.bytes;
                var fileName = "BOL.pdf";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
                    var byteCharacters = atob(data);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var blob = new Blob([byteArray], { type: 'application/pdf' });
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                }
                else { // much easier if not IE
                    var pdfWindow = window.open("");
                    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data) + "'></iframe>");
                }
            }
            this.alerts.success('Generate BOL successful', 5000);
        }
        else {
            this.alerts.error('Error generate BOL');
        }
    };
    ManifestCreateContentComponent.prototype.onContextMenu = function ($event, manifestItem, menu) {
        this.manifestItem = manifestItem;
        this.contextMenuService.show.next({
            contextMenu: menu.contextMenu,
            event: $event,
            item: null
        });
        $event.preventDefault();
        $event.stopPropagation();
        this.cdr.markForCheck();
    };
    ManifestCreateContentComponent.prototype.onLoading = function (loading) {
        if (loading) {
            this.startLoading();
        }
        else {
            this.stopLoading();
        }
    };
    ManifestCreateContentComponent.prototype.onRefresh = function () {
        this.refresh.emit();
    };
    ManifestCreateContentComponent.prototype.isExternalCarrier = function () {
        return this.manifest && this.manifest.carrier && !this.manifest.carrier.inHouse;
    };
    ManifestCreateContentComponent.prototype.onManifestTrailerChange = function (trailer) {
        //    console.log('TRAILER', trailer);
        this.manifest.items.forEach(function (mi) {
            mi.trailer = trailer;
            if (mi.shipment && mi.shipment.load) {
                mi.shipment.load.trailer = trailer;
            }
        });
    };
    ManifestCreateContentComponent.prototype.getDisplayManifestItems = function () {
        var _this = this;
        return this.orderModes && this.orderModes.length > 0 ?
            this.manifest.items.filter(function (mi) { return assigned(mi.orders.find(function (o) { return _this.orderModes.hasEquals(o.genericMode); })); })
            : this.manifest.items;
    };
    ManifestCreateContentComponent.prototype.getDisplayManifestItemOrders = function (mi) {
        var _this = this;
        return mi && mi.orders ?
            (this.orderModes && this.orderModes.length > 0 ? mi.orders.filter(function (o) { return _this.orderModes.hasEquals(o.genericMode); }) : mi.orders)
            : [];
    };
    ManifestCreateContentComponent.prototype.showAsConsolidated = function (item) {
        return item && item.consolidated;
    };
    ManifestCreateContentComponent.prototype.isRecoveryDirect = function (item) {
        return item && item.loadTypeOriginal === LoadType.DIRECT && item.orders.hasEquals(function (o) { return o.isRecovery; });
    };
    ManifestCreateContentComponent = __decorate([
        Logger({}),
        __metadata("design:paramtypes", [DateTimeService,
            ChangeDetectorRef,
            OmsDialogsService,
            MatDialog,
            OmsAlertsService,
            OrdersService,
            TruckService,
            TrailerService,
            DriverService,
            DispatchService,
            CarrierService,
            UserService,
            AuthService,
            ContextMenuService,
            DispatchUtils])
    ], ManifestCreateContentComponent);
    return ManifestCreateContentComponent;
}(AbstractComponent));
export { ManifestCreateContentComponent };
