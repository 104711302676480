var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RestService } from "../rest.service";
import { BaseEntityService } from "../base/base-entity.service";
import { OrderNotification } from "../../modules/shared/models/order/order-notification";
import { HttpUtilsService } from "../http-utils.service";
import { plainToClass } from "class-transformer";
import { map } from "rxjs/operators";
var OrderNotificationService = /** @class */ (function (_super) {
    __extends(OrderNotificationService, _super);
    function OrderNotificationService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/order-notifications/';
        _this.classType = OrderNotification;
        return _this;
    }
    OrderNotificationService.prototype.getUnreadOrderNotifications = function () {
        var _this = this;
        var params = this.httpUtils.getHTTPParams({});
        return this.httpRestService.getWithOptions(this.apiUrl + 'get-unread', this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (items) { return _this.prepareItems(items); }));
    };
    OrderNotificationService.prototype.readOrderNotification = function (orderNotificationId) {
        return this.httpRestService.post(this.apiUrl + 'read', null, { params: { orderNotificationIds: [orderNotificationId] } });
    };
    OrderNotificationService.prototype.addComment = function (orderNotificationId, comment) {
        return this.httpRestService.post(this.apiUrl + orderNotificationId + '/comment', comment);
    };
    OrderNotificationService.prototype.moveToEnd = function (orderNotificationId) {
        var _this = this;
        return this.httpRestService.post(this.apiUrl + orderNotificationId + '/move-to-end')
            .pipe(map(function (items) { return _this.prepareItems(items); }));
    };
    OrderNotificationService.prototype.prepareItems = function (items) {
        return plainToClass(OrderNotification, items).sort(function (i1, i2) { return i1.orderNumber - i2.orderNumber; });
    };
    return OrderNotificationService;
}(BaseEntityService));
export { OrderNotificationService };
