<!--ng-template #cellButton>

</ng-template-->

<ng-container [ngSwitch]="displayType">
  <!-- HYPERLINK -->
  <ng-container *ngSwitchCase="DisplayType.LINK">
    <a (click)="onCellClick(cell, $event)" [class]="cellClass" [popover]="tooltip" placement="auto" container="body">
      <i class="{{iconClass}}" *ngIf="hasIcon"></i><span innerHTML="{{value | highlight : searchText : rawValue}}"></span>
    </a>

    <a [routerLink]="cellButtonLink" [queryParamsHandling]="'preserve'" (click)="onCellButtonClick(cell, $event)"
       [attr.hidden]="cellButtonHidden ? 'hidden' : undefined"
       [attr.target]="cellButtonRefTarget" style="float: right; padding-right: 10px;" *ngIf="cellButton"
       [popover]="cellButtonTooltip" placement="auto" [container]="'body'">
      <i class="{{cellButtonIcon}}"></i>
    </a>
  </ng-container>

<!--
  <ng-container *ngSwitchCase="DisplayType.NEW_WINDOW_LINK">
    <a (click)="navigateToAndOpenNewWindow(hyperLink)" [class]="cellClass"><i class="{{iconClass}}" *ngIf="hasIcon"></i>{{value}}</a>
  </ng-container>
-->

<!--
  <ng-container *ngSwitchCase="DisplayType.TARGET">
    <a [href]="targetHyperLink" target="_blank">PDF<i class="pull-right fa fa-file-pdf-o"></i></a>
  </ng-container>
-->

  <!-- DIALOG -->
  <ng-container *ngSwitchCase="DisplayType.DIALOG">
    <a [class]="cellClass" (click)="openDialog(cell, $event);"><i class="{{iconClass}}" [ngClass]="{'emptyValueIcon':value==null || value==0}" *ngIf="hasIcon"></i>{{value}}</a>
  </ng-container>

  <!-- ACTIONS -->
  <ng-container *ngSwitchCase="DisplayType.ACTIONS">
    <div class="btn-group" style="position: absolute; margin-top: -2px;">
      <button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-file"></i>
      </button>
      <ul class="dropdown-menu actions">
        <li (click)="generateMasterMDF(cell, $event);"><i class="fa fa-file-pdf-o"></i>&nbsp;Generate PTT</li>
        <!--
                  <li><i class="fa fa-file-pdf-o"></i>Generate PDF</li>
        -->
      </ul>
    </div>
  </ng-container>

  <!-- CHECKBOX -->
  <ng-container *ngSwitchCase="DisplayType.CHECKBOX">
    <span *ngIf="info">{{info}}</span>
    <span (contextmenu)="onRightClick($event)" #dateTimeEdit [class]="'table-checkbox-body fancy-checkbox custom-bgcolor-green '+cellClass" *ngIf="!isHidden"
          [popover]="tooltip" placement="auto" container="body"
          [style.text-align]="column.align"
          (click)="editColumnValue(cell, $event)">
      <input type="checkbox" [ngModel]="value" class="table-checkbox" title="">
      <span [style.cursor]="editable ? 'pointer' : 'not-allowed'" >{{placeholder}}</span>
    </span>
  </ng-container>

  <!-- FILL INDICATOR  todo Make Custom -->
  <ng-container *ngSwitchCase="DisplayType.FILL">
    <oms-fill-indicator [fill]="value" placement="auto" [popover]="tooltip" container="body" (click)="editColumnValue(cell, $event)"></oms-fill-indicator>
  </ng-container>

  <!-- CUSTOM CELL COMPONENT -->
  <ng-container *ngSwitchCase="DisplayType.CUSTOM">
    <div #target></div>
  </ng-container>

  <!-- TEXT -->
  <ng-container *ngSwitchDefault>
    <span *ngIf="info">{{info}}</span>
    <!-- EDITABLE (CLICKABLE) -->
    <!--span *ngIf="isHidden">{{table.emptyValue}}</span-->
    <ng-template [ngIf]="editable" [ngIfElse]="textStatic" *ngIf="!isHidden">
      <!-- STATIC MODE -->
      <ng-template [ngIf]="!editing" [ngIfElse]="inEdit" *ngIf="!isHidden">
        <a [routerLink]="hyperLink" [queryParamsHandling]="assigned(hyperLink) ? 'preserve' : 'preserve'" (contextmenu)="onRightClick($event)" class="no-wrap" [popover]="tooltip" placement="auto" container="body" [class]="cellClass" [ngClass]="{'editable editable-click':editable, 'editable-empty':empty}"
           (click)="editColumnValue(cell, $event)"  (focus)="onFocusColumn(cell, $event)">
          <i class="{{iconClass}}" *ngIf="true"></i><span innerHTML="{{display | highlight : searchText : rawValue}}"></span>
        </a>
        <a [routerLink]="cellButtonLink" [queryParamsHandling]="assigned(cellButtonLink) ? 'preserve': 'preserve'" (click)="onCellButtonClick(cell, $event)"
           [attr.hidden]="cellButtonHidden ? 'hidden' : undefined"
           [attr.target]="cellButtonRefTarget" style="float: right; padding-right: 10px;"
           *ngIf="cellButton && cellButtonIcon" [popover]="cellButtonTooltip" [container]="'body'">
          <i class="{{cellButtonIcon}}"></i>
        </a>
      </ng-template>

      <!-- EDIT MODE -->
      <ng-template #inEdit>
        <ng-container [ngSwitch]="cellDisplayType">

          <!-- TEXT COLUMN EDIT MODE -->
          <ng-container *ngSwitchCase="DisplayType.TEXTBOX">
            <div (contextmenu)="onRightClick($event)" class="input-group input-group-sm input-group-inplace" (dblclick)="onDoubleClick($event);" style="width: 100%">
              <input #textInput type="text" class="form-control" (blur)="onBlurEditor()" title=""
                     [ngClass]="{'form-control-invalid':error}"
                     data-toggle="dropdown" aria-expanded="true"
                     [attr.readonly]=""
                     [placeholder]="placeholder"
                     [textMask]="editor?.mask || {mask:false}" [popover]="error" placement="auto" [container]="'body'"
                     [(ngModel)]="localValue" (keydown)="onKeyDown($event)">

              <span class="input-group-btn not_inside" >
                  <button class="btn btn-clean" (click)="onClear()" *ngIf="editor.cleanable"><i class="fa fa-times"></i></button>
                </span>

              <ul #textSearch class="multiselect-container dropdown-menu" style="max-height: 200px; overflow-y: auto; overflow-x: hidden;" *ngIf="selectable">
                <li style="display: list-item;" [ngClass]="{'active': isSelected(item)}" *ngFor="let item of editor.select" (click)="onSelectItem($event, item)">
                  <a href="javascript:void(0);" >
                    <label [ngClass]="{'radio': !isMultiSelect, 'checkbox':isMultiSelect}" class="radio">
                      <input [value]="item" type="radio">{{item.label}}</label>
                  </a>
                </li>
              </ul>

            </div>

          </ng-container>

          <!-- SELECT COLUMN EDIT MODE -->
          <ng-container *ngSwitchCase="DisplayType.SELECT">
            <div class="input-group input-group-sm input-group-inplace" style="width: 100%" >

              <ng-select #ngSelect class="form-control inplace widget" style="position: relative; font-size: 14px;"
                         *ngIf="isSelectable"
                         appendTo="body"
                         [clearable]="editor.cleanable"
                         [(ngModel)]="selected"
                         [items]="selectItemsSubject | async"
                         [placeholder]="selectOptions.placeholder"
                         [bindLabel]="selectOptions.bindLabel"
                         (open)="onSelectOpen($event)"

                         [searchFn]="searchSelectable"
                         (dblclick)="onDoubleClick($event);"
                         (change)="onSelectItem()"
                         (blur)="onBlurEditor()"
                         (keydown)="onKeyDown($event)"
                         [groupBy]="selectGroupBy"
                         (keyup)="onSelectKeyUp($event)">

                <ng-container [ngSwitch]="column.fieldType">


                  <!--ng-template ng-header-tmp>
                    <input title="" #filterInput style="width: 100%; line-height: 24px" type="text"
                           (input)="ngSelect.filter($event.target.value)"
                           appOnInit (onInit)="onSearchInit($event)"/>
                  </ng-template-->

                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngSwitchCase="FieldType.ADDRESS">
                    <div>
                      <b>{{item.name}}</b>, {{item.addressLines}}, {{item.city}} {{getState(item)}}{{getWithComa(item.postCode)}}
                    </div>
                    <!--small>{{item.iataCode}} / {{item.icaoCode}}</small-->
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngSwitchDefault>
                    <div [ngClass]="{'top-item': item.top}" [ngOptionHighlight]="search">{{item.name || item.label}}
                      <div class="pull-right" *ngIf="item.top">
                        <a (click)="onRemoveFromTop($event, item)"><i class="fa fa-times"></i></a>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>

                <ng-template ng-footer-tmp *ngIf="column.fieldType === FieldType.ADDRESS || selectOptions.createNewHandler">
                  <div class="widget-footer">
                    <oms-checkbox
                      *ngIf="column.fieldType === FieldType.ADDRESS"
                      label="Show All" [(checked)]="addressForceSelectAll" (checkedChange)="onSelectOpen()"></oms-checkbox>
                    <div class="dialog-btns">
                      <button class="btn btn-xs  btn-primary" (click)="onCreateNewItem($event)">Create New&hellip;</button>
                    </div>
                  </div>
                </ng-template>



              </ng-select>
              <!--input class="form-control" type="text" title="None selected" data-toggle="dropdown" aria-expanded="false" readonly autofocus/-->

            </div>
          </ng-container>
        </ng-container>

      </ng-template>
    </ng-template>

    <!-- STATIC TEXT (NON-CLICKABLE) -->
    <ng-template #textStatic >
      <span [popover]="tooltip"  placement="auto" (click)="onCellClick(cell, $event)"
            container="body" (contextmenu)="onRightClick($event)" class="no-wrap" [ngClass]="{'editable-empty':editable && empty}" [class]="cellClass" >
        <i class="{{iconClass}}" *ngIf="hasIcon"></i>
        <span innerHTML="{{value | highlight : searchText : rawValue}}"></span>
      </span>

      <a [href]="cellButtonLink" *ngIf="cellButton && cellButtonIcon"
         (click)="onCellButtonClick(cell, $event)"
         [attr.hidden]="cellButtonHidden ? 'hidden' : undefined"
         [attr.target]="cellButtonRefTarget" style="float: right; padding-right: 10px;"
         [popover]="cellButtonTooltip" [container]="'body'">
        <i class="{{cellButtonIcon}}"></i>
      </a>

    </ng-template>
  </ng-container>

</ng-container>
