var ValueAccessorBase = /** @class */ (function () {
    function ValueAccessorBase() {
        this.changed = [];
        this.touched = [];
    }
    ValueAccessorBase.prototype.getValue = function () {
        return this.innerValue;
    };
    ValueAccessorBase.prototype.absent = function (value) {
        return value === undefined || value === null;
    };
    ValueAccessorBase.prototype.sameValue = function (v1, v2) {
        return this.absent(v1) && this.absent(v2) || v1 === v2;
    };
    ValueAccessorBase.prototype.setValue = function (value) {
        if (!this.sameValue(this.innerValue, value)) {
            this.innerValue = value;
            this.changed.forEach(function (f) { return f(value); });
        }
    };
    Object.defineProperty(ValueAccessorBase.prototype, "value", {
        get: function () {
            return this.getValue();
        },
        set: function (value) {
            this.setValue(value);
        },
        enumerable: true,
        configurable: true
    });
    ValueAccessorBase.prototype.writeValue = function (value) {
        this.setValue(value);
    };
    ValueAccessorBase.prototype.registerOnChange = function (fn) {
        this.changed.push(fn);
    };
    ValueAccessorBase.prototype.registerOnTouched = function (fn) {
        this.touched.push(fn);
    };
    ValueAccessorBase.prototype.touch = function () {
        this.touched.forEach(function (f) { return f(); });
    };
    return ValueAccessorBase;
}());
export { ValueAccessorBase };
