import {UploadedFiles} from "../documents/uploaded.files";

export class ComCenter {
  id: number;
  userName: string;
  text: string;
  date: Date;
  noteType: string;
  uploadedFiles: UploadedFiles;
}
