import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ModalResult, OmsDialogsService} from "../../../../../components/common/oms-dialogs";
import {AuthService, OrdersService} from "../../../../../services";
import {DispatchService, DriverAction} from "../../../../shared/services/dispatch/dispatch.service";
import {DateTimeService} from "../../../../../services/date-time.service";
import {DriverService} from "../../../../../services/driver-service";
import {OmsAlertsService} from "../../../../shared/components/oms-alerts/oms-alerts.service";
import {TruckService} from "../../../../../services/truck-service";
import {TrailerService} from "../../../../../services/trailer-service";
import {UserService} from "../../../../shared/services/user.service";
import {ManifestItem} from "../../../../shared/models/manifest/manifest-item";
import {convertLoadNumber, convertManifestNumber} from "../../../../shared/services/oms-converters.service";
import {OrderModes} from "../../../../shared/models/order/order-mode";
import {Order} from "../../../../shared/models";
import {
  OrdersDispatchData,
  OrdersDispatchDialogComponent
} from "../../../../../components/dialogs/orders-dispatch-dialog/orders-dispatch-dialog.component";
import {Manifest} from "../../../../shared/models/manifest/manifest";
import {assigned} from "../../../../../_helpers/utils";
import {minDate} from "../../../../../common/oms-date-time.utils";
import {ContextMenuComponent} from "ngx-contextmenu";
import {SplitOrderDialogComponent} from "../split-order-dialog/split-order-dialog.component";
import {isNullOrUndefined} from 'util';
import {DispatchUtils} from "../../../../../_helpers/dispatch-utils";
import {Logger} from "../../../../../_helpers/logger";


@Component({
  selector: 'manifest-item-context-menu',
  templateUrl: './manifest-item-context-menu.component.html',
  styleUrls: ['./manifest-item-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({})
export class ManifestItemContextMenuComponent implements OnInit, OnChanges {
  @ViewChild('contextMenu') public contextMenu: ContextMenuComponent;

  @Input() public manifest: Manifest;
  @Input() public manifestItem: ManifestItem;
  @Input() public items: ManifestItem[];
  @Input() public isVisibleSelect: boolean;
  @Input() public selectedOrder: Order;

  @Output() public manifestChange = new EventEmitter<Manifest>();
  @Output() public loadingChange = new EventEmitter<boolean>();
  @Output() public refreshChange = new EventEmitter<void>();

  public canDriverUpdate: boolean = false;
  public menuHeader: string = "";

  constructor(
    public dateTime: DateTimeService,
    public cdr: ChangeDetectorRef,
    private dialogs: OmsDialogsService,
    private dialog: MatDialog,
    private alerts: OmsAlertsService,
    private orderService: OrdersService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService,
    private dispatchService: DispatchService,
    private dispatchUtils: DispatchUtils,
    public userService: UserService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.initCanDriverUpdate();
  }

  private initCanDriverUpdate() {
    this.userService.getCurrentUser()
      .subscribe((user) => {
        this.canDriverUpdate = user.canDriverUpdate;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.manifestItem || changes.manifest) {
      this.menuHeader = this.manifestItem ? this.getMenuHeader(this.manifestItem) : "";
    }
  }

  private getMenuHeader(current: ManifestItem): string {
    let selected = this.getSelectedManifestItems(current);
    if (selected.length > 1) {
      return 'Multiple Selected';
    } else if (selected.length === 1) {
      return convertLoadNumber(selected[0].shipment ? selected[0].shipment.id : null);
    } else {
      return 'None Selected';
    }

  }

  public generateBOL(isOpenPDF: boolean, isPrintBOL: boolean) {
    let isNew = this.manifest.isNew() || !isNullOrUndefined(this.items.find((mi) => mi.id === undefined));
    let items: ManifestItem[] = this.getSelectedManifestItems(this.manifestItem);
    let orderIds = isPrintBOL ? items.flatMap(mi => mi.orders.map(or => or.id)) :
      items.filter((mi) => mi.id === undefined).flatMap(mi => mi.orders.map(or => or.id));
    let manifestIds = items.filter(mi => mi.id !== undefined).map(mi => mi.id);

    if (isPrintBOL) {
      this.orderService.printBOLs(orderIds).subscribe(response => {
        this.findBOLOpenPDF(response);
      });
    } else {
      if (orderIds.length) {
        this.orderService.generateBOLs(orderIds).subscribe(response => {
          this.generateBOLOpenPDF(response, isOpenPDF);
        });
      }
      if (manifestIds.length) {
        this.dispatchService.generateBOLs(manifestIds).subscribe(response => {
          this.generateBOLOpenPDF(response, isOpenPDF);
        });
      }
    }
  }

  public findBOLOpenPDF(response) {
    if (!isNullOrUndefined(response)) {
      this.orderService.openBase64PDF(response);
      this.alerts.success('Find BOLs successful', 5000);
    } else {
      this.alerts.error('No BOL available');
    }
  }

  public generateBOLOpenPDF(response, isOpenPDF: boolean) {
    if (!isNullOrUndefined(response)) {
      if (isOpenPDF) {
        this.orderService.openBase64PDF(response);
      }
      this.alerts.success('Generate BOL successful', 5000);
    } else {
      this.alerts.error('Error generate BOL');
    }
  }

  public driverActionCancel() {
    const manifestItem = this.manifestItem;
    console.log(manifestItem);

    if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
      this.alerts.warning("The manifest is not dispatched");
      return;
    }

    let items: ManifestItem[] = this.getSelectedManifestItems(manifestItem);
    if (manifestItem && !items.includes(manifestItem)) {items.push(manifestItem); }
    items.forEach(mi => {mi.orders.forEach(o => o.selected = !mi.isNew()); });
    items = items.filter(mi => mi.isSelectedFully);

    if (items.length === 0) {
      this.alerts.warning("All selected items must be dispatched");
      return;
    }

    let ids: number[] = items.map(mi => mi.id).filter(n => assigned(n));
    this.startLoading();
    this.dispatchService.routeDriverUpdateCancel(ids)
      .then((manifest: Manifest) => {
        this.stopLoading();
        this.alerts.success("Manifest Updated");

        this.manifest = this.prepareManifest(manifest);
        this.updateManifest();
        this.refreshChange.emit();
        this.cdr.markForCheck();

      })
      .catch(error => {
        this.stopLoading();
        this.alerts.error(error);
      });

  }

  public getSelectedManifestItems(current: ManifestItem): ManifestItem[] {
    let selected = this.items ? this.items.filter((mi) => mi.hasSelected) : [];
    return selected.length > 0 ? selected : (current ? [current] : []);
  }

  public get selectedOneOrder(): Order {
    if (this.selectedOrder) {
      return this.selectedOrder;
    }
    if (!this.manifestItem || this.manifestItem.orders.length !== 1) {
      return null;
    }
    return this.manifestItem.orders[0];
  }

  public consolidate() {
    this.dispatchUtils.consolidateSelectedItems(this.manifest)
      .then((result) => { if (result) { this.updateManifest(); }})
      .catch((error) => this.alerts.error(error));
  }

  public splitOrder(order: Order) {
//    const originalPcs = order.pieces;
    this.dialogs.openDialog(SplitOrderDialogComponent, {
      order: order,
      onOk: (newPcs: number, newHu: number, newWeight: number) => {
        order.pieces = newPcs;
        order.hu = newHu;
        order.weight = newWeight;

        let mi: ManifestItem = this.manifest.findItem(order.id);
        mi.update();
        this.updateManifest();
        this.refreshChange.emit();
        this.cdr.markForCheck();
      }}).then();
  }

  public unconsolidate() {
    this.dispatchUtils.unconsolidateSelectedItems(this.manifest)
      .then((result) => { if (result) {this.updateManifest(); } })
      .catch((error) => this.alerts.error(error));
  }

  public redispatchSelected(current?: ManifestItem) {

    let orders: Order[] = [];

    let hasNotCompleted = false;
    let notCompleted: ManifestItem;

    this.items.forEach((mi) => {
      let o = mi.orders.filter((o1) => o1.selected);
      if (o && o.length) {
        orders = orders.concat(o);
        if (mi.isNotCompleted()) {
          hasNotCompleted = true;
          notCompleted = mi;
        }
      }
    });

    if (orders.length === 0 && !!current) {
      orders = [...current.orders];
      if (current.isNotCompleted()) {
        hasNotCompleted = true;
        notCompleted = current;
      }

    }

    if (this.selectedAll || this.items.length === 1) {
      this.alerts.warning(`Unable to redispatch all Manifest Routes!\nPlease update Date or Driver in the Manifest Form`, 5000);
    } else if (hasNotCompleted) {
      this.alerts.warning(`Not Completed Load ${convertLoadNumber(notCompleted.shipment.id)} cannot be redispatched`, 5000);
    } else if (orders.length === 0) {
      this.alerts.warning("No items selected!", 5000);
    } else {

      let data: OrdersDispatchData = {
        noCarrier: true,
        delivery: false,
        orders: orders,
        date: this.dateTime.utcDateOfMidnight(new Date()),
        driver: null,   // this.manifest.driver,
        trailer: null,  // this.manifest.trailer,
        truck: null,    // this.manifest.truck,
        manifestOriginal: this.manifest,
      };

      this.dialogs.openDialog(OrdersDispatchDialogComponent, data, {
        handler: (res) => {
          return new Promise((resolve, reject) => {

            if (res.result === ModalResult.OK) {
              let data1 = res.data;
              let manifest: Manifest = data1.manifestToMerge;

              if (!manifest) {
                // Create new Manifest
                manifest = new Manifest();
                manifest.driver = data1.driver;
                manifest.dateDispatchedFor = data1.date;
              }
              manifest.truck = data1.truck;
              manifest.trailer = data1.trailer;

              this.startLoading();
              this.moveSelectedItemsToManifest(manifest)
                .then((r) => {
                  this.stopLoading();
                  this.cdr.markForCheck();
                  this.alerts.success(`Selected items moved to ${manifest.isNew() ? 'new' : 'existing'} Manifest ${convertManifestNumber(r.id)}`, 10000);

                  this.manifest = this.prepareManifest(r);
                  this.updateManifest();
                  this.refreshChange.emit();
                  this.cdr.markForCheck();

                  resolve(res); // close dialog
                })
                .catch(error => {
                  this.stopLoading();
                  this.alerts.error(error);
                  reject(error);
                });

            } else {
              // Cancel - allow close;
              resolve(res);
            }
          });
        }
      }).catch(error => {
        this.alerts.error(error);
      });
    }
  }

  public selectAll() {
    this.items.forEach((mi) => {
      mi.orders.forEach((o) => {o.selected = true; });
    });
  }

  public unselect() {
    this.items.forEach((mi) => {
      mi.orders.forEach((o) => {o.selected = false; });
    });
  }

  public driverAction(action: DriverAction) {
    const manifestItem = this.manifestItem;
    console.log(manifestItem, action);

    if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
      this.alerts.warning("The manifest is not dispatched");
      return;
    }

    let items: ManifestItem[] = this.getSelectedManifestItems(manifestItem);
    if (manifestItem && !items.includes(manifestItem)) {
      items.push(manifestItem);
    }
    items.forEach(mi => {
      mi.orders.forEach(o => o.selected = !mi.isNew());
    });
    items = items.filter(mi => mi.isSelectedFully);

    if (items.length === 0) {
      this.alerts.warning("All selected items must be dispatched");
      return;
    }

    let ids: number[] = items.map(mi => mi.id).filter(n => assigned(n));

    let driverId = this.manifest.driver.id;
    this.startLoading();
    this.dispatchService.routeDriverUpdate(driverId, ids, action /*, minDate(this.manifest.dateDispatchedFor, new Date())*/)
      .then((manifest: Manifest) => {
        this.stopLoading();
        this.alerts.success("Manifest Updated");

        this.manifest = this.prepareManifest(manifest);
        this.updateManifest();
        this.refreshChange.emit();
        this.cdr.markForCheck();

      })
      .catch(error => {
        this.stopLoading();
        this.alerts.error(error);
      });
  }

  private get selectedAll(): boolean {
    for (let mi of this.items) {
      for (let o of mi.orders) {
        if (!o.selected) {
          return false;
        }
      }
    }
    return true;
  }

  private startLoading() {
    this.loadingChange.emit(true);
  }

  private stopLoading() {
    this.loadingChange.emit(false);
  }

  private updateManifest() {
    this.manifestChange.emit(this.manifest);
    this.cdr.markForCheck();
  }

  private prepareManifest(manifest: Manifest): Manifest {
    manifest.items.forEach(mi => {
      mi.loadTypeOriginal = mi.loadType;
      mi.orders.forEach(o => {
        o.info.legAddressDelivery = mi.addressDelivery;
      });
    });
    return manifest;
  }

  private moveSelectedItemsToManifest(target: Manifest): Promise<Manifest> {
    let source = Object.assign(new Manifest(), this.manifest);

    const noSelected = source.items.every(item => !item.hasSelected);
    console.log('SOURCE', source);
    let items: ManifestItem[] = [...source.items];
    items.forEach(mi => {
      if (mi.isSelectedFully || (noSelected && mi === this.manifestItem)) {
        source.items.removeAll(i => i.id === mi.id);
        target.items.push(mi);
        mi.id = undefined;
      } else if (mi.isSelectedPartially) {
        let item: ManifestItem = Object.assign(new ManifestItem(), mi);
        item.id = undefined;
        item.orders = [];
        target.items.push(item);

        [...mi.orders].forEach(o => {
          if (o.selected) {
            mi.orders.removeAll(o);
            item.orders.push(o);
          }
        });

      }
    });
    source.update(true);
    target.update(true);

//    return this.dispatchService.redispatch(source, target);

    return this.dispatchService.dispatchOrders(source, false, false)
      .toPromise()
      .then((manifest) => this.dispatchService.dispatchOrders(target, false, false).toPromise());
  }
}
