<div class="row">
  <div class="col-xs-6">
    <oms-custom-search [multiple] = "true" [searchable]="true" [service]="customerService" label="Customer"
                       [(ngModel)]="customer" [sort-by]="'name'" (changing)="onCustomerChange()">
      <ng-container group-before>
        <!--mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon-->
        <i class="fa fa-user"></i>
      </ng-container>
    </oms-custom-search>
  </div>


  <div class="col-xs-6">
    <oms-custom-search  [multiple] = "true" [searchable]="true" [service]="freightForwarderService" label="F.Forwarder"
                        [(ngModel)]="freightForwarder" [sort-by]="'name'" (changing)="onFreightForwarderChange()">
      <ng-container group-before>
        <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
      </ng-container>
    </oms-custom-search>
  </div>



</div>
