var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { isNullOrUndefined } from "util";
import { DataTableComponent } from "../../data-table.component";
import { GenericTableCell } from "../mawb-table-cell/generic-table-cell";
import { Order, Master } from "../../../../../models";
import { ContextMenuService } from "ngx-contextmenu";
import { DispatchService } from "../../../../../services/dispatch/dispatch.service";
import { ManifestItemContextMenuComponent } from "../../../../../../dispatch/pages/manifest-create/manifest-item-context-menu/manifest-item-context-menu.component";
import { MatDialog } from "@angular/material";
import { OmsConstants } from "../../../../../../../common/oms-constants.service";
import { DateTimeService } from "../../../../../../../services/date-time.service";
import { CbpStatusUtils } from "../../../../../services/stb-status/cbp-status.utils";
import { CbpQuickViewStatusDialogComponent } from "../../../../../../../components/common/cbp-quick-view-status-dialog/cbp-quick-view-status-dialog.component";
import { LogChangesService } from "../../../../../../../services/logchanges/log.changes.service";
import { switchMap, map, tap } from "rxjs/operators";
import { OrdersService } from "../../../../../../../services";
import { MasterService } from "../../../../../../../services/master/master.service";
import { UserUtil } from "../../../../../../settings/util/user.util";
import { UserRoleType } from "../../../../../models/user-role.type";
var DateBatteryTableCellComponent = /** @class */ (function (_super) {
    __extends(DateBatteryTableCellComponent, _super);
    function DateBatteryTableCellComponent(table, cdr, contextMenuService, dispatchService, dialog, dateTimeService, logChangesService, orderService, masterService) {
        var _this = _super.call(this) || this;
        _this.table = table;
        _this.cdr = cdr;
        _this.contextMenuService = contextMenuService;
        _this.dispatchService = dispatchService;
        _this.dialog = dialog;
        _this.dateTimeService = dateTimeService;
        _this.logChangesService = logChangesService;
        _this.orderService = orderService;
        _this.masterService = masterService;
        _this.editing = false;
        return _this;
    }
    DateBatteryTableCellComponent.prototype.ngOnChanges = function (changes) {
        //    console.log('CHANGE CELL', changes);
        if (changes.column) {
            this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
        }
        if (changes.row || changes.column || changes.search) {
            this.updateObject();
        }
    };
    DateBatteryTableCellComponent.prototype.updateObject = function () {
        _super.prototype.updateObject.call(this);
        this.rowObject = this.getRowObject(this.row);
        this.rawValue = this.column.getValue(this.rowObject);
        this.editable = this.column.editable(this.row);
        this.empty = isNullOrUndefined(this.rawValue);
        this.iconClass = this.getIconClass(this.rowObject, this.rawValue);
        this.cellClass = this.getClass(this.rowObject, this.rawValue);
        this.hasIcon = !isNullOrUndefined(this.iconClass);
        this.tooltip = this.getTooltip(this.rowObject);
        this.isHidden = this.column.isHidden(this.rowObject, this.rawValue);
        this.cdr.markForCheck();
    };
    DateBatteryTableCellComponent.prototype.getTooltip = function (rowObject) {
        var order = rowObject instanceof Order ? rowObject : null;
        var master = rowObject instanceof Master ? rowObject : order.master;
        return CbpStatusUtils.getMasterOrder1F1CTooltip(master, order, this.data.is1F);
    };
    DateBatteryTableCellComponent.prototype.getIconClass = function (rowObject, rawValue) {
        var order = rowObject instanceof Order ? rowObject : null;
        var master = rowObject instanceof Master ? rowObject : order.master;
        return CbpStatusUtils.getBatteryIcon(master, order, this.data.is1F);
    };
    DateBatteryTableCellComponent.prototype.getClass = function (rowObject, rawValue) {
        var order = rowObject instanceof Order ? rowObject : null;
        var master = rowObject instanceof Master ? rowObject : order.master;
        return CbpStatusUtils.getBatteryColor(master, order, this.data.is1F);
    };
    DateBatteryTableCellComponent.prototype.ngOnInit = function () {
        this.editor = this.column.handlers.editor ? this.column.handlers.editor : {};
        this.updateObject();
    };
    DateBatteryTableCellComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    DateBatteryTableCellComponent.prototype.getFormatTime = function (date) {
        return this.dateTimeService.utcToNYFormat(date, OmsConstants.MOMENT_TIME_SHORT_FORMAT);
    };
    DateBatteryTableCellComponent.prototype.isReadOnly = function () {
        return this.data.readonly || !UserUtil.getCurrentUser().hasRole(UserRoleType.ROLE_AMS);
    };
    DateBatteryTableCellComponent.prototype.onCellClick = function ($event) {
        var _this = this;
        if (this.isReadOnly()) {
            return;
        }
        var order = this.rowObject instanceof Order ? this.rowObject : null;
        var master = this.rowObject instanceof Master ? this.rowObject : order.master;
        var initialDate = CbpStatusUtils.getDateSet(master, order, this.data.is1F);
        this.table.editDateTime(this.column.handlers.editor.cleanable, initialDate, null, false, $event)
            .pipe(switchMap(function (newValue) {
            var _a, _b;
            var fieldName = _this.data.is1F ? 'date1F' : 'date1C';
            if (order) {
                return _this.orderService.updateOrderObs(order.id, (_a = {}, _a[fieldName] = newValue.actual, _a))
                    .pipe(tap(function (orderMaster) { return Object.assign(_this.row, orderMaster[0]); }), map(function () { return newValue; }));
            }
            else {
                return _this.masterService.updateMasterObs(master.id, (_b = {}, _b[fieldName] = newValue.actual, _b))
                    .pipe(tap(function (orderMaster) { return Object.assign(_this.row, orderMaster); }), map(function () { return newValue; }));
            }
        }))
            .subscribe(function () {
            // this.table.updateColumnValue(this.rowObject, this.column, newValue.actual, this.column.field);
            _this.cdr.markForCheck();
            _this.updateObject();
        });
    };
    DateBatteryTableCellComponent.prototype.onRightClick = function () {
        var columnField = isNullOrUndefined(this.column.history.field) ? this.column.field : this.column.history.field;
        if (typeof columnField !== "string") {
            var fieldKeys = Object.keys(columnField);
            columnField = columnField[fieldKeys[0]];
        }
        var params = this.logChangesService.getRequiredLogCenterParameters(this.row, columnField);
        this.dialog.open(CbpQuickViewStatusDialogComponent, {
            width: '80%', height: '70%', data: {
                id: this.row.id, objectType: this.row.rowId.toUpperCase(), readonly: true,
                logParams: params, column: this.column, convertByBaseType: this.column.history.logConverter
            }
        });
        return false;
    };
    return DateBatteryTableCellComponent;
}(GenericTableCell));
export { DateBatteryTableCellComponent };
