import {Type} from 'class-transformer';
import {BaseEntity} from '../base-entity';
import {Airline, Airport} from '../air';

export class MasterShipmentAir extends BaseEntity {
  public javaClassName: string = 'com.oms.entity.master.MasterShipmentAir';

  @Type(() => Airline)
  airline: Airline;

  @Type(() => Airport)
  airport: Airport;

  flightNumber: string;
}
