import {Component, Input, OnInit} from '@angular/core';
import {FilterPageRequest} from "../../../../../modules/shared/models/filter.page.request";
import {Customer, FreightForwarder, MasterStatus} from "../../../../../modules/shared/models";
import {FreightForwarderService} from "../../../../../services/freight.forwarder.service";
import {FreightForwarderGroup} from "../../../../../modules/shared/models/freight.forwarder.group";
import {FreightForwarderGroupService} from "../../../../../services/freight.forwarder.group.service";
import {CustomerService} from "../../../../../services/customer.service";
import {assigned, isEmptyString} from "../../../../../_helpers/utils";


@Component({
  selector: 'oms-orders-report-filters',
  templateUrl: './orders-report-filters.component.html',
  styleUrls: ['./orders-report-filters.component.scss']
})
export class OrdersReportFiltersComponent implements OnInit {

  @Input() public filters: FilterPageRequest;

  public selectedFreightForwarders: FreightForwarder[] = [];
  public selectedFreightForwarderGroups: FreightForwarderGroup[] = [];
  public selectedCustomers: Customer[];
  public dateCreatedSince: Date;

  public filterYes = {id: 1, label: 'Yes'};
  public filterNo = {id: 0, label: 'No'};


  public modes = {
    field: "genericMode",
    items: [],
    selected: [],
    search: {
      searchable: true,
      multiple: true,
      searchType: "list",
    }
  };

  public status = {
    label: "Order Status",
    field: 'status',
    items: [] = MasterStatus.items,
    selected: [],
    search: {
      searchable: true,
      searchType: "list",
      items: MasterStatus.items,
      multiple: true,
    }
  };

  public deliveredOptions = [
    {id: 'NOT_DELIVERED', label: 'All Not Delivered'},
    {id: 'ALL_DELIVERED', label: 'All Delivered'},
    {id: 'DELIVERED_LAST_MONTH', label: 'Delivered Last Month'},
    {id: 'DELIVERED_LAST_WEEK', label: 'Delivered Last Week'},
    {id: 'DELIVERED_YESTERDAY', label: 'Delivered Yesterday'},
    {id: 'DELIVERED_SINCE_PREVIOUS', label: 'Delivered Since Previous Report'},
    {id: 'DELIVERED_CURRENT_YEAR', label: 'Delivered in Current Year (YTD)'},
    ];
  public selectedDeliveredOption: any;


  public compareById(a: any, b: any): boolean {
    return a && b && a['id'] && a['id'] === b['id'];
  }

  public onOrderModesChange(event) {
    this.filters.modesFilter = event ? event.map((o) => o.id) : [];
  }

  public onOrderStatusChange(event) {
    this.filters.setColumnSearchMultipleValues('status', event ? event.map((o) => o.id) : []);
  }


  constructor(
    public freightForwarderService: FreightForwarderService,
    public freightForwarderGroupService: FreightForwarderGroupService,
    public customerService: CustomerService
  ) {

  }


  ngOnInit() {
    this.modes.items = [
      {id: 0, label: 'Recovery'},
      {id: 1, label: 'Air Import'},
      {id: 2, label: 'Air Export'},
      {id: 3, label: 'LCL Import'},
      {id: 4, label: 'LCL Export'},
      {id: 5, label: 'FCL Import'},
      {id: 6, label: 'FCL Export'},
      {id: 7, label: 'Domestic'},
      {id: 8, label: 'Day Rent'},
      {id: 9, label: 'LTL'},
      {id: 10, label: 'FTL'},
      {id: 11, label: 'Shuttle'},
    ];

    if (this.filters) {
      this.dateCreatedSince = this.getDateCreatedSince();
      for (let filter of this.filters.filterByColumns) {

        if (filter.field === 'customerId') {
          this.customerService.getMany(filter.values.map(s => Number(s))).subscribe(
            (customers) => this.selectedCustomers = customers
          );
        }

        if (filter.field === 'freightForwarderId') {
          if (filter.multipleValues) {
            this.freightForwarderService.getMany(filter.values.map(s => Number(s))).subscribe(
              (ff) => this.selectedFreightForwarders = ff
            );
          }
        }

        if (filter.field === 'freightForwarderGroupId') {
          this.freightForwarderGroupService.getMany(filter.values.map(s => Number(s))).subscribe(
            (ffg) => this.selectedFreightForwarderGroups = ffg
          );
        }
      }
    }

    this.modes.selected =
      this.filters &&
      this.filters.modesFilter &&
      this.filters.modesFilter.map((mode) => this.modes.items.find((m) => m.id === mode));

    let selectedStatuses: string[] = this.filters.getColumnSearchMultipleValues('status') || [];
    this.status.selected = selectedStatuses.map((s) => MasterStatus.get(Number(s)));

    this.selectedDeliveredOption = this.filters && this.filters.anyNamedCondition(this.deliveredOptions.map((o) => o.id));
  }

/*  get selectedDeliveredOption(): string {
    return this.filters && this.filters.anyNamedCondition(this.deliveredOptions.map((o) => o.id));
  }*/

  setSelectedDeliveredOption(option: string) {
    if (this.filters) {
      this.deliveredOptions.forEach((o) => {
        this.filters.removeNamedCondition(o.id);
      });
//      this.filters.removeNamedCondition(this.selectedDeliveredOption);

      if (!isEmptyString(option)) {
        this.filters.addNamedCondition(option);
      }
    }
  }


  public get updatedYtd(): boolean {
    return this.filters.hasNamedCondition("UPDATED_YTD");
  }

  public set updatedYtd(value: boolean) {
    if (value) {
      this.filters.addNamedCondition("UPDATED_YTD");
    } else {
      this.filters.removeNamedCondition("UPDATED_YTD");
    }
  }

  public getDateCreatedSince(): Date {
    let s = this.filters && this.filters.getNamedCondition('CREATED_SINCE');
    if (!!s) {
      s = s.split('|')[1];
      return new Date(Date.parse(s));
    } else {
      return undefined;
    }
  }

  public setDateCreatedSince(date: Date) {
    this.dateCreatedSince = date;
    if (assigned(date)) {
      this.filters.addNamedCondition(`CREATED_SINCE|${date.toISOString()}`);
    } else {
      this.filters.removeNamedCondition('CREATED_SINCE');
    }
  }

  onFreightForwarderChange() {
    if (this.selectedFreightForwarders && this.selectedFreightForwarders.length) {
      this.filters.addColumnFilter('freightForwarderId', this.selectedFreightForwarders.map((ff) => ff.id));
    } else {
      this.filters.removeColumnFilter('freightForwarderId');
    }
  }

  public onFreightForwarderGroupChange() {
    if (this.selectedFreightForwarderGroups && this.selectedFreightForwarderGroups.length) {
      this.filters.addColumnFilter('freightForwarderGroupId', this.selectedFreightForwarderGroups.map((ffg) => ffg.id));
    } else {
      this.filters.removeColumnFilter('freightForwarderGroupId');
    }
  }

  onCustomerChange() {
    if (this.selectedCustomers && this.selectedCustomers.length) {
      this.filters.addColumnFilter('customerId', this.selectedCustomers.map((c) => c.id));
    } else {
      this.filters.removeColumnFilter('customerId');
    }
  }


  public get ordersBilled() {
    if (this.filters.hasNamedCondition('BILLED')) {
      return this.filterYes;
    }

    if (this.filters.hasNamedCondition('NOT_BILLED')) {
      return this.filterNo;
    }

    return undefined;
  }

  public set ordersBilled(value) {
    this.filters.removeNamedConditions('NOT_BILLED', 'BILLED');
    switch (value && value.id) {
      case 0:
        this.filters.addNamedCondition('NOT_BILLED');
        break;
      case 1:
        this.filters.addNamedCondition('BILLED');
        break;
    }
  }

}
