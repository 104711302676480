import {Exclude, Transform, Type} from 'class-transformer';
import {latest} from '../../../common/oms-date-time.utils';
import {checkNumber, equals} from '../../../_helpers/utils';
import {FillStatus} from '../../../components/common/fill-indicator/fill-indicator.component';
import {convertMasterNumber, convertOrderNumber} from '../services/oms-converters.service';
import {RowObject, TrackByObject} from '../components/base/data-table/columns/column-types';
import {Master} from './master/master';
import {MasterAir} from './master/master.air';
import {Load, LoadType} from './load';
import {ShipmentContent} from './shipment.content';
import {Address, CfsLocation} from './address';
import {Customer} from './customer';
import {Shipment} from './shipment';
import {MasterStatusId, transformMasterStatusId} from './master/master-status';
import {OmsConstants} from '../../../common/oms-constants.service';
import {BaseEntity} from "./base-entity";
import {OrderMode, OrderModes, transformOrderMode} from "./order/order-mode";
import {isNullOrUndefined} from "util";
import {FreightForwarder} from "./freight.forwarder";
import {Uld, UldRecovery} from "./master/masterAirUld";
import {OrderDispatch} from "./dispatch/order-dispatch";
import {Carrier} from "./carrier";
import {Airport} from "./air";
import {Billable, Comparable} from "../../../common/oms-types";
import {Driver} from "./driver";
import {StringUtil} from "../../../_helpers/string.util";

interface OrderInfo {
  legAddressPickup?: Address;
  legAddressDelivery?: Address;
}

export class Order extends BaseEntity implements RowObject, Comparable<Order>, TrackByObject, Billable {
  public rowId = 'Order';
  public javaClassName: string = 'com.oms.entity.order.Order';
  public uid: number;

  @Exclude() // have to create additional Order-Descendant object OrderRoute, or use OrderDispatch instead to store this info
  info: OrderInfo = {};

  @Exclude()
  public selected: boolean;

  @Transform((v, o, tt) => transformOrderMode(v, o, tt))
  public genericMode: OrderMode;

  public customerRef: string;  // ref1  Customer Ref || REF , depending on Mode
  public hawb: string;         // ref2  HAWB || Container Number , depending on Mode
  public ref3: string = '';      // ref3: MAWB ||  BOOKING # || MBL , depending on Mode

  public huUnits: string;
  public pcsUnits: string;
  public quote: number;

  get mawb(): string {
    return this.ref3;
  }

  set mawb(value: string) {
    this.ref3 = value;
  }

  hu: number;     // Handling Units declared in Manifest
  pieces: number; // Pieces declared in Manifest
  pcsReceived: number;
  huReceived: number;
  volume: number; // in cubic meters
  weight: number; // in kilograms
  pcs1F: number;
  pcs1C: number;


  isAcknowledged: boolean = false;
  isHazardous: boolean;
  deliveryApproval: boolean = false;
  pttCreated: boolean = false;
  dispatchedStatus: boolean = false;
  hasProblem: boolean = false;
  deliveryAppointmentRequired: boolean = false;
//  recoveryMasterId: number;


  @Transform((v, o, tt) => transformMasterStatusId(v, o, tt))
  status: MasterStatusId = MasterStatusId.NEW;

  requestedBy: string;

  @Type(() => Date)
  date1C: Date;
  @Type(() => Date)
  date1CUnchecked: Date;
  @Type(() => Date)
  date1F: Date;
  @Type(() => Date)
  dateISCPaid: Date;
  @Type(() => Date)
  dateCfsInAct: Date;
  @Type(() => Date)
  dateCfsInEst: Date;
  @Type(() => Date)
  dateCfsOutAct: Date;
  @Type(() => Date)
  dateCfsOutEst: Date;
  @Type(() => Date)
  dateCfsFreeTime: Date;

  @Type(() => Customer)
  customer: Customer;
  @Type(() => FreightForwarder)
  freightForwarder: FreightForwarder;

  @Type(() => Date)
  dateDeleted: Date;
  @Type(() => Date)
  dateDocsToWarehouse: Date;

  @Type(() => Date)
  dateBilled: Date;
  @Type(() => Date)
  datePickupEst: Date;
  @Type(() => Date)
  datePickupAct: Date;

  @Type(() => Date)
  dateDeliveryReq: Date;  // todo Should Be dateDeliveryEst
  @Type(() => Date)
  dateDeliveryAct: Date;
  @Type(() => Date)
  requestedDeliveryDate: Date;
  @Type(() => Address)
  addressDelivery: Address;
  @Type(() => Address)
  addressCfs: Address;
  @Type(() => CfsLocation)
  cfsLocation: CfsLocation;
  @Type(() => Address)
  addressCargoBuilding: Address;
  @Type(() => Carrier)
  carrier: Carrier;

  @Type(() => ShipmentContent)
  shipmentContents: ShipmentContent[] = [];

  @Type(() => Master)
  master: Master;

  // dynamically updating internal data (not retrieved by Service)
  @Exclude()
  public data: {
    recoveringMasterId?: number
  } = {};

  @Type(() => Airport)
  originAirport: Airport;

  overageNotes: number;
  shortageNotes: number;
  damageNotes: number;
  hazardousNotes: number;
  defaultNotes: number;
  documents: number;
  podDocuments: number;
  manifestDocuments: number;

  cbpStatus: string;
  cbpStatusPiecesNotValid: boolean;

  /*** @deprecated ***/
  @Type(() => Uld)
  ulds: Uld[] = [];
  /*** @deprecated ***/
  uldCount: number;

  @Type(() => UldRecovery)
  uldsRecovered: UldRecovery[] = [];

  collapsed: boolean;
  edit: boolean;

  hot: boolean;
  cod: boolean;
  holdStatus: boolean;
  chassis: string;
  podSingerName: string;
  purchaseOrder: string;
  descriptionOfGoods: string;
  dispatchNotes: string;
  manual1FAms: boolean;
  manual1CAms: boolean;

  constructor(id?: number) {
    super(id);
    this.dateCreated = new Date();
  }

  public static fromOrderDispatch(dispatch: OrderDispatch): Order {
    let order = new Order(dispatch.orderId);
    order.customerRef = dispatch.customerRef;
    order.customer = dispatch.customer;
    order.freightForwarder = dispatch.freightForwarder;
    order.hawb = dispatch.hawb;
    order.id = dispatch.orderId;
    order.genericMode = dispatch.orderMode;
    order.pieces = dispatch.pieces;
    order.hu = dispatch.hu;
    order.weight = dispatch.weight;
    order.ref3 = dispatch.mawb;
    order.addressCfs = dispatch.addressPickup;
    order.addressDelivery = dispatch.addressDelivery;
    order.dispatchNotes = dispatch.dispatchNotes;
    order.hot = dispatch.hot;
    order.cod = dispatch.cod;
    order.purchaseOrder = dispatch.purchaseOrder;
    order.status = dispatch.orderStatus && dispatch.orderStatus.id;
    order.requestedDeliveryDate = dispatch.requestedDeliveryDate;
    order.quote = dispatch.quote;

    if (dispatch.masterId) {
      order.master = new Master();
      order.master.id = dispatch.masterId;
//      order.master.mawbNumber = dispatch.mawb;
    }

    return order;
  }

  public static newGenericOrder(mode: OrderMode = OrderModes.defaultMode): Order {
    let order = new Order();
    order.genericMode = mode;
    order.status = MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH;

/*
    let shipmentContent = new ShipmentContent();
    shipmentContent.shipment = new Shipment();
    shipmentContent.order = order;
    order.shipmentContents.push(shipmentContent);

    let load = new Load();
    load.loadType = LoadType.DELIVERY;
    shipmentContent.shipment.load = load;
    load.shipment = shipmentContent.shipment;
*/
    return order;
  }

  public static newOrder(type): Order {
    let order = new Order();

    let shipmentContent = new ShipmentContent();
    shipmentContent.shipment = new Shipment();
    shipmentContent.shipment.master = new Master();
    order.master = shipmentContent.shipment.master;

    // todo depends on type
    shipmentContent.shipment.master.masterAir = new MasterAir();
    shipmentContent.order = order;
    order.shipmentContents.push(shipmentContent);

    return order;
  }

  public static createOrderByMaster(master: Master): Order {
    let order = new Order();
    order.master = master;
    order.addressDelivery = master.orders.asUniqueValue(o => o.addressDelivery) || master.orders.lastValueOf(o => o.addressDelivery);
    order.customerRef = master.customerRef;

    // 1. Arrived with Master's first shipment shipment
    let shipmentContent = new ShipmentContent();
    shipmentContent.shipment = master.firstShipment.shipment;
    shipmentContent.shipment.master = master;
    shipmentContent.order = order;
    order.shipmentContents.push(shipmentContent);

//    if (master.orders.length === 0) {
      // Create New Loads for first Order
//      console.log('CREATING');

      let content: ShipmentContent;
/*
      // 4. Create Recovery Load Shipment
      content = new ShipmentContent();
      content.shipment = new Shipment();
      content.shipment.load = new Load();
      content.shipment.load.loadType = LoadType.RECOVERY;
      content.shipment.load.shipment = content.shipment;
      content.order = order;
      order.shipmentContents.push(content);
 */

      // 5. Create Delivery Load Shipment
      content = new ShipmentContent();
      content.shipment = new Shipment();
      content.shipment.load = new Load();
      content.shipment.load.loadType = LoadType.DELIVERY;
      content.shipment.load.shipment = content.shipment;
      content.order = order;
      order.shipmentContents.push(content);

/*    } else {
      // 2. Share existing loads of Master
      let loads: Set<Load> = new Set<Load>();
      master.orders.forEach(o1 => {
        o1.shipmentContents.forEach(sc1 => {
          if (sc1.shipment.load && !loads.has(sc1.shipment.load)) {
            let sc2: ShipmentContent = new ShipmentContent();
            sc2.shipment = sc1.shipment;
            sc2.order = order;
            order.shipmentContents.push(sc2);
            loads.add(sc1.shipment.load);
          }
        });
      });
    } */

    return order;
  }

  get customerName(): string | null {
    return this.customer ? this.customer.name : null;
  }

  get loadShipments(): Shipment[] {
    return this.shipmentContents
      .filter((sc) => sc.shipment && sc.shipment.load)
      .map((sc) => sc.shipment);
/*    let loadShipments: Shipment[] = [];
    this.shipmentContents.forEach((content, index, array) => {
      if (content.shipment && content.shipment.load) {
        loadShipments.push(content.shipment);
      }
    });
    return loadShipments;
 */
  }

  get loadShipmentContents(): ShipmentContent[] {
    return this.shipmentContents.filter((sc) => sc.shipment && sc.shipment.load);

/*    let loadShipmentContents: ShipmentContent[] = [];
    this.shipmentContents.forEach((content, index, array) => {
      if (content.shipment && content.shipment.load) {
        loadShipmentContents.push(content);
      }
    });
    return loadShipmentContents;
 */
  }

  @Exclude()
  get addressPickup(): Address {
    const master = this.master;
    return master ? (master.shipment ? master.shipment.addressDelivery : null) : null;
  }

  @Exclude()
  get orderNumber(): string {
    return convertOrderNumber(this.id, this.isRecovery);
  }

  @Exclude()
  get isRecovery(): boolean {
    return this.genericMode === OrderMode.RECOV;
  }

  get masterNumber(): string {
    return this.master ? convertMasterNumber(this.master.id) : null;
  }

  get loadsNumber(): string {
    const loads: Shipment[] = this.loadShipments;
    let loadIDs = '';
    loads.forEach(load => {
      loadIDs = loadIDs + ' ' + load.load.id;
    });
    return loadIDs;
  }

  get availablePieces(): number {
    const loadShipmentContents = this.loadShipmentContents;
    let alreadyInLoad: number = 0;
    loadShipmentContents.forEach(content => {
      alreadyInLoad += content.pieces;
    });
    return this.pieces - alreadyInLoad;
  }

  get addressDelivery_() {
    return this.addressDelivery;
  }

  set addressDelivery_(address) {
    this.addressDelivery = address;
  }

  @Exclude()
  get contentsDelivery(): ShipmentContent[] {
    return this.shipmentContents.filter(sc => sc.shipment && sc.shipment.load && sc.shipment.load.isDelivery);
  }

  @Exclude()
  get contentsRecovery(): ShipmentContent[] {
    return this.shipmentContents.filter(sc => sc.shipment && sc.shipment.load && sc.shipment.load.isRecovery);
  }


  @Exclude()
  get masterContents(): ShipmentContent[] {
    return this.shipmentContents.filter(sc => sc.shipment && !sc.shipment.load);
  }


  @Exclude()
  get partsArriving(): ShipmentContent[] {
    let scs: ShipmentContent[] = [];
    this.master.masterShipments.forEach(ms => {
      ms.shipment.shipmentContents.filter(sc => equals(this, sc.order)).forEach(sc => {
        scs.push(sc);
      });
    });
    return scs;
  }



  get hu_(): number {
    return ~~this.huReceived || ~~this.hu;
  }

  get fillStatusPrc(): number {
    return (this.pcsReceived * 100 / this.pieces) || 0;
  }


  get fillStatus(): FillStatus {
    let received: number = this.pcsReceived;
    if (received > 0) {
      if (received < this.pieces) {
        return FillStatus.HALF;
      } else if (received > this.pieces) {
        return FillStatus.OVER;
      } else {
        return FillStatus.FULL;
      }
    } else {
      return FillStatus.EMPTY;
    }
  }

  get fillStatusDescription(): string {
    if (this.direct) {
      return 'Direct';
    } else {
      return StringUtil.receivingDescription(this.pieces, this.pcsReceived);
    }
  }

  get pcsArrived(): number {
    return this.partsArriving.aggregate((acc, sc) => acc + (sc.isArrived ? ~~sc.pieces : 0), 0);
  }

  get documentNumber(): string {
    return convertOrderNumber(this.id);
  }

  get dateArrivalAct(): Date {
    return latest(this.masterContents, sc => sc.shipment.dateDeliveryActual);
  }

  set dateArrivalAct(date: Date) {
    if (!equals(date, this.dateArrivalAct)) {
      this.masterContents.forEach(sc => {
        sc.shipment.dateDeliveryActual = date;
      });
    }
  }

  get dateArrivalEst() {
    return latest(this.masterContents, sc => sc.shipment.dateDeliveryExpectation);
  }

  set dateArrivalEst(date: Date) {
    if (!equals(date, this.dateArrivalEst)) {
      this.masterContents.forEach(sc => {
        sc.shipment.dateDeliveryExpectation = date;
      });
    }
  }

  get isOSD() {
    return checkNumber(this.shortageNotes, 0) + checkNumber(this.overageNotes, 0) + checkNumber(this.damageNotes, 0);
  }

  get osdNotes(): number {
    return ~~this.overageNotes + ~~this.shortageNotes + ~~this.damageNotes;
  }

  get noCfs(): boolean {
    return this.master && this.master.noCfs;
  }

  get nonAMS(): boolean {
    return this.master && this.master.nonAMS;
  }

  get direct(): boolean {
    return this.master && this.master.direct;
  }

  @Exclude()
  get deliveryDriver(): string {
    let drivers: Driver[] = this
      .contentsDelivery
      .filter((sc) => !sc.shipment.hasProblem)
      .map((sc) => sc.shipment.load.driver)
      .unique();
//    console.log('FOUND DRIVERS', drivers);
    return (!drivers || !drivers.length) ?
        OmsConstants.EMPTY_VALUE : (drivers.length > 1 ? OmsConstants.MULTIPLE_VALUE : drivers[0].fullName || OmsConstants.EMPTY_VALUE);
  }

  @Exclude()
  get masterID() {
    return this.master ? this.master.id : null;
  }

  get isPiecesInAMS(): boolean {
    return this.cbpStatusPiecesNotValid;
  }

  @Exclude()
  get isGeneric(): boolean {
    return !isNullOrUndefined(this.genericMode);
  }

  @Exclude()
  get isAirImport(): boolean {
    return OrderModes.isAirImport(this.genericMode);
  }

  trackBy(): any {
    return this.id;
  }
}
