import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild,
  EventEmitter, HostBinding, Inject,
  Input,
  IterableDiffer,
  IterableDiffers,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  OnInit, Output, QueryList,
  SimpleChanges, TemplateRef, ViewChildren, ViewEncapsulation,
} from '@angular/core';
import {DataTableComponent} from '../data-table.component';
import {Subject} from 'rxjs';
import {BaseColumn} from '../columns/column-types';
import {PARENT_REF, TreeModel} from '../data-table.utils';
import {Logger} from '../../../../../../_helpers/logger';
import {takeUntil} from "rxjs/operators";
import {isNullOrUndefined} from "util";
import {DataTableCellComponent} from "../data-table-cell/data-table-cell.component";

@Component({
  selector: 'oms-data-table-row',
  templateUrl: './data-table-row.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({
  name: 'DataTableRowComponent'
})
export class DataTableRowComponent implements OnInit, OnDestroy, OnChanges {

  @Input('top-offset') topOffset: number = 0;
  @Input() odd: boolean;
  @Input() tree: TreeModel;
  @Input('parent-row') parentRow: any;
  @Input() index: number;
  @Input() row: any;
  @Input() specificRowClassHandler: any[] = null; // todo refactor
  @Input() selectable: boolean = false;
  @Input() expandedItems: any[] = [];
  @Input('row-draggable') rowDraggable: boolean = false;

  @Input() public dragScope: string | string[];
  @Input() public dropScope: string | string[];

  @ContentChild('template', { read: TemplateRef }) template: TemplateRef<any>;

  @Input() selected: boolean;
  @Output() selectedChange = new EventEmitter<MouseEvent>();

  @ViewChildren('cells') cells: QueryList<DataTableCellComponent<any, any>>;

//  @Input() public table: DataTableComponent;

//  @ViewChildren(DataTableCell) public cells: QueryList<DataTableCell<GenericTableCell<MasterLine>, MasterLine>>;

  rowClassesDef: any;
  rowStyles: any = {};
  rowHeight: number;
  @HostBinding('class.row-expanded') expanded: boolean;
  specificRowClass: string;
  rowClass: string;
  isLeaf: boolean;
  private rowDiffer: KeyValueDiffer<{}, {}>;
  private columnsDiffer: IterableDiffer<BaseColumn>;
  private unsubscribe$ = new Subject<void>();

  get isChild(): boolean {
    return !!this.parentRow;
  }

  constructor(
      @Inject(DataTableComponent) public table: DataTableComponent,
      private cdr: ChangeDetectorRef,
      private differs: KeyValueDiffers,
      private iterableDiffers: IterableDiffers,
  ) {
    this.columnsDiffer = this.iterableDiffers.find([]).create(null);
    this.rowDiffer = differs.find({}).create();
  }

  ngOnInit() {
    this.dragScope = this.table.getRowDragScope(this.row);
    this.dropScope = this.table.getRowDropScope(this.row);

    this.table.expandEvent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.cdr.markForCheck());
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.row || changes.parentRow) {
      if (!isNullOrUndefined(this.parentRow)) {
        this.row[PARENT_REF] = this.parentRow;
      }
      this.rowClass = this.table.getRowClass(this.row);
      this.isLeaf = this.table.isLeaf(this.row);
    }
    if (changes.row || changes.parentRow || changes.tree || changes.expandedItems) {
      let isTree = !isNullOrUndefined(this.tree);
      this.expanded = isTree ? this.table.isExpanded(this.row) : false;
    }
    if (changes.specificRowClassHandler || changes.row || changes.parentRow) {
      this.specificRowClass = this.generateSpecificRowClass();
    }
    if (changes.odd || changes.expanded || changes.row || changes.parentRow || changes.selected || changes.expandedItems) {
      this.updateRowClassesDef();
    }
    if (changes.topOffset) {
      this.updateRowStyles();
    }
    if (changes.row || changes.parentRow) {
      this.rowHeight = this.table.getRowHeight(this.row);
    }
  }

  selectedRow(event: MouseEvent) {
    if (this.selectable) {
      this.selectedChange.emit(event);
    }
  }

  generateSpecificRowClass() {
    if (isNullOrUndefined(this.specificRowClassHandler)) {
      return null;
    } else {
      let specificClass = this.specificRowClassHandler.find((handler) => {
        return this.row[handler.field].toString() === handler.value;
      });
      return isNullOrUndefined(specificClass) ? '' : specificClass.class;
    }
  }

  private updateRowClassesDef() {
    let classes = {
      'row-archived': this.row && !isNullOrUndefined(this.row.dateDeleted),
      'data-table-row': true,
      'info': this.selected,
      'table-row-odd': this.odd,
      'table-row-even': !this.odd,
      'child-row': this.isChild,
      'expanded': this.isChild || this.expanded
    };

    if (!isNullOrUndefined(this.specificRowClass)) {
      classes[this.specificRowClass] = true;
    }

    this.rowClassesDef = classes;
  }

  ngOnDestroy(): void {
    this.table = undefined;
    this.tree = undefined;

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  /*  get rowClassesDef() {
      let classes = {
        'row-archived' : this.row && !isNullOrUndefined(this.row.dateDeleted),
        'data-table-row': true,
        'info': this.table.isSelected(this.row),
        'table-row-odd': this.odd,
        'table-row-even':!this.odd,
        'child-row':this.isChild,
        'expanded': this.isChild || this.expanded
      };

      if(!isNullOrUndefined(this.specificRowClass)){
        classes[this.specificRowClass] = true;
      }

      return classes;
    }*/


  get top(): number {
    return this.isChild ? (this.topOffset || 0) : (this.table.virtualization ? this.table.rowHeightsCache.query(this.table.getRowIndex(this.row) - 1) : 0);
  }

  getRowStyles(row: any, parent?: any) {
    return this.rowStyles;
  }

  private updateRowStyles() {
    let styles = {};

    if (this.table.virtualization) {
//      const idx = this.table.getRowIndex(this.row);
//      let top = this.table.rowHeightsCache.query(idx - 1);
//      styles['height'] = this.table.rowHeight + 'px';
      styles['position'] = 'absolute';
      styles['transform'] = `translate3d(0px, ${this.top}px, 0px)`;
    }
    this.rowStyles = styles;
  }

  getChildRowOffset(index: number, items: any[]): number {
    let offset = this.isChild ? this.topOffset || 0 : (this.table.virtualization ? this.table.rowHeightsCache.query(this.table.getRowIndex(this.row) - 1) : 0);
    offset += this.table.getRowHeight(this.row);
    if (!this.table.isLeaf(this.row)) {
      for (let i = 0; i < index; i++) {
        offset += this.table.getRowHeight(items[i]);
      }
    }
    return offset;
  }

  public trackByRowObject(index: number, item: {id: number}): number {
    return item && item.id ? item.id : index;
  }

  public onRowClick(row, event: MouseEvent) {
    if (this.selectable) {
      this.table.onRowSelect(row, event);
    } else if (this.parentRow) {
      this.table.onRowSelect(this.parentRow, event);
    }
  }

  public reRender() {
    this.cells.forEach(cell => cell.reRender());
    this.cdr.markForCheck();
  }
}
