var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isNullOrUndefined } from "util";
import { isArray } from 'rxjs/internal-compatibility';
import { padZeros } from "../modules/shared/services/oms-converters.service";
import { BehaviorSubject } from "rxjs";
export function assigned(value) {
    return !absent(value);
}
export function absent(value) {
    return value === undefined || value === null;
    //  return isNullOrUndefined(object);
}
export function isEmpty(array) {
    return !(array && array['length'] > 0);
}
export function getBaseUrl() {
    return window.location.origin;
}
export function encryptNumber(i, additional) {
    var n = ((++i) * 12345) + '';
    if (additional) {
        n += '#' + padZeros(additional || 0, 6);
    }
    return btoa(n);
}
export function buildCfsStatusPageUrl(ffgId, orderId) {
    var encrypted = encryptNumber(ffgId, orderId);
    var path = "cfs-status";
    return getBaseUrl() + "/" + path + "/" + encodeURIComponent(encrypted);
}
export function openFullscreen() {
    var elem = document.documentElement;
    var methodToBeInvoked = elem.requestFullscreen || elem['webkitRequestFullScreen'] ||
        elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
        methodToBeInvoked.call(elem);
    }
}
/*
export function closeFullscreen() {
  if (this.document.exitFullscreen) {
    this.document.exitFullscreen();
  } else if (this.document.mozCancelFullScreen) {
    this.document.mozCancelFullScreen();
  } else if (this.document.webkitExitFullscreen) {
    this.document.webkitExitFullscreen();
  } else if (this.document.msExitFullscreen) {
    this.document.msExitFullscreen();
  }
}
*/
export function copyToClipboard(text) {
    var selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
}
export function printElement(elem) {
    //  let printContents = document.getElementById(divName).innerHTML;
    //  let originalContents = document.body.innerHTML;
    //  document.body.innerHTML = elem.innerHtml;
    //  window.print();
    //  document.body.innerHTML = originalContents;
    var w = window.open('', '_blank');
    w.document.write(elem.innerHTML);
    w.print();
    w.close();
}
export function downloadFile(uri) {
    var link = document.createElement('a');
    link.href = uri;
    link.download = uri.substr(uri.lastIndexOf('/') + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export function sendEmail(subject, text, to) {
    var link = document.createElement('a');
    link.href = "mailto:" + to + "?subject=" + subject + "&body=" + text;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export function openMailTo(email, subject, cc, body) {
    if (cc === void 0) { cc = []; }
    var content = 'mailto:' + email + '?';
    if (cc && cc.length) {
        content += 'cc=' + encodeURIComponent(cc.join(", ")) + '&';
    }
    if (subject) {
        content += 'subject=' + encodeURIComponent(subject) + '&';
    }
    if (body) {
        content += 'body=' + encodeURIComponent(body);
    }
    window.open(content);
}
export function checkNumber(n, def) {
    if (def === void 0) { def = null; }
    if (isNullOrUndefined(n)) {
        return def;
    }
    if (isNaN(n)) {
        n = Number(n.toString().replace(/[^\d.-]/g, ''));
    }
    return isNaN(n) ? def : n;
}
export function ensureNumber(n) {
    return isNullOrUndefined(n) || typeof n !== 'number' ? 0 : n;
}
export function toFixed(x, d) {
    return isNullOrUndefined(x) ? x : parseFloat(x.toFixed(d));
    //  if (!d) return x.toFixed(d); // don't go wrong if no decimal
    //  return x.toFixed(d).replace(/\.?0+$/, '');
}
export function clamp(n, min, max) {
    return Math.max(min, Math.min(max, n));
}
export function getParent(element, type) {
    do {
        element = element.parentElement;
    } while (element && (element.constructor.name !== type.name));
    return element;
}
export function findAncestor(el, sel) {
    while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el, sel))) { }
    return el;
}
export function ifChanged(oldValue, newValue, onChanged) {
    if (!equals(oldValue, newValue)) {
        onChanged(newValue);
    }
}
var BehaviorArraySubject = /** @class */ (function (_super) {
    __extends(BehaviorArraySubject, _super);
    function BehaviorArraySubject() {
        return _super.call(this, []) || this;
    }
    BehaviorArraySubject.prototype.next = function (value) {
        _super.prototype.next.call(this, value ? value : []);
    };
    Object.defineProperty(BehaviorArraySubject.prototype, "length", {
        get: function () {
            return this.value.length;
        },
        enumerable: true,
        configurable: true
    });
    BehaviorArraySubject.prototype.clear = function () {
        this.next([]);
    };
    BehaviorArraySubject.prototype.add = function () {
        var items = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            items[_i] = arguments[_i];
        }
        var toSelect = this.value.concat(items).unique();
        this.next(toSelect.slice());
    };
    BehaviorArraySubject.prototype.remove = function () {
        var items = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            items[_i] = arguments[_i];
        }
        console.log('Remove Selection 1');
        var toSelect = this.value.filter(function (item) { return !items.includes(item); });
        this.next(toSelect);
    };
    BehaviorArraySubject.prototype.has = function (item) {
        return this.value.includes(item);
    };
    return BehaviorArraySubject;
}(BehaviorSubject));
export { BehaviorArraySubject };
Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
};
Date.prototype.isToday = function () {
    var today = new Date();
    return this.getDate() === today.getDate() &&
        this.getMonth() === today.getMonth() &&
        this.getFullYear() === today.getFullYear();
};
Array.prototype.compare = function (target, action, fncmp) {
    var _this = this;
    if (target) {
        var toAdd_1 = [];
        var toDelete_1 = [];
        var cmp_1 = fncmp || equals;
        target.forEach(function (o) {
            if (!_this.some(function (i) { return cmp_1(i, o); })) {
                toAdd_1.push(o);
            }
        });
        this.forEach(function (o) {
            if (!target.some(function (i) { return cmp_1(i, o); })) {
                toDelete_1.push(o);
            }
        });
        action(toAdd_1, toDelete_1);
    }
};
Array.prototype.first = function () {
    return this.length > 0 ? this[0] : null;
};
Array.prototype.last = function () {
    return this.length > 0 ? this[this.length - 1] : null;
};
Array.prototype.next = function (item, loop) {
    var i = this.indexOf(item);
    return i < 0 || this.length <= i + 1 ? (loop ? this.first() : null) : this[i + 1];
};
Array.prototype.prev = function (item, loop) {
    var i = this.indexOf(item);
    return i < 1 ? (loop ? this.last() : null) : this[i - 1];
};
Array.prototype.replaceAll = function (o, n, fn) {
    var _this = this;
    var result = false;
    this.forEach(function (e, i) { if (equals(e, o)) {
        result = true;
        if (fn) {
            fn(n, _this[i]);
        }
        _this[i] = n;
    } });
    return result;
};
Array.prototype.aggregate = function (fn, initial) {
    var acc = initial;
    this.forEach(function (item) { acc = fn(acc, item); });
    return acc;
};
Array.prototype.lastValueOf = function (fn) {
    var item = this.length ? this[this.length - 1] : null;
    return item && fn ? fn(item) : item;
};
Array.prototype.asUniqueValue = function (f, ifMultiple, ifEmpty) {
    var result = null;
    var multi = false;
    this.some(function (item) {
        var next = f ? f(item) : item;
        if (isNullOrUndefined(result)) {
            result = next;
        }
        else {
            multi = !equals(result, next);
        }
        return multi;
    });
    return multi ? ifMultiple : result == null && ifEmpty ? ifEmpty : result;
};
Array.prototype.unique = function (f) {
    var result = [];
    this.forEach(function (item) {
        var d = f ? f(item) : item;
        if (d && !result.hasEquals(d)) {
            result.push(d);
        }
    });
    return result;
};
Array.prototype.duplicates = function (f) {
    var _this = this;
    return this.filter(function (i) { return _this.filter(function (ii) { return isNullOrUndefined(f) ? equals(ii, i) : equals(f(ii), f(i)); }).length > 1; });
};
Array.prototype.removeAll = function (o, onRemove) {
    var _this = this;
    var had = false;
    this.forEach(function (item, index) {
        if (typeof o === 'function' ? o(item) : equals(o, item)) {
            had = true;
            if (onRemove) {
                onRemove(item);
            }
            _this.splice(index, 1);
        }
    });
    return had;
};
Array.prototype.flatMap = function (fn) {
    var result = [];
    this.forEach(function (item) {
        var items = fn(item);
        if (items && items.length) {
            result.push.apply(result, items);
        }
    });
    return result;
};
Array.prototype.hasEquals = function (o) {
    return this.some(function (item) { return typeof o === 'function' ? o(item) : equals(item, o); });
};
Array.prototype.clear = function () {
    this.length = 0;
    return this;
};
Array.prototype.findEquals = function (o) {
    var found;
    this.some(function (item) { if (equals(item, o)) {
        found = item;
    } return !isNullOrUndefined(found); });
    return found;
};
Array.prototype.findFirst = function (f) {
    var found;
    this.some(function (item) { if (f(item)) {
        found = item;
    } return !isNullOrUndefined(found); });
    return found;
};
Array.prototype.equals = function (array) {
    var _this = this;
    return isNullOrUndefined(array) ? this.length === 0 : !(this.some(function (item) { return !array.hasEquals(item); }) || array.some(function (item) { return !_this.hasEquals(item); }));
};
export function cumulativeOffset(element, relative) {
    var top = 0, left = 0;
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent || element.parentElement;
    } while (element && (relative ? relative !== element : true));
    return {
        top: top,
        left: left
    };
}
export function getOffset(el) {
    var rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}
export function isEmptyString(s) {
    return isNullOrUndefined(s) || s.toString().trim().length === 0;
}
export function notEmptyString(s) {
    return s && !!s.trim().length;
}
// http://stackoverflow.com/a/10899795/604296
export function addThousandsSeparator(number, sep) {
    var parts = number.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep) + (parts[1] ? "." + parts[1] : "");
}
export function isComparable(object) {
    return object && typeof object.equals === 'function';
}
export function equals(a, b) {
    return isComparable(a) ? a.equals(b) : a === b;
}
export function contains(object, arr) {
    return arr.some(function (item) { return equals(item, object); });
}
export function cmpNumeric(a, b) {
    if (isNaN(a)) {
        return isNaN(b) ? 0 : -1;
    }
    if (isNaN(b)) {
        return isNaN(a) ? 0 : 1;
    }
    return a - b;
}
export function indexOf(object, arr) {
    var res = -1;
    arr.some(function (item, index) { if (equals(item, object)) {
        res = index;
    } return res >= 0; });
    return res;
}
export function find(object, array) {
    var found;
    if (object && array) {
        array.some(function (item) { if (equals(item, object)) {
            found = item;
        } return !isNullOrUndefined(found); });
    }
    return found;
}
export function asArray(o) {
    return isNullOrUndefined(o) || isArray(o) ? o : [o];
}
export function separatedValuesString(array, valueFn, separator) {
    //  let s ='';
    //  if (array) {array.forEach(item => {s += s.length===0 ? valueFn(item) : separator+ valueFn(item);});}
    //  return s;
    if (separator === void 0) { separator = ', '; }
    return array.aggregate(function (acc, item) { return acc + (acc.length === 0 ? valueFn(item) : separator + valueFn(item)); }, '');
}
export function isMobile() {
    var userAgent = navigator.userAgent;
    return /\b(iPhone|iP[ao]d)/.test(userAgent) ||
        /\b(iP[ao]d)/.test(userAgent) ||
        /Android/i.test(userAgent) ||
        /Mobile/i.test(userAgent);
}
export function popupCenter(url, title, w, h) {
    var mobile = isMobile(), screenX = window.screenX | window.screenLeft, screenY = window.screenY | window.screenTop, outerWidth = window.outerWidth | document.documentElement.clientWidth, outerHeight = window.outerHeight | (document.documentElement.clientHeight - 22), V = screenX < 0 ? window.screen.width + screenX : screenX, left = V + (outerWidth - w) / 2, top = screenY + (outerHeight - h) / 2.5, features = [
        'left=' + left,
        'top=' + top,
        'scrollbars=yes',
        'resizable=yes',
        'directories=no',
        'titlebar=no',
        'toolbar=no',
        'location=no',
        'status=no',
        'menubar=no'
    ];
    if (!mobile) {
        features.push('width=' + w);
        features.push('height=' + h);
    }
    var newWindow = window.open(url, title, features.join(','));
    if (window.focus) {
        newWindow.focus();
    }
    return newWindow;
}
export function nextChar(c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
}
export function nextLetter(s) {
    return s.replace(/([a-zA-Z])[^a-zA-Z]*$/, function (a) {
        var c = a.charCodeAt(0);
        switch (c) {
            case 90: return 'A';
            case 122: return 'a';
            default: return String.fromCharCode(++c);
        }
    });
}
export function diff(source, dest, add, remove) {
    if (isNullOrUndefined(dest) || isNullOrUndefined(source)) {
        return false;
    }
    var changed = false;
    //  let toAdd: any[] = [];
    //  let toDelete: any[] = [];
    if (isNullOrUndefined(source)) {
        if (isNullOrUndefined(dest)) {
            return false;
        }
        else {
            dest.forEach(function (item) { changed = true; add(item); });
        }
    }
    else {
        if (isNullOrUndefined(dest)) {
            source.forEach(function (item) { changed = true; remove(item); });
        }
        else {
            dest.forEach(function (item) {
                if (!source.hasEquals(item)) {
                    changed = true;
                    add(item);
                }
            });
            source.forEach(function (item) {
                if (!dest.hasEquals(item)) {
                    changed = true;
                    remove(item);
                }
            });
        }
    }
    return changed;
}
// need to check
export function chain() {
    var promises = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        promises[_i] = arguments[_i];
    }
    return new Promise(function (confirm, reject) {
        if (promises && promises.length > 0) {
            promises.shift()
                .then(function () {
                chain.apply(void 0, promises).then(function (r) { return confirm(r); })
                    .catch(function (error) { return reject(error); });
            })
                .catch(function (error) { return reject(error); });
        }
        else {
            confirm();
        }
    });
}
/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
/**
 * Deep merge two objects.
 * @param target Target Object
 * @param sources Source Object Array
 */
export function mergeDeep(target) {
    var sources = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        sources[_i - 1] = arguments[_i];
    }
    var _a, _b;
    if (!sources.length) {
        return target;
    }
    var source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (var key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, (_a = {}, _a[key] = {}, _a));
                }
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, (_b = {}, _b[key] = source[key], _b));
            }
        }
    }
    return mergeDeep.apply(void 0, [target].concat(sources));
}
export function generateUid() {
    return (new Date).getTime() + Math.random();
}
export function messageBroadcast(data) {
    data.uid = generateUid();
    localStorage.setItem('message', JSON.stringify(data));
    localStorage.removeItem('message');
}
export function messageListen(handler) {
    window.addEventListener('storage', function (ev) {
        if (ev.key === 'message' && ev.newValue) {
            handler(JSON.parse(ev.newValue));
        }
    });
}
