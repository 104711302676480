var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from "./base/base-entity.service";
import { ScheduledReport } from "../modules/shared/models/reporting/scheduled-report";
import { RestService } from "./rest.service";
import { HttpUtilsService } from "./http-utils.service";
import { plainToClass } from "class-transformer";
import { map } from "rxjs/operators";
var ReportService = /** @class */ (function (_super) {
    __extends(ReportService, _super);
    function ReportService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/report/';
        _this.classType = ScheduledReport;
        return _this;
    }
    ReportService.prototype.getAvailableReports = function () {
        return this.httpRestService.getWithHeader(this.apiUrl + '/getAvailableReports', this.httpUtils.getHTTPHeaders());
    };
    ReportService.prototype.run = function (id, mode) {
        var params = this.httpUtils.getHTTPParams({ user: mode === 'EMAIL_USER', recipients: mode === 'EMAIL_RECIPIENTS' });
        return this.httpRestService.getWithOptions(this.apiUrl + '/run/' + id, this.httpUtils.getHTTPHeaders(), params);
    };
    ReportService.prototype.progress = function (guid) {
        return this.httpRestService.getWithHeader(this.apiUrl + '/progress/' + guid, this.httpUtils.getHTTPHeaders());
    };
    ReportService.prototype.setActive = function (report, value) {
        var _this = this;
        return this.httpRestService.put(this.apiUrl + ("/" + report.id + "/set-active/" + value), '', this.httpUtils.getHTTPHeaders())
            .pipe(map(function (result) { return _this.afterLoad(plainToClass(ScheduledReport, result)); }));
    };
    ReportService.prototype.getAvailableColumns = function (reportTypeId) {
        return this.httpRestService.getWithHeader(this.apiUrl + '/type/' + reportTypeId + "/columns", this.httpUtils.getHTTPHeaders());
    };
    return ReportService;
}(BaseEntityService));
export { ReportService };
