<perfect-scrollbar>
  <div #toolbar class="oms-modal widget">

    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <ul class="nav nav-tabs">
        <li (click)="changeTab('signature-info')" [ngClass]="{'active': activeTab == 'signature-info'}"><a href="#signature-info" data-toggle="tab"><i></i>Info</a></li>
        <li (click)="changeTab('signature-comments')" [ngClass]="{'active': activeTab == 'signature-comments'}"><a href="#signature-comments" data-toggle="tab"><i></i>Comments</a></li>
        <li (click)="changeTab('signature-email')" [ngClass]="{'active': activeTab == 'signature-email'}"><a href="#signature-email" data-toggle="tab"><i></i>Email</a></li>
        <li (click)="changeTab('signature-name')" [ngClass]="{'active': activeTab == 'signature-name'}"><a href="#signature-name" data-toggle="tab"><i></i>Name</a></li>
        <li (click)="changeTab('signature')" [ngClass]="{'active': activeTab == 'signature'}"><a href="#signature" data-toggle="tab"><i></i>Signature</a></li>
      </ul>

      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <div class="tab-content no-padding">
      <div class="tab-pane fade in active" id="signature-name">

        <div class="widget-content table-responsive" *ngIf="activeTab == 'signature-name'">
          <div class="form-row" style="overflow-y: auto; min-height: 60vh;">
            <div style="width: 100%;">
              <div class="col col-sm-12">
                <b>Name</b>
                <input type="text" (focus)="$event.target.select();" style="margin-bottom: 10px; height: 100px; font-size: 30px;" (ngModelChange)="onChange($event, 'name')"
                       class="form-control" title="" [(ngModel)]="name"
                       [placeholder]="'Name'" >
              </div>
            </div>
          </div>
        </div>

        <div class="widget-content table-responsive" *ngIf="activeTab == 'signature-comments'">
          <div class="form-row" style="overflow-y: auto; min-height: 60vh;">
            <div style="width: 100%;">
              <div class="col col-sm-12">
                <b>Comment</b>
                <textarea style="height: 200px; font-size: 30px;" class="form-control" (focus)="$event.target.select();" [(ngModel)]="comment" (ngModelChange)="onChange($event, 'comment')" [placeholder]="'Comment'"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="widget-content table-responsive" *ngIf="activeTab == 'signature-email'">
          <div class="form-row" style="overflow-y: auto; min-height: 60vh;">
            <div style="width: 100%;">
              <div class="col col-sm-12">
                <b>Email</b>
                <input type="text" (focus)="$event.target.select();" style="margin-bottom: 10px; height: 100px; font-size: 30px;" (ngModelChange)="onChange($event, 'email')"
                       class="form-control" title="" [(ngModel)]="email"
                       [placeholder]="'Email'" >
              </div>
            </div>
          </div>
        </div>

        <div class="widget-content table-responsive" *ngIf="activeTab == 'signature-info'">
          <div class="form-row" style="overflow-y: auto; min-height: 60vh;">
            <div class="form-row col-md-12 link-color">
              <tr>
                <td colspan="12" class="row">
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">DATE: {{convertDate(orderDispatch.dateDispatchedFor) || 'N/A'}}</span>
                  </div>
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">Manifest #{{orderDispatch.dispatchId}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="hr-line" colspan="12"></td>
              </tr>
              <tr>
                <td colspan="12" class="row">
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">From: {{orderDispatch.addressPickup?.name || 'N/A'}}</span>
                  </div>
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">{{getAddressInfo(orderDispatch.addressPickup)}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="hr-line" colspan="12"></td>
              </tr>
              <tr>
                <td colspan="12" class="row">
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">To: {{orderDispatch.addressDelivery?.name || 'N/A'}}</span>
                  </div>
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">{{getAddressInfo(orderDispatch.addressDelivery)}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="hr-line" colspan="12"></td>
              </tr>
              <tr>
                <td colspan="12" class="row">
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">PCS: {{orderDispatch.pieces || 'N/A'}}</span>
                  </div>
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">Weight: {{orderDispatch.weight || 'N/A'}} KG</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="hr-line" colspan="12"></td>
              </tr>
              <tr>
                <td colspan="12" class="row">
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">MAWB: {{orderDispatch.mawb || 'N/A'}}</span>
                  </div>
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">REF: {{orderDispatch.customerRef || 'N/A'}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="hr-line" colspan="12"></td>
              </tr>
              <tr>
                <td colspan="12" class="row">
                  <div class="col col-xs-12 text-left">
                    <span class="address-ref">HAWB: {{orderDispatch.hawb || 'N/A'}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="hr-line" colspan="12"></td>
              </tr>
              <tr>
                <td colspan="12" class="row">
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">FW: {{orderDispatch.freightForwarder?.name || 'N/A'}}</span>
                  </div>
                  <div class="col col-xs-6 text-left">
                    <span class="address-ref">CUST: {{orderDispatch.customer?.name || 'N/A'}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="hr-line" colspan="12"></td>
              </tr>
            </div>
          </div>
        </div>

        <div class="widget-content table-responsive" [ngClass]="{'hidden': activeTab != 'signature'}">
          <div class="form-row" style="overflow-y: auto; min-height: 60vh;">
            <div class="form-row col-md-12"  style="background-color: #8BA6AF; max-width: 700px">
                <signature-pad [options]="signaturePadOptions" (window:resize)="resizeSignaturePad()" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="widget-footer">
      <div class="dialog-btns pull-right">
        <button class="btn btn-lg btn-primary" (click)="changeTabByButton(activeTab, false);" *ngIf="activeTab != 'signature-info'">
          <i class="fa fa-arrow-circle-o-left oms-filter-items__arrows"></i>&emsp;
          <span>Previous</span>
        </button>
        <button class="btn btn-lg btn-primary" (click)="changeTabByButton(activeTab, true);" *ngIf="activeTab != 'signature'">
          <i class="fa fa-arrow-circle-o-right oms-filter-items__arrows"></i>&emsp;
          <span>Next</span>
        </button>
        <button class="btn btn-lg btn-primary" (click)="clear();" *ngIf="activeTab == 'signature'">
          <i class="fa fa-trash"></i>&emsp;
          <span>Clear</span>
        </button>
        <button class="btn btn-lg btn-success" (click)="onSubmit();" [disabled]="!this.isSignatureComplete" *ngIf="activeTab == 'signature'">
          <i class="fa fa-send"></i>&emsp;
          <span>Send</span>
        </button>
      </div>
    </div>

  </div>
</perfect-scrollbar>

