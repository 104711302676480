<oms-base-dialog [readonly]="readonly" [title]="'Report'" [icon]="'fa fa-file-text-o'" (ok)="onOk($event)" >

  <div widget-header>
    <ul class="nav nav-tabs pull-right">
      <li class="active"><a href="#tabSchedule" data-toggle="tab" aria-expanded="false"><i class="fa fa-calendar-check-o"></i>Schedule</a></li>
      <li class=""><a href="#tabRecipients" data-toggle="tab" aria-expanded="true"><i class="fa fa-users"></i>Recipients</a></li>
      <li class="" *ngIf="hasFilters"><a href="#tabFilters" data-toggle="tab" aria-expanded="true"><i class="fa fa-gears"></i>Filters</a></li>
      <li class=""><a href="#tabOutput" data-toggle="tab" aria-expanded="true"><i class="fa fa-table"></i>Output</a></li>
    </ul>
  </div>


  <div style="min-width: 650px; min-height: 300px;">

    <div class="row">
      <div class="col-md-6" >
        <oms-custom-search required
                           [(ngModel)]="selectedReportType"
                           (ngModelChange)="onReportClassChange($event)"
                           [allowClear]="true"
                           [readonly]="!data.report.isNew()"
                           [label]="'Report Type'"
                           [items]="reportTypes">
        </oms-custom-search>
      </div>
      <div class="col-md-6">
        <oms-string-input label="Name" [(ngModel)]="data.report.name"></oms-string-input>
      </div>
    </div>

    <hr>

    <div class="row tab-content no-padding" >
      <div id="tabSchedule" class="col-md-12 tab-pane fade active in">
        <div class="row">
          <div class="col-md-6">
            <oms-date-input [display-format]="'DD MMM YYYY'" [time]="true" [show-checkbox]="true" label="Since" [(ngModel)]="report.schedule.dateFrom"></oms-date-input>
          </div>
          <div class="col-md-6">
            <oms-date-input [display-format]="'DD MMM YYYY'" [time]="true" [show-checkbox]="true" label="Until" [(ngModel)]="report.schedule.dateTo"></oms-date-input>
          </div>
        </div>

        <div class="row">

          <div class="col-md-7 vcenter" style="line-height: 1.5em;">
            <span class="form-inline">
              <label class="control-label control-inline fancy-radio custom-color-green">
                <input type="radio" name="inline-radio2"
                       [value]="ScheduleType.HOURLY" [(ngModel)]="data.report.schedule.scheduleType">
                <span><i></i>Hourly</span>
              </label>
              <label class="control-label control-inline fancy-radio custom-color-green">
                <input type="radio" name="inline-radio2"
                       [value]="ScheduleType.DAILY" [(ngModel)]="data.report.schedule.scheduleType">
                <span><i></i>Daily</span>
              </label>
              <label class="control-label control-inline fancy-radio custom-color-green">
                <input type="radio" name="inline-radio2"
                       [value]="ScheduleType.WEEKLY" [(ngModel)]="data.report.schedule.scheduleType">
                <span><i></i>Weekly</span>
              </label>
              <label class="control-label control-inline fancy-radio custom-color-green">
                <input type="radio" name="inline-radio2"
                       [value]="ScheduleType.MONTHLY" [(ngModel)]="data.report.schedule.scheduleType">
                <span><i></i>Monthly</span>
              </label>
            </span>
          </div>
          <div class="col-md-5 control-group vcenter">
            <div class="form-inline">
              <div class="form-group" *ngIf="report.schedule.isHourly">
                <label for="hourPeriod" class="control-label">Every</label>
                <input id="hourPeriod" type="number" min="1" max="24" size="3" maxlength="2" width="3" class="form-control" [(ngModel)]="report.schedule.hourPeriod">
                <label for="hourPeriod" class="control-label"> Hour(s)</label>
              </div>

              <div class="form-group" *ngIf="report.schedule.isDaily">
                <label for="dayNumber" class="control-label">Every</label>
                <input id="dayNumber" type="number" min="1" max="100" size="3" class="form-control" [(ngModel)]="report.schedule.dayNumber">
                <label for="dayNumber" class="control-label"> Day(s)</label>
              </div>

              <div class="form-group" *ngIf="report.schedule.isWeekly">
                <label for="weekNumber" class="control-label">Every</label>
                <input id="weekNumber" type="number" min="1" max="100" size="3" class="form-control" [(ngModel)]="report.schedule.weekNumber">
                <label for="weekNumber" class="control-label"> Week(s)</label>
              </div>

              <div class="form-group" *ngIf="report.schedule.isMonthly">
                <label for="monthNumber" class="control-label">Every</label>
                <input id="monthNumber" type="number" min="1"  max="100" size="3"  class="form-control" [(ngModel)]="report.schedule.monthNumber">
                <label for="monthNumber" class="control-label"> Month(s)</label>
              </div>


            </div>
          </div>

        </div>

        <div class="row" *ngIf="!report.schedule.isHourly">
          <div class="col-xs-4 time-select">
            <oms-custom-search style="width: 100px;" [showLabel]="false" [items]="dayHoursItems" bindValue="id" bindLabel="name" [allowClear]="false"
                               [searchFn]="dayHoursSearch" [(ngModel)]="report.schedule.hour">
              <span group-before>At</span>
            </oms-custom-search>

            <div class="form-group" style="padding-left: 10px">
              <input class="form-control minute-input" id="minNumber" placeholder="00" type="number" min="0" max="59" size="3" cons
                     [(ngModel)]="report.schedule.minute" (ngModelChange)="validateMinutes()">
            </div>
          </div>

          <!--div class="col-lg-2 col-sm-2">
            <div class="form-group">
              <input id="minNumber" type="number" min="0" max="59" size="3" class="form-control" [(ngModel)]="report.schedule.minute">
            </div>
          </div-->

          <div class="col-xs-8">
            <oms-custom-search [searchFn]="tzSearch" [(ngModel)]="data.report.schedule.timeZone" [items]="tzService.timeZones" [bindLabel]="'name'" [bindValue]="'name'" [searchable]="true" [allowClear]="false">

              <span group-before>On</span>

              <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="search">
                <div [ngOptionHighlight]="search">{{item.name}} <small class="pull-right" style="font-size: 0.8em; color: #008000">{{item.desc}}</small></div>
              </ng-template>

              <ng-template #labelTemplate ng-label-tmp let-item="item" let-index="index" let-search="search">
                <div [ngOptionHighlight]="search">{{item.name}} <small class="pull-right" style="font-size: 0.8em; color: #008000">{{item.desc}}</small></div>
              </ng-template>
            </oms-custom-search>
          </div>
        </div>

        <div class="row" *ngIf="report.schedule.isWeekly">
          <div class="col-md-2 text-right">On Day:</div>
          <div class="col-md-10">
            <oms-checkbox [(checked)]="report.schedule.weekDays[0]" label="Sun"></oms-checkbox>
            <oms-checkbox [(checked)]="report.schedule.weekDays[1]" label="Mon"></oms-checkbox>
            <oms-checkbox [(checked)]="report.schedule.weekDays[2]" label="Tue"></oms-checkbox>
            <oms-checkbox [(checked)]="report.schedule.weekDays[3]" label="Wed"></oms-checkbox>
            <oms-checkbox [(checked)]="report.schedule.weekDays[4]" label="Thu"></oms-checkbox>
            <oms-checkbox [(checked)]="report.schedule.weekDays[5]" label="Fri"></oms-checkbox>
            <oms-checkbox [(checked)]="report.schedule.weekDays[6]" label="Sat"></oms-checkbox>
          </div>
        </div>

        <div class="row form-inline" *ngIf="report.schedule.isMonthly">
          <div class="col-md-4">
            <label class="control-label control-inline fancy-radio custom-color-green">
              <input type="radio" name="monthly-type-0" [value]="0" [(ngModel)]="data.report.schedule.monthlyType">
              <span class="control-group">
                <i></i>
                  <input id="dayOfMonth" [disabled]="data.report.schedule.monthlyType !== 0"
                         type="number" min="1" max="31" size="3" maxlength="2" width="3" class="form-control" [(ngModel)]="report.schedule.dayOfMonthNumber">
                  <label>Day</label>
              </span>
            </label>
          </div>

          <div class="col-md-8">
            <label class="control-label control-inline fancy-radio custom-color-green"  style="width: 100%;">
              <input type="radio" name="monthly-type-1" [value]="1" [(ngModel)]="data.report.schedule.monthlyType">
              <span>
                <i></i>
                <oms-custom-search
                    [disabled]="data.report.schedule.monthlyType !== 1"
                    [allowClear]="false" [(ngModel)]="report.schedule.dayOfWeekInMonthNumber"
                    [bindValue]="'id'"
                    [bindLabel]="'label'"
                    [items]="[{id:0, label:'First'}, {id:1, label:'Second'}, {id:2, label:'Third'}, {id:3, label:'Fourth'}, {id:4, label:'Last'}]">
                </oms-custom-search>

                <oms-custom-search style="margin-left: 10px;"
                  [disabled]="data.report.schedule.monthlyType !== 1"
                  [allowClear]="false" [(ngModel)]="report.schedule.dayOfWeek"
                  [bindValue]="'id'"
                  [bindLabel]="'name'"
                  [items]="weekDaysItems">
                </oms-custom-search>

              </span>
            </label>
          </div>
        </div>
      </div>

      <div id="tabRecipients" class="col-md-12 tab-pane fade" >
        <oms-custom-search
          [label]="'Recipients'"
          [iconClass]="'fa fa-user'"
          [placeholder]="'Search to add...'"
          [service]="userService"
          [sort-by]="'firstName|lastName'"
          [(ngModel)]="userToAdd"
          (ngModelChange)="onUserAdd($event)"

          [bindLabel]="'fullNameWithEmail'">

          <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="search">
            <div>
              <p style="font-size: 1.2em; margin-bottom: 0" [ngOptionHighlight]="search">{{item.firstName}} {{item.lastName}}</p>
              <small style="color: #008000; margin-bottom: 5px" class="result-url" [ngOptionHighlight]="search">{{item.email}}</small>
              <!--sup>{{'('+item.email+')'}}          </sup-->
            </div>
          </ng-template>

        </oms-custom-search>
        <div class="table-responsive">
          <table class="table table-hover table-condensed table-bordered table-striped ">
            <thead>
            <tr>
              <th style="width: 200px;"><a>Recipient</a></th>
              <th style="width: auto;"><a>Email</a></th>
              <th style="width: 70px; text-align: center;"><a>Enabled</a></th>
              <th style="width: 20px;"></th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let rec of report.recipients; let i=index;">
              <td style="width: 200px;">{{rec.user?.fullName}}</td>
              <td style="width: auto;">{{rec.user?.email}}</td>
              <td style="width: 70px; vertical-align: middle; text-align: center;"><oms-checkbox [condensed]="true" [(checked)]="rec.enabled"></oms-checkbox></td>
              <td style="width: 20px; vertical-align: middle; text-align: center;"><a (click)="removeRecipient(rec);"><i class="fa fa-user-times"></i></a></td>
            </tr>
            </tbody>

          </table>
        </div>

        <label for="#emails">Custom Email Addresses (separated with coma or space)</label>
        <div class="input-group">
          <span class="input-group-addon">@</span>
          <textarea id="#emails" class="form-control" [(ngModel)]="report.customEmails"></textarea>
        </div>

      </div>

      <div id="tabFilters" class="col-md-12 tab-pane fade" *ngIf="hasFilters">
        <oms-orders-report-filters *ngIf="isOrdersReport" [filters]="report.filters"></oms-orders-report-filters>
        <oms-routes-report-filters *ngIf="isRoutesReport" [filters]="report.filters"></oms-routes-report-filters>
        <oms-masters-report-filters *ngIf="isMastersReport" [filters]="report.filters"></oms-masters-report-filters>
        <pending-recovery-report-filters *ngIf="isPendingRecoveryReport" [filters]="report.filters"></pending-recovery-report-filters>
      </div>

      <div id="tabOutput" class="col-md-12 tab-pane fade">
        <div class="oms-report-dialog__columns">
          <div class="oms-report-dialog__columns-item"
               *ngFor="let container of containers; let i = index">
            <div class="panel panel-warning" style="margin-bottom: 0; height: 100%"
                 dnd-sortable-container [sortableData]="container.columns" [dropZones]="['widget-dropZone']">
              <div class="panel-heading">
                {{container.name}}
              </div>
              <ul class="list-group" style="margin-bottom: 0px">
                <perfect-scrollbar style="max-height: 35vh;">
                  <li *ngFor="let reportColumn of container.columns; let x = index" class="list-group-item"
                      dnd-sortable [sortableIndex]="x" [dragEnabled]="true"
                      [dragData]="reportColumn">{{reportColumn?.label}}</li>
                </perfect-scrollbar>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div widget-footer>
    <oms-toggle-switch [(ngModel)]="report.active" [label]="'Active'"></oms-toggle-switch>
    <span style="margin-left: 20px;">
      <label>Table Output</label>&nbsp;
      <oms-select-button bindValue="id" [(selected)]="report.outputType" [items]="outputTypes"></oms-select-button>
    </span>
  </div>



</oms-base-dialog>
