import { BehaviorSubject } from "rxjs";
import { parseBoolean } from "../_helpers/string.util";
import * as i0 from "@angular/core";
var UserPreferencesService = /** @class */ (function () {
    function UserPreferencesService() {
        this.TIME_FORMAT_KEY = "TIME_INPUT_FORMAT_12";
        this.timeFormat12$ = new BehaviorSubject(true);
        this.setTimeFormat12(parseBoolean(localStorage.getItem(this.TIME_FORMAT_KEY) || 'true'));
    }
    UserPreferencesService.prototype.getTimeFormat12 = function () {
        return this.timeFormat12$.asObservable();
    };
    UserPreferencesService.prototype.getTimeFormatLabel = function () {
        return this.timeFormat12$.value ? '12H' : '24H';
    };
    UserPreferencesService.prototype.setTimeFormat12 = function (value) {
        if (value !== this.timeFormat12$.value) {
            this.timeFormat12$.next(value);
            localStorage.setItem(this.TIME_FORMAT_KEY, value.toString());
        }
    };
    UserPreferencesService.prototype.isTimeFormat12 = function () {
        return this.timeFormat12$.value;
    };
    UserPreferencesService.ngInjectableDef = i0.defineInjectable({ factory: function UserPreferencesService_Factory() { return new UserPreferencesService(); }, token: UserPreferencesService, providedIn: "root" });
    return UserPreferencesService;
}());
export { UserPreferencesService };
