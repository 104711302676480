var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { OrdersService } from '../../services';
import { OmsAlertsService } from "../../modules/shared/components/oms-alerts/oms-alerts.service";
import { FileUploadService } from "../../services/file.upload.service";
import { OmsDialogsService } from "../../components/common/oms-dialogs";
import { OmsConstants } from "../../common/oms-constants.service";
import { MatDialog } from "@angular/material/dialog";
import { AbstractEntityListComponent } from "../../modules/settings/pages/abstract-entity-list.component";
import { Master, MasterStatus, MasterStatusId } from "../../modules/shared/models";
import { FieldType, HyperLinkColumn } from "../../modules/shared/components/base/data-table/columns/column-types";
import { UserService } from "../../modules/shared/services/user.service";
import { ColumnType } from "../../common/column-type";
import { plainToClass } from "class-transformer";
import { convertMawbNumber } from "../../modules/shared/services/oms-converters.service";
import { ActivatedRoute } from "@angular/router";
import { FreightForwarderGroupService } from "../../services/freight.forwarder.group.service";
import { PublicApiService } from "../../services/public-api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { PopoverConfig } from "ngx-bootstrap";
import { OrderSubscriptionDialogComponent } from "../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import { forkJoin } from "rxjs";
import { map, tap } from "rxjs/operators";
var StatusInfoComponent = /** @class */ (function (_super) {
    __extends(StatusInfoComponent, _super);
    function StatusInfoComponent(popoverConfig, route, dialog, cdr, alerts, publicApi, ordersService, userService, dialogs, fileUploadService, ffgService, constants, spinner) {
        var _this = _super.call(this, cdr, alerts, ordersService, dialogs, fileUploadService, spinner) || this;
        _this.popoverConfig = popoverConfig;
        _this.route = route;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.publicApi = publicApi;
        _this.ordersService = ordersService;
        _this.userService = userService;
        _this.dialogs = dialogs;
        _this.fileUploadService = fileUploadService;
        _this.ffgService = ffgService;
        _this.constants = constants;
        _this.spinner = spinner;
        _this.orders = [];
        _this.searchHAWB = '';
        _this.searchRef3 = '';
        _this.searchMAWB = '';
        _this.found = null;
        _this.isEnterClick = false;
        _this.loading = true;
        _this.paging = { enabled: false, pageSize: 1000, pageSizes: [10, 20, 50, 100, 200, 500] };
        _this.docTypes = ['POD', 'JJS_ON_HAND', 'CHECKED_MANIFEST'];
        _this.convertMawbNumber = convertMawbNumber;
        //    this.popoverConfig.outsideClick = true;
        _this.popoverConfig.container = 'body';
        _this.popoverConfig.adaptivePosition = false;
        _this.popoverConfig.triggers = 'hover';
        return _this;
    }
    StatusInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columns = this.buildColumns();
        this.route.params.subscribe(function (params) {
            _this.ffg = params['id'];
            var id = atob(_this.ffg).split('#')[1];
            if (id) {
                _this.findOrdersById();
            }
        });
    };
    StatusInfoComponent.prototype.getSearchText = function (text) {
        switch (text) {
            case 'HAWB': return this.searchHAWB;
            case 'MAWB': return this.searchHAWB;
            case 'REF3': return this.searchRef3;
        }
    };
    StatusInfoComponent.prototype.searchChanged = function (text, isClear) {
        if (!this.isEnterClick) {
            this.refresh(text, isClear);
        }
    };
    StatusInfoComponent.prototype.prepareOrders = function (orders) {
        var _this = this;
        this.orders = [];
        var orders$ = [];
        orders.forEach(function (order) {
            order.documents = 0;
            var a = _this.fileUploadService.findAllByIdObs(order.id, 'ORDER', true, _this.docTypes, _this.ffg, true)
                .pipe(map(function (d) { return d.length; }));
            orders$.push(a.pipe(tap(function (i) {
                order.documents = i;
            })));
            orders$.push(_this.fileUploadService.getActiveMasterDocCountByOrderId(order.id, "CHECKED_MANIFEST")
                .pipe(tap(function (i) {
                order.manifestDocuments = i;
            })));
        });
        forkJoin(orders$).subscribe(function () {
            _this.orders = orders;
            _this.changed();
        });
    };
    StatusInfoComponent.prototype.refresh = function (text, isClear) {
        if (text === 'HAWB' && this.searchHAWB.trim().length > 4) {
            this.findOrdersByHawb();
        }
        else if (text === 'REF3' && this.searchRef3.trim().length > 4) {
            this.findOrdersByRef3();
        }
        else if (text === 'MAWB' && this.searchMAWB.trim().length === 11) {
            this.findMastersByMawb();
        }
        else if (!isClear) {
            if (text === 'HAWB' || text === 'REF3') {
                this.dialogs.openInfoDialog('Value must be at least 5 characters').then();
            }
            else {
                this.dialogs.openInfoDialog('Value must be at least 11 digits').then();
            }
            this.orders = null;
            this.changed();
        }
    };
    StatusInfoComponent.prototype.findOrdersById = function () {
        var _this = this;
        this.orders = [];
        this.publicApi.orderInfoById(this.ffg).then(function (orders) {
            if (orders && orders.length > 0) {
                _this.prepareOrders(orders);
            }
            else {
                _this.dialogs.openInfoDialog('Order not found').then();
            }
        }).catch(function () {
            _this.dialogs.openInfoDialog('Order not found').then();
        });
        this.resetSearch();
        this.changed();
    };
    StatusInfoComponent.prototype.findOrdersByRef3 = function () {
        var _this = this;
        this.orders = [];
        var ref3 = this.searchRef3.trim();
        this.publicApi.orderInfoByRef3(ref3, this.ffg).then(function (orders) {
            if (orders && orders.length > 0) {
                _this.prepareOrders(orders);
            }
            else {
                _this.dialogs.openInfoDialog('Order with REF3 ' + ref3 + ' not found').then();
            }
        }).catch(function () {
            _this.dialogs.openInfoDialog('Order with REF3  ' + ref3 + ' not found').then();
        });
        this.resetSearch();
        this.changed();
    };
    StatusInfoComponent.prototype.findOrdersByHawb = function () {
        var _this = this;
        this.orders = [];
        this.spinner.show().then();
        var hAWB = this.searchHAWB.trim();
        this.publicApi.orderInfoByHawb(hAWB, this.ffg)
            .then(function (orders) {
            _this.spinner.hide().then();
            if (orders && orders.length > 0) {
                _this.prepareOrders(orders);
            }
            else {
                _this.dialogs.openInfoDialog('Order with REF/HAWB/PO ' + hAWB + ' not found').then();
            }
        })
            .catch(function () {
            _this.spinner.hide().then();
            _this.dialogs.openInfoDialog('Order with REF/HAWB/PO  ' + hAWB + ' not found').then();
        });
        this.resetSearch();
        this.changed();
    };
    StatusInfoComponent.prototype.findMastersByMawb = function () {
        var _this = this;
        var mAWB = this.searchMAWB.trim();
        this.spinner.show().then();
        this.publicApi.searchMastersByMawb(mAWB, this.ffg).toPromise()
            .then(function (masters) {
            _this.spinner.hide().then();
            if (masters.numberOfElements > 1) {
                _this.selectMaster(plainToClass(Master, masters.content));
            }
            else if (masters.numberOfElements === 1) {
                _this.findOrdersByMaster(masters.content[0]);
            }
            else {
                _this.orders = null;
                _this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
                _this.resetSearch();
            }
        })
            .catch(function () {
            _this.spinner.hide().then();
            _this.orders = null;
            _this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
            _this.resetSearch();
        });
        this.changed();
    };
    StatusInfoComponent.prototype.findOrdersByMaster = function (master) {
        var _this = this;
        var mAWB = this.searchMAWB.trim();
        this.spinner.show().then();
        this.orders = [];
        this.publicApi.orderInfoByMaster(master.id, this.ffg)
            .then(function (orders) {
            _this.spinner.hide().then();
            if (orders && orders.length > 0) {
                _this.prepareOrders(orders);
                _this.changed();
            }
            else {
                _this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
            }
        })
            .catch(function () {
            _this.spinner.hide().then();
            _this.dialogs.openInfoDialog('Order with mawb number ' + mAWB + ' not found').then();
        });
        this.resetSearch();
    };
    /*
    setMaster(master: Master) {
      this.findOrdersByMaster(master);
      this.changed();
    }
     */
    StatusInfoComponent.prototype.getStatus = function () {
        return MasterStatus.items.filter(function (ms) { return ms.id === MasterStatusId.ONHAND_COMPLETE_PENDING_1C || ms.id === MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH; });
    };
    StatusInfoComponent.prototype.changed = function () {
        var _this = this;
        setTimeout(function () { return _this.cdr.markForCheck(); });
        setTimeout(function () { return _this.isEnterClick = false; });
    };
    StatusInfoComponent.prototype.selectMaster = function (masters) {
        this.found = masters.slice();
        this.changed();
    };
    StatusInfoComponent.prototype.resetSearch = function () {
        this.searchHAWB = '';
        this.searchMAWB = '';
        this.found = null;
        this.search_active = null;
    };
    StatusInfoComponent.prototype.onSearchKey = function (event, text) {
        if (event && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
            this.isEnterClick = true;
            this.refresh(text, false);
        }
    };
    StatusInfoComponent.prototype.subscribeEvents = function (order) {
        var dialogRef = this.dialog.open(OrderSubscriptionDialogComponent, { width: 'auto', data: { orders: [order], isPublic: true, needCustomEmail: true } });
        dialogRef.afterClosed().subscribe(function () { });
    };
    StatusInfoComponent.prototype.buildColumns = function () {
        var _this = this;
        var columns = [];
        var tooltip = "Pending recovery\n" +
            "Recovered, pending on hand\n" +
            "On hand, pending customs\n" +
            "On hand, ready for dispatch (released)\n" +
            "Out for Delivery\n" +
            "Delivered";
        columns = columns.concat([
            ColumnType.ORDER_MAWB(false)
                .setEditor(false).setAlign('start').setWidth('150px'),
            ColumnType.ORDER_HAWB(false)
                .setEditor(false).setAlign('start').setWidth('150px'),
            ColumnType.ORDER_PCS(this.constants.piecesMask, this.constants.piecesPlaceholder)
                .setEditor(false).setAlign('end').setWidth('60px'),
            ColumnType.EMPTY_COLUMN
                .setEditor(false).setSearch(false).setSortable(false).setAlign('end'),
            ColumnType.ORDER_WEIGHT_KG
                .setEditor(false).setSearch(false).setAlign('end').setWidth('80px'),
            ColumnType.EMPTY_COLUMN
                .setEditor(false).setSearch(false).setSortable(false).setAlign('end'),
            ColumnType.ORDER_CFS_LAST_FREE_DAY(this.getStatus())
                .setEditor(false).setSearch(false).setAlign('center').setWidth('150px'),
            ColumnType.LAST_UPDATED_DATE_TIME
                .setEditor(false).setSortable(false).setSearch(false).setWidth('150px').setAlign('start'),
            ColumnType.PUBLIC_ORDER_STATUS([], tooltip)
                .setEditor(false).setSortable(false).setSearch(false).setWidth('auto').setAlign('start'),
            new HyperLinkColumn('SUBSCRIBE', '', '', FieldType.TEXT, '120px', {
                converter: function () { return ''; },
                tooltip: function () { return 'Subscribe...'; },
                header: { noarrows: true, icon: 'fa fa-flag', tooltip: 'Subscribe...' },
                icon: function () { return 'fa fa-flag'; },
                onClick: function (row) { return _this.subscribeEvents(row); }
            }).setWidth('120px').setAlign('start'),
            /*      ColumnType.ORDER_LAST_DOC('jjsOnHandDocuments', this.fileUploadService, 'JJS On Hand', 'JJS_ON_HAND')
                    .setWidth('100px').setAlign('center'),
                  ColumnType.ORDER_LAST_DOC('podDocuments', this.fileUploadService, 'POD', 'POD')
                    .setWidth('100px').setAlign('center'),
                  ColumnType.MASTER_LAST_DOC('manifestDocuments', this.fileUploadService, "Manifest", 'CHECKED_MANIFEST')
                    .setWidth('100px').setAlign('center'), */
            ColumnType.ORDER_DOC_CENTER(true, this.ordersService, this.dialogs, function (config) {
                config.docTypes = _this.docTypes;
                config.readonly = true;
                config.ffg = _this.ffg;
                config.latestActive = true;
            })
        ]);
        return columns;
    };
    return StatusInfoComponent;
}(AbstractEntityListComponent));
export { StatusInfoComponent };
