import {Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {TabConfig} from "./oms-tabs.component";

@Directive({
  selector: '[oms-tab]'
})
export class OmsTabDirective implements TabConfig, OnChanges {
  private static current_id = 0;

  @Input() public name: string;
  @Input() public visible: boolean = true;
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public active: boolean = false;
  @Input('icon-class') public iconClass: string;
  @Output() public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('tab-class') public tabClass: string;
  @Input('tab-id') tabId = 'oms-tab-' + OmsTabDirective.current_id++;

  constructor(
    public template: TemplateRef<any>,
    public elementRef: ElementRef) {
  }

  public setActive(value: boolean) {
    if (this.active === undefined || this.active !== value) {
      this.active = value;
      this.activeChange.emit(value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      this.visibleChange.emit(this.visible);
    }
  }
}
