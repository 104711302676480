import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrderDocuments} from "../../../../modules/shared/models/documents/order.documents";
import {FileUploadService} from "../../../../services/file.upload.service";
import {BehaviorSubject, Subject} from "rxjs";
import {OmsAlertsService} from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {DocCenterDialogComponent} from "../../doc-center/doc-center-dialog";

@Component({
  selector: 'oms-document-preview-dialog',
  templateUrl: './document-preview-dialog.component.html',
  styleUrls: ['./document-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentPreviewDialogComponent implements OnInit {
  public current: OrderDocuments;
  public loading: boolean = false;
  public objectType: 'ORDER' | 'MASTER';
  readonly documents: OrderDocuments[] = [];
  readonly source: Subject<Uint8Array> = new BehaviorSubject<Uint8Array>(undefined);

  constructor(public dialogRef: MatDialogRef<DocCenterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cdr: ChangeDetectorRef,
              private alerts: OmsAlertsService,
              public fileUploadService: FileUploadService) {

    this.objectType = data && data.objectType;
    this.current = data && data.document;
    this.documents = data && data.documents ?
          data.documents.filter((od: OrderDocuments) => od.uploadedFiles.name.endsWith('.pdf')) :
          [this.current];
  }

  private getData() {
    if (this.current) {
      this.loading = true;
      this.fileUploadService.stream(this.current.uploadedFiles)
        .subscribe(
        (result: Blob) => {
          new Response(result).arrayBuffer()
            .then((buffer) => {
              this.loading = false;
              this.source.next(new Uint8Array(buffer));
              this.cdr.markForCheck();
            }).catch((error) => {
              this.loading = false;
              this.cdr.markForCheck();
              this.alerts.error(error, 'Error Loading Document');
            });
        }, (error) => {
          this.loading = false;
          this.cdr.markForCheck();
          this.alerts.error(error, 'Error Loading Document');
        });
    }
  }

  ngOnInit() {
    this.getData();
  }

  public download() {
    if (this.current) {
      this.fileUploadService.download(this.current.id, this.objectType);
    }
  }

  public next() {
    if (this.current && this.multipleDocuments) {
      this.current = this.documents.next(this.current, true);
      this.getData();
    }
  }

  public previous() {
    if (this.current && this.multipleDocuments) {
      this.current = this.documents.next(this.current, true);
      this.getData();
    }
  }

  public get multipleDocuments(): boolean {
    return this.documents && this.documents.length > 0;
  }


}
