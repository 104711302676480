﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget" [ngClass]="{ '_loading' : viewLoading, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">

    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-truck"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <form class="widget-content" [formGroup]="form" style="min-height: 450px;">
        <div class="form-row">
          <div class="form-row col-md-12">

            <div class="col-md-4">
              <span class="import-air__content-input-header">Date PickUp Estimation</span>
              <input [owlDateTimeTrigger]="datePickUpExpect" [owlDateTime]="datePickUpExpect"  class="oms_text-input" formControlName="datePickUpExpectation" >
              <owl-date-time #datePickUpExpect></owl-date-time>
              <div *ngIf="isControlInvalid('datePickUpExpectation')" >Date PickUp Expectation is required</div>
            </div>

            <div class="col-md-4">
              <span class="import-air__content-input-header">Date PickUp Actual</span>
              <input [owlDateTimeTrigger]="datePickUpAct" [owlDateTime]="datePickUpAct"  class="oms_text-input" formControlName="datePickUpActual" >
              <owl-date-time #datePickUpAct></owl-date-time>
            </div>

            <div class="col-md-4">
              <oms-string-input [label]="'Address Pick Up'" [iconClass]="'fa fa-map-marker'" [disabled]="true" [ngModelOptions]="{standalone: true}" [ngModel]="addressPickUp.name"></oms-string-input>
            </div>
          </div>

          <div class="form-row col-md-12">

            <div class="col-md-4">
              <span class="import-air__content-input-header">Date Delivery Estimation</span>
              <input [owlDateTimeTrigger]="dateDeliveryExpect" [owlDateTime]="dateDeliveryExpect"  class="oms_text-input" formControlName="dateDeliveryExpectation" >
              <owl-date-time #dateDeliveryExpect></owl-date-time>
            </div>

            <div class="col-md-4">
              <span class="import-air__content-input-header">Date Delivery Actual</span>
              <input [owlDateTimeTrigger]="dateDeliveryAct" [owlDateTime]="dateDeliveryAct"  class="oms_text-input" formControlName="dateDeliveryActual" >
              <owl-date-time #dateDeliveryAct></owl-date-time>
            </div>

            <div class="col-md-4">
              <oms-address-search
                [(address)]="addressDelivery"
                [label]="'Address Delivery'"
                [placeholder]="'select from List or create...'">
              </oms-address-search>
            </div>
          </div>

          <div formArrayName="shipmentLoads">
            <div *ngFor="let item of form.get('shipmentLoads')['controls']; let i = index;">
              <div class="col-md-12" [formGroupName]="i">
                  <div class="col-md-1">
                    <label>Order ID</label>
                    <input placeholder="Order ID" class="oms_text-input" [attr.disabled]="true" formControlName="id">
                  </div>

                <div class="col-md-1">
                  <label>Client</label>
                  <input placeholder="Client" class="oms_text-input" [attr.disabled]="true" formControlName="client">
                </div>

                  <!--div class="col-md-3">
                    <oms-max-count-input
                      [label]="'Pieces'"
                      [placeholder]="'Pieces'"
                      [required]="true" [iconClass]="'fa fa-check-circle-o'"
                      [inputMask]="_constants.piecesMask"
                      [typeOfParcel]="'pallet'"
                      [maxCount]="item.value.availablePieces"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="item.value.pieces">
                    </oms-max-count-input>
                  </div-->

                  <div class="col-md-3">
                    <oms-string-input
                      required
                      label="Quantity"
                      placeholder="00"
                      [minCount]="1" [maxCount]="item.value.availablePieces"
                      (changing)="calculateTotal()"
                      [disabled]="!recoveryLoad"
                      [clean-mask]="true"
                      [(ngModel)]="item.value.pieces" [ngModelOptions]="{standalone: true}"
                      [input-mask]="_constants.piecesMask">

                      <ng-container input-before>
                        <span class="input-group-addon" style="color: green; padding: 6px 8px; background-color: #eee;" >
                          <i class="glyphicon glyphicon-wa fa-"></i>
                          {{item.value.availablePieces}}
                        </span>
                      </ng-container>

                      <ng-container input-after>
                        <!--todo make units Selector Component-->
                        <span class="input-group-btn">
                          <button type="button" class="btn btn-info dropdown-toggle"
                                  data-toggle="dropdown" aria-haspopup="true"
                                  aria-expanded="false">PCS&nbsp;<span class="caret"></span></button>
                          <ul class="dropdown-menu dropdown-menu-right">
                            <li><a href="#">PCS</a></li>
                            <li><a href="#">PLTS</a></li>
                            <li><a href="#">CTNS</a></li>
                            <li><a href="#">Something else here</a></li>
                          </ul>
                        </span>
                      </ng-container>

                    </oms-string-input>
                  </div>

                  <div class="col-md-3">
                    <oms-weight-input
                      [disabled]="!recoveryLoad"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="item.value.weight" (ngModelChange)="calculateTotal()">
                      <span class="input-group-addon">
                        <i class="fa fa-dashboard"></i>
                      </span>
                    </oms-weight-input>

                    <!--oms-max-count-input [label]="'Weight'"
                                         (changing)="calculateTotal()"
                                         [disabled]="!recoveryLoad"
                                         [placeholder]="'0.00'" [iconClass]="'fa fa-check-circle-o'" [inputMask]="_constants.piecesMask" [typeOfParcel]="'kg'" [maxCount]="item.value.weight" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.value.weight"></oms-max-count-input-->
                  </div>

                  <div class="col-md-3">
<!--                    [inputMask]="_constants.piecesMask"-->
                    <oms-max-count-input [label]="'Volume'" (changing)="calculateTotal()" [disabled]="!recoveryLoad" [placeholder]="'Volume'" [iconClass]="'fa fa-check-circle-o'" [typeOfParcel]="'Count'" [maxCount]="item.value.volume" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.value.volume"></oms-max-count-input>
                  </div>
                <div class="col-md-1">
                  <label style="display: block">Delete</label>
                  <i class="fa fa-times dialog-header-button remove" (click)="deleteItem('shipmentLoads', i)"></i>
                </div>

              </div>
            </div>
            <div class="col-md-12" style="background-color: white; border: 1px solid;">
              <div class="col-md-2" style="text-align: right;">
                Total:
              </div>
              <div class="col-md-3">
                Quantity:{{total.quantity}}
              </div>
              <div class="col-md-3">
                Weight:{{total.weight}}
              </div>
              <div class="col-md-3">
                Volume:{{total.volume}}
              </div>
            </div>

          </div>


        </div>
    </form>

    <div class="widget-footer right">
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="viewLoading">OK</button>
      <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
    </div>

  </div>
</perfect-scrollbar>
