import {TransformationType} from "class-transformer";

export enum Status {
  UNDEFINED = 0,
  COMPLETED = 1,
  NOT_COMPLETED = 2
}


export function transformStatus(value: any, obj: any, transformationType: TransformationType): any {
  switch (transformationType) {
    case TransformationType.PLAIN_TO_CLASS: return value ? value.id : undefined;
    case TransformationType.CLASS_TO_PLAIN: return {id: value};
    default: return value;
  }
}
