var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { OmsConstants } from '../../../../../../common/oms-constants.service';
import { ValueAccessorBase } from '../../../base/value-accessor';
import { CurrencyUnits } from '../../../../../../common/oms-unit-types';
import { checkNumber } from '../../../../../../_helpers/utils';
import { isNullOrUndefined } from 'util';
import { Size } from '../../../../../../common/oms-types';
var CurrencyInputComponent = /** @class */ (function (_super) {
    __extends(CurrencyInputComponent, _super);
    function CurrencyInputComponent(cdr, _constants) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this._constants = _constants;
        _this.Size = Size;
        _this.label = 'Amount';
        _this.size = Size.DEFAULT;
        _this.readonly = false;
        _this.disabled = false;
        _this.forceChangeUnits = false;
        _this.units = _this._constants.currencyUnits.default;
        return _this;
    }
    CurrencyInputComponent.prototype.toDisplay = function (value) { return checkNumber(CurrencyUnits.fromDefault(value, this.units)); };
    CurrencyInputComponent.prototype.toDefault = function (value) { return checkNumber(CurrencyUnits.toDefault(value, this.units)); };
    Object.defineProperty(CurrencyInputComponent.prototype, "internal", {
        get: function () {
            if (isNullOrUndefined(this.inputValue)) {
                this.inputValue = this.toDisplay(_super.prototype.getValue.call(this));
            }
            return this.inputValue;
        },
        set: function (value) {
            if (this.inputValue !== value) {
                this.inputValue = value;
                _super.prototype.setValue.call(this, this.toDefault(value));
            }
        },
        enumerable: true,
        configurable: true
    });
    CurrencyInputComponent.prototype.onUnitsChanged = function (event) {
        if (this.forceChangeUnits /* this.disabled || this.readonly*/) {
            // Assume that user looks at value - convert input value to selected units
            this.inputValue = this.toDisplay(_super.prototype.getValue.call(this));
            this.cdr.detectChanges();
        }
        else {
            _super.prototype.setValue.call(this, this.toDefault(this.inputValue));
            this.cdr.detectChanges();
        }
    };
    CurrencyInputComponent.prototype.setValue = function (value) {
        this.inputValue = this.toDisplay(value);
        this.innerValue = value;
        this.cdr.detectChanges();
    };
    return CurrencyInputComponent;
}(ValueAccessorBase));
export { CurrencyInputComponent };
