import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import 'reflect-metadata';
import 'es6-shim';
import { TooltipConfig } from "ngx-bootstrap/tooltip";
import { tooltipConfig } from "./common/oms-constants.service";
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ1 = function () { return Object.assign(new TooltipConfig(), tooltipConfig); }, ɵ2 = {
    hasBackdrop: true,
    panelClass: 'm-mat-dialog-container__wrapper',
    height: 'auto',
    width: '900px'
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
