var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit } from '@angular/core';
import { Airport } from '../../../modules/shared/models';
import { MatDialog } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { OmsDialogsService } from '../oms-dialogs/oms-dialogs.service';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { Size } from '../../../common/oms-types';
import { BaseInputSelectComponent } from "../../../modules/shared/components/base/base-input/base-input-select.component";
import { NgModel } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { NgSelectComponent } from "@ng-select/ng-select";
import { AirportService } from "../../../services/air/airport.service";
import { FilterSearchColumn } from "../../../modules/shared/models/filter.search.column";
import { FilterPageRequest } from "../../../modules/shared/models/filter.page.request";
import { SearchSortRequest } from "../../../modules/shared/models/search.sort.request";
import { KeyCode } from "../../../modules/shared/components/common/input/ng-select-types/ng-select.types";
var AirportSearchComponent = /** @class */ (function (_super) {
    __extends(AirportSearchComponent, _super);
    function AirportSearchComponent(validators, asyncValidators, airportService, dialog, alerts, dialogs) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.airportService = airportService;
        _this.dialog = dialog;
        _this.alerts = alerts;
        _this.dialogs = dialogs;
        _this.identifier = 'airport-search_' + AirportSearchComponent.initial_id++;
        _this.Size = Size;
        _this.size = Size.DEFAULT;
        _this.airportChange = new EventEmitter();
        _this.subjectAirports = new BehaviorSubject([]);
        _this.searchSubjectText = new BehaviorSubject('');
        _this.iconClass = 'fa fa-fighter-jet';
        return _this;
    }
    AirportSearchComponent.prototype.change = function (airport) {
        this.isOpen = false;
        this.airportChange.emit(airport);
    };
    AirportSearchComponent.prototype.ngOnInit = function () {
        this.initSearch();
    };
    Object.defineProperty(AirportSearchComponent.prototype, "tooltip", {
        get: function () {
            return isNullOrUndefined(this.value) ? "" : this.value.fullName;
        },
        enumerable: true,
        configurable: true
    });
    AirportSearchComponent.prototype.onOpen = function () {
        /*  if (this.items && this.items.length && this.searchService)
            this.searchService.subjAddresses.next(this.items);
          else*/
        this.searchSubjectText.next('');
    };
    AirportSearchComponent.prototype.setValue = function (value) {
        _super.prototype.setValue.call(this, value);
    };
    AirportSearchComponent.prototype.search = function () {
        this.searchSubjectText.next(this.searchSubjectText.value);
    };
    AirportSearchComponent.prototype.onKeyUp = function (event) {
        if (event.key !== 'Tab') {
            var search = event.target.value.trim();
            if (search !== this._input) {
                this._input = search;
                this.searchSubjectText.next(search);
                // this.search();
            }
        }
    };
    AirportSearchComponent.prototype.onKeyDown = function (event) {
        if (event.keyCode === KeyCode.Tab) {
            if (this.ngSelect && this.ngSelect.itemsList && this.ngSelect.itemsList.markedItem && this.ngSelect.itemsList.markedItem.value) {
                this.setValue(this.ngSelect.itemsList.markedItem.value);
            }
            this.ngSelect.filterValue = null;
            this.isOpen = false;
        }
    };
    AirportSearchComponent.prototype.initSearch = function () {
        var _this = this;
        var results$ = this.searchSubjectText.pipe(debounceTime(300), switchMap(function (search) {
            return _this.searchServiceFunction(search);
        })).subscribe(function (items) { _this.subjectAirports.next(plainToClass(Airport, items)); });
    };
    AirportSearchComponent.prototype.searchServiceFunction = function (search) {
        var filterPageRequest = new FilterPageRequest(1, 15, '', new SearchSortRequest("iataCode", true), []);
        if (this.arrival && !this.departure) {
            filterPageRequest.filterByColumns.push(new FilterSearchColumn('arrival', '1'));
        }
        else if (!this.arrival && this.departure) {
            filterPageRequest.filterByColumns.push(new FilterSearchColumn('departure', '1'));
        }
        filterPageRequest.filterByColumns.push(new FilterSearchColumn('iataCode', search));
        return this.airportService.findByFilterPromise(filterPageRequest, true);
    };
    AirportSearchComponent.prototype.setDisabledState = function (isDisabled) {
    };
    AirportSearchComponent.initial_id = 0;
    return AirportSearchComponent;
}(BaseInputSelectComponent));
export { AirportSearchComponent };
