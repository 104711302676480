<div class="form-group">
  <label [attr.for]="identifier ? identifier : null">{{label}}</label>


  <div class="input-group">


    <input #input type="text" title="" class="form-control"
      [id]="identifier"
      [attr.disabled]="disabled ? '' : null"
      [placeholder]="this.placeholder"
      [(ngModel)]="value"
      (keyup)="onKeyUp($event)"
      [ngClass]="{'form-control-invalid':invalidPublic | async}">

      <span class="input-group-addon">
        <i *ngIf="iconClass" ngClass="{{iconClass}}"></i>
        {{maxCount}} {{typeOfParcel}}
      </span>

  </div>
  <validation [@flyInOut]="'in,out'" *ngIf="invalidPublic | async" [messages]="failures | async"></validation>
</div>
