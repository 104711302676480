import {OrdersListPageState} from "./orders-list-page.state";
import {Injectable} from "@angular/core";

@Injectable({  providedIn: 'root' })
export class StateService {
  saveOrdersState(state: OrdersListPageState) {
    this.saveObject('state.order-list', state);
  }

  getOrdersState(): OrdersListPageState {
    return this.getObject('state.order-list');
  }

  private saveObject(key: string, state: any): void {
    localStorage.setItem(key, JSON.stringify(state));
  }

  private getObject<T>(key: string): T {
    let obStr = localStorage.getItem(key);
    if (!obStr) {
      return null;
    }
    return JSON.parse(obStr);
  }
}
