import {OrderMode, OrderModes} from "../modules/shared/models/order/order-mode";
import {Manifest} from "../modules/shared/models/manifest/manifest";
import {Address} from "../modules/shared/models/address";
import {OrderDispatch} from "../modules/shared/models/dispatch/order-dispatch";
import {ManifestItem} from "../modules/shared/models/manifest/manifest-item";
import {Order} from "../modules/shared/models";
import {Injectable} from "@angular/core";
import {DispatchService} from "../modules/shared/services/dispatch/dispatch.service";
import {forkJoin, from, Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {assigned} from "./utils";

@Injectable()
export class DispatchUtils {

  constructor(private dispatchService: DispatchService) {
  }

  public static sameRef(ref1: string, ref2: string): boolean {
    return ref1 === ref2 || !ref1 && !ref2;
  }


  public static findManifestItemToConsolidate(orderMode: OrderMode, manifest: Manifest, addressPickup, addressDelivery, ref) {

    if (OrderModes.isFCL(orderMode)  || OrderModes.isRecovery(orderMode)) {
      return null;
    }

    return manifest.items.find((mi) =>
      Address.sameAddress(mi.addressDelivery, addressDelivery) &&
      Address.sameAddress(mi.addressPickUp, addressPickup) &&
      DispatchUtils.sameRef(mi.customerRef, ref)
    );
  }


  public manifestAddOrderDispatch(manifest: Manifest, order: OrderDispatch) {

    console.log('ORDER TO DISPATCH', order);

    // Exclude FCL orders to consolidate
    let mi: ManifestItem = DispatchUtils.findManifestItemToConsolidate(order.orderMode, manifest, order.addressPickup, order.addressDelivery, order.customerRef);

    if (mi) {
      let o: Order = Order.fromOrderDispatch(order);
      mi.orders.push(o);
      mi.update();
      this.dispatchService.getLoadDeliveryAddressForOrder(o.id, mi.loadType, o.genericMode)
        .then((a) => {
          o.info.legAddressDelivery = a;
          mi.update();
        });

    } else {
      mi = ManifestItem.createFromDispatchDto(order, order.addressDelivery);
      manifest.items.push(mi);
      mi.trailer = manifest.trailer;
      mi.update();
      mi.orders.forEach(o => {
        this.dispatchService.getLoadDeliveryAddressForOrder(o.id, mi.loadType, o.genericMode).then((a) => {
          o.info.legAddressDelivery = a;
          mi.addressDelivery = a;
          mi.update();
///
          let mi1: ManifestItem = DispatchUtils.findManifestItemToConsolidate(order.orderMode, manifest, order.addressPickup, o.info.legAddressDelivery, o.customerRef);
          if (mi1 && mi1 !== mi) {
            mi1.orders.push(o);
            manifest.items.removeAll(mi);
            mi1.update();
          }
///
        });
      });
      manifest.enumerate();
    }
  }

  public manifestAddOrder(manifest: Manifest, order: Order): Observable<any> {
    let mi: ManifestItem = DispatchUtils.findManifestItemToConsolidate(order.genericMode, manifest, order.addressPickup, order.addressDelivery, order.customerRef);
    if (mi) {
      mi.orders.push(order);
      mi.update();
      let promise = this.dispatchService.getLoadDeliveryAddressForOrder(order.id, mi.loadType, order.genericMode);
      return from(promise).pipe(tap(a => {
        order.info.legAddressDelivery = a;
        mi.update();
      }));

    } else {
      mi = ManifestItem.createFromOrder(order);
      mi.trailer = manifest.trailer;
      manifest.items.push(mi);
      mi.update();

      let requests = mi.orders.map(o => {
        return from(this.dispatchService.getLoadDeliveryAddressForOrder(o.id, mi.loadType, o.genericMode))
          .pipe(tap((a) => {
            o.info.legAddressDelivery = a;
            mi.update();
            let mi1: ManifestItem = DispatchUtils.findManifestItemToConsolidate(order.genericMode, manifest, order.addressPickup, o.info.legAddressDelivery, o.customerRef);
            if (mi1 && mi1 !== mi) {
              mi1.orders.push(o);
              manifest.items.removeAll(mi);
              mi1.update();
            }
          }));
      });

      manifest.enumerate();

      let lastDeliveryLoadReq = this.dispatchService.getLastDeliveryLoad(order.id).pipe(tap(load => {
        if (load && load.shipment) {
          mi.addressPickUp = load.shipment.addressDelivery;
        }
      }));
      return forkJoin([...requests, lastDeliveryLoadReq]);
    }
  }

  public manifestAddOrderDispatch_Promise(manifest: Manifest, order: OrderDispatch): Promise<Manifest> {
    return new Promise<Manifest>((resolve, reject) => {

      this.dispatchService.getLoadDeliveryAddressForOrder(order.orderId, order.loadType, order.orderMode)
        .then((address) => {
          let mi = DispatchUtils.findManifestItemToConsolidate(order.orderMode, manifest, order.addressPickup, order.addressDelivery, order.customerRef);
          if (mi) {
            let o: Order = Order.fromOrderDispatch(order);
            o.info.legAddressDelivery = address;
            mi.orders.push(o);
          } else {
            mi = ManifestItem.createFromDispatchDto(order, address);
            mi.orderNumber = manifest.items.length;
            mi.trailer = manifest.trailer;
            manifest.items.push(mi);
          }
          manifest.update();
          resolve(manifest);
        })
        .catch(error => reject(error));
    });
  }

  public manifestAddOrders(manifest: Manifest, orders: OrderDispatch[]): Promise<Manifest> {
    return new Promise<Manifest>((resolve, reject) => {
      this.manifestAddOrderDispatch_Promise(manifest, orders.pop())
        .then((m) => {
          if (orders.length) {
            this.manifestAddOrders(m, orders)
              .then((m1) => resolve(m1))
              .catch((error) => reject(error));
          } else {
            resolve(m);
          }
        })
        .catch(error => reject(error));
    });
  }

  public manifestDeleteOrder(manifest: Manifest, order: Order) {
    let mi: ManifestItem = manifest.items.find((i) => i.orders.includes(order));
    if (mi) {
      mi.orders.removeAll(order);

      if (mi.orders.length === 0) {
        manifest.items.removeAll(mi);
      } else {
        mi.update();
      }

    }
  }

  public consolidateSelectedItems(manifest: Manifest): Promise<boolean> {
    let items: ManifestItem[] = manifest.items
      .filter(mi => mi.hasSelected)
      .sort((mi1, mi2) => mi1.orders.length - mi2.orders.length);

    return new Promise<boolean>((resolve, reject) => {
      if (items && items.length > 1) {

        if (items.find((item) =>
          item.orders && assigned(item.orders.find((o) => OrderModes.isFCL(o.genericMode)))
        )) {
          reject('FCL Orders consolidation is not allowed');
          return;
        }

        if (items.find((item) =>
          item.orders && assigned(item.orders.find((o) => OrderModes.isRecovery(o.genericMode)))
        )) {
          reject('Recovery Orders consolidation is not allowed!');
          return;
        }

        items.sort((mi1, mi2) => mi2.orders.length - mi1.orders.length);
        let mi: ManifestItem = items.shift();
        items.forEach((item) => {
          item.orders.forEach((order, i, array) => {
            if (order.selected) {
              mi.orders.push(order);
              this.dispatchService.getLoadDeliveryAddressForOrder(order.id, mi.loadType, order.genericMode)
                .then((a) => {
                  order.info.legAddressDelivery = a;
                  mi.updateAddressDelivery();
                });
              array.splice(i, 1);
            }
          });

          // Consolidate Dispatch Comments
          mi.comments = '';

          if (item.orders.length === 0) {
            manifest.items.removeAll(item);
          }
        });

        resolve(true);

      } else {
        resolve(false);
      }
    });
  }

  public unconsolidateSelectedItems(manifest: Manifest): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      manifest.items.forEach((mi) => {
        if (mi.hasSelected && mi.orders.length > 1) {
          let orders = [...mi.orders];
          orders.forEach((o: Order) => {
            if (o.selected && mi.orders.length > 1) {
              mi.orders.removeAll(o);
              if (mi.orders.length === 1) {
                mi.comments = ManifestItem.composeDispatchComments(mi.orders[0]);
              }


              let item: ManifestItem = new ManifestItem();
              item.orders.push(o);
              item.pieces = o.pieces;
              item.hu = o.hu;
              item.weight = o.weight;
              item.loadType = mi.loadType;
              item.trailer = mi.trailer;
              item.addressPickUp = mi.addressPickUp;
              item.addressDelivery = mi.addressDelivery;
              item.customer = o.customer;
              item.customerRef = o.customerRef;
              item.freightForwarder = o.freightForwarder;
              item.uldCount = o.uldCount;
              item.comments = ManifestItem.composeDispatchComments(o);
              manifest.items.push(item);
            }
          });

        }
      });
      resolve(true);
    });

  }


}
