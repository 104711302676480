import {Pipe, PipeTransform} from '@angular/core';
import {BaseColumn} from './columns/column-types';
import {isNullOrUndefined} from 'util';
import {ensureDate, getPendingStatus, PendingStatus} from '../../../../../common/oms-date-time.utils';
import {Logger} from "../../../../../_helpers/logger";
import {Observable} from 'rxjs';

export const OBJECT_ROW_ID = '__ROW_ID__';
export const PARENT_REF = '__PARENT__';
export const SELECTED_FLAG = '__SELECTED__';
export const EXPANDED_FLAG = '__EXPANDED__';

export interface TreeModel<P = any, C = any> {
  children(parent: P): Observable<C[]>;
  isLeaf(parent: P): boolean;
  childrenSelectable?(parent: P): 'single' | 'multiple' | undefined;
  clear?(): void;
}

export interface FilterOptions {
  total: number;
  search: string;
  colorItem: any;
}

export interface PagingOptions {
  enabled: boolean;
  pageSize: number;
  currentPage?: number;
  pageSizes?: number[];
  total?: number;
}

export interface FieldUpdateEvent<T = any> {
/*  constructor(
    public cdr:ChangeDetectorRef,
    public column:BaseColumn,
    public row: any,
    public oldValue: any,
    public newValue: any,
    public field: string) {}; */

  row: T;
  column: BaseColumn;
  oldValue: any;
  newValue: any;
  field: string;

  apply: (value?) => void;
  cancel: () => void;
  error: (msg) => void;


/*  apply(value?) {if (!isUndefined(value)) {this.newValue = value;}  this.column.setValue(this.row, this.newValue, this.field); this.cdr.markForCheck()}
  cancel() {
    this.column.setValue(this.row, this.oldValue, this.field);
    this.cdr.markForCheck()
  }
  error(message){

  } */
}


@Pipe({name: "filter", pure: false})
@Logger({name: 'TableFilterPipe'})
export class TableFilterPipe implements PipeTransform {
  transform(array: Array<any>, options: FilterOptions, columns: BaseColumn[], tree?: TreeModel): Array<any> {
//    array = this.filterItems(array, options, columns, tree);
    if (array) {
      array.forEach((item) => {this.initChildrenWithParent(item, tree); });
    }
    options.total = array ? array.length : 0;
    return array;
  }

  initChildrenWithParent(value, tree: TreeModel) {
    if (tree) {
      let children = tree.children(value);
      if (children) {
        children.forEach((child) => {
          child[PARENT_REF] = value;
          this.initChildrenWithParent(child, tree);
        });
      }
    }
  }

/*
  private isSearchByColumns(columns: BaseColumn[]) {
    let isByColumnSearch = false;
    columns.forEach((column) => {
      isByColumnSearch = isByColumnSearch || !(isNullOrUndefined(column.search.search) || column.search.search.toString().trim().length === 0);
    });

    return isByColumnSearch;
  }
 */

  /*private filterItems(items: Array<any>, options: FilterOptions, columns: BaseColumn[], tree: TreeModel):Array<any> {
    return items ? items.filter(
      master => {
        let isByColumnSearch = this.isSearchByColumns(columns);

        if (!isByColumnSearch && (isNullOrUndefined(options.search) || options.search.trim().length == 0) && isNullOrUndefined(options.colorItem)) {
          return true;
        }


        let found = true;
        let colorFound = true;
        if (isByColumnSearch) {
          columns.forEach(column => {
            if (column.search.searchable && !isEmptyString(column.search.search)) {
              found = this.search(master, column.search.search, column, tree) && found;
            }
          });
        } else {
          found = isEmptyString(options.search);
          colorFound = isNullOrUndefined(options.colorItem);
          if (!colorFound) {
            let status = options.colorItem.status;
            colorFound = master.isMasterInPendingStatus(status);
          }
        }

        return found && colorFound;
      }) : items;
  }*/

  /*private search(object: any, search: string, column: BaseColumn, tree: TreeModel): boolean {
    if (tree && this.searchChildren(object, search, column, tree))
      return true;

    let value = column.getValue(object);
    return this[column.search.searchFunction](value, search);
  }

  private searchChildren(object: any, search:string, column: BaseColumn, tree: TreeModel):boolean {
    if (tree) {
      let children: any[] = tree.children(object);
      if (children && children.length > 0) {
        for (let child of children) {
          if (this.search(child, search, column, tree))
            return true;
        }
      }
    }
    return false;
  }*/

  findByNameIndexOf(item: any, searchInput: string) {
    return isNullOrUndefined(item) ? false : this.findByIndexOf(item.name, searchInput);
  }

  findByIndexOf(item: string, searchInput: string) {
    return !isNullOrUndefined(item) && item.indexOf && item.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
  }

  findByProblemStatus(item: any[], searchInput: string) {
    if (!isNullOrUndefined(item)) {
     if (searchInput === "1" && item.length === 0) {
      return true;
     } else if (searchInput === "0") {
       let notResolvedItems = item.filter(problem => {
         return !problem.isResolved;
       });

       return notResolvedItems.length > 0;
     } else if (searchInput === "2" && item.length > 0) {
       let notResolvedItems = item.filter(problem => {
         return !problem.isResolved;
       });

       return notResolvedItems.length === 0;
     }
    }
   return false;
  }

  findCustomerByNameIndexOf(item: any, searchInput: string) {
    return !isNullOrUndefined(item) && this.findByIndexOf(item.name, searchInput);
  }

  numberEquals(item: number, searchInput: number) {
    return !isNullOrUndefined(item) && item === searchInput;
  }

  itemNumberEquals(item: number, searchInput: number[]) {
    return !isNullOrUndefined(item) && searchInput.indexOf(item) !== -1;
  }

  isTheSameDay(item: Date, searchInput: Date) {
    if (!isNullOrUndefined(item)) {
//      console.log('item', item, 'search', searchInput);
      return ensureDate(item).toDateString() === searchInput.toDateString();
    }
    return false;
  }

  searchDayByColor(item: Date, pendingStatus: PendingStatus) {
    let itemStatus = getPendingStatus(item);
    return itemStatus === pendingStatus;
  }

  isSelectedValue(item: any, searchInput: string) {
    if (searchInput === '0') {
      return !isNullOrUndefined(item);
    } else if (searchInput === '1') {
      return isNullOrUndefined(item);
    }
    return true;
  }

  findByFirst3OrLast4Chars(item: string, searchInput: string) {
    if (!isNullOrUndefined(item) && item.length > 10) {
      let itemText = item.toLowerCase();
      return itemText.substring(0, 3).startsWith(searchInput) || itemText.substring(item.length - 4, item.length).startsWith(searchInput);
    }
    return false;
  }

}

@Pipe({name: "sort"})
@Logger({name: 'TableSortPipe'})
export class TableSortPipe implements PipeTransform {
  transform(array: Array<any>, column: BaseColumn, reverse?: boolean, count?: (number) => void, selectionOrder?: string): Array<any> {
//    selectionOrder = selectionOrder || reverse? 'asc' : 'desc';

//    console.log('sort by',column, 'selectionOrder:', selectionOrder, 'reverse:', reverse);

    if (array) {
      array.sort((a: any, b: any) => {

        if (selectionOrder && selectionOrder.length > 0) {
          let order = this.sortValues(!!(a && a[SELECTED_FLAG]), !!(b && b[SELECTED_FLAG]), selectionOrder === 'asc');
          if (order !== 0) {
            return order;
          }
        }

        if (!column) {
          return 0;
        }

//        console.log('compare', a, 'vs', b);
        let v1 = column ? column.getValue(a) : a; // this.getFieldValue(a, column.field, column);
        let v2 = column ? column.getValue(b) : b; // this.getFieldValue(b, column.field, column);

//        if (isNullOrUndefined(v1)) { return isNullOrUndefined(v2) ? -1 : 1; }
//        if (isNullOrUndefined(v2)) { return 1; }

        return column.sortField ?
          this.sortValues(v1[column.sortField], v2[column.sortField], reverse) :
          this.sortValues(v1, v2, reverse);
      });
    }

    if (count) {
      count(array ? array.length : 0);
    }

    return array;
  }

  sortValues(v1, v2, reverse: boolean) {
    if (isNullOrUndefined(v1)) {
      return isNullOrUndefined(v2) ? 0 : reverse ? 1 : -1;
    }

    if (isNullOrUndefined(v2)) {
      return reverse ? -1 : 1;
    }

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      return v1.localeCompare(v2, 'en', {'sensitivity': 'base'}) * (reverse ? -1 : 1);
    }

    if (v1 < v2) { return reverse ? 1 : -1; }
    if (v1 > v2) { return reverse ? -1 : 1; }

    return 0;
  }
}

@Pipe({name: "sort_children"})
export class TableSortPipeChildren extends TableSortPipe implements PipeTransform {

  transform(array: Array<any>, column: BaseColumn, reverse?: boolean, count?: (number) => void): Array<any> {
    /* todo Make controlled sorting of different type of Children inside Parent not mixing
    Currently sorting of child item is disabled.*/
    return array; // super.transform(array, column, reverse, count);
  }

}


@Pipe({name: "paging", pure: false})
export class TablePagingPipe implements PipeTransform {

  transform(array: Array<any>, options: PagingOptions): Array<any> {
    if (array) {
//      array.forEach((item)=>{this.initChildrenWithParent(item)});

      if (options.enabled) {
        if (isNullOrUndefined(options.currentPage)) {
          options.currentPage = 1;
        }

        let startIndex = (options.currentPage - 1) * options.pageSize;
        let endIndex = Math.min(startIndex + options.pageSize - 1, array.length - 1);
        array =  array.slice(startIndex, endIndex + 1);
      }
    }

    return array;
  }

}

