import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {OmsDialogsService} from '../components/common/oms-dialogs';
import {CustomerDeliveryLocation} from '../modules/shared/models/customer-delivery-location';
import {Customer} from "../modules/shared/models";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

@Injectable()
export class CustomerDeliveryLocationService extends BaseEntityService<CustomerDeliveryLocation> {
  apiUrl = '/api/customer-delivery-location/';
  classType = CustomerDeliveryLocation;

  public constructor(
    public dialogs: OmsDialogsService,
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  searchContactByAddress(addressId: number): Promise<Customer> {
    const params = new HttpParams()
      .set('addressId', addressId.toString());

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.httpRestService.getWithOptions<Customer>(this.apiUrl + 'searchContactByAddress', headers, params)
      .pipe(map(a => plainToClass(Customer, a)))
      .toPromise();
  }
}
