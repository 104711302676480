﻿<perfect-scrollbar style="max-height: 60vh;">
  <!--loadingAfterSubmit-->
  <div class="oms-modal widget" style="width: 500px"
       [ngClass]="{'m-portlet--body-progress-overlay' : false }">


    <div class="widget-header">
      <h3><i class="fa fa-comment"></i>
        Manifest Item Comments
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <form class="widget-content" [formGroup]="form" style="min-height: 250px;">
        <div class="form-row col-md-12">
          <div class="form-group">
            <label class="control-label">Comment</label>
            <textarea class="form-control" placeholder="Comment" formControlName="comments" rows="5"
                      [ngClass]="{'form-control-invalid':isControlInvalid('comments')}"></textarea>
            <div *ngIf="isControlInvalid('address')" class="invalid-feedback">Comments required</div>
          </div>
        </div>
    </form>

    <div class="widget-footer">
      <div></div>
      <div class="dialog-btns">
        <button type="button" class="btn btn-primary" (click)="onSubmit()">OK</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div>

  </div>
</perfect-scrollbar>
