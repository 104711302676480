import {AddressService} from './address.service';
import {Subject} from 'rxjs';
import {Customer} from '../models';
import {plainToClass} from 'class-transformer';
import {SearchService, Sort} from '../../../common/oms-types';
import {PageResult} from '../models/query-models/page-result';
import {Address, AddressType} from "../models/address";

// non-singleton / non-injectable
export class AddressSearchService implements SearchService<Address> {

  public subjAddresses: Subject<Address[]> = new Subject<Address[]>();
  public ignoreTypes: boolean = false;

  private addressService: AddressService;
  private readonly types: AddressType[];
  private readonly count: number;

  constructor(addressService: AddressService, types: AddressType[], count?: number) {
    this.addressService = addressService;
    this.types = types;
    this.count = count;
  }

  search(search: string, count?: number, ignoreTypes: boolean = false) {
    this.addressService.getByType(search, ignoreTypes ? null : this.types, count || this.count).then(addresses => {
      this.subjAddresses.next(plainToClass(Address, addresses));
    });
  }

  searchPromise(search: string, count?: number, ignoreTypes: boolean = false, addressTypes?: AddressType[]) {
//    console.log(4, search, 'IgnoreTypes', ignoreTypes, 'Types', addressTypes);
    return this.addressService.getByType(search, ignoreTypes ? null : addressTypes || this.types, count || this.count);
  }

  searchCustomerDeliveryLocations(search: string, customer: Customer, count?: number, ignoreTypes: boolean = false) {
    this.addressService.getCustomerDeliveryLocations(customer).then(addresses => {
      let result: Address[] = [];
      addresses.forEach(address => {
        address.top = true;
        result.push(address);
      });

//      console.log(1);
      this.addressService.getByType(search, ignoreTypes ? null : [AddressType.DELIVERY_LOCATION], count || this.count).then(found => {
        found.forEach((item) => result.push(plainToClass(Address, item)));
        this.subjAddresses.next(result);
        console.log(result);
      });
    });
  }

  searchCustomerDeliveryLocationsPromise(search: string, customer: Customer, count?: number, ignoreTypes: boolean = false) {
    return this.addressService.getCustomerDeliveryLocations(customer).then(addresses => {
      let result: Address[] = [];
      addresses.forEach(address => {
        address.top = true;
        result.push(address);
      });

      return this.addressService.getByType(search, ignoreTypes ? null : [AddressType.DELIVERY_LOCATION], count || this.count);
    });
  }

  findBy(by: any, count?: number, active?: boolean, sort?: Sort): Promise<PageResult<Address>> {
    return new Promise<PageResult<Address>>((resolve, reject) => {
      this.addressService.getByType(by, this.ignoreTypes ? null : this.types, count || this.count)
        .catch((error) => reject(error))
        .then((result) => {resolve(PageResult.fromArray<Address>(result as Address[])); });
    });
  }
}
