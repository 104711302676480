﻿import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ComCenterService} from "../../../services/com-center.service";
import {ObjectComCenter} from "../../../modules/shared/models/comcenter/object.com.center";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MeasureUnit, NoteType} from "../../../common/oms-unit-types";
import {isNullOrUndefined} from "util";
import {HttpEvent, HttpEventType, HttpResponse} from "@angular/common/http";
import {ComCenter} from "../../../modules/shared/models/comcenter/com.center";
import {FileUploadService} from "../../../services/file.upload.service";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";
import {convertMasterNumber, convertOrderNumber} from "../../../modules/shared/services/oms-converters.service";
import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {LoggedUser} from "../../../modules/shared/models/logged-user";
import {UserUtil} from "../../../modules/settings/util/user.util";
import {UserRoleType} from "../../../modules/shared/models/user-role.type";


@Component({
  templateUrl: 'notes-center.dialog.component.html',
  styleUrls: ['./notes-center.dialog.component.scss'],
  selector: 'oms-notes-center-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesCenterDialogComponent implements OnInit, AfterViewChecked {

  @Input() saveComment: (com: ObjectComCenter, selectedFiles: FileList) => Observable<any>;

  id: number;
  objectType: string;
  selectedNoteType: MeasureUnit;
  noteTypeId: number;

  selectedFiles: FileList;

  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  showProgressBar: boolean = false;
  public readonly: boolean = false;
  public sendonly: boolean = false;
  public haveDriverRole: boolean = false;
  public haveExternalWarehouseRole: boolean = false;

  progress: { percentage: number } = {percentage: 0};

  public modified: boolean = false;
  public dataChanged: boolean = true;

  @ViewChild('comments') private myScrollContainer: ElementRef;

  form: FormGroup;

  objectComCenter: ObjectComCenter = new ObjectComCenter();
  currentUser: LoggedUser;

  editorConfig: any =
    {
      placeholder: '',
      tabsize: 2,
      height: 200,
      lineHeight: 1,
      disableDragAndDrop: true,
      toolbar: []
    };

  /*{
      placeholder: '',
      tabsize: 2,
      height: 200,
      lineHeight:1,
      disableDragAndDrop:true,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
        ['fontsize', ['fontname', 'fontsize']],
        ['para', ['style0', 'ul', 'ol', 'paragraph']],
      ],
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    };*/

  constructor(public dialogRef: MatDialogRef<NotesCenterDialogComponent>,
              private fb: FormBuilder,
              private alerts: OmsAlertsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public noteType: NoteType,
              public fileUploadService: FileUploadService,
              public com: ComCenterService) {
    this.readonly = data.readonly;
    this.sendonly = data.sendonly;

    this.com.objectComCentersSubject.subscribe(() => {
      this.dataChanged = true;
    });
  }

  ngOnInit() {
    this.viewLoading = true;
    this.id = this.data.id;
    this.objectType = this.data.objectType;
    this.noteTypeId = this.data.noteTypeId;
    let noteTypesArray = this.getNoteTypesArray(this.noteTypeId);
    let selectedNoteType = this.noteTypeId !== 5 ? noteTypesArray[0].id : null;
    this.toggleItem(selectedNoteType);
    this.createForm();

    if (this.id) {
      this.com.findAllById(this.id, this.objectType);
    } else {
      this.com.objectComCentersSubject.next([]);
    }

    this.viewLoading = false;
    this.currentUser = UserUtil.getCurrentUser();
    this.haveDriverRole = this.currentUser.hasAnyRoles([UserRoleType.ROLE_DRIVER]);
    this.haveExternalWarehouseRole = this.currentUser.hasAnyRoles([UserRoleType.ROLE_EXTERNAL_WAREHOUSE]);
  }


  createForm() {
    this.form = this.fb.group({
      message: [this.objectComCenter.comCenter.text, Validators.required]
    });

  }

  getTitle(): string {
    return 'Notes';
  }

  getSubTitle(): string {
    switch (this.objectType) {
      case 'ORDER':
        return convertOrderNumber(this.id);
      case 'MASTER':
        return convertMasterNumber(this.id);
    }
    return this.objectType + ' ' + this.id;
  }



  onSubmit() {
    this.save();
  }

  save() {
    if (this.noteTypeId === 5 && isNullOrUndefined(this.selectedNoteType)) {
      this.alerts.warning("Please select one of the options: (O)verage, (S)hortage, (D)amage", 2000);

      return;
    }
    this.modified = false;
    const controls = this.form.controls;
    this.objectComCenter.objectId = this.id;
    this.objectComCenter.comCenter.text = controls['message'].value;
    let noteType = isNullOrUndefined(this.selectedNoteType) ? this.noteType.default : this.selectedNoteType;
    this.objectComCenter.comCenter.noteType = noteType.label;
    this.objectComCenter.objectType = this.objectType;
    if (isNullOrUndefined(this.selectedFiles)) {
      this.sendMessage(this.objectComCenter).subscribe((objectComCenter: ObjectComCenter) => {
        this.updateList(objectComCenter);
        this.objectComCenter.id = null;
        this.objectComCenter.comCenter.id = null;
        this.objectComCenter.comCenter.text = "";
        controls['message'].setValue("");
      }, (error) => {
        if (this.haveDriverRole || this.haveExternalWarehouseRole) {
          this.alerts.warning( "Cannot access server, data are stored will be sent later", 3000);
          this.saveLocalStorageData();
        }
      });
    } else {
      this.sendMessage(this.objectComCenter, this.selectedFiles).subscribe((event: HttpEvent<{}>) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.updateList();
          this.progress.percentage = 0;
          this.selectedFiles = undefined;
          this.showProgressBar = false;
        }
      }, (error) => {
        this.alerts.error(error, "File Was Not Loaded");
    //    this.saveLocalStorageData();
      });
    }
  }

  private updateList(newItems?: ObjectComCenter[] | ObjectComCenter) {
    if (this.id) {
      this.com.findAllById(this.id, this.objectType);
    } else {
      let docs = [];
      if (!newItems) {
        let copy = Object.assign(new ObjectComCenter(), newItems || this.objectComCenter);
        copy.comCenter = Object.assign(new ComCenter(), copy.comCenter);
        docs.push(copy);
      } else if (Array.isArray(newItems)) {
        docs.push(newItems[0]);
      } else {
        docs.push(newItems);
      }

      this.com.objectComCentersSubject.pipe(take(1)).subscribe(res => {
        this.com.objectComCentersSubject.next(res.concat(docs));
      });
    }
  }

  private sendMessage(com: ObjectComCenter, selectedFiles?: FileList): Observable<ObjectComCenter | HttpEvent<{}>> {
    if (this.saveComment) {
      return this.saveComment(com, selectedFiles);
    }
    if (selectedFiles) {
      return this.com.createWithDocument(this.objectComCenter, this.selectedFiles);
    }
    return this.com.createObservable(this.objectComCenter);
  }

  getFormControl(name) {
    const controls = this.form.controls;
    return controls[name];
  }

  edit(objectComCenter) {
    this.objectComCenter = objectComCenter;
//    console.log(objectComCenter.comCenter.text);
    this.form.controls['message'].setValue(objectComCenter.comCenter.text);
  }

  delete(objectComCenter) {
    this.com.delete(objectComCenter.id, this.objectType).subscribe(() => {
      if (this.id) {
        this.com.findAllById(this.id, this.objectType);
      }
    });
  }

  toggleItem(nodeTypeId) {
    let newNodeType = this.noteType.get(nodeTypeId);
    this.selectedNoteType = this.isSelected(nodeTypeId) ? newNodeType : newNodeType;
  }

  isSelected(nodeTypeId) {
    return !isNullOrUndefined(this.selectedNoteType) && this.selectedNoteType.id === nodeTypeId;
  }


  closeDialog() {
    this.dialogRef.close();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }


  scrollToBottom(): void {
    try {
      if (this.dataChanged) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        this.dataChanged = false;
      }
    } catch (err) {
    }
  }

  /*  transferDataSuccess($event) {
      let dataTransfer: DataTransfer = $event.mouseEvent.dataTransfer;
      if (dataTransfer && dataTransfer.files) {
        let files: FileList = dataTransfer.files;
        this.selectedFiles = files;
      }
    }*/

  allowDrop(ev) {
    ev.preventDefault();
  }


  drop(event) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files) {
      this.selectedFiles = event.dataTransfer.files;
    }
  }

  getSelectedFileName() {
    return !isNullOrUndefined(this.selectedFiles) && this.selectedFiles.length > 0 ? this.selectedFiles[0].name : "";
  }

  selectFile(event) {
    this.progress.percentage = 0;
    this.showProgressBar = false;
    this.selectedFiles = event.target.files;
  }

  buildDownloadUrl(comCenter: ComCenter) {
    return this.fileUploadService.buildDownloadFileUrl(comCenter.uploadedFiles.id);
  }

  getNoteTypesArray(noteTypeId) {
    switch (noteTypeId) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        return [this.noteType.get(noteTypeId)];
      case 5:
        return [this.noteType.get(1), this.noteType.get(2), this.noteType.get(3)];

    }
  }

  saveLocalStorageData() {
    let user = this.currentUser;
    if (user) {
      let storageAmount = localStorage.getItem('saveNotesCenterAmount' + user.id);
      // tslint:disable-next-line:radix
      let amount = isNullOrUndefined(storageAmount) ? 1 : parseInt(storageAmount) + 1;
      localStorage.setItem('saveNotesCenterAmount' + user.id, amount.toString());
      localStorage.setItem('saveComCenter' + user.id + "_" + amount, JSON.stringify(this.objectComCenter));
      if (!isNullOrUndefined(this.selectedFiles)) {
        let myArray = [];
        let file = {};
        for (let i = 0; i < this.selectedFiles.length; i++) {
          file = {
            'lastModified'     : this.selectedFiles[i].lastModified,
            'lastModifiedDate' : new Date(),
            'name'             : this.selectedFiles[i].name,
            'size'             : this.selectedFiles[i].size,
            'type'             : this.selectedFiles[i].type
          };
          myArray.push(file);
        }
       localStorage.setItem('saveSelectedFiles' + user.id + "_" + amount, JSON.stringify(file));
      }
    }
  }
}
