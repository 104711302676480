import {Injectable} from '@angular/core';
import {Address, Order, RecoveryOrder} from '../modules/shared/models';
import {MasterService} from '../services/master/master.service';
import {DispatchData, LoadDispatchDialogComponent} from '../components/dialogs/load-dispatch-dialog/load-dispatch-dialog.component';
import {Master} from '../modules/shared/models';
import {equals} from '../_helpers/utils';
import {OmsAlertsService} from '../modules/shared/components/oms-alerts/oms-alerts.service';
import {MasterPttCreatorDialogComponent} from '../components/common/master-ptt-creator-dialog';
import {MatDialog} from '@angular/material';
import {NgxSpinnerService} from 'ngx-spinner';
import {
  MastersDispatchData,
  MastersDispatchDialogComponent
} from '../components/dialogs/masters-dispatch-dialog/masters-dispatch-dialog.component';
import {OrdersDeliveryManifestRequest} from '../modules/shared/models/master.manifest.excel.request';
import {FileUploadService} from "../services/file.upload.service";
import {
  OrdersDispatchData,
  OrdersDispatchDialogComponent
} from '../components/dialogs/orders-dispatch-dialog/orders-dispatch-dialog.component';
import {OrdersService} from '../services';
import {LoadPickupDialogComponent, LoadPickupDialogData} from '../components/dialogs/load-pickup-dialog/load-pickup-dialog.component';
import {DialogResult, ModalResult, OmsDialogsService} from "../components/common/oms-dialogs";
import {isNullOrUndefined} from "util";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class MasterProcessor {

  public orderFromMasterEditor$ = new BehaviorSubject(null);

  constructor(
    private alerts: OmsAlertsService,
    private masterService: MasterService,
    private orderService: OrdersService,
    private matDialog: MatDialog,
    protected spinner: NgxSpinnerService,
    private fileUploadService: FileUploadService,
    private dialog: OmsDialogsService) {}


  public setDirect(master: Master, direct: boolean, deliveryAddress?: Address) {
    master.direct = direct;
    if (direct) {
      if (deliveryAddress) { master.orders.forEach(o => o.addressDelivery = deliveryAddress); }
    } else {
      if (deliveryAddress) { master.orders.forEach(o => o.addressCfs = deliveryAddress); }
    }
  }

  public buildShowUrl(generatedDocumentInfo) {
    return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
  }

  public dispatchOrdersDelivery(orders: Order[]): Promise<any> {
    return new Promise<any>((confirm, reject) => {

      let data: OrdersDispatchData = {
        orders: orders,
        date: new Date(),
        delivery: true
      };

      this.dialog.openDialog<OrdersDispatchDialogComponent, OrdersDispatchData>(OrdersDispatchDialogComponent, data)
        .catch(error => reject(error))
        .then((result: DialogResult<OrdersDispatchData>) => {

          switch (result.result) {

            case ModalResult.OK :
              let orderIds: number[] = orders.map(o => o.id);
              this.masterService.dispatchOrdersDelivery(orderIds, data.date, data.driver, data.truck, data.trailer, data.carrier)
                .catch(error => reject(error))
                .then(dispatched => {
                  console.log('show spinner');
                  this.spinner.show();
                  this.masterService.downloadOrdersDeliveryManifest(new OrdersDeliveryManifestRequest(orderIds, data.driver, data.truck, data.trailer))
                    .subscribe(res => {
                      console.log('hide spinner 1');
                      this.spinner.hide();
                      const fileURL = this.buildShowUrl(res);
                      console.log('URI', fileURL);
                      window.open(fileURL, '_blank');
                      confirm(dispatched);
                  }, error => {
                      console.log('hide spinner 2');
                      this.spinner.hide();
                      this.alerts.danger(error);
                    });
                });
              break;

            case ModalResult.DELETE:
              break;
          }

        });

    });
  }

  /** Dispatch Multiple Masters for Recovery**/
  public dispatchMastersRecovery(masters: Master[]): Promise<any> {
    return new Promise<any>((confirm, reject) => {

      let data: MastersDispatchData = {
        masters: masters,
        date: new Date(),
      };

      this.dialog.openDialog<MastersDispatchDialogComponent, MastersDispatchData>(MastersDispatchDialogComponent, data)
        .catch(error => reject(error))
        .then((result) => {

          if (result && result.result === ModalResult.OK) {
            let masterIds: number[] = masters.map(m => m.id);
            this.masterService.dispatchMastersRecovery(masterIds, data.date, data.driver, data.truck, data.trailer, data.pieces, data.hu)
              .catch(error => reject(error))
              .then((m) => confirm(m));
          } else if (result && result.result === ModalResult.DELETE) {
            this.masterService.cancelMastersRecovery(masters.map(m => m.id))
              .catch(error => reject(error))
              .then((m) => confirm(m));
          }

        });
    });
  }

  /** Dispatch Single Recovery Load **/
  public dispatchRecoveryLoad(split: RecoveryOrder, save: boolean, readonly: boolean): Promise<Master> {

    return new Promise<any>((confirm, reject) => {

      let master: Master = split.master;
      let data: DispatchData = {
        generatePtt: !split.isDispatched,
        readonly: readonly,
        split: split,
      };

      console.log('Prepared Data', data, 'Master', master);

      this.dialog.openDialog<LoadDispatchDialogComponent, DispatchData>(LoadDispatchDialogComponent, data)
        .then((result: DialogResult<DispatchData>) => {
          console.log('RESULT FROM DIALOG >', result);

          switch (result.result) {
            // Dispatch virtual Split or Update
            case ModalResult.OK:
              this.masterService.dispatchLoadRecovery(master, result.data.dispatch, save)
                .then((m) => {
                  console.log('DISPATCH RESULT FROM SERVER >', m);
                  confirm(m);
                  if (result.data.generatePtt) { this.matDialog.open(MasterPttCreatorDialogComponent, { width: '90%', data: { master: m, pcs: result.data.dispatch.pcs, load: split.load} }); }
                })
                .catch(error => {
                  this.alerts.error(error, 'Error on Load Dispatch');
                  reject(error);
                });
              break;

            // Cancel existing load;
            case ModalResult.DELETE:
              this.masterService.cancelLoadDispatch(master.id, split.load.id, split.orderId)
                .then((m) => {
                  console.log('CANCEL RESULT FROM SERVER >', m);

                  master.orders.forEach((o) => {o.shipmentContents.removeAll((sc) => equals(sc.shipment.load, split.load));
                  master.reset();
                  }); })
                .catch(error => {
                  this.alerts.error(error, 'Error on Load Cancel');
                  reject(error);
                });
              break;
          }
        })
        .catch(error => reject(error));
    });

  }

  /** Update Recovery Load PickUp **/
  public updateMasterRecoveryPickUp(master: Master, order?: RecoveryOrder): Promise<any> {
    console.log('LOAD ID', order ? order.loadId : '---------');
    return new Promise<any>((confirm, reject) => {
      this.dialog.openDialog<LoadPickupDialogComponent, LoadPickupDialogData>(LoadPickupDialogComponent, {masterId: master.id, orderId: order ? order.orderId : undefined})
        .then((result) => {
          if (result.result === ModalResult.OK) {
            console.log('PICKUP', result);
            this.masterService.pickupMasterRecovery(master, result.data.pickup, null)
              .then(r => confirm(r))
              .catch(error => reject(error));
          }
        })
        .catch((error) => reject(error));
    });
  }

  /** Update Recovery Load PickUp for Driver interface **/
  public updateMasterRecoveryPickUpWebDrivers(masterId: number, orderId: number): Promise<any> {
    return new Promise<any>((confirm, reject) => {
      this.dialog.openDialog<LoadPickupDialogComponent, LoadPickupDialogData>(LoadPickupDialogComponent, {noCamera: true, masterId: masterId, orderId: orderId})
        .then((result) => {
          if (result.result === ModalResult.OK) {
            console.log('PICKUP', result);
            this.masterService.pickupMasterRecovery(null, result.data.pickup, masterId)
              .then(r => confirm(r))
              .catch((error) => reject(error));
          }
        })
        .catch((error) => reject(error));
    });
  }

  public openNewManifestFromMasterEditor(order: Order) {
    if (!isNullOrUndefined(order)) {
      this.orderFromMasterEditor$.next(order.id);
    }
  }
}
