<oms-base-dialog [title]="title" [no-padding]="true" [icon]="'fa fa-camera'"
                 [loading]="viewLoading" [draggable]="false" [sizable]="false"
                 [auto-close]="false"  [store-position]="false" [show-label]="true"
                 [show-dialog-btns]="false">

  <div class="form-row">
    <div style="text-align:center">
      <div>
        <webcam *ngIf="!currentImage" [height]="mobHeight" [width]="mobWidth" [trigger]="triggerObservable"
                (imageCapture)="handleImage($event)"
                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions"
                (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)">
        </webcam>

        <div class="snapshot" *ngIf="currentImage">
          <img class="css-adaptive" [src]="currentImage.imageAsDataUrl"/>
        </div>
        <br/>
      </div>
    </div>
  </div>

  <ng-container widget-footer>
    <div class="widget-footer parts">

      <!--div class="btn-toolbar">
        <button type="button" class="btn btn-sm btn-default" (click)="closeDialog()">Close</button>
      </div-->

      <div class="dialog-btns">
        <!--button *ngIf="multipleWebcamsAvailable" class="btn btn-sm btn-primary" (click)="showNextWebcam(true);">
          <i class="fa fa-retweet"></i>&emsp;
          <span class="medium-screen-only">Switch Camera</span>
        </button-->

        <button class="btn btn-sm btn-primary" (click)="onClear();" *ngIf="!!currentImage">
          <i class="fa fa-trash"></i>&emsp;
          <span class="medium-screen-only">Delete</span>
        </button>

        <ng-container *ngIf="!!currentImage">
          <button *ngFor="let image of images;let index = index;"
                  [ngClass]="{'active': image === currentImage}"
            class="btn btn-sm btn-primary" (click)="currentImage = image;" popover="Page {{index+1}}">
            <i class="fa fa-file-picture-o"></i>&emsp;
            <span class="medium-screen-only">{{index+1}}</span>
          </button>
        </ng-container>

        <button class="btn btn-sm btn-primary" (click)="currentImage = undefined;" popover="Add Page" *ngIf="!!currentImage">
          <i class="fa fa-plus"></i>&emsp;
          <span class="medium-screen-only">Add Page</span>
        </button>


        <button class="btn btn-sm btn-primary" (click)="triggerSnapshot();" *ngIf="!currentImage">
          <i class="fa fa-camera"></i>&emsp;
          <span class="medium-screen-only">Take Picture</span>
        </button>
      </div>

      <div class="dialog-btns">
        <button class="btn btn-sm btn-success" (click)="onSubmit();" [disabled]="!hasImages() || docType == null" *ngIf="!!currentImage">
          <i class="fa fa-send"></i>&emsp;
          <span>&nbsp;Submit</span>
        </button>


        <ng-select [items]="docTypeList" *ngIf="docTypeList && docTypeList.length > 1"
                   dropdownPosition='top'
                   (change)="docTypeChanged($event)"
                   [searchable]="false"
                   [clearable]="false"
                   [(ngModel)]="docType">
          placeholder="Document type">
        </ng-select>
      </div>

      <!--div class="dialog-btns pull-right">
      </div-->
    </div>
  </ng-container>
</oms-base-dialog>
