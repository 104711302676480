import {Directive, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appOnInit]'
})
export class OnInitDirective implements OnInit {

  @Output('onInit')onInit: EventEmitter<any> = new EventEmitter();
  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.onInit.emit(this.el);
    this.el.nativeElement.focus();
  }
}
