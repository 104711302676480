import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FieldUpdateEvent,
  FilterOptions,
  PagingOptions,
  TreeModel
} from '../../../shared/components/base/data-table/data-table.utils';
import {
  DataTableComponent,
  GroupedFunc,
  TableParentNode
} from '../../../shared/components/base/data-table/data-table.component';
import {OmsAlertsService} from '../../../shared/components/oms-alerts/oms-alerts.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BaseColumn} from '../../../shared/components/base/data-table/columns/column-types';
import {FilterPageRequest} from '../../../shared/models/filter.page.request';
import {OrderDispatch} from '../../../shared/models/dispatch/order-dispatch';
import {DispatchService} from '../../../shared/services/dispatch/dispatch.service';
import {OmsDialogsService} from '../../../../components/common/oms-dialogs';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploadService} from '../../../../services/file.upload.service';
import {ColumnType} from "../../../../common/column-type";
import {OrdersService} from '../../../../services';
import {MatDialog} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {AddressMapInfoDialogComponent} from '../../../shared/components/address-map-info-dialog/address-map-info-dialog.component';
import {NumberUtils} from '../../../../_helpers/number.utils';
import {DriverOrderDispatch} from '../../../shared/models/dispatch/driver-order-dispatch';
import {take, takeUntil, tap} from 'rxjs/operators';
import {DriverService} from "../../../../services/driver-service";
import {Observable, Subject} from "rxjs";
import {TabInfo} from "../../../../pages/masters/masters-list/tabs-type";
import {UserService} from "../../../shared/services/user.service";
import {FilterSearchColumn} from "../../../shared/models/filter.search.column";
import {AbstractComponent} from "../../../../common/component/abstract.component";
import {Sort} from "../../../../common/oms-types";
import {ItemUpdateData} from "../../../../pages/masters/masters-list/item-update-data";
import {SearchSortRequest} from "../../../shared/models/search.sort.request";
import {ShipmentListColumns} from "./shipment-list-columns";
import {OrderMode} from "../../../shared/models/order/order-mode";
import {ContextMenuComponent} from "ngx-contextmenu";
import {ArrayUtils} from "../../../../_helpers/array.utils";
import {convertManifestNumber} from "../../../shared/services/oms-converters.service";
import {SearchRange} from "../../../shared/models/search.range";
import * as moment from "moment-timezone";
import {OmsConstants} from "../../../../common/oms-constants.service";

export enum ViewMode {
  DISPATCH = 'DISPATCH',
  STAGING = 'STAGING',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  DRIVER_ROUTES = 'DRIVER_ROUTES',
  REMOVED_ORDERS = 'REMOVED_ORDERS',

  /*** @deprecated ***/
  ALL_ORDERS = 'ALL_ORDERS',

  /*** @deprecated ***/
  AE_ACTIVE = 'AE_ACTIVE',

  ORDER_ACTIVE = 'ORDER_ACTIVE',   // todo check usage to remove
//  ORDER_DELIVERED = 'ORDER_DELIVERED', // todo check usage to remove
//  ORDER_BILLED = 'ORDER_BILLED', // todo check usage to remove
//  ALL = 'ALL' // todo check usage to remove
}

@Component({
  selector: 'oms-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShipmentListComponent extends AbstractComponent implements OnInit, OnChanges, ItemUpdateData {
  public total: number;
  public items: OrderDispatch[] = [];
  isDirty: boolean;

  @Input() viewMode: ViewMode;
  @Input('order-modes') orderModes: OrderMode[] = [];

  @Input() public selected: OrderDispatch[] = [];
  @Input() public tabInfo?: TabInfo;
  @Input() content: any;
  @Input() toolbar: any;
  @Input() searchColumns: FilterSearchColumn[] = [];
  @Input() public isVisibleTable: boolean = true;
  @Input() menu: ((row, column?: BaseColumn) => ContextMenuComponent) | ContextMenuComponent;
  @Output() public selectedChange = new EventEmitter<OrderDispatch[]>();
  @Output() public clickDispatch = new EventEmitter<OrderDispatch>();
  pageRequest: boolean = false;
  treeModel: TreeModel;

  activeSearchFilterCondition: any = {};
  public filter: FilterOptions = {total: 0, search: '', colorItem: null};

  totalPcs: number = 0;
  totalHu: number = 0;
  totalWeight: number = 0;
  totalUld: number = 0;

  public paging: PagingOptions = {enabled: true, pageSize: 100, pageSizes: [10, 20, 50, 100, 200, 500, 1000]};

  @ViewChild('table') table: DataTableComponent;

  public request: FilterPageRequest = new FilterPageRequest(1, 100, null, new SearchSortRequest('id', false), []);

  private dispatchSort: Sort = {field: 'orderId', order: 'desc'};
  private activeSort: Sort = {field: 'dispatchId', order: 'desc'};
  private completedSort: Sort = {field: 'dispatchId', order: 'desc'};
  private driverRoutesSort: Sort = {field: 'dateDispatchedFor', order: 'desc'};

  private refresh$ = new Subject<any>();

  dispatchDateColumn: BaseColumn;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private dialogs: OmsDialogsService,
    private cdr: ChangeDetectorRef,
    private alerts: OmsAlertsService,
    private dispatchService: DispatchService,
    private spinner: NgxSpinnerService,
    private file: FileUploadService,
    private ordersService: OrdersService,
    private driverService: DriverService,
    private userService: UserService
  ) {
    super();
  }

  DISPATCH_COLUMNS: BaseColumn[] = [
    ShipmentListColumns.ORDER_MODE,
    ShipmentListColumns.HOT,
    ShipmentListColumns.ORDER_ID,
    ShipmentListColumns.DOC_CENTER(this.ordersService, this.dialogs),
    ShipmentListColumns.COM_CENTER(r => this.onComCenterOpen(r)),
    ShipmentListColumns.ORDER_COD,
    ShipmentListColumns.ORDER_1C,
    ShipmentListColumns.DELIVERY_APPOINTMENT_REQUIRED,
    ShipmentListColumns.REQUESTED_DELIVERY_DATE,
    ShipmentListColumns.CUSTOMER_REF,
    ShipmentListColumns.ORDER_PO,
    ShipmentListColumns.HAWB,
    ShipmentListColumns.MAWB(r => this.openMasterEditor(r)),
    ShipmentListColumns.CUSTOMER_NAME,
    ShipmentListColumns.FREIGHT_FORWARDER_NAME,
    ShipmentListColumns.PCS,
    ShipmentListColumns.HU,
    ShipmentListColumns.WEIGHT,
    ShipmentListColumns.ULD(this.dialog),

    ShipmentListColumns.ADDRESS_PICKUP_NAME(r => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_PICKUP_STATE,
    ShipmentListColumns.ADDRESS_PICKUP_CITY,
    ShipmentListColumns.ADDRESS_PICKUP_ZIP,

    ShipmentListColumns.ADDRESS_DELIVERY_NAME(r => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_DELIVERY_STATE,
    ShipmentListColumns.ADDRESS_DELIVERY_CITY,
    ShipmentListColumns.ADDRESS_DELIVERY_ZIP,
    ColumnType.CREATED_BY,
    ColumnType.DATE_CREATED
  ];
  STAGING_COLUMNS: BaseColumn[] = [
    ShipmentListColumns.LOAD_TYPE,

    ShipmentListColumns.ORDER_MODE,
    ShipmentListColumns.ORDER_ID,
    ShipmentListColumns.DISPATCHED_DATE,
    ShipmentListColumns.STAGING_MANIFEST_ID(this.dispatchService, r => this.openManifestForm(r)),
//    ShipmentListColumns.DRIVER_NAME(this.userService, r => this.onDriverLocationOpen(r)),
//    ShipmentListColumns.TRUCK_NUMBER,
    ShipmentListColumns.TRAILER_NUMBER,
    ShipmentListColumns.DOC_CENTER(this.ordersService, this.dialogs),
    ShipmentListColumns.COM_CENTER(r => this.onComCenterOpen(r)),
    ShipmentListColumns.ORDER_COD,
    ShipmentListColumns.REQUESTED_DELIVERY_DATE,
    ShipmentListColumns.CUSTOMER_REF,
    ShipmentListColumns.ORDER_PO,
    ShipmentListColumns.HAWB,
    ShipmentListColumns.MAWB(r => this.openMasterEditor(r)),
    ShipmentListColumns.CUSTOMER_NAME,
    ShipmentListColumns.FREIGHT_FORWARDER_NAME,
    ShipmentListColumns.PCS,
    ShipmentListColumns.HU,
    ShipmentListColumns.WEIGHT,
    ShipmentListColumns.ULD(this.dialog),

    ShipmentListColumns.PROBLEM,

    ShipmentListColumns.ADDRESS_PICKUP_NAME(r => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_PICKUP_STATE,
    ShipmentListColumns.ADDRESS_PICKUP_CITY,
    ShipmentListColumns.ADDRESS_PICKUP_ZIP,

    ShipmentListColumns.ADDRESS_DELIVERY_NAME(r => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_DELIVERY_STATE,
    ShipmentListColumns.ADDRESS_DELIVERY_CITY,
    ShipmentListColumns.ADDRESS_DELIVERY_ZIP,

    ShipmentListColumns.DATE_PICKUP,
    ShipmentListColumns.DATE_DELIVERY,
    ShipmentListColumns.COMMENTS,
    ShipmentListColumns.CREATED_BY,
    ShipmentListColumns.DATE_CREATED,
    ShipmentListColumns.ORDER_CREATED_BY,
    ShipmentListColumns.ORDER_DATE_CREATED
  ];
  ACTIVE_COLUMNS: BaseColumn[] = [
    ShipmentListColumns.LOAD_TYPE,

    ShipmentListColumns.ORDER_MODE,
    ShipmentListColumns.ORDER_ID,
    this.dispatchDateColumn = ShipmentListColumns.DISPATCHED_DATE,
    ShipmentListColumns.MANIFEST_ID(this.dispatchService, (r) => this.openManifestForm(r)),

    ShipmentListColumns.DRIVER_NAME(this.userService, (r) => this.onDriverLocationOpen(r)),
    ShipmentListColumns.TRUCK_NUMBER,
    ShipmentListColumns.TRAILER_NUMBER,

    ShipmentListColumns.DOC_CENTER(this.ordersService, this.dialogs),
    ShipmentListColumns.COM_CENTER((r) => this.onComCenterOpen(r)),
    ShipmentListColumns.ORDER_COD,
    ShipmentListColumns.REQUESTED_DELIVERY_DATE,
    ShipmentListColumns.CUSTOMER_REF,
    ShipmentListColumns.ORDER_PO,
    ShipmentListColumns.HAWB,
    ShipmentListColumns.MAWB((r) => this.openMasterEditor(r)),
    ShipmentListColumns.CUSTOMER_NAME,
    ShipmentListColumns.FREIGHT_FORWARDER_NAME,
    ShipmentListColumns.PCS,
    ShipmentListColumns.HU,
    ShipmentListColumns.WEIGHT,
    ShipmentListColumns.ULD(this.dialog),

    ShipmentListColumns.PROBLEM,

    ShipmentListColumns.ADDRESS_PICKUP_NAME((r) => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_PICKUP_STATE,
    ShipmentListColumns.ADDRESS_PICKUP_CITY,
    ShipmentListColumns.ADDRESS_PICKUP_ZIP,

    ShipmentListColumns.ADDRESS_DELIVERY_NAME((r) => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_DELIVERY_STATE,
    ShipmentListColumns.ADDRESS_DELIVERY_CITY,
    ShipmentListColumns.ADDRESS_DELIVERY_ZIP,

    ShipmentListColumns.DATE_PICKUP,
    ShipmentListColumns.DATE_DELIVERY,
    ShipmentListColumns.COMMENTS,
    ShipmentListColumns.CREATED_BY,
    ShipmentListColumns.DATE_CREATED,
    ShipmentListColumns.ORDER_CREATED_BY,
    ShipmentListColumns.ORDER_DATE_CREATED
  ];
  COMPLETED_COLUMNS: BaseColumn[] = [
    ShipmentListColumns.LOAD_TYPE,

    ShipmentListColumns.ORDER_MODE,
    ShipmentListColumns.ORDER_ID,
    ShipmentListColumns.DISPATCHED_DATE,
    ShipmentListColumns.MANIFEST_ID(this.dispatchService, r => this.openManifestForm(r)),

    ShipmentListColumns.DOC_CENTER_FOR_COMPLETED(this.ordersService, this.dialogs),
    ShipmentListColumns.COM_CENTER(r => this.onComCenterOpen(r)),

    ShipmentListColumns.DRIVER_NAME(this.userService, r => this.onDriverLocationOpen(r)),
    ShipmentListColumns.TRUCK_NUMBER,
    ShipmentListColumns.TRAILER_NUMBER,

    ShipmentListColumns.ORDER_COD,
    ShipmentListColumns.REQUESTED_DELIVERY_DATE,
    ShipmentListColumns.CUSTOMER_REF,
    ShipmentListColumns.ORDER_PO,
    ShipmentListColumns.HAWB,
    ShipmentListColumns.MAWB(r => this.openMasterEditor(r)),
    ShipmentListColumns.CUSTOMER_NAME,
    ShipmentListColumns.FREIGHT_FORWARDER_NAME,
    ShipmentListColumns.PCS,
    ShipmentListColumns.HU,
    ShipmentListColumns.WEIGHT,
    ShipmentListColumns.ULD(this.dialog),

    ShipmentListColumns.PROBLEM,

    ShipmentListColumns.ADDRESS_PICKUP_NAME(r => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_PICKUP_STATE,
    ShipmentListColumns.ADDRESS_PICKUP_CITY,
    ShipmentListColumns.ADDRESS_PICKUP_ZIP,

    ShipmentListColumns.ADDRESS_DELIVERY_NAME(r => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_DELIVERY_STATE,
    ShipmentListColumns.ADDRESS_DELIVERY_CITY,
    ShipmentListColumns.ADDRESS_DELIVERY_ZIP,


    ShipmentListColumns.DATE_PICKUP,
    ShipmentListColumns.DATE_DELIVERY,
    ShipmentListColumns.COMMENTS,
    ShipmentListColumns.CREATED_BY,
    ShipmentListColumns.DATE_CREATED,
    ShipmentListColumns.ORDER_CREATED_BY,
    ShipmentListColumns.ORDER_DATE_CREATED,
    ShipmentListColumns.DISPATCH_LOAD_STATUS
  ];
  ORDER_ACTIVE: BaseColumn[] = [
    ShipmentListColumns.ORDER_ID,

    ShipmentListColumns.DOC_CENTER(this.ordersService, this.dialogs),
    ShipmentListColumns.COM_CENTER((r) => this.onComCenterOpen(r)),

    ShipmentListColumns.MAWB((r) => this.openMasterEditor(r)),
    ShipmentListColumns.HAWB,
    ShipmentListColumns.CUSTOMER_REF,
    ShipmentListColumns.ORDER_PO,
    ShipmentListColumns.CUSTOMER_NAME,
    ShipmentListColumns.ULD(this.dialog),
    ShipmentListColumns.PCS,
    ShipmentListColumns.HU,
    ShipmentListColumns.WEIGHT,

    ShipmentListColumns.ADDRESS_PICKUP_NAME((r) => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_PICKUP_STATE,
    ShipmentListColumns.ADDRESS_PICKUP_CITY,
    ShipmentListColumns.ADDRESS_PICKUP_ZIP,

    ShipmentListColumns.ADDRESS_DELIVERY_NAME((r) => this.addressInfoPopup(r.addressDelivery)),
    ShipmentListColumns.ADDRESS_DELIVERY_STATE,
    ShipmentListColumns.ADDRESS_DELIVERY_CITY,
    ShipmentListColumns.ADDRESS_DELIVERY_ZIP,

    ShipmentListColumns.ORDER_DATE_CREATED,
    ShipmentListColumns.ORDER_CREATED_BY,
  ];
  DRIVER_ROUTES_COLUMNS: BaseColumn[] = [
    ShipmentListColumns.ROUTE_DATE,
    ShipmentListColumns.DRIVER_NAME(this.userService, (r) => this.onDriverLocationOpen(r)),
    ShipmentListColumns.DRIVER_MANIFEST_ID((r) => this.openManifestFormByDriver(r)),
    ShipmentListColumns.DRIVER_ROUTE_LOADS
  ];

  AE_ACTIVE_COLUMNS: BaseColumn[] = this.createAeActiveColumns();

/*
  public static ViewModes() {
    return ViewMode;
  }
 */


  private createAeActiveColumns(): BaseColumn[] {
    let columns = this.ACTIVE_COLUMNS.filter((c) => ![
      ShipmentListColumns.ORDER_MODE,
      ShipmentListColumns.ADDRESS_PICKUP_STATE,
      ShipmentListColumns.ADDRESS_PICKUP_CITY,
      ShipmentListColumns.ADDRESS_PICKUP_ZIP,
      ShipmentListColumns.ADDRESS_DELIVERY_STATE,
      ShipmentListColumns.ADDRESS_DELIVERY_CITY,
      ShipmentListColumns.ADDRESS_DELIVERY_ZIP].includes(c));
    columns.splice(14, 0, ColumnType.ORDER_CHASSIS);
    return columns;
  }

  isSelectable(): boolean {
    return this.viewMode === ViewMode.DISPATCH || this.viewMode === ViewMode.ORDER_ACTIVE;
  }

  get columns(): BaseColumn[] {
    switch (this.viewMode) {
      case ViewMode.DISPATCH:
        return this.DISPATCH_COLUMNS;
      case ViewMode.STAGING:
        return this.STAGING_COLUMNS;
      case ViewMode.ACTIVE:
        return this.ACTIVE_COLUMNS;
      case ViewMode.AE_ACTIVE:
        return this.AE_ACTIVE_COLUMNS;
      case ViewMode.COMPLETED:
        return this.COMPLETED_COLUMNS;
      case ViewMode.DRIVER_ROUTES:
        return this.DRIVER_ROUTES_COLUMNS;
      case ViewMode.ORDER_ACTIVE:
        return this.ORDER_ACTIVE;
    }
    console.error('Columns for View mode not defined. ' + this.viewMode);
    return [];
  }

  ngOnInit() {
//    this.updateDispatchDate();
  }

  // note Removed by Request 0001472: DISPATCH/AE/FCL UI: Remove Default filter for manifest date.
  private updateDispatchDate(): void {

    if (this.viewMode === ViewMode.ACTIVE || this.viewMode === ViewMode.AE_ACTIVE) {
      let startDay = moment.tz(new Date(), OmsConstants.ETC_ZONE)
        .set({h: 0, m: 0, s: 0, ms: 0})
        .utc();
      let range = new SearchRange(startDay.format(DispatchService.FULL_MOMENT_FORMAT), startDay.format(DispatchService.FULL_MOMENT_FORMAT), false);
      this.dispatchDateColumn.search.search = range;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewMode && this.request) {
      this.request.sort.field = this.defaultColumnForSort();
    }
    if (changes.viewMode) {
      this.updateTreeModel(this.viewMode);
      this.items = [];
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }
  }

  private updateTreeModel(viewMode: ViewMode) {
    // if (viewMode === ViewMode.DRIVER_ROUTES) {
    //   this.treeModel = new DriverRouteTreeModel(this.dispatchService);
    // } else {
    this.treeModel = null;
    // }
  }

  onChangeSelected(selected: OrderDispatch[]) {
    this.selectedChange.emit([...selected]);
  }

  private updateTotalValues(selectedDispatch: OrderDispatch[]) {
    let totalPcs = 0;
    let totalHu = 0;
    let totalWeight = 0;
    let totalUld = 0;
    for (let i = 0; i < selectedDispatch.length; i++) {
      if (NumberUtils.isNumber(selectedDispatch[i].pieces)) {
        totalPcs += selectedDispatch[i].pieces;
      }
      if (NumberUtils.isNumber(selectedDispatch[i].hu)) {
        totalHu += selectedDispatch[i].hu;
      }
      if (NumberUtils.isNumber(selectedDispatch[i].weight)) {
        totalWeight += selectedDispatch[i].weight;
      }
      if (NumberUtils.isNumber(selectedDispatch[i].uldCount)) {
        totalUld += selectedDispatch[i].uldCount;
      }
    }
    this.totalPcs = totalPcs;
    this.totalHu = totalHu;
    this.totalWeight = totalWeight;
    this.totalUld = totalUld;
    this.cdr.markForCheck();
  }

/*
  ngAfterViewInit(): void {
//    this.table.buildSearchRequest();
//    this.onSearch(null);
  }

 */

  get editEnabled() {
    return this.selected && this.selected.length === 1;
  }

  editSelected() {
    if (this.editEnabled) {

    }
  }

  // Update Row Field
  onUpdate(event: FieldUpdateEvent<OrderDispatch>) {
//    console.log('update:', event);
    let fields: any = {};
    fields[event.field] = event.newValue;
    this.ordersService.updateOrderOld(event.row.orderId, fields)
      .then(() => {
        event.row[event.field] = event.newValue;
        event.row = Object.assign(new OrderDispatch(), event.row);
        event.row['fakeTrackId'] = null;
        this.items = [...this.items];
        this.items.replaceAll(event.row, event.row);
        this.table.refresh([event.row]);
        this.cdr.markForCheck();
      })
      .catch(error => this.onUpdateFieldError(error, event));
  }

  onUpdateFieldError(error, updateEvent: FieldUpdateEvent) {
    if (updateEvent) {
      updateEvent.cancel();
    }

    if (typeof error === 'string' && error.includes('UNIQUE KEY')) {
      error = 'Duplicated value not allowed: ' + updateEvent.newValue;
    }

//    console.log('Update Field Error', updateEvent, error);
    this.alerts.danger(error);
  }

  sortBySelected() {
    if (this.viewMode === 'DISPATCH') {
      return 'desc';
    }
    return '';
  }

  defaultColumnForSort(): string {
    if (this.viewMode === 'DISPATCH') {
      return null;
    }
    return 'dispatchId';
  }

  public updateData(filterPageRequest?: FilterPageRequest): Subject<void> {
    let subject = new Subject<void>();
    this.updateList(filterPageRequest).subscribe(() => subject.next());
    return subject;
  }

  private updateList(request?: FilterPageRequest): Observable<void> {
//    this.items = [];
    if (this.treeModel && this.treeModel.clear) {
      this.treeModel.clear();
    }
    if (this.table) { // is not visible
      this.table.afterUpdate();
    }
    this.cdr.markForCheck();

    this.spinner.show().then();


    this.request = request || this.request;
    this.request.replaceColumns(this.searchColumns);
    this.request.findByOccurs = this.getSearchText();
    if (this.table) {
      this.table.filter.search = this.getSearchText();
    }
    this.request.sort.field = this.request.sort.field || this.defaultColumnForSort();
    let namedCondition = this.viewMode === ViewMode.AE_ACTIVE ? ViewMode.ACTIVE : this.viewMode;
    this.request.addNamedCondition(namedCondition);
    if (this.table) {
      this.table.beforeUpdate();
    }
    this.pageRequest = true;
    this.request.modesFilter = [];
    if (this.orderModes && this.orderModes.length > 0) {
      this.orderModes.forEach((mode) => {
        this.request.modesFilter.push(mode.toString());
      });
    }
    let range = this.dispatchDateColumn.search.search;
    if (this.viewMode === ViewMode.AE_ACTIVE && range instanceof SearchRange) {
      let filterByDate = new FilterSearchColumn('dateDispatchedFor', null, null, range);
      this.request.filterByColumns.push(filterByDate);
    }

    return this.callRequestByViewMode()
      .pipe(tap((page) => {
        console.log('Page received', page);
        this.items = page.content;
        if (this.treeModel && this.treeModel.clear) {
          this.treeModel.clear();
        }
        this.updateTotalValues(this.items);
        this.paging.total = page.totalElements;
//        console.log("Page>", this.items.length);
        if (this.table) {
          this.table.afterUpdate();
        }
        this.spinner.hide().then();

        this.pageRequest = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        this.refresh$.next();
      }, error => {
        if (this.table) {
          this.table.afterUpdate();
        }
        this.spinner.hide().then();
        this.pageRequest = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        this.alerts.error(error);
        this.refresh$.error(error);
      }));
  }

  private callRequestByViewMode(): Observable<any> {
    if (this.viewMode === ViewMode.DISPATCH) {
      return this.dispatchService.findAllToDispatch(this.request);
    } else if (this.viewMode === ViewMode.DRIVER_ROUTES) {
      return this.dispatchService.findDispatchedLoadsForDriver(this.request);
    }
    return this.dispatchService.findDispatchedLoadsObs(this.request);
  }

  openManifestFormByDriver(dr: DriverOrderDispatch) {
    this.dispatchService.findDispatchedLoadsByDriver(dr)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response.content.length) {
          this.openManifestForm(response.content[0]);
        }
      });
  }

  openManifestForm(dispatch: OrderDispatch) {
    // this.dialog.open(ManifestDialogComponent, {data:{manifest: manifest}});
    // this.dialogs.openDialog(ManifestDialogComponent, {manifest: manifest, });
    // console.log('Show Manifest', manifest);
    this.clickDispatch.emit(dispatch);
  }

  addressInfoPopup(address) {
    if (!isNullOrUndefined(address)) {
      this.dialog.open(AddressMapInfoDialogComponent, {width: '50%', data: {address: address, searchByAddress: true}});
    }
  }

  openMasterEditor(dispatch: OrderDispatch) {
    if (dispatch.masterId) {
      this.router.navigate(['dispatch/shipments/master', {id: dispatch.masterId}]).then();
    }
  }

  refresh(): Observable<any> {
    this.table.buildSearchRequest();
    this.cdr.markForCheck();
    return this.refresh$.pipe(take(1));
  }

  public getRowClass(row: OrderDispatch): string {
    if (this.viewMode === ViewMode.DRIVER_ROUTES) {
      return '';
    }
    let result = 'row-dispatch';

    if (!(row instanceof OrderDispatch)) {
      return result;
    }

    /*    if (row.isRecovery) {
          if (row.isPickedUp) {
            result += ' success';
          }
        } else { */

    if (row.dispatchCancelled) {
      result += ' dispatch-cancelled';
    }

    if (row.isDispatched) {
      result += row.isConfirmed ? ' confirmed' : ' not-confirmed';
    }


    if (row.isNotCompleted()) {
      result += ' danger';
    }

    if (row.isCompletedWithProblem()) {
      result += ' warning';
    }

    if (row.isCompleted()) {
      result += ' success';
    }


//      result += row.isDelivered ?
//        (row.hasProblem ? ' warning' : (this.viewMode === ViewMode.DISPATCH ? '' : ' success')) :
//        (row.hasProblem ? ' danger' : '');
//    }

    return result;
  }

  public onComCenterOpen(row: OrderDispatch) {
    this.dialogs.openComCenterDialog(row.orderId, 'ORDER');
  }

  public onDriverLocationOpen(row: OrderDispatch) {
    this.dialogs.openDriverLocationDialog(row.driver);
  }

  get sort(): Sort {
    switch (this.viewMode) {
      case ViewMode.DISPATCH:
        return this.dispatchSort;
      case ViewMode.ACTIVE:
        return this.activeSort;
      case ViewMode.AE_ACTIVE:
        return this.activeSort;
      case ViewMode.COMPLETED:
        return this.completedSort;
      case ViewMode.DRIVER_ROUTES:
        return this.driverRoutesSort;
      case ViewMode.ORDER_ACTIVE:
        return this.activeSort;
    }
  }

  set sort(value: Sort) {
//    console.log('Sort Changed', this.viewMode, value);
    switch (this.viewMode) {
      case ViewMode.DISPATCH:
        this.dispatchSort = value;
        break;
      case ViewMode.ACTIVE:
        this.activeSort = value;
        break;
      case ViewMode.AE_ACTIVE:
        this.activeSort = value;
        break;
      case ViewMode.COMPLETED:
        this.completedSort = value;
        break;
      case ViewMode.DRIVER_ROUTES:
        this.driverRoutesSort = value;
        break;
      case ViewMode.ORDER_ACTIVE:
        this.activeSort = value;
        break;
    }
  }

  public setSearchText(text: string) {
    this.filter.search = text;
    this.filter = Object.assign({}, this.filter);
  }

  public changeSearchText(searchText: string): void {
    this.setSearchText(searchText);
    this.request.findByOccurs = searchText;
    if (this.table) {
      this.table.changeSearchText(searchText);
    }
  }

  get filterPageRequest(): FilterPageRequest {
    return this.request;
  }

  public getSearchText(): string {
    return this.filter.search;
  }


/*  public getChassisOrUld(row: OrderDispatch): string {
    if (OrderModes.isFCL(row.orderMode)) {
      return row.chassis;
    } else {
      return row.uldCount;
    }
  }
 */

  getDisplayForRowHeader(row: TableParentNode<OrderDispatch>): string {
    if (!row.children.length) {
      return null;
    }
    let customerNames = row.children
      .filter(item => item.customer && item.customer.name)
      .map(item => item.customer.name);
    customerNames = ArrayUtils.removeDuplicate(customerNames, c => c);
    const displayDispatch = convertManifestNumber(row.children[0].dispatchId);
    const trailer = !isNullOrUndefined(row.children[0].trailer) && !isNullOrUndefined(row.children[0].trailer.number) ? row.children[0].trailer.number : 'N/A';
    let header = displayDispatch + (customerNames.length ? (' - ' + customerNames.join(', ')) : '');
    if (ViewMode.STAGING === this.viewMode) {
      header = header + ' - Trailer:' + trailer;
    }
    return header;
  }

  getGroupedFunc(): GroupedFunc<any> {
    if (this.viewMode !== ViewMode.STAGING && this.viewMode !== ViewMode.ACTIVE) {
      return null;
    }
    return this.groupedByDispatchId;
  }

  groupedByDispatchId = (items: OrderDispatch[]) => {
    let grouped = items.reduce((total, item) => {
      if (item.dispatchId) {
        total[item.dispatchId] = total[item.dispatchId] || [];
        total[item.dispatchId].push(item);
      }
      return total;
    }, {});
    return Object.keys(grouped).map(dispatchId => {
      return {
        children: grouped[dispatchId]
      };
    });
  }
}
