import {MasterStatus, MasterStatusId} from '../../../modules/shared/models';
import {FilterSearchColumn} from '../../../modules/shared/models/filter.search.column';
import {FilterPageRequest} from '../../../modules/shared/models/filter.page.request';
import {TabFilter} from './orders-table/orders-table.component';
import { UserRoleType } from "../../../modules/shared/models/user-role.type";

const only_2a_orders = 'ONLY_2A_ORDERS';  // show any masters with 2a orders
const some_received_condition = 'SOME_RECEIVED';
const ams_no_1c_condition = 'AMS_NO_1C';
const non_ams_or_has_1c_condition = 'NON_AMS_OR_HAS_1C';
// const not_generic_condition = 'NOT_GENERIC';
// const air_export_condition = 'AIR_EXPORT';

export class TabsType {
  public static readonly RECOVERY: TabInfo = {
    id: 'recovery',
    statuses: [ // deprecated
      MasterStatusId.NEW,
      MasterStatusId.ACKNOWLEDGED,
      MasterStatusId.FLIGHT_ARRIVED,
      MasterStatusId.RECOVERY_DISPATCHED,
      MasterStatusId.PROBLEM_RECOVERY,
      MasterStatusId.RECOVERED_PENDING_ONHAND
    ],

    preLoad: true,
    tabFilters: [
      {id: '1a', label: '1.a', statuses: [MasterStatusId.NEW], },
      {id: '1b', label: '1.b', statuses: [MasterStatusId.ACKNOWLEDGED]},
      {id: '1c', label: '1.c', statuses: [MasterStatusId.FLIGHT_ARRIVED]},
      {id: '1d', label: '1.d', statuses: [MasterStatusId.RECOVERY_DISPATCHED]},
      {id: '1e', label: '1.e', statuses: [MasterStatusId.PROBLEM_RECOVERY]},
//      {id: '2a', label: '2.a', statuses: [MasterStatusId.RECOVERED_PENDING_ONHAND]},
    ],
    filterName: 'recovery',
    showCheckboxes: true,
    label: 'Recov',
    masterListFunction: 'recoveryMasters'
  };

  public static readonly RECEIVE: TabInfo = {
    id: 'receive',
    /**
     * @deprecated
     */
    statuses: [MasterStatusId.RECOVERED_PENDING_ONHAND],

    tabFilters: [
      {id: '2a', label: '2.a', statuses: [MasterStatusId.RECOVERED_PENDING_ONHAND]},
      ],

    preLoad: true,
    filterName: 'pending',
    label: 'Receiv',
    masterListFunction: 'pendingMasters'
  };

  public static readonly PENDING: TabInfo = {
    id: 'pending',

    namedConditions: ['ORDER_PENDING'],
    preLoad: true,
    filterName: 'pending',
    label: 'Pending',
    showCheckboxes: true,
    masterListFunction: 'pendingOrders'
  };

  public static readonly ACTIVE_ORDER: TabInfo = {
    id: 'active',

    namedConditions: ['ORDER_ACTIVE'],
    preLoad: true,
    showCheckboxes: true,
    filterName: 'all', label: 'Active', masterListFunction: 'allOrders'
  };

  // todo note Tab Renamed to Delivered. Check Request Logic and naming
  public static readonly DELIVERY_ORDER: TabInfo = {
    id: 'delivered',

    namedConditions: ['ORDER_DELIVERY'],
    preLoad: true,
    showCheckboxes: true,
    filterName: 'delivery', label: 'Delivered', masterListFunction: 'delivery'
  };

  public static readonly ARCHIVED_ORDER: TabInfo = {
    id: 'archived',

    namedConditions: ['ARCHIVED_ORDER'],
    preLoad: true,
    showCheckboxes: true,
    filterName: 'close', label: 'Arch', masterListFunction: 'close'
  };

  // todo note Tab Renamed to Billed. Check Request Logic and naming
  public static readonly BILLING_ORDER: TabInfo = {
    id: 'billing',

    namedConditions: ['ORDER_BILLING'],
    preLoad: true,
    showCheckboxes: true,
    filterName: 'billing', label: 'Billed', masterListFunction: 'billing'
  };

  public static readonly CFS: TabInfo = {
    id: 'cfs',

    statuses: MasterStatus.cfsStatusesIds, // deprecated
    tabFilters: [
      {id: '1x', label: '1.x', statuses: [MasterStatusId.NEW, MasterStatusId.ACKNOWLEDGED, MasterStatusId.FLIGHT_ARRIVED, MasterStatusId.RECOVERY_DISPATCHED, MasterStatusId.PROBLEM_RECOVERY],
        additionalConditions: [some_received_condition, ams_no_1c_condition]},
//      {id: '2a', label: '2.a', statuses: [MasterStatusId.RECOVERED_PENDING_ONHAND],
//        additionalConditions: [some_received_condition, ams_no_1c_condition]},
      {id: '2b', label: '2.b', statuses: [MasterStatusId.ONHAND_COMPLETE_PENDING_1C]},
      {id: '2d', label: '2.d', statuses: [MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY],
        additionalConditions: [ams_no_1c_condition]},
    ],

    preLoad: true,
    filterName: 'cfsanddelivery',
    label: 'CFS',
    showCheckboxes: true,
    masterListFunction: 'cfsanddeliveryMasters',
    isOrder: true
  };

  public static readonly WHSE: TabInfo = {
    id: 'whse',

    /**
     * @deprecated
     */
    statuses: MasterStatus.whseStatusesIds,
    tabFilters: [
      {id: '1x', label: '1.x', statuses: [MasterStatusId.NEW, MasterStatusId.ACKNOWLEDGED, MasterStatusId.FLIGHT_ARRIVED, MasterStatusId.RECOVERY_DISPATCHED, MasterStatusId.PROBLEM_RECOVERY],
        additionalConditions: [some_received_condition]},
      {id: '2c', label: '2.c', statuses: [MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH]},
      {id: '2d', label: '2.d', statuses: [MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY],
        additionalConditions: [non_ams_or_has_1c_condition]},
      {id: '2e', label: '2.e', statuses: [MasterStatusId.PROBLEM]},

    ],

    preLoad: true,
    filterName: 'whse',
    label: 'WHSE',
    showCheckboxes: true,
    masterListFunction: 'whse',
    isOrder: true
  };

  public static readonly DELIVERY: TabInfo = {
    id: 'delivery',

    statuses: MasterStatus.deliveryStatusesIds,
    tabFilters: [
      {id: '3a', label: '3.a', statuses: [MasterStatusId.PICKED_UP_FROM_JJS]},
    ],

    preLoad: true,
    filterName: 'delivery',
    label: 'Deliv',
    masterListFunction: 'deliveryMasters',
    isOrder: true
  };

  public static readonly BILLING: TabInfo = {
    id: 'billing',

    statuses: [ // deprecated
      MasterStatusId.DELIVERED_PENDING_POD,
      MasterStatusId.ORDER_COMPLETE_READY_TO_BILL
    ],
    tabFilters: [
      {id: '3b', label: '3.b', statuses: [MasterStatusId.DELIVERED_PENDING_POD]},
      {id: '3c', label: '3.c', statuses: [MasterStatusId.ORDER_COMPLETE_READY_TO_BILL]},
    ],

    preLoad: true,
    filterName: 'billing',
    label: 'Bill',
    masterListFunction: 'billingMasters',
  };

  public static readonly ACTIVE: TabInfo = {
    id: 'active',

    statuses: MasterStatus.allStatusesIds, // deprecated

    tabFilters: [ // All except Closed
      {id: '1a', label: '1.a', statuses: [MasterStatusId.NEW], btnHidden: false },
      {id: '1b', label: '1.b', statuses: [MasterStatusId.ACKNOWLEDGED], btnHidden: false },
      {id: '1c', label: '1.c', statuses: [MasterStatusId.FLIGHT_ARRIVED], btnHidden: false },
      {id: '1d', label: '1.d', statuses: [MasterStatusId.RECOVERY_DISPATCHED], btnHidden: false },
      {id: '1e', label: '1.e', statuses: [MasterStatusId.PROBLEM_RECOVERY], btnHidden: false },

      {id: '2a', label: '2.a', statuses: [MasterStatusId.RECOVERED_PENDING_ONHAND], btnHidden: false },
      {id: '2b', label: '2.b', statuses: [MasterStatusId.ONHAND_COMPLETE_PENDING_1C], btnHidden: false },

      {id: '2c', label: '2.c', statuses: [MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH], btnHidden: false },
      {id: '2d', label: '2.d', statuses: [MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY], btnHidden: false },
      {id: '2e', label: '2.e', statuses: [MasterStatusId.PROBLEM], btnHidden: false },

      {id: '3a', label: '3.a', statuses: [MasterStatusId.PICKED_UP_FROM_JJS], btnHidden: false },
      {id: '3b', label: '3.b', statuses: [MasterStatusId.DELIVERED_PENDING_POD], btnHidden: false },
      {id: '3c', label: '3.c', statuses: [MasterStatusId.ORDER_COMPLETE_READY_TO_BILL], btnHidden: false },
    ],

    preLoad: true,
    filterName: 'all', label: 'Active', masterListFunction: 'allMasters'
  };



  public static readonly ARCHIVED: TabInfo = {
    id: 'archived',

    statuses: [MasterStatusId.CLOSED], // deprecated
    tabFilters: [
      {id: '4a', label: '4.a', statuses: [MasterStatusId.CLOSED], btnHidden: true},
    ],

    preLoad: false,
    filterName: 'closed', label: 'Arch', masterListFunction: 'closedMasters'
  };

  public static readonly MASTERS_DELETED: TabInfo = {
    id: 'deleted',

    tabFilters: [],

    preLoad: false,
    filterName: 'deleted',
    label: '',
    masterListFunction: 'deletedMasters',
//    access: UserRoleType.ROLE_USER,
    icon: 'fa fa-trash',
    showCheckboxes: true,
    getFilters: () => [new FilterSearchColumn('dateDeleted', 'NOT_NULL', null, null)]
  };

  public static readonly ALL_ORDERS: TabInfo = {
    id: 'orders',

    tabFilters: [],

    preLoad: false,
    filterName: 'allOrders',
    label: '',
    masterListFunction: 'allOrders',
    access: UserRoleType.ROLE_ADMIN,
    icon: 'fa fa-trash',
    showCheckboxes: true,
    getFilters: () => []
  };

  public static readonly ORDERS_DELETED: TabInfo = {
    id: 'deleted',

    tabFilters: [],

    preLoad: false,
    filterName: 'deleted',
    label: '',
    masterListFunction: 'deletedOrders',
//    access: UserRoleType.ROLE_USER,
    tabType: 'DELETED',
    icon: 'fa fa-trash',
    showCheckboxes: true,
    getFilters: () => [
      new FilterSearchColumn('dateDeleted', 'NOT_NULL', null, null),
      new FilterSearchColumn('genericModeNonAI', null, null, null)]
  };

  /**
   * @deprecated
   */
  public static readonly AIR_EXPORT_ORDERS: TabInfo = {
    id: 'orders',

    tabFilters: [],

    preLoad: false,
    filterName: 'deleted',
    label: '',
    masterListFunction: 'deletedOrders',
    access: UserRoleType.ROLE_ADMIN,
    icon: 'fa fa-trash',
    showCheckboxes: true,
    getFilters: () => []
  };

  public static readonly allAirImportTabs: TabInfo[] = [
    TabsType.RECOVERY,
    TabsType.RECEIVE,
    TabsType.CFS,
    TabsType.WHSE,
    TabsType.DELIVERY,
    TabsType.BILLING,
    TabsType.ACTIVE,
    TabsType.ARCHIVED,
    TabsType.MASTERS_DELETED
  ];

  public static readonly allAirExportOrderTabs: TabInfo[] = [
    TabsType.PENDING,
    TabsType.ACTIVE_ORDER,
    TabsType.DELIVERY_ORDER,
    TabsType.BILLING_ORDER,
    TabsType.ARCHIVED_ORDER,
    TabsType.ORDERS_DELETED,
  ];

  public static ORDER_TABS: TabInfo[] = [
    TabsType.AIR_EXPORT_ORDERS,
    TabsType.ALL_ORDERS,
    TabsType.BILLING_ORDER,
    TabsType.CFS,
    TabsType.WHSE,
    TabsType.DELIVERY_ORDER, TabsType.DELIVERY];

}

export interface TabInfo {
  id: string;
  filterName: string;
  label: string;
  icon?: string;
  showCheckboxes?: boolean;
  tabType?: string;
  /**
   * @deprecated
   */
  statuses?: MasterStatusId[];
  tabFilters?: TabFilter[];
  getFilters?: () => FilterSearchColumn[];
  apply?: (request: FilterPageRequest, tab: TabInfo) => void;
  preLoad?: boolean;  // updateData on background. if False then table updates data if Active
  masterListFunction: string;
  access?: UserRoleType;
  isOrder?: boolean;
  namedConditions?: string[];

  isLoading?: boolean;
}
