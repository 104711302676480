﻿<perfect-scrollbar style="max-height: 60vh;">
  <!--loadingAfterSubmit-->
  <div class="oms-modal widget"
       [ngClass]="{'m-portlet--body-progress-overlay' : false }">


    <div class="widget-header">
      <h3><i class="fa fa-comment"></i>
        Chat
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>

    <div class="widget-content">

      <div class="col-md-12">

        <div class="col-md-2 driver-list">
          <ul class="user-list">
            <li *ngFor="let driver of drivers" [ngClass]="{'active':isCurrentDriver(driver)}">
              <span (click)="changeCurrentDriver(driver)">{{ driver.fullName }}</span>
            </li>
          </ul>
        </div>

        <div class="col-md-10">
          <div class="chat-window">
            <section #chatSession class="chat-session">
              <ul class="message-list">
                <li class="user-message" *ngFor="let chatRecord of chatRecords">
                 <!-- <span class="user-id">{{ chatRecord.senderId }}</span>-->
                  <span [innerHTML]="chatRecord.text"></span>
              <!--    <a *ngFor="let link of chatRecord.url_matches" href="{{ link }}"
                     class="link-preview">{{
                    link }}</a>-->
                </li>
              </ul>
              <!--emoji-mart class="emoji-mart" set="emojione" (emojiSelect)="addEmoji($event)" *ngIf="showEmojiPicker" title="Pick your emoji…"></emoji-mart-->
            </section>

            <div class="chat-footer">
              <form (ngSubmit)='sendMessage()'>

                <i class="emoji-button fa fa-smile-o" aria-hidden="true" (click)="toggleEmojiPicker()"></i>

                <input placeholder="Type a message. Hit Enter to send" type="text"
                       name="message" [(ngModel)]="message">
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="widget-footer">
      <div></div>
      <div class="dialog-btns">
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>


    </div>

  </div>
</perfect-scrollbar>
