import {Exclude, Type} from 'class-transformer';
import {Labeled} from "../../../common/oms-types";
import {UserVisibilityFilter} from "./uservisibilityfilter/user-visibility-filter";
import {UserProfileResource} from "./user-profile-resource";
import {BaseDeletable} from "./base-deletable";

export class UserProfile extends BaseDeletable implements Labeled {

  name: string;
  canRemoveOrder: boolean = true;

  @Type(() => UserVisibilityFilter)
  userVisibilityFilter: UserVisibilityFilter;

  @Type(() => UserProfileResource)
  resources: UserProfileResource[];

  @Exclude()
  get label(): string {
    return this.name;
  }

}
