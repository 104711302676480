import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class DataTableService {

  public searchInputSubject = new Subject<string>();
  public searchColorSubject = new Subject<string>();


  constructor() { }

  public changeText(text) {
    this.searchInputSubject.next(text);
  }

  searchColorChanged(colorItem: any) {
    this.searchColorSubject.next(colorItem);
  }
}
