import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import {Injectable} from "@angular/core";
import {tap} from "rxjs/operators";
import {BnNgIdleService} from "bn-ng-idle";

@Injectable()
export class AuthInterseptor implements HttpInterceptor {
  constructor(private bnIdle: BnNgIdleService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            this.bnIdle.resetTimer();
          }
        }
      )
    );
  }
}
