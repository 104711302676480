import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Address, AddressType, Customer, UsaState} from '../models';
import {HttpUtilsService} from '../../../services/http-utils.service';
import {RestService} from '../../../services/rest.service';
import {map} from 'rxjs/operators';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {isNullOrUndefined} from 'util';
import {plainToClass} from 'class-transformer';
import {OmsAlertsService} from '../components/oms-alerts/oms-alerts.service';
import {BaseEntityService} from '../../../services/base/base-entity.service';
import {AddressSearchService} from './address-search.service';
import {CfsLocation} from '../models/address/cfs-location';
import {DialogType, ModalResult, OmsDialogsService} from "../../../components/common/oms-dialogs";


@Injectable({providedIn: 'root'})
export class AddressService extends BaseEntityService<Address> {
  protected apiUrl = '/oms/address/';
  protected classType = Address;

  public addressPopUpEvent = new Subject<any>();
  public addressesSubject = new BehaviorSubject<Address[]>([]);

  public customerDeliveryLocations: Map<number, Address[]> = new Map<number, Address[]>();

  public constructor(
        public httpRestService: RestService,
        public httpUtils: HttpUtilsService,
        private dialogs: OmsDialogsService,
        private alerts: OmsAlertsService) {
    super(httpRestService, httpUtils);
  }


  public hideAddressPopUp() {
    this.addressPopUpEvent.next({
      show: false,
      address: null
    });
  }

  getAllAddresses() {
    this.httpRestService.get<Address[]>(this.apiUrl + 'getAllAddresses').subscribe(items => {
      this.addressesSubject.next(plainToClass(Address, items));
    });
  }

  getByType(search: string, types: AddressType[], count?: number):Promise<Address[]> {
    const params = new HttpParams()
      .set('search', search)
      .set('count', !isNullOrUndefined(count) ? count.toString() : '');
    return this.httpRestService.put<Address[]>(this.apiUrl + 'searchByType', types, this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

  setAddressTypes(addressId: number, types: AddressType[]) {
    const params = new HttpParams()
      .set('addressId', addressId.toString());
    return this.httpRestService.put<Address[]>(this.apiUrl + 'setAddressTypes', types, this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

  getAirportWarehouse(airportId:number, airlineId:number):Promise<Address> {
    const params = new HttpParams()
      .set('airport', airportId.toString())
      .set('airline', airlineId.toString());

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

      return this.httpRestService.getWithOptions<Address>(this.apiUrl + 'searchAirportWarehouse', headers, params)
        .pipe(map(a => plainToClass(Address, a)))
        .toPromise();
  }

  updateAddressTypes(address: Address, types: AddressType[]) {
    this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm',
      'Selected Address does not match requested type ('+Address.addressDescription(types)+').\nWould you like to update selected address?',
      [{caption: "Update", result:ModalResult.YES, class:'btn-primary'},
               {caption: "Cancel", result:ModalResult.CANCEL, class:'btn-danger'}])
      .then(result=>{
        if (result.result === ModalResult.YES) {
          this.setAddressTypes(address.id, types).
          then(()=>this.alerts.info("Selected Address has updated as "+ Address.addressDescription(types), 2000));
        }
      });
  }

  getNewSearchService(types: AddressType[], count?:number) {
    return new AddressSearchService(this, types, count);
  }

  getCustomerDeliveryLocations(customer: Customer):Promise<Address[]> {
    let addresses = !customer || customer.isNew() ? [] : this.customerDeliveryLocations.get(customer.id);
    if (addresses) {
      //Found local, don't query server
      return new Promise<Address[]>((resolve)=>{resolve(addresses)});
    } else {
      //Not found, query server for list

      let params = new HttpParams().set('customerId', customer.id.toString());
      return this.httpRestService.put<Address[]>(this.apiUrl + 'getCustomerDeliveryLocations', null,
        this.httpUtils.getHTTPHeaders(),params).toPromise()
        //chaining storing received array into the local map
        .then(array=>{
          let addresses = plainToClass(Address, array);
          //Store received address array to local map
          this.customerDeliveryLocations.set(customer.id, addresses);
          return addresses;
        });
    }
  }

  setCustomerDeliveryLocation(customer: Customer, address:Address) {
    if (customer && address) {
      let addresses: Address[] = this.customerDeliveryLocations.get(customer.id);
      if (!addresses) {
        addresses = [];
        this.customerDeliveryLocations.set(customer.id, addresses);
      }

      if (!addresses.hasEquals(address)) {
        let params = new HttpParams()
          .set('customerId', customer.id.toString())
          .set('addressId', address.id.toString());
        return this.httpRestService.put<any>(this.apiUrl + 'setCustomerDeliveryLocation', null, this.httpUtils.getHTTPHeaders(), params)
          .subscribe(()=>addresses.push(address));
      }
    }
  }

  public deleteCustomerDeliveryLocation(customer: Customer, address: Address) {
    if (customer && !customer.isNew()) {
      let addresses: Address[] =  this.customerDeliveryLocations.get(customer.id);
      if (addresses && addresses.length >0)
        addresses.removeAll(address);

      let params = new HttpParams()
        .set('customerId', customer.id.toString())
        .set('addressId', address.id.toString());
      return this.httpRestService.put<any>(this.apiUrl + 'deleteCustomerDeliveryLocation', null, this.httpUtils.getHTTPHeaders(), params)
        .subscribe(()=>{});
    }
  }

  getCfsLocationsByAddress(addressCfsId: number):Promise<CfsLocation[]> {
    const params = new HttpParams()
      .set('addressCfsId', addressCfsId.toString());
    return this.httpRestService.getWithOptions<CfsLocation[]>(this.apiUrl + 'getCfsLocationsByAddress', this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

  fillStateAndCity(): Observable<any> {
    return this.httpRestService.get<any>(this.apiUrl + 'fillStateAndCity');
  }
}
