import {isNullOrUndefined} from 'util';
import {add, isSameDay, subtract} from 'ngx-bootstrap/chronos';
import * as moment from "moment-timezone";
import {OmsConstants} from "./oms-constants.service";

export enum PendingStatus {
  PENDING,   // Regular font color
  IMPENDING, // Green
  AT_RISK,   // Yellow
  PAST_DUE   // Red
}

export function ensureDate(date: Date | string): Date {
  if (isNullOrUndefined(date)) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
    return isNaN(date.getTime()) ? null : date;
  } else {
    return date;
  }
}

/*export function compareDate(date1, date2: Date):number {
  let a:any = ensureDate(date1).valueOf();
  let b:any= ensureDate(date2).valueOf();
  return isFinite(a) && isFinite(b) ? (a > b ? 1 : 0)-(a < b ? 1 : 0) : NaN;
}*/

// days diff ignoring time
export function daysDiff(date1: Date, date2: Date): number {
  if (isNullOrUndefined(date1) || isNullOrUndefined(date2)) {
    return NaN;
  }

  date1 = ensureDate(date1);
  date2 = ensureDate(date2);

  let _date1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  let _date2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  let ms = _date2 - _date1;
  return Math.floor(ms / 1000 / 60 / 60 / 24);
}

export function minutesDiff(date1: Date, date2: Date): number {
  if (isNullOrUndefined(date1) || isNullOrUndefined(date2)) {
    return NaN;
  }

  return Math.floor((date2.getTime() - date1.getTime()) / 60000);
}


export function addHours(date: Date, hours: number): Date {
  if (isNullOrUndefined(date) || isNullOrUndefined (hours)) {
    return date;
  }
  return add(new Date(date), hours, 'hours', false);
}

export function convertLocalDateToEST(date: Date): Date {
  if (isNullOrUndefined(date)) {
    return date;
  }
  let dateWithUtc: any = moment.utc(date);
  let estTimeOffsetToUTC = moment.tz.zone(OmsConstants.ETC_ZONE).utcOffset(dateWithUtc);
  let difference = estTimeOffsetToUTC / 60;
  let prepareUTCTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
  return addHours(prepareUTCTime, difference);
}

export function convertToEST(date: Date) {
  if (isNullOrUndefined(date)) {
    return date;
  }
  let dateWithUtc: any = moment.utc(date);
  let estTimeOffsetToUTC = moment.tz.zone(OmsConstants.ETC_ZONE).utcOffset(dateWithUtc);
  let difference = estTimeOffsetToUTC / 60;
  let prepareUTCTime = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes());
  return subtract(prepareUTCTime, difference, 'hours', false);
}

export function convertESTToLocal(date: Date): Date {
  return new Date(date.toLocaleString("en-US", {timeZone: OmsConstants.ETC_ZONE}));
}

export function maxETCDateFromNow() {
  let now: any = moment.utc();
  let estTimeOffsetToUTC = moment.tz.zone(OmsConstants.ETC_ZONE).utcOffset(now);

  let difference = estTimeOffsetToUTC / 60;
  let prepareUTCTime = new Date(now);
  let nowUtc = new Date( prepareUTCTime.getTime() + (prepareUTCTime.getTimezoneOffset() * 60000));
  return subtract(nowUtc, difference, 'hours', true);
}

export function subtractHours(date: Date, hours: number) {
  if (isNullOrUndefined(date) || isNullOrUndefined (hours)) {
    return date;
  }
  return subtract(new Date(date), hours, 'hours', false);
}

export function compareDate(a: Date, b: Date): number {
  if (!a) {
    return !b ? 0 : -1;
  } else if (!b) {
    return 1;
  }
  return a.getTime() - b.getTime();
}

export function sameDate(date1: Date | string, date2: Date | string): boolean {
  date1 = ensureDate(date1);
  date2 = ensureDate(date2);

  if (isNullOrUndefined(date1)) { return isNullOrUndefined(date2); }
  if (isNullOrUndefined(date2)) { return false; }

//  if (typeof date2 === 'string') date2 = new Date(date2); // May be String
//  if (typeof date1 === 'string') date1 = new Date(date1); // May be String

  return isSameDay(date1, date2);
}

export function sameDateTime(date1: Date | string, date2: Date | string): boolean {
  date1 = ensureDate(date1);
  date2 = ensureDate(date2);
  if (isNullOrUndefined(date1)) { return isNullOrUndefined(date2); }
  if (isNullOrUndefined(date2)) { return false; }

//  if (typeof date2 === 'string') date2 = new Date(date2); // May be String
//  if (typeof date1 === 'string') date1 = new Date(date1); // May be String

  return date1.valueOf() === date2.valueOf();
}

export function isBefore(d1, d2: Date) {
  d1 = ensureDate(d1);
  d2 = ensureDate(d2);

  if (isNullOrUndefined(d1)) { return false; }
  if (isNullOrUndefined(d2)) { return false; }

  return d1.valueOf() < d2.valueOf();
}

export function minDate(d1, d2: Date) {
  d1 = ensureDate(d1);
  d2 = ensureDate(d2);

  if (isNullOrUndefined(d1)) { return d2; }
  if (isNullOrUndefined(d2)) { return d1; }

  return d1.valueOf() < d2.valueOf() ? d1 : d2;
}

export function maxDate(date1: Date, date2: Date): Date {
  date1 = ensureDate(date1);
  date2 = ensureDate(date2);
  if (isNullOrUndefined(date1)) { return date2; }
  if (isNullOrUndefined(date2)) { return date1; }
  return date1.valueOf() < date2.valueOf() ? date2 : date1;
}

export function maxDateOrNull(date1: Date, date2: Date): Date {
  if (isNullOrUndefined(date1) || isNullOrUndefined(date2)) { return null; }
  date1 = ensureDate(date1);
  date2 = ensureDate(date2);
  return date1.valueOf() < date2.valueOf() ? date2 : date1;
}


export function latest<T>(array: T[], getter: (item: T) => Date): Date {
  let date: Date = null;
  for (let item of array) {
    let d = getter(item);
    if (isNullOrUndefined(d)) {
      return null;
    } else {
      date = maxDate(date, d);
    }
  }
  return date;
//  return array.some(ms=>isNullOrUndefined(date = maxDate(date, getter(ms)))) ? null : date;
}

export function latestObjectByDate<T>(array: T[], getter: (item: T) => Date): T {
  let date: Date = null;
  let biggestObject: T;
  for (let item of array) {
    let d = getter(item);
    if (isNullOrUndefined(d)) {
      return null;
    } else {
      let newDate = maxDate(date, d);
      if (newDate !== date) {
        date = newDate;
        biggestObject = item;
      }
    }
  }
  return biggestObject;
}

export function getPendingStatus(date: Date, based?: Date): PendingStatus {
  if (isNullOrUndefined(date)) {
    return null;
  }

  let days = daysDiff(isNullOrUndefined(based) ? new Date() : ensureDate(based), date);
  if (days === 0) {
    return PendingStatus.IMPENDING;
  } else if (days < 0) {
    return PendingStatus.PAST_DUE;
       } else {
    return PendingStatus.PENDING;
       }
}

export function getPendingStatusEstimated(date: Date, based?: Date): PendingStatus {
  if (isNullOrUndefined(date)) {
    return null;
  }

  let before = isBefore(isNullOrUndefined(based) ? new Date() : ensureDate(based), date);
  let days = daysDiff(isNullOrUndefined(based) ? new Date() : ensureDate(based), date);

  if (before && days === 0) {
    return PendingStatus.IMPENDING;
  } else if (!before) {
    return PendingStatus.PAST_DUE;
  } else {
    return PendingStatus.PENDING;
  }
}


