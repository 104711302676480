﻿import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UploadedFiles} from '../../../modules/shared/models/documents/uploaded.files';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {ObjectDocuments} from '../../../modules/shared/models/documents/object.documents';
import {FileUploadService} from '../../../services/file.upload.service';
import {isNullOrUndefined} from 'util';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {DocumentTypeService} from '../../../services/document-type.service';
import {DocumentType} from '../../../modules/shared/models/documents/document-type';
import {Carrier} from "../../../modules/shared/models/carrier";


@Component({templateUrl: 'file-upload-dialog.component.html',
            styleUrls: ['./file-upload-dialog.component.scss'],
            selector: 'oms-file-upload-dialog'})
export class FileUploadDialogComponent implements OnInit {

  id: number | number[];

  objectDocuments = new ObjectDocuments();
  uploadedFiles = new UploadedFiles();
  objectType: 'ORDER' | 'MASTER';
  carrier: Carrier;

  selectedFiles: FileList;
  public error: any;

  form: FormGroup;

  hasFormErrors = false;
  viewLoading = false;
  documentType: DocumentType;
  loadingAfterSubmit = false;
  showProgressBar: boolean = false;
  noDescription: boolean = false;

  public units: DocumentType;
  documentTypes: DocumentType[] = [];
  progress: { percentage: number } = { percentage: 0 };

  constructor(public dialogRef: MatDialogRef<FileUploadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private fileUploadService: FileUploadService,
              private documentTypeService: DocumentTypeService,
              public alertsService: OmsAlertsService) {
    this.noDescription = this.data && this.data.noDescription;
    this.carrier = this.data && this.data.carrier;
  }

  public close = () => this.closeDialog();
  public submit = () => this.onSubmit();

  ngOnInit() {
    this.id = this.data.id;
    this.objectType = this.data.objectType;
    this.viewLoading = true;

    this.createForm();
    this.documentTypeService.findAll({field: 'name', order: 'asc'})
      .then((types) => {
        this.documentTypes = DocumentType.sort(types);
        this.units = this.documentTypes[0];
      });

    this.viewLoading = false;
  }

  createForm() {
    this.form = this.fb.group({
      description: [""],
      file: ["", Validators.required],
      docType: ["", Validators.required],
      carrier: [""]
    });

    if (this.data.files) {
      this.selectedFiles = this.data.files;
    }
  }

  getTitle(): string {
    return 'File Upload';
  }


  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && control.touched;
  }

  getSelectedFileName() {
    return !isNullOrUndefined(this.selectedFiles) && this.selectedFiles.length > 0 ? this.selectedFiles[0].name : "";
  }

  onSubmit() {
    if (this.id) {
      this.hasFormErrors = false;
      this.loadingAfterSubmit = false;
      const controls = this.form.controls;
      console.log('Controls', controls);

      /** check form */
      if (this.form.invalid) {
        Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
        this.hasFormErrors = true;
        return;
      }

      this.progress.percentage = 0;
      this.showProgressBar = true;
      this.error = undefined;
//      this.objectDocuments.objectId = 0; // this.id;
//      this.objectDocuments.objectType = this.objectType;
//      this.objectDocuments.uploadedFiles = this.uploadedFiles;

      let data = {};
      if (this.needCarrier && this.carrier) {
        data['carrier'] = this.carrier.id;
      }

      this.fileUploadService.uploadMultipleDocuments(this.documentType, this.objectType, this.id, this.selectedFiles, data)
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress.percentage = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
            }
          },
          (error) => {
            this.showProgressBar = false;
            console.log('Error', error.toString(), error);
            this.error = error;
            this.alertsService.error(error, "File Was Not Uploaded");
          });

      this.selectedFiles = undefined;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  onUnitsChanged(event: DocumentType) {
    this.documentType = event;
    this.objectDocuments.documentType = event;
    if (this.needCarrier) {
      this.form.controls['carrier'].setValidators(Validators.required);
      console.log('Set Validations', this.form);
    } else {
      this.form.controls['carrier'].clearValidators();
      this.form.controls['carrier'].setErrors(null);
      console.log('Clear Validations', this.form);
    }
  }


  selectFile(event) {
    this.progress.percentage = 0;
    this.showProgressBar = false;
    this.selectedFiles = event.target.files;
  }

  public get isPOD() {
    return this.documentType && this.documentType.isPOD;
  }

  public get needCarrier(): boolean {
    return this.objectType === 'ORDER' && this.isPOD;
  }
}
