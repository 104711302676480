var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ValueAccessorBase } from '../../../base/value-accessor';
var SelectableTextAreaInputComponent = /** @class */ (function (_super) {
    __extends(SelectableTextAreaInputComponent, _super);
    function SelectableTextAreaInputComponent() {
        var _this = _super.call(this) || this;
        _this.hasToggle = true;
        _this.isEdit = false;
        return _this;
    }
    SelectableTextAreaInputComponent.prototype.onCbClick = function () {
        if (this.value) {
            this.value = null;
        }
        else {
            this.isEdit = true;
        }
    };
    SelectableTextAreaInputComponent.prototype.ngOnInit = function () {
    };
    SelectableTextAreaInputComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasToggle && !this.hasToggle) {
            this.isEdit = true;
        }
    };
    return SelectableTextAreaInputComponent;
}(ValueAccessorBase));
export { SelectableTextAreaInputComponent };
