<div class="tab-wrapper" [ngClass]="{'custom-tabs-left': style==='tabs-left'}" >
  <ul class="nav" role="tablist" [hidden]="externalButtons"
      [ngClass]="{'nav-tabs-custom-colored': colored,
                  'nav-tabs': style==='tabs' || style==='tabs-left',
                  'nav-pills': style === 'pills' || style==='minimal' || style==='minimal-bottom',
                  'nav-pills-custom-minimal': style==='minimal' || style==='minimal-bottom',
                  'custom-minimal-bottom': style === 'minimal-bottom'}">

    <li class="" *ngFor="let tab of tabs; let i = index">
      <a oms-tab-button [tab]="tab" [href]="'#'+tab.tabId" data-toggle="tab" (click)="setActive(tab)" >
        <i [class]="tab.iconClass" *ngIf="tab.iconClass">&nbsp;</i>{{tab.name}}</a>
    </li>
  </ul>

  <div [class]="'tab-content clearfix ' + contentClass || ''">
    <div class="tab-pane" [class]="tab.tabClass" [ngClass]="{'fade': !tab.active, 'active': tab.active}" [id]="tab.tabId" *ngFor="let tab of tabs">
      <span *ngIf="tab.active">
        <ng-container *ngTemplateOutlet="tab.template;context{tab: tab}" ></ng-container>
      </span>
    </div>
  </div>
</div>
