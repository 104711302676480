<!--perfect-scrollbar style="max-height: 90vh;"-->
<oms-base-dialog [title]="getTitle()" [btnOk]="null" [min-width]="'500px'">
  <!--div class="oms-modal widget"-->
<!--
    <div class="widget-header">
      <h3><i class="fa fa-exclamation-triangle"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>
-->
    <!--div class="widget-content" style="align-content: start; min-height: 315px"-->

      <div class="form-row">

        <div class="form-group" *ngIf="!readonly">
          <label class="control-label">Select from list and add</label>
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-exclamation-triangle"></i>
            </span>

            <!--ng-select>
            </ng-select-->
            <!--select type="search" class="form-control" [(ngModel)]="newProblem" placeholder="aaa">
              <option value="cheese">Cheese</option>
              <option value="tomatoes">Tomatoes</option>
              <option value="mozarella">Mozzarella</option>
              <option value="mushrooms">Mushrooms</option>
              <option value="pepperoni">Pepperoni</option>
              <option value="onions">Onions</option>
            </select-->

              <!--button type="button" class="btn btn-warning" tabindex="-1">Action</button-->
            <input #textInput type="text" class="form-control dropdown-toggle" data-toggle="dropdown" tabindex="-1" aria-expanded="true" title=""
                   (keydown)="onInputKeyDown($event)" (select)="addProblem()"
                   [(ngModel)]="newProblemDescription"/>
            <ul class="dropdown-menu pull-right" role="menu" style="left: 0;">
              <li *ngFor="let problem of allProblems" [ngClass]="{'active': newProblemType === problem.id}" (click)="onSelectProblem(problem)"><a>{{problem.label}}</a></li>
              <li class="divider"></li>
              <li (click)="newProblemType = null; newProblemDescription = null"><a>Clear</a></li>
            </ul>

            <div class="input-group-btn">
              <button class="btn btn-primary" (click)="addProblem()" [disabled]="disableAddProblem()">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row">
        <table class="table table-responsive table-striped table-bordered table-hover table condensed">
          <thead>
            <tr>
              <th>Problem</th>
              <th>Reported By</th>
              <th>Date</th>
              <th style="width: 25px">Resolved</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let problem of problems" [ngClass]="{'problem-resolved':problem.isResolved}" [tooltip]="getProblemNoteTooltip(problem)">
              <td>{{problem.label}}</td>
              <td>{{problem.systemUserName}}</td>
              <td>{{problem.createdAt | oms_date_time}}</td>
              <td>
                <span class="fancy-checkbox custom-bgcolor-green" (click)="readonly ? return : problem.isResolved = ! problem.isResolved"
                      [tooltip]="problem.dateResolved | oms_date_time" [container]="'body'">
                  <input type="checkbox" title="" [ngModel]="problem.isResolved" >
                  <span></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    <!--/div-->


    <!--div class="widget-footer">
      <div></div>

      <div class="dialog-btns">
        <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="false" *ngIf="!readonly"><i class="fa fa-save"></i>Save</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div-->
  <!--/div-->

  <ng-container widget-footer-buttons>
    <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="false" *ngIf="!readonly"><i class="fa fa-save"></i>Save</button>
    <!--button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button-->
  </ng-container>
</oms-base-dialog>
<!--/perfect-scrollbar-->
