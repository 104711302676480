/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cfs-location-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common/oms-dialogs/base-dialog/base-dialog.component.ngfactory";
import * as i3 from "../../common/oms-dialogs/base-dialog/base-dialog.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "ngx-spinner";
import * as i6 from "../../../modules/shared/components/common/input/string-input/string-input.component.ngfactory";
import * as i7 from "@angular/forms";
import * as i8 from "../../../modules/shared/components/common/input/string-input/string-input.component";
import * as i9 from "../../../modules/shared/components/oms-checkbox/oms-checkbox.component.ngfactory";
import * as i10 from "../../../modules/shared/components/oms-checkbox/oms-checkbox.component";
import * as i11 from "./cfs-location-dialog.component";
var styles_CfsLocationDialogComponent = [i0.styles];
var RenderType_CfsLocationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CfsLocationDialogComponent, data: {} });
export { RenderType_CfsLocationDialogComponent as RenderType_CfsLocationDialogComponent };
export function View_CfsLocationDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { inputs: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "oms-base-dialog", [], [[8, "className", 0]], [[null, "ok"], ["document", "keydown.escape"], ["window", "popstate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onEscape($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:popstate" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onPopState($event) !== false);
        ad = (pd_1 && ad);
    } if (("ok" === en)) {
        var pd_2 = (_co.onOk($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_BaseDialogComponent_0, i2.RenderType_BaseDialogComponent)), i1.ɵdid(2, 4440064, null, 0, i3.BaseDialogComponent, [i4.MAT_DIALOG_DATA, i5.NgxSpinnerService, i4.MatDialogRef], { _icon: [0, "_icon"], _title: [1, "_title"], _readonly: [2, "_readonly"] }, { eventOK: "ok" }), (_l()(), i1.ɵeld(3, 0, null, 1, 9, "div", [["class", "row-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "oms-string-input", [["maxlength", "30"], ["required", ""]], [[1, "required", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.cfsLocation.name = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_StringInputComponent_0, i6.RenderType_StringInputComponent)), i1.ɵdid(5, 16384, null, 0, i7.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵdid(6, 540672, null, 0, i7.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i7.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i7.RequiredValidator, i7.MaxLengthValidator]), i1.ɵdid(8, 49152, [[1, 4]], 0, i8.StringInputComponent, [[2, i7.NG_VALIDATORS], [2, i7.NG_ASYNC_VALIDATORS], i4.MatDialog], { label: [0, "label"] }, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.StringInputComponent]), i1.ɵdid(10, 671744, null, 0, i7.NgModel, [[8, null], [6, i7.NG_VALIDATORS], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(12, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, 1, 3, "div", [["class", "row-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "oms-checkbox", [], null, [[null, "checkedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkedChange" === en)) {
        var pd_0 = ((_co.cfsLocation.isAms = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_OmsCheckboxComponent_0, i9.RenderType_OmsCheckboxComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.OmsCheckboxComponent]), i1.ɵdid(16, 49152, null, 0, i10.OmsCheckboxComponent, [[2, i7.NG_VALIDATORS], [2, i7.NG_ASYNC_VALIDATORS]], { label: [0, "label"], checked: [1, "checked"] }, { checkedChange: "checkedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fa fa-industry"; var currVal_2 = "CFS Location"; var currVal_3 = _co.readonly; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_13 = ""; _ck(_v, 5, 0, currVal_13); var currVal_14 = "30"; _ck(_v, 6, 0, currVal_14); var currVal_15 = "Location Name:"; _ck(_v, 8, 0, currVal_15); var currVal_16 = _co.cfsLocation.name; _ck(_v, 10, 0, currVal_16); var currVal_17 = "AMS"; var currVal_18 = _co.cfsLocation.isAms; _ck(_v, 16, 0, currVal_17, currVal_18); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); var currVal_4 = (i1.ɵnov(_v, 5).required ? "" : null); var currVal_5 = (i1.ɵnov(_v, 6).maxlength ? i1.ɵnov(_v, 6).maxlength : null); var currVal_6 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 12).ngClassValid; var currVal_11 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_CfsLocationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-driver-dialog", [], null, null, null, View_CfsLocationDialogComponent_0, RenderType_CfsLocationDialogComponent)), i1.ɵdid(1, 4308992, null, 0, i11.CfsLocationDialogComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CfsLocationDialogComponentNgFactory = i1.ɵccf("app-driver-dialog", i11.CfsLocationDialogComponent, View_CfsLocationDialogComponent_Host_0, {}, {}, []);
export { CfsLocationDialogComponentNgFactory as CfsLocationDialogComponentNgFactory };
