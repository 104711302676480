import {BaseEntityService} from '../base/base-entity.service';
import {FilterPageRequest} from '../../modules/shared/models/filter.page.request';
import {PageResult} from '../../modules/shared/models/query-models/page-result';
import {Observable, Subject} from 'rxjs';
import {plainToClass} from 'class-transformer';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from '../http-utils.service';
import {RestService} from '../rest.service';
import {map, share, tap} from 'rxjs/operators';
import {Master} from "../../modules/shared/models";

@Injectable()
export class MasterLineService extends BaseEntityService<Master> {
  protected apiUrl = '/oms/master-line/';
  protected classType = Master;

  public mastersPageSubject = new Subject<PageResult<any>>();
  private lastPageRequest: FilterPageRequest;

  public constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  getMastersByFilterSearchRequest(filterPageRequest: FilterPageRequest, onError?: (error) => void, onSuccess?: (page) => void): Observable<PageResult<Master>> {
    if (!filterPageRequest) {
      return;
    }

//    console.time('Page Request');
    this.lastPageRequest = filterPageRequest;
    return this.httpRestService.postWithHeader<PageResult<Master>>(this.apiUrl + 'findByFilters', this.httpUtils.getHTTPHeaders(), filterPageRequest)
      .pipe(
        share(),
        map((response) => {
//          console.timeEnd('Page Request');
//          console.time('Page plainToClass');
          try {
            if (response && response.content) {
              response.content = response.content.map(m => {
                return this.afterLoad(plainToClass(Master, m));
              });
            }
          } catch (e) {
            console.log('plainToClass error', e);
          }
          // console.timeEnd('Page plainToClass');
          return response;
        }),
        tap((response) => {
//          console.time('Subject Next');
          this.mastersPageSubject.next(response);
//          console.timeEnd('Subject Next');
          if (onSuccess) {
            onSuccess(response);
          }
        }, error => {
//          console.timeEnd('Page Request');
          console.log('error', error);
          if (onError) {
            onError(error);
          }
        }));
  }

  public refreshByLastPageRequest() {
    this.getMastersByFilterSearchRequest(this.lastPageRequest);
  }


}
