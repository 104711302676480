import { OnInit } from '@angular/core';
import { FilterPageRequest } from "../../../../../modules/shared/models/filter.page.request";
import { FreightForwarderGroupService } from "../../../../../services/freight.forwarder.group.service";
import { CustomerService } from "../../../../../services/customer.service";
import { FreightForwarderService } from "../../../../../services/freight.forwarder.service";
import { isNullOrUndefined } from "util";
var PendingRecoveryReportFiltersComponent = /** @class */ (function () {
    function PendingRecoveryReportFiltersComponent(freightForwarderGroupService, customerService, freightForwarderService) {
        this.freightForwarderGroupService = freightForwarderGroupService;
        this.customerService = customerService;
        this.freightForwarderService = freightForwarderService;
        this.filterOptions = [];
        this.freightForwarder = [];
    }
    PendingRecoveryReportFiltersComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('Loaded', this.filters);
        if (this.filters) {
            for (var _i = 0, _a = this.filters.filterByColumns; _i < _a.length; _i++) {
                var filter = _a[_i];
                if (filter) {
                    console.log('FOUND', filter.field);
                    if (filter.field === 'customerId') {
                        this.customerService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (customer) { return _this.customer = customer; });
                    }
                    if (filter.field === 'freightForwarderId') {
                        if (filter.multipleValues) {
                            this.freightForwarderService.getMany(filter.values.map(function (s) { return Number(s); })).subscribe(function (ff) { return _this.freightForwarder = ff; });
                        }
                    }
                }
            }
            var _loop_1 = function (options) {
                var filter = this_1.filters.getColumnFilter(options.field);
                if (filter) {
                    if (!isNullOrUndefined(filter.value)) {
                        options.search.search = filter.value;
                        if (options.search.items) {
                            options.search.selected = options.search.items.find(function (item) { return item.id === filter.value; });
                        }
                    }
                    else if (filter.multipleValues) {
                        if (options.search.items) {
                            options.search.selected =
                                filter.multipleValues.map(function (id) { return options.search.items.find(function (item) { return item.id === ~~id; }); });
                        }
                    }
                }
            };
            var this_1 = this;
            for (var _b = 0, _c = this.filterOptions; _b < _c.length; _b++) {
                var options = _c[_b];
                _loop_1(options);
            }
        }
    };
    PendingRecoveryReportFiltersComponent.prototype.onCustomerChange = function () {
        if (this.customer && this.customer.length) {
            this.filters.addColumnFilter('customerId', this.customer.map(function (c) { return c.id; }));
        }
        else {
            this.filters.removeColumnFilter('customerId');
        }
    };
    PendingRecoveryReportFiltersComponent.prototype.onFreightForwarderChange = function () {
        if (this.freightForwarder && this.freightForwarder.length) {
            this.filters.addColumnFilter('freightForwarderId', this.freightForwarder.map(function (ff) { return ff.id; }));
        }
        else {
            this.filters.removeColumnFilter('freightForwarderId');
        }
        console.log('>>>', this.filters);
    };
    return PendingRecoveryReportFiltersComponent;
}());
export { PendingRecoveryReportFiltersComponent };
