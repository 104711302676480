var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { AbstractComponent } from "../../../../../common/component/abstract.component";
import { MasterStatus, MasterStatusId } from "../../../../shared/models";
import { OrderSubscriptionService } from "../../../../../services/order-subscription/order-subscription.service";
import { OmsAlertsService } from "../../../../shared/components/oms-alerts/oms-alerts.service";
import { forkJoin } from "rxjs";
import { isNullOrUndefined } from "util";
import { OrderModes } from "../../../../shared/models/order/order-mode";
import { ArrayUtils } from "../../../../../_helpers/array.utils";
import { StringUtil } from "../../../../../_helpers/string.util";
import { openMailTo } from "../../../../../_helpers/utils";
import { OmsDialogsService } from "../../../../../components/common/oms-dialogs";
var OrderSubscriptionDialogComponent = /** @class */ (function (_super) {
    __extends(OrderSubscriptionDialogComponent, _super);
    function OrderSubscriptionDialogComponent(dialogRef, data, cdr, orderSubscriptionService, dialogs, alerts) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.cdr = cdr;
        _this.orderSubscriptionService = orderSubscriptionService;
        _this.dialogs = dialogs;
        _this.alerts = alerts;
        _this.statusesColumns = [];
        _this.addStatuses = [];
        _this.orderSubscriptions = [];
        _this.customSubscriptions = [];
        _this.comment = "";
        _this.customEmail = "";
        _this.needCustomEmail = false;
        _this.isPublic = false;
        _this.placeholder = "";
        _this.viewLoading = false;
        return _this;
    }
    OrderSubscriptionDialogComponent.prototype.getAiStatuses = function () {
        return MasterStatus.items.filter(function (s) { return s.id !== MasterStatusId.CLOSED; });
    };
    OrderSubscriptionDialogComponent.prototype.getNonAiStatuses = function () {
        return [
            MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH,
            MasterStatusId.DISPATCHED_FOR_JJS_DELIVERY,
            MasterStatusId.PROBLEM,
            MasterStatusId.PICKED_UP_FROM_JJS,
            MasterStatusId.DELIVERED_PENDING_POD,
            MasterStatusId.ORDER_COMPLETE_READY_TO_BILL
        ].map(function (id) { return MasterStatus.get(id); });
    };
    OrderSubscriptionDialogComponent.prototype.ngOnInit = function () {
        this.needCustomEmail = this.data.needCustomEmail;
        this.isPublic = this.data.isPublic || false;
        this.load();
    };
    OrderSubscriptionDialogComponent.prototype.load = function () {
        var _this = this;
        this.viewLoading = true;
        this.statusesColumns = [];
        this.addStatuses = [];
        forkJoin([
            this.orderSubscriptionService.findByOrderIds(this.data.orders.map(function (o) { return o.id; })),
            this.orderSubscriptionService.findCustomSubscriptionsByOrderIds(this.data.orders.map(function (o) { return o.id; })),
            this.orderSubscriptionService.getAvailableStatuses()
        ])
            .subscribe(function (responses) {
            _this.orderSubscriptions = responses[0];
            _this.customSubscriptions = responses[1];
            _this.initStatuses(responses[2]);
            _this.initNameField();
            console.log('SUBSCRIPTIONS', _this.orderSubscriptions);
            _this.getAllStatuses().forEach(function (status) {
                var originalStatus = MasterStatus.items.find(function (s) { return s.id === status.id; });
                if (_this.orderSubscriptions.length === _this.data.orders.length
                    && _this.orderSubscriptions.every(function (os) { return os.statuses.includes(originalStatus); })) {
                    status.selected = true;
                }
                else if (_this.orderSubscriptions.every(function (os) { return !os.statuses.includes(originalStatus); })) {
                    status.selected = false;
                }
            });
            _this.addStatuses.forEach(function (status) {
                if (_this.orderSubscriptions.length === _this.data.orders.length
                    && _this.orderSubscriptions.every(function (os) { return os.additionalStatuses.some(function (as) { return as.id === status.id; }); })) {
                    status.selected = true;
                }
                else if (_this.orderSubscriptions.every(function (os) { return !os.additionalStatuses.some(function (as) { return as.id === status.id; }); })) {
                    status.selected = false;
                }
            });
            _this.viewLoading = false;
            _this.cdr.markForCheck();
        }, function (error) {
            _this.viewLoading = false;
        });
    };
    OrderSubscriptionDialogComponent.prototype.initStatuses = function (orderSubscriptionStatuses) {
        var hasAiOrder = this.data.orders.some(function (o) { return OrderModes.isAirImport(o.genericMode); });
        var statuses = hasAiOrder ? this.getAiStatuses() : this.getNonAiStatuses();
        var chunk = Math.ceil(statuses.length / 3);
        for (var i = 0; i < 3; i++) {
            var columns = statuses.slice(i * chunk, (i + 1) * chunk).map(function (s) { return MasterStatus.clone(s); });
            this.statusesColumns.push(columns);
        }
        if (hasAiOrder) {
            this.addStatuses = orderSubscriptionStatuses;
        }
    };
    OrderSubscriptionDialogComponent.prototype.initNameField = function () {
        var hasComment = this.orderSubscriptions.some(function (os) { return !!os.comment; });
        var comments = this.orderSubscriptions.map(function (os) { return os.comment; }).filter(function (c) { return !!c; });
        var hasDifferentComment = ArrayUtils.removeDuplicate(comments, function (c) { return c; }).length > 1;
        if ((this.data.orders.length !== this.orderSubscriptions.length && hasComment) || hasDifferentComment) {
            this.placeholder = 'Various';
            return;
        }
        if (comments.length) {
            this.comment = comments[0];
        }
    };
    OrderSubscriptionDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    OrderSubscriptionDialogComponent.prototype.onSave = function () {
        var _this = this;
        this.viewLoading = true;
        var ors = this.data.orders.map(function (order) {
            var orderSubscription = _this.orderSubscriptions.find(function (os) { return os.order.id === order.id; });
            if (!orderSubscription) {
                orderSubscription = { order: order, statuses: [] };
            }
            var indeterminateStatuses = _this.getIndeterminateStatus();
            var statusIds = orderSubscription.statuses.map(function (s) { return s.id; });
            orderSubscription.statuses = _this.getAvailableStatusByOrder(order, _this.getSelectedStatus().concat(indeterminateStatuses.filter(function (indeterminateStatus) { return statusIds.includes(indeterminateStatus.id); })));
            orderSubscription.additionalStatuses = _this.addStatuses.filter(function (s) { return s.selected; });
            if (_this.comment) {
                orderSubscription.comment = _this.comment;
            }
            return orderSubscription;
        });
        var emails = StringUtil.split(this.customEmail).concat(this.customSubscriptions.map(function (os) { return os.customEmail; })).filter(function (s) { return !!s; }).unique().join(", ");
        console.log('Custom Emails', emails);
        this.orderSubscriptionService.saveOrderSubscription(ors, emails)
            .subscribe(function (a) {
            _this.viewLoading = false;
            _this.alerts.info("Flag added", 20000);
            _this.dialogRef.close(_this.getAllStatuses());
        }, function (error) {
            _this.viewLoading = false;
            _this.alerts.error("Error on subscribe", error);
        });
    };
    OrderSubscriptionDialogComponent.prototype.getAvailableStatusByOrder = function (order, statuses) {
        if (OrderModes.isAirImport(order.genericMode)) {
            return statuses;
        }
        var listIds = this.getNonAiStatuses().map(function (s) { return s.id; });
        return statuses.filter(function (s) { return listIds.includes(s.id); });
    };
    OrderSubscriptionDialogComponent.prototype.isSelectAll = function () {
        return this.getAllStatuses().length === this.getSelectedStatus().length
            && this.addStatuses.every(function (s) { return s.selected; });
    };
    OrderSubscriptionDialogComponent.prototype.isSelectStatus = function (status) {
        return status.selected;
    };
    OrderSubscriptionDialogComponent.prototype.isIndeterminateStatus = function (status) {
        return isNullOrUndefined(status.selected);
    };
    OrderSubscriptionDialogComponent.prototype.isIndeterminateSelectAll = function () {
        var isSelectAll = this.isSelectAll();
        var isNoSelectAll = this.getAllStatuses().every(function (s) { return !s.selected; }) && this.addStatuses.every(function (s) { return !s.selected; });
        return !isSelectAll && !isNoSelectAll;
    };
    OrderSubscriptionDialogComponent.prototype.onSelectAll = function () {
        var isSelectAll = this.isSelectAll();
        this.getAllStatuses().forEach(function (s) { return s.selected = !isSelectAll; });
        this.addStatuses.forEach(function (s) { return s.selected = !isSelectAll; });
    };
    OrderSubscriptionDialogComponent.prototype.onStatusChange = function (value, status) {
        status.selected = value;
    };
    OrderSubscriptionDialogComponent.prototype.getIndeterminateStatus = function () {
        var _this = this;
        return this.getAllStatuses().filter(function (s) { return _this.isIndeterminateStatus(s); });
    };
    OrderSubscriptionDialogComponent.prototype.getSelectedStatus = function () {
        return this.getAllStatuses().filter(function (s) { return s.selected; });
    };
    OrderSubscriptionDialogComponent.prototype.getStatusLabel = function (status) {
        if (status.id === MasterStatusId.DELIVERED_PENDING_POD) {
            return "3.b. Delivered, pending POD";
        }
        else if (status.id === MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH) {
            return "2.c. On Hand, Ready for Dispatch";
        }
        return status.label;
    };
    OrderSubscriptionDialogComponent.prototype.getAllStatuses = function () {
        return this.statusesColumns.reduce(function (total, column) { return total.concat(column); }, []);
    };
    OrderSubscriptionDialogComponent.prototype.canSave = function () {
        return (!this.needCustomEmail || this.customEmail) &&
            (this.getSelectedStatus().length > 0 || this.getIndeterminateStatus().length > 0);
    };
    OrderSubscriptionDialogComponent.prototype.removeRecipient = function (rec) {
        this.customSubscriptions.removeAll(rec);
    };
    OrderSubscriptionDialogComponent.prototype.sendEmail = function (rec) {
        var subject = 'REF: ' + this.orderSubscriptions
            .map(function (os) { return os.order; })
            .map(function (o) { return o.customerRef || o.hawb; })
            .join(', ');
        var to = rec ? rec.customEmail : this.customSubscriptions.map(function (cs) { return cs.customEmail; }).join(', ');
        /*    let text;
            let subscription = rec || this.customSubscriptions.first();
            if (subscription && subscription.order && subscription.order.freightForwarder && subscription.order.freightForwarder.freightForwarderGroup) {
              let url = buildCfsStatusPageUrl(subscription.order.freightForwarder.freightForwarderGroup.id, subscription.order.id);
              text = 'Track the progress of your shipment here:%0D%0A' + url;
            }
        
         */
        openMailTo(to, subject, []);
    };
    OrderSubscriptionDialogComponent.prototype.addCustomEmails = function () {
        var _this = this;
        if (this.customEmail) {
            var ids = this.data.orders.map(function (o) { return o.id; });
            this.viewLoading = true;
            console.log("Adding Email", this.customEmail);
            this.orderSubscriptionService.addOrderCustomSubscriptions(ids, this.customEmail)
                .subscribe(function (subscriptions) {
                var added = subscriptions.map(function (s) { return s.customEmail; });
                if (added.length > 0) {
                    _this.dialogs.openInfoDialog(added.length + " Email(s) successfully subscribed:\n" + added.join("\n")).then();
                }
                //          this.customSubscriptions = subscriptions;
                _this.customEmail = '';
                _this.viewLoading = false;
                _this.load();
                //          this.cdr.markForCheck();
            }, function (error) {
                console.error('ERROR subscribe', error);
                _this.viewLoading = false;
                _this.dialogs.openErrorDialog("Server Internal Error", "Error subscribe emails").then();
                _this.cdr.markForCheck();
            });
        }
    };
    return OrderSubscriptionDialogComponent;
}(AbstractComponent));
export { OrderSubscriptionDialogComponent };
