export enum ColumnWidth {
  AUTO_SIZE = 'unset',

  ADDRESS = '150px',
  CARGO_BLDG = '90px',
  DATE = '60px',
  DATE_TIME = '100px',
  FLIGHT = '80px',
  NAME = '120px',
  CHECK_BOX = '50px',
  BUTTON = '50px',
  MAWB = '80px',
  MASTER_NUMBER = '65px',
  WIDE = '450px'
}
