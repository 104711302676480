import {Component, Inject, Input, Optional, ViewChild} from '@angular/core';
import {NgSelectSearchParams} from "../../../../../../settings/util/ng-select-search-params";
import {Carrier} from "../../../../../models/carrier";
import {CarrierService} from "../../../../../services/carrier.service";
import {BaseInputComponent} from "../../../../base/base-input/base-input.component";
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from "@angular/forms";
import {CarrierDialogComponent} from "../../../../../../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {animations} from "../../../../base/base-input/animations";

@Component({
  selector: 'oms-carrier-search',
  animations,
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: CarrierSearchComponent, multi: true}],
})

export class CarrierSearchComponent extends BaseInputComponent<Carrier> {

  @Input() canCreateNew: boolean = false;

  @ViewChild(NgModel) model: NgModel;
  public carrierSearch: NgSelectSearchParams<Carrier>;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
              public carrierService: CarrierService,
              private dialog: MatDialog) {
    super(validators, asyncValidators);
    this.label = 'Carrier';
  }

  ngOnInit() {
    this.carrierSearch = new NgSelectSearchParams<Carrier>(this.carrierService);
  }

  openCreateCarrier() {
    const dialogRef = this.dialog.open<CarrierDialogComponent, any, { res: Carrier, isEdit: boolean }>(CarrierDialogComponent, {
      width: 'auto'
    });
    dialogRef.componentInstance.carrier = null;
    dialogRef.componentInstance.displayInHouse = false;
    return dialogRef.afterClosed()
      .subscribe(response => {
        this.value = response.res;
      });
  }

}
