﻿import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Address, City, Load, Order, Shipment} from '../../../modules/shared/models';
import {ShipmentService} from "../../../services/shipment.service";
import {AddressService} from "../../../services";
import {ShipmentContent} from "../../../modules/shared/models/shipment.content";
import {OmsConstants} from "../../../common/oms-constants.service";
import {isNullOrUndefined} from "util";


@Component({templateUrl: 'load-dialog.component.html',
            styleUrls: ['./load-dialog.component.scss'],
            selector: 'oms-load-dialog'})
/**
 * @deprecated
 **/
export class LoadDialogComponent implements OnInit {

  orders: Order[];
  edit = false;
  addressPickUp: Address;
  masterShipment: Shipment;
  addressDelivery: Address;
  total:any = {};

  public form: FormGroup;

  recoveryLoad: boolean;

  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;

  constructor(
    public _constants: OmsConstants,
    public dialogRef: MatDialogRef<LoadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public addressService: AddressService,
              private fb: FormBuilder,
              private shipmentService: ShipmentService,
) {
    this.addressService.getAllAddresses();
  }

  ngOnInit() {
    this.viewLoading = true;

    this.orders = this.data.orders;
    this.recoveryLoad = this.data.recovery;
    this.findMaster();
    this.createForm();
    this.calculateTotal();
    this.viewLoading = false;
  }

  findMaster(){
    this.masterShipment = this.orders[0].shipmentContents[0].shipment;
    this.addressPickUp = this.masterShipment.addressDelivery;
  }

  createForm() {
    this.form = this.fb.group({
      datePickUpExpectation: ["", Validators.required],
      datePickUpActual: ["", Validators.required],
/*
      addressPickUp: [""],
*/
      dateDeliveryExpectation: ["", Validators.required],
      dateDeliveryActual: ["", Validators.required],
      addressDelivery: [""],
      shipmentLoads: this.fb.array(this.createShipmentLoadFields())
    });
  }

  createShipmentLoadFields() {
    let shipmentLoadFields = [];
    this.orders.forEach(order => {
      let fieldSet = {
        order: order,
        id: order.id,
        client: order.customer.name,
        availablePieces: order.availablePieces,
        pieces: order.availablePieces,
        weight: '',
        volume: ''
      };
      shipmentLoadFields.push(this.fb.group(fieldSet));
    });

    return shipmentLoadFields;
  }

  getTitle(): string {
    return 'Create Load';
  }

  deleteItem(arrayName: string, index: number) {
    let formArray = <FormArray>this.form.get(arrayName);
    formArray.removeAt(index);
  }


  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }



  onSubmit() {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;
    const controls = this.form.controls;
    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const shipment = this.prepare();
/*    if (shipment.id > 0) {
      this.update(shipment);
    } else {
      this.create(shipment);
    }*/
  }

  prepare(): Shipment {
    const controls = this.form.controls;
    const _shipment = new Shipment();
    _shipment.load = new Load();
    _shipment.shipmentContents = this.prepareShipmentContents(controls['shipmentLoads'].value, _shipment);
    _shipment.addressPickUp = this.addressPickUp;
    _shipment.addressDelivery = this.addressDelivery;
    _shipment.datePickUpExpectation = controls['datePickUpExpectation'].value;
    _shipment.datePickUpActual = controls['datePickUpActual'].value;
    _shipment.dateDeliveryExpectation = controls['dateDeliveryExpectation'].value;
    _shipment.dateDeliveryActual = controls['dateDeliveryActual'].value;
    return _shipment;
  }

  prepareShipmentContents(objects: any[], shipment: Shipment): any[] {
    let childItems = [];
    objects.forEach(object => {
      let objectForCreation = new ShipmentContent();
      objectForCreation.order = object.order;
    //  objectForCreation.shipment = object.shipment;
      objectForCreation.pieces = object.pieces;
      childItems.push(objectForCreation)
    });
    return childItems;
  }

  calculateTotal(){
    const controls = this.form.controls;
    let total = {quantity:0, weight:0, volume:0};
    const shipmentLoads = controls['shipmentLoads'].value;
    shipmentLoads.forEach(object => {
      total.quantity += this.getIntegerFromString(object.pieces);
      total.weight += this.getIntegerFromString(object.weight);
      total.volume += this.getIntegerFromString(object.volume);
    });
    this.total = total;
  }

  getIntegerFromString(value){
    return isNullOrUndefined(value) ? 0 : Number(value);
  }

  update(_shipment: Shipment) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.shipmentService.update(_shipment).subscribe(res => {
      /* Server loading imitation. Remove this on real code */
      this.viewLoading = false;
      this.dialogRef.close({
        res,
        isEdit: true
      });
    });
  }



  create(_shipment: Shipment) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.shipmentService.create(_shipment).subscribe(res => {
      this.viewLoading = false;
      this.dialogRef.close({
        res,
        isEdit: false
      });
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }


}
