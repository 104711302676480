var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material";
import { CustomerService } from "../../../../services/customer.service";
import { SplitOrderDialogComponent } from "../../../dispatch/pages/manifest-create/split-order-dialog/split-order-dialog.component";
import { NgSelectSearchParams } from "../../../settings/util/ng-select-search-params";
import { OmsAlertsService } from "../../../shared/components/oms-alerts/oms-alerts.service";
import { AbstractEntityListComponent } from "../../../settings/pages/abstract-entity-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { OmsColumns } from "../../../../common/oms-columns.service";
import { FileUploadService } from '../../../../services/file.upload.service';
import { OmsDialogsService } from "../../../../components/common/oms-dialogs";
import { MasterStatus, MasterStatusId } from "../../../shared/models";
import { AuthService, OrdersService } from "../../../../services";
import { ColumnType, getDocumentLink } from "../../../../common/column-type";
import { OmsConstants } from "../../../../common/oms-constants.service";
import { MasterService } from "../../../../services/master/master.service";
import { CarrierService } from "../../../shared/services/carrier.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { isNullOrUndefined } from 'util';
import { AddressMapInfoDialogComponent } from "../../../shared/components/address-map-info-dialog/address-map-info-dialog.component";
import { UserVisibilityService } from "../../../shared/services/user-visibility.service";
import { UserVisibilityFilter } from "../../../shared/models/uservisibilityfilter/user-visibility-filter";
import { FilterSearchColumn } from "../../../shared/models/filter.search.column";
import { OrderMode, OrderModes } from "../../../shared/models/order/order-mode";
import { UserService } from "../../../shared/services/user.service";
import { StateService } from "../../../shared/services/state/state.service";
import { ArrayUtils } from "../../../../_helpers/array.utils";
import { ContextMenuComponent } from "ngx-contextmenu";
import { map, takeUntil } from "rxjs/operators";
import { NotesCenterDialogComponent } from "../../../../components/common/notes-center-dialog";
import { ComCenterService } from "../../../../services/com-center.service";
import { ObjectComCenter } from "../../../shared/models/comcenter/object.com.center";
import { forkJoin } from "rxjs";
import { DispatchService } from "../../../shared/services/dispatch/dispatch.service";
import { SelectEntityDialogComponent } from "./select-entity-dialog/select-entity-dialog.component";
import { SelectTextDialogComponent } from "./enter-text-dialog/select-text-dialog.component";
import { CarrierDialogComponent } from "../../../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import { OrderSubscriptionService } from "../../../../services/order-subscription/order-subscription.service";
import { OrderSubscriptionDialogComponent } from "./order-subscription-dialog/order-subscription-dialog.component";
import { UserUtil } from "../../../settings/util/user.util";
import { FileUploadDialogComponent } from "../../../../components/common/file-upload-dialog";
import { Size } from "../../../../common/oms-types";
import { PageResult } from "../../../shared/models/query-models/page-result";
import { cleanSearchString } from "../../../../_helpers/string.util";
var OrdersListComponent = /** @class */ (function (_super) {
    __extends(OrdersListComponent, _super);
    function OrdersListComponent(dialog, cdr, alerts, router, ordersService, dialogs, omsColumns, fileUploadService, constants, masterService, carrierService, spinner, userVisibilityFilterService, authService, stateService, comCenterService, dispatchService, customerService, userService, orderSubscriptionService, route) {
        var _this = _super.call(this, cdr, alerts, ordersService, dialogs, fileUploadService, spinner) || this;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.router = router;
        _this.ordersService = ordersService;
        _this.dialogs = dialogs;
        _this.omsColumns = omsColumns;
        _this.fileUploadService = fileUploadService;
        _this.constants = constants;
        _this.masterService = masterService;
        _this.carrierService = carrierService;
        _this.spinner = spinner;
        _this.userVisibilityFilterService = userVisibilityFilterService;
        _this.authService = authService;
        _this.stateService = stateService;
        _this.comCenterService = comCenterService;
        _this.dispatchService = dispatchService;
        _this.customerService = customerService;
        _this.userService = userService;
        _this.orderSubscriptionService = orderSubscriptionService;
        _this.route = route;
        _this.allOtherOption = _this.createAllOtherOption();
        _this.defaultSortColumn = "id";
        _this.userVisibilityFilters = [];
        _this.statusFilters = _this.createStatusFilters();
        _this.orderModeGroups = _this.createOrderModes();
        _this.startVisibleItem = 0;
        _this.MAX_DISPLAY_ITEMS = 22;
        _this.selectedUserVisibilityFilters = [];
        _this.isDeleteAvailable = false;
        _this.displayStatuses = MasterStatus.items.concat([MasterStatus.VOIDED]);
        _this.availableStatuses = [];
        _this.Size = Size;
        _this.search = { drivers: [] };
        _this.contextMenu = function ( /*row, column?: BaseColumn*/) {
            // if (column && (column.id === ColumnIds.MASTER_ID || column.id === 'id')) {
            return _this.menu;
        };
        _this.paging.pageSize = 100;
        _this.showArchived = true;
        return _this;
    }
    OrdersListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columns = this.buildColumns();
        this.request = this.createDefaultRequest(this.defaultSortColumn, true);
        this.initCanDeleteOrder();
        this.initUserVisibilityFilters().then(function () {
            _this.selectSavedStates();
            _this.updateStatuses();
            var modes = _this.collectOrderModeItems();
            _this.modeColumn.setItems(modes);
            _this.modeColumn.setSelectedItems(modes);
            _this.applyAllHeaderFilters();
        });
        this.currentUser = UserUtil.getCurrentUser();
        this.route.queryParams.subscribe(function (params) {
            var search = new Map();
            if (params.id) {
                search.set('id', params.id);
            }
            if (search.size > 0) {
                setTimeout(function () {
                    _this.table.setSearch(search);
                }, 100);
            }
        });
    };
    OrdersListComponent.prototype.updatePathParams = function () {
        var params = {};
        var search = this.table.getSearch();
        if (search && search.size) {
            search.forEach(function (value, key) {
                params[key] = value;
            });
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
        }).then();
    };
    OrdersListComponent.prototype.updateStatuses = function () {
        var statuses = this.collectStatuses();
        this.orderStatusColumn.setItems(statuses);
        this.orderStatusColumn.setSelectedItems(statuses);
        this.availableStatuses = statuses.slice();
    };
    OrdersListComponent.prototype.selectSavedStates = function () {
        var ordersState = this.stateService.getOrdersState();
        if (!ordersState) {
            return;
        }
        if (ordersState.topUserVisibilityFilters) {
            this.selectedUserVisibilityFilters = this.userVisibilityFilters.filter(function (cf) { return ordersState.topUserVisibilityFilters.includes(cf.id); });
        }
        if (ordersState.modeFilters) {
            this.orderModeGroups.filter(function (om) { return ordersState.modeFilters.includes(om.id); })
                .forEach(function (om) { return om.isSelected = true; });
        }
        if (ordersState.statusFilters) {
            this.statusFilters.forEach(function (sf) { return sf.isSelected = ordersState.statusFilters.includes(sf.statusValue); });
        }
    };
    OrdersListComponent.prototype.initUserVisibilityFilters = function () {
        var _this = this;
        return this.userVisibilityFilterService.find(0, 100).then(function (userVisibilityFilters) {
            if (userVisibilityFilters.content.length) {
                _this.userVisibilityFilters = userVisibilityFilters.content.concat([_this.allOtherOption]);
            }
        });
    };
    OrdersListComponent.prototype.initCanDeleteOrder = function () {
        this.isDeleteAvailable = this.authService.canDeleteOrder();
    };
    OrdersListComponent.prototype.collectOrderModeItems = function () {
        var modes = this.collectSelectedModesFromTop();
        return OrderModes.filterItems.filter(function (fi) { return !modes.length || modes.includes(fi.id); });
    };
    OrdersListComponent.prototype.createOrderModes = function () {
        return [{ id: 0, name: 'AE', tooltip: 'Air Export', modes: [OrderMode.AEDIR, OrderMode.AEMSS] },
            { id: 1, name: 'AI', tooltip: 'Air Import', modes: [OrderMode.AICFS, OrderMode.AIDOM] },
            { id: 2, name: 'FCLE', tooltip: 'FCL Export', modes: [OrderMode.FCLEX] },
            { id: 3, name: 'FCLI', tooltip: 'FCL Import', modes: [OrderMode.FCLIM] },
            { id: 4, name: 'DOM', tooltip: 'Domestic Load', modes: [OrderMode.DOMLD] },
            { id: 5, name: 'RLD', tooltip: 'Recovery orders', modes: [OrderMode.RECOV] },
            { id: 6, name: 'LTL', tooltip: 'LTL', modes: [OrderMode.LTL] },
            { id: 7, name: 'FTL', tooltip: 'FTL', modes: [OrderMode.FTL] },
            { id: 8, name: 'SHUTTLE', tooltip: 'Shuttle', modes: [OrderMode.SHUTTLE] },
        ];
    };
    OrdersListComponent.prototype.createAllOtherOption = function () {
        var filter = new UserVisibilityFilter();
        filter.name = 'All other';
        return filter;
    };
    OrdersListComponent.prototype.applyRemoveVoidedStatus = function (columns) {
        if (columns.some(function (c) { return c.field === 'status'; })) {
            return columns;
        }
        // columns.push(new FilterSearchColumn('status', null, MasterStatus.allStatusesIds.map(s => '' + s)));
        return columns;
    };
    OrdersListComponent.prototype.createStatusFilters = function () {
        return [
            {
                name: 'Pending',
                tooltip: 'Pending',
                statuses: this.getPendingStatus(),
                statusValue: 'pending'
            },
            {
                name: 'Active',
                tooltip: 'Active',
                isSelected: true,
                statuses: this.getActiveStatus(),
                statusValue: 'active'
            },
            {
                name: 'Delivered 30', tooltip: 'delivered in last 30 days',
                statuses: this.getDeliveredStatus(),
                statusValue: 'delivered_30'
            },
            {
                name: 'Delivered 90', tooltip: 'delivered in last 90 days',
                statuses: this.getDeliveredStatus(),
                statusValue: 'delivered_90'
            },
            {
                name: 'Dlvd All',
                tooltip: 'Delivered all',
                statuses: this.getDeliveredStatus(),
                statusValue: 'delivered_all'
            },
            {
                name: 'Deleted',
                tooltip: 'Deleted/Void',
                statuses: this.getDeletedStatus(),
                statusValue: 'deleted'
            }
        ];
    };
    OrdersListComponent.prototype.getPendingStatus = function () {
        return MasterStatus.items.filter(function (ms) { return ms.id <= MasterStatusId.FLIGHT_ARRIVED; });
    };
    OrdersListComponent.prototype.getActiveStatus = function () {
        return MasterStatus.items.filter(function (ms) { return ms.id >= MasterStatusId.RECOVERY_DISPATCHED && ms.id < MasterStatusId.DELIVERED_PENDING_POD; });
    };
    OrdersListComponent.prototype.getDeletedStatus = function () {
        return [MasterStatus.get(MasterStatusId.VOIDED)];
    };
    OrdersListComponent.prototype.getDeliveredStatus = function () {
        return MasterStatus.items.filter(function (ms) { return ms.id >= MasterStatusId.DELIVERED_PENDING_POD; });
    };
    OrdersListComponent.prototype.buildColumns = function () {
        var _this = this;
        var carrierColumn = null;
        var columns = [];
        columns = columns.concat([
            ColumnType.ORDER_JJSM(true),
            this.modeColumn = ColumnType.GENERIC_MODE
                .clone(),
            ColumnType.ORDER_DOC_CENTER(false, this.ordersService, this.dialogs),
            ColumnType.ORDER_OSD,
            ColumnType.ORDER_HAZ,
            ColumnType.ORDER_COM,
            ColumnType.ORDER_CUSTOMER_SEARCH(function (s) { return _this.searchCustomer(s); }),
            ColumnType.ORDER_MAWB(true),
            ColumnType.ORDER_HAWB(false),
            ColumnType.ORDER_CUSTOMER_REF
                .clone()
                .setEditor(false),
            ColumnType.ORDER_PO.clone()
                .setLabel('PO Number')
                .setWidth('100px')
                .setSearch({ searchable: true, search: '', searchFunction: '', baseLineSearch: true })
                .setEditor(false),
            ColumnType.ORDER_FF,
            ColumnType.ORDER_DATE_DELIVERY_REQUESTED,
            ColumnType.ORDER_HU,
            ColumnType.ORDER_PCS(this.constants.piecesMask, this.constants.piecesPlaceholder),
            ColumnType.ORDER_WEIGHT_KG,
            ColumnType.ORDER_RCVD,
            this.orderStatusColumn = ColumnType.ORDER_STATUS([]),
            // pickup location
            ColumnType.ORDER_PICKUP_ADDRESS
                .clone()
                .setHandlers({ onClick: function (o) { return _this.addressInfoPopup(o.addressDelivery); } }),
            ColumnType.ORDER_DELIVERY_LOCATION
                .clone()
                .setHandlers({ onClick: function (o) { return _this.addressInfoPopup(o.addressDelivery); } })
                .setEditor(false),
            ColumnType.ORDER_PICKUP_DATE(this.masterService),
            ColumnType.ORDER_DELIVERY_DATE()
                .setHistory({ logChanges: true }),
            ColumnType.ORDER_DATE_DOCS_TO_WAREHOUSE(),
            carrierColumn = ColumnType.CARRIER
                .clone()
                .setEditor(false),
            ColumnType.ORDER_DELIVERY_DRIVER
                .clone()
                .setSearch({
                searchable: true,
                searchType: 'list',
                multiple: true,
                displayFilter: true,
                items: function (text) { return searchDriver(_this.userService, text); }
            }),
            //        .setSearch(false),
            ColumnType.ORDER_CFS_LOCATION,
            //      ColumnType.LAST_UPDATED_DATE,
            ColumnType.CREATED_BY,
            ColumnType.REQUESTED_BY,
            ColumnType.DATE_CREATED,
            ColumnType.UPDATED_BY,
            ColumnType.LAST_UPDATED_DATE_TIME,
            ColumnType.ORDER_1F_BATTERY,
            ColumnType.ORDER_1C_BATTERY,
            ColumnType.DATE_BILLED
                .clone()
                .setEditor(false)
        ]);
        if (this.authService.getCurrentUser().canCreateCarrier) {
            carrierColumn.setEditor({ select: { createNewHandler: function () { return _this.createCarrierDialog(); } } });
        }
        return columns;
    };
    OrdersListComponent.prototype.navigateToHome = function () {
        this.router.navigate(["/home"]).then();
    };
    OrdersListComponent.prototype.createCarrierDialog = function () {
        var dialogRef = this.dialog.open(CarrierDialogComponent, {
            width: 'auto'
        });
        dialogRef.componentInstance.carrier = null;
        dialogRef.componentInstance.displayInHouse = false;
        return dialogRef.afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .pipe(map(function (response) { return response.res; }));
    };
    OrdersListComponent.prototype.addressInfoPopup = function (address) {
        if (!isNullOrUndefined(address)) {
            this.dialog.open(AddressMapInfoDialogComponent, {
                width: '50%',
                data: { address: address, searchByAddress: true }
            });
        }
    };
    OrdersListComponent.prototype.applyUserVisibilityFilter = function (userVisibilityFilter) {
        if (this.isSelectedUserVisibilityFilter(userVisibilityFilter)) {
            this.selectedUserVisibilityFilters = this.selectedUserVisibilityFilters.filter(function (cf) { return cf !== userVisibilityFilter; });
        }
        else {
            this.selectedUserVisibilityFilters.push(userVisibilityFilter);
        }
        var modes = this.selectedUserVisibilityFilters
            .filter(function (f) { return f.modes; })
            .map(function (f) { return f.modes; })
            .reduce(function (total, innerModes) { return total.concat(innerModes); }, []);
        modes = ArrayUtils.removeDuplicate(modes, function (mode) { return mode.id; });
        if (userVisibilityFilter.modes && userVisibilityFilter.modes.length) {
            this.orderModeGroups.forEach(function (orderMode) {
                orderMode.isSelected = modes.some(function (m) { return orderMode.modes.includes(m.id); });
            });
            this.applyOrderModes();
        }
        else {
            this.applyAllHeaderFilters();
        }
    };
    OrdersListComponent.prototype.isSelectedUserVisibilityFilter = function (userVisibilityFilter) {
        return this.selectedUserVisibilityFilters.some(function (cf) { return cf === userVisibilityFilter; });
    };
    OrdersListComponent.prototype.collectFiltersByCustomer = function () {
        var _this = this;
        var baseFilterColumns = [];
        if (this.selectedUserVisibilityFilters.length) {
            var selectedUserVisibilityFilterIds = this.selectedUserVisibilityFilters.map(function (cf) {
                if (cf === _this.allOtherOption) {
                    return '-1';
                }
                return '' + cf.id;
            });
            baseFilterColumns.push(new FilterSearchColumn('customerTopFilter', null, selectedUserVisibilityFilterIds));
        }
        return baseFilterColumns;
    };
    OrdersListComponent.prototype.collectFiltersByStatusFilter = function () {
        var names = this.statusFilters.filter(function (sf) { return sf.isSelected; })
            .map(function (sf) { return sf.statusValue; });
        return [new FilterSearchColumn('statusFilter', null, names)];
    };
    OrdersListComponent.prototype.clickStatusFilter = function (statusFilter) {
        statusFilter.isSelected = !statusFilter.isSelected;
        this.applyStatusFilters();
    };
    OrdersListComponent.prototype.applyStatusFilters = function () {
        this.updateStatuses();
        this.applyAllHeaderFilters();
    };
    OrdersListComponent.prototype.collectStatuses = function () {
        var selectedStatuses = this.statusFilters.filter(function (sf) { return sf.isSelected; });
        var statuses = (selectedStatuses.length ? selectedStatuses : this.statusFilters)
            .map(function (sf) { return sf.statuses; })
            .reduce(function (total, filters) { return total.concat(filters); }, []);
        return ArrayUtils.removeDuplicate(statuses, function (status) { return status.id; });
    };
    OrdersListComponent.prototype.clickOrderMode = function (orderMode) {
        orderMode.isSelected = !orderMode.isSelected;
        this.applyOrderModes();
    };
    OrdersListComponent.prototype.applyOrderModes = function () {
        var modes = this.collectOrderModeItems();
        this.modeColumn.setItems(modes);
        this.modeColumn.setSelectedItems(modes);
        this.applyAllHeaderFilters();
    };
    OrdersListComponent.prototype.applyAllHeaderFilters = function () {
        var baseFilterColumns = this.collectFiltersByCustomer().concat(this.collectFiltersByStatusFilter());
        var selectedOrderModeIds = this.collectSelectedModesFromTop().map(function (id) { return '' + id; });
        if (selectedOrderModeIds.length) {
            baseFilterColumns.push(new FilterSearchColumn('orderMode', null, selectedOrderModeIds));
        }
        this.applyRemoveVoidedStatus(baseFilterColumns);
        this.setBaseFilterColumns(baseFilterColumns);
        this.table.buildSearchRequest();
        this.updateState();
    };
    OrdersListComponent.prototype.collectSelectedModesFromTop = function () {
        return this.orderModeGroups.filter(function (orderMode) { return orderMode.isSelected; })
            .map(function (orderMode) { return orderMode.modes; })
            .reduce(function (total, items) { return total.concat(items); }, []);
    };
    OrdersListComponent.prototype.prevStep = function () {
        this.startVisibleItem = Math.max(0, this.startVisibleItem - 1);
    };
    OrdersListComponent.prototype.nextStep = function () {
        this.startVisibleItem = Math.min(this.userVisibilityFilters.length - this.MAX_DISPLAY_ITEMS, this.startVisibleItem + 1);
    };
    OrdersListComponent.prototype.refresh = function () {
        this.updateItems();
    };
    OrdersListComponent.prototype.onSearch = function (request) {
        request.setColumnFilter('drivers', (this.search.drivers || []).map(function (d) { return d.id + ''; }));
        //    this.updatePathParams();
        //    console.log('SEARCH', request, this.table.getSearch());
        _super.prototype.onSearch.call(this, request);
    };
    OrdersListComponent.prototype.downloadSelectedOrdersExcel = function () {
        var _this = this;
        if (!this.selected.length) {
            this.alerts.warning("Orders is not selected.", 30000);
            return;
        }
        this.itemService.downloadExcelByIds(this.selected.map(function (s) { return s.id; }), this.alerts)
            .subscribe(function (res) {
            var fileURL = _this.buildShowUrl(res);
            window.open(fileURL, '_blank');
        });
    };
    OrdersListComponent.prototype.deleteOrders = function () {
        var _this = this;
        this.ordersService.softDelete(this.selected.map(function (o) { return o.id; })).then(function () {
            _this.selected = [];
            _this.alerts.info('Orders deleted.', 3000);
            _this.refresh();
        }, function (error) { return _this.alerts.error(error); });
    };
    OrdersListComponent.prototype.updateState = function () {
        this.stateService.saveOrdersState({
            topUserVisibilityFilters: this.selectedUserVisibilityFilters.map(function (c) { return c.id; }),
            modeFilters: this.orderModeGroups.filter(function (c) { return c.isSelected; }).map(function (c) { return c.id; }),
            statusFilters: this.statusFilters.filter(function (c) { return c.isSelected; }).map(function (c) { return c.statusValue; })
        });
    };
    // Update Row Field
    OrdersListComponent.prototype.onUpdate = function (event) {
        if (event.field === 'carrier') {
            this.updateOrder(event.row, { carrier: event.newValue ? event.newValue.id : null }).then();
            return;
        }
        _super.prototype.onUpdate.call(this, event);
    };
    OrdersListComponent.prototype.changeDeliveryDate = function (event) {
        var _this = this;
        var actualDate = this.selected.length === 1 ? this.selected[0].dateDeliveryAct : new Date();
        var estDate = this.selected.length === 1 ? this.selected[0].dateDeliveryReq : new Date();
        this.table.editDateTime(true, actualDate, estDate, true, event)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (newValue) {
            var ids = _this.selected.map(function (o) { return o.id; });
            var actual = newValue.hasOwnProperty('actual');
            var date = actual ? newValue.actual : newValue.estimated;
            _this.spinner.show().then();
            _this.dispatchService.updateOrderDeliveryDate(ids, date, actual).subscribe(function (orders) {
                orders.forEach(function (order) { return _this.items.replaceAll(order, order); });
                _this.items = _this.items.slice();
                _this.spinner.hide().then();
                _this.alerts.info(orders.length + " orders updated.", 30000);
            }, function (error) {
                _this.alerts.error(error, 'Cannot update orders');
                _this.spinner.hide().then();
            });
        });
        this.table.cdr.markForCheck();
    };
    OrdersListComponent.prototype.openOrders = function (currentOrder) {
        var _this = this;
        if (this.selected.length) {
            this.selected.forEach(function (o) { return _this.openOrder(o); });
        }
        else {
            this.openOrder(currentOrder);
        }
    };
    OrdersListComponent.prototype.openMultipleOrders = function (currentOrder) {
        var ids = this.selected.length ? this.selected.map(function (o) { return o.id; }) : [currentOrder.id];
        localStorage.setItem('multipleOrders' + this.currentUser.id, ids.toString());
        window.open('/warehouse/multiple-order', '_blank');
    };
    OrdersListComponent.prototype.openOrder = function (order) {
        var link = getDocumentLink(order);
        var path = window.location.origin + link.path;
        if (link.params && Object.keys(link.params).length) {
            path += "?" + Object.keys(link.params).map(function (key) { return key + "=" + link.params[key]; }).join("&");
        }
        console.log('Trying to open', path);
        window.open(path, "_blank");
    };
    OrdersListComponent.prototype.openCarrierEditor = function () {
        var _this = this;
        var instance = this.dialog.open(SelectEntityDialogComponent, {
            width: '20%',
            data: {}
        });
        instance.componentInstance.label = 'Carrier';
        instance.componentInstance.entity = null;
        instance.componentInstance.selectSearch = new NgSelectSearchParams(this.carrierService);
        instance.afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (carrier) {
            _this.selected.forEach(function (order) {
                _this.updateOrder(order, { carrier: carrier ? carrier.id : null }).then();
            });
        });
    };
    OrdersListComponent.prototype.openCustomerEditor = function () {
        var _this = this;
        var instance = this.dialog.open(SelectEntityDialogComponent, {
            width: '20%',
            data: {}
        });
        instance.componentInstance.label = 'Customer';
        instance.componentInstance.entity = null;
        instance.componentInstance.selectSearch = new NgSelectSearchParams(this.customerService);
        instance.afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (customer) {
            _this.selected.forEach(function (order) {
                _this.updateOrder(order, { customer: customer ? customer.id : null }).then();
            });
        });
    };
    OrdersListComponent.prototype.openChangeRefEditor = function () {
        var _this = this;
        this.openTextEditor("Customer Ref")
            .subscribe(function (customerRef) {
            _this.selected.forEach(function (order) {
                _this.updateOrder(order, { customerRef: customerRef }).then();
            });
        });
    };
    OrdersListComponent.prototype.openChangeRef2Editor = function () {
        var _this = this;
        this.openTextEditor("Ref2")
            .subscribe(function (hawb) {
            _this.selected.forEach(function (order) {
                _this.updateOrder(order, { hawb: hawb }).then();
            });
        });
    };
    OrdersListComponent.prototype.openChangeRef3Editor = function () {
        var _this = this;
        this.openTextEditor("Ref3")
            .subscribe(function (ref3) {
            _this.selected.forEach(function (order) {
                _this.updateOrder(order, { ref3: ref3 }).then();
            });
        });
    };
    OrdersListComponent.prototype.openChangeDescriptionOfGoods = function () {
        var _this = this;
        this.openTextEditor("Description of goods")
            .subscribe(function (desc) {
            _this.selected.forEach(function (order) {
                _this.updateOrder(order, { descriptionOfGoods: desc }).then();
            });
        });
    };
    OrdersListComponent.prototype.openTextEditor = function (label) {
        var instance = this.dialog.open(SelectTextDialogComponent, {
            width: '20%',
            data: {}
        });
        instance.componentInstance.dialogLabel = 'Enter ' + label;
        instance.componentInstance.label = label;
        return instance.afterClosed()
            .pipe(takeUntil(this.unsubscribe$));
    };
    OrdersListComponent.prototype.updateOrder = function (order, fields) {
        var _this = this;
        return this.ordersService.updateOrder(order.id, fields).then(function (res) {
            var newOrder = res[0];
            _this.items.replaceAll(order, newOrder);
            _this.items = _this.items.slice();
            return newOrder;
        });
    };
    OrdersListComponent.prototype.openDocumentUploader = function () {
        var _this = this;
        var ids = this.selected.map(function (order) { return order.id; });
        this.dialog.open(FileUploadDialogComponent, { disableClose: true, width: 'auto', data: { id: ids, objectType: 'ORDER', noDescription: true } })
            .afterClosed().subscribe(function () { return _this.refresh(); });
    };
    OrdersListComponent.prototype.openCommentCenter = function () {
        var _this = this;
        var instance = this.dialog.open(NotesCenterDialogComponent, {
            width: '80%',
            data: {
                id: null,
                objectType: 'ORDER',
                noteTypeId: 0,
                readonly: false
            }
        });
        instance.componentInstance.saveComment = function (com, selectedFiles) {
            return forkJoin(_this.selected.map(function (order) {
                var copy = Object.assign(new ObjectComCenter(), com);
                copy.objectId = order.id;
                return _this.saveComment(copy, selectedFiles);
            }));
        };
    };
    OrdersListComponent.prototype.splitOrder = function () {
        var _this = this;
        var order = this.selected[0];
        this.dialogs.openDialog(SplitOrderDialogComponent, {
            order: order,
            onOk: function (newPcs, newHu, newWeight) {
                order.pieces = newPcs;
                order.hu = newHu;
                order.weight = newWeight;
                _this.refresh();
                _this.cdr.markForCheck();
            }
        })
            .then();
    };
    OrdersListComponent.prototype.recalculateStatus = function () {
        var _this = this;
        this.selected.forEach(function (o) { return _this.updateOrder(o, {}); });
    };
    OrdersListComponent.prototype.saveComment = function (com, selectedFiles) {
        if (selectedFiles) {
            return this.comCenterService.createWithDocument(com, selectedFiles);
        }
        return this.comCenterService.createObservable(com);
    };
    OrdersListComponent.prototype.isSelectedOnlyNoAI = function () {
        return this.selected.length && !this.selected.some(function (o) { return OrderModes.isAirImport(o.genericMode); });
    };
    OrdersListComponent.prototype.generateBOL = function (isOpenPDF) {
        var _this = this;
        var ids = this.selected.map(function (oi) { return oi.id; });
        this.ordersService.generateBOLs(ids).subscribe(function (response) {
            _this.generateBOLOpenPDF(response, isOpenPDF);
        });
    };
    OrdersListComponent.prototype.printBOL = function () {
        var _this = this;
        var ids = this.selected.map(function (oi) { return oi.id; });
        this.ordersService.printBOLs(ids).subscribe(function (response) {
            _this.findBOLOpenPDF(response);
        });
    };
    OrdersListComponent.prototype.findBOLOpenPDF = function (response) {
        if (!isNullOrUndefined(response)) {
            this.ordersService.openBase64PDF(response);
            this.alerts.success('Find BOLs successful', 5000);
        }
        else {
            this.alerts.error('No BOL available');
        }
    };
    OrdersListComponent.prototype.generateBOLOpenPDF = function (response, isOpenPDF) {
        if (!isNullOrUndefined(response)) {
            if (isOpenPDF) {
                this.ordersService.openBase64PDF(response);
            }
            this.alerts.success('Generate BOL successful', 5000);
        }
        else {
            this.alerts.error('Error generate BOL');
        }
    };
    OrdersListComponent.prototype.addFlag = function () {
        var dialogRef = this.dialog.open(OrderSubscriptionDialogComponent, { width: 'auto', data: { orders: this.selected } });
        dialogRef.afterClosed().subscribe(function () {
        });
    };
    OrdersListComponent.prototype.removeFlag = function () {
        var _this = this;
        this.orderSubscriptionService.deleteByOrders(this.selected.map(function (o) { return o.id; }))
            .subscribe(function () { return _this.alerts.info("Flag removed", 20000); });
    };
    OrdersListComponent.prototype.toggleStatusWithDelay = function (masterStatus) {
        var _this = this;
        var statuses = this.getSelectedStatusesByColumn();
        if (this.isSelectedMasterStatus(masterStatus)) {
            statuses = statuses.filter(function (i) { return i !== masterStatus; });
        }
        else {
            statuses = statuses.concat([masterStatus]);
        }
        this.orderStatusColumn.setSelectedItems(statuses);
        if (this.statusToggleTimer) {
            clearTimeout(this.statusToggleTimer);
            this.statusToggleTimer = null;
        }
        this.statusToggleTimer = setTimeout(function () {
            _this.applyAllHeaderFilters();
        }, 1500);
    };
    OrdersListComponent.prototype.isSelectedMasterStatus = function (masterStatus) {
        return this.getSelectedStatusesByColumn().includes(masterStatus);
    };
    OrdersListComponent.prototype.getSelectedStatusesByColumn = function () {
        return this.orderStatusColumn.getSelectedItems();
    };
    OrdersListComponent.prototype.getSearchText = function () {
        return this.request.findByOccurs;
    };
    OrdersListComponent.prototype.setSearchText = function (s) {
        this.request.findByOccurs = cleanSearchString(s);
    };
    OrdersListComponent.prototype.searchCustomer = function (search) {
        var request = this.table.buildSearchRequest(false);
        request.pageSize = 9999;
        request.pageNumber = 1;
        console.log('searchCustomer:', search);
        return this.ordersService.findCustomersOfSelectedOrders(search, request);
    };
    return OrdersListComponent;
}(AbstractEntityListComponent));
export { OrdersListComponent };
function searchDriver(userService, searchText) {
    return userService.findUsersByRoles(['ROLE_DRIVER'], true, searchText).pipe(map(function (response) {
        return PageResult.fromArray(response.content.filter(function (user) { return user.driver; }).map(function (user) { return user.driver; }));
        // response.content.forEach(driver => driver['label'] = driver.fullName);
    }));
}
