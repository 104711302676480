import {Component, OnInit, Output, EventEmitter, Inject, Input} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {OrderDispatch} from "../../../modules/shared/models/dispatch/order-dispatch";
import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {ReceivingService} from "../../../services/receiving.service";
import {OrderWarehouseService} from "../../../services/order-warehouse.service";
import {isNullOrUndefined} from "util";
import {WebDriversService} from "../../../services/web-drivers.service";
import {LoggedUser} from "../../../modules/shared/models/logged-user";
import {UserUtil} from "../../../modules/settings/util/user.util";
import {Master, Part} from "../../../modules/shared/models";
import {ModalResult} from "../../common/oms-dialogs";


export interface CameraDialogData {
  title?: string;

  master?: Master; // deprecated
  masterId?: number;
  orderId?: number;
  isSaveToMaster?: boolean;
  docTypeList?: string[];
  defaultDocType?: string;
  orderDispatches?: OrderDispatch[]; // deprecated
  receivingPart?: Part; // deprecated
}

@Component({
  selector: 'oms-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
/** @deprecated **/
export class VideoDialogComponent implements OnInit {
  @Output()
  public pictureTaken = new EventEmitter<WebcamImage>();

  @Input() public masterId: number;
  @Input() public orderId: number;

  docTypeList: string[] = [];
  orderDispatches: OrderDispatch[] = [];
  viewLoading = false;
  loadingAfterSubmit = false;
  public title = 'Take Picture';
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public images: WebcamImage[] = [];
  public currentImage: WebcamImage;
  public mobHeight: any;
  public mobWidth: any;
  public videoOptions: MediaTrackConstraints = {
    width: { min: 640, ideal: 2048/*1920*/, max: 2048/*4000*/},
    height: { min: 480, ideal: 1536/*1080*/, max: 1536/*3000*/},
    facingMode: "environment"
  };
  public errors: WebcamInitError[] = [];
  public docType: string = null;
  public isSaveToMaster = false;
  currentUser: LoggedUser;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(public dialogRef: MatDialogRef<VideoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CameraDialogData,
              private alerts: OmsAlertsService,
              private receivingService: ReceivingService,
              private webDriversService: WebDriversService,
              private orderWarehouseService: OrderWarehouseService) {
    this.mobHeight = window.innerHeight - window.innerHeight / 5;
    this.mobWidth = window.innerWidth;
  }


  public ngOnInit(): void {
    this.title = this.data && this.data.title || this.title;
    this.showWebcam = true;
    this.orderId = this.data && (this.data.orderId || (this.data.receivingPart && this.data.receivingPart.order && this.data.receivingPart.order.id));
    this.masterId = this.data && (this.data.masterId || (this.data.master && this.data.master.id));
    this.isSaveToMaster = this.data.isSaveToMaster;
    console.log('SAVE TO MASTER', this.isSaveToMaster);
    this.docTypeList = this.data.docTypeList;
    this.docType = this.data.defaultDocType || this.docTypeList[0];
    this.orderDispatches = this.data.orderDispatches;

    console.log('DOC TYPES:', this.docTypeList);

    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        this.viewLoading = false;
      });
    this.currentUser = UserUtil.getCurrentUser();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.showWebcam = false;
  }

  public onSubmit(): void {
    if (!isNullOrUndefined(this.docType)) {
      if (this.docType.indexOf('WHSE') > -1 && (isNullOrUndefined(this.orderDispatches) || this.orderDispatches.length === 0)) {
        this.saveWarehousePicture();
      } else {
        this.savePicture();
      }
    } else {
      this.alerts.warning('Document type not selected');
    }
  }

  public onClear(): void {
    if (this.currentImage) {
      this.showWebcam = !this.showWebcam;
      this.images.removeAll(this.currentImage);
      this.currentImage = undefined;
    }
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(image: WebcamImage): void {
    console.info('received webcam image', image);
    this.images.push(image);
    this.currentImage = image;
    this.pictureTaken.emit(image);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  closeDialog(result: any) {
    this.dialogRef.close(result);
  }

  public hasImages(): boolean {
    return this.images && this.images.length > 0;
  }

  public getImagesAsData(): string[] {
    return this.hasImages() ? this.images.map((i) => i.imageAsDataUrl) : [];
  }

  public savePicture(): void {
    if (!this.hasImages()) {
      return;
    }

    if (!isNullOrUndefined(this.orderDispatches) && this.orderDispatches.length > 0) {
      this.viewLoading = true;
      this.webDriversService.savePictureOld(this.orderDispatches, this.docType, this.getImagesAsData())
        .then(() => {
          this.alerts.success('The image was successfully saved');
          this.viewLoading = false;
          this.closeDialog(ModalResult.OK);
        })
        .catch((error) => {
          this.saveLocalStorageData();
          this.viewLoading = false;
          this.alerts.warning( "Cannot access server, data are stored will be sent later", 3000);
          this.closeDialog(ModalResult.CANCEL);
        });
    } else {
      this.viewLoading = true;
      this.receivingService.savePicture(this.orderId, this.masterId, this.docType, this.getImagesAsData())
        .then(() => {
          this.alerts.success('The image was successfully saved');
          this.viewLoading = false;
          this.closeDialog(ModalResult.OK);
        })
        .catch((error) => {
          this.viewLoading = false;
          this.alerts.error(error, 'Image saving error');
        });
    }
  }

  /** @deprecated **/
  public saveWarehousePicture(): void {
    if (this.isSaveToMaster && this.masterId) {
      this.viewLoading = true;
      this.orderWarehouseService.savePictureByMasterId(this.masterId, 'WHSE', this.getImagesAsData()).then(() => {
        this.alerts.success('The image was successfully saved');
        this.viewLoading = false;
        this.closeDialog(ModalResult.OK);
      }).catch((error) => {
        this.viewLoading = false;
        this.saveLocalStorageData();
        this.alerts.warning( "Cannot access server, data are stored will be sent later", 3000);
        this.closeDialog(ModalResult.CANCEL);
      });
    } else if (this.orderId) {
      this.viewLoading = true;
      this.orderWarehouseService.savePictureByOrderId(this.orderId, 'WHSE', this.getImagesAsData()).then(() => {
        this.alerts.success('The image was successfully saved');
        this.viewLoading = false;
        this.closeDialog(ModalResult.OK);
      }).catch((error) => {
        this.saveLocalStorageData();
        this.viewLoading = false;
        this.alerts.warning( "Cannot access server, data are stored will be sent later", 3000);
        this.closeDialog(ModalResult.CANCEL);
      });
    }
  }

  docTypeChanged(docType: string) {
    if (!isNullOrUndefined((docType))) {
      this.docType = docType;
    }
  }

  saveLocalStorageData() {
    let user = this.currentUser;
    if (user) {
      let storageAmount = localStorage.getItem('savePictureAmount' + user.id);

      // tslint:disable-next-line:radix
      let amount = isNullOrUndefined(storageAmount) ? 1 : parseInt(storageAmount) + 1;
      localStorage.setItem('savePictureAmount' + user.id, amount.toString());
      localStorage.setItem('savePictureLoads' + user.id + "_" + amount, JSON.stringify(this.orderDispatches));
      localStorage.setItem('savePictureDocType' + user.id + "_" + amount, this.docType);
      for (let i = 0; i < this.images.length; i++) {
        localStorage.setItem(`savePictureImages${user.id}_${i}_${amount}`, this.images[i].imageAsDataUrl);
      }
      if (this.isSaveToMaster) {
        localStorage.setItem('savePictureToMaster' + user.id + "_" + amount, "true");
      }
    }
  }
}
