var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { OmsAlertsService } from '../../../../../../shared/components/oms-alerts/oms-alerts.service';
import { AbstractEntityDialogComponent } from '../../../../abstract-entity-dialog.component';
import { Carrier } from '../../../../../../shared/models/carrier';
import { CarrierService } from '../../../../../../shared/services/carrier.service';
var CarrierDialogComponent = /** @class */ (function (_super) {
    __extends(CarrierDialogComponent, _super);
    function CarrierDialogComponent(dialogRef, fb, carrierService, alerts) {
        var _this = _super.call(this, dialogRef, carrierService, alerts) || this;
        _this.dialogRef = dialogRef;
        _this.fb = fb;
        _this.carrierService = carrierService;
        _this.alerts = alerts;
        _this.displayInHouse = false;
        return _this;
    }
    CarrierDialogComponent.prototype.ngOnInit = function () {
        this.carrier = this.carrier || new Carrier();
        this.createForm();
    };
    CarrierDialogComponent.prototype.ngOnChanges = function (changes) {
        if (changes.carrier) {
            this.carrier = this.carrier
                ? Object.assign(new Carrier(), this.carrier)
                : new Carrier();
            this.edit = !this.carrier.isNew();
        }
    };
    CarrierDialogComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            name: [this.carrier.name, [Validators.required]],
            scacCode: [this.carrier.scacCode, [Validators.required]],
            inHouse: [this.carrier.inHouse]
        });
    };
    CarrierDialogComponent.prototype.getTitle = function () {
        return this.carrier.isNew() ? 'New carrier' : 'Carrier';
    };
    CarrierDialogComponent.prototype.prepare = function () {
        var controls = this.form.controls;
        this.carrier.name = controls['name'].value;
        this.carrier.scacCode = controls['scacCode'].value;
        this.carrier.inHouse = controls['inHouse'].value;
        return this.carrier;
    };
    return CarrierDialogComponent;
}(AbstractEntityDialogComponent));
export { CarrierDialogComponent };
