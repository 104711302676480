import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import {
  BaseColumn,
  DateTimeColumn,
  FieldType,
  HyperLinkColumn
} from '../modules/shared/components/base/data-table/columns/column-types';
import { Part } from '../modules/shared/models';
import { ColumnWidth } from './column-width';
import { Carrier } from '../modules/shared/models/carrier';
import {HyperLink} from "./oms-types";

export * from './column-width';
export * from './column-ids';


@Injectable({
  providedIn: "root"
})
export class CbpStatusDefaultColumnsService {
  private cbpStatusDefaultColumns: BaseColumn[];

  constructor() {
    this.initialize();
  }


  private initialize() {

    this.cbpStatusDefaultColumns = this.buildCbpColumns();
  }

  getCbpColumns(): BaseColumn[] {
    return this.cbpStatusDefaultColumns;
  }

  buildCbpColumns(isReadMode: boolean = false): BaseColumn[] {
    let mawbColumn = new HyperLinkColumn('mawbNumber', 'Mawb', 'mawbNumber', FieldType.TEXT, '100px', {
      link: getCbpLink, sorted: {asc: true}
    })
      .setNewWindow(true)
      .setSearch({searchable: true, search: '',
        convertInput: (str) => str && str.replace(/ /g, '').replace(/-/g, ''),
        baseLineSearch: true}) as HyperLinkColumn;

    let hawbColumn = new HyperLinkColumn('hawb', 'HAWB', 'hawb', FieldType.TEXT, '100px', {link: getCbpLink}).setNewWindow(true);
    let partColumn = new HyperLinkColumn('part', 'Part', 'part', FieldType.TEXT, '70px', {
      link: getCbpLink,
      converter: (row, value) => {
        return isNullOrUndefined(value) ? '-' : value;
      }
    }).setNewWindow(true);
    return [
      isReadMode ? mawbColumn.convertToBaseColumn() : mawbColumn,
      isReadMode ? hawbColumn.convertToBaseColumn() : hawbColumn,
      isReadMode ? partColumn.convertToBaseColumn() : partColumn,
      new BaseColumn('uscsFsnStatus', 'Status', 'uscsFsnStatus', FieldType.TEXT, '70px'),
      new DateTimeColumn('fsnSentDate', 'FSN Sent Date', 'fsnSentDate', '130px'),
      new BaseColumn('totalPieces', 'Pieces', 'totalPieces', FieldType.TEXT, '70px'),
      /*      new BaseColumn('omsHU', 'OMS HU', 'omsHU', FieldType.TEXT, '70px'),
            new BaseColumn('omsPieces', 'OMS PCS', 'omsPieces', FieldType.TEXT, '70px'),*/
      new BaseColumn('originAirport.iataCode', 'Orig Airport', 'originAirport.iataCode', FieldType.TEXT, '100px'),
      new BaseColumn('destinationAirport.iataCode', 'Dest Airport', 'destinationAirport.iataCode', FieldType.TEXT, '100px'),
      new BaseColumn('carrierCode', 'Carrier', 'carrierCode', FieldType.TEXT, '80px'),
      new BaseColumn('originatorCode', 'Originator Code', 'originatorCode', FieldType.TEXT, '120px'),
      new BaseColumn('flightNo', 'Flight No', 'flightNo', FieldType.TEXT, '80px'),
      new DateTimeColumn('etaDate', 'Eta Date', 'etaDate', '100px'),
      new DateTimeColumn('transactionDate', 'Transaction Date', 'transactionDate', '130px')
        .setHandlers({sorted: {asc: false}}),
      new BaseColumn('uscsFsnEntryType', 'Entry Type', 'uscsFsnEntryType', FieldType.TEXT, '80px'),
      new BaseColumn('fsqReference', 'FSQ Reference', 'fsqReference', FieldType.TEXT, '80px'),
      new DateTimeColumn('transactionTime', 'Transaction Time', 'transactionTime', '130px'),
      new BaseColumn('uscsFsnEntryNumber', 'Entry Number', 'uscsFsnEntryNumber', FieldType.TEXT, '100px'),
      new BaseColumn('fsnReference', 'FSN Reference', 'fsnReference', FieldType.TEXT, '100px'),
      new BaseColumn('ediReferenceNum', 'Edi Ref Num', 'ediReferenceNum', FieldType.TEXT, '100px'),
      new BaseColumn('inboundFileName', 'Inbound File Name', 'inboundFileName', FieldType.TEXT, '100px'),
      new DateTimeColumn('fsqSentDate', 'FSQ Sent Date', 'fsqSentDate', '100px'),
      new BaseColumn('weight', 'Weight', 'weight', FieldType.TEXT, '70px'),
      new BaseColumn('commodityDescription', 'Commodity Description', 'commodityDescription', FieldType.TEXT, '200px'),
      new DateTimeColumn('dateCreated', 'Created Date', 'dateCreated', ColumnWidth.DATE_TIME),
    ];
  }


}

  let getCbpLink = function (row): HyperLink {
  if (row.master) {
    return {path: ['/home', 'masters', 'master'], params: {id: row.master.id}};
  }
  return null;
};
