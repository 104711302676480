import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {DomSanitizer} from "@angular/platform-browser";

@Injectable()
export class HttpUtilsService {

  public getHTTPHeaders(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }

  public getHTTPParams(params): HttpParams | undefined {
    return params ? new HttpParams({fromObject: params}) : undefined;
  }
}
