import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {displayLoadActionType, getCbpLink, OmsColumns} from "../../../common/oms-columns.service";
import {BaseColumn, FieldType, HyperLinkColumn} from "../../../modules/shared/components/base/data-table/columns/column-types";
import {AbstractEntityListComponent} from "../../../modules/settings/pages/abstract-entity-list.component";
import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {OmsDialogsService} from "../oms-dialogs";
import {FileUploadService} from '../../../services/file.upload.service';
import {FilterPageRequest} from "../../../modules/shared/models/filter.page.request";
import {SearchSortRequest} from "../../../modules/shared/models/search.sort.request";
import {FilterSearchColumn} from "../../../modules/shared/models/filter.search.column";
import {DispatchAndRecoveryLog} from "../../../modules/shared/models/dispatch.and.recovery.log";
import {DispatchAndRecoveryService} from "../../../modules/shared/services/logs/dispatch-and-recovery/dispatch.and.recovery.service";
import {convertManifestNumber, convertOrderNumber, getOrderLink} from "../../../modules/shared/services/oms-converters.service";

@Component({
  selector: 'oms-recovery-and-dispatch-log-dialog',
  templateUrl: './recovery-and-dispatch-log-dialog.component.html',
  styleUrls: ['./recovery-and-dispatch-log-dialog.component.scss']
})
export class RecoveryAndDispatchLogDialogComponent extends AbstractEntityListComponent<DispatchAndRecoveryLog> implements OnInit {

  @Input() masterId: number;
  @Input() shipmentId: number;
  @Input() orderId: number;
  @Input() objectType: "MASTER" | "SPLIT";

  dispatchAndRecoveryLogs: DispatchAndRecoveryLog[] = [];
  columns: BaseColumn[];

  defaultSortColumn: string = "id";

  constructor(public dialogRef: MatDialogRef<RecoveryAndDispatchLogDialogComponent>,
              private dialog: MatDialog,
              protected cdr: ChangeDetectorRef,
              protected alerts: OmsAlertsService,
              protected dialogs: OmsDialogsService,
              public dispatchAndRecoveryService: DispatchAndRecoveryService,
              private omsColumns: OmsColumns,
              protected fileUploadService: FileUploadService) {
    super(cdr, alerts, dispatchAndRecoveryService, dialogs, fileUploadService);
    this.dispatchAndRecoveryService.dispatchAndRecoveryLogSubject.subscribe(dispatchAndRecoveryLogs => {
      this.dispatchAndRecoveryLogs = dispatchAndRecoveryLogs;
    });
  }


  ngOnInit() {
    this.columns = this.buildColumns();
    this.omsColumns.getDispatchAndRecoveryLogsColumns();
    switch (this.objectType) {
      case "MASTER":
        this.dispatchAndRecoveryService.findAllByMasterId(this.masterId);
        break;
      case "SPLIT":
        this.dispatchAndRecoveryService.findAllByOrderId(this.orderId, this.shipmentId);
        break;
    }
  }

  getTitle(): string {
    return 'Dispatch and Recovery Logs';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  generateExcel() {
    let searchField = "";
    switch (this.objectType) {
      case "MASTER":
        searchField = "masterId";
        break;
      case "SPLIT":
        searchField = "shipmentId";
        break;
    }
    let filterPageRequest = this.createDefaultRequest(searchField);
    this.request = filterPageRequest;
    this.downloadExcel();
    this.dialogRef.close();
  }

  createDefaultRequest(searchField: string): FilterPageRequest {
    let id = this.objectType === "MASTER" ? this.masterId : this.shipmentId;
    let filterSearchColumn = new FilterSearchColumn(searchField, id + '', null, null);
    return new FilterPageRequest(1, 999, '', new SearchSortRequest(searchField, true), [filterSearchColumn]);
  }

  private buildColumns() {
    return [
      new BaseColumn('dateCreated', 'Date/Time', "dateCreated", FieldType.DATETIME, '100px'),
      new BaseColumn('createdBy', 'User Name', "createdBy", FieldType.TEXT, '130px'),
      new HyperLinkColumn('mawbNumber', 'Mawb', "mawbNumber", FieldType.TEXT, '100px', {
        link: getCbpLink,
        sorted: {asc: true}
      }),
      new BaseColumn('dispatchId', 'RT#', "dispatchId", FieldType.TEXT, '100px')
        .setHandlers({
          converter: (row, id) => convertManifestNumber(id)
        }),
      new HyperLinkColumn('orderId', 'RLD Number', 'orderId', FieldType.TEXT, '100px')
        .setHandlers({
          converter: (row, value) => convertOrderNumber(value, true),
          link: (o: DispatchAndRecoveryLog) => getOrderLink(o.orderId)
        }),
      new BaseColumn('pieces', 'Pieces', 'pieces', FieldType.TEXT, '60px'),
      new BaseColumn('datePickUpActual', 'Pick Up', 'datePickUpActual', FieldType.DATETIME, '100px'),

      new BaseColumn('driver.fullName', 'Driver', 'driver.fullName', FieldType.TEXT, '100px'),
      new BaseColumn('truck.number', 'Truck', 'truck.number', FieldType.TEXT, '100px'),
      new BaseColumn('trailer.number', 'Trailer', 'trailer.number', FieldType.TEXT, '100px'),

      new BaseColumn('action', 'Action', item => displayLoadActionType(item), FieldType.TEXT, '120px'),
    ];
  }
}
