<oms-base-dialog [readonly]="readonly" [title]="'Driver'" [icon]="'fa fa-truck'" (ok)="onOk($event)">
  <form [formGroup]="form">

    <div class="row-widget">
      <oms-string-input [label]="'First Name:'" formControlName="firstName" required maxlength="50"></oms-string-input>
    </div>
    <div class="row-widget">
      <oms-string-input [label]="'Last Name:'" formControlName="lastName" required maxlength="50"></oms-string-input>
    </div>
    <div class="row-widget">
      <oms-string-input [label]="'Email:'" formControlName="email" maxlength="80"></oms-string-input>
    </div>
    <div class="row-widget" *ngIf="adminEdit">
      <oms-string-input [label]="'Username:'" formControlName="username" maxlength="80"></oms-string-input>
    </div>
    <div class="row-widget" *ngIf="adminEdit">
      <oms-string-input [label]="'PIN:'" formControlName="pin" numbersOnly="true" maxlength="4"></oms-string-input>
    </div>
  </form>
</oms-base-dialog>
