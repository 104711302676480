import {Type} from "class-transformer";
import {BaseDeletable} from "./base-deletable";
import {Airport} from "./air";
import {Master} from "./master/master";
import {Order} from "./order";
import {isNullOrUndefined} from "util";

export class CbpStatus extends BaseDeletable  {
  mawbNumber: string;
  hawb: string;
  @Type(() => Master)
  master: Master;
  @Type(() => Order)
  order: Order;
  part: string;
  carrierCode: string;
  originatorCode: string;
  flightNo: string;
  @Type(() => Date)
  etaDate: Date;
  @Type(() => Airport)
  destinationAirport: Airport;
  @Type(() => Date)
  transactionDate: Date;
  uscsFsnEntryType: string;
  @Type(() => Date)
  fsqSentDate: Date;
  fsqReference: string;
  @Type(() => Date)
  transactionTime: Date;
  uscsFsnEntryNumber: string;
  @Type(() => Date)
  fsnSentDate: Date;
  fsnReference: string;
  uscsFsnStatus: string;
  totalPieces: number;
  totalWeight: number;
  ediReferenceNum: string;
  inboundFileName: string;
  inSystem: boolean;

  get omsPieces() {
    if (!this.inSystem) {
      return null;
    }
    return isNullOrUndefined(this.order) ? this.master.pieces : this.order.pieces;
  }

  get omsHU() {
    if (!this.inSystem) {
      return null;
    }
    return isNullOrUndefined(this.order) ? this.master.hu : this.order.hu;
  }
}
