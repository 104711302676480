import {Address, Driver, Load, Master, Order, Trailer, Truck, User} from '..';
import {BaseEntity} from '../base-entity';
import {Exclude, Type} from 'class-transformer';
import {Action} from '../../../../common/oms-types';
import {OrderPart} from "./order-part";
import {OrderWarehouse} from "./order.warehouse";
import {convertManifestNumber, convertMasterNumber, convertMawbNumber} from "../../services/oms-converters.service";
import {Carrier} from "../carrier";
import {absent} from "../../../../_helpers/utils";
import {Status} from "./status";


export class Receiving extends BaseEntity {
  public routeId: number;
  public masterId: number;
  public mawb: string;

  @Type(() => Address)
  public address: Address;
  @Type(() => OrderPart)
  public parts: OrderPart[] = [];
  @Type(() => Date)
  readonly date: Date;
  @Type(() => User)
  public user: User;
  @Type(() => Load)
  public load: Load;

  public loadAction: Action;

  public sealNumber: string;

  @Type(() => Carrier)
  public carrier: Carrier;
  @Type(() => Carrier)
  public truck: Truck;
  @Type(() => Trailer)
  public trailer: Trailer;
  @Type(() => Driver)
  public driver: Driver;

  constructor(master?: Master) {
    super();
    if (master) {
      this.masterId = master.id;
      this.mawb = master.mawbNumber;
      this.fillReceiving(master);
    }
  }

  public static buildReceiving(master: Master): Receiving {
    let receiving = new Receiving();
    receiving.fillReceiving(master);
    return receiving;
  }

  public static buildReceivingWarehouse(orders?: OrderWarehouse[]) {
    let receiving = new Receiving();
    receiving.fillWarehouseReceiving(orders);
    console.log('RECEIVING', receiving);
    return receiving;
  }

  protected fromOrderWarehouse(o: OrderWarehouse, masterReceiving: boolean): OrderPart {
    let part: OrderPart = new OrderPart();

    part.order = {
      id: o.id,
      hawb: o.hawb,
      pieces: o.pieces,
      hu: o.hu,
      ref3: o.ref3,
      customerRef: o.customerRef,
      purchaseOrder: o.purchaseOrder,
      addressFrom: o.addressRouteFrom,
      addressTo: o.addressDelivery,
      mode: o.genericMode
    };

    part.masterReceiving = masterReceiving;
    part.masterId = o.masterId;
    part.loadId = o.routeInLoadId;
//    console.warn(">>>>", o.pcsReceived, o.huReceived, o.id);
    part.pieces = part.pcsProcessed = o.pcsReceived || 0;
    part.hu = part.huProcessed = o.huReceived || 0;
    part.open = true;
//    part.weightProcessed = o.weight;

    part.orderNumber = o.routeInOrderNumber;
    part.address = o.addressWhse;
    part.status = o.receivingStatus; // */ o.routeInProblem ? Status.NOT_COMPLETED : (o.pcsReceived >= o.pieces ? Status.COMPLETED : Status.UNDEFINED);
    part.routeProblem = o.routeInProblem;
    part.sealNumber = o.routeInSealNumber;
    part.dispatchId = o.routeInId;
    part.shipmentId = o.routeInShipmentId;

    if (o.routeIn) {
      this.sealNumber = o.routeInSealNumber;
      this.driver = o.routeIn.driver;
      this.carrier = o.routeIn.carrier;
      this.truck = o.routeIn.truck;
      this.trailer = o.routeIn.trailer;
    }

    if (o.receiving && o.receiving.length) {
      part.receivings = [];
      o.receiving.forEach( (ow) => {
        ow.routeInProblem = o.routeInProblem;
        part.receivings.push(this.fromOrderWarehouse(ow, true));
      });
    }

    console.log('PREPARED', part, "OF", o);
    return part;
  }


  @Exclude()
  get modified(): boolean {
    return this.parts.some((part) => part.modified);
  }

  getTotalPiecesProcessed(): number {
    return this.parts.aggregate((acc, rp) => acc + ~~rp.pcsProcessed, 0);
  }

  getTotalHuProcessed(): number {
    return this.parts.aggregate((acc, rp) => acc + ~~rp.huProcessed, 0);
  }

  @Exclude()
  getOrderPart(order: Order): OrderPart {
    return this.parts.find((part) => part.order.id === order.id);
  }

  private fillReceiving(master: Master) {
    master.orders.forEach((o) => {
      let part: OrderPart = new OrderPart();

      part.masterId = master.id;
      part.masterReceiving = true;
      part.open = true;

      part.address = o.addressCfs;
      part.order = {
        id: o.id,
        hawb: o.hawb,
        pieces: o.pieces,
        hu: o.hu,
        customerRef: o.customerRef,
        purchaseOrder: o.purchaseOrder,
        ref3: o.ref3,
        addressFrom: o.addressCfs,
        addressTo: o.addressDelivery,
        mode: o.genericMode
      };
      part.pieces = part.pcsProcessed = o.pcsReceived || 0;
      part.hu = part.huProcessed = o.huReceived || 0;

      part.status = o.pieces > 0 && o.pieces <= o.pcsReceived ? Status.COMPLETED : Status.UNDEFINED;

      this.parts.push(part);
    });
  }

  public addPart(o: OrderWarehouse): OrderPart {
    let part: OrderPart = this.fromOrderWarehouse(o, absent(o.routeInId));
    console.log("ADDING PART", part);
    this.parts.push(part);
    return part;
  }

  private fillWarehouseReceiving(orders?: OrderWarehouse[]) {
    orders.forEach((o) => this.addPart(o));
  }

  @Exclude()
  public get label(): string {
    if (this.routeId) {
      return convertManifestNumber(this.routeId);
    }

    if (this.masterId) {
      return this.mawb ? 'MAWB#:' + convertMawbNumber(this.mawb) : convertMasterNumber(this.masterId);
    }

    return 'N/A';
  }


  @Exclude()
  get isOwnCarrier(): boolean {
    return !this.carrier || this.carrier.isOwnCarrier;
  }

}
