import { Master, Order, ProblemStatus } from "../modules/shared/models";
import { FillStatus } from "../components/common/fill-indicator/fill-indicator.component";
import { PARENT_REF } from "../modules/shared/components/base/data-table/data-table.utils";
import {
  convertDate
} from "../modules/shared/services/oms-converters.service";
import * as moment from "moment-timezone";
import { Moment } from "moment";
import { OmsConstants } from "./oms-constants.service";
import { NumberUtils } from "../_helpers/number.utils";
import { isNullOrUndefined } from "util";
import { OrderModes } from "../modules/shared/models/order/order-mode";
import { OrderWarehouse } from "../modules/shared/models/warehouse/order.warehouse";

export function checkboxTypeByProblem(status: ProblemStatus) {
  switch (status) {
    case ProblemStatus.PROBLEM: return 'cb-alert';
    default: return null;
  }
}

export function fillIndicatorClass(row: Order | Master, value?: any): string {
  let s = 'fill-indicator';
  let isOver: boolean = false;

  if (!row.direct) {
    if (row instanceof Master && row.someOver) {
      isOver = true;
      s += ' fill-indicator-over';
    }

    if (row.fillStatus === FillStatus.OVER) {
      isOver = true;
      s += ' fill-indicator-over';
    }
  }

  if (!isOver) {
    try {
      let master: Master = row instanceof Master ? row : row[PARENT_REF];
      if (master && master.isDispatched) {
        s += ' dispatched';
      }
    } catch (e) {
//      console.log('unknown type:', row);
    }
  }
  return s;
}

export function iconFillStatus(row: Order, value: FillStatus): string {
  if (row.direct) {
    return 'fa fa-truck';
  }

  if (row instanceof Order && !OrderModes.isAirImport(row.genericMode)) {
    return '';
  }

  switch (value || FillStatus.NONE) {
    case FillStatus.EMPTY: return 'fa fa-battery-empty';
    case FillStatus.HALF: return 'fa fa-battery-half'; // 'fa fa-star-half';
    case FillStatus.FULL: return 'fa fa-battery-full'; // 'fa fa-star';
    case FillStatus.OVER: return 'fa fa-battery-full'; // 'fa fa-star';
    default: return '';
  }
}

export function getJjsLfd(order: Order): string {
  let jjsLfdDate = getJjsLfdDate(order);
  if (!jjsLfdDate) {
    return null;
  }
  return convertDate(jjsLfdDate);
}

export function getJjsLfdDate(order: Order): Date {
  let dateCfsIn = order.dateCfsInAct || order.dateCfsInEst;
  if (!dateCfsIn) {
    return null;
  }
  return moment(dateCfsIn).add(5, 'days').toDate();
}

export function getCfsLfdDate(order: Order): Moment {
  return order.dateCfsFreeTime ? moment.tz(order.dateCfsFreeTime, OmsConstants.ETC_ZONE) : null;
}

export function getStorageDue(order: Order): string {
  let jjsLfd = getCfsLfdDate(order);
  if (!jjsLfd) { return "$0"; }
  jjsLfd = resetToStartDay(jjsLfd);
  let todayMorning = resetToStartDay(createTodayWithNYTimeZone());
  if (todayMorning.isSameOrBefore(jjsLfd)) {
    return "$0";
  }
  let days = todayMorning.diff(jjsLfd, 'days');
  let priceByDay = Math.max(40, 0.08 * (order.weight || 0));
  return "$" + NumberUtils.round(days * priceByDay, 0);
}

export function resetToStartDay(date: Moment): Moment {
  return date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
}

export function createStartDateWithNYTimeZone(m: Moment): Moment {
  return resetToStartDay(moment.tz(m.format('YYYY-MM-DD'), OmsConstants.ETC_ZONE));
}

export function createTodayWithNYTimeZone(): Moment {
  return resetToStartDay(moment.tz(moment().format('YYYY-MM-DD'), OmsConstants.ETC_ZONE));
}

export function noNA(value): any {
  return isNullOrUndefined(value) ? value : value;
}



export function receivedValueClass(row: Order | Master, value): string {
  let s = '';

  if (row instanceof Master && row.someOver) {
    s += ' fill-indicator-over';
  }

  if (row.fillStatus === FillStatus.OVER) {
    s += ' fill-indicator-over';
  }

  return s;
}

export function statusIndicatorClass(row: Order | Master, value): string {

  if (row instanceof Master || row instanceof Order) {
    if (row instanceof Master && row.isReAcknowledged && row.status === 0) {
      return ' status-new-ack';
    }
    switch (row.status) {
      case 0:
        return ' status-new';
    }
    if (row instanceof Master) {
      let masterStatus = (row as Master).status;
      let orders = (row as Master).orders;
      let statusMasterMoreOrder = orders.some(o => o.status > masterStatus);
      if (statusMasterMoreOrder) {
        return ' status-master-less-than-order';
      }
    }
  }

  return '';
}

export function getWeightReceived(order: OrderWarehouse): string {
  if (!isNaN(order.weight) && !isNaN(order.pieces) && !isNaN(order.pcsReceived) && order.pieces !== 0) {
    let weightReceived = NumberUtils.round((order.weight / order.pieces) * order.pcsReceived, 1);
    return String(weightReceived);
  } else {
    return "N/A";
  }
}
