import {Directive, ElementRef, EventEmitter, Host, Input, OnInit, Output, Renderer2} from '@angular/core';
import {isNullOrUndefined} from "util";


@Directive({
  selector: '[sticky-column]',
  host: {
    '[class.fixed-column]': "sticky",
//    '[style.background-color]':"sticky? 'inherit' : undefined",
    '[style.z-index]' : "sticky? 900 : undefined",
//    '[style.position]': "sticky ? 'sticky': undefined",
//    '[style.position]': "sticky ? '-webkit-sticky': undefined",
    '[style.left.px]': "sticky && (direction === 'left') ? left: undefined",
    '[style.right.px]': "sticky && (direction === 'right') ? 0 : undefined"   // just only one right aligned column is supported
  }
})
export class StickyDirective implements OnInit/*, OnDestroy,  AfterViewInit*/ {
  @Input('sticky-column')
  public sticky: boolean = true;
  @Input('sticky-column-direction')
  @Input('params') params;
  public direction: 'left' | 'right' = 'left';
  private _left;
  @Output('sticky-columnEvent')
  private stickyColumnEvent: EventEmitter<any> = new EventEmitter<any>(false);

  constructor(@Host() private host: ElementRef<HTMLElement>) { }

  ngOnInit() {
    if (!isNullOrUndefined(this.params && this.params.position)) {
      this._left = this.params.position;
    } else {
      this._left = 0; // this.host.nativeElement.offsetLeft;
      if (this._left > 0) {
        this._left--;
      }
      if (this.params && this.params.index) {
        this.stickyColumnEvent.emit({index: this.params.index, position : this._left});
      }
    }
  }
/*
  ngOnDestroy() { }
  ngAfterViewInit(): void { } */

  get left() {
    return this._left; // = this._left || this.host.nativeElement.clientLeft;
  }

}
