var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { OmsConstants } from '../../../../../../common/oms-constants.service';
import { ValueAccessorBase } from '../../../base/value-accessor';
import { WeightUnits } from '../../../../../../common/oms-unit-types';
import { checkNumber } from '../../../../../../_helpers/utils';
import { isNullOrUndefined } from 'util';
import { Size } from '../../../../../../common/oms-types';
var WeightInputComponent = /** @class */ (function (_super) {
    __extends(WeightInputComponent, _super);
    function WeightInputComponent(_ngControl, cdr, _constants) {
        var _this = _super.call(this) || this;
        _this._ngControl = _ngControl;
        _this.cdr = cdr;
        _this._constants = _constants;
        _this.Size = Size;
        _this.label = 'Weight';
        _this.size = Size.DEFAULT;
        _this.readonly = false;
        _this.disabled = false;
        _this.forceChangeUnits = false;
        _this.showLabel = true;
        _this.units = _this._constants.weightUnits.default;
        _this._ngControl.valueAccessor = _this;
        return _this;
    }
    WeightInputComponent.prototype.toDisplay = function (value) {
        return checkNumber(WeightUnits.fromDefault(value, this.units));
    };
    WeightInputComponent.prototype.toDefault = function (value) {
        return checkNumber(WeightUnits.toDefault(value, this.units));
    };
    Object.defineProperty(WeightInputComponent.prototype, "internal", {
        get: function () {
            if (isNullOrUndefined(this.inputValue)) {
                this.inputValue = this.toDisplay(_super.prototype.getValue.call(this));
            }
            return this.inputValue;
        },
        set: function (value) {
            if (this.inputValue !== value) {
                this.inputValue = value;
                _super.prototype.setValue.call(this, this.toDefault(value));
            }
        },
        enumerable: true,
        configurable: true
    });
    WeightInputComponent.prototype.onUnitsChanged = function (event) {
        if (this.forceChangeUnits /* this.disabled || this.readonly*/) {
            // Assume that user looks at value - convert input value to selected units
            this.inputValue = this.toDisplay(_super.prototype.getValue.call(this));
            this.cdr.detectChanges();
        }
        else {
            _super.prototype.setValue.call(this, this.toDefault(this.inputValue));
            this.cdr.detectChanges();
        }
    };
    WeightInputComponent.prototype.setValue = function (value) {
        this.inputValue = this.toDisplay(value);
        this.innerValue = value;
        this.cdr.detectChanges();
    };
    WeightInputComponent.prototype.isDisplayError = function () {
        if (!this._ngControl || this._ngControl.valid) {
            return false;
        }
        if (this._ngControl.invalid && (this._ngControl.dirty || this._ngControl.touched)) {
            return true;
        }
        return false;
    };
    return WeightInputComponent;
}(ValueAccessorBase));
export { WeightInputComponent };
