<oms-base-dialog [title]="'Receiving Log'" [icon]="'fa fa-history'" [no-padding]="true" [loading]="loading">

  <div class="form-row" style="min-height: 300px">
    <table class="table table-fixed table-condensed table-striped table-hover no-margin">
      <thead>
        <tr role="row">
          <th style="width: 150px;">Date</th>
          <th style="width: unset;">User</th>
          <th style="width: 120px;">MAWB</th>
          <th style="width: 120px;">HAWB</th>
          <th style="width: 65px;">old HU</th>
          <th style="width: 65px;">new HU</th>
          <th style="width: 65px;">old PCS</th>
          <th style="width: 65px;">new PCS</th>
          <th style="width: 60px;">RLD</th>
          <th style="width: 120px;">Driver</th>
          <th style="width: 60px;">Truck</th>
          <th style="width: 60px;">Trailer</th>
          <!--th style="width: 80px;">Action</th-->
          <!--th style="width: 17px;"></th-->
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of list | async, let i = index;">
          <ng-container *ngFor="let part of row.parts, let p = index, let first = first;">
            <tr role="row" [ngClass]="{'odd': p%2, 'even':!(p%2)}" *ngIf="part.pieces || part.hu">
              <td style="width: 150px;"><span>{{row.date | oms_date_time_zone}}</span></td>
              <td style="width: unset;"><a>{{row.user.fullName}}</a></td>
              <td style="width: 120px;"><span>{{converters.convertMawbNumber(data.master.mawbNumber)}}</span></td>
              <td style="width: 120px;">{{part.order.hawb}}</td>
              <td style="width: 65px; text-align: right;">{{part.huProcessed - part.hu || 'N/A'}}</td>
              <td style="width: 65px; text-align: right;">{{part.huProcessed || 'N/A'}}</td>
              <td style="width: 65px; text-align: right;">{{part.pcsProcessed - part.pieces || 'N/A'}}</td>
              <td style="width: 65px; text-align: right;">{{part.pcsProcessed || 'N/A'}}</td>
              <td style="width: 60px;">{{rldOf(row.load) || 'N/A'}}</td>
              <td style="width: 120px;">{{driverName(row.load) || 'N/A'}}</td>
              <td style="width: 65px;">{{truckNumber(row.load) || 'N/A'}}</td>
              <td style="width: 65px;">{{trailerNumber(row.load) || 'N/A'}}</td>
              <!--td style="width: 80px;">{{row.loadAction || 'N/A'}}</td-->
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</oms-base-dialog>
