import {Region} from './region';
import {BaseEntity} from '../base-entity';

export class Country extends BaseEntity {
  name: string;
  iso2Code: string;
  iso3Code: string;
  numCode: number;
  region: Region;

  public static USA_CODE = 'US';

  get isUsa() {
    return !this.isNew() && (this.iso2Code === Country.USA_CODE);
  }

  public static isUsa(country: Country) {
    return country.iso2Code === Country.USA_CODE;
  }
}
