import { Injectable } from '@angular/core';

import * as moment from 'moment';
import {Moment} from "moment";
import {Schedule} from "../modules/shared/models/reporting/schedule";
import {OmsConstants} from "../common/oms-constants.service";
import {StringUtil} from "../_helpers/string.util";


@Injectable()
export class DateTimeService {
  public tenantTimeZone: string;
  public timeZones: any[] = [];
  public workingHoursStart = 9;

  public weekDays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public dayHours: string[] =
    ['12 AM', '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM', '09 AM', '10 AM', '11 AM',
     '12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM'];
  public dayOfWeekInMonth: string[] = ['First', 'Second', 'Third', 'Fourth', 'Last'];

  constructor() {
    this.tenantTimeZone = 'America/New_York';
    moment.tz.names().forEach(name => {
      this.timeZones.push({'name': name, 'desc': moment.tz(name).format('z (UTC Z)')});
    });
  }

  private getDaysOfWeekDescription(n: boolean[]): string {
    return n.map((b, i) => b ? this.weekDays[i] : undefined).filter((s) => !!s).join(', ');
  }

  public getTimeZoneNames() {
    return moment.tz.names();
  }

  public setTenantTimeZone(tenantTz: string) {
    this.tenantTimeZone = tenantTz;
  }

  public utcToTenant(utcDateTime: Moment): Moment {
    return moment.tz(utcDateTime, this.tenantTimeZone);
  }

  public utcToTenantString(utcDateTime: Moment, format: string): string {
    format = moment.tz.guess() === this.tenantTimeZone ? format : format + ' (UTC Z)';
    return moment.tz(utcDateTime, this.tenantTimeZone).format(format);
  }

  public utcToNYFormat(date: Date | Moment, format: string): string {
    if (date) {
      return moment.tz(date, OmsConstants.ETC_ZONE).format(format);
    }
    return "";
  }

  public tenantToUtc(tenantDateTime: Moment): Moment {
    return moment(tenantDateTime).utc();
  }

  public scheduleDescription(schedule: Schedule): string {
    let timeZone = moment.tz(schedule.timeZone).format(' z');
//    let hour = this.dayHours[schedule.hour];
//    let hour = ((schedule.hour || 0) + '').padStart(2, '0');
//    let minute = ((schedule.minute || 0) + '').padStart(2, '0');

    let time = StringUtil.formatAMPM(schedule.hour || 0, schedule.minute || 0) + " " + timeZone;

//    return "From " + schedule.dateFrom + " to " + schedule.dateTo + "\n" +
    return(schedule.isHourly ? "Hourly, every " + (schedule.hourPeriod + 1) + " hour(s)" :
      (schedule.isDaily ? "Daily, every " + schedule.dayNumber + " day(s) at " + time :
      (schedule.isWeekly ? "Weekly, every " + schedule.weekNumber + " week(s) at " + time + " on " + this.getDaysOfWeekDescription(schedule.weekDays) :
      ("Monthly, every " + schedule.monthNumber + " month(s) at " + time + " on " +
          ((schedule.monthlyType === 0) ? schedule.dayOfMonthNumber + " day" : this.dayOfWeekInMonth[schedule.dayOfWeekInMonthNumber] + " " + this.weekDays[schedule.dayOfWeek])))));
  }

  /***
   * get UTC time of Day beginning of location in specified TimeZone
   * @param date - specific Date
   * @param zone - Time Zone of Location (America/New_York by default)
   */
  public utcDateOfMidnight(date: Date, zone =  this.tenantTimeZone): Date {
    return new Date(moment.tz(date, zone).format('YYYY-MM-DDT00:00:00Z'));
  }

  public getNextWorkingDay(current?: Date, timeZone = this.tenantTimeZone): Date {
    if (!current) {
      current = new Date();
    }

    current = new Date(current.toLocaleString("en-US", {timeZone: timeZone}));

    if (current.getHours() >= this.workingHoursStart) {
      current.addHours(24);
    }

    while ([0, 6].includes(current.getDay())) {
      current.addHours(24);
    }

    current = this.utcDateOfMidnight(current).addHours(this.workingHoursStart);
    return current;
  }
}
