import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from '../services';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public lastError;

  constructor(private authService: AuthService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err) => {
      this.lastError = err.error;


      console.error('ERROR RESPONSE', err);

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authService.logOut(this.router.url);
        if (request.url.includes('account/login/')) {
          return throwError(err);
        }
        return null;
      }

      if (err.status === 400 || err.status === 403) {
        return throwError(err);
      }


      if (err.status === 404) {
        // auto logout if 404 response returned from api
        this.router.navigate(['/404'], {replaceUrl: true});
        return null; // throwError(err); Error Handled
      }


      if (err.error && err.error.exception && err.error.exception.includes('DataIntegrityViolation')) {
        return throwError('DataIntegrityViolation');
      }

      // Trow pure error if it contains stack
      if (err.error && err.error.stackTrace) {
        return throwError(err.error);
      }

      return throwError((err.error ? err.error.message : err.message) || err.statusText);
    }));
  }
}
