import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TruckService} from '../../../services/truck-service';
import {Address, AddressType, Load, RecoveryOrder} from '../../../modules/shared/models';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {TrailerService} from '../../../services/trailer-service';
import {DriverService} from '../../../services/driver-service';
import {AddressService} from '../../../services';
import {Master} from '../../../modules/shared/models';
import {LoadDispatchDto, Size} from '../../../common/oms-types';
import {AddressSearchService} from '../../../modules/shared/services/address-search.service';
import {Truck} from '../../../modules/shared/models';
import {Trailer} from '../../../modules/shared/models';
import {Driver} from '../../../modules/shared/models';
import {addHours} from '../../../common/oms-date-time.utils';
import {DialogCloseEvent, DialogResult, DialogType, ModalResult, OmsDialogsService} from "../../common/oms-dialogs";

export interface DispatchData {
  generatePtt: boolean;
  readonly: boolean;
  split: RecoveryOrder;
  dispatch?: LoadDispatchDto;
}

@Component({
  templateUrl: './load-dispatch-dialog.component.html',
  styleUrls: ['./load-dispatch-dialog.component.scss']
})
export class LoadDispatchDialogComponent implements OnInit {
  Size = Size;
  AddressType = AddressType;

  public load: Load;
  public master: Master;
  public readonly: boolean = false;
  public delivery: Address[];
  public disp: LoadDispatchDto;
  public addressSearch: AddressSearchService;

  constructor(
    public cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data:DispatchData,
    public dialogRef: MatDialogRef<LoadDispatchDialogComponent, DialogResult<DispatchData>>,
    public addressService: AddressService,
    private dialogs: OmsDialogsService,
    private alerts: OmsAlertsService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService
  ) {

    this.readonly = data.readonly;
    this.master = data.split.master;
    this.load = data.split.load || new Load();
    this.delivery = this.data.split.master.orders.unique(o=>o.addressDelivery);
    this.addressSearch = this.addressService.getNewSearchService([], 15);


    //prepare initial Dispatch data
    data.dispatch = this.disp = {
      loadId: data.split.loadId,
      orderId: data.split.orderId,

      direct: this.master.direct,
      nonAMS: this.master.nonAMS,

      fromAddress: data.split.addressPickup || this.master.cargoBuilding,
      toAddress: data.split.addressDelivery || this.master.addressDelivery,

      datePickupEst: data.split.datePickupEst || addHours(new Date(), 4),
      pcs:data.split.pieces,
      hu: data.split.hu,

      driver: data.split.driver,
      trailer: data.split.trailer,
      truck: data.split.truck,
    };

//    console.log('OPEN>', data.split, data.dispatch);

  }

  ngOnInit() {
  }

  get cancelEnable(): boolean {
    return !this.readonly && !!this.load.id/* && !this.data.split.isReceived*/;
  }

  onCancelDispatch() {
    this.dialogs.confirm(DialogType.CONFIRMATION, 'Cancel Dispatch?',
      'The load will be deleted and all related data will be lost.\nContinue?').then((result) => {
        switch (result.result) {
          case ModalResult.YES: {
            this.dialogRef.close({result: ModalResult.DELETE});
          }
        }
    });
  }

  onOk(event: DialogCloseEvent) {
    if (!this.disp.driver) {
      this.alerts.warning('Please select Driver');
      event.canClose = false;
    }

    if (!this.disp.pcs) {
      this.alerts.warning('Pieces must be greater than 0');
      event.canClose = false;
    }
    // todo validate
  }

  onCreateTruck(number: string) {
    const truck = new Truck();
    truck.number = number;
    this.truckService.createEditAndSave(truck, this.readonly)
      .then(truck => {this.disp.truck = truck; })
      .catch(error => this.alerts.danger(error));
  }

  onCreateTrailer(number: string) {
    const trailer = new Trailer();
    trailer.number = number;
    this.trailerService.createEditAndSave(trailer, this.readonly)
      .then(trailer => {this.disp.trailer = trailer; })
      .catch(error => this.alerts.danger(error));
  }

  onCreateDriver(event) {
    const driver = new Driver();
    this.driverService.createEditAndSave(driver, this.readonly)
      .then(driver => {this.disp.driver = driver; })
      .catch(error => this.alerts.danger(error));
  }

  get direct(): boolean {
    return this.disp.direct;
  }

  set direct(value: boolean) {
    if (this.disp.direct !== value) {
      this.disp.direct = value;
      this.disp.toAddress = this.disp.direct ? this.master.orders.asUniqueValue(o => o.addressDelivery) : this.master.orders.asUniqueValue(o=>o.addressCfs);
      this.cdr.markForCheck();
    }
  }

  get showPickup(): boolean {
    return this.direct || this.nonAMS;
  }

  get nonAMS():boolean {
    return this.disp.nonAMS;
  }

  set nonAMS(value: boolean) {
    if (this.disp.nonAMS != value) {
      this.disp.nonAMS = value;
    }
  }

  get masterRecoveryToAddressTypes() {
    return this.disp.direct ? [AddressType.DELIVERY_LOCATION] : [AddressType.CFS_LOCATION];
  }

  get masterAddressesRecoveryToDefault(): Address[] | undefined {
    return this.disp.direct ? this.master.orders.unique(o=>o.addressDelivery) : undefined;
  }

  onPickupLocationChange(address:Address) {
    this.nonAMS = address && address.cfs3plLocation;
  }
}
