import { AfterViewInit, EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";
import { ModalResult } from "../oms-dialogs.types";
var BaseDialogComponent = /** @class */ (function () {
    function BaseDialogComponent(data, spinner, dialogRef) {
        var _this = this;
        this.data = data;
        this.spinner = spinner;
        this.dialogRef = dialogRef;
        this.draggable = true;
        this.sizable = true;
        this.no_padding = false;
        this.visible = true;
        this._readonly = false;
        this.canOk = true;
        this.autoClose = true;
        this.storePosition = true;
        this.showLabel = true;
        this.showDialogBtns = true;
        this.showFooter = true;
        this.flexContent = false;
        this.eventOK = new EventEmitter(false);
        this.eventCancel = new EventEmitter(false);
        this.btnOk = { icon: 'fa fa-check', caption: 'OK' };
        this.btnCancel = { icon: 'fa fa-times', caption: 'Cancel' };
        this.sub = [];
        this.loadingAfterSubmit = false;
        this.viewLoading = false;
        this.sbDisableOnInit = true;
        this.dialogRef.disableClose = true; // disable closing by background click;
        this.sub.push(this.dialogRef.afterOpen().subscribe(function () { return BaseDialogComponent.stack.push(_this.dialogRef); }));
        this.sub.push(this.dialogRef.afterClosed().subscribe(function () { return BaseDialogComponent.stack.removeAll(_this.dialogRef); }));
    }
    Object.defineProperty(BaseDialogComponent.prototype, "loading", {
        set: function (value) {
            this.viewLoading = value;
            if (value) {
                this.spinner.show('spModalDialog').then();
            }
            else {
                this.spinner.hide('spModalDialog').then();
            }
        },
        enumerable: true,
        configurable: true
    });
    BaseDialogComponent.prototype.ngOnDestroy = function () {
        BaseDialogComponent.stack.removeAll(this.dialogRef);
        this.sub.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(BaseDialogComponent.prototype, "class", {
        get: function () {
            return this.sizable ? 'sizable' : '';
        },
        enumerable: true,
        configurable: true
    });
    BaseDialogComponent.prototype.onEscape = function (event) {
        console.log(BaseDialogComponent.stack, this.dialogRef);
        if (this.dialogRef === BaseDialogComponent.stack.last()) {
            event.stopImmediatePropagation();
            this.onCancel();
        }
    };
    BaseDialogComponent.prototype.onPopState = function (event) {
        if (this.dialogRef === BaseDialogComponent.stack.last()) {
            // Browser Back button Handler
            event.stopImmediatePropagation();
            window.history.forward();
            this.onCancel();
        }
    };
    BaseDialogComponent.prototype.onOK = function () {
        console.log('OK Button', this.btnOk);
        if (this.btnOk && this.btnOk.handler && typeof this.btnOk.handler === 'function') {
            this.btnOk.handler(); //.bind(this);
        }
        else {
            if (this.eventOK.observers && this.eventOK.observers.length > 0) {
                var event_1 = { canClose: this.autoClose, result: { result: ModalResult.OK, data: this.data } };
                this.eventOK.emit(event_1);
                if (event_1.canClose) {
                    this.dialogRef.close(event_1.result);
                }
            }
        }
    };
    BaseDialogComponent.prototype.onCancel = function () {
        if (this.btnCancel && this.btnCancel.handler) {
            this.btnCancel.handler().bind(this);
        }
        else {
            var event_2 = { canClose: true, result: { result: ModalResult.CANCEL, data: this.data } };
            this.eventCancel.emit(event_2);
            if (event_2.canClose) {
                this.dialogRef.close(event_2.result);
            }
        }
    };
    BaseDialogComponent.prototype.ngOnInit = function () {
    };
    BaseDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { _this.sbDisableOnInit = false; }, 500);
    };
    BaseDialogComponent.stack = [];
    return BaseDialogComponent;
}());
export { BaseDialogComponent };
