var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { OmsConstants } from '../../../../../../common/oms-constants.service';
import { ValueAccessorBase } from '../../../base/value-accessor';
import { checkNumber } from '../../../../../../_helpers/utils';
import { isNullOrUndefined } from 'util';
import { Size } from '../../../../../../common/oms-types';
import { MatDialog } from "@angular/material";
import { UldsDialogComponent } from "../../../../../../components/common/ulds-dialog/ulds-dialog.component";
import { Uld } from "../../../../models";
var UldInputComponent = /** @class */ (function (_super) {
    __extends(UldInputComponent, _super);
    function UldInputComponent(cdr, _constants, dialog) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this._constants = _constants;
        _this.dialog = dialog;
        _this.Size = Size;
        _this.label = 'Quantity';
        _this.size = Size.DEFAULT;
        _this.readonly = false;
        _this.disabled = false;
        _this.showButton = true;
        _this.ULDsChange = new EventEmitter();
        return _this;
    }
    UldInputComponent.prototype.toDisplay = function (value) { return checkNumber(value); };
    UldInputComponent.prototype.toDefault = function (value) { return checkNumber(value); };
    Object.defineProperty(UldInputComponent.prototype, "internal", {
        get: function () {
            if (isNullOrUndefined(this.inputValue)) {
                this.inputValue = this.toDisplay(_super.prototype.getValue.call(this));
            }
            return this.inputValue;
        },
        set: function (value) {
            if (this.ULDs.length > (value + this.emptyULDsLength)) {
                return;
            }
            if (value !== this.inputValue) {
                this.inputValue = value;
                _super.prototype.setValue.call(this, this.toDefault(value));
                this.initULDs(this.inputValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UldInputComponent.prototype, "emptyULDsLength", {
        get: function () {
            var emptyULD = this.ULDs.filter(function (uld) {
                return isNullOrUndefined(uld.id) || isNullOrUndefined(uld.uldNumber);
            });
            return emptyULD.length;
        },
        enumerable: true,
        configurable: true
    });
    UldInputComponent.prototype.setValue = function (value) {
        this.inputValue = this.toDisplay(value);
        this.initULDs(this.inputValue);
        //    super.setValue(value);
        this.cdr.detectChanges();
    };
    UldInputComponent.prototype.initULDs = function (uldCount) {
        var _this = this;
        if (!isNullOrUndefined(uldCount)) {
            if (this.ULDs.length < uldCount) {
                var difference = uldCount - this.ULDs.length;
                Array(difference).fill(0).map(function (x, i) {
                    _this.ULDs.push(new Uld());
                });
            }
            else if (this.ULDs.length > uldCount) {
                var difference = this.ULDs.length - uldCount;
                Array(difference).fill(0).map(function (x, i) {
                    _this.ULDs.pop();
                });
            }
        }
    };
    UldInputComponent.prototype.openUldDialog = function () {
        var _this = this;
        if (!isNullOrUndefined(this.rootId)) {
            var data = 'Master' === this.objectType ? {
                masterId: this.rootId,
                readonly: this.readonly
            } : { orderId: this.rootId, readonly: this.readonly };
            var dialogRef = this.dialog.open(UldsDialogComponent, {
                width: '30%', data: data
            });
            dialogRef.afterClosed().subscribe(function (res) {
                if (!res) {
                    return;
                }
                _this.setValue(res.uldCount);
                _super.prototype.setValue.call(_this, _this.toDefault(res.uldCount));
                _this.ULDsChange.next(res.ULDs);
            });
        }
    };
    return UldInputComponent;
}(ValueAccessorBase));
export { UldInputComponent };
