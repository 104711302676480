import {absent} from "./utils";

export function extractFileExt(fileName: string): string {
  return fileName ?  /(?:\.([^.]+))?$/.exec(fileName)[1] : undefined;
}

export function cleanSearchString(s: string): string {
  return s && s.replace(/\s\s+/g, ' ').trim();
}

export function parseBoolean(value: string): boolean {
  return !!value && ['1', 'TRUE'].includes(value.toUpperCase());
}

export class StringUtil {

  public static readableFromCamelCase(s: string): string {
    return this.toUpperCase(s, 0, 1)
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .split(" ")
      .map((a) => a.length < 4 ? a.toUpperCase() : a)
      .join(' ');
  }

  public static isBigLetters(value: string): boolean {
    return value ? value === value.toUpperCase() : false;
  }

  public static toUpperCase(value: string, start: number = 0, end: number = 1): string {
    return value.substring(0, start) + value.substring(start, end).toUpperCase() + value.substring(end, value.length);
  }

  public static toLowerCase(value: string, start: number = 0, end: number = 1): string {
    return value.substring(0, start) + value.substring(start, end).toLowerCase() + value.substring(end, value.length);
  }

  public static equalsIgnoreCase(str1: string, str2: string): boolean {
    if (str1 && str2) {
      return str1.toLowerCase() === str2.toLowerCase();
    }
    return str1 === str2;
  }

  public static includeIgnoreCase(str1: string, str2: string): boolean {
    if (str1 && str2) {
      return str1.toLowerCase().includes(str2.toLowerCase());
    }
    return false;
  }

  public static reverseString(str): string {
    return str.split("").reverse().join("");
  }

  public static generateUuid(): string {
    // noinspection SpellCheckingInspection
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static formatDateToAMPM(date: Date) {
    return this.formatAMPM(date.getHours(), date.getMinutes());
  }

  public static formatAMPM(hours: number, minutes: number) {
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    return (hours + '').padStart(2, '0') + ':' + (minutes + '').padStart(2, '0') + ' ' + ampm;
  }

  public static sameRef(ref1: string, ref2: string): boolean {
    return ref1 === ref2 || !ref1 && !ref2;
  }

  public static validateEmail(email): boolean {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  public static split(s: string): string[] {
    return s ? s.split('[;, ^\r\n]').filter((str) => !!str) : [];
  }

  public static receivingDescription(total: number, received: number): string {
    if (received && total) {
      let s = `${received} of ${total} PCS RECEIVED`;
      if (received < total) {
        s += `\n${total - received} PCS SHORT`;
      } else if (received > total) {
        s += `\n${received - total} PCS OVERAGE`;
      }
      return s;
    } else {
      return '';
    }
  }

}
