var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EventEmitter } from '@angular/core';
import { Size } from "../../../../../common/oms-types";
import { Logger } from "../../../../../_helpers/logger";
var SelectButtonComponent = /** @class */ (function () {
    function SelectButtonComponent() {
        this.Size = Size;
        this.size = Size.DEFAULT;
        this.btnClass = 'btn-outline-secondary';
        this.disabled = false;
        this.toggle = false;
        this.selectedChange = new EventEmitter();
    }
    SelectButtonComponent.prototype.onSelect = function (item) {
        this.selected = this.bindValue ? item[this.bindValue] : item;
        this.selectedChange.emit(this.selected);
    };
    SelectButtonComponent.prototype.labelOf = function (item) {
        return this.converter ? this.converter(item) : item ? item['label'] || item : '';
    };
    Object.defineProperty(SelectButtonComponent.prototype, "selectedLabel", {
        get: function () {
            var _this = this;
            var item = this.items.find(function (i) {
                return (_this.bindValue ? i[_this.bindValue] : i) === _this.selected;
            });
            return this.labelOf(item /*this.selected*/);
        },
        enumerable: true,
        configurable: true
    });
    SelectButtonComponent.prototype.isActive = function (item) {
        return this.selected === (this.bindValue ? item[this.bindValue] : item);
    };
    SelectButtonComponent.prototype.onSelectToggle = function () {
        var _this = this;
        var notSelected = this.items.find(function (item) { return !_this.isActive(item); });
        this.onSelect(notSelected);
    };
    SelectButtonComponent = __decorate([
        Logger({})
    ], SelectButtonComponent);
    return SelectButtonComponent;
}());
export { SelectButtonComponent };
