import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {parseBoolean} from "../_helpers/string.util";


@Injectable({providedIn: 'root'})
export class UserPreferencesService {
  private TIME_FORMAT_KEY: string = "TIME_INPUT_FORMAT_12";
  public timeFormat12$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    this.setTimeFormat12(parseBoolean(localStorage.getItem(this.TIME_FORMAT_KEY) || 'true'));
  }

  public getTimeFormat12(): Observable<boolean> {
    return this.timeFormat12$.asObservable();
  }

  public getTimeFormatLabel(): string {
    return this.timeFormat12$.value ? '12H' : '24H';
  }


  public setTimeFormat12(value: boolean) {
    if (value !== this.timeFormat12$.value) {
       this.timeFormat12$.next(value);
       localStorage.setItem(this.TIME_FORMAT_KEY, value.toString());
    }
  }

  public isTimeFormat12(): boolean {
    return this.timeFormat12$.value;
  }
}
