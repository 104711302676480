import {AfterViewInit, ChangeDetectorRef, Directive, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import { MatDialogContainer, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators/takeUntil';
import 'rxjs/add/observable/fromEvent';
import {ModalPositionCache, Position} from './modal-position-cache';
import { take } from 'rxjs/operators/take';

@Directive({
  selector: '[dialog-draggable-title]',
//  host: {
//    '[class.draggable]': "active",
//  }
})
export class DialogDraggableTitleDirective implements OnInit, AfterViewInit {
  @Input('dialog-draggable-title') active: boolean = true;
  @Input('keep-position') keepPosition: boolean = true;

  private _subscription: Subscription;
  mouseStart: Position;
  mouseDelta: Position;
  offset: Position;

  private static clamp(value: number, max: number, min: number): number {
    return Math.min(Math.max(min, value), max);
  }

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private container: MatDialogContainer,
    private positionCache: ModalPositionCache,
    private cdr: ChangeDetectorRef) {
  }

  @HostBinding('class.draggable')
  get draggable_class() {
    return this.active;
  }

  ngAfterViewInit() {
    if (this.keepPosition) {
      const dialogType = this.matDialogRef.componentInstance.constructor;
      const cachedValue = this.positionCache.get(dialogType);
      this.offset = cachedValue || this._getOffset();
      this._updatePosition(this.offset.y, this.offset.x);
      this.cdr.detectChanges();

      this.matDialogRef.beforeClose().pipe(take(1))
        .subscribe(() => this.positionCache.set(dialogType, this.offset));
    } else {
      this.offset = this._getOffset();
    }
  }

  ngOnInit() {
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (this.active && event.button === 0) {

      this.mouseStart = {x: event.pageX, y: event.pageY};
      this.mouseDelta = {x: 0, y: 0};

      const mouseup$ = Observable.fromEvent(document, 'mouseup');
      this._subscription = mouseup$.subscribe(() => this.onMouseUp());

      const mousemove$ = Observable.fromEvent(document, 'mousemove')
        .pipe(takeUntil(mouseup$))
        .subscribe((e: MouseEvent) => this.onMouseMove(e));

      this._subscription.add(mousemove$);
    }
  }

  onMouseMove(event: MouseEvent) {

    if (this.active && this._subscription) {
      this.mouseDelta = {
        x: (event.pageX - this.mouseStart.x),
        y: (event.pageY - this.mouseStart.y),
      };

      this.mouseStart = {x: event.pageX, y: event.pageY};

      this.offset.x += this.mouseDelta.x;
      this.offset.y += this.mouseDelta.y;

      this._updatePosition(this.offset.y, this.offset.x);
    }
  }

  onMouseUp() {
    if (this.active) {
      if (this._subscription) {
        this._subscription.unsubscribe();
        this._subscription = undefined;
      }

/*      if (this.mouseDelta) {
        this.offset.x += this.mouseDelta.x;
        this.offset.y += this.mouseDelta.y;
      } */
    }
  }

  private _updatePosition(top: number, left: number) {
    const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
    this.matDialogRef.updatePosition({
      top: DialogDraggableTitleDirective.clamp(top, window.innerHeight - box.height, 40) + 'px',
      left: DialogDraggableTitleDirective.clamp( left, window.innerWidth - box.width, 0) + 'px',

//      top: Math.max(0, top) + 'px',
//      left: Math.max(0, left) + 'px',
    });
  }

  private _getOffset(): Position {
    const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
    return {
      x: box.left + pageXOffset,
      y: box.top + pageYOffset
    };
  }
}

