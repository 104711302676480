export class PageResult<T> {
  content: Array<T>;
  last: boolean; // set to true if its the last page otherwise false
  first: boolean;  // set to true if it’s the first page otherwise false
  totalElements: number; // the total number of rows/records. In our example, we passed this to the ui-grid options $scope.gridOptions.totalItems to determine how many pages will be available
  totalPages: number; // the total number of pages which was derived from (totalElements / size)
  size: number; // the number of records per page, this was passed from the client via param size
  number: number; // the page number sent by the client, in our response the number is 0 because in our backend we are using an array of Students which is a zero-based index, so in our backend, we decrement the page number by 1
  sort: any; // the sorting parameter for the page
  numberOfElements: number; //– the number of rows/records return for the page

  constructor(from: T[]) {
    this.content = from;
    this.last = true;
    this.first = true;
    this.numberOfElements = this.totalElements = from ? from.length : 0;
    this.totalPages = this.totalElements ? 1 : 0;
  }

  public static buildEmpty<T>(): PageResult<T> {
    return {
      content: [],
      last: false,
      first: false,
      totalElements: 0,
      totalPages: 0,
      size: 0,
      number: 0,
      sort: null,
      numberOfElements: 0
    };
  }

  public static fromArray<T>(arr: T[]): PageResult<T> {
    return {
      content: arr,
      last: true,
      first: true,
      totalElements: arr ? arr.length : 0,
      totalPages: arr ? 1 : 0,
      size: arr ? arr.length : 0,
      number: 0,
      sort: null,
      numberOfElements: arr ? arr.length : 0
    };

  }
}
