import {Address} from './address/address';
import {CustomerGroup} from './customer.group';
import {Labeled} from '../../../common/oms-types';
import {BaseDeletable} from './base-deletable';
import {CustomerEmail} from "./customer-email";
import {Type} from "class-transformer";

export class Customer extends BaseDeletable implements Labeled {
  name: string;
  @Type(() => Address)
  address: Address;
  @Type(() => CustomerGroup)
  customerGroup: CustomerGroup;
  @Type(() => CustomerEmail)
  emails: CustomerEmail[] = [];
  createBOL: boolean;
  sendPODToEmails: boolean;

  get label() {
    return this.name;
  }
}
