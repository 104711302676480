import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  OnDestroy,
  OnInit, QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Load, Master, MasterStatus, Order, RecoveryOrder, Split} from '../../../modules/shared/models';
import {MasterService} from '../../../services/master/master.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ColumnIds} from '../../../common/column-ids';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {isNullOrUndefined} from 'util';
import {AuthService, OrdersService} from '../../../services';
import {
  FieldUpdateEvent,
  SELECTED_FLAG,
  TreeModel
} from '../../../modules/shared/components/base/data-table/data-table.utils';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterProcessor} from '../../../common/master-processor';
import {FileUploadService} from '../../../services/file.upload.service';
import {JsSocketSubscription, WebSocketService} from '../../../services/websocet/web.socket.service';
import {ClipboardCopyService} from '../../../modules/shared/services/clipboard.copy.service';
import {BaseColumn} from '../../../modules/shared/components/base/data-table/columns/column-types';
import {ContextMenuComponent} from 'ngx-contextmenu';
import {ColorItem} from '../../../components/common/color-search-select/color-item';
import {TabInfo, TabsType} from './tabs-type';
import {MasterManifestExcelRequest} from '../../../modules/shared/models/master.manifest.excel.request';
import {AbstractMasterTable} from './abstract-master-table';
import {LocationBackService} from '../../../services/location-back.service';
import {CopyToClipboardColumnsType} from '../../../common/copy-to-clipboard-orders';
import {RecoveryAndDispatchLogDialogComponent} from "../../../components/common/recovery-and-dispatch-log-dialog/recovery-and-dispatch-log-dialog.component";
import {MatDialog} from "@angular/material";
import {MawbTrackDialogComponent} from "../../../components/common/mawb-track-dialog/mawb-track-dialog.component";
import {ReceivingLogDialog} from '../../../components/dialogs/logs/receiving-log-dialog/receiving-log-dialog.component';
import {CellHandleEvent} from '../../../modules/shared/components/base/data-table/data-table.component';
import {convertMawbNumber, valueOrNA, valueOrNAFixed} from "../../../modules/shared/services/oms-converters.service";
import { Observable, of, Subject, Subscription} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {FilterSearchColumn} from "../../../modules/shared/models/filter.search.column";
import {MasterMode} from "../../../modules/shared/models/master/master-mode";
import {
  OrderSubscriptionDialogComponent,
  OrderSubscriptionDialogInput
} from "../../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import {OrderSubscriptionService} from "../../../services/order-subscription/order-subscription.service";
import {CbpStatusService} from "../../../modules/shared/services/stb-status/cbp.status.service";
import {CbpStatus} from "../../../modules/shared/models/cbp.status";
import {MasterLineService} from "../../../services/master/master-line.service";
import {Manifest} from "../../../modules/shared/models/manifest/manifest";
import {OrderDispatch} from "../../../modules/shared/models/dispatch/order-dispatch";
import {ManifestItem} from "../../../modules/shared/models/manifest/manifest-item";
import {DispatchService} from "../../../modules/shared/services/dispatch/dispatch.service";
import {DispatchUtils} from "../../../_helpers/dispatch-utils";
import {OmsColumns} from "../../../common/oms-columns.service";
import {LoggedUser} from "../../../modules/shared/models/logged-user";
import {UserUtil} from "../../../modules/settings/util/user.util";
import {assigned, openMailTo} from "../../../_helpers/utils";

@Component({
  selector: 'oms-masters-list',
  templateUrl: './masters-list.component.html',
  styleUrls: ['./masters-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MastersListComponent implements OnInit, OnDestroy, TreeModel, AfterViewInit {

  TabsType = TabsType;

  selectedTab: TabInfo;

  MasterMode = MasterMode;

  clientUser: boolean = false;

  availableTabs: TabInfo[] = [];

  webSocketEventName: string = '/oms-event/master-update'; // '/data-table/masters';
  activeSearchFilterCondition: any = {};

  public colorStatusFilter: ColorItem;

  recoveryMasters: any[] = [];
  cfsanddeliveryMasters: any[] = [];
  billingMasters: any[] = [];
  closedMasters: any[] = [];
  allMasters: any[] = [];
  pendingMasters: any[] = [];
  deletedMasters: any[] = [];

  countHolder: { [key: string]: number } = {};

  @ViewChildren('mastersTables') tabs: QueryList<AbstractMasterTable<any>>;
  @ViewChild('content') divContent: ElementRef<HTMLDivElement>;
  @ViewChild('mastersMenu') mastersMenu: ContextMenuComponent;
  @ViewChild('ordersMenu') ordersMenu: ContextMenuComponent;
  @ViewChild('splitsMenu') splitsMenu: ContextMenuComponent;

  activeTable: AbstractMasterTable<any>;

  private unsubscribe$ = new Subject<void>();
  private socketSub: JsSocketSubscription;

  public widthFirstPanel = 100;
  public widthSecondPanel = 0;
  public prevWidthFirstPanel = 35;
  public prevWidthSecondPanel = 65;
  public manifestForRightPanel: Manifest = new Manifest();
  public sub: Subscription;
  public orderDispatchLoads: OrderDispatch[] = [];
  currentUser: LoggedUser;


  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private alerts: OmsAlertsService,
    private masterProcessor: MasterProcessor,
    private authService: AuthService,
    public _masterService: MasterService,
    protected _masterLineService: MasterLineService,
    private spinner: NgxSpinnerService,
    private fileUploadService: FileUploadService,
    private webSocketService: WebSocketService,
    public ordersService: OrdersService,
    private clipboardCopyService: ClipboardCopyService,
    private locationBackService: LocationBackService,
    private orderSubscriptionService: OrderSubscriptionService,
    private cbpStatusService: CbpStatusService,
    private dispatchService: DispatchService,
    private dispatchUtils: DispatchUtils,
    private _columnsService: OmsColumns) {
  }

  private updatePathParams() {
    const params: Params = {
      tab: (this.selectedTab || this.availableTabs[0]).id,
    };

    let search = this.getSearchText();
    if (search) {
      params['search'] = search;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: params,
//        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }).then();
  }

  ngOnInit() {
    this.availableTabs = TabsType.allAirImportTabs;
    this.currentUser = UserUtil.getCurrentUser();
    this.initWebSocket();

    this.locationBackService.back$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (value === 'delete') {
        this.activeTable.updateData();
      }
    });

    this.route.queryParams.subscribe((params) => {
      console.log('Receive Params');
      let tab = params.tab;
      let search = params.search;
      if (search) {
        setTimeout(() => this.searchChanged(search), 500);
      }

      this.selectedTab = this.availableTabs.find((t) => t.id === tab);
      if (!this.selectedTab) {
        this.selectedTab = this.availableTabs[0];
        this.updatePathParams();
      }
      this.switchTab();
    });

    this.openManifestFromShipment();
  }

  ngAfterViewInit(): void {
    this.activeTable = this.getActiveTab();
    this.changeStatusFilterSearch(this.selectedTab/*acavailableTabs[0]*/);
    this.openManifestFromOrder();
  }

  private switchTab() {
    this.activeTable = this.getActiveTab();
    this.countHolder[this.selectedTab.filterName] = 0;
    if (this.activeTable && this.activeTable.isDirty) {
      this.activeTable.spinner.show();
      this.activeTable.updateData().pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => this.activeTable.spinner.hide(),
          error => {
            this.activeTable.spinner.hide().then();
            this.alerts.error(error);
          });
    }
    this.openManifestFromOrder();
  }

  onSelectTab(tabInfo: TabInfo) {
    this.selectedTab = tabInfo;
    this.updatePathParams();
    this.switchTab();
  }


  eventList(activeTab: TabInfo): number {
    return this.countHolder[activeTab.filterName] || 0;
  }

  initWebSocket() {
    this.webSocketService.stompClientSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((stompClient) => {
      if (!isNullOrUndefined(stompClient)) {

        this.socketSub = stompClient.subscribe(this.webSocketEventName,
          (message) => {
            const messageObject = JSON.parse(message.body);
            console.log('MESSAGE', messageObject);
            const statusId = messageObject.masterStatus.id;
//            let master = onAfterLoad(plainToClass(Master, messageObject.updatedValue as Master));

            if (messageObject.operation === 'CREATE') {
              if (!this.isMasterCreatedInSelectedTab(statusId)) {

                this.availableTabs
                  .filter(tab => tab.statuses && tab.statuses.indexOf(statusId) > -1)
                  .forEach(tab => {
                    this.countHolder[tab.filterName] = this.countHolder[tab.filterName] || 0;
                    this.countHolder[tab.filterName]++;
                  });

                this.changed();
              }
            } else if (messageObject.operation === 'UPDATE') {
              console.log('UPDATE', messageObject);
              this.availableTabs
                .filter(tab => tab.statuses && tab.statuses.indexOf(statusId) > -1)
                .forEach(tab => {
                  let table = this.tabs.find((t) => t.tabInfo.filterName === tab.filterName);
                  if (table) { table.isDirty = true; }
                });
              this.changed();

/*              // todo Objects are different for every tab, need check object type and refresh all tabs

              let activeTab = this.getActiveTab();
              if (activeTab) {
                console.log('UPDATED', master);
                activeTab.updateItems([master]);
              }

 */
            }
          });
      }
    });
  }

  isMasterCreatedInSelectedTab(statusId) {
//    const statusId = master.status;
    const newMasterCreated = this._masterService.newMasterCreated.getValue();
    if (newMasterCreated) {
      this._masterService.newMasterCreated.next(false);
      if (this.selectedTab.statuses && this.selectedTab.statuses.indexOf(statusId) > -1) {
        this.countHolder[this.selectedTab.filterName] = 0;
        return true;
      }
    }
    return false;
  }

  downloadExcel() {
    let table = this.getActiveTab();
    if (table) {
      (table.tabInfo.isOrder
        ? this.ordersService.downloadExcel(table.filterPageRequest, this.alerts)
        : this._masterLineService.downloadExcel(table.filterPageRequest, this.alerts))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
          const fileURL = this.buildShowUrl(res);
          window.open(fileURL, '_blank');
        });
    }
  }

  downloadExcelPendingRecoveries() {
    let table = this.getActiveTab();
    if (table) {
      table.filterPageRequest.filterByColumns.push(new FilterSearchColumn(ColumnIds.MASTER_PENDING_RECOVERIES, '1', null, null));
      (table.tabInfo.isOrder
        ? this.ordersService.downloadExcel(table.filterPageRequest, this.alerts)
        : this._masterService.downloadMasters(table.filterPageRequest, this.alerts))
        .subscribe(res => {
          const fileURL = this.buildShowUrl(res);
          window.open(fileURL, '_blank');
        });
    }
  }

  downloadInventory() {
    let table = this.getActiveTab();
    if (table) {
      let statusFilters = [...TabsType.CFS.tabFilters, ...TabsType.WHSE.tabFilters];
      table.filterPageRequest.statusFilters = statusFilters;
      (table.tabInfo.isOrder
        ? this.ordersService.downloadExcel(table.filterPageRequest, this.alerts)
        : this._masterService.downloadMasters(table.filterPageRequest, this.alerts))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
          const fileURL = this.buildShowUrl(res);
          window.open(fileURL, '_blank');
        });
    }
  }

  toggleSearchableFilter() {
    this.getActiveTab().searchable = !this.getActiveTab().searchable;
  }

  clearFilters() {
    this.getActiveTab().table.clearTableInputs();
  }

  isTableFilterNotClear(): boolean {
    let tab = this.getActiveTab();
    if (!tab || !tab.table) {
      return false;
    }
    return !tab.table.isClearTableInputs();
  }

  downloadNominationExcel() {
    let selectedMasters = this.selectedMasters;
    let selectedMasterIds = selectedMasters.map(item => item.id);
    this._masterService.downloadNominationMasters(selectedMasterIds).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const fileURL = this.buildShowUrl(res);
      window.open(fileURL, '_blank');
    });

  }

  downloadMastersManifest() {
    this._masterService.downloadMastersManifest(this.getActiveTab().filterPageRequest).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const fileURL = this.buildShowUrl(res);
      window.open(fileURL, '_blank');
    });
  }

  buildShowUrl(generatedDocumentInfo) {
    return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
  }

  changeStatusFilterSearch(activeTab: TabInfo) {
    if (activeTab.statuses) {
      this.activeSearchFilterCondition[ColumnIds.MASTER_STATUS] = activeTab.statuses;
    }
    // for start digest
    this.activeSearchFilterCondition = Object.assign({}, this.activeSearchFilterCondition);
    this.selectedTab = activeTab;
    this.countHolder[activeTab.filterName] = 0;

    this.updateData();
  }


  get defaultSortableColumn() {
    return ColumnIds.MASTER_STATUS;
  }

  public changed() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  public refresh() {
    this.updateData();
  }

  updateData() {
    this.callSync(this.tabs.toArray());
  }

  private callSync(items: AbstractMasterTable<any>[]) {
    if (items.length === 0) {
      return;
    }

    const findSelectedTab: AbstractMasterTable<any> = items.find((item) => item.tabInfo === this.selectedTab);
    const index = findSelectedTab ? items.indexOf(findSelectedTab) : 0;
    const tabToUpdate = findSelectedTab || items[0];

    if (tabToUpdate === findSelectedTab || tabToUpdate.tabInfo.preLoad) {
      setTimeout(() => {
        tabToUpdate.updateData().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
          items.splice(index, 1);
          this.callSync(items);
        });

      }, 500);
    } else {
      tabToUpdate.isDirty = true;
      items.splice(index, 1);
      this.callSync(items);
    }
  }

  public getSearchText(): string {
    let tab: AbstractMasterTable<any> = this.tabs.find((t) => assigned(t.getSearchText()));
    return tab && tab.getSearchText();
  }

  searchChanged(text: string) {
    this.tabs.forEach((tab) => tab.changeSearchText(text, 0));
    this.updatePathParams();
//    this.updateData();
  }

  searchColorChanged(event: ColorItem) {
    this.colorStatusFilter = event;
    this.tabs.forEach(table => {
      table.colorStatusFilter = event;
    });
    this.updateData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.socketSub) { this.socketSub.unsubscribe(); }
  }

  /*
    showMaster(data: Object) {
      // this.table.toggleExpanded(data);
    }

    onSelectMaster(event) {
    }
   */

  buildDownloadUrl(fileType: string) {
    return this._masterService.buildDownloadUrl(fileType);
  }

  onUpdateFieldError(error, updateEvent: FieldUpdateEvent) {
    if (updateEvent) {
      updateEvent.cancel();
    }

    if (typeof error === 'string' && error.includes('UNIQUE KEY')) {
      error = 'Duplicated value not allowed: ' + updateEvent.newValue;
    }

//    console.log('Update Field Error', updateEvent, error);
    this.alerts.danger(error);
  }

  children(parent): Observable<any[]> {
    if (parent instanceof Master) {
      return of([...parent.splits, ...parent.orders]);
    }

    return of([]);
  }

  isLeaf(parent): boolean {
    return !(parent instanceof Master);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.cdr.markForCheck();
//    this.calculateContentHeight();
  }

  get showDeleted(): boolean {
    return this.selectedTab && this.selectedTab === TabsType.MASTERS_DELETED;
  }

  copyDataToClipboard() {
    const activeTable = this.getActiveTab();
    let columns = activeTable.tabInfo.isOrder
      ? CopyToClipboardColumnsType.createOrderColumns()
      : CopyToClipboardColumnsType.createMasterLineColumns();
    let fileName = activeTable.tabInfo.isOrder
      ? 'orders'
      : 'masters';
    if (activeTable.tabInfo.isOrder) {
      this.clipboardCopyService.prepareItemsForCopyToClipboard(activeTable.items, columns, fileName);
    } else {
      let subject = this._masterService.mastersByIDs(activeTable.items.map((master) => master.id));
      subject
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((items) => {
          this.clipboardCopyService.prepareItemsForCopyToClipboard(items, columns, fileName);
        });
    }


    // this.clipboardCopyService.parseDataAndCopyToClipboard(parsedMasters);
  }

  copyDataToClipboardManifest() {
    let masters = this.getActiveTab().items;
    let masterIds = masters.map(master => master.id);
    let masterManifestExcelRequest = new MasterManifestExcelRequest(masterIds, null, null, null, null, true);
    this._masterService.downloadMastersManifestByManifest(masterManifestExcelRequest).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const fileURL = this.buildShowUrl(res);
      window.open(fileURL, '_blank');
    });
  }

  private getActiveTab(): AbstractMasterTable<any> {
    return this.tabs ? this.tabs.find(table => table.tabInfo.filterName === this.selectedTab.filterName) : null;
  }

  private getTab(tab: TabInfo): AbstractMasterTable<any> {
    return this.tabs ? this.tabs.find(table => table.tabInfo.filterName === tab.filterName) : null;
  }

  onCellRightClick(event: CellHandleEvent) {
    if (event.column.id === ColumnIds.MASTER_FILL_STATUS || event.column.id === ColumnIds.ORDER_FILL_STATUS) {
      console.log('Right Click Handler', event);
      this.showReceivingLog(event.row);
      event.handled = true;
    }
  }

  public openDispatchAndRecoveryLogs(obj: Master | RecoveryOrder, type: "MASTER" | "SPLIT"): void {
    const masterId = type === "MASTER" ? (<Master>obj).id : (<RecoveryOrder>obj).masterId;
    const shipmentId = type === "SPLIT" ? obj.shipment.id : null;
    const orderId = type === "SPLIT" ? (<RecoveryOrder>obj).orderId : null;
    const instance = this.dialog.open(RecoveryAndDispatchLogDialogComponent, {
      width: '68%'
    });
    instance.componentInstance.masterId = masterId;
    instance.componentInstance.shipmentId = shipmentId;
    instance.componentInstance.orderId = orderId;
    instance.componentInstance.objectType = type;
  }


  onCellClick(event: CellHandleEvent) {
    if (event.column.id === ColumnIds.MASTER_PICKUP_DATE || event.column.id === ColumnIds.ORDER_PICKUP_DATE) {
      if (event.row instanceof Master) {
//        this.pickupMaster(event.row);
        this.openDispatchAndRecoveryLogs(event.row, 'MASTER');
      } else if (event.row instanceof RecoveryOrder) {
        this.pickupLoad(event.row);
      }
    }
  }

  public pickupMaster(master: Master) {
    this.masterProcessor.updateMasterRecoveryPickUp(master)
      .then((m) => {
        console.log('PICKUP RESULT>', m);
        this.updateMaster(m);
      })
      .catch(error => this.alerts.error(error, 'Error Pickup'));
  }

  public pickupLoad(order: RecoveryOrder) {
    this.masterProcessor.updateMasterRecoveryPickUp(order.master, order)
      .then(master => {
        console.log('PICKUP RESULT>', master);
        this.updateMaster(master);
      })
      .catch(error => this.alerts.error(error, 'Error Pickup'));
  }


  contextMenu = (row, column?: BaseColumn): ContextMenuComponent => {
    if (column && (column.id === ColumnIds.MASTER_ID || column.id === 'id')) {
      if (row instanceof Master) {
        return this.mastersMenu;
      }

      if (row instanceof Order) {
        return this.ordersMenu;
      }

      if (row instanceof RecoveryOrder) {
        return this.splitsMenu;
      }
    }

    return null;

  }

  toggleMasterDirect(e) {
    let master = e.item.row;
    let direct = !master.direct;
    if (direct) {
      if (master.orders.asUniqueValue(o => o.addressDelivery, 'mixed') === 'mixed') {
        this.alerts.warning('Delivery Locations of Orders must be equal!', 3000);
        return;
      }
    }
    this._masterService._updateMaster(master, (m) => {
      this.masterProcessor.setDirect(m, direct);
      return true;
    })
      .then(() => {
        this.masterProcessor.setDirect(master, direct);
        this.updateData();
      });
  }

  toggleMasterNonAms(e) {
    let master = e.item.row;
    this._masterService._updateMaster(master, (m) => {
      m.nonAMS = !m.nonAMS;
      return true;
    }).then(() => {
      master.nonAMS = !master.nonAMS;
    });
  }

  get selectedMasters(): Master[] {
    let table = this.getActiveTab();
    if (table) {
      return table.selected.filter(o => {
        return o instanceof Master;
      });
    }
    return [];
  }

  get dispatchAllowed(): boolean {
    let table = this.getActiveTab();
    return table && [TabsType.CFS.filterName, TabsType.RECOVERY.filterName, TabsType.WHSE.filterName].includes(table.tabInfo.filterName) ? table.selected.length > 0 : false;
  }

  get manifestAllowed(): boolean {
    let table = this.getActiveTab();
    return table && [TabsType.CFS.filterName, TabsType.RECOVERY.filterName].includes(table.tabInfo.filterName) ? table.selected.length > 0 : false;
  }

  get showPendingRecoveryFilter() {
    return this.activeTable.tabInfo === TabsType.ACTIVE || this.activeTable.tabInfo === TabsType.RECOVERY;
  }

  public dispatch() {
    let table = this.getActiveTab();
    if (table) {
      switch (table.tabInfo.filterName) {
        case TabsType.CFS.filterName:
          this.dispatchOrdersDelivery(undefined, table);
          break;

        case TabsType.WHSE.filterName:
          this.dispatchOrdersDelivery(undefined, table);
          break;

        case TabsType.RECOVERY.filterName:
          this.openManifestFromMasters();
          break;
      }
    }
  }

  private dispatchOrdersDelivery(order: Order, table: AbstractMasterTable<Order>) {
    let orders: Order[] = [...table.selected];
    if (orders.length === 0 && order) {
      orders.push(order);
    }

    if (orders.length > 0) {
      this.masterProcessor.dispatchOrdersDelivery(orders)
        .catch(error => this.alerts.danger(error))
        .then((os) => {
          console.log('Dispatched', os);
          table.selected.clear();
          table.updateData();
//          this.activeTable.updateData(false);
//          this.changed();
        });
    }
  }

  public dispatchMastersRecovery(master?: Master) {
    console.log('HERE');
    let recovery = this.getTab(TabsType.RECOVERY);
    let masters: Master[] = recovery ? [...recovery.selected] : [];
    if (masters.length === 0 && master) {
      masters.push(master);
    }

    if (masters.length > 0) {
      this.masterProcessor.dispatchMastersRecovery(masters)
        .catch(error => this.alerts.danger(error))
        .then((ms) => {
          let table = this.getActiveTab().table;
          table.selected.clear();
//        masters.forEach(m=>this._masterService.getMaster(m.id).then(master=>this.table.refresh([master])));
          table.refresh(ms);
          this.changed();
        });
    }
  }

  openMultipleOrders(master?: Master) {
    let recovery = this.getTab(TabsType.RECOVERY);
    let masters: Master[] = recovery ? [...recovery.selected] : [];
    if (masters.length === 0 && master) {
      masters.push(master);
    }
    let ids: number[] = [];
    if (masters.length > 0) {
      masters.forEach((m) => { ids = ids.concat(m.orders.map(oi => oi.id)); });
      localStorage.setItem('multipleOrders' + this.currentUser.id, ids.toString());
      window.open('/warehouse/multiple-order', '_blank');
    }
  }

  public receive(object?: Master | RecoveryOrder) {
//    console.log('Receive', object, this.router);

    this.router.navigate(['receiving',
        {master: object instanceof Master ? object.id : object.masterId}],
      {relativeTo: this.route});
  }

  dispatchMastersMenuLabel(master: Master) {
    let recovery = this.getTab(TabsType.RECOVERY);
    return (!recovery || recovery.selected.length === 0) ? 'Dispatch Recovery...' : 'Dispatch ' + recovery.selected.length + ' Master(s)...';
  }

  isSupportDispatch(): boolean {
    return this.selectedTab !== TabsType.CFS && this.selectedTab !== TabsType.ACTIVE;
  }

  dispatchAndRecoveryLogs(obj: Master & RecoveryOrder, type: "MASTER" | "SPLIT"): void {
    const masterId = type === "MASTER" ? obj.id : obj.masterId;
    const shipmentId = type === "SPLIT" ? obj.shipment.id : null;
    const orderId = type === "SPLIT" ? obj.orderId : null;
    const instance = this.dialog.open(RecoveryAndDispatchLogDialogComponent, {
      width: '68%'
    });
    instance.componentInstance.masterId = masterId;
    instance.componentInstance.shipmentId = shipmentId;
    instance.componentInstance.orderId = orderId;
    instance.componentInstance.objectType = type;
  }

  showReceivingLog(obj: Master | Order | Split) {
    let master: Master;
    let load: Load;

    if (obj instanceof Master) {
      master = <Master>obj;
    } else if (obj instanceof Order) {
      master = obj.master;
    } else if (obj instanceof RecoveryOrder) {
      master = obj.master;
      load = obj.load;
    }

    this.dialog.open(ReceivingLogDialog, {data: {master: master, load: load}, width: 'auto;'});
  }


  sendMail(master: Master) {
    let email = this.getEmail(master) || '';

    let subject = (convertMawbNumber(master.mawbNumber) || '');
    subject += isNullOrUndefined(master.freightForwarderRef) || master.freightForwarderRef.length === 0 ? '' : '  REF ' + (master.freightForwarderRef || '');

    openMailTo(email, subject, [], '');
  }

  sendNominationMail(master: Master) {
    let selectedMails = [];
    selectedMails.push(master);
    this.activeTable.selected.forEach(item => {
      if (item.id !== master.id) {
        selectedMails.push(item);
      }
    });

    let body = "Hello,\nPlease nominate to JJS TRANSPORTATION - Firms code: E804 as follows:\n\n\n";

    let subject = selectedMails.length === 1 ? convertMawbNumber(master.mawbNumber) + " E804 nomination request" :
      "Multiple MAWBs E804 nomination request";

    selectedMails.forEach((m) => {
      body += convertMawbNumber(m.mawbNumber) + "\t\t";
      body += valueOrNA(m.pieces) + " PCS       \t";
      body += valueOrNAFixed(m.weight) + " KG       \t";
      body += m.orders.length + " HAWBS\n\n";
    });

    body += "Thank you,\n\n";

    body += this.getEmailFooter();
    let email = this.getEmail(master) || '';

    openMailTo(email, subject, ['ams@jjstransportation.com'], body);
  }

  openCbpStatusNotificationEmail(order: Order): void {
    this.cbpStatusService.findAllByOrderId(order.id)
      .subscribe(items => {
        let email = this.getEmailFromOrder(order) || '';
        if (!email && order.master) {
          email = this.getEmail(order.master) || '';
        }
        const cbpStatus = this.collectUniqCbpStatuses(items).join(", ");
        const hawb = order.hawb || '';
        const mawb = order.master ? convertMawbNumber(order.master.mawbNumber) : '';
        const ref = order.customerRef || '';
        const subject = "CBP status Notification " + mawb + ' ' + hawb + ' ' + ref;
        const body = "Hello,\n\n" +
          "We received a " + cbpStatus + " from US Customs for " + mawb + " - " + hawb + ".\n" +
          "Please update clearance status urgently.\n\n" +
          "Thank you.\n\n" +
          this.getEmailFooter();
        openMailTo(email, subject, ['ams@jjstransportation.com'], body);
      });
  }

  private collectUniqCbpStatuses(items: CbpStatus[]): string[] {
    const availableStatuses = ['1A', '1R', '1S', '1E', '1H', '2H', '3H', '4A'];
    const allStatuses = items.map(i => i.uscsFsnStatus).filter(s => !!s).map(s => s.toLowerCase());
    return availableStatuses.filter(s => allStatuses.includes(s.toLowerCase()));
  }

  private getEmailFooter(): string {
    let loggedUser = this.authService.user$.getValue();
    let content = loggedUser.firstName + " " + loggedUser.lastName + "\n";
    content += "AMS Team\n";
    content += "JJS Transportation & Distribution Co, Inc\n";
    content += "145 Hook Creek Blvd.\n";
    content += "Valley Stream, NY 11581\n";
    content += "Tel: (516)-256-1561\n";
    content += "www.jjstransportation.com\n";
    return content;
  }

  getFieldIfObjectNotNull(object, items) {
    if (isNullOrUndefined(object)) {
      return "";
    }
    if (items.length > 1) {
      return this.getFieldIfObjectNotNull(object[items.shift()], items);
    }
    return object[items[0]];
  }

  sendMailForOrder(order: Order) {
    let email = this.getEmailFromOrder(order) || '';

    let subject = isNullOrUndefined(order.master) ? convertMawbNumber(order.mawb || '') : convertMawbNumber(order.master.mawbNumber || '');
    subject += isNullOrUndefined(order.hawb) ? '' : '  HAWB ' + (order.hawb);
    subject += isNullOrUndefined(order.customerRef) ? '' : '  Ref ' + (order.customerRef);

    openMailTo(email, subject, [], "");
  }

  getEmail(master: Master): string {
    for (let i = 0; i < master.orders.length; i++) {
      let email = this.getEmailFromOrder(master.orders[i]);
      if (email) {
        return email;
      }
    }
    return null;
  }

  getEmailFromOrder(order: Order): string {
    if (order.customer && order.customer.emails) {
      for (let i = 0; i < order.customer.emails.length; i++) {
        let email = order.customer.emails[i];
        if (email && email.email) {
          return email.email;
        }
      }
    }
    return null;
  }

  showAsmDataDialog(obj) {
    this.dialog.open(MawbTrackDialogComponent, {width: '80%', data: {id: obj.id}});
  }

  private updateMaster(master: Master) {
    this.tabs.forEach(tab => tab.updateItems([master]));
    this.changed();
  }

  public updateObject(object: Master | Order, fields: {}): Promise<any> {
    if (object instanceof Master) {
      return this._masterService.updateMaster(object.id, fields)
        .then((master: Master) => this.updateMaster(master));
    } else if (object instanceof Order) {
      return this.ordersService.updateOrderOld(object.id, fields)
        .then((obj) => {
          let order: Order = obj instanceof Master ? obj.orders.find((o) => o.id === object.id) : object;
          if (order) {
            order['cfsLocation'] = object['cfsLocation'];
            this.tabs.forEach(tab => tab.updateItems([order]));
            order.mawb = object.mawb;
          }
        });
    }
  }


  public recalculateOrderStatus(order: Order) {
    console.log('recalculate', order);
    this.updateObject(order, {}).then();
  }

  public recalculateMasterStatus(master: Master) {
    this.updateObject(master, {}).then();
  }

  generateBOL(isOpenPDF: boolean, order: Order) {
    let ids: number[] = [];
    if (!isNullOrUndefined(order)) {
      ids = ids.concat(order.id);
    } else {
      let selectedMasters = this.selectedMasters;
      selectedMasters.forEach((master) => {
        ids = ids.concat(master.orders.map(oi => oi.id));
      });
    }
    this.ordersService.generateBOLs(ids).subscribe(response => {
      this.generateBOLOpenPDF(response, isOpenPDF);
    });
  }

  printBOL(order: Order) {
    let ids: number[] = [];
    if (!isNullOrUndefined(order)) {
      ids = ids.concat(order.id);
    } else {
      let selectedMasters = this.selectedMasters;
      selectedMasters.forEach((master) => {
        ids = ids.concat(master.orders.map((oi) => oi.id));
      });
    }

    this.ordersService.printBOLs(ids).subscribe((response) => {
      this.findBOLOpenPDF(response);
    });
  }

  public findBOLOpenPDF(response) {
    if (!isNullOrUndefined(response)) {
      this.ordersService.openBase64PDF(response);
      this.alerts.success('Find BOLs successful', 5000);
    } else {
      this.alerts.error('No BOL available');
    }
  }

  public generateBOLOpenPDF(response, isOpenPDF: boolean) {
    if (!isNullOrUndefined(response)) {
      if (isOpenPDF) {
        this.ordersService.openBase64PDF(response);
      }
      this.alerts.success('Generate BOL successful', 5000);
    } else {
      this.alerts.error('Error generate BOL');
    }
  }

  addFlagMaster(master: Master): void {
    this.addFlag(master.orders);
  }

  removeFlagMaster(master: Master): void {
    this.removeFlag(master.orders);
  }

  addFlag(orders: Order[]): void {
    const dialogRef = this.dialog.open<OrderSubscriptionDialogComponent, OrderSubscriptionDialogInput, MasterStatus[]>(OrderSubscriptionDialogComponent,
      {width: 'auto', data: {orders: orders}});
    dialogRef.afterClosed().subscribe(res => {
    });
  }

  removeFlag(orders: Order[]): void {
    this.orderSubscriptionService.deleteByOrders(orders.map(o => o.id))
      .subscribe(() => this.alerts.info("Flag removed", 20000));
  }

  changePrev(first: number, second: number): void {
    if (first <= 90) {
      this.prevWidthFirstPanel = first;
      this.prevWidthSecondPanel = second;
    }
  }

  dragEnd(event): void {
    this.widthFirstPanel = event.sizes[0];
    this.widthSecondPanel = event.sizes[1];
    this.changePrev(event.sizes[0], event.sizes[1]);
  }

  onManifestChange(manifest: Manifest): void {
    let items = this.orderDispatchLoads.filter(dispatchDto => manifest.items.some(manifestItem => manifestItem.hasOrder(dispatchDto.orderId)));
    this.setSelected(items);
    this.updateRightPanel();
  }

  onDispatchListSelectionChange(list: OrderDispatch[]): void {
    this.orderDispatchLoads = list;
    list.forEach((od: OrderDispatch) => {
      let created: ManifestItem = this.manifestForRightPanel.items.find((mi) => mi.orders.some(o => o.id === od.orderId));
      if (!created) {
        //   this.setOrderDispatchValuesFromOrder(od);
        this.dispatchUtils.manifestAddOrderDispatch(this.manifestForRightPanel, od);
      }
    });

    // remove from new Items only
    const manifestItems = this.manifestForRightPanel.items.filter((i) => i.isNew());
    manifestItems.forEach((mi) => {
      mi.orders.forEach((o) => {
        let selected = list.find((od) => od.orderId === o.id);
        if (!selected) {
          this.dispatchUtils.manifestDeleteOrder(this.manifestForRightPanel, o);
        }
      });
    });

    this.setDispatchListToRightPanel([...this.manifestForRightPanel.items]);
  }

  private setDispatchListToRightPanel(items: ManifestItem[]): void {
    this.manifestForRightPanel.items = items;
    this.manifestForRightPanel = Object.assign(new Manifest(), this.manifestForRightPanel);
    this.updateRightPanel();
  }


  private setSelected(items: OrderDispatch[]): void {
    this.orderDispatchLoads = items;
  }

  private updateRightPanel() {
    let list = this.manifestForRightPanel.items;
    if (!list.length && this.widthFirstPanel < 100) {
      this.changePrev(this.widthFirstPanel, this.widthSecondPanel);
      this.widthFirstPanel = 100;
      this.widthSecondPanel = 0;
    } else if (list.length && this.widthFirstPanel === 100) {
      this.widthFirstPanel = this.prevWidthFirstPanel;
      this.widthSecondPanel = this.prevWidthSecondPanel;
    }
  }

   onCreatedEvent(manifest: Manifest): void {
    this.manifestForRightPanel = new Manifest();
    this.setSelected([]);
    this.setDispatchListToRightPanel([]);
    this.clearSelection();
    this.refresh();
  }
  openManifestFromShipment() {
    this._columnsService.shipmentFromMasterList$.subscribe((id) => {
      if (id) {
        this.spinner.show();
        this.dispatchService.findOrderDispatchesFromShipment(id).then((od) => {
            if (od) {
              this.openManifest(od.id);
            }
          }).catch((error) => {
          this.spinner.hide();
          this.alerts.error(error, 'Error open Manifest');
        });
      }
    });
  }
  openManifestFromOrder() {
    if (this.activeTable) {
      this.activeTable.orderFromMasterList$.subscribe((orderId) => {
        if (orderId) {
          this.ordersChangeSelect([orderId]);
        }
      });
    }
  }

  public openManifestFromMasters(master?: Master) {
    let recovery = this.getTab(TabsType.RECOVERY);
    let masters: Master[] = recovery ? [...recovery.selected] : [];
    if (masters.length === 0 && master) {
      masters.push(master);
    }

    if (masters.length > 0) {
      let ids: number[] = masters.flatMap((m) => m.splits)
        .filter((ro: RecoveryOrder) => !ro.isDispatched)
        .map((ro) => ro.order.id)
        .unique();


/*      let orders: Order[] = [];
      masters.forEach((m) => {
        let recoveryOrders = m.splits.filter(ro => !ro.load || !ro.load.isRecovery).map((ro) => ro.order);
        orders = orders.concat(recoveryOrders);
      });
      let ids: number[] = orders.map((ow) => ow.id).unique();
*/
      this.ordersChangeSelect(ids);
    }
  }

  ordersChangeSelect(ids: number[]) {
    if (ids && ids.length) {
      this.spinner.show();
      console.log('findDispatchesForOrders', ids);
      this.dispatchService.findDispatchesForOrders(ids).then((list) => {
        console.log('Found Dispatches', list);
        this.spinner.hide();
        this.onDispatchListSelectionChange(list);
        this.cdr.detectChanges();
      }).catch((error) => {
        this.spinner.hide();
        this.alerts.error(error);
      });
    }
  }

  openManifest(dispatchId: number) {
    if (dispatchId) {
      console.log('openManifest');
      this.dispatchService.get(dispatchId)
        .subscribe(manifest => {
          this.spinner.hide();
          console.log('manifest received', manifest);
          manifest.items.forEach(mi => {
            mi.loadTypeOriginal = mi.loadType;
            mi.orders.forEach(o => {
              mi.loadTypeOriginal = mi.loadType;
              o.info.legAddressDelivery = mi.addressDelivery;
            });
          });
          this.manifestForRightPanel = manifest;
          this.updateRightPanel();
          this.cdr.detectChanges();
        }, error => {
          this.spinner.hide();
          this.alerts.error(error, 'Error open Manifest');
        });
    }
  }

  clearSelection() {
    let table = this.getActiveTab();
    if (table) {
      table.selected.forEach((item) => delete item[SELECTED_FLAG]);
      table.selected.clear();
      table.selectedChange.emit(table.selected);
    }
  }
}
