<oms-string-input
  [history]="history"
  [number]="true" [label]="label" [placeholder]="_constants.currencyPlaceholder" [size]="size" [readonly]="readonly" [disabled]="disabled"
  [input-mask]="_constants.currencyMask" [(ngModel)] = "internal">
    <!--ng-container input-before-->
      <ng-content input-before></ng-content>
    <!--/ng-container-->

    <span input-after class="input-group-btn">
      <oms-select-button [(selected)]="units" (selectedChange)="onUnitsChanged($event)" [items]="_constants.currencyUnits.getAll()"></oms-select-button>
    </span>
</oms-string-input>
