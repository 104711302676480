import {OrderWarehouse} from "./order.warehouse";
import {OrderPart} from "./order-part";
import {Receiving} from "./receiving";


export class LoadOut extends Receiving {

  constructor(orders?: OrderWarehouse[]) {
    super();
    if (orders) {
      orders.forEach((o) => this.addPart(o));
    }
  }

  protected fromOrderWarehouse(o: OrderWarehouse): OrderPart {
    let part: OrderPart = new OrderPart();

    part.order = {
      id: o.id,
      hawb: o.hawb,
      pieces: o.pieces,
      hu: o.hu,
      customerRef: o.customerRef,
      purchaseOrder: o.purchaseOrder,
      ref3: o.ref3,
      addressFrom: o.addressRouteFrom,
      addressTo: o.addressDelivery,
      mode: o.genericMode
    };

    part.pieces = part.pcsProcessed = o.pcsLoadOut || 0;
    part.hu = part.huProcessed = o.huLoadOut || 0;
    part.weightProcessed = o.weight;

    part.orderNumber = o.routeOutOrderNumber;
    part.address = o.addressWhse;
    part.status =  o.loadOutStatus; // */ o.routeOutProblem ? Status.NOT_COMPLETED : (o.pcsLoadOut >= o.pieces ? Status.COMPLETED : Status.UNDEFINED);
    part.routeProblem = o.routeOutProblem;
    part.sealNumber = o.routeOutSealNumber;
    part.dispatchId = o.routeOutId;
    part.shipmentId = o.routeOutShipmentId;
    part.masterId = o.masterId;

    if (o.routeOut) {
      this.sealNumber = o.routeOutSealNumber;
      this.driver = o.routeOut.driver;
      this.carrier = o.routeOut.carrier;
      this.truck = o.routeOut.truck;
      this.trailer = o.routeOut.trailer;
    }

    return part;
  }

}
