﻿<oms-base-dialog [title]="getTitle()" [icon]="'fa fa-industry'" (ok)="onSubmit()" [loading]="viewLoading" min-width="460px">

  <ng-container widget-footer-buttons *ngIf="form.controls['freightForwarderGroup'].value">
    <label>CFS Status Page:</label>
    <a [href]="getStatusPageUrl()" target="_blank">{{getStatusPageUrl()}}</a>
    <button class="btn btn-clean" popover="Copy URL to Clipboard" (click)="copyStatusPageUrl()">
      <i class="fa fa-copy"></i>
    </button>
  </ng-container>



  <form [formGroup]="form" style="min-height: 300px;">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Name</label>
          <input class="form-control" placeholder="Freight Forwarder Name" formControlName="name" />
          <div *ngIf="isControlInvalid('name')" class="invalid-feedback">Name is required</div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="form-row col-md-12">
        <div class="form-group">
          <label class="control-label">Freight Forwarder Group</label>
          <ng-select
            appendTo="body"
            bindLabel="name"
            [items]="freightForwarderGroupSearch.items$ | async"
            [loading]="freightForwarderGroupSearch.loading"
            [typeahead]="freightForwarderGroupSearch.input$"
            [addTag]="true"
            [addTagText]="'Create new freight forwarder group'"
            formControlName="freightForwarderGroup"
            [ngClass]="{'form-control-invalid':isControlInvalid('freightForwarderGroup')}"
            placeholder="Freight Forwarder Group">
          </ng-select>
          <div *ngIf="isControlInvalid('freightForwarderGroup')" class="invalid-feedback">Freight Forwarder is mandatory</div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Address</label>
          <ng-select
            bindLabel="name"
            [items]="addressSearch.items$ | async"
            [loading]="addressSearch.loading"
            [typeahead]="addressSearch.input$"
            [compareWith]="compareById"
            formControlName="address"
            [ngClass]="{'form-control-invalid':isControlInvalid('address')}"
            placeholder="Address">
          </ng-select>
          <div *ngIf="isControlInvalid('address')" class="invalid-feedback">Address is mandatory</div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Email</label>
            <ng-select
              appendTo="body"
              placeholder="Email"
              formControlName="email"
              [items]="emails"
              [addTag]="true"
              [multiple]="true"
              [addTagText]="'Create new customer email'"
              [ngClass]="{'form-control-invalid':isControlInvalid('email')}">
            </ng-select>
            <div *ngIf="isControlInvalid('email')" class="invalid-feedback">Email is mandatory</div>
          </div>
        </div>
      </div>

      <div class="form-row col-md-12">
        <div class="form-input">
          <label class="fancy-checkbox _green">
            <input type="checkbox" formControlName="sendPODToEmails">
            <span>Send POD to emails</span>
          </label>
        </div>
      </div>
    </div>
  </form>
</oms-base-dialog>
