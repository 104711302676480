<oms-slide-panel [activePane]="displayChildPage ? 'right' : 'left'">
  <span leftPane>
    <div class="content">
      <div class="row main-header-row" *ngIf="showHeader">
        <div class="main-header col-md-3">
          <h2>{{title}}</h2>
          <em>{{subtitle}}</em>
        </div>
        <div class="col-md-9 main-header-right">
          <ng-content select="[page-header]"></ng-content>
        </div>
      </div>

      <div #mainContent class="main-content">
        <oms-spinner [show]="loading" [fullScreen]="true"></oms-spinner>
        <ng-content></ng-content>
        <ng-content select="[page-body]"></ng-content>


        <div id="footer" #footer class="main-content-bottom if-not-empty" (change)="checkSize()">
          <ng-content select="[page-bottom]"></ng-content>
        </div>
      </div>
    </div>
  </span>

  <span rightPane>
    <router-outlet></router-outlet>
  </span>
</oms-slide-panel>


