import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {RestService} from "../../../../services/rest.service";
import {HttpUtilsService} from "../../../../services/http-utils.service";
import {plainToClass} from "class-transformer";
import {CbpStatus} from "../../models/cbp.status";
import {BaseEntityService} from "../../../../services/base/base-entity.service";
import {map, tap} from "rxjs/operators";
import {HttpHeaders, HttpParams} from "@angular/common/http";

@Injectable()
export class CbpStatusService extends BaseEntityService<CbpStatus> {
  apiUrl = '/oms/cbp-status/';
  classType = CbpStatus;

  public cbpStatusSubject = new BehaviorSubject<CbpStatus[]>([]);

  public constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  findAllByMasterId(id: number, uscsFsnStatus?: string): Observable<CbpStatus[]> {
    let params: HttpParams = new HttpParams();
    if (uscsFsnStatus) {
      params = params.append('uscsFsnStatus', uscsFsnStatus);
    }

    let headers: HttpHeaders = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.getWithOptions<CbpStatus[]>(this.apiUrl + 'find-by-master-id/' + id, headers, params)
      .pipe(
        map((items) => plainToClass(CbpStatus, items as Object[])),
        tap((items) => this.cbpStatusSubject.next(items))
      );
  }

  findAllByOrderId(id: number): Observable<CbpStatus[]> {
    let headers: HttpHeaders = this.httpUtils.getHTTPHeaders();
    return this.httpRestService.getWithHeader<CbpStatus[]>(this.apiUrl + 'find-by-order-id/' + id, headers)
      .pipe(
        map((items) => plainToClass(CbpStatus, items as Object[])),
        tap((items) => this.cbpStatusSubject.next(items))
      );
  }
}
