import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material";
import {CustomerService} from "../../../../services/customer.service";
import {SplitOrderDialogComponent} from "../../../dispatch/pages/manifest-create/split-order-dialog/split-order-dialog.component";
import {NgSelectSearchParams} from "../../../settings/util/ng-select-search-params";
import {OmsAlertsService} from "../../../shared/components/oms-alerts/oms-alerts.service";
import {BaseColumn, DateValue} from "../../../shared/components/base/data-table/columns/column-types";
import {AbstractEntityListComponent} from "../../../settings/pages/abstract-entity-list.component";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {OmsColumns} from "../../../../common/oms-columns.service";
import {FileUploadService} from '../../../../services/file.upload.service';
import {OmsDialogsService} from "../../../../components/common/oms-dialogs";
import {Customer, Driver, MasterStatus, MasterStatusId, Order} from "../../../shared/models";
import {AuthService, OrdersService} from "../../../../services";
import {ColumnType, getDocumentLink} from "../../../../common/column-type";
import {OmsConstants} from "../../../../common/oms-constants.service";
import {MasterService} from "../../../../services/master/master.service";
import {CarrierService} from "../../../shared/services/carrier.service";
import {NgxSpinnerService} from 'ngx-spinner';
import {isNullOrUndefined} from 'util';
import {AddressMapInfoDialogComponent} from "../../../shared/components/address-map-info-dialog/address-map-info-dialog.component";
import {UserVisibilityService} from "../../../shared/services/user-visibility.service";
import {UserVisibilityFilter} from "../../../shared/models/uservisibilityfilter/user-visibility-filter";
import {FilterSearchColumn} from "../../../shared/models/filter.search.column";
import {OrderStatusFilter} from "./order-status-filter";
import {OrderMode, OrderModes} from "../../../shared/models/order/order-mode";
import {UserService} from "../../../shared/services/user.service";
import {StateService} from "../../../shared/services/state/state.service";
import {ArrayUtils} from "../../../../_helpers/array.utils";
import {OrderModeItem} from "../../../shared/models/order/order-mode-item";
import {ContextMenuComponent} from "ngx-contextmenu";
import {map, takeUntil} from "rxjs/operators";
import {Carrier} from "../../../shared/models/carrier";
import {NotesCenterDialogComponent} from "../../../../components/common/notes-center-dialog";
import {ComCenterService} from "../../../../services/com-center.service";
import {ObjectComCenter} from "../../../shared/models/comcenter/object.com.center";
import {forkJoin, Observable} from "rxjs";
import {DispatchService} from "../../../shared/services/dispatch/dispatch.service";
import {SelectEntityDialogComponent} from "./select-entity-dialog/select-entity-dialog.component";
import {SelectTextDialogComponent} from "./enter-text-dialog/select-text-dialog.component";
import {CarrierDialogComponent} from "../../../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import {FieldUpdateEvent} from "../../../shared/components/base/data-table/data-table.utils";
import {OrderSubscriptionService} from "../../../../services/order-subscription/order-subscription.service";
import {
  OrderSubscriptionDialogComponent,
  OrderSubscriptionDialogInput
} from "./order-subscription-dialog/order-subscription-dialog.component";
import {LoggedUser} from "../../../shared/models/logged-user";
import {UserUtil} from "../../../settings/util/user.util";
import {FileUploadDialogComponent} from "../../../../components/common/file-upload-dialog";
import {OrderModeFilter} from "./order-mode-filter";
import {HyperLink, Size} from "../../../../common/oms-types";
import {FilterPageRequest} from "../../../shared/models/filter.page.request";
import {PageResult} from "../../../shared/models/query-models/page-result";
import {cleanSearchString} from "../../../../_helpers/string.util";

@Component({
  selector: 'oms-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})

export class OrdersListComponent extends AbstractEntityListComponent<Order> implements OnInit {

  @ViewChild('headerContainer') headerContainer: ElementRef;
  @ViewChild('menu') menu: ContextMenuComponent;

  allOtherOption = this.createAllOtherOption();
  columns: BaseColumn[];

  defaultSortColumn: string = "id";
  userVisibilityFilters: UserVisibilityFilter[] = [];
  statusFilters: OrderStatusFilter[] = this.createStatusFilters();
  orderModeGroups: OrderModeFilter[] = this.createOrderModes();

  startVisibleItem = 0;
  MAX_DISPLAY_ITEMS = 22;

  selectedUserVisibilityFilters: UserVisibilityFilter[] = [];

  orderStatusColumn: BaseColumn;
  modeColumn: BaseColumn;

  isDeleteAvailable: boolean = false;
  displayStatuses = [...MasterStatus.items, MasterStatus.VOIDED];
  availableStatuses: MasterStatus[] = [];
  private statusToggleTimer;
  currentUser: LoggedUser;
  Size = Size;

  public search: {drivers?: Driver[], carrier?: Carrier, test?: number} = {drivers: []};

  constructor(
    protected dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    protected alerts: OmsAlertsService,
    protected router: Router,
    protected ordersService: OrdersService,
    protected dialogs: OmsDialogsService,
    protected omsColumns: OmsColumns,
    protected fileUploadService: FileUploadService,
    protected constants: OmsConstants,
    protected masterService: MasterService,
    protected carrierService: CarrierService,
    protected spinner: NgxSpinnerService,
    protected userVisibilityFilterService: UserVisibilityService,
    protected authService: AuthService,
    protected stateService: StateService,
    protected comCenterService: ComCenterService,
    protected dispatchService: DispatchService,
    protected customerService: CustomerService,
    protected userService: UserService,
    protected orderSubscriptionService: OrderSubscriptionService,
    protected route: ActivatedRoute
  ) {
    super(cdr, alerts, ordersService, dialogs, fileUploadService, spinner);
    this.paging.pageSize = 100;
    this.showArchived = true;
  }

  ngOnInit() {
    this.columns = this.buildColumns();
    this.request = this.createDefaultRequest(this.defaultSortColumn, true);
    this.initCanDeleteOrder();

    this.initUserVisibilityFilters().then(() => {
      this.selectSavedStates();
      this.updateStatuses();
      let modes = this.collectOrderModeItems();
      this.modeColumn.setItems(modes);
      this.modeColumn.setSelectedItems(modes);
      this.applyAllHeaderFilters();
    });
    this.currentUser = UserUtil.getCurrentUser();

    this.route.queryParams.subscribe((params) => {

      let search: Map<string, any> = new Map<string, any>();
      if (params.id) {
        search.set('id', params.id);
      }

      if (search.size > 0) {
        setTimeout(() => {
          this.table.setSearch(search);
        }, 100);

      }


    });

  }

  private updatePathParams() {
    const params: Params = {};
    let search = this.table.getSearch();
    if (search && search.size) {
      search.forEach((value, key) => {
        params[key] = value;
      });
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: params,
//        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }).then();
  }


  private updateStatuses(): void {
    let statuses = this.collectStatuses();
    this.orderStatusColumn.setItems(statuses);
    this.orderStatusColumn.setSelectedItems(statuses);
    this.availableStatuses = [...statuses];
  }

  private selectSavedStates() {
    let ordersState = this.stateService.getOrdersState();
    if (!ordersState) {
      return;
    }
    if (ordersState.topUserVisibilityFilters) {
      this.selectedUserVisibilityFilters = this.userVisibilityFilters.filter(cf => ordersState.topUserVisibilityFilters.includes(cf.id));
    }
    if (ordersState.modeFilters) {
      this.orderModeGroups.filter(om => ordersState.modeFilters.includes(om.id))
        .forEach(om => om.isSelected = true);
    }
    if (ordersState.statusFilters) {
      this.statusFilters.forEach(sf => sf.isSelected = ordersState.statusFilters.includes(sf.statusValue));
    }
  }

  private initUserVisibilityFilters(): Promise<any> {
    return this.userVisibilityFilterService.find(0, 100).then(userVisibilityFilters => {
      if (userVisibilityFilters.content.length) {
        this.userVisibilityFilters = userVisibilityFilters.content.concat([this.allOtherOption]);
      }
    });
  }

  private initCanDeleteOrder() {
    this.isDeleteAvailable = this.authService.canDeleteOrder();
  }

  private collectOrderModeItems(): OrderModeItem[] {
    let modes = this.collectSelectedModesFromTop();
    return OrderModes.filterItems.filter((fi) => !modes.length || modes.includes(fi.id));
  }

  private createOrderModes(): OrderModeFilter[] {
    return [{id: 0, name: 'AE', tooltip: 'Air Export', modes: [OrderMode.AEDIR, OrderMode.AEMSS]},
      {id: 1, name: 'AI', tooltip: 'Air Import', modes: [OrderMode.AICFS, OrderMode.AIDOM]},
      {id: 2, name: 'FCLE', tooltip: 'FCL Export', modes: [OrderMode.FCLEX]},
      {id: 3, name: 'FCLI', tooltip: 'FCL Import', modes: [OrderMode.FCLIM]},
      {id: 4, name: 'DOM', tooltip: 'Domestic Load', modes: [OrderMode.DOMLD]},
      {id: 5, name: 'RLD', tooltip: 'Recovery orders', modes: [OrderMode.RECOV]},
      {id: 6, name: 'LTL', tooltip: 'LTL', modes: [OrderMode.LTL]},
      {id: 7, name: 'FTL', tooltip: 'FTL', modes: [OrderMode.FTL]},
      {id: 8, name: 'SHUTTLE', tooltip: 'Shuttle', modes: [OrderMode.SHUTTLE]},
    ];
  }

  private createAllOtherOption(): UserVisibilityFilter {
    let filter = new UserVisibilityFilter();
    filter.name = 'All other';
    return filter;
  }

  private applyRemoveVoidedStatus(columns: FilterSearchColumn[]): FilterSearchColumn[] {
    if (columns.some(c => c.field === 'status')) {
      return columns;
    }
    // columns.push(new FilterSearchColumn('status', null, MasterStatus.allStatusesIds.map(s => '' + s)));
    return columns;
  }

  private createStatusFilters(): OrderStatusFilter[] {
    return [
      {
        name: 'Pending',
        tooltip: 'Pending',
        statuses: this.getPendingStatus(),
        statusValue: 'pending'
      },
      {
        name: 'Active',
        tooltip: 'Active',
        isSelected: true,
        statuses: this.getActiveStatus(),
        statusValue: 'active'
      },
      {
        name: 'Delivered 30', tooltip: 'delivered in last 30 days',
        statuses: this.getDeliveredStatus(),
        statusValue: 'delivered_30'
      },
      {
        name: 'Delivered 90', tooltip: 'delivered in last 90 days',
        statuses: this.getDeliveredStatus(),
        statusValue: 'delivered_90'
      },
      {
        name: 'Dlvd All',
        tooltip: 'Delivered all',
        statuses: this.getDeliveredStatus(),
        statusValue: 'delivered_all'
      },
      {
        name: 'Deleted',
        tooltip: 'Deleted/Void',
        statuses: this.getDeletedStatus(),
        statusValue: 'deleted'
      }
    ];
  }

  private getPendingStatus(): MasterStatus[] {
    return MasterStatus.items.filter(ms => ms.id <= MasterStatusId.FLIGHT_ARRIVED);
  }

  getActiveStatus(): MasterStatus[] {
    return MasterStatus.items.filter(ms => ms.id >= MasterStatusId.RECOVERY_DISPATCHED && ms.id < MasterStatusId.DELIVERED_PENDING_POD);
  }

  private getDeletedStatus(): MasterStatus[] {
    return [MasterStatus.get(MasterStatusId.VOIDED)];
  }

  private getDeliveredStatus(): MasterStatus[] {
    return MasterStatus.items.filter(ms => ms.id >= MasterStatusId.DELIVERED_PENDING_POD);
  }

  buildColumns(): BaseColumn[] {
    let carrierColumn: BaseColumn = null;
    let columns: BaseColumn[] = [];
    columns = columns.concat([
      ColumnType.ORDER_JJSM(true),
      this.modeColumn = ColumnType.GENERIC_MODE
        .clone(),
      ColumnType.ORDER_DOC_CENTER(false, this.ordersService, this.dialogs),
      ColumnType.ORDER_OSD,
      ColumnType.ORDER_HAZ,
      ColumnType.ORDER_COM,
      ColumnType.ORDER_CUSTOMER_SEARCH((s) => this.searchCustomer(s)),
      ColumnType.ORDER_MAWB(true),
      ColumnType.ORDER_HAWB(false),
      ColumnType.ORDER_CUSTOMER_REF
        .clone()
        .setEditor(false),
      ColumnType.ORDER_PO.clone()
        .setLabel('PO Number')
        .setWidth('100px')
        .setSearch({searchable: true, search: '', searchFunction: '', baseLineSearch: true})
        .setEditor(false),
      ColumnType.ORDER_FF,
      ColumnType.ORDER_DATE_DELIVERY_REQUESTED,
      ColumnType.ORDER_HU,
      ColumnType.ORDER_PCS(this.constants.piecesMask, this.constants.piecesPlaceholder),
      ColumnType.ORDER_WEIGHT_KG,
      ColumnType.ORDER_RCVD,
      this.orderStatusColumn = ColumnType.ORDER_STATUS([]),
      // pickup location
      ColumnType.ORDER_PICKUP_ADDRESS
        .clone()
        .setHandlers({onClick: o => this.addressInfoPopup(o.addressDelivery)}),
      ColumnType.ORDER_DELIVERY_LOCATION
        .clone()
        .setHandlers({onClick: o => this.addressInfoPopup(o.addressDelivery)})
        .setEditor(false),
      ColumnType.ORDER_PICKUP_DATE(this.masterService),
      ColumnType.ORDER_DELIVERY_DATE()
        .setHistory({logChanges: true}),
      ColumnType.ORDER_DATE_DOCS_TO_WAREHOUSE(),
      carrierColumn = ColumnType.CARRIER
        .clone()
        .setEditor(false), /*        .setEditor({
          editable: (o: Order) => !this.masterService.isDisableOrder(o),
          cleanable: false,
          select: {
            bindLabel: 'name',
            search: this.carrierService
          } as SelectableOptions<Carrier>
        }),*/
      ColumnType.ORDER_DELIVERY_DRIVER
        .clone()
        .setSearch({
          searchable: true,
          searchType: 'list',
          multiple: true,
          displayFilter: true,
          items: (text: string) => searchDriver(this.userService, text)
        }),
//        .setSearch(false),
      ColumnType.ORDER_CFS_LOCATION,
//      ColumnType.LAST_UPDATED_DATE,
      ColumnType.CREATED_BY,
      ColumnType.REQUESTED_BY,
      ColumnType.DATE_CREATED,
      ColumnType.UPDATED_BY,
      ColumnType.LAST_UPDATED_DATE_TIME,
      ColumnType.ORDER_1F_BATTERY,
      ColumnType.ORDER_1C_BATTERY,
      ColumnType.DATE_BILLED
        .clone()
        .setEditor(false)
    ]);


    if (this.authService.getCurrentUser().canCreateCarrier) {
      carrierColumn.setEditor({select: {createNewHandler: () => this.createCarrierDialog()}});
    }
    return columns;
  }

  navigateToHome() {
    this.router.navigate(["/home"]).then();
  }

  private createCarrierDialog(): Observable<Carrier> {
    const dialogRef = this.dialog.open<CarrierDialogComponent, any, { res: Carrier, isEdit: boolean }>(CarrierDialogComponent, {
      width: 'auto'
    });
    dialogRef.componentInstance.carrier = null;
    dialogRef.componentInstance.displayInHouse = false;
    return dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(map((response) => response.res));
  }

  addressInfoPopup(address) {
    if (!isNullOrUndefined(address)) {
      this.dialog.open(AddressMapInfoDialogComponent, {
        width: '50%',
        data: {address: address, searchByAddress: true}
      });
    }
  }

  applyUserVisibilityFilter(userVisibilityFilter: UserVisibilityFilter): void {
    if (this.isSelectedUserVisibilityFilter(userVisibilityFilter)) {
      this.selectedUserVisibilityFilters = this.selectedUserVisibilityFilters.filter(cf => cf !== userVisibilityFilter);
    } else {
      this.selectedUserVisibilityFilters.push(userVisibilityFilter);
    }
    let modes = this.selectedUserVisibilityFilters
      .filter(f => f.modes)
      .map(f => f.modes)
      .reduce((total, innerModes) => total.concat(innerModes), []);
    modes = ArrayUtils.removeDuplicate(modes, (mode) => mode.id);
    if (userVisibilityFilter.modes && userVisibilityFilter.modes.length) {
      this.orderModeGroups.forEach(orderMode => {
        orderMode.isSelected = modes.some(m => orderMode.modes.includes(m.id));
      });
      this.applyOrderModes();
    } else {
      this.applyAllHeaderFilters();
    }
  }

  isSelectedUserVisibilityFilter(userVisibilityFilter: UserVisibilityFilter): boolean {
    return this.selectedUserVisibilityFilters.some(cf => cf === userVisibilityFilter);
  }

  private collectFiltersByCustomer(): FilterSearchColumn[] {
    let baseFilterColumns: FilterSearchColumn[] = [];
    if (this.selectedUserVisibilityFilters.length) {
      let selectedUserVisibilityFilterIds = this.selectedUserVisibilityFilters.map(cf => {
        if (cf === this.allOtherOption) {
          return '-1';
        }
        return '' + cf.id;
      });
      baseFilterColumns.push(new FilterSearchColumn('customerTopFilter', null, selectedUserVisibilityFilterIds));
    }
    return baseFilterColumns;
  }

  private collectFiltersByStatusFilter(): FilterSearchColumn[] {
    let names = this.statusFilters.filter(sf => sf.isSelected)
      .map(sf => sf.statusValue);
    return [new FilterSearchColumn('statusFilter', null, names)];
  }

  clickStatusFilter(statusFilter: OrderStatusFilter): void {
    statusFilter.isSelected = !statusFilter.isSelected;
    this.applyStatusFilters();
  }

  private applyStatusFilters() {
    this.updateStatuses();
    this.applyAllHeaderFilters();
  }

  private collectStatuses(): MasterStatus[] {
    const selectedStatuses = this.statusFilters.filter(sf => sf.isSelected);
    let statuses = (selectedStatuses.length ? selectedStatuses : this.statusFilters)
      .map(sf => sf.statuses)
      .reduce((total, filters) => total.concat(filters), []);

    return ArrayUtils.removeDuplicate(statuses, (status) => status.id);
  }

  clickOrderMode(orderMode: OrderModeFilter): void {
    orderMode.isSelected = !orderMode.isSelected;
    this.applyOrderModes();
  }

  private applyOrderModes() {
    let modes = this.collectOrderModeItems();
    this.modeColumn.setItems(modes);
    this.modeColumn.setSelectedItems(modes);
    this.applyAllHeaderFilters();
  }

  private applyAllHeaderFilters() {
    let baseFilterColumns = this.collectFiltersByCustomer().concat(this.collectFiltersByStatusFilter());
    let selectedOrderModeIds = this.collectSelectedModesFromTop().map((id) => '' + id);
    if (selectedOrderModeIds.length) {
      baseFilterColumns.push(new FilterSearchColumn('orderMode', null, selectedOrderModeIds));
    }
    this.applyRemoveVoidedStatus(baseFilterColumns);
    this.setBaseFilterColumns(baseFilterColumns);
    this.table.buildSearchRequest();
    this.updateState();
  }

  private collectSelectedModesFromTop(): OrderMode[] {
    return this.orderModeGroups.filter(orderMode => orderMode.isSelected)
      .map((orderMode) => orderMode.modes)
      .reduce((total, items) => total.concat(items), []);
  }

  prevStep() {
    this.startVisibleItem = Math.max(0, this.startVisibleItem - 1);
  }

  nextStep() {
    this.startVisibleItem = Math.min(this.userVisibilityFilters.length - this.MAX_DISPLAY_ITEMS, this.startVisibleItem + 1);
  }

  refresh() {
    this.updateItems();
  }

  onSearch(request: FilterPageRequest) {
    request.setColumnFilter('drivers', (this.search.drivers || []).map(d => d.id + ''));
//    this.updatePathParams();
//    console.log('SEARCH', request, this.table.getSearch());
    super.onSearch(request);
  }


  public downloadSelectedOrdersExcel() {
    if (!this.selected.length) {
      this.alerts.warning("Orders is not selected.", 30000);
      return;
    }
    this.itemService.downloadExcelByIds(this.selected.map(s => s.id), this.alerts)
      .subscribe((res) => {
        const fileURL = this.buildShowUrl(res);
        window.open(fileURL, '_blank');
      });
  }

  deleteOrders() {
    this.ordersService.softDelete(this.selected.map(o => o.id)).then(() => {
      this.selected = [];
      this.alerts.info('Orders deleted.', 3000);
      this.refresh();
    }, (error) => this.alerts.error(error));
  }

  updateState() {
    this.stateService.saveOrdersState({
      topUserVisibilityFilters: this.selectedUserVisibilityFilters.map(c => c.id),
      modeFilters: this.orderModeGroups.filter(c => c.isSelected).map(c => c.id),
      statusFilters: this.statusFilters.filter(c => c.isSelected).map(c => c.statusValue)
    });
  }

  contextMenu = (/*row, column?: BaseColumn*/): ContextMenuComponent => {
    // if (column && (column.id === ColumnIds.MASTER_ID || column.id === 'id')) {
    return this.menu;
  }

  // Update Row Field
  onUpdate(event: FieldUpdateEvent<Order>) {
    if (event.field === 'carrier') {
      this.updateOrder(event.row, {carrier: event.newValue ? event.newValue.id : null}).then();
      return;
    }
    super.onUpdate(event);
  }

  changeDeliveryDate(event: MouseEvent) {
    let actualDate = this.selected.length === 1 ? this.selected[0].dateDeliveryAct : new Date();
    let estDate = this.selected.length === 1 ? this.selected[0].dateDeliveryReq : new Date();
    this.table.editDateTime(true, actualDate, estDate, true, event)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newValue: DateValue) => {
        let ids = this.selected.map(o => o.id);
        let actual = newValue.hasOwnProperty('actual');
        let date = actual ? newValue.actual : newValue.estimated;
        this.spinner.show().then();
        this.dispatchService.updateOrderDeliveryDate(ids, date, actual).subscribe(orders => {
          orders.forEach(order => this.items.replaceAll(order, order));
          this.items = [...this.items];
          this.spinner.hide().then();
          this.alerts.info(orders.length + " orders updated.", 30000);
        }, (error) => {
          this.alerts.error(error, 'Cannot update orders');
          this.spinner.hide().then();
        });
      });
    this.table.cdr.markForCheck();
  }

  openOrders(currentOrder: Order) {
    if (this.selected.length) {
      this.selected.forEach((o) => this.openOrder(o));
    } else {
      this.openOrder(currentOrder);
    }
  }

  openMultipleOrders(currentOrder: Order) {
    let ids: number[] = this.selected.length ? this.selected.map(o => o.id) : [currentOrder.id];
    localStorage.setItem('multipleOrders' + this.currentUser.id, ids.toString());
    window.open('/warehouse/multiple-order', '_blank');
  }

  private openOrder(order: Order): void {
    let link: HyperLink = getDocumentLink(order);
    let path = window.location.origin + link.path;
    if (link.params && Object.keys(link.params).length) {
      path += "?" + Object.keys(link.params).map(key => key + "=" + link.params[key]).join("&");
    }
    console.log('Trying to open', path);
    window.open(path, "_blank");
  }

  openCarrierEditor() {
    let instance = this.dialog.open<SelectEntityDialogComponent, any, Carrier>(SelectEntityDialogComponent, {
      width: '20%',
      data: {}
    });
    instance.componentInstance.label = 'Carrier';
    instance.componentInstance.entity = null;
    instance.componentInstance.selectSearch = new NgSelectSearchParams<Carrier>(this.carrierService);
    instance.afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(carrier => {
        this.selected.forEach(order => {
          this.updateOrder(order, {carrier: carrier ? carrier.id : null}).then();
        });
      });
  }

  openCustomerEditor() {
    let instance = this.dialog.open<SelectEntityDialogComponent, any, Customer>(SelectEntityDialogComponent, {
      width: '20%',
      data: {}
    });
    instance.componentInstance.label = 'Customer';
    instance.componentInstance.entity = null;
    instance.componentInstance.selectSearch = new NgSelectSearchParams<Customer>(this.customerService);
    instance.afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customer) => {
        this.selected.forEach(order => {
          this.updateOrder(order, {customer: customer ? customer.id : null}).then();
        });
      });
  }

  openChangeRefEditor() {
    this.openTextEditor("Customer Ref")
      .subscribe(customerRef => {
        this.selected.forEach((order) => {
          this.updateOrder(order, {customerRef: customerRef}).then();
        });
      });
  }

  openChangeRef2Editor() {
    this.openTextEditor("Ref2")
      .subscribe(hawb => {
        this.selected.forEach((order) => {
          this.updateOrder(order, {hawb: hawb}).then();
        });
      });
  }

  openChangeRef3Editor(): void {
    this.openTextEditor("Ref3")
      .subscribe(ref3 => {
        this.selected.forEach((order) => {
          this.updateOrder(order, {ref3: ref3}).then();
        });
      });
  }

  openChangeDescriptionOfGoods(): void {
    this.openTextEditor("Description of goods")
      .subscribe((desc) => {
        this.selected.forEach(order => {
          this.updateOrder(order, {descriptionOfGoods: desc}).then();
        });
      });
  }

  private openTextEditor(label: string): Observable<string> {
    let instance = this.dialog.open<SelectTextDialogComponent, any, string>(SelectTextDialogComponent, {
      width: '20%',
      data: {}
    });
    instance.componentInstance.dialogLabel = 'Enter ' + label;
    instance.componentInstance.label = label;
    return instance.afterClosed()
      .pipe(takeUntil(this.unsubscribe$));
  }

  private updateOrder(order: Order, fields: any): Promise<Order> {
    return this.ordersService.updateOrder(order.id, fields).then(res => {
      let newOrder = res[0];
      this.items.replaceAll(order, newOrder);
      this.items = [...this.items];
      return newOrder;
    });
  }

  openDocumentUploader() {
    let ids: number[] = this.selected.map((order) => order.id);
    this.dialog.open(FileUploadDialogComponent,
      { disableClose: true, width: 'auto', data: {id: ids, objectType: 'ORDER', noDescription: true} })
      .afterClosed().subscribe(() => this.refresh());
  }

  openCommentCenter() {
    let instance = this.dialog.open(NotesCenterDialogComponent, {
      width: '80%',
      data: {
        id: null,
        objectType: 'ORDER',
        noteTypeId: 0,
        readonly: false
      }
    });
    instance.componentInstance.saveComment = (com, selectedFiles) => {
      return forkJoin(this.selected.map((order) => {
        let copy = Object.assign(new ObjectComCenter(), com);
        copy.objectId = order.id;
        return this.saveComment(copy, selectedFiles);
      }));
    };
  }

  public splitOrder(): void {
    let order = this.selected[0];
    this.dialogs.openDialog(SplitOrderDialogComponent, {
      order: order,
      onOk: (newPcs: number, newHu: number, newWeight: number) => {
        order.pieces = newPcs;
        order.hu = newHu;
        order.weight = newWeight;

        this.refresh();
        this.cdr.markForCheck();
      }
    })
      .then();
  }

  recalculateStatus(): void {
    this.selected.forEach(o => this.updateOrder(o, {}));
  }

  private saveComment(com: ObjectComCenter, selectedFiles?: FileList) {
    if (selectedFiles) {
      return this.comCenterService.createWithDocument(com, selectedFiles);
    }
    return this.comCenterService.createObservable(com);
  }

  isSelectedOnlyNoAI(): boolean {
    return this.selected.length && !this.selected.some(o => OrderModes.isAirImport(o.genericMode));
  }

  generateBOL(isOpenPDF: boolean) {
    let ids = this.selected.map(oi => oi.id);
    this.ordersService.generateBOLs(ids).subscribe(response => {
      this.generateBOLOpenPDF(response, isOpenPDF);
    });
  }

  printBOL() {
    let ids = this.selected.map(oi => oi.id);
    this.ordersService.printBOLs(ids).subscribe(response => {
      this.findBOLOpenPDF(response);
    });
  }

  public findBOLOpenPDF(response) {
    if (!isNullOrUndefined(response)) {
      this.ordersService.openBase64PDF(response);
      this.alerts.success('Find BOLs successful', 5000);
    } else {
      this.alerts.error('No BOL available');
    }
  }

  public generateBOLOpenPDF(response, isOpenPDF: boolean) {
    if (!isNullOrUndefined(response)) {
      if (isOpenPDF) {
        this.ordersService.openBase64PDF(response);
      }
      this.alerts.success('Generate BOL successful', 5000);
    } else {
      this.alerts.error('Error generate BOL');
    }
  }

  addFlag(): void {
    const dialogRef = this.dialog.open<OrderSubscriptionDialogComponent, OrderSubscriptionDialogInput, MasterStatus[]>(OrderSubscriptionDialogComponent,
      {width: 'auto', data: {orders: this.selected}});
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  removeFlag(): void {
    this.orderSubscriptionService.deleteByOrders(this.selected.map(o => o.id))
      .subscribe(() => this.alerts.info("Flag removed", 20000));
  }

  toggleStatusWithDelay(masterStatus: MasterStatus) {
    let statuses = this.getSelectedStatusesByColumn();
    if (this.isSelectedMasterStatus(masterStatus)) {
      statuses = statuses.filter(i => i !== masterStatus);
    } else {
      statuses = [...statuses, masterStatus];
    }
    this.orderStatusColumn.setSelectedItems(statuses);

    if (this.statusToggleTimer) {
      clearTimeout(this.statusToggleTimer);
      this.statusToggleTimer = null;
    }
    this.statusToggleTimer = setTimeout(() => {
      this.applyAllHeaderFilters();
    }, 1500);
  }

  isSelectedMasterStatus(masterStatus: MasterStatus): boolean {
    return this.getSelectedStatusesByColumn().includes(masterStatus);
  }

  getSelectedStatusesByColumn(): MasterStatus[] {
    return this.orderStatusColumn.getSelectedItems();
  }


  public getSearchText(): string {
    return this.request.findByOccurs;
  }

  public setSearchText(s: string) {
    this.request.findByOccurs = cleanSearchString(s);
  }

  public searchCustomer(search: string): Observable<PageResult<Customer>> {
    let request: FilterPageRequest = this.table.buildSearchRequest(false);
    request.pageSize = 9999;
    request.pageNumber = 1;
    console.log('searchCustomer:', search);
    return this.ordersService.findCustomersOfSelectedOrders(search, request);
  }

}

function searchDriver(userService: UserService, searchText: string): Observable<PageResult<Driver>> {
  return userService.findUsersByRoles(['ROLE_DRIVER'], true, searchText).pipe(map((response) => {
    return PageResult.fromArray(response.content.filter(user => user.driver).map(user => user.driver));
    // response.content.forEach(driver => driver['label'] = driver.fullName);
  }));
}
