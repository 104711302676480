var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BaseInputComponent } from "../../base/base-input/base-input.component";
var ToggleSwitchComponent = /** @class */ (function (_super) {
    __extends(ToggleSwitchComponent, _super);
    //  public identifier = `oms-toggle-${ToggleSwitchComponent.current_id++}`;
    function ToggleSwitchComponent(validators, asyncValidators) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        //  protected static current_id = 0;
        _this.type = 'yes-no';
        _this.marks = false;
        _this.showLabel = true;
        _this.disabled = false;
        _this.checkedChange = new EventEmitter();
        return _this;
    }
    Object.defineProperty(ToggleSwitchComponent.prototype, "checked", {
        set: function (value) { this.setValue(value); },
        enumerable: true,
        configurable: true
    });
    ToggleSwitchComponent.prototype.onChange = function (event) {
        this.checkedChange.emit(event);
    };
    return ToggleSwitchComponent;
}(BaseInputComponent));
export { ToggleSwitchComponent };
