import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterItem} from "./filter-item";
import {AbstractComponent} from "../../../../../common/component/abstract.component";

@Component({
  selector: 'oms-filter-items',
  templateUrl: './filter-items.component.html',
  styleUrls: ['./filter-items.component.scss']
})

export class FilterItemsComponent extends AbstractComponent {

  @Input() items: FilterItem[] = [];
  @Input() selected: FilterItem[] = [];
  @Input() maxDisplayItems: number = 10;
  @Input() multiple: boolean = true;
  @Input() delay: number = 1500;
  @Output() change: EventEmitter<FilterItem> = new EventEmitter<FilterItem>();
  @Output() selectedChange: EventEmitter<FilterItem[]> = new EventEmitter<FilterItem[]>();

  startVisibleItem = 0;
  private timer;

  constructor() {
    super();
  }

  clickByItem(item: FilterItem): void {
    this.change.emit(item);
    if (this.multiple) {
      if (this.isActive(item)) {
        this.selected = this.selected.filter(s => s !== item);
      } else {
        this.selected.push(item);
      }
    } else if (this.isActive(item)) {
      this.selected = [];
    } else {
      this.selected = [item];
    }
    this.selectedEmit();
  }

  private selectedEmit() {
    if (!this.delay || this.delay < 0) {
      this.selectedChange.emit(this.selected);
      return;
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      this.selectedChange.emit(this.selected);
    }, 1500);
  }

  isActive(item: FilterItem): boolean {
    return this.selected.some(s => s === item || s.object === item.object);
  }

  isDisabled(item: FilterItem): boolean {
    return this.getBoolValue(item, item.disabled, false);
  }

  private getBoolValue(item: FilterItem, field: boolean | ((item: FilterItem, items: FilterItem[]) => boolean), defaultValue: boolean): boolean {
    if (typeof field === 'boolean') {
      return field;
    } else if (typeof field === 'function') {
      return field(item, this.items);
    }
    return defaultValue;
  }

  prevStep() {
    this.startVisibleItem = Math.max(0, this.startVisibleItem - 1);
  }

  nextStep() {
    this.startVisibleItem = Math.min(this.items.length - this.maxDisplayItems, this.startVisibleItem + 1);
  }
}
