<div class="col-lg-12 widget-split-area">
  <oms-widget label="Order" [description]="'   Status:' + statusName">

    <ng-container class="widget-header-actions" widget-header>

      <span>
        <h3 style="margin: 10px" *ngIf="masterId">
          <a [routerLink]="['/home', 'master']" [queryParams]="{id: masterId}" target="_blank" style="margin-right: 10px;">
            <i class="widget-header-actions-icon fas fa-edit"></i>
            {{masterId | master_number}}
          </a>
        </h3>

        <h3 style="margin: 10px" *ngIf="order?.isAirImport">
          <a [routerLink]="['/home', 'masters']" [queryParams]="{tab: 'active', search: order.mawb}" target="_blank">
            <i class="widget-header-actions-icon fas fa-external-link-alt"></i>
            Show in Masters
          </a>
        </h3>

        <h3 style="margin: 10px" *ngIf="!order.isAirImport && !order.isRecovery">
          <a [routerLink]="['/home', 'order-list']" [queryParams]="{id: order.id}" target="_blank">
            <i class="widget-header-actions-icon fas fa-external-link-alt"></i>
            Show in Orders
          </a>
        </h3>
      </span>

    </ng-container>

    <div class="widget-header-actions" widget-toolbar>
      <ng-container *ifRole="['ROLE_ADMIN', 'ROLE_USER']">

        <a (click)="copyStatusInfoUrl()" *ngIf="hasStatusInfoPageLink()"
          [popover]="'Status Info Page'" [container]="'body'">
          <i class="widget-header-actions-icon fas fa-external-link-alt"></i>
        </a>

        <a (click)="openBillingDialog()" *ngIf="!order.isNew() && canShowBillingInfo"
           [popover]="'Billing Information'" [container]="'body'" [placement]="'bottom'"
           [ngClass]="{'green': invoices.length}">
          <i class="widget-header-actions-icon fa fa-dollar-sign"></i>
        </a>

        <a (click)="openOrderSubscriptionDialog()"
           [popover]="'Order Notifications'" [container]="'body'">
          <i class="widget-header-actions-icon fa fa-bell" *ngIf="!order.isNew()"></i>
        </a>

        <a (click)="openUldDialog()" *ngIf="!isFCL"
           [popover]="'ULD'" [container]="'body'">
          <i class="widget-header-actions-icon fa fa-box-open" *ngIf="!order.isNew()"
             [ngClass]="{'green':order.uldCount > 0}">
          </i>
        </a>

        <a (click)="openDocCenter()"
           [popover]="'Document Center'" [container]="'body'">
          <i class="fa fa-paperclip" ></i>
          <span class="badge" *ngIf="docCount">{{docCount}}</span>
        </a>

        <a (click)="openNoteCenter(0)"
           [popover]="'Com Center'" [container]="'body'">
          <i class="fa fa fa-comments"></i>
          <span class="badge" *ngIf="notesCount">{{notesCount}}</span>
        </a>

        <a (click)="onShowHistory()"
           [popover]="'Show History Log'" [container]="'body'">
          <i class="widget-header-actions-icon fas fa-history"></i>
        </a>

      </ng-container>
    </div>

    <div class="row row-widget">

      <div class="col-md-8">

        <!--first line-->
        <div class="row">
          <div class="col-md-3">
            <!-- ORDER MODE -->
            <oms-custom-search [items]="orderModeValue" [searchable]="false" (changing)="onModeChange()"
                               [disabled]="!isGeneric || isRecovery || isReadMode"
                               [placeholder]="nonGenericModeLabel"
                               [allowClear]="false" label="Order Mode" [showLabel]="true" [(ngModel)]="genericMode">
              <ng-template #optionTemplate ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search"><b>{{modes.nameOf(item)}}</b>&emsp;({{modes.labelOf(item)}})</div>
              </ng-template>

              <ng-template #labelTemplate ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search"><b>{{modes.nameOf(item)}}</b>&emsp;({{modes.labelOf(item)}})</div>
              </ng-template>
            </oms-custom-search>
          </div>
          <div class="col-md-3">
            <!-- CUSTOMER -->

            <oms-custom-search [searchable]="true" [service]="customerService" label="Customer"
                               [(ngModel)]="order.customer" [sort-by]="'name'" [required]="isCustomerRequired"
                                [disabled]="isReadMode">
              <ng-container group-before>
                <!--mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon-->
                <i class="fa fa-user"></i>
              </ng-container>
            </oms-custom-search>
          </div>
          <div class="col-md-3">
            <!-- FREIGHT FORWARDER -->

            <oms-custom-search [searchable]="true" [service]="freightForwarderService" label="F.Forwarder"
                               [(ngModel)]="order.freightForwarder" [sort-by]="'name'"
                               [disabled]="isReadMode">
              <ng-container group-before>
                <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
              </ng-container>
            </oms-custom-search>
          </div>
          <div class="col-md-3">

            <oms-date-input label="Requested delivery date" [show-checkbox]="false" [estimation]="false"
                            [(ngModel)]="order.requestedDeliveryDate"
                            [disabled]="isReadMode"></oms-date-input>
          </div>
        </div>
        <!--second line-->
        <div class="row">
          <div class="col-md-3">
            <!--REF 1-->
            <oms-string-input label="{{ref1Label}}" [(ngModel)]="ref1" [disabled]="isReadMode">
            </oms-string-input>

          </div>
          <div class="col-md-3">
            <!--REF 2-->
            <oms-string-input label="{{ref2Label}}"
                              [input-mask]="ref2Mask" [clean-mask]="!!ref2Mask" [placeholder]="ref2Placeholder"
                              [(ngModel)]="ref2"
                              [orderMode]="actualMode"
                              [disabled]="isReadMode" cnt-number>
            </oms-string-input>
          </div>
          <div class="col-md-3">
            <!--REF 3-->
            <oms-string-input label="{{ref3Label}}"
                              [input-mask]="ref3Mask" [clean-mask]="!!ref3Mask" [placeholder]="ref3Placeholder"
                              [(ngModel)]="ref3" [disabled]="isReadMode">
            </oms-string-input>
          </div>

          <div class="col-md-3">
            <!--CARRIER (for AI only)-->
            <oms-carrier-search [placeholder]="'Carrier'"
                                [(ngModel)]="order.carrier" [canCreateNew]="canCreateCarrier" [disabled]="isReadMode">
            </oms-carrier-search>
          </div>

        </div>
        <!--3 line-->
        <div class="row">

          <div class="col-md-9">
            <div class="col-md-6 no-left-padding">
              <!-- ADDRESS PICKUP -->

              <oms-address-search label="Pick Up" [(address)]="addressPickup" [disabled]="!isGeneric || isReadMode">
              </oms-address-search>
              <textarea class="address-desc" rows="6" readonly disabled>{{addressPickup?.fullAddress}}</textarea>
            </div>
            <div class="col-md-6 no-right-padding">
              <!-- ADDRESS DELIVERY -->

              <oms-address-search label="Delivery" [(address)]="addressDelivery" [disabled]="isReadMode"></oms-address-search>
              <textarea class="address-desc" rows="6" readonly disabled>{{addressDelivery?.fullAddress}}</textarea>
            </div>

            <div class="col-md-12 no-padding">
              <div class="col-md-3 no-padding">
                <oms-pieces-input [show-label]="true" [label]="isFCL ? 'CNT': 'PCS'" [(units)]="pcsUnits" [containers]="isFCL"
                                  [(ngModel)]="pieces" [readonly]="isFCL" [disabled]="!isGeneric || isReadMode" required></oms-pieces-input>
              </div>
              <div class="col-md-3">
                <oms-pieces-input [show-label]="true" label="HU" [(units)]="huUnits" [containers]="isFCL" [readonly]="isFCL"
                                  [disabled]="isFCL || !isGeneric || isReadMode" [(ngModel)]="hu"></oms-pieces-input>
              </div>
              <div class="col-md-3 no-right-padding">
                <oms-weight-input [show-label]="true" label="WGHT" [(ngModel)]="weight"
                                  [forceChangeUnits]="true" [disabled]="!isGeneric || isReadMode"></oms-weight-input>
              </div>
              <div class="col-md-3 no-right-padding">
                <oms-string-input label="Chassis" [(ngModel)]="chassis" [disabled]="isReadMode"></oms-string-input>
              </div>

            </div>
          </div>

          <div class="col-md-3">

            <div class="col-xs-12">
              <oms-airport-search
                [size]="Size.DEFAULT"
                [departure]="true"
                [(ngModel)]="order.originAirport"
                [label]="'Origin Airport'"
                [disabled]="isReadMode">
              </oms-airport-search>
            </div>

            <div class="col-xs-12">
              <oms-string-input label="PO Number"
                                [placeholder]="'PO Number'"
                                [(ngModel)]="order.purchaseOrder"
                                [disabled]="isReadMode">
              </oms-string-input>
            </div>

            <div class="col-xs-12">
              <oms-toggle-switch label="Delivery appointment required" class="danger"
                                 [(ngModel)]="order.deliveryAppointmentRequired"
                                 [disabled]="isReadMode"></oms-toggle-switch>
            </div>
            <div class="col-xs-12">
              <oms-toggle-switch label="Hot" class="danger" [(ngModel)]="order.hot"
                                 [disabled]="isReadMode"></oms-toggle-switch>
            </div>
            <div class="col-xs-12">
              <oms-toggle-switch label="COD" class="danger" [(ngModel)]="order.cod"
                                 [disabled]="isReadMode"></oms-toggle-switch>
            </div>
          </div>


          <div class="col-md-12">
            <div class="col-md-6 no-left-padding">
              <oms-text-area-input label="Description of Goods" [(ngModel)]="order.descriptionOfGoods" [hasToggle]="false">
              </oms-text-area-input>
            </div>
            <div class="col-md-6 no-right-padding">
              <oms-text-area-input label="Dispatch Notes" [(ngModel)]="order.dispatchNotes" [hasToggle]="false">
              </oms-text-area-input>
            </div>
          </div>

        </div>
      </div>

      <div class="col-md-4">
        <div class="col-md-6">
          <oms-string-input label="Created by" [(ngModel)]="order.createdBy" [disabled]="true">
          </oms-string-input>
        </div>

        <div class="col-md-6">
          <oms-date-input label="Created date" [show-checkbox]="false" [estimation]="false" [disabled]="true"
                          [(ngModel)]="order.dateCreated"></oms-date-input>
        </div>

        <div class="col-md-6">
          <oms-string-input label="Last updated by" [(ngModel)]="order.updatedBy" [disabled]="true">
          </oms-string-input>

        </div>

        <div class="col-md-6">
          <oms-date-input label="Last updated date" [show-checkbox]="false" [estimation]="false" [disabled]="true"
                          [(ngModel)]="order.dateUpdated"></oms-date-input>
        </div>

        <div class="col-md-6">
          <oms-date-input label="Order Pick up Date" [show-checkbox]="false" [estimation]="true"
                          (contextmenu)="onRightClick(false)"
                          [disabled]="!!order.master || isReadMode" [(estimated)]="order.datePickupEst"
                          [(ngModel)]="order.datePickupAct"></oms-date-input>
        </div>
        <div class="col-md-6">
          <oms-date-input label="Order Delivery Date" [show-checkbox]="false" [estimation]="true"
                          (contextmenu)="onRightClick(true)"
                          [disabled]="!!order.master || isReadMode" [(estimated)]="order.dateDeliveryReq"
                          [(ngModel)]="order.dateDeliveryAct"></oms-date-input>
        </div>

        <div class="col-md-6" *ngIf="isAIOrder()">
          <oms-date-input label="CFS in Date" [show-checkbox]="false" [estimation]="true"
                          [disabled]="true"
                          [(ngModel)]="order.dateCfsInAct"
                          [(estimated)] = "order.dateCfsInEst"></oms-date-input>
        </div>
        <div class="col-md-6" *ngIf="isAIOrder()">
          <oms-date-input label="CFS out Date" [show-checkbox]="false" [estimation]="true"
                          [disabled]="true"
                          [(ngModel)]="order.dateCfsOutAct"
                          [(estimated)] = "order.dateCfsOutEst"></oms-date-input>
        </div>

        <div class="col-sm-6">
          <oms-date-input [size]="Size.SMALL" label="Docs To Warehouse" [disabled]="isReadMode"
                          [show-switch]="false" [show-checkbox]="true" [(ngModel)]="order.dateDocsToWarehouse"></oms-date-input>
        </div>

        <div class="col-sm-6">
          <oms-date-input [size]="Size.SMALL" label="Billed" [disabled]="isBillingDisabled || isReadMode"
                          [show-switch]="false" [show-checkbox]="true" [(ngModel)]="order.dateBilled"></oms-date-input>
        </div>

        <div class="col-md-6">
          <oms-string-input label="Requested by" [(ngModel)]="order.requestedBy" [disabled]="isReadMode">
          </oms-string-input>
        </div>

        <div class="col-md-6">
          <oms-string-input label="Received By" [(ngModel)]="order.podSingerName">
          </oms-string-input>
        </div>

        <div class="col-md-6">
          <oms-currency-input
            [history]="{id: order.id, className: 'com.oms.entity.order.Order', field: 'quote'}"
            (contextmenu)="onShowQuoteHistory($event)"
            label="Quote"
            [(ngModel)]="order.quote">
          </oms-currency-input>
        </div>

      </div>
    </div>
  </oms-widget>
</div>

