import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { DateTimeService } from "../../../services/date-time.service";
import { ScheduleType } from "../../../modules/shared/models/reporting/schedule";
import { UserService } from "../../../modules/shared/services/user.service";
import { ReportRecipient } from "../../../modules/shared/models/reporting/report-recipient";
import { ReportService } from "../../../services/report.service";
import { FilterPageRequest } from "../../../modules/shared/models/filter.page.request";
import { absent, clamp, isEmptyString } from "../../../_helpers/utils";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
var Container = /** @class */ (function () {
    function Container(name, columns) {
        this.name = name;
        this.columns = columns;
    }
    return Container;
}());
var ReportDialogComponent = /** @class */ (function () {
    function ReportDialogComponent(alert, reportService, ts, cdr, tzService, userService, dialogRef, data) {
        this.alert = alert;
        this.reportService = reportService;
        this.ts = ts;
        this.cdr = cdr;
        this.tzService = tzService;
        this.userService = userService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.available = new Container('Available fields', []);
        this.output = new Container('Output fields', []);
        this.containers = [
            this.available,
            this.output
        ];
        this.ScheduleType = ScheduleType;
        this.hourMinutesItems = [];
        this.outputTypes = [
            { id: 0, label: 'Email Body' },
            { id: 1, label: 'Attachment' },
            { id: 2, label: 'Body & Attachment' },
            { id: 3, label: 'Download URL' }
        ];
        this.readonly = false;
        this.reportTypes = [];
        this.dayHoursSearch = function (search, item) {
            return item.name.includes(search);
        };
        this.report = data.report;
        var i = 0;
        this.dayHoursItems = this.ts.dayHours.map(function (n) { return ({ id: i++, name: n }); });
        for (var m = 0; m < 60; m++) {
            this.hourMinutesItems.push({ id: m, name: m });
        }
        i = 0;
        this.weekDaysItems = this.ts.weekDays.map(function (n) { return ({ id: i++, name: n }); });
        if (!this.report.filters) {
            this.report.filters = FilterPageRequest.simpleFilters();
        }
    }
    ReportDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.reportService.getAvailableReports()
            .subscribe(function (reportTypes) {
            _this.reportTypes = reportTypes;
            if (_this.report) {
                _this.selectedReportType = reportTypes.find(function (rt) { return rt.reportClass === _this.report.reportClass; });
            }
            if (!!_this.selectedReportType) {
                _this.loadAvailableColumns(_this.selectedReportType.typeId);
            }
            _this.cdr.markForCheck();
        });
    };
    ReportDialogComponent.prototype.tzSearch = function (search, item) {
        return search && item && item.name && item.name.toLowerCase().includes(search.toLowerCase());
    };
    ReportDialogComponent.prototype.validate = function (report) {
        if (absent(report.reportClass)) {
            this.alert.error("Report Type is missing");
            return false;
        }
        if (isEmptyString(report.name)) {
            this.alert.error("Report Name is missing");
            return false;
        }
        return true;
    };
    ReportDialogComponent.prototype.onOk = function (event) {
        if (!this.validate(this.report)) {
            event.canClose = false;
        }
        var columns = this.output.columns.length
            ? this.output.columns
            : this.available.columns;
        this.report.fields = columns.map(function (c, i) {
            return {
                reportFieldType: c.id,
                orderNumber: i
            };
        });
    };
    ReportDialogComponent.prototype.onUserAdd = function (user) {
        if (user) {
            var recipient = new ReportRecipient();
            recipient.user = user;
            recipient.enabled = true;
            this.report.recipients.push(recipient);
            this.userToAdd = null;
            this.cdr.markForCheck();
        }
    };
    ReportDialogComponent.prototype.removeRecipient = function (rec) {
        this.report.recipients.removeAll(rec);
    };
    ReportDialogComponent.prototype.onReportClassChange = function (event) {
        if (event) {
            if (this.data.report.reportClass !== event.reportClass) {
                this.data.report.reportClass = event.reportClass;
                if (this.data.report.isNew()) {
                    this.data.report.name = event.label;
                }
                this.loadAvailableColumns(event.typeId);
            }
        }
        else {
            this.data.report.reportClass = event;
        }
    };
    Object.defineProperty(ReportDialogComponent.prototype, "isOrdersReport", {
        get: function () {
            return this.report && this.report.reportClass &&
                (this.report.reportClass.endsWith("OrdersExcelReport")
                    || this.report.reportClass.endsWith("DeliveredOrdersReport"));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "isRoutesReport", {
        // todo hardcoded (well, almost) note need ReportClassType enum
        get: function () {
            return this.report && this.report.reportClass &&
                (this.report.reportClass.endsWith("RoutesExcelReport")
                    || this.report.reportClass.endsWith("RoutesMissingPodReport")
                    || this.report.reportClass.endsWith("DispatchReport"));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "isPendingRecoveryReport", {
        get: function () {
            return this.report && this.report.reportClass &&
                this.report.reportClass.endsWith("PendingRecoveriesReport");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "isMastersReport", {
        get: function () {
            return this.report && this.report.reportClass &&
                (this.report.reportClass.endsWith("MasterExcelReport") || this.report.reportClass.endsWith("MastersOutstandingExcelReport"));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "hasFilters", {
        // todo hardcoded
        get: function () {
            return this.isOrdersReport || this.isRoutesReport || this.isMastersReport || this.isPendingRecoveryReport;
        },
        enumerable: true,
        configurable: true
    });
    ReportDialogComponent.prototype.loadAvailableColumns = function (reportTypeId) {
        var _this = this;
        this.reportService.getAvailableColumns(reportTypeId)
            .subscribe(function (columns) {
            _this.available.columns = columns
                .filter(function (c) { return !_this.report.fields
                .some(function (f) { return f.reportFieldType === c.id; }); });
            _this.output.columns = _this.report.fields
                .sort(function (f1, f2) { return f1.orderNumber - f2.orderNumber; })
                .map(function (f) { return columns.find(function (c) { return c.id === f.reportFieldType; }); });
            _this.cdr.markForCheck();
        });
    };
    ReportDialogComponent.prototype.validateMinutes = function () {
        this.report.schedule.minute = clamp(this.report.schedule.minute, 0, 59);
        this.cdr.markForCheck();
    };
    return ReportDialogComponent;
}());
export { ReportDialogComponent };
