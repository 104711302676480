import {Type} from "class-transformer";
import {BaseDeletable} from "./base-deletable";
import {Airport} from "./air";

export class MawbTrack extends BaseDeletable  {
  mawbNumber: string;
  hawb: string;
  masterId: number;
  part: string;
  carrierCode: string;
  flightNo: string;
  @Type(() => Date)
  etaDate: Date;
  @Type(() => Date)
  actualDate: Date;
  @Type(() => Airport)
  destinationAirport: Airport;
  @Type(() => Airport)
  originAirport: Airport;
  totalPieces: number;
  totalWeight: number;
  inSystem: boolean;
}
