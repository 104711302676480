var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { BaseInputComponent } from '../../../base/base-input/base-input.component';
import { NgModel } from '@angular/forms';
import { conformToMask } from 'text-mask-core/dist/textMaskCore';
import { isFunction } from 'util';
import { isNullOrUndefined } from 'util';
import { Size } from '../../../../../../common/oms-types';
import { HistoryInformationDialogComponent } from "../../../../../../components/common/history-information-dialog";
import { MatDialog } from "@angular/material/dialog";
var StringInputComponent = /** @class */ (function (_super) {
    __extends(StringInputComponent, _super);
    function StringInputComponent(validators, asyncValidators, dialog) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.dialog = dialog;
        _this.Size = Size;
        _this.size = Size.DEFAULT;
        _this.isNumber = false;
        _this.readonly = false;
        _this.showLabel = true;
        _this.multiline = false;
        _this.labelInline = false;
        _this.invalidValue = false;
        _this.inputType = 'text';
        _this.changingEvent = new EventEmitter();
        //  @ViewChild("input", { read: ViewContainerRef }) public input;
        _this._mask = StringInputComponent.NO_MASK;
        return _this;
    }
    Object.defineProperty(StringInputComponent.prototype, "mask", {
        set: function (value) { this.composeMask(value); } // text-mask value
        ,
        enumerable: true,
        configurable: true
    });
    StringInputComponent.prototype.composeMask = function (inputMask) {
        //    if (this._mask)
        //      return this._mask;
        if (isNullOrUndefined(inputMask)) {
            this._mask = StringInputComponent.NO_MASK;
        }
        else if (isFunction(inputMask)) {
            this._mask = { mask: inputMask };
        }
        else if (inputMask.mask) {
            this._mask = inputMask;
        }
        else {
            this._mask = {
                mask: inputMask,
                guide: true,
                showMask: !this.placeholder
            };
        }
        return this._mask;
    };
    Object.defineProperty(StringInputComponent.prototype, "internal", {
        get: function () {
            if (isNullOrUndefined(this._internalValue)) {
                this._internalValue = this.value;
            }
            return this._internalValue;
        },
        set: function (value) {
            if (this._internalValue !== value) {
                this._internalValue = value;
                _super.prototype.setValue.call(this, this.cleanup(value));
                this.changingEvent.emit(this.value);
            }
        },
        enumerable: true,
        configurable: true
    });
    StringInputComponent.prototype.setValue = function (value) {
        this._internalValue = value;
        _super.prototype.setValue.call(this, this._internalValue);
    };
    StringInputComponent.prototype.cleanup = function (s) {
        if (this._mask) {
            // remove _ guide before passing to the model
            if (this._mask.mask) {
                s = conformToMask(s, this._mask.mask, { guide: false }).conformedValue;
            }
            // keep only alphanumerical values and '.' for number support
            if (this.cleanMask || this.isNumber) {
                s = s.replace(/[^0-9A-Za-z.]/g, '');
            }
        }
        return this.isNumber ? (s ? Number(s) : null) : s;
    };
    StringInputComponent.prototype.onShowHistory = function () {
        if (this.history) {
            this.dialog.open(HistoryInformationDialogComponent, {
                width: '80%',
                data: {
                    logParams: [this.history], convertByBaseType: true
                }
            });
        }
    };
    StringInputComponent.NO_MASK = { mask: false };
    return StringInputComponent;
}(BaseInputComponent));
export { StringInputComponent };
