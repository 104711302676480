import {
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  Input, OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Title} from "@angular/platform-browser";
import {NgxSpinnerService} from "ngx-spinner";
// import {Location} from '@angular/common';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'oms-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showHeader: boolean = true;
//  @Input() scrollable: boolean = false;
  @Input('loading') loading: boolean = false;
  @Input() backOnEscape: boolean = false;

  @ViewChild('mainContent') mainContent: ElementRef;
  @ViewChild('footer') footer: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
//    private location: Location,
    public spinner: NgxSpinnerService,
    public titleService: Title,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
//    console.log('ACTIVATED', route.snapshot.toString());

  }

  get displayChildPage(): boolean {
    let children: ActivatedRouteSnapshot[] = this.activatedRoute.snapshot.children;
    return children && children.length > 0;
  }

  ngOnInit() {
    const node = document.querySelector('#footer');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => console.log(mutation));
      this.checkSize();
    });

    observer.observe(node, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }


  checkSize() {
//    console.log('resize');
//    if (this.mainContent.nativeElement instanceof HTMLDivElement) {
      if (this.footer.nativeElement instanceof HTMLDivElement) {
        this.mainContent.nativeElement.style.setProperty('padding-bottom', this.footer.nativeElement.clientHeight /* .offsetHeight*/ + 'px');
        this.cdr.markForCheck();
      }
//    }
  }

  getTitle(): string {
    return this.title;
  }

  getSubTitle(): string {
    return this.subtitle;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) {
    console.log('TITLE', this.title, changes);
    this.titleService.setTitle(this.title);
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent) {
    if (this.backOnEscape) {
      event.stopImmediatePropagation();
      this.goBack();
    }
  }

  public goBack() {
    // this is working solution
    this.router.navigateByUrl(this.router.url.substr(0, this.router.url.lastIndexOf('/'))).then();

    //    this.router.navigate(['..'], { relativeTo: this.route}).then();
//    this.location.back();
  }


}
