import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TruckService} from '../../../services/truck-service';
import {Order} from '../../../modules/shared/models';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {TrailerService} from '../../../services/trailer-service';
import {DriverService} from '../../../services/driver-service';
import {AddressService} from '../../../services';
import {Size} from '../../../common/oms-types';
import {AddressSearchService} from '../../../modules/shared/services/address-search.service';
import {Truck} from '../../../modules/shared/models';
import {Trailer} from '../../../modules/shared/models';
import {Driver} from '../../../modules/shared/models';
import {convertManifestNumber, OmsConverters} from '../../../modules/shared/services/oms-converters.service';
import {NgSelectSearchParams} from '../../../modules/settings/util/ng-select-search-params';
import {Carrier} from '../../../modules/shared/models/carrier';
import {CarrierService} from '../../../modules/shared/services/carrier.service';
import {DialogCloseEvent, DialogResult, DialogType, ModalResult, OmsDialogsService} from "../../common/oms-dialogs";
import {DispatchService} from "../../../modules/shared/services/dispatch/dispatch.service";
import {Manifest} from "../../../modules/shared/models/manifest/manifest";
import {equals} from "../../../_helpers/utils";
import {sameDate} from "../../../common/oms-date-time.utils";

export interface OrdersDispatchData {
  orders: Order[];
  date: Date;
  delivery: boolean;
  driver?: Driver;
  truck?: Truck;
  trailer?: Trailer;
  carrier?: Carrier;
  noCarrier?: boolean;
  manifestToMerge?: Manifest;
  manifestOriginal?: Manifest;
}

@Component({
  templateUrl: './orders-dispatch-dialog.component.html',
  styleUrls: ['./orders-dispatch-dialog.component.scss']
})
export class OrdersDispatchDialogComponent implements OnInit, AfterViewInit {
  Size = Size;

  public readonly: boolean = false;
  public addressSearch: AddressSearchService;
  carrierSearch: NgSelectSearchParams<Carrier>;
  public loading: boolean = false;
  initialized: boolean = false;

  private tempTrailer: Trailer;
  private tempTruck: Truck;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrdersDispatchData,
    public converter: OmsConverters,
    public cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<OrdersDispatchDialogComponent, DialogResult<OrdersDispatchData>>,
    public addressService: AddressService,
    private dialogs: OmsDialogsService,
    private alerts: OmsAlertsService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService,
    public dispatchService: DispatchService,
    public carrierService: CarrierService
  ) {
//    this.addressSearch = this.addressService.getNewSearchService([], 15);
    this.tempTrailer = this.data.trailer;
    this.tempTruck = this.data.truck;
  }

  ngOnInit() {
    this.carrierSearch = new NgSelectSearchParams<Carrier>(this.carrierService);
//    this.carrierService.getDefaultCarrier().subscribe(carrier=>{this.data.carrier = carrier});
  }

  ngAfterViewInit(): void {
    this.initialized = true;
  }


  get cancelEnable(): boolean {
    return false; // !this.readonly && !this.data.split.isVirtual/* && !this.data.split.isReceived*/;
  }

  onCancelDispatch() {
    this.dialogs.confirm(DialogType.CONFIRMATION, 'Cancel Dispatch?',
      'The load will be deleted and all related data will be lost.\nContinue?').then((result) => {
        switch (result.result) {
          case ModalResult.YES: {
            this.dialogRef.close({result: ModalResult.DELETE});
          }
        }
    });
  }

  onOk(event: DialogCloseEvent) {
    event.canClose = this.validate();
  }

  onCreateTruck(number: string) {
    const truck = new Truck();
    truck.number = number;
    this.truckService.createEditAndSave(truck, this.readonly)
      .then((t) => {this.data.truck = t; })
      .catch(error => this.alerts.danger(error));
  }

  onCreateTrailer(number: string) {
    const trailer = new Trailer();
    trailer.number = number;
    this.trailerService.createEditAndSave(trailer, this.readonly)
      .then((t) => {this.data.trailer = t; })
      .catch((error) => this.alerts.danger(error));
  }

  onCreateDriver(event) {
    const driver = new Driver();
    this.driverService.createEditAndSave(driver, this.readonly)
      .then((dr) => {this.data.driver = dr; })
      .catch((error) => this.alerts.danger(error));
  }

  public allowDispatch(order: Order): boolean {
    return this.data.delivery ? order.nonAMS || !!order.date1C : true;
  }


  public validate(): boolean {
        let o: Order[] = this.data.orders.filter((order) => !this.allowDispatch(order));
        if (o.length > 0) {
          this.alerts.warning('All AMS orders must have 1C');
          return false;
        }

    return true;
  }

  private checkMerge() {
    if (this.initialized && this.data.driver && this.data.date) {

      if (this.data.manifestOriginal &&
        equals(this.data.manifestOriginal.driver, this.data.driver) &&
        sameDate(this.data.manifestOriginal.dateDispatchedFor, this.data.date)
      ) {
        this.data.manifestToMerge = null;
        this.data.trailer = this.tempTrailer;
        this.data.truck = this.tempTruck;

      } else {
        this.loading = true;
        this.dispatchService.getDriverManifestOnDate(this.data.date, this.data.driver)
          .then(manifest => {
            if (manifest && this.data.manifestOriginal && this.data.manifestOriginal.id !== manifest.id) {
              if (!this.data.manifestToMerge) {
                this.tempTruck = this.data.truck;
                this.tempTrailer = this.data.trailer;
              }
              this.data.manifestToMerge = manifest;
              this.data.truck = manifest.truck;
              this.data.trailer = manifest.trailer;
            } else {
              if (this.data.manifestToMerge) {
                this.data.manifestToMerge = null;
                this.data.trailer = this.tempTrailer;
                this.data.truck = this.tempTruck;
              }
            }
            this.loading = false;
          })
          .catch(error => {
            this.alerts.error(error);
            this.loading = false;
          });
      }
    }
  }

  public onDriverChange(event) {
    console.log('Driver Changed', event);
    this.checkMerge();
  }

  public onDateChange(event) {
    console.log('Date Changed', event);
    this.checkMerge();
  }

  public get info() {
    if  (this.data.manifestOriginal &&
      sameDate(this.data.manifestOriginal.dateDispatchedFor, this.data.date) &&
      equals(this.data.manifestOriginal.driver, this.data.driver)) {
      return "Select another Date or Driver";
    }

    if (this.data.manifestToMerge) {
      return `Existing ${convertManifestNumber(this.data.manifestToMerge.id)} found to Merge`;
    }

    return  undefined;
  }

}
