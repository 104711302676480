import {
  AfterViewInit,
  ChangeDetectorRef,
  DoCheck, EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges, OnDestroy, OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ContextMenuComponent} from 'ngx-contextmenu';
import {ColorItem} from '../../../components/common/color-search-select/color-item';
import {
  Address,
  Customer,
  FreightForwarder,
  Master,
  MasterStatus,
  Order,
  Part, RecoveryOrder,
} from '../../../modules/shared/models';
import {
  FieldUpdateEvent, FilterOptions,
  PagingOptions,
  PARENT_REF, TreeModel
} from '../../../modules/shared/components/base/data-table/data-table.utils';
import {FilterPageRequest} from '../../../modules/shared/models/filter.page.request';
import {BaseColumn} from '../../../modules/shared/components/base/data-table/columns/column-types';
import {CellHandleEvent, DataTableComponent, ExpandEvent} from '../../../modules/shared/components/base/data-table/data-table.component';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {MasterProcessor} from '../../../common/master-processor';
import {ColumnIds} from '../../../common/column-ids';
import {AddressService, AuthService, OrdersService} from '../../../services';
import {MasterService} from '../../../services/master/master.service';
import {FileUploadService} from '../../../services/file.upload.service';
import {isNullOrUndefined} from 'util';
import {validMAWB} from '../../../modules/shared/services/oms-converters.service';
import {sameDateTime, subtractHours} from '../../../common/oms-date-time.utils';
import {BaseEntity} from '../../../modules/shared/models';
import {ExtendedMasterStatus, TabFilter} from './orders-table/orders-table.component';
import {PageResult} from '../../../modules/shared/models/query-models/page-result';
import {switchMap, takeUntil} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {TabInfo} from './tabs-type';
import {Carrier} from '../../../modules/shared/models/carrier';
import {OmsDialogsService} from '../../../components/common/oms-dialogs';
import {FilterSearchColumn} from '../../../modules/shared/models/filter.search.column';
import {UserService} from "../../../modules/shared/services/user.service";
import {ItemUpdateData} from "./item-update-data";

export abstract class AbstractMasterTable<T extends Master | Order> implements DoCheck, OnDestroy, TreeModel, OnInit, AfterViewInit, OnChanges, ItemUpdateData {

  abstract tabInfo: TabInfo;
  isVisible: boolean;
  private refreshTimer;
  private refreshTimeout: number = 2000;

  @Input() public selected: T[] = [];
  @Input() menu: ((row, column?: BaseColumn) => ContextMenuComponent) | ContextMenuComponent;
  @Input() public active: boolean = true;
  @Output('cell-right-click') cellRightClickEvent: EventEmitter<CellHandleEvent> = new EventEmitter<CellHandleEvent>(false);
  @Output('cell-click') cellClickEvent: EventEmitter<CellHandleEvent> = new EventEmitter<CellHandleEvent>();
  @Output() selectedChange = new EventEmitter<T[]>();

  toolbar: HTMLDivElement;
  colorStatusFilter: ColorItem;
  searchable: boolean = true;

  protected filter: FilterOptions = {total: 0, search: '', colorItem: null};

  public isDirty: boolean;

  private valuesDiffer: KeyValueDiffer<any, any>;

  public showDirectMasters: boolean = false;
  public showNonAmsMasters: boolean = false;
  public showPendingRecoveriesMasters: boolean = false;
  public showAmsMasters: boolean = false;
  public showCargoBldg: boolean = false;
  public show3rdPartyCFS: boolean = false;

  public availableStatuses: ExtendedMasterStatus[] = [];
  public selectedStatuses: MasterStatus[] = [];
  public selectedFilters: TabFilter[] = [];

  public paging: PagingOptions = {enabled: true, pageSize: 100, pageSizes: [50, 100, 200, 500]};
  public abstract filterPageRequest: FilterPageRequest;

  protected clientUser: boolean = false;

  public items: T[] = [];
  protected columns: BaseColumn[];

  protected activeSearchFilterCondition: any = {};

  protected pageRequest: boolean = false;

  @ViewChild('mastersTable') public table: DataTableComponent;

  protected unsubscribe$ = new Subject<void>();
  protected updateData$ = new Subject<FilterPageRequest>();
  protected response$ = new Subject<void>();

  totalPcs: number = 0;
  totalHu: number = 0;
  totalWeight: number = 0;
  totalUld: number = 0;

  public orderFromMasterList$ = new BehaviorSubject(null);

  protected constructor(
    protected valueDiffers: KeyValueDiffers,
    protected cdr: ChangeDetectorRef,
    protected alerts: OmsAlertsService,
    protected masterProcessor: MasterProcessor,
    protected addressService: AddressService,
    protected _masterService: MasterService,
    protected fileUploadService: FileUploadService,
    protected _ordersService: OrdersService,
    public spinner: NgxSpinnerService,
    protected dialogsService: OmsDialogsService,
    protected userService: UserService,
    protected authService: AuthService) {
    this.valuesDiffer = this.valueDiffers.find({}).create();
  }

  ngOnInit() {
    this.updateFilterButtons();
    const a: number = +localStorage.getItem('masterListPageSize');
    if (a > 0) {
      this.paging.pageSize = a;
    }
    this.columns = this.createColumns(this.authService.getCurrentUser().canCreateCarrier);

//    this.spinner.show();
    this.updateDataHandler();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabInfo) {
      // console.log('CHANGED', changes);
      this.updateFilterButtons();
    }
  }

  protected abstract loadItemsByFilter(request: FilterPageRequest): Observable<PageResult<T>>;

  protected abstract createColumns(isCanCreateCarrier: boolean): BaseColumn[];

  protected updateDataHandler() {
    this.updateData$.pipe(
      takeUntil(this.unsubscribe$),
//      debounceTime(2000),
      switchMap((request) => {
          this.pageRequest = true;
          if (this.tabInfo) {
            this.tabInfo.isLoading = true;
          }

          if (this.isVisible) {
            this.spinner.show();
            if (this.table) {
              this.table.beforeUpdate();
            }

            this.cdr.markForCheck();
          }
          return this.loadItemsByFilter(request);
        }
      ))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.response$.next();
          this.paging.total = response.totalElements;
          this.items = response.content;
          this.updateTotalValues(this.items);
//        console.log('LOADED >', this.tabInfo.filterName, this.items);
          this.pageRequest = false;
          if (this.tabInfo) {
            this.tabInfo.isLoading = false;
          }

          this.spinner.hide();
          if (this.isVisible) {
            if (this.table) {
              this.table.afterUpdate();
            }
            this.cdr.markForCheck();
          }
        },
        (error) => {
          this.spinner.hide();
          this.pageRequest = false;
          if (this.tabInfo) {
            this.tabInfo.isLoading = false;
          }

          this.alerts.danger(error);
          this.cdr.markForCheck();
        });
  }

  private updateFilterButtons() {
    // No Status Selected by Default (Display all)
    // if (this.tabInfo) {
    //  this.selectedFilters = this.tabInfo.tabFilters ? [...this.tabInfo.tabFilters] : [];
    // }

/*    if (this.tabInfo && this.tabInfo.statuses) {
      this.availableStatuses = this.tabInfo.statuses.map(statusId => {
        let status = MasterStatus.get(statusId);
        let obj = Object.assign({}, status) as ExtendedMasterStatus;
        let splits = status.label.split('.', 2);
        obj.shortName = splits[0] + '.' + splits[1][0];
        return obj;
      });
      this.selectedStatuses = [...this.availableStatuses];
    } */
  }

  ngAfterViewInit(): void {
  }

  onChangeStatus(status: MasterStatus) {
    const index = this.selectedStatuses.indexOf(status);
    if (index >= 0) {
      this.selectedStatuses.splice(index, 1);
    } else {
      this.selectedStatuses.push(status);
    }
    this.refresh(true);
  }

  toggleTabFilter(filter: TabFilter) {
    if (this.selectedFilters.includes(filter)) {
      this.selectedFilters.removeAll(filter);
    } else {
      this.selectedFilters.push(filter);
    }

    console.log('filters', this.selectedFilters);

    this.refresh(true);
  }

  downloadExcel() {
    this._masterService.downloadMasters(this.filterPageRequest, this.alerts).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const fileURL = this.buildShowUrl(res);
      window.open(fileURL, '_blank');
    });
  }

  onPageSizeChanged(pageSize) {
    if (!isNullOrUndefined(pageSize)) {
      localStorage.setItem('masterListPageSize', pageSize);
    }
  }

  buildShowUrl(generatedDocumentInfo) {
    return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
  }

  getDefaultSortableColumn(): string {
    return String(ColumnIds.MASTER_STATUS);
  }

  public changed() {
    this.cdr.markForCheck();
  }

  public updateItems(items: T[]) {
    if (this.items && items) {
      items.forEach((i) => this.items.replaceAll(i, i));
      this.changed();
    }
  }


  public refresh(timeout?: boolean) {
    clearTimeout(this.refreshTimer);
    if (timeout) {
      this.refreshTimer = setTimeout(() => this.updateData(), this.refreshTimeout);
    } else {
      this.updateData();
    }
  }

  public updateData(filterPageRequest?: FilterPageRequest): Subject<void> {
//    console.log('Update Tab', this.tabInfo);
    this.isDirty = false;

    if (!this.filterPageRequest) {
      return;
    }
    if (filterPageRequest) {
      this.filterPageRequest.pageSize = filterPageRequest.pageSize;
      this.filterPageRequest.pageNumber = filterPageRequest.pageNumber;
      this.filterPageRequest.statusFilters = filterPageRequest.statusFilters || [];
      this.filterPageRequest.filterByColumns = filterPageRequest.filterByColumns || [];
      if (isNullOrUndefined(filterPageRequest.findByOccurs)) {
        this.filterPageRequest.findByOccurs = this.getSearchText();
      } else {
        this.filterPageRequest.findByOccurs = filterPageRequest.findByOccurs;
        this.setSearchText(filterPageRequest.findByOccurs);
      }
      this.table.filter.search = this.filterPageRequest.findByOccurs;
      if (filterPageRequest.sort && filterPageRequest.sort.field) {
        this.filterPageRequest.sort = this.filterPageRequest.sort || filterPageRequest.sort;
        this.filterPageRequest.sort.field = filterPageRequest.sort.field;
        this.filterPageRequest.sort.sortedAsc = filterPageRequest.sort.sortedAsc;
      }
    } else {
      this.filterPageRequest.findByOccurs = this.getSearchText();
      this.filterPageRequest.filterByColumns = this.filterPageRequest.filterByColumns || [];
    }

    this.applyFilter(this.filterPageRequest);


    if (this.tabInfo.getFilters) {
      let filters = this.tabInfo.getFilters();
      this.filterPageRequest.filterByColumns.push(...filters);
    }

    // apply status filters
    if (this.selectedFilters.length || this.tabInfo.getFilters) {
      this.filterPageRequest.statusFilters = [...this.selectedFilters];
    } else if (this.tabInfo.namedConditions) {
      this.filterPageRequest.namedConditions =  this.tabInfo.namedConditions;
    } else {
      // no selected filters - return all status filters to select (New Logic)
      this.filterPageRequest.statusFilters = [...this.tabInfo.tabFilters];

/*      // no selected filters - return empty result set (Old Logic)
      this.response$.next();
      this.paging.total = 0;
      this.items = [];
      this.updateTotalValues(this.items);
      this.cdr.markForCheck();
      return this.response$;

 */
    }

//    console.log('NEXT>>>', this.filterPageRequest);
    if (this.tabInfo) {
      this.tabInfo.isLoading = true;
    }

    this.updateData$.next(this.filterPageRequest);
    return this.response$;
  }

  public setSearchText(text: string) {
    this.filter.search = text;
    this.filter = Object.assign({}, this.filter);
  }

  public getSearchText(): string {
    return this.filter.search;
  }

  removeFilterValue(filterPageRequest: FilterPageRequest, columnId: string | number) {
    filterPageRequest.filterByColumns = filterPageRequest.filterByColumns.filter(column => column.field !== columnId);
  }

  changeFilterValue(filterPageRequest: FilterPageRequest, columnId: string | number, value) {
    this.removeFilterValue(filterPageRequest, columnId);
    if (value) {
      filterPageRequest.filterByColumns.push(new FilterSearchColumn(columnId, value, null, null));
    }
  }

  public abstract applyFilter(filterPageRequest?: FilterPageRequest): void;

  public abstract updateTotalValues(items: T[]): void;

  ngOnDestroy(): void {
    delete this.table;

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showMaster(data: Object) {
    this.table.toggleExpanded(data);
  }

  onSelectMaster(event) {
    this.selectedChange.emit(event);
  }

  buildDownloadUrl(fileType: string) {
    return this._masterService.buildDownloadUrl(fileType);
  }

  onUpdateFieldError(error, updateEvent: FieldUpdateEvent) {
    if (updateEvent) {
      updateEvent.cancel();
    }

    if (typeof error === 'string' && error.includes('UNIQUE KEY')) {
      error = 'Duplicated value not allowed: ' + updateEvent.newValue;
    }

//    console.log('Update Field Error', updateEvent, error);
    this.alerts.danger(error);
  }

  public updateObject(object: Master | Order, fields: {}): Promise<any> {
    console.log('updateObject', object, fields);

    if (object instanceof Master) {
      return this._masterService.updateMaster(object.id, fields)
        .then((master: Master) => {
          if (/*!this.webSocketService.isSupport && */this.items) {
            (this.items as Master[]).replaceAll(master, master);
            this.table.refresh([master]);
          }
        });
    } else if (object instanceof Order) {
      return this._ordersService.updateOrderOld(object.id, fields)
        .then((master) => {
          if (this.items && master instanceof Master) {
            let order: Order = isNullOrUndefined(master) ? object : master.orders.filter((o) => o.id === object.id)[0];
            if (order) {
              order.mawb = object.mawb;
              order['cfsLocation'] = object['cfsLocation'];
//              console.log('REPLACE', this.items, 'WITH', order);
              (this.items as Order[]).replaceAll(order, order, (o1) => console.log('found'));
              this.table.refresh([order]);
            }
          }
        });
         }
  }

  /*  private updateOrderObject(master: Master, order: Order, field: any, value: any): Promise<MasterLine> {
      console.log('updateOrderObject', master, order, field, value);

      return this._masterService.updateOrderDate(master, order, field, value)
        .then((newMaster: Master) => {
          if (!this.webSocketService.isSupport && this.masters) {
            this.masters.replaceAll(newMaster, newMaster);
            console.log('refreshed', newMaster);
            this.table.refresh([newMaster]);
          }
          return newMaster;
        });
    }*/

  onExpandItem(event: ExpandEvent) {
/*    if (event.row instanceof MasterLine && event.expanded) {
      this._masterService.getMaster(event.row.id, false).then(master => {
        this.table.data.replaceAll(event.row, master);
        this.table.refresh([master]);
        this.table.cdr.markForCheck();
      });
    } */
  }

  onUpdateField(event: FieldUpdateEvent) {
    if (event.row instanceof Master) {
      const fields = {[event.field]: event.newValue};
      const master: Master = <Master>event.row;
      console.log('Master Fields:', fields);
      switch (event.field) {

        case 'masterAir.mawbNumber':
          console.log('validate', event.newValue);
          if (validMAWB(event.newValue, error => {
            event.error(error);
            this.alerts.danger(error, 1000);
          })) {
            this.updateObject(master, fields).catch((error) => this.onUpdateFieldError(error, event));
          }
          break;

        case 'status':
          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.status = event.newValue))
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'datePickupActual':
          const changePickUp = !sameDateTime(event.newValue, event.oldValue || master.datePickupEstimated);
          const oldDateArrivalActual = master.dateArrivalAct;
          if (changePickUp) {
            fields['dateArrivalAct'] = master.updateArrivalDay();
          }

          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.datePickupAct = event.newValue))
            .catch(error => {
              master.dateArrivalAct = oldDateArrivalActual;
              this.onUpdateFieldError(error, event);
            });
          break;

        case 'dateCfsIn':
          const newDatePickupAct = isNullOrUndefined(event.newValue) ? null : subtractHours(event.newValue, 2);
          const updatePickup: boolean = !isNullOrUndefined(newDatePickupAct);
          if (updatePickup) {
            const oldDatePickupActual = master.datePickupActual;
            fields['datePickupActual'] = newDatePickupAct;
          }

          this.updateObject(master, fields)
            .then(() => {
              if (updatePickup) {
                master.datePickupActual = newDatePickupAct;
                master.orders.forEach(order => {
                  order.datePickupAct = newDatePickupAct;
                });
              }
            })
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'datePickupEstimated':
          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.datePickupEst = event.newValue))
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'dateDeliveryActual':
          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.dateDeliveryAct = event.newValue))
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'dateDeliveryEstimated':
          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.dateDeliveryReq = event.newValue))
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'date1C':
          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.date1C = event.newValue))
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'date1F':
          console.log('date1F', fields);
          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.date1F = event.newValue))
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'dateISCPaid':
          this.updateObject(master, fields)
            .then(() => master.orders.forEach((order) => order.dateISCPaid = event.newValue))
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'customer':
          fields[event.field] = isNullOrUndefined(event.newValue) ? null : (<Customer>event.newValue).id;
          this.updateObject(master, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });
          break;

        case 'freightForwarder':
          fields[event.field] = isNullOrUndefined(event.newValue) ? null : (<FreightForwarder>event.newValue).id;
          this.updateObject(master, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });
          break;

        case 'addressDelivery':
          if (!master.direct) {
            fields['addressCfs'] = isNullOrUndefined(event.newValue) ? null : (<Address>event.newValue).id;
            this.updateObject(master, fields).catch((error) => {
              this.onUpdateFieldError(error, event);
            });
          } else {
            event.cancel();
          }
          break;

        case 'cargoBuilding':
        case 'shipment.addressDelivery':
          fields[event.field] = isNullOrUndefined(event.newValue) ? null : (<Address>event.newValue).id;
          if (event.newValue && (<Address>event.newValue).cfs3plLocation) {
            fields['nonAMS'] = true;
          }
          this.updateObject(master, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });
          break;

        case 'dateArrivalEst':
/*          let oldLastFreeDay = master.dateLastFreeDay;
          if (isNullOrUndefined(master.dateArrivalAct)) {
            fields['dateLastFreeDay'] = master.calculateLastFreeDate(fields['dateArrivalEst'], null);
          } */
          this.updateObject(master, fields)
            .catch((error) => {/*master.dateLastFreeDay = oldLastFreeDay; */
              this.onUpdateFieldError(error, event);
            });
          break;

        case 'masterAir.airline':
          fields[event.field] = BaseEntity.idOf(event.newValue);
          this.updateObject(master, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });
          break;

        case 'dateArrivalAct':
/*          const changed = !sameDateTime(event.newValue, event.oldValue || master.dateArrivalEst);
          oldLastFreeDay = master.dateLastFreeDay;
          if (changed) {
            fields['dateLastFreeDay'] = master.calculateLastFreeDate(fields['dateArrivalAct'], master.dateArrivalEst);
          } */
          this.updateObject(master, fields)
            .catch((error) => {/*master.dateLastFreeDay = oldLastFreeDay; */
              this.onUpdateFieldError(error, event);
            });
          break;

        case 'pieces':
          if (master.isSingleOrder) {
            this.updateObject(master, fields)
              .then(() => event.apply())
              .catch(error => this.onUpdateFieldError(error, event));
          } else {
            event.cancel();
          }
          break;

        case 'weight':
          if (master.isSingleOrder) {
            this.updateObject(master, fields)
              .then(() => {
                event.apply();
              })
              .catch(error => this.onUpdateFieldError(error, event));
          } else {
            event.cancel();
          }
          break;

        case 'volume':
          if (master.isSingleOrder) {
            this.updateObject(master, fields)
              .then(() => {
                event.apply();
              })
              .catch(error => this.onUpdateFieldError(error, event));
          } else {
            event.cancel();
          }
          break;

        case 'hu':
          if (master.isSingleOrder) {
            this.updateObject(master, fields)
              .then(() => {
                event.apply();
              })
              .catch(error => this.onUpdateFieldError(error, event));
          } else {
            event.cancel();
          }
          break;


        default:
          this.updateObject(master, fields).catch((error) => this.onUpdateFieldError(error, event));
          break;
      }

    } else

    // Order Part
    if (event.row instanceof Part) {
      console.log('UPDATE PART', event);
      const part: Part = <Part>event.row;

      switch (event.field) {
        case 'pcsOrder':
          const fields = {['pieces']: event.newValue};
          this.updateObject(part.order, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });
          break;
      }

    } else if (event.row instanceof Order) {
      const order = <Order>event.row;
      const master: Master = <Master>event.row[PARENT_REF];
      const fields = {[event.field]: event.newValue};
//      console.log('UPDATE HERE 2: ', order, event.field);

      // if (!isNullOrUndefined(master)) {
      switch (event.field) {
        case 'customer':
          fields[event.field] = isNullOrUndefined(event.newValue) ? null : (<Customer>event.newValue).id;
          this.updateObject(order, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });
          break;

        case 'addressDelivery':
          fields[event.field] = isNullOrUndefined(event.newValue) ? null : (<Address>event.newValue).id;
          this.updateObject(order, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });

          const address: Address = event.newValue;
          if (address && order.customer) {
            this.addressService.setCustomerDeliveryLocation(order.customer, address);
          }
          break;

        case 'pieces':
          this.updateObject(order, fields)
            .then(() => {
              if (master && master.updatePieces()) {
                this.updateObject(master, {'pieces': master.pieces})
                  .catch(error => this.alerts.danger(error));
              }
            })
            .then(() => master && master.reset())
            .catch(error => this.onUpdateFieldError(error, event));
          break;

        case 'dateCfsInAct':
        case 'dateCfsInEst':
        case 'dateCfsOutAct':
        case 'dateCfsOutEst':
        case 'dateDeliveryAct':
        case 'dateDeliveryReq':
          this._masterService.updateOrderDate(master, order, event.field, event.newValue)
            .then(() => event.apply())
            .catch(e => {
              event.cancel();
              this.alerts.danger(/*'Error updating field: ' + */e);
            });
          break;

        case 'weight':
          this.updateObject(order, fields)
            .then(() => {
              if (master && master.updateWeight()) {
                this.updateObject(master, {'weight': master.weight})
                  .catch(error => this.onUpdateFieldError(error, event));
              }
            }).catch(error => this.onUpdateFieldError(error, event));
          break;
        case 'carrier':
          fields[event.field] = isNullOrUndefined(event.newValue) ? null : (<Carrier>event.newValue).id;
          this.updateObject(order, fields).catch((error) => {
            this.onUpdateFieldError(error, event);
          });
          break;

        default:
          this.updateObject(order, fields).catch(error => this.onUpdateFieldError(error, event));
          break;
      }
    } else if (event.row instanceof RecoveryOrder) {
      const split: RecoveryOrder = <RecoveryOrder>event.row;
      console.log(event.field);

      switch (event.field) {
        case 'isDispatched':
          if (split.load) {
            this.masterProcessor.dispatchRecoveryLoad(split, true, this.clientUser)
              .then(master => {
                this.table.refresh([master]);
                console.log('DISPATCH RESULT 1 >', master);
                this.changed();
              });
          } else {
            this.openNewManifestFromMasterList(split.order);
          }
          break;

        case 'dateCfsIn':
          split.shipment.dateDeliveryActual = null;
          event.apply();
          break;

        case 'datePickupAct':
          this._masterService.updateLoadDate(split.master, split.load, 'datePickupAct', event.newValue)
            .then((master) => {
              event.apply();
              this.table.refresh([master]);
            })
            .catch(e => {
              event.cancel();
              this.alerts.danger(e);
            });
          break;

        case 'datePickupEst':
          this._masterService.updateLoadDate(split.master, split.load, 'datePickupEst', event.newValue)
            .then((master) => {
              event.apply();
              this.table.refresh([master]);
            })
            .catch(e => {
              event.cancel();
              this.alerts.danger(e);
            });
          break;

        case 'pcsToRecover': {
          if (this._masterService.validateRecoveryLoadPieces(split.master, split, event.newValue)) {
//            if (!split.isDispatched) {
            this._masterService._updateMaster(split.master, master => {
              if (split.isVirtual) {
                master.addRecoveryLoad(event.newValue, null);
              } else {
                master.recovery.forEach(ld => {
                  if (ld.id === split.loadId) {
                    ld.shipment.pieces = event.newValue;
                  }
                });
              }
              return true;
            }).catch(error => {
              event.cancel();
              this.alerts.danger('Error Saving changes:\n ' + error);
            }).then((master: Master) => {
//                split.master.reset();
              (this.items as Master[]).replaceAll(master, master);
              this.table.refresh([master]);
              this.changed();
            });
//            } else
//              split.master.reset();

          } else {
            event.cancel();
          }

          break;
        }
        default: {
          /*let fields = {[event.field]: event.newValue};
          this.updateObject(split.load, fields)
            .then(() => {
              event.apply()
            })
            .catch(e => {
              event.cancel();
              this.alerts.danger( e)
            });*/
        }
      }
    }
  }

  children(parent): Observable<any[]> {
    if (parent instanceof Master) {
      return of([...parent.splits, ...parent.orders]);
    }

    return of([]);
  }

  isLeaf(parent): boolean {
    return !(parent instanceof Master);
  }


  ngDoCheck(): void {
/*
    let changed: boolean = false;
    if (this.valuesDiffer.diff({
      searchable: this.searchable,
    })) {
      changed = true;
    }

    if (changed)
      this.cdr.markForCheck();
*/

  }

  doCellRightClick(event) {
    this.cellRightClickEvent.emit(event);
  }

  doCellClick(event) {
    this.cellClickEvent.emit(event);
  }

  public changeSearchText(searchText: string, debounceTime?: number): void {
    this.setSearchText(searchText);
    this.filterPageRequest.findByOccurs = searchText;
    if (this.table) {
      this.table.changeSearchText(searchText, debounceTime);
    }
  }

  public openNewManifestFromMasterList(order: Order) {
    if (!isNullOrUndefined(order)) {
      this.orderFromMasterList$.next(order.id);
    }
  }

  abstract onRestoreSelected();

}
