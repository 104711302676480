﻿import {Route, RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './pages/login';
import {RegisterComponent} from './pages/register';
import {AuthGuard} from './_helpers';
import {HomeComponent} from './modules/shared/pages/home/home.component';
import {TestPageComponent} from './pages/test-page';
import {OrderEditorComponent} from './pages/orders/order-editor/order-editor.component';
import {MastersListComponent} from './pages/masters/masters-list/masters-list.component';
import {MasterEditorComponent} from './pages/masters/master-editor/master-editor.component';
import {PickupComponent} from "./pages/pickup/pickup.component";
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {OrdersListComponent} from "./modules/logs/pages/orders-list/orders-list.component";
import {StatusInfoComponent} from "./pages/status-info/status-info.component";

import {PagePermissionDeniedComponent} from "./pages/page-permission-denied/page-permission-denied.component";
import {RedirectRoleAuthGuard} from "./_helpers/redirect-role-auth.guard";
import {UserRoleType} from "./modules/shared/models/user-role.type";
import {PageFileDownloadComponent} from "./pages/page-file-download/page-file-download.component";
import {ManifestCreatePageComponent} from "./modules/dispatch/pages/manifest-create-page/manifest-create-page.component";
import {OmsMapsPageComponent} from "./modules/dispatch/pages/oms-maps-page/oms-maps-page.component";
import {DocCenterPageComponent} from "./components/common/doc-center/doc-center-page/doc-center-page.component";

export const routingConfiguration: any = {
    paramsInheritanceStrategy: 'always'
};

const pageNotFound: Route = {path: '404', component: PageNotFoundComponent, data: {label: 'Page Not Found', roles: [UserRoleType.ROLE_CLIENT, UserRoleType.ROLE_ADMIN]}};
const pagePermissionDenied: Route = {path: '403', component: PagePermissionDeniedComponent, data: {label: 'Permission denied'}};
const pageDocCenter: Route = {path: 'doc-center', component: DocCenterPageComponent, data: {label: 'Document Center', roles: [UserRoleType.ROLE_CLIENT, UserRoleType.ROLE_ADMIN]}};
const pageLogin: Route = {path: 'login', component: LoginComponent, data: {label: 'Login'}};
const pageManifest: Route = {path: 'manifest', component: ManifestCreatePageComponent, data: {label: 'Manifest'}};
const pageOmsMaps: Route = {path: 'map', component: OmsMapsPageComponent, data: {label: 'Map'}};
const pageRegisterUser: Route = {path: 'register', component: RegisterComponent, data: {label: 'Register'}};

/*
const pageReceiving: Route = {
    path: 'receiving',
    component: ReceivingComponent,
    canActivate: [AuthGuard],
    data: {label: 'Receiving', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]}
};
 */

const pagePickUp: Route = {
    path: 'pickup',
    component: PickupComponent,
    canActivate: [AuthGuard],
    data: {
        label: 'Driver/Dispatch pick up',
        roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]
    }
};


/*const pageSettings: Route = {path: 'settings', data: {label: 'Settings'}, canActivate: [AuthGuard],
  children: [
    {path: 'users', component: UserListComponent, canActivate: [AuthGuard], data:{label: 'Users'}},
    {path: 'addresses', component: AddressListComponent, canActivate: [AuthGuard], data:{label: 'Addresses'}},
    {path: 'customers', component: CustomerListComponent, canActivate: [AuthGuard], data:{label: 'Customers'}},
    { path: '**', redirectTo: 'settings' }
  ]};*/


const pageOrderEdit: Route = {
    path: 'order',
    component: OrderEditorComponent,
    canActivate: [AuthGuard],
    data: {label: 'Order', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]}
};

const pageMasterEdit: Route = {
    path: 'master',
    component: MasterEditorComponent,
    canActivate: [AuthGuard],
    data: {label: 'Master', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]},
    children: [
        pageOrderEdit,
//        pageReceiving,
        pagePickUp]
};

const pageMasterList: Route = {
    path: 'masters',
    component: MastersListComponent,
    canActivate: [AuthGuard],
    data: {label: 'Air Import List', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]},
    children: [
        pageMasterEdit,
        pageOrderEdit,
//        pageReceiving,
        pagePickUp,
        {path: '**', redirectTo: 'masters'}]
};

/*
const pageOrderList: Route = {
  path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: {label: 'Order List'},
  children: [
    pageOrderEdit,
    {path: '**', redirectTo: 'orders'}]
};
 */

const pageOrderList2: Route = {
    path: 'order-list',
    component: OrdersListComponent,
    data: {label: 'Order List', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]},
    canActivate: [AuthGuard],
    children: [
        pageOrderEdit,
        {path: '**', redirectTo: 'order-list'}]
};

const pageTest: Route = {
    path: 'test',
    component: TestPageComponent,
    canActivate: [AuthGuard],
    data: {label: 'Test Page'}
};

const pageFileDownload: Route = {
  path: 'download',
  component: PageFileDownloadComponent,
  canActivate: [AuthGuard],
  data: {label: 'File Download'}
};

const pageHome: Route = {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {label: 'Home', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]},
    children: [
//        pageReceiving,
        pageNotFound,
        pagePickUp,
        pageMasterList,
        pageMasterEdit,
        pageOrderEdit,
        pageOrderList2,
        pageTest,
        {path: '**', redirectTo: 'masters'}
    ]
};

const settingsRoutes: Route = {
    path: 'settings',
    data: {roles: [UserRoleType.ROLE_ADMIN]},
    canActivate: [AuthGuard],
    loadChildren: ('./modules/settings/settings.module#SettingsModule')
};

const webDriversRoutes: Route = {
    path: 'webdrivers',
    data: {roles: [UserRoleType.ROLE_DRIVER]},
    canActivate: [AuthGuard],
    loadChildren: ('./modules/web-drivers/web-drivers.module#WebDriversModule')
};

const logsRoutes: Route = {
    path: 'logs',
    data: {roles: [UserRoleType.ROLE_ADMIN]},
    canActivate: [AuthGuard],
    loadChildren: ('./modules/logs/logs.module#LogsModule')
};

const shipmentRoutes: Route = {
    path: 'dispatch',
    canActivate: [AuthGuard],
    loadChildren: ('./modules/dispatch/dispatch.module#DispatchModule'),
    data: {roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]}
};

const airExportRoutes: Route = {
    path: 'air-export',
    data: {restricted: true, roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]},
    canActivate: [AuthGuard],
    loadChildren: ('./modules/air-export/air-export.module#AirExportModule')
};

const fclRoutes: Route = {
    path: 'fcl',
    data: {restricted: true, roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]},
    canActivate: [AuthGuard],
    loadChildren: ('./modules/fcl/fcl.module#FclModule')
};

const stbStatusRoutes: Route = {
    path: 'ams',
    canActivate: [AuthGuard],
    data: {roles: [UserRoleType.ROLE_AMS, UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER]},
    loadChildren: ('./modules/cbp-status/cbp-status.module#CbpStatusModule')
};

/** old implementation
const statusInfoRoutes: Route = {
    path: 'cfsstatusinfo',
    component: StatusInfoComponent,
    data: {label: 'CFS Status Info', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]}
};
**/

/*
function pathMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  if (segments.length > 2) {
    if (segments[2].path.match(/([\w|]+[\d]+)/)) {
      let posParams = {'id': segments[2]};
      return {consumed: segments, posParams: posParams};
    } else {
      return null;
    }
  } else {
    return null;
  }
}
 */


const cfsStatusRoutes: Route = {
  path: 'cfs-status/:id',
//  matcher: <any> pathMatcher,
  component: StatusInfoComponent,
  data: {label: 'CFS Status Info', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_QA, UserRoleType.ROLE_USER]},
  children: [pageDocCenter]
};


const clientModule: Route = {
    path: 'client',
    canActivate: [AuthGuard],
    data: {roles: [UserRoleType.ROLE_CLIENT, UserRoleType.ROLE_ADMIN]},
    loadChildren: ('./modules/client/client.module#ClientModule')
};

const warehouseRoutes: Route = {
    path: 'warehouse',
    data: {roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER, UserRoleType.ROLE_WAREHOUSE, UserRoleType.ROLE_EXTERNAL_WAREHOUSE]},
    canActivate: [AuthGuard],
    loadChildren: ('./modules/warehouse/warehouse.module#WarehouseModule')
};

const appRoutes: Routes = [
    pageLogin,
    pageHome,
    pagePermissionDenied,
    pageNotFound,
    pageDocCenter,
    pageFileDownload,
//    pageReceiving,
    pageRegisterUser,
    pageManifest,
    pageOmsMaps,
    settingsRoutes,
    airExportRoutes,
    fclRoutes,
    logsRoutes,
    stbStatusRoutes,
    shipmentRoutes,
//    statusInfoRoutes,
    cfsStatusRoutes,
    clientModule,
    warehouseRoutes,
    webDriversRoutes,
    {
        path: '**', canActivate: [RedirectRoleAuthGuard], component: HomeComponent, // fake component
        data: {
            redirects: [
              {redirectTo: 'home', roles: [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER]},
              {redirectTo: 'client', roles: [UserRoleType.ROLE_CLIENT]},
              {redirectTo: 'ams', roles: [UserRoleType.ROLE_AMS]}]
        }
    },
    // {path: '**', redirectTo: 'home'}
];


export const routing = RouterModule.forRoot(appRoutes, routingConfiguration);
