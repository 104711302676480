﻿<oms-base-dialog [icon]="'fa fa-upload'" [max-width]="'500px'" [title]="getTitle()" [loading]="viewLoading"
                 [btnOk]="{caption: 'Upload', icon: 'fa fa-upload', handler: submit}"
                 [btnCancel]="{caption: 'Close', icon: 'fa fa-times', handler: close}"
                 [store-position]="false" [sizable]="false">

    <form [formGroup]="form">

      <div class="form-group">
        <label for="inputFiles">Select File</label>
        <div class="input-group" style="width: 100%;" >
          <input id="inputFiles" [attr.readonly]="true" class="form-control" type="text" required="required"
                 [ngClass]="{'form-control-invalid': isControlInvalid('file')}"
                 formControlName="file"
                 [ngModel]="getSelectedFileName()" >
          <span class="input-group-btn">
            <div class="btn btn-default btn-file" style="width: 43px">
              <i class="fa fa-search"></i>
              <input type="file" (change)="selectFile($event)"/>
            </div>
          </span>
        </div>
        <div *ngIf="isControlInvalid('file')" class="invalid-feedback">Please select file to upload</div>

      </div>

      <!--div class="form-group">
        <label for="inputDocType">Document Type</label>
        <div class="input-group" style="width: 100%;" >
          <input id="inputDocType" [attr.readonly]="true" type="text" class="form-control" required="required"
                 [ngClass]="{'form-control-invalid': isControlInvalid('docType')}"
                 formControlName="docType"
                 [ngModel]="documentType?.description">

          <span class="input-group-btn">
            <button type="button" style="width: 43px"
                    class="btn btn-default dropdown-toggle" data-boundary="window" data-toggle="dropdown" data-target="#dropdownItems" aria-haspopup="true" aria-expanded="false">
              <span class="caret"></span>
            </button>
          </span>
          <div id="dropdownItems" >
            <ul class="dropdown-menu dropdown-menu-right" role="menu" id="dropdown-menu">
              <ng-container *ngFor="let item of documentTypes; let i = index">
                <li><a (click)="onUnitsChanged(item)">{{item.name}}</a></li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div *ngIf="isControlInvalid('docType')" class="invalid-feedback">Document Type required</div>

      </div-->

      <div class="form-group">
        <oms-custom-search [label]="'Document Type'" [bindLabel]="'description'" [items]="documentTypes"
                           (changing)="onUnitsChanged($event)" [allowClear]="false"
                           [(ngModel)]="documentType" formControlName="docType" required>
        </oms-custom-search>

      </div>

      <div class="form-group" *ngIf="!noDescription">
        <label>Description:</label>
        <textarea class="form-control" placeholder="Description" formControlName="description" rows="5"></textarea>
         <div *ngIf="isControlInvalid('description')" class="invalid-feedback">Description required</div>
      </div>

      <div class="form-group" *ngIf="needCarrier">
        <oms-carrier-search [(ngModel)]="carrier" formControlName="carrier" required></oms-carrier-search>
        <!--div *ngIf="isControlInvalid('carrier')" class="invalid-feedback">Carrier required</div-->
        <hr/>
      </div>



      <div class="progress progress-striped active" *ngIf="showProgressBar">
        <div class="progress-bar" role="progressbar"  [attr.aria-valuenow]="progress.percentage" aria-valuemin="0" aria-valuemax="100" [style.width.%]="progress.percentage" >{{progress.percentage}}%</div>
      </div>

      <div class="alert alert-danger" *ngIf="error">
        <h3><i class="fa fa-exclamation-triangle"></i>&nbsp;Error uploading file</h3>
      </div>

    </form>


</oms-base-dialog>
