var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ElementRef, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { Size } from '../../../../../../common/oms-types';
import { BaseInputComponent } from "../../../base/base-input/base-input.component";
import { Logger } from "../../../../../../_helpers/logger";
import { getOffset } from "../../../../../../_helpers/utils";
var DateInputComponent = /** @class */ (function (_super) {
    __extends(DateInputComponent, _super);
    function DateInputComponent(validators, asyncValidators) {
        var _this = _super.call(this, validators, asyncValidators) || this;
        _this.changingEvent = new EventEmitter();
        _this.estimation = false;
        _this.estimatedChange = new EventEmitter();
        _this.inline = false;
        _this.Size = Size;
        _this.size = Size.DEFAULT;
        _this.time = true;
        _this.minDate = null;
        _this.maxDate = null;
        _this.showCheckbox = true;
        _this.showSwitch = false;
        _this.showButton = true;
        _this.showLabel = true;
        _this.showClearButton = false;
        _this.cleanable = true;
        _this.isEdit = false;
        return _this;
    }
    DateInputComponent_1 = DateInputComponent;
    DateInputComponent.prototype.onCbClick = function () {
        if (this.value) {
            this.value = null;
            this.changingEvent.emit(event);
        }
        else {
            this.isEdit = true;
        }
    };
    Object.defineProperty(DateInputComponent.prototype, "popupTop", {
        get: function () {
            var isOnTop = this.input && getOffset(this.input.nativeElement).top > window.outerHeight / 2;
            return isOnTop ? undefined : this.input.nativeElement.offsetHeight;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateInputComponent.prototype, "popupBottom", {
        get: function () {
            var isOnTop = getOffset(this.input.nativeElement).top > window.outerHeight / 2;
            return isOnTop ? 0 : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateInputComponent.prototype, "displayValue", {
        get: function () {
            return this.value || this.estimated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateInputComponent.prototype, "estimatedOnly", {
        get: function () {
            return this.estimation && isNullOrUndefined(this.value) && !isNullOrUndefined(this.estimated);
        },
        enumerable: true,
        configurable: true
    });
    DateInputComponent.prototype.onEstimatedDateChanged = function (event) {
        //    console.log('EST CHANGE', event, this.model);
        this.estimatedChange.emit(event);
    };
    DateInputComponent.prototype.onActualDateChanged = function (event) {
        //    console.log('ACT CHANGE', event);
        this.changingEvent.emit(event);
    };
    DateInputComponent.prototype.onClear = function (isActual) {
        if (isActual) {
            this.value = null;
            this.onActualDateChanged(this.value);
        }
        else {
            this.estimated = null;
            this.onEstimatedDateChanged(this.estimated);
        }
    };
    var DateInputComponent_1;
    DateInputComponent = DateInputComponent_1 = __decorate([
        Logger({}),
        __metadata("design:paramtypes", [Array,
            Array])
    ], DateInputComponent);
    return DateInputComponent;
}(BaseInputComponent));
export { DateInputComponent };
