import {Injectable} from '@angular/core';
import {RestService} from "../rest.service";
import {HttpUtilsService} from "../http-utils.service";
import {Airport} from "../../modules/shared/models/air/airport";
import {BaseEntityService} from '../base/base-entity.service';
import {PageResult} from "../../modules/shared/models/query-models/page-result";
import {plainToClass} from "class-transformer";
import {FilterPageRequest} from "../../modules/shared/models/filter.page.request";
import {Observable} from "rxjs";

@Injectable()
export class AirportService extends BaseEntityService<Airport> {
  apiUrl = '/oms/airport/';
  classType = Airport;

  public constructor(public httpRestService: RestService,  public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public findByFilterPromise(filterPageRequest: FilterPageRequest, active: boolean = true): Promise<any>  {
    return new Promise<any>((result, reject) => {
      return this.httpRestService.post<PageResult<any>>(this.apiUrl + 'findByFilters', filterPageRequest, {params: {active: active}}).subscribe(
      page => {
        result(page.content)
      },
        error => {
          console.error('error', error);
        });
    });
  }
}
