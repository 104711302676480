<oms-base-dialog [no-padding]="true" [store-position]="false" [auto-close]="false"
                 [icon]="'fa fa-dollar-sign'" [title]="'Billing Information'"
                 [can-ok]="modified" [btnOk]="{caption: 'Update'}" (ok)="onUpdate()"
                 [btnCancel]="{caption: 'Close'}"
                 [loading]="loading">

  <oms-data-table
                  [fixed]="false"
                  [columns]="columns"
                  [height]="500"
                  [data]="invoices">
  </oms-data-table>

  <div widget-footer style="width: 300px; display: flex">
    <h3 class="footer-label">Billed:</h3>
    <oms-date-input [disabled]="isReadMode" [showLabel]="false" [size]="Size.SMALL"
                    (changing)="modified = true;"
                    [show-switch]="false" [show-checkbox]="true" [(ngModel)]="dateBilled">
    </oms-date-input>
  </div>
</oms-base-dialog>
