import {Injectable, NgZone} from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {environment} from "../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {filter, finalize, switchMap} from "rxjs/operators";


@Injectable()
export class WebSocketService {
  serverUrl = environment.apiUrl;
  isSupport: boolean = false;

  public stompClientSubject = new BehaviorSubject<JsSocketClient>(null);
//  private sub: JsSocketSubscription;
//  private unsubscribe$ = new Subject<void>();

//  public events$: Subject<any> = new Subject<any>();

  private stompClient = null;

  constructor(private ngZone: NgZone) {
    // this.connect();
  }

  public subscribeByCurrentUser(path: string): Observable<SocketMessage> {
    let subscription: JsSocketSubscription;
    return this.stompClientSubject.pipe(
      filter(stompClient => !!stompClient),
      switchMap((stompClient) => {
        this.unsubscribeSocket(subscription);
        subscription = null;
        return new Observable<SocketMessage>((obs) => {
          const suffix = this.getUserSubscribeSuffix(stompClient);
          subscription = stompClient.subscribe(path + suffix, (message) => {
            console.log("WS: message - %o", message);
            obs.next(message);
          });
          console.log("WS: subscribed by user %o - %o", path, suffix);
        });
      }),
      finalize(() => {
        this.unsubscribeSocket(subscription);
        subscription = null;
      }));
  }

  private unsubscribeSocket(subscription: JsSocketSubscription): void {
    if (subscription) {
      subscription.unsubscribe();
      console.log("WS: unsubscribe");
    }
  }

  public connect() {
    if (this.stompClient) {
      this.stompClient.disconnect();
      this.stompClient = null;
    }
    this.disconnect();
    this.ngZone.runOutsideAngular(() => {
      const socket = new SockJS(this.serverUrl + '/ws');
      this.stompClient = Stomp.over(socket);
      this.stompClient.debug = false;
      this.stompClient.connect({}, () => {
        this.ngZone.run(() => {
          this.isSupport = true;
          this.stompClientSubject.next(this.stompClient);
        });
      });
    });
  }

  /*
    private onConnected(ws: JsSocketClient) {
      if (ws) {
        this.sub = ws.subscribe('/oms-event/*', (event) => {
          this.events$.next(event);
        });

        console.log('WS CONNECTED');
      }
    }

    private onError(error) {
      console.log('WS ERROR', error);
      this.disconnect();

      setTimeout(() => {
        this.connect();
      }, 5000);
    }
   */

  disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect();
      this.stompClient = null;
      console.log('WS DISCONNECTED');
    }
  }

  getUserSubscribeSuffix(stompClient: JsSocketClient): string {
    let url = stompClient.ws._transport.url as string;
    url = url.split("/ws", 2)[1];
    url = url.replace("/websocket", "");
    url = url.split("/")[2];
    console.log("Your current session is: " + url);
    return "-user" + url;
  }
}

export interface JsSocketClient {
  ws: any;
  subscribe: (eventName: string, callback: (message: SocketMessage) => any) => JsSocketSubscription;

  disconnect();
}

export interface JsSocketSubscription {
  unsubscribe: () => void;
}

export interface SocketMessage {
  body: string;
  command: "MESSAGE";
  headers: {};
}
