var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AfterViewInit, ChangeDetectorRef, DoCheck, ElementRef, EventEmitter, IterableDiffer, IterableDiffers, KeyValueDiffer, KeyValueDiffers, OnChanges, OnDestroy, OnInit, QueryList, Renderer2, SimpleChanges, TemplateRef } from '@angular/core';
import { AccountService } from '../../../../../services';
import { DateTimePopupComponent } from '../../common/input/date-time-popup/date-time-popup.component';
import { forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { TreeColumn } from './columns/column-types';
import { isNullOrUndefined, isUndefined } from 'util';
import { DataTableService } from '../../../../../services/data-table.service';
import { assigned, equals, getParent, ifChanged } from '../../../../../_helpers/utils';
import { OBJECT_ROW_ID, SELECTED_FLAG } from './data-table.utils';
import 'rxjs/add/observable/from';
import { RowHeightCache } from './utils/row-height-cache';
import { RowUpdater } from './utils/row-updater';
import { FilterPageRequest } from '../../../models/filter.page.request';
import { FilterSearchColumn } from '../../../models/filter.search.column';
import { SearchSortRequest } from '../../../models/search.sort.request';
import { ContextMenuService } from 'ngx-contextmenu';
import { Logger } from "../../../../../_helpers/logger";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { map, share, switchMap, takeUntil } from 'rxjs/operators';
import { DropEvent } from "ng-drag-drop";
import { Size } from "../../../../../common/oms-types";
import { cleanSearchString } from "../../../../../_helpers/string.util";
// disabled events for updated columns
// let disabledForNow: string[] = ['addressCfs', 'shipment.addressDelivery', 'customer', 'freightForwarder', 'masterAir.airline'];
var selectionSort = ['', 'asc', 'desc'];
var DataTableDropEvent = /** @class */ (function (_super) {
    __extends(DataTableDropEvent, _super);
    function DataTableDropEvent(event, data, row) {
        var _this = _super.call(this, event, data) || this;
        _this.row = row;
        return _this;
    }
    return DataTableDropEvent;
}(DropEvent));
export { DataTableDropEvent };
var DataTableComponent = /** @class */ (function () {
    function DataTableComponent(hostElement, contextMenuService, cdr, element, iterableDiffers, valueDiffers, renderer, accountService, dataTableService) {
        var _this = this;
        this.hostElement = hostElement;
        this.contextMenuService = contextMenuService;
        this.cdr = cdr;
        this.iterableDiffers = iterableDiffers;
        this.valueDiffers = valueDiffers;
        this.renderer = renderer;
        this.accountService = accountService;
        this.dataTableService = dataTableService;
        this.Size = Size;
        this.showDateTimePopup = false;
        this.stickyColumns = undefined;
        this.otherColumns = undefined;
        this.resizeColumns = false;
        this.draggableData = false;
        this.updater = new RowUpdater(this.trackFn);
        this.onRefresh = new EventEmitter();
        this.rowIndexes = new Map();
        this.rowHeightsCache = new RowHeightCache();
        this.indexes = {};
        this.offsetX = 0;
        this.offsetY = 0;
        this.mx = 0;
        this.my = 0;
        this.sx = 0;
        this.sy = 0;
        this.drag = false;
        this.searchTimeout = 1000;
        this.columnSearchItems = {};
        this.unsubscribe$ = new Subject();
        //  private socketSub: JsSocketSubscription;
        this.data = [];
        this.psConfig = {
            wheelSpeed: 1,
            handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
            suppressScrollX: false,
            suppressScrollY: false,
            useBothWheelAxes: false
        };
        this.simplePaging = false;
        this.filter = { total: 0, search: '', colorItem: null };
        this.sort = {};
        this.sortChange = new EventEmitter();
        this.paging = { enabled: false, pageSize: 20 };
        this.contentDrag = false;
        this.readonly = false;
        this.specificRowClassHandler = null;
        this.striped = false;
        this.hover = true;
        this.noFooter = false;
        this.rowDraggable = false;
        this.columnDraggable = false;
        this.rowDropEvent = new EventEmitter();
        this.tableDropEvent = new EventEmitter();
        this.sortBySelected = '';
        this.selectable = false;
        this.checkboxes = false;
        this.multiSelect = false;
        this.selectByRow = true;
        this.keepSelected = false;
        this.isSearchWHSE = false;
        this.bordered = false;
        this.fixed = true;
        this.condensed = false;
        this.searchable = false;
        this.grid = true;
        this.debug = false;
        this.searchDisabled = false;
        this.emptyValue = 'N/A';
        this.virtualization = false; // Experimental!!
        this.usePerfectScrollBar = false; // Experimental!!
        this.selected = [];
        this.expanded = [];
        this.expandedChange = new EventEmitter();
        this.cellRightClick = new EventEmitter(false); // need event result; return handled = true if event is completely handled. It will prevent Context Menu popup
        this.cellClick = new EventEmitter(true);
        this.expandEvent = new EventEmitter();
        this.updateFieldEvent = new EventEmitter(false /*need result for handle*/);
        this.searchCriteriaChanged = new EventEmitter(false /* need false to avoid requests mixing */);
        this.dataDblClick = new EventEmitter();
        this.page = new EventEmitter();
        this.scroll = new EventEmitter();
        this.detailToggle = new EventEmitter();
        this.editErrorEvent = new EventEmitter();
        this.selectedChange = new EventEmitter();
        this.pageSizeChange = new EventEmitter();
        this.rowDetail = { rowHeight: 50 };
        this.rowHeight = function () { return _this.condensed ? 28 : 36; };
        this.getChildrenRowHeight = function (row, index) {
            if (!row) {
                return of(0);
            }
            var height = 0;
            if (!_this.isLeaf(row) && _this.isExpanded(row)) {
                var itemsObs = _this.treeModel.children(row);
                return itemsObs.pipe(switchMap(function (items) {
                    return forkJoin(items.map(function (child) { return _this.getTotalRowHeight(child); }))
                        .pipe(map(function (childHeights) { return childHeights.reduce(function (a, b) { return a + b; }, 0); }));
                }));
            }
            return of(height);
        };
        this.getTotalRowHeight = function (row) {
            var rowHeight = _this.rowHeight;
            var height = typeof (rowHeight) === 'function' ? rowHeight(row) : rowHeight;
            return _this.getChildrenRowHeight(row).pipe(map(function (childHeight) {
                return height + childHeight;
            }));
        };
        this.element = element.nativeElement;
        // this.columnsDiffer = this.iterableDiffers.find([]).create(null);
        this.dataDiffer = this.iterableDiffers.find([]).create(this.trackByRowObject);
        this.selectionDiffer = this.iterableDiffers.find([]).create(this.trackByRowObject);
        this.valuesDiffer = this.valueDiffers.find({}).create();
        dataTableService.searchInputSubject
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (searchText) {
            _this.filter.search = searchText;
            _this.buildSearchRequest();
            // this.setPage(1);
            // this.refresh();
        });
        dataTableService.searchColorSubject
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (colorItem) {
            _this.filter.colorItem = colorItem;
            //  this.setPage(1);
            //  this.refresh();
        });
    }
    Object.defineProperty(DataTableComponent.prototype, "defaultColumnForSort", {
        /*** @deprecated ***/
        set: function (value) { this.sort = { field: value, order: 'asc' }; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "hasData", {
        get: function () {
            return !!this.rowCount;
        },
        enumerable: true,
        configurable: true
    });
    DataTableComponent.prototype.calculatePopupOffset = function (cell) {
        //    console.log('calculatePopupOffset');
        var popupWidth = 300;
        var popupHeight = 450;
        var offset = {
            top: cell.getBoundingClientRect().top - this.bodyDiv.nativeElement.getBoundingClientRect().top + cell.offsetHeight,
            left: cell.getBoundingClientRect().left - this.bodyDiv.nativeElement.getBoundingClientRect().left
        };
        if (this.bodyDiv.nativeElement.getBoundingClientRect().left + offset.left + popupWidth > window.screen.width) {
            offset.right = -offset.left;
            offset.left = undefined;
        }
        if (this.bodyDiv.nativeElement.getBoundingClientRect().top + offset.top + popupHeight > window.screen.height) {
            offset.bottom = -offset.top + cell.offsetHeight;
            offset.top = undefined;
        }
        //    console.log('OFFSET:', offset.top);
        return offset;
    };
    DataTableComponent.prototype.editDateTime = function (cleanable, actual, estimated, showTime, event) {
        if (showTime === void 0) { showTime = true; }
        var target = event.target ? event.target : event.srcElement;
        var cell = getParent(target, HTMLTableCellElement) || target;
        this.dateTimePopup.estimation = !isUndefined(estimated);
        this.editableActualDate = actual || estimated;
        this.editableEstimatedDate = estimated;
        this.dateTimePopup.showTime = showTime;
        this.dateTimePopup.activeTab = (!actual && estimated) ? "estimated" : "actual";
        this.dateTimePopup.clearButton.show = cleanable;
        // adjust popup top position with initialized popup component
        this.dateTimePopup.position = this.calculatePopupOffset(cell);
        this.showDateTimePopup = true;
        this.dateSubject = new Subject();
        return this.dateSubject;
    };
    DataTableComponent.prototype.onDateTimeEdited = function (event, isActual) {
        if (this.dateSubject) {
            if (isActual) {
                this.dateSubject.next({ actual: event });
            }
            else {
                this.dateSubject.next({ estimated: event });
            }
            this.cdr.markForCheck();
        }
    };
    DataTableComponent.prototype.changeSearchText = function (searchText, debounceTime) {
        var _this = this;
        if (debounceTime === void 0) { debounceTime = 1500; }
        if (this.searchTextTimer) {
            clearTimeout(this.searchTextTimer);
            this.searchTextTimer = null;
        }
        this.searchTextTimer = setTimeout(function () {
            _this.dataTableService.changeText(cleanSearchString(searchText));
        }, debounceTime);
    };
    DataTableComponent.prototype.setValue = function (object, value, field) {
        if (object && typeof field === 'string') {
            var path = field.split('.').reverse();
            this.setFieldValue(object, path, value);
        }
    };
    DataTableComponent.prototype.setFieldValue = function (object, path, newValue) {
        if (object && path) {
            if (path.length > 1) {
                this.setFieldValue(object[path.pop()], path, newValue);
            }
            else {
                object[path.pop()] = newValue;
            }
        }
    };
    DataTableComponent.prototype.getHeaderSearchItems = function (column) {
        return Array.isArray(column.search.items) ?
            of(column.search.items) :
            column.search.items(null).pipe(map(function (response) { return response.content; }));
    };
    Object.defineProperty(DataTableComponent.prototype, "sortedAsc", {
        get: function () {
            return this.sort ? !this.sort.order || this.sort.order === 'asc' : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "sortedBy", {
        get: function () {
            return this.sort && this.sort.field;
        },
        enumerable: true,
        configurable: true
    });
    // todo remove OMS Masters specific dependency!!
    DataTableComponent.prototype.ngOnInit = function () {
        /*
            if (!isNullOrUndefined(this.webSocketEventName)) {
              this.webSocketService.stompClientSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(stompClient => {
                if (!isNullOrUndefined(stompClient)) {
                  let that = this;
                  this.socketSub = stompClient.subscribe(this.webSocketEventName, function (message) {
                    let messageObject = JSON.parse(message.body);
                    if (messageObject.operation === 'UPDATE_FIELD') {
        //              console.log('FIELD UPDATE RECEIVED');
                      // todo fix updating object and remove check
                      if (disabledForNow.indexOf(messageObject.field) < 0) {
                        let items = that.data.filter(filteredItem => {
                          return messageObject.id === filteredItem.id;
                        });
                        if (items.length > 0) {
                          that.setValue(items[0], messageObject.updatedValue, messageObject.field);
                          that.refresh();
                        }
                      }
                    } else if (messageObject.operation === 'CREATE') {
                      //  that.data.push( plainToClass(Master, messageObject.updatedValue));
                      //  that.refresh();
                    } else if (messageObject.operation === 'UPDATE') {
        //              console.log('UPDATE RECEIVED');
                      let scrollLeft = that.bodyDiv.nativeElement.scrollLeft;
                      let master: Master = onAfterLoad(plainToClass(Master, <Object> messageObject.updatedValue));
                      that.updater.update([master]);
                      that.onRefresh.emit(true);
                      // restore left scroll position
                      that.bodyDiv.nativeElement.scrollLeft = scrollLeft;
                    }
                  });
                }
              });
            }
        */
        this.onSortChanged();
    };
    DataTableComponent.prototype.onSortChanged = function () {
        var _this = this;
        if (this.columns) {
            // find specified sortable column
            if (this.sort && this.sort.field) {
                this.sortedByColumn = this.columns.find(function (column) { return column.sortable && column.id === _this.sort.field; }); // not strict equals
            }
            // Find Default sortable column
            if (!this.sortedByColumn) {
                this.sortedByColumn = this.columns.find(function (column) { return column.sortable && !!column.handlers.sorted; });
                if (this.sortedByColumn) {
                    this.sort = {
                        field: this.sortedByColumn.id,
                        order: this.sortedByColumn.handlers.sorted.asc ? 'asc' : 'desc'
                    };
                }
            }
        }
        else {
            this.sortedByColumn = null;
        }
    };
    DataTableComponent.prototype.clearInput = function (column) {
        column.search.search = "";
        this.buildSearchRequest();
        //  this.setPage(1);
        this.refresh();
    };
    DataTableComponent.prototype.clearTableInputs = function () {
        this.filter.search = "";
        this.columns.forEach(function (column) {
            column.search.search = "";
        });
        this.selectComponents.forEach(function (item) {
            item.clearModel();
        });
        this.refresh();
        this.searchChanged(true);
    };
    DataTableComponent.prototype.isClearTableInputs = function () {
        if (this.filter.search) {
            return false;
        }
        if (!this.columns) {
            return true;
        }
        return !this.columns
            .filter(function (c) { return c.search.searchable; })
            .some(function (column) {
            return (typeof column.search.search === "string" && !!column.search.search)
                || (Array.isArray(column.search.search) && column.search.search.length !== 0);
        });
    };
    /***@deprecated***/
    DataTableComponent.prototype.changeStatusFilterSearch = function (statuses, columnId) {
        this.columns.forEach(function (column) {
            column.search.search = "";
        });
        // this.selectComponents.forEach(item => {item.clearModel();});
        var statusColumn = this.columns.filter(function (column) { return column.id === columnId; });
        // @ts-ignore
        statusColumn[0].search.search = statuses;
        this.buildSearchRequest();
        this.refresh();
    };
    DataTableComponent.prototype.onSelectSearchItem = function (event, column, multiple) {
        if (multiple && !isNullOrUndefined(event)) {
            column.search.search = event.map(function (item) { return item.id || item; });
        }
        else {
            column.search.search = isNullOrUndefined(event) ? "" : event.id.toString();
        }
        this.searchChanged();
    };
    DataTableComponent.prototype.onSelectSearchItemById = function (event, column, multiple, force) {
        if (multiple && !isNullOrUndefined(event)) {
            column.search.search = event.map(function (item) { return item.id; });
        }
        else {
            column.search.search = isNullOrUndefined(event) ? "" : event.id.toString();
        }
        this.searchChanged(force, 3000);
    };
    DataTableComponent.prototype.clearSelected = function (event, column) {
        event.preventDefault();
        event.stopPropagation();
        column.search.selected = [];
        this.onSelectSearchItemById([], column, column.search.multiple, true);
    };
    DataTableComponent.prototype.onSelectColorSearchItem = function (event, column) {
        column.search.search = isNullOrUndefined(event) ? "" : event.status;
        //  this.setPage(1);
        this.refresh();
    };
    DataTableComponent.prototype.dateRangeSearchChanged = function (event, column, force) {
        // column.search.search = !isNullOrUndefined(event) ? event.fromDate : null;
        column.search.search = event;
        this.searchChanged(force);
    };
    DataTableComponent.prototype.dateSearchChanged = function (event, column) {
        if (isNullOrUndefined(event) || event.length === 0) {
            column.search.search = null;
        }
        else if (event.length === 10) {
            var newDate = new Date(event);
            if (newDate.toDateString() !== 'Invalid Date') {
                column.search.search = newDate;
            }
        }
        this.buildSearchRequest();
        //  this.setPage(1);
        this.refresh();
    };
    // Manual sort by Column
    DataTableComponent.prototype.sortBy = function (column, asc) {
        if (column) {
            if (!column.sortable) {
                return;
            }
            // Toggle sort direction
            if (isNullOrUndefined(asc)) {
                asc = !this.isSortedAsc(column); // column.id === this.sortedBy ? !this.sortedAsc : true;
            }
            this.sort = { field: column.id, order: asc ? 'asc' : 'desc' };
        }
        else {
            this.sort = {};
        }
        this.sortChange.next(this.sort);
        this.sortedByColumn = column;
        this.buildSearchRequest();
    };
    DataTableComponent.prototype.isSortedAsc = function (column) {
        return this.sort && (column.id === this.sort.field) && this.sort.order === 'asc';
    };
    DataTableComponent.prototype.isSortedDesc = function (column) {
        return this.sort && (column.id === this.sort.field) && this.sort.order === 'desc';
        //    return column.id === this.sortedBy && !this.sortedAsc;
    };
    DataTableComponent.prototype.sortColumnsByOrderNumber = function (columns, filterByVisibility) {
        if (columns) {
            var result = columns.sort(function (a, b) {
                if (a.orderNumber < b.orderNumber) {
                    return -1;
                }
                else if (a.orderNumber > b.orderNumber) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
            return filterByVisibility ? result.filter(function (column) { return column.visible; }) : result;
        }
        else {
            return null;
        }
    };
    DataTableComponent.prototype.isExpanded = function (row) {
        var _this = this;
        if (!this.isTree || !row) {
            return false;
        }
        var id = this.trackByRowObject(null, row);
        if (id) {
            return !!this.expanded.find(function (item) { return _this.trackByRowObject(null, item) === id; });
        }
        else {
            return this.expanded.hasEquals(row);
        }
    };
    DataTableComponent.prototype.getRowClass = function (row) {
        var result = row && row.rowClass || '';
        if (this.rowClass) {
            result += ' ' + (typeof this.rowClass === 'string' ? this.rowClass : this.rowClass(row));
        }
        return result;
    };
    DataTableComponent.prototype.isLeaf = function (row) {
        return !this.isTree || this.treeModel.isLeaf(row);
    };
    DataTableComponent.prototype.expand = function (row) {
        var _this = this;
        var obs = this.updateRowHeightsCache(row, function (r) { return _this.expanded.push(r); });
        this.expandEvent.emit({ row: row, expanded: true });
        this.expanded = this.expanded.slice();
        //    console.log('>>>>>', this.expanded);
        this.expandedChange.emit(this.expanded);
        return obs;
    };
    DataTableComponent.prototype.collapse = function (row) {
        var _this = this;
        var obs = this.updateRowHeightsCache(row, function (r) { return _this.expanded.removeAll(r); });
        this.expandEvent.emit({ row: row, expanded: false });
        this.expanded = this.expanded.slice();
        //    console.log('<<<<<', this.expanded);
        this.expandedChange.emit(this.expanded);
        return obs;
    };
    DataTableComponent.prototype.updateRowHeightsCache = function (row, onAction) {
        var _this = this;
        var obs = new Observable(function (subscriber) {
            _this.getTotalRowHeight(row)
                .subscribe(function (oldHeight) {
                onAction(row);
                _this.getTotalRowHeight(row)
                    .subscribe(function (newHeight) {
                    subscriber.next();
                    subscriber.complete();
                    if (_this.virtualization) {
                        _this.rowHeightsCache.update(_this.getRowIndex(row), newHeight - oldHeight);
                    }
                });
            });
        }).pipe(share());
        obs.subscribe(function () { });
        return obs;
    };
    DataTableComponent.prototype.toggleExpanded = function (row) {
        if (!this.isLeaf(row)) {
            if (this.isExpanded(row)) {
                this.collapse(row);
            }
            else {
                this.expand(row);
            }
        }
    };
    /*  getFocused(): any {
        return this.selected.length ? this.selected[0] : null;
      }
    */
    DataTableComponent.prototype.resizeColumnsEvent = function () {
        this.resizeColumns = true;
        this.draggableData = false;
    };
    DataTableComponent.prototype.dragColumnsEvent = function () {
        this.draggableData = true;
        this.resizeColumns = false;
    };
    DataTableComponent.prototype.hideShowColumnsEvent = function () {
        this.accountService.showHideShowPopUp(this.columns);
    };
    DataTableComponent.prototype.saveChanges = function () {
        this.resizeColumns = false;
        this.draggableData = false;
        //    localStorage.setItem('order.columns', JSON.stringify(this.columns));
    };
    DataTableComponent.prototype.onItemDrop = function (event) {
        var column = event.dragData;
        var oldOrderNumber = column.orderNumber;
        var newOrderNumber = Number(event.nativeEvent.target.closest(".drag-handle").attr("orderNumber"));
        if (newOrderNumber) {
            this.columns.splice(oldOrderNumber - 1, 1);
            this.columns.splice(newOrderNumber - 1, 0, column);
            this.columns.forEach(function (c, index) { return (c.orderNumber = index + 1); });
        }
    };
    DataTableComponent.prototype.hideColumn = function (column) {
        column.visible = false;
    };
    // resizable table
    DataTableComponent.prototype.onMouseDown = function (event, columnSetting) {
        this.start = event.target;
        this.pressed = true;
        this.startX = event.x;
        this.startWidth = this.start.parentElement.offsetWidth;
        this.initResizableColumns(columnSetting);
    };
    DataTableComponent.prototype.initResizableColumns = function (columnSetting) {
        var _this = this;
        this.renderer.listen('body', 'mousemove', function (event) {
            if (_this.pressed) {
                var width = _this.startWidth + (event.x - _this.startX);
                var parent_1 = _this.start.parentElement;
                parent_1.style.minWidth = width + "px";
                parent_1.style.maxWidth = width + "px";
            }
        });
        this.renderer.listen('body', 'mouseup', function () {
            if (_this.pressed) {
                _this.pressed = false;
            }
        });
    };
    DataTableComponent.prototype.onRowDoubleClick = function (row) {
        this.dataDblClick.emit(row);
    };
    DataTableComponent.prototype.onCellDoubleClick = function (row, cell) {
        // this.dataDblClick.emit(data);
    };
    // todo make observable
    DataTableComponent.prototype.updateColumnValue = function (object, column, newValue, field) {
        var _this = this;
        var subject = new ReplaySubject(1);
        if (!isUndefined(newValue)) {
            var oldValue_1 = column.getValue(object, field);
            if (oldValue_1 !== newValue) {
                //        if (!column.postUpdate)
                //          column.setValue(object, newValue, field);
                // sending event
                var applied_1 = false;
                var error_1;
                this.updateFieldEvent.emit({
                    newValue: newValue,
                    oldValue: oldValue_1,
                    row: object,
                    column: column,
                    field: field,
                    apply: function (value) {
                        applied_1 = true;
                        if (!isUndefined(value)) {
                            newValue = value;
                        }
                        column.setValue(object, newValue, field);
                        subject.next(object);
                        _this.cdr.markForCheck();
                        _this.onRefresh.emit(true);
                    },
                    cancel: function () {
                        applied_1 = true;
                        column.setValue(object, oldValue_1, field);
                        subject.next(object);
                        _this.cdr.markForCheck();
                        _this.onRefresh.emit(true);
                    },
                    error: function (msg) {
                        error_1 = msg;
                    }
                });
                if (error_1) { // prevent close editor
                    throw error_1;
                }
                if (!applied_1 && !column.postUpdate) {
                    column.setValue(object, newValue, field);
                    subject.next(object);
                    this.cdr.markForCheck();
                    this.onRefresh.emit(true);
                }
            }
        }
        else {
            subject.next(object);
        }
        return subject;
    };
    Object.defineProperty(DataTableComponent.prototype, "vertScrollBarWidth", {
        get: function () {
            return 17; // this.bodyDiv.nativeElement.offsetWidth - this.bodyDiv.nativeElement.clientWidth;
        },
        enumerable: true,
        configurable: true
    });
    //  public get vertScrollbarVisible(): boolean {
    //    return this.bodyDiv.nativeElement.scrollHeight > this.bodyDiv.nativeElement.clientHeight;
    //  }
    /*
      get headerHeight(): number {
        return this._headerHeight || (this._headerHeight = this.headerDiv.nativeElement.offsetHeight);
      }
    
      get footerHeight(): number {
        return this._footerHeight || (this._footerHeight =  this.footer.nativeElement.offsetHeight);
      }
    
    
      get calcHeight(): any {
    //    console.log('calc');
        return ~~this.height > 0 ? this.height - this.headerHeight - this.footerHeight : undefined;
      }
      */
    DataTableComponent.prototype.onSearch = function (column, value) {
        if (column.search && column.search.searchable) {
            column.search.search = value;
            this.searchChanged(true);
        }
    };
    DataTableComponent.prototype.setSearch = function (search) {
        this.columns.forEach(function (column) {
            if (column.search && column.search.searchable) {
                column.search.search = search.get(column.id);
            }
        });
        this.searchChanged(true);
    };
    DataTableComponent.prototype.getSearch = function () {
        var result = new Map();
        this.columns.forEach(function (column) {
            if (column.search && column.search.searchable && assigned(column.search.search)) {
                result.set(column.id, column.search.search);
            }
        });
        return result;
    };
    DataTableComponent.prototype.searchChanged = function (force, timeout) {
        var _this = this;
        clearTimeout(this.searchTimer);
        if (force) {
            this.buildSearchRequest();
            this.refreshAndResetCurrentPage();
        }
        else {
            this.searchTimer = setTimeout(function () {
                _this.buildSearchRequest();
                _this.refreshAndResetCurrentPage();
            }, timeout || this.searchTimeout);
        }
    };
    DataTableComponent.prototype.buildSearchRequest = function (refreshTable) {
        if (refreshTable === void 0) { refreshTable = true; }
        var filterSearchColumn = [];
        if (this.columns) {
            this.columns.forEach(function (column) {
                var filter = FilterSearchColumn.fromSearchOptions(column.search, column.id);
                if (filter) {
                    filterSearchColumn.push(filter);
                }
                /*      if (column.search.searchable && !isNullOrUndefined(column.search.search) && (column.search.search + "").length > 0) {
        
                        let fieldName = column.search.field || column.id;
                        // @ts-ignore
                        if (column.search.search instanceof Array) {
                          filterSearchColumn.push(new FilterSearchColumn(fieldName, null, column.search.search, null));
                          //@ts-ignore
                        } else if(column.search.search instanceof SearchRange) {
                          if(!column.search.search.isEmpty)
                            filterSearchColumn.push(new FilterSearchColumn(fieldName, null, null, column.search.search));
                        } else {
                          filterSearchColumn.push(new FilterSearchColumn(fieldName, column.search.search, null, null));
                        }
                      } */
            });
        }
        if (this.isSearchWHSE) {
            var filterByWHSELocation = new FilterSearchColumn('whseBuilding', 'true');
            filterSearchColumn.push(filterByWHSELocation);
        }
        var page = this.getPage();
        var sort = new SearchSortRequest(this.sort && this.sort.field, this.sort && this.sort.order === 'asc');
        var filterPageRequest = new FilterPageRequest(isNullOrUndefined(page) || page <= 0 ? 1 : page, this.getPageSize(), this.filter.search, sort, filterSearchColumn);
        //    console.log('FILTER', this.filter.search);
        if (refreshTable) {
            this.searchCriteriaChanged.emit(filterPageRequest);
        }
        return filterPageRequest;
    };
    DataTableComponent.prototype.refreshAndResetCurrentPage = function () {
        // this.setPage(1);
        this.refresh();
    };
    DataTableComponent.prototype.isSelectedRow = function (row) {
        return row && row[SELECTED_FLAG];
    };
    // update is a part! of items to replace in the data array. Not the all list of items
    DataTableComponent.prototype.refresh = function (updateInList) {
        var _this = this;
        if (updateInList && this.data) {
            updateInList.forEach(function (item) { return _this.data.replaceAll(item, item); });
            //      this.updater.update(updateInList);
        }
        // this.cdr.markForCheck();
        // this.cdr.detectChanges();
        /// !!! todo check
        this.recalcLayout();
        this.onRefresh.emit(true);
    };
    DataTableComponent.prototype.setPage = function (page) {
        if (this.paging
            && this.paging.enabled
            && this.paging.currentPage !== page) {
            this.paging.currentPage = page;
            this.buildSearchRequest();
            this.refresh();
        }
    };
    DataTableComponent.prototype.getPage = function () {
        if (this.paging && this.paging.enabled) {
            return this.paging.currentPage;
        }
        return -1;
    };
    Object.defineProperty(DataTableComponent.prototype, "totalPages", {
        /*get isTree(): boolean {
          return !isNullOrUndefined(this.treeModel)
        }*/
        get: function () {
            return this.paging && this.paging.enabled ? Math.ceil((this.paging.total || 0) / this.paging.pageSize) : 1;
        },
        enumerable: true,
        configurable: true
    });
    DataTableComponent.prototype.onSetPageNumber = function (event) {
        event.stopPropagation();
        if (this.paging && this.paging.enabled) {
            if ((event instanceof KeyboardEvent && event.code === 'Enter') || (event instanceof FocusEvent && event.type === 'blur')) {
                var page = ~~event.target.value;
                if (page && page !== this.paging.currentPage && page <= this.totalPages /*this.pagination.totalPages*/) {
                    this.setPage(page);
                }
                else {
                    event.target.value = (this.paging.currentPage || 1) + '';
                }
            }
        }
    };
    DataTableComponent.prototype.setPageSize = function (pageSize) {
        if (this.paging && this.paging.enabled) {
            if (pageSize > this.paging.pageSize) {
                this.paging.currentPage = 1;
            }
            this.paging.pageSize = pageSize;
            this.pageSizeChange.emit(pageSize);
        }
        this.buildSearchRequest();
        this.refresh();
    };
    DataTableComponent.prototype.getPageSize = function () {
        if (this.paging && this.paging.enabled) {
            return this.paging.pageSize;
        }
        return -1;
    };
    DataTableComponent.prototype.columnByRow = function (column, row) {
        return column instanceof TreeColumn ? column.byRow(row) : column;
    };
    DataTableComponent.prototype.onContentMouseDown = function (event) {
        if (this.contentDrag) {
            this.sx = this.bodyDiv.nativeElement.scrollLeft;
            this.sy = this.bodyDiv.nativeElement.scrollTop;
            this.mx = event.pageX - this.bodyDiv.nativeElement.offsetLeft;
            this.my = event.pageY - this.bodyDiv.nativeElement.offsetTop;
            this.drag = true;
        }
    };
    DataTableComponent.prototype.onMouseMove = function (event) {
        if (this.drag) {
            var mx2 = event.pageX - this.bodyDiv.nativeElement.offsetLeft;
            if (this.mx) {
                this.bodyDiv.nativeElement.scrollLeft = this.sx + this.mx - mx2;
            }
            var my2 = event.pageY - this.bodyDiv.nativeElement.offsetTop;
            if (this.my) {
                this.bodyDiv.nativeElement.scrollTop = this.sy + this.my - my2;
            }
        }
    };
    DataTableComponent.prototype.onMouseUp = function (event) {
        this.drag = false;
    };
    Object.defineProperty(DataTableComponent.prototype, "noData", {
        get: function () {
            return !this.hasData;
        },
        enumerable: true,
        configurable: true
    });
    DataTableComponent.prototype.beforeUpdate = function () {
        this.scrollLeft = this.bodyDiv.nativeElement.scrollLeft;
    };
    DataTableComponent.prototype.afterUpdate = function () {
        this.bodyDiv.nativeElement.scrollLeft = this.scrollLeft;
        this.cdr.markForCheck();
    };
    DataTableComponent.prototype.ngOnChanges = function (changes) {
        /*    if (changes.data) {
              this.cdr.markForCheck();
              this.cdr.detectChanges();
            } */
        if (changes.originalData || changes.groupedFunc) {
            if (this.groupedFunc) {
                this.data = this.groupedFunc(this.originalData);
            }
            else {
                this.data = this.originalData;
            }
        }
        if (changes.activeSearchFilterCondition) {
            this.columnSearchItems = {};
            var statusDropdown = (this.selectComponents || []).find(function (item) { return item.labelForId === 'status'; });
            if (statusDropdown && statusDropdown.selected && statusDropdown.selected.length) {
                statusDropdown.clearModel();
            }
        }
        if (changes.sort) {
            this.onSortChanged();
        }
        if (changes.treeModel) {
            this.isTree = !isNullOrUndefined(this.treeModel);
        }
        if (changes.columns || changes.fixedColumns) {
            var innerStickyColumns = (!this.columns || !this.fixedColumns) ? [] : this.columns.slice().filter(function (column) { return !column.isHiddenColumn(); }).slice(0, this.fixedColumns);
            this.stickyColumns = this.sortColumnsByOrderNumber(innerStickyColumns, null);
            var innerOtherColumns = (!this.columns || !this.fixedColumns) ? this.columns : this.columns.slice().filter(function (column) { return !column.isHiddenColumn(); }).slice(this.fixedColumns);
            this.otherColumns = this.sortColumnsByOrderNumber(innerOtherColumns, null);
        }
        if (changes.striped || changes.selectable || changes.bordered || changes.fixed || changes.condensed) {
            this.tableClassesDef = {
                'table-striped': this.striped,
                'table-hover': this.hover,
                'table-bordered': this.bordered,
                'table-fixed': this.fixed,
                'table-condensed': this.condensed
            };
        }
        /*    if (changes.selected || changes.data) {
              this.updateSelectedItems(this.selected, this.data);
            }*/
    };
    // TODO need test
    /*  private updateSelectedItems(selected: any[], data: any[]) {
        data.forEach(item => {
          let isSelected = selected.some(selectedItem => selectedItem === item
            || (selectedItem && item && selectedItem.id && item.id && selectedItem.id === item.id));
          if (isSelected) {
            item[SELECTED_FLAG] = true;
          } else {
            delete item[SELECTED_FLAG];
          }
        });
      }*/
    DataTableComponent.prototype.replaceInSelected = function (item) {
        var _this = this;
        if (item) {
            this.selected.forEach(function (el, index, array) {
                if (equals(item, el) || _this.trackByRowObject(null, el) === _this.trackByRowObject(null, item)) {
                    array[index] = item;
                    item[SELECTED_FLAG] = true;
                }
            });
        }
    };
    DataTableComponent.prototype.ngDoCheck = function () {
        var _this = this;
        var changed = false;
        if (this.recalculateDims()) {
            changed = true;
        }
        if (this.valuesDiffer.diff({
            searchable: this.searchable,
            checkboxes: this.checkboxes,
        })) {
            changed = true;
        }
        /*    if (this.columnsDiffer.diff(this.columns)) {
              this._stickyColumns = null;
              this._otherColumns = null;
              changed = true;
            }*/
        var dataChanges = this.dataDiffer.diff(this.data);
        var selectionChanges = this.selectionDiffer.diff(this.selected);
        if (dataChanges || selectionChanges) {
            if (selectionChanges) {
                selectionChanges.forEachAddedItem(function (item) { return item.item[SELECTED_FLAG] = true; });
                selectionChanges.forEachRemovedItem(function (item) { return delete item.item[SELECTED_FLAG]; });
            }
            var pageChanged_1 = false;
            var itemsChanged_1 = [];
            if (dataChanges) {
                dataChanges.forEachAddedItem(function (item) { pageChanged_1 = true; _this.replaceInSelected(item.item); });
                dataChanges.forEachRemovedItem(function (item) { return pageChanged_1 = true; });
                dataChanges.forEachIdentityChange(function (item) {
                    itemsChanged_1.push(item.item);
                    _this.replaceInSelected(item.item);
                    if (_this.isExpanded(item.item)) {
                        // force expand event if updated row is Expanded
                        _this.expandEvent.emit({ row: item.item, expanded: true });
                    }
                });
                //        console.log('Update Page Detected', dataChanges, 'page changed', pageChanged, 'items changed', this.data);
            }
            if (itemsChanged_1.length > 0 || pageChanged_1 || selectionChanges) {
                changed = true;
                if (pageChanged_1 || selectionChanges) {
                    this.updateData(selectionChanges && !pageChanged_1);
                }
                else if (itemsChanged_1.length > 0) {
                    this.updater.update(itemsChanged_1);
                }
            }
        }
        if (changed) {
            this.refresh();
            //      this.cdr.markForCheck();
        }
    };
    DataTableComponent.prototype.updateData = function (forceInstant) {
        var _this = this;
        if (!this.keepSelected) {
            this.selected.forEach(function (row) { if (!_this.data.hasEquals(row)) {
                _this.selected.removeAll(row);
            } });
        }
        //    this.expanded.forEach(row => {if (!this.data.hasEquals(row)) this.expanded.removeAll(row);});
        // update selected items with new from the item list
        if (this.keepSelected) {
            this.data.forEach(function (d) {
                _this.selected.replaceAll(d, d, function (replaced) { return replaced[SELECTED_FLAG] = true; });
            });
        }
        if (this.updater) { // in during destroy
            this.updater.setData(this.keepSelected ? this.selected.filter(function (item) { return !_this.data.hasEquals(item); }).concat(this.data) : this.data, forceInstant || this.virtualization, function () { return _this.recalcLayout(); });
        }
    };
    Object.defineProperty(DataTableComponent.prototype, "rows", {
        /*get tableClassesDef() {
          return {
            'table-striped': this.striped,
            'table-hover': this.selectable,
            'table-bordered': this.bordered,
            'table-fixed': this.fixed,
            'table-condensed': this.condensed
          }
        }*/
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "rowCount", {
        //  _rowCount:number;
        get: function () {
            if (this.virtualization) {
                return this.data ? this.data.length : 0;
            }
            else {
                return this.updater && this.updater.items ? this.updater.items.length : 0; // this._rowCount || 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Updates the rows in the view port
     */
    DataTableComponent.prototype.updateRows = function () {
        //    console.time('Update Rows');
        if (this.virtualization) {
            var _a = this.indexes, first = _a.first, last = _a.last;
            //      console.log('indexes', this.indexes);
            var rowIndex = first;
            var idx = 0;
            var temp = [];
            this.rowIndexes.clear();
            // if grouprowsby has been specified treat row paging
            // parameters as group paging parameters ie if limit 10 has been
            // specified treat it as 10 groups rather than 10 rows
            while (rowIndex < last && rowIndex < this.rowCount) {
                var row = this.rows[rowIndex];
                if (row) {
                    this.rowIndexes.set(row, rowIndex);
                    temp[idx] = row;
                }
                idx++;
                rowIndex++;
            }
            //      console.timeEnd('Update Rows');
            this.updater.setData(temp, this.virtualization);
            this.buffer = temp;
        }
        //    this.cdr.detectChanges();
    };
    /**
     * Recalculates the table
     */
    DataTableComponent.prototype.recalcLayout = function () {
        this.vertScrollbarVisible = !this.usePerfectScrollBar && this.bodyDiv.nativeElement.scrollHeight > this.bodyDiv.nativeElement.clientHeight;
        this.refreshRowHeightCache();
        this.updateIndexes();
        this.updateRows();
    };
    /**
     * Refreshes the full Row Height cache.  Should be used
     * when the entire row array state has changed.
     */
    DataTableComponent.prototype.refreshRowHeightCache = function () {
        if (!this.virtualization) {
            return;
        }
        //    console.log('refreshRowHeightCache');
        // clear the previous row height cache if already present.
        // this is useful during sorts, filters where the state of the
        // rows array is changed.
        this.rowHeightsCache.clearCache();
        // Initialize the tree only if there are rows inside the tree.
        if (this.rows && this.rows.length) {
            this.rowHeightsCache.initCache({
                rows: this.rows,
                rowHeight: this.getTotalRowHeight,
                //        detailRowHeight: this.getChildrenRowHeight,
                externalVirtual: this.externalPaging,
                rowCount: this.rowCount,
                rowIndexes: this.rowIndexes,
            });
        }
    };
    /**
     * Updates the index of the rows in the viewport
     */
    DataTableComponent.prototype.updateIndexes = function () {
        var first;
        var last;
        if (this.virtualization) {
            // Calculation of the first and last indexes will be based on where the
            // scrollY position would be at.  The last index would be the one
            // that shows up inside the view port the last.
            var height = this._bodyHeight; // */parseInt(this.bodyHeight, 0);
            first = this.rowHeightsCache.getRowIndex(this.offsetY);
            last = this.rowHeightsCache.getRowIndex(height + this.offsetY) + 1;
        }
        else {
            // If virtual rows are not needed
            // We render all in one go
            first = 0;
            last = this.rowCount;
        }
        this.indexes = { first: first, last: last };
    };
    DataTableComponent.prototype.getRowHeight = function (row) {
        var rowHeight = this.rowHeight;
        return typeof (rowHeight) === 'function' ? rowHeight(row) : rowHeight;
    };
    /**
     * Body was scrolled, this is mainly useful for
     * when a user is server-side pagination via virtual scroll.
     */
    DataTableComponent.prototype.onBodyScroll = function (event) {
        var scrollYPos = event.scrollYPos;
        var scrollXPos = event.scrollXPos;
        var scroll = this.offsetY !== scrollYPos;
        // if scroll change, trigger update
        // this is mainly used for header cell positions
        if (this.offsetY !== scrollYPos || this.offsetX !== scrollXPos) {
            this.scroll.emit({
                offsetY: scrollYPos,
                offsetX: scrollXPos
            });
        }
        this.offsetY = scrollYPos;
        this.offsetX = scrollXPos;
        this.updateIndexes();
        if (scroll) {
            //    this.updatePage(event.direction);
            this.updateRows();
        }
    };
    Object.defineProperty(DataTableComponent.prototype, "scrollHeight", {
        /**
         * Property that would calculate the height of scroll bar
         * based on the row heights cache for virtual scroll and virtualization. Other scenarios
         * calculate scroll height automatically (as height will be undefined).
         */
        get: function () {
            if (this.virtualization && this.rowCount) {
                return this.rowHeightsCache.query(this.rowCount - 1);
            }
            // avoid TS7030: Not all code paths return a value.
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Gets the row index given a row
     */
    DataTableComponent.prototype.getRowIndex = function (row) {
        return this.rowIndexes.get(row) || 0;
    };
    /**
     * Recalculates the dimensions of the table size.
     * Internally calls the page size and row count calcs too.
     *
     */
    DataTableComponent.prototype.recalculateDims = function () {
        var _this = this;
        //    console.time('recalculateDims');
        if (!this.virtualization) {
            return false;
        }
        var changed = false;
        var dims = this.element.getBoundingClientRect();
        ifChanged(this._innerWidth, Math.floor(dims.width), function (v) { _this._innerWidth = v; changed = true; });
        ifChanged(this._innerHeight, Math.floor(dims.height), function (v) { _this._innerHeight = v; changed = true; });
        ifChanged(this._bodyHeight, this.bodyDiv.nativeElement.getBoundingClientRect().height, function (v) { _this._bodyHeight = v; changed = true; });
        //    console.timeEnd('recalculateDims');
        return changed;
    };
    /**
     * Recalc's the sizes of the grid.
     *
     * Updated automatically on changes to:
     *
     *  - Columns
     *  - Rows
     *  - Paging related
     *
     * Also can be manually invoked or upon window resize.
     */
    DataTableComponent.prototype.recalculate = function () {
        this.recalculateDims();
        //    this.recalculateColumns();
    };
    /**
     * Window resize handler to update sizes.
     */
    DataTableComponent.prototype.onWindowResize = function () {
        //    console.log('Window Resize');
        this.recalculate();
        this.recalcLayout();
    };
    /**
     * Lifecycle hook that is called after a component's
     * view has been fully initialized.
     */
    DataTableComponent.prototype.ngAfterViewInit = function () {
        // this.buildSearchRequest();
        var _this = this;
        // this has to be done to prevent the change detection
        // tree from freaking out because we are readjusting
        if (typeof requestAnimationFrame === 'undefined') {
            return;
        }
        requestAnimationFrame(function () {
            _this.recalculate();
            _this.recalcLayout();
            _this.cdr.markForCheck();
            /*      // emit page for virtual server-side kickoff
                  if (this.externalPaging && this.scrollbarV) {
                    this.page.emit({
                      count: this.count,
                      pageSize: this.pageSize,
                      limit: this.limit,
                      offset: 0
                    });
                  }*/
        });
    };
    DataTableComponent.prototype.compareById = function (item1, item2) {
        if (!item1 || !item2) {
            return false;
        }
        return item1['id'] === item2['id'];
    };
    Object.defineProperty(DataTableComponent.prototype, "trackFn", {
        get: function () {
            return this.trackByRowObject.bind(this);
        },
        enumerable: true,
        configurable: true
    });
    DataTableComponent.prototype.trackByRowObject = function (index, item) {
        //    return item[OBJECT_ROW_ID] || (item[OBJECT_ROW_ID] = '10');
        if (!!item && !!item[OBJECT_ROW_ID]) {
            return item[OBJECT_ROW_ID];
        }
        if (!!item) {
            if (!isNullOrUndefined(this.objectId)) {
                if (typeof this.objectId === 'string') {
                    item[OBJECT_ROW_ID] = item[this.objectId];
                }
                else {
                    item[OBJECT_ROW_ID] = this.objectId(item);
                }
                return item[OBJECT_ROW_ID];
            }
            else {
                if (typeof item.trackBy === 'function') {
                    item[OBJECT_ROW_ID] = item.trackBy();
                }
                else if (!!item['id']) {
                    item[OBJECT_ROW_ID] = item['id'];
                }
                else {
                    item[OBJECT_ROW_ID] = Math.random();
                }
                return item[OBJECT_ROW_ID];
            }
        }
        else {
            return index;
        }
    };
    DataTableComponent.prototype.isSelected = function (row) {
        return this.selectable && row && row[SELECTED_FLAG]; // : false;// this.selected.hasEquals(row) : false;
    };
    Object.defineProperty(DataTableComponent.prototype, "isSelectedAll", {
        get: function () {
            //    this.selected.all
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "isSelectedPartially", {
        get: function () {
            return this.selected.length > 0;
            //    return true;
        },
        enumerable: true,
        configurable: true
    });
    DataTableComponent.prototype.toggleSelected = function (row, event) {
        event.stopPropagation();
        if (this.selectable) {
            var wasSelected = this.isSelected(row);
            if (this.multiSelect) {
                if (this.lastSelected && event.shiftKey) {
                    var last = this.data.indexOf(this.lastSelected);
                    var current = this.data.indexOf(row);
                    if (last >= 0 && current >= 0) {
                        if (last <= current) {
                            for (var i = last + 1; i <= current; i++) {
                                this.setSelected(this.data[i], true);
                            }
                        }
                        else {
                            for (var i = last - 1; i >= current; i--) {
                                this.setSelected(this.data[i], true);
                            }
                        }
                    }
                    else {
                        this.setSelected(row, true);
                    }
                }
                else {
                    this.setSelected(row, !wasSelected);
                }
            }
            else {
                if (!wasSelected) {
                    this.clearSelection();
                    this.setSelected(row, !wasSelected);
                }
            }
            this.selectedChange.emit(this.selected);
            this.cdr.markForCheck();
        }
    };
    DataTableComponent.prototype.onRowSelect = function (row, event) {
        if (this.selectable && this.selectByRow) {
            var toggle = this.multiSelect && event.ctrlKey;
            var addRange = !toggle && this.multiSelect && event.shiftKey;
            if (addRange) {
                var last = this.data.indexOf(this.lastSelected);
                var current = this.data.indexOf(row);
                if (last >= 0 && current >= 0) {
                    if (last <= current) {
                        for (var i = last + 1; i <= current; i++) {
                            this.setSelected(this.data[i], true);
                        }
                    }
                    else {
                        for (var i = last - 1; i >= current; i--) {
                            this.setSelected(this.data[i], true);
                        }
                    }
                }
                else {
                    this.setSelected(row, true);
                }
            }
            else if (toggle) {
                this.setSelected(row, !this.isSelected(row));
            }
            else {
                this.clearSelection();
                this.setSelected(row, true);
            }
            this.selectedChange.emit(this.selected);
            this.cdr.markForCheck();
        }
    };
    DataTableComponent.prototype.setSelected = function (item, selected) {
        //    console.log('TOGGLE', item, selected);
        if (item) {
            if (selected) {
                item[SELECTED_FLAG] = true;
                this.lastSelected = item;
                if (!this.selected.hasEquals(item)) {
                    this.selected.push(item);
                }
            }
            else {
                delete item[SELECTED_FLAG];
                this.selected.removeAll(item);
            }
        }
    };
    DataTableComponent.prototype.selectAll = function () {
        var _this = this;
        this.data.forEach(function (item) { return _this.setSelected(item, true); });
        this.selectedChange.emit(this.selected);
    };
    DataTableComponent.prototype.clearSelection = function () {
        this.selected.forEach(function (item) { return delete item[SELECTED_FLAG]; });
        this.selected.clear();
        this.selectedChange.emit(this.selected);
    };
    DataTableComponent.prototype.loopSelectionSort = function () {
        this.sortBySelected = selectionSort.next(this.sortBySelected, true);
    };
    DataTableComponent.prototype.getContextMenu = function (row, column) {
        return typeof this.menu === 'function' ? this.menu(row, column) : this.menu;
    };
    DataTableComponent.prototype.doCellClick = function ($event, row, column) {
        //    console.log('Cell Click', $event, row, column);
        this.cellClick.emit({ row: row, column: column, handled: false });
    };
    DataTableComponent.prototype.onCellContextMenu = function ($event, row, column) {
        // High Priority: Cell Right Click Handler
        var event = { row: row, column: column, handled: false };
        this.cellRightClick.emit(event);
        if (event.handled) {
            return;
        }
        // Lower priority: Cell Context menu
        var menu = this.getContextMenu(row, column);
        if (menu) {
            this.contextMenuService.show.next({
                anchorElement: $event.target,
                // Optional - if unspecified, all context menu components will open
                contextMenu: menu,
                event: $event,
                item: { row: row, column: column },
            });
            $event.preventDefault();
            $event.stopPropagation();
        }
    };
    DataTableComponent.prototype.onRowContextMenu = function ($event, row) {
        var menu = this.getContextMenu(row);
        if (menu) {
            this.contextMenuService.show.next({
                anchorElement: $event.target,
                // Optional - if unspecified, all context menu components will open
                contextMenu: menu,
                event: $event,
                item: { row: row },
            });
            $event.preventDefault();
            $event.stopPropagation();
        }
    };
    DataTableComponent.prototype.toggleExpandCollapse = function () {
        var _this = this;
        var rows = this.updater.subject.getValue();
        if (rows) {
            var openRow = rows.find(function (row) { return _this.isExpanded(row); });
            if (openRow) {
                this.collapseAll();
            }
            else {
                this.expandAll();
            }
        }
    };
    DataTableComponent.prototype.expandAll = function (index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        var rows = this.updater.subject.getValue();
        if (!rows) {
            return;
        }
        if (index < rows.length) {
            this.expand(rows[index]).subscribe(function () {
                setTimeout(function () {
                    _this.expandAll(index + 1);
                    _this.cdr.markForCheck();
                }, 10);
            });
        }
    };
    DataTableComponent.prototype.collapseAll = function (index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        var rows = this.updater.subject.getValue();
        if (!rows) {
            return;
        }
        if (index < rows.length) {
            this.collapse(rows[index]).subscribe(function () {
                // setTimeout(() => {
                _this.collapseAll(index + 1);
                // }, 10);
            });
        }
        else {
            this.cdr.markForCheck();
        }
    };
    DataTableComponent.prototype.ngOnDestroy = function () {
        delete this.updater;
        this.updater = undefined;
        this.treeModel = undefined;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        //    if (this.socketSub) { this.socketSub.unsubscribe(); }
    };
    DataTableComponent.prototype.onRowDrop = function (event, row) {
        //    event['row'] = row;
        this.rowDropEvent.next(new DataTableDropEvent(event.nativeEvent, event.dragData, row));
    };
    DataTableComponent.prototype.onTableDrop = function (event) {
        this.tableDropEvent.next(new DataTableDropEvent(event.nativeEvent, event.dragData, null));
    };
    DataTableComponent.prototype.getRowDragScope = function (row) {
        return typeof this.rowDragScope === 'function' ? this.rowDragScope(row) : this.rowDragScope;
    };
    DataTableComponent.prototype.getRowDropScope = function (row) {
        return typeof this.rowDropScope === 'function' ? this.rowDropScope(row) : this.rowDropScope;
    };
    DataTableComponent.prototype.onOpen = function (column) {
        var _this = this;
        console.log('On Open Search Column', column);
        setTimeout(function () {
            if (_this.searchInput) {
                _this.searchInput.nativeElement.focus();
                column.ngSelect.initLoadItems();
                column.doSearch('');
            }
        }, 50);
    };
    DataTableComponent.prototype.reRender = function () {
        this.rowComponents.forEach(function (row) { return row.reRender(); });
        this.cdr.markForCheck();
    };
    DataTableComponent = __decorate([
        Logger({
            name: 'DataTableComponent'
        }),
        __metadata("design:paramtypes", [ElementRef,
            ContextMenuService,
            ChangeDetectorRef,
            ElementRef,
            IterableDiffers,
            KeyValueDiffers,
            Renderer2,
            AccountService,
            DataTableService])
    ], DataTableComponent);
    return DataTableComponent;
}());
export { DataTableComponent };
