<oms-base-page [title]="" [subtitle]="">

  <ng-container page-body>
    <div class="widget widget-center">
      <div class="widget-header">
        <div class="row" style="line-height: 35px;">
          <div class="col-xs-8">
            <h3>MAWB# {{mawbNumber}}</h3>
          </div>
          <div class="col-xs-4" style="padding: 0 8px 0 0;">
            <div class="input-group input-group-sm" style="margin-top: 2px;">
              <input title="" type="text" class="form-control form-control-lg" [(ngModel)]="mawbSearch" placeholder="MAWB last 4 digits" (blur)="mawbSearch=null;" (ngModelChange)="onMawbSearch($event)">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
            </div>
          </div>
        </div>
      </div>

      <div class="widget-content">
        <div class="row">
          <div class="col-xs-5 cell">
            <h4>RLD#&emsp;({{masterPieces}})</h4>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-xs-5 cell" style="padding: 0;">
            <ng-select class="form-control input-sm" [disabled]="!multipleLoads"
                       appendTo="body"
                       [clearable]="false"
                       [(ngModel)]="selectedSplit"
                       (ngModelChange)="onUrlChange()"
                       [items]="splits"></ng-select>
          </div>
        </div>


        <div class="row form-group">
          <div class="col-xs-5">
            <h3>HAWB</h3>
          </div>
          <div class="col-xs-7 no-padding">
            <div class="col-xs-6">
              <h3>PCS</h3>
            </div>
            <!--div class="col-xs-4">
              <h3>WGHT</h3>
            </div-->
            <div class="col-xs-6">
              <h3>HHU</h3>
            </div>
          </div>
        </div>

        <hr style="margin-bottom: 10px; margin-top: -15px;">

        <h3 style="text-align: center; margin: 20px;" *ngIf="noMaster"><p>Please select a MAWB.</p></h3>
        <h3 style="text-align: center; margin: 20px;" *ngIf="noDispatched"><p>No loads available for receiving.</p>Please contact dispatch if you feel this is an error</h3>

        <div class="row form-group" *ngFor="let part of parts">

          <div class="col-xs-5 cell">
            <h4 style="margin-top: 5px;">{{part.order.hawb}}&emsp;({{part.order.pieces}})</h4>
          </div>
          <div class="col-xs-7 no-padding">
            <div class="col-xs-6 cell">
              <!--oms-pieces-input [showButton]="false" label="" [required]="false"></oms-pieces-input-->
              <input type="text" class="form-control" title="" [ngModel]="part.pieces" (ngModelChange)="part.pieces = cleanUp($event)"
                     [disabled]="!allowSubmit"
                     [placeholder]="constants.piecesPlaceholder" [textMask]="{mask:constants.piecesMask}">
            </div>
            <!--div class="col-xs-4 cell">
              <input type="text" class="form-control" title="" [(ngModel)]="part.weight"
                     [disabled]="!allowSubmit"
                     [placeholder]="constants.weightPlaceholder" [textMask]="{mask:constants.weightMask}" >
            </div-->
            <div class="col-xs-6 cell">
              <input type="text" class="form-control" title="" [ngModel]="part.hu" (ngModelChange)="part.hu = cleanUp($event)"
                     [disabled]="!allowSubmit"
                     [placeholder]="constants.piecesPlaceholder" [textMask]="{mask:constants.piecesMask}" >
            </div>

          </div>
        </div>

        <hr style="margin-bottom: 10px; margin-top: -5px;">

        <div class="row form-group">
          <div class="col-xs-5" style="padding: 0 3px">
            <h3 style="margin-top: 5px; text-align: center">TOTAL</h3>
          </div>
          <div class="col-xs-7 no-padding">
            <div class="col-xs-6 cell" style="padding: 0 3px">
              <input title="" type="text" class="form-control form-control-lg" [value]="totalReceivedPieces" disabled
                     [placeholder]="constants.piecesPlaceholder"
                     [textMask]="{mask:constants.piecesMask}" style="padding: 0 2px">
            </div>
            <div class="col-xs-6 cell" style="padding: 0 3px">
              <input title="" type="text" class="form-control form-control-lg" [value]="totalReceivedHu" disabled
                     [placeholder]="constants.weightPlaceholder"
                     [textMask]="{mask:constants.weightMask}" style="padding: 0 2px">
            </div>
          </div>
        </div>


      </div>

      <div class="widget-footer parts">
        <button class="btn btn-lg btn-primary" (click)="onSubmit();" [disabled]="!allowSubmit"><i class="fa fa-send"></i>&emsp;Submit</button>
      </div>
    </div>
  </ng-container>
</oms-base-page>
