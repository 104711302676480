/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oms-alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/ngx-bootstrap/alert/ngx-bootstrap-alert.ngfactory";
import * as i4 from "ngx-bootstrap/alert";
import * as i5 from "./dynamic-injector/dynamic-injector.component.ngfactory";
import * as i6 from "./dynamic-injector/dynamic-injector.component";
import * as i7 from "./oms-alerts.component";
import * as i8 from "./oms-alerts.service";
import * as i9 from "../../../../services/auth.service";
var styles_OmsAlertsComponent = [i0.styles];
var RenderType_OmsAlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OmsAlertsComponent, data: {} });
export { RenderType_OmsAlertsComponent as RenderType_OmsAlertsComponent };
function View_OmsAlertsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "btn-copy-details"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyToClipboard(_v.parent.context.$implicit.message, _v.parent.context.$implicit.stack) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa fa-clipboard"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵted(-1, null, ["Copy Details\u2026 "]))], null, null); }
function View_OmsAlertsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "progress progress-striped active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "progress-bar"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "%"]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.progress.percents + "%"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.parent.context.$implicit.progress.percents; _ck(_v, 2, 0, currVal_1); }); }
function View_OmsAlertsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (!_v.parent.context.$implicit.progress.completed && !_v.parent.context.$implicit.progress.error); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.progress.status; _ck(_v, 2, 0, currVal_0); }); }
function View_OmsAlertsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "bs-alert", [], null, [[null, "onClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClosed" === en)) {
        var pd_0 = (_co.onAlertClosed(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i1.ɵdid(2, 114688, null, 0, i4.AlertComponent, [i4.AlertConfig, i1.ChangeDetectorRef], { type: [0, "type"], dismissible: [1, "dismissible"], dismissOnTimeout: [2, "dismissOnTimeout"] }, { onClosed: "onClosed" }), (_l()(), i1.ɵeld(3, 0, null, 0, 9, "div", [["class", "gritter-without-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "gritter-title"], ["style", "text-shadow: none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["style", "white-space: pre-line;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 0, "div", [["style", "clear:both"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; var currVal_1 = true; var currVal_2 = _v.context.$implicit.timeout; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _v.context.$implicit.stack; _ck(_v, 10, 0, currVal_6); var currVal_7 = _v.context.$implicit.progress; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.icon; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.context.$implicit.message; _ck(_v, 8, 0, currVal_5); }); }
function View_OmsAlertsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "progress progress-striped active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "progress-bar"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "% "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.parent.parent.context.$implicit.progress.percents + "%"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.parent.parent.context.$implicit.progress.percents; _ck(_v, 2, 0, currVal_1); }); }
function View_OmsAlertsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (!_v.parent.parent.context.$implicit.progress.completed && !_v.parent.parent.context.$implicit.progress.error); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.progress.status; _ck(_v, 2, 0, currVal_0); }); }
function View_OmsAlertsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "bs-alert", [], null, [[null, "onClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClosed" === en)) {
        var pd_0 = (_co.onMessageClosed(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i1.ɵdid(2, 114688, null, 0, i4.AlertComponent, [i4.AlertConfig, i1.ChangeDetectorRef], { type: [0, "type"], dismissible: [1, "dismissible"], dismissOnTimeout: [2, "dismissOnTimeout"] }, { onClosed: "onClosed" }), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "div", [["class", "gritter-without-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "gritter-title"], ["style", "text-shadow: none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["style", "white-space: pre-line;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_8)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 0, "div", [["style", "clear:both"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.type; var currVal_1 = true; var currVal_2 = _v.parent.context.$implicit.timeout; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _v.parent.context.$implicit.progress; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.icon; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.title; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.parent.context.$implicit.message; _ck(_v, 8, 0, currVal_5); }); }
function View_OmsAlertsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "oms-dynamic-injector", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onMessageClosed(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DynamicInjectorComponent_0, i5.RenderType_DynamicInjectorComponent)), i1.ɵdid(2, 704512, null, 0, i6.DynamicInjectorComponent, [i1.ComponentFactoryResolver, i1.Injector], { component: [0, "component"], data: [1, "data"] }, { close: "close" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.componentType; var currVal_1 = _v.parent.context.$implicit.componentData; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_OmsAlertsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.componentType; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.componentType; _ck(_v, 4, 0, currVal_1); }, null); }
function View_OmsAlertsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["messagesPanel", 1]], null, 3, "div", [["class", "oms-alerts__panel bottom-right"], ["id", "gritter-notice-wrapper"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "oms-alerts__panel-inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_6)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.messages; var currVal_2 = _co.createTrackBy("id"); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isOpen; _ck(_v, 0, 0, currVal_0); }); }
function View_OmsAlertsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "oms-alerts__collapse-expand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "dropdown-toggle"], ["data-toggle", "dropdown"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "i", [["class", "oms-alerts__collapse-expand-icon fa fa-bell"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "has-new": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "oms-alerts__collapse-expand-icon fa fa-bell"; var currVal_1 = _ck(_v, 4, 0, _co.hasNew); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_OmsAlertsComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { messagesPanel: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "top-left"], ["id", "gritter-notice-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_OmsAlertsComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmsAlertsComponent_11)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.alerts$)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.showNotifications && _co.displayMessage); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.showNotifications && _co.displayMessage); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_OmsAlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-alerts", [], null, null, null, View_OmsAlertsComponent_0, RenderType_OmsAlertsComponent)), i1.ɵdid(1, 245760, null, 0, i7.OmsAlertsComponent, [i1.ChangeDetectorRef, i8.OmsAlertsService, i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OmsAlertsComponentNgFactory = i1.ɵccf("oms-alerts", i7.OmsAlertsComponent, View_OmsAlertsComponent_Host_0, { showNotifications: "showNotifications" }, {}, []);
export { OmsAlertsComponentNgFactory as OmsAlertsComponentNgFactory };
