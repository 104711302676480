﻿import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, NG_ASYNC_VALIDATORS, NG_VALIDATORS, Validators} from '@angular/forms';
import {Address, FreightForwarder} from '../../../modules/shared/models';
import {FreightForwarderService} from '../../../services/freight.forwarder.service';
import {AbstractEntityDialogComponent} from 'src/app/modules/settings/pages/abstract-entity-dialog.component';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {AddressService} from '../../../modules/shared/services/address.service';
import {NgSelectSearchParams} from '../../../modules/settings/util/ng-select-search-params';
import {FreightForwarderGroupService} from "../../../services/freight.forwarder.group.service";
import {FreightForwarderGroup} from "../../../modules/shared/models/freight.forwarder.group";
import {isNullOrUndefined} from "util";
import {FreightForwarderEmail} from "../../../modules/shared/models/freight.forwarder-email";
import {FileUploadService} from "../../../services/file.upload.service";
import {buildCfsStatusPageUrl, copyToClipboard} from "../../../_helpers/utils";
import {flatMap} from "rxjs/operators";


@Component({
  templateUrl: 'freight-forwarder.dialog.component.html',
  styleUrls: ['./freight-forwarder.dialog.component.scss'],
  selector: 'oms-freight-forwarder-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreightForwarderDialogComponent extends AbstractEntityDialogComponent<FreightForwarder> implements OnInit {

  freightForwarder: FreightForwarder = new FreightForwarder();
  addressSearch: NgSelectSearchParams<Address>;
  freightForwarderGroupSearch: NgSelectSearchParams<FreightForwarderGroup>;
  emails = Object.values(FreightForwarderEmail);

  constructor(public dialogRef: MatDialogRef<FreightForwarderDialogComponent>,
              @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private freightForwarderGroupService: FreightForwarderGroupService,
              public freightForwarderService: FreightForwarderService,
              public addressService: AddressService,
              public alerts: OmsAlertsService,
              public file: FileUploadService
  ) {
    super(dialogRef, freightForwarderService, alerts);
  }

  ngOnInit() {
    this.freightForwarder = Object.assign(this.freightForwarder, this.data.freightForwarder);
    this.freightForwarderGroupSearch = new NgSelectSearchParams<FreightForwarderGroup>(this.freightForwarderGroupService);

    this.edit = !this.freightForwarder.isNew();
    this.addressSearch = new NgSelectSearchParams<Address>(this.addressService);
    this.viewLoading = true;
    this.freightForwarder = this.data.freightForwarder;
    this.createForm();
    this.viewLoading = false;
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.freightForwarder.name, Validators.required],
      freightForwarderGroup: [this.freightForwarder.freightForwarderGroup, Validators.required],
      address: [this.freightForwarder.address],
      email: [this.freightForwarder.emails],
      sendPODToEmails: [this.freightForwarder.sendPODToEmails]
    });
  }

  getTitle(): string {
    if (this.freightForwarder.id > 0) {
      return 'Edit Freight Forwarder';
    }
    return 'New Freight Forwarder';
  }

  prepare(): FreightForwarder {
    const controls = this.form.controls;
    const _freightForwarder = this.freightForwarder;
    _freightForwarder.name = controls['name'].value;
    _freightForwarder.address = controls['address'].value;

    let freightForwarderGroup = controls['freightForwarderGroup'].value;
    if ((typeof freightForwarderGroup)  === "string") {
      _freightForwarder.freightForwarderGroup = new FreightForwarderGroup();
      _freightForwarder.freightForwarderGroup.name = freightForwarderGroup;
    } else {
      _freightForwarder.freightForwarderGroup = freightForwarderGroup;
    }

    _freightForwarder.sendPODToEmails = controls['sendPODToEmails'].value;
    let emailValues = controls['email'].value;
    if (emailValues) {
      let updateFreightForwarderEmails = _freightForwarder.emails;
      _freightForwarder.emails = [];
      emailValues.forEach((emailValue, index) => {
        if (!isNullOrUndefined(emailValue)) {
          if (updateFreightForwarderEmails && updateFreightForwarderEmails.length > index) {
            let freightForwarderEmail = updateFreightForwarderEmails[index];
            freightForwarderEmail.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
            _freightForwarder.emails.push(freightForwarderEmail);
          } else {
            let email = new FreightForwarderEmail();
            email.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
            _freightForwarder.emails.push(email);
          }
        }
      });
    } else {
      _freightForwarder.emails = [];
    }

    return _freightForwarder;
  }

  onAlertClose() {
    this.hasFormErrors = false;
  }

  update(item: FreightForwarder) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    let ffg = Object.assign(new FreightForwarderGroup(), item.freightForwarderGroup);
    this.freightForwarderGroupService.save(ffg)
      .pipe(flatMap((freightForwarderGroup) => {
        item.freightForwarderGroup = freightForwarderGroup;
        return this.freightForwarderService.save(item);
      }))
      .subscribe((res) => {
        this.viewLoading = false;
        this.dialogRef.close({res, isEdit: !item.isNew()});
      }, (error) => {
        this.viewLoading = false;
        this.alerts.danger(error);
      });
  }

  public getStatusPageUrl(): string {
    let ffg = this.form.controls['freightForwarderGroup'].value;
    let ffgId: number = ffg && ffg.id;
    return ffgId ? buildCfsStatusPageUrl(ffgId) : undefined;
  }

  public copyStatusPageUrl() {
    copyToClipboard(this.getStatusPageUrl());
    this.alerts.info("Page URL copied to the Clipboard buffer", 1000);
  }
}
