var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { BaseDeletable } from './base-deletable';
import { Exclude, Type } from 'class-transformer';
import { Address, User } from "./";
import { DriverLicense } from "./driver-license";
import { DriverInsurance } from "./driver-insurance";
var ɵ0 = function () { return User; }, ɵ1 = function () { return Date; }, ɵ2 = function () { return Date; }, ɵ3 = function () { return Address; }, ɵ4 = function () { return DriverLicense; }, ɵ5 = function () { return DriverInsurance; };
var Driver = /** @class */ (function (_super) {
    __extends(Driver, _super);
    function Driver() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Driver.prototype, "fullName", {
        get: function () {
            return this.user ? this.user.fullName : (this.firstName + ' ' + this.lastName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Driver.prototype, "label", {
        get: function () {
            return this.fullName;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Type(ɵ0),
        __metadata("design:type", User)
    ], Driver.prototype, "user", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Date)
    ], Driver.prototype, "hireDate", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Date)
    ], Driver.prototype, "leaveDate", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Address)
    ], Driver.prototype, "homeBase", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", DriverLicense)
    ], Driver.prototype, "license", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", DriverInsurance)
    ], Driver.prototype, "insurance", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Driver.prototype, "fullName", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Driver.prototype, "label", null);
    return Driver;
}(BaseDeletable));
export { Driver };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
