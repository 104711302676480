﻿<div class="form-group custom-search-container" [id]="identifier"
     [ngClass]="{'max-width': maxWidth, 'width-auto': autoWidth, 'height-auto': autoHeight, 'inplace': inplace, 'inline': inline}" >
  <label class="control-label" *ngIf="showLabel && !inplace">{{label}}</label>
  <ng-content select="[input-before]"></ng-content>
  <div class="input-group" [ngClass]="{'input-group-sm': size == Size.SMALL, 'input-group-lg': size == Size.LARGE}"
       style="width: 100%;">

    <span class="input-group-addon" *ngIf="iconClass || header">
      <i *ngIf="iconClass" ngClass="{{iconClass}}"></i>
      {{header}}
    </span>

    <span class="input-group-addon if-not-empty">
        <ng-content select="[group-before]"></ng-content>
    </span>

    <ng-select #ngSelect class="form-control widget filters"
               [appendTo]="appendTo"
               [disabled]="disabled"
               [multiple]="multiple"
               [clearable]="allowClear"
               (click)="toggleOpen($event)"
               [bindLabel]="bindLabel"
               [bindValue]="bindValue"
               [items]="searchSubject | async"
               [loading]="loading"
               [typeahead]="typeahead"
               (change)="onChanged($event)"
               (search)="onSearch($event)"
               [searchable]="searchable && !readonly"
               [searchFn]="defaultSearchFn"
               (open)="onOpen()"
               [isOpen]="isOpen"
               (blur)="onSearchExit()"
               [closeOnSelect]="!multiple"
               (keydown)="onKeyDown($event)"
               [virtualScroll]="true"
               [(ngModel)]="value"
               [ngClass]="{'form-control-invalid':(invalid | async) || invalidValue, 'no-input':multiple}"
               [placeholder]="placeholder">


      <ng-template ng-header-tmp *ngIf="multiple">
        <div class="form-row" style="display: flex; align-items: center;">
          <span *ngIf="searchable" style="width: 100%;">
            <input #search placeholder="Search&hellip;" style="width: 100%; line-height: 24px"
                   [ngModel]="_input"
                   class="form-control" type="text" (input)="onSearchInput($event.target['value'])"/>
            <button class="btn btn-clean btn-clear-input ng-star-inserted" *ngIf="search.value"
                    style="right: 10px; top: 10px;" (click)="onSearchInput('')">
              <i class="fas fa-backspace"></i>
            </button>
          </span>
        </div>

        <span (click)="searchCheckboxClick($event)" style="cursor: pointer;" [popover]="searchCheckboxHint"
              style="width: 100%; display: block; border-top: 1px solid #cccccc; padding-top: 5px;">

          <oms-checkbox [indeterminate]="isPartiallySelected" [checked]="isAllSelected" style="pointer-events: none"
                        [label]="searchCheckboxHint" >
          </oms-checkbox>

          <div class="btn btn-xs btn-primary" (click)="ngSelect.onInputBlur($event);" style="float: right;">
            <i class="fas fa-check"></i>Close
          </div>

        </span>

      </ng-template>

      <!--ng-template ng-footer-tmp *ngIf="multiple" class="form-group">
        <h4 style="float: left; margin-right: 10px">Selected count: {{ngSelect.selectedItems.length}}</h4>
        <div class="btn btn-sm btn-primary" (click)="closeSelect(ngSelect);">Close</div>
      </ng-template-->

      <ng-template ng-notfound-tmp>
        <h5 style="text-align: center;color: #a8a8a8;">No Items Found</h5>
      </ng-template>


      <ng-template ng-multi-label-tmp let-items="items" *ngIf="!labelTemplate">

        <ng-template #selectedTooltip>
          <ul style="margin: 0; padding: 0 0 0 15px;" *ngIf="multiple">
            <li *ngFor="let item of value" style="white-space: nowrap; text-align: left;">{{labelOf(item)}}</li>
          </ul>
        </ng-template>

        <div class="multi-selected-items" [container]="'body'" popoverTitle="Selected" placement="right"
             [popover]="selectedTooltip">{{items.length > 1 ? items.length + ' selected' : labelOf(items[0])}}</div>
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!!labelTemplate">
        <ng-container *ngTemplateOutlet="labelTemplate; context:{item: item, clear: clear}"></ng-container>
      </ng-template>

      <!-- CUSTOM OPTIONS TEMPLATE -->
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" *ngIf="!!optionTemplate">
        <ng-container *ngTemplateOutlet="optionTemplate; context:{item: item, search:search}"></ng-container>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" *ngIf="!optionTemplate">
        <li><a><label [ngClass]="{'radio': !multiple, 'checkbox': multiple}" [ngOptionHighlight]="search">{{(bindLabel && item) ? item[bindLabel] : item?.label || item}}</label></a></li>
      </ng-template>

      <ng-template ng-footer-tmp *ngIf="allowCreate">
        <div class="widget-footer right">
          <button class="btn btn-sm  btn-primary" (click)="onCreate();">Create New&hellip;</button>
        </div>
      </ng-template>

    </ng-select>
  </div>
  <validation [@flyInOut]="'in,out'" *ngIf="invalid | async" [messages]="failures | async"></validation>
</div>

