<oms-base-page [title]="'Test'" [subtitle]="'Components Test Page'">

  <ng-container page-header>
    <div class="row">

      <div class="col col-md-2">
        <oms-date-input label="Date"  [(ngModel)]="date" [time]="false" ></oms-date-input>
      </div>

      <div class="col col-md-4">
        <oms-custom-search #driverInput [inplace]="true"
                           label="Driver" [showLabel]="true"
                           [placeholder]="'Driver'"
                           [(ngModel)]="driver" (ngModelChange)="refresh();"
                           bindLabel="fullName"
                           [sort-by]="'firstName'"
                           [items]="selectDrivers"
                           [service]="userService">
        </oms-custom-search>
      </div>

      <div class="col col-md-1">
        <button class="btn btn-primary" (click)="refresh();">
          <label>
            <i class="fa fa-refresh"></i>
            Reload
          </label>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container page-body>
    <div class="row" style="display: flex; justify-content: center;" >
      <div class="col-md-4" >
        <oms-widget [label]="'Test'" [showToolbar]="false" [no-padding]="true">
          <div #bodyDiv style="height: 200px; overflow: auto;">
            <table #table class="table table-bordered table-striped table-fixed table-body-only table-condensed data-table table-hover table-responsive">
              <tbody class="data-table-scroll" oms-scroller  *ngIf="temp?.length"
                     [scrollbarV]="scrollbarV"
                     [scrollbarH]="scrollbarH"
                     [scrollHeight]="scrollHeight" (scroll)="onBodyScroll($event)">

              <ng-container *ngFor="let row of temp, let i = index">
                <ng-container *ngTemplateOutlet="drivers;context{item: row}" >

                  <!--tr  class="table-row" [ngStyle]="getRowStyles(row)" (click)="toggleRowExpansion(row)">
                    <span class="sticky-columns">
                      <td width="50px">+{{row.id}}</td>
                      <td width="150px">+{{row.firstName}}</td>
                    </span>
                    <td width="150px">+{{row.lastName}}</td>
                    <td width="50px">{{row.id}}</td>
                    <td width="150px">{{row.firstName}}</td>
                    <td width="150px">{{row.lastName}}</td>
                    <td width="50px">{{row.id}}</td>
                    <td width="150px">{{row.firstName}}</td>
                    <td width="150px">{{row.lastName}}</td>
                    <td width="50px">{{row.id}}</td>
                    <td width="150px">{{row.firstName}}</td>
                    <td width="150px">{{row.lastName}}</td>
                  </tr-->
                </ng-container>
              </ng-container>
              </tbody>
            </table>
          </div>
        </oms-widget>
      </div>
      <div class="col-md-2">
        <div *ngFor="let row of temp">
          <div>{{row.firstName}} {{row.lastName}}</div>
        </div>
      </div>
    </div>


    <oms-widget [showFooter]="true" [flex]="true" [description]="tabs.activeTab?.tabId" [no-padding]="false" [label]="tabs.activeTab?.name" [iconClass]="tabs.activeTab?.iconClass">

      <ng-container widget-header>
      </ng-container>

      <oms-tabs #tabs [tabs-style]="'tabs-left'" (activeChange)="onActiveTabChange($event)" [(active)]="activeTab">

        <ng-template oms-tab name="Driver Routes" icon-class="fa fa-truck" tab-id="tabRoutes">
              <div *ngFor="let route of routes">
                <div>{{route.id}}</div>
                <div>{{route}}</div>
                <div>{{route.addressPickUp?.fullAddress}}</div>
                <div>{{route.addressDelivery?.fullAddress}}</div>
              </div>
        </ng-template>

        <ng-template oms-tab name="BBB" tab-id="tabBBB" [visible]="tabVisible" [active]="true">
        </ng-template>

        <ng-template oms-tab name="CCC" tab-id="tabCCC">
          CCCCCC
        </ng-template>

        <ng-template oms-tab name="DDD">
          DDDDDDD
        </ng-template>


      </oms-tabs>

      <ng-container widget-footer>
        <oms-toggle-switch [label]="'Visible Tab'" [(ngModel)]="tabVisible"></oms-toggle-switch>
        footer
      </ng-container>
    </oms-widget>



    <ng-template #drivers let-item="item">
      <tr  class="table-row" [ngStyle]="getRowStyles(item)" (click)="toggleRowExpansion(item)">
        <span class="sticky-columns">
          <td width="50px">+{{item.id}}</td>
          <td width="150px">+{{item.firstName}}</td>
        </span>
        <td width="150px">+{{item.lastName}}</td>
        <td width="50px">{{item.id}}</td>
        <td width="150px">{{item.firstName}}</td>
        <td width="150px">{{item.lastName}}</td>
        <td width="50px">{{item.id}}</td>
        <td width="150px">{{item.firstName}}</td>
        <td width="150px">{{item.lastName}}</td>
        <td width="50px">{{item.id}}</td>
        <td width="150px">{{item.firstName}}</td>
        <td width="150px">{{item.lastName}}</td>
      </tr>
    </ng-template>


  </ng-container>

  <ng-container page-bottom>
    BOTTOM
  </ng-container>


</oms-base-page>
