<ngx-spinner bdColor = "#33333366" color = "#fff" [fullScreen]="false" type="pacman" size="medium">
  <p style="color: white" >Loading...</p>
</ngx-spinner>

<div class="top-bar navbar-fixed-top">
  <div class="container">
    <div class="clearfix" style="text-align: center;"><!--style="display: flex; height: 33px;"-->
      <h3 class="pull-left" style="color: white; font-size: 25px; margin: 0 auto;">Order Status</h3>
      <div class="logo">
        <div class="main-header" style="margin: 0 auto; display: flex;">
          <h2>JJS</h2>
          <em>OMS</em>
        </div>
      </div>
    </div>
  </div>
</div>

<oms-base-page [title]="'Order Status'" [subtitle]="" [showHeader]="false">
  <nav #toolbar style="margin-top: 55px;">
    <div class="container-fluid">
      <!--label class="control-label search-label medium-screen-only">Order Status</label-->
      <form class="navbar-form form-group" >
          <div class="input-group input-group-huge col-sm-6">
            <input type="text" class="form-control"
                   [(ngModel)]="searchHAWB"
                   title="Value must be at least 5 characters"
                   (ngModelChange)="searchMAWB = ''; searchRef3 = ''"
                   [ngModelOptions]="{standalone: true}"
                   (keypress)="onSearchKey($event, 'HAWB')"
                   placeholder="REF / HAWB / PO"/>
            <div class="input-group-btn">
              <button type="submit" class="btn btn-default" (click)="searchChanged('HAWB', false)"><i class="glyphicon glyphicon-search"></i></button>
              <button class="btn btn-clean btn-clear-input" *ngIf="getSearchText('HAWB')" (click)="searchHAWB='';searchChanged('HAWB', true)" style="right: 130px;"><i class="fa fa-times"></i></button>
            </div>
          </div>

<!--
          <div class="input-group input-group-huge col-lg-4">
            <input type="text" class="form-control"
                   [(ngModel)]="searchRef3"
                   title="Value must be at least 5 characters"
                   (ngModelChange)="searchMAWB = ''; searchHAWB = ''"
                   [ngModelOptions]="{standalone: true}"
                   (keypress)="onSearchKey($event, 'REF3')"
                   placeholder="REF3"/>
            <div class="input-group-btn">
              <button type="submit" class="btn btn-default" (click)="searchChanged('REF3', false)"><i class="glyphicon glyphicon-search"></i></button>
              <button class="btn btn-clean btn-clear-input" *ngIf="getSearchText('REF3')" (click)="searchRef3='';searchChanged('REF3', true)" style="right: 90px;"><i class="fa fa-times"></i></button>
            </div>
          </div>
          -->


          <div class="input-group input-group-huge col-sm-6">
            <input type="text" class="form-control"
                   [(ngModel)]="searchMAWB"
                   [mask]="'000-0000-0000'" [showTemplate]="true"
                   (ngModelChange)="searchHAWB = ''; searchRef3= ''"
                   [ngModelOptions]="{standalone: true}"
                   (keypress)="onSearchKey($event, 'MAWB')"
                   placeholder="AIR IMPORT MAWB ONLY"/>
            <div class="input-group-btn">
              <button type="submit" class="btn btn-default" (click)="searchChanged('MAWB', false)" ><i class="glyphicon glyphicon-search"></i></button>
              <button class="btn btn-clean btn-clear-input" *ngIf="searchMAWB" (click)="searchMAWB='';searchChanged('MAWB', true)" style="right: 130px;"><i class="fa fa-times"></i></button>
            </div>
            <!--ul class="multiselect-container dropdown-menu pull-right" style="max-height: 300px; overflow: hidden auto; font-size: 1.4em;" *ngIf="found">
              <li *ngFor="let master of found" class="active" [ngClass]="{'active': search_active === master}">
                <a (click)="setMaster(master);"><label class="radio"><input type="radio" value="{{master.mawbNumber}}">{{convertMawbNumber(master.mawbNumber)}}</label></a>
              </li>
            </ul-->
          </div>

        <!-- OLD - With Search Dropdown functionality
        <div class="form-group">
          <div class="input-group input-group-sm open">
            <input type="text" class="form-control search-component"
                   [ngModel]="getSearchText('MAWB')"
                   title="Value must be at least 4 characters"
                   (ngModelChange)="setSearchText($event, 'MAWB')"
                   [ngModelOptions]="{standalone: true}"
                   (keypress)="onSearchKey($event, 'MAWB')"
                   placeholder="MAWB"/>
            <div class="input-group-btn">
              <button type="submit" class="btn btn-default" (click)="searchChanged('MAWB', false)" style="height: 75px;"><i class="glyphicon glyphicon-search"></i></button>
              <button class="btn btn-clean btn-clear-input" *ngIf="getSearchText('MAWB')" (click)="setSearchText('', 'MAWB');searchChanged('MAWB', true)" style="right: 40px;"><i class="fa fa-times fa-3x"></i></button>
            </div>
            <ul class="multiselect-container dropdown-menu pull-right" style="max-height: 300px; overflow: hidden auto; font-size: 1.4em;" *ngIf="found">
              <li *ngFor="let master of found" class="active" [ngClass]="{'active': search_active === master}">
                <a (click)="setMaster(master);"><label class="radio"><input type="radio" value="{{master.mawbNumber}}">{{convertMawbNumber(master.mawbNumber)}}</label></a>
              </li>
            </ul>
          </div>
        </div>
          -->
      </form>

    </div>
  </nav>
  <ng-container page-body>
    <div class="widget widget-table">
      <div #content class="widget-content my-component">
        <oms-data-table #table
                        [rowHeight]="36"
                        [striped]="true"
                        [condensed]="true"
                        [selectable]="false"
                        [searchable]="false"
                        [checkboxes]="true"
                        [fixed]="false"
                        [columns]="columns"
                        [data]="orders"
                        [selected]="selected"
                        [height]="content.offsetHeight - toolbar.offsetHeight"
                        [paging]="paging">
        </oms-data-table>
      </div>
    </div>
  </ng-container>
</oms-base-page>
