import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Country} from '../models/address/country';
import {plainToClass} from 'class-transformer';
import {BaseEntityService} from '../../../services/base/base-entity.service';
import {HttpUtilsService} from '../../../services/http-utils.service';
import {RestService} from '../../../services/rest.service';
import {map} from 'rxjs/operators';

@Injectable()
export class CountryService extends BaseEntityService<Country> {
  protected apiUrl = '/oms/country/';
  protected classType = Country;

  public countriesSubject = new Subject<Country[]>();

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }


  getByIso2Code(code: string): Observable<Country> {
    return this.httpRestService.get<Country>(this.apiUrl + 'getByIso2Code', code)
      .pipe(map((item) => plainToClass(this.classType, item)));
  }

  getByIso3Code(code: string): Observable<Country> {
    return this.httpRestService.get<Country>(this.apiUrl + 'getByIso3Code', code)
      .pipe(map((item) => plainToClass(this.classType, item)));
  }


  /***@deprecated***/
  getAllCountries() {
    this.httpRestService.get<Country[]>(this.apiUrl + 'getAllCountries').subscribe(items => {
      this.countriesSubject.next(plainToClass(Country, items as Object[]));
    });
  }

}
