import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { RestService } from './rest.service';
import { plainToClass } from 'class-transformer';
import { Router } from '@angular/router';
import { UserUtil } from "../modules/settings/util/user.util";
import { WebSocketService } from "./websocet/web.socket.service";
import { LoggedUser } from "../modules/shared/models/logged-user";
import { map } from "rxjs/operators";
import { UserRoleType } from "../modules/shared/models/user-role.type";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
import * as i4 from "./websocet/web.socket.service";
var AuthService = /** @class */ (function () {
    function AuthService(restService, router, http, webSocketService) {
        this.restService = restService;
        this.router = router;
        this.http = http;
        this.webSocketService = webSocketService;
        this.user$ = new BehaviorSubject(null);
        this.isClientOnly = new BehaviorSubject(false);
        this.user$.next(UserUtil.getCurrentUser());
    }
    AuthService.prototype.logIn = function (username, password, returnUrl) {
        var _this = this;
        return new Promise(function (success, reject) {
            var base64Credential = btoa(username + ':' + password);
            var headers = new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': 'Basic ' + base64Credential,
                'withCredentials': 'true'
            });
            _this.restService.getWithHeader('/account/login', headers).subscribe(function (data) {
                console.log('RECEIVED', data);
                _this.onUserInfo(data);
                _this.router.navigateByUrl(returnUrl || '/').then();
                success(data);
            }, function (e) {
                reject(e);
            });
        });
    };
    AuthService.prototype.logOut = function (returnUrl) {
        var _this = this;
        //    console.log('log out...', returnUrl);
        return new Promise(function (success, reject) {
            _this.restService.get('/account/logout').subscribe(function () {
                _this.onUserInfo(null);
                if (!returnUrl || !returnUrl.includes('login')) {
                    _this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } }).then();
                }
                success();
            }, function (e) {
                reject(e);
            });
        });
    };
    AuthService.prototype.onUserInfo = function (userInfo) {
        var logged = userInfo ? plainToClass(LoggedUser, userInfo) : null;
        localStorage.setItem("currentUser", JSON.stringify(logged));
        if (logged) {
            this.webSocketService.connect();
        }
        else {
            this.webSocketService.disconnect();
        }
        this.user$.next(logged);
        this.initIsClientOnlyUser();
    };
    AuthService.prototype.getUserInfo = function () {
        var _this = this;
        //    console.log('get User Info...');
        this.restService.get('/account/getUserInfo').subscribe(function (user) {
            console.log('User Data', user);
            _this.onUserInfo(user);
        });
    };
    AuthService.prototype.isRestricted = function () {
        var user = this.user$.getValue();
        return user && !user.haveAiDispatchAccess;
    };
    AuthService.prototype.isUserInRole = function (role) {
        var user = this.user$.getValue();
        return user && user.hasRole(role);
    };
    AuthService.prototype.isUserInRoles = function (roles) {
        var _this = this;
        return roles && !!roles.find(function (role) { return _this.isUserInRole(role); });
    };
    AuthService.prototype.hasBillingApprove = function () {
        var user = this.user$.getValue();
        return user && user.billingApprove;
    };
    AuthService.prototype.initIsClientOnlyUser = function () {
        var isClientOnly = this.isUserInRole(UserRoleType.ROLE_CLIENT)
            && !this.isUserInRole(UserRoleType.ROLE_ADMIN)
            && !this.isUserInRole(UserRoleType.ROLE_QA);
        this.isClientOnly.next(isClientOnly);
    };
    AuthService.prototype.currentUserHasRole = function (userRole) {
        return this.user$.pipe(map(function (user) { return user && user.hasRole(userRole); }));
    };
    AuthService.prototype.isReadMode = function () {
        return this.user$.pipe(map(function (user) { return user && user.userRoles && user.userRoles.length === 1 && user.hasRole(UserRoleType.ROLE_CUSTOMER); }));
    };
    AuthService.prototype.getCurrentUser = function () {
        return this.user$.getValue();
    };
    AuthService.prototype.canDeleteOrder = function () {
        var user = this.user$.getValue();
        return user && user.canDeleteOrder;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.RestService), i0.inject(i2.Router), i0.inject(i3.HttpClient), i0.inject(i4.WebSocketService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
