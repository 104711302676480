var ValidationComponent = /** @class */ (function () {
    function ValidationComponent() {
    }
    Object.defineProperty(ValidationComponent.prototype, "state", {
        get: function () {
            return this.messages && this.messages.length > 0 ? 'in' : 'out';
        },
        enumerable: true,
        configurable: true
    });
    return ValidationComponent;
}());
export { ValidationComponent };
