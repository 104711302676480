import {Injectable} from '@angular/core';
import {Uld} from "../modules/shared/models";
import {RestService} from "./rest.service";
import {Observable} from "rxjs";
import {HttpUtilsService} from "./http-utils.service";
import {BaseEntityService} from "./base/base-entity.service";


const API_URL = "/oms/ulds/";

@Injectable()
export class UldService extends BaseEntityService<Uld> {

  public apiUrl = API_URL;
  public classType = Uld;

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  updateULDs(ulds: Uld[], masterId: number, orderId: number): Observable<Uld[]> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    let ps: any = {};
    if (masterId) {
      ps.masterId = masterId;
    }
    if (orderId) {
      ps.orderId = orderId;
    }
    let params = this.httpUtils.getHTTPParams(ps);
    return this.httpRestService.put<Uld[]>(API_URL + 'update-ulds', ulds, {headers: httpHeader}, params);
  }

}
