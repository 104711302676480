var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { BaseDeletable } from './base-deletable';
import { Type } from 'class-transformer';
import { Driver } from "./driver";
import { User } from "./user";
var ɵ0 = function () { return Driver; }, ɵ1 = function () { return User; }, ɵ2 = function () { return Driver; }, ɵ3 = function () { return Date; }, ɵ4 = function () { return Date; };
var ChatRecord = /** @class */ (function (_super) {
    __extends(ChatRecord, _super);
    function ChatRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Driver)
    ], ChatRecord.prototype, "driver", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", User)
    ], ChatRecord.prototype, "sendByUser", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Driver)
    ], ChatRecord.prototype, "sendByDriver", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Date)
    ], ChatRecord.prototype, "driverAcknowledge", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", Date)
    ], ChatRecord.prototype, "jjsUserAcknowledge", void 0);
    return ChatRecord;
}(BaseDeletable));
export { ChatRecord };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
