import {TransformationType} from "class-transformer";

export enum ReportExecutionStatus {
  ERROR,
  SCHEDULED,
  MANUALLY
}

export function transformReportExecutionStatus(value: any, obj: any, transformationType: TransformationType): any {
  switch (transformationType) {
    case TransformationType.PLAIN_TO_CLASS: return value ? value.id : undefined;
    case TransformationType.CLASS_TO_PLAIN: return value ? {id: value} : undefined;
    default: return value;
  }
}
