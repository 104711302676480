import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {
  BaseColumn,
  FieldType, HyperLinkColumn,
} from "../../../../modules/shared/components/base/data-table/columns/column-types";
import {Master, Order} from "../../../../modules/shared/models";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Billable, Size} from 'src/app/common/oms-types';
import {OrdersService} from "../../../../services";
import {OmsAlertsService} from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {Invoice} from "../../../../modules/shared/models/invoice";
import {convertOrderNumber} from "../../../../modules/shared/services/oms-converters.service";
import {MasterService} from "../../../../services/master/master.service";
import {assigned, copyToClipboard, downloadFile} from "../../../../_helpers/utils";

@Component({
  selector: 'oms-billing-info-list-dialog',
  templateUrl: './billing-info-list-dialog.component.html',
  styleUrls: ['./billing-info-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingInfoListDialogComponent {

  public Size = Size;
  invoices: Invoice[] = [];
  public isReadMode: boolean;
  public billable: Billable;
  loading: boolean = false;
  public dateBilled: Date;

  public modified: boolean = false;


  columns: BaseColumn[] = [

    new HyperLinkColumn('invoiceNumber', 'Invoice #', 'invoiceNumber', FieldType.TEXT, '150px')
      .setHyperLink((row) => {
        if (row.invoiceUri) {
            downloadFile(row.invoiceUri);
        } else {
          this.alerts.warning("No document file found");
        }
      })
      .setButton({
        hidden: (row) => !row.invoiceUri,
        icon: 'fas fa-copy',
        onClick: (row) => {
          copyToClipboard(row.invoiceUri);
          this.alerts.info(`Invoice Link:\n${row.invoiceUri}\nCopied to the clipboard`);
        },
        tooltip: (row) => row.invoiceUri ? `Copy Invoice Link:\n${row.invoiceUri}` : `No Document`})
      .setSortable(false),

/*    new BaseColumn('invoiceNumber', 'Copy', 'invoiceNumber', FieldType.TEXT, '80px', {converter: () => ''})
      .setButton({icon: 'fas fa-copy', tooltip: (row) => `Copy Invoice Link\n${row.invoiceUri}`, onClick: (row) => {copyToClipboard(row.invoiceUri)}}),
*/
    new BaseColumn('order.id', 'Order', 'order.id', FieldType.TEXT, '80px', {
      converter: (r, v) => v ? convertOrderNumber(v) : "N/A"}).setSortable(false),

    new BaseColumn('shipment.id', 'Shipment', 'shipment.id', FieldType.TEXT, '80px').setSortable(false),
    new BaseColumn('amount', 'Amount', 'amount', FieldType.TEXT, '60px', {
      converter: (n, v) => v ? v.toFixed(2) : 'N/A'}).setSortable(false),
    new BaseColumn('billToName', 'Bill To', 'billToName', FieldType.TEXT, '200px').setMaxWidth('auto').setSortable(false),
    new BaseColumn('dateCreated', 'Date', 'dateCreated', FieldType.DATE, '70px').setSortable(false),
    new BaseColumn('createdBy', 'Created by', 'createdBy', FieldType.TEXT, '200px').setSortable(false)
  ];

  constructor(
    public orderService: OrdersService,
    public masterService: MasterService,
    public alerts: OmsAlertsService,
//    private dialogRef: MatDialogRef<BillingInfoListDialogComponent, MasterStatus[]>,
    protected cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('Info', data);
    this.invoices = data.invoices;
    this.billable = data.object;
    this.isReadMode = data.isReadMode;
    this.dateBilled = data.object ? data.object.dateBilled : undefined;
  }

  public onUpdate() {
    this.loading = true;

    let request: Promise<any>;
    if (this.billable instanceof Master) {
      request = this.masterService.updateMaster(this.billable.id, {dateBilled: this.dateBilled});
    } else if (this.billable instanceof Order) {
      request = this.orderService.updateOrder(this.billable.id, {dateBilled: this.dateBilled});
    }

    if (assigned(request)) {
      request
        .then(() => {
          this.loading = false;
          this.billable.dateBilled = this.dateBilled;
          this.modified = false;
          this.cdr.markForCheck();
          this.alerts.success("Billing Information Updated");
        })
        .catch((error) => {
          this.loading = false;
          this.alerts.error(error, 'Error updating Billing Information');
          this.cdr.markForCheck();
        });
    }
  }

}
