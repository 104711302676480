<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget" (keyup)="onKeyUp($event)">

    <div class="widget-header">
      <h3>
        <i class="fa" [ngClass]="{'fa-question-circle':type === DialogType.CONFIRMATION,
                       'fa-check-circle':type === DialogType.SUCCESS,
                       'fa-exclamation-triangle':type === DialogType.WARNING,
                       'fa-info-circle':type === DialogType.INFORMATION,
                       'fa-times-circle':type === DialogType.ERROR}"></i>
        {{data.title}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog(ResultType.CANCEL)">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>


    <div class="widget-content" style="min-height: 150px;">
      <div class="form-row alert alert-message" [innerHTML]="data.message"
           [ngClass]="{'alert-info':type === DialogType.CONFIRMATION || type === DialogType.INFORMATION,
                        'alert-success':type === DialogType.SUCCESS,
                       'alert-warning':type === DialogType.WARNING,
                       'alert-danger':type === DialogType.ERROR}">
      </div>

      <ng-content>

      </ng-content>
    </div>

    <div class="widget-footer">

      <div>
      </div>

      <div class="dialog-btns">
        <!-- todo REWORK COMPLETELY -->

        <button autofocus type="button" class="btn btn-default" (click)="closeDialog(ResultType.OK)" *ngIf="buttons.has(ResultType.OK)">
          <i class="fas fa-check">&nbsp;</i>
          {{getBtnCaption(ResultType.OK)}}
        </button>

        <button autofocus type="button" class="btn btn-primary" (click)="closeDialog(ResultType.YES)" *ngIf="buttons.has(ResultType.YES)">
          <i class="fas fa-check">&nbsp;</i>
          {{getBtnCaption(ResultType.YES)}}
        </button>

        <button type="button" class="btn btn-danger" (click)="closeDialog(ResultType.NO)" *ngIf="buttons.has(ResultType.NO)">
          <i class="fas fa-times">&nbsp;</i>
          {{getBtnCaption(ResultType.NO)}}
        </button>

        <button type="button" class="btn btn-danger" (click)="closeDialog(ResultType.CANCEL)" *ngIf="buttons.has(ResultType.CANCEL)">
          <i class="fas fa-times">&nbsp;</i>
          {{getBtnCaption(ResultType.CANCEL)}}
        </button>
      </div>
    </div>

  </div>
</perfect-scrollbar>
