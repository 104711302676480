﻿import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../../../services';
import {NavigationService} from '../../../../../services';
import {copyToClipboard} from '../../../../../_helpers/utils';
import {BreadcrumbsComponent} from '../breadcrumbs/breadcrumbs.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {OmsDialogsService} from "../../../../../components/common/oms-dialogs";
import {LoggedUser} from "../../../models/logged-user";
import {UserPreferencesService} from "../../../../../services/user-preferences.service";

@Component({
    selector: 'oms-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss'],
//    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class HeaderComponent implements OnInit {
  @Input() currentUser: LoggedUser;
  public breadcrumbs;

  constructor(private auth: AuthService,
              public authService: AuthService,
              public userPreferences: UserPreferencesService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router,
              private omsDialogService: OmsDialogsService,
              public navigationService: NavigationService) {}

  toggleLeftSideBar() {
    this.navigationService.toggleLeftSideBar();
  }

  public copyUrl() {
    copyToClipboard(window.location.href);
  }

  ngOnInit() {
    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.breadcrumbs = BreadcrumbsComponent.getBreadcrumbs(this.activatedRoute.root);
    });
    this.breadcrumbs = BreadcrumbsComponent.getBreadcrumbs(this.activatedRoute.root);
  }


  openChat() {
    this.omsDialogService.openChatDialog();
  }
}
