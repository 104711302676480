import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {
  BaseColumn,
  FieldType,
  HyperLinkColumn
} from "../modules/shared/components/base/data-table/columns/column-types";
import {isNullOrUndefined} from "util";
import {HyperLink} from "./oms-types";

@Injectable({
  providedIn: "root"
})
export class CbqQuickViewStatusColumnsService {

  public cbpQuickViewStatusColumns: Subject<BaseColumn[]> = new Subject<BaseColumn[]>();

  private cbpQuickView1FStatusDefaultColumns: BaseColumn[];
  private cbpQuickView1CStatusDefaultColumns: BaseColumn[];

  constructor() {
    this.init();
  }


  getCbpQuickViewStatusColumns(view: string) {
    if (view === "MASTER") {
      this.cbpQuickViewStatusColumns.next(this.cbpQuickView1FStatusDefaultColumns);
    } else {
      this.cbpQuickViewStatusColumns.next(this.cbpQuickView1CStatusDefaultColumns);
    }
  }

  private init() {

    this.cbpQuickView1FStatusDefaultColumns = [
      new BaseColumn('transactionDate', 'Transaction Date', 'transactionDate', FieldType.DATETIME, '130px', {sorted: {asc: false}}).setHiddenColumn(true),
      new HyperLinkColumn('mawbNumber', 'Mawb', 'mawbNumber', FieldType.TEXT, '100px', {
        link: getCbpLink,
        sorted: {asc: true}
      }),
      new HyperLinkColumn('part', 'Part', 'part', FieldType.TEXT, '70px', {
        link: getCbpLink,
        converter: (row, value) => {
          return isNullOrUndefined(value) ? '-' : value;
        }
      }),
      new BaseColumn('uscsFsnStatus', 'Status', 'uscsFsnStatus', FieldType.TEXT, '70px'),
      new BaseColumn('fsnSentDate', 'FSN Sent Date', 'fsnSentDate', FieldType.DATETIME, '130px'),
      new BaseColumn('totalPieces', 'Pieces', 'totalPieces', FieldType.TEXT, '70px')
    ];

    this.cbpQuickView1CStatusDefaultColumns = [
      new BaseColumn('transactionDate', 'Transaction Date', 'transactionDate', FieldType.DATETIME, '130px', {sorted: {asc: false}}).setHiddenColumn(true),
      new HyperLinkColumn('hawb', 'HAWB', 'hawb', FieldType.TEXT, '100px', {link: getCbpLink}),
      new HyperLinkColumn('part', 'Part', 'part', FieldType.TEXT, '70px', {
        link: getCbpLink,
        converter: (row, value) => {
          return isNullOrUndefined(value) ? '-' : value;
        }
      }),
      new BaseColumn('uscsFsnStatus', 'Status', 'uscsFsnStatus', FieldType.TEXT, '70px'),
      new BaseColumn('fsnSentDate', 'FSN Sent Date', 'fsnSentDate', FieldType.DATETIME, '130px'),
      new BaseColumn('totalPieces', 'Pieces', 'totalPieces', FieldType.TEXT, '70px')
    ];
  }


}

let getCbpLink = function (row): HyperLink {
  if (row.master) {
    return {path: ['/home', 'masters', 'master'], params: {id: row.master.id}};
  }
  return null;
};
