<oms-base-page [title]="'Order List'" subtitle="Order List" [showHeader]="false">
  <ng-container page-body>
    <div class="widget widget-table">
      <nav #toolbar class="navbar navbar-default">
        <div class="container-fluid no-left-right-padding" #headerContainer>
          <div class="navbar-form navbar-left no-left-right-padding orders-list__header">

            <div class="nav navbar-nav navbar-right">
              <li class="dropdown">
                <a class="dropdown-toggle orders-list__status_link" data-toggle="dropdown" href="#" role="button">
                  <i class="fa fa-download"></i>&nbsp;Download<span class="caret"></span>
                </a>
                <ul class="dropdown-menu" style="width: 185px;">
                  <li><a (click)="downloadExcel()">Excel<i class="pull-right fa fa-file-excel-o"></i></a></li>
                  <li><a (click)="downloadSelectedOrdersExcel()">Excel. only selected<i class="pull-right fa fa-file-excel-o"></i></a></li>
                  <li role="separator" class="divider"></li>
                  <li><a (click)="downloadExcelByFilter('OUTBOUND')">Outbound<i class="pull-right fa fa-file-excel-o"></i></a></li>
                  <li><a (click)="downloadExcelByFilter('AIR_IMPORT')">Air Import<i class="pull-right fa fa-file-excel-o"></i></a></li>
                  <li><a (click)="downloadExcelByFilter('DOM')">DOM<i class="pull-right fa fa-file-excel-o"></i></a></li>
                </ul>
              </li>
            </div>

            <div class="nav navbar-nav navbar-right">
              <div class="btn-toolbar">
                <button class="btn btn-sm btn-default" type="button" style="margin-left: 20px" [routerLink]="['order']" [queryParams]="{new:'generic'}" >
                  <i class="fa fa-plus"></i>New Order&hellip;
                </button>
              </div>
            </div>


            <div class="nav orders-list__modes" *ngIf="isDeleteAvailable">
              <button class="btn btn-sm btn-default" type="button" [disabled]="!selected.length" (click)="deleteOrders()" style="margin-left: 20px">
                <i class="fa fa-trash"></i>Delete
              </button>
            </div>

            <div class="nav orders-list__modes">
              <button type="button" class="btn btn-sm btn-default orders-list__modes__item"
                      *ngFor="let orderMode of orderModeGroups; let index = index"
                      [ngClass]="{'_active': orderMode.isSelected}"
                      [popover]="orderMode.tooltip"
                      (click)="clickOrderMode(orderMode)">
                <span>{{orderMode.name}}</span>
              </button>
            </div>

            <div class="nav orders-list__filters">
              <button type="button" class="btn btn-sm btn-default orders-list__filters__item"
                      *ngFor="let statusFilter of statusFilters; let index = index"
                      [ngClass]="{'_active': statusFilter.isSelected}"
                      [popover]="statusFilter.tooltip"
                      (click)="clickStatusFilter(statusFilter)">
                <span>{{statusFilter.name}}</span>
              </button>
            </div>

            <div class="nav orders-list__filters">
              <ng-container *ngFor="let displayMasterStatus of displayStatuses">
                <button type="button" class="btn btn-sm btn-default orders-list__modes__item"
                        [ngClass]="{'_active': isSelectedMasterStatus(displayMasterStatus),
                        '_disabled': !availableStatuses.includes(displayMasterStatus)}"
                        [disabled]="!availableStatuses.includes(displayMasterStatus)"
                        [popover]="displayMasterStatus.label"
                        (click)="toggleStatusWithDelay(displayMasterStatus)">
                  <span>{{displayMasterStatus.name}}</span>
                </button>
              </ng-container>
            </div>
          </div>

          <div class="navbar-form navbar-left no-left-right-padding orders-list__header">

            <a class="btn btn-link" (click)="refresh();"><i class="fa fa-refresh" ></i></a>

            <a class="btn btn-link font-bold fa-filter-remove no-left-right-padding" [ngClass]="{'active': isTableFilterNotClear()}" (click)="clearAllColumns();">
              <i class="fa fa-filter"></i>
              <i class="fa fa-remove"></i>
            </a>

            <div class="form-group">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control"
                       [ngModel]="getSearchText()"
                       (ngModelChange)="setSearchText($event)"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="Search"/>
                <div class="input-group-btn">
                  <button type="submit" class="btn btn-default" (click)="applySearchText()"><i class="glyphicon glyphicon-search"></i></button>
                  <button class="btn btn-clean btn-clear-input" *ngIf="getSearchText()" (click)="setSearchText('');applySearchText()" style="right: 40px;"><i class="fa fa-times"></i></button>
                </div>
              </div>
            </div>


            <div class="input-group input-group-sm" style="width: 150px; margin-right: 15px;">
              <oms-driver-search [multiple]="true" [size]="Size.SMALL"
                                 [(value)]="search.drivers" (searchChange)="applySearchText()">
              </oms-driver-search>
            </div>

            <div class="orders-list__filters">
              <i class="fa fa-arrow-circle-o-left orders-list__filters__arrows" *ngIf="userVisibilityFilters.length > MAX_DISPLAY_ITEMS" (click)="prevStep()"></i>
              <button type="button" class="btn btn-sm btn-default orders-list__filters__item"
                      *ngFor="let userVisibilityFilter of userVisibilityFilters; let index = index"
                      [hidden]="(startVisibleItem > index) || (startVisibleItem + MAX_DISPLAY_ITEMS <= index)"
                      [ngClass]="{'_active': isSelectedUserVisibilityFilter(userVisibilityFilter)}"
                      [popover]="userVisibilityFilter.name"
                      (click)="applyUserVisibilityFilter(userVisibilityFilter)">
                <span>{{userVisibilityFilter.name}}</span>
              </button>
              <i class="fa fa-arrow-circle-o-right orders-list__filters__arrows" *ngIf="userVisibilityFilters.length > MAX_DISPLAY_ITEMS" (click)="nextStep()"></i>
            </div>
          </div>
        </div>
      </nav>

      <div #content class="widget-content">
        <oms-data-table #table
                        [striped]="true"
                        [condensed]="true"
                        [selectable]="true"
                        [searchable]="true"
                        [multi-select]="true"
                        [checkboxes]="true"
                        [fixed]="false"
                        [keep-selected]="true"
                        [sortBySelected]="'asc'"
                        [fixed-columns]="6"
                        [columns]="columns"
                        [defaultColumnForSort]="defaultSortColumn"
                        [data]="items"
                        [selected]="selected"
                        (searchCriteriaChanged)="onSearch($event)"
                        (update)="onUpdate($event)"
                        [height]="content.offsetHeight - toolbar.offsetHeight"
                        [menu]="contextMenu"
                        [select-by-row]="false"
                        [paging]="paging">
        </oms-data-table>

        <context-menu #menu>

          <ng-template contextMenuItem let-item>
            {{selected.length}} selected
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openOrders($event.item.row)">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Open in new tab
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openMultipleOrders($event.item.row)">
            <i class="fa fa-plus" style="margin-right: 15px;"></i>Send to Multiple Orders
          </ng-template>

          <ng-template contextMenuItem divider="true"></ng-template>

          <ng-template contextMenuItem let-item (execute)="changeDeliveryDate($event.event)" [enabled]="selected.length">
            <i class="fa fa-history" style="margin-right: 15px;"></i>Delivery date
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openCarrierEditor()" [enabled]="selected.length">
            <i class="fa fa-refresh" style="margin-right: 15px;"></i>Carrier
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openCustomerEditor()" [enabled]="selected.length">
            <i class="fa fa-refresh" style="margin-right: 15px;"></i>Customer
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openChangeRefEditor()" [enabled]="selected.length">
            <i class="fa fa-refresh" style="margin-right: 15px;"></i>Change Ref
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openChangeRef2Editor()" [enabled]="isSelectedOnlyNoAI()">
            <span [popover]="isSelectedOnlyNoAI()?null:'Not allowed for AI orders'">
              <i class="fa fa-refresh" style="margin-right: 15px;"></i>Change Ref2
            </span>
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openChangeRef3Editor()" [enabled]="isSelectedOnlyNoAI()">
            <span [popover]="isSelectedOnlyNoAI()?null:'Not allowed for AI orders'">
              <i class="fa fa-refresh" style="margin-right: 15px;"></i>Change Ref3
            </span>
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openChangeDescriptionOfGoods()" [enabled]="selected.length">
            <i class="fa fa-refresh" style="margin-right: 15px;"></i>Change Description of goods
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="recalculateStatus()" [enabled]="selected.length">
            <i class="fa fa-refresh" style="margin-right: 15px;"></i>Recalculate Status
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openDocumentUploader()" [enabled]="selected.length">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Document upload
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="openCommentCenter()" [enabled]="selected.length">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Add comment
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="generateBOL(false)" [enabled]="selected.length">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Create BOL
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="printBOL()" [enabled]="selected.length">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Print BOL
          </ng-template>

          <ng-template contextMenuItem let-item (execute)="generateBOL(true)" [enabled]="selected.length === 1">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Create and Print
          </ng-template>

          <ng-template contextMenuItem (execute)="splitOrder()" [enabled]="selected.length === 1">
            <i class="fa fa-clone"></i><span>Split&hellip;</span>
          </ng-template>

          <ng-template contextMenuItem divider="true"></ng-template>

          <ng-template contextMenuItem let-item (execute)="addFlag()" [enabled]="selected.length">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Notifications
          </ng-template>
          <ng-template contextMenuItem let-item (execute)="removeFlag()" [enabled]="selected.length">
            <i class="fas fa-external-link-alt" style="margin-right: 15px;"></i>Clear Notifications
          </ng-template>

<!--          <ng-template contextMenuItem divider="true"></ng-template>-->

        </context-menu>

      </div>

    </div>
  </ng-container>

</oms-base-page>
