import {Injectable} from '@angular/core';
import {RestService} from "../rest.service";
import {BaseEntityService} from "../base/base-entity.service";
import {OrderNotification} from "../../modules/shared/models/order/order-notification";
import {HttpUtilsService} from "../http-utils.service";
import {Observable} from "rxjs";
import {plainToClass} from "class-transformer";
import {map} from "rxjs/operators";

@Injectable()
export class OrderNotificationService extends BaseEntityService<OrderNotification> {

  public apiUrl = '/oms/order-notifications/';
  public classType = OrderNotification;

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  getUnreadOrderNotifications(): Observable<OrderNotification[]> {
    const params = this.httpUtils.getHTTPParams({});
    return this.httpRestService.getWithOptions<OrderNotification[]>(this.apiUrl + 'get-unread', this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((items) => this.prepareItems(items)));
  }

  readOrderNotification(orderNotificationId: number): Observable<any> {
    return this.httpRestService.post(this.apiUrl + 'read', null, {params: {orderNotificationIds: [orderNotificationId]}});
  }

  addComment(orderNotificationId: number, comment: string): Observable<void> {
    return this.httpRestService.post(this.apiUrl + orderNotificationId + '/comment', comment);
  }

  moveToEnd(orderNotificationId: number): Observable<OrderNotification[]> {
    return this.httpRestService.post<OrderNotification[]>(this.apiUrl + orderNotificationId + '/move-to-end')
      .pipe(map((items) => this.prepareItems(items)));
  }

  private prepareItems(items: OrderNotification[]): OrderNotification[] {
    return plainToClass(OrderNotification, items as any[]).sort((i1, i2) => i1.orderNumber - i2.orderNumber);
  }
}
