import {BaseDeletable} from './base-deletable';
import {Exclude, Type} from 'class-transformer';
import {Labeled} from "../../../common/oms-types";

export class DriverInsurance extends BaseDeletable implements Labeled {

  number: string;
  carrier: string;
  @Type(() => Date)
  expirationDate: Date;

  @Exclude()
  get label(): string {
    return this.number;
  }

}
