
    <div>
      <label *ngIf="label" [attr.for]="identifier">{{label}}</label>
      <select
          [(ngModel)]="value"
          [ngClass]="{invalid: (invalidPublic | async)}"
          [id]="identifier">
        <option value="" disabled selected *ngIf="placeholder">{{placeholder}}</option>
        <ng-content></ng-content>
      </select>
      <validation
        [@flyInOut]="'in,out'"
        *ngIf="invalidPublic | async"
        [messages]="failures | async">
      </validation>
    </div>
  