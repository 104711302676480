<ng-template [ngIf]="editable" [ngIfElse]="textStatic" *ngIf="!isHidden">
  <!-- STATIC MODE -->
  <ng-template [ngIf]="!editing" [ngIfElse]="inEdit" *ngIf="!isHidden">
    <a (contextmenu)="onRightClick()" class="no-wrap data-table-cell" [popover]="tooltip"
       container="body" [class]="cellClass" [ngClass]="{'editable editable-click':editable, 'editable-empty':empty}"
       (focus)="onFocusColumn(cell, $event)">
      <i [class]="iconClass"></i>
      <span (click)="editColumnValue(cell, $event)" innerHTML="{{display | highlight : search : rawValue}}">
      </span>&nbsp;&nbsp;
      <span (click)="openDialog()" [ngClass]="{'mawb-table-cell__not-empty-order': rowObject.cbpStatusCount}">({{rowObject.orders.length}})</span>
    </a>
    <a [attr.href]="cellButtonLink"
       target="_blank"
       style="float: right; padding-right: 10px;"
       [popover]="cellButtonTooltip"
       [container]="'body'">
      <i [attr.class]="cellButtonIcon"></i>
    </a>
  </ng-template>

  <!-- EDIT MODE -->
  <ng-template #inEdit>
    <!-- TEXT COLUMN EDIT MODE -->

    <div (contextmenu)="onRightClick()" class="input-group input-group-sm input-group-inplace"
         (dblclick)="onDoubleClick($event);" style="width: 100%">
      <input #textInput type="text" class="form-control" (blur)="onBlurEditor()" title=""
             [ngClass]="{'form-control-invalid':error}"
             data-toggle="dropdown" aria-expanded="true"
             [attr.readonly]=""
             [placeholder]="placeholder"
             [textMask]="editor?.mask || {mask:false}" [popover]="error" [container]="'body'"
             [(ngModel)]="localValue" (keydown)="onKeyDown($event)">

      <span class="input-group-btn not_inside">
        <button class="btn btn-clean" (click)="onClear()" *ngIf="editor.cleanable"><i class="fa fa-times"></i></button>
      </span>

    </div>

  </ng-template>
</ng-template>


<!-- STATIC TEXT (NON-CLICKABLE) -->
<ng-template #textStatic>
  <span [popover]="tooltip" container="body" (contextmenu)="onRightClick()" class="no-wrap data-table-cell"
        [ngClass]="{'editable-empty':editable && empty}" [class]="cellClass">
    <i [attr.class]="iconClass" *ngIf="hasIcon"></i>
    <span [innerHTML]="value | highlight : search : rawValue"></span>
  </span>

  <a *ngIf="showTracking" [attr.href]="cellButtonLink"
     target="_blank"
     style="float: right; padding-right: 10px;"
     [popover]="cellButtonTooltip"
     [container]="'body'">
    <i [attr.class]="cellButtonIcon"></i>
  </a>
</ng-template>
