﻿import {ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Address, AddressType } from '../../models';
import {CountryService} from '../../services/country.service';
import {CityService} from '../../services/city.service';
import {AddressService} from '../../../../services/index';
import {UsaStateService} from '../../services/usa.state.service';
import {OmsAlertsService} from '../oms-alerts/oms-alerts.service';
import {Country} from '../../models/address/country';
import {MapsAPILoader} from '@agm/core';
import {google} from 'google-maps';
import {isNullOrUndefined} from 'util';

@Component({
  templateUrl: 'address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
  selector: 'oms-address-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddressDialogComponent implements OnInit {
  AddressType = AddressType;
  address: Address = new Address();
  edit = false;
  isUsa: boolean;

  form: FormGroup;

  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  disabledTypes: AddressType[];

  addressInput: string;
  private geoCoder;

  @ViewChild('addressAutocompleteSearch')
  public searchElementRef: ElementRef;

  constructor(public dialogRef: MatDialogRef<AddressDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private addressService: AddressService,
              public countryService: CountryService,
              public cityService: CityService,
              public usaStateService: UsaStateService,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              public alerts: OmsAlertsService) {
  }

  ngOnInit() {
    this.viewLoading = true;

    this.address = Object.assign(this.address, this.data.address);
    this.disabledTypes = this.data.disabledTypes;
    this.edit = !this.address.isNew();
    this.isUsa = this.address.isUsa;

    this.createForm();
    this.setValidators();

    if (this.address.isNew()) {
      this.countryService.getByIso2Code(Country.USA_CODE).subscribe(country => {
        this.cityService.getAllCitiesByCountryId(country.id).subscribe();
        this.form.controls['country'].setValue(country);
      });
    }

    this.countryService.getAllCountries();
    this.usaStateService.getUsaStates();

    this.viewLoading = false;

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.convertGmapsResponse(place);
        });
      });
    });
  }

  convertGmapsResponse(result: google.maps.places.PlaceResult) {
    const controls = this.form.controls;
    console.log('here', result);
    let omsAddress: any = {};
    let addressComponenst = result.address_components;
    addressComponenst.forEach(addressComponent => {
      if (addressComponent.types.indexOf('postal_code') > -1) {
        omsAddress.postCode = addressComponent.short_name;
      } else if (addressComponent.types.indexOf('country') > -1) {
        omsAddress.country = addressComponent.short_name;
      } else if (addressComponent.types.indexOf('administrative_area_level_1') > -1) {
        omsAddress.state = addressComponent.short_name;
      } else if (addressComponent.types.indexOf('locality') > -1 || addressComponent.types.indexOf('neighborhood') > -1 || addressComponent.types.indexOf('sublocality') > -1) {
        omsAddress.city = addressComponent.short_name;
      } else if (addressComponent.types.indexOf('street_number') > -1) {
        omsAddress.streetNumber = addressComponent.short_name;
      } else if (addressComponent.types.indexOf('route') > -1) {
        omsAddress.street = addressComponent.short_name;
      }
    });

    let addressLines = this.createAddressLines(omsAddress.streetNumber, omsAddress.street);

    controls['postCode'].setValue(omsAddress.postCode);
    controls['city'].setValue(omsAddress.city);
    controls['address'].setValue(addressLines);

    this.countryService.getByIso2Code(omsAddress.country).subscribe(country => {
      this.form.controls['country'].setValue(country);
    });

    if (omsAddress.country === Country.USA_CODE) {
      this.usaStateService.getStateByUspsCode(omsAddress.state).subscribe(state => {
        this.form.controls['usaState'].setValue(state);
      });
    } else {
      this.form.controls['province'].setValue(omsAddress.state);
    }
  }

  createAddressLines(streetNumber, street) {
    if (isNullOrUndefined(streetNumber) && isNullOrUndefined(street)) {
      return '';
    } else if (isNullOrUndefined(streetNumber)) {
      return street;
    } else {
      return streetNumber + ' ' + street;
    }
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.address.name, Validators.required],
      country: [{value: this.address.country, disabled: false}, Validators.required],
      city: [{value: this.address.city, disabled: false}, Validators.required],
      address: [this.address.addressLines, Validators.required],
      lat: [this.address.lat, Validators.pattern("^(\\+|-)?(?:90(?:(?:\\.0{1,9})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,9})?))$")],
      lon: [this.address.lon, Validators.pattern("^(\\+|-)?(?:180(?:(?:\\.0{1,9})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,9})?))$")],
      gmapsSearch: [!this.edit],
      showAs: [this.address.showAs],
      postCode: [{value: this.address.postCode, disabled: false}, Validators.required],
      province: [{value: this.address.province, disabled: false}],
      usaState: [{value: this.address.usaState, disabled: false}, this.isUsa ? Validators.required : null]
    });
  }

  private setValidators() {
    const stateControl = this.form.controls['usaState'];
    const provinceControl = this.form.controls['province'];
    console.log(stateControl, provinceControl);


    this.form.get('country').valueChanges.subscribe(country => {
      if (country && country.isUsa) {
        this.isUsa = true;
        stateControl.setValidators([Validators.required]);
        provinceControl.setValidators(null);
      } else {
        this.isUsa = false;
        stateControl.setValidators(null);
//        provinceControl.setValidators([Validators.required]); no need validation for Province
      }

      stateControl.updateValueAndValidity();
      provinceControl.updateValueAndValidity();
    });
  }

  getTitle(): string {
    return this.address.isNew() ? 'New Address' : 'Address';
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && control.touched;
  }

  get isGmapsSearch(): boolean {
    /*const control = this.form.controls["gmapsSearch"];
    return control.value;*/
    return false;
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;
    const controls = this.form.controls;

    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      });
      this.hasFormErrors = true;
      return;
    }

    const address = this.prepare();
    this.save(address);
  }

  prepare(): Address {
    const controls = this.form.controls;
    const _address = this.address;
    _address.name = controls['name'].value;
    _address.country = controls['country'].value;
    _address.city = controls['city'].value;
    _address.addressLines = controls['address'].value;
    _address.lat = controls['lat'].value;
    _address.lon = controls['lon'].value;
    _address.postCode = controls['postCode'].value;
    _address.province = controls['province'].value;
    _address.usaState = controls['usaState'].value;
    _address.showAs = controls['showAs'].value;
    _address.cfsLocation = this.address.cfsLocation;
    _address.cargoBuilding = this.address.cargoBuilding;
    _address.pickupLocation = this.address.pickupLocation;
    _address.deliveryLocation = this.address.deliveryLocation;
    _address.whseBuilding = this.address.whseBuilding;
    _address.homeBase = this.address.homeBase;

    return _address;
  }

  private save(address: Address) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.addressService.save(address).subscribe(res => {
      this.viewLoading = false;
      this.dialogRef.close({res, isEdit: !address.isNew()});
    }, error => {
      this.viewLoading = false;
      this.alerts.danger(error);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isDisabledType(addressType: AddressType) {
    return false;
//    return this.disabledTypes && this.disabledTypes.hasEquals(addressType);
  }

  changeAddressSearchType(isGmaps: boolean) {
    /*    let disable: boolean = this.isGmapsSearch;
        if(disable){
          this.form.controls["country"].disable();
          this.form.controls["city"].disable();
          this.form.controls["postCode"].disable();
          this.form.controls["province"].disable();
          this.form.controls["usaState"].disable();
        } else {
          this.form.controls["country"].enable();
          this.form.controls["city"].enable();
          this.form.controls["postCode"].enable();
          this.form.controls["province"].enable();
          this.form.controls["usaState"].enable();
        }*/
  }

  countryChanged() {
    let country = this.form.controls['country'].value;
    this.cityService.getAllCitiesByCountryId(country.id).subscribe();
  }

}
