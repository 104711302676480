﻿import {ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OmsAlertsService} from '../oms-alerts/oms-alerts.service';
import {DriverNewService} from "../../../../services/driver-new-service";
import {ChatService} from "../../../../services/chat-service";
import {Driver} from "../../models";
import {ChatRecord} from "../../models/chat.record";

declare const microlink;


@Component({
  templateUrl: 'chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  selector: 'oms-chat-dialog'
})

export class ChatDialogComponent implements OnInit {

  @ViewChild('chatSession') private myScrollContainer: ElementRef;

  form: FormGroup;
  _message: string = '';

  hasFormErrors = false;
  chatRecords: ChatRecord[] = [];
  drivers = [];
  currentUser: any;
  currentDriver;
  showEmojiPicker = false;

  constructor(public dialogRef: MatDialogRef<ChatDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private driverService: DriverNewService,
              private chatService: ChatService,
              public alerts: OmsAlertsService) {
  }

  ngOnInit() {
    this.driverService.findAll().then(drivers => {
      this.drivers = drivers;
      this.currentDriver = drivers[0];

      this.chatService.findAllByDriverId(this.currentDriver.id).then(chatRecords => {
        this.chatRecords = chatRecords;
        this.scrollToBottom();
      });
    });
  }

  ngAfterViewChecked() {
    microlink('.link-preview');
  }

  changeCurrentDriver(driver: Driver) {
    this.currentDriver = driver;
    this.chatService.findAllByDriverId(this.currentDriver.id).then(chatRecords => {
      this.chatRecords = chatRecords;
      this.scrollToBottom();
    });
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    const { message } = this;
    const text = `${message}${event.emoji.native}`;

    this.message = text;
    this.showEmojiPicker = false;
  }

  sendMessage() {
    let chatRecord = new ChatRecord();
    chatRecord.driver = this.currentDriver;
    chatRecord.text = this.message;
    chatRecord.jjsUserAcknowledge = new Date;
    this.chatService.save(chatRecord).subscribe();
    this.message = '';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isCurrentDriver(driver: Driver) {
    return this.currentDriver.id === driver.id;
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {

    }
  }
}
