var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { AbstractComponent } from "../../../../../common/component/abstract.component";
import { NgSelectSearchParams } from "../../../../settings/util/ng-select-search-params";
import { OmsAlertsService } from "../../../../shared/components/oms-alerts/oms-alerts.service";
var SelectEntityDialogComponent = /** @class */ (function (_super) {
    __extends(SelectEntityDialogComponent, _super);
    function SelectEntityDialogComponent(dialogRef, data, fb, alerts) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.fb = fb;
        _this.alerts = alerts;
        _this.label = "";
        return _this;
    }
    SelectEntityDialogComponent.prototype.ngOnInit = function () {
    };
    SelectEntityDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    SelectEntityDialogComponent.prototype.onSave = function () {
        this.dialogRef.close(this.entity);
    };
    return SelectEntityDialogComponent;
}(AbstractComponent));
export { SelectEntityDialogComponent };
