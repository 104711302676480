﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget"
       [ngClass]="{ '_loading' : viewLoading, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">

    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-map-marker"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
          <!--i class="fa fa-close sb-popup__head-close-icon"></i-->
        </a>
      </div>
    </div>

    <form class="widget-content" [formGroup]="form">

      <div class="row">
        <div class="form-row col-md-6">
          <div class="form-group">
            <label class="control-label">Filter Name</label>
            <input type="text" class="form-control" placeholder="Name" formControlName="name"
                   [ngClass]="{'form-control-invalid':isControlInvalid('name')}"/>
            <div *ngIf="isControlInvalid('name')" class="invalid-feedback">Name is mandatory</div>
          </div>
        </div>
        <div class="form-row col-md-6">
          <div class="form-group">
            <label class="control-label">Field</label>
            <ng-select [clearable]="true"
                       class="form-control input-sm inplace"
                       [items]="columnsSettings"
                       (change)="addFilter($event)"
                       appendTo="body">
              <ng-template ng-label-tmp let-item="item">
                <div>{{item.label}}</div>
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                <div>{{item.label}}</div>
              </ng-template>

            </ng-select>
          </div>
        </div>
      </div>

      <div class="form-row">
        <table class="table table-bordered table-hover table condensed">
          <thead>
          <th><a>Field</a></th>
          <th><a>Criterias</a></th>
          <th><a>Remove</a></th>
          </thead>
          <tbody>
          <tr *ngFor="let filter of this.filterContainer.filters" (click)="selectFilter(filter)">
            <td>{{filter.label}}</td>
            <td>{{filter.filterCriterias.length}}</td>
            <td (click)="deleteFilter(filter)">X</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="row" *ngIf="selectedFilter && column">

        <div class="criteria-filter">
          <div class="criteria-left">
            <ng-select #selectSearches [clearable]="true" *ngIf="column.search.searchable && (column.search.searchType != null && column.search.searchType == 'list')"
                       class="form-control input-sm inplace"
                       bindValue="id"
                       (change)="listValueChanged($event)"
                       [items]="column.search.items"
                       appendTo="body"
                       style="position: relative;">
            </ng-select>
          </div>

          <div class="criteria-right">
            <oms-checkbox label="Is Include" (checked)="listConditionChanged($event)"></oms-checkbox>

            <div class="input-group-btn">
              <button class="btn btn-primary" (click)="addListFilterCriteriaValue()">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="criteria-content">
          <table class="table table-hover table condensed" *ngIf="selectedFilter.filterCriterias && selectedFilter.filterCriterias.length > 0">
            <thead>
            <tr>
              <th><a>ID</a></th>
              <th><a>Label</a></th>
              <th><a>Condition</a></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let criteria of selectedFilter.filterCriterias">
              <td>{{criteria.value.id}}</td>
              <td>{{criteria.value.label}}</td>
              <td>{{criteria.condition}}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>



    </form>

    <div class="widget-footer right">
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="viewLoading">OK</button>
      <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
    </div>

  </div>
</perfect-scrollbar>
