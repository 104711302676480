import {BaseEntity} from "../base-entity";
import {RowObject, TrackByObject} from "../../components/base/data-table/columns/column-types";
import {Comparable} from "../../../../common/oms-types";
import {Transform, Type} from "class-transformer";
import {Order} from "../order";
import {User} from "../user";
import {MasterStatus, transformMasterStatus} from "..";
import {OrderSubscriptionStatus} from "../../../../services/order-subscription/order-subscription-status";

export class OrderSubscription extends BaseEntity implements RowObject, Comparable<OrderSubscription>, TrackByObject {
  public static readonly ROW_ID = 'OrderSubscription';
  rowId = OrderSubscription.ROW_ID;

  id: number;
  @Type(() => Order)
  order: Order;
  @Type(() => User)
  user: User;
  customEmail: string;
  @Type(() => MasterStatus)
  @Transform((v, o, tt) => v.map(item => transformMasterStatus(item, o, tt)))
  statuses: MasterStatus[];
  additionalStatuses: OrderSubscriptionStatus[] = [];
  comment: string;

  trackBy(): any {
    return this.id;
  }

}
