<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget" style="width: 400px"
       [ngClass]="{ '_loading' : viewLoading, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">

    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-map-marker"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
          <!--i class="fa fa-close sb-popup__head-close-icon"></i-->
        </a>
      </div>
    </div>

    <form class="widget-content" [formGroup]="form" style="min-height: 200px;">
      <div class="form-row">
        <div class="form-row col-md-12">
          <div class="form-group">
            <label class="control-label">Carrier name</label>
            <input type="text"
                   class="form-control"
                   placeholder="Name"
                   [maxLength]="50"
                   formControlName="name"
                   [ngClass]="{'form-control-invalid':isControlInvalid('name')}"/>
            <div *ngIf="!!getControlErrorMessage('name', 'Name')" class="invalid-feedback">{{getControlErrorMessage('name', 'Name')}}</div>
          </div>
        </div>

        <div class="form-row col-md-12">
          <div class="form-group">
            <label class="control-label">SCAC Code</label>
            <input type="text"
                   class="form-control"
                   placeholder="Name"
                   [maxLength]="50"
                   formControlName="scacCode"
                   [ngClass]="{'form-control-invalid':isControlInvalid('scacCode')}"/>
            <div *ngIf="!!getControlErrorMessage('scacCode', 'SCAC Code')" class="invalid-feedback">{{getControlErrorMessage('scacCode', 'SCAC Code')}}</div>
          </div>
        </div>
        <div class="form-row col-md-12" *ngIf="displayInHouse">
          <div class="form-input">

            <label class="fancy-checkbox _green">
              <input type="checkbox" formControlName="inHouse">
              <span>In-house</span>
            </label>
          </div>
        </div>
      </div>

    </form>

    <div class="widget-footer">
      <div></div>
      <div class="dialog-btns">
        <button type="button" class="btn btn-primary" (click)="onSubmit()">OK</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div>

  </div>
</perfect-scrollbar>
