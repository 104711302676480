import {Driver} from './driver';
import {Trailer} from './trailer';
import {Truck} from './truck';

export class OrdersDeliveryManifestRequest {
  constructor(
    public orderIds: number[],
    public driver?: Driver,
    public truck?: Truck,
    public trailer?: Trailer) {
  }
}


export class MasterManifestExcelRequest {
  masterIds: number[];
  driverName: string;
  truckNumber: string;
  trailerNumber: string;
  findDriver: boolean;
  dateDispatched: Date;


  constructor(masterIds: number[], driverName: string, truckNumber: string, trailerNumber: string, dateDispatched: Date, findDriver: boolean) {
    this.masterIds = masterIds;
    this.driverName = driverName;
    this.truckNumber = truckNumber;
    this.trailerNumber = trailerNumber;
    this.dateDispatched = dateDispatched;
    this.findDriver = findDriver;
  }
}
