import { ElementRef, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { Address, AddressType } from '../../models';
import { CountryService } from '../../services/country.service';
import { CityService } from '../../services/city.service';
import { AddressService } from '../../../../services/index';
import { UsaStateService } from '../../services/usa.state.service';
import { OmsAlertsService } from '../oms-alerts/oms-alerts.service';
import { Country } from '../../models/address/country';
import { MapsAPILoader } from '@agm/core';
import { isNullOrUndefined } from 'util';
var AddressDialogComponent = /** @class */ (function () {
    function AddressDialogComponent(dialogRef, data, fb, addressService, countryService, cityService, usaStateService, mapsAPILoader, ngZone, alerts) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.addressService = addressService;
        this.countryService = countryService;
        this.cityService = cityService;
        this.usaStateService = usaStateService;
        this.mapsAPILoader = mapsAPILoader;
        this.ngZone = ngZone;
        this.alerts = alerts;
        this.AddressType = AddressType;
        this.address = new Address();
        this.edit = false;
        this.hasFormErrors = false;
        this.viewLoading = false;
        this.loadingAfterSubmit = false;
    }
    AddressDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.viewLoading = true;
        this.address = Object.assign(this.address, this.data.address);
        this.disabledTypes = this.data.disabledTypes;
        this.edit = !this.address.isNew();
        this.isUsa = this.address.isUsa;
        this.createForm();
        this.setValidators();
        if (this.address.isNew()) {
            this.countryService.getByIso2Code(Country.USA_CODE).subscribe(function (country) {
                _this.cityService.getAllCitiesByCountryId(country.id).subscribe();
                _this.form.controls['country'].setValue(country);
            });
        }
        this.countryService.getAllCountries();
        this.usaStateService.getUsaStates();
        this.viewLoading = false;
        // load Places Autocomplete
        this.mapsAPILoader.load().then(function () {
            _this.geoCoder = new google.maps.Geocoder;
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
            autocomplete.addListener('place_changed', function () {
                _this.ngZone.run(function () {
                    // get the place result
                    var place = autocomplete.getPlace();
                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    _this.convertGmapsResponse(place);
                });
            });
        });
    };
    AddressDialogComponent.prototype.convertGmapsResponse = function (result) {
        var _this = this;
        var controls = this.form.controls;
        console.log('here', result);
        var omsAddress = {};
        var addressComponenst = result.address_components;
        addressComponenst.forEach(function (addressComponent) {
            if (addressComponent.types.indexOf('postal_code') > -1) {
                omsAddress.postCode = addressComponent.short_name;
            }
            else if (addressComponent.types.indexOf('country') > -1) {
                omsAddress.country = addressComponent.short_name;
            }
            else if (addressComponent.types.indexOf('administrative_area_level_1') > -1) {
                omsAddress.state = addressComponent.short_name;
            }
            else if (addressComponent.types.indexOf('locality') > -1 || addressComponent.types.indexOf('neighborhood') > -1 || addressComponent.types.indexOf('sublocality') > -1) {
                omsAddress.city = addressComponent.short_name;
            }
            else if (addressComponent.types.indexOf('street_number') > -1) {
                omsAddress.streetNumber = addressComponent.short_name;
            }
            else if (addressComponent.types.indexOf('route') > -1) {
                omsAddress.street = addressComponent.short_name;
            }
        });
        var addressLines = this.createAddressLines(omsAddress.streetNumber, omsAddress.street);
        controls['postCode'].setValue(omsAddress.postCode);
        controls['city'].setValue(omsAddress.city);
        controls['address'].setValue(addressLines);
        this.countryService.getByIso2Code(omsAddress.country).subscribe(function (country) {
            _this.form.controls['country'].setValue(country);
        });
        if (omsAddress.country === Country.USA_CODE) {
            this.usaStateService.getStateByUspsCode(omsAddress.state).subscribe(function (state) {
                _this.form.controls['usaState'].setValue(state);
            });
        }
        else {
            this.form.controls['province'].setValue(omsAddress.state);
        }
    };
    AddressDialogComponent.prototype.createAddressLines = function (streetNumber, street) {
        if (isNullOrUndefined(streetNumber) && isNullOrUndefined(street)) {
            return '';
        }
        else if (isNullOrUndefined(streetNumber)) {
            return street;
        }
        else {
            return streetNumber + ' ' + street;
        }
    };
    AddressDialogComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            name: [this.address.name, Validators.required],
            country: [{ value: this.address.country, disabled: false }, Validators.required],
            city: [{ value: this.address.city, disabled: false }, Validators.required],
            address: [this.address.addressLines, Validators.required],
            lat: [this.address.lat, Validators.pattern("^(\\+|-)?(?:90(?:(?:\\.0{1,9})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,9})?))$")],
            lon: [this.address.lon, Validators.pattern("^(\\+|-)?(?:180(?:(?:\\.0{1,9})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,9})?))$")],
            gmapsSearch: [!this.edit],
            showAs: [this.address.showAs],
            postCode: [{ value: this.address.postCode, disabled: false }, Validators.required],
            province: [{ value: this.address.province, disabled: false }],
            usaState: [{ value: this.address.usaState, disabled: false }, this.isUsa ? Validators.required : null]
        });
    };
    AddressDialogComponent.prototype.setValidators = function () {
        var _this = this;
        var stateControl = this.form.controls['usaState'];
        var provinceControl = this.form.controls['province'];
        console.log(stateControl, provinceControl);
        this.form.get('country').valueChanges.subscribe(function (country) {
            if (country && country.isUsa) {
                _this.isUsa = true;
                stateControl.setValidators([Validators.required]);
                provinceControl.setValidators(null);
            }
            else {
                _this.isUsa = false;
                stateControl.setValidators(null);
                //        provinceControl.setValidators([Validators.required]); no need validation for Province
            }
            stateControl.updateValueAndValidity();
            provinceControl.updateValueAndValidity();
        });
    };
    AddressDialogComponent.prototype.getTitle = function () {
        return this.address.isNew() ? 'New Address' : 'Address';
    };
    AddressDialogComponent.prototype.isControlInvalid = function (controlName) {
        var control = this.form.controls[controlName];
        return control.invalid && control.touched;
    };
    Object.defineProperty(AddressDialogComponent.prototype, "isGmapsSearch", {
        get: function () {
            /*const control = this.form.controls["gmapsSearch"];
            return control.value;*/
            return false;
        },
        enumerable: true,
        configurable: true
    });
    AddressDialogComponent.prototype.onSubmit = function () {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        var controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(function (controlName) {
                controls[controlName].markAsTouched();
            });
            this.hasFormErrors = true;
            return;
        }
        var address = this.prepare();
        this.save(address);
    };
    AddressDialogComponent.prototype.prepare = function () {
        var controls = this.form.controls;
        var _address = this.address;
        _address.name = controls['name'].value;
        _address.country = controls['country'].value;
        _address.city = controls['city'].value;
        _address.addressLines = controls['address'].value;
        _address.lat = controls['lat'].value;
        _address.lon = controls['lon'].value;
        _address.postCode = controls['postCode'].value;
        _address.province = controls['province'].value;
        _address.usaState = controls['usaState'].value;
        _address.showAs = controls['showAs'].value;
        _address.cfsLocation = this.address.cfsLocation;
        _address.cargoBuilding = this.address.cargoBuilding;
        _address.pickupLocation = this.address.pickupLocation;
        _address.deliveryLocation = this.address.deliveryLocation;
        _address.whseBuilding = this.address.whseBuilding;
        _address.homeBase = this.address.homeBase;
        return _address;
    };
    AddressDialogComponent.prototype.save = function (address) {
        var _this = this;
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        this.addressService.save(address).subscribe(function (res) {
            _this.viewLoading = false;
            _this.dialogRef.close({ res: res, isEdit: !address.isNew() });
        }, function (error) {
            _this.viewLoading = false;
            _this.alerts.danger(error);
        });
    };
    AddressDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    AddressDialogComponent.prototype.isDisabledType = function (addressType) {
        return false;
        //    return this.disabledTypes && this.disabledTypes.hasEquals(addressType);
    };
    AddressDialogComponent.prototype.changeAddressSearchType = function (isGmaps) {
        /*    let disable: boolean = this.isGmapsSearch;
            if(disable){
              this.form.controls["country"].disable();
              this.form.controls["city"].disable();
              this.form.controls["postCode"].disable();
              this.form.controls["province"].disable();
              this.form.controls["usaState"].disable();
            } else {
              this.form.controls["country"].enable();
              this.form.controls["city"].enable();
              this.form.controls["postCode"].enable();
              this.form.controls["province"].enable();
              this.form.controls["usaState"].enable();
            }*/
    };
    AddressDialogComponent.prototype.countryChanged = function () {
        var country = this.form.controls['country'].value;
        this.cityService.getAllCitiesByCountryId(country.id).subscribe();
    };
    return AddressDialogComponent;
}());
export { AddressDialogComponent };
