import {Exclude, Type} from 'class-transformer';
import {BaseEntity} from './base-entity';

export class BaseDeletable extends BaseEntity {
  @Type(() => Date)
  dateDeleted: Date;

  @Exclude()
  get deleted(): boolean {
    return !!this.dateDeleted;
  }

  public isDeleted(): boolean {
    return !!this.dateDeleted;
  }
}
