import { VERSION } from 'src/environments/version';
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { OmsConstants } from '../../../../../common/oms-constants.service';
import { formatDate } from '@angular/common';
import { AuthService } from "../../../../../services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
var FooterComponent = /** @class */ (function () {
    function FooterComponent(auth) {
        var _this = this;
        this.version = VERSION;
        this.unsubscribe$ = new Subject();
        auth.user$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (u) {
            if (u) {
                _this.environment = u.env;
            }
        });
        var user = auth.user$.value;
        if (user) {
            this.environment = user.env;
        }
    }
    FooterComponent.prototype.getHeight = function () {
        return this.footer.nativeElement.getBoundingClientRect().height;
    };
    FooterComponent.prototype.ngOnInit = function () {
        try {
            this.dateStr = formatDate(new Date(this.version['buildDate'] || this.version.commit['committerDate']), 'dd MMM yyyy h:mm a', OmsConstants.DEFAULT_LOCALE);
        }
        catch (e) {
            this.dateStr = this.version.commit['committerDate'];
        }
    };
    Object.defineProperty(FooterComponent.prototype, "versionName", {
        get: function () {
            if (this.version['branch'] !== 'HEAD') {
                return this.version['branch'];
            }
            return this.version.tag ? this.version.tag : '';
        },
        enumerable: true,
        configurable: true
    });
    FooterComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    Object.defineProperty(FooterComponent.prototype, "resolution", {
        get: function () {
            return window.innerWidth + ' x ' + window.innerHeight;
        },
        enumerable: true,
        configurable: true
    });
    return FooterComponent;
}());
export { FooterComponent };
