﻿import {Component, OnInit} from '@angular/core';
import {ColumnSettings} from "../../../modules/shared/models/column.settings";
import {AccountService} from "../../../services/account.service";


@Component({templateUrl: 'columns-configuration.component.html',
            styleUrls: ['./columns-configuration.component.scss'],
            selector: 'oms-columns-configuration'})
export class ColumnsConfigurationComponent implements OnInit {

  isShown = false;
  originalColumnSettings: ColumnSettings[];
  columnSettings: ColumnSettings[];

  constructor(private accountService: AccountService) {
   this.accountService.hideShowColumnsSubject.subscribe(data => {
     this.isShown = data.show;
     this.originalColumnSettings = data.columnSettings;
     this.columnSettings = this.cloneData(this.originalColumnSettings);
   });
  }

  saveChanges(){
    for(let i = 0; i < this.originalColumnSettings.length; i++){
      let original = this.originalColumnSettings[i];
      let changed = this.columnSettings[i];
      original.visible = changed.visible;
    }
    this.accountService.hideShowHidePopUp();
  }

  cloneData(data: any){
    let cloned = data.map(x => Object.assign({}, x));

    return cloned;
  }

  hidePopUp() {
    this.accountService.hideShowHidePopUp();
  }

  ngOnInit() {}

}
