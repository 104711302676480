var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { displayLoadActionType, getCbpLink, OmsColumns } from "../../../common/oms-columns.service";
import { BaseColumn, FieldType, HyperLinkColumn } from "../../../modules/shared/components/base/data-table/columns/column-types";
import { AbstractEntityListComponent } from "../../../modules/settings/pages/abstract-entity-list.component";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { OmsDialogsService } from "../oms-dialogs";
import { FileUploadService } from '../../../services/file.upload.service';
import { FilterPageRequest } from "../../../modules/shared/models/filter.page.request";
import { SearchSortRequest } from "../../../modules/shared/models/search.sort.request";
import { FilterSearchColumn } from "../../../modules/shared/models/filter.search.column";
import { DispatchAndRecoveryService } from "../../../modules/shared/services/logs/dispatch-and-recovery/dispatch.and.recovery.service";
import { convertManifestNumber, convertOrderNumber, getOrderLink } from "../../../modules/shared/services/oms-converters.service";
var RecoveryAndDispatchLogDialogComponent = /** @class */ (function (_super) {
    __extends(RecoveryAndDispatchLogDialogComponent, _super);
    function RecoveryAndDispatchLogDialogComponent(dialogRef, dialog, cdr, alerts, dialogs, dispatchAndRecoveryService, omsColumns, fileUploadService) {
        var _this = _super.call(this, cdr, alerts, dispatchAndRecoveryService, dialogs, fileUploadService) || this;
        _this.dialogRef = dialogRef;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.dialogs = dialogs;
        _this.dispatchAndRecoveryService = dispatchAndRecoveryService;
        _this.omsColumns = omsColumns;
        _this.fileUploadService = fileUploadService;
        _this.dispatchAndRecoveryLogs = [];
        _this.defaultSortColumn = "id";
        _this.dispatchAndRecoveryService.dispatchAndRecoveryLogSubject.subscribe(function (dispatchAndRecoveryLogs) {
            _this.dispatchAndRecoveryLogs = dispatchAndRecoveryLogs;
        });
        return _this;
    }
    RecoveryAndDispatchLogDialogComponent.prototype.ngOnInit = function () {
        this.columns = this.buildColumns();
        this.omsColumns.getDispatchAndRecoveryLogsColumns();
        switch (this.objectType) {
            case "MASTER":
                this.dispatchAndRecoveryService.findAllByMasterId(this.masterId);
                break;
            case "SPLIT":
                this.dispatchAndRecoveryService.findAllByOrderId(this.orderId, this.shipmentId);
                break;
        }
    };
    RecoveryAndDispatchLogDialogComponent.prototype.getTitle = function () {
        return 'Dispatch and Recovery Logs';
    };
    RecoveryAndDispatchLogDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    RecoveryAndDispatchLogDialogComponent.prototype.generateExcel = function () {
        var searchField = "";
        switch (this.objectType) {
            case "MASTER":
                searchField = "masterId";
                break;
            case "SPLIT":
                searchField = "shipmentId";
                break;
        }
        var filterPageRequest = this.createDefaultRequest(searchField);
        this.request = filterPageRequest;
        this.downloadExcel();
        this.dialogRef.close();
    };
    RecoveryAndDispatchLogDialogComponent.prototype.createDefaultRequest = function (searchField) {
        var id = this.objectType === "MASTER" ? this.masterId : this.shipmentId;
        var filterSearchColumn = new FilterSearchColumn(searchField, id + '', null, null);
        return new FilterPageRequest(1, 999, '', new SearchSortRequest(searchField, true), [filterSearchColumn]);
    };
    RecoveryAndDispatchLogDialogComponent.prototype.buildColumns = function () {
        return [
            new BaseColumn('dateCreated', 'Date/Time', "dateCreated", FieldType.DATETIME, '100px'),
            new BaseColumn('createdBy', 'User Name', "createdBy", FieldType.TEXT, '130px'),
            new HyperLinkColumn('mawbNumber', 'Mawb', "mawbNumber", FieldType.TEXT, '100px', {
                link: getCbpLink,
                sorted: { asc: true }
            }),
            new BaseColumn('dispatchId', 'RT#', "dispatchId", FieldType.TEXT, '100px')
                .setHandlers({
                converter: function (row, id) { return convertManifestNumber(id); }
            }),
            new HyperLinkColumn('orderId', 'RLD Number', 'orderId', FieldType.TEXT, '100px')
                .setHandlers({
                converter: function (row, value) { return convertOrderNumber(value, true); },
                link: function (o) { return getOrderLink(o.orderId); }
            }),
            new BaseColumn('pieces', 'Pieces', 'pieces', FieldType.TEXT, '60px'),
            new BaseColumn('datePickUpActual', 'Pick Up', 'datePickUpActual', FieldType.DATETIME, '100px'),
            new BaseColumn('driver.fullName', 'Driver', 'driver.fullName', FieldType.TEXT, '100px'),
            new BaseColumn('truck.number', 'Truck', 'truck.number', FieldType.TEXT, '100px'),
            new BaseColumn('trailer.number', 'Trailer', 'trailer.number', FieldType.TEXT, '100px'),
            new BaseColumn('action', 'Action', function (item) { return displayLoadActionType(item); }, FieldType.TEXT, '120px'),
        ];
    };
    return RecoveryAndDispatchLogDialogComponent;
}(AbstractEntityListComponent));
export { RecoveryAndDispatchLogDialogComponent };
