var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Exclude, Type } from 'class-transformer';
import { BaseEntity } from './base-entity';
var ɵ0 = function () { return Date; };
var BaseDeletable = /** @class */ (function (_super) {
    __extends(BaseDeletable, _super);
    function BaseDeletable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(BaseDeletable.prototype, "deleted", {
        get: function () {
            return !!this.dateDeleted;
        },
        enumerable: true,
        configurable: true
    });
    BaseDeletable.prototype.isDeleted = function () {
        return !!this.dateDeleted;
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Date)
    ], BaseDeletable.prototype, "dateDeleted", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], BaseDeletable.prototype, "deleted", null);
    return BaseDeletable;
}(BaseEntity));
export { BaseDeletable };
export { ɵ0 };
