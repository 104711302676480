import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {DriverGps} from "../modules/shared/models/driver.gps";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

@Injectable({providedIn: 'root'})
export class DriverGpsService extends BaseEntityService<DriverGps> {
  apiUrl = '/oms/driver-gps/';
  classType = DriverGps;

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  getCurrentLocation(driverId): Observable<DriverGps> {
    return this.httpRestService.get<DriverGps>(this.apiUrl + 'driver/' + driverId)
      .pipe(map(item => plainToClass(this.classType, item)));
  }

}
