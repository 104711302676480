import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Master, RecoveryOrder, Uld, UldRecovery} from '../../../modules/shared/models';
import {DriverService} from '../../../services/driver-service';
import {TrailerService} from '../../../services/trailer-service';
import {TruckService} from '../../../services/truck-service';
import {LoadPickupDto, Size} from '../../../common/oms-types';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {MasterService} from '../../../services/master/master.service';
import {DialogResult, ModalResult, OmsDialogsService} from "../../common/oms-dialogs";
import {AuthService} from "../../../services";
import {UserRoleType} from "../../../modules/shared/models/user-role.type";

export interface LoadPickupDialogData {
  masterId: number;
  orderId?: number;
  noCamera?: boolean;
  master?: Master;
  pickup?: LoadPickupDto[];
  onSubmit?: (result: boolean) => void;
}

/**
 * @deprecated
 */
@Component({
  selector: 'oms-load-pickup-dialog',
  templateUrl: './load-pickup-dialog.component.html',
  styleUrls: ['./load-pickup-dialog.component.scss']
})
export class LoadPickupDialogComponent implements OnInit {
  public selectedLoad: any;
//  public activeLoad: any;
  public loads: any[];
  public loadsPickedUp: any[] = [];
  public Size = Size;
  public master: Master;
  public ulds: Uld[];
  public returned: Uld[] = [];
  public pickedUp: Uld[] = [];
  public loading: boolean = true;
  public visible: boolean = true;
  public forceUpdate: boolean = false;

  // Total Picked Up by Prior Recoveries
  public pcsPickedUpTotal: number = 0;
  public huPickedUpTotal: number = 0;
  public isInternalUser: boolean;
  public isDriver: boolean;
  public noCamera: boolean = false;

  constructor(
    public auth: AuthService,
    public dialogs: OmsDialogsService,
    public cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: LoadPickupDialogData,
    public dialogRef: MatDialogRef<LoadPickupDialogComponent, DialogResult<LoadPickupDialogData>>,
    public masterService: MasterService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService,
    public alerts: OmsAlertsService,
  ) {
    this.loading = true;
//    this.visible = false;
    console.log('DATA', this.data);
    this.isInternalUser = auth.isUserInRoles([UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_USER]);
    this.isDriver = auth.isUserInRole(UserRoleType.ROLE_DRIVER);


      this.masterService.getMaster(data.masterId)
        .then((master) => {
          this.loading = false;
//          this.visible = true;

          console.log('MASTER>', master);
          this.master = master;
          this.ulds = master.masterAirUld;
          while (this.ulds.length < 12) {
            this.ulds.push(new Uld());
          }
          this.ulds.forEach((uld) => {
            if (uld.isPickedUp) {
              this.pickedUp.push(uld);
            }
            if (uld.isReturned) {
              this.returned.push(uld);
            }
          });
          console.log('ORDER ID', this.data.orderId, 'Picked', this.pickedUp, 'Returned', this.returned, this.ulds);

          this.loads = this.prepareLoads(master.splits, master.ulds).sort((a, b) => this.byPickupDate(a, b));
          this.loadsPickedUp = this.loads.filter((ld) => ld.isPickedUp);
          this.setSelected(this.loads.find((ld) => this.data.orderId ? ld.orderId === this.data.orderId : !ld.isPickedUp) || this.loads.first());

      }).catch((error) => {
        this.loading = false;
        this.alerts.error(error);
        this.dialogRef.close({result: ModalResult.CANCEL});
//        this.visible = true;
      });
  }

  public compareDate(a: Date, b: Date): number {
    if (!a) {
      return !b ? 0 : -1;
    } else if (!b) {
      return 1;
    }
    return a.getTime() - b.getTime();
  }

  public byPickupDate(a, b): number {
    return this.compareDate(a && a.datePickupAct, b && b.datePickupAct);
  }

  public getUldsPickedUp(ld) {
    return ld.ulds.filter(u => u.isPickedUp && u.pickedUpByOrderId === ld.orderId);
  }

  public getUldsToPickUp(ld) {
    return ld.ulds.filter(u => !u.isPickedUp || (u.pickedUpByOrderId === ld.orderId));
  }


  private getRecoveredUlds(split: RecoveryOrder, ulds: Uld[], splits: RecoveryOrder[]) {
    return ulds.map((u) => {
      let ur: UldRecovery = split.uldsRecovered && split.uldsRecovered.find((u1) => u1.uld.id === u.id);
      let pickedUpByOrder: RecoveryOrder;
      let pcsPickedUp: number;
      let huPickedUp: number;

      if (!!u.datePickupAct) {
        // uld is already recovered with Order
        pickedUpByOrder = splits.find((ro) => !!ro.uldsRecovered.find((u1) =>  u1.uld.id === u.id));
        if (pickedUpByOrder) {
          let recovered: UldRecovery = pickedUpByOrder.uldsRecovered.find((ur1) => ur1.uld.id === u.id);
          huPickedUp = recovered && recovered.hu;
          pcsPickedUp = recovered && recovered.pcs;
        }
      }

      return {
        id: u.id,
        uldNumber: u.uldNumber,
        datePickupAct: u.datePickupAct,

        isPickedUp: !!u.datePickupAct,
        pcsPickedUp: pcsPickedUp,
        huPickedUp: huPickedUp,
        pickedUpByOrderId: pickedUpByOrder && pickedUpByOrder.orderId,

        pcs: ur && ur.pcs || undefined,
        hu: ur && ur.hu || undefined,
      };
    });
  }

  private prepareLoads(splits: RecoveryOrder[], ulds: Uld[]): any[] {
    let result = [];
    this.pcsPickedUpTotal = 0;
    this.huPickedUpTotal = 0;

    splits.forEach((split) => {
      let uldsRecovered = this.getRecoveredUlds(split, ulds, splits);

      let pcsDispatched = split.pieces;      // Initially Dispatched PCS
      let huDispatched = split.hu;           // Initially dispatched HU

      let pcsUld = 0; uldsRecovered.forEach((n) => pcsUld += ~~n.pcs); // Total PCS Picked Up from ULDs
      let huUld = 0;  uldsRecovered.forEach((n) => huUld += ~~n.hu);  // Total HU Picked Up from ULDs

      let isPickedUp: boolean = !!split.datePickupAct;
      let huPickedUp = isPickedUp ?  split.hu : undefined;
      let pcsPickedUp = isPickedUp ?  split.pieces : undefined;
      if (isPickedUp) {
        this.pcsPickedUpTotal += ~~pcsPickedUp;
        this.huPickedUpTotal += ~~huPickedUp;
      }

      let pcsLoose = isPickedUp ? ~~pcsPickedUp - pcsUld : undefined;
      let huLoose = isPickedUp ?  ~~huPickedUp - huUld : undefined;

      let load = {
        split: split,
        loadId: split.loadId,
        orderId: split.orderId,
        dateDispatched: split.dateDispatched,
        datePickupEst: split.datePickupEst,
        datePickupAct: split.datePickupAct || new Date(),
        isPickedUp: isPickedUp,
        driverDispatched: split.driver,
        driverPickedUp: split.driver,
        truckDispatched: split.truck,
        truckPickedUp: split.truck,
        trailerDispatched: split.trailer,
        trailerPickedUp: split.trailer,

        pcsDispatched:  pcsDispatched,
        huDispatched: huDispatched,
        pcsPickedUp: pcsPickedUp,
        huPickedUp: huPickedUp,
        pcsLoose: pcsLoose,
        huLoose: huLoose,
        ulds: uldsRecovered
      };
      console.log('PREPARED', load);
      result.push(load);
    });


    return result;

  }

  private validate(): Promise<any> {
    return new Promise<any>((confirm, reject) => {
      if (!this.selectedLoad) {
        reject("No Load Selected");
      }

      if (this.selectedLoad.huLoose && this.selectedLoad.huLoose > ~~this.selectedLoad.pcsLoose) {
        reject('Loose HU must be less or equal to Loose PCS');
        return;
      }

      this.selectedLoad.ulds.forEach((u) => {
        if (u.hu && u.hu > ~~u.pcs) {
          reject('ULD HU must be less or equal to ULD PCS');
          return;
        }
        if (u.pcs && !u.uldNumber) {
          reject('ULD Number is missing');
          return;
        }
      });

      if (!this.selectedLoad || !this.selectedLoad.datePickupAct) {
        reject('Actual Pickup Date must be selected');
      } else

      if (!this.selectedLoad.driverPickedUp) {
        reject('Picked Up Driver must be selected');
      } else {




        let pcsTotal = this.getTotalPieces(this.selectedLoad);
//        let huTotal = this.getTotalHu(this.selectedLoad);

        if (!pcsTotal) {
          reject('Picked Up PCS must be greater than zero');
        } else {
          confirm();
        }
      }
    });
  }

  public isSelected(split: any): boolean {
    return split === this.selectedLoad;
  }

  public setSelected(split: any) {
    this.selectedLoad = split;
  }

  public isEstimated(split: any): boolean {
    return !split.datePickupAct && !!split.datePickupEst;
  }

  ngOnInit() {
  }

  public onSubmit($event) {
    $event.canClose = false;

    if (!this.selectedLoad.huLoose && this.selectedLoad.pcsLoose) {
      this.selectedLoad.huLoose = this.selectedLoad.pcsLoose;
    }
    this.selectedLoad.ulds.forEach((u) => {
      if (!u.hu && u.pcs) {
        u.hu = u.pcs;
      }
    });

    this.validate()
      .then(() => {

        this.ulds.forEach((uld) => {
          if (this.isPickedUp(uld)) {
            uld.datePickupAct = this.selectedLoad.datePickupAct;
          }
          if (this.isReturned(uld)) {
            uld.dateReturnAct = this.selectedLoad.datePickupAct;
          }
        });
        this.data.pickup = [{
          orderId: this.selectedLoad.orderId,
          datePickupAct: this.selectedLoad.datePickupAct,
          driver: this.selectedLoad.driverPickedUp,
          truck: this.selectedLoad.truckPickedUp,
          trailer: this.selectedLoad.trailerPickedUp,
          pcs: this.getTotalPieces(this.selectedLoad),
          hu: this.getTotalHu(this.selectedLoad),
          uld: this.selectedLoad.ulds
        }];


        /*this.loads.forEach(ld=>{
          this.data.pickup.push({
            id: ld.loadId,
            datePickupAct: ld.datePickupAct,
            driver: ld.driverPickedUp,
            truck: ld.truckPickedUp,
            trailer: ld.trailerPickedUp,
            pcs: ld.pcsPickedUp,
            hu:ld.huPickedUp,
            uld:[{id: 10, result: 'PickedUp'}]
          })
        }); */

        this.dialogRef.close({result: ModalResult.OK, data: this.data});
      })
      .catch(error => {
        this.alerts.danger(error);
      });
  }

  public setReturned(uld: Uld, value: boolean) {
    if (value) {
      this.returned.push(uld);
    } else {
      uld.dateReturnAct = undefined;
      this.returned.removeAll((item) => item === uld);
    }
  }

  isPickedUp(uld: Uld): boolean {
    return this.pickedUp.includes(uld);
  }

  isReturned(uld: Uld): boolean {
    return this.returned.includes(uld);
  }

  getTotalHu(load): number {
    let hu = 0;
    load.ulds.forEach((uld) => hu += ~~uld.hu);
    return hu + ~~load.huLoose;
  }

  getTotalPieces(load): number {
    let pcs = 0;
    load.ulds.forEach((uld) => pcs += ~~uld.pcs);
    return pcs + ~~load.pcsLoose;
  }

  public canUpdateLoad() {
    return this.selectedLoad && (!this.selectedLoad.isPickedUp || this.forceUpdate);
  }

  public canUpdateUld(uld) {
    return this.canUpdateLoad() && (!uld.isPickedUp || (uld.pickedUpByOrderId === this.selectedLoad.orderId));
  }

  public openCamera() {
    this.dialogs.openCameraDialog({
      orderId: this.selectedLoad && this.selectedLoad.orderId,
      masterId: this.master && this.master.id,
      docTypeList: ['POD']});
  }

  public openDocCenter() {
    this.dialogs.openDocCenterWindow( {objectType: 'ORDER', id: this.selectedLoad.orderId }, true);
  }

  public get showDetails() {
    return !this.isDriver && this.isInternalUser;
  }

}
