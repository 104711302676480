var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { OmsDialogsService } from '../components/common/oms-dialogs';
import { CustomerDeliveryLocation } from '../modules/shared/models/customer-delivery-location';
import { Customer } from "../modules/shared/models";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
var CustomerDeliveryLocationService = /** @class */ (function (_super) {
    __extends(CustomerDeliveryLocationService, _super);
    function CustomerDeliveryLocationService(dialogs, httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.dialogs = dialogs;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/api/customer-delivery-location/';
        _this.classType = CustomerDeliveryLocation;
        return _this;
    }
    CustomerDeliveryLocationService.prototype.searchContactByAddress = function (addressId) {
        var params = new HttpParams()
            .set('addressId', addressId.toString());
        var headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
        return this.httpRestService.getWithOptions(this.apiUrl + 'searchContactByAddress', headers, params)
            .pipe(map(function (a) { return plainToClass(Customer, a); }))
            .toPromise();
    };
    return CustomerDeliveryLocationService;
}(BaseEntityService));
export { CustomerDeliveryLocationService };
