<!-- removed [draggable]="true" [dragEnabled]="table.draggableData" [dragData]="column"  -->
<th class="th-lg table-column"  [ngClass]="{'draggable':table.draggableData}" [attr.orderNumber]="order"
     *ngIf="column.visible" [style.text-align]="column.align" [style.float]="column.align === 'end' ? 'right' : 'unset'">
  <span class="ui-column-resizer" *ngIf="table.resizeColumns" (mousedown) = "table.onMouseDown($event, column)"></span>

  <a dropdown container="body" (click)="table.sortBy(column)" class="column-header" style="white-space: nowrap; overflow-x: hidden;" [popover]="column.headerTooltip">
    <i class="{{iconClass}}" *ngIf="hasIcon"></i>
    {{column.label}}

    <ng-container *ngIf="isHeaderSelectable">
      <button *ngIf="isToggleButton"
              class="btn btn-header btn-xs btn-outline-secondary"
              (click)="clickByToggleButton($event)">
        <span *ngIf="column.handlers.header.displaySelectedLabel">{{column.headerLabelOfSelected}}</span>
        <i class="caret"></i>
      </button>
      <button *ngIf="!isToggleButton" dropdownToggle class="btn btn-header btn-xs btn-outline-secondary"
         data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" onclick="event.stopPropagation()">
        <span *ngIf="column.handlers.header.displaySelectedLabel">{{column.headerLabelOfSelected}}</span>
        <i class="caret"></i>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu" style="min-width: auto">

        <li role="presentation" [ngClass]="{'active':selectedInHeader===item}" class="active" *ngFor="let item of column.handlers.header.select"
            (click)="selectedInHeader = item; table.refresh();">
          <a role="menuitem" >
            <i class=""></i>{{item[column.handlers.header.selectLabel]}}</a>
        </li>
      </ul>
    </ng-container>

    <span *ngIf="column.sortable && (!column.handlers.header || column.handlers.header.noarrows)"
          [ngClass]="{'sortable': true, 'asc':table.isSortedAsc(column), 'desc': table.isSortedDesc(column)}">
    </span>
  </a>
</th>
