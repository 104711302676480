import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FileUploadService } from "../../../../services/file.upload.service";
import { BehaviorSubject } from "rxjs";
import { OmsAlertsService } from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
var DocumentPreviewDialogComponent = /** @class */ (function () {
    function DocumentPreviewDialogComponent(dialogRef, data, cdr, alerts, fileUploadService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.cdr = cdr;
        this.alerts = alerts;
        this.fileUploadService = fileUploadService;
        this.loading = false;
        this.documents = [];
        this.source = new BehaviorSubject(undefined);
        this.objectType = data && data.objectType;
        this.current = data && data.document;
        this.documents = data && data.documents ?
            data.documents.filter(function (od) { return od.uploadedFiles.name.endsWith('.pdf'); }) :
            [this.current];
    }
    DocumentPreviewDialogComponent.prototype.getData = function () {
        var _this = this;
        if (this.current) {
            this.loading = true;
            this.fileUploadService.stream(this.current.uploadedFiles)
                .subscribe(function (result) {
                new Response(result).arrayBuffer()
                    .then(function (buffer) {
                    _this.loading = false;
                    _this.source.next(new Uint8Array(buffer));
                    _this.cdr.markForCheck();
                }).catch(function (error) {
                    _this.loading = false;
                    _this.cdr.markForCheck();
                    _this.alerts.error(error, 'Error Loading Document');
                });
            }, function (error) {
                _this.loading = false;
                _this.cdr.markForCheck();
                _this.alerts.error(error, 'Error Loading Document');
            });
        }
    };
    DocumentPreviewDialogComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    DocumentPreviewDialogComponent.prototype.download = function () {
        if (this.current) {
            this.fileUploadService.download(this.current.id, this.objectType);
        }
    };
    DocumentPreviewDialogComponent.prototype.next = function () {
        if (this.current && this.multipleDocuments) {
            this.current = this.documents.next(this.current, true);
            this.getData();
        }
    };
    DocumentPreviewDialogComponent.prototype.previous = function () {
        if (this.current && this.multipleDocuments) {
            this.current = this.documents.next(this.current, true);
            this.getData();
        }
    };
    Object.defineProperty(DocumentPreviewDialogComponent.prototype, "multipleDocuments", {
        get: function () {
            return this.documents && this.documents.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return DocumentPreviewDialogComponent;
}());
export { DocumentPreviewDialogComponent };
