import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, PipeTransform, QueryList } from '@angular/core';
import { OmsConstants } from '../../../../common/oms-constants.service';
import { FreightForwarder, LoadType, Master, MasterShipment, MasterShipmentAir, MasterStatus, MasterStatusService, Order, Shipment, ShipmentContent, MasterAir } from '../../../../modules/shared/models';
import { AirlineService } from '../../../../services/air/airline.service';
import { ColumnIds, OmsColumns } from '../../../../common/oms-columns.service';
import { Subject } from 'rxjs';
import { add, subtract } from 'ngx-bootstrap/chronos';
import { CustomerService } from '../../../../services/customer.service';
import { AddressService, AuthService, OrdersService } from '../../../../services';
import { isNullOrUndefined } from 'util';
import { StringInputComponent } from "../../../../modules/shared/components/common/input/string-input/string-input.component";
import { DateInputComponent } from "../../../../modules/shared/components/common/input/date-input/date-input.component";
import { MasterService } from "../../../../services/master/master.service";
import { OmsAlertsService } from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { Location } from '@angular/common';
import { convertMawbNumber } from '../../../../modules/shared/services/oms-converters.service';
import { NotesCenterComponent } from "../../../../components/common/notes-center";
import { NotesCenterDialogComponent } from "../../../../components/common/notes-center-dialog";
import { MatDialog } from "@angular/material";
import * as Dates from '../../../../common/oms-date-time.utils';
import { WebSocketService } from "../../../../services/websocet/web.socket.service";
import { HistoryInformationDialogComponent } from "../../../../components/common/history-information-dialog";
import { LogChangesService } from "../../../../services/logchanges/log.changes.service";
import { CustomerSearchComponent } from "../../../../components/common/customer-search";
import { assigned, equals } from '../../../../_helpers/utils';
import { Router } from "@angular/router";
import { maxDate } from '../../../../common/oms-date-time.utils';
import { Size } from '../../../../common/oms-types';
import { MasterProcessor } from '../../../../common/master-processor';
import { FreightForwarderService } from "../../../../services/freight.forwarder.service";
import { CustomSearchComponent } from "../../../../modules/shared/components/common/input/custom-search/custom-search.component";
import { FreightForwarderSearchComponent } from "../../../../components/common/freight-forwarder-search/freight-forwarder-search.component";
import { FreightForwarderDialogComponent } from '../../../../components/common/freight-forwarder-dialog';
import { DataTableComponent } from '../../../../modules/shared/components/base/data-table/data-table.component';
import { DialogType, ModalResult, OmsDialogsService } from "../../../../components/common/oms-dialogs";
import { UldsDialogComponent } from "../../../../components/common/ulds-dialog/ulds-dialog.component";
import { SplitOrderDialogComponent } from "../../../../modules/dispatch/pages/manifest-create/split-order-dialog/split-order-dialog.component";
import { AddressType } from "../../../../modules/shared/models";
import { BillingInfoListDialogComponent } from "../../../orders/order-editor/billing-info-list-dialog/billing-info-list-dialog.component";
import { BillingService } from "../../../../services/billing.service";
import { takeUntil } from "rxjs/operators";
import { OrderSubscriptionDialogComponent } from "../../../../modules/logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
var LoadTableColumnsFilter = /** @class */ (function () {
    function LoadTableColumnsFilter() {
    }
    LoadTableColumnsFilter.prototype.transform = function (array, noCfs) {
        return noCfs ? array.filter(function (c) { return c.id !== ColumnIds.LOAD_PCS_RCVD; }) : array;
    };
    return LoadTableColumnsFilter;
}());
export { LoadTableColumnsFilter };
var OrderTableColumnsFilter = /** @class */ (function () {
    function OrderTableColumnsFilter() {
    }
    OrderTableColumnsFilter.prototype.transform = function (array, nonAMS) {
        return nonAMS ? array.filter(function (c) { return c.id !== ColumnIds.ORDER_1C && c.id !== ColumnIds.ORDER_1F; }) : array;
    };
    return OrderTableColumnsFilter;
}());
export { OrderTableColumnsFilter };
var PartsBySelectedLoadFilter = /** @class */ (function () {
    function PartsBySelectedLoadFilter() {
    }
    PartsBySelectedLoadFilter.prototype.transform = function (array, partsByLoad, selected) {
        var _this = this;
        return array.filter(function (part) { return _this.showLoadPart(part, partsByLoad, selected); });
    };
    PartsBySelectedLoadFilter.prototype.showLoadPart = function (part, partsByLoad, selected) {
        return !partsByLoad || selected.length === 0 || !isNullOrUndefined(selected.find(function (split) { return split.splitNumber === part.splitNumber; }));
    };
    return PartsBySelectedLoadFilter;
}());
export { PartsBySelectedLoadFilter };
var MasterImportAirComponent = /** @class */ (function () {
    function MasterImportAirComponent(_constants, _alerts, _statuses, _location, cdr, dialog, dialogs, logChangesService, customerService, freightForwarderService, 
    //    public customerGroupService: CustomerGroupService,
    addressService, billingService, _columns, 
    //    public airportService: AirportService,
    airlineService, alerts, webSocketService, masterService, masterProcessor, orderService, router, authService) {
        this._constants = _constants;
        this._alerts = _alerts;
        this._statuses = _statuses;
        this._location = _location;
        this.cdr = cdr;
        this.dialog = dialog;
        this.dialogs = dialogs;
        this.logChangesService = logChangesService;
        this.customerService = customerService;
        this.freightForwarderService = freightForwarderService;
        this.addressService = addressService;
        this.billingService = billingService;
        this._columns = _columns;
        this.airlineService = airlineService;
        this.alerts = alerts;
        this.webSocketService = webSocketService;
        this.masterService = masterService;
        this.masterProcessor = masterProcessor;
        this.orderService = orderService;
        this.router = router;
        this.authService = authService;
        this.readonly = false;
        this.AddressType = AddressType;
        this.submitting = false;
        this.columnIds = ColumnIds;
        this.invoices = [];
        this.unsubscribe$ = new Subject();
        this.webSocketEventName = '/data-table/masters';
        this.masterChangeEvent = new EventEmitter();
        this.masterUpdatedEvent = new EventEmitter();
        this.Size = Size;
        this.sub = [];
        this.orderLoads = new Map();
        this.loadContents = new Map();
        this.selectedOrders = [];
        this.selectedParts = [];
        this.selectedLoads = [];
        this.loadsByOrders = false;
        this.partsByLoad = false;
        this.isDeleteAvailable = false;
        this.customerService.getAllCustomers();
        this.freightForwarderService.getAllFreightForwarders();
        //    this.customerGroupService.getAllCustomerGroups();
        //    this.addressService.getAllAddresses();
    }
    Object.defineProperty(MasterImportAirComponent.prototype, "orderParts", {
        get: function () {
            return this.master.parts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "recoveryLoads", {
        get: function () {
            return this.master.splits;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "orders", {
        get: function () {
            return this.master.orders;
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.initCanDeleteOrder = function () {
        this.isDeleteAvailable = this.authService.canDeleteOrder();
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "loadItems", {
        get: function () {
            var _this = this;
            if (this.loadsByOrders) {
                var loads_1 = [];
                if (this.selectedOrders && this.selectedOrders.length > 0) {
                    //        return this.orderLoads.get(this.selectedOrders.values().next().value);
                    this.selectedOrders.forEach(function (order) {
                        var ld = _this.orderLoads.get(order);
                        if (ld) {
                            ld.forEach(function (load) { return loads_1.push(load); });
                            // todo: why does not work???  loads.concat(ld);
                        }
                    });
                }
                return loads_1;
            }
            else {
                return this.loads;
            }
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.prepareLoads = function (master) {
        this.orderLoads.clear();
        this.loadContents.clear();
        this.loads = [];
    };
    MasterImportAirComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.master && !assigned(this.master.masterAir)) {
            this.master.masterAir = new MasterAir();
            this.master.masterAir.master = this.master;
            console.log('Restored MasterAir', this.master);
        }
        this.prepareLoads(this.master);
        this.authService.user$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (user) { return _this.user = user; });
        this.billingService.getInvoicesByMasterId(this.master.id)
            .subscribe(function (invoices) { return _this.invoices = invoices; });
        this.sub.push(this._columns.recoveryLoadColumns.subscribe(function (columns) { _this.recoveryLoadColumns = columns; }));
        this._columns.getRecoveryLoadColumns();
        this.sub.push(this._columns.orderPartsColumns.subscribe(function (columns) { _this.orderPartsColumns = columns; }));
        this._columns.getOrderPartsColumns();
        this.sub.push(this._columns.masterOrdersColumns.subscribe(function (columns) { _this.masterOrdersColumns = columns; }));
        this._columns.getMasterOrdersColumns();
        this.sub.push(this._columns.masterLoadsColumns.subscribe(function (columns) { _this.masterLoadsColumns = columns; }));
        this._columns.getMasterLoadsColumns();
        this.sub.push(this._columns.masterListColumns.subscribe(function (columns) { _this.masterDefaultColumns = columns; }));
        this._columns.getMasterListColumns();
        this.initCanDeleteOrder();
    };
    MasterImportAirComponent.prototype.changeMawbEvent = function (master, event) {
        var _this = this;
        if (master && master.masterAir) {
            for (var _i = 0, _a = master.orders; _i < _a.length; _i++) {
                var order = _a[_i];
                if (order.isNew()) {
                    order.hawb = master.masterAir.mawbNumber;
                }
            }
            if (master.masterAir.mawbNumber.length === 11) {
                var awbPrefix = master.masterAir.mawbNumber.substr(0, 3);
                /*this.masterAirService.findByMawbNumber(master.masterAir.mawbNumber).subscribe(masterAir => {
                  if (isNullOrUndefined(masterAir)) {
                    this.masterExist = false;
                    master.master.id = null;
                    master.shipment.id = null;
                    master.masterAir.id = null;
                  } else {
                    this.masterExist = true;
                    master.master.id = masterAir.master.id;
                    master.shipment = masterAir.master.shipment;
                    master.masterAir.flightNumber = masterAir.flightNumber;
                    master.masterAir.id = masterAir.id;
                  }
                }); */
                this.airlineService.getByPrefix(awbPrefix).subscribe(function (airline) {
                    _this.masterAirline = airline;
                    //          this.checkWarehouse();
                    _this.cdr.detectChanges();
                });
            }
        }
    };
    MasterImportAirComponent.prototype.onCustomerChange = function (master, event) {
        var old = master.freightForwarder;
        master.freightForwarder = event;
        /*    if (master.freightForwarder) {
              master.orders.forEach(order => {
                if (!order.customer || equals(order.customer, old))
                order.customer = master.freightForwarder})
            }*/
    };
    MasterImportAirComponent.prototype.onCustomerGroupChange = function (master, event) {
        this.customerGroup = event;
        if (isNullOrUndefined(this.customerGroup)) {
            if (!isNullOrUndefined(master.customer) && this.customerGroup !== master.customer.customerGroup) {
                master.customer = null;
            }
            this.customerService.getAllCustomers();
        }
        else {
            this.customerService.getAllCustomersByCustomerGroup(this.customerGroup.id);
        }
    };
    MasterImportAirComponent.prototype.ngOnDestroy = function () {
        this.sub.forEach(function (item) { item.unsubscribe(); });
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "masterDateArrivalAct", {
        get: function () {
            return this.master.dateArrivalAct;
        },
        set: function (date) {
            var changed = !Dates.sameDateTime(date, this.master.dateArrivalAct || this.master.dateArrivalEst);
            this.master.dateArrivalAct = date;
            if (changed) {
                this.master.updateLastFreeDay();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterDateArrivalEst", {
        get: function () {
            return this.master.dateArrivalEst;
        },
        set: function (date) {
            this.master.dateArrivalEst = date;
            if (isNullOrUndefined(this.master.dateArrivalAct)) {
                this.master.updateLastFreeDay();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "deliveryApproval", {
        get: function () {
            var deliveryApproval = true;
            this.master.orders.forEach(function (order) {
                deliveryApproval = deliveryApproval && order.deliveryApproval;
            });
            return deliveryApproval;
        },
        set: function (deliveryApproval) {
            this.master.orders.forEach(function (order) {
                order.deliveryApproval = deliveryApproval;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "customer", {
        get: function () {
            return this.master.customer;
        },
        set: function (customer) {
            this.master.customer = this.customer;
            for (var _i = 0, _a = this.master.orders; _i < _a.length; _i++) {
                var order = _a[_i];
                if (order.isNew()) {
                    order.customer = customer;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "dateCfsInAct", {
        get: function () {
            return this.master.dateCfsInAct;
        },
        set: function (date) {
            if (this.master.dateCfsInAct !== date) {
                this.master.dateCfsInAct = date;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "dateCfsOutAct", {
        get: function () {
            return this.master.dateCfsOutAct;
        },
        set: function (date) {
            if (this.master.dateCfsOutAct !== date) {
                this.master.dateCfsOutAct = date;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "freightForwarder", {
        get: function () {
            return this.master.freightForwarder;
        },
        set: function (freightForwarder) {
            this.master.freightForwarder = freightForwarder;
            /*    for (let order of this.master.orders) {
                  if (order.isNew()) {
                    order.customer = customer;
                  }
                }*/
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterUldCount", {
        get: function () {
            return this.master.uldCount;
        },
        set: function (value) {
            this.master.uldCount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterAirUlds", {
        get: function () {
            return this.master.masterAirUld;
        },
        set: function (uldAirs) {
            this.master.masterAirUld = uldAirs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterHu", {
        get: function () {
            return this.master.hu;
        },
        set: function (value) {
            this.master.hu = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterPieces", {
        get: function () {
            return this.master.pieces;
        },
        set: function (pieces) {
            this.master.pieces = pieces;
            this.resetLoads();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterPiecesReceived", {
        get: function () {
            return this.master.pcsReceived;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterWeight", {
        get: function () {
            return this.master.weight;
        },
        set: function (weight) {
            this.master.weight = weight;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterVolume", {
        get: function () {
            return this.master.volume;
        },
        set: function (volume) {
            this.master.shipment.volume = volume;
            for (var _i = 0, _a = this.master.orders; _i < _a.length; _i++) {
                var order = _a[_i];
                if (order.isNew()) {
                    order.volume = volume;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterPickupDate", {
        get: function () {
            return this.master.datePickupActual;
        },
        set: function (date) {
            var changed = !Dates.sameDateTime(date, this.master.datePickupActual || this.master.datePickupEstimated);
            this.master.datePickupActual = date;
            if (changed) {
                this.master.updateArrivalDay();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterPickupDateEstimated", {
        get: function () {
            return this.master.datePickupEstimated;
        },
        set: function (date) {
            this.master.datePickupEstimated = date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterDeliveryDate", {
        get: function () {
            return this.master.dateDeliveryActual;
        },
        set: function (date) {
            this.master.dateDeliveryActual = date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterDeliveryDateEstimated", {
        get: function () {
            var date = this.master.dateDeliveryEstimated;
            if (isNullOrUndefined(date)) {
                for (var _i = 0, _a = this.loads; _i < _a.length; _i++) {
                    var load = _a[_i];
                    if (load.isDelivery) {
                        var content = this.loadContents.get(load);
                        if (!date || date < content.shipment.dateDeliveryExpectation) {
                            date = content.shipment.dateDeliveryExpectation;
                        }
                    }
                }
            }
            return date;
        },
        set: function (date) {
            this.master.dateDeliveryEstimated = date;
            var dateCfsPickup = isNullOrUndefined(date) ? null : subtract(new Date(date), 2, 'hours');
            for (var _i = 0, _a = this.master.orders; _i < _a.length; _i++) {
                var order = _a[_i];
                //      order.dateDeliveryEst = date;
                var loads = this.orderLoads.get(order);
                if (loads) {
                    for (var _b = 0, loads_2 = loads; _b < loads_2.length; _b++) {
                        var load = loads_2[_b];
                        if (load.isDelivery) {
                            load.shipment.dateDeliveryExpectation = isNullOrUndefined(date) ? null : maxDate(load.shipment.dateDeliveryExpectation, date);
                            load.shipment.datePickUpExpectation = dateCfsPickup;
                        }
                    }
                }
            }
            //    this.accountStatus(this.master);
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.onCargoBuildingChange = function (address) {
        this.nonAMS = address && address.cfs3plLocation;
        for (var _i = 0, _a = this.loads; _i < _a.length; _i++) {
            var load = _a[_i];
            if (load.loadType === LoadType.RECOVERY) {
                load.shipment.addressPickUp = address;
            }
        }
    };
    MasterImportAirComponent.prototype.onSave = function (back) {
        var _this = this;
        if (this.submitting) {
            return;
        }
        if (!this.validateFields()) {
            this.submitting = true;
            var saving = this.master;
            console.log('Save Master', saving);
            if (this.master.isNew()) {
                this.masterService.newMasterCreated.next(true);
                this.masterService.create(this.master).subscribe(function (master) {
                    //          this.master = master;
                    //          this.prepareLoads(this.master);
                    //          this.master.orders.forEach((o) => {o.master = this.master; });
                    //          this.masterService.refreshByLastPageRequest(); // getAllMasters(); //todo
                    _this.alerts.success('New Master Created: ' + master.masterNumber);
                    _this.masterChangeEvent.emit(master);
                    //          this.router.navigate(['home/masters/master', {id: master.id}], {replaceUrl: true});
                    _this.submitting = false;
                    if (back) {
                        _this._location.back();
                    }
                    /*this.parent.masterSubject.next(this.master);*/
                }, function (error) {
                    _this.submitting = false; // todo
                    if (error === 'DataIntegrityViolation') {
                        error = 'MAWB ' + convertMawbNumber(_this.master.mawbNumber) + ' already exists';
                    }
                    _this.alerts.danger(error);
                });
            }
            else {
                console.log('UPDATE', this.master);
                this.masterService.update(this.master).subscribe(function (master) {
                    _this.submitting = false;
                    //          this.master = plainToClass(Master, master);
                    //          this.prepareLoads(this.master);
                    //          this.parent.loadMaster(this.master.id);
                    _this.masterChangeEvent.emit(_this.master);
                    _this.alerts.success('Master Updated: ' + _this.master.masterNumber);
                    if (back) {
                        _this._location.back();
                    }
                }, function (error) {
                    _this.submitting = false; // todo
                    if (error === 'DataIntegrityViolation') {
                        error = 'MAWB ' + convertMawbNumber(_this.master.mawbNumber) + ' already exists';
                    }
                    _this.alerts.danger(error);
                });
            }
        }
    };
    MasterImportAirComponent.prototype.validateFields = function () {
        var invalid = false;
        this.stringInputs.forEach(function (input) {
            input.touchControl();
            input.invalid.subscribe(function (res) {
                invalid = invalid || res;
            });
        });
        this.freightForwarderSearchInputs.forEach(function (input) {
            input.touchControl();
            input.invalid.subscribe(function (res) {
                invalid = invalid || res;
            });
        });
        this.customSearchComponentInputs.forEach(function (input) {
            input.touchControl();
            input.invalid.subscribe(function (res) {
                invalid = invalid || res;
            });
        });
        this.dateInputs.forEach(function (input) {
            input.touchControl();
            input.invalid.subscribe(function (res) {
                invalid = invalid || res;
            });
        });
        return invalid;
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "masterStatus", {
        get: function () {
            return MasterStatus.get(this.master.status);
        },
        set: function (status) {
            this.master.status = status ? status.id : null;
            //    if (this.master.orders)
            //      this.master.orders.forEach((order)=>order.status = status ? status.id : null)
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.setMasterStatus = function (item) {
        this.master.status = item.id;
    };
    MasterImportAirComponent.prototype.onSaveNote = function () {
        this._alerts.success('Changes are saved');
    };
    MasterImportAirComponent.prototype.openNoteCenter = function (noteTypeId) {
        var dialogRef = this.dialog.open(NotesCenterDialogComponent, { width: '80%', data: { id: this.master.id, objectType: 'MASTER', noteTypeId: noteTypeId, readonly: this.readonly } });
    };
    MasterImportAirComponent.prototype.openBillingDialog = function () {
        this.dialogs.openDialog(BillingInfoListDialogComponent, {
            object: this.master,
            invoices: this.invoices,
            isReadMode: this.readonly
        }, {}).then();
    };
    MasterImportAirComponent.prototype.openUldDialog = function () {
        var dialogRef = this.dialog.open(UldsDialogComponent, {
            width: '30%', data: {
                masterId: this.master.id,
                readonly: this.readonly
            }
        });
    };
    MasterImportAirComponent.prototype.openDocCenter = function () {
        // old way dialog:
        //    this.dialog.open(DocCenterDialogComponent, { width: '80%', data: { id: this.master.id, object: this.master, objectType: 'MASTER', readonly: this.readonly } });
        // New Way - window:
        this.dialogs.openDocCenterDialog({ id: this.master.id, objectType: 'MASTER' });
    };
    MasterImportAirComponent.prototype.onOrderFieldUpdate = function (event) {
        var _this = this;
        if (event.field === 'dateDeliveryAct') {
            this.masterService.updateOrderDate(this.master, event.row, 'dateDeliveryAct', event.newValue)
                .then(function (master) { _this.masterUpdatedEvent.emit(master); });
        }
        if (event.field === 'pieces') {
            if (this.master.updatePieces()) {
                this.resetLoads();
            }
            else {
                event.cancel();
            }
        }
        if (event.field === 'weight') {
            if (this.master.updateWeight()) {
                this.cdr.markForCheck();
            }
            else {
                event.cancel();
            }
        }
        if (event.field === 'addressDelivery') {
            if (event.row instanceof Order && !isNullOrUndefined(event.newValue)) {
                this.addressService.setCustomerDeliveryLocation(event.row.customer, event.newValue);
            }
        }
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "lfdDisabled", {
        get: function () {
            return isNullOrUndefined(this.master.dateArrivalEst) && isNullOrUndefined(this.master.dateArrivalAct);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "lfdCleanable", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "lfdMinDate", {
        get: function () {
            return Dates.minDate(this.master.dateArrivalEst, this.master.dateArrivalAct);
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.checkWarehouse = function (ms) {
        //    this.master.masterShipments.forEach(ms=>{
        var airport = ms.airport;
        var airline = ms.airline;
        if (airport && airline) {
            this.addressService.getAirportWarehouse(airport.id, airline.id).then(function (warehouse) { ms.shipment.addressDelivery = warehouse; });
        }
        //    });
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "masterAirline", {
        get: function () {
            return this.master.airline;
            /*    if (this.master.isSingleSplit)
                  return this.master.singleSplit.masterShipmentAir.airline;
                else return null; */
        },
        set: function (airline) {
            var _this = this;
            if (!equals(this.masterAirline, airline)) {
                this.master.masterShipments.forEach(function (ms) {
                    var old = _this.masterAirline;
                    ms.airline = airline;
                    if (!equals(old, airline)) {
                        _this.checkWarehouse(ms);
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterAirport", {
        get: function () {
            return this.master.airport;
        },
        set: function (airport) {
            var _this = this;
            if (!equals(this.master.airport, airport)) {
                this.master.masterShipments.forEach(function (ms) {
                    var old = ms.airport;
                    ms.airport = airport;
                    if (!equals(old, airport)) {
                        _this.checkWarehouse(ms);
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterCargoBuilding", {
        get: function () {
            return this.master.cargoBuilding;
        },
        set: function (address) {
            this.master.cargoBuilding = address;
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.resetLoads = function () {
        this.master.reset();
        this.selectedLoads = [];
        this.cdr.markForCheck();
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "masterOriginalAirport", {
        get: function () {
            return this.master.masterAir && this.master.masterAir.originalAirport;
        },
        set: function (airport) {
            this.master.masterAir.originalAirport = airport;
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.addOrder = function () {
        var _this = this;
        var order = Order.createOrderByMaster(this.master);
        this.master.orders.push(order);
        this.ordersTable.refresh(this.master.orders);
        this.prepareLoads(this.master);
        this.master.masterShipments.forEach(function (ms) { _this.checkWarehouse(ms); });
        this.selectedOrders = [order];
        this.resetLoads();
    };
    MasterImportAirComponent.prototype.splitOrder = function () {
        var _this = this;
        var order = this.selectedOrders[0];
        this.dialogs.openDialog(SplitOrderDialogComponent, {
            order: order,
            onOk: function (newPcs, newHu, newWeight, newOrder) {
                var copyOrder = Object.assign(new Order(), order);
                _this.master.orders.replaceAll(copyOrder, copyOrder);
                _this.master.orders.push(newOrder);
                _this.ordersTable.refresh(_this.master.orders);
                _this.resetLoads();
                _this.cdr.markForCheck();
            }
        }).then();
    };
    MasterImportAirComponent.prototype.isDisabledSplitOrder = function () {
        return this.selectedOrders && this.selectedOrders.length !== 1;
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "allowDeleteOrder", {
        get: function () {
            return this.selectedOrders && this.selectedOrders.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "allowDeletePart", {
        get: function () {
            return this.selectedParts && this.selectedParts.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.splitOrderPart = function () {
        var ms = new MasterShipment();
        this.master.masterShipments.push(ms);
        ms.master = this.master;
        ms.masterShipmentAir = new MasterShipmentAir();
        ms.shipment = new Shipment();
        this.selectedParts.forEach(function (part) {
            var sc = new ShipmentContent();
            sc.shipment = ms.shipment;
            sc.order = part.order;
            sc.order.shipmentContents.push(sc);
        });
        this.resetLoads();
    };
    MasterImportAirComponent.prototype.deleteOrder = function () {
        var _this = this;
        if (this.selectedOrders && this.selectedOrders.length > 0) {
            this.dialogs.confirm(DialogType.CONFIRMATION, 'Delete Order(s) ?', 'Are you sure you want to delete selected Order(s)?', [{ result: ModalResult.YES, caption: 'Delete' }, ModalResult.CANCEL])
                .then(function (res) {
                switch (res.result) {
                    case ModalResult.YES: {
                        _this.selectedOrders.forEach(function (order) {
                            if (order.isNew()) {
                                _this.master.orders.removeAll(order);
                                _this.ordersTable.refresh(_this.master.orders);
                                _this.resetLoads();
                            }
                            else {
                                _this.orderService.delete(order.id).toPromise()
                                    .then(function () {
                                    _this.master.orders.removeAll(order);
                                    _this.ordersTable.refresh(_this.master.orders);
                                    _this.resetLoads();
                                })
                                    .catch(function (error) { return _this.alerts.error(error, "Error deleting Order"); });
                            }
                        });
                        _this.selectedOrders.clear();
                    }
                }
            });
            this.resetLoads();
            this.cdr.markForCheck();
        }
    };
    MasterImportAirComponent.prototype.onMawbActualDeliveryChange = function (date) {
        var dateCfsPickup = isNullOrUndefined(date) ? null : subtract(new Date(date), 2, 'hours');
        for (var _i = 0, _a = this.master.orders; _i < _a.length; _i++) {
            var order = _a[_i];
            order.dateDeliveryAct = date;
            var loads = this.orderLoads.get(order);
            if (loads) {
                for (var _b = 0, loads_3 = loads; _b < loads_3.length; _b++) {
                    var load = loads_3[_b];
                    if (load.isDelivery) {
                        load.shipment.dateDeliveryActual = isNullOrUndefined(date) ? null : maxDate(load.shipment.dateDeliveryActual, date);
                        load.shipment.datePickUpActual = dateCfsPickup;
                    }
                }
            }
        }
    };
    MasterImportAirComponent.prototype.onMawbEstimatedDeliveryChange = function (date) {
        var dateCfsPickup = isNullOrUndefined(date) ? null : subtract(new Date(date), 2, 'hours');
        for (var _i = 0, _a = this.master.orders; _i < _a.length; _i++) {
            var order = _a[_i];
            order.dateDeliveryReq = date;
            var loads = this.orderLoads.get(order);
            if (loads) {
                for (var _b = 0, loads_4 = loads; _b < loads_4.length; _b++) {
                    var load = loads_4[_b];
                    if (load.isDelivery) {
                        load.shipment.dateDeliveryExpectation = isNullOrUndefined(date) ? null : maxDate(load.shipment.dateDeliveryExpectation, date);
                        load.shipment.datePickUpExpectation = dateCfsPickup;
                    }
                }
            }
        }
    };
    MasterImportAirComponent.prototype.onMawbActualPickupChange = function (date) {
        var dateCfsDelivery = isNullOrUndefined(date) ? null : add(new Date(date), 2, 'hours');
        for (var _i = 0, _a = this.master.orders; _i < _a.length; _i++) {
            var order = _a[_i];
            var loads = this.orderLoads.get(order);
            if (loads) {
                for (var _b = 0, loads_5 = loads; _b < loads_5.length; _b++) {
                    var load = loads_5[_b];
                    if (load.isRecovery) {
                        load.shipment.datePickUpActual = isNullOrUndefined(date) ? null : maxDate(load.shipment.datePickUpActual, date);
                        load.shipment.dateDeliveryActual = dateCfsDelivery;
                    }
                }
            }
        }
    };
    MasterImportAirComponent.prototype.onMawbEstimatedPickupChange = function (date) {
        var dateCfsDelivery = isNullOrUndefined(date) ? null : add(new Date(date), 2, 'hours');
        for (var _i = 0, _a = this.master.orders; _i < _a.length; _i++) {
            var order = _a[_i];
            var loads = this.orderLoads.get(order);
            if (loads) {
                for (var _b = 0, loads_6 = loads; _b < loads_6.length; _b++) {
                    var load = loads_6[_b];
                    if (load.isRecovery) {
                        load.shipment.datePickUpExpectation = isNullOrUndefined(date) ? null : maxDate(load.shipment.datePickUpExpectation, date);
                        load.shipment.dateDeliveryExpectation = dateCfsDelivery;
                    }
                }
            }
        }
    };
    MasterImportAirComponent.prototype.onRightClick = function (field, event, columnId) {
        event.stopPropagation();
        event.preventDefault();
        var columns = this.getColumnById(columnId);
        var column = columns.length > 0 ? this.getActualColumn(columns[0]) : null;
        if (!isNullOrUndefined(column) && column.history.logChanges) {
            var columnField = isNullOrUndefined(column.history.field) ? column.field : column.history.field;
            var params = this.logChangesService.getRequiredLogCenterParameters(this.master, columnField);
            this.dialog.open(HistoryInformationDialogComponent, { width: '80%', data: { logParams: params, column: column } });
        }
    };
    MasterImportAirComponent.prototype.getActualColumn = function (column) {
        if (column.map) {
            return column.map.get("Master");
        }
        return column;
    };
    MasterImportAirComponent.prototype.getColumnById = function (columnId) {
        return this.masterDefaultColumns.filter(function (c) { return c.id === columnId; });
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "customerNameLabel", {
        get: function () {
            var _this = this;
            var customers = [];
            this.master.orders.forEach(function (order) {
                if (!isNullOrUndefined(order.customer) && !_this.isCustomerExist(customers, order.customer)) {
                    customers.push(order.customer);
                }
            });
            return customers.length === 1 ? { label: customers[0].name, warning: false } : { label: "Various", warning: true };
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.isCustomerExist = function (customers, customer) {
        return customers.find(function (customerFromList) {
            return customerFromList.id === customer.id;
        });
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "masterFlightNumber", {
        get: function () {
            return this.master.flightNumber;
        },
        set: function (flightNumber) {
            this.master.flightNumber = flightNumber;
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.onUpdatePartField = function (event) {
        var part = event.row;
        switch (event.field) {
            case 'pieces':
                var total = part.content.shipment.pieces;
                var received = part.content.shipment.shipmentContents.aggregate(function (acc, sc) { return acc + ~~sc.pieces; }, 0);
                this.resetLoads();
                break;
        }
    };
    MasterImportAirComponent.prototype.onUpdateLoadField = function (event) {
        var split = event.row;
        switch (event.field) {
            case 'isDispatched':
                if (split.load) {
                    this.masterProcessor.dispatchRecoveryLoad(split, false, this.readonly)
                        .then(function (result) { console.log('DISPATCH RESULT>', result); });
                }
                else {
                    this.masterProcessor.openNewManifestFromMasterEditor(split.order);
                }
                break;
            case 'datePickupEst':
                if (this.masterService.validateRecoveryLoadPieces(this.master, split, event.newValue)) {
                    if (split.isVirtual) {
                        this.master.addRecoveryLoad(split.pieces, split.hu, event.newValue);
                    } // Make Recovery load Actual;
                    this.resetLoads();
                }
                else {
                    event.cancel();
                }
                break;
            case 'pcsToRecover':
                if (this.masterService.validateRecoveryLoadPieces(this.master, split, event.newValue)) {
                    if (split.isVirtual) {
                        this.master.addRecoveryLoad(event.newValue, null);
                    } // Make Recovery load Actual;
                    this.resetLoads();
                }
                else {
                    event.cancel();
                }
                break;
        }
    };
    MasterImportAirComponent.prototype.onUpdateMasterPieces = function () {
        this.resetLoads();
        //    this.master.reset();
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "masterDate1F", {
        get: function () {
            return this.master.date1F;
        },
        set: function (value) {
            if (!equals(value, this.master.date1F)) {
                this.master.date1F = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterDateDilled", {
        get: function () {
            return this.master && this.master.dateBilled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterDateBilled", {
        set: function (date) {
            if (this.master && !equals(date, this.master.dateBilled)) {
                this.master.dateBilled = date;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterOrders", {
        get: function () {
            return this.master ? this.master.orders : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "direct", {
        get: function () {
            return this.master.direct;
        },
        set: function (value) {
            this.master.direct = value;
            if (this.master.direct) {
                this.master.addressCfs = null;
                this.master.dateCfsInAct = null;
                this.master.dateCfsOutAct = null;
            }
            this.ordersTable.refresh();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "nonAMS", {
        get: function () {
            return this.master.nonAMS;
        },
        set: function (value) {
            this.master.nonAMS = value;
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.onFreightForwarderCreate = function (event) {
        var _this = this;
        var freightForwarder = new FreightForwarder();
        var dialogRef = this.dialog.open(FreightForwarderDialogComponent, { width: 'auto', data: { freightForwarder: freightForwarder } });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res) {
                _this.master.freightForwarder = res.res;
            }
        });
    };
    MasterImportAirComponent.prototype.isDeliveryDateDisabled = function () {
        return this.readonly || this.masterService.isDisableMaster(this.master);
    };
    Object.defineProperty(MasterImportAirComponent.prototype, "cfsDisabled", {
        get: function () {
            return this.readonly || this.master.direct || this.masterService.isDisableMaster(this.master);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "pickupLocationLabel", {
        get: function () {
            return this.master.nonAMS ? 'Pick Up' : 'Cargo Bldg';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterRecoveryToAddressTypes", {
        get: function () {
            return this.master.direct ? [AddressType.DELIVERY_LOCATION] : [AddressType.CFS_LOCATION];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterAddressRecoveryTo", {
        get: function () {
            return this.master.addressDelivery;
        },
        set: function (address) {
            this.master.addressDelivery = address;
            this.cdr.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterImportAirComponent.prototype, "masterAddressesRecoveryToDefault", {
        get: function () {
            return this.master.direct ? this.master.orders.unique(function (o) { return o.addressDelivery; }) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    MasterImportAirComponent.prototype.openSubscriptionDialog = function () {
        this.dialog.open(OrderSubscriptionDialogComponent, { width: 'auto', data: { orders: this.orders.slice() } })
            .afterClosed().subscribe(function (res) { });
    };
    MasterImportAirComponent.prototype.getRldRowStatusClass = function (order) {
        console.log('TEST', order);
        return order && order.getRouteStatusClass();
    };
    MasterImportAirComponent.prototype.onShowHistory = function () {
        var logChanges = {
            className: new Master().javaClassName,
            converter: function (value) { return value ? value.toString() : OmsConstants.EMPTY_VALUE; },
            id: this.master.id
        };
        console.log('LOG', logChanges);
        this.dialogs.openDialog(HistoryInformationDialogComponent, {
            logParams: [logChanges], column: null, convertByBaseType: null
        }).then();
    };
    return MasterImportAirComponent;
}());
export { MasterImportAirComponent };
