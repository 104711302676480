import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from 'rxjs';
import {Router, UrlTree} from "@angular/router";
import {LeftMenu} from "../menu/left.menu";

export interface MenuItemConfig {
  label: string;
  href?: any;
  iconClass?: string;
  svg?: string;
  dataTarget?: string;
  role?: string | string[];
  children?: MenuItemConfig[];
}


@Injectable()
export class LeftMenuConfigService {
  public configModel: LeftMenu = new LeftMenu();
  public onMenuUpdated$: Subject<MenuItemConfig[]> = new BehaviorSubject<MenuItemConfig[]>(this.configModel.config);

  constructor(private router: Router) { }

}
