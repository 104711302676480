export class ArrayUtils {

  /**
   * Save only first duplicate objects
   */
  public static removeDuplicate<T>(arr: T[], uniqCriteria: (item: T) => any): T[] {
    let index = [];
    return arr.filter((item) => {
      let k = uniqCriteria(item);
      let isFind = index.some(searchItem => searchItem === k);
      return isFind ? false : index.push(k);
    });
  }

  public static removeItems<T>(source: T[], predicate: (item: T) => boolean): T[] {
    if (!source || !source.length) {
      return [];
    }
    return source.filter(item => !predicate(item));
  }

  public static buildRemoveDuplicateFilter<T>(uniqCriteria?: (item: T) => any): (value: T) => boolean {
    let index = [];
    return (value: T) => {
      let k = uniqCriteria ? uniqCriteria(value) : value;
      let isFind = index.some(searchItem => searchItem === k);
      if (!isFind) {
        index.push(k);
      }
      return !isFind;
    };
  }

  public static getLast<T>(items: T[]): T {
    if (!items || !items.length) {
      return null;
    }
    return items[items.length - 1];
  }
}
