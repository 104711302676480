export enum ProblemType {
  CUSTOM = 0,
  SHORTAGE = 1,
  OVERAGE = 2,
  DAMAGE = 3,
  AMS_PCS = 4,
  CUSTOMS_1C = 5,
  CUSTOMS_1F = 6,
  ISC_NOT_PAID = 7,
  NOT_BROKEN_DOWN = 8,
  DRIVER_PICK_UP = 9
}
