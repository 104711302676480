import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {Manifest} from "../modules/shared/models/manifest/manifest";
import {OrderDispatch} from "../modules/shared/models/dispatch/order-dispatch";
import {classToPlain, plainToClass} from "class-transformer";
import {HttpParams} from "@angular/common/http";
import {Address} from "../modules/shared/models";
import {tap} from "rxjs/operators";
import {DriverLoad, DriverLocation} from "../modules/web-drivers/web-drivers-ui.data";

@Injectable()
export class WebDriversService extends BaseEntityService<Manifest> {
  protected apiUrl = '/web-drivers/dispatch/';
  protected classType = Manifest;


  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public getDriverRoutes():  Promise<OrderDispatch[]> {
    return this.httpRestService
      .post<OrderDispatch[]>(this.apiUrl + 'get-driver-routes', this.httpUtils.getHTTPHeaders())
      .pipe(tap((n) => console.log('LOADS', n)))
      .toPromise().
      then((list) => plainToClass(OrderDispatch, list));
  }

  /***
   * @deprecated
   * @param loads
   */
  public updateDispatchItem(loads: OrderDispatch[]): Promise<OrderDispatch[]> {
    console.info('updateDispatchItem');
    return this.httpRestService.put<OrderDispatch[]>(this.apiUrl + 'updateDispatchItem', classToPlain(<Object[]>loads), this.httpUtils.getHTTPHeaders()).toPromise().
      then((list) => plainToClass(OrderDispatch, list));
  }

  public updateDispatchNotification(loads: OrderDispatch[]): Promise<OrderDispatch[]> {
    return this.httpRestService.put<OrderDispatch[]>(this.apiUrl + 'updateDispatchNotification', classToPlain(<Object[]>loads), this.httpUtils.getHTTPHeaders()).toPromise().
      then((list) => plainToClass(OrderDispatch, list));
  }

  /**
   * @deprecated
   */
  public updateOnSiteDispatch(addressType: string, buttonType: string, loads: OrderDispatch[]): Promise<any> {
    let params = new HttpParams().set('addressType', addressType).set('buttonType', buttonType);
    return this.httpRestService.put(this.apiUrl + 'updateOnSiteDispatch', classToPlain(<Object[]>loads), this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

  public updateLocationCompleted(address: Address, loads: DriverLoad[]): Promise<any> {
    let params = new HttpParams()
      .set('address', address.id.toString())
      .set('pick', loads.filter((ld) => ld.isPickupLocation()).map((l) => l.dispatchItemId).unique().toString())
      .set('drop', loads.filter((ld) => ld.isDeliveryLocation()).map((l) => l.dispatchItemId).unique().toString());

    return this.httpRestService.put(this.apiUrl + 'update-location-completed', '', this.httpUtils
      .getHTTPHeaders(), params)
      .toPromise();
  }

  public updateLocationOnSite(address: Address, loads: DriverLoad[]): Promise<any> {
    let items = loads.map((l) => l.dispatchItemId).unique().toString();
    let params = new HttpParams()
      .set('address', address.id.toString())
      .set('items', items);
    return this.httpRestService.put(this.apiUrl + 'update-location-on-site', '', this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

  public updateNotCompleted(loads: DriverLoad[]): Promise<any> {
    let items = loads.map((l) => l.dispatchItemId).unique().toString();
    let params = new HttpParams()
      .set('items', items);
    return this.httpRestService.put(this.apiUrl + 'update-not-completed', '', this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

  public updateCancel(address: Address, pick: DriverLoad[], drop: DriverLoad[]): Promise<any> {
    let params = new HttpParams()
      .set('address', address.id.toString())
      .set('pick', pick.map((l) => l.dispatchItemId).unique().toString())
      .set('drop', drop.map((l) => l.dispatchItemId).unique().toString());
    return this.httpRestService.put(this.apiUrl + 'update-cancel', '', this.httpUtils.getHTTPHeaders(), params).toPromise();
  }


  public saveSignaturePdfToDispatchItem(id: string, name: string, comment: string, email: string, imageAsData: string): Promise<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    let params = new HttpParams()
      .set('id', id)
      .set('name', name)
      .set('comment', comment)
      .set('email', email);
    return this.httpRestService.put(this.apiUrl + 'saveSignaturePdfToDispatchItem', imageAsData, httpHeader, params).toPromise();
  }

  /**
   * return ID of stored Document
    * @param loads
   * @param docType
   * @param imagesAsData
   */
  public savePicture(loads: DriverLoad[], docType: string, imagesAsData: string[]): Promise<any> {
    console.log('CALL Save Picture', docType, loads);
    let orderIds: number[] = loads.flatMap((ld) => ld.orders).map((o) => o.orderId).unique();
    let params = new HttpParams()
      .set('docType', docType)
      .set('orderIds', orderIds.join(','));

    return this.httpRestService.put<any>(this.apiUrl + `savePicture`, imagesAsData, this.httpUtils.getHTTPHeaders(), params).toPromise();
  }

  /**
   * @deprecated
   */
  public savePictureOld(loads: OrderDispatch[], docType: string, imagesAsData: string[]): Promise<any> {
    let orderIds: number[] = loads.map((o) => o.orderId).unique();
    let params = new HttpParams().set('docType', docType).set('orderIds', orderIds.join(','));

    return this.httpRestService.put<any>(this.apiUrl + `savePicture`, imagesAsData, this.httpUtils.getHTTPHeaders(), params).toPromise();
  }



}
