import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { UploadedFiles } from '../../../modules/shared/models/documents/uploaded.files';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ObjectDocuments } from '../../../modules/shared/models/documents/object.documents';
import { FileUploadService } from '../../../services/file.upload.service';
import { isNullOrUndefined } from 'util';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { DocumentTypeService } from '../../../services/document-type.service';
import { DocumentType } from '../../../modules/shared/models/documents/document-type';
var FileUploadDialogComponent = /** @class */ (function () {
    function FileUploadDialogComponent(dialogRef, data, fb, fileUploadService, documentTypeService, alertsService) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.fileUploadService = fileUploadService;
        this.documentTypeService = documentTypeService;
        this.alertsService = alertsService;
        this.objectDocuments = new ObjectDocuments();
        this.uploadedFiles = new UploadedFiles();
        this.hasFormErrors = false;
        this.viewLoading = false;
        this.loadingAfterSubmit = false;
        this.showProgressBar = false;
        this.noDescription = false;
        this.documentTypes = [];
        this.progress = { percentage: 0 };
        this.close = function () { return _this.closeDialog(); };
        this.submit = function () { return _this.onSubmit(); };
        this.noDescription = this.data && this.data.noDescription;
        this.carrier = this.data && this.data.carrier;
    }
    FileUploadDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.id = this.data.id;
        this.objectType = this.data.objectType;
        this.viewLoading = true;
        this.createForm();
        this.documentTypeService.findAll({ field: 'name', order: 'asc' })
            .then(function (types) {
            _this.documentTypes = DocumentType.sort(types);
            _this.units = _this.documentTypes[0];
        });
        this.viewLoading = false;
    };
    FileUploadDialogComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            description: [""],
            file: ["", Validators.required],
            docType: ["", Validators.required],
            carrier: [""]
        });
        if (this.data.files) {
            this.selectedFiles = this.data.files;
        }
    };
    FileUploadDialogComponent.prototype.getTitle = function () {
        return 'File Upload';
    };
    FileUploadDialogComponent.prototype.isControlInvalid = function (controlName) {
        var control = this.form.controls[controlName];
        return control.invalid && control.touched;
    };
    FileUploadDialogComponent.prototype.getSelectedFileName = function () {
        return !isNullOrUndefined(this.selectedFiles) && this.selectedFiles.length > 0 ? this.selectedFiles[0].name : "";
    };
    FileUploadDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.id) {
            this.hasFormErrors = false;
            this.loadingAfterSubmit = false;
            var controls_1 = this.form.controls;
            console.log('Controls', controls_1);
            /** check form */
            if (this.form.invalid) {
                Object.keys(controls_1).forEach(function (controlName) { return controls_1[controlName].markAsTouched(); });
                this.hasFormErrors = true;
                return;
            }
            this.progress.percentage = 0;
            this.showProgressBar = true;
            this.error = undefined;
            //      this.objectDocuments.objectId = 0; // this.id;
            //      this.objectDocuments.objectType = this.objectType;
            //      this.objectDocuments.uploadedFiles = this.uploadedFiles;
            var data = {};
            if (this.needCarrier && this.carrier) {
                data['carrier'] = this.carrier.id;
            }
            this.fileUploadService.uploadMultipleDocuments(this.documentType, this.objectType, this.id, this.selectedFiles, data)
                .subscribe(function (event) {
                if (event.type === HttpEventType.UploadProgress) {
                    _this.progress.percentage = Math.round(100 * event.loaded / event.total);
                }
                else if (event instanceof HttpResponse) {
                    _this.dialogRef.close();
                }
            }, function (error) {
                _this.showProgressBar = false;
                console.log('Error', error.toString(), error);
                _this.error = error;
                _this.alertsService.error(error, "File Was Not Uploaded");
            });
            this.selectedFiles = undefined;
        }
    };
    FileUploadDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    FileUploadDialogComponent.prototype.onAlertClose = function ($event) {
        this.hasFormErrors = false;
    };
    FileUploadDialogComponent.prototype.onUnitsChanged = function (event) {
        this.documentType = event;
        this.objectDocuments.documentType = event;
        if (this.needCarrier) {
            this.form.controls['carrier'].setValidators(Validators.required);
            console.log('Set Validations', this.form);
        }
        else {
            this.form.controls['carrier'].clearValidators();
            this.form.controls['carrier'].setErrors(null);
            console.log('Clear Validations', this.form);
        }
    };
    FileUploadDialogComponent.prototype.selectFile = function (event) {
        this.progress.percentage = 0;
        this.showProgressBar = false;
        this.selectedFiles = event.target.files;
    };
    Object.defineProperty(FileUploadDialogComponent.prototype, "isPOD", {
        get: function () {
            return this.documentType && this.documentType.isPOD;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploadDialogComponent.prototype, "needCarrier", {
        get: function () {
            return this.objectType === 'ORDER' && this.isPOD;
        },
        enumerable: true,
        configurable: true
    });
    return FileUploadDialogComponent;
}());
export { FileUploadDialogComponent };
