var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RestService } from "./rest.service";
import { convertMawbNumber, order1CByAmsStatusesClass } from '../modules/shared/services/oms-converters.service';
import { BaseColumn, FieldType, HyperLinkColumn, VolumeColumn, WeightColumn } from '../modules/shared/components/base/data-table/columns/column-types';
var AccountService = /** @class */ (function () {
    function AccountService(restService) {
        this.restService = restService;
        this.columnsSettingsSubject = new Subject();
        this.loadColumnsSubject = new Subject();
        this.tableSettingsSubject = new Subject();
        this.hideShowColumnsSubject = new Subject();
    }
    AccountService.prototype.showHideShowPopUp = function (columnSettings) {
        this.hideShowColumnsSubject.next({
            columnSettings: columnSettings,
            show: true
        });
    };
    AccountService.prototype.hideShowHidePopUp = function () {
        this.hideShowColumnsSubject.next({
            columnSettings: [],
            show: false
        });
    };
    AccountService.prototype.createAccount = function (user) {
        return this.restService.post('/account/register', user).pipe(map(function (resp) { return console.log(resp); }));
    };
    AccountService.prototype.getLoadColumns = function () {
        var columns = [];
        columns.push(new BaseColumn(1, 'LD#', 'load', FieldType.TEXT, '40px'), new BaseColumn(2, 'LD#', 'load', FieldType.TEXT, '40px'));
        this.loadColumnsSubject.next(columns);
    };
    AccountService.prototype.getOrderTableConfigurations = function () {
        var columnsSettings = [];
        columnsSettings.push(new (/** @class */ (function (_super) {
            __extends(class_1, _super);
            function class_1() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            class_1.prototype.getHyperLink = function (order) {
                return ['order', { id: order ? order.id : null }];
            };
            return class_1;
        }(HyperLinkColumn)))(1, 'JJS #', 'orderNumber', FieldType.TEXT, '100px'));
        columnsSettings.push(new BaseColumn(1, 'Customer', 'customerName', FieldType.TEXT, '200px'));
        columnsSettings.push(new BaseColumn(11, 'Airline', 'master.masterAir.airline.name', FieldType.TEXT, '100px'));
        columnsSettings.push(new BaseColumn(12, 'Cargo Bldg', 'master.shipment.addressDelivery', FieldType.ADDRESS, '100px'));
        columnsSettings.push(new BaseColumn(13, 'Arrival', 'master.shipment.dateDeliveryActual', FieldType.DATETIME, '100px'));
        //    columnsSettings.push(new ColumnSettings(14, 'MAWB#', 'master.mawbNumber', 'master.mawbNumber', FieldType.TEXT, '100px'));
        columnsSettings.push(new (/** @class */ (function (_super) {
            __extends(class_2, _super);
            function class_2() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            class_2.prototype.getHyperLink = function (order) {
                return ['../master',
                    { id: order && order.master ? order.master.id : null }];
            };
            class_2.prototype.convert = function (row, value) { return convertMawbNumber(value); };
            return class_2;
        }(HyperLinkColumn)))(14, 'MAWB#', 'master.mawbNumber', FieldType.TEXT, '100px'));
        columnsSettings.push(new BaseColumn(15, 'HAWB#', 'hawb', FieldType.TEXT, '100px'));
        columnsSettings.push(new BaseColumn(16, 'LFD', 'master.dateLastFreeDay', FieldType.DATE, '80px'));
        columnsSettings.push(new BaseColumn(17, 'ISC', 'master.dateISCPaid', FieldType.BOOLEAN, '40px'));
        columnsSettings.push(new BaseColumn(18, '1C', 'date1C', FieldType.BOOLEAN, '40px', { class: function (o) { return order1CByAmsStatusesClass(o); } }));
        columnsSettings.push(new BaseColumn(19, '1F', 'date1F', FieldType.BOOLEAN, '40px'));
        columnsSettings.push(new BaseColumn(20, 'HAZ', 'isHazardous', FieldType.BOOLEAN, '40px'));
        //    columnsSettings.push(new ColumnSettings(4, 'JJS Master #', 'masterNumber', 'masterNumber', FieldType.TEXT, '100px'));
        columnsSettings.push(new BaseColumn(2, 'PickUp Address', 'addressPickup', FieldType.ADDRESS, '250px'));
        columnsSettings.push(new BaseColumn(3, 'Pick UP', 'child.pickupDate', FieldType.DATE, '120px'));
        columnsSettings.push(new BaseColumn(4, 'Delivery Address', 'addressDelivery', FieldType.ADDRESS, '250px'));
        columnsSettings.push(new BaseColumn(5, 'Est. Delivery', 'dateDeliveryReq', FieldType.DATE, '120px'));
        columnsSettings.push(new BaseColumn(6, 'Act. Delivery', 'dateDeliveryAct', FieldType.DATE, '120px'));
        columnsSettings.push(new BaseColumn(7, 'PCS(HU)', 'pieces', FieldType.TEXT, '100px'));
        columnsSettings.push(new WeightColumn(8, 'GW(LB)', 'weight', FieldType.TEXT, '100px'));
        columnsSettings.push(new VolumeColumn(9, 'VOL(CFT)', 'volume', FieldType.TEXT, '100px'));
        //    columnsSettings.push(new ColumnSettings(10, 'Load', 'loadsNumber', 'child.loadID', FieldType.TEXT, '80px'));
        this.columnsSettingsSubject.next(columnsSettings);
    };
    AccountService.prototype.getTableColumnConfigurations = function () {
        var columnsSettings = [];
        columnsSettings.push({ name: 'JJS #', prop: 'orderNumber' });
        columnsSettings.push({ name: 'Order Pieces', prop: 'pieces' });
        columnsSettings.push({ name: 'Customer Name', prop: 'customerName' });
        columnsSettings.push({ name: 'JJS Master #', prop: 'masterNumber' });
        columnsSettings.push({ name: 'PickUp Address', prop: 'masterNumber' });
        columnsSettings.push({ name: 'Load', prop: 'loadsNumber' });
        this.tableSettingsSubject.next(columnsSettings);
    };
    return AccountService;
}());
export { AccountService };
