<oms-base-dialog [icon]="'fa fa-flag'" [title]="'Order Notifications'" [auto-close]="false" [loading]="viewLoading"
                 [width]="'1200px'"
                 [can-ok]="canSave()"
                 [store-position]="false"
                 (ok)="onSave()"
                 [btnOk]="{caption: 'Submit', icon: 'fa fa-send'}">


  <div class="row form-group" style="display: flex; flex-direction: row">
    <div *ngIf="!isPublic && customSubscriptions?.length" class="table-responsive" style="flex: 1; margin: 5px;">
      <table class="table table-hover table-condensed table-bordered table-striped" style="max-height: 200px;">
        <thead>
          <tr>
            <th style="width: auto;">Subscribed <a (click)="sendEmail()" style="float: right"><i class="fas fa-envelope"></i>&nbsp;Send email&hellip;</a></th>
            <th style="width: 70px; text-align: center;"><b>JJS#</b></th>
            <th style="width: 20px;"></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let rec of customSubscriptions; let i=index;">
            <td style="width: auto;">{{rec.customEmail}}</td>
            <td style="width: 70px; vertical-align: middle; text-align: center;">{{rec.order.id | order_number}}</td>
            <td style="width: 20px; vertical-align: middle; text-align: center;"><a popover="Remove recipient" (click)="removeRecipient(rec);"><i class="fa fa-user-times"></i></a></td>
          </tr>
        </tbody>

      </table>
    </div>

    <oms-string-input [label]="'Custom Email(s):'" [labelInline]="false" [required]="needCustomEmail"
                      [multiline]="3"
                      style="flex: 1; margin: 5px;"
                      [(ngModel)]="customEmail"
                      [placeholder]="'Enter Email(s) separated with space or semicolon'">
      <span toolbar-right>
        <button class="btn btn-xs btn-primary" [disabled]="!customEmail" (click)="addCustomEmails()">Add Emails</button>
      </span>
    </oms-string-input>

  </div>

  <div class="row form-group">
    <oms-string-input [label]="'Description:'" [labelInline]="false" style="width: 100%; margin: 5px;"
                      [(ngModel)]="comment"
                      [placeholder]="placeholder">
    </oms-string-input>
  </div>

  <div class="oms-modal widget">

    <div class="container">

      <div class="row">
        <div class="col-md-4 no-gutters order-subscription-dialog__select-all">
          <oms-checkbox class="order-subscription-dialog__select-all-checkbox"
                        [checked]="isSelectAll()"
                        [indeterminate]="isIndeterminateSelectAll()"
                        (checkedChange)="onSelectAll()"
                        [label]="'Select All'"></oms-checkbox>
        </div>
      </div>

      <div class="row order-subscription-dialog__statuses-panel">
        <div *ngFor="let statusColumn of statusesColumns; let last = last; let index = index" class="no-gutters col-sm-4">
          <div class="col align-self-start order-subscription-dialog__status" *ngFor="let status of statusColumn">
            <div class="form-group">
              <oms-checkbox [checked]="isSelectStatus(status)"
                            [indeterminate]="isIndeterminateStatus(status)"
                            (checkedChange)="onStatusChange($event, status)"
                            [label]="getStatusLabel(status)"></oms-checkbox>
            </div>
          </div>
        </div>

        <div *ngFor="let status of addStatuses; let last = last; let index = index" class="no-gutters col-sm-4">
          <div class="form-group">
            <oms-checkbox [checked]="isSelectStatus(status)"
                          [indeterminate]="isIndeterminateStatus(status)"
                          (checkedChange)="onStatusChange($event, status)"
                          [label]="status.label"></oms-checkbox>
          </div>
        </div>
      </div>
    </div>

  </div>
</oms-base-dialog>
