var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Exclude, Transform, Type } from 'class-transformer';
import { latest } from '../../../common/oms-date-time.utils';
import { checkNumber, equals } from '../../../_helpers/utils';
import { FillStatus } from '../../../components/common/fill-indicator/fill-indicator.component';
import { convertMasterNumber, convertOrderNumber } from '../services/oms-converters.service';
import { Master } from './master/master';
import { MasterAir } from './master/master.air';
import { Load, LoadType } from './load';
import { ShipmentContent } from './shipment.content';
import { Address, CfsLocation } from './address';
import { Customer } from './customer';
import { Shipment } from './shipment';
import { MasterStatusId, transformMasterStatusId } from './master/master-status';
import { OmsConstants } from '../../../common/oms-constants.service';
import { BaseEntity } from "./base-entity";
import { OrderMode, OrderModes, transformOrderMode } from "./order/order-mode";
import { isNullOrUndefined } from "util";
import { FreightForwarder } from "./freight.forwarder";
import { Uld, UldRecovery } from "./master/masterAirUld";
import { Carrier } from "./carrier";
import { Airport } from "./air";
import { StringUtil } from "../../../_helpers/string.util";
var ɵ0 = function (v, o, tt) { return transformOrderMode(v, o, tt); }, ɵ1 = function (v, o, tt) { return transformMasterStatusId(v, o, tt); }, ɵ2 = function () { return Date; }, ɵ3 = function () { return Date; }, ɵ4 = function () { return Date; }, ɵ5 = function () { return Date; }, ɵ6 = function () { return Date; }, ɵ7 = function () { return Date; }, ɵ8 = function () { return Date; }, ɵ9 = function () { return Date; }, ɵ10 = function () { return Date; }, ɵ11 = function () { return Customer; }, ɵ12 = function () { return FreightForwarder; }, ɵ13 = function () { return Date; }, ɵ14 = function () { return Date; }, ɵ15 = function () { return Date; }, ɵ16 = function () { return Date; }, ɵ17 = function () { return Date; }, ɵ18 = function () { return Date; }, ɵ19 = function () { return Date; }, ɵ20 = function () { return Date; }, ɵ21 = function () { return Address; }, ɵ22 = function () { return Address; }, ɵ23 = function () { return CfsLocation; }, ɵ24 = function () { return Address; }, ɵ25 = function () { return Carrier; }, ɵ26 = function () { return ShipmentContent; }, ɵ27 = function () { return Master; }, ɵ28 = function () { return Airport; }, ɵ29 = function () { return Uld; }, ɵ30 = function () { return UldRecovery; };
var Order = /** @class */ (function (_super) {
    __extends(Order, _super);
    function Order(id) {
        var _this = _super.call(this, id) || this;
        _this.rowId = 'Order';
        _this.javaClassName = 'com.oms.entity.order.Order';
        _this.info = {};
        _this.ref3 = ''; // ref3: MAWB ||  BOOKING # || MBL , depending on Mode
        _this.isAcknowledged = false;
        _this.deliveryApproval = false;
        _this.pttCreated = false;
        _this.dispatchedStatus = false;
        _this.hasProblem = false;
        _this.deliveryAppointmentRequired = false;
        //  recoveryMasterId: number;
        _this.status = MasterStatusId.NEW;
        _this.shipmentContents = [];
        // dynamically updating internal data (not retrieved by Service)
        _this.data = {};
        /*** @deprecated ***/
        _this.ulds = [];
        _this.uldsRecovered = [];
        _this.dateCreated = new Date();
        return _this;
    }
    Object.defineProperty(Order.prototype, "mawb", {
        get: function () {
            return this.ref3;
        },
        set: function (value) {
            this.ref3 = value;
        },
        enumerable: true,
        configurable: true
    });
    Order.fromOrderDispatch = function (dispatch) {
        var order = new Order(dispatch.orderId);
        order.customerRef = dispatch.customerRef;
        order.customer = dispatch.customer;
        order.freightForwarder = dispatch.freightForwarder;
        order.hawb = dispatch.hawb;
        order.id = dispatch.orderId;
        order.genericMode = dispatch.orderMode;
        order.pieces = dispatch.pieces;
        order.hu = dispatch.hu;
        order.weight = dispatch.weight;
        order.ref3 = dispatch.mawb;
        order.addressCfs = dispatch.addressPickup;
        order.addressDelivery = dispatch.addressDelivery;
        order.dispatchNotes = dispatch.dispatchNotes;
        order.hot = dispatch.hot;
        order.cod = dispatch.cod;
        order.purchaseOrder = dispatch.purchaseOrder;
        order.status = dispatch.orderStatus && dispatch.orderStatus.id;
        order.requestedDeliveryDate = dispatch.requestedDeliveryDate;
        order.quote = dispatch.quote;
        if (dispatch.masterId) {
            order.master = new Master();
            order.master.id = dispatch.masterId;
            //      order.master.mawbNumber = dispatch.mawb;
        }
        return order;
    };
    Order.newGenericOrder = function (mode) {
        if (mode === void 0) { mode = OrderModes.defaultMode; }
        var order = new Order();
        order.genericMode = mode;
        order.status = MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH;
        /*
            let shipmentContent = new ShipmentContent();
            shipmentContent.shipment = new Shipment();
            shipmentContent.order = order;
            order.shipmentContents.push(shipmentContent);
        
            let load = new Load();
            load.loadType = LoadType.DELIVERY;
            shipmentContent.shipment.load = load;
            load.shipment = shipmentContent.shipment;
        */
        return order;
    };
    Order.newOrder = function (type) {
        var order = new Order();
        var shipmentContent = new ShipmentContent();
        shipmentContent.shipment = new Shipment();
        shipmentContent.shipment.master = new Master();
        order.master = shipmentContent.shipment.master;
        // todo depends on type
        shipmentContent.shipment.master.masterAir = new MasterAir();
        shipmentContent.order = order;
        order.shipmentContents.push(shipmentContent);
        return order;
    };
    Order.createOrderByMaster = function (master) {
        var order = new Order();
        order.master = master;
        order.addressDelivery = master.orders.asUniqueValue(function (o) { return o.addressDelivery; }) || master.orders.lastValueOf(function (o) { return o.addressDelivery; });
        order.customerRef = master.customerRef;
        // 1. Arrived with Master's first shipment shipment
        var shipmentContent = new ShipmentContent();
        shipmentContent.shipment = master.firstShipment.shipment;
        shipmentContent.shipment.master = master;
        shipmentContent.order = order;
        order.shipmentContents.push(shipmentContent);
        //    if (master.orders.length === 0) {
        // Create New Loads for first Order
        //      console.log('CREATING');
        var content;
        /*
              // 4. Create Recovery Load Shipment
              content = new ShipmentContent();
              content.shipment = new Shipment();
              content.shipment.load = new Load();
              content.shipment.load.loadType = LoadType.RECOVERY;
              content.shipment.load.shipment = content.shipment;
              content.order = order;
              order.shipmentContents.push(content);
         */
        // 5. Create Delivery Load Shipment
        content = new ShipmentContent();
        content.shipment = new Shipment();
        content.shipment.load = new Load();
        content.shipment.load.loadType = LoadType.DELIVERY;
        content.shipment.load.shipment = content.shipment;
        content.order = order;
        order.shipmentContents.push(content);
        /*    } else {
              // 2. Share existing loads of Master
              let loads: Set<Load> = new Set<Load>();
              master.orders.forEach(o1 => {
                o1.shipmentContents.forEach(sc1 => {
                  if (sc1.shipment.load && !loads.has(sc1.shipment.load)) {
                    let sc2: ShipmentContent = new ShipmentContent();
                    sc2.shipment = sc1.shipment;
                    sc2.order = order;
                    order.shipmentContents.push(sc2);
                    loads.add(sc1.shipment.load);
                  }
                });
              });
            } */
        return order;
    };
    Object.defineProperty(Order.prototype, "customerName", {
        get: function () {
            return this.customer ? this.customer.name : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "loadShipments", {
        get: function () {
            return this.shipmentContents
                .filter(function (sc) { return sc.shipment && sc.shipment.load; })
                .map(function (sc) { return sc.shipment; });
            /*    let loadShipments: Shipment[] = [];
                this.shipmentContents.forEach((content, index, array) => {
                  if (content.shipment && content.shipment.load) {
                    loadShipments.push(content.shipment);
                  }
                });
                return loadShipments;
             */
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "loadShipmentContents", {
        get: function () {
            return this.shipmentContents.filter(function (sc) { return sc.shipment && sc.shipment.load; });
            /*    let loadShipmentContents: ShipmentContent[] = [];
                this.shipmentContents.forEach((content, index, array) => {
                  if (content.shipment && content.shipment.load) {
                    loadShipmentContents.push(content);
                  }
                });
                return loadShipmentContents;
             */
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "addressPickup", {
        get: function () {
            var master = this.master;
            return master ? (master.shipment ? master.shipment.addressDelivery : null) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "orderNumber", {
        get: function () {
            return convertOrderNumber(this.id, this.isRecovery);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "isRecovery", {
        get: function () {
            return this.genericMode === OrderMode.RECOV;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "masterNumber", {
        get: function () {
            return this.master ? convertMasterNumber(this.master.id) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "loadsNumber", {
        get: function () {
            var loads = this.loadShipments;
            var loadIDs = '';
            loads.forEach(function (load) {
                loadIDs = loadIDs + ' ' + load.load.id;
            });
            return loadIDs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "availablePieces", {
        get: function () {
            var loadShipmentContents = this.loadShipmentContents;
            var alreadyInLoad = 0;
            loadShipmentContents.forEach(function (content) {
                alreadyInLoad += content.pieces;
            });
            return this.pieces - alreadyInLoad;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "addressDelivery_", {
        get: function () {
            return this.addressDelivery;
        },
        set: function (address) {
            this.addressDelivery = address;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "contentsDelivery", {
        get: function () {
            return this.shipmentContents.filter(function (sc) { return sc.shipment && sc.shipment.load && sc.shipment.load.isDelivery; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "contentsRecovery", {
        get: function () {
            return this.shipmentContents.filter(function (sc) { return sc.shipment && sc.shipment.load && sc.shipment.load.isRecovery; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "masterContents", {
        get: function () {
            return this.shipmentContents.filter(function (sc) { return sc.shipment && !sc.shipment.load; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "partsArriving", {
        get: function () {
            var _this = this;
            var scs = [];
            this.master.masterShipments.forEach(function (ms) {
                ms.shipment.shipmentContents.filter(function (sc) { return equals(_this, sc.order); }).forEach(function (sc) {
                    scs.push(sc);
                });
            });
            return scs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "hu_", {
        get: function () {
            return ~~this.huReceived || ~~this.hu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "fillStatusPrc", {
        get: function () {
            return (this.pcsReceived * 100 / this.pieces) || 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "fillStatus", {
        get: function () {
            var received = this.pcsReceived;
            if (received > 0) {
                if (received < this.pieces) {
                    return FillStatus.HALF;
                }
                else if (received > this.pieces) {
                    return FillStatus.OVER;
                }
                else {
                    return FillStatus.FULL;
                }
            }
            else {
                return FillStatus.EMPTY;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "fillStatusDescription", {
        get: function () {
            if (this.direct) {
                return 'Direct';
            }
            else {
                return StringUtil.receivingDescription(this.pieces, this.pcsReceived);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "pcsArrived", {
        get: function () {
            return this.partsArriving.aggregate(function (acc, sc) { return acc + (sc.isArrived ? ~~sc.pieces : 0); }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "documentNumber", {
        get: function () {
            return convertOrderNumber(this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "dateArrivalAct", {
        get: function () {
            return latest(this.masterContents, function (sc) { return sc.shipment.dateDeliveryActual; });
        },
        set: function (date) {
            if (!equals(date, this.dateArrivalAct)) {
                this.masterContents.forEach(function (sc) {
                    sc.shipment.dateDeliveryActual = date;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "dateArrivalEst", {
        get: function () {
            return latest(this.masterContents, function (sc) { return sc.shipment.dateDeliveryExpectation; });
        },
        set: function (date) {
            if (!equals(date, this.dateArrivalEst)) {
                this.masterContents.forEach(function (sc) {
                    sc.shipment.dateDeliveryExpectation = date;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "isOSD", {
        get: function () {
            return checkNumber(this.shortageNotes, 0) + checkNumber(this.overageNotes, 0) + checkNumber(this.damageNotes, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "osdNotes", {
        get: function () {
            return ~~this.overageNotes + ~~this.shortageNotes + ~~this.damageNotes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "noCfs", {
        get: function () {
            return this.master && this.master.noCfs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "nonAMS", {
        get: function () {
            return this.master && this.master.nonAMS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "direct", {
        get: function () {
            return this.master && this.master.direct;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "deliveryDriver", {
        get: function () {
            var drivers = this
                .contentsDelivery
                .filter(function (sc) { return !sc.shipment.hasProblem; })
                .map(function (sc) { return sc.shipment.load.driver; })
                .unique();
            //    console.log('FOUND DRIVERS', drivers);
            return (!drivers || !drivers.length) ?
                OmsConstants.EMPTY_VALUE : (drivers.length > 1 ? OmsConstants.MULTIPLE_VALUE : drivers[0].fullName || OmsConstants.EMPTY_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "masterID", {
        get: function () {
            return this.master ? this.master.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "isPiecesInAMS", {
        get: function () {
            return this.cbpStatusPiecesNotValid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "isGeneric", {
        get: function () {
            return !isNullOrUndefined(this.genericMode);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Order.prototype, "isAirImport", {
        get: function () {
            return OrderModes.isAirImport(this.genericMode);
        },
        enumerable: true,
        configurable: true
    });
    Order.prototype.trackBy = function () {
        return this.id;
    };
    __decorate([
        Exclude() // have to create additional Order-Descendant object OrderRoute, or use OrderDispatch instead to store this info
        ,
        __metadata("design:type", Object)
    ], Order.prototype, "info", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean)
    ], Order.prototype, "selected", void 0);
    __decorate([
        Transform(ɵ0),
        __metadata("design:type", Number)
    ], Order.prototype, "genericMode", void 0);
    __decorate([
        Transform(ɵ1),
        __metadata("design:type", Number)
    ], Order.prototype, "status", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Date)
    ], Order.prototype, "date1C", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Date)
    ], Order.prototype, "date1CUnchecked", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", Date)
    ], Order.prototype, "date1F", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Date)
    ], Order.prototype, "dateISCPaid", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Date)
    ], Order.prototype, "dateCfsInAct", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", Date)
    ], Order.prototype, "dateCfsInEst", void 0);
    __decorate([
        Type(ɵ8),
        __metadata("design:type", Date)
    ], Order.prototype, "dateCfsOutAct", void 0);
    __decorate([
        Type(ɵ9),
        __metadata("design:type", Date)
    ], Order.prototype, "dateCfsOutEst", void 0);
    __decorate([
        Type(ɵ10),
        __metadata("design:type", Date)
    ], Order.prototype, "dateCfsFreeTime", void 0);
    __decorate([
        Type(ɵ11),
        __metadata("design:type", Customer)
    ], Order.prototype, "customer", void 0);
    __decorate([
        Type(ɵ12),
        __metadata("design:type", FreightForwarder)
    ], Order.prototype, "freightForwarder", void 0);
    __decorate([
        Type(ɵ13),
        __metadata("design:type", Date)
    ], Order.prototype, "dateDeleted", void 0);
    __decorate([
        Type(ɵ14),
        __metadata("design:type", Date)
    ], Order.prototype, "dateDocsToWarehouse", void 0);
    __decorate([
        Type(ɵ15),
        __metadata("design:type", Date)
    ], Order.prototype, "dateBilled", void 0);
    __decorate([
        Type(ɵ16),
        __metadata("design:type", Date)
    ], Order.prototype, "datePickupEst", void 0);
    __decorate([
        Type(ɵ17),
        __metadata("design:type", Date)
    ], Order.prototype, "datePickupAct", void 0);
    __decorate([
        Type(ɵ18),
        __metadata("design:type", Date)
    ], Order.prototype, "dateDeliveryReq", void 0);
    __decorate([
        Type(ɵ19),
        __metadata("design:type", Date)
    ], Order.prototype, "dateDeliveryAct", void 0);
    __decorate([
        Type(ɵ20),
        __metadata("design:type", Date)
    ], Order.prototype, "requestedDeliveryDate", void 0);
    __decorate([
        Type(ɵ21),
        __metadata("design:type", Address)
    ], Order.prototype, "addressDelivery", void 0);
    __decorate([
        Type(ɵ22),
        __metadata("design:type", Address)
    ], Order.prototype, "addressCfs", void 0);
    __decorate([
        Type(ɵ23),
        __metadata("design:type", CfsLocation)
    ], Order.prototype, "cfsLocation", void 0);
    __decorate([
        Type(ɵ24),
        __metadata("design:type", Address)
    ], Order.prototype, "addressCargoBuilding", void 0);
    __decorate([
        Type(ɵ25),
        __metadata("design:type", Carrier)
    ], Order.prototype, "carrier", void 0);
    __decorate([
        Type(ɵ26),
        __metadata("design:type", Array)
    ], Order.prototype, "shipmentContents", void 0);
    __decorate([
        Type(ɵ27),
        __metadata("design:type", Master)
    ], Order.prototype, "master", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Object)
    ], Order.prototype, "data", void 0);
    __decorate([
        Type(ɵ28),
        __metadata("design:type", Airport)
    ], Order.prototype, "originAirport", void 0);
    __decorate([
        Type(ɵ29),
        __metadata("design:type", Array)
    ], Order.prototype, "ulds", void 0);
    __decorate([
        Type(ɵ30),
        __metadata("design:type", Array)
    ], Order.prototype, "uldsRecovered", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Address),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "addressPickup", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "orderNumber", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "isRecovery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "contentsDelivery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "contentsRecovery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "masterContents", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "partsArriving", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "deliveryDriver", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "masterID", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "isGeneric", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Order.prototype, "isAirImport", null);
    return Order;
}(BaseEntity));
export { Order };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30 };
