import { ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { convertLocalDateToEST, ensureDate } from '../../../../../../common/oms-date-time.utils';
import { isNullOrUndefined } from 'util';
var DateTimeRangePopupComponent = /** @class */ (function () {
    function DateTimeRangePopupComponent() {
        this.onCustomButton = new EventEmitter();
        this.valueChange = new EventEmitter();
        this.appendTo = 'body';
        this.showPopup = false;
        this.showPopupChange = new EventEmitter();
        this.showDate = true;
        this.showWeeks = false;
        this.datepickerMode = 'day';
        this.minDate = null;
        this.maxDate = null;
        this.dateDisabled = [];
        this.fromDate = null;
        this.toDate = null;
        this.isOpening = false;
        this.isDropUp = false;
        this.est = false;
        this.popupStyles = null;
    }
    Object.defineProperty(DateTimeRangePopupComponent.prototype, "position", {
        set: function (value) {
            this.left = value.left;
            this.top = value.top;
            this.bottom = value.bottom;
            this.right = value.right;
        },
        enumerable: true,
        configurable: true
    });
    DateTimeRangePopupComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (!this.nowButton) {
            this.nowButton = { show: true, label: 'Now', cssClass: 'btn btn-secondary btn-sm' };
        }
        if (!this.clearButton) {
            this.clearButton = { show: true, label: 'Clear', cssClass: 'btn btn-secondary btn-sm' };
        }
        if (!this.closeButton) {
            this.closeButton = { show: true, label: 'Close', cssClass: 'btn btn-secondary btn-sm' };
        }
        // user maybe typing a value into an input box, so would come in as string
        this.fromDate = ensureDate(this.valueFromDate) || new Date();
        this.toDate = this.toDate || ensureDate(this.valueToDate);
        if (changes.left || changes.right || changes.top || changes.bottom || changes.position) {
            this.updatePopupStyles();
        }
        // toggle if open
        if (changes.showPopup) {
            this.showPopup = changes.showPopup.currentValue === true;
            if (this.showPopup) {
                setTimeout(function () {
                    _this.focus();
                }, 100);
            }
        }
    };
    DateTimeRangePopupComponent.prototype.onOpenChange = function () {
        var _this = this;
        this.isOpening = true;
        setTimeout(function () { return _this.isOpening = false; }, 250);
    };
    DateTimeRangePopupComponent.prototype.onClear = function () {
        this.onPickerFromDateChange(null, false);
        this.onPickerToDateChange(null, false);
        this.tryClosePopup();
    };
    DateTimeRangePopupComponent.prototype.onCustomButtonClick = function (event) {
        this.onClose(false);
        this.onCustomButton.emit(event);
    };
    DateTimeRangePopupComponent.prototype.onClose = function (apply) {
        if (this.showPopup) {
            this.showPopup = false;
            this.showPopupChange.emit(false);
            if (apply) {
                var valueFromTo = { from: convertLocalDateToEST(this.fromDate), to: convertLocalDateToEST(this.toDate), est: this.est };
                this.valueChange.emit(valueFromTo);
            }
        }
    };
    DateTimeRangePopupComponent.prototype.onPickerFromDateChange = function (data, isClose) {
        if (isClose === void 0) { isClose = true; }
        this.fromDate = data;
        if (isClose) {
            this.tryClosePopup();
        }
    };
    DateTimeRangePopupComponent.prototype.onPickerToDateChange = function (data, isClose) {
        if (isClose === void 0) { isClose = true; }
        this.toDate = data;
        if (isClose) {
            this.tryClosePopup();
        }
    };
    DateTimeRangePopupComponent.prototype.tryClosePopup = function () {
        if (!this.isOpening) {
            if (this.showDate === true && !isNullOrUndefined(this.fromDate) && !isNullOrUndefined(this.toDate)) {
                this.onClose(true);
            }
        }
    };
    DateTimeRangePopupComponent.prototype.focus = function () {
        if (this.btnClose) {
            this.btnClose.nativeElement.focus();
        }
    };
    /*
      get maxDateInESTFunc() {
        return maxETCDateFromNow();
      }
     */
    DateTimeRangePopupComponent.prototype.updatePopupStyles = function () {
        var styles = {};
        styles['top'] = isNullOrUndefined(this.top) ? 'auto' : this.top + 'px';
        var windowWidth = window.innerWidth;
        if (this.left + 600 > windowWidth) {
            styles['left'] = windowWidth - (this.left + 600) + 'px'; // extra right
        }
        else if (this.left < 233) {
            styles['left'] = -this.left + 'px'; // extra left
        }
        else {
            styles['left'] = '-233px';
        }
        styles['right'] = isNullOrUndefined(this.right) ? 'auto' : this.right + 'px';
        styles['bottom'] = isNullOrUndefined(this.bottom) ? 'auto' : this.bottom + 'px';
        this.popupStyles = styles;
    };
    return DateTimeRangePopupComponent;
}());
export { DateTimeRangePopupComponent };
