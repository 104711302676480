import {OrderDispatch} from "../../../shared/models/dispatch/order-dispatch";
import {isNullOrUndefined} from "util";
import {DriverOrderDispatch} from "../../../shared/models/dispatch/driver-order-dispatch";
import {OmsDialogsService} from "../../../../components/common/oms-dialogs";
import {Injectable} from "@angular/core";
import {FileUploadService} from "../../../../services/file.upload.service";
import {AddressMapInfoDialogComponent} from "../../../shared/components/address-map-info-dialog/address-map-info-dialog.component";
import {OrdersService} from "../../../../services";
import {DriverService} from "../../../../services/driver-service";
import {MatDialog} from "@angular/material/dialog";
import {
  BaseColumn, CheckboxColumn, DateCheckboxColumn, DateColumn, DateTimeColumn,
  FieldType,
  HyperLinkColumn,
  TextColumn, TreeColumn, WeightColumn
} from "../../../shared/components/base/data-table/columns/column-types";
import {ColumnType, downloadManifest} from "../../../../common/column-type";
import {
  convertDateTime,
  convertLoadType,
  convertManifestNumber,
  convertOrderNumber, convertOrderRef2, convertOrderRef3, convertSubString, valueOrMultiple, valueOrMultipleValues
} from "../../../shared/services/oms-converters.service";
import {LoadType} from "../../../shared/models";
import {
  UldsDialogComponent,
  UldsDialogInputData
} from "../../../../components/common/ulds-dialog/ulds-dialog.component";
import {ColumnWidth} from "../../../../common/column-width";
import * as moment from "moment";
import {OmsConstants} from "../../../../common/oms-constants.service";
import {ColumnIds} from "../../../../common/column-ids";
import {NumberUtils} from "../../../../_helpers/number.utils";
import {ActivatedRoute, Router} from "@angular/router";
import {DispatchService} from "../../../shared/services/dispatch/dispatch.service";
import {OrderModes} from "../../../shared/models/order/order-mode";
import {EMPTY_LINK} from "../../../../common/oms-types";

@Injectable()
export class DispatchColumnsService {


  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private dialogs: OmsDialogsService,
    private file: FileUploadService,
    private ordersService: OrdersService,
    private driverService: DriverService,
    private dispatchService: DispatchService,

  ) {
  }


  allColumns = {
    STATUS: new TextColumn('orderStatus', 'STATUS', 'orderStatus.label', FieldType.TEXT, '100px', {tooltip: row => row.orderStatus ? row.orderStatus.label : null}),

    ORDER_MODE: ColumnType.ORDER_MODE,

    MANIFEST_ID: ColumnType.DISPATCHED_MANIFEST_ID.clone()
      .setButton({
        icon: 'fa fa-download',
        tooltip: 'Download Manifest',
        onClick: row => downloadManifest(this.file, row),
        newPage: true
      })
      .setHandlers({
        converter: (row, id) => convertManifestNumber(id),
        onClick: manifest => this.openManifestForm(manifest)
      }),

    LOAD_TYPE: new TextColumn('loadType', 'Type', 'loadType', FieldType.TEXT, '60px')
      .setHandlers({
        converter: (row, value) => convertLoadType(value),
        tooltip: row => row.orderStatus ? row.orderStatus.label : null
      })
      .setSearch({searchable: true, searchType: 'list', multiple: true, items: BaseColumn.loadTypeFilterItems}),

    ORDER_ID: new HyperLinkColumn('orderId', 'Order', 'orderId', FieldType.TEXT, '60px')
      .setHandlers({
        link: (row, value) => {
          return row.isRecovery ? undefined : {path: ['order'], params: {
            'id': value,
            'dispatch': row.dispatchId || undefined,
            'shipment': row.dispatchShipmentId || undefined
          }};
        },
        converter: (row, value) => row.isRecovery ? 'RECOV' : convertOrderNumber(value),
        tooltip: row => row.isRecovery ? 'Recovery Load' : row.orderStatus ? row.orderStatus.label : null
      })
      .setSearch({searchable: true}),
//      .setSearch({searchable: true, searchType: 'list', items: BaseColumn.loadTypeFilterItems}),


    PROBLEM: new CheckboxColumn('hasProblem', 'Prblm', 'hasProblem', FieldType.DIALOG, '40px',
      {
        class: (row) => {
          return row.hasProblem ? 'cb-alert' : null;
        }, tooltip: (row) => row.hasProblem ? 'Has Problem' : 'No Problem'
      }),

    COMMENTS: new TextColumn('comments', 'Comments', 'comments', FieldType.TEXT, '120px', {tooltip: (row) => row.comments}).setSortable(false),

    CUSTOMER_NAME: new TextColumn('customerName', 'Customer', 'customer.name', FieldType.TEXT, '65px'),
    FREIGHT_FORWARDER_NAME: new TextColumn('freightForwarderName', 'F.Forwarder', 'freightForwarder.name', FieldType.TEXT, '65px').setSearch(true),

    // REF1
    CUSTOMER_REF: new TextColumn('customerRef', 'REF', 'customerRef', FieldType.TEXT, '150px',
      {
        converter: (row, value) => row.isRecovery ? null : valueOrMultiple(value, (ref1) => ref1),
        tooltip: (row) => row.isRecovery ? null : valueOrMultipleValues(row.customerRef, (ref1) => ref1)
      }),
    // REF2
    HAWB: new TextColumn('hawb', 'H/CNTR/REF2', 'hawb', FieldType.TEXT, '100px',
      {
        converter: (row, value) => row.isRecovery ? null : valueOrMultiple(value, (hawb) => convertOrderRef2(row.orderMode, hawb)),
        tooltip: (row) => row.isRecovery ? null : valueOrMultipleValues(row.hawb, (hawb) => convertOrderRef2(row.orderMode, hawb))
      }),
    // REF3
    MAWB: new HyperLinkColumn('mawb', 'MAWB/REF3', 'mawb', FieldType.TEXT, '100px', {
      converter: (row) => valueOrMultiple(row.mawb, (mawb) => convertOrderRef3(row.orderMode, mawb)),
      onClick: (row) => this.openMasterEditor(row)
    }).setNewWindow(true),

    PCS: new TextColumn('pieces', 'PCS', (o) => OrderModes.isFCL(o.genericMode) || OrderModes.isFCL(o.orderMode) ? o.pcsUnits : o.pieces, FieldType.TEXT, '40px').setAlign("end"),
    HU: new TextColumn('hu', 'HU', 'hu', FieldType.TEXT, '30px'),
    WEIGHT: new WeightColumn('weight', 'Weight', 'weight', FieldType.TEXT, '50px').setAlign("end"),
    ULD: new HyperLinkColumn('uld', 'ULD', 'uldCount', FieldType.TEXT, '70px', {
      header: {noarrows: true},
      onClick: (row: OrderDispatch, value) => {
        let data = row.loadType === LoadType.RECOVERY ? {masterId: row.masterId} : {orderId: row.orderId};
        this.dialog.open(UldsDialogComponent, {
          width: '30%',
          data: data as UldsDialogInputData
        });
      },
      converter: (row, value) => isNullOrUndefined(value) || value === 0 ? 'N/A' : value,
      link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
    })
      .setSearch(false).setEditor(false).setAlign('center'),


    ADDRESS_PICKUP_NAME: ColumnType.DISPATCHED_ADDRESS_PICKUP_NAME.clone()
      .setHandlers({onClick: o => this.addressInfoPopup(o.addressPickup)}),
    ADDRESS_PICKUP_STATE: new TextColumn('addressPickupState', 'Fm ST', 'addressPickup.usaState.uspsCode', FieldType.TEXT, '40px'),
    ADDRESS_PICKUP_CITY: new TextColumn('addressPickupCity', 'Fm City', 'addressPickup.city', FieldType.TEXT, '90px'),
    ADDRESS_PICKUP_ZIP: new TextColumn('addressPickupZip', 'Fm ZIP', (o) => convertSubString(o, 'addressPickup.postCode', 0, 5), FieldType.TEXT, '40px'),

    ADDRESS_DELIVERY_NAME: ColumnType.DISPATCHED_ADDRESS_DELIVERY_NAME
      .setHandlers({onClick: o => this.addressInfoPopup(o.addressDelivery)}),
    ADDRESS_DELIVERY_STATE: new TextColumn('addressDeliveryState', 'To ST', 'addressDelivery.usaState.uspsCode', FieldType.TEXT, '40px'),
    ADDRESS_DELIVERY_CITY: new TextColumn('addressDeliveryCity', 'To City', 'addressDelivery.city', FieldType.TEXT, '90px'),
    ADDRESS_DELIVERY_ZIP: new TextColumn('addressDeliveryZip', 'To ZIP', (o) => convertSubString(o, 'addressDelivery.postCode', 0, 5), FieldType.TEXT, '40px'),

    DRIVER_NAME: new HyperLinkColumn('driverId', 'Driver', 'driver.fullName', FieldType.TEXT, '80px', {
      class: () => 'driver',
      tooltip: (row) => row.isConfirmed ? 'Route Confirmed on ' + convertDateTime(row.dateConfirmedOn) : 'Route Not Confirmed',
      onClick: this.onDriverLocationOpen.bind(this)
    }).setSearch({searchable: true, searchType: 'list', multiple: true, items: []}),
    TRUCK_NUMBER: new TextColumn('truckNumber', 'Truck', 'truck.number', FieldType.TEXT, '80px'),
    TRAILER_NUMBER: new TextColumn('trailerNumber', 'Trailer', 'trailer.number', FieldType.TEXT, '80px'),

    DATE_PICKUP: ColumnType.DISPATCHED_DATE_PICKUP,
    DATE_DELIVERY: ColumnType.DISPATCHED_DATE_DELIVERY,

    DOC_CENTER: new HyperLinkColumn('docCount', '', 'docCount', FieldType.TEXT, '15px', {
      header: {noarrows: true, icon: 'fa fa-paperclip'},
      converter: (row, value) => value || '',
      onClick: this.onDocCenterOpen.bind(this),
      icon: (row, value) => {
        return value ? '' : 'fa fa-paperclip emptyValueIcon';
      },
    }).setSortable(false).setSearch(false).setEditor(false),

    DOC_CENTER_FOR_COMPLETED: new HyperLinkColumn('docCount', '', 'docCount', FieldType.TEXT, '25px', {
      header: {noarrows: true, icon: 'fa fa-paperclip'},
      converter: (row, value) => value || '',
      onClick: this.onDocCenterOpen.bind(this),
      class: (row, value) => {
        return (!row.hasPodDocuments && row.loadType === LoadType.DELIVERY) ? 'warning' : '';
      },
      icon: (row, value) => {
        let res = '';
        if (!value) {
          res += 'fa fa-paperclip emptyValueIcon';
        }
        if (!row.hasPodDocuments && row.loadType === LoadType.DELIVERY) {
          res += ' warning';
        }
        return res;
      },
    }).setSortable(false).setSearch({
      searchable: true,
      search: '',
      searchFunction: 'list',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS,
      field: 'hasPodDocuments'
    }).setEditor(false),

    COM_CENTER: new HyperLinkColumn('comCount', '', 'comCount', FieldType.TEXT, '15px', {
      class: (dispatch) => dispatch.hasDriverComment ? 'color-green font-bold' : '',
      header: {noarrows: true, icon: 'fa fa-comments'},
      converter: (row, value) => value || '',
      onClick: this.onComCenterOpen.bind(this),
      icon: (row, value) => value ? '' : 'fa fa-comments emptyValueIcon'
    }).setSortable(false).setSearch(false).setEditor(false),

    ORDER_1C: new DateCheckboxColumn('orderDate1C', '1C', 'orderDate1C', '30px')
      .setSortable(false).setSearch(false).setEditor(false),

    DISPATCHED_DATE: new DateColumn('dateDispatchedFor', 'Disp date', 'dateDispatchedFor', ColumnWidth.DATE)
      .setSortable(true)
      .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
      .setEditor(false),

    DELIVERY_APPOINTMENT_REQUIRED: new CheckboxColumn('deliveryAppointmentRequired', 'DAR', 'deliveryAppointmentRequired', FieldType.BOOLEAN, '40px')
      .setEditor(true),

    HOT: new CheckboxColumn('hot', 'Hot', 'hot', FieldType.BOOLEAN, '40px')
      .setSearch({
        searchable: true,
        search: '',
        searchFunction: 'isSelectedValue',
        searchType: 'list',
        items: BaseColumn.YES_NO_FILTERS
      })
      .setEditor(true),
    REQUESTED_DELIVERY_DATE: new DateTimeColumn('requestedDeliveryDate', 'RDD', 'requestedDeliveryDate', '100px')
      .setSearch({searchable: true, searchFunction: "isTheSameDay", searchType: "time"})
      .setEditor(true)
      .setHistory({logChanges: true, field: 'requestedDeliveryDate', logConverter: true}),

    DATE_CREATED: new BaseColumn('dateCreated', 'Manifested Date', 'dateCreated', FieldType.DATETIME, ColumnWidth.DATE_TIME),
    CREATED_BY: new TextColumn('createdBy', 'Manifested By', 'createdBy', FieldType.TEXT, ColumnWidth.DATE_TIME),

    ORDER_DATE_CREATED: new BaseColumn('orderDateCreated', 'Created Date', 'orderDateCreated', FieldType.DATETIME, ColumnWidth.DATE_TIME),
    ORDER_CREATED_BY: new TextColumn('orderCreatedBy', 'Created By', 'orderCreatedBy', FieldType.TEXT, ColumnWidth.DATE_TIME),

    ORDER_COD: new CheckboxColumn('cod', 'COD', 'cod', FieldType.BOOLEAN, '40px')
      .setHandlers({
        class: () => 'custom-bgcolor-red'
      })
      .setSearch({
        searchable: true,
        search: '',
        searchFunction: 'isSelectedValue',
        searchType: 'list',
        items: BaseColumn.YES_NO_FILTERS
      })
      .setEditor(true)
      .setHistory({logChanges: true, field: 'requestedDeliveryDate', logConverter: true}),

    ROUTE_DATE: new TextColumn('dateDispatchedFor', 'Route date', 'dateDispatchedFor', FieldType.TEXT, '60px')
      .setHandlers({
        converter: (row: OrderDispatch, value) => moment(value).tz(OmsConstants.ETC_ZONE).format('DD-MMM')
      })
      .setSortable(true)
      .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
      .setEditor(false),

    DRIVER_ROUTE_STATUS: new TextColumn(ColumnIds.MASTER_FILL_STATUS, 'Status', 'fillStatus', FieldType.TEXT, '50px',
      {
        converter: (row: OrderDispatch) => null,
        tooltip: this.driverRouteStatusTooltip.bind(this),
        icon: this.driverRouteStatusIcon.bind(this),
        class: this.driverRouteStatusClass.bind(this)
      }, null, 'center').setEditor(false),

    NOT_COMPLETED_STATUS: new CheckboxColumn('notCompletedCount', 'NC', 'notCompletedCount', FieldType.BOOLEAN, '40px', {})
      .setHandlers({
        converter: (row: DriverOrderDispatch) => {
          return row.notCompletedCount > 0;
        },
        class: () => 'custom-bgcolor-red',
        header: {tooltip: 'Not Delivered'}
      })
      .setHidden((row: OrderDispatch | DriverOrderDispatch) => (row instanceof OrderDispatch))
      .setSearch({
        searchable: true,
        search: '',
        searchFunction: 'isSelectedValue',
        searchType: 'list',
        items: BaseColumn.YES_NO_FILTERS
      })
      .setSortable(true)
  };
  treeColumns = {
    DRIVER_MANIFEST_ID: new TreeColumn('dispatchIds', 'RT#', '100px', {
      row: DriverOrderDispatch.ROW_ID,
      column: new HyperLinkColumn('dispatchIds', 'RT#', 'dispatchIds', FieldType.TEXT, '100px',
        {
          converter: (row: DriverOrderDispatch, id) => this.isMultiRecord(row) ? 'multi' : convertManifestNumber(row.loads[0].dispatchId),
          onClick: (row: DriverOrderDispatch) => {
            if (!this.isMultiRecord(row)) {
              this.openManifestFormByDriver(row);
            }
          }
        })
    }, {
      row: OrderDispatch.ROW_ID,
      column: this.allColumns.MANIFEST_ID
    }),
    DRIVER_ORDER_ID: new TreeColumn('orderIds', 'Order', '60px', {
      row: DriverOrderDispatch.ROW_ID,
      column: new HyperLinkColumn('orderIds', 'Order', 'orderIds', FieldType.TEXT, '60px',
        {
          converter: (row: DriverOrderDispatch, id) => this.isMultiRecord(row) ? 'multi' : convertOrderNumber(row.loads[0].orderId),
          link: (row: DriverOrderDispatch, value) => {
            return this.isMultiRecord(row) ? undefined : {path: ['order'], params: {
              'id': row.loads[0].orderId,
              'dispatch': row.loads[0].dispatchId || undefined,
              'shipment': undefined
            }};
          },
        })
    }, {
      row: OrderDispatch.ROW_ID,
      column: this.allColumns.ORDER_ID
    }),

    DRIVER_PCS: new TreeColumn('piecesTotal', 'PCS', '40px', {
      row: DriverOrderDispatch.ROW_ID,
      column: new TextColumn('piecesTotal', 'PCS', 'piecesTotal', FieldType.TEXT, '40px')
        .setAlign("end")
    }, {
      row: OrderDispatch.ROW_ID,
      column: this.allColumns.PCS
    }),

    DRIVER_WEIGHT: new TreeColumn('weightTotal', 'Weight', '50px', {
      row: DriverOrderDispatch.ROW_ID,
      column: new TextColumn('weightTotal', 'Weight', 'weightTotal', FieldType.TEXT, '50px')
        .setHandlers({converter: (row: DriverOrderDispatch) => (row.weightTotal ? ('' + NumberUtils.round(row.weightTotal, 1)) : 0)})
        .setAlign("end")
    }, {
      row: OrderDispatch.ROW_ID,
      column: this.allColumns.WEIGHT
    }),
  };

  DISPATCH_COLUMNS: BaseColumn[] = [
//    this.allColumns.STATUS,
    this.allColumns.ORDER_MODE,
    this.allColumns.HOT,
//    this.allColumns.LOAD_TYPE,
    this.allColumns.ORDER_ID,
    this.allColumns.DOC_CENTER,
    this.allColumns.COM_CENTER,
    this.allColumns.ORDER_COD,
    this.allColumns.ORDER_1C,
    this.allColumns.DELIVERY_APPOINTMENT_REQUIRED,
    this.allColumns.REQUESTED_DELIVERY_DATE,
    this.allColumns.CUSTOMER_REF,
    this.allColumns.HAWB,
    this.allColumns.MAWB,
    this.allColumns.CUSTOMER_NAME,
    this.allColumns.FREIGHT_FORWARDER_NAME,
    this.allColumns.PCS,
    this.allColumns.HU,
    this.allColumns.WEIGHT,
    this.allColumns.ULD,

    this.allColumns.ADDRESS_PICKUP_NAME,
    this.allColumns.ADDRESS_PICKUP_STATE,
    this.allColumns.ADDRESS_PICKUP_CITY,
    this.allColumns.ADDRESS_PICKUP_ZIP,

    this.allColumns.ADDRESS_DELIVERY_NAME,
    this.allColumns.ADDRESS_DELIVERY_STATE,
    this.allColumns.ADDRESS_DELIVERY_CITY,
    this.allColumns.ADDRESS_DELIVERY_ZIP,
    ColumnType.CREATED_BY,
    ColumnType.DATE_CREATED
  ];
  ACTIVE_COLUMNS: BaseColumn[] = [
    this.allColumns.LOAD_TYPE,

    this.allColumns.ORDER_MODE,
    this.allColumns.ORDER_ID,
    this.allColumns.DISPATCHED_DATE,
    this.allColumns.MANIFEST_ID,
    this.allColumns.DRIVER_NAME,
    this.allColumns.DOC_CENTER,
    this.allColumns.COM_CENTER,
    this.allColumns.ORDER_COD,
    this.allColumns.REQUESTED_DELIVERY_DATE,
    this.allColumns.CUSTOMER_REF,
    this.allColumns.HAWB,
    this.allColumns.MAWB,
    this.allColumns.CUSTOMER_NAME,
    this.allColumns.FREIGHT_FORWARDER_NAME,
    this.allColumns.PCS,
    this.allColumns.HU,
    this.allColumns.WEIGHT,
    this.allColumns.ULD,

    this.allColumns.PROBLEM,

    this.allColumns.ADDRESS_PICKUP_NAME,
    this.allColumns.ADDRESS_PICKUP_STATE,
    this.allColumns.ADDRESS_PICKUP_CITY,
    this.allColumns.ADDRESS_PICKUP_ZIP,

    this.allColumns.ADDRESS_DELIVERY_NAME,
    this.allColumns.ADDRESS_DELIVERY_STATE,
    this.allColumns.ADDRESS_DELIVERY_CITY,
    this.allColumns.ADDRESS_DELIVERY_ZIP,

    this.allColumns.TRUCK_NUMBER,
    this.allColumns.TRAILER_NUMBER,

    this.allColumns.DATE_PICKUP,
    this.allColumns.DATE_DELIVERY,
    this.allColumns.COMMENTS,
    this.allColumns.CREATED_BY,
    this.allColumns.DATE_CREATED,
    this.allColumns.ORDER_CREATED_BY,
    this.allColumns.ORDER_DATE_CREATED
  ];
  COMPLETED_COLUMNS: BaseColumn[] = [
    this.allColumns.LOAD_TYPE,

    this.allColumns.ORDER_MODE,
    this.allColumns.ORDER_ID,
    this.allColumns.DISPATCHED_DATE,
    this.allColumns.MANIFEST_ID,
    this.allColumns.DRIVER_NAME,
    this.allColumns.DOC_CENTER_FOR_COMPLETED,
    this.allColumns.COM_CENTER,
    this.allColumns.ORDER_COD,
    this.allColumns.REQUESTED_DELIVERY_DATE,
    this.allColumns.CUSTOMER_REF,
    this.allColumns.HAWB,
    this.allColumns.MAWB,
    this.allColumns.CUSTOMER_NAME,
    this.allColumns.FREIGHT_FORWARDER_NAME,
    this.allColumns.PCS,
    this.allColumns.HU,
    this.allColumns.WEIGHT,
    this.allColumns.ULD,

    this.allColumns.PROBLEM,

    this.allColumns.ADDRESS_PICKUP_NAME,
    this.allColumns.ADDRESS_PICKUP_STATE,
    this.allColumns.ADDRESS_PICKUP_CITY,
    this.allColumns.ADDRESS_PICKUP_ZIP,

    this.allColumns.ADDRESS_DELIVERY_NAME,
    this.allColumns.ADDRESS_DELIVERY_STATE,
    this.allColumns.ADDRESS_DELIVERY_CITY,
    this.allColumns.ADDRESS_DELIVERY_ZIP,

    this.allColumns.TRUCK_NUMBER,
    this.allColumns.TRAILER_NUMBER,

    this.allColumns.DATE_PICKUP,
    this.allColumns.DATE_DELIVERY,
    this.allColumns.COMMENTS,
    this.allColumns.CREATED_BY,
    this.allColumns.DATE_CREATED,
    this.allColumns.ORDER_CREATED_BY,
    this.allColumns.ORDER_DATE_CREATED
  ];
  ORDER_ACTIVE: BaseColumn[] = [
    this.allColumns.ORDER_ID,

    this.allColumns.DOC_CENTER,
    this.allColumns.COM_CENTER,

    this.allColumns.MAWB,
    this.allColumns.HAWB,
    this.allColumns.CUSTOMER_REF,
    this.allColumns.CUSTOMER_NAME,
    this.allColumns.ULD,
    this.allColumns.PCS,
    this.allColumns.HU,
    this.allColumns.WEIGHT,

    this.allColumns.ADDRESS_PICKUP_NAME,
    this.allColumns.ADDRESS_PICKUP_STATE,
    this.allColumns.ADDRESS_PICKUP_CITY,
    this.allColumns.ADDRESS_PICKUP_ZIP,

    this.allColumns.ADDRESS_DELIVERY_NAME,
    this.allColumns.ADDRESS_DELIVERY_STATE,
    this.allColumns.ADDRESS_DELIVERY_CITY,
    this.allColumns.ADDRESS_DELIVERY_ZIP,


    this.allColumns.ORDER_DATE_CREATED,
    this.allColumns.ORDER_CREATED_BY,
  ];
  DRIVER_ROUTES_COLUMNS: BaseColumn[] = [
    this.createTreeColumn(this.allColumns.ROUTE_DATE, null),
    this.createTreeColumn(this.allColumns.DRIVER_NAME, null),
    this.treeColumns.DRIVER_MANIFEST_ID,
    this.createTreeColumn(this.allColumns.DRIVER_ROUTE_STATUS, null, false),
    this.allColumns.NOT_COMPLETED_STATUS,
    this.treeColumns.DRIVER_ORDER_ID,
    this.createTreeColumn(this.allColumns.DOC_CENTER, ''),
    this.createTreeColumn(this.allColumns.COM_CENTER, ''),

    this.treeColumns.DRIVER_PCS,
    this.treeColumns.DRIVER_WEIGHT,

    this.createTreeColumn(this.allColumns.ADDRESS_PICKUP_NAME, ''),
    this.createTreeColumn(this.allColumns.ADDRESS_PICKUP_STATE, ''),
    this.createTreeColumn(this.allColumns.ADDRESS_PICKUP_CITY, ''),
    this.createTreeColumn(this.allColumns.ADDRESS_PICKUP_ZIP, ''),

    this.createTreeColumn(this.allColumns.ADDRESS_DELIVERY_NAME, ''),
    this.createTreeColumn(this.allColumns.ADDRESS_DELIVERY_STATE, ''),
    this.createTreeColumn(this.allColumns.ADDRESS_DELIVERY_CITY, ''),
    this.createTreeColumn(this.allColumns.ADDRESS_DELIVERY_ZIP, ''),

    this.createTreeColumn(this.allColumns.CUSTOMER_REF, ''),
    this.createTreeColumn(this.allColumns.HAWB, ''),
    this.createTreeColumn(this.allColumns.MAWB, ''),

    this.createTreeColumn(this.allColumns.ORDER_MODE, '')
  ];


  private createTreeColumn(baseColumn: BaseColumn, displayValue: (((row: DriverOrderDispatch) => string) | string) = 'N/A',
                           isRemoveHandlers: boolean = true): TreeColumn {
    let driverOrderDispatchColumn = baseColumn.clone();
    if (displayValue != null) {
      if (typeof displayValue === 'function') {
        driverOrderDispatchColumn.setHandlers({converter: displayValue.bind(this)});
      } else {
        driverOrderDispatchColumn.setHandlers({converter: () => displayValue});
      }
    }
    if (isRemoveHandlers) {
      driverOrderDispatchColumn.setHandlers({icon: () => '', class: () => '', tooltip: () => ''});
    }
    driverOrderDispatchColumn.setButton(null);

    let treeColumn = Object.assign(new TreeColumn(baseColumn.field, baseColumn.label, baseColumn.width), driverOrderDispatchColumn).clone();
    treeColumn.setColumns({
      row: DriverOrderDispatch.ROW_ID, column: driverOrderDispatchColumn
    }, {
      row: OrderDispatch.ROW_ID, column: baseColumn
    });
    return treeColumn;
  }



  public onComCenterOpen(row: OrderDispatch) {
    this.dialogs.openComCenterDialog(row.orderId, 'ORDER');
  }

  public onDocCenterOpen(row: OrderDispatch) {
    this.ordersService.get(row.orderId).subscribe(order => {
      this.dialogs.openDocCenterDialog({id: row.orderId, objectType: 'ORDER'});
    });
  }

  public onDriverLocationOpen(row: OrderDispatch) {
    this.dialogs.openDriverLocationDialog(row.driver);
  }

  addressInfoPopup(address) {
    if (!isNullOrUndefined(address)) {
      this.dialog.open(AddressMapInfoDialogComponent, {width: '50%', data: {address: address, searchByAddress: true}});
    }
  }

  driverRouteStatusTooltip(row: DriverOrderDispatch | OrderDispatch): string {
    if (row instanceof DriverOrderDispatch) {
      let tooltip = '';
      if (row.openedCount === 0) {
        tooltip = 'No opened';
      } else if (row.openedCount !== row.count) {
        tooltip = 'Some opened';
      } else {
        tooltip = 'All opened';
      }
      tooltip += ', ';

      let updatesCount = row.completedCount + row.completedWithProblemCount + row.notCompletedCount;
      if (updatesCount === 0) {
        tooltip += 'no updates';
      } else if (updatesCount !== row.count) {
        tooltip += 'some updates';
      } else {
        tooltip += 'all updates';
      }
      return tooltip;
    }
    if (row instanceof OrderDispatch) {
      return row.getRouteStatus();
    }
  }

  driverRouteStatusIcon(row: DriverOrderDispatch | OrderDispatch): string {
    if (row instanceof DriverOrderDispatch) {
      let updatesCount = row.completedCount + row.completedWithProblemCount + row.notCompletedCount;
      if (row.openedCount === 0 || (updatesCount === 0)) {
        return 'fa fa-battery-empty';
      }
      if (updatesCount !== row.count) {
        return 'fa fa-battery-half';
      }
      return 'fa fa-battery-full';
    }
    if (row instanceof OrderDispatch) {
      if (row.isCompleted() || row.isCompletedWithProblem() || row.isNotCompleted()) {
        return 'fa fa-battery-full';
      }
      return 'fa fa-battery-empty';
    }
  }

  driverRouteStatusClass(row: DriverOrderDispatch | OrderDispatch): string {
    if (row instanceof DriverOrderDispatch) {
      if (row.openedCount === 0) {
        return 'fill-indicator';
      }
      if (row.openedCount !== row.count) {
        return 'fill-indicator fill-indicator-over';
      }
      return 'fill-indicator dispatched';
    }
    if (row instanceof OrderDispatch) {
      if (!row.isConfirmed) {// didn't open
        return 'fill-indicator'; // black
      }
      if (row.isPickedUp && row.hasProblem) {// completed w/problem
        return 'fill-indicator fill-indicator-over'; // yellow
      }
      if ((row.isPickedUp && !row.hasProblem) || (!row.isPickedUp && !row.hasProblem)) {// completed or viewed didn't do any updates from mobile/or internal
        return 'fill-indicator dispatched'; // green
      }
      if (row.isNotCompleted()) {
        return 'fill-indicator danger'; // red
      }
    }
  }


  openManifestFormByDriver(dr: DriverOrderDispatch) {
    this.dispatchService.findDispatchedLoadsByDriver(dr).subscribe(response => {
      if (response.content.length) {
        this.openManifestForm(response.content[0]);
      }
    });
  }

  openManifestForm(dispatch: OrderDispatch) {
    // this.dialog.open(ManifestDialogComponent, {data:{manifest: manifest}});
    // this.dialogs.openDialog(ManifestDialogComponent, {manifest: manifest, });
    // console.log('Show Manifest', manifest);
//    this.clickDispatch.emit(dispatch);
  }

  openMasterEditor(dispatch: OrderDispatch) {
    if (dispatch.masterId) {
      this.router.navigate(['dispatch/shipments/master', {id: dispatch.masterId}]);
    }
  }


  isMultiRecord(dispatch: DriverOrderDispatch): boolean {
    return dispatch.loads.length > 1;
  }
}
