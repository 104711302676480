import {Type} from "class-transformer";
import {BaseEntity} from "./base-entity";
import {Uld} from "./master/masterAirUld";
import {Shipment} from "./shipment";

export class UldLinked extends BaseEntity {
public javaClassName: string = 'com.oms.entity.air.UldLinked';

  @Type(() => Uld)
  uld: Uld;


  mawbNumber: string;
  hawb: string;
  masterId: number;
  orderId: number;
  documents: number;
  rowId: string;

  freightForwarderName: string;
  customerName: string;

  addressTo: string;
  addressFrom: string;

  @Type(() => Shipment)
  recoveryShipment: Shipment;

  get docCenter() {
    return "";
  }

}
