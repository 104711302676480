import {LoggerConfiguration} from './logger.configuration';
import {DefaultLoggerConfiguration} from './default-logger.configuration';
import {isDevMode, NgZone} from '@angular/core';
import {ArrayUtils} from "../array.utils";

let statistics: { [key: string]: IStatisticItem };
let ngZone: NgZone;

let excludeLogging: RegExp[] = [

  // new RegExp(/ngDoCheck/),
  // new RegExp(/ngOnChanges/),
  // new RegExp(/trackByRowObject/),
  // new RegExp(/ngAfterViewInit/),
  // new RegExp(/trackFn/),

  new RegExp(/DataTableRowComponent/),
  new RegExp(/DataTableComponent/),
  new RegExp(/DataTableCell/),
  new RegExp(/BaseColumn/),
  new RegExp(/MawbTableCellComponent/),

  new RegExp(/DataTableRowComponent.isChild/),

  new RegExp(/DataTableComponent.sortedBy/),
  new RegExp(/DataTableComponent.sortedAsc/),
  new RegExp(/DataTableComponent.columnByRow/),
  new RegExp(/DataTableComponent.isLeaf/),
  new RegExp(/DataTableComponent.getRowHeight/),
  new RegExp(/DataTableComponent.getRowDragScope/),
  new RegExp(/DataTableComponent.getRowClass/),
  new RegExp(/DataTableComponent.isClearTableInputs/),
  new RegExp(/DataTableComponent.recalculateDims/),

  new RegExp(/DataTableComponent.scrollHeight/),
  new RegExp(/DataTableComponent.isSelectedRow/),
  new RegExp(/DataTableComponent.rowCount/),
  new RegExp(/DataTableComponent.hasData/),
  new RegExp(/DataTableComponent.noData/),


  new RegExp(/DataTableComponent.onMouseMove/),
  new RegExp(/DataTableComponent.isSelectedPartially/),
  new RegExp(/DataTableComponent.isSelectedAll/),
  new RegExp(/DataTableComponent.getHeaderSearchItems/),
  new RegExp(/DataTableComponent.vertScrollBarWidth/),


  new RegExp(/DataTableCell.ngOnInit/),
  new RegExp(/DataTableCell.getDownloadPdfLink/),
  new RegExp(/DataTableCell.initSearch/),
  new RegExp(/DataTableCell.cellButton/),
  new RegExp(/DataTableCell.updateObject/),
  new RegExp(/DataTableCell.selectOptions/),
  new RegExp(/DataTableCell.searchService/),

];
let includeLogging: RegExp[] = [
  // new RegExp(/DataTable/)
];


export function Logger(inputOptions?: LoggerConfiguration) {
  let options = new DefaultLoggerConfiguration();
  if (inputOptions) {
    Object.assign(options, inputOptions);
  }

  if (!DefaultLoggerConfiguration.isGlobalLoggingTurnOn || !options.isTurnOn || (!options.isLoggingEachMethod && !options.isDisplayStatistics)) {
    return function (target) {
      return target;
    };
  }
  if (!ngZone) {
    initStatistics();
  }

  return function (target) {
    let className = options.name || target.name;
    let fields = getAllProperties(target);
    for (let i = 0; i < fields.length; i++) {
      let propertyName = fields[i];
      let path = className + '.' + propertyName;
      if (!isLoggingMethod(path)) {
        continue;
      }

      let descriptor = Object.getOwnPropertyDescriptor(
        target.prototype,
        propertyName
      );

      if (descriptor && descriptor.get) {
        let getter = descriptor.get;
        Object.defineProperty(target.prototype, propertyName, {
          get: function () {
            return wrapMethod(this, arguments, getter, path, options);
          }
        });
      } else {
        const propertyValue = target.prototype[propertyName];
        const isMethod = propertyValue instanceof Function;
        if (!isMethod)
          continue;

        target.prototype[propertyName] = function () {
          return wrapMethod(this, arguments, propertyValue, path, options);
        };
      }
    }

    return target;
  };

  function wrapMethod(context, args, method: Function, path: string, options: DefaultLoggerConfiguration) {
    let t1 = 0;
    let t2 = 0;
    let res;
    if (options.isDisplayStatistics || (options.isLoggingEachMethod && options.isLoggingTime)) {
      t1 = window.performance.now();
      res = method.apply(context, args);
      t2 = window.performance.now();
    } else {
      res = method.apply(context, args);
    }
    if (options.isDisplayStatistics) {
      if (!statistics[path]) {
        statistics[path] = {
          time: 0,
          times: 0
        };
      }
      let statistic = statistics[path];
      statistic.time += (t2 - t1);
      statistic.times += 1;
    }

    if (options.isLoggingEachMethod) {
      let message = path;
      let args = [];
      if (options.isLoggingResult) {
        message += ', result=%o';
        args.push(res);
      }
      if (options.isLoggingTime) {
        message += ', time=%o ms';
        args.push(Math.round((t2 - t1) * 1000) / 1000);
      }
      console.log(message, ...args);
    }

    return res;
  }

  function isLoggingMethod(methodName: string): boolean {
    if (includeLogging.length) {
      let isIncluded = includeLogging.some(pat => pat.test(methodName));
      if (!isIncluded) {
        return false;
      }
    }
    if (excludeLogging.length) {
      let isExcluded = excludeLogging.some(pat => pat.test(methodName));
      if (isExcluded) {
        return false;
      }
    }
    return true;
  }

  function getAllProperties(obj) {
    let props: string[] = [];

    if (options.isLoggingParentMethod && obj.prototype && obj.prototype.prototype) {
      for (const prop in obj.prototype.prototype) {
        props.push(prop);
      }
    }

    for (const prop in obj.prototype) {
      props.push(prop);
    }

    return ArrayUtils.removeDuplicate(props, p => p);
  }
}

function initStatistics() {
  ngZone = new NgZone({enableLongStackTrace: isDevMode()});
  statistics = {};

  ngZone.runOutsideAngular(() => {
    setInterval(() => {
      let displayStatistics = [];
      for (const timeElement in statistics) {
        displayStatistics.push({
          name: timeElement,
          time: statistics[timeElement].time,
          times: statistics[timeElement].times,
          avg: statistics[timeElement].time / statistics[timeElement].times
        });
      }
      displayStatistics = displayStatistics
        .sort((v1, v2) => v2.time - v1.time)
        .filter((v, index) => index < 10);
      if (displayStatistics.length) {
        console.log('statistics - %o', displayStatistics);
      }
      statistics = {};
    }, DefaultLoggerConfiguration.displayStatisticsInterval || 20000);
  });
}

interface IStatisticItem {
  time: number;
  times: number;
}
