import {isNullOrUndefined} from 'util';
import {Comparable} from '../../../common/oms-types';
import {Type} from "class-transformer";

export class BaseEntity implements Comparable {
  public id: number;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;


  public static idOf(entity) {
    return entity instanceof BaseEntity ? entity.id : null;
  }

  public isNew(): boolean {
    return isNullOrUndefined(this.id);
  }

  public constructor(id?: number) {
    this.id = id;
  }


  public equals(object) {
    return object === this || (!this.isNew() && object instanceof BaseEntity && object.id === this.id);
  }

  public removeCircularDependency() {
    let keys = Object.keys(this);
    this.circularDependencyRemove(keys, this);
  }

  private circularDependencyRemove(keys, parent) {
    keys.forEach(key => {
      let fieldValue = this[key];
      if (fieldValue instanceof Array) {
        fieldValue.forEach(fieldValueItem => {
          if (fieldValueItem.circularDependencyRemove) {
            fieldValueItem.circularDependencyRemove(Object.keys(fieldValueItem), parent);
          }
        });
      } else if (!isNullOrUndefined(fieldValue)) {
        if (fieldValue === parent) {
          this[key] = null;
        } else {
          if (fieldValue.circularDependencyRemove) {
            this[key] = fieldValue.circularDependencyRemove(Object.keys(fieldValue), this);
          }
        }
      }
    });
    return this;
  }

}
