import {BaseEntity} from "../base-entity";
import {Exclude, Type} from "class-transformer";
import {isNullOrUndefined} from "util";
import {Address, CfsLocation} from "../address";
import {Carrier} from "../carrier";
import {Driver} from "../driver";
import {Status} from "./status";
import {Truck} from "../truck";
import {Trailer} from "../trailer";
import {OrderMode} from "../order/order-mode";

export class OrderPart extends BaseEntity {
  public status: Status = Status.UNDEFINED;
  public routeProblem: boolean; // indicates Route's shipment.hasProblem;
  public dispatchId: number;
  public shipmentId: number;
  public loadId: number;

  public receivings: OrderPart[] = [];

  @Exclude()
  public open: boolean;
  @Exclude()
  public orderNumber: number;

  public masterReceiving: boolean;
  public masterId: number;

//  @Type(() => Order)
  public order: {
    id: number;
    hawb: string;
    purchaseOrder: string;
    pieces: number,
    hu: number,
    customerRef: string;
    ref3: string;
    addressFrom: Address;
    addressTo: Address;
    mode: OrderMode;
    uldCount?: number;
  };

  @Type(() => Address)
  public address?: Address;

  @Type(() => CfsLocation)
  public cfsLocation?: CfsLocation;

  // Original pieces/hu
  public pieces: number;
  public hu: number;

  // Received/LoadedOut
  public pcsProcessed: number;
  public huProcessed: number;
  public weightProcessed: number;

  public overage: boolean;
  public shortage: boolean;
  public damage: boolean;
  public comments: string;
  public sealNumber: string;


  @Exclude()
  modified: boolean = false;

  @Exclude()
  public get warnings(): boolean {
    return this.overage || this.shortage || this.damage || (!isNullOrUndefined(this.comments) && this.comments.length > 0);
  }


  public isHuGreaterThanPcs(): boolean {
    return ~~this.huProcessed > ~~this.pcsProcessed;
  }

  public mustHaveHu(): boolean {
    return this.status !== Status.UNDEFINED && this.pcsProcessed > 0 && !~~this.huProcessed;
  }

  public isHuInvalid(): boolean {
    return this.mustHaveHu() || this.isHuGreaterThanPcs();
  }

  public isProcessedAny(): boolean {
    return ~~this.pcsProcessed < ~~this.order.pieces;
  }

  public isProcessedInFull(): boolean {
    return ~~this.order.pieces <= ~~this.pcsProcessed;
  }

  @Exclude()
  public get processedInFull(): boolean {
    return ~~this.order.pieces <= ~~this.pcsProcessed;
  }


  public isProcessedOver(): boolean {
    return ~~this.order.pieces < ~~this.pcsProcessed;
  }


}
