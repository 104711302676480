import {Master} from "./master";
import {BaseEntity} from "../base-entity";
import {Airport} from '..';
import {Type, Exclude} from 'class-transformer';

export class MasterAir extends BaseEntity {
  public javaClassName: string = "com.oms.entity.master.MasterAir";

  master: Master;
  mawbNumber: string;
  flightNumber: string;

//  @Type(() => Airline)
//  airline: Airline;

//  @Type(() => Airport)
//  airport: Airport;

  @Type(() => Airport)
  originalAirport: Airport;
}
