import {
  BaseColumn,
  CheckboxColumn,
  CustomColumn,
  DateCheckboxColumn,
  DateColumn,
  DateTimeColumn,
  FieldType,
  HyperLinkColumn,
  TextColumn,
  WeightColumn
} from "../../../shared/components/base/data-table/columns/column-types";
import {ColumnType, generateManifest} from "../../../../common/column-type";
import {
  convertDateTime,
  convertLoadType,
  convertManifestNumber,
  convertOrderNumber,
  convertOrderRef2,
  convertRecoveryOrderNumber,
  convertSubString,
  valueOrMultiple,
  valueOrMultipleValues
} from "../../../shared/services/oms-converters.service";
import {DispatchService} from "../../../shared/services/dispatch/dispatch.service";
import {OrderDispatch} from "../../../shared/models/dispatch/order-dispatch";
import {MawbTableCellComponent} from "../../../shared/components/base/data-table/data-table-cell/mawb-table-cell/mawb-table-cell.component";
import {OmsConstants} from "../../../../common/oms-constants.service";
import {OrderModes} from "../../../shared/models/order/order-mode";
import {Driver, LoadType} from "../../../shared/models";
import {UldsDialogComponent, UldsDialogInputData} from "../../../../components/common/ulds-dialog/ulds-dialog.component";
import {isNullOrUndefined} from "util";
import {ColumnWidth} from "../../../../common/column-width";
import * as moment from "moment";
import {ColumnIds} from "../../../../common/column-ids";
import {DriverRoutesLoadsTableCellComponent} from "../../../shared/components/base/data-table/data-table-cell/driver-routes-loads-table-cell/driver-routes-loads-table-cell.component";
import {DriverOrderDispatch} from "../../../shared/models/dispatch/driver-order-dispatch";
import {ArrayUtils} from "../../../../_helpers/array.utils";
import {OrdersService} from "../../../../services";
import {OmsDialogsService} from "../../../../components/common/oms-dialogs";
import {UserService} from "../../../shared/services/user.service";
import {Observable} from "rxjs";
import {PageResult} from "../../../shared/models/query-models/page-result";
import {map} from "rxjs/operators";
import {MatDialog} from "@angular/material";
import {Status} from "../../../shared/models/warehouse/status";
import {EMPTY_LINK} from "../../../../common/oms-types";

export class ShipmentListColumns {
  public static STATUS = new TextColumn('orderStatus', 'STATUS', 'orderStatus.label', FieldType.TEXT, '100px', {tooltip: row => row.orderStatus ? row.orderStatus.label : null});

  public static ORDER_MODE = ColumnType.ORDER_MODE;
  public static ORDER_PO = ColumnType.ORDER_PO;

  public static MANIFEST_ID = ((dispatchService: DispatchService, openManifestForm: (od: OrderDispatch) => any) =>
    ColumnType.DISPATCHED_MANIFEST_ID.clone()
      .setButton({
        icon: 'fa fa-download',
        tooltip: 'Download Manifest',
        onClick: (row) => generateManifest(dispatchService, row),
        newPage: true
      })
      .setHandlers({
        converter: (row, id) => convertManifestNumber(id),
        onClick: (manifest) => openManifestForm(manifest)
      }));

  public static STAGING_MANIFEST_ID = ((dispatchService: DispatchService, openManifestForm: (od: OrderDispatch) => any) =>
    ColumnType.DISPATCHED_MANIFEST_ID.clone()
      .setButton({
        icon: 'fa fa-download',
        tooltip: 'Download Manifest',
        onClick: (row) => generateManifest(dispatchService, row),
        newPage: true
      })
      .setHandlers({
        tooltip: (row: OrderDispatch) => row.loadOutStatus === Status.COMPLETED ? 'Load Out Completed' : undefined,
        converter: (row, id) => convertManifestNumber(id),
        class: (row: OrderDispatch) => 'inline-block ' + (row.loadOutStatus === Status.COMPLETED ? 'alert alert-success' : ''),
        onClick: (manifest) => openManifestForm(manifest)
      }));


  public static LOAD_TYPE = new TextColumn('loadType', 'Type', 'loadType', FieldType.TEXT, '60px')
    .setHandlers({
      converter: (row, value) => convertLoadType(value),
      tooltip: row => row.orderStatus ? row.orderStatus.label : null
    })
    .setSearch({searchable: true, searchType: 'list', multiple: true, items: BaseColumn.loadTypeFilterItems});

  public static ORDER_ID = new HyperLinkColumn('orderId', 'Order', 'orderId', FieldType.TEXT, '60px')
    .setNewWindow(true)
    .setHandlers({
      link: (row, value) => {
        return {path: ['/home', 'order'], params: {id: value}};
      },
      converter: (row, value) => row.isRecovery ? convertRecoveryOrderNumber(value) : convertOrderNumber(value),
      tooltip: row => row.isRecovery ? 'Recovery Load' : row.orderStatus ? row.orderStatus.label : null
    })
    .setSearch({searchable: true});
//      .setSearch({searchable: true, searchType: 'list', items: BaseColumn.loadTypeFilterItems}),


  public static PROBLEM = new CheckboxColumn('hasProblem', 'Prblm', 'hasProblem', FieldType.DIALOG, '40px',
    {
      class: (row) => {
        return row.hasProblem ? 'cb-alert' : null;
      }, tooltip: (row) => row.hasProblem ? 'Has Problem' : 'No Problem'
    });

  public static COMMENTS = new TextColumn('comments', 'Comments', 'comments', FieldType.TEXT, '120px', {tooltip: (row) => row.comments}).setSortable(false);

  public static CUSTOMER_NAME = new TextColumn('customerName', 'Customer', 'customer.name', FieldType.TEXT, '65px');
  public static FREIGHT_FORWARDER_NAME = new TextColumn('freightForwarderName', 'F.Forwarder', 'freightForwarder.name', FieldType.TEXT, '65px').setSearch(true);

  // REF1
  public static CUSTOMER_REF = new TextColumn('customerRef', 'REF', 'customerRef', FieldType.TEXT, '150px',
    {
      converter: (row, value) => row.isRecovery ? null : valueOrMultiple(value, (ref1) => ref1),
      tooltip: (row) => row.isRecovery ? null : valueOrMultipleValues(row.customerRef, (ref1) => ref1)
    });
  // REF2
  public static HAWB = new TextColumn('hawb', 'H/CNTR/REF2', 'hawb', FieldType.TEXT, '100px',
    {
      converter: (row, value) => row.isRecovery ? null : valueOrMultiple(value, (hawb) => convertOrderRef2(row.orderMode, hawb)),
      tooltip: (row) => row.isRecovery ? null : valueOrMultipleValues(row.hawb, (hawb) => convertOrderRef2(row.orderMode, hawb))
    });
  // REF3
  public static MAWB = ((onClick: (row: OrderDispatch) => any) =>
    new CustomColumn('mawb', 'MAWB/REF3', 'mawb', FieldType.TEXT, '120px')
      .setComponent(MawbTableCellComponent)
      .setHandlers({
        onClick: (row) => onClick(row)
      })
      .setEditor({
        editable: false,
        mask: OmsConstants.mawbMask,
        clearMask: true,
        showTracking: (row: OrderDispatch) => OrderModes.isAir(row.orderMode),
        placeholder: OmsConstants.mawbPlaceholder,
        cleanable: false
      }));

  public static PCS = new TextColumn('pieces', 'PCS',  (o) => OrderModes.isFCL(o.genericMode) || OrderModes.isFCL(o.orderMode) ? o.pcsUnits : o.pieces, FieldType.TEXT, '40px'
  ).setAlign("end");
  public static HU = new TextColumn('hu', 'HU', 'hu', FieldType.TEXT, '30px').setAlign("end");
  public static WEIGHT = new WeightColumn('weight', 'Weight', 'weight', FieldType.TEXT, '50px').setAlign("end");
  public static ULD = ((dialog: MatDialog) => new HyperLinkColumn('uldOrChassis', 'ULD', (o) => getChassisOrUld(o), FieldType.TEXT, '50px', {
    header: {noarrows: true},
    onClick: (row: OrderDispatch) => {
      if (!OrderModes.isFCL(row.orderMode)) {
        let data = row.loadType === LoadType.RECOVERY ? {masterId: row.masterId} : {orderId: row.orderId};
        dialog.open(UldsDialogComponent, {
          width: '30%',
          data: data as UldsDialogInputData
        });
      }
    },
    converter: (row, value) => isNullOrUndefined(value) || value === 0 ? 'N/A' : value,
    link: (row, value) => isNullOrUndefined(value) || value === 0 ? null : EMPTY_LINK
  })
    .setSearch(false).setEditor(false).setAlign('center'));


  public static ADDRESS_PICKUP_NAME = ((onClick: (row: OrderDispatch) => any) =>
    ColumnType.DISPATCHED_ADDRESS_PICKUP_NAME.clone()
      .setHandlers({onClick: o => onClick(o)}));
  public static ADDRESS_PICKUP_STATE = new TextColumn('addressPickupState', 'Fm ST', 'addressPickup.usaState.uspsCode', FieldType.TEXT, '40px');
  public static ADDRESS_PICKUP_CITY = new TextColumn('addressPickupCity', 'Fm City', 'addressPickup.city', FieldType.TEXT, '90px');
  public static ADDRESS_PICKUP_ZIP = new TextColumn('addressPickupZip', 'Fm ZIP', (o) => convertSubString(o, 'addressPickup.postCode', 0, 5), FieldType.TEXT, '40px');

  public static ADDRESS_DELIVERY_NAME = ((onClick: (row: OrderDispatch) => any) =>
    ColumnType.DISPATCHED_ADDRESS_DELIVERY_NAME.clone()
      .setHandlers({onClick: o => onClick(o)}));
  public static ADDRESS_DELIVERY_STATE = new TextColumn('addressDeliveryState', 'To ST', 'addressDelivery.usaState.uspsCode', FieldType.TEXT, '40px');
  public static ADDRESS_DELIVERY_CITY = new TextColumn('addressDeliveryCity', 'To City', 'addressDelivery.city', FieldType.TEXT, '90px');
  public static ADDRESS_DELIVERY_ZIP = new TextColumn('addressDeliveryZip', 'To ZIP', (o) => convertSubString(o, 'addressDelivery.postCode', 0, 5), FieldType.TEXT, '40px');

  public static DRIVER_NAME = ((userService: UserService, onClick: (row: OrderDispatch) => any) =>
    new HyperLinkColumn('driverId', 'Driver', 'driver.fullName', FieldType.TEXT, '80px', {
      class: () => 'driver',
      tooltip: (row) => row.isConfirmed ? 'Route Confirmed on ' + convertDateTime(row.dateConfirmedOn) : 'Route Not Confirmed',
      onClick: r => onClick(r)
    }).setSearch({
      searchable: true,
      searchType: 'list',
      multiple: true,
      displayFilter: true,
      items: (text: string) => searchDriver(userService, text)
    }));
  public static TRUCK_NUMBER = new TextColumn('truckNumber', 'Truck', 'truck.number', FieldType.TEXT, '80px');
  public static TRAILER_NUMBER = new TextColumn('trailerNumber', 'Trailer', 'trailer.number', FieldType.TEXT, '80px');

  public static DATE_PICKUP = ColumnType.DISPATCHED_DATE_PICKUP;
  public static DATE_DELIVERY = ColumnType.DISPATCHED_DATE_DELIVERY;

  public static DOC_CENTER = ((ordersService: OrdersService, dialogs: OmsDialogsService) =>
    ColumnType.DISPATCH_DOC_CENTER(ordersService, dialogs)
      .setHandlers({
        icon: (row, value) => {
          return value ? '' : 'fa fa-paperclip emptyValueIcon';
        },
      }));

  public static DOC_CENTER_FOR_COMPLETED = ((ordersService: OrdersService, dialogs: OmsDialogsService) =>
    ColumnType.DISPATCH_DOC_CENTER(ordersService, dialogs)
      .setHandlers({
        class: (row) => {
          return (!row.hasPodDocuments && row.loadType === LoadType.DELIVERY) ? 'warning' : '';
        },
      }));

  public static COM_CENTER = ((onClick: (row: OrderDispatch) => any) => new HyperLinkColumn('comCount', '', 'comCount', FieldType.TEXT, '15px', {
    class: (dispatch) => dispatch.hasDriverComment ? 'color-green font-bold' : '',
    header: {noarrows: true, icon: 'fa fa-comments'},
    converter: (row, value) => value || '',
    onClick: row => onClick(row),
    icon: (row, value) => value ? '' : 'fa fa-comments emptyValueIcon'
  }).setSortable(false).setSearch(false).setEditor(false));

  public static ORDER_1C = new DateCheckboxColumn('orderDate1C', '1C', 'orderDate1C', '30px')
    .setSortable(false).setSearch(false).setEditor(false);

  public static DISPATCHED_DATE = new DateColumn('dateDispatchedFor', 'Date', 'dateDispatchedFor', '35px')
    .setHandlers({header: {tooltip: 'Dispatch date'}})
    .setSortable(true)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
    .setEditor(false);

  public static DELIVERY_APPOINTMENT_REQUIRED = new CheckboxColumn('deliveryAppointmentRequired', 'DAR', 'deliveryAppointmentRequired', FieldType.BOOLEAN, '40px')
    .setEditor(true);

  public static HOT = new CheckboxColumn('hot', 'Hot', 'hot', FieldType.BOOLEAN, '40px')
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS
    })
    .setEditor(true);
  public static REQUESTED_DELIVERY_DATE = new DateTimeColumn('requestedDeliveryDate', 'RDD', 'requestedDeliveryDate', '100px')
    .setSearch({searchable: true, searchFunction: "isTheSameDay", searchType: "time"})
    .setEditor(true)
    .setHistory({logChanges: true, field: 'requestedDeliveryDate', logConverter: true});

  public static DATE_CREATED = new DateTimeColumn('dateCreated', 'Manifested Date', 'dateCreated', ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'});
  public static CREATED_BY = new TextColumn('createdBy', 'Manifested By', 'createdBy', FieldType.TEXT, ColumnWidth.DATE_TIME);

  public static ORDER_DATE_CREATED = new DateTimeColumn('orderDateCreated', 'Created Date', 'orderDateCreated', ColumnWidth.DATE_TIME)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'});
  public static ORDER_CREATED_BY = new TextColumn('orderCreatedBy', 'Created By', 'orderCreatedBy', FieldType.TEXT, ColumnWidth.DATE_TIME);

  public static ORDER_COD = new CheckboxColumn('cod', 'COD', 'cod', FieldType.BOOLEAN, '40px')
    .setHandlers({
      class: () => 'custom-bgcolor-red'
    })
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: 'isSelectedValue',
      searchType: 'list',
      items: BaseColumn.YES_NO_FILTERS
    })
    .setEditor(true)
    .setHistory({logChanges: true, field: 'requestedDeliveryDate', logConverter: true});

  public static ROUTE_DATE = new TextColumn('dateDispatchedFor', 'Route date', 'dateDispatchedFor', FieldType.TEXT, '60px')
    .setHandlers({
      converter: (row: OrderDispatch, value) => moment(value).tz(OmsConstants.ETC_ZONE).format('DD-MMM')
    })
    .setSortable(true)
    .setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'})
    .setEditor(false);

  public static DRIVER_ROUTE_LOADS = new CustomColumn(ColumnIds.MASTER_FILL_STATUS, 'Status', 'fillStatus', FieldType.TEXT, '1400px')
    .setComponent(DriverRoutesLoadsTableCellComponent)
    .setEditor(false);

  public static DRIVER_MANIFEST_ID = ((onClick: (row: DriverOrderDispatch) => any) => new HyperLinkColumn('dispatchIds', 'RT#', 'dispatchIds', FieldType.TEXT, '100px',
    {
      converter: (row: DriverOrderDispatch) => isMultiRecord(row) ? 'multi' : convertManifestNumber(row.loads[0].dispatchId),
      onClick: (row: DriverOrderDispatch) => {
        if (!isMultiRecord(row)) {
          onClick(row);
        }
      }
    }));

  public static DISPATCH_LOAD_STATUS = new BaseColumn('dispatchItemStatus', 'Load Status', (row: OrderDispatch) => row.getStatusName(), FieldType.TEXT, '100px')
    .setSearch({
      searchable: true,
      search: '',
      searchFunction: "isSelectedValue",
      searchType: "list",
      multiple: true,
      items: [{id: "completed", label: "Completed"},
        {id: "completed_with_problem", label: "Completed with problem"},
        {id: "not_completed", label: "Not completed"}]
    });
}

function getChassisOrUld(row: OrderDispatch): string {
  if (OrderModes.isFCL(row.orderMode)) {
    return row.chassis;
  } else {
    return row.uldCount;
  }
}

function isMultiRecord(driverOrderDispatch: DriverOrderDispatch): boolean {
  return ArrayUtils.removeDuplicate(driverOrderDispatch.loads, l => l.dispatchId).length > 1;
}

function searchDriver(userService: UserService, searchText: string): Observable<PageResult<Driver>> {
  return userService.findUsersByRoles(['ROLE_DRIVER'], true, searchText).pipe(map((response) => {
    return PageResult.fromArray(response.content.filter(user => user.driver).map(user => user.driver));
    // response.content.forEach(driver => driver['label'] = driver.fullName);
  }));
}
