import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ButtonConfig, DialogType, ModalResult} from "../oms-dialogs.types";


@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  ResultType = ModalResult;
  DialogType = DialogType;
  buttons: Map<ModalResult, ButtonConfig> = new Map<ModalResult, ButtonConfig>();
  type: DialogType;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.type = this.data.type;
    this.data.buttons.forEach(btn => {
      if (btn.caption) {
        this.buttons.set(btn.result, btn);
      } else {
        this.buttons.set(btn, {result: btn, caption: btn});
      }
    });
  }

  getBtnCaption(mr: ModalResult): string {
    return this.buttons.has(mr) ? this.buttons.get(mr).caption : mr;
  }

  closeDialog(button: ModalResult) {
    this.dialogRef.close({result: button});
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeDialog(ModalResult.CANCEL);
    }
  }

}
