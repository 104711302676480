<!--div class="form-group"-->

  <div class="row">

    <!-- ORDER MODES -->
    <div class="col-xs-6">
      <oms-custom-search [multiple] = "true" [searchable]="false" [items]="modes.items" label="Order Modes"
                         [(ngModel)]="modes.selected" [sort-by]="'id'" (changing)="onOrderModesChange($event)">
        <ng-container group-before>
          <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
        </ng-container>
      </oms-custom-search>
    </div>

    <!-- ORDER STATUS -->
    <div class="col-xs-6">
      <oms-custom-search [multiple] = "true" [searchable]="false" [items]="status.items" label="Order Status"
                         [(ngModel)]="status.selected" [sort-by]="'id'" (changing)="onOrderStatusChange($event)">
        <ng-container group-before>
          <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
        </ng-container>
      </oms-custom-search>
    </div>

  </div>

  <div class="row">
    <!-- CUSTOMERS -->
    <div class="col-xs-6">
      <oms-custom-search [multiple] = "true" [searchable]="true" [service]="customerService" label="Customer"
                         [(ngModel)]="selectedCustomers" [sort-by]="'name'" (changing)="onCustomerChange()">
        <ng-container group-before>
          <!--mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon-->
          <i class="fa fa-user"></i>
        </ng-container>
      </oms-custom-search>
    </div>

    <!-- DELIVERED OPTIONS -->
    <div class="col-xs-6">
      <oms-custom-search
        [multiple] = "false" [searchable]="false" [items]="deliveredOptions" label="Delivered Options&hellip;"
        [bindLabel]="'label'" [bindValue]="'id'" [(ngModel)]="selectedDeliveredOption" [sort-by]="'id'"
        (changing)="setSelectedDeliveredOption($event.id)">
        <ng-container group-before>
          <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
        </ng-container>
      </oms-custom-search>
    </div>

  </div>

  <div class="row">
    <!-- FREIGHT FORWARDER -->
    <div class="col-xs-6">
      <oms-custom-search  [multiple] = "true" [searchable]="true" [service]="freightForwarderService" label="F.Forwarder"
                          [(ngModel)]="selectedFreightForwarders" [sort-by]="'name'" (changing)="onFreightForwarderChange()">
        <ng-container group-before>
          <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
        </ng-container>
      </oms-custom-search>
    </div>

    <div class="col-xs-6">
      <oms-custom-search [multiple] = "true" [searchable]="true" [service]="freightForwarderGroupService" label="F.Forwarder Group"
                         [(ngModel)]="selectedFreightForwarderGroups" [sort-by]="'name'" (changing)="onFreightForwarderGroupChange()">
        <ng-container group-before>
          <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
        </ng-container>
      </oms-custom-search>

    </div>
  </div>

<!--/div-->

<hr class="form-separator"/>

<div class="row">
  <div class="col-md-6">
    <oms-checkbox label="Created or Updated in Current Year (YTD)" [(checked)]="updatedYtd"></oms-checkbox>
  </div>
  <div class="col-md-6">
    <oms-date-input
      [inline]="true" [display-format]="'DD-MMM-YYYY'" [time]="false" label="Created Since"
      [ngModel]="dateCreatedSince" (ngModelChange)="setDateCreatedSince($event)" ></oms-date-input>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
  </div>
  <div class="col-md-6">
    <oms-custom-search [(ngModel)]="ordersBilled" [inline]="true" [multiple]="false" [label]="'Billed'"
                       [items]="[filterYes, filterNo]">
    </oms-custom-search>
  </div>
</div>


