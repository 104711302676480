var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable, Subject } from 'rxjs';
import { RestService } from '../../../services/rest.service';
import { HttpUtilsService } from '../../../services/http-utils.service';
import { City } from '../models';
import { BaseEntityService } from '../../../services/base/base-entity.service';
var API_URL = '/oms/city/';
var CityService = /** @class */ (function (_super) {
    __extends(CityService, _super);
    function CityService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = API_URL;
        _this.classType = City;
        _this.citiesSubject = new Subject();
        return _this;
    }
    CityService.prototype.getAllCitiesByCountryId = function (countryId) {
        var _this = this;
        return new Observable(function (obs) {
            _this.httpRestService.get(API_URL + 'getAllCitiesByCountryId', countryId).subscribe(function (items) {
                _this.citiesSubject.next(items);
                obs.next(items);
                obs.complete();
            });
        });
    };
    return CityService;
}(BaseEntityService));
export { CityService };
