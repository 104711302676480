import { AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var AlertDialogComponent = /** @class */ (function () {
    function AlertDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.mode = 'info';
        if (data) {
            this.message = data.message || this.message;
            this.mode = data.mode || 'info';
            this.caption = data.caption;
            this.timeout = data.timeout;
            this.button = data.button;
        }
    }
    Object.defineProperty(AlertDialogComponent.prototype, "dialogCaption", {
        get: function () {
            if (this.caption) {
                return this.caption;
            }
            switch (this.mode) {
                case 'success': return 'Success';
                case 'info': return 'Information';
                case 'warning': return 'Warning';
                case 'danger': return 'Error';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertDialogComponent.prototype, "iconClass", {
        get: function () {
            switch (this.mode) {
                case 'success': return 'fa fa-check-circle';
                case 'info': return 'fa fa-info-circle';
                case 'warning': return 'fa fa-exclamation-triangle';
                case 'danger': return 'fa fa-times-circle';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertDialogComponent.prototype, "dialogClass", {
        get: function () {
            switch (this.mode) {
                case 'success': return 'alert alert-success';
                case 'info': return 'alert alert-info';
                case 'warning': return 'alert alert-warning';
                case 'danger': return 'alert alert-danger';
            }
        },
        enumerable: true,
        configurable: true
    });
    AlertDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.timeout > 0) {
            setTimeout(function () { return _this.dialogRef.close(); }, this.timeout);
        }
    };
    AlertDialogComponent.prototype.onButtonClick = function (button) {
        if (!!button.handler) {
            button.handler();
        }
        else {
            this.dialogRef.close(button.result);
        }
    };
    return AlertDialogComponent;
}());
export { AlertDialogComponent };
