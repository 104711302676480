var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CbpStatusService } from "../../../modules/shared/services/stb-status/cbp.status.service";
import { AbstractEntityListComponent } from "../../../modules/settings/pages/abstract-entity-list.component";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { OmsDialogsService } from "../oms-dialogs";
import { FileUploadService } from '../../../services/file.upload.service';
import { isNullOrUndefined } from "util";
import { OmsDateTimePipe } from "../../../modules/shared/services/oms-converters.service";
import { LogChangesService } from "../../../services/logchanges/log.changes.service";
import { CbqQuickViewStatusColumnsService } from "../../../common/cbq-quick-view-status-columns.service";
import { CbpStatusTableComponent } from "../cbp-status-dialog/cbp-status-table/cbp-status-table.component";
var CbpQuickViewStatusDialogComponent = /** @class */ (function (_super) {
    __extends(CbpQuickViewStatusDialogComponent, _super);
    function CbpQuickViewStatusDialogComponent(dialogRef, data, dialog, cdr, alerts, dialogs, cbpStatusService, omsColumns, fileUploadService, logChangesService) {
        var _this = _super.call(this, cdr, alerts, cbpStatusService, dialogs, fileUploadService) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.dialogs = dialogs;
        _this.cbpStatusService = cbpStatusService;
        _this.omsColumns = omsColumns;
        _this.fileUploadService = fileUploadService;
        _this.logChangesService = logChangesService;
        _this.specificRowClassHandler = [
            {
                field: "inSystem",
                value: "false",
                class: "table-row-missed"
            }
        ];
        _this.selectedMainTab = "ams";
        _this.cbpStatuses = [];
        _this.logChanges = [];
        _this.convertByBaseType = false;
        _this.omsDatePipe = new OmsDateTimePipe;
        _this.defaultSortColumn = "transactionDate";
        _this.cbpStatusService.cbpStatusSubject.subscribe(function (cbpStatuses) {
            switch (_this.objectType) {
                case "MASTER":
                    _this.cbpStatuses = cbpStatuses.filter(function (cbpStatus) { return isNullOrUndefined(cbpStatus.hawb); });
                    break;
                case "ORDER":
                    _this.cbpStatuses = cbpStatuses;
                    break;
            }
        });
        return _this;
    }
    CbpQuickViewStatusDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.objectType = this.data.objectType;
        this.omsColumns.cbpQuickViewStatusColumns.subscribe(function (columns) {
            _this.columns = columns;
        });
        this.omsColumns.getCbpQuickViewStatusColumns(this.data.objectType);
        /*      switch (this.data.objectType) {
              case "MASTER":
                this.cbpStatusService.findAllByMasterId(this.data.id).subscribe();
                break;
              case "ORDER":
                this.cbpStatusService.findAllByOrderId(this.data.id).subscribe();
                break;
            } */
        this.logParams = this.data.logParams;
        this.selectedParameter = this.logParams[0].key;
        this.logChangesService.getLogChanges(this.logParams[0].id, this.logParams[0].className, this.logParams[0].field).then(function (logChanges) {
            _this.logChanges = logChanges;
            _this.cdr.markForCheck();
        });
        this.column = this.data.column;
        this.convertByBaseType = this.data.convertByBaseType;
    };
    CbpQuickViewStatusDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    CbpQuickViewStatusDialogComponent.prototype.changeTab = function (tab) {
        this.selectedMainTab = tab;
    };
    CbpQuickViewStatusDialogComponent.prototype.convertValue = function (value, logChanges) {
        if (this.convertByBaseType) {
            return this.getConvertedValueByLogChanges(value, logChanges);
        }
        return this.column.convert(null, value);
    };
    CbpQuickViewStatusDialogComponent.prototype.getConvertedValueByLogChanges = function (value, logChanges) {
        if (value && (logChanges.valueType.indexOf("Date") > -1 || logChanges.valueType.indexOf("Timestamp") > -1)) {
            var date = new Date(value);
            return this.omsDatePipe.transform(date);
        }
        return value;
    };
    CbpQuickViewStatusDialogComponent.prototype.getRowClass = function (row) {
        if (row.hawb && !row.order) {
            return 'warning';
        }
        return '';
    };
    CbpQuickViewStatusDialogComponent.prototype.generateExcel = function () {
        this.cbpStatusTableComponent.generateExcel();
        this.dialogRef.close();
    };
    return CbpQuickViewStatusDialogComponent;
}(AbstractEntityListComponent));
export { CbpQuickViewStatusDialogComponent };
