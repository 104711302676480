var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Exclude, Type } from "class-transformer";
import { Address, Driver } from "./";
import { BaseDeletable } from "./base-deletable";
import { UserAms } from "./user-ams";
import { UserClient } from "./user-client";
import { UserProfile } from "./user-profile";
import { UserRole } from "./user-role";
var ɵ0 = function () { return UserProfile; }, ɵ1 = function () { return Driver; }, ɵ2 = function () { return UserClient; }, ɵ3 = function () { return UserAms; }, ɵ4 = function () { return UserRole; }, ɵ5 = function () { return Address; };
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userRoles = [];
        return _this;
    }
    Object.defineProperty(User.prototype, "fullName", {
        get: function () {
            return this.firstName + ' ' + this.lastName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "fullNameWithEmail", {
        get: function () {
            return this.firstName + ' ' + this.lastName + ' <' + this.email + '>';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "label", {
        get: function () {
            return this.fullName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "icon", {
        get: function () {
            return 'fa fa-user';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "canDeleteOrder", {
        get: function () {
            return this.profile == null || this.profile.canRemoveOrder;
        },
        enumerable: true,
        configurable: true
    });
    User.prototype.hasRole = function (role) {
        return !role || this.userRoles.some(function (ur) { return ur.roleName === role; });
    };
    User.prototype.hasAnyRoles = function (roles) {
        var _this = this;
        if (!roles || !roles.length) {
            return false;
        }
        return roles.some(function (r) { return _this.hasRole(r); });
    };
    User.prototype.isValid = function () {
        if (!this.userRoles) {
            return false;
        }
        return true;
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", UserProfile)
    ], User.prototype, "profile", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Driver)
    ], User.prototype, "driver", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", UserClient)
    ], User.prototype, "userClient", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", UserAms)
    ], User.prototype, "userAms", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", Array)
    ], User.prototype, "userRoles", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Address)
    ], User.prototype, "homeAddress", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], User.prototype, "fullName", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], User.prototype, "fullNameWithEmail", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], User.prototype, "label", null);
    __decorate([
        Exclude(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], User.prototype, "icon", null);
    return User;
}(BaseDeletable));
export { User };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
