import {Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import {OmsTabsComponent, TabConfig} from "./oms-tabs.component";
import {Subscription} from "rxjs";

@Directive({
  selector: '[oms-tab-button]'
})
export class OmsTabButtonDirective implements OnDestroy {
  @Input() public tab: TabConfig;
  public sub: Subscription[] = [];
  public active: boolean;

  constructor(
    public parent: OmsTabsComponent,
    public elementRef: ElementRef
  ) {
    this.sub.push(this.parent.activeChange.subscribe(() => this.setActive(this.tab && this.tab.active)));
    setTimeout(() => this.setActive(this.tab && this.tab.active));
  }

  ngOnDestroy(): void {
    this.sub.forEach((s) => s.unsubscribe());
  }


  private setActive(active: boolean) {
    if (this.active !== active) {
      this.active = active;

      if (this.active) {
        this.elementRef.nativeElement.click();
      }
    }
  }


}
