import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { CbpStatus } from "../../../modules/shared/models/cbp.status";
import { CbpStatusService } from "../../../modules/shared/services/stb-status/cbp.status.service";
import { BaseColumn } from "../../../modules/shared/components/base/data-table/columns/column-types";
import { AbstractEntityListComponent } from "../../../modules/settings/pages/abstract-entity-list.component";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { OmsDialogsService } from "../oms-dialogs";
import { FileUploadService } from '../../../services/file.upload.service';
import { isNullOrUndefined } from "util";
import { LogChanges } from "../../../modules/shared/models/logchanges/log.changes";
import { OmsDateTimePipe } from "../../../modules/shared/services/oms-converters.service";
import { LogChangesService } from "../../../services/logchanges/log.changes.service";
import { CbqQuickViewStatusColumnsService } from "../../../common/cbq-quick-view-status-columns.service";
import { CbpStatusTableComponent } from "../cbp-status-dialog/cbp-status-table/cbp-status-table.component";

@Component({
  selector: 'oms-cbp-quick-view-status-dialog',
  templateUrl: './cbp-quick-view-status-dialog.component.html',
  styleUrls: ['./cbp-quick-view-status-dialog.component.scss']
})
export class CbpQuickViewStatusDialogComponent extends AbstractEntityListComponent<CbpStatus> implements OnInit {

  @ViewChild(CbpStatusTableComponent) cbpStatusTableComponent: CbpStatusTableComponent;
  public specificRowClassHandler = [
    {
      field: "inSystem",
      value: "false",
      class: "table-row-missed"
    }
  ];

  selectedMainTab: string = "ams";

  cbpStatuses: CbpStatus[] = [];
  columns: BaseColumn[];
  objectType: string;


  logChanges: LogChanges[] = [];
  column: BaseColumn;
  convertByBaseType: boolean = false;
  omsDatePipe: OmsDateTimePipe = new OmsDateTimePipe;
  logParams: any[];
  selectedParameter: any;

  defaultSortColumn: string = "transactionDate";

  constructor(public dialogRef: MatDialogRef<CbpQuickViewStatusDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              protected cdr: ChangeDetectorRef,
              protected alerts: OmsAlertsService,
              protected dialogs: OmsDialogsService,
              public cbpStatusService: CbpStatusService,
              private omsColumns: CbqQuickViewStatusColumnsService,
              protected fileUploadService: FileUploadService,
              private logChangesService: LogChangesService) {
    super(cdr, alerts, cbpStatusService, dialogs, fileUploadService);
    this.cbpStatusService.cbpStatusSubject.subscribe(cbpStatuses => {
      switch (this.objectType) {
        case "MASTER":
          this.cbpStatuses = cbpStatuses.filter(cbpStatus => isNullOrUndefined(cbpStatus.hawb));
          break;
        case "ORDER":
          this.cbpStatuses = cbpStatuses;
          break;
      }

    });
  }


  ngOnInit() {
    this.objectType = this.data.objectType;
    this.omsColumns.cbpQuickViewStatusColumns.subscribe(columns => {
      this.columns = columns;
    });
    this.omsColumns.getCbpQuickViewStatusColumns(this.data.objectType);


/*      switch (this.data.objectType) {
      case "MASTER":
        this.cbpStatusService.findAllByMasterId(this.data.id).subscribe();
        break;
      case "ORDER":
        this.cbpStatusService.findAllByOrderId(this.data.id).subscribe();
        break;
    } */

    this.logParams = this.data.logParams;
    this.selectedParameter = this.logParams[0].key;
    this.logChangesService.getLogChanges(this.logParams[0].id, this.logParams[0].className, this.logParams[0].field).then(logChanges => {
      this.logChanges = logChanges;
      this.cdr.markForCheck();
    });
    this.column = this.data.column;
    this.convertByBaseType = this.data.convertByBaseType;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeTab(tab) {
    this.selectedMainTab = tab;
  }

  convertValue(value: any, logChanges: LogChanges) {
    if (this.convertByBaseType) {
      return this.getConvertedValueByLogChanges(value, logChanges);
    }
    return this.column.convert(null, value);
  }

  getConvertedValueByLogChanges(value: any, logChanges: LogChanges) {
    if (value && (logChanges.valueType.indexOf("Date") > -1 || logChanges.valueType.indexOf("Timestamp") > -1)) {
      let date = new Date(value);
      return this.omsDatePipe.transform(date);
    }
    return value;
  }

  public getRowClass(row: CbpStatus): string {
    if (row.hawb && !row.order) {
      return 'warning';
    }
    return '';
  }

  generateExcel() {
    this.cbpStatusTableComponent.generateExcel();
    this.dialogRef.close();
  }
}
