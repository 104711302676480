var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
import { Country } from '../models/address/country';
import { plainToClass } from 'class-transformer';
import { BaseEntityService } from '../../../services/base/base-entity.service';
import { HttpUtilsService } from '../../../services/http-utils.service';
import { RestService } from '../../../services/rest.service';
import { map } from 'rxjs/operators';
var CountryService = /** @class */ (function (_super) {
    __extends(CountryService, _super);
    function CountryService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/country/';
        _this.classType = Country;
        _this.countriesSubject = new Subject();
        return _this;
    }
    CountryService.prototype.getByIso2Code = function (code) {
        var _this = this;
        return this.httpRestService.get(this.apiUrl + 'getByIso2Code', code)
            .pipe(map(function (item) { return plainToClass(_this.classType, item); }));
    };
    CountryService.prototype.getByIso3Code = function (code) {
        var _this = this;
        return this.httpRestService.get(this.apiUrl + 'getByIso3Code', code)
            .pipe(map(function (item) { return plainToClass(_this.classType, item); }));
    };
    /***@deprecated***/
    CountryService.prototype.getAllCountries = function () {
        var _this = this;
        this.httpRestService.get(this.apiUrl + 'getAllCountries').subscribe(function (items) {
            _this.countriesSubject.next(plainToClass(Country, items));
        });
    };
    return CountryService;
}(BaseEntityService));
export { CountryService };
