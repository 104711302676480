﻿<div class="form-group search" [id]="identifier">
  <span style="width: 100%;">
    <label class="control-label">{{label}}</label>
    <span style="float: right;"><ng-content></ng-content></span>
  </span>
  <div id="group123" class="input-group" [ngClass]="{'input-group-sm': size == Size.SMALL, 'input-group-lg': size == Size.LARGE}" style="width: 100%">
    <ng-select class="form-control"
               [appendTo]="'body'"
               [disabled]="disabled"
               [popover]="tooltip"
               bindLabel="iataCode"
               [items]="subjectAirports | async"
               (change)="change($event)"
               (click)="toggleOpen($event)"
               (clickOutside)="_handleOutsideClick($event)"
               [(ngModel)]="value"
               (keyup)="onKeyUp($event)"
               (keydown)="onKeyDown($event)"
               (open)="onOpen()"
               placeholder="Enter name and select item">

      <ng-template ng-footer-tmp>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div><b>({{item.iataCode}})</b> - {{item.name}}</div>
      </ng-template>

    </ng-select>


  </div>

  <validation [@flyInOut]="'in,out'" *ngIf="invalid | async" [messages]="failures | async"></validation>

</div>

