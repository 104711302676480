﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget" (drop)="drop($event)"
       (dragover)="allowDrop($event)" [ngClass]="{ '_loading' : viewLoading, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">
    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-comments"></i>
        {{getTitle()}}
      </h3>
      <em>{{getSubTitle()}}</em>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
        </a>
      </div>
    </div>


    <form class="widget-content" [formGroup]="form" style="min-height: 450px;">

      <ng-container *ngIf="!readonly">
        <label class="control-label">Message</label>
        <div class="actions">
          <div class="col-lg-2 actions-column">
            <button type="button" class="btn btn-primary send-btn" (click)="save()" [disabled]="viewLoading">Send</button>
          </div>


          <div class="col-lg-7 actions-column">
                <span class="actions__osd">

                  <span class="widget-content-osd">
                    <ng-container *ngIf="noteTypeId == 5">
                      <span class="osd-item">
                        <span class="osd-label">O</span>
                        <td class="osd-checkbox" scope="row" >
                            <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(1)">
                              <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(1) ? '' : null" title="">
                              <span></span>
                            </span>
                        </td>
                      </span>

                      <span class="osd-item">
                        <span class="osd-label">S</span>
                        <td class="osd-checkbox" scope="row" >
                            <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(2)">
                              <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(2) ? '' : null" title="">
                              <span></span>
                            </span>
                        </td>
                      </span>

                      <span class="osd-item">
                        <span class="osd-label">D</span>
                        <td class="osd-checkbox" scope="row" >
                            <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(3)">
                              <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(3) ? '' : null" title="">
                              <span></span>
                            </span>
                        </td>
                      </span>
                    </ng-container>

                    <span class="osd-item" *ngIf="noteTypeId == 4">
                        <span class="osd-label">HAZ</span>
                        <td class="osd-checkbox" scope="row" >
                            <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(4)">
                              <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(4) ? '' : null" title="">
                              <span></span>
                            </span>
                        </td>
                      </span>
                  </span>

                </span>
          </div>

          <div class="col-lg-3 actions-column">
                <span class="actions__download">
                  <input [attr.readonly]="true" class="actions__download-label form-control" type="text" [ngModel]="getSelectedFileName()" [ngModelOptions]="{standalone: true}">
                  <span class="input-group-btn">
                   <span class="btn btn-info btn-file">
                     <i class="fa fa-paperclip"></i><input type="file" (change)="selectFile($event)"/>
                   </span>
                  </span>
                </span>
          </div>
        </div>

        <div class="form-row editor-section">
          <span class="input-group" style="width:100%">

  <!--
            <div class="widget-header-actions">


              <span class="widget-header-actions-left widget-content-osd-wrapper">
                <span class="widget-content-osd" *ngIf="noteTypeId == 5">
                  <span class="osd-item">
                    <span class="osd-label">O</span>
                    <td class="osd-checkbox" scope="row" >
                        <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(1)">
                          <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(1) ? '' : null" title="">
                          <span></span>
                        </span>
                    </td>
                  </span>

                  <span class="osd-item">
                    <span class="osd-label">S</span>
                    <td class="osd-checkbox" scope="row" >
                        <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(2)">
                          <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(2) ? '' : null" title="">
                          <span></span>
                        </span>
                    </td>
                  </span>

                  <span class="osd-item">
                    <span class="osd-label">D</span>
                    <td class="osd-checkbox" scope="row" >
                        <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(3)">
                          <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(3) ? '' : null" title="">
                          <span></span>
                        </span>
                    </td>
                  </span>
                </span>
              </span>


              <span class="widget-header-actions-left widget-content-osd-wrapper" style="margin-left: 20px" *ngIf="noteTypeId == 4">
                <span class="widget-content-osd">
                  <span class="osd-item">
                    <span class="osd-label">HAZ</span>
                    <td class="osd-checkbox" scope="row" >
                        <span class="fancy-checkbox custom-bgcolor-green" (click)="toggleItem(4)">
                          <input type="checkbox" value="" class="table-checkbox" [attr.checked]="isSelected(4) ? '' : null" title="">
                          <span></span>
                        </span>
                    </td>
                  </span>
                </span>
              </span>



              <span class="widget-header-actions-right">

                <span class="widget-header-actions-download input-group">
                  <input [attr.readonly]="true" class="widget-header-actions-download-label form-control" type="text" [ngModel]="getSelectedFileName()" [ngModelOptions]="{standalone: true}">
                  <span class="input-group-btn">
                   <span class="btn btn-info btn-file">
                     <i class="fa fa-paperclip"></i><input type="file" (change)="selectFile($event)"/>
                   </span>
                  </span>
                </span>

              </span>

            </div>
  -->
           <!-- <input class="form-control" placeholder="Message" formControlName="message" />-->
            <div id="summernote" [ngxSummernote]="editorConfig" [formControl]="getFormControl('message')"></div>


          </span>

        </div>
      </ng-container>

      <div #comments class="form-row comments">
        <div class="note-comment" *ngFor="let objectComCenter of  com.objectComCentersSubject | async | noteCenterTypeFilterPype:noteTypeId">
          <span class="note-author">{{objectComCenter.comCenter.date|oms_date_time}} - {{objectComCenter.comCenter.userName}} {{objectComCenter.comCenter.noteType === 'DEFAULT' ? '' : ' - ' +objectComCenter.comCenter.noteType}} :&emsp;</span>
          <span style="display: inline-block" [innerHTML]="objectComCenter.comCenter.text"></span>

          <span class="action-section" *ngIf="!readonly && !sendonly">
            <span class="download-section" *ngIf="objectComCenter.comCenter.uploadedFiles">
              <a  [href]="buildDownloadUrl(objectComCenter.comCenter)"><i class="fa fa-paperclip"></i></a>
            </span>

            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <li class="glyphicon glyphicon-option-vertical"></li>
              </a>
              <ul class="dropdown-menu">
                <li><i class="fa fa-edit" (click)="edit(objectComCenter)"></i></li>
                <li><i class="fa fa-trash" (click)="delete(objectComCenter)"></i></li>
              </ul>
            </li>
          </span>
          <hr>
        </div>
      </div>
    </form>

    <div class="widget-footer right">
      <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
    </div>

  </div>
</perfect-scrollbar>
