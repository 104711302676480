var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { FreightForwarder } from '../../../modules/shared/models';
import { FreightForwarderService } from '../../../services/freight.forwarder.service';
import { AbstractEntityDialogComponent } from 'src/app/modules/settings/pages/abstract-entity-dialog.component';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { AddressService } from '../../../modules/shared/services/address.service';
import { NgSelectSearchParams } from '../../../modules/settings/util/ng-select-search-params';
import { FreightForwarderGroupService } from "../../../services/freight.forwarder.group.service";
import { FreightForwarderGroup } from "../../../modules/shared/models/freight.forwarder.group";
import { isNullOrUndefined } from "util";
import { FreightForwarderEmail } from "../../../modules/shared/models/freight.forwarder-email";
import { FileUploadService } from "../../../services/file.upload.service";
import { buildCfsStatusPageUrl, copyToClipboard } from "../../../_helpers/utils";
import { flatMap } from "rxjs/operators";
var FreightForwarderDialogComponent = /** @class */ (function (_super) {
    __extends(FreightForwarderDialogComponent, _super);
    function FreightForwarderDialogComponent(dialogRef, validators, asyncValidators, data, fb, freightForwarderGroupService, freightForwarderService, addressService, alerts, file) {
        var _this = _super.call(this, dialogRef, freightForwarderService, alerts) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.fb = fb;
        _this.freightForwarderGroupService = freightForwarderGroupService;
        _this.freightForwarderService = freightForwarderService;
        _this.addressService = addressService;
        _this.alerts = alerts;
        _this.file = file;
        _this.freightForwarder = new FreightForwarder();
        _this.emails = Object.values(FreightForwarderEmail);
        return _this;
    }
    FreightForwarderDialogComponent.prototype.ngOnInit = function () {
        this.freightForwarder = Object.assign(this.freightForwarder, this.data.freightForwarder);
        this.freightForwarderGroupSearch = new NgSelectSearchParams(this.freightForwarderGroupService);
        this.edit = !this.freightForwarder.isNew();
        this.addressSearch = new NgSelectSearchParams(this.addressService);
        this.viewLoading = true;
        this.freightForwarder = this.data.freightForwarder;
        this.createForm();
        this.viewLoading = false;
    };
    FreightForwarderDialogComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            name: [this.freightForwarder.name, Validators.required],
            freightForwarderGroup: [this.freightForwarder.freightForwarderGroup, Validators.required],
            address: [this.freightForwarder.address],
            email: [this.freightForwarder.emails],
            sendPODToEmails: [this.freightForwarder.sendPODToEmails]
        });
    };
    FreightForwarderDialogComponent.prototype.getTitle = function () {
        if (this.freightForwarder.id > 0) {
            return 'Edit Freight Forwarder';
        }
        return 'New Freight Forwarder';
    };
    FreightForwarderDialogComponent.prototype.prepare = function () {
        var controls = this.form.controls;
        var _freightForwarder = this.freightForwarder;
        _freightForwarder.name = controls['name'].value;
        _freightForwarder.address = controls['address'].value;
        var freightForwarderGroup = controls['freightForwarderGroup'].value;
        if ((typeof freightForwarderGroup) === "string") {
            _freightForwarder.freightForwarderGroup = new FreightForwarderGroup();
            _freightForwarder.freightForwarderGroup.name = freightForwarderGroup;
        }
        else {
            _freightForwarder.freightForwarderGroup = freightForwarderGroup;
        }
        _freightForwarder.sendPODToEmails = controls['sendPODToEmails'].value;
        var emailValues = controls['email'].value;
        if (emailValues) {
            var updateFreightForwarderEmails_1 = _freightForwarder.emails;
            _freightForwarder.emails = [];
            emailValues.forEach(function (emailValue, index) {
                if (!isNullOrUndefined(emailValue)) {
                    if (updateFreightForwarderEmails_1 && updateFreightForwarderEmails_1.length > index) {
                        var freightForwarderEmail = updateFreightForwarderEmails_1[index];
                        freightForwarderEmail.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
                        _freightForwarder.emails.push(freightForwarderEmail);
                    }
                    else {
                        var email = new FreightForwarderEmail();
                        email.email = !isNullOrUndefined(emailValue.email) ? emailValue.email : emailValue;
                        _freightForwarder.emails.push(email);
                    }
                }
            });
        }
        else {
            _freightForwarder.emails = [];
        }
        return _freightForwarder;
    };
    FreightForwarderDialogComponent.prototype.onAlertClose = function () {
        this.hasFormErrors = false;
    };
    FreightForwarderDialogComponent.prototype.update = function (item) {
        var _this = this;
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        var ffg = Object.assign(new FreightForwarderGroup(), item.freightForwarderGroup);
        this.freightForwarderGroupService.save(ffg)
            .pipe(flatMap(function (freightForwarderGroup) {
            item.freightForwarderGroup = freightForwarderGroup;
            return _this.freightForwarderService.save(item);
        }))
            .subscribe(function (res) {
            _this.viewLoading = false;
            _this.dialogRef.close({ res: res, isEdit: !item.isNew() });
        }, function (error) {
            _this.viewLoading = false;
            _this.alerts.danger(error);
        });
    };
    FreightForwarderDialogComponent.prototype.getStatusPageUrl = function () {
        var ffg = this.form.controls['freightForwarderGroup'].value;
        var ffgId = ffg && ffg.id;
        return ffgId ? buildCfsStatusPageUrl(ffgId) : undefined;
    };
    FreightForwarderDialogComponent.prototype.copyStatusPageUrl = function () {
        copyToClipboard(this.getStatusPageUrl());
        this.alerts.info("Page URL copied to the Clipboard buffer", 1000);
    };
    return FreightForwarderDialogComponent;
}(AbstractEntityDialogComponent));
export { FreightForwarderDialogComponent };
