import {absent, assigned, ensureNumber} from "./utils";


/**
 *  Tries to convert any value to array of number
 * @param value: Input number, number[], string separated with ',', ';' or space
 * @return Resolve with array of any numbers found or Reject if empty array in result;
 */
export function ifNumbers(value: any): Promise<number[]> {
  return new Promise<number[]>(
    (resolve, reject) => {
        if (assigned(value)) {
          let n: number[] = value.toString()
              .split(/[\D.]/)
              .map((i) => Number(i.trim()))
              .filter((i) => !isNaN(i))
              .unique();
          if (n && n.length) {
            resolve(n);
          } else {
            reject();
          }
        } else {
          reject();
        }
    });
}


export class NumberUtils {

  public static round(value: number, frac: number): number {
    let coef = Math.pow(10, frac);
    return Math.round(value * coef) / coef;
  }

  public static kgToLbs(kg: number) {
    return kg * 2.2046;
  }

  public static lbsToKg(lbs: number): number {
    return lbs / 2.2046;
  }

  public static isNumber(value): boolean {
    return typeof value === 'number';
  }

  public static isNaN(value: any): boolean {
    return Number.isNaN(value);
  }

  public static checkNumbers(s: any): number[] {
    return (absent(s)) ? [] :
        s.toString().split('[\,\;]').map((i) => i.trim()).filter((i) => !isNaN(i));
  }
}
