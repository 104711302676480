﻿<div class="columns-configuration" *ngIf="isShown">

  <div class="columns-configuration-content">
    <div class="columns-configuration-content__header">
      <span class="close" (click)="hidePopUp()">&times;</span>
      <h6 class="columns-configuration-content__header-title">Change columns visibility</h6>
    </div>

    <div class="columns-configuration-content__settings">
      <label *ngFor="let columnSetting of columnSettings" class="oms-container">
        <input type="checkbox" [(ngModel)]="columnSetting.visible"> <span>{{columnSetting.label}}</span>
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="columns-configuration-content__buttons">
      <button type="button" (click)="saveChanges()" class="btn columns-configuration-content__buttons-btn">Save</button>
      <button type="button" (click)="hidePopUp()" class="btn columns-configuration-content__buttons-btn">Cancel</button>
    </div>
  </div>

</div>
