import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicInjectorType } from "../dynamic-injector/dynamic-injector-type";
import { OrderNotification } from "../../../models/order/order-notification";
import { isNullOrUndefined } from "util";
import { OmsConstants } from "../../../../../common/oms-constants.service";
import { MasterStatus } from "../../../models";
import { convertOrderNumber } from "../../../services/oms-converters.service";
import { DateTimeService } from "../../../../../services/date-time.service";
import { OrderNotificationService } from "../../../../../services/order-notification/order-notification.service";
import { OmsAlertsService } from "../oms-alerts.service";
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'oms-order-notification-alert',
  templateUrl: './order-notification-alert.component.html',
  styleUrls: ['./order-notification-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderNotificationAlertComponent extends DynamicInjectorType<OrderNotification> implements OnInit, OnChanges {

  orderLink: string = "";
  firstLine: string = "";
  secondLine: string = "";
  thirdLine: string = "";
  cbpStatus: string = null;
  isEdit: boolean = false;
  comment: string = "";
  dragPosition = {x: 0, y: 0};

  constructor(
    private cdr: ChangeDetectorRef,
    private orderNotificationService: OrderNotificationService,
    private dateTimeService: DateTimeService,
    private alertsService: OmsAlertsService) {
    super();
  }

  ngOnInit() {
    if (this.data) {
      this.updateMessages(this.data);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.updateMessages(this.data);
    }
  }

  private updateMessages(orderNotification: OrderNotification): void {
    const order = orderNotification.order;
    const ref = order.customerRef ? "REF:" + order.customerRef + " " : "";
    const customer = order.customerName ? "CUST:" + order.customerName + " " : "";
    const pcs = isNullOrUndefined(order.pieces) ? "" : "PCS:" + order.pieces;
    const dateCreated = this.dateTimeService.utcToNYFormat(orderNotification.dateCreated, OmsConstants.LONG_DATE_TIME_FORMAT);
    const status = MasterStatus.getLabel(orderNotification.status.id).substr(0, 30);
    this.orderLink = convertOrderNumber(order.id);
    this.firstLine = ref + customer + pcs;
    this.secondLine = (orderNotification.comment || order.customerRef || "name");
    this.thirdLine = status + " " + dateCreated;
    this.comment = orderNotification.comment;

    if (orderNotification.cbpStatus) {
      const cbpStatus = orderNotification.cbpStatus.uscsFsnStatus;
      const date = this.dateTimeService.utcToNYFormat(orderNotification.cbpStatus.fsnSentDate, OmsConstants.LONG_DATE_TIME_FORMAT);
      this.cbpStatus = "Received CBP status " + cbpStatus + " " + date;
    }
  }

  onBlurEditor() {
    this.isEdit = false;
    this.orderNotificationService.addComment(this.data.id, this.comment)
      .pipe(switchMap(() => {
        this.data.comment = this.comment;
        this.updateMessages(this.data);
        this.cdr.markForCheck();
        return this.orderNotificationService.getUnreadOrderNotifications();
      }))
      .subscribe((orderNotifications) => {
        this.displayOrderNotifications(orderNotifications);
        this.cdr.markForCheck();
      });
  }

  onKeyDown(event): void {
    if (event.key === 'Enter') {
      this.onBlurEditor();
      event.preventDefault();
    } else if (event.key === 'Escape') {
      this.comment = this.data.comment;
      this.isEdit = false;
      event.preventDefault();
    }
  }

  onMessageClosed(): void {
    this.close.emit();
  }

  dragEnded(event: any): void {
    const width = event.source.element.nativeElement.offsetWidth;
    if (width / 3 < Math.abs(event.distance.x)) {
      this.onMoveToEnd();
    }
    this.resetPosition();
  }

  onMoveToEnd() {
    this.orderNotificationService.moveToEnd(this.data.id)
      .subscribe(orderNotifications => {
        this.displayOrderNotifications(orderNotifications);
        this.cdr.detectChanges();
      });
  }

  private displayOrderNotifications(orderNotifications: OrderNotification[]): void {
    this.alertsService.setMessages(orderNotifications.map(on => ({
      componentType: OrderNotificationAlertComponent,
      componentData: on,
      onClose: () => {
        this.orderNotificationService.readOrderNotification(on.id).subscribe();
      }
    })));
  }

  resetPosition(): void {
    this.dragPosition = {x: 0, y: 0};
  }
}
