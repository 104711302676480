var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntityService } from './base/base-entity.service';
import { HttpUtilsService } from './http-utils.service';
import { RestService } from './rest.service';
import { Manifest } from "../modules/shared/models/manifest/manifest";
import { OrderDispatch } from "../modules/shared/models/dispatch/order-dispatch";
import { classToPlain, plainToClass } from "class-transformer";
import { HttpParams } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { DriverLoad } from "../modules/web-drivers/web-drivers-ui.data";
var WebDriversService = /** @class */ (function (_super) {
    __extends(WebDriversService, _super);
    function WebDriversService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/web-drivers/dispatch/';
        _this.classType = Manifest;
        return _this;
    }
    WebDriversService.prototype.getDriverRoutes = function () {
        return this.httpRestService
            .post(this.apiUrl + 'get-driver-routes', this.httpUtils.getHTTPHeaders())
            .pipe(tap(function (n) { return console.log('LOADS', n); }))
            .toPromise().
            then(function (list) { return plainToClass(OrderDispatch, list); });
    };
    /***
     * @deprecated
     * @param loads
     */
    WebDriversService.prototype.updateDispatchItem = function (loads) {
        console.info('updateDispatchItem');
        return this.httpRestService.put(this.apiUrl + 'updateDispatchItem', classToPlain(loads), this.httpUtils.getHTTPHeaders()).toPromise().
            then(function (list) { return plainToClass(OrderDispatch, list); });
    };
    WebDriversService.prototype.updateDispatchNotification = function (loads) {
        return this.httpRestService.put(this.apiUrl + 'updateDispatchNotification', classToPlain(loads), this.httpUtils.getHTTPHeaders()).toPromise().
            then(function (list) { return plainToClass(OrderDispatch, list); });
    };
    /**
     * @deprecated
     */
    WebDriversService.prototype.updateOnSiteDispatch = function (addressType, buttonType, loads) {
        var params = new HttpParams().set('addressType', addressType).set('buttonType', buttonType);
        return this.httpRestService.put(this.apiUrl + 'updateOnSiteDispatch', classToPlain(loads), this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    WebDriversService.prototype.updateLocationCompleted = function (address, loads) {
        var params = new HttpParams()
            .set('address', address.id.toString())
            .set('pick', loads.filter(function (ld) { return ld.isPickupLocation(); }).map(function (l) { return l.dispatchItemId; }).unique().toString())
            .set('drop', loads.filter(function (ld) { return ld.isDeliveryLocation(); }).map(function (l) { return l.dispatchItemId; }).unique().toString());
        return this.httpRestService.put(this.apiUrl + 'update-location-completed', '', this.httpUtils
            .getHTTPHeaders(), params)
            .toPromise();
    };
    WebDriversService.prototype.updateLocationOnSite = function (address, loads) {
        var items = loads.map(function (l) { return l.dispatchItemId; }).unique().toString();
        var params = new HttpParams()
            .set('address', address.id.toString())
            .set('items', items);
        return this.httpRestService.put(this.apiUrl + 'update-location-on-site', '', this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    WebDriversService.prototype.updateNotCompleted = function (loads) {
        var items = loads.map(function (l) { return l.dispatchItemId; }).unique().toString();
        var params = new HttpParams()
            .set('items', items);
        return this.httpRestService.put(this.apiUrl + 'update-not-completed', '', this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    WebDriversService.prototype.updateCancel = function (address, pick, drop) {
        var params = new HttpParams()
            .set('address', address.id.toString())
            .set('pick', pick.map(function (l) { return l.dispatchItemId; }).unique().toString())
            .set('drop', drop.map(function (l) { return l.dispatchItemId; }).unique().toString());
        return this.httpRestService.put(this.apiUrl + 'update-cancel', '', this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    WebDriversService.prototype.saveSignaturePdfToDispatchItem = function (id, name, comment, email, imageAsData) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = new HttpParams()
            .set('id', id)
            .set('name', name)
            .set('comment', comment)
            .set('email', email);
        return this.httpRestService.put(this.apiUrl + 'saveSignaturePdfToDispatchItem', imageAsData, httpHeader, params).toPromise();
    };
    /**
     * return ID of stored Document
      * @param loads
     * @param docType
     * @param imagesAsData
     */
    WebDriversService.prototype.savePicture = function (loads, docType, imagesAsData) {
        console.log('CALL Save Picture', docType, loads);
        var orderIds = loads.flatMap(function (ld) { return ld.orders; }).map(function (o) { return o.orderId; }).unique();
        var params = new HttpParams()
            .set('docType', docType)
            .set('orderIds', orderIds.join(','));
        return this.httpRestService.put(this.apiUrl + "savePicture", imagesAsData, this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    /**
     * @deprecated
     */
    WebDriversService.prototype.savePictureOld = function (loads, docType, imagesAsData) {
        var orderIds = loads.map(function (o) { return o.orderId; }).unique();
        var params = new HttpParams().set('docType', docType).set('orderIds', orderIds.join(','));
        return this.httpRestService.put(this.apiUrl + "savePicture", imagesAsData, this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    return WebDriversService;
}(BaseEntityService));
export { WebDriversService };
