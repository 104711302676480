import {PageResult} from '../modules/shared/models/query-models/page-result';
import {Type} from 'class-transformer';
import {Address, Driver, Uld, Trailer, Truck} from '../modules/shared/models';
import {Observable} from "rxjs";

export interface Labeled {
  label: string;
  icon?: string;
}

export enum Action {
  CREATED = 0,
  UPDATED = 1,
  DELETED = 2
}

export type OmsObjectType = 'ORDER' | 'MASTER';

export interface HyperLink {
  path: string | string[];
  params?: object;
}

export const EMPTY_LINK: HyperLink = {path: []};

export interface Billable {
  dateBilled: Date;
}

export interface Comparable<T = any> {
  equals(object: T): boolean;
}

export interface SearchService<T> {
  findBy(by: string, count?: number, active?: boolean, sort?: Sort): Promise<PageResult<T>>;
}

export interface OmsDocumentsService {
  getDocumentTypes(): Observable<string[]>;
  getDocuments(id: number, objectType: OmsObjectType, documentTypes: string[]);
}

export interface ValueConverter<T> {
  toString: (value: T) => string;
  fromString?: (s: string) => T;
}

export enum Size {
  DEFAULT, SMALL, LARGE
}

export interface Sort {
  field?: any;
  order?: 'asc' | 'desc';
}

export class LoadDispatchDto {
  loadId: number;

  direct: boolean;
  nonAMS: boolean;

  @Type(() => Date)
  datePickupEst: Date;

  pcs: number;
  hu: number;
  orderId?: number;

  @Type(() => Address)
  fromAddress: Address;
  @Type(() => Address)
  toAddress: Address;

  @Type(() => Driver)
  driver: Driver;
  @Type(() => Truck)
  truck: Truck;
  @Type(() => Trailer)
  trailer: Trailer;
}

export class UldPickupDto {

}

export class LoadPickupDto {
  orderId: number;

  @Type(() => Date)
  datePickupAct: Date;
  @Type(() => Driver)
  driver: Driver;
  @Type(() => Truck)
  truck: Truck;
  @Type(() => Trailer)
  trailer: Trailer;
  pcs: number;
  hu: number;
  uld: Uld[];
}
