<div class="form-group oms-string-input" [ngClass]="{'oms-string-input__inline': labelInline}">
  <span>
    <label class="control-label if-not-empty" *ngIf="showLabel" [attr.for]="identifier ? identifier : null">{{label}}</label>
    <span class="toolbar-right if-not-empty">
        <ng-content select="[toolbar-right]"></ng-content>
    </span>
  </span>
  <ng-content select="[input-before]"></ng-content>
  <div class="input-group" [ngClass]="{'input-group-sm': size == Size.SMALL, 'input-group-lg': size == Size.LARGE}">
    <span class="input-group-addon" *ngIf="iconClass || header">
      <i *ngIf="iconClass" ngClass="{{iconClass}}"></i>
      {{header}}
    </span>
    <span class="input-group-addon if-not-empty">
        <ng-content select="[group-before]"></ng-content>
    </span>


    <input type="text" title="" class="form-control" *ngIf="!multiline; else text"
      [id]="identifier"
      [attr.disabled]="disabled ? '' : null"
      [attr.readonly]="readonly ? '' : null"
      [placeholder]="placeholder"
      [textMask]="_mask"
      [(ngModel)]="internal"
      [ngClass]="{'form-control-invalid':(invalid | async) || invalidValue}">

    <ng-template #text>
      <textarea class="form-control"
          [id]="identifier"
          [rows]="multiline"
          [attr.disabled]="disabled ? '' : null"
          [attr.readonly]="readonly ? '' : null"
          [placeholder]="placeholder"
          [(ngModel)]="internal"
          [ngClass]="{'form-control-invalid':(invalid | async) || invalidValue}"></textarea>
    </ng-template>

    <span class="input-group-btn if-not-empty">
      <button class="btn btn-outline-secondary" popover="Show History" (click)="onShowHistory()" *ngIf="history">
        <i class="fas fa-history"></i>
      </button>
    </span>
    <ng-content select="[input-after]"></ng-content>
  </div>
  <validation [@flyInOut]="'in,out'" *ngIf="invalid | async" [messages]="failures | async"></validation>
</div>



