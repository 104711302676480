<div class="row">
  <div class="col-md-4">
    <ol class="breadcrumb">

      <li *ngFor="let breadcrumb of breadcrumbs; let first=first; let last = last;" [ngClass]="{'active': last}">
        <a [routerLink]="['/home']" *ngIf="first">
          <i class="fa fa-home" ></i>
        </a>
        <a [routerLink]="[breadcrumb.url, breadcrumb.params]">
          {{breadcrumb.label}}&nbsp;
        </a>
      </li>
    </ol>
  </div>
  <div class="btn-toolbar breadcrumb-right-buttons">
    <button class="btn btn-sm btn-clean" (click)="copyUrl()" tooltip="Copy URL to Clipboard" placement="top">
      <i class="fa fa-clipboard"></i>
      &nbsp;
    </button>
    <button class="btn btn-sm btn-clean" tooltip="Forward URL" placement="top">
      <i class="fa fa-mail-forward"></i>
    </button>
  </div>
</div>
