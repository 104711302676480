import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {OrderSubscriptionStatus} from "./order-subscription-status";
import {HttpUtilsService} from "../http-utils.service";
import {BaseEntityService} from "../base/base-entity.service";
import {OrderSubscription} from "../../modules/shared/models/order/order-subscription";
import {IHttpParams, IPostHttpOptions, RestService} from "../rest.service";
import {MasterStatusId} from "../../modules/shared/models";

@Injectable()
export class OrderSubscriptionService extends BaseEntityService<OrderSubscription> {

  public apiUrl = '/oms/order-subscriptions/';
  public classType = OrderSubscription;

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  deleteByOrders(orderIds: number[]): Observable<OrderSubscription[]> {
    return this.httpRestService.deleteWithOptions<OrderSubscription[]>(this.apiUrl + 'delete', {params: {orderIds: orderIds.join(",")}})
      .pipe(map((items) => plainToClass(OrderSubscription, items as any[])));
  }

  createByOrderIds(orderIds: number[], statuses: MasterStatusId[], additionalStatuses: OrderSubscriptionStatus[]): Observable<OrderSubscription[]> {
    return this.httpRestService.post<OrderSubscription[]>(this.apiUrl + 'create-by-orders', null, {
      params: {
        orderIds: orderIds.join(","),
        statuses: statuses.join(","),
        additionalStatuses: additionalStatuses.map(s => s.id).join(",")
      }
    })
      .pipe(map((items) => plainToClass(OrderSubscription, items as any[])));
  }

  findByOrderIds(orderIds: number[]): Observable<OrderSubscription[]> {
    return this.httpRestService.getWithOptions<OrderSubscription[]>(this.apiUrl + 'find-by-order-ids', null, {
      orderIds: orderIds.join(",")
    }).pipe(map((items) => plainToClass(OrderSubscription, items as any[])));
  }

  findCustomSubscriptionsByOrderIds(orderIds: number[]): Observable<OrderSubscription[]> {
    return this.httpRestService.getWithOptions<OrderSubscription[]>(this.apiUrl + 'find-custom-subscriptions', null,
      {orderIds: orderIds.join(",")})
      .pipe(map((items) => plainToClass(OrderSubscription, items as any[])));
  }


  getAvailableStatuses(): Observable<OrderSubscriptionStatus[]> {
    return this.httpRestService.getWithHeader<OrderSubscriptionStatus[]>(this.apiUrl + 'available-statuses', null);
  }

  saveOrderSubscription(orderSubscriptions: OrderSubscription[], email?: string): Observable<OrderSubscription[]> {
    let body = orderSubscriptions.map((os) => {
      return {
        ...os,
//        customEmail: email,
        order: {id: os.order.id}
      };
    });

    let params = email ? this.httpUtils.getHTTPParams({email: email}) : undefined;
    let options: IPostHttpOptions = {headers: this.httpUtils.getHTTPHeaders(), params: params};
    return this.httpRestService.post<OrderSubscription[]>(this.apiUrl + 'subscribe', body, options)
      .pipe(map((items) => plainToClass(OrderSubscription, items as any[])));
  }

  public addOrderCustomSubscriptions(orderIds: number[], emails: string): Observable<OrderSubscription[]> {
    let params = this.httpUtils.getHTTPParams({ids: orderIds});
    let options: IPostHttpOptions = {headers: this.httpUtils.getHTTPHeaders(), params: params};
    return this.httpRestService.post<OrderSubscription[]>(this.apiUrl + 'add-custom-subscriptions', emails, options)
      .pipe(map((items) => plainToClass(OrderSubscription, items as any[])));
  }


}
