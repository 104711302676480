import { AfterViewInit, ChangeDetectorRef, DoCheck, EventEmitter, KeyValueDiffer, KeyValueDiffers, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Master, Order, Part, RecoveryOrder, } from '../../../modules/shared/models';
import { PARENT_REF } from '../../../modules/shared/components/base/data-table/data-table.utils';
import { DataTableComponent } from '../../../modules/shared/components/base/data-table/data-table.component';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { ColumnIds } from '../../../common/column-ids';
import { isNullOrUndefined } from 'util';
import { validMAWB } from '../../../modules/shared/services/oms-converters.service';
import { sameDateTime, subtractHours } from '../../../common/oms-date-time.utils';
import { BaseEntity } from '../../../modules/shared/models';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FilterSearchColumn } from '../../../modules/shared/models/filter.search.column';
var AbstractMasterTable = /** @class */ (function () {
    function AbstractMasterTable(valueDiffers, cdr, alerts, masterProcessor, addressService, _masterService, fileUploadService, _ordersService, spinner, dialogsService, userService, authService) {
        this.valueDiffers = valueDiffers;
        this.cdr = cdr;
        this.alerts = alerts;
        this.masterProcessor = masterProcessor;
        this.addressService = addressService;
        this._masterService = _masterService;
        this.fileUploadService = fileUploadService;
        this._ordersService = _ordersService;
        this.spinner = spinner;
        this.dialogsService = dialogsService;
        this.userService = userService;
        this.authService = authService;
        this.refreshTimeout = 2000;
        this.selected = [];
        this.active = true;
        this.cellRightClickEvent = new EventEmitter(false);
        this.cellClickEvent = new EventEmitter();
        this.selectedChange = new EventEmitter();
        this.searchable = true;
        this.filter = { total: 0, search: '', colorItem: null };
        this.showDirectMasters = false;
        this.showNonAmsMasters = false;
        this.showPendingRecoveriesMasters = false;
        this.showAmsMasters = false;
        this.showCargoBldg = false;
        this.show3rdPartyCFS = false;
        this.availableStatuses = [];
        this.selectedStatuses = [];
        this.selectedFilters = [];
        this.paging = { enabled: true, pageSize: 100, pageSizes: [50, 100, 200, 500] };
        this.clientUser = false;
        this.items = [];
        this.activeSearchFilterCondition = {};
        this.pageRequest = false;
        this.unsubscribe$ = new Subject();
        this.updateData$ = new Subject();
        this.response$ = new Subject();
        this.totalPcs = 0;
        this.totalHu = 0;
        this.totalWeight = 0;
        this.totalUld = 0;
        this.orderFromMasterList$ = new BehaviorSubject(null);
        this.valuesDiffer = this.valueDiffers.find({}).create();
    }
    AbstractMasterTable.prototype.ngOnInit = function () {
        this.updateFilterButtons();
        var a = +localStorage.getItem('masterListPageSize');
        if (a > 0) {
            this.paging.pageSize = a;
        }
        this.columns = this.createColumns(this.authService.getCurrentUser().canCreateCarrier);
        //    this.spinner.show();
        this.updateDataHandler();
    };
    AbstractMasterTable.prototype.ngOnChanges = function (changes) {
        if (changes.tabInfo) {
            // console.log('CHANGED', changes);
            this.updateFilterButtons();
        }
    };
    AbstractMasterTable.prototype.updateDataHandler = function () {
        var _this = this;
        this.updateData$.pipe(takeUntil(this.unsubscribe$), 
        //      debounceTime(2000),
        switchMap(function (request) {
            _this.pageRequest = true;
            if (_this.tabInfo) {
                _this.tabInfo.isLoading = true;
            }
            if (_this.isVisible) {
                _this.spinner.show();
                if (_this.table) {
                    _this.table.beforeUpdate();
                }
                _this.cdr.markForCheck();
            }
            return _this.loadItemsByFilter(request);
        }))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (response) {
            _this.response$.next();
            _this.paging.total = response.totalElements;
            _this.items = response.content;
            _this.updateTotalValues(_this.items);
            //        console.log('LOADED >', this.tabInfo.filterName, this.items);
            _this.pageRequest = false;
            if (_this.tabInfo) {
                _this.tabInfo.isLoading = false;
            }
            _this.spinner.hide();
            if (_this.isVisible) {
                if (_this.table) {
                    _this.table.afterUpdate();
                }
                _this.cdr.markForCheck();
            }
        }, function (error) {
            _this.spinner.hide();
            _this.pageRequest = false;
            if (_this.tabInfo) {
                _this.tabInfo.isLoading = false;
            }
            _this.alerts.danger(error);
            _this.cdr.markForCheck();
        });
    };
    AbstractMasterTable.prototype.updateFilterButtons = function () {
        // No Status Selected by Default (Display all)
        // if (this.tabInfo) {
        //  this.selectedFilters = this.tabInfo.tabFilters ? [...this.tabInfo.tabFilters] : [];
        // }
        /*    if (this.tabInfo && this.tabInfo.statuses) {
              this.availableStatuses = this.tabInfo.statuses.map(statusId => {
                let status = MasterStatus.get(statusId);
                let obj = Object.assign({}, status) as ExtendedMasterStatus;
                let splits = status.label.split('.', 2);
                obj.shortName = splits[0] + '.' + splits[1][0];
                return obj;
              });
              this.selectedStatuses = [...this.availableStatuses];
            } */
    };
    AbstractMasterTable.prototype.ngAfterViewInit = function () {
    };
    AbstractMasterTable.prototype.onChangeStatus = function (status) {
        var index = this.selectedStatuses.indexOf(status);
        if (index >= 0) {
            this.selectedStatuses.splice(index, 1);
        }
        else {
            this.selectedStatuses.push(status);
        }
        this.refresh(true);
    };
    AbstractMasterTable.prototype.toggleTabFilter = function (filter) {
        if (this.selectedFilters.includes(filter)) {
            this.selectedFilters.removeAll(filter);
        }
        else {
            this.selectedFilters.push(filter);
        }
        console.log('filters', this.selectedFilters);
        this.refresh(true);
    };
    AbstractMasterTable.prototype.downloadExcel = function () {
        var _this = this;
        this._masterService.downloadMasters(this.filterPageRequest, this.alerts).pipe(takeUntil(this.unsubscribe$)).subscribe(function (res) {
            var fileURL = _this.buildShowUrl(res);
            window.open(fileURL, '_blank');
        });
    };
    AbstractMasterTable.prototype.onPageSizeChanged = function (pageSize) {
        if (!isNullOrUndefined(pageSize)) {
            localStorage.setItem('masterListPageSize', pageSize);
        }
    };
    AbstractMasterTable.prototype.buildShowUrl = function (generatedDocumentInfo) {
        return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
    };
    AbstractMasterTable.prototype.getDefaultSortableColumn = function () {
        return String(ColumnIds.MASTER_STATUS);
    };
    AbstractMasterTable.prototype.changed = function () {
        this.cdr.markForCheck();
    };
    AbstractMasterTable.prototype.updateItems = function (items) {
        var _this = this;
        if (this.items && items) {
            items.forEach(function (i) { return _this.items.replaceAll(i, i); });
            this.changed();
        }
    };
    AbstractMasterTable.prototype.refresh = function (timeout) {
        var _this = this;
        clearTimeout(this.refreshTimer);
        if (timeout) {
            this.refreshTimer = setTimeout(function () { return _this.updateData(); }, this.refreshTimeout);
        }
        else {
            this.updateData();
        }
    };
    AbstractMasterTable.prototype.updateData = function (filterPageRequest) {
        var _a;
        //    console.log('Update Tab', this.tabInfo);
        this.isDirty = false;
        if (!this.filterPageRequest) {
            return;
        }
        if (filterPageRequest) {
            this.filterPageRequest.pageSize = filterPageRequest.pageSize;
            this.filterPageRequest.pageNumber = filterPageRequest.pageNumber;
            this.filterPageRequest.statusFilters = filterPageRequest.statusFilters || [];
            this.filterPageRequest.filterByColumns = filterPageRequest.filterByColumns || [];
            if (isNullOrUndefined(filterPageRequest.findByOccurs)) {
                this.filterPageRequest.findByOccurs = this.getSearchText();
            }
            else {
                this.filterPageRequest.findByOccurs = filterPageRequest.findByOccurs;
                this.setSearchText(filterPageRequest.findByOccurs);
            }
            this.table.filter.search = this.filterPageRequest.findByOccurs;
            if (filterPageRequest.sort && filterPageRequest.sort.field) {
                this.filterPageRequest.sort = this.filterPageRequest.sort || filterPageRequest.sort;
                this.filterPageRequest.sort.field = filterPageRequest.sort.field;
                this.filterPageRequest.sort.sortedAsc = filterPageRequest.sort.sortedAsc;
            }
        }
        else {
            this.filterPageRequest.findByOccurs = this.getSearchText();
            this.filterPageRequest.filterByColumns = this.filterPageRequest.filterByColumns || [];
        }
        this.applyFilter(this.filterPageRequest);
        if (this.tabInfo.getFilters) {
            var filters = this.tabInfo.getFilters();
            (_a = this.filterPageRequest.filterByColumns).push.apply(_a, filters);
        }
        // apply status filters
        if (this.selectedFilters.length || this.tabInfo.getFilters) {
            this.filterPageRequest.statusFilters = this.selectedFilters.slice();
        }
        else if (this.tabInfo.namedConditions) {
            this.filterPageRequest.namedConditions = this.tabInfo.namedConditions;
        }
        else {
            // no selected filters - return all status filters to select (New Logic)
            this.filterPageRequest.statusFilters = this.tabInfo.tabFilters.slice();
            /*      // no selected filters - return empty result set (Old Logic)
                  this.response$.next();
                  this.paging.total = 0;
                  this.items = [];
                  this.updateTotalValues(this.items);
                  this.cdr.markForCheck();
                  return this.response$;
            
             */
        }
        //    console.log('NEXT>>>', this.filterPageRequest);
        if (this.tabInfo) {
            this.tabInfo.isLoading = true;
        }
        this.updateData$.next(this.filterPageRequest);
        return this.response$;
    };
    AbstractMasterTable.prototype.setSearchText = function (text) {
        this.filter.search = text;
        this.filter = Object.assign({}, this.filter);
    };
    AbstractMasterTable.prototype.getSearchText = function () {
        return this.filter.search;
    };
    AbstractMasterTable.prototype.removeFilterValue = function (filterPageRequest, columnId) {
        filterPageRequest.filterByColumns = filterPageRequest.filterByColumns.filter(function (column) { return column.field !== columnId; });
    };
    AbstractMasterTable.prototype.changeFilterValue = function (filterPageRequest, columnId, value) {
        this.removeFilterValue(filterPageRequest, columnId);
        if (value) {
            filterPageRequest.filterByColumns.push(new FilterSearchColumn(columnId, value, null, null));
        }
    };
    AbstractMasterTable.prototype.ngOnDestroy = function () {
        delete this.table;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    AbstractMasterTable.prototype.showMaster = function (data) {
        this.table.toggleExpanded(data);
    };
    AbstractMasterTable.prototype.onSelectMaster = function (event) {
        this.selectedChange.emit(event);
    };
    AbstractMasterTable.prototype.buildDownloadUrl = function (fileType) {
        return this._masterService.buildDownloadUrl(fileType);
    };
    AbstractMasterTable.prototype.onUpdateFieldError = function (error, updateEvent) {
        if (updateEvent) {
            updateEvent.cancel();
        }
        if (typeof error === 'string' && error.includes('UNIQUE KEY')) {
            error = 'Duplicated value not allowed: ' + updateEvent.newValue;
        }
        //    console.log('Update Field Error', updateEvent, error);
        this.alerts.danger(error);
    };
    AbstractMasterTable.prototype.updateObject = function (object, fields) {
        var _this = this;
        console.log('updateObject', object, fields);
        if (object instanceof Master) {
            return this._masterService.updateMaster(object.id, fields)
                .then(function (master) {
                if ( /*!this.webSocketService.isSupport && */_this.items) {
                    _this.items.replaceAll(master, master);
                    _this.table.refresh([master]);
                }
            });
        }
        else if (object instanceof Order) {
            return this._ordersService.updateOrderOld(object.id, fields)
                .then(function (master) {
                if (_this.items && master instanceof Master) {
                    var order = isNullOrUndefined(master) ? object : master.orders.filter(function (o) { return o.id === object.id; })[0];
                    if (order) {
                        order.mawb = object.mawb;
                        order['cfsLocation'] = object['cfsLocation'];
                        //              console.log('REPLACE', this.items, 'WITH', order);
                        _this.items.replaceAll(order, order, function (o1) { return console.log('found'); });
                        _this.table.refresh([order]);
                    }
                }
            });
        }
    };
    /*  private updateOrderObject(master: Master, order: Order, field: any, value: any): Promise<MasterLine> {
        console.log('updateOrderObject', master, order, field, value);
  
        return this._masterService.updateOrderDate(master, order, field, value)
          .then((newMaster: Master) => {
            if (!this.webSocketService.isSupport && this.masters) {
              this.masters.replaceAll(newMaster, newMaster);
              console.log('refreshed', newMaster);
              this.table.refresh([newMaster]);
            }
            return newMaster;
          });
      }*/
    AbstractMasterTable.prototype.onExpandItem = function (event) {
        /*    if (event.row instanceof MasterLine && event.expanded) {
              this._masterService.getMaster(event.row.id, false).then(master => {
                this.table.data.replaceAll(event.row, master);
                this.table.refresh([master]);
                this.table.cdr.markForCheck();
              });
            } */
    };
    AbstractMasterTable.prototype.onUpdateField = function (event) {
        var _this = this;
        var _a, _b, _c;
        if (event.row instanceof Master) {
            var fields = (_a = {}, _a[event.field] = event.newValue, _a);
            var master_1 = event.row;
            console.log('Master Fields:', fields);
            switch (event.field) {
                case 'masterAir.mawbNumber':
                    console.log('validate', event.newValue);
                    if (validMAWB(event.newValue, function (error) {
                        event.error(error);
                        _this.alerts.danger(error, 1000);
                    })) {
                        this.updateObject(master_1, fields).catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    }
                    break;
                case 'status':
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.status = event.newValue; }); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'datePickupActual':
                    var changePickUp = !sameDateTime(event.newValue, event.oldValue || master_1.datePickupEstimated);
                    var oldDateArrivalActual_1 = master_1.dateArrivalAct;
                    if (changePickUp) {
                        fields['dateArrivalAct'] = master_1.updateArrivalDay();
                    }
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.datePickupAct = event.newValue; }); })
                        .catch(function (error) {
                        master_1.dateArrivalAct = oldDateArrivalActual_1;
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'dateCfsIn':
                    var newDatePickupAct_1 = isNullOrUndefined(event.newValue) ? null : subtractHours(event.newValue, 2);
                    var updatePickup_1 = !isNullOrUndefined(newDatePickupAct_1);
                    if (updatePickup_1) {
                        var oldDatePickupActual = master_1.datePickupActual;
                        fields['datePickupActual'] = newDatePickupAct_1;
                    }
                    this.updateObject(master_1, fields)
                        .then(function () {
                        if (updatePickup_1) {
                            master_1.datePickupActual = newDatePickupAct_1;
                            master_1.orders.forEach(function (order) {
                                order.datePickupAct = newDatePickupAct_1;
                            });
                        }
                    })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'datePickupEstimated':
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.datePickupEst = event.newValue; }); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'dateDeliveryActual':
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.dateDeliveryAct = event.newValue; }); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'dateDeliveryEstimated':
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.dateDeliveryReq = event.newValue; }); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'date1C':
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.date1C = event.newValue; }); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'date1F':
                    console.log('date1F', fields);
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.date1F = event.newValue; }); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'dateISCPaid':
                    this.updateObject(master_1, fields)
                        .then(function () { return master_1.orders.forEach(function (order) { return order.dateISCPaid = event.newValue; }); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'customer':
                    fields[event.field] = isNullOrUndefined(event.newValue) ? null : event.newValue.id;
                    this.updateObject(master_1, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'freightForwarder':
                    fields[event.field] = isNullOrUndefined(event.newValue) ? null : event.newValue.id;
                    this.updateObject(master_1, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'addressDelivery':
                    if (!master_1.direct) {
                        fields['addressCfs'] = isNullOrUndefined(event.newValue) ? null : event.newValue.id;
                        this.updateObject(master_1, fields).catch(function (error) {
                            _this.onUpdateFieldError(error, event);
                        });
                    }
                    else {
                        event.cancel();
                    }
                    break;
                case 'cargoBuilding':
                case 'shipment.addressDelivery':
                    fields[event.field] = isNullOrUndefined(event.newValue) ? null : event.newValue.id;
                    if (event.newValue && event.newValue.cfs3plLocation) {
                        fields['nonAMS'] = true;
                    }
                    this.updateObject(master_1, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'dateArrivalEst':
                    /*          let oldLastFreeDay = master.dateLastFreeDay;
                              if (isNullOrUndefined(master.dateArrivalAct)) {
                                fields['dateLastFreeDay'] = master.calculateLastFreeDate(fields['dateArrivalEst'], null);
                              } */
                    this.updateObject(master_1, fields)
                        .catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'masterAir.airline':
                    fields[event.field] = BaseEntity.idOf(event.newValue);
                    this.updateObject(master_1, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'dateArrivalAct':
                    /*          const changed = !sameDateTime(event.newValue, event.oldValue || master.dateArrivalEst);
                              oldLastFreeDay = master.dateLastFreeDay;
                              if (changed) {
                                fields['dateLastFreeDay'] = master.calculateLastFreeDate(fields['dateArrivalAct'], master.dateArrivalEst);
                              } */
                    this.updateObject(master_1, fields)
                        .catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'pieces':
                    if (master_1.isSingleOrder) {
                        this.updateObject(master_1, fields)
                            .then(function () { return event.apply(); })
                            .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    }
                    else {
                        event.cancel();
                    }
                    break;
                case 'weight':
                    if (master_1.isSingleOrder) {
                        this.updateObject(master_1, fields)
                            .then(function () {
                            event.apply();
                        })
                            .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    }
                    else {
                        event.cancel();
                    }
                    break;
                case 'volume':
                    if (master_1.isSingleOrder) {
                        this.updateObject(master_1, fields)
                            .then(function () {
                            event.apply();
                        })
                            .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    }
                    else {
                        event.cancel();
                    }
                    break;
                case 'hu':
                    if (master_1.isSingleOrder) {
                        this.updateObject(master_1, fields)
                            .then(function () {
                            event.apply();
                        })
                            .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    }
                    else {
                        event.cancel();
                    }
                    break;
                default:
                    this.updateObject(master_1, fields).catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
            }
        }
        else 
        // Order Part
        if (event.row instanceof Part) {
            console.log('UPDATE PART', event);
            var part = event.row;
            switch (event.field) {
                case 'pcsOrder':
                    var fields = (_b = {}, _b['pieces'] = event.newValue, _b);
                    this.updateObject(part.order, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
            }
        }
        else if (event.row instanceof Order) {
            var order = event.row;
            var master_2 = event.row[PARENT_REF];
            var fields = (_c = {}, _c[event.field] = event.newValue, _c);
            //      console.log('UPDATE HERE 2: ', order, event.field);
            // if (!isNullOrUndefined(master)) {
            switch (event.field) {
                case 'customer':
                    fields[event.field] = isNullOrUndefined(event.newValue) ? null : event.newValue.id;
                    this.updateObject(order, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                case 'addressDelivery':
                    fields[event.field] = isNullOrUndefined(event.newValue) ? null : event.newValue.id;
                    this.updateObject(order, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    var address = event.newValue;
                    if (address && order.customer) {
                        this.addressService.setCustomerDeliveryLocation(order.customer, address);
                    }
                    break;
                case 'pieces':
                    this.updateObject(order, fields)
                        .then(function () {
                        if (master_2 && master_2.updatePieces()) {
                            _this.updateObject(master_2, { 'pieces': master_2.pieces })
                                .catch(function (error) { return _this.alerts.danger(error); });
                        }
                    })
                        .then(function () { return master_2 && master_2.reset(); })
                        .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'dateCfsInAct':
                case 'dateCfsInEst':
                case 'dateCfsOutAct':
                case 'dateCfsOutEst':
                case 'dateDeliveryAct':
                case 'dateDeliveryReq':
                    this._masterService.updateOrderDate(master_2, order, event.field, event.newValue)
                        .then(function () { return event.apply(); })
                        .catch(function (e) {
                        event.cancel();
                        _this.alerts.danger(/*'Error updating field: ' + */ e);
                    });
                    break;
                case 'weight':
                    this.updateObject(order, fields)
                        .then(function () {
                        if (master_2 && master_2.updateWeight()) {
                            _this.updateObject(master_2, { 'weight': master_2.weight })
                                .catch(function (error) { return _this.onUpdateFieldError(error, event); });
                        }
                    }).catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
                case 'carrier':
                    fields[event.field] = isNullOrUndefined(event.newValue) ? null : event.newValue.id;
                    this.updateObject(order, fields).catch(function (error) {
                        _this.onUpdateFieldError(error, event);
                    });
                    break;
                default:
                    this.updateObject(order, fields).catch(function (error) { return _this.onUpdateFieldError(error, event); });
                    break;
            }
        }
        else if (event.row instanceof RecoveryOrder) {
            var split_1 = event.row;
            console.log(event.field);
            switch (event.field) {
                case 'isDispatched':
                    if (split_1.load) {
                        this.masterProcessor.dispatchRecoveryLoad(split_1, true, this.clientUser)
                            .then(function (master) {
                            _this.table.refresh([master]);
                            console.log('DISPATCH RESULT 1 >', master);
                            _this.changed();
                        });
                    }
                    else {
                        this.openNewManifestFromMasterList(split_1.order);
                    }
                    break;
                case 'dateCfsIn':
                    split_1.shipment.dateDeliveryActual = null;
                    event.apply();
                    break;
                case 'datePickupAct':
                    this._masterService.updateLoadDate(split_1.master, split_1.load, 'datePickupAct', event.newValue)
                        .then(function (master) {
                        event.apply();
                        _this.table.refresh([master]);
                    })
                        .catch(function (e) {
                        event.cancel();
                        _this.alerts.danger(e);
                    });
                    break;
                case 'datePickupEst':
                    this._masterService.updateLoadDate(split_1.master, split_1.load, 'datePickupEst', event.newValue)
                        .then(function (master) {
                        event.apply();
                        _this.table.refresh([master]);
                    })
                        .catch(function (e) {
                        event.cancel();
                        _this.alerts.danger(e);
                    });
                    break;
                case 'pcsToRecover': {
                    if (this._masterService.validateRecoveryLoadPieces(split_1.master, split_1, event.newValue)) {
                        //            if (!split.isDispatched) {
                        this._masterService._updateMaster(split_1.master, function (master) {
                            if (split_1.isVirtual) {
                                master.addRecoveryLoad(event.newValue, null);
                            }
                            else {
                                master.recovery.forEach(function (ld) {
                                    if (ld.id === split_1.loadId) {
                                        ld.shipment.pieces = event.newValue;
                                    }
                                });
                            }
                            return true;
                        }).catch(function (error) {
                            event.cancel();
                            _this.alerts.danger('Error Saving changes:\n ' + error);
                        }).then(function (master) {
                            //                split.master.reset();
                            _this.items.replaceAll(master, master);
                            _this.table.refresh([master]);
                            _this.changed();
                        });
                        //            } else
                        //              split.master.reset();
                    }
                    else {
                        event.cancel();
                    }
                    break;
                }
                default: {
                    /*let fields = {[event.field]: event.newValue};
                    this.updateObject(split.load, fields)
                      .then(() => {
                        event.apply()
                      })
                      .catch(e => {
                        event.cancel();
                        this.alerts.danger( e)
                      });*/
                }
            }
        }
    };
    AbstractMasterTable.prototype.children = function (parent) {
        if (parent instanceof Master) {
            return of(parent.splits.concat(parent.orders));
        }
        return of([]);
    };
    AbstractMasterTable.prototype.isLeaf = function (parent) {
        return !(parent instanceof Master);
    };
    AbstractMasterTable.prototype.ngDoCheck = function () {
        /*
            let changed: boolean = false;
            if (this.valuesDiffer.diff({
              searchable: this.searchable,
            })) {
              changed = true;
            }
        
            if (changed)
              this.cdr.markForCheck();
        */
    };
    AbstractMasterTable.prototype.doCellRightClick = function (event) {
        this.cellRightClickEvent.emit(event);
    };
    AbstractMasterTable.prototype.doCellClick = function (event) {
        this.cellClickEvent.emit(event);
    };
    AbstractMasterTable.prototype.changeSearchText = function (searchText, debounceTime) {
        this.setSearchText(searchText);
        this.filterPageRequest.findByOccurs = searchText;
        if (this.table) {
            this.table.changeSearchText(searchText, debounceTime);
        }
    };
    AbstractMasterTable.prototype.openNewManifestFromMasterList = function (order) {
        if (!isNullOrUndefined(order)) {
            this.orderFromMasterList$.next(order.id);
        }
    };
    return AbstractMasterTable;
}());
export { AbstractMasterTable };
