var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Type } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { AuthService } from "../../../../services";
import { skip } from "rxjs/operators";
var OmsAlertsService = /** @class */ (function () {
    function OmsAlertsService(authService) {
        this.authService = authService;
        this.alerts = [];
        this.alertsSubject = new BehaviorSubject([]);
        this.alert_id = 0;
        this.messages = [];
        this.messagesSubject = new BehaviorSubject([]);
        this.DEFAULT_SUCCESS_TIMEOUT = 1000;
        this.logoutHandler();
    }
    OmsAlertsService.prototype.logoutHandler = function () {
        var _this = this;
        this.authService.user$
            .pipe(skip(1))
            .subscribe(function (user) {
            if (!user) {
                _this.alerts = [];
                _this.alertsSubject.next(_this.alerts);
                _this.messages = [];
                _this.messagesSubject.next(_this.messages);
                localStorage.removeItem(OmsAlertsService.ALERT_HAS_NEW_STORAGE_KEY);
            }
        });
    };
    OmsAlertsService.prototype.warning = function (message, timeout) {
        this.add({
            icon: 'fa fa-exclamation-triangle',
            type: 'warning',
            title: 'Warning!',
            message: message,
            timeout: timeout || this.DEFAULT_SUCCESS_TIMEOUT
        });
    };
    OmsAlertsService.prototype.showMessages = function (options) {
        var _this = this;
        if (options === void 0) { options = []; }
        this.addMessages(options.map(function (option) { return (__assign({ type: 'warning', timeout: _this.DEFAULT_SUCCESS_TIMEOUT }, option)); }));
    };
    OmsAlertsService.prototype.setMessages = function (options) {
        var _this = this;
        if (options === void 0) { options = []; }
        this.replaceMessages(options.map(function (option) { return (__assign({ type: 'warning', timeout: _this.DEFAULT_SUCCESS_TIMEOUT }, option)); }));
    };
    OmsAlertsService.prototype.info = function (message, timeout) {
        this.add({ icon: 'fa fa-info-circle', type: 'info', title: 'Info:', message: message, timeout: timeout || this.DEFAULT_SUCCESS_TIMEOUT });
    };
    OmsAlertsService.prototype.success = function (message, timeout) {
        console.log('SUCCESS', message);
        this.add({
            icon: 'fa fa-check-circle',
            type: 'success',
            title: 'Success!',
            message: message,
            timeout: timeout || this.DEFAULT_SUCCESS_TIMEOUT
        });
    };
    OmsAlertsService.prototype.danger = function (message, timeout) {
        console.log('DANGER MESSAGE', message);
        if (typeof message === 'string') {
            this.add({ icon: 'fa fa-times-circle', type: 'danger', title: 'Error!', message: message, timeout: timeout });
        }
        else {
            this.add({
                icon: 'fa fa-times-circle',
                type: 'danger',
                title: 'Error!',
                message: message.message || 'Internal Server Error',
                stack: message.stackTrace,
                timeout: timeout
            });
        }
    };
    OmsAlertsService.prototype.error = function (error, title, timeout) {
        if (title === void 0) { title = 'Error!'; }
        console.log('ERROR MESSAGE', error);
        this.add({
            icon: 'fa fa-times-circle', type: 'danger', title: title,
            message: typeof error === "string" ? error : error && error.message || error.error || 'Internal Server Error',
            stack: error && error['stackTrace'],
            timeout: timeout
        });
    };
    OmsAlertsService.prototype.process = function (title, message, update, period) {
        var _this = this;
        if (period === void 0) { period = 5000; }
        var alert = {
            type: 'info',
            icon: 'fa fa-gears',
            message: message,
            title: title,
            progress: { percents: 0, status: 'Processing...' }
        };
        this.add(alert);
        return new Promise(function (success, error) {
            var sub = interval(period)
                .subscribe(function () {
                if (!_this.alerts.includes(alert)) {
                    sub.unsubscribe();
                }
                else {
                    update(alert).subscribe(function (status) {
                        console.log('STATUS', status);
                        if (!status || status.completed || status.error) {
                            sub.unsubscribe();
                        }
                        if (status) {
                            alert.progress = status;
                            if (status.error) {
                                console.log('ERROR', alert, status.error);
                                alert.type = 'danger';
                                alert.title = 'Error: ';
                                alert.message = status.error.message;
                                alert.stack = status.error.stackTrace;
                                error(status.error);
                            }
                            else if (status.completed) {
                                success(status);
                            }
                        }
                        _this.alertsSubject.next(_this.alerts = _this.alerts.slice());
                    });
                }
            });
        });
    };
    OmsAlertsService.prototype.add = function (alert) {
        alert.id = this.alert_id++;
        this.alerts.push(alert);
        this.alertsSubject.next(this.alerts);
    };
    OmsAlertsService.prototype.addMessages = function (alerts) {
        var _this = this;
        if (!alerts.length) {
            return;
        }
        alerts.forEach(function (a) { return a.id = _this.alert_id++; });
        this.messages = alerts.reverse().concat(this.messages);
        this.messagesSubject.next(this.messages);
    };
    OmsAlertsService.prototype.replaceMessages = function (alerts) {
        var _this = this;
        if (!alerts.length) {
            return;
        }
        alerts.forEach(function (a) { return a.id = _this.alert_id++; });
        this.messages = alerts.reverse().slice();
        this.messagesSubject.next(this.messages);
    };
    OmsAlertsService.prototype.remove = function (alert) {
        if (alert && alert.id >= 0) {
            if (alert.onClose) {
                alert.onClose();
            }
            this.alertsSubject.next(this.alerts = this.alerts.filter(function (item) { return item.id !== alert.id; }));
        }
    };
    OmsAlertsService.prototype.removeMessage = function (alert) {
        if (alert && alert.id >= 0) {
            if (alert.onClose) {
                alert.onClose();
            }
            this.messagesSubject.next(this.messages = this.messages.filter(function (item) { return item.id !== alert.id; }));
        }
    };
    OmsAlertsService.prototype.getAll = function () {
        this.alertsSubject.next(this.alerts);
    };
    OmsAlertsService.ALERT_HAS_NEW_STORAGE_KEY = "alertHasNewMessage";
    return OmsAlertsService;
}());
export { OmsAlertsService };
