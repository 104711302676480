var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base/base.service';
import { RestService } from './rest.service';
import { HttpUtilsService } from './http-utils.service';
import { plainToClass } from 'class-transformer';
import { Receiving } from "../modules/shared/models/warehouse/receiving";
import { HttpParams } from "@angular/common/http";
var ReceivingService = /** @class */ (function (_super) {
    __extends(ReceivingService, _super);
    function ReceivingService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/receiving/';
        return _this;
    }
    /*
      public update(data: Receiving): Promise<Master | any> {
        return this.put('update', data).toPromise().then((response) => plainToClass(Master, <Object>response));
      }
    */
    ReceivingService.prototype.updateMapping = function (data) {
        return this.put('updateMapping', data).toPromise();
    };
    ReceivingService.prototype.getAllByMasterId = function (masterId) {
        return this.get('getAllByMasterId/' + masterId).toPromise().then(function (response) { return plainToClass(Receiving, response); });
    };
    /*
    public savePictureByOrderId(id: number, imageAsData: string[]): Promise<any> {
      const httpHeader = this.httpUtils.getHTTPHeaders();
      return this.httpRestService.put<any>(this.apiUrl + 'savePicture/' + id, imageAsData, httpHeader).toPromise();
    }
     */
    ReceivingService.prototype.savePicture = function (orderId, masterId, docType, imageAsData) {
        var params = new HttpParams();
        if (orderId) {
            params = params.set('orderId', orderId.toString());
        }
        if (masterId) {
            params = params.set('masterId', masterId.toString());
        }
        params = params.set('docType', docType);
        return this.httpRestService.put(this.apiUrl + "savePicture", imageAsData, this.httpUtils.getHTTPHeaders(), params).toPromise();
    };
    return ReceivingService;
}(BaseService));
export { ReceivingService };
