import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FileUploadService} from "../../services/file.upload.service";

@Component({
  selector: 'oms-page-file-download',
  templateUrl: './page-file-download.component.html',
  styleUrls: ['./page-file-download.component.scss']
})
export class PageFileDownloadComponent implements OnInit {
  public ref: string;

  constructor(private route: ActivatedRoute,
              private file: FileUploadService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      let id = params['id'];
      console.log('PARAM', params);
      this.ref = this.file.buildDownloadFileUrl(id);
      window.open(this.ref);
    });
  }

}
