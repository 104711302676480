import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services';
import { getFormValidationErrors } from '../../_helpers/get-form-validation-errors';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, route, router, authService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.loading = false;
        this.submitted = false;
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: [false]
        });
        // reset login status
        // this.authenticationService.logout();
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "userName", {
        get: function () {
            return this.loginForm.get('username');
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            var error = getFormValidationErrors(this.loginForm.controls).shift();
            if (error) {
                var text = void 0;
                switch (error.error_name) {
                    case 'required':
                        text = error.control_name + " is required!";
                        break;
                    case 'pattern':
                        text = error.control_name + " has wrong pattern!";
                        break;
                    case 'email':
                        text = error.control_name + " has wrong email format!";
                        break;
                    case 'minlength':
                        text = error.control_name + " has wrong length! Required length: " + error.error_value.requiredLength;
                        break;
                    case 'areEqual':
                        text = error.control_name + " must be equal!";
                        break;
                    default:
                        text = error.control_name + ": " + error.error_name + ": " + error.error_value;
                }
                this.warning = text;
            }
            /*          for (const field of Object.keys(this.loginForm.controls)) {
                        const control = this.loginForm.get(field);
                        if (!control.valid) {
                          for (const error in control.errors) {
                            error.error_name
                          }
                          this.warning += control.errors + '\n';
                        }
                      } */
            return;
        }
        this.loading = true;
        this.authService.logIn(this.f.username.value, this.f.password.value, this.returnUrl)
            .then(function () {
            _this.loading = true;
            _this.warning = undefined;
        }).catch(function (error) {
            _this.loading = false;
            _this.warning = 'Incorrect password or user name'; //error.toString();
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
