import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";
import {CustomerGroup} from "../modules/shared/models";
import {BaseEntityService} from './base/base-entity.service';

@Injectable()
export class CustomerGroupService extends BaseEntityService<CustomerGroup> {
  apiUrl = '/oms/customer-group/';
  classType = CustomerGroup;
  public customerGroupSubject = new Subject<CustomerGroup[]>();
  public customerGroupPopUpEvent = new Subject<any>();

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public showCustomerGroupPopUp(item: CustomerGroup) {
    this.customerGroupPopUpEvent.next({
      show: true,
      customer: item
    });
  }

  public hideCustomerGroupPopUp() {
    this.customerGroupPopUpEvent.next({
      show: false,
      customer: null
    });
  }

  getAllCustomerGroups() {
    this.findAll().then(items => this.customerGroupSubject.next(items));
  }

}
