import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {OmsConstants} from '../../common/oms-constants.service';
import {Master, Part, RecoveryOrder} from '../../modules/shared/models';
import {ActivatedRoute, Router} from '@angular/router';
import {MasterService} from '../../services/master/master.service';
import {convertMawbNumber} from '../../modules/shared/services/oms-converters.service';
import {OmsAlertsService} from '../../modules/shared/components/oms-alerts/oms-alerts.service';
import {plainToClass} from 'class-transformer';
import {subtractHours} from '../../common/oms-date-time.utils';
import {isNullOrUndefined} from 'util';
import {PageResult} from '../../modules/shared/models/query-models/page-result';
import {Size} from '../../common/oms-types';
import {checkNumber} from '../../_helpers/utils';
import {ReceivingService} from '../../services/receiving.service';


@Component({
  selector: 'oms-pickup',
  templateUrl: './pickup.component.html',
  styleUrls: ['./pickup.component.scss']
})
export class PickupComponent implements OnInit {
  public master: Master;
  public selectedSplit: RecoveryOrder;
  public splits: RecoveryOrder[];
  Size = Size;
  public mawbSearch: string;
  public isUpdate: boolean = false;

  constructor(
    public cdr: ChangeDetectorRef,
    public constants: OmsConstants,
    private route: ActivatedRoute,
    private alerts: OmsAlertsService,
    private router: Router,
    private receivingService: ReceivingService,
    private masterService: MasterService) { }


  changed() {
    this.cdr.markForCheck();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let masterId = +params['master'];
      let loadId = +params['load'];
      if (masterId) {
        this.masterService.getMaster(masterId)
          .then((master) => {
            this.setMaster(master, loadId);
          });
      }
    });
  }

  setMaster(master: Master, loadId?: number) {
    this.selectedSplit = null;
    this.master = master;
    this.isUpdate = false;
    if (this.master) {
      this.splits = this.master.splits.filter((split) => split.isDispatched);

      if (loadId) {
        this.selectedSplit = this.splits.find((split) => split.loadId === loadId);
      } else {
        this.selectedSplit = this.splits.length > 0 ? this.splits[0] : null;
      }
    }
    this.changed();
  }

  get parts(): Part[] {
    return this.master ? this.master.parts.filter((part) => this.selectedSplit && part.loadId ===  this.selectedSplit.loadId) : null;
  }

  get mawbNumber(): string {
    return this.master ? convertMawbNumber(this.master.mawbNumber) : null;
  }

  onUrlChange() {
    this.router.navigate([], { relativeTo: this.route, queryParams: {master: this.master.id, load: this.selectedSplit.loadId}});
  }

  get multipleLoads(): boolean {
    return this.master && this.master.splits.length > 1;
  }

  onSubmit() {
    if (this.selectedSplit) {
      let dateDeliveryActual = new Date();
      let datePickupActual = this.selectedSplit.shipment.datePickUpExpectation || this.selectedSplit.shipment.datePickUpActual || subtractHours(dateDeliveryActual, 4);

      let parts: Part[] = this.parts;
      if (parts.length > 0) {
        let data = {
            masterId: this.master.id,
            loadId: this.selectedSplit.loadId,
            pickupDate: datePickupActual,
            deliveryDate: dateDeliveryActual,
            loadPieces: this.selectedSplit.pcsReceived,
            loadWeight: this.selectedSplit.weightReceived,
            loadShipmentId: this.selectedSplit.shipment.id,
            parts: []
          };

        parts.forEach(part => {
          data.parts.push({
            orderId: part.orderId,
            shipmentContentId: part.content.id,
            pieces: part.pieces,
            weight: part.weight,
            hu: part.hu});
        });

        this.receivingService.updateMapping(data)
          .then(() => {
            this.selectedSplit.shipment.dateDeliveryActual = dateDeliveryActual;
            this.selectedSplit.shipment.datePickUpActual = datePickupActual;
            this.isUpdate = false;
            this.cdr.markForCheck();
            this.alerts.success("Receiving Data is Stored successfully.");
          })
          .catch(error => {
            this.cdr.markForCheck();
            this.alerts.danger("Error saving data: " + error);
          });
      }
    }
  }

  get allowSubmit(): boolean {
    return this.selectedSplit && (isNullOrUndefined(this.selectedSplit.dateDeliveryAct) || this.isUpdate);
  }

  get totalReceivedPieces(): number {
    return this.selectedSplit ? this.selectedSplit.pcsReceived : null;
  }

  get totalReceivedWeight(): number {
    return this.selectedSplit ? this.selectedSplit.weightReceived : null;
  }

  get totalReceivedHu(): number {
    return this.selectedSplit ? this.selectedSplit.huReceived : null;
  }


  onMawbSearch(mawb: string) {
    if (mawb && mawb.length >= 4) {
      this.masterService.searchByMawb(mawb, 3).toPromise().then((masters: PageResult<Master>) => {
        this.setMaster(masters.numberOfElements > 0 ? plainToClass(Master, <Object> masters.content[0]) : null);
      }).catch(error => {
        this.setMaster(null);
        console.error(error);
      });
    }
  }

  get noMaster(): boolean {
    return isNullOrUndefined(this.master);
  }

  get noDispatched() {
    return !this.noMaster && (!this.parts || this.parts.length === 0);
  }

  get masterPieces(): number {
    return this.master ? this.master.pieces || null : null;
  }

  public cleanUp(value): number {
    return checkNumber(value);
  }

}
