import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {DynamicInjectorType} from "./dynamic-injector-type";

@Component({
  selector: 'oms-dynamic-injector',
  templateUrl: './dynamic-injector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicInjectorComponent<T extends DynamicInjectorType> implements OnChanges, OnDestroy {

  @Input() component: Type<any>;
  @Input() data: any;
  @Output() close = new EventEmitter<void>();
  @ViewChild('target', {read: ViewContainerRef}) target: ViewContainerRef;

  private cellComponentRef: ComponentRef<T>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.component) {
      if (this.cellComponentRef) {
        this.cellComponentRef.destroy();
      }
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
      this.cellComponentRef = this.target.createComponent(componentFactory, 0, this.injector);
      this.cellComponentRef.instance.close.subscribe(() => this.close.emit());
    }
    if (changes.data || changes.component) {
      this.cellComponentRef.instance.data = this.data;
      this.cellComponentRef.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    if (this.target) {
      this.target.clear();
    }
    if (this.cellComponentRef) {
      this.cellComponentRef.destroy();
    }
  }

}
