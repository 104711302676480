<div class="form-group" >
  <label class="control-label">{{label}}</label>
  <div  class="input-group" [ngClass]="{'input-group-sm': size == Size.SMALL, 'input-group-lg': size == Size.LARGE}" style="width: 100%">
    <input type="text" #input class="form-control" title=""
           [ngClass]="{'form-control-invalid':invalid | async}"
           [ngModel]="displayValue"
           [attr.readonly]="''"
           [attr.disabled]="disabled ? '' : null"
           (click)="isEdit = true"
           (keyup)="changeEventTrigger($event)">

      <oms-date-time-range-popup #dateTimePopup
                    [top]="realTopPosition"
                    [left]="realLeftPosition"
                    [(valueFromDate)]="fromDate"
                    [(valueToDate)]="toDate"
                    [est]="est"
                    [minDate]="minDate" [maxDate]="maxDate"
                    (valueChange)="changeEventTrigger($event)"
                    (onCustomButton)="onNaButtonClick($event)"
                    [(showPopup)]="isEdit"
                    [showDate]="true"
                    (showPopupChange)="onPopupShow($event)"
                    [customButton]="{show: showNaButton, label: 'N/A', cssClass:'btn btn-primary'}"
                    [estButton]="{show: true, label: 'Est', cssClass:'btn btn-primary'}"
                    [clearButton]="{show: cleanable, label:'Clear', cssClass:'btn btn-danger'}"
                    [nowButton]="{show: true, label:'Now', cssClass:'btn btn-primary'}"
                    [closeButton]="{show: true, label:'Select', cssClass:'btn btn-primary'}">
    </oms-date-time-range-popup>

    <span class="input-group-btn">
      <button class="btn btn-clean btn-clear-input" *ngIf="isDisplayClearButton()" (click)="value = null; changeEventTrigger($event)"
              [attr.disabled]="disabled ? '' : null">
          <i class="fa fa-remove" aria-hidden="true"></i>
      </button>

      <button class="btn btn-outline-secondary" (click)="isEdit = !isEdit" *ngIf="showButton" [attr.disabled]="disabled ? '' : null">
          <i class="fa fa-calendar" aria-hidden="true"></i>
      </button>
    </span>
  </div>
  <validation [@flyInOut]="'in,out'" *ngIf="invalid | async" [messages]="failures | async"></validation>
</div>

