/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oms-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "./oms-spinner.component";
var styles_OmsSpinnerComponent = [i0.styles];
var RenderType_OmsSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OmsSpinnerComponent, data: {} });
export { RenderType_OmsSpinnerComponent as RenderType_OmsSpinnerComponent };
export function View_OmsSpinnerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { spinner: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["style", "position: relative; height: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngx-spinner", [["bdColor", "#33333366"], ["color", "#fff"], ["size", "medium"], ["type", "pacman"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i1.ɵdid(3, 770048, [[1, 4]], 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"], fullScreen: [4, "fullScreen"], name: [5, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [["style", "color: white"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "#33333366"; var currVal_1 = "medium"; var currVal_2 = "#fff"; var currVal_3 = "pacman"; var currVal_4 = _co.fullScreen; var currVal_5 = _co.name; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.message; _ck(_v, 5, 0, currVal_6); }); }
export function View_OmsSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-spinner", [], null, null, null, View_OmsSpinnerComponent_0, RenderType_OmsSpinnerComponent)), i1.ɵdid(1, 573440, null, 0, i4.OmsSpinnerComponent, [i3.NgxSpinnerService], null, null)], null, null); }
var OmsSpinnerComponentNgFactory = i1.ɵccf("oms-spinner", i4.OmsSpinnerComponent, View_OmsSpinnerComponent_Host_0, { timeOut: "time-out", name: "name", message: "message", show: "show", fullScreen: "fullScreen" }, {}, ["*"]);
export { OmsSpinnerComponentNgFactory as OmsSpinnerComponentNgFactory };
