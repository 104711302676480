﻿<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget" style="width: 500px"
       [ngClass]="{ '_loading' : viewLoading, 'm-portlet--body-progress-overlay' : loadingAfterSubmit }">

    <mat-spinner *ngIf="viewLoading" class="sb-spinner" [diameter]="40"></mat-spinner>

    <div class="widget-header">
      <h3><i class="fa fa-map-marker"></i>
        {{getTitle()}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
          <!--i class="fa fa-close sb-popup__head-close-icon"></i-->
        </a>
      </div>
    </div>

    <form class="widget-content" [formGroup]="form" style="min-height: 450px;">
      <div class="form-row">
        <div class="form-row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label">Name</label>
              <input type="text" class="form-control" placeholder="Name" formControlName="name"
                     [ngClass]="{'form-control-invalid':isControlInvalid('name')}"/>
              <div *ngIf="isControlInvalid('name')" class="invalid-feedback">Name is mandatory</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Show As</label>
              <input type="text" class="form-control" placeholder="showAs" formControlName="showAs"
                     [ngClass]="{'form-control-invalid':isControlInvalid('showAs')}"/>
              <div *ngIf="isControlInvalid('showAs')" class="invalid-feedback">Show As is mandatory</div>
            </div>
          </div>
        </div>

        <!--<div class="form-row col-md-12">
          <div class="control-inline toolbar-item-group" style="color: #868686">
            <label class="control-label" style="cursor: pointer "><i class="fa fa-map"></i>&nbsp;Search By Google Maps
              <div class="control-inline onoffswitch" >
                <input name="onoffswitch" class="onoffswitch-checkbox" id="switch-address" formControlName="gmapsSearch" type="checkbox" (change)="changeAddressSearchType($event)">
                <label class="onoffswitch-label" for="switch-address">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </label>
          </div>
        </div>-->



        <div class="form-row col-md-12">
          <label class="control-label">Address</label>
          <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Address" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #addressAutocompleteSearch>
        </div>

        <div class="form-row col-md-12">
          <div class="form-group">
            <label class="control-label">Address Lines</label>
            <textarea class="form-control" placeholder="Address" formControlName="address" rows="3"
                      [ngClass]="{'form-control-invalid':isControlInvalid('address')}"></textarea>
            <div *ngIf="isControlInvalid('address')" class="invalid-feedback">Address required</div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Latitude</label>
              <input type="text" class="form-control" placeholder="Latitude" formControlName="lat"
                     [ngClass]="{'form-control-invalid':isControlInvalid('lat')}"/>
              <div *ngIf="isControlInvalid('lat')" class="invalid-feedback">Must match latitude pattern</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Longitude</label>
              <input type="text" class="form-control" placeholder="Longitude" formControlName="lon"
                     [ngClass]="{'form-control-invalid':isControlInvalid('lon')}"/>
              <div *ngIf="isControlInvalid('lat')" class="invalid-feedback">Must match longitude pattern</div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <!-- Country -->
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label">Country</label>
              <ng-select class="form-control"
                         bindLabel="name"
                         appendTo="body"
                         [ngClass]="{'form-control-invalid':isControlInvalid('country')}"
                         [items]="countryService.countriesSubject | async"
                         formControlName="country"
                         (change)="countryChanged()"
                         placeholder="Country">
                <ng-template ng-label-tmp let-item="item">
                  {{item.name}} ({{item.iso2Code}})
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  <div>{{item.name}}</div>
                  <small>{{item.iso2Code}} / {{item.region.name}}</small>
                </ng-template>
              </ng-select>
              <div *ngIf="isControlInvalid('country')" class="invalid-feedback">Country is required</div>
            </div>
          </div>

          <!-- State -->
          <div class="col-md-4">
            <div class="form-group" *ngIf="!isUsa">
              <label class="control-label">State / Province</label>
              <input class="form-control" placeholder="Province" formControlName="province"
                     [ngClass]="{'form-control-invalid':isControlInvalid('province')}" />
              <div *ngIf="isControlInvalid('province')" class="invalid-feedback">Province required</div>
            </div>

            <div class="form-group" *ngIf="isUsa">
              <label class="control-label">State</label>
              <ng-select class="form-control"
                         bindLabel="name"
                         [items]="usaStateService.usaStateSubject | async"
                         formControlName="usaState"
                         [ngClass]="{'form-control-invalid':isControlInvalid('usaState')}"
                         placeholder="State">
              </ng-select>
              <div *ngIf="isControlInvalid('usaState')" class="invalid-feedback">State required</div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <!-- City-->
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label">City</label>
              <ng-select [clearable]="false" [addTag]="true" [addTagText]="'New City:'" appendTo="body"
                         class="form-control"
                         bindLabel="name"
                         bindValue="name"
                         [items]="cityService.citiesSubject | async"
                         formControlName="city"
                         placeholder="City"
                         [ngClass]="{'form-control-invalid':isControlInvalid('city')}">
              </ng-select>
              <div *ngIf="isControlInvalid('city')" class="invalid-feedback">City is required</div>
            </div>
          </div>

          <!-- ZIP Code-->
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">{{isUsa ? 'ZIP Code' : 'Post Code'}}</label>
              <input id="post_code" type="text" class="form-control" placeholder="Post Code" formControlName="postCode"
                     [ngClass]="{'form-control-invalid':isControlInvalid('postCode')}"/>
              <div *ngIf="isControlInvalid('postCode')" class="invalid-feedback">Post Code is required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <oms-checkbox [disabled]="isDisabledType(AddressType.CARGO_BUILDING)" label="Cargo Building" [(checked)]="address.cargoBuilding"></oms-checkbox>
          <oms-checkbox [disabled]="isDisabledType(AddressType.CFS_LOCATION)" label="CFS Location" [(checked)]="address.cfsLocation"></oms-checkbox>
          <oms-checkbox [disabled]="isDisabledType(AddressType.CFS_3PL_LOCATION)" label="3PL CFS Location" [(checked)]="address.cfs3plLocation"></oms-checkbox>
        </div>
        <div class="col-md-6">
          <oms-checkbox [disabled]="isDisabledType(AddressType.PICKUP_LOCATION)" label="Pickup Location" [(checked)]="address.pickupLocation"></oms-checkbox>
          <oms-checkbox [disabled]="isDisabledType(AddressType.DELIVERY_LOCATION)" label="Delivery Location" [(checked)]="address.deliveryLocation"></oms-checkbox>
            <br>
          <oms-checkbox [disabled]="isDisabledType(AddressType.WHSE_BUILDING)" label="WHSE" [(checked)]="address.whseBuilding"></oms-checkbox>
        </div>
        <div class="col-md-6">
          <oms-checkbox [disabled]="isDisabledType(AddressType.HOME_BASE)" label="Homebase" [(checked)]="address.homeBase"></oms-checkbox>
        </div>
      </div>

    </form>

    <div class="widget-footer">
      <div></div>
      <div class="dialog-btns">
        <button type="button" class="btn btn-primary" (click)="onSubmit()">OK</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div>

  </div>
</perfect-scrollbar>
