import {
  Address,
  Airline,
  Airport,
  Customer,
  CustomsClearance,
  FreightForwarder,
  Load,
  LoadType,
  MasterAir,
  MasterShipment,
  MasterShipmentAir,
  MasterStatusId,
  Order,
  Problem,
  ShipmentContent,
  Split, RecoveryOrder,
  Uld
} from '..';
import {Exclude, Transform, Type} from 'class-transformer';
import {isNullOrUndefined} from 'util';
import {assigned, checkNumber, equals, nextLetter} from '../../../../_helpers/utils';
import {
  addHours,
  getPendingStatus,
  latest,
  latestObjectByDate,
  maxDate,
  minDate,
  PendingStatus,
  subtractHours
} from '../../../../common/oms-date-time.utils';
import {OmsConstants} from '../../../../common/oms-constants.service';
import {FillStatus} from '../../../../components/common/fill-indicator/fill-indicator.component';
import {convertMasterNumber, convertMawbStatus} from '../../services/oms-converters.service';
import {RowObject} from '../../components/base/data-table/columns/column-types';
import {Shipment} from '../shipment';
import {BaseDeletable} from "../base-deletable";
import {MasterMode, MasterModes} from "./master-mode";
import {transformOrderMode} from "../order/order-mode";
import {AfterLoad, BeforeSave} from "../../../../services/oms-decorators";
import { CbpStatus } from "../cbp.status";
import {Billable} from "../../../../common/oms-types";
import {StringUtil} from "../../../../_helpers/string.util";

export function isMaster(any): boolean {
  return any instanceof Master;
}

export enum ProblemStatus {
  NONE, PROBLEM, RESOLVED
}

export class Part implements RowObject {
  public rowId = 'Part';

  master: Master;
  content: ShipmentContent;
  order: Order;
  part: string;


  get splitNumber(): number {
    return this.content.shipment.load ? this.content.shipment.load.splitNumber : null;
  }

  get id() {
    return this.order.id;
  }

  get loadId(): number {
    return this.load ? this.load.id : null;
  }

  get orderId(): number {
    return this.order ? this.order.id : null;
  }

  get mawbNumber(): string {
    return this.master.mawbNumber;
  }

  get hawb() {
    return this.order.hawb;
  }

  get customer() {
    return this.order.customer;
  }


  get huOrder(): number {
    return this.order.hu;
  }

  set huOrder(value: number) {
    this.order.hu = value;
  }

  get pcsOrder(): number {
    return this.order.pieces;
  }

  set pcsOrder(value: number) {
    this.order.pieces = value;
  }

  get pieces(): number {
    return this.content.pieces || null;
  }

  set pieces(value: number) {
    this.content.pieces = value;
  }

  get weight(): number {
    return this.content.weight;
  }

  set weight(value: number) {
    this.content.weight = value;
  }

  get volume(): number {
    return this.content.volume;
  }

  set volume(value: number) {
    this.content.volume = value;
  }

  get hu(): number {
    return this.content.hu;
  }

  set hu(value: number) {
    this.content.hu = value;
  }

  get dateCfsInAct(): Date {
    return this.order.dateCfsInAct;
  }

  get dateCfsInEst(): Date {
    return this.order.dateCfsInEst;
  }


  /*  get dateCfsOut():Date {
      return this.order.dateCfsOut;
    } */

  get dateDeliveryAct(): Date {
    return this.order.dateDeliveryAct;
  }

  get dateDeliveryReq(): Date {
    return this.order.dateDeliveryReq;
  }

  get load(): Load {
    return this.content.shipment.load;
  }

  get noCfs(): boolean {
    return this.order && this.order.noCfs;
  }
}

export class Master extends BaseDeletable implements RowObject, Billable {
  public rowId = 'Master';
  public javaClassName: string = 'com.oms.entity.master.Master';

  @Type(() => MasterShipment)
  masterShipments: MasterShipment[] = [];
  @Type(() => MasterAir)
  masterAir: MasterAir;
  @Type(() => CustomsClearance)
  customsClearance: CustomsClearance = new CustomsClearance();
  @Type(() => Customer)
  customer: Customer;
  @Type(() => FreightForwarder)
  freightForwarder: FreightForwarder;
  @Type(() => Order)
  orders: Order[] = [];
  @Type(() => Order)
  recoveryOrders: Order[] = [];
  @Type(() => Date)
  dateISCPaid: Date; // Import Customs Service fee paid date;
  customerRef: string;
  freightForwarderRef: string;
  @Type(() => Date)
  datePickupEstimated: Date;
  @Type(() => Date)
  datePickupActual: Date;
  @Type(() => Date)
  dateDeliveryEstimated: Date;
  @Type(() => Date)
  dateDeliveryActual: Date;
  @Type(() => Date)
  dateBilled: Date;

  private _dateCfsOutEst: Date = undefined;


  /**@deprecated@**/
  problem: string;

  cbpStatus: string;

  @Type(() => Problem)
  problems: Problem[];
  @Exclude()
  shipments: Shipment[] = [];

  @Type(() => Uld)
  ulds: Uld[] = [];
  uldCount: number;

  isAcknowledged: boolean = false;
  isReAcknowledged: boolean = false;
  direct: boolean = false;
  nonAMS: boolean = false;

  @Transform((v, o, tt) => transformOrderMode(v, o, tt))
  genericMode: MasterMode;

  overageNotes: number;
  shortageNotes: number;
  damageNotes: number;
  hazardousNotes: number;
  defaultNotes: number;
  documents: number;

  requestedBy: string;

  colorStatus: number;

  cbpStatusPiecesNotValid: boolean;
  cbpStatusCount: number;
  cbpFsc10WithoutHawb: number;
  cbpWithoutHawb: number;

  @Type(() => CbpStatus)
  cbpStatuses: CbpStatus[] = [];

  @Exclude()
  private _splits: RecoveryOrder[];
  @Exclude()
  private _parts: Part[];
  orderPieces: number;

  pcs1F: number;
  pcs1C: number;

  constructor() {
    super();
    this.dateCreated = new Date();
  }

  // todo move to server side?
  public static newMaster(type): Master {
    // 1. Create master
    let master = new Master();
    master.masterAir = new MasterAir();
    master.status = MasterStatusId.NEW;
    master.genericMode = type ? MasterModes.valueOf(type) : MasterMode.AIR_IMPORT;

    // 2. Create Virtual order
    let order: Order = new Order();
    master.orders.push(order);
    order.master = master;

    // 3. Create Master Shipment
    let content: ShipmentContent = new ShipmentContent();
    content.order = order;
    content.shipment = new Shipment();
    content.shipment.shipmentContents.push(content);
    content.shipment.master = master;
    master.masterShipments = [];
    let masterShipment = new MasterShipment();
    masterShipment.masterShipmentAir = new MasterShipmentAir();
    masterShipment.shipment = content.shipment;
    master.masterShipments.push(masterShipment);
    order.shipmentContents.push(content);

    // 4. Create Recovery Load Shipment
    /*    content = new ShipmentContent();
        content.shipment = new Shipment();
        content.shipment.load = new Load();
        content.shipment.load.loadType = LoadType.RECOVERY;
        content.shipment.load.shipment = content.shipment;
        content.order = order;
        order.shipmentContents.push(content);*/

    // 5. Create Delivery Load Shipment
    /*    content = new ShipmentContent();
        content.shipment = new Shipment();
        content.shipment.load = new Load();
        content.shipment.load.loadType = LoadType.DELIVERY;
        content.shipment.load.shipment = content.shipment;
        content.order = order;
        order.shipmentContents.push(content);*/

    return master;
  }


  public reset() {
    this._parts = undefined;
    this._splits = undefined;
    this._dateCfsOutEst = undefined;
  }

  get isOSD() {
    return checkNumber(this.shortageNotes, 0) + checkNumber(this.overageNotes, 0) + checkNumber(this.damageNotes, 0);
  }

  public get masterStatus(): string {
    return convertMawbStatus(this.status);
  }

  get osdNotes(): number {
    return this.overageNotes + this.shortageNotes + this.damageNotes;
  }

  get mawbNumber(): string {
    return this.masterAir ? this.masterAir.mawbNumber : null;
  }

  set mawbNumber(mawb: string) {
    if (assigned(this.masterAir)) {
      this.masterAir.mawbNumber = mawb;
    }
  }

  get hawb() {
    return this.orders.length;
  }

  get shipment(): Shipment {
    return this.masterShipments && this.masterShipments.length === 1 ? this.masterShipments[0].shipment : null;
  }

  get firstShipment(): MasterShipment {
    return this.masterShipments.length > 0 ? this.masterShipments[0] : null;
  }

  get cargoBuildingAddressWithAirport(): Address {
    let airportAbbr = isNullOrUndefined(this.airport) ? '' : this.airport.iataCode;
    let cargo = this.shipment.addressDelivery;
    let showAs = isNullOrUndefined(cargo) || isNullOrUndefined(cargo.showAs) ? '' : cargo.showAs.toLocaleUpperCase() + ':';
    let address = new Address();
    // address.id = this.shipment.addressDelivery.id;
    address.name = showAs + airportAbbr;
    return address;
  }

  set cargoBuildingAddressWithAirport(address: Address) {
    this.cargoBuilding = address;
  }

  // Sum of Orders HU
  get hu(): number {
    return this.orders.aggregate((acc, o) => acc + ~~o.hu, 0) || null;
  }

  set hu(hu: number) {
    if (this.orders && this.orders.length === 1) { this.orders[0].hu = hu; }
  }

  // Sum of Orders pieces delivered to CFS
  get pcsReceived(): number {
    return this.orders.aggregate((acc, o) => acc + ~~o.pcsReceived, 0) || null;
  }

  // Sum of Orders pieces delivered to CFS
  get huReceived(): number {
    return this.orders.aggregate((acc, o) => acc + ~~o.huReceived, 0) || null;
  }

  get hu_(): number {
    return this.huReceived || this.hu;
  }


  // Sum of Orders pieces
  get pieces(): number {
    return this.orders.aggregate((acc, o) => acc + ~~o.pieces, 0) || null;
  }

  @Exclude() // note important!
  set pieces(pcs: number) {
    if (this.orders && this.orders.length === 1) { this.orders[0].pieces = pcs; }
  }

  // Sum of Orders pieces
  get pcsArrived(): number {
    return this.orders.aggregate((acc, o) => {
      return acc + ~~o.pcsArrived;
    }, 0);
  }


  get weight(): number {
    return this.orders.aggregate((acc, o) => acc + +o.weight, 0) || null;
  }

  set weight(weight: number) {
    if (this.orders && this.orders.length === 1) { this.orders[0].weight = weight; }
  }

  get volume(): number {
    return this.orders.aggregate((acc, o) => acc + +o.volume, 0) || null;
  }

  set volume(value: number) {
    if (this.orders && this.orders.length === 1) { this.orders[0].volume = value; }
  }

  get masterNumber(): string {
    return this.id ? convertMasterNumber(this.id) : null;
  }

  get dateArrivalAct(): Date {
    let date: Date = null;
    return this.masterShipments.some((ms) => isNullOrUndefined(date = maxDate(date, ms.shipment.dateDeliveryActual))) ? null : date;
  }

  set dateArrivalAct(date: Date) {
    if (!equals(date, this.dateArrivalAct)) {
      this.masterShipments.forEach((ms) => {
        ms.shipment.dateDeliveryActual = date;
      });
    }
  }

  get dateArrivalEst() {
    let date: Date;
    return this.masterShipments.some((ms) => isNullOrUndefined(date = maxDate(date, ms.shipment.dateDeliveryExpectation))) ? null : date;
  }

  set dateArrivalEst(date: Date) {
    this.masterShipments.forEach((ms) => {
      ms.shipment.dateDeliveryExpectation = date;
    });
  }

  get isArrived(): boolean {
    return !isNullOrUndefined(this.dateArrivalAct) && (this.dateArrivalAct <= new Date());
  }

  addRecoveryLoad(pieces: number, hu: number, datePickupEst?: Date): Load {
    let load: Load = new Load();
    load.shipment = new Shipment();
    load.shipment.addressPickUp = this.cargoBuilding;
    load.shipment.addressDelivery = this.addressDelivery;
    load.shipment.hu = hu;
    load.shipment.pieces = pieces; // Pieces to be recovered
    load.shipment.datePickUpExpectation = !isNullOrUndefined(datePickupEst) ? datePickupEst : undefined; // || addHours(new Date(), 4);
    load.shipment.master = this;
    load.shipment.load = load;

    load.loadType = LoadType.RECOVERY;
    this.orders.forEach(o => {
      let content = new ShipmentContent();
      load.shipment.shipmentContents.push(content);
      content.shipment = load.shipment;
      content.order = o;
      o.shipmentContents.push(content);
    });
    return load;
  }

  get isMultipleOrders(): boolean {
    return (this.orders && this.orders.length > 1);
  }

  get isSingleOrder(): boolean {
    return (this.orders && this.orders.length === 1);
  }

  get singleOrder(): Order {
    return this.isSingleOrder ? this.orders[0] : null;
  }

  get someOrder(): Order {
    return this.orders.length > 0 ? this.orders[0] : null;
  }

  public updatePieces(): boolean {
    let pcs = 0;
    this.orders.forEach(order => {
      if (!isNaN(order.pieces)) { pcs += checkNumber(order.pieces); }
    });
    this.pieces = pcs;
    return true;
  }

  public updateWeight(): boolean {
    let wght = 0.0;
    this.orders.forEach(order => {
      if (!isNaN(order.weight)) { wght += checkNumber(order.weight); }
    });
    this.weight = wght;
    return true;
  }

  public updateLastFreeDay(): Date {
    let date = null;
    if (this.masterShipments) {
      this.masterShipments.forEach((ms) => {
        date = minDate(date, ms.updateLastFreeDay());
      });
    }
    return date;
  }

  public calculateLastFreeDate(date: Date, estDate) {
    if (isNullOrUndefined(date)) {
      return addHours(estDate, 48);
    }
    return addHours(date, 48);
  }

  public updateArrivalDay(): Date {
    let date = this.datePickupActual;
    if (!isNullOrUndefined(date) && isNullOrUndefined(this.dateArrivalAct)) {
      if (isNullOrUndefined(this.dateArrivalEst)) {
        this.dateArrivalAct = subtractHours(date, 2);
      } else {
        this.dateArrivalAct = this.dateArrivalEst;
      }
    }
    return this.dateArrivalAct;
  }


  public isMasterInPendingStatus(pendingStatus: PendingStatus): boolean {
    let pendingStatuses = [];
    if (isNullOrUndefined(this.dateArrivalAct)) {
      this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.dateArrivalEst));
    }

    if (isNullOrUndefined(this.datePickupActual)) {
      this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.dateLastFreeDay));
      this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.datePickupEstimated));
    }

    if (isNullOrUndefined(this.dateDeliveryActual)) {
      this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.dateDeliveryEstimated));
    }

    switch (pendingStatus) {
      case PendingStatus.PAST_DUE:
        return pendingStatuses.indexOf(pendingStatus) > -1;
      case PendingStatus.AT_RISK:
        return pendingStatuses.indexOf(pendingStatus) > -1 && pendingStatuses.indexOf(PendingStatus.PAST_DUE) === -1;
      case PendingStatus.IMPENDING:
        return pendingStatuses.indexOf(pendingStatus) > -1 && pendingStatuses.indexOf(PendingStatus.PAST_DUE) === -1 && pendingStatuses.indexOf(PendingStatus.AT_RISK) === -1;
    }
  }

  get problemStatus(): ProblemStatus {
    let status = ProblemStatus.NONE;
    let stillProblem: boolean = false;
    if (this.problems) {
      this.problems.forEach(problem => {
        if (problem.isResolved) {
          status = ProblemStatus.RESOLVED;
        } else {
          stillProblem = true;
        }
      });
    }
    return stillProblem ? ProblemStatus.PROBLEM : status;
  }

  get problemDesc(): string {
    let desc = '';
    if (this.problems) {
      this.problems.forEach(problem => {
        if (!problem.isResolved) {
          desc += desc === '' ? problem.description : ', ' + problem.description;
        }
      });
    }
    return desc;
  }

  get customerNameBasedOnOrders(): string {
    let customers = [];
    this.orders.forEach(order => {
      if (!isNullOrUndefined(order.customer) && !this.isCustomerExist(customers, order.customer)) {
        customers.push(order.customer);
      }
    });
    return customers.length === 0 ? OmsConstants.EMPTY_VALUE : customers.length === 1 ? customers[0].name : 'Various';
  }

  get deliveryApproval() {
    return !this.orders.some(o => !o.deliveryApproval);
  }

  set deliveryApproval(value: boolean) {
    this.orders.forEach(o => o.deliveryApproval = value);
  }

  get pttCreated(): boolean {
    return !this.orders.some((o) => !o.pttCreated);
  }

  set pttCreated(value: boolean) {
    this.orders.forEach((o) => o.pttCreated = value);
  }

  get hasProblem() {
    return !this.orders.some((o) => !o.hasProblem);
  }

  set hasProblem(value: boolean) {
    this.orders.forEach(o => o.hasProblem = value);
  }

  get dispatchedStatus() {
    return !this.orders.some((o) => !o.dispatchedStatus);
  }

  set dispatchedStatus(value: boolean) {
    this.orders.forEach((o) => o.dispatchedStatus = value);
  }

  get dateCfsOutActOrder(): Order {
    return latestObjectByDate(this.orders, (o) => o.dateCfsOutAct);
  }

  get dateCfsOutEstOrder(): Order {
    return latestObjectByDate(this.orders, (o) => o.dateCfsOutEst);
  }

  get dateCfsInActOrder(): Order {
    return latestObjectByDate(this.orders, (o) => o.dateCfsInAct);
  }

  get dateCfsInEstOrder(): Order {
    return latestObjectByDate(this.orders, (o) => o.dateCfsInEst);
  }

  get dateCfsInAct(): Date {
    return latest(this.orders, (o) => o.dateCfsInAct);
  }

  set dateCfsInAct(date: Date) {
    this.orders.forEach(order => {
      order.dateCfsInAct = date;
    });
  }

  get dateCfsInEst(): Date {
    return latest(this.orders, o => o.dateCfsInEst);
  }

  set dateCfsInEst(date: Date) {
    this.orders.forEach(order => {
      order.dateCfsInEst = date;
    });
  }

  get dateCfsOutAct(): Date {
    return latest(this.orders, o => o.dateCfsOutAct);
  }

  set dateCfsOutAct(date: Date) {
    this.orders.forEach(order => {order.dateCfsOutAct = date; });
  }

  get dateCfsOutEst(): Date {
    return this._dateCfsOutEst === undefined ? (this._dateCfsOutEst = latest(this.orders, o => o.dateCfsOutEst)) : this._dateCfsOutEst;
//    return latest(this.orders, o => o.dateCfsOutEst);
  }

  set dateCfsOutEst(date: Date) {
    this._dateCfsOutEst = date;
    this.orders.forEach(order => {order.dateCfsOutEst = date; });
  }


  isCustomerExist(customers: Customer[], customer: Customer) {
    return customers.find(customerFromList => {
      return customerFromList.id === customer.id;
    });
  }

  public addPendingStatusIfNotNull(pendingStatuses: any[], pendingStatus) {
    if (!isNullOrUndefined(pendingStatus)) {
      pendingStatuses.push(pendingStatus);
    }
  }

  get status(): MasterStatusId {
    return this.masterShipments.asUniqueValue(o => o.status);
//    return this.isSingleSplit ? this.singleSplit.status : null;
  }

  set status(status: MasterStatusId) {
    if (!equals(this.status, status)) {
      this.masterShipments.forEach(o => {
        o.status = status;
      });
    }
  }

  get flightNumber(): string {
    return this.masterShipments.asUniqueValue(ms => ms.flightNumber);
  }

  set flightNumber(value: string) {
    if (!equals(this.flightNumber, value)) {
      this.masterShipments.forEach(ms => {
        ms.flightNumber = value;
      });
    }
  }

  get airport(): Airport {
    return this.masterShipments.asUniqueValue(ms => ms.airport);
  }

  set airport(value: Airport) {
    if (!equals(this.airport, value)) {
      this.masterShipments.forEach(ms => {ms.airport = value; });
    }
  }

  get cargoBuilding(): Address {
    return this.masterShipments.asUniqueValue(ms => ms.shipment.addressDelivery);
  }

  set cargoBuilding(value: Address) {
    if (!equals(this.cargoBuilding, value)) {
      this.masterShipments.forEach(ms => {
        ms.shipment.addressDelivery = value;
      });
    }
  }

  get airline(): Airline {
    return this.masterShipments.asUniqueValue(ms => ms.airline);
  }

  get masterShipmentAir(): MasterShipmentAir {
    return this.masterShipments && this.masterShipments.length === 1 ? this.masterShipments[0].masterShipmentAir : null;
  }

  set airline(value: Airline) {
    if (!equals(this.airline, value)) {
      this.masterShipments.forEach(ms => {
        ms.airline = value;
      });
    }
  }

  get splitCount(): number {
    return this.splits.length;
  }

  get dateLastFreeDay(): Date {
    let date: Date = null;
    this.masterShipments.forEach(ms => {date = minDate(date, ms.dateLastFreeDay); });
    return date;
  }

  get dateCfsFreeTime(): Date {
    let date: Date = null;
    this.orders.forEach(or => {date = minDate(date, or.dateCfsFreeTime); });
    return date;
  }

  get recovery(): Load[] {
    let r: Load[] = [];
    this.orders.forEach(o => {
      o.shipmentContents.forEach(sc => {
        let load = sc.shipment.load;
        if (load && load.isRecovery && !r.hasEquals(load)) {
          r.push(load);
        }
      });
    });

    return r;
  }

  get splits(): RecoveryOrder[] {
    if (this._splits) {
      return this._splits;
    }

    let num = 1;
//    this._splits = [];

    // New Recovery orders implementation
    this._splits = this.recoveryOrders ? this.recoveryOrders.map((o) => new RecoveryOrder(this, o)) : [];
    this._splits.sort((a, b) => a.order.id - b.order.id);


    // Add Old Split Implementation
    this.orders.forEach((order) => {
      order.loadShipmentContents.forEach((sc) => {
        if (sc.shipment && sc.shipment.load && sc.shipment.load.isRecovery) {

          let split: Split = <Split> this._splits.find((s) => s instanceof Split && equals(s.shipment, sc.shipment));
          if (!split) {
            split = new Split(this, num++);
            split.shipment = sc.shipment;
            split.load = sc.shipment.load;
            split.load.splitNumber = split.splitNumber;
            this._splits.push(split);
          }
          split.contents.push(sc);
        }
      });
    });

/*    let remains: number = this.pcsToRecover;// this.pieces - this.pcsReceived;
    if (remains > 0) {
      //Find existing load which has no pieces assigned
      let split: Split = <Split> this._splits.findFirst(s => s instanceof Split && !s.isVirtual && !s.isDispatched && (this.isNew() && s.isAssigned  || !s.isAssigned));

      if (!split) {
        //Create new virtual load
        split = new Split(this, num++);
        this._splits.push(split);
        split.pcsToRecover = remains;
      } else {
        split.pcsToRecover = remains + ~~split.pcsToRecover;
      }

    } else {
      let split: Split = <Split> this._splits.findFirst(s => s instanceof Split && !s.isVirtual && !s.isDispatched && (this.isNew() && s.isAssigned  || !s.isAssigned));
      if (split)
        split.pcsToRecover = remains + ~~split.pcsToRecover;

    }
    */
    return this._splits;
  }


  get parts(): Part[] {
    if (this._parts) {
      return this._parts;
    }

    this._parts = [];

    this.orders.forEach(order => {
      let partNo = 'A';
      order.contentsRecovery.forEach(sc => {
        let part = new Part();
        part.content = sc;
        part.master = this;
        part.order = order;
        part.content = sc;
        part.part = partNo;
        this._parts.push(part);
        partNo = nextLetter(partNo);
      });
    });
    return this._parts;
  }


  get someOver(): boolean {
    return this.orders.some(o => o.fillStatus === FillStatus.OVER);
  }

  get fillStatus(): FillStatus {
    let someHalf = this.orders.some(o => {
      return o.fillStatus === FillStatus.HALF;
    });
    let received: number = this.pcsReceived;
    if (received > 0) {
      if (received < this.pieces || someHalf) {
        return FillStatus.HALF;
      } else if (received > this.pieces) {
        return FillStatus.OVER;
           } else {
        return FillStatus.FULL;
           }
    } else {
      return FillStatus.EMPTY;
    }
  }


  get receiveStatus(): FillStatus | number {
    let prc = this.pcsReceived * 100 / this.pieces || 0;
    let someHalf = this.orders.some(o => {
      return o.fillStatus === FillStatus.HALF;
    });
    return someHalf ? (prc < 100 ? prc : FillStatus.HALF) : prc;
  }

  get fillStatusPrc(): number {
    return this.pcsReceived * 100 / this.pieces || 0;
  }


  get fillStatusDescription(): string {
    let s: string = '';
    if (this.direct) {
      s = 'Direct:';
    } else {
      s = StringUtil.receivingDescription(this.pieces, this.pcsReceived);
    }

    this.splits.forEach((split: RecoveryOrder) => {
      if (split.isDispatched) {
        if (split.load.driver) { s += '\nR#' + split.orderId + ': ' + split.load.driver.fullName; }
        if (split.load.truck) { s += ' TRK: ' + split.load.truck.number; }
        if (split.load.trailer) { s += ' TRL: ' + split.load.trailer.number; }
      }
    });
    return s;
  }

  get driverName() {
    let driverName = '';
    this.splits.forEach((split: RecoveryOrder) => {
      if (split.isDispatched) {
        if (!isNullOrUndefined(driverName) && driverName.length > 0 && !isNullOrUndefined(split.load.driver) && driverName !== split.load.driver.fullName) {
          driverName = '';
          return driverName;
        }
        driverName = driverName.length === 0 ? !isNullOrUndefined(split.load.driver) ? split.load.driver.fullName : '' : '';
      }
    });
    return driverName;
  }

  get truckNumber() {
    let truckNumber = '';
    this.splits.forEach((split: RecoveryOrder) => {
      if (split.isDispatched) {
        if (!isNullOrUndefined(truckNumber) && truckNumber.length > 0 && !isNullOrUndefined(split.load.truck) && truckNumber !== split.load.truck.number) {
          truckNumber = '';
          return truckNumber;
        }
        truckNumber = truckNumber.length === 0 ? !isNullOrUndefined(split.load.truck) ? split.load.truck.number : '' : '';
      }
    });
    return truckNumber;
  }

  get trailerNumber() {
    let trailerNumber = '';
    this.splits.forEach((split: RecoveryOrder) => {
      if (split.isDispatched) {
        if (!isNullOrUndefined(trailerNumber) && trailerNumber.length > 0 && !isNullOrUndefined(split.load.trailer) && trailerNumber !== split.load.trailer.number) {
          trailerNumber = '';
          return trailerNumber;
        }
        trailerNumber = trailerNumber.length === 0 ? !isNullOrUndefined(split.load.trailer) ? split.load.trailer.number : '' : '';
      }
    });
    return trailerNumber;
  }

  get pcsToRecover(): number {
    let num = this.pieces || 0;
    let active: boolean = false;
    this.splits.forEach(s => {
      if (s.loadId) {
        num -= s.pcsToRecover || 0;
        if (!s.load.isDelivered) {
          active = true;
        }
      }
    });

    if (!active && ~~this.pieces > ~~this.pcsReceived) {
      return ~~this.pieces - ~~this.pcsReceived;
    }
    return num || null;
  }

  get huToRecover(): number {
    let num = this.hu || 0;
    let active: boolean = false;
    this.splits.forEach(s => {
      if (s.loadId) {
        num -= s.huToRecover || 0;
        if (!s.load.isDelivered) {
          active = true;
        }
      }
    });

    if (!active && ~~this.hu > ~~this.huReceived) {
      return ~~this.hu - ~~this.hu;
    }
    return num || null;
  }


  get pcsToReceive(): number {
    return ~~this.pieces - ~~this.pcsReceived;
  }


  get date1F(): Date {
    return latest(this.masterShipments, ms => ms.date1F);
  }

  set date1F(value: Date) {
    this.masterShipments.forEach(ms => ms.date1F = value);
  }

  get isDispatched(): boolean {
    return !isNullOrUndefined(this.splits.find((s) => s.isDispatched && !s.isRecovered));
  }

  get noCfs(): boolean {
    return this.direct;
  }

  get masterAirUld() {
    return this.ulds;
  }

  set masterAirUld(uldAirs: Uld[]) {
    this.ulds = uldAirs;
  }

  @Exclude()
  get addressDelivery() {
    return this.direct ? this.orders.asUniqueValue(o => o.addressDelivery) : this.orders.asUniqueValue(o => o.addressCfs);
  }

  set addressDelivery(address: Address) {
    if (this.direct) {
      this.orders.forEach(o => o.addressDelivery = address);
    } else {
      this.orders.forEach(o => o.addressCfs = address);
    }
  }

  @Exclude()
  get addressCfs(): Address {
    return this.orders.asUniqueValue(o => o.addressCfs);
  }

  set addressCfs(address: Address) {
    this.orders.forEach(o => o.addressCfs = address);
  }

  get date1C(): Date {
    return latest(this.orders, o => o.date1C);
  }

  @BeforeSave()
  public beforeSave() {
//    console.log('MASTER BEFORE SAVE', this);
    let id = 0;
    // 1. Enum unique shipments to shipment content relation.
    this.orders.forEach(o => {
      o.shipmentContents.forEach(sc => {
        sc.shipment.uid = ++id;
      });
    });
    this.orders.forEach(o => {
      o.shipmentContents.forEach(sc => {
        sc.shipment_uid = sc.shipment.uid;
      });
    });

    // 2. Enum orders
    id = 0;
    this.orders.forEach(o => {
      o.uid = ++id;
    });
    this.orders.forEach(o => {
      o.shipmentContents.forEach(sc => {
        sc.order = o;
        sc.order_uid = o.uid;
      });
    });
  }

  @AfterLoad()
  public afterLoad() {
//    console.log('--- MASTER ON LOAD ----------', this);

    if (!this.masterShipments) {
      this.masterShipments = [];
    }

    this.masterShipments.forEach((ms) => ms.master = this);

    // keep unique shipments for all orders inside master
    this.masterShipments.forEach((ms) => {
      this.shipments.push(ms.shipment);
    });

    this.orders.forEach(o => {
      o.shipmentContents.forEach((sc) => {
        let shipment = this.shipments.find((s) => equals(s, sc.shipment));
        if (shipment) { sc.shipment = shipment; } else { this.shipments.push(sc.shipment); }
      });
    });

    this.orders.forEach(o => {
      o.master = this;
      o.shipmentContents.forEach((sc) => {
        sc.order = o;
        sc.shipment.shipmentContents.push(sc);
        if (sc.shipment.load) {
          sc.shipment.load.shipment = sc.shipment;
        }
      });
    });

  }

}
