import { Master, Order } from "../../models";
import { CbpStatus } from "../../models/cbp.status";
import { ArrayUtils } from "../../../../_helpers/array.utils";
import * as moment from "moment";
import { OmsConstants } from "../../../../common/oms-constants.service";

export class CbpStatusUtils {

  public static readonly FULL_RELEASED_ICON = 'fa fa-circle-check';
  public static readonly FULL_NOT_RELEASED_ICON = 'fa fa-circle';
  public static readonly HALF_RELEASED_ICON = 'fa fa-circle-half-stroke';
  public static readonly EMPTY_ICON = 'fa-regular fa-circle';

  public static getToggledDate(master: Master, order: Order, is1F: boolean): Date {
    let hasDate;
    if (order) {
      hasDate = is1F ? order.date1F : order.date1C;
    } else {
      hasDate = master.orders.some(o => is1F ? !!o.date1F : !!o.date1C);
    }
    if (hasDate) {
      return null;
    }
    const lastCbpStatus = CbpStatusUtils.getLastCbpStatus(master, order, is1F);
    return lastCbpStatus ? CbpStatusUtils.getDateFromCbpStatus(lastCbpStatus) : new Date();
  }

  public static getBatteryColor(master: Master, order: Order, is1F: boolean): string {
    if (CbpStatusUtils.isManualReleased(master, order, is1F)) {
      return 'fill-indicator dispatched'; // green
    }
    if (CbpStatusUtils.isManualCancel(master, order, is1F)) {
      return 'fill-indicator grey';
    }
    let status = CbpStatusUtils.getStatus(master, order, is1F);
    if (!status) {
      return 'fill-indicator grey';
    }
    if (!order && !is1F) { // TODO don't display part status
      const pieces = CbpStatusUtils.getPieces(master, order, is1F);
      const cbpPieces = CbpStatusUtils.getCbpPieces(order, master, is1F);
      if (CbpStatusUtils.isReleased(status) && cbpPieces >= pieces) {
        return 'fill-indicator dispatched';
      }
      return 'fill-indicator grey';
    }
    return (CbpStatusUtils.isReleased(status) || CbpStatusUtils.isRemoveHoldStatus(status))
      ? 'fill-indicator dispatched' // green
      : 'fill-indicator danger'; // red
  }

  public static getLastCbpStatus(master: Master, order: Order, is1F: boolean): CbpStatus {
    const lastCbpStatuses = CbpStatusUtils.getLastCbpStatuses(master, order, is1F);
    const notReleased = lastCbpStatuses.find((cbpStatus) => CbpStatusUtils.isNotReleased(cbpStatus.uscsFsnStatus)
      || CbpStatusUtils.isHoldStatus(cbpStatus.uscsFsnStatus));
    if (notReleased) {
      return notReleased;
    }
    return lastCbpStatuses.length ? lastCbpStatuses[0] : null;
  }

  public static getLastCbpStatuses(master: Master, order: Order, is1F: boolean): CbpStatus[] {
    const cbpStatuses = CbpStatusUtils.getCbpStatusesFromMaster(master, order, is1F);
    let groups: { [key: string]: CbpStatus[] } = {};
    cbpStatuses.forEach(cbpStatus => {
      let items = groups[cbpStatus.part] || [];
      items.push(cbpStatus);
      groups[cbpStatus.part] = items;
    });
    return Object.values(groups).map(statuses => ArrayUtils.getLast(statuses));
  }

  public static getStatus(master: Master, order: Order, is1F: boolean): string {
    const lastCbpStatus = CbpStatusUtils.getLastCbpStatus(master, order, is1F);
    return lastCbpStatus ? lastCbpStatus.uscsFsnStatus : null;
  }

  public static getMasterOrder1F1CTooltip(master: Master, order: Order, is1F: boolean): string {
    const date = CbpStatusUtils.getDateSet(master, order, is1F);
    let tooltip = date ? moment(date).tz(OmsConstants.ETC_ZONE).format('MM/DD/YY') : '';
    if (CbpStatusUtils.isManualStatus(master, order, is1F)) {
      return tooltip + ' manual';
    }
    const status = CbpStatusUtils.getStatus(master, order, is1F);
    const isNeedPieces = CbpStatusUtils.isReleased(status) || CbpStatusUtils.isRemoveHoldStatus(status);
    tooltip += status ? ' ' + status : '';
    if ((!isNeedPieces || !status) && !(!is1F && !order)) {
      return tooltip;
    }
    if (order || master) {
      tooltip += ' PCS:' + CbpStatusUtils.getCbpPieces(order, master, is1F) + '/' + CbpStatusUtils.getPieces(master, order, is1F);
/* debug
      if (master) {
        tooltip += '\n Master: ' + (is1F ? master.pcs1F : master.pcs1C);
      }

      if (order) {
        tooltip += '\n Order: ' + (is1F ? order.pcs1F : order.pcs1C);

      }

 */
    }
    return tooltip;
  }

  private static getPieces(master: Master, order: Order, is1F: boolean): number {
    if (is1F && master) {
      return master.pieces || master.orderPieces;
    }
    if (order) {
      return order.pieces;
    }
    if (master) {
      return master.pieces || master.orderPieces;
    }
    return null;
  }

  public static getCbpPieces(order: Order, master: Master, is1F: boolean): number {
    const cbpStatuses = CbpStatusUtils.getCbpStatusesFromMaster(master, order, is1F);
    // 1F Master/Order || 1C Order
    if (is1F || (!is1F && order)) {
      return CbpStatusUtils.calculateTotalByGroup(cbpStatuses);
    }
    // 1C Master
    return master.orders.reduce((total, itOrder) => total + CbpStatusUtils.getCbpPieces(itOrder, master, is1F), 0);
  }

  private static sortCbpStatuses(cbpStatuses: CbpStatus[]): void {
    cbpStatuses.sort((c1, c2) => {
      const t1 = CbpStatusUtils.getDateFromCbpStatus(c1).getTime();
      const t2 = CbpStatusUtils.getDateFromCbpStatus(c2).getTime();
      if (t1 === t2) {
        return c1.id - c2.id;
      }
      return t1 - t2;
    });
  }

  private static calculateTotalByGroup(cbpStatuses: CbpStatus[]): number {
    const hasPart = cbpStatuses.some((cbpStatus) => !!cbpStatus.part);
    if (hasPart) {
      const piecesGroup = {} as { [key: string]: number };
      cbpStatuses.filter((cbpStatus) => !!cbpStatus.part)
        .forEach((cbpStatus) => {
          piecesGroup[cbpStatus.part] = cbpStatus.totalPieces;
        });
      return Object.values(piecesGroup).reduce((total, pieces) => total + pieces, 0);
    }
    const lastCbpStatus = ArrayUtils.getLast(cbpStatuses);
    return lastCbpStatus ? lastCbpStatus.totalPieces : 0;
  }

  private static getDateFromCbpStatus(cbpStatus: CbpStatus): Date {
    return cbpStatus.fsnSentDate || cbpStatus.transactionTime || cbpStatus.transactionDate || cbpStatus.dateCreated || new Date(0);
  }

  public static sanitizeHawb(hawb: string) {
    return hawb ? hawb.replace(/[-_. ]/, '').trim() : hawb;
  }

  private static filterCbpStatusByOrder(cbpStatuses: CbpStatus[], order: Order): CbpStatus[] {
    return cbpStatuses.filter((cbpStatus) => this.sanitizeHawb(cbpStatus.hawb) === this.sanitizeHawb(order.hawb));
  }

  private static filterAms1FStatuses(statuses: CbpStatus[]): CbpStatus[] {
    return (statuses || []).filter((status) => !status.hawb);
  }

  private static filterAms1CStatuses(statuses: CbpStatus[]): CbpStatus[] {
    return (statuses || []).filter((status) => status.hawb);
  }

  private static getCbpStatusesFromMaster(master: Master, order: Order, is1F: boolean): CbpStatus[] {
    if (!master || !master.cbpStatuses) {
      return [];
    }
    let cbpStatuses = master.cbpStatuses;
    cbpStatuses = cbpStatuses.filter((cbpStatus) => {
      const status = cbpStatus.uscsFsnStatus && cbpStatus.uscsFsnStatus.toUpperCase();
      return CbpStatusUtils.isReleased(status) || CbpStatusUtils.isNotReleased(status)
        || CbpStatusUtils.isHoldStatus(status) || CbpStatusUtils.isRemoveHoldStatus(status);
    });
    cbpStatuses = is1F
      ? CbpStatusUtils.filterAms1FStatuses(cbpStatuses)
      : (order ? CbpStatusUtils.filterCbpStatusByOrder(cbpStatuses, order) : CbpStatusUtils.filterAms1CStatuses(cbpStatuses));
    return CbpStatusUtils.filterCbpStatusByGroup(cbpStatuses);
  }

  private static filterCbpStatusByGroup(cbpStatuses: CbpStatus[]): CbpStatus[] {
    CbpStatusUtils.sortCbpStatuses(cbpStatuses);
    const lastStatus = [...cbpStatuses].reverse().find(cbpStatus => {
      return CbpStatusUtils.isHoldStatus(cbpStatus.uscsFsnStatus)
        || CbpStatusUtils.isRemoveHoldStatus(cbpStatus.uscsFsnStatus);
    });
    if (lastStatus && CbpStatusUtils.isHoldStatus(lastStatus.uscsFsnStatus)) {
      return [lastStatus];
    }

    return cbpStatuses.filter(cbpStatus => {
      const status = cbpStatus.uscsFsnStatus && cbpStatus.uscsFsnStatus.toUpperCase();
      if (CbpStatusUtils.isHoldStatus(status) || CbpStatusUtils.isRemoveHoldStatus(status)) {
        return false;
      }
      if (status === '4E') {
        return !cbpStatuses.some(s => s !== cbpStatus
          && CbpStatusUtils.getDateFromCbpStatus(s).getTime() === CbpStatusUtils.getDateFromCbpStatus(cbpStatus).getTime()
          && cbpStatus.part === s.part);
      }
      return true;
    });
  }

  public static getBatteryIcon(master: Master, order: Order, is1F: boolean): string {
    if (!master && !order) {
      return CbpStatusUtils.EMPTY_ICON;
    }
    if (CbpStatusUtils.isManualReleased(master, order, is1F)) {
      return CbpStatusUtils.FULL_RELEASED_ICON;
    }
    if (CbpStatusUtils.isManualCancel(master, order, is1F)) {
      return CbpStatusUtils.EMPTY_ICON;
    }
    const status = CbpStatusUtils.getStatus(master, order, is1F);
    if (!status) {
      return CbpStatusUtils.EMPTY_ICON;
    }
    const cbpPieces = CbpStatusUtils.getCbpPieces(order, master, is1F);
    const pieces = CbpStatusUtils.getPieces(master, order, is1F);
    if (!order && !is1F) { // TODO don't display part status
      if (CbpStatusUtils.isReleased(status) && cbpPieces >= pieces) {
        return CbpStatusUtils.FULL_RELEASED_ICON;
      }
      return CbpStatusUtils.EMPTY_ICON;
    }
    if (CbpStatusUtils.isNotReleased(status) || CbpStatusUtils.isHoldStatus(status)) {
      return CbpStatusUtils.FULL_NOT_RELEASED_ICON;
    }
    if (!cbpPieces || cbpPieces === 0) {
      return CbpStatusUtils.EMPTY_ICON;
    } else if (cbpPieces < pieces) {
      return CbpStatusUtils.HALF_RELEASED_ICON;
    } else {
      return CbpStatusUtils.FULL_RELEASED_ICON;
    }
  }

  private static isManualReleased(master: Master, order: Order, is1F: boolean): boolean {
    if (!CbpStatusUtils.isManualStatus(master, order, is1F)) {
      return false;
    }
    return CbpStatusUtils.isManualSet(master, order, is1F);
  }

  private static isManualCancel(master: Master, order: Order, is1F: boolean): boolean {
    if (!CbpStatusUtils.isManualStatus(master, order, is1F)) {
      return false;
    }
    return !CbpStatusUtils.isManualSet(master, order, is1F);
  }

  private static isManualSet(master: Master, order: Order, is1F: boolean): boolean {
    return !!CbpStatusUtils.getDateSet(master, order, is1F);
  }

  public static getDateSet(master: Master, order: Order, is1F: boolean): Date {
    if (is1F) {
      return order ? order.date1F : CbpStatusUtils.getMaxDate(master.orders.map(o => o.date1F));
    }
    return order ? order.date1C : (CbpStatusUtils.getMaxDate(master.orders.map(o => o.date1C)) || master.date1C);
  }

  private static getMaxDate(dates: Date[]): Date {
    if (!dates.length || dates.some(d => !d)) {
      return null;
    }
    return new Date(Math.max(...dates.map((d) => d.getTime())));
  }

  private static isManualStatus(master: Master, order: Order, is1F: boolean): boolean {
    if (is1F) {
      return order ? order.manual1FAms : master.orders.every((o) => o.manual1FAms);
    }
    return order ? order.manual1CAms : master.orders.every((o) => o.manual1CAms);
  }

  private static isReleased(status: string): boolean {
    return ['1B', '1C', '1D', '1F', '1L', '4C'].some((s) => status && s === status.toUpperCase());
  }

  private static isNotReleased(status: string): boolean {
    return ['1A', '1E', '1G', '1J', '1M', '4A', '4E', '1R', '1S'].some((s) => status && s === status.toUpperCase());
  }

  private static isHoldStatus(status: string): boolean {
    return ['1H', '2H', '3H', '5H'].some((s) => status && s === status.toUpperCase());
  }

  private static isRemoveHoldStatus(status: string): boolean {
    return ['1I', '2I', '3I', '5I'].some(s => status && s === status.toUpperCase());
  }
}
