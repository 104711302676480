import {
  Address,
  Customer, Driver,
  FreightForwarder, LoadType,
  MasterStatus, Trailer, transformLoadType, transformMasterStatus,
} from '..';
import {Exclude, Transform, Type} from 'class-transformer';
import {UploadedFiles} from "../documents/uploaded.files";
import {isNullOrUndefined} from "util";
import {OrderMode, transformOrderMode} from "../order/order-mode";
import {RowObject, TrackByObject} from '../../components/base/data-table/columns/column-types';
import {Status, transformStatus} from "../warehouse/status";

export class OrderDispatch implements RowObject, TrackByObject {
  public static readonly ROW_ID = 'OrderDispatch';
  // className? TODO

  rowId = OrderDispatch.ROW_ID;
  id: number;
  orderId: number;
  masterId: number;
  dispatchId: number;
  shipmentId: number;
  dispatchShipmentId: number;  // actually DispatchItemId
  dispatchCancelled: boolean;
  orderNumber: number;

  docCount: number;
  comCount: number;

  @Type(() => Customer)
  customer: Customer;
  @Type(() => FreightForwarder)
  freightForwarder: FreightForwarder;
  freightForwarderRef: String;
  @Type(() => Driver)
  driver: Driver;

  @Transform((v, o, tt) => transformOrderMode(v, o, tt))
  public orderMode: OrderMode;
  customerRef: string;   // ref1
  hawb: string;          // ref2
  mawb: string;          // ref3
  purchaseOrder: string;

  pieces: number;
//  pcsDispatched: number;
  pcsReceived: number;
  weight: number;
  hu: number;
  comments: string;
  uldCount;
  quote: number;

// tbd
//  @Transform((v, o, tt) => transformStatus(v, o, tt))
//  receivingStatus: Status;

  @Transform((v, o, tt) => transformStatus(v, o, tt))
  loadOutStatus: Status;

  @Type(() => Address)
  addressPickup: Address;
  @Type(() => Address)
  addressDelivery: Address;

  @Type(() => UploadedFiles)
  manifestDocument: UploadedFiles;

  @Transform((v, o, tt) => transformLoadType(v, o, tt))
  loadType: LoadType;
  @Type(() => Date)
  dateCreated: Date;  // Manifested Date
  @Type(() => Date)
  dateDispatchedFor: Date;    // Route Date
  @Type(() => Date)
  datePickupEst: Date;
  @Type(() => Date)
  datePickupAct: Date;
  @Type(() => Date)
  dateDeliveryEst: Date;
  @Type(() => Date)
  dateDeliveryAct: Date;
  @Type(() => Date)
  dateRouteCompleted: Date;
  @Type(() => Date)
  dateConfirmedOn: Date;

  hasProblem: boolean;

  @Transform((v, o, tt) => transformMasterStatus(v, o, tt))
  orderStatus: MasterStatus;
  hot: boolean;
  @Type(() => Date)
  requestedDeliveryDate: Date;
  cod: boolean;
  chassis: string;
  dispatchNotes: string;

  @Type(() => Date)
  dateOnSitePickup: Date;
  @Type(() => Date)
  dateOnSiteDelivery: Date;

  @Type(() => Trailer)
  trailer: Trailer;

  status: string;

  @Exclude()
  public get isRouteCompleted(): boolean {
    return !isNullOrUndefined(this.dateRouteCompleted);
  }

  @Exclude()
  public get isDelivered(): boolean {
    return this.dispatchId && !!this.dateDeliveryAct;
  }

  @Exclude()
  public get isPickedUp(): boolean {
    return this.dispatchId && !!this.datePickupAct;
  }

  @Exclude()
  public get isDispatched(): boolean {
    return !isNullOrUndefined(this.dispatchShipmentId);
  }

  @Exclude()
  public get isConfirmed(): boolean {
    return !isNullOrUndefined(this.dateConfirmedOn);
  }

  @Exclude()
  public get isRecovery(): boolean {
    return this.loadType === LoadType.RECOVERY;
  }

  public trackBy() {
    return '' + this.dispatchId + this.orderId + this.shipmentId;
  }


  public isNotCompleted(): boolean {
    return this.hasProblem &&  !(this.isRecovery ? this.isPickedUp : this.isDelivered);
  }

  public isCompleted(): boolean {
    return !this.hasProblem && (this.isRecovery ? this.isPickedUp : this.isDelivered);
  }

  public isCompletedWithProblem(): boolean {
    return this.hasProblem && (this.isRecovery ? this.isPickedUp : this.isDelivered);
  }

  public getStatusName() {
    if (this.isCompleted()) {
      return "Completed";
    } else if (this.isCompletedWithProblem()) {
      return "Completed with problem";
    } else if (this.isNotCompleted()) {
      return "Not Completed";
    }
    return null;
  }

  public getRouteStatus(): string {
    let statusName = this.getStatusName();
    if (statusName) {
      return statusName;
    } else if (this.isConfirmed) {
      return "Confirmed";
    } else {
      return "Dispatched";
    }
  }
}
