var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntity } from "../base-entity";
var City = /** @class */ (function (_super) {
    __extends(City, _super);
    //  countryString: string;
    //  subdivision: string;
    function City(name) {
        var _this = _super.call(this) || this;
        _this.name = name;
        return _this;
    }
    Object.defineProperty(City.prototype, "label", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    ;
    return City;
}(BaseEntity));
export { City };
