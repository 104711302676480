import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {OrderDispatch} from "../../../shared/models/dispatch/order-dispatch";
import {Driver, Trailer, Truck} from "../../../shared/models";
import {AddressSearchService} from "../../../shared/services/address-search.service";
import {OmsConverters} from "../../../shared/services/oms-converters.service";
import {AddressService} from "../../../shared/services/address.service";
import {OmsAlertsService} from "../../../shared/components/oms-alerts/oms-alerts.service";
import {TruckService} from "../../../../services/truck-service";
import {TrailerService} from "../../../../services/trailer-service";
import {DriverService} from "../../../../services/driver-service";
import {Size} from '../../../../common/oms-types';
import {
  DialogCloseEvent,
  DialogResult,
  DialogType,
  ModalResult,
  OmsDialogsService
} from "../../../../components/common/oms-dialogs";


export interface DispatchData {
  dispatchDtos: OrderDispatch[];
  date: Date;
  driver?: Driver;
  truck?: Truck;
  trailer?: Trailer;
}

@Component({
  templateUrl: './dispatch-route-dialog.component.html',
  styleUrls: ['./dispatch-route-dialog.component.scss']
})
export class DispatchRouteDialogComponent implements OnInit {
  Size = Size;

  public readonly: boolean = false;
  public addressSearch: AddressSearchService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DispatchData,
    public converter: OmsConverters,
    public cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<DispatchRouteDialogComponent, DialogResult<DispatchData>>,
    public addressService: AddressService,
    private dialogs: OmsDialogsService,
    private alerts: OmsAlertsService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService
  ) {
  }

  ngOnInit() {
  }

  onCancelDispatch() {
    this.dialogs.confirm(DialogType.CONFIRMATION, 'Cancel Dispatch?',
      'The load will be deleted and all related data will be lost.\nContinue?').then((result) => {
        switch (result.result) {
          case ModalResult.YES: {
            this.dialogRef.close({result: ModalResult.DELETE});
          }
        }
    });
  }

  onOk(event: DialogCloseEvent) {
    event.canClose = this.validate();
  }

  onCreateTruck(number: string) {
    const truck = new Truck();
    truck.number = number;
    this.truckService.createEditAndSave(truck, this.readonly)
      .then((t) => this.data.truck = t)
      .catch((error) => this.alerts.danger(error));
  }

  onCreateTrailer(number: string) {
    const trailer = new Trailer();
    trailer.number = number;
    this.trailerService.createEditAndSave(trailer, this.readonly)
      .then((t) => this.data.trailer = t)
      .catch(error => this.alerts.danger(error));
  }

  onCreateDriver() {
    const driver = new Driver();
    this.driverService.createEditAndSave(driver, this.readonly)
      .then((d) => this.data.driver = d)
      .catch(error => this.alerts.danger(error));
  }


  public allowDispatch(master: any): boolean {
    return true; // master.nonAMS || !!master.date1C;
  }

  public validate(): boolean {
/*    let m:Master[] = (this.data.masters as Master[]).filter(master=>!this.allowDispatch(master));
    if (m.length>0) {
      this.alerts.warning('All AMS masters must have 1C');
      return false;
    } */

    return true;
  }

}
