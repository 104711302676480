import {Injectable, Type} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subject} from 'rxjs';
import {AuthService} from "../../../../services";
import {skip} from "rxjs/operators";

export interface ProgressStatus {
  percents: number;
  status: string;
  error?: any;
  completed?: boolean;
}

export interface MessageAlertOptions {
  componentType?: Type<any>;
  componentData?: any;
  title?: string;
  icon?: string;
  onClose?: () => void;
  type?: 'info' | 'warning' | 'success' | 'danger';
  timeout?: number;
  message?: string;
}

export interface Alert extends MessageAlertOptions {
  id?: number;
  stack?: any;
  progress?: ProgressStatus;
}

@Injectable()
export class OmsAlertsService {

  public static readonly ALERT_HAS_NEW_STORAGE_KEY = "alertHasNewMessage";

  private alerts: any[] = [];
  public alertsSubject: Subject<Alert[]> = new BehaviorSubject<Alert[]>([]);
  private alert_id = 0;

  private messages: any[] = [];
  public messagesSubject: Subject<Alert[]> = new BehaviorSubject<Alert[]>([]);

  private DEFAULT_SUCCESS_TIMEOUT = 1000;

  constructor(private authService: AuthService) {
    this.logoutHandler();
  }

  private logoutHandler() {
    this.authService.user$
      .pipe(skip(1))
      .subscribe(user => {
        if (!user) {
          this.alerts = [];
          this.alertsSubject.next(this.alerts);
          this.messages = [];
          this.messagesSubject.next(this.messages);
          localStorage.removeItem(OmsAlertsService.ALERT_HAS_NEW_STORAGE_KEY);
        }
      });
  }

  public warning(message: string, timeout?: number) {
    this.add({
      icon: 'fa fa-exclamation-triangle',
      type: 'warning',
      title: 'Warning!',
      message: message,
      timeout: timeout || this.DEFAULT_SUCCESS_TIMEOUT
    });
  }

  showMessages(options: MessageAlertOptions[] = []): void {
    this.addMessages(options.map(option => ({
      type: 'warning' as any,
      timeout: this.DEFAULT_SUCCESS_TIMEOUT,
      ...option
    })));
  }

  setMessages(options: MessageAlertOptions[] = []): void {
    this.replaceMessages(options.map(option => ({
      type: 'warning' as any,
      timeout: this.DEFAULT_SUCCESS_TIMEOUT,
      ...option
    })));
  }

  public info(message: string, timeout?: number) {
    this.add({icon: 'fa fa-info-circle', type: 'info', title: 'Info:', message: message, timeout: timeout || this.DEFAULT_SUCCESS_TIMEOUT});
  }

  public success(message: string, timeout?: number) {
    console.log('SUCCESS', message);
    this.add({
      icon: 'fa fa-check-circle',
      type: 'success',
      title: 'Success!',
      message: message,
      timeout: timeout || this.DEFAULT_SUCCESS_TIMEOUT
    });
  }

  public danger(message: string | any, timeout?: number) {
    console.log('DANGER MESSAGE', message);
    if (typeof message === 'string') {
      this.add({icon: 'fa fa-times-circle', type: 'danger', title: 'Error!', message: message, timeout: timeout});
    } else {
      this.add({
        icon: 'fa fa-times-circle',
        type: 'danger',
        title: 'Error!',
        message: message.message || 'Internal Server Error',
        stack: message.stackTrace,
        timeout: timeout
      });
    }
  }

  error(error: string | { error?: string, message?: string, stackTrace?: string }, title: string = 'Error!', timeout?: number) {
    console.log('ERROR MESSAGE', error);
    this.add({
      icon: 'fa fa-times-circle', type: 'danger', title: title,
      message: typeof error === "string" ? error : error && error.message || error.error || 'Internal Server Error',
      stack: error && error['stackTrace'],
      timeout: timeout
    });
  }

  process(title: string, message: string, update: (alert: Alert) => Observable<ProgressStatus>, period: number = 5000): Promise<any> {
    const alert: Alert = {
      type: 'info',
      icon: 'fa fa-gears',
      message: message,
      title: title,
      progress: {percents: 0, status: 'Processing...'}
    };
    this.add(alert);

    return new Promise<any>((success, error) => {
      const sub = interval(period)
        .subscribe(() => {
          if (!this.alerts.includes(alert)) {
            sub.unsubscribe();
          } else {
            update(alert).subscribe(
              (status) => {
                console.log('STATUS', status);
                if (!status || status.completed || status.error) {
                  sub.unsubscribe();
                }

                if (status) {
                  alert.progress = status;

                  if (status.error) {
                    console.log('ERROR', alert, status.error);
                    alert.type = 'danger';
                    alert.title = 'Error: ';
                    alert.message = status.error.message;
                    alert.stack = status.error.stackTrace;
                    error(status.error);
                  } else if (status.completed) {
                    success(status);
                  }
                }

                this.alertsSubject.next(this.alerts = [...this.alerts]);
              });
          }
        });
    });

  }

  add(alert: Partial<Alert>) {
    alert.id = this.alert_id++;
    this.alerts.push(alert);
    this.alertsSubject.next(this.alerts);
  }

  private addMessages(alerts: (Partial<Alert>)[]) {
    if (!alerts.length) {
      return;
    }
    alerts.forEach(a => a.id = this.alert_id++);
    this.messages = [...alerts.reverse(), ...this.messages];
    this.messagesSubject.next(this.messages);
  }

  private replaceMessages(alerts: (Partial<Alert>)[]) {
    if (!alerts.length) {
      return;
    }
    alerts.forEach(a => a.id = this.alert_id++);
    this.messages = [...alerts.reverse()];
    this.messagesSubject.next(this.messages);
  }

  remove(alert: Alert) {
    if (alert && alert.id >= 0) {
      if (alert.onClose) {
        alert.onClose();
      }
      this.alertsSubject.next(this.alerts = this.alerts.filter((item) => item.id !== alert.id));
    }
  }

  removeMessage(alert: Alert) {
    if (alert && alert.id >= 0) {
      if (alert.onClose) {
        alert.onClose();
      }
      this.messagesSubject.next(this.messages = this.messages.filter((item) => item.id !== alert.id));
    }
  }

  getAll() {
    this.alertsSubject.next(this.alerts);
  }
}
