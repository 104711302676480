import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  Inject,
  Input,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {DataTableComponent} from '../data-table.component';
import {BaseColumn, TreeColumn} from '../columns/column-types';
import {isEmptyString} from '../../../../../../_helpers/utils';
import {OmsConstants} from '../../../../../../common/oms-constants.service';
import {Logger} from '../../../../../../_helpers/logger';
import {Subject} from "rxjs";


@Component({
  selector: 'oms-data-table-column, [oms-data-table-column]',
  templateUrl: './data-table-column.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./data-table-column.component.scss']
})
@Logger({
  name: 'DataTableColumn'
})
export class DataTableColumnComponent implements OnDestroy, DoCheck, OnChanges {
  @Input() baseColumn: BaseColumn;
  @Input() order: number;
  private differ;
  private unsubscribe$ = new Subject<void>();
  private sortBy: any;
  private sortAsc: boolean;

  column: BaseColumn;
  iconClass: string;
  hasIcon: boolean = true;
  isHeaderSelectable: boolean;
  isToggleButton = false;

  constructor(
    @Inject(DataTableComponent) public table: DataTableComponent,
    private differs: KeyValueDiffers,
    private cdr: ChangeDetectorRef) {
    this.differ = differs.find(this).create();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.baseColumn) {
      this.column = this.baseColumn instanceof TreeColumn ? this.baseColumn.byRow(null) : this.baseColumn;
      this.isHeaderSelectable = this.column.isHeaderSelectable();
      this.iconClass = this.column.getHeaderIconClass();
      this.isToggleButton = this.getItems().length === 2;
    }
  }

  display(value: any): any {
    return this.column.editable && isEmptyString(value) ? OmsConstants.EMPTY_VALUE : value;
  }

  get selectedInHeader(): any {
    return this.isHeaderSelectable ? this.column.handlers.header.selected : null;
  }

  set selectedInHeader(item) {
    if (this.isHeaderSelectable && this.column.handlers.header.apply) {
      this.column.handlers.header.apply(item);
      this.table.reRender();
    }
  }

  ngOnDestroy(): void {
    delete this.table;
    delete this.differ;
    delete this.column;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngDoCheck(): void {
    let changed: boolean = false;
    if (this.sortBy !== this.table.sortedBy) {
      changed = true;
      this.sortBy = this.table.sortedBy;
    }

    if (this.sortAsc !== this.table.sortedAsc) {
      changed = true;
      this.sortAsc = this.table.sortedAsc;
    }

    /*    if (this.differ.diff(this)) {
          changed = true;
        }*/

    if (changed) {
      this.cdr.markForCheck();
    }
  }

  getItems(): any[] {
    return (this.column.handlers.header && Array.isArray(this.column.handlers.header.select)) ? this.column.handlers.header.select : [];
  }

  clickByToggleButton($event: Event) {
    $event.stopPropagation();
    let notSelected = this.getItems().find(item => this.selectedInHeader() !== item);
    this.selectedInHeader = notSelected;
    this.table.refresh();
  }
}
