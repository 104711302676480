import {Exclude, Type} from 'class-transformer';
import {Address, Customer, Driver, FreightForwarder, Trailer, Truck, User} from '../';
import {ManifestItem} from './manifest-item';
import {BaseEntity} from '../base-entity';
import {UploadedFiles} from "../documents/uploaded.files";
import {convertManifestNumber} from "../../services/oms-converters.service";
import {OmsConstants} from "../../../../common/oms-constants.service";
import {Carrier} from "../carrier";
import {absent} from "../../../../_helpers/utils";

export class Manifest extends BaseEntity {
  public externalId: number;
  @Type(() => Date)
  public dateDispatchedFor: Date;
  @Type(() => Date)
  public dateDispatched: Date;
  @Type(() => Date)
  public dateApprovedOn: Date;
  @Type(() => User)
  public dispatchedByUser: User;
  @Type(() => User)
  public approvedByUser: User;
  @Type(() => Driver)
  public driver: Driver;
  @Type(() => Truck)
  public truck: Truck;
  @Type(() => Trailer)
  public trailer: Trailer;
  @Type(() => ManifestItem)
  public items: ManifestItem[] = [];
  @Type(() => UploadedFiles)
  public manifestDocument: UploadedFiles;
  @Type(() => Carrier)
  public carrier: Carrier;

  public sealNumber: string;

  constructor(id?: number, dateDispatched?: Date) {
    super(id);
    this.dateDispatchedFor = dateDispatched;
  }

  get manifestNumber(): String {
    return this.id ? convertManifestNumber(this.id) : 'N/A';
  }

  get addressPickup(): Address {
    return this.items.asUniqueValue((s) => s.addressPickUp);
  }

  get addressPickupName(): Address {
    return this.items.asUniqueValue((s) => s.addressPickUp && s.addressPickUp.name, OmsConstants.MULTIPLE_VALUE);
  }

  get addressDeliveryName(): Address {
    return this.items.asUniqueValue((s) => s.addressDelivery && s.addressDelivery.name, OmsConstants.MULTIPLE_VALUE);
  }

  get addressDelivery(): Address {
    return this.items.asUniqueValue((s) => s.addressDelivery);
  }

  get pieces(): number {
    return this.items.aggregate((sum, s) => sum + ~~s.pieces, 0);
  }

  get hu(): number {
    return this.items.aggregate((sum, s) => sum + ~~s.hu, 0);
  }

  get weight(): number {
    return this.items.aggregate((sum, s) => sum + (s.weight || 0), 0);
  }

  get customerRef(): string {
    return this.items.asUniqueValue((s) => s.customerRef, OmsConstants.MULTIPLE_VALUE);
  }

  get customer(): Customer {
    return this.items.asUniqueValue((s) => s.customer, OmsConstants.MULTIPLE_VALUE);
  }

  get freightForwarder(): FreightForwarder {
    return this.items.asUniqueValue((s) => s.freightForwarder, OmsConstants.MULTIPLE_VALUE);
  }

  get hawb(): string {
    return this.items.asUniqueValue((s) => s.hawb, OmsConstants.MULTIPLE_VALUE);
  }

  get mawb(): string {
    return this.items.asUniqueValue((s) => s.mawb, OmsConstants.MULTIPLE_VALUE);
  }

  @Exclude()
  public findItem(orderId: number): ManifestItem {
    return this.items.find((mi) => mi.hasOrder(orderId));
  }

  @Exclude()
  public get isApproved(): boolean {
    return !!this.dateApprovedOn;
  }

  @Exclude()
  public get isStaging(): boolean {
    return absent(this.driver);
  }

  public enumerate() {
    let index = 0;
    this.items.forEach((i) => i.orderNumber = index++);
  }

  public update(updatePieces?: boolean) {
    if (updatePieces) {
      this.items.forEach((mi) => mi.update());
    }

    this.items.sort((i1, i2) => i1.orderNumber - i2.orderNumber);
    this.enumerate();
  }

  @Exclude()
  public getUpdatedLoads(): ManifestItem[] {
    return this.items.filter((mi) => mi.isUpdatedByDriver());
  }

  @Exclude()
  public getNotUpdatedLoads(): ManifestItem[] {
    return this.items.filter((mi) => !mi.isUpdatedByDriver());
  }

  @Exclude()
  public allLoadsUpdated(): boolean {
    return this.items && this.items.length && this.items.every((mi) => mi.isUpdatedByDriver());
  }

  @Exclude()
  public someLoadsUpdated(): boolean {
    return this.items && this.items.length && this.items.some((mi) => mi.isUpdatedByDriver());
  }

  @Exclude()
  public allTrailerTheSame(): boolean {
    let trailers = [];
    trailers = this.items.map((mi) => mi.trailer).unique();
    return trailers.length < 2;
  }

}
