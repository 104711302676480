import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Trailer} from '../../../modules/shared/models/trailer';
import {StringInputComponent} from '../../../modules/shared/components/common/input/string-input/string-input.component';
import {ReasonCode} from "../../../modules/shared/models/reason-code";

@Component({
  selector: 'app-reason-code-dialog',
  templateUrl: './reason-code-dialog.component.html',
  styleUrls: ['./reason-code-dialog.component.scss']
})
export class ReasonCodeDialogComponent {
  public readonly: boolean = false;
  public reasonCode: ReasonCode;
  @ViewChild('name') input: StringInputComponent;

  constructor(
    public dialogRef: MatDialogRef<ReasonCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.readonly = data.readonly;
    this.reasonCode = data.reasonCode;
  }

  onOk(event) {
    if (this.input.isInvalid || !this.reasonCode.name || this.reasonCode.name.length==0)  {
      this.input.touchControl();
      event.canClose = false;
    }
  }

}
