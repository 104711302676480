import {ChangeDetectorRef, Component, Inject, Input, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {OmsConstants} from '../../../../../../common/oms-constants.service';
import {ValueAccessorBase} from '../../../base/value-accessor';
import {MeasureUnit, WeightUnits} from '../../../../../../common/oms-unit-types';
import {checkNumber} from '../../../../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {Size} from '../../../../../../common/oms-types';

@Component({
  selector: 'oms-weight-input',
  templateUrl: './weight-input.component.html',
  styleUrls: ['./weight-input.component.css']
})

export class WeightInputComponent extends ValueAccessorBase<number> {
  Size = Size;
  @Input() label: string = 'Weight';
  @Input() size: Size = Size.DEFAULT;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() forceChangeUnits: boolean = false;
  @Input('show-label') showLabel: boolean = true;


  public units: MeasureUnit = this._constants.weightUnits.default;
  private inputValue: number; // value in visible units

  constructor(@Optional() @Self() @Inject(NgControl) private _ngControl: NgControl,
              private cdr: ChangeDetectorRef,
              public _constants: OmsConstants) {
    super();
    this._ngControl.valueAccessor = this;
  }

  private toDisplay(value) {
    return checkNumber(WeightUnits.fromDefault(value, this.units));
  }

  private toDefault(value) {
    return checkNumber(WeightUnits.toDefault(value, this.units));
  }

  get internal() {
    if (isNullOrUndefined(this.inputValue)) {
      this.inputValue = this.toDisplay(super.getValue());
    }
    return this.inputValue;
  }


  set internal(value) {
    if (this.inputValue !== value) {
      this.inputValue = value;
      super.setValue(this.toDefault(value));
    }
  }

  onUnitsChanged(event) {
    if (this.forceChangeUnits/* this.disabled || this.readonly*/) {
      // Assume that user looks at value - convert input value to selected units
      this.inputValue = this.toDisplay(super.getValue());
      this.cdr.detectChanges();
    } else {
      super.setValue(this.toDefault(this.inputValue));
      this.cdr.detectChanges();
    }
  }

  protected setValue(value: number) {
    this.inputValue = this.toDisplay(value);
    this.innerValue = value;
    this.cdr.detectChanges();
  }

  isDisplayError(): boolean {
    if (!this._ngControl || this._ngControl.valid) {
      return false;
    }
    if (this._ngControl.invalid && (this._ngControl.dirty || this._ngControl.touched)) {
      return true;
    }
    return false;
  }

}
