import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {DocumentType} from '../modules/shared/models/documents/document-type';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class DocumentTypeService extends BaseEntityService<DocumentType> {
  apiUrl = '/oms/document-type/';
  classType = DocumentType;

//  private list: Subject<DocumentType[]> = new BehaviorSubject<DocumentType[]>([]);

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
//    this.findAllObs().subscribe((res) => this.list.next(res));
  }
}
