/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slide-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./slide-panel.component";
import * as i4 from "@angular/router";
var styles_SlidePanelComponent = [i0.styles];
var RenderType_SlidePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SlidePanelComponent, data: { "animation": [{ type: 7, name: "left-pane", definitions: [{ type: 0, name: "left", styles: { type: 6, styles: { transform: "translateX(0)", opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "right", styles: { type: 6, styles: { transform: "translateX(-100%)", opacity: 0, display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: [{ type: 4, styles: null, timings: 300 }], options: null }], options: {} }, { type: 7, name: "right-pane", definitions: [{ type: 0, name: "left", styles: { type: 6, styles: { transform: "translateX(100%)", opacity: 0, display: "none" }, offset: null }, options: undefined }, { type: 0, name: "right", styles: { type: 6, styles: { transform: "translateX(0)", opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: [{ type: 4, styles: null, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_SlidePanelComponent as RenderType_SlidePanelComponent };
export function View_SlidePanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "panes"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "pane"]], [[24, "@left-pane", 0]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "pane"]], [[24, "@right-pane", 0]], null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "active": 0 }), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "pane"; var currVal_2 = _ck(_v, 3, 0, (_co.activePane === "left")); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = "pane"; var currVal_5 = _ck(_v, 7, 0, (_co.activePane === "right")); _ck(_v, 6, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activePane; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.activePane; _ck(_v, 5, 0, currVal_3); }); }
export function View_SlidePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-slide-panel", [], null, null, null, View_SlidePanelComponent_0, RenderType_SlidePanelComponent)), i1.ɵdid(1, 245760, null, 0, i3.SlidePanelComponent, [i1.ChangeDetectorRef, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SlidePanelComponentNgFactory = i1.ɵccf("oms-slide-panel", i3.SlidePanelComponent, View_SlidePanelComponent_Host_0, { activePane: "activePane" }, {}, ["[leftPane]", "[rightPane]"]);
export { SlidePanelComponentNgFactory as SlidePanelComponentNgFactory };
