import {Component, Input, OnInit} from '@angular/core';
import {FilterPageRequest} from "../../../../../modules/shared/models/filter.page.request";
import {FreightForwarderGroupService} from "../../../../../services/freight.forwarder.group.service";
import {CustomerService} from "../../../../../services/customer.service";
import {FreightForwarderService} from "../../../../../services/freight.forwarder.service";
import {FilterOptions} from "../report-filter/filter-options";
import {Customer, FreightForwarder} from "../../../../../modules/shared/models";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'pending-recovery-report-filters',
  templateUrl: './pending-recovery-report-filters.component.html',
  styleUrls: ['./pending-recovery-report-filters.component.scss']
})
export class PendingRecoveryReportFiltersComponent implements OnInit {
  public filterOptions: FilterOptions[] = [];
  public customer: Customer[];
  public freightForwarder: FreightForwarder[] = [];

  @Input() public filters: FilterPageRequest;

  constructor(public freightForwarderGroupService: FreightForwarderGroupService,
              public customerService: CustomerService,
              public freightForwarderService: FreightForwarderService) { }

  ngOnInit() {
    console.log('Loaded', this.filters);
    if (this.filters) {
      for (let filter of this.filters.filterByColumns) {
        if (filter) {
          console.log('FOUND', filter.field);

          if (filter.field === 'customerId') {
            this.customerService.getMany(filter.values.map(s => Number(s))).subscribe(
              (customer) => this.customer = customer
            );
          }

          if (filter.field === 'freightForwarderId') {
            if (filter.multipleValues) {
              this.freightForwarderService.getMany(filter.values.map(s => Number(s))).subscribe(
                (ff) => this.freightForwarder = ff
              );
            }
          }
        }
      }

      for (let options of this.filterOptions) {

        let filter = this.filters.getColumnFilter(options.field);

        if (filter) {
          if (!isNullOrUndefined(filter.value)) {
            options.search.search = filter.value;

            if (options.search.items) {
              options.search.selected = (options.search.items as any[]).find((item) => item.id === filter.value);
            }

          } else if (filter.multipleValues) {
            if (options.search.items) {
              options.search.selected =
                filter.multipleValues.map((id) => (options.search.items as any[]).find((item) => item.id === ~~id));
            }
          }
        }
      }
    }

  }

  onCustomerChange() {
    if (this.customer && this.customer.length) {
      this.filters.addColumnFilter('customerId', this.customer.map((c) => c.id));
    } else {
      this.filters.removeColumnFilter('customerId');
    }
  }

  onFreightForwarderChange() {
    if (this.freightForwarder && this.freightForwarder.length) {
      this.filters.addColumnFilter('freightForwarderId', this.freightForwarder.map((ff) => ff.id));
    } else {
      this.filters.removeColumnFilter('freightForwarderId');
    }
    console.log('>>>', this.filters);
  }


}
