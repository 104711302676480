import {BaseDeletable} from "../base-deletable";
import {Schedule} from "./schedule";
import {Transform, Type} from "class-transformer";
import {ReportRecipient} from "./report-recipient";
import {FilterPageRequest} from "../filter.page.request";
import {ReportExecutionStatus, transformReportExecutionStatus} from "./report-execution-status";

export class ScheduledReport extends BaseDeletable {
  public name: string;
  public active: boolean = true;
  public reportClass: string;
  @Type(() => Schedule)
  public schedule: Schedule = new Schedule();
  @Type(() => FilterPageRequest)
  public filters: FilterPageRequest = new FilterPageRequest(0, 0, null, {field: 'id', sortedAsc: false}, []);
  @Type(() => ReportRecipient)
  public recipients: ReportRecipient[] = [];
  @Type(() => Date)
  public dateLastExecutedOn: Date;

  @Transform((v, o, tt) => transformReportExecutionStatus(v, o, tt))
  public lastExecutedStatus: ReportExecutionStatus;

  public lastExecutedInfo: string;
  public lastExecutedUri: string;

  public customEmails: string;

  public html: boolean = false;
  public outputType: number = 0;
  public fields: ScheduledReportField[] = [];
}

export class ScheduledReportField {
  id?: number;
  reportFieldType: number;
  orderNumber: number;
}
