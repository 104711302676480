import {isNullOrUndefined} from 'util';

export class SearchRange {
  from: string | Date;
  to: string | Date;
  est: boolean;

  constructor(from: string | Date, to: string | Date, est: boolean) {
    this.from = from;
    this.to = to;
    this.est = est;
  }

  get isEmpty() {
    return isNullOrUndefined(this.from) && isNullOrUndefined(this.to);
  }

}
