﻿import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../services';
import {AllValidationErrors, getFormValidationErrors} from '../../_helpers/get-form-validation-errors';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
//    user: User = new User();
  warning: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false]
    });

    // reset login status
    // this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get userName() {
    return this.loginForm.get('username');
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      const error: AllValidationErrors = getFormValidationErrors(this.loginForm.controls).shift();
      if (error) {
        let text;
        switch (error.error_name) {
          case 'required':
            text = `${error.control_name} is required!`;
            break;
          case 'pattern':
            text = `${error.control_name} has wrong pattern!`;
            break;
          case 'email':
            text = `${error.control_name} has wrong email format!`;
            break;
          case 'minlength':
            text = `${error.control_name} has wrong length! Required length: ${error.error_value.requiredLength}`;
            break;
          case 'areEqual':
            text = `${error.control_name} must be equal!`;
            break;
          default:
            text = `${error.control_name}: ${error.error_name}: ${error.error_value}`;
        }
        this.warning = text;
      }
      /*          for (const field of Object.keys(this.loginForm.controls)) {
                  const control = this.loginForm.get(field);
                  if (!control.valid) {
                    for (const error in control.errors) {
                      error.error_name
                    }
                    this.warning += control.errors + '\n';
                  }
                } */
      return;
    }

    this.loading = true;
    this.authService.logIn(this.f.username.value, this.f.password.value, this.returnUrl)
      .then(() => {
        this.loading = true;
        this.warning = undefined;
      }).catch((error) => {
      this.loading = false;
      this.warning = 'Incorrect password or user name';//error.toString();
    });
  }
}
