import {BaseDeletable} from '../base-deletable';
import {Address} from './address';
import {Type, Exclude, Expose, plainToClass} from 'class-transformer';

export class CfsLocation extends BaseDeletable {
  public name: string;
  public isAms: boolean = false;
  @Type(()=>Address)
  public addressCfs?: Address;
}
