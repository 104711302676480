﻿import {VERSION} from 'src/environments/version';
import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OmsConstants} from '../../../../../common/oms-constants.service';
import {formatDate} from '@angular/common';
import {AuthService} from "../../../../../services";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'oms-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

  public version = VERSION;
  public environment: string;

  @ViewChild('footer')
  private footer: ElementRef<HTMLElement>;
//  public lastCommitDate: string;
  public dateStr: String;

  private unsubscribe$ = new Subject<void>();

  constructor(auth: AuthService) {
    auth.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((u) => {
        if (u) {
          this.environment = u.env;
        }
      });

    let user = auth.user$.value;
    if (user) {
      this.environment = user.env;
    }
  }

  getHeight(): number {
    return this.footer.nativeElement.getBoundingClientRect().height;
  }

  ngOnInit() {
    try {
      this.dateStr = formatDate(
        new Date(this.version['buildDate'] || this.version.commit['committerDate']),
        'dd MMM yyyy h:mm a', OmsConstants.DEFAULT_LOCALE);
    } catch (e) {
      this.dateStr = this.version.commit['committerDate'];
    }
  }

  get versionName() {
    if (this.version['branch'] !== 'HEAD') {
      return this.version['branch'];
    }
    return this.version.tag ? this.version.tag : '';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get resolution(): string {
    return window.innerWidth + ' x ' + window.innerHeight;
  }
}
