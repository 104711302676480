import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {DateTimeService} from "../../../services/date-time.service";
import {ScheduleType} from "../../../modules/shared/models/reporting/schedule";
import {ScheduledReport} from "../../../modules/shared/models/reporting/scheduled-report";
import {UserService} from "../../../modules/shared/services/user.service";
import {User} from "../../../modules/shared/models";
import {ReportRecipient} from "../../../modules/shared/models/reporting/report-recipient";
import {ReportColumn, ReportService, ReportType} from "../../../services/report.service";
import {FilterPageRequest} from "../../../modules/shared/models/filter.page.request";
import {absent, clamp, isEmptyString} from "../../../_helpers/utils";
import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";

class Container {
  constructor(public name: string, public columns: ReportColumn[]) {
  }
}

@Component({
  selector: 'oms-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportDialogComponent implements OnInit {

  available = new Container('Available fields', []);
  output = new Container('Output fields', []);

  containers: Array<Container> = [
    this.available,
    this.output
  ];

  public report: ScheduledReport;
  public ScheduleType = ScheduleType;

  public dayHoursItems: any[];
  public hourMinutesItems: any[] = [];
  public weekDaysItems: any[];

  public outputTypes = [
    {id: 0, label: 'Email Body'},
    {id: 1, label: 'Attachment'},
    {id: 2, label: 'Body & Attachment'},
    {id: 3, label: 'Download URL'}
    ];

  public userToAdd: User;
  @Input() readonly: boolean = false;
  reportTypes: ReportType[] = [];
  selectedReportType: ReportType;

  constructor(
    public alert: OmsAlertsService,
    public reportService: ReportService,
    public ts: DateTimeService,
    public cdr: ChangeDetectorRef,
    public tzService: DateTimeService,
    public userService: UserService,
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.report = data.report;

    let i = 0;
    this.dayHoursItems = this.ts.dayHours.map(n => ({id: i++, name: n}));

    for (let m = 0; m < 60; m++) {
      this.hourMinutesItems.push({id: m, name: m});
    }

    i = 0;
    this.weekDaysItems = this.ts.weekDays.map(n => ({id: i++, name: n}));
    if (!this.report.filters) {
      this.report.filters = FilterPageRequest.simpleFilters();
    }
  }

  ngOnInit() {
    this.reportService.getAvailableReports()
      .subscribe((reportTypes) => {
        this.reportTypes = reportTypes;
        if (this.report) {
          this.selectedReportType = reportTypes.find((rt) => rt.reportClass === this.report.reportClass);
        }

        if (!!this.selectedReportType) {
          this.loadAvailableColumns(this.selectedReportType.typeId);
        }
        this.cdr.markForCheck();
      });
  }

  tzSearch(search: string, item: any): boolean {
    return search && item && item.name && item.name.toLowerCase().includes(search.toLowerCase());
  }

  dayHoursSearch = (search, item) => {
    return item.name.includes(search);
  }

  private validate(report: ScheduledReport): boolean {
    if (absent(report.reportClass)) {
      this.alert.error("Report Type is missing");
      return false;
    }

    if (isEmptyString(report.name)) {
      this.alert.error("Report Name is missing");
      return false;
    }


    return true;
  }

  public onOk(event) {
    if (!this.validate(this.report)) {
      event.canClose = false;
    }


    let columns = this.output.columns.length
      ? this.output.columns
      : this.available.columns;
    this.report.fields = columns.map((c, i) => {
      return {
        reportFieldType: c.id,
        orderNumber: i
      };
    });
  }

  onUserAdd(user: User) {
    if (user) {
      let recipient = new ReportRecipient();
      recipient.user = user;
      recipient.enabled = true;
      this.report.recipients.push(recipient);
      this.userToAdd = null;
      this.cdr.markForCheck();
    }
  }

  removeRecipient(rec: ReportRecipient) {
    this.report.recipients.removeAll(rec);
  }

  public onReportClassChange(event: ReportType) {
    if (event) {
      if (this.data.report.reportClass !== event.reportClass) {
        this.data.report.reportClass = event.reportClass;
        if (this.data.report.isNew()) {
          this.data.report.name = event.label;
        }
        this.loadAvailableColumns(event.typeId);
      }
    } else {
      this.data.report.reportClass = event;
    }
  }

  public get isOrdersReport(): boolean {
    return this.report && this.report.reportClass &&
      (this.report.reportClass.endsWith("OrdersExcelReport")
        || this.report.reportClass.endsWith("DeliveredOrdersReport")
      );
  }

  // todo hardcoded (well, almost) note need ReportClassType enum
  public get isRoutesReport(): boolean {
    return this.report && this.report.reportClass &&
      (this.report.reportClass.endsWith("RoutesExcelReport")
        || this.report.reportClass.endsWith("RoutesMissingPodReport")
        || this.report.reportClass.endsWith("DispatchReport")
      );
  }

  public get isPendingRecoveryReport(): boolean {
    return this.report && this.report.reportClass &&
      this.report.reportClass.endsWith("PendingRecoveriesReport");

  }

  public get isMastersReport(): boolean {
    return this.report && this.report.reportClass &&
      (this.report.reportClass.endsWith("MasterExcelReport") || this.report.reportClass.endsWith("MastersOutstandingExcelReport"));
  }


  // todo hardcoded
  public get hasFilters(): boolean {
    return this.isOrdersReport || this.isRoutesReport || this.isMastersReport || this.isPendingRecoveryReport;
  }

  loadAvailableColumns(reportTypeId: string) {
    this.reportService.getAvailableColumns(reportTypeId)
      .subscribe((columns) => {
        this.available.columns = columns
          .filter(c => !this.report.fields
            .some(f => f.reportFieldType === c.id));

        this.output.columns = this.report.fields
          .sort((f1, f2) => f1.orderNumber - f2.orderNumber)
          .map(f => columns.find(c => c.id === f.reportFieldType));
        this.cdr.markForCheck();
      });
  }

  public validateMinutes() {
    this.report.schedule.minute = clamp(this.report.schedule.minute, 0, 59);
    this.cdr.markForCheck();
  }
}
