var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormUtils } from '../../../_helpers/form-utils';
import { AbstractComponent } from "../../../common/component/abstract.component";
var AbstractEntityDialogComponent = /** @class */ (function (_super) {
    __extends(AbstractEntityDialogComponent, _super);
    function AbstractEntityDialogComponent(dialogRef, itemService, alerts) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.itemService = itemService;
        _this.alerts = alerts;
        _this.edit = false;
        _this.hasFormErrors = false;
        _this.viewLoading = false;
        _this.loadingAfterSubmit = false;
        return _this;
    }
    AbstractEntityDialogComponent.prototype.isControlInvalid = function (controlName) {
        var control = this.form.controls[controlName];
        return control.invalid && control.touched;
    };
    AbstractEntityDialogComponent.prototype.onSubmit = function () {
        this.hasFormErrors = false;
        this.loadingAfterSubmit = false;
        var controls = this.form.controls;
        /** check form */
        if (this.form.invalid) {
            Object.keys(controls).forEach(function (controlName) {
                controls[controlName].markAsTouched();
            });
            this.hasFormErrors = true;
            return;
        }
        this.update(this.prepare());
    };
    AbstractEntityDialogComponent.prototype.update = function (item) {
        var _this = this;
        this.loadingAfterSubmit = true;
        this.viewLoading = true;
        this.itemService.save(item)
            .subscribe(function (res) {
            _this.viewLoading = false;
            _this.dialogRef.close({ res: res, isEdit: !item.isNew() });
        }, function (error) {
            _this.viewLoading = false;
            _this.alerts.danger(error);
        });
    };
    AbstractEntityDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    AbstractEntityDialogComponent.prototype.getControlErrorMessage = function (controlName, fieldName) {
        var control = this.form.controls[controlName];
        return FormUtils.getControlErrorMessage(control, fieldName);
    };
    return AbstractEntityDialogComponent;
}(AbstractComponent));
export { AbstractEntityDialogComponent };
