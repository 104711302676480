import { createNumberMask, emailMask } from 'text-mask-addons/dist/textMaskAddons';
import { ContainerSizes, CurrencyUnits, PackagingUnits, VolumeUnits, WeightUnits } from './oms-unit-types';
/*
 * Documentation: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 * create number mask addon:  https://github.com/text-mask/text-mask/tree/master/addons#createnumbermask
 */
export var WEIGHT_DECIMAL_DIGITS = 0;
export var tooltipConfig = { container: 'body', delay: 1000 };
var OmsConstants = /** @class */ (function () {
    function OmsConstants(weightUnits, volumeUnits, currencyUnits, packagingUnits, containerSizes) {
        this.weightUnits = weightUnits;
        this.volumeUnits = volumeUnits;
        this.currencyUnits = currencyUnits;
        this.packagingUnits = packagingUnits;
        this.containerSizes = containerSizes;
        this.ADDRESS_SEARCH_PLACEHOLDER = 'Enter Name and select';
        this.AIRLINE_SEARCH_PLACEHOLDER = 'Select Airline';
        this.CUSTOMER_REF_LENGTH = 20;
        // *** just testing
        this.dateMask = {
            mask: [/\d/, /\d/, '-', /[A-Z]/i, /[a-z]/i, /[a-z]/i, '-', /\d/, /\d/, /\d/, /\d/],
            pipe: function (value) { return value.toLowerCase(); },
            keepCharPositions: false
        };
        // */
        this.zipCodeMask = {
            mask: function (value) {
                value = value.replace(/[\W_]/g, '');
                if (value.length > 5) {
                    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
                }
                return [/\d/, /\d/, /\d/, /\d/, /\d/];
            },
            guide: true
        };
        this.piecesPlaceholder = '00';
        this.piecesMask = createNumberMask({
            prefix: '',
            suffix: ''
        });
        this.doubleMask = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true
        });
        this.weightPlaceholder = '0.00';
        this.weightMask = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true,
            decimalLimit: 2
        });
        this.volumePlaceholder = '0.00';
        this.volumeMask = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true,
            decimalLimit: 2
        });
        this.currencyPlaceholder = '0.00';
        this.currencyMask = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true,
            decimalLimit: 2
        });
    }
    Object.defineProperty(OmsConstants.prototype, "mawbMask", {
        get: function () {
            return OmsConstants.mawbMask;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmsConstants.prototype, "mawbPlaceholder", {
        get: function () {
            return OmsConstants.mawbPlaceholder;
        },
        enumerable: true,
        configurable: true
    });
    OmsConstants.mawbMask = { mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/], keepCharPositions: false };
    OmsConstants.mawbPlaceholder = '___-____ ____';
    OmsConstants.contNumberMask = { mask: [/[A-Z,a-z]/, /[A-Z,a-z]/, /[A-Z,a-z]/, /[A-Z,a-z]/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/], pipe: function (value) { return value.toUpperCase(); }, keepCharPositions: false };
    OmsConstants.contNumberPlaceholder = '____ ______-_';
    OmsConstants.hawbMask = { mask: [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/], keepCharPositions: false };
    OmsConstants.hawbPlaceholder = '___________';
    OmsConstants.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    OmsConstants.emailMask = emailMask;
    //  @Inject(LOCALE_ID) private _locale: string;
    OmsConstants.DEFAULT_LOCALE = 'en-US';
    OmsConstants.DATE_FORMAT = 'MM/dd/yyyy';
    OmsConstants.MOMENT_DATE_FORMAT = 'MM/DD';
    OmsConstants.MOMENT_DATE_FORMAT_WITHOUT_YEAR = 'MM/DD';
    OmsConstants.DEFAULT_TIME_ZONE = "EST";
    OmsConstants.DATE_TIME_FORMAT = 'MM/dd/yyyy h:mma z';
    OmsConstants.LONG_DATE_TIME_FORMAT = 'MMM D YYYY h:mmA';
    OmsConstants.MOMENT_DATE_TIME_FORMAT = 'MM/DD  hh:mmA';
    OmsConstants.MOMENT_DATE_YEAR_TIME_FORMAT = 'MM/DD/YYYY  hh:mmA';
    OmsConstants.MOMENT_DATE_TIME_ZONE_FORMAT = 'MM/DD  hh:mmA z';
    //  public static MOMENT_TIME_FORMAT = 'hh:mmA';
    OmsConstants.MOMENT_TIME_SHORT_FORMAT = 'HH:mm';
    //  public static STANDART_DATE_TIME_FORMAT = 'yyyy-MM-ddTHH:mm:ss';
    //  public static MOMENT_STANDART_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
    OmsConstants.DEFAULT_WEIGHT_UNITS = WeightUnits.WEIGHT_KG;
    OmsConstants.DEFAULT_VOLUME_UNITS = VolumeUnits.VOLUME_CFT;
    OmsConstants.EMPTY_VALUE = 'N/A';
    OmsConstants.MULTIPLE_VALUE = 'multiple';
    OmsConstants.VARIOUS_VALUE = 'various';
    OmsConstants.ETC_ZONE = 'America/New_York';
    OmsConstants.UTC_ZONE = 'Europe/London';
    OmsConstants.SARCONA_CFS_ID = 7680;
    OmsConstants.CFS_PROPERTIES = [{
            contactName: 'Ed Teta',
            address1: 'JJS Transportation & Distribution Co., Inc',
            address2: '145 Hook Creek Blvd',
            address3: 'Valley Stream NY 11581',
            sarcona: false,
        }, {
            contactName: 'Marlice Moore',
            address1: 'Sarcona Management Inc',
            address2: '10 North Avenue East',
            address3: 'Elizabeth, NJ 07201',
            sarcona: true,
        }];
    return OmsConstants;
}());
export { OmsConstants };
