import {Injectable} from '@angular/core';
import {RestService} from '../../../../services/rest.service';
import {HttpUtilsService} from '../../../../services/http-utils.service';
import {BaseEntityService} from '../../../../services/base/base-entity.service';
import {SpecialService} from "../../models/manifest/special-service";

@Injectable()
export class SpecialServiceService extends BaseEntityService<SpecialService> {

  protected apiUrl = '/oms/special-services/';
  protected classType = SpecialService;

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }
}
