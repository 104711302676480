import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TruckService } from '../../../services/truck-service';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { TrailerService } from '../../../services/trailer-service';
import { DriverService } from '../../../services/driver-service';
import { AddressService } from '../../../services';
import { Size } from '../../../common/oms-types';
import { Truck } from '../../../modules/shared/models';
import { Trailer } from '../../../modules/shared/models';
import { Driver } from '../../../modules/shared/models';
import { OmsConverters } from '../../../modules/shared/services/oms-converters.service';
import { DialogType, ModalResult, OmsDialogsService } from "../../common/oms-dialogs";
import { CustomSearchComponent } from "../../../modules/shared/components/common/input/custom-search/custom-search.component";
var MastersDispatchDialogComponent = /** @class */ (function () {
    function MastersDispatchDialogComponent(data, converter, cdr, dialogRef, addressService, dialogs, alerts, truckService, trailerService, driverService) {
        this.data = data;
        this.converter = converter;
        this.cdr = cdr;
        this.dialogRef = dialogRef;
        this.addressService = addressService;
        this.dialogs = dialogs;
        this.alerts = alerts;
        this.truckService = truckService;
        this.trailerService = trailerService;
        this.driverService = driverService;
        this.Size = Size;
        this.readonly = false;
    }
    MastersDispatchDialogComponent.prototype.ngOnInit = function () {
        if (this.data.masters && this.data.masters.length == 1) {
            this.data.pieces = this.data.masters[0].pcsToRecover;
            this.data.hu = this.data.masters[0].huToRecover;
        }
    };
    MastersDispatchDialogComponent.prototype.onCancelDispatch = function () {
        var _this = this;
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Cancel Dispatch?', 'The load will be deleted and all related data will be lost.\nContinue?').then(function (result) {
            switch (result.result) {
                case ModalResult.YES: {
                    _this.dialogRef.close({ result: ModalResult.DELETE });
                }
            }
        });
    };
    MastersDispatchDialogComponent.prototype.onOk = function (event) {
        event.canClose = this.validate();
    };
    MastersDispatchDialogComponent.prototype.onCreateTruck = function (number) {
        var _this = this;
        var truck = new Truck();
        truck.number = number;
        this.truckService.createEditAndSave(truck, this.readonly)
            .then(function (truck) { _this.data.truck = truck; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    MastersDispatchDialogComponent.prototype.onCreateTrailer = function (number) {
        var _this = this;
        var trailer = new Trailer();
        trailer.number = number;
        this.trailerService.createEditAndSave(trailer, this.readonly)
            .then(function (trailer) { _this.data.trailer = trailer; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    MastersDispatchDialogComponent.prototype.onCreateDriver = function () {
        var _this = this;
        var driver = new Driver();
        this.driverService.createEditAndSave(driver, this.readonly)
            .then(function (driver) { _this.data.driver = driver; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    MastersDispatchDialogComponent.prototype.allowDispatch = function (master) {
        return true; //master.nonAMS || !!master.date1C;
    };
    MastersDispatchDialogComponent.prototype.validate = function () {
        if (!this.data.driver) {
            this.alerts.warning('Please select Driver');
            return false;
        }
        /*    let m:Master[] = (this.data.masters as Master[]).filter(master=>!this.allowDispatch(master));
            if (m.length>0) {
              this.alerts.warning('All AMS masters must have 1C');
              return false;
            } */
        return true;
    };
    Object.defineProperty(MastersDispatchDialogComponent.prototype, "isMultiple", {
        get: function () {
            return this.data.masters && this.data.masters.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    return MastersDispatchDialogComponent;
}());
export { MastersDispatchDialogComponent };
