import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectTextDialogComponent} from "../logs/pages/orders-list/enter-text-dialog/select-text-dialog.component";
import {SelectEntityDialogComponent} from "../logs/pages/orders-list/select-entity-dialog/select-entity-dialog.component";
import {HomeComponent} from "./pages/home/home.component";
import {LeftSidebarComponent} from "./pages/home/left-sidebar";
import {HeaderComponent} from "./pages/home/header";
import {FooterComponent} from "./pages/home/footer";
import {RouterModule} from "@angular/router";
import {ContextMenuModule} from 'ngx-contextmenu';
import {BreadcrumbsComponent} from "./pages/home/breadcrumbs/breadcrumbs.component";
import {LeftMenuConfigService} from "./services/left.menu.config.service";
import {OmsAlertsComponent} from './components/oms-alerts/oms-alerts.component';
import {AddressService} from "./services/address.service";
import {AddressDialogComponent} from "./components/address-dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CityService} from "./services/city.service";
import {CountryService} from "./services/country.service";
import {RegionService} from "./services/region.service";
import {UsaStateService} from "./services/usa.state.service";
import {NgSelectComponent, NgSelectModule} from "@ng-select/ng-select";
import {DataTableComponent} from "./components/base/data-table/data-table.component";
import {DataTableColumnComponent} from "./components/base/data-table/data-table-column/data-table-column.component";
import {DataTableRowComponent} from "./components/base/data-table/data-table-row/data-table-row.component";
import {DataTableCellComponent} from "./components/base/data-table/data-table-cell/data-table-cell.component";
import {
  TableFilterPipe,
  TablePagingPipe,
  TableSortPipe,
  TableSortPipeChildren
} from './components/base/data-table/data-table.utils';
import {OmsScrollerDirective} from "./components/base/data-table/utils/oms-scroller.directive";
import {DateInputComponent} from "./components/common/input/date-input/date-input.component";
import {DateRangeInputComponent} from "./components/common/input/date-range-input/date-range-input.component";
import {DateTimePopupComponent} from "./components/common/input/date-time-popup/date-time-popup.component";
import {DateTimeRangePopupComponent} from "./components/common/input/date-time-range-popup/date-time-range-popup.component";
import {
  HighlightSearch, MasterNumberPipe, MawbNumberPipe,
  OmsDatePipe,
  OmsDateTimePipe,
  OmsDateTimeZonePipe,
  OmsWeightPipe, SafeHtmlPipe, SafeUrlPipe, OrderNumberPipe
} from './services/oms-converters.service';
import {BasePageComponent} from "./components/base/base-page/base-page.component";
import {SlidePanelComponent} from '../../components/common/slide-panel/slide-panel.component';
import {ClickOutsideModule} from "ng-click-outside";
import {OmsCheckboxComponent} from './components/oms-checkbox/oms-checkbox.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {OffClickDirective} from './components/offclick.directive';
import {NgxMaskModule} from 'ngx-mask';
import {TextMaskModule} from 'angular2-text-mask';
import {CustomSearchComponent} from "./components/common/input/custom-search/custom-search.component";
import {DndModule} from "ng2-dnd";
import {WeightInputComponent} from "./components/common/input/weight-input/weight-input.component";
import {VolumeInputComponent} from "./components/common/input/volume-input/volume-input.component";
import {StringInputComponent} from "./components/common/input/string-input/string-input.component";
import {SelectableTextAreaInputComponent} from "./components/common/input/selectable-text-area-input/selectable-text-area-input.component";
import {PiecesInputComponent} from "./components/common/input/pieces-input/pieces-input.component";
import {MaxCountInputComponent} from "./components/common/input/max-count-input/max-count-input.component";
import {SelectButtonComponent} from "./components/common/select-button/select-button.component";
import {FormSelectComponent, FormTextComponent, ValidationComponent} from './components/base/base-input/components';
import {ToggleSwitchComponent} from "./components/common/toggle-switch/toggle-switch.component";
import {UldInputComponent} from "./components/common/input/uld-input/uld-input.component";
import {UldsDialogComponent} from "../../components/common/ulds-dialog/ulds-dialog.component";
import {IfRoleDirective} from '../../_helpers/if-role.directive';
import {UserRoleService} from "./services/user-role.service";
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClipboardCopyService} from "./services/clipboard.copy.service";
import {WizardHeaderComponent} from "./pages/home/wizard-header/wizard-header.component";
import {WizardHeaderConfigService} from "./services/wizard.header.config.service";
import {CbpStatusService} from "./services/stb-status/cbp.status.service";
import {CustomerDeliveryLocationService} from '../../services/customer-delivery-location.service';
import {OnInitDirective} from './components/on-init.directive';
import {CarrierService} from './services/carrier.service';
import {CfsLocationService} from '../../services/cfs-location.service';
import {MawbTrackService} from "./services/mawb-track/mawb.track.service";
import {MawbTableCellComponent} from "./components/base/data-table/data-table-cell/mawb-table-cell/mawb-table-cell.component";
import {MasterFsrService} from "./services/master-fsr/master.fsr.service";
import {DispatchService} from "./services/dispatch/dispatch.service";
import {CommentDialogComponent} from "./components/comment-dialog/comment-dialog.component";
import {OmsWidgetComponent} from "../../components/common/oms-widget/oms-widget.component";
import {MatIconModule, MatProgressSpinnerModule, MatSelectModule} from '@angular/material';
import {BaseDialogComponent} from "../../components/common/oms-dialogs/base-dialog/base-dialog.component";
import {OmsDialogsService} from "../../components/common/oms-dialogs";
import {EditingTableCellComponent} from "./components/base/data-table/data-table-cell/editing-table-cell/editing-table-cell.component";
import {ColumnsConfigurationComponent} from '../../components/common/columns-configuration';
import {NgDragDropModule} from 'ng-drag-drop';
import {FillIndicatorComponent} from '../../components/common/fill-indicator/fill-indicator.component';
import {DialogDraggableTitleDirective} from '../../components/common/oms-dialogs/utils/dialog-draggable-title.directive';
import {MasterEditorComponent} from "../../pages/masters/master-editor/master-editor.component";
import {
  LoadTableColumnsFilter,
  MasterImportAirComponent,
  OrderTableColumnsFilter,
  PartsBySelectedLoadFilter
} from "../../pages/masters/master-editor/master-import-air/master-import-air.component";
import {AddressSearchComponent} from "../../components/common/address-search";
import {AirportSearchComponent} from "../../components/common/airport-search";
import {OmsColumns} from "../../common/oms-columns.service";
import {OmsConstants, tooltipConfig} from "../../common/oms-constants.service";
import {GlobalSearchService} from "../../services/global-search.service";
import {OrderEditorComponent} from "../../pages/orders/order-editor/order-editor.component";
import {GenericOrderFormComponent} from "../../pages/orders/order-editor/generic-order-form/generic-order-form.component";
import {FreightForwarderSearchComponent} from "../../components/common/freight-forwarder-search/freight-forwarder-search.component";
import {CustomerDialogComponent} from "../../components/common/customer-dialog/customer.dialog.component";
import {FreightForwarderDialogComponent} from "../../components/common/freight-forwarder-dialog";
import {CustomerSearchComponent} from "../../components/common/customer-search";
import {MawbNumberValidator} from "../../common/validators/mawb-number.validator";
import {AgmCoreModule} from "@agm/core";
import {DriverGpsService} from "../../services/driver-gps-service";
import {AddressNewService} from "../../services/address-new-service";
import {AddressMapInfoDialogComponent} from "./components/address-map-info-dialog/address-map-info-dialog.component";
import {ChatDialogComponent} from "./components/chat-dialog/chat-dialog.component";
import {DriverNewService} from "../../services/driver-new-service";
import {ChatService} from "../../services/chat-service";
import {UldLinkedService} from "./services/uld.linked.service";
import {AlertModule} from "ngx-bootstrap/alert";
import {TooltipConfig, TooltipModule} from "ngx-bootstrap/tooltip";
import {BsDatepickerModule, DatepickerModule} from "ngx-bootstrap/datepicker";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {OrdersTableComponent} from '../../pages/masters/masters-list/orders-table/orders-table.component';
import {CntNumberValidator} from "../../common/validators/cnt-number.validator";
import {HexadecimalValueValidator, MaxCountValidator, MinCountValidator} from "../../common/validators";
import {UldNumberValidator} from "../../common/validators/uld-number.validator";
import {OmsSpinnerComponent} from "../../components/common/oms-spinner/oms-spinner.component";

import {MastersTableComponent} from "../../pages/masters/masters-list/masters-table/masters-table.component";
import {ManifestCreateContentComponent} from "../dispatch/pages/manifest-create/manifest-create-content.component";
import {ManifestCreatePanelComponent} from "../dispatch/pages/manifest-create-panel/manifest-create-panel.component";
import {EditableCellComponent} from "../dispatch/pages/manifest-create/editable-cell/editable-cell.component";
import {SpecialServiceService} from "./services/dispatch/special-service.service";
import {ShipmentListComponent} from "../dispatch/pages/shipment-list/shipment-list.component";
import {OrdersListComponent} from "../logs/pages/orders-list/orders-list.component";
import {SplitOrderDialogComponent} from "../dispatch/pages/manifest-create/split-order-dialog/split-order-dialog.component";
import {CarrierSearchComponent} from './components/common/input/search/carrier-search/carrier-search.component';
import {RestrictedDirective} from "../../_helpers/restricted.directive";
import {DriverRoutesLoadsTableCellComponent} from "./components/base/data-table/data-table-cell/driver-routes-loads-table-cell/driver-routes-loads-table-cell.component";
import {OmsTabsComponent} from './components/common/oms-tabs/oms-tabs.component';
import {OmsTabDirective} from './components/common/oms-tabs/oms-tab.directive';
import {OmsTabButtonDirective} from './components/common/oms-tabs/oms-tab-button.directive';
import {CarrierDialogComponent} from "../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import {LoadStatusFilterComponent} from "../../components/common/load-status-filter/load-status-filter.component";
import {ManifestItemContextMenuComponent} from "../dispatch/pages/manifest-create/manifest-item-context-menu/manifest-item-context-menu.component";
import {PopoverModule} from "ngx-bootstrap";
import {OrderSubscriptionDialogComponent} from "../logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import {OrderSubscriptionService} from "../../services/order-subscription/order-subscription.service";
import {DynamicInjectorComponent} from "./components/oms-alerts/dynamic-injector/dynamic-injector.component";
import {DispatchUtils} from "../../_helpers/dispatch-utils";
import {FilterItemsComponent} from "../logs/pages/orders-list/filter-items/filter-items.component";
import {BillingInfoListDialogComponent} from "../../pages/orders/order-editor/billing-info-list-dialog/billing-info-list-dialog.component";
import { DateBatteryTableCellComponent } from "./components/base/data-table/data-table-cell/date-battery-table-cell/date-battery-table-cell.component";
import {DocumentPreviewDialogComponent} from "../../components/common/oms-dialogs/document-preview-dialog/document-preview-dialog.component";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {DropZoneDirective} from "./components/drop-zone.directive";
import {OmsMapsService} from "../../services/oms-maps.service";
import {AngularSplitModule} from "angular-split";
import { DriverSearchComponent } from './components/common/input/search/driver-search/driver-search.component';
import {OmsCameraComponent} from "../../components/common/oms-camera/oms-camera.component";
import {WebcamModule} from "ngx-webcam";
import {LoadPickupComponent} from "../../components/common/load-pickup/load-pickup.component";
import {CurrencyInputComponent} from "./components/common/input/currency-input/currency-input.component";
import {CameraData, DocumentCameraPageComponent} from "./pages/document-camera-page/document-camera-page.component";
import {DocCenterPageComponent} from "../../components/common/doc-center/doc-center-page/doc-center-page.component";
import {DocumentPreviewComponent} from "../../components/common/doc-center/document-preview/document-preview.component";
import {DocCenterDialogComponent} from "../../components/common/doc-center/doc-center-dialog";
import {DocCenterComponent} from "../../components/common/doc-center/doc-center.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

let omsSharedPipes =
  [OmsDatePipe, OmsDateTimePipe, OmsDateTimeZonePipe, OmsWeightPipe,
   MawbNumberPipe, MasterNumberPipe, OrderNumberPipe, TableSortPipe, TablePagingPipe, TableFilterPipe];

let omsSharedComponents = [
  DataTableComponent, SlidePanelComponent, HighlightSearch,
  OmsScrollerDirective,
  DateInputComponent,
  BasePageComponent,
  OmsWidgetComponent,
  OmsCheckboxComponent,
  OmsTabsComponent, OmsTabDirective,
  OnInitDirective,
  OffClickDirective,
  DropZoneDirective,
  IfRoleDirective, RestrictedDirective,
  ValidationComponent,
  WeightInputComponent,
  VolumeInputComponent,
  CurrencyInputComponent,
  StringInputComponent,
  SelectableTextAreaInputComponent,
  PiecesInputComponent,
  UldInputComponent,
  MaxCountInputComponent,
  SelectButtonComponent,
  ToggleSwitchComponent,
  CustomSearchComponent,
  BaseDialogComponent,
  ColumnsConfigurationComponent,
  DialogDraggableTitleDirective,
  FormTextComponent,
  FormSelectComponent,

  // todo move to doc-center Module
  [DocCenterComponent, DocumentPreviewDialogComponent, DocCenterPageComponent, DocCenterDialogComponent, DocumentPreviewComponent],

  AddressSearchComponent,
  AirportSearchComponent,
  LoadTableColumnsFilter,
  OrderTableColumnsFilter,
  PartsBySelectedLoadFilter,
  CustomerSearchComponent,
  CustomerDialogComponent,
  FreightForwarderSearchComponent,
  FreightForwarderDialogComponent,
  MaxCountValidator,
  MinCountValidator,
  HexadecimalValueValidator,
  UldNumberValidator,

  MasterEditorComponent,
  MasterImportAirComponent,

  OrderEditorComponent,
  GenericOrderFormComponent,
  OrdersTableComponent,
  OmsSpinnerComponent,
  MastersTableComponent,
  ManifestCreateContentComponent,
  ManifestCreatePanelComponent,
  CarrierDialogComponent,
  SplitOrderDialogComponent,
  OrdersListComponent,
  ShipmentListComponent,
  EditableCellComponent,
  LoadStatusFilterComponent,
  ManifestItemContextMenuComponent,
  OrderSubscriptionDialogComponent,
  DynamicInjectorComponent,
  FilterItemsComponent,
  BillingInfoListDialogComponent, SafeHtmlPipe, SafeUrlPipe,
  OmsCameraComponent,
  LoadPickupComponent
];

@NgModule({
  declarations: [
    OmsAlertsComponent,
    HomeComponent,
    DocumentCameraPageComponent,
    LeftSidebarComponent,
    HeaderComponent,
    FooterComponent,
    WizardHeaderComponent,
    BreadcrumbsComponent,
    AddressDialogComponent,
    AddressMapInfoDialogComponent,
    CommentDialogComponent,
    ChatDialogComponent,

    DataTableColumnComponent,
    DataTableCellComponent,
    MawbTableCellComponent,
    DriverRoutesLoadsTableCellComponent,
    DateBatteryTableCellComponent,
    EditingTableCellComponent,
    DataTableRowComponent,
    DateRangeInputComponent,
    DateTimePopupComponent,
    DateTimeRangePopupComponent,

    TableSortPipeChildren,
    SafeUrlPipe, SafeHtmlPipe, OmsDatePipe, SlidePanelComponent, HighlightSearch, OmsWeightPipe, MawbNumberPipe,
    TableSortPipe, TablePagingPipe, TableFilterPipe,

    UldsDialogComponent,
    FillIndicatorComponent,

    MawbNumberValidator,
    CntNumberValidator,

    CarrierSearchComponent, DriverSearchComponent,
    SelectEntityDialogComponent,
 //   OrderWarehouseListComponentOld,
    SelectEntityDialogComponent,
    SelectTextDialogComponent,
    OmsTabButtonDirective,

    ...omsSharedComponents,
    ...omsSharedPipes
  ],
  entryComponents: [
    AddressDialogComponent,
    AddressMapInfoDialogComponent,
    CommentDialogComponent,
    ChatDialogComponent,
    BreadcrumbsComponent,
    FormTextComponent,
    FormSelectComponent,
    OmsCheckboxComponent,
    OmsWidgetComponent,
    UldsDialogComponent,
    MawbTableCellComponent,
    DriverRoutesLoadsTableCellComponent,
    DateBatteryTableCellComponent,
    BaseDialogComponent,
    EditingTableCellComponent,
    OrdersListComponent,
    SplitOrderDialogComponent,
    SelectEntityDialogComponent,
 //   OrderWarehouseListComponentOld,
    SelectTextDialogComponent,
    CarrierDialogComponent,
    OrderSubscriptionDialogComponent,
    BillingInfoListDialogComponent,

    // todo move to doc-center Module
    [DocumentPreviewDialogComponent, DocCenterPageComponent, DocCenterDialogComponent, DocumentPreviewComponent],
  ],
  imports: [
    PerfectScrollbarModule,
//    PickerModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    ClickOutsideModule,
    AlertModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(), TimepickerModule.forRoot(), DatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    DndModule.forRoot(),
    ContextMenuModule.forRoot(),
    AgmCoreModule,
    CollapseModule,
    NgDragDropModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ClickOutsideModule,
    AngularSplitModule.forChild(),
    PopoverModule,
    PdfViewerModule,
    WebcamModule,

    /*
        FeatherModule.pick(allIcons)
    */

//    ClipboardModule,
  ],
  providers: [
    AddressService,
    CameraData,
    GlobalSearchService,
    CfsLocationService,
    CityService,
    CountryService,
    RegionService,
    UldLinkedService,
    UsaStateService,
    OmsConstants, OmsColumns,
    OmsMapsService,
    { provide: TooltipConfig, useFactory: () => Object.assign(new TooltipConfig(), tooltipConfig)},
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },

    LeftMenuConfigService,
    WizardHeaderConfigService,
    CityService,
    CountryService,
    RegionService,
    UsaStateService,
    DispatchService, DispatchUtils,
    CbpStatusService,
    MawbTrackService,
    MasterFsrService,
    OmsScrollerDirective,
    OmsDialogsService,
    OffClickDirective,
    OnInitDirective,
    DropZoneDirective,
    IfRoleDirective, RestrictedDirective,
    ClipboardCopyService,
    DriverGpsService,
    AddressNewService,
    DriverNewService,
    ChatService,
    UserRoleService,
    CustomerDeliveryLocationService,
    CarrierService,
    DialogDraggableTitleDirective,
    SpecialServiceService,
    OrderSubscriptionService
  ],
    exports: [
        FormsModule,
        NgDragDropModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        NgSelectComponent,

        ...omsSharedComponents,
        ...omsSharedPipes,
        CarrierSearchComponent
    ],
  schemas: []
})

export class SharedModule {

}
