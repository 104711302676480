import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Size} from '../../../../../../common/oms-types';
import {convertDate, convertDateTime, convertDateWithoutYear} from "../../../../services/oms-converters.service";
import {animations} from "../../../base/base-input/animations";
import {BaseInputComponent} from "../../../base/base-input/base-input.component";
import {SearchRange} from "../../../../models/search.range";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'oms-date-range-input',
  animations,
  styleUrls: ['./date-range-input.component.scss'],
  templateUrl: './date-range-input.component.html',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: DateRangeInputComponent, multi: true}]
})
export class DateRangeInputComponent extends BaseInputComponent<any> implements OnInit {
  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input: ElementRef;
  @Output('changing') changingEvent = new EventEmitter();

  Size = Size;
  @Input() size: Size = Size.DEFAULT;
//  @Input() label:string;
  @Input() time: boolean = true;
  @Input() public minDate: Date = null;
  @Input() public maxDate: Date = null;
  @Input('show-button') showButton: boolean = true;
  @Input('show-clear-button') showClearButton: boolean = false;
  @Input() cleanable: boolean = true;
  @Input() showNaButton: boolean = false;
  @Output() onNaButton: EventEmitter<any> = new EventEmitter<any>();
  public fromDate: Date = null;
  public toDate: Date = null;
  public est: boolean = false;
  public isEdit: boolean = false;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>, private cdr: ChangeDetectorRef) {
    super(validators, asyncValidators);
  }

  onCbClick() {
    if (this.fromDate) {
      this.fromDate = null;
    } else {
      this.isEdit = true;
    }
  }

  changeEventTrigger(event: SearchRange) {
    this.value = undefined;
    this.fromDate = event.from as Date;
    this.toDate = event.to as Date;
    this.est = isNullOrUndefined(event.est) ? false : event.est;
    this.changingEvent.emit(new SearchRange(event.from, event.to, this.est));
  }


  onPopupShow(event) {
  }

  ngOnInit() {
  }

  get displayValue() {
    return typeof this.value === 'string' ? this.value :
        this.valueIsNotEmpty(this.fromDate) && this.valueIsNotEmpty(this.toDate) ?
          this.displayFromTo : this.valueIsNotEmpty(this.fromDate) ? convertDate(this.fromDate) : '';
  }

  valueIsNotEmpty(value: Date) {
    return !isNullOrUndefined(value) && value.toString().length > 0;
  }

  get displayFromTo() {
    return convertDateWithoutYear(this.fromDate) + " - " + convertDateWithoutYear(this.toDate);
  }

  get realLeftPosition() {
    return this.isEdit ? this.input.nativeElement.getBoundingClientRect().left : 0;
  }

  get realTopPosition() {
    return this.isEdit ? this.input.nativeElement.offsetHeight : 0;
  }

  isDisplayClearButton(): boolean {
    if (!this.showClearButton || !this.cleanable) {
      return false;
    }
    let v = this.value;
    return v && !v.isEmpty;
  }

  public onNaButtonClick(event) {
    this.value = 'N/A';
    this.fromDate = undefined;
    this.toDate = undefined;
    this.onNaButton.emit(event);
  }

  writeValue(value: SearchRange) {
    super.writeValue(value);
    if (value) {
      this.fromDate = value.from as Date;
      this.toDate = value.to as Date;
      this.est = isNullOrUndefined(value.est) ? false : value.est;
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.est = false;
    }
  }
}
