﻿import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomerGroupService} from "../../../services/customer.group.service";
import {CustomerGroup} from "../../../modules/shared/models/customer.group";


@Component({templateUrl: 'customer.group.dialog.component.html',
            styleUrls: ['./customer.group.dialog.component.scss'],
            selector: 'oms-customer-group-dialog'})
export class CustomerGroupDialogComponent implements OnInit {

  customerGroup: CustomerGroup;

  form: FormGroup;

  hasFormErrors: boolean = false;
  viewLoading: boolean = false;
  loadingAfterSubmit: boolean = false;

  constructor(public dialogRef: MatDialogRef<CustomerGroupDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public customerGroupService: CustomerGroupService
  ) { }

  ngOnInit() {
    this.viewLoading = true;
    this.customerGroup = this.data.customerGroup;

    this.createForm();

    this.viewLoading = false;

  }

  createForm() {
    this.form = this.fb.group({
      name: [this.customerGroup.name, Validators.required],
    });
  }

  getTitle(): string {
    if (this.customerGroup.id > 0) {
      return 'Edit Customer Group';
    }
    return 'New Customer Group';
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }


  onSubmit() {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;
    const controls = this.form.controls;
    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const customer = this.prepare();
    if (customer.id > 0) {
      this.update(customer);
    } else {
      this.create(customer);
    }
  }

  prepare(): CustomerGroup {
    const controls = this.form.controls;
    const _customerGroup = new CustomerGroup();
    _customerGroup.id = this.customerGroup.id;
    _customerGroup.name = controls['name'].value;
    return _customerGroup;
  }

  update(_customerGroup: CustomerGroup) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.customerGroupService.update(_customerGroup).subscribe(res => {
      /* Server loading imitation. Remove this on real code */
      this.viewLoading = false;
      this.viewLoading = false;
      this.dialogRef.close({
        _customerGroup,
        isEdit: true
      });
    });
  }



  create(_customerGroup: CustomerGroup) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.customerGroupService.create(_customerGroup).subscribe(res => {
      this.viewLoading = false;
      this.dialogRef.close({
        res,
        isEdit: false
      });
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }


}
