var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Address, Customer, CustomsClearance, FreightForwarder, Load, LoadType, MasterAir, MasterShipment, MasterShipmentAir, MasterStatusId, Order, Problem, ShipmentContent, Split, RecoveryOrder, Uld } from '..';
import { Exclude, Transform, Type } from 'class-transformer';
import { isNullOrUndefined } from 'util';
import { assigned, checkNumber, equals, nextLetter } from '../../../../_helpers/utils';
import { addHours, getPendingStatus, latest, latestObjectByDate, maxDate, minDate, PendingStatus, subtractHours } from '../../../../common/oms-date-time.utils';
import { OmsConstants } from '../../../../common/oms-constants.service';
import { FillStatus } from '../../../../components/common/fill-indicator/fill-indicator.component';
import { convertMasterNumber, convertMawbStatus } from '../../services/oms-converters.service';
import { Shipment } from '../shipment';
import { BaseDeletable } from "../base-deletable";
import { MasterMode, MasterModes } from "./master-mode";
import { transformOrderMode } from "../order/order-mode";
import { AfterLoad, BeforeSave } from "../../../../services/oms-decorators";
import { CbpStatus } from "../cbp.status";
import { StringUtil } from "../../../../_helpers/string.util";
export function isMaster(any) {
    return any instanceof Master;
}
export var ProblemStatus;
(function (ProblemStatus) {
    ProblemStatus[ProblemStatus["NONE"] = 0] = "NONE";
    ProblemStatus[ProblemStatus["PROBLEM"] = 1] = "PROBLEM";
    ProblemStatus[ProblemStatus["RESOLVED"] = 2] = "RESOLVED";
})(ProblemStatus || (ProblemStatus = {}));
var Part = /** @class */ (function () {
    function Part() {
        this.rowId = 'Part';
    }
    Object.defineProperty(Part.prototype, "splitNumber", {
        get: function () {
            return this.content.shipment.load ? this.content.shipment.load.splitNumber : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "id", {
        get: function () {
            return this.order.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "loadId", {
        get: function () {
            return this.load ? this.load.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "orderId", {
        get: function () {
            return this.order ? this.order.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "mawbNumber", {
        get: function () {
            return this.master.mawbNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "hawb", {
        get: function () {
            return this.order.hawb;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "customer", {
        get: function () {
            return this.order.customer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "huOrder", {
        get: function () {
            return this.order.hu;
        },
        set: function (value) {
            this.order.hu = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "pcsOrder", {
        get: function () {
            return this.order.pieces;
        },
        set: function (value) {
            this.order.pieces = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "pieces", {
        get: function () {
            return this.content.pieces || null;
        },
        set: function (value) {
            this.content.pieces = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "weight", {
        get: function () {
            return this.content.weight;
        },
        set: function (value) {
            this.content.weight = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "volume", {
        get: function () {
            return this.content.volume;
        },
        set: function (value) {
            this.content.volume = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "hu", {
        get: function () {
            return this.content.hu;
        },
        set: function (value) {
            this.content.hu = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "dateCfsInAct", {
        get: function () {
            return this.order.dateCfsInAct;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "dateCfsInEst", {
        get: function () {
            return this.order.dateCfsInEst;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "dateDeliveryAct", {
        /*  get dateCfsOut():Date {
            return this.order.dateCfsOut;
          } */
        get: function () {
            return this.order.dateDeliveryAct;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "dateDeliveryReq", {
        get: function () {
            return this.order.dateDeliveryReq;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "load", {
        get: function () {
            return this.content.shipment.load;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Part.prototype, "noCfs", {
        get: function () {
            return this.order && this.order.noCfs;
        },
        enumerable: true,
        configurable: true
    });
    return Part;
}());
export { Part };
var ɵ0 = function () { return MasterShipment; }, ɵ1 = function () { return MasterAir; }, ɵ2 = function () { return CustomsClearance; }, ɵ3 = function () { return Customer; }, ɵ4 = function () { return FreightForwarder; }, ɵ5 = function () { return Order; }, ɵ6 = function () { return Order; }, ɵ7 = function () { return Date; }, ɵ8 = function () { return Date; }, ɵ9 = function () { return Date; }, ɵ10 = function () { return Date; }, ɵ11 = function () { return Date; }, ɵ12 = function () { return Date; }, ɵ13 = function () { return Problem; }, ɵ14 = function () { return Uld; }, ɵ15 = function (v, o, tt) { return transformOrderMode(v, o, tt); }, ɵ16 = function () { return CbpStatus; };
var Master = /** @class */ (function (_super) {
    __extends(Master, _super);
    function Master() {
        var _this = _super.call(this) || this;
        _this.rowId = 'Master';
        _this.javaClassName = 'com.oms.entity.master.Master';
        _this.masterShipments = [];
        _this.customsClearance = new CustomsClearance();
        _this.orders = [];
        _this.recoveryOrders = [];
        _this._dateCfsOutEst = undefined;
        _this.shipments = [];
        _this.ulds = [];
        _this.isAcknowledged = false;
        _this.isReAcknowledged = false;
        _this.direct = false;
        _this.nonAMS = false;
        _this.cbpStatuses = [];
        _this.dateCreated = new Date();
        return _this;
    }
    // todo move to server side?
    Master.newMaster = function (type) {
        // 1. Create master
        var master = new Master();
        master.masterAir = new MasterAir();
        master.status = MasterStatusId.NEW;
        master.genericMode = type ? MasterModes.valueOf(type) : MasterMode.AIR_IMPORT;
        // 2. Create Virtual order
        var order = new Order();
        master.orders.push(order);
        order.master = master;
        // 3. Create Master Shipment
        var content = new ShipmentContent();
        content.order = order;
        content.shipment = new Shipment();
        content.shipment.shipmentContents.push(content);
        content.shipment.master = master;
        master.masterShipments = [];
        var masterShipment = new MasterShipment();
        masterShipment.masterShipmentAir = new MasterShipmentAir();
        masterShipment.shipment = content.shipment;
        master.masterShipments.push(masterShipment);
        order.shipmentContents.push(content);
        // 4. Create Recovery Load Shipment
        /*    content = new ShipmentContent();
            content.shipment = new Shipment();
            content.shipment.load = new Load();
            content.shipment.load.loadType = LoadType.RECOVERY;
            content.shipment.load.shipment = content.shipment;
            content.order = order;
            order.shipmentContents.push(content);*/
        // 5. Create Delivery Load Shipment
        /*    content = new ShipmentContent();
            content.shipment = new Shipment();
            content.shipment.load = new Load();
            content.shipment.load.loadType = LoadType.DELIVERY;
            content.shipment.load.shipment = content.shipment;
            content.order = order;
            order.shipmentContents.push(content);*/
        return master;
    };
    Master.prototype.reset = function () {
        this._parts = undefined;
        this._splits = undefined;
        this._dateCfsOutEst = undefined;
    };
    Object.defineProperty(Master.prototype, "isOSD", {
        get: function () {
            return checkNumber(this.shortageNotes, 0) + checkNumber(this.overageNotes, 0) + checkNumber(this.damageNotes, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "masterStatus", {
        get: function () {
            return convertMawbStatus(this.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "osdNotes", {
        get: function () {
            return this.overageNotes + this.shortageNotes + this.damageNotes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "mawbNumber", {
        get: function () {
            return this.masterAir ? this.masterAir.mawbNumber : null;
        },
        set: function (mawb) {
            if (assigned(this.masterAir)) {
                this.masterAir.mawbNumber = mawb;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "hawb", {
        get: function () {
            return this.orders.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "shipment", {
        get: function () {
            return this.masterShipments && this.masterShipments.length === 1 ? this.masterShipments[0].shipment : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "firstShipment", {
        get: function () {
            return this.masterShipments.length > 0 ? this.masterShipments[0] : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "cargoBuildingAddressWithAirport", {
        get: function () {
            var airportAbbr = isNullOrUndefined(this.airport) ? '' : this.airport.iataCode;
            var cargo = this.shipment.addressDelivery;
            var showAs = isNullOrUndefined(cargo) || isNullOrUndefined(cargo.showAs) ? '' : cargo.showAs.toLocaleUpperCase() + ':';
            var address = new Address();
            // address.id = this.shipment.addressDelivery.id;
            address.name = showAs + airportAbbr;
            return address;
        },
        set: function (address) {
            this.cargoBuilding = address;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "hu", {
        // Sum of Orders HU
        get: function () {
            return this.orders.aggregate(function (acc, o) { return acc + ~~o.hu; }, 0) || null;
        },
        set: function (hu) {
            if (this.orders && this.orders.length === 1) {
                this.orders[0].hu = hu;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "pcsReceived", {
        // Sum of Orders pieces delivered to CFS
        get: function () {
            return this.orders.aggregate(function (acc, o) { return acc + ~~o.pcsReceived; }, 0) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "huReceived", {
        // Sum of Orders pieces delivered to CFS
        get: function () {
            return this.orders.aggregate(function (acc, o) { return acc + ~~o.huReceived; }, 0) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "hu_", {
        get: function () {
            return this.huReceived || this.hu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "pieces", {
        // Sum of Orders pieces
        get: function () {
            return this.orders.aggregate(function (acc, o) { return acc + ~~o.pieces; }, 0) || null;
        },
        set: function (pcs) {
            if (this.orders && this.orders.length === 1) {
                this.orders[0].pieces = pcs;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "pcsArrived", {
        // Sum of Orders pieces
        get: function () {
            return this.orders.aggregate(function (acc, o) {
                return acc + ~~o.pcsArrived;
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "weight", {
        get: function () {
            return this.orders.aggregate(function (acc, o) { return acc + +o.weight; }, 0) || null;
        },
        set: function (weight) {
            if (this.orders && this.orders.length === 1) {
                this.orders[0].weight = weight;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "volume", {
        get: function () {
            return this.orders.aggregate(function (acc, o) { return acc + +o.volume; }, 0) || null;
        },
        set: function (value) {
            if (this.orders && this.orders.length === 1) {
                this.orders[0].volume = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "masterNumber", {
        get: function () {
            return this.id ? convertMasterNumber(this.id) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateArrivalAct", {
        get: function () {
            var date = null;
            return this.masterShipments.some(function (ms) { return isNullOrUndefined(date = maxDate(date, ms.shipment.dateDeliveryActual)); }) ? null : date;
        },
        set: function (date) {
            if (!equals(date, this.dateArrivalAct)) {
                this.masterShipments.forEach(function (ms) {
                    ms.shipment.dateDeliveryActual = date;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateArrivalEst", {
        get: function () {
            var date;
            return this.masterShipments.some(function (ms) { return isNullOrUndefined(date = maxDate(date, ms.shipment.dateDeliveryExpectation)); }) ? null : date;
        },
        set: function (date) {
            this.masterShipments.forEach(function (ms) {
                ms.shipment.dateDeliveryExpectation = date;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "isArrived", {
        get: function () {
            return !isNullOrUndefined(this.dateArrivalAct) && (this.dateArrivalAct <= new Date());
        },
        enumerable: true,
        configurable: true
    });
    Master.prototype.addRecoveryLoad = function (pieces, hu, datePickupEst) {
        var load = new Load();
        load.shipment = new Shipment();
        load.shipment.addressPickUp = this.cargoBuilding;
        load.shipment.addressDelivery = this.addressDelivery;
        load.shipment.hu = hu;
        load.shipment.pieces = pieces; // Pieces to be recovered
        load.shipment.datePickUpExpectation = !isNullOrUndefined(datePickupEst) ? datePickupEst : undefined; // || addHours(new Date(), 4);
        load.shipment.master = this;
        load.shipment.load = load;
        load.loadType = LoadType.RECOVERY;
        this.orders.forEach(function (o) {
            var content = new ShipmentContent();
            load.shipment.shipmentContents.push(content);
            content.shipment = load.shipment;
            content.order = o;
            o.shipmentContents.push(content);
        });
        return load;
    };
    Object.defineProperty(Master.prototype, "isMultipleOrders", {
        get: function () {
            return (this.orders && this.orders.length > 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "isSingleOrder", {
        get: function () {
            return (this.orders && this.orders.length === 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "singleOrder", {
        get: function () {
            return this.isSingleOrder ? this.orders[0] : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "someOrder", {
        get: function () {
            return this.orders.length > 0 ? this.orders[0] : null;
        },
        enumerable: true,
        configurable: true
    });
    Master.prototype.updatePieces = function () {
        var pcs = 0;
        this.orders.forEach(function (order) {
            if (!isNaN(order.pieces)) {
                pcs += checkNumber(order.pieces);
            }
        });
        this.pieces = pcs;
        return true;
    };
    Master.prototype.updateWeight = function () {
        var wght = 0.0;
        this.orders.forEach(function (order) {
            if (!isNaN(order.weight)) {
                wght += checkNumber(order.weight);
            }
        });
        this.weight = wght;
        return true;
    };
    Master.prototype.updateLastFreeDay = function () {
        var date = null;
        if (this.masterShipments) {
            this.masterShipments.forEach(function (ms) {
                date = minDate(date, ms.updateLastFreeDay());
            });
        }
        return date;
    };
    Master.prototype.calculateLastFreeDate = function (date, estDate) {
        if (isNullOrUndefined(date)) {
            return addHours(estDate, 48);
        }
        return addHours(date, 48);
    };
    Master.prototype.updateArrivalDay = function () {
        var date = this.datePickupActual;
        if (!isNullOrUndefined(date) && isNullOrUndefined(this.dateArrivalAct)) {
            if (isNullOrUndefined(this.dateArrivalEst)) {
                this.dateArrivalAct = subtractHours(date, 2);
            }
            else {
                this.dateArrivalAct = this.dateArrivalEst;
            }
        }
        return this.dateArrivalAct;
    };
    Master.prototype.isMasterInPendingStatus = function (pendingStatus) {
        var pendingStatuses = [];
        if (isNullOrUndefined(this.dateArrivalAct)) {
            this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.dateArrivalEst));
        }
        if (isNullOrUndefined(this.datePickupActual)) {
            this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.dateLastFreeDay));
            this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.datePickupEstimated));
        }
        if (isNullOrUndefined(this.dateDeliveryActual)) {
            this.addPendingStatusIfNotNull(pendingStatuses, getPendingStatus(this.dateDeliveryEstimated));
        }
        switch (pendingStatus) {
            case PendingStatus.PAST_DUE:
                return pendingStatuses.indexOf(pendingStatus) > -1;
            case PendingStatus.AT_RISK:
                return pendingStatuses.indexOf(pendingStatus) > -1 && pendingStatuses.indexOf(PendingStatus.PAST_DUE) === -1;
            case PendingStatus.IMPENDING:
                return pendingStatuses.indexOf(pendingStatus) > -1 && pendingStatuses.indexOf(PendingStatus.PAST_DUE) === -1 && pendingStatuses.indexOf(PendingStatus.AT_RISK) === -1;
        }
    };
    Object.defineProperty(Master.prototype, "problemStatus", {
        get: function () {
            var status = ProblemStatus.NONE;
            var stillProblem = false;
            if (this.problems) {
                this.problems.forEach(function (problem) {
                    if (problem.isResolved) {
                        status = ProblemStatus.RESOLVED;
                    }
                    else {
                        stillProblem = true;
                    }
                });
            }
            return stillProblem ? ProblemStatus.PROBLEM : status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "problemDesc", {
        get: function () {
            var desc = '';
            if (this.problems) {
                this.problems.forEach(function (problem) {
                    if (!problem.isResolved) {
                        desc += desc === '' ? problem.description : ', ' + problem.description;
                    }
                });
            }
            return desc;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "customerNameBasedOnOrders", {
        get: function () {
            var _this = this;
            var customers = [];
            this.orders.forEach(function (order) {
                if (!isNullOrUndefined(order.customer) && !_this.isCustomerExist(customers, order.customer)) {
                    customers.push(order.customer);
                }
            });
            return customers.length === 0 ? OmsConstants.EMPTY_VALUE : customers.length === 1 ? customers[0].name : 'Various';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "deliveryApproval", {
        get: function () {
            return !this.orders.some(function (o) { return !o.deliveryApproval; });
        },
        set: function (value) {
            this.orders.forEach(function (o) { return o.deliveryApproval = value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "pttCreated", {
        get: function () {
            return !this.orders.some(function (o) { return !o.pttCreated; });
        },
        set: function (value) {
            this.orders.forEach(function (o) { return o.pttCreated = value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "hasProblem", {
        get: function () {
            return !this.orders.some(function (o) { return !o.hasProblem; });
        },
        set: function (value) {
            this.orders.forEach(function (o) { return o.hasProblem = value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dispatchedStatus", {
        get: function () {
            return !this.orders.some(function (o) { return !o.dispatchedStatus; });
        },
        set: function (value) {
            this.orders.forEach(function (o) { return o.dispatchedStatus = value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsOutActOrder", {
        get: function () {
            return latestObjectByDate(this.orders, function (o) { return o.dateCfsOutAct; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsOutEstOrder", {
        get: function () {
            return latestObjectByDate(this.orders, function (o) { return o.dateCfsOutEst; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsInActOrder", {
        get: function () {
            return latestObjectByDate(this.orders, function (o) { return o.dateCfsInAct; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsInEstOrder", {
        get: function () {
            return latestObjectByDate(this.orders, function (o) { return o.dateCfsInEst; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsInAct", {
        get: function () {
            return latest(this.orders, function (o) { return o.dateCfsInAct; });
        },
        set: function (date) {
            this.orders.forEach(function (order) {
                order.dateCfsInAct = date;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsInEst", {
        get: function () {
            return latest(this.orders, function (o) { return o.dateCfsInEst; });
        },
        set: function (date) {
            this.orders.forEach(function (order) {
                order.dateCfsInEst = date;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsOutAct", {
        get: function () {
            return latest(this.orders, function (o) { return o.dateCfsOutAct; });
        },
        set: function (date) {
            this.orders.forEach(function (order) { order.dateCfsOutAct = date; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsOutEst", {
        get: function () {
            return this._dateCfsOutEst === undefined ? (this._dateCfsOutEst = latest(this.orders, function (o) { return o.dateCfsOutEst; })) : this._dateCfsOutEst;
            //    return latest(this.orders, o => o.dateCfsOutEst);
        },
        set: function (date) {
            this._dateCfsOutEst = date;
            this.orders.forEach(function (order) { order.dateCfsOutEst = date; });
        },
        enumerable: true,
        configurable: true
    });
    Master.prototype.isCustomerExist = function (customers, customer) {
        return customers.find(function (customerFromList) {
            return customerFromList.id === customer.id;
        });
    };
    Master.prototype.addPendingStatusIfNotNull = function (pendingStatuses, pendingStatus) {
        if (!isNullOrUndefined(pendingStatus)) {
            pendingStatuses.push(pendingStatus);
        }
    };
    Object.defineProperty(Master.prototype, "status", {
        get: function () {
            return this.masterShipments.asUniqueValue(function (o) { return o.status; });
            //    return this.isSingleSplit ? this.singleSplit.status : null;
        },
        set: function (status) {
            if (!equals(this.status, status)) {
                this.masterShipments.forEach(function (o) {
                    o.status = status;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "flightNumber", {
        get: function () {
            return this.masterShipments.asUniqueValue(function (ms) { return ms.flightNumber; });
        },
        set: function (value) {
            if (!equals(this.flightNumber, value)) {
                this.masterShipments.forEach(function (ms) {
                    ms.flightNumber = value;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "airport", {
        get: function () {
            return this.masterShipments.asUniqueValue(function (ms) { return ms.airport; });
        },
        set: function (value) {
            if (!equals(this.airport, value)) {
                this.masterShipments.forEach(function (ms) { ms.airport = value; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "cargoBuilding", {
        get: function () {
            return this.masterShipments.asUniqueValue(function (ms) { return ms.shipment.addressDelivery; });
        },
        set: function (value) {
            if (!equals(this.cargoBuilding, value)) {
                this.masterShipments.forEach(function (ms) {
                    ms.shipment.addressDelivery = value;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "airline", {
        get: function () {
            return this.masterShipments.asUniqueValue(function (ms) { return ms.airline; });
        },
        set: function (value) {
            if (!equals(this.airline, value)) {
                this.masterShipments.forEach(function (ms) {
                    ms.airline = value;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "masterShipmentAir", {
        get: function () {
            return this.masterShipments && this.masterShipments.length === 1 ? this.masterShipments[0].masterShipmentAir : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "splitCount", {
        get: function () {
            return this.splits.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateLastFreeDay", {
        get: function () {
            var date = null;
            this.masterShipments.forEach(function (ms) { date = minDate(date, ms.dateLastFreeDay); });
            return date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "dateCfsFreeTime", {
        get: function () {
            var date = null;
            this.orders.forEach(function (or) { date = minDate(date, or.dateCfsFreeTime); });
            return date;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "recovery", {
        get: function () {
            var r = [];
            this.orders.forEach(function (o) {
                o.shipmentContents.forEach(function (sc) {
                    var load = sc.shipment.load;
                    if (load && load.isRecovery && !r.hasEquals(load)) {
                        r.push(load);
                    }
                });
            });
            return r;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "splits", {
        get: function () {
            var _this = this;
            if (this._splits) {
                return this._splits;
            }
            var num = 1;
            //    this._splits = [];
            // New Recovery orders implementation
            this._splits = this.recoveryOrders ? this.recoveryOrders.map(function (o) { return new RecoveryOrder(_this, o); }) : [];
            this._splits.sort(function (a, b) { return a.order.id - b.order.id; });
            // Add Old Split Implementation
            this.orders.forEach(function (order) {
                order.loadShipmentContents.forEach(function (sc) {
                    if (sc.shipment && sc.shipment.load && sc.shipment.load.isRecovery) {
                        var split = _this._splits.find(function (s) { return s instanceof Split && equals(s.shipment, sc.shipment); });
                        if (!split) {
                            split = new Split(_this, num++);
                            split.shipment = sc.shipment;
                            split.load = sc.shipment.load;
                            split.load.splitNumber = split.splitNumber;
                            _this._splits.push(split);
                        }
                        split.contents.push(sc);
                    }
                });
            });
            /*    let remains: number = this.pcsToRecover;// this.pieces - this.pcsReceived;
                if (remains > 0) {
                  //Find existing load which has no pieces assigned
                  let split: Split = <Split> this._splits.findFirst(s => s instanceof Split && !s.isVirtual && !s.isDispatched && (this.isNew() && s.isAssigned  || !s.isAssigned));
            
                  if (!split) {
                    //Create new virtual load
                    split = new Split(this, num++);
                    this._splits.push(split);
                    split.pcsToRecover = remains;
                  } else {
                    split.pcsToRecover = remains + ~~split.pcsToRecover;
                  }
            
                } else {
                  let split: Split = <Split> this._splits.findFirst(s => s instanceof Split && !s.isVirtual && !s.isDispatched && (this.isNew() && s.isAssigned  || !s.isAssigned));
                  if (split)
                    split.pcsToRecover = remains + ~~split.pcsToRecover;
            
                }
                */
            return this._splits;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "parts", {
        get: function () {
            var _this = this;
            if (this._parts) {
                return this._parts;
            }
            this._parts = [];
            this.orders.forEach(function (order) {
                var partNo = 'A';
                order.contentsRecovery.forEach(function (sc) {
                    var part = new Part();
                    part.content = sc;
                    part.master = _this;
                    part.order = order;
                    part.content = sc;
                    part.part = partNo;
                    _this._parts.push(part);
                    partNo = nextLetter(partNo);
                });
            });
            return this._parts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "someOver", {
        get: function () {
            return this.orders.some(function (o) { return o.fillStatus === FillStatus.OVER; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "fillStatus", {
        get: function () {
            var someHalf = this.orders.some(function (o) {
                return o.fillStatus === FillStatus.HALF;
            });
            var received = this.pcsReceived;
            if (received > 0) {
                if (received < this.pieces || someHalf) {
                    return FillStatus.HALF;
                }
                else if (received > this.pieces) {
                    return FillStatus.OVER;
                }
                else {
                    return FillStatus.FULL;
                }
            }
            else {
                return FillStatus.EMPTY;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "receiveStatus", {
        get: function () {
            var prc = this.pcsReceived * 100 / this.pieces || 0;
            var someHalf = this.orders.some(function (o) {
                return o.fillStatus === FillStatus.HALF;
            });
            return someHalf ? (prc < 100 ? prc : FillStatus.HALF) : prc;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "fillStatusPrc", {
        get: function () {
            return this.pcsReceived * 100 / this.pieces || 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "fillStatusDescription", {
        get: function () {
            var s = '';
            if (this.direct) {
                s = 'Direct:';
            }
            else {
                s = StringUtil.receivingDescription(this.pieces, this.pcsReceived);
            }
            this.splits.forEach(function (split) {
                if (split.isDispatched) {
                    if (split.load.driver) {
                        s += '\nR#' + split.orderId + ': ' + split.load.driver.fullName;
                    }
                    if (split.load.truck) {
                        s += ' TRK: ' + split.load.truck.number;
                    }
                    if (split.load.trailer) {
                        s += ' TRL: ' + split.load.trailer.number;
                    }
                }
            });
            return s;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "driverName", {
        get: function () {
            var driverName = '';
            this.splits.forEach(function (split) {
                if (split.isDispatched) {
                    if (!isNullOrUndefined(driverName) && driverName.length > 0 && !isNullOrUndefined(split.load.driver) && driverName !== split.load.driver.fullName) {
                        driverName = '';
                        return driverName;
                    }
                    driverName = driverName.length === 0 ? !isNullOrUndefined(split.load.driver) ? split.load.driver.fullName : '' : '';
                }
            });
            return driverName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "truckNumber", {
        get: function () {
            var truckNumber = '';
            this.splits.forEach(function (split) {
                if (split.isDispatched) {
                    if (!isNullOrUndefined(truckNumber) && truckNumber.length > 0 && !isNullOrUndefined(split.load.truck) && truckNumber !== split.load.truck.number) {
                        truckNumber = '';
                        return truckNumber;
                    }
                    truckNumber = truckNumber.length === 0 ? !isNullOrUndefined(split.load.truck) ? split.load.truck.number : '' : '';
                }
            });
            return truckNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "trailerNumber", {
        get: function () {
            var trailerNumber = '';
            this.splits.forEach(function (split) {
                if (split.isDispatched) {
                    if (!isNullOrUndefined(trailerNumber) && trailerNumber.length > 0 && !isNullOrUndefined(split.load.trailer) && trailerNumber !== split.load.trailer.number) {
                        trailerNumber = '';
                        return trailerNumber;
                    }
                    trailerNumber = trailerNumber.length === 0 ? !isNullOrUndefined(split.load.trailer) ? split.load.trailer.number : '' : '';
                }
            });
            return trailerNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "pcsToRecover", {
        get: function () {
            var num = this.pieces || 0;
            var active = false;
            this.splits.forEach(function (s) {
                if (s.loadId) {
                    num -= s.pcsToRecover || 0;
                    if (!s.load.isDelivered) {
                        active = true;
                    }
                }
            });
            if (!active && ~~this.pieces > ~~this.pcsReceived) {
                return ~~this.pieces - ~~this.pcsReceived;
            }
            return num || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "huToRecover", {
        get: function () {
            var num = this.hu || 0;
            var active = false;
            this.splits.forEach(function (s) {
                if (s.loadId) {
                    num -= s.huToRecover || 0;
                    if (!s.load.isDelivered) {
                        active = true;
                    }
                }
            });
            if (!active && ~~this.hu > ~~this.huReceived) {
                return ~~this.hu - ~~this.hu;
            }
            return num || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "pcsToReceive", {
        get: function () {
            return ~~this.pieces - ~~this.pcsReceived;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "date1F", {
        get: function () {
            return latest(this.masterShipments, function (ms) { return ms.date1F; });
        },
        set: function (value) {
            this.masterShipments.forEach(function (ms) { return ms.date1F = value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "isDispatched", {
        get: function () {
            return !isNullOrUndefined(this.splits.find(function (s) { return s.isDispatched && !s.isRecovered; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "noCfs", {
        get: function () {
            return this.direct;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "masterAirUld", {
        get: function () {
            return this.ulds;
        },
        set: function (uldAirs) {
            this.ulds = uldAirs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "addressDelivery", {
        get: function () {
            return this.direct ? this.orders.asUniqueValue(function (o) { return o.addressDelivery; }) : this.orders.asUniqueValue(function (o) { return o.addressCfs; });
        },
        set: function (address) {
            if (this.direct) {
                this.orders.forEach(function (o) { return o.addressDelivery = address; });
            }
            else {
                this.orders.forEach(function (o) { return o.addressCfs = address; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "addressCfs", {
        get: function () {
            return this.orders.asUniqueValue(function (o) { return o.addressCfs; });
        },
        set: function (address) {
            this.orders.forEach(function (o) { return o.addressCfs = address; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Master.prototype, "date1C", {
        get: function () {
            return latest(this.orders, function (o) { return o.date1C; });
        },
        enumerable: true,
        configurable: true
    });
    Master.prototype.beforeSave = function () {
        //    console.log('MASTER BEFORE SAVE', this);
        var id = 0;
        // 1. Enum unique shipments to shipment content relation.
        this.orders.forEach(function (o) {
            o.shipmentContents.forEach(function (sc) {
                sc.shipment.uid = ++id;
            });
        });
        this.orders.forEach(function (o) {
            o.shipmentContents.forEach(function (sc) {
                sc.shipment_uid = sc.shipment.uid;
            });
        });
        // 2. Enum orders
        id = 0;
        this.orders.forEach(function (o) {
            o.uid = ++id;
        });
        this.orders.forEach(function (o) {
            o.shipmentContents.forEach(function (sc) {
                sc.order = o;
                sc.order_uid = o.uid;
            });
        });
    };
    Master.prototype.afterLoad = function () {
        //    console.log('--- MASTER ON LOAD ----------', this);
        var _this = this;
        if (!this.masterShipments) {
            this.masterShipments = [];
        }
        this.masterShipments.forEach(function (ms) { return ms.master = _this; });
        // keep unique shipments for all orders inside master
        this.masterShipments.forEach(function (ms) {
            _this.shipments.push(ms.shipment);
        });
        this.orders.forEach(function (o) {
            o.shipmentContents.forEach(function (sc) {
                var shipment = _this.shipments.find(function (s) { return equals(s, sc.shipment); });
                if (shipment) {
                    sc.shipment = shipment;
                }
                else {
                    _this.shipments.push(sc.shipment);
                }
            });
        });
        this.orders.forEach(function (o) {
            o.master = _this;
            o.shipmentContents.forEach(function (sc) {
                sc.order = o;
                sc.shipment.shipmentContents.push(sc);
                if (sc.shipment.load) {
                    sc.shipment.load.shipment = sc.shipment;
                }
            });
        });
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Array)
    ], Master.prototype, "masterShipments", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", MasterAir)
    ], Master.prototype, "masterAir", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", CustomsClearance)
    ], Master.prototype, "customsClearance", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Customer)
    ], Master.prototype, "customer", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", FreightForwarder)
    ], Master.prototype, "freightForwarder", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Array)
    ], Master.prototype, "orders", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Array)
    ], Master.prototype, "recoveryOrders", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", Date)
    ], Master.prototype, "dateISCPaid", void 0);
    __decorate([
        Type(ɵ8),
        __metadata("design:type", Date)
    ], Master.prototype, "datePickupEstimated", void 0);
    __decorate([
        Type(ɵ9),
        __metadata("design:type", Date)
    ], Master.prototype, "datePickupActual", void 0);
    __decorate([
        Type(ɵ10),
        __metadata("design:type", Date)
    ], Master.prototype, "dateDeliveryEstimated", void 0);
    __decorate([
        Type(ɵ11),
        __metadata("design:type", Date)
    ], Master.prototype, "dateDeliveryActual", void 0);
    __decorate([
        Type(ɵ12),
        __metadata("design:type", Date)
    ], Master.prototype, "dateBilled", void 0);
    __decorate([
        Type(ɵ13),
        __metadata("design:type", Array)
    ], Master.prototype, "problems", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Array)
    ], Master.prototype, "shipments", void 0);
    __decorate([
        Type(ɵ14),
        __metadata("design:type", Array)
    ], Master.prototype, "ulds", void 0);
    __decorate([
        Transform(ɵ15),
        __metadata("design:type", Number)
    ], Master.prototype, "genericMode", void 0);
    __decorate([
        Type(ɵ16),
        __metadata("design:type", Array)
    ], Master.prototype, "cbpStatuses", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Array)
    ], Master.prototype, "_splits", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Array)
    ], Master.prototype, "_parts", void 0);
    __decorate([
        Exclude() // note important!
        ,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [Number])
    ], Master.prototype, "pieces", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Address),
        __metadata("design:paramtypes", [Address])
    ], Master.prototype, "addressDelivery", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Address),
        __metadata("design:paramtypes", [Address])
    ], Master.prototype, "addressCfs", null);
    __decorate([
        BeforeSave(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Master.prototype, "beforeSave", null);
    __decorate([
        AfterLoad(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Master.prototype, "afterLoad", null);
    return Master;
}(BaseDeletable));
export { Master };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
