import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material';
import {OmsAlertsService} from '../../../shared/components/oms-alerts/oms-alerts.service';
import {Manifest} from '../../../shared/models/manifest/manifest';
import {TruckService} from '../../../../services/truck-service';
import {TrailerService} from '../../../../services/trailer-service';
import {DriverService} from '../../../../services/driver-service';
import {ManifestItem} from '../../../shared/models/manifest/manifest-item';
import {OrderDispatch} from '../../../shared/models/dispatch/order-dispatch';
import {DispatchService} from '../../../shared/services/dispatch/dispatch.service';
import {NgxSpinnerService} from "ngx-spinner";
import {convertManifestNumber} from "../../../shared/services/oms-converters.service";
import {
  BUTTONS_CONFIRM_CANCEL,
  BUTTONS_CONTINUE_CANCEL,
  BUTTONS_YES_NO,
  DialogType,
  ModalResult,
  OmsDialogsService
} from "../../../../components/common/oms-dialogs";
import {ManifestCreateContentComponent} from "../manifest-create/manifest-create-content.component";
import {Address, MasterStatusId, Order, Trailer} from "../../../shared/models";
import {absent, assigned} from "../../../../_helpers/utils";
import {HttpErrorResponse} from "@angular/common/http";
import {Logger} from "../../../../_helpers/logger";
import {isNullOrUndefined} from "util";
import {OrderMode} from "../../../shared/models/order/order-mode";

@Component({
  selector: 'manifest-create-panel',
  templateUrl: './manifest-create-panel.component.html',
  styleUrls: ['./manifest-create-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({})
export class ManifestCreatePanelComponent implements OnInit, OnChanges {

  @Input('order-modes') public orderModes: OrderMode[] = [];
  @Input('can-close') canClose: boolean = true;
  @Input('allow-add-shipments') allowAddShipments: boolean = true;
  @Input() public mergeToExisting: boolean = true;
  @Input() public isCreateFromWarehouse: boolean = false;

  @Input() public manifest: Manifest = new Manifest();
  @Output() public manifestChange = new EventEmitter<Manifest>();

  @Input() public selected: OrderDispatch[] = [];
  @Output() public selectedChange = new EventEmitter<OrderDispatch[]>();
  @Output() public removeItems = new EventEmitter<ManifestItem[]>();
  @Output() public created = new EventEmitter<Manifest>();
  @Output() public deleted = new EventEmitter<number>();
  @Output() public refresh = new EventEmitter<void>();
  @Output() public reset = new EventEmitter<void>();
  @Output() public edit = new EventEmitter<void>();
  @Output('close') public closeEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(ManifestCreateContentComponent) manifestContent: ManifestCreateContentComponent;

  public loading: boolean = false;

  manifestNumber: string = '';

  isLoadingSave = false;
  isLoadingDelete = false;
  isDisplayDelete = false;
  isDisplayAddShipments = false;
  isSendEmail: boolean = false;
  isStaging: boolean;
  wasStaging: boolean;

  constructor(
    public cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private dialogs: OmsDialogsService,
    private alerts: OmsAlertsService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService,
    private dispatchService: DispatchService,
    private dialogsService: OmsDialogsService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.manifest) {
      let isCreated = this.manifest && !this.manifest.isNew();
      this.isStaging = this.manifest && !this.manifest.isNew() && this.manifest.isStaging;
      this.wasStaging = this.isStaging;
      this.isDisplayDelete = isCreated;
      this.isDisplayAddShipments = isCreated;
      this.manifestNumber = isCreated ? convertManifestNumber(this.manifest.id) : 'New';
    }
  }

/*  public onSetStaging(staging: boolean) {
    if (!staging) {
      this.manifest.driver = undefined;
    }
  } */

  private validateAmsAI(manifest: Manifest): Promise<Manifest> {
    manifest.items.flatMap((mi) => mi.orders).forEach((o) => {
      console.log(`VALIDATE J#${o.id}, Mode: ${o.genericMode} non-AMS: ${o.nonAMS} Status: ${o.status}`);
    });


    let order: Order = manifest.items.flatMap((mi) => mi.orders).find((o) =>
      (absent(o.genericMode) || o.genericMode === OrderMode.AICFS) &&
       assigned(o.nonAMS) && (!o.nonAMS) &&
       o.status < MasterStatusId.ONHAND_COMPLETE_READY_FOR_DISPATCH);

    return order ?
      this.dialogs.confirm(DialogType.ERROR, "Warning",
      `YOU ARE DISPATCHING AN ORDER <b>J#${order.id}</b> THAT IS NOT CLEARED BY US CUSTOMS<br/>` +
        `THIS MAY RESULT IN FINES AND PENALTIES AGAINST JJS TRANSPORTATION & DISTRIBUTION.<br/><br/>` +
        `PLEASE CONFIRM THAT THIS IS HOW YOU WANT TO PROCEED`
        , BUTTONS_CONFIRM_CANCEL)
      .then((r) =>
        (r.result === ModalResult.OK) ? Promise.resolve(manifest) : Promise.reject()
      ) :
      Promise.resolve(manifest);
  }

  private validateEqualAddresses(manifest: Manifest): Promise<Manifest> {
    // Validate equal From-To Addresses
    let item: ManifestItem = manifest.items.find((mi) => Address.sameAddress(mi.addressPickUp, mi.addressDelivery));

    return assigned(item) ?
      this.dialogs.confirm(DialogType.WARNING, "Confirm",
        `You are dispatching a load from/to the same address, is that what you meant to do?`,
        BUTTONS_CONTINUE_CANCEL)

        .then((r) => r.result === ModalResult.OK ? Promise.resolve(manifest) : Promise.reject())

    : Promise.resolve(manifest);

  }


  private validate(manifest: Manifest): Promise<Manifest> {
    return new Promise((success, reject) => {

      /** it Marks as invalid only */
      this.manifestContent.validate();


      let m = this.manifestContent.validateManifest(manifest);
      if (m) {
        reject(m);
      } else {
        this.validateAmsAI(manifest)
          .then(() => this.validateEqualAddresses(manifest))
          .then(() => success(manifest))
          .catch(() => reject());
      }
    });
  }

  private prepareManifest(manifest: Manifest): Manifest {
    console.log('PREPARE MANIFEST', manifest);
    manifest.items.forEach((mi) => {
      mi.loadTypeOriginal = mi.loadType;
      mi.orders.forEach((o) => {o.info.legAddressDelivery = mi.addressDelivery; });
    });
    return manifest;
  }

  saveAndCloseDispatch(isClose) {
    console.log('SAVE MANIFEST');
    this.isLoadingSave = true;
    this.loading = true;
    if (this.isStaging && !this.manifest.allTrailerTheSame()) {
      this.splitDispatchByTrailers();
    } else {
      this.manifest.enumerate();
      this.validate(this.manifest)
        .then((manifest) => this.dispatchService.dispatchOrders(manifest, this.isSendEmail).toPromise())
        .then((manifest) => {
//        if (manifest.isPending) {
          let action = this.manifest.isNew() ? 'added to' : ( this.wasStaging !== manifest.isStaging ? 'moved to' : 'updated on' );
          let tab = manifest.isStaging ? 'STAGING' : 'ACTIVE';
          this.dialogs.openInfoDialog(`${convertManifestNumber(manifest.id)} was ${action} the ${tab} tab`, {mode: 'success', caption: 'Success', timeout: 5000}).then();
          /*        } else {
                    let action = this.manifest.isNew() ? 'created' : 'updated';
                    this.alerts.success(`Manifest ${convertManifestNumber(manifest.id)} was ${action}`, 10000);
                  }
           */

          this.isSendEmail = false;
          this.loading = false;
          this.isLoadingSave = false;

          // https://jjstransportation.mantishub.io/view.php?id=1062
          /*        if (manifest.manifestDocument && manifest.manifestDocument.id) {
                    window.open(this.file.buildDownloadFileByIdUrl(manifest.manifestDocument.id), '_blank');
                  }*/
          if (isClose) {
            this.created.emit(manifest);
            this.refresh.emit();
          } else {
            this.manifest = this.prepareManifest(manifest);
          }
          console.log('Result - %o', manifest);
          this.cdr.markForCheck();
        })
        .catch((error) => {
          this.loading = false;
          this.isLoadingSave = false;
          this.cdr.markForCheck();
          if (error) {
            if (typeof error === 'string') {
              this.dialogs.openErrorDialog(error, 'Manifest Validation Error', 5000).then();
            } else {
              this.alerts.error(error, 'Manifest Validation Error', 5000);
            }
            console.log('Error', error);
          } else {
            this.alerts.info('Manifest Save cancelled', 2000);
          }
        });
    }
  }

  downloadManifestJson() {
    window.open(this.dispatchService.buildManifestJsonDownloadUrl(this.manifest.id), '_blank');
  }

  removeDispatch() {
    let updated = this.manifest.getUpdatedLoads();
    let message = updated && updated.length ?
      'This Manifest includes loads that were updated (completed/not completed).\n' +
      'Deleting the manifest will delete all these loads, and will also undo the updates,\n' +
      'as if the orders in question were never dispatched.\n' +
      'Please confirm that you would like to delete all the loads.\n' +
      'Any PODs current in these orders will be changed to file-type "Cancelled POD".\n' +
      'Continue?' :
      'This will cancel this Manifest/Dispatch and return all Orders to the dispatch list.\nContinue?';

    this.dialogsService.confirm(DialogType.CONFIRMATION, 'Delete dispatch', message,
      BUTTONS_YES_NO).then((result) => {
      if (result.result === ModalResult.YES) {
        this.isLoadingDelete = true;
        this.loading = true;
        this.spinner.show();
        const manifestId = this.manifest.id;
        this.dispatchService.cancelOrdersDispatch(manifestId, this.orderModes)
          .subscribe(() => {
            this.spinner.hide();
            this.loading = false;
            this.isLoadingDelete = false;
            this.alerts.info(`Manifest ${convertManifestNumber(manifestId)} was deleted!`, 5000);
            this.deleted.emit(manifestId);

            this.manifest = new Manifest();
            this.manifestChange.emit(this.manifest);
            this.created.emit();  // todo: why this is here?

          }, (error) => {
            this.spinner.hide();
            this.loading = false;
            this.isLoadingDelete = false;

            console.log("ERROR DELETE MANIFEST", error);
            if (error instanceof HttpErrorResponse && error.status === 400) {
              console.log('HERE');
              this.alerts.info(`Manifest ${convertManifestNumber(manifestId)} not found`);
            } else {
              this.alerts.error(error, 'Error deleting Manifest');
            }
          });
      }
    });
  }

  splitDispatchByTrailers() {
    let items: ManifestItem[] = this.manifest.items;
    let trailers: Trailer[] = items.map((mi) => mi.trailer).unique();
    let trailerNumbers = trailers.map((t) => t.number);
    this.dialogs.confirm(DialogType.CONFIRMATION, 'confirm',
      'This manifest will be splitting for different trailers: \n' + trailerNumbers, BUTTONS_CONTINUE_CANCEL)
      .then((r) => {
        console.log('RESULT', r.result);
        if (r.result === ModalResult.OK) {
          console.log('PROCEED', items);
          this.dispatchService.splitManifestByTrailers(this.manifest)
            .subscribe(
              (manifests) => {
                let message = "";
                manifests.forEach((manifest: Manifest) => {
                  this.created.emit(manifest);
                  message = message + "Dispatch " + convertManifestNumber(manifest.id) + ", Trailer - " + (isNullOrUndefined(manifest.trailer) ? "N/A" : manifest.trailer.number) + "\n";
                });
                this.dialogs.openInfoDialog(`${message}`, {mode: 'success', caption: 'Success', timeout: 5000}).then();

                this.refresh.emit();
                this.loading = false;
                this.isLoadingSave = false;
                console.log('Result - %o', manifests);
                this.cdr.markForCheck();
              },
              (error) => {
                this.loading = false;
                this.isLoadingSave = false;
                this.cdr.markForCheck();
                if (error) {
                  if (typeof error === 'string') {
                    this.dialogs.openErrorDialog(error, 'Manifest Validation Error', 5000).then();
                  } else {
                    this.alerts.error(error, 'Manifest Validation Error', 5000);
                  }
                  console.log('Error', error);
                } else {
                  this.alerts.info('Manifest Save cancelled', 2000);
                }
              });
        } else {
          this.loading = false;
          this.isLoadingSave = false;
        }
      });
  }

  clearDispatch() {
    this.selectedChange.emit([]);
    this.removeItems.emit(this.manifest.items);
    this.manifest = new Manifest();
    this.manifestChange.emit(this.manifest);
    this.closeEvent.emit();
  }



  addShipments() {
    this.edit.emit();
  }

/*
  public get selectedOne():Order {
    return this.manifestContent.selectedOne;
  }
 */

  public splitOrder(order: Order) {
    this.manifestContent.splitOrder(order);
  }

  public onRefresh(event) {
    this.cdr.markForCheck();
    this.refresh.emit();
  }

  public refreshData() {
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    this.manifestContent.refreshData();
  }

  public clearFields() {
    this.manifestContent.clearFields();
  }

  public selectedCount(): number {
    return this.manifestContent.selected ? this.manifestContent.selected.length : 0;
  }

  public onManifestChange(manifest: Manifest) {
//    this.isStaging = manifest && manifest.isStaging;
    this.cdr.detectChanges();
    this.manifestChange.emit(manifest);
  }

  public onStagingChange(staging: boolean) {
//    console.log('STAGING', staging);
    this.isStaging = staging;
    this.cdr.detectChanges();
  }

}
