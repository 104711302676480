import { OnInit, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { WebcamUtil } from 'ngx-webcam';
import { MatDialogRef } from "@angular/material";
import { OmsAlertsService } from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { ReceivingService } from "../../../services/receiving.service";
import { OrderWarehouseService } from "../../../services/order-warehouse.service";
import { isNullOrUndefined } from "util";
import { WebDriversService } from "../../../services/web-drivers.service";
import { UserUtil } from "../../../modules/settings/util/user.util";
import { ModalResult } from "../../common/oms-dialogs";
var VideoDialogComponent = /** @class */ (function () {
    function VideoDialogComponent(dialogRef, data, alerts, receivingService, webDriversService, orderWarehouseService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.alerts = alerts;
        this.receivingService = receivingService;
        this.webDriversService = webDriversService;
        this.orderWarehouseService = orderWarehouseService;
        this.pictureTaken = new EventEmitter();
        this.docTypeList = [];
        this.orderDispatches = [];
        this.viewLoading = false;
        this.loadingAfterSubmit = false;
        this.title = 'Take Picture';
        this.showWebcam = true;
        this.allowCameraSwitch = true;
        this.multipleWebcamsAvailable = false;
        this.images = [];
        this.videoOptions = {
            width: { min: 640, ideal: 2048 /*1920*/, max: 2048 /*4000*/ },
            height: { min: 480, ideal: 1536 /*1080*/, max: 1536 /*3000*/ },
            facingMode: "environment"
        };
        this.errors = [];
        this.docType = null;
        this.isSaveToMaster = false;
        // webcam snapshot trigger
        this.trigger = new Subject();
        // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
        this.nextWebcam = new Subject();
        this.mobHeight = window.innerHeight - window.innerHeight / 5;
        this.mobWidth = window.innerWidth;
    }
    VideoDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.title = this.data && this.data.title || this.title;
        this.showWebcam = true;
        this.orderId = this.data && (this.data.orderId || (this.data.receivingPart && this.data.receivingPart.order && this.data.receivingPart.order.id));
        this.masterId = this.data && (this.data.masterId || (this.data.master && this.data.master.id));
        this.isSaveToMaster = this.data.isSaveToMaster;
        console.log('SAVE TO MASTER', this.isSaveToMaster);
        this.docTypeList = this.data.docTypeList;
        this.docType = this.data.defaultDocType || this.docTypeList[0];
        this.orderDispatches = this.data.orderDispatches;
        console.log('DOC TYPES:', this.docTypeList);
        WebcamUtil.getAvailableVideoInputs()
            .then(function (mediaDevices) {
            _this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            _this.viewLoading = false;
        });
        this.currentUser = UserUtil.getCurrentUser();
    };
    VideoDialogComponent.prototype.triggerSnapshot = function () {
        this.trigger.next();
        this.showWebcam = false;
    };
    VideoDialogComponent.prototype.onSubmit = function () {
        if (!isNullOrUndefined(this.docType)) {
            if (this.docType.indexOf('WHSE') > -1 && (isNullOrUndefined(this.orderDispatches) || this.orderDispatches.length === 0)) {
                this.saveWarehousePicture();
            }
            else {
                this.savePicture();
            }
        }
        else {
            this.alerts.warning('Document type not selected');
        }
    };
    VideoDialogComponent.prototype.onClear = function () {
        if (this.currentImage) {
            this.showWebcam = !this.showWebcam;
            this.images.removeAll(this.currentImage);
            this.currentImage = undefined;
        }
    };
    VideoDialogComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
    };
    VideoDialogComponent.prototype.showNextWebcam = function (directionOrDeviceId) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    };
    VideoDialogComponent.prototype.handleImage = function (image) {
        console.info('received webcam image', image);
        this.images.push(image);
        this.currentImage = image;
        this.pictureTaken.emit(image);
    };
    VideoDialogComponent.prototype.cameraWasSwitched = function (deviceId) {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    };
    Object.defineProperty(VideoDialogComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoDialogComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    VideoDialogComponent.prototype.closeDialog = function (result) {
        this.dialogRef.close(result);
    };
    VideoDialogComponent.prototype.hasImages = function () {
        return this.images && this.images.length > 0;
    };
    VideoDialogComponent.prototype.getImagesAsData = function () {
        return this.hasImages() ? this.images.map(function (i) { return i.imageAsDataUrl; }) : [];
    };
    VideoDialogComponent.prototype.savePicture = function () {
        var _this = this;
        if (!this.hasImages()) {
            return;
        }
        if (!isNullOrUndefined(this.orderDispatches) && this.orderDispatches.length > 0) {
            this.viewLoading = true;
            this.webDriversService.savePictureOld(this.orderDispatches, this.docType, this.getImagesAsData())
                .then(function () {
                _this.alerts.success('The image was successfully saved');
                _this.viewLoading = false;
                _this.closeDialog(ModalResult.OK);
            })
                .catch(function (error) {
                _this.saveLocalStorageData();
                _this.viewLoading = false;
                _this.alerts.warning("Cannot access server, data are stored will be sent later", 3000);
                _this.closeDialog(ModalResult.CANCEL);
            });
        }
        else {
            this.viewLoading = true;
            this.receivingService.savePicture(this.orderId, this.masterId, this.docType, this.getImagesAsData())
                .then(function () {
                _this.alerts.success('The image was successfully saved');
                _this.viewLoading = false;
                _this.closeDialog(ModalResult.OK);
            })
                .catch(function (error) {
                _this.viewLoading = false;
                _this.alerts.error(error, 'Image saving error');
            });
        }
    };
    /** @deprecated **/
    VideoDialogComponent.prototype.saveWarehousePicture = function () {
        var _this = this;
        if (this.isSaveToMaster && this.masterId) {
            this.viewLoading = true;
            this.orderWarehouseService.savePictureByMasterId(this.masterId, 'WHSE', this.getImagesAsData()).then(function () {
                _this.alerts.success('The image was successfully saved');
                _this.viewLoading = false;
                _this.closeDialog(ModalResult.OK);
            }).catch(function (error) {
                _this.viewLoading = false;
                _this.saveLocalStorageData();
                _this.alerts.warning("Cannot access server, data are stored will be sent later", 3000);
                _this.closeDialog(ModalResult.CANCEL);
            });
        }
        else if (this.orderId) {
            this.viewLoading = true;
            this.orderWarehouseService.savePictureByOrderId(this.orderId, 'WHSE', this.getImagesAsData()).then(function () {
                _this.alerts.success('The image was successfully saved');
                _this.viewLoading = false;
                _this.closeDialog(ModalResult.OK);
            }).catch(function (error) {
                _this.saveLocalStorageData();
                _this.viewLoading = false;
                _this.alerts.warning("Cannot access server, data are stored will be sent later", 3000);
                _this.closeDialog(ModalResult.CANCEL);
            });
        }
    };
    VideoDialogComponent.prototype.docTypeChanged = function (docType) {
        if (!isNullOrUndefined((docType))) {
            this.docType = docType;
        }
    };
    VideoDialogComponent.prototype.saveLocalStorageData = function () {
        var user = this.currentUser;
        if (user) {
            var storageAmount = localStorage.getItem('savePictureAmount' + user.id);
            // tslint:disable-next-line:radix
            var amount = isNullOrUndefined(storageAmount) ? 1 : parseInt(storageAmount) + 1;
            localStorage.setItem('savePictureAmount' + user.id, amount.toString());
            localStorage.setItem('savePictureLoads' + user.id + "_" + amount, JSON.stringify(this.orderDispatches));
            localStorage.setItem('savePictureDocType' + user.id + "_" + amount, this.docType);
            for (var i = 0; i < this.images.length; i++) {
                localStorage.setItem("savePictureImages" + user.id + "_" + i + "_" + amount, this.images[i].imageAsDataUrl);
            }
            if (this.isSaveToMaster) {
                localStorage.setItem('savePictureToMaster' + user.id + "_" + amount, "true");
            }
        }
    };
    return VideoDialogComponent;
}());
export { VideoDialogComponent };
