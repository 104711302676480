import {Component, Inject, QueryList, ViewChildren} from '@angular/core';
import {OrdersService} from "../../../../../services";
import {OmsAlertsService} from "../../../../shared/components/oms-alerts/oms-alerts.service";
import {Order} from "../../../../shared/models";
import {DialogResult} from "../../../../../components/common/oms-dialogs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {NgModel} from "@angular/forms";
import {convertOrderNumber} from "../../../../shared/services/oms-converters.service";
import {Observable} from "rxjs";
import {assigned} from "../../../../../_helpers/utils";

@Component({
  templateUrl: './split-order-dialog.component.html',
  styleUrls: ['./split-order-dialog.component.scss']
})
export class SplitOrderDialogComponent {
  public order: Order;
  public query: boolean = false;
  public pcs: number;
  public hu: number;
  public weight: number;
  public kgPerPiece: number;
  public equalsHu: boolean = false;
  public originalPcs: number;
  public originalHu: number;
  public showWeight: boolean = true;
  public originalWeight: number;
  public externalHandler: (pcs: number, hu: number, weight: number) => Observable<boolean>;

  private fnOk: (pcs: number, hu: number, weight: number, order: Order) => boolean;
  @ViewChildren(NgModel) inputs: QueryList<NgModel>;

  constructor(private orderService: OrdersService,
              private alerts: OmsAlertsService,
              public dialogRef: MatDialogRef<SplitOrderDialogComponent, DialogResult<any>>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.order = data.order;
    this.fnOk = data.onOk;
    this.externalHandler = data.externalHandler;
    if (this.data.order) {
      this.originalPcs = this.pcs = ~~this.order.pieces;
      this.originalHu = this.hu = ~~this.order.hu;
      this.originalWeight = this.weight = ~~this.order.weight;
    } else {
      this.originalPcs = this.pcs = this.data.pcs;
      this.originalHu = this.hu = this.data.hu;
      this.originalWeight = this.weight = this.data.weight;
    }
    this.kgPerPiece = this.weight && this.pcs ? this.weight / this.pcs : 0.0;
    if (!this.hu) { this.hu = this.pcs; }
    this.equalsHu = this.pcs === this.hu;
    if (assigned(data.showWeight)) {
      this.showWeight = data.showWeight;
    }
  }

/*  validate() {
    return this.inputs.forEach(i=>{
      i.control.markAsTouched();
      if (i.valueAccessor instanceof BaseInputComponent) {
        i.valueAccessor.touchControl();
        i.valueAccessor.validate();
//        return i.invalid;
      }

    });
  } */


  public onOk(event) {
    event.canClose = false;

    if (this.externalHandler) {
      this.query = true;
      this.externalHandler(~~this.pcs, ~~this.hu, ~~this.weight).subscribe(
        (close) => {
          this.query = false;
          if (close) {
            this.dialogRef.close();
          }
         },
        (error) => {
          this.query = false;
          this.alerts.error(error);
        });

    } else {
      this.query = true;
      this.orderService.splitOrder(this.order.id, ~~this.pcs, ~~this.hu, ~~this.weight)
        .then(splitted => {
          this.query = false;
          let newPcs = this.order.pieces - splitted.pieces;
          let newHu = this.order.hu - splitted.hu;
          let newWeight = this.order.weight - splitted.weight;

          this.order.pieces = newPcs;
          this.order.hu = newHu;
          this.order.weight = newWeight;
          this.fnOk(newPcs, newHu, newWeight, splitted);

          this.alerts.success(`${convertOrderNumber(this.order.id)} ${this.order.pieces} pcs changed to ${newPcs} pcs;\n${convertOrderNumber(splitted.id)} created for ${splitted.pieces} pcs;`, 5000);
          this.dialogRef.close();
        })
        .catch((error) => {
          this.query = false;
          this.alerts.error(error, "Error Splitting Order:");
        });
    }
  }

  public get label(): string {
    return this.data.label || (this.order ? `Split order ${convertOrderNumber(this.order.id)}...` : 'Split...');
  }

  public onPcsChange(pcs: number) {
    this.weight = pcs * this.kgPerPiece;
    if (this.equalsHu) {
      this.hu = pcs;
    }
  }

  public onHuChange(event) {
    if (this.pcs !== this.hu) {
      this.equalsHu = false;
    }
  }

}
