import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpUtilsService} from './http-utils.service';
import {FreightForwarder} from '../modules/shared/models/freight.forwarder';
import {RestService} from './rest.service';
import {BaseEntityService} from './base/base-entity.service';
import {OmsDialogsService} from '../components/common/oms-dialogs/oms-dialogs.service';

@Injectable()
export class FreightForwarderService extends BaseEntityService<FreightForwarder> {
  apiUrl = '/oms/freight-forwarder/';
  classType = FreightForwarder;

  public freightForwarderSubject = new Subject<FreightForwarder[]>();
  public freightForwarderPopUpEvent = new Subject<any>();

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService,
    public dialogs: OmsDialogsService) {
    super(httpRestService, httpUtils);
  }

  public showFreightForwarderPopUp(item: FreightForwarder) {
    this.freightForwarderPopUpEvent.next({show: true, freightForwarder: item});
  }

  public hideFreightForwarderPopUp() {
    this.freightForwarderPopUpEvent.next({show: false, freightForwarder: null});
  }

  getAllFreightForwarders() {
    this.findAll().then(items => {
      this.freightForwarderSubject.next(items);
    });
  }

}
