import {Component, OnInit, Output, EventEmitter, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";
import {SignaturePad} from "angular2-signaturepad";
import {ReceivingService} from "../../../services/receiving.service";
import {OrderWarehouseService} from "../../../services/order-warehouse.service";
import {OrderDispatch} from "../../../modules/shared/models/dispatch/order-dispatch";
import {WebDriversService} from "../../../services/web-drivers.service";
import {isNullOrUndefined} from "util";
import {convertDate} from "../../../modules/shared/services/oms-converters.service";
import {LoggedUser} from "../../../modules/shared/models/logged-user";
import {UserUtil} from "../../../modules/settings/util/user.util";


@Component({
  selector: 'oms-signature-pad-dialog',
  templateUrl: './signature-pad-dialog.component.html',
  styleUrls: ['./signature-pad-dialog.component.scss']
})
export class SignaturePadDialogComponent implements OnInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  signaturePadOptions:  Object = {
/*    'minWidth': 2,
    'canvasWidth': 600,
    'canvasHeight': 300,
    'backgroundColor': '#f6fbff',
    'penColor': '#666a73'*/
  };
  convertDate = convertDate;
  public isSignatureComplete = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  orderDispatch: OrderDispatch;
  activeTab: string = 'signature-info';
  mobHeight: any;
  mobWidth: any;
  dialogHeight: any;
  dialogWidth: any;
  name: string = '';
  comment: string = '';
  email: string = '';

  currentUser : LoggedUser;

  constructor(public dialogRef: MatDialogRef<SignaturePadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private alerts: OmsAlertsService,
              private webDriversService: WebDriversService) {
    this.mobHeight = window.screen.availHeight - window.screen.availHeight/5;
    this.mobWidth = window.innerWidth > 700 ? 700 : window.innerWidth;
  }

  public ngOnInit(): void {
    this.dialogRef.updateSize(this.mobWidth + 'px');
    this.orderDispatch = this.data.orderDispatch;
    this.currentUser = UserUtil.getCurrentUser();
  }

  ngAfterViewInit() {
    if(!isNullOrUndefined(this.signaturePad)){
      this.signaturePad.set('minWidth', 1);
      this.signaturePad.set('minDistance', 1);
      this.signaturePad.set('maxWidth', 1);
      this.dialogHeight = this.dialogRef.componentInstance.mobHeight;
      this.dialogWidth = this.dialogRef.componentInstance.mobWidth;
      let signatureHeight = this.dialogWidth > this.dialogHeight ? this.dialogHeight - this.dialogHeight/3 : this.dialogWidth - this.dialogWidth/3;
      this.signaturePad.queryPad()._canvas.width = this.mobWidth > 700 ? 700 : this.mobWidth;
      this.signaturePad.queryPad()._canvas.height = signatureHeight;

      this.signaturePad.clear();
    }
  }

  drawComplete() {
    this.isSignatureComplete = true;
  }

  drawStart() {
  }

  onSubmit(): void {
    this.viewLoading = true;
    this.webDriversService.saveSignaturePdfToDispatchItem(this.orderDispatch.dispatchShipmentId.toString(), this.name, this.comment,
      this.email, this.signaturePad.toDataURL()).then(() => {
      this.alerts.success('The image was successfully saved');
      this.closeDialog();
    }).catch((error) => {
      this.alerts.warning( "Cannot access server, data are stored will be sent later", 3000);
      this.viewLoading = false;
      this.saveLocalStorageData();
    });
  }

  public clear(): void {
    this.isSignatureComplete = false;
    this.signaturePad.clear();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeTab(tab) {
    this.activeTab = tab;
  }

  changeTabByButton(tab, isNext) {
    if(isNext){
      if(tab == "signature-info"){
        this.activeTab = "signature-comments";
      } else if(tab == "signature-comments"){
        this.activeTab = "signature-email";
      } else if(tab == "signature-email"){
        this.activeTab = "signature-name";
      } else if(tab == "signature-name"){
        this.activeTab = "signature";
      }
    } else {
      if(tab == "signature"){
        this.activeTab = "signature-name";
      } else if(tab == "signature-name"){
        this.activeTab = "signature-email";
      } else if(tab == "signature-email"){
        this.activeTab = "signature-comments";
      } else if(tab == "signature-comments"){
        this.activeTab = "signature-info";
      }
    }

  }

  resizeSignaturePad() {
    if(!isNullOrUndefined(this.signaturePad)){
      this.mobHeight = window.screen.availHeight - window.screen.availHeight/5;
      this.mobWidth = window.innerWidth > 700 ? 700 : window.innerWidth;
      this.dialogHeight = this.dialogRef.componentInstance.mobHeight;
      this.dialogWidth = this.dialogRef.componentInstance.mobWidth;
      let signatureHeight = this.dialogWidth > this.dialogHeight ? this.dialogHeight - this.dialogHeight/3 : this.dialogWidth - this.dialogWidth/3;
      this.dialogRef.updateSize(this.mobWidth + 'px');
      this.signaturePad.queryPad()._canvas.width = this.mobWidth;
      this.signaturePad.queryPad()._canvas.height = signatureHeight;
      this.signaturePad.clear();
    }
  }

  getAddressInfo(address) {
    let addressLines = !isNullOrUndefined(address.addressLines) ?  address.addressLines + ", " :  "";
    let city = !isNullOrUndefined(address.city) ?  address.city :  "";
    return addressLines + city + this.provinceOrState(address) + this.limitPostCode(address);
  }

  limitPostCode(address) {
    return !isNullOrUndefined(address.postCode) && address.postCode.length > 5 ? ", " + address.postCode.substring(0, 5) : "";
  }

  provinceOrState(address){
    return !isNullOrUndefined(address.province) ? ", " + address.province : !isNullOrUndefined(address.usaState) && !isNullOrUndefined(address.usaState.uspsCode) &&
    address.usaState.uspsCode.length > 0 ? ", " + address.usaState.uspsCode : "";
  }

  onChange(event, type) {
    if('name' == type){
      this.name = event;
    } else if('comment' == type){
      this.comment = event;
    } else {
      this.email = event;
    }
  }

  saveLocalStorageData(){
    let user = this.currentUser;
    if(user){
      let storageAmount = localStorage.getItem('saveSignaturePadAmount' + user.id);
      let amount = isNullOrUndefined(storageAmount) ? 1 : parseInt(storageAmount) + 1;
      localStorage.setItem('saveSignaturePadAmount' + user.id, amount.toString());
      localStorage.setItem('saveSignaturePadShipmentId' + user.id + "_" + amount, this.orderDispatch.dispatchShipmentId.toString());
      localStorage.setItem('saveSignaturePadName' + user.id + "_" + amount, this.name);
      localStorage.setItem('saveSignaturePadComment' + user.id + "_" + amount, this.comment);
      localStorage.setItem('saveSignaturePadEmail' + user.id + "_" + amount, this.email);
      localStorage.setItem('saveSignaturePadImage' + user.id + "_" + amount, this.signaturePad.toDataURL());
    }
  }
}
