import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, Subscriber} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {isNullOrUndefined} from "util";
import {WINDOW} from "../common/window.providers";


@Injectable()
export class RestService {
  public serverUrl = environment.apiUrl;

  constructor(@Inject(WINDOW) private window: Window, private http: HttpClient) {
    console.log('CURRENT HOST', this.serverUrl);
    if (!this.serverUrl || this.serverUrl.length === 0 || this.serverUrl === 'https://' || this.serverUrl === 'http://') {
      console.log('Detect Host', this.window.location.host);
      this.serverUrl =
        this.window.location.protocol + '//' +
        this.window.location.host; // +
//        (this.window.location.port ? (':' + this.window.location.port) : '' );
      environment.apiUrl = this.serverUrl;
    }
    console.log('API URL>>', environment.apiUrl, 'HOST >>', this.serverUrl);
  }

  getWithHeader<T>(url, headers): Observable<T> {
    return this.http.get<T>(this.serverUrl + url + '/', {headers: headers, withCredentials: true});
  }

  getWithOptions<T>(url: string, httpHeaders: IHttpHeaders, httpParams): Observable<T> {
    let params = null;
    let isHttpParams = httpParams && httpParams instanceof HttpParams;
    if (httpParams && !isHttpParams) {
      params = this.removeBlankProperty(httpParams);
    } else if (httpParams && isHttpParams) {
      params = this.removeBlankPropertyFromHttp(httpParams);
    }
    return this.http.get<T>(this.serverUrl + url, {
      headers: httpHeaders,
      params: params,
      withCredentials: true
    });
  }

  private removeBlankProperty<T>(object: T): T {
    let params = <T>{};
    Object.keys(object).forEach(key => {
      if (!isNullOrUndefined(object[key])) {
        params[key] = object[key];
      }
    });
    return params;
  }

  private removeBlankPropertyFromHttp(httpParams: HttpParams) {
    httpParams.keys().forEach(key => {
      if (isNullOrUndefined(httpParams.get(key))) {
        httpParams.delete(key);
      }
    });
    return httpParams;
  }

  buildUrl(url, ...parameters): string {
    return this.serverUrl + url + (parameters && parameters.length ? '/' + parameters.join("/") : '');
  }

  public encodeQueryData(data): string {
    return '?' + Object.keys(data)
      .filter((key) => !!data[key])
      .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
      .join('&');
  }

  buildUrlForDownload(url, fileType: string) {
    return this.serverUrl + url + fileType;
  }

  get<T>(url, ...parameters): Observable<T> {
    return this.http.get<T>(this.serverUrl + url + '/' + parameters.join("/") + '/', {withCredentials: true});
  }

  post<T>(url: string, body?: any, options?: IPostHttpOptions): Observable<T> {
    const extendedOptions = Object.assign({withCredentials: true}, options);
    return this.http.post<T>(this.serverUrl + url, body, extendedOptions);
  }

  postWithHeader<T>(url, httpHeader, parameter): Observable<T> {
    return this.http.post<T>(this.serverUrl + url, parameter, {headers: httpHeader, withCredentials: true});
  }

  delete<T>(url, ...parameters): Observable<T> {
    return this.http.delete<T>(this.serverUrl + url + '/' + parameters.join("/"), {withCredentials: true});
  }

  deleteWithOptions<T>(url, options?: IPostHttpOptions): Observable<T> {
    options = options || {};
    options = {
      ...options,
      withCredentials: true
    };
    return this.http.delete<T>(this.serverUrl + url, options);
  }

  public getHttpParamsFromObject(params: Object): HttpParams {
    if (params) {
      let p = new HttpParams();
      Object.keys(params).forEach((key) => {
        p = p.append(key, JSON.stringify(params[key]));
      });
      return p;
    }
  }

  put<T>(url, body: any = '', httpHeader, params?: HttpParams | Object): Observable<T> {
    let p: HttpParams = params && params instanceof HttpParams ? params : this.getHttpParamsFromObject(params);
    return this.http.put<T>(this.serverUrl + url, body, {
      headers: httpHeader,
      params: p,
      withCredentials: true
    });
  }

  requestQuery(method, url, data, options?: HttpRequestOptions): Observable<any> {
    const req = new HttpRequest(method, this.serverUrl + url, data, Object.assign({
      reportProgress: true,
      responseType: 'text',
      withCredentials: true
    }, options || {}));

    return this.http.request(req);
  }

  _getAuth(url: string): Observable<any> {

    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;

      this.http
        .get(url, {
          headers:  new HttpHeaders().append('JSESSIONID', '123'),
          responseType: 'blob'
        }).subscribe((m) => {
          console.log('BLOB', m);
          objectUrl = URL.createObjectURL(m);
        console.log('objectUrl', objectUrl);
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }

}

interface HttpRequestOptions {
  headers?: HttpHeaders;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

export interface IPostHttpOptions {
  headers?: IHttpHeaders;
  observe?: 'body';
  params?: IHttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export type IHttpHeaders = HttpHeaders | { [header: string]: string | string[] };
export type IHttpParams = HttpParams | { [param: string]: string | string[] | any };
