import {
  ChangeDetectionStrategy,
  Component, ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {findAncestor} from "../../../../../_helpers/utils";
import {Logger} from "../../../../../_helpers/logger";

@Component({
  selector: 'editable-cell',
  templateUrl: './editable-cell.component.html',
  styleUrls: ['./editable-cell.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({})
export class EditableCellComponent implements OnInit, OnChanges {

  @Input() custom: boolean = false;
  @Input() value: any;
  @Input() type: string;
  @Input() enabled: boolean = true;
  @Input() tooltipText: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild('textInput') textInput: ElementRef<HTMLInputElement>;
  @ContentChild('customTemplate', { read: TemplateRef }) customTemplate: TemplateRef<any>;

  currentValue: string;


  editing: boolean = false;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.currentValue = this.value;
    }
  }

  onBlurEditor(event) {
    this.saveValue();
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.saveValue();
      event.preventDefault();
    } else if (event.key === 'Escape') {
      this.currentValue = this.value;
      this.editing = false;
      event.preventDefault();
    }
  }

  onFocusText() {
    this.startEditing();
  }

  onClickText() {
    this.startEditing();
  }

  withInput(proc: (el) => void) {
    let input =
      this.textInput && this.textInput.nativeElement ||
      this.elRef.nativeElement.querySelector('input');

    if (input) {
      proc(input);
    }
  }

  private startEditing() {
    if (this.enabled) {
      this.editing = true;
      setTimeout(() => {
        this.withInput(el => {

          let ngSelect = findAncestor(el, 'div.ng-select-container');
          if (ngSelect) {
            // ng-select found - open panel
            let event = new Event('mousedown');
            ngSelect.dispatchEvent(event);
          } else {
            el.focus();
            el.select();
          }
          el.addEventListener('onblur', this.onBlurEditor);
        });
      }, 10);
    }
  }

  clickOutside() {
    this.saveValue();
  }

  private saveValue() {
    if (this.editing) {
      this.editing = false;
      this.value = this.currentValue;
      this.valueChange.emit(this.value);
    }
  }
}
