import {OnDestroy, TemplateRef} from '@angular/core';
import {Subject} from 'rxjs';
import {AbstractControl, FormControl} from "@angular/forms";
import {isNullOrUndefined} from "util";

export abstract class AbstractComponent implements OnDestroy {
  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  trackById<T extends { id: string }>(index: number, object: T): any {
    return object && object.id;
  }

  trackByName<T extends { name: string }>(index: number, object: T): any {
    return object && object.name;
  }

  createTrackBy<T>(field: string): (index: number, obj: T) => string {
    return (index, obj) => {
      const trackValue = this.getDeepValue(obj, field);
      if (isNullOrUndefined(trackValue)) {
        console.warn("Track func can works incorrect:" + trackValue);
      }
      return trackValue;
    };
  }

  compareById<T extends { id: string }>(a: T, b: T): boolean {
    return a && b && a.id === b.id;
  }

  compareByName<T extends { name: string }>(a: T, b: T): boolean {
    return a && b && a.name === b.name;
  }

  createCompareBy(field: string): (a: any, b: any) => boolean {
    return (a: any, b: any) => {
      return this.getDeepValue(a, field) === this.getDeepValue(b, field);
    };
  }

  private getDeepValue(obj: any, pathToField: string): any {
    if (!obj || !this.isString(pathToField)) {
      return obj;
    }
    let names = pathToField.split('.');
    let res = obj;
    for (const name of names) {
      if (!res) {
        break;
      }
      res = res[name];
    }
    return res;
  }

  private isString(value): boolean {
    return typeof value === 'string' || value instanceof String;
  }

  resetSubscribers() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$ = new Subject();
  }

  toFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  toTemplateRef(tempRef: any): TemplateRef<any> {
    return tempRef as TemplateRef<any>;
  }
}
