import {Country} from './country';
import {UsaState} from './usa-state';
import {Type, Exclude} from 'class-transformer';
import {separatedValuesString} from '../../../../_helpers/utils';
import {Labeled} from '../../../../common/oms-types';
import {BaseDeletable} from '../base-deletable';

export enum AddressType {
  CARGO_BUILDING, // Warehouse
  CFS_LOCATION,
  CFS_3PL_LOCATION,
  PICKUP_LOCATION,
  DELIVERY_LOCATION,
  WHSE_BUILDING,
  HOME_BASE
}

const addressTypeFieldsMap: Map<AddressType, string> = new Map<AddressType, string>([
  [AddressType.CARGO_BUILDING, 'cargoBuilding'],
  [AddressType.CFS_LOCATION, 'cfsLocation'],
  [AddressType.CFS_3PL_LOCATION, 'cfs3plLocation'],
  [AddressType.PICKUP_LOCATION, 'pickupLocation'],
  [AddressType.DELIVERY_LOCATION, 'deliveryLocation'],
  [AddressType.WHSE_BUILDING, 'whseBuilding'],
  [AddressType.HOME_BASE, 'homeBase'],
]);

const addressTypeDescriptionMap: Map<AddressType, string> = new Map<AddressType, string>([
  [AddressType.CARGO_BUILDING, 'Cargo Building'],
  [AddressType.CFS_LOCATION, 'CFS Location'],
  [AddressType.CFS_3PL_LOCATION, 'CFS 3PL Location'],
  [AddressType.PICKUP_LOCATION, 'Pickup Location'],
  [AddressType.DELIVERY_LOCATION, 'Delivery Location'],
  [AddressType.WHSE_BUILDING, 'WHSE'],
  [AddressType.HOME_BASE, 'Homebase'],
]);


export class Address extends BaseDeletable implements Labeled {
  name: string;
  addressLines: string;
  lat: string;
  lon: string;
  showAs: string;

  cargoBuilding: boolean;
  cfsLocation: boolean;
  cfs3plLocation: boolean;
  pickupLocation: boolean;
  deliveryLocation: boolean;
  whseBuilding: boolean;
  homeBase: boolean;
  top: boolean = false; // for displaying as most frequently used or default

  @Type(() => Country)
  country: Country;
  city: string;
  postCode: string;
  province: string;

  @Type(() => UsaState)
  usaState: UsaState;

  public static addressDescription(addressTypes: AddressType[]): string {
    return separatedValuesString(addressTypes, type => addressTypeDescriptionMap.get(type));
  }

  public static sameAddress(a1: Address, a2: Address): boolean {
    return (a1 && a1.id) === (a2 && a2.id);
  }

  get isUsa(): boolean {
    return this.country && this.country.isUsa;
  }

  set types(types: AddressType[]) {
    if (types) { types.forEach((type) => this[addressTypeFieldsMap.get(type)] = true); }
  }


  public supportsAny(types: AddressType[]): boolean {
    return types && types.length > 0 ? types.some((type) => this[addressTypeFieldsMap.get(type)]) : true;
  }


  public supportsAll(types: AddressType[]): boolean {
    let supports = true;
    if (types && types.length > 0) {
      types.some(type => {
        if (!this[addressTypeFieldsMap.get(type)]) {supports = false; }
        return !supports;
      });
    }
    return supports;
  }

  @Exclude()
  get label(): string {
    return this.name || (this.addressLines && this.addressLines.split("\n", 1)[0]);
  }


  @Exclude()
  get fullAddress(): string {
    let result = this.name + '\n';
    result += this.addressLines ? this.addressLines + '\n' : '';
    result += this.city + ', ' + (this.usaState && this.usaState.uspsCode || this.province || '') + ', ' + this.postCode || '';
    result += this.country ? ',\n' + this.country.name : '';
    return result;
  }

  @Exclude()
  get fullAddressNameAndLines(): string {
    let result = this.name + ', ' + '\n';
    result += this.addressLines ? this.addressLines + '\n' : '';
    return result;
  }

  @Exclude()
  getCityStateZip(): string {
    return [this.city, this.usaState && this.usaState.uspsCode, this.postCode].join(', ');
  }



}
