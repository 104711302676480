﻿import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef, EventEmitter,
  Input,
  OnInit, Output,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import {NavigationService} from '../../../../../services';
import {LeftMenuConfigService, MenuItemConfig} from "../../../services/left.menu.config.service";
import {Router} from "@angular/router";


@Component({
    selector: 'left-sidebar',
    templateUrl: 'left-sidebar.component.html',
    styleUrls: ['./left-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush, encapsulation: ViewEncapsulation.None,
  })
export class LeftSidebarComponent implements OnInit, DoCheck {
  @ViewChild('panel') private panel: ElementRef;
  @Input() visibility: boolean;
  @Input() minified: boolean;
  @Input() floating: boolean;
  @Output('on-item-open') onItemOpen: EventEmitter<any> = new EventEmitter<any>();
  public mouseHover: boolean;

  menuItems: MenuItemConfig[];

  open: any[] = [];

  constructor(
    public cdr: ChangeDetectorRef,
    public navigationService: NavigationService,
    public router: Router,
    private leftMenuConfigService: LeftMenuConfigService) {
  }

  ngOnInit() {
    this.leftMenuConfigService.onMenuUpdated$.subscribe((items) => {
      this.menuItems = items;
    });
  }

  isOpen(item: any): boolean {
    return this.open.hasEquals(item);
  }

  private getUrl(href: any): string {
    return href && (typeof href === "string" ? href : href[0]);
  }

  isActive(item: MenuItemConfig): boolean {
    return item.children ? !!item.children.find((mi) => this.isActive(mi)) : this.router.url === this.getUrl(item.href);
  }

  toggleOpen(event, item: any) {
    if (item.children && item.children.length) {
      if (this.isOpen(item)) {
        this.open.removeAll(item);
      } else {
        this.open.push(item);
      }

      this.onItemOpen.emit({item: item, open: this.isOpen(item)});
      this.cdr.markForCheck();
    }
  }

  getBottom(): number {
    return this.panel.nativeElement.getBoundingClientRect().bottom;
  }

  enter() {
    this.mouseHover = true;
    this.cdr.markForCheck();
    if (this.floating) {
      this.navigationService.showLeftSideBar();
    }
  }

  leave() {
      this.mouseHover = false;
      this.cdr.markForCheck();
      if (this.floating) {
        this.navigationService.hideLeftSideBar();
      }
  }

  outside() {
    this.cdr.markForCheck();
    if (this.floating && this.navigationService.leftSideBarVisible) {
      this.navigationService.hideLeftSideBar();
    }
  }

  ngDoCheck(): void {
//      this.cdr.markForCheck();
  }
}
