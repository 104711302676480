import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TruckService } from '../../../services/truck-service';
import { AddressType, Load } from '../../../modules/shared/models';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { TrailerService } from '../../../services/trailer-service';
import { DriverService } from '../../../services/driver-service';
import { AddressService } from '../../../services';
import { Size } from '../../../common/oms-types';
import { Truck } from '../../../modules/shared/models';
import { Trailer } from '../../../modules/shared/models';
import { Driver } from '../../../modules/shared/models';
import { addHours } from '../../../common/oms-date-time.utils';
import { DialogType, ModalResult, OmsDialogsService } from "../../common/oms-dialogs";
var LoadDispatchDialogComponent = /** @class */ (function () {
    function LoadDispatchDialogComponent(cdr, data, dialogRef, addressService, dialogs, alerts, truckService, trailerService, driverService) {
        this.cdr = cdr;
        this.data = data;
        this.dialogRef = dialogRef;
        this.addressService = addressService;
        this.dialogs = dialogs;
        this.alerts = alerts;
        this.truckService = truckService;
        this.trailerService = trailerService;
        this.driverService = driverService;
        this.Size = Size;
        this.AddressType = AddressType;
        this.readonly = false;
        this.readonly = data.readonly;
        this.master = data.split.master;
        this.load = data.split.load || new Load();
        this.delivery = this.data.split.master.orders.unique(function (o) { return o.addressDelivery; });
        this.addressSearch = this.addressService.getNewSearchService([], 15);
        //prepare initial Dispatch data
        data.dispatch = this.disp = {
            loadId: data.split.loadId,
            orderId: data.split.orderId,
            direct: this.master.direct,
            nonAMS: this.master.nonAMS,
            fromAddress: data.split.addressPickup || this.master.cargoBuilding,
            toAddress: data.split.addressDelivery || this.master.addressDelivery,
            datePickupEst: data.split.datePickupEst || addHours(new Date(), 4),
            pcs: data.split.pieces,
            hu: data.split.hu,
            driver: data.split.driver,
            trailer: data.split.trailer,
            truck: data.split.truck,
        };
        //    console.log('OPEN>', data.split, data.dispatch);
    }
    LoadDispatchDialogComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(LoadDispatchDialogComponent.prototype, "cancelEnable", {
        get: function () {
            return !this.readonly && !!this.load.id /* && !this.data.split.isReceived*/;
        },
        enumerable: true,
        configurable: true
    });
    LoadDispatchDialogComponent.prototype.onCancelDispatch = function () {
        var _this = this;
        this.dialogs.confirm(DialogType.CONFIRMATION, 'Cancel Dispatch?', 'The load will be deleted and all related data will be lost.\nContinue?').then(function (result) {
            switch (result.result) {
                case ModalResult.YES: {
                    _this.dialogRef.close({ result: ModalResult.DELETE });
                }
            }
        });
    };
    LoadDispatchDialogComponent.prototype.onOk = function (event) {
        if (!this.disp.driver) {
            this.alerts.warning('Please select Driver');
            event.canClose = false;
        }
        if (!this.disp.pcs) {
            this.alerts.warning('Pieces must be greater than 0');
            event.canClose = false;
        }
        // todo validate
    };
    LoadDispatchDialogComponent.prototype.onCreateTruck = function (number) {
        var _this = this;
        var truck = new Truck();
        truck.number = number;
        this.truckService.createEditAndSave(truck, this.readonly)
            .then(function (truck) { _this.disp.truck = truck; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    LoadDispatchDialogComponent.prototype.onCreateTrailer = function (number) {
        var _this = this;
        var trailer = new Trailer();
        trailer.number = number;
        this.trailerService.createEditAndSave(trailer, this.readonly)
            .then(function (trailer) { _this.disp.trailer = trailer; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    LoadDispatchDialogComponent.prototype.onCreateDriver = function (event) {
        var _this = this;
        var driver = new Driver();
        this.driverService.createEditAndSave(driver, this.readonly)
            .then(function (driver) { _this.disp.driver = driver; })
            .catch(function (error) { return _this.alerts.danger(error); });
    };
    Object.defineProperty(LoadDispatchDialogComponent.prototype, "direct", {
        get: function () {
            return this.disp.direct;
        },
        set: function (value) {
            if (this.disp.direct !== value) {
                this.disp.direct = value;
                this.disp.toAddress = this.disp.direct ? this.master.orders.asUniqueValue(function (o) { return o.addressDelivery; }) : this.master.orders.asUniqueValue(function (o) { return o.addressCfs; });
                this.cdr.markForCheck();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoadDispatchDialogComponent.prototype, "showPickup", {
        get: function () {
            return this.direct || this.nonAMS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoadDispatchDialogComponent.prototype, "nonAMS", {
        get: function () {
            return this.disp.nonAMS;
        },
        set: function (value) {
            if (this.disp.nonAMS != value) {
                this.disp.nonAMS = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoadDispatchDialogComponent.prototype, "masterRecoveryToAddressTypes", {
        get: function () {
            return this.disp.direct ? [AddressType.DELIVERY_LOCATION] : [AddressType.CFS_LOCATION];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoadDispatchDialogComponent.prototype, "masterAddressesRecoveryToDefault", {
        get: function () {
            return this.disp.direct ? this.master.orders.unique(function (o) { return o.addressDelivery; }) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    LoadDispatchDialogComponent.prototype.onPickupLocationChange = function (address) {
        this.nonAMS = address && address.cfs3plLocation;
    };
    return LoadDispatchDialogComponent;
}());
export { LoadDispatchDialogComponent };
