﻿import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OmsAlertsService} from '../oms-alerts/oms-alerts.service';
import {ManifestItem} from "../../models/manifest/manifest-item";

@Component({
  templateUrl: 'comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
  selector: 'oms-comment-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CommentDialogComponent implements OnInit {
  manifestItem: ManifestItem;

  form: FormGroup;

  hasFormErrors = false;

  constructor(public dialogRef: MatDialogRef<CommentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public alerts: OmsAlertsService) {
  }

  ngOnInit() {
    this.manifestItem = this.data.manifestItem;
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      comment: [this.manifestItem.comments]
    });
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && control.touched;
  }

  onSubmit() {
    this.hasFormErrors = false;
    const controls = this.form.controls;

    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach((controlName) => {
        controls[controlName].markAsTouched();
      });
      this.hasFormErrors = true;
      return;
    }

    const address = this.prepare();
    this.save(address);
  }

  prepare(): ManifestItem {
    const controls = this.form.controls;
    this.manifestItem.comments = controls['comments'].value;
    return this.manifestItem;
  }

  private save(manifestItem: ManifestItem) {
      this.dialogRef.close({manifestItem: manifestItem});
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
