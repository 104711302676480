/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./validation";
var styles_ValidationComponent = ["[_nghost-%COMP%] {\n      position: relative;\n      z-index: 9999;\n    }\n    .validation[_ngcontent-%COMP%] {\n      overflow: hidden;\n    }\n    .validation[_ngcontent-%COMP%]   .message[_ngcontent-%COMP%]{\n      white-space: nowrap;\n      text-overflow: ellipsis;\n    }"];
var RenderType_ValidationComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ValidationComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "translateX(0)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: null, timings: 100 }], options: null }, { type: 0, name: "out", styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, options: undefined }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: 100 }], options: null }], options: {} }] } });
export { RenderType_ValidationComponent as RenderType_ValidationComponent };
function View_ValidationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_ValidationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "validation invalid-feedback"]], [[24, "@flyInOut", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.messages; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 0, 0, currVal_0); }); }
export function View_ValidationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "validation", [], null, null, null, View_ValidationComponent_0, RenderType_ValidationComponent)), i0.ɵdid(1, 49152, null, 0, i2.ValidationComponent, [], null, null)], null, null); }
var ValidationComponentNgFactory = i0.ɵccf("validation", i2.ValidationComponent, View_ValidationComponent_Host_0, { messages: "messages" }, {}, []);
export { ValidationComponentNgFactory as ValidationComponentNgFactory };
