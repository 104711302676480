/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manifest-create-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/common/oms-spinner/oms-spinner.component.ngfactory";
import * as i3 from "../../../../components/common/oms-spinner/oms-spinner.component";
import * as i4 from "ngx-spinner";
import * as i5 from "../manifest-create-panel/manifest-create-panel.component.ngfactory";
import * as i6 from "../manifest-create-panel/manifest-create-panel.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../components/common/oms-dialogs/oms-dialogs.service";
import * as i9 from "../../../shared/components/oms-alerts/oms-alerts.service";
import * as i10 from "../../../../services/truck-service";
import * as i11 from "../../../../services/trailer-service";
import * as i12 from "../../../../services/driver-service";
import * as i13 from "../../../shared/services/dispatch/dispatch.service";
import * as i14 from "./manifest-create-page.component";
import * as i15 from "@angular/router";
var styles_ManifestCreatePageComponent = [i0.styles];
var RenderType_ManifestCreatePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManifestCreatePageComponent, data: {} });
export { RenderType_ManifestCreatePageComponent as RenderType_ManifestCreatePageComponent };
export function View_ManifestCreatePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "oms-spinner", [], null, null, null, i2.View_OmsSpinnerComponent_0, i2.RenderType_OmsSpinnerComponent)), i1.ɵdid(1, 573440, null, 0, i3.OmsSpinnerComponent, [i4.NgxSpinnerService], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "manifest-create-panel", [], null, [[null, "reset"], [null, "deleted"], [null, "created"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reset" === en)) {
        var pd_0 = (_co.loadManifest(_co.manifest.id) !== false);
        ad = (pd_0 && ad);
    } if (("deleted" === en)) {
        var pd_1 = (_co.onManifestDeleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("created" === en)) {
        var pd_2 = (_co.onManifestCreated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_ManifestCreatePanelComponent_0, i5.RenderType_ManifestCreatePanelComponent)), i1.ɵdid(3, 638976, [["editor", 4]], 0, i6.ManifestCreatePanelComponent, [i1.ChangeDetectorRef, i7.MatDialog, i4.NgxSpinnerService, i8.OmsDialogsService, i9.OmsAlertsService, i10.TruckService, i11.TrailerService, i12.DriverService, i13.DispatchService, i8.OmsDialogsService], { canClose: [0, "canClose"], allowAddShipments: [1, "allowAddShipments"], manifest: [2, "manifest"] }, { created: "created", deleted: "deleted", reset: "reset" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading || i1.ɵnov(_v, 3).loading); _ck(_v, 1, 0, currVal_0); var currVal_1 = false; var currVal_2 = false; var currVal_3 = _co.manifest; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ManifestCreatePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-manifest-create-page", [], null, null, null, View_ManifestCreatePageComponent_0, RenderType_ManifestCreatePageComponent)), i1.ɵdid(1, 114688, null, 0, i14.ManifestCreatePageComponent, [i15.ActivatedRoute, i13.DispatchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManifestCreatePageComponentNgFactory = i1.ɵccf("oms-manifest-create-page", i14.ManifestCreatePageComponent, View_ManifestCreatePageComponent_Host_0, {}, {}, []);
export { ManifestCreatePageComponentNgFactory as ManifestCreatePageComponentNgFactory };
