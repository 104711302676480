var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Exclude, Type } from 'class-transformer';
import { PackagingUnits } from '../../../common/oms-unit-types';
import { BaseEntity } from './base-entity';
import { Address } from './address';
import { Load, LoadType } from './load';
import { ShipmentContent } from './shipment.content';
import { isNullOrUndefined } from 'util';
import { User } from './user';
import { Master } from "./master/master";
import { absent, assigned } from "../../../_helpers/utils";
var ɵ0 = function () { return Date; }, ɵ1 = function () { return Date; }, ɵ2 = function () { return Date; }, ɵ3 = function () { return Date; }, ɵ4 = function () { return Date; }, ɵ5 = function () { return Date; }, ɵ6 = function () { return Date; }, ɵ7 = function () { return User; }, ɵ8 = function () { return PackagingUnits; }, ɵ9 = function () { return PackagingUnits; }, ɵ10 = function () { return Address; }, ɵ11 = function () { return Address; }, ɵ12 = function () { return Master; }, ɵ13 = function () { return Load; }, ɵ14 = function () { return ShipmentContent; }, ɵ15 = function () { return Date; }, ɵ16 = function () { return Date; };
var Shipment = /** @class */ (function (_super) {
    __extends(Shipment, _super);
    function Shipment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.javaClassName = 'com.oms.entity.Shipment';
        _this.shipmentContents = [];
        return _this;
    }
    Object.defineProperty(Shipment.prototype, "isDelivered", {
        get: function () {
            return !isNullOrUndefined(this.dateDeliveryActual);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Shipment.prototype, "isPickedUp", {
        get: function () {
            return !isNullOrUndefined(this.datePickUpActual);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Shipment.prototype, "isDispatched", {
        get: function () {
            return !isNullOrUndefined(this.load) && (!isNullOrUndefined(this.datePickUpExpectation) || !isNullOrUndefined(this.datePickUpActual));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Shipment.prototype, "isStaging", {
        get: function () {
            return this.load && assigned(this.datePickUpExpectation) && absent(this.load.driver) && absent(this.datePickUpActual);
        },
        enumerable: true,
        configurable: true
    });
    Shipment.prototype.isRecovery = function () {
        return this.load && this.load.loadType === LoadType.RECOVERY;
    };
    Shipment.prototype.isCompleted = function () {
        return (this.isRecovery() ? this.isPickedUp : this.isDelivered) && !this.hasProblem;
    };
    Shipment.prototype.isCompletedWithProblem = function () {
        return (this.isRecovery() ? this.isPickedUp : this.isDelivered) && this.hasProblem;
    };
    Shipment.prototype.isNotCompleted = function () {
        return !(this.isRecovery() ? this.isPickedUp : this.isDelivered) && this.hasProblem;
    };
    Shipment.JAVA_CLASS_NAME = 'com.oms.entity.Shipment';
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Date)
    ], Shipment.prototype, "datePickUpExpectation", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Date)
    ], Shipment.prototype, "datePickUpActual", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Date)
    ], Shipment.prototype, "dateDeliveryExpectation", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Date)
    ], Shipment.prototype, "dateDeliveryActual", void 0);
    __decorate([
        Type(ɵ4),
        __metadata("design:type", Date)
    ], Shipment.prototype, "dateConfirmedOn", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", Date)
    ], Shipment.prototype, "dateCompletedOn", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", Date)
    ], Shipment.prototype, "dispatchedForRecovery", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", User)
    ], Shipment.prototype, "dispatchedByUser", void 0);
    __decorate([
        Type(ɵ8),
        __metadata("design:type", PackagingUnits)
    ], Shipment.prototype, "hu_units", void 0);
    __decorate([
        Type(ɵ9),
        __metadata("design:type", PackagingUnits)
    ], Shipment.prototype, "pieces_units", void 0);
    __decorate([
        Type(ɵ10),
        __metadata("design:type", Address)
    ], Shipment.prototype, "addressPickUp", void 0);
    __decorate([
        Type(ɵ11),
        __metadata("design:type", Address)
    ], Shipment.prototype, "addressDelivery", void 0);
    __decorate([
        Type(ɵ12),
        __metadata("design:type", Master)
    ], Shipment.prototype, "master", void 0);
    __decorate([
        Type(ɵ13),
        __metadata("design:type", Load)
    ], Shipment.prototype, "load", void 0);
    __decorate([
        Type(ɵ14),
        __metadata("design:type", Array)
    ], Shipment.prototype, "shipmentContents", void 0);
    __decorate([
        Type(ɵ15),
        __metadata("design:type", Date)
    ], Shipment.prototype, "dateOnSitePickup", void 0);
    __decorate([
        Type(ɵ16),
        __metadata("design:type", Date)
    ], Shipment.prototype, "dateOnSiteDelivery", void 0);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Shipment.prototype, "isDelivered", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Shipment.prototype, "isPickedUp", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Shipment.prototype, "isDispatched", null);
    __decorate([
        Exclude(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], Shipment.prototype, "isStaging", null);
    return Shipment;
}(BaseEntity));
export { Shipment };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
