var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, KeyValueDiffers, } from '@angular/core';
import { MasterService } from '../../../../services/master/master.service';
import { ColumnIds, OmsColumns } from '../../../../common/oms-columns.service';
import { OmsAlertsService } from '../../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { AddressService, AuthService, OrdersService } from '../../../../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterProcessor } from '../../../../common/master-processor';
import { FileUploadService } from '../../../../services/file.upload.service';
import { MasterLineService } from '../../../../services/master/master-line.service';
import { FilterSearchColumn } from '../../../../modules/shared/models/filter.search.column';
import { AbstractMasterTable } from '../abstract-master-table';
import { FilterPageRequest } from '../../../../modules/shared/models/filter.page.request';
import { ColorItem } from '../../../../components/common/color-search-select/color-item';
import { SearchSortRequest } from '../../../../modules/shared/models/search.sort.request';
import { TabsType } from '../tabs-type';
import { convertMasterNumber, convertMawbNumber } from '../../../../modules/shared/services/oms-converters.service';
import { DialogType, ModalResult, OmsDialogsService } from "../../../../components/common/oms-dialogs";
import { NumberUtils } from '../../../../_helpers/number.utils';
import { Master, RecoveryOrder } from "../../../../modules/shared/models";
import { UserService } from "../../../../modules/shared/services/user.service";
import { assigned } from "../../../../_helpers/utils";
var MastersTableComponent = /** @class */ (function (_super) {
    __extends(MastersTableComponent, _super);
    function MastersTableComponent(valueDiffers, cdr, alerts, masterProcessor, _columnsService, addressService, _masterService, _masterLineService, spinner, fileUploadService, _ordersService, dialogsService, userService, authService) {
        var _this = _super.call(this, valueDiffers, cdr, alerts, masterProcessor, addressService, _masterService, fileUploadService, _ordersService, spinner, dialogsService, userService, authService) || this;
        _this.valueDiffers = valueDiffers;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.masterProcessor = masterProcessor;
        _this._columnsService = _columnsService;
        _this.addressService = addressService;
        _this._masterService = _masterService;
        _this._masterLineService = _masterLineService;
        _this.spinner = spinner;
        _this.fileUploadService = fileUploadService;
        _this._ordersService = _ordersService;
        _this.dialogsService = dialogsService;
        _this.userService = userService;
        _this.authService = authService;
        _this.filterPageRequest = new FilterPageRequest(1, 100, null, new SearchSortRequest('9', true), []);
        return _this;
    }
    MastersTableComponent.prototype.loadItemsByFilter = function (request) {
        request.modesFilter = this.modes;
        if (assigned(request.filterByColumns)) {
            request.filterByColumns.forEach(function (filterByColumn) {
                filterByColumn.value = filterByColumn.value != null ? filterByColumn.value.trim() : null;
            });
        }
        return this._masterLineService.getMastersByFilterSearchRequest(request);
    };
    MastersTableComponent.prototype.createColumns = function (isCanCreateCarrier) {
        return this._columnsService.createMasterDefaultColumns({
            isCarrier: (this.tabInfo !== TabsType.RECOVERY && this.tabInfo !== TabsType.RECEIVE),
            isArchived: this.tabInfo === TabsType.MASTERS_DELETED || this.tabInfo === TabsType.MASTERS_DELETED,
            displayCfsLocation: this.tabInfo === TabsType.ACTIVE || this.tabInfo === TabsType.ARCHIVED,
            displayPO: this.tabInfo === TabsType.ACTIVE,
            isCanCreateCarrier: isCanCreateCarrier,
            statuses: { available: this.tabInfo.statuses, selected: [] }
        });
    };
    MastersTableComponent.prototype.applyFilter = function (filterPageRequest) {
        // Apply additional filters
        this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_COLOR_STATUS, this.colorStatusFilter ? ('' + this.colorStatusFilter.status) : null);
        this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_DIRECT, this.showDirectMasters ? '1' : null);
        this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_PENDING_RECOVERIES, this.showPendingRecoveriesMasters ? '1' : null);
        if (this.showNonAmsMasters) {
            this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_NON_AMS, '1');
        }
        else if (this.showAmsMasters) {
            this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_NON_AMS, '0');
        }
        else {
            this.removeFilterValue(this.filterPageRequest, ColumnIds.MASTER_NON_AMS);
        }
        this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_IS_CARGO, this.showCargoBldg ? '1' : null);
        this.changeFilterValue(this.filterPageRequest, ColumnIds.MASTER_IS_3D_PARTY_CFS, this.show3rdPartyCFS ? '1' : null);
        if (this.availableStatuses.length) {
            var statusColumn = this.filterPageRequest.filterByColumns.find(function (column) { return column.field === 'status' || column.field === ColumnIds.MASTER_STATUS; });
            var statusesIds = this.selectedStatuses.map(function (selectedStatus) { return '' + selectedStatus.id; });
            if (!statusColumn) {
                this.filterPageRequest.filterByColumns.push(new FilterSearchColumn(ColumnIds.MASTER_STATUS, null, statusesIds, null));
            }
            else {
                statusColumn.multipleValues = statusesIds;
            }
        }
    };
    MastersTableComponent.prototype.onRestoreSelected = function () {
        var _this = this;
        if (this.selected.length > 0) {
            this.dialogsService.confirm(DialogType.CONFIRMATION, 'Restore Masters', "Selected " + this.selected.length + " Master(s) will be restored.\nContinue?")
                .then(function (result) {
                if (result.result === ModalResult.YES) {
                    var arr_1 = [];
                    var info_1 = '';
                    _this.selected.forEach(function (m) {
                        arr_1.push(m.id);
                        info_1 += '\n' + convertMasterNumber(m.id) + ' ' + convertMawbNumber(m.mawbNumber);
                    });
                    _this._masterService.restore(arr_1)
                        .then(function () {
                        _this.selected.clear();
                        _this.refresh();
                        _this.alerts.info(info_1 + ' restored.', 3000);
                    }).catch(function (e) {
                        _this.alerts.danger(e);
                    });
                }
            });
        }
    };
    MastersTableComponent.prototype.onDeleteSelected = function () {
        var _this = this;
        if (this.selected.length > 0) {
            this.dialogsService.confirm(DialogType.CONFIRMATION, 'Delete Masters', "Selected " + this.selected.length + " Master(s) will be deleted.\nContinue?")
                .then(function (result) {
                if (result.result === ModalResult.YES) {
                    var arr_2 = [];
                    var info_2 = '';
                    _this.selected.forEach(function (m) {
                        arr_2.push(m.id);
                        info_2 += '\n' + convertMasterNumber(m.id) + ' ' + convertMawbNumber(m.mawbNumber);
                    });
                    _this._masterService.softDelete(arr_2)
                        .then(function () {
                        _this.selected.clear();
                        _this.refresh();
                        _this.alerts.info(info_2 + ' deleted.', 3000);
                    }).catch(function (e) {
                        _this.alerts.danger(e);
                    });
                }
            });
        }
    };
    MastersTableComponent.prototype.updateTotalValues = function (items) {
        var totalPcs = 0;
        var totalHu = 0;
        var totalWeight = 0;
        var totalUld = 0;
        for (var i = 0; i < items.length; i++) {
            if (NumberUtils.isNumber(items[i].pieces)) {
                totalPcs += items[i].pieces;
            }
            if (NumberUtils.isNumber(items[i].hu_)) {
                totalHu += items[i].hu_;
            }
            if (NumberUtils.isNumber(items[i].weight)) {
                totalWeight += items[i].weight;
            }
            if (NumberUtils.isNumber(items[i].uldCount)) {
                totalUld += items[i].uldCount;
            }
        }
        this.totalPcs = totalPcs;
        this.totalHu = totalHu;
        this.totalWeight = totalWeight;
        this.totalUld = totalUld;
    };
    MastersTableComponent.prototype.getRowClass = function (row) {
        if (row instanceof RecoveryOrder) {
            return row.getRouteStatusClass();
        }
        if (row instanceof Master && row.cbpWithoutHawb) {
            return 'warning';
        }
        return '';
    };
    return MastersTableComponent;
}(AbstractMasterTable));
export { MastersTableComponent };
