import { Subject } from "rxjs";
import { BaseColumn, FieldType, HyperLinkColumn } from "../modules/shared/components/base/data-table/columns/column-types";
import { isNullOrUndefined } from "util";
import * as i0 from "@angular/core";
var CbqQuickViewStatusColumnsService = /** @class */ (function () {
    function CbqQuickViewStatusColumnsService() {
        this.cbpQuickViewStatusColumns = new Subject();
        this.init();
    }
    CbqQuickViewStatusColumnsService.prototype.getCbpQuickViewStatusColumns = function (view) {
        if (view === "MASTER") {
            this.cbpQuickViewStatusColumns.next(this.cbpQuickView1FStatusDefaultColumns);
        }
        else {
            this.cbpQuickViewStatusColumns.next(this.cbpQuickView1CStatusDefaultColumns);
        }
    };
    CbqQuickViewStatusColumnsService.prototype.init = function () {
        this.cbpQuickView1FStatusDefaultColumns = [
            new BaseColumn('transactionDate', 'Transaction Date', 'transactionDate', FieldType.DATETIME, '130px', { sorted: { asc: false } }).setHiddenColumn(true),
            new HyperLinkColumn('mawbNumber', 'Mawb', 'mawbNumber', FieldType.TEXT, '100px', {
                link: getCbpLink,
                sorted: { asc: true }
            }),
            new HyperLinkColumn('part', 'Part', 'part', FieldType.TEXT, '70px', {
                link: getCbpLink,
                converter: function (row, value) {
                    return isNullOrUndefined(value) ? '-' : value;
                }
            }),
            new BaseColumn('uscsFsnStatus', 'Status', 'uscsFsnStatus', FieldType.TEXT, '70px'),
            new BaseColumn('fsnSentDate', 'FSN Sent Date', 'fsnSentDate', FieldType.DATETIME, '130px'),
            new BaseColumn('totalPieces', 'Pieces', 'totalPieces', FieldType.TEXT, '70px')
        ];
        this.cbpQuickView1CStatusDefaultColumns = [
            new BaseColumn('transactionDate', 'Transaction Date', 'transactionDate', FieldType.DATETIME, '130px', { sorted: { asc: false } }).setHiddenColumn(true),
            new HyperLinkColumn('hawb', 'HAWB', 'hawb', FieldType.TEXT, '100px', { link: getCbpLink }),
            new HyperLinkColumn('part', 'Part', 'part', FieldType.TEXT, '70px', {
                link: getCbpLink,
                converter: function (row, value) {
                    return isNullOrUndefined(value) ? '-' : value;
                }
            }),
            new BaseColumn('uscsFsnStatus', 'Status', 'uscsFsnStatus', FieldType.TEXT, '70px'),
            new BaseColumn('fsnSentDate', 'FSN Sent Date', 'fsnSentDate', FieldType.DATETIME, '130px'),
            new BaseColumn('totalPieces', 'Pieces', 'totalPieces', FieldType.TEXT, '70px')
        ];
    };
    CbqQuickViewStatusColumnsService.ngInjectableDef = i0.defineInjectable({ factory: function CbqQuickViewStatusColumnsService_Factory() { return new CbqQuickViewStatusColumnsService(); }, token: CbqQuickViewStatusColumnsService, providedIn: "root" });
    return CbqQuickViewStatusColumnsService;
}());
export { CbqQuickViewStatusColumnsService };
var getCbpLink = function (row) {
    if (row.master) {
        return { path: ['/home', 'masters', 'master'], params: { id: row.master.id } };
    }
    return null;
};
var ɵ0 = getCbpLink;
export { ɵ0 };
