import * as i0 from "@angular/core";
var StateService = /** @class */ (function () {
    function StateService() {
    }
    StateService.prototype.saveOrdersState = function (state) {
        this.saveObject('state.order-list', state);
    };
    StateService.prototype.getOrdersState = function () {
        return this.getObject('state.order-list');
    };
    StateService.prototype.saveObject = function (key, state) {
        localStorage.setItem(key, JSON.stringify(state));
    };
    StateService.prototype.getObject = function (key) {
        var obStr = localStorage.getItem(key);
        if (!obStr) {
            return null;
        }
        return JSON.parse(obStr);
    };
    StateService.ngInjectableDef = i0.defineInjectable({ factory: function StateService_Factory() { return new StateService(); }, token: StateService, providedIn: "root" });
    return StateService;
}());
export { StateService };
