import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material';
import {OmsAlertsService} from '../../../shared/components/oms-alerts/oms-alerts.service';
import {isNullOrUndefined} from 'util';
import {Manifest} from '../../../shared/models/manifest/manifest';
import {Size} from '../../../../common/oms-types';
import {TruckService} from '../../../../services/truck-service';
import {TrailerService} from '../../../../services/trailer-service';
import {DriverService} from '../../../../services/driver-service';
import {ManifestItem} from '../../../shared/models/manifest/manifest-item';
import {Address, AddressType, Driver, LoadType, LoadTypes, Order, Trailer, Truck, Uld} from '../../../shared/models';
import {OrderDispatch} from '../../../shared/models/dispatch/order-dispatch';
import {DispatchService, DriverAction} from '../../../shared/services/dispatch/dispatch.service';
import {
  convertDateTime,
  convertLoadNumber,
  convertManifestNumber,
  convertMawbNumber,
  convertOrderNumber,
} from '../../../shared/services/oms-converters.service';
import {
  UldsDialogComponent,
  UldsDialogInputData
} from '../../../../components/common/ulds-dialog/ulds-dialog.component';
import {CustomSearchComponent} from '../../../shared/components/common/input/custom-search/custom-search.component';
import {DropEvent} from "ng-drag-drop";
import {NgModel} from "@angular/forms";
import {BaseInputComponent} from "../../../shared/components/base/base-input/base-input.component";
import {OmsConstants} from "../../../../common/oms-constants.service";
import {SplitOrderDialogComponent} from "./split-order-dialog/split-order-dialog.component";
import {
  BUTTONS_CONTINUE_CANCEL,
  DialogType,
  ModalResult,
  OmsDialogsService
} from "../../../../components/common/oms-dialogs";
import {AuthService, OrdersService} from "../../../../services";
import {DateTimeService} from "../../../../services/date-time.service";
import {UserService} from "../../../shared/services/user.service";
import {ISearchItemsFunction} from "../../../settings/util/search-items.function";
import {map} from 'rxjs/operators';
import {PageResult} from "../../../shared/models/query-models/page-result";
import {
  OrdersDispatchData,
  OrdersDispatchDialogComponent
} from "../../../../components/dialogs/orders-dispatch-dialog/orders-dispatch-dialog.component";
import {DateInputComponent} from "../../../shared/components/common/input/date-input/date-input.component";
import {absent, assigned, notEmptyString} from "../../../../_helpers/utils";
import {ContextMenuService} from "ngx-contextmenu";
import {ManifestItemContextMenuComponent} from "./manifest-item-context-menu/manifest-item-context-menu.component";
import {UserRoleType} from "../../../shared/models/user-role.type";
import {CarrierService} from "../../../shared/services/carrier.service";
import {
  CarrierDialogComponent
} from "../../../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import {Carrier} from "../../../shared/models/carrier";
import {DispatchUtils} from "../../../../_helpers/dispatch-utils";
import {Logger} from "../../../../_helpers/logger";
import {AbstractComponent} from "../../../../common/component/abstract.component";
import {OrderMode} from 'src/app/modules/shared/models/order/order-mode';

@Component({
  selector: 'manifest-create-content',
  templateUrl: './manifest-create-content.component.html',
  styleUrls: ['./manifest-create-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Logger({})
export class ManifestCreateContentComponent extends AbstractComponent implements OnInit, OnChanges {

  @Input('order-modes') public orderModes: OrderMode[] = [];
  @Input() public mergeToExisting: boolean = true;
  @Input() public isCreateFromWarehouse: boolean = false;
  @Input() public manifest: Manifest = new Manifest();
  @Input() public selected: OrderDispatch[] = [];
  @Output() public selectedChange = new EventEmitter<OrderDispatch[]>();
  @Output() public removeItems = new EventEmitter<ManifestItem[]>();
  @Output() public manifestChange = new EventEmitter<Manifest>();
  @Output() public created = new EventEmitter<void>();
  @Output() public refresh = new EventEmitter<void>();

  @Input() public loading: boolean;
  @Output() public loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public _isStaging: boolean;
  @Input('staging') set staging(value: boolean) {this.setStaging(value); }
  @Output('stagingChange') public stagingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(CustomSearchComponent) searchComponents: QueryList<CustomSearchComponent>;
  @ViewChildren(NgModel) inputs: QueryList<NgModel>;
  @ViewChild('dateInput') dateInput: DateInputComponent;
  @ViewChild('driverInput') driverInput: CustomSearchComponent;
  @ViewChild('menu') menu: ManifestItemContextMenuComponent;

  LoadTypes = LoadTypes;
  public total: number;
  public readonly: boolean = false;
  public items: OrderDispatch[] = [];
//  public loadTypes: LoadType[] = [LoadType.DIRECT, LoadType.DELIVERY, LoadType.X_DOCK];
  manifestItem: ManifestItem;

  private originalManifest: Manifest = null; // for Switching & Merging manifests
  searchDrivers: ISearchItemsFunction<Driver>;

  public canDeleteOrder: boolean = false;

  totalPcs: number = 0;
  totalHu: number = 0;
  totalWeight: number = 0;
  totalUld: number = 0;

  Size = Size;

  private startLoading() {
    this.loading = true;
    this.loadingChange.emit(this.loading);
  }


  private stopLoading() {
    this.loading = false;
    this.loadingChange.emit(this.loading);
  }


  convertOrderNumber = (orderId, isRecovery?) => convertOrderNumber(orderId, isRecovery);
  convertLoadNumber = (id) => convertLoadNumber(id);

  constructor(
    public dateTime: DateTimeService,
    public cdr: ChangeDetectorRef,
    private dialogs: OmsDialogsService,
    private dialog: MatDialog,
    private alerts: OmsAlertsService,
    private orderService: OrdersService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService,
    private dispatchService: DispatchService,
    public carrierService: CarrierService,
    public userService: UserService,
    public authService: AuthService,
    private contextMenuService: ContextMenuService,
    private dispatchUtils: DispatchUtils
  ) {
    super();
  }

  private initCanDeleteOrder() {
    this.canDeleteOrder = this.authService.canDeleteOrder();
  }


  ngOnInit() {
    this.initCanDeleteOrder();

    this.searchDrivers = (searchText: string) => {
      return this.userService.findUsersByRoles([UserRoleType.ROLE_DRIVER], true, searchText)
        .pipe(map((res) => {
          console.log('RESULT', res);
        return PageResult.fromArray(res.content.filter(user => user.driver).map(user => user.driver));
      }));
    };
  }

  private changed() {
    setTimeout(() => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
  }

  private setStaging(staging: boolean) {
    console.log('SET STAGING', staging);

    if (this._isStaging !== staging) {
      if (staging) {
        if (!this.manifest.isNew()) {
          if (this.manifest.allLoadsUpdated()) {
            // 1. The Route is fully updated: Deny staging
            this.dialogs.openErrorDialog('Completed orders cannot be moved to Staging', 'Warning', 5000).then();
            this.stagingChange.emit(false);

          } else if (this.manifest.someLoadsUpdated()) {

            // 2. The Route is partially updated: split Manifest
            let items: ManifestItem[] = this.manifest.getNotUpdatedLoads();
              let orders = items.flatMap((mi) => mi.orders).map((o) => convertOrderNumber(o.id)).join(', ');
              let itemsToSplit: number[] = items.map((mi) => mi.id);
              this.dialogs.confirm(DialogType.CONFIRMATION, 'confirm',
                'The following orders will be moved to a new staging manifest: \n' + orders, BUTTONS_CONTINUE_CANCEL)
                .then((r) => {
                  console.log('RESULT', r.result);
                  if (r.result === ModalResult.OK) {
                    console.log('PROCEED', items);
                    this.startLoading();
                    this.dispatchService.splitManifest(this.manifest.id, itemsToSplit)
                      .subscribe(
                        (split) => {
                          this.stopLoading();
                          this.manifest = this.prepareManifest(split.source);
                          this.dialogs.openInfoDialog(`${convertManifestNumber(split.target.id)} was added to the STAGING tab`, {mode: 'success', caption: 'Success', timeout: 5000});
                          this.updateManifest();
                          this.refresh.next();
                          this.cdr.markForCheck();
                        },
                        (error) => {
                          this.stopLoading();
                          this.alerts.error(error, 'Error Splitting Manifest...');
                          this.stagingChange.emit(false);
                        });
                  } else {
                    this.stagingChange.emit(false);
                  }
                });
              this.changed();
          } else {

            // 3. The Route is not updated
            this.manifest.driver = undefined;
            this.manifest.truck = undefined;
            this._isStaging = staging;
            this.stagingChange.emit(staging);
          }


        } else {
          this.manifest.driver = undefined;
          this.manifest.truck = undefined;
          this._isStaging = staging;
          this.stagingChange.emit(staging);
        }
      } else {
        this._isStaging = staging;
        this.stagingChange.emit(staging);
      }
      this.changed();
    }

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.manifest.items = this.manifest.items.filter(manifestItem => {
        let foundItem = this.selected.find(dispatchItem => manifestItem.equalsWithDispatchDto(dispatchItem));
        return !isNullOrUndefined(foundItem);
      });

//      let manifestItems = this.manifest.items;

      this.selected.forEach((dispatchItem) => {
        let foundItem = this.manifest.items.find((fi) => fi.equalsWithDispatchDto(dispatchItem));
        if (isNullOrUndefined(foundItem)) {
          console.log('ADDED HERE', foundItem);
          let mi: ManifestItem = ManifestItem.createFromDispatchDto(dispatchItem, dispatchItem.addressDelivery);
          mi.trailer = this.manifest.trailer;
          this.manifest.items.push(mi);
          this.manifest.enumerate();
        }
      });
    }

    if (changes.manifest) {
      if (this.manifest) {

        this.manifest.items.forEach((mi) => {
          mi.loadTypeOriginal = mi.loadType;
          mi.orders.forEach((o) => {
            o.info.legAddressDelivery = mi.addressDelivery;
          });
        });

        let staging = !this.manifest.isNew() && this.manifest.isStaging;
        if (this._isStaging !== staging) {
          this._isStaging = staging;
          this.stagingChange.emit(this._isStaging);
        }
      }
    }

    if (changes.selected || changes.manifest) {
      this.updateManifest();
      this.updateTotalValues(this.manifest.items);
    }
    if (changes.manifest && this.manifest) {
      if (!this.manifest.driver && !this.manifest.truck && this.searchComponents) {
        this.searchComponents.forEach(c => c.reset());
      }
    }
  }

  onCreateTruck(number: string) {
    const truck = new Truck();
    truck.number = number;
    this.startLoading();
    this.truckService.createEditAndSave(truck, this.readonly)
      .then((t) => {
        this.stopLoading();
        this.manifest.truck = t;
        this.updateManifest();
      })
      .catch(error => {
        this.stopLoading();
        this.alerts.danger(error);
      });
  }

  onCreateTrailer(number: string, container: {trailer: Trailer}) {
    const trailer = new Trailer();
    trailer.number = number;
    this.startLoading();
    this.trailerService.createEditAndSave(trailer, this.readonly)
      .then((t) => {
        this.stopLoading();
        container.trailer = t;
        this.updateManifest();
      })
      .catch(error => {
        this.stopLoading();
        this.alerts.danger(error);
      });
  }

  onCreateDriver(ignore) {
    const driver = new Driver();
    this.startLoading();
    this.driverService.createEditAndSave(driver, this.readonly)
      .then((d) => {
        this.stopLoading();
        this.manifest.driver = d;
        this.updateManifest();
      })
      .catch(error => {
        this.stopLoading();
        this.alerts.danger(error);
      });
  }

  onClearCarrier() {
/*    this.carrierService.getDefaultCarrier().subscribe((carrier) => {
      this.manifest.carrier = carrier;
      this.cdr.markForCheck();
      console.log('CARRIER FOUND', carrier);
    });*/
  }

  onCreateCarrier() {
    this.startLoading();
    const dialogRef = this.dialog.open<CarrierDialogComponent, any, { res: Carrier, isEdit: boolean }>(CarrierDialogComponent, {
      width: 'auto'
    });
    dialogRef.componentInstance.carrier = null;
    dialogRef.componentInstance.displayInHouse = false;
    return dialogRef.afterClosed()
      .subscribe(response => {
        this.stopLoading();
        this.manifest.carrier = response.res;
        this.updateManifest();
      }, (error) => {
        this.stopLoading();
        this.alerts.danger(error);
      });
  }

  onCustomerRefChange(manifestItem: ManifestItem, value: string): void {
    this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm', 'The REF will be changed in all records;\nContinue?', BUTTONS_CONTINUE_CANCEL).then(
      (result) => {
        if (result.result === ModalResult.OK) {
          manifestItem.customerRef = value;
          manifestItem.orders.forEach(o => {o.customerRef = value; });
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        } else {
          manifestItem.update();
          this.cdr.markForCheck();
          this.cdr.detectChanges();

        }
      });
  }

  onPoChange(manifestItem: ManifestItem, value: string): void {
    this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm', 'The PO will be changed in all records;\nContinue?', BUTTONS_CONTINUE_CANCEL).then(
      (result) => {
        if (result.result === ModalResult.OK) {
          manifestItem.orders.forEach((o) => {o.purchaseOrder = value; });
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        } else {
          manifestItem.update();
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        }
      });
  }


  onChangeInput(manifest: ManifestItem | Order, field: string, value: string) {
    manifest[field] = +value;
    this.updateTotalValues(this.manifest.items);
  }

  convertMawb(mawb: string): string {
    return convertMawbNumber(mawb);
  }

  updateTotalValues(selectedDispatch: ManifestItem[]) {
    let totalPcs = 0;
    let totalHu = 0;
    let totalWeight = 0;
    let totalUld = 0;

    selectedDispatch.forEach((d) => {
      totalPcs += d.pieces || 0;
      totalHu += d.hu || 0;
      totalWeight += d.weight || 0;
      totalUld += d.dispatchShipmentUlds.length;
    });

/*
    for (let i = 0; i < selectedDispatch.length; i++) {
      if (NumberUtils.isNumber(selectedDispatch[i].pieces)) {
        totalPcs += selectedDispatch[i].pieces;
      }
      if (NumberUtils.isNumber(selectedDispatch[i].hu)) {
        totalHu += selectedDispatch[i].hu;
      }
      if (NumberUtils.isNumber(selectedDispatch[i].weight)) {
        totalWeight += selectedDispatch[i].weight;
      }
      if (selectedDispatch[i].uldCount) {
        totalUld += selectedDispatch[i].uldCount;
      }
    } */

    this.totalPcs = totalPcs;
    this.totalHu = totalHu;
    this.totalWeight = totalWeight;
    this.totalUld = totalUld;
    this.cdr.markForCheck();
  }

  removeManifestItem(dispatchItem: ManifestItem) {
    if (!dispatchItem.isNew()) {
      this.startLoading();
      this.dispatchService.validateRemoveShipmentItem(dispatchItem.id)
        .subscribe(value => {
          this.stopLoading();
          if (value) {
            this.alerts.error({message: value}, 'Remove order', 10000);
          } else {
            this.removeDispatchItemFromDispatch(dispatchItem);
          }
        });
    } else {
      this.removeDispatchItemFromDispatch(dispatchItem);
    }
  }

  private removeDispatchItemFromDispatch(dispatchItem: ManifestItem) {
    this.manifest.items = this.manifest.items.filter(dispatchDto => dispatchItem !== dispatchDto);
    this.updateManifest();
    // this.selectedChange.emit(items);
    this.removeItems.emit([dispatchItem]);
  }

  trackByOrder(index: number, item: ManifestItem): any {
    if (!item) {
      return null;
    }

    if (item.id) {
      return item.id;
    }


    if (item.hawb) {
      return item.hawb;
    }
    return item;
  }

  openUldDialog(manifestItem: ManifestItem) {
    this.dialog.open(UldsDialogComponent, {
      width: '30%',
      data: {
        orderId: manifestItem.orderId
      } as UldsDialogInputData
    }).afterClosed().subscribe((ulds: Uld[]) => {
      if (ulds) {
        manifestItem.uldCount = ulds.length;
        this.updateTotalValues(this.manifest.items);
      }
    });
    /*let ulds = manifestItem.dispatchShipmentUlds;
    const dialogRef = this.dialog.open(UldsDialogComponent, {width: '30%', data:
        {uldsCount: ulds.length, ULDs: ulds, readonly: false} as UldsDialogInputData
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      manifestItem.dispatchShipmentUlds = res.ULDs;
      if (!this.manifest.isNew()) {
        let manifestUrlItems = manifestItem.dispatchShipmentUlds.map(uld => {
          let shipmentUld = new DispatchShipmentUld();
          shipmentUld.uldNumber = uld.uldNumber;
          return shipmentUld;
        });

        this.dispatchService.updateDispatchFields(this.manifest.id, manifestItem.id, 'ulds', JSON.stringify(manifestUrlItems))
          .subscribe(manifest => {
            this.manifest = manifest;
            this.updateManifest();
          });
      }
      this.updateTotalValues(this.manifest.items);
    });*/
  }

  upOrder(index: number) {
    if (index === 0) {
      return;
    }
    this.swapOrder(index, index - 1);
  }

  downOrder(index: number) {
    if (index === this.manifest.items.length - 1) {
      return;
    }
    this.swapOrder(index, index + 1);
  }

  swapOrder(index1: number, index2: number) {
    let first = this.manifest.items[index1];
    let second = this.manifest.items[index2];
    let firstNumber = first.orderNumber;
    first.orderNumber = second.orderNumber;
    second.orderNumber = firstNumber;
    this.updateManifest();
  }

  clearFields() {
    this.manifest.dateDispatchedFor = null;
    this.manifest.driver = null;
    this.manifest.truck = null;
    this.manifest.trailer = null;
    this.manifest.carrier = null;
    this.searchComponents.forEach(c => c.reset());
    this.manifestChange.emit(this.manifest);
  }

  public updateManifest() {
    this.manifest.update(true);
    this.manifestChange.emit(this.manifest);
    this.cdr.markForCheck();
  }

  onDropItem(target: ManifestItem, event: DropEvent) {
    console.log('DROP', event);

    let source: ManifestItem = event.dragData;
    let newIndex = target.orderNumber;

    this.manifest.items.removeAll(source);
    this.manifest.items.splice(newIndex, 0, source);
    let i = 0;
    this.manifest.items.forEach((mi) => mi.orderNumber = i++);
  }

  onLoadTypeChange(item: ManifestItem, loadType: LoadType) {
    let items: ManifestItem[] = this.getSelectedManifestItems(item);

    if (item && !items.includes(item)) {
      items.push(item);
    }

    items.forEach((i) => {
      console.log("HERE 1");
      i.loadType = loadType;
      i.orders.forEach((o) => {
        this.dispatchService.getLoadDeliveryAddressForOrder(o.id, loadType, o.genericMode)
          .then((a) => {
            o.info.legAddressDelivery = a;
            i.updateAddressDelivery();
            this.cdr.markForCheck();
          })
          .catch((error) => {
            i.loadType = i.loadTypeOriginal;
            this.cdr.markForCheck();
            this.alerts.error(error, "Can't switch Load Type");
          });
      });
    });
  }

  canChangeDelivery(item: ManifestItem): boolean {
    return !!item; // && [LoadType.X_DOCK, LoadType.PICKUP].includes(item.loadType);
  }

  canChangeDeliveryForOrder(item: ManifestItem): boolean {
    return !!item; // && [LoadType.X_DOCK, LoadType.PICKUP].includes(item.loadType);
  }

  private isFirstLeg(item: ManifestItem): boolean {
    return !assigned(item.loadTypePreviousLeg);
  }

  canChangePickup(item: ManifestItem): boolean {
    return !!item &&
      !(item.hasAirImport() && this.isFirstLeg(item)); // disable edit From Address for AI first leg
  }

  // todo: refactor it all
  getAllowedLoadTypes(item: ManifestItem): LoadType[] {
    console.log("TEST ITEM", item);

    if (this.isRecoveryDirect(item)) {
      return [LoadType.RECOVERY, LoadType.DIRECT];
    }

    if (item.hasAirImport()) {
      return [LoadType.DIRECT, LoadType.X_DOCK, LoadType.DELIVERY];
    }


    if (item.isNew()) {
      // New dispatch based on previous load type and locations
      if (!item.loadTypePreviousLeg) {
        return item.loadTypeOriginal === LoadType.RECOVERY ? [LoadType.RECOVERY, LoadType.DIRECT] :
          [LoadType.DIRECT, LoadType.PICKUP, LoadType.X_DOCK, LoadType.DELIVERY];
      }

      switch (item.loadTypePreviousLeg) {
        case LoadType.RECOVERY : return [LoadType.RECOVERY, LoadType.DIRECT];
//        case LoadType.DIRECT : return [LoadType.DIRECT, LoadType.PICKUP, LoadType.DELIVERY];
        case LoadType.PICKUP : return [LoadType.DIRECT, LoadType.PICKUP, LoadType.X_DOCK, LoadType.DELIVERY];
//        case LoadType.DELIVERY : return [LoadType.DELIVERY, LoadType.X_DOCK];
        case LoadType.X_DOCK : return [LoadType.X_DOCK, LoadType.DELIVERY];
        default: return [LoadType.PICKUP, LoadType.DELIVERY, LoadType.DIRECT];
      }
    }

    console.log("TEST ITEM", 3);

    // Edit existing
    switch (item && item.loadTypeOriginal) {
      case LoadType.RECOVERY : return [LoadType.RECOVERY, LoadType.DIRECT];
      case LoadType.DIRECT : return [LoadType.PICKUP, LoadType.DIRECT, LoadType.DELIVERY];
      case LoadType.PICKUP : return [LoadType.PICKUP, LoadType.DIRECT, LoadType.DELIVERY];
      case LoadType.DELIVERY : return [LoadType.X_DOCK, LoadType.DELIVERY];
      case LoadType.X_DOCK : return [LoadType.X_DOCK, LoadType.DELIVERY];
      default: return [];
    }
  }

  getDeliveryAddressTypes(manifestItem: ManifestItem): AddressType[] {
    return [AddressType.CFS_LOCATION, AddressType.CFS_3PL_LOCATION, AddressType.DELIVERY_LOCATION];
  }

  getPickupAddressTypes(manifestItem: ManifestItem): AddressType[] {
    return [AddressType.CFS_LOCATION, AddressType.CFS_3PL_LOCATION, AddressType.PICKUP_LOCATION];
  }

  validate() {
    return this.inputs.some((i) => {
      i.control.markAsTouched();
      if (i.valueAccessor instanceof BaseInputComponent) {
        i.valueAccessor.touchControl();
        i.valueAccessor.validate();
        return i.invalid;
      }
    });
  }

/*  public getOrderNumber(item: ManifestItem): string {
    if (item && item.orders) {
      if (item.orders.length === 1) {
        return convertOrderNumber(item.orders[0].id);
      }
      if (item.orders.length > 1) {
        return OmsConstants.MULTIPLE_VALUE;
      }
    }
    return OmsConstants.EMPTY_VALUE;
  } */

  public getItemMawb(item: ManifestItem): string {
    return item.orders.asUniqueValue((i) => convertMawbNumber(i.mawb), OmsConstants.MULTIPLE_VALUE);
  }

  public getItemHawb(item: ManifestItem): string {
    return item.orders.asUniqueValue((i) => i.hawb, OmsConstants.MULTIPLE_VALUE);
  }

  public getItemRef(item: ManifestItem): string {
    return item.orders.asUniqueValue((i) => i.customerRef, OmsConstants.MULTIPLE_VALUE);
  }

  public getItemPo(item: ManifestItem): string {
    return item.orders.asUniqueValue((i) => i.purchaseOrder, OmsConstants.MULTIPLE_VALUE);
  }


  public toggleManifestItemSelected(manifestItem: ManifestItem) {
    if (manifestItem.isSelectedFully ) {
      manifestItem.orders.forEach((o) => o.selected = false);
    } else {
      manifestItem.orders.forEach((o) => o.selected = true);
    }

    this.cdr.markForCheck();
  }

  public toggleManifestSelected() {
     if (this.selectedAll) {
      this.unselect();
    } else {
      this.selectAll();
     }

    this.cdr.markForCheck();
  }


  consolidate() {
    this.dispatchUtils.consolidateSelectedItems(this.manifest)
      .then( (result) => { if (result) { this.updateManifest(); }})
      .catch((error) => this.alerts.error(error));
  }

  unconsolidate() {
    this.dispatchUtils.unconsolidateSelectedItems(this.manifest)
      .then( (result) => { if (result) { this.updateManifest(); }})
      .catch((error) => this.alerts.error(error));

    this.manifest.items.forEach((mi) => {
      if (mi.hasSelected && mi.orders.length > 1) {
        let orders = [...mi.orders];
        orders.forEach((o: Order) => {
          if (o.selected && mi.orders.length > 1) {
//            console.log('UNCONSOLIDATE', o);
            mi.orders.removeAll(o);
// consolidate            mi.comments = mi.orders.map((o1) => o1.dispatchNotes).filter((s) => assigned(s)).join('\n');
            if (mi.orders.length === 1) {
              mi.comments = ManifestItem.composeDispatchComments(mi.orders[0]);
            }


            let item: ManifestItem = new ManifestItem();
            item.orders.push(o);
            item.pieces = o.pieces;
            item.hu = o.hu;
            item.weight = o.weight;
            item.loadType = mi.loadType;
            item.trailer = mi.trailer;
            item.addressPickUp = mi.addressPickUp;
            item.addressDelivery = mi.addressDelivery;
            item.customer = o.customer;
            item.customerRef = o.customerRef;
            item.freightForwarder = o.freightForwarder;
            item.uldCount = o.uldCount;
            item.comments = ManifestItem.composeDispatchComments(o);
            this.manifest.items.push(item);

            this.cdr.markForCheck();
          }
        });

        this.updateManifest();
      }
    });
  }

  validateManifestItem(manifestItem: ManifestItem): string {
    if (absent(manifestItem.addressPickUp)) {
      return "Pickup Address must be specified";
    }

    if (absent(manifestItem.addressPickUp)) {
      return "Delivery Address must be specified";
    }

    if (manifestItem.consolidated) {
      if (manifestItem.mixedDelivery) {
        return "Mixed Order Delivery Addresses";
      }
    }

    if (manifestItem.loadType === LoadType.DELIVERY) {
      if (!manifestItem.weight) {
        return "Recovery Load must have weight specified";
      }
    }
//    return null;
  }

  validateManifest(manifest: Manifest): string {
    if (absent(manifest.dateDispatchedFor) && !this._isStaging) {
      return 'Date is Mandatory unless Manifest is Staging';
    }
    if (absent(manifest.driver) && !this._isStaging && !this.isExternalCarrier()) {
      return 'Driver is Mandatory unless Manifest is Staging';
    }

    // Validate Manifest Items
    let messages: string[] = manifest.items.map(this.validateManifestItem).filter(notEmptyString);
    if (messages.length) {
      console.warn('Manifest Items', messages);
      return messages.first();
    }
  }

  getManifestItemAddressDeliveryName(manifestItem: ManifestItem): string {
    return manifestItem.orders.asUniqueValue((o) => o.info.legAddressDelivery && o.info.legAddressDelivery.label,
      OmsConstants.MULTIPLE_VALUE, OmsConstants.EMPTY_VALUE);
  }

  onManifestItemAddressDeliveryChange(manifestItem, address) {
    if (manifestItem.loadType === LoadType.DELIVERY) {
      this.dialogs.confirm(DialogType.CONFIRMATION, 'Confirm', 'The Delivery Address will be changed in all records;\nContinue?', BUTTONS_CONTINUE_CANCEL).then(
        (result) => {
          if (result.result === ModalResult.OK) {
            manifestItem.addressDelivery = address;
            manifestItem.orders.forEach(o => {
              o.info.legAddressDelivery = address;
              o.addressDelivery = address;
            });
            this.cdr.markForCheck();
            this.cdr.detectChanges();

          } else {
            manifestItem.update();
            this.cdr.markForCheck();
            this.cdr.detectChanges();
          }
        });

    } else {
      manifestItem.addressDelivery = address;
      manifestItem.orders.forEach(o => o.info.legAddressDelivery = address);
    }
  }

  onOrderAddressDeliveryChange(manifestItem: ManifestItem, order, address) {
    order.info.legAddressDelivery = address;
    manifestItem.addressDelivery = manifestItem.orders.asUniqueValue((o) => o.info.legAddressDelivery);
    this.cdr.markForCheck();
  }

  public selectAll() {
    let items: ManifestItem[]  = this.getDisplayManifestItems() || [];
    items.forEach((mi) => {
      mi.orders.forEach((o) => {o.selected = true; });
    });
  }

  public unselect() {
    this.manifest.items.forEach((mi) => {
      mi.orders.forEach((o) => {o.selected = false; });
    });
  }

  public get selectedSome(): boolean {
    let items: ManifestItem[]  = this.getDisplayManifestItems() || [];
    for (let mi of items) {
      for (let o of mi.orders) {
        if (o.selected) {
          return true;
        }
      }
    }
    return false;
  }

  public get selectedAll(): boolean {
    let items: ManifestItem[]  = this.getDisplayManifestItems() || [];

    for (let mi of items) {
      for (let o of mi.orders) {
        if (!o.selected) {
          return false;
        }
      }
    }
    return true;
  }


  public get selectedOneOrder(): Order {
    let selected = [];
    this.manifest.items.forEach(mi => selected.push(...mi.orders.filter(o => o.selected)));
    return selected.length === 1 ? selected[0] : null;
  }

  public splitOrder(order: Order) {
//    const originalPcs = order.pieces;
    this.dialogs.openDialog(SplitOrderDialogComponent, {
      order: order,
      onOk: (newPcs: number, newHu: number, newWeight: number) => {
        order.pieces = newPcs;
        order.hu = newHu;
        order.weight = newWeight;

        let mi: ManifestItem = this.manifest.findItem(order.id);
        mi.update();
        this.updateManifest();
        this.refresh.next();
        this.cdr.markForCheck();
      }}).then();
  }

  // Switch to received manifest & add new Items
  private mergeManifest(manifest: Manifest|null): Manifest {

    console.log('Merge Manifest', this.manifest, 'to', manifest);
    let date = this.manifest && this.manifest.dateDispatchedFor || new Date();
    let driver = this.manifest && this.manifest.driver;
    let truck = this.manifest && this.manifest.truck;
    let trailer = this.manifest && this.manifest.trailer;
    let carrier = this.manifest && this.manifest.carrier;
    let addedItems: ManifestItem[] = this.manifest.items.filter(mi => mi.isNew());

    if (!manifest) {
      if (!addedItems.length) {
        console.log('Nothing to Merge. Just switch Driver or Date');
//        return;
      } else {
        this.manifest = new Manifest();
        this.manifest.dateDispatchedFor = date;
        this.manifest.driver = driver;
        this.manifest.trailer = trailer;
        this.manifest.truck = truck;
        this.manifest.carrier = carrier;
      }
    } else {
      if (!this.originalManifest) {
        this.originalManifest = this.manifest;
      }

      this.manifest = manifest;

      if (this.manifest.id !== this.originalManifest.id) {
        let oldItems = this.originalManifest.items.filter((mi) => !mi.isNew());
        console.log('OLD', oldItems);
        this.manifest.items.push(...oldItems);
      }
    }

/*    let num = this.manifest.items.length;
    addedItems.forEach((mi) => {
      this.manifest.items.push(mi);
      mi.orderNumber = num++;
    }); */

    this.manifest.items.push(...addedItems);

    this.updateManifest();
    return this.manifest;
  }

  private prepareManifest(manifest: Manifest): Manifest {
    manifest.items.forEach((mi) => {
      mi.loadTypeOriginal = mi.loadType;
      mi.orders.forEach((o) => {o.info.legAddressDelivery = mi.addressDelivery; });
    });
    return manifest;
  }

  private switchManifest(newDate: Date, newDriver: Driver) {
    this.dispatchService.getDriverManifestOnDate(this.dateTime.utcDateOfMidnight(newDate), newDriver)
      .then(manifest => {

        if (!!manifest) {

          this.dialogs.confirm(DialogType.CONFIRMATION,
            "Merge to existing Manifest?",
            `Existing Manifest ${convertManifestNumber(manifest.id)}\n` +
            `for ${manifest.driver.fullName} on ${convertDateTime(manifest.dateDispatchedFor)} has found.\n\n` +
            `Merge selected Items to the found Manifest?`, BUTTONS_CONTINUE_CANCEL)
            .then(r => {
              if (r.result === ModalResult.OK) {
                this.mergeManifest(this.prepareManifest(manifest));
              } else {
                this.refreshData();
              }
            });

        } else {
          this.manifest.driver = newDriver;
          this.manifest.dateDispatchedFor = newDate;
        }

      }).catch(error => this.alerts.error(error));
  }

  onDriverChange(driver: Driver) {
    if (this.mergeToExisting && !!driver && !isNullOrUndefined(this.manifest.dateDispatchedFor)) {
      if (driver !== this.manifest.driver) {
        // avoid extra call on initial loading
        this.switchManifest(this.manifest.dateDispatchedFor, driver);
      }
    } else {
      this.manifest.driver = driver;
    }
  }

  onDateChange(date: Date) {
    if (this.mergeToExisting && !!this.manifest.driver && !isNullOrUndefined(date)) {
      if (date !== this.manifest.dateDispatchedFor) {
        // avoid extra call on initial loading
        this.switchManifest(date, this.manifest.driver);
      }
    } else {
      this.manifest.dateDispatchedFor = date;
    }
  }


  refreshData() {
    this.dateInput.writeValue(this.manifest && this.manifest.dateDispatchedFor);
    this.driverInput.writeValue(this.manifest && this.manifest.driver);
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  public getColorForOrder(obj: Order, manifestItem: ManifestItem): string {
    return '';

/*
    if (manifestItem.isCompleted()) {
      return '#dff0d8'; // green
    }

    else if (manifestItem.isCompletedWithProblem()) {
      return '#fcf8e3'; // yellow
    }

    else if (manifestItem.isNotCompleted()) {
      return 'red';
    }

    else {
      return '';
    } */
  }

  public getItemClass(manifestItem: ManifestItem) {
    return {
      'control-invalid': !!this.validateManifestItem(manifestItem),
      'alert-danger': manifestItem.isNotCompleted(),
      'alert-warning': manifestItem.isCompletedWithProblem(),
      'alert-success': manifestItem.isCompleted(),
    };
  }

  getFullAddressText(address: Address): string {
    return address ? address.fullAddress : undefined;
  }

  removeOrder(mi: ManifestItem, order: Order) {
    if (mi.orders.includes(order)) {
      if (mi.orders.length === 1 ) {
          this.removeManifestItem(mi);
      } else {

        this.startLoading();
        this.dispatchService.validateRemoveOrder(order.id, mi.id)
          .subscribe(value => {
            this.stopLoading();
            if (value) {
              this.alerts.error({message: value}, 'Remove order', 10000);
            } else {
              mi.orders.removeAll(o => o === order);
              this.updateManifest();
            }
          });

      }
    }
  }

  public getSelectedOrders(): any[] {
    let orders = [];
    this.manifest.items.forEach((mi) => {orders = orders.concat(mi.orders.filter((o) => o.selected)); });
    return orders;
  }

  private getSelected()  {
    let items = {items: [], orders: []};

//    let selected: ManifestItem[]  = this.getDisplayManifestItems() || [];

    this.manifest.items.forEach(mi => {
      if (mi.isSelectedFully) {
        items.items.push(mi);
      } else {
        mi.orders.forEach((o) => {
          if (o.selected) {
            items.orders.push(o);
          }
        });
      }
    });

    return items;
  }


  private moveSelectedItemsToManifest(target: Manifest): Promise<Manifest> {
    let source = Object.assign(new Manifest(), this.manifest);

    console.log('SOURCE', source);
    let items: ManifestItem[] = [...source.items];
    items.forEach(mi => {
      if (mi.isSelectedFully) {
        source.items.removeAll(i => i.id === mi.id);
        target.items.push(mi);
        mi.id = undefined;
      } else if (mi.isSelectedPartially) {
        let item: ManifestItem = Object.assign(new ManifestItem(), mi);
        item.id = undefined;
        item.orders = [];
        target.items.push(item);

        [...mi.orders].forEach(o => {
          if (o.selected) {
            mi.orders.removeAll(o);
            item.orders.push(o);
          }
        });

      }
    });
    source.update(true);
    target.update(true);

//    return this.dispatchService.redispatch(source, target);

    return this.dispatchService.dispatchOrders(source, false, false)
      .toPromise()
      .then((manifest) => this.dispatchService.dispatchOrders(target, false, false).toPromise());
  }

  public redispatchSelected(current?: ManifestItem) {

    let orders: Order[] = [];

    let hasNotCompleted = false;
    let notCompleted: ManifestItem;

    let items = this.getDisplayManifestItems() || [];
    items.forEach((mi) => {
      let o = mi.orders.filter((o1) => o1.selected);
      if (o && o.length) {
        orders = orders.concat(o);
        if (mi.isNotCompleted()) {
          hasNotCompleted = true;
          notCompleted = mi;
        }
      }
    });

    if (orders.length === 0 && !!current) {
      orders = [...current.orders];
      if (current.isNotCompleted()) {
        hasNotCompleted = true;
        notCompleted = current;
      }

    }

    if (this.selectedAll || this.manifest.items.length === 1) {
      this.alerts.warning(`Unable to redispatch all Manifest Routes!\nPlease update Date or Driver in the Manifest Form`, 5000);
    } else if (hasNotCompleted) {
      this.alerts.warning(`Not Completed Load ${convertLoadNumber(notCompleted.shipment.id)} cannot be redispatched`, 5000);
    } else if (orders.length === 0) {
      this.alerts.warning("No items selected!", 5000);
    } else {

      let data: OrdersDispatchData = {
        noCarrier: true,
        delivery: false,
        orders: orders,
        date: this.dateTime.utcDateOfMidnight(new Date()),
        driver: null,   // this.manifest.driver,
        trailer: null,  // this.manifest.trailer,
        truck: null,    // this.manifest.truck,
        carrier: null,    // this.manifest.carrier,
        manifestOriginal: this.manifest,
      };

      this.dialogs.openDialog(OrdersDispatchDialogComponent, data, {
        handler: (res) => {
          return new Promise((resolve, reject) => {

            if (res.result === ModalResult.OK) {
              let data1 = res.data;
              let manifest: Manifest = data1.manifestToMerge;

              if (!manifest) {
                // Create new Manifest
                manifest = new Manifest();
                manifest.driver = data1.driver;
                manifest.dateDispatchedFor = data1.date;
              }
              manifest.truck = data1.truck;
              manifest.trailer = data1.trailer;
              manifest.carrier = data1.carrier;

              this.startLoading();
              this.moveSelectedItemsToManifest(manifest)
                .then((r) => {
                  this.stopLoading();
                  this.alerts.success(`Selected items moved to ${manifest.isNew() ? 'new' : 'existing'} Manifest ${convertManifestNumber(r.id)}`, 10000);

                  this.manifest = this.prepareManifest(r);
                  this.updateManifest();
                  this.refresh.next();
                  this.cdr.markForCheck();

                  resolve(res); // close dialog
                })
                .catch((error) => {
                  this.stopLoading();
                  this.alerts.error(error);
                  reject(error);
                });

            } else {
              // Cancel - allow close;
              resolve(res);
            }
          });
        }
      }).catch(error => {
        this.alerts.error(error);
      });
    }
  }

  public driverAction(manifestItem: ManifestItem, action: DriverAction) {
    console.log(manifestItem, action);

    if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
      this.alerts.warning("The manifest is not dispatched");
      return;
    }

    let items: ManifestItem[] = this.getSelectedManifestItems(manifestItem);
    if (manifestItem && !items.includes(manifestItem)) {items.push(manifestItem); }
    items.forEach(mi => {mi.orders.forEach(o => o.selected = !mi.isNew()); });
    items = items.filter(mi => mi.isSelectedFully);

    if (items.length === 0) {
      this.alerts.warning("All selected items must be dispatched");
      return;
    }

    let ids: number[] = items.map(mi => mi.id).filter(n => assigned(n));

    let driverId =  this.manifest.driver.id;
    this.startLoading();
    this.dispatchService.routeDriverUpdate(driverId, ids, action /* Up to Server date , minDate(this.manifest.dateDispatchedFor, new Date())*/)
      .then((manifest: Manifest) => {
        this.stopLoading();
        this.alerts.success("Manifest Updated");

        this.manifest = this.prepareManifest(manifest);
        this.updateManifest();
        this.refresh.next();
        this.cdr.markForCheck();

      })
      .catch(error => {
        this.stopLoading();
        this.alerts.error(error);
      });
  }

  public driverActionCancel(manifestItem: ManifestItem) {
    console.log(manifestItem);

    if (!this.manifest || this.manifest.isNew() || !this.manifest.driver) {
      this.alerts.warning("The manifest is not dispatched");
      return;
    }

    let items: ManifestItem[] = this.getSelectedManifestItems(manifestItem);
    if (manifestItem && !items.includes(manifestItem)) {items.push(manifestItem); }
    items.forEach(mi => {mi.orders.forEach(o => o.selected = !mi.isNew()); });
    items = items.filter(mi => mi.isSelectedFully);

    if (items.length === 0) {
      this.alerts.warning("All selected items must be dispatched");
      return;
    }

    let ids: number[] = items.map(mi => mi.id).filter(n => assigned(n));
    this.startLoading();
    this.dispatchService.routeDriverUpdateCancel(ids)
      .then((manifest: Manifest) => {
        this.stopLoading();
        this.alerts.success("Manifest Updated");

        this.manifest = this.prepareManifest(manifest);
        this.updateManifest();
        this.refresh.next();
        this.cdr.markForCheck();

      })
      .catch(error => {
        this.stopLoading();
        this.alerts.error(error);
      });

  }

  public getSelectedManifestItems(current: ManifestItem): ManifestItem[] {
    let selected: ManifestItem[] = [];

    if (this.manifest) {
      this.manifest.items.forEach(mi => {
        if (mi.hasSelected) {
          selected.push(mi);
        }
      });
    }

    return selected.length > 0 ? selected : (current ? [current] : []);
  }

  public getMenuHeader(current: ManifestItem): string {
    let selected = this.getSelectedManifestItems(current);
    if (selected.length > 1) {
      return 'Multiple Selected';
    } else if (selected.length === 1) {
      return this.convertLoadNumber(selected[0].shipment ? selected[0].shipment.id : null);
    } else {
      return 'None Selected';
    }

  }

  public onCommentsInit(element: ElementRef<HTMLTextAreaElement>) {
    setTimeout(() => {
      element.nativeElement.style.height = '';
      element.nativeElement.style.height = element.nativeElement.scrollHeight + 3 + "px";
    }, 50);
//    console.log("change", event);
    // onchange='this.style.height = "";this.style.height = this.scrollHeight + 3+ "px"'
  }

  public generateBOL(current: ManifestItem, isOpenPDF: boolean) {
    let isNew = this.manifest.isNew() || !isNullOrUndefined(this.manifest.items.find((mi) => mi.id === undefined));
    let items: ManifestItem[] = this.getSelectedManifestItems(current);
    let orderIds = items.filter(mi => mi.id === undefined).flatMap((mi) => mi.orders.map(or => or.id));
    let manifestIds = items.filter(mi => mi.id !== undefined).map((mi) => mi.id);

    if (orderIds.length > 0) {
      this.orderService.generateBOLs(orderIds).subscribe(response => {
        this.generateBOLOpenPDF(response, isOpenPDF);
      });
    }
    if (manifestIds.length > 0) {
      this.dispatchService.generateBOLs(manifestIds).subscribe(response => {
        this.generateBOLOpenPDF(response, isOpenPDF);
      });
    }
  }

  public generateBOLOpenPDF(response, isOpenPDF: boolean) {
      if (!isNullOrUndefined(response)) {
        if (isOpenPDF) {
          let data = response.bytes;
          let fileName = "BOL.pdf";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
            let byteCharacters = atob(data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {type: 'application/pdf'});
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else { // much easier if not IE
            let pdfWindow = window.open("");
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data) + "'></iframe>");
          }
        }
        this.alerts.success('Generate BOL successful', 5000);
      } else {
        this.alerts.error('Error generate BOL');
      }
  }

  public onContextMenu($event: MouseEvent, manifestItem: ManifestItem, menu: ManifestItemContextMenuComponent) {
    this.manifestItem = manifestItem;
    this.contextMenuService.show.next({
      contextMenu: menu.contextMenu,
      event: $event,
      item: null
    });

    $event.preventDefault();
    $event.stopPropagation();
    this.cdr.markForCheck();
  }

  onLoading(loading: boolean) {
    if (loading) {
      this.startLoading();
    } else {
      this.stopLoading();
    }
  }

  onRefresh() {
    this.refresh.emit();
  }

  public isExternalCarrier(): boolean {
    return this.manifest && this.manifest.carrier && !this.manifest.carrier.inHouse;
  }

  public onManifestTrailerChange(trailer: Trailer) {
//    console.log('TRAILER', trailer);

    this.manifest.items.forEach((mi) => {
      mi.trailer = trailer;
      if (mi.shipment && mi.shipment.load) {
        mi.shipment.load.trailer = trailer;
      }
    });
  }

  public getDisplayManifestItems(): ManifestItem[] {
    return this.orderModes && this.orderModes.length > 0 ?
      this.manifest.items.filter(
        (mi) => assigned(mi.orders.find((o) => this.orderModes.hasEquals(o.genericMode))))
    : this.manifest.items;
  }

  public getDisplayManifestItemOrders(mi: ManifestItem): Order[] {
    return mi && mi.orders ?
      (this.orderModes && this.orderModes.length > 0 ? mi.orders.filter(
        (o) => this.orderModes.hasEquals(o.genericMode)) : mi.orders)
      : [];

  }

  public showAsConsolidated(item: ManifestItem): boolean {
    return item && item.consolidated;
  }

  public isRecoveryDirect(item: ManifestItem): boolean {
    return item && item.loadTypeOriginal === LoadType.DIRECT && item.orders.hasEquals((o) => o.isRecovery);
  }

}
