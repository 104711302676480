import {BaseEntity} from "../base-entity";
import {Carrier} from "../carrier";
import {Truck} from "../truck";
import {Trailer} from "../trailer";
import {Driver} from "../driver";
import {Type} from "class-transformer";

export class RouteInfo extends BaseEntity {
  @Type(() => Date)
  public dateRoute: Date;

  public masterId: number;

  public sealNumber: string;
  @Type(() => Carrier)
  public carrier: Carrier = new Carrier();
  @Type(() => Driver)
  public driver: Driver = new Driver();
  @Type(() => Truck)
  public truck: Truck = new Truck();
  @Type(() => Trailer)
  public trailer: Trailer = new Trailer();
}
