import {Directive} from '@angular/core';
import {NG_VALIDATORS, AbstractControl, Validator, FormControl, ValidationErrors} from '@angular/forms';
import {validUld} from '../../modules/shared/services/oms-converters.service';

@Directive({
  selector: '[uld-number]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UldNumberValidator, multi: true }]
})
export class UldNumberValidator implements Validator {

  static validateULDNumber(control: FormControl): ValidationErrors {
    if (!control.value) {  // the [required] validator will check presence, not us
      return null;
    }

    const value = control.value;
    let error;
    error = validUld(value, (msg) => error = {uld: msg}) ? null : error;
    return error;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {  // the [required] validator will check presence, not us
      return null;
    }

    const value = control.value;
    let error;
    return validUld(value, (msg) => error = {uld: msg}) ? null : error;


/*    // Example of how to do asynchronous validation
    return Observable.create(obs => {
      setImmediate(() => {
        obs.next({hexadecimal: 'Please enter a hexadecimal value (alphanumeric, 0-9 and A-F)'});
        obs.complete();
      });
    }); */
  }

}
