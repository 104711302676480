import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from "ngx-webcam";
import {LoggedUser} from "../../../modules/shared/models/logged-user";
import {Subject} from "rxjs/Subject";
import {UserUtil} from "../../../modules/settings/util/user.util";
import {Observable} from "rxjs/Observable";
import {assigned} from "../../../_helpers/utils";

@Component({
  selector: 'oms-camera',
  templateUrl: './oms-camera.component.html',
  styleUrls: ['./oms-camera.component.scss'],
  encapsulation: ViewEncapsulation.None})
export class OmsCameraComponent implements OnInit {
  @Input() public title = 'Take Picture';
  @Output() public pictureTaken = new EventEmitter<WebcamImage>();

  @Input() public images: WebcamImage[] = [];
  @Output() public imagesChange: EventEmitter<WebcamImage[]> = new EventEmitter<WebcamImage[]>();

  @Input() public docType: string = null;
  @Output() public docTypeChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() submit: EventEmitter<WebcamImage[]> = new EventEmitter<WebcamImage[]>();

  @Input() docTypes: string[] = [];
  viewLoading = false;
  loadingAfterSubmit = false;

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public currentImage: WebcamImage;
//  public mobHeight: any;
//  public mobWidth: any;

  public videoOptions: MediaTrackConstraints = {
    width: { min: 640, ideal: 2048/*1920*/, max: 2048/*4000*/},
    height: { min: 480, ideal: 1536/*1080*/, max: 1536/*3000*/},
    facingMode: "environment"
  };

  public errors: WebcamInitError[] = [];
  currentUser: LoggedUser;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor() {
//    this.mobHeight = window.innerHeight - window.innerHeight / 5;
//    this.mobWidth = window.innerWidth;
  }

  public ngOnInit() {
    this.showWebcam = true;

    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        this.viewLoading = false;
      });
    this.currentUser = UserUtil.getCurrentUser();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.showWebcam = false;
  }

  public onSubmit(): void {
    this.submit.emit(this.images);
  }

  public onDeleteImage(): void {
    if (this.currentImage) {
//      this.showWebcam = !this.showWebcam;
      let index = this.images.indexOf(this.currentImage);
      this.images.removeAll(this.currentImage);
      this.currentImage = this.images[index] || this.images[0];
      this.imagesChange.emit(this.images);
    }
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(image: WebcamImage): void {
    console.info('received webcam image', image);
    this.images.push(image);
    this.imagesChange.emit(this.images);
    this.currentImage = image;
    this.pictureTaken.emit(image);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public hasImages(): boolean {
    return this.images && this.images.length > 0;
  }

  public getImagesAdData(): string[] {
    return this.hasImages() ? this.images.map((i) => i.imageAsDataUrl) : [];
  }

  docTypeChanged(docType) {
    if (assigned(docType)) {
      this.docType = docType;
      this.docTypeChange.emit(this.docType);
    }
  }

}
