﻿import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {WizardHeaderConfigService} from "../../../services/wizard.header.config.service";
import {WizardHeader} from "../../../menu/wizard.header";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {isNullOrUndefined} from "util";


@Component({
    selector: 'wizard-header',
    templateUrl: 'wizard-header.component.html',
    styleUrls: ['./wizard-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class WizardHeaderComponent implements OnInit {

  currentPath: string;

  currentHeaders: any;
  visible: boolean = false;

  headers : any;

  constructor(public cdr:ChangeDetectorRef,
              private router: Router,
              private wizardHeaderConfigService: WizardHeaderConfigService) {}


  ngOnInit(): void {
    this.wizardHeaderConfigService.onMenuUpdated$.subscribe(items => {
      this.headers = items;
    });

    this.getCurrentHeaders(this.router.url);

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.getCurrentHeaders(val.url);
      }
    });
  }

  isActive(url: string){
    return url.indexOf(this.currentPath) > -1;
  }

  private getCurrentHeaders(url: string){
    this.currentPath = url;
    this.currentHeaders = (this.headers as any[]).find((header) => header.applyFor.indexOf(url) > -1);
    this.visible = !isNullOrUndefined(this.currentHeaders);
    this.cdr.markForCheck();
  }

}
