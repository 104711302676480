import {BaseDeletable} from "../base-deletable";
import {Labeled} from "../../../../common/oms-types";

export class SpecialService extends BaseDeletable implements Labeled {

  name: string;

  get label(): string {
    return name;
  }
}
