import {Injectable, Type} from '@angular/core';

export interface Position {
  x: number;
  y: number;
}


@Injectable()
export class ModalPositionCache {
  private _cache = new Map<Type<any>, Position>();

  set(dialog: Type<any>, position: Position) {
    console.log('STORE', position);
    this._cache.set(dialog, position);
  }

  get(dialog: Type<any>): Position|null {
    let result = this._cache.get(dialog);
    console.log('GET', result);
    return result;
  }
}
