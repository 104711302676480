import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


type PaneType = 'left' | 'right';

@Component({
  selector: 'oms-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
  animations: [
    trigger('left-pane', [
      state('left', style({ transform: 'translateX(0)', opacity: 1})),
      state('right', style({ transform: 'translateX(-100%)', opacity: 0, display: 'none'})),
      transition('* => *', [animate(300)])
    ]),

    trigger('right-pane', [
      state('left', style({ transform: 'translateX(100%)', opacity: 0, display: 'none'})),
      state('right', style({ transform: 'translateX(0)', opacity: 1})),
      transition('* => *', [animate(300)])
    ])
  ]
})
export class SlidePanelComponent implements OnInit, OnDestroy {
  @Input() activePane: PaneType = 'left';
  private unsubscribe$ = new Subject<void>();

  constructor(
    public cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(()=>this.cdr.markForCheck());
  }

  get isLeft(): boolean {
    return this.activePane === 'left';
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
