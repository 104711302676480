import {Type} from "class-transformer";
import {BaseDeletable} from "./base-deletable";
import {Driver} from "./driver";
import {Truck} from "./truck";
import {Trailer} from "./trailer";

export class DispatchAndRecoveryLog extends BaseDeletable {
  masterId: number;
  orderId: number;
  mawbNumber: string;
  hawb: string;

  pieces: number;
  hu: number;

  loadType: number;
  action: { id: number, name: string };

  @Type(() => Date)
  datePickUpActual: Date;

  @Type(() => Driver)
  driver: Driver;
  @Type(() => Truck)
  truck: Truck;
  @Type(() => Trailer)
  trailer: Trailer;
}
