<oms-custom-search [label]="label"
                   [size]="size"
                   [autoWidth]="false"
                   [maxWidth]="true"
                   [placeholder]="'Select Driver'"
                   [(ngModel)]="value"
                   (changing)="onSelect($event)"
                   [multiple]="multiple"
                   [disabled]="disabled"
                   [items]="search.items$ | async"
                   [loading]="search.loading"
                   [typeahead]="search.input$"
                   bindLabel="fullName"
                   [allowCreate]="false">
</oms-custom-search>
