import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {BaseColumn} from '../components/base/data-table/columns/column-types';
import {OmsAlertsService} from '../components/oms-alerts/oms-alerts.service';
import {CopyToClipboardItem} from '../../../common/copy-to-clipboard-orders';
import {copyToClipboard} from '../../../_helpers/utils';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import * as XLSX from 'xlsx';
import {OmsConstants} from '../../../common/oms-constants.service';
import {Master} from "../models";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const abcdString = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
declare var SheetClip: any;

@Injectable()
export class ClipboardCopyService {


  constructor(private alerts: OmsAlertsService) {
  }

  parseDataAndCopyToClipboard(data: any[]) {
    let str = SheetClip.stringify(data);
//    this.clipboardService.copyFromContent(str);
    copyToClipboard(str);
    this.alerts.success('Copied to clipboard');
  }

  prepareMasterManifestForCopyToClipboard(columns: BaseColumn[], masters: Master[], copyToClipboardItems: CopyToClipboardItem<Master>[]) {
    let parsedMasters = [];
    let header = [];
    let headerForCopy = [];

    header.push({label: 'MAWB/H', width: 20});
    headerForCopy.push('MAWB/H');

    header.push({label: 'From', width: 30});
    headerForCopy.push('From');

    header.push({label: 'Forwarder', width: 20});
    headerForCopy.push('Forwarder');

    header.push({label: 'To', width: 20});
    headerForCopy.push('To');

    header.push({label: 'PCS', width: 20});
    headerForCopy.push('PCS');

    header.push({label: 'Weight (kg)', width: 20});
    headerForCopy.push('Weight (kg)');

    let copyToClipboardData = [];
    copyToClipboardData.push(headerForCopy);


    let loadInfo: any = {};

    masters.forEach(master => {

      loadInfo.driverName = isNullOrUndefined(loadInfo.driverName) || loadInfo.driverName.length == 0 ? master.driverName : loadInfo.driverName;
      loadInfo.truckNumber = isNullOrUndefined(loadInfo.truckNumber) || loadInfo.truckNumber.length == 0 ? master.truckNumber : loadInfo.truckNumber;
      loadInfo.trailerNumber = isNullOrUndefined(loadInfo.trailerNumber) || loadInfo.trailerNumber.length == 0 ? master.trailerNumber : loadInfo.trailerNumber;


      let masterArr = [];
      let address = master.addressDelivery;
      masterArr.push(this.convertAndGetData(copyToClipboardItems, 0, master)); //MAWB
      masterArr.push(this.getIataCodeIfPresent(master.airport) + ":" + this.substringNameIfPresent(master.airline));
      masterArr.push(this.convertAndGetData(copyToClipboardItems, 1, master)); //FORWARDER
      masterArr.push(this.getNameIfPresent(address));

      masterArr.push(this.convertAndGetData(copyToClipboardItems, 2, master)); //PCS
      masterArr.push(this.convertAndGetData(copyToClipboardItems, 3, master)); //WEIGHT

      parsedMasters.push(masterArr);
      copyToClipboardData.push(masterArr);
    });

    this.parseDataAndCopyToClipboard(copyToClipboardData);
    this.exportAsExcelManifestFile(parsedMasters, header, loadInfo, "masterManifest");
    return parsedMasters;
  }

  convertAndGetData<T>(columns: CopyToClipboardItem<T>[], index: number, item: T): string {
    let column = columns[index];
    return column.value(item);
  }

  getNameIfPresent(object: any) {
    return isNullOrUndefined(object) ? "" : object.name;
  }

  substringNameIfPresent(object: any) {
    return isNullOrUndefined(object) ? "" : object.name.length > 15 ? object.name.substring(0, 15) : object.name;
  }

  getIataCodeIfPresent(object: any) {
    return isNullOrUndefined(object) ? "" : object.iataCode;
  }

  prepareItemsForCopyToClipboard<T>(items: T[], copyToClipboardColumns: CopyToClipboardItem<T>[], fileName: string): string[][] {
    console.log('prepareItemsForCopyToClipboard', fileName, copyToClipboardColumns);
    let copyToClipboardData: string[][] = [];
    copyToClipboardData.push(copyToClipboardColumns.map((column) => this.formatValueStr(column.label, column.width)));

    let convertedMasters = items.map(item => copyToClipboardColumns.map(column => (column.value(item) || 'N/A')));
    let formattedConvertedMasters = items.map(item => copyToClipboardColumns.map(column => this.formatValueStr((column.value(item) || 'N/A'), column.width)));
    copyToClipboardData.push(...formattedConvertedMasters);

    this.parseDataAndCopyToClipboard(copyToClipboardData);
    this.exportAsExcelFile(convertedMasters, copyToClipboardColumns, fileName);
    return convertedMasters;
  }

  private formatValueStr(value: any, width: number): string {
    let valueStr = String(value);
    let additionalCharsCount = Math.floor(width) - valueStr.length;
    let additionalChars = additionalCharsCount > 0 ? ' '.repeat(additionalCharsCount) : '';
    return value + additionalChars;
  }

  public exportAsExcelFile(json: any[], headers: IHeader[], excelFileName: string): void {
    console.log('HERE');

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    let wscols = [];
    headers.forEach((item, index) => {
      wscols[index] = {wch: item.width};
      worksheet[abcdString.charAt(index) + 1].v = item.label;
    });

    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelManifestFile(json: any[], header: any[], loadInfo: any, excelFileName: string): void {
    let data = [];
    data.push(['', 'Date:' + moment.tz(new Date(), OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT), '', 'Driver Name:' + loadInfo.driverName, '', '']);
    data.push(['', '', '', '', '', '']);
    data.push(['', 'Trailer #:' + loadInfo.trailerNumber, '', 'Truck #:' + +loadInfo.truckNumber, '', '']);
    data.push(['', '', '', '', '', '']);
    data.push(['', '', '', '', '', '']);
    json.forEach(item => {
      data.push(item)
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    let wscols = [];

    header.forEach((item, index) => {
      worksheet[abcdString.charAt(index) + 1].v = '';
    });

    header.forEach((item, index) => {
      wscols[index] = {wch: item.width};
      worksheet[abcdString.charAt(index) + 6].v = item.label;
    });

    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


}

interface IHeader {
  label: string;
  width: number;
}
