<oms-string-input [number]="true" [show-label]="showLabel" [label]="label" [placeholder]="_constants.weightPlaceholder"
                  [size]="size" [readonly]="readonly" [disabled]="disabled"
                  [input-mask]="_constants.weightMask" [(ngModel)]="internal"
                  [invalidValue]="isDisplayError()">

  <ng-container input-before>
    <ng-content select="[input-before]">
    </ng-content>
  </ng-container>

  <ng-container group-before>
    <ng-content select="[group-before]">
    </ng-content>
  </ng-container>


  <span input-after class="input-group-btn">
    <oms-select-button [(selected)]="units"
                       (selectedChange)="onUnitsChanged($event)"
                       [toggle]="true"
                       [items]="_constants.weightUnits.getAll()"></oms-select-button>
  </span>
</oms-string-input>
