import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {LoadTypes, MasterStatus, Order} from '../../../modules/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, OrdersService } from '../../../services';
import { Location } from '@angular/common';
import { OmsAlertsService } from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import { MasterService } from '../../../services/master/master.service';
import { OrderMode, OrderModes } from '../../../modules/shared/models/order/order-mode';
import { DispatchService } from '../../../modules/shared/services/dispatch/dispatch.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderDispatch } from '../../../modules/shared/models/dispatch/order-dispatch';
import {
  BaseColumn,
  DateTimeColumn,
  FieldType,
  TextColumn
} from '../../../modules/shared/components/base/data-table/columns/column-types';
import { ColumnType, downloadManifest } from '../../../common/column-type';
import { convertManifestNumber } from '../../../modules/shared/services/oms-converters.service';
import { FileUploadService } from "../../../services/file.upload.service";
import { ColumnWidth } from "../../../common/column-width";
import { FieldUpdateEvent } from "../../../modules/shared/components/base/data-table/data-table.utils";
import { DialogType, ModalResult, OmsDialogsService } from "../../../components/common/oms-dialogs";
import { MatDialog } from '@angular/material';
import { takeUntil } from "rxjs/operators";
import { AbstractComponent } from "../../../common/component/abstract.component";
import {Manifest} from "../../../modules/shared/models/manifest/manifest";
import {ManifestItem} from "../../../modules/shared/models/manifest/manifest-item";
import {DispatchUtils} from "../../../_helpers/dispatch-utils";

@Component({
  selector: 'oms-order-editor',
  templateUrl: './order-editor.component.html',
  styleUrls: ['./order-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderEditorComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input() order: Order;

//  public recoveringMasterId: number;
  private sub: Subscription[] = [];
  private snapshotParams: any;
  masterNotEditable: boolean = false;
  loads: OrderDispatch[];
  loadColumns: BaseColumn[] = [];
  type: string;

  isDisabled: boolean = false;
  isReadMode: boolean = false;

  public widthFirstPanel = 100;
  public widthSecondPanel = 0;
  public prevWidthFirstPanel = 35;
  public prevWidthSecondPanel = 65;
  public manifestForRightPanel: Manifest = new Manifest();

  public orderDispatchLoads: OrderDispatch[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    public alerts: OmsAlertsService,
    public location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private dispatchService: DispatchService,
    private ordersService: OrdersService,
    private authService: AuthService,
    private masterService: MasterService,
    private dialogs: OmsDialogsService,
    private file: FileUploadService,
    private dispatchUtils: DispatchUtils,
    public dialog: MatDialog) {
    super();
    this.authService.isReadMode()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isReadMode => this.isReadMode = isReadMode);
  }

  ngOnInit() {
    let columns = this.createLoadColumns();
    if (this.isReadMode) {
      columns = columns.map(c => c.clone().setEditor(false));
      columns[0].setButton(null);
    }
    this.loadColumns = columns;

    this.sub.push(this.route.queryParams.subscribe((params) => {
      let id = ~~params['id'];
      let newType = params['new'];
      if (!!newType && id) {
        this.masterNotEditable = true;
        this.getMasterAndCreateNewOrder(id);
      } else if (id) {
        this.loadOrder(id);
        this.loadOrderDispatches(id);
      } else {
        this.newOrder(newType);
      }
    }));

/*    this.snapshotParams = this.route.snapshot.params;
    this.sub.push(this.route.parent.params.subscribe(
      params => {

        let id = ~~this.snapshotParams['id'];
        let masterId = params['id'];
        let newType = this.snapshotParams['new'];
        this.type = newType;
        if (masterId && newType) {
          this.masterNotEditable = true;
          this.getMasterAndCreateNewOrder(masterId);
        } else if (id) {
          this.loadOrder(id);
          this.loadOrderDispatches(id);
        } else {
          this.newOrder(this.snapshotParams['new']);
        }
      })
    );
 */
  }

  private createLoadColumns(): BaseColumn[] {
    return [
      ColumnType.DISPATCHED_MANIFEST_ID.clone()
        .setButton({
          icon: 'fa fa-download',
          tooltip: 'Download Manifest',
          onClick: row => downloadManifest(this.file, row),
          newPage: true
        })
        .setSearch(false)
        .setHandlers({
          converter: (row, id) => convertManifestNumber(id),
          onClick: row => this.openManifest(row.dispatchId)
        })
        .setWidth('150px'),
      ColumnType.DISPATCHED_ADDRESS_PICKUP_NAME.clone()
        .setSearch(false)
        .setWidth('150px'),
      ColumnType.DISPATCHED_ADDRESS_DELIVERY_NAME.clone()
        .setSearch(false)
        .setWidth('150px'),
      new DateTimeColumn('dateOnSitePickup', 'On Site Pickup', 'dateOnSitePickup', ColumnWidth.DATE_TIME)
        .setEditor(true)
        .setSearch(false)
        .setWidth('150px'),
      new DateTimeColumn('datePickup', 'Pick Up', {
        actual: 'datePickupAct',
        estimated: 'datePickupEst'
      }, ColumnWidth.DATE_TIME)
        .setEditor(true)
        .setSearch(false)
        .setWidth('150px'),
      new DateTimeColumn('dateOnSiteDelivery', 'On Site Delivery', 'dateOnSiteDelivery', ColumnWidth.DATE_TIME)
        .setEditor(true)
        .setSearch(false)
        .setWidth('150px'),
      new DateTimeColumn('dateDelivery', 'Delivery', {
        actual: 'dateDeliveryAct',
        estimated: 'dateDeliveryEst'
      }, ColumnWidth.DATE_TIME)
        .setEditor(true)
        .setSearch(false)
        .setWidth('150px'),
      new TextColumn('driver', 'Driver', 'driver.fullName', FieldType.TEXT, '150px', {
        class: () => 'driver'
      }).setSearch(false),

      ColumnType.DISPATCHED_CREATED_BY.clone()
        .setSearch(false)
        .setWidth('150px'),
      ColumnType.DISPATCHED_DATE_CREATED.clone()
        .setSearch(false)
        .setWidth('150px'),

      new TextColumn('loadType', 'Load Type', 'loadType', FieldType.TEXT, '150px', {
        converter: (row, value) => LoadTypes.labelOf(value)
      }).setSearch(false),

      new TextColumn('status', 'Load Status', (od: OrderDispatch) => od.getRouteStatus(), FieldType.TEXT, '150px', {}).setSearch(false),
    ];
  }

  private loadOrderDispatches(orderId: number) {
    this.dispatchService.findOrderDispatches(orderId).then((list ) => {
        this.loads = list;
        this.cdr.markForCheck();
      }
    );

    /*    forkJoin([
          this.dispatchService.findDispatchedLoadsObs(this.createLoadRequest(orderId, ViewMode.ACTIVE)),
          this.dispatchService.findDispatchedLoadsObs(this.createLoadRequest(orderId, ViewMode.COMPLETED)),
          this.dispatchService.findOrderDispatches(orderId)
        ])
          .subscribe(responses => {
            this.loads = responses[0].content.concat(responses[1].content).concat(responses[2].content);
            this.cdr.markForCheck();
          }); */
  }

/*
  private createLoadRequest(orderId: number, viewMode: ViewMode) {
    let filterByColumn = new FilterSearchColumn('orderId', '' + orderId);
    let request = new FilterPageRequest(1, 100, '',
      new SearchSortRequest('orderId', true), [filterByColumn]);
    request.addNamedCondition(viewMode);
    return request;
  }

 */

  private getMasterAndCreateNewOrder(id: number) {
    this.masterService.getMaster(id).then(master => {
      this.order = Order.createOrderByMaster(master);
    }, error => {
      this.alerts.danger(error);
    });
  }

  private loadOrder(id: number) {
    this.ordersService.getOrder(id)
      .then(
        (order) => {
          console.log("Loaded Order", order);
          this.order = order;
          if (order && order.isRecovery) {
            this.ordersService.getMasterInfoByRecoveryOrder(id).subscribe((info) => {
              this.order.data.recoveringMasterId = info && info.id;
            });
          }
          this.cdr.detectChanges();
        })
      .catch(
        (error) => {
          if (error.status === 403) {
            this.router.navigate(['/404'], {replaceUrl: true});
          } else {
            this.alerts.danger(error);
          }
        });
  }

  private newOrder(type) {
    switch (type) {
      case 'generic':
        this.order = Order.newGenericOrder();
        break;
      case 'AIR_EXPORT':
        this.order = Order.newGenericOrder(OrderMode.AEDIR);
        break;
      case 'FCL':
        this.order = Order.newGenericOrder(OrderMode.FCLEX);
        break;
      default:
        this.order = Order.newOrder(type);
    }
  }

  public get title(): string {
    return !this.order ? null : (this.order.isNew() ? 'New Order' : this.order.orderNumber);
  }

  public get orderStatus(): string {
    return status = this.order && MasterStatus.getLabel(this.order.status);
  }
  public get orderMode(): string {
    return (this.order && this.order.isGeneric) ? OrderModes.labelOf(this.order.genericMode) : 'Air Import';
  }


  ngOnDestroy(): void {
    this.sub.forEach(s => s.unsubscribe());
  }

  onSave() {
    if (!this.order.pieces && this.order.hu) {
      this.order.pieces = this.order.hu;
    }
    if (!this.order.hu && this.order.pieces) {
      this.order.hu = this.order.pieces;
    }
    if (!this.order.pieces) {
      this.alerts.error('PCS should be more then 0', 'Error');
      return true;
    }
    if (typeof this.order.pieces === 'number' && typeof this.order.hu === 'number' && this.order.hu > this.order.pieces) {
      this.alerts.error('HU should be less then PCS', 'Error');
      return true;
    }
    this.dispatchService.validateOrderDispatch(this.order).subscribe(res => {
      if (res && res.warnings) {
        this.displayWarnings(res.warnings)
          .then(confirm => {
            if (confirm) {
              this.saveOrder();
            }
          });
      } else {
        this.saveOrder();
      }
    });
  }

  private displayWarnings(warnings: string[]): Promise<boolean> {
    if (!warnings.length) {
      return Promise.resolve<boolean>(true);
    }
    let ws = [...warnings];
    let message = ws.shift();
    return this.dialogs.confirm(DialogType.CONFIRMATION, 'Order save?', message).then(result => {
      switch (result.result) {
        case ModalResult.YES: {
          return this.displayWarnings(ws);
        }
      }
      return Promise.resolve<boolean>(false);
    });
  }

  saveOrder() {
    if (this.order.isNew()) {
      this.spinner.show();
      this.ordersService.create(this.order).toPromise().then(
        order => {
          this.spinner.hide();
          this.onSuccess(order);
          this.order = order;
          this.loadOrderDispatches(order.id);

          this.alerts.success('New order ' + order.orderNumber + ' has been created', 5000);
          this.cdr.markForCheck();
        },

        error => {
          this.spinner.hide().then();
          this.alerts.error(error);
        });


    } else {
        this.spinner.show();
        this.dispatchService.updateOrderDispatch(this.order).toPromise().then(
          order => {
            this.spinner.hide();
            this.onSuccess(order);
            this.order = order;
            this.loadOrderDispatches(order.id);
            this.alerts.success('Order ' + order.orderNumber + ' has been updated', 5000);
            this.cdr.markForCheck();
          },
          error => {
            this.spinner.hide();
            this.alerts.error(error);
          });
    }
  }

  onSuccess(order: Order) {
    if (this.masterNotEditable) {
      this.masterService.refreshDataRequired.next(order.shipmentContents[0].shipment.master);
    }
  }

  updateField(event: FieldUpdateEvent<OrderDispatch>) {
//    let dispatchId = event.row.dispatchId;
//    let dispatchShipmentId = event.row.dispatchShipmentId;
    this.isDisabled = true;
    this.cdr.markForCheck();


    let shipment = this.order.shipmentContents.map(sc => sc.shipment).find((s) => s.id === event.row.shipmentId);
    if (shipment) {
      switch (event.field) {
        case 'datePickupAct':
          shipment.datePickUpActual = event.newValue;
          break;
        case 'datePickupEst':
          shipment.datePickUpExpectation = event.newValue;
          break;
        case 'dateDeliveryAct':
          shipment.dateDeliveryActual = event.newValue;
          break;
        case 'dateDeliveryEst':
          shipment.dateDeliveryExpectation = event.newValue;
          break;
        case 'dateOnSitePickup':
          shipment.dateOnSitePickup = event.newValue;
          break;
        case 'dateOnSiteDelivery':
          shipment.dateOnSiteDelivery = event.newValue;
          break;

      }
    }
    this.isDisabled = false;
    this.cdr.markForCheck();

  }

/*
  private findManifestItem(dispatch: Manifest, order: Order, orderDispatch: OrderDispatch): ManifestItem {
    return dispatch.items.find(i => {
      return i.hasOrder(order.id) && this.equals(orderDispatch.addressPickup, i.addressPickUp) && this.equals(orderDispatch.addressDelivery, i.addressDelivery);
    });
  }

 */

/*
  private equals(a1: BaseEntity, a2: BaseEntity) {
    if (a1 == null && a2 == null) {
      return true;
    }
    if (a1 == null || a2 == null) {
      return false;
    }
    return a1.id === a2.id;
  }

 */

  public getRowClass(row: OrderDispatch): string {
    let result = 'row-dispatch';

    if (!(row instanceof OrderDispatch)) {
      return result;
    }


    if (row.dispatchCancelled) {
      result += ' dispatch-cancelled';
    }

    if (row.isDispatched) {
      result += row.isConfirmed ? ' confirmed' : ' not-confirmed';
    }

    if (row.isCompleted()) {
      result += ' success';
    } else if (row.isCompletedWithProblem()) {
      result += ' warning';
    } else if (row.isNotCompleted()) {
      result += ' danger';
    }

    return result;
  }

  changePrev(first: number, second: number): void {
    if (first <= 90) {
      this.prevWidthFirstPanel = first;
      this.prevWidthSecondPanel = second;
    }
  }

  dragEnd(event): void {
    this.widthFirstPanel = event.sizes[0];
    this.widthSecondPanel = event.sizes[1];
    this.changePrev(event.sizes[0], event.sizes[1]);
  }

  onManifestChange(manifest: Manifest): void {
    if (manifest != null && manifest.id > 0) {
      console.log('CHANGED', manifest);

      this.loadOrder(this.order.id);
      this.loadOrderDispatches(this.order.id);

      let items = this.orderDispatchLoads.filter(dispatchDto => manifest.items.some(manifestItem => manifestItem.hasOrder(dispatchDto.orderId)));
      this.setSelected(items);
      this.updateRightPanel();
    }
  }

  onDispatchListSelectionChange(list: OrderDispatch[]): void {
    this.orderDispatchLoads = list;
    list.forEach((od: OrderDispatch) => {
      let created: ManifestItem = this.manifestForRightPanel.items.find((mi) => mi.orders.some(o => o.id === od.orderId));
      if (!created) {
        //   this.setOrderDispatchValuesFromOrder(od);
        this.dispatchUtils.manifestAddOrderDispatch(this.manifestForRightPanel, od);
      }
    });

    // remove from new Items only
    const manifestItems = this.manifestForRightPanel.items.filter((i) => i.isNew());
    manifestItems.forEach((mi) => {
      mi.orders.forEach((o) => {
        let selected = list.find((od) => od.orderId === o.id);
        if (!selected) {
          this.dispatchUtils.manifestDeleteOrder(this.manifestForRightPanel, o);
        }
      });
    });

    this.setDispatchListToRightPanel([...this.manifestForRightPanel.items]);
  }

  private setDispatchListToRightPanel(items: ManifestItem[]): void {
    this.manifestForRightPanel.items = items;
    this.manifestForRightPanel = Object.assign(new Manifest(), this.manifestForRightPanel);
    this.updateRightPanel();
  }


  private setSelected(items: OrderDispatch[]): void {
    this.orderDispatchLoads = items;
  }

  private updateRightPanel() {
    let list = this.manifestForRightPanel.items;
    if (!list.length && this.widthFirstPanel < 100) {
      this.changePrev(this.widthFirstPanel, this.widthSecondPanel);
      this.widthFirstPanel = 100;
      this.widthSecondPanel = 0;
    } else if (list.length && this.widthFirstPanel === 100) {
      this.widthFirstPanel = this.prevWidthFirstPanel;
      this.widthSecondPanel = this.prevWidthSecondPanel;
    }
  }

  onCreatedEvent(manifest: Manifest): void {
    this.manifestForRightPanel = new Manifest();
    this.setSelected([]);
    this.setDispatchListToRightPanel([]);
  }

  openManifest(dispatchId: number) {
    if (dispatchId) {
      this.spinner.show();
      console.log('openManifest');
      this.dispatchService.get(dispatchId)
        .subscribe(manifest => {
          this.spinner.hide();
          console.log('manifest received', manifest);
          manifest.items.forEach(mi => {
            mi.loadTypeOriginal = mi.loadType;
            mi.orders.forEach(o => {
              mi.loadTypeOriginal = mi.loadType;
              o.info.legAddressDelivery = mi.addressDelivery;
            });
          });
          this.manifestForRightPanel = manifest;
          this.updateRightPanel();
          this.cdr.detectChanges();
        }, error => {
          this.spinner.hide();
          this.alerts.error(error, 'Error open Manifest');
        });
    }
  }

  public onDispatch() {
    if (this.order && this.order.id) {
//      this.spinner.show();
      this.dispatchService.findDispatchesForOrders([this.order.id])
        .then((list) => {
//        this.spinner.hide();
          this.onDispatchListSelectionChange(list);
          if (this.manifestForRightPanel) {
            this.manifestForRightPanel.carrier = this.order.carrier;
          }
          this.cdr.detectChanges();
        })
        .catch((error) => {
//        this.spinner.hide();
          this.alerts.error(error);
        });
    }
  }

}
