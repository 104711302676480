var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject, Subject } from 'rxjs';
import { RestService } from '../rest.service';
import { HttpUtilsService } from '../http-utils.service';
import { map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { classToPlain, plainToClass } from 'class-transformer';
import { OmsAlertsService } from '../../modules/shared/components/oms-alerts/oms-alerts.service';
import { Master, MasterStatusId, Order } from '../../modules/shared/models';
import { BaseEntityService } from "../base/base-entity.service";
import { StringUtil } from "../../_helpers/string.util";
import { assigned } from "../../_helpers/utils";
var MasterService = /** @class */ (function (_super) {
    __extends(MasterService, _super);
    function MasterService(alerts, 
    //    private http: HttpClient,
    httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.alerts = alerts;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/master/';
        _this.classType = Master;
        //  public mastersSubject = new Subject<Master[]>();
        _this.refreshDataRequired = new Subject();
        _this.mastersPageSubject = new Subject();
        _this.newMasterCreated = new BehaviorSubject(false);
        return _this;
    }
    MasterService.prototype.getMastersByFilterSearchRequest = function (filterPageRequest, onError) {
        var _this = this;
        console.time('Page Request');
        this.lastPageRequest = filterPageRequest;
        this.httpRestService.postWithHeader(this.apiUrl + 'findMastersByFilters', this.httpUtils.getHTTPHeaders(), filterPageRequest).subscribe(function (page) {
            console.timeEnd('Page Request');
            console.time('Page plainToClass');
            page.content = plainToClass(Master, page.content).map(function (master) { return _this.afterLoad(master); });
            console.timeEnd('Page plainToClass');
            console.time('Subject Next');
            _this.mastersPageSubject.next(page);
            console.timeEnd('Subject Next');
        }, function (error) {
            onError(error);
        });
    };
    MasterService.prototype.refreshByLastPageRequest = function () {
        this.getMastersByFilterSearchRequest(this.lastPageRequest, function () { });
    };
    MasterService.prototype.getMaster = function (id, activeOnly) {
        var _this = this;
        if (activeOnly === void 0) { activeOnly = true; }
        var params = this.httpUtils.getHTTPParams({ active: activeOnly });
        return this.httpRestService.getWithOptions(this.apiUrl + 'getMaster/' + id, this.httpUtils.getHTTPHeaders(), params) // .get<Master>(API_URL + 'getMaster', id)
            .pipe(map(function (master) {
            console.log('LOADED: ', master);
            return _this.afterLoad(plainToClass(Master, master));
        }))
            .toPromise();
    };
    MasterService.prototype.getMastersByRecoveryOrderId = function (id, activeOnly) {
        var _this = this;
        if (activeOnly === void 0) { activeOnly = true; }
        var params = this.httpUtils.getHTTPParams({ active: activeOnly });
        return this.httpRestService.getWithOptions(this.apiUrl + 'get-by-recovery-order/' + id, this.httpUtils.getHTTPHeaders(), params) // .get<Master>(API_URL + 'getMaster', id)
            .pipe(map(function (objects) { return plainToClass(Master, objects); }), map(function (masters) { return masters.map(function (master) { return _this.afterLoad(master); }); }))
            //      .pipe(map((masters) => masters.map(master => this.afterLoad(master))))
            .toPromise();
    };
    MasterService.prototype.updateMaster = function (id, fields) {
        return this.updateMasterObs(id, fields).toPromise();
    };
    MasterService.prototype.updateMasterObs = function (id, fields) {
        var _this = this;
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'updateMaster/' + id, fields, httpHeader)
            .pipe(map(function (master) { return _this.afterLoad(plainToClass(Master, master)); }));
    };
    MasterService.prototype.updateMasterProblems = function (id, problems) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'updateMasterProblems/' + id, problems, httpHeader).toPromise();
    };
    MasterService.prototype.createMasterPTT = function (item) {
        return this.httpRestService.post(this.apiUrl + 'createMasterPTT', item);
    };
    MasterService.prototype.downloadMasters = function (filterPageRequest, alerts) {
        var _this = this;
        var uuid = StringUtil.generateUuid();
        alerts.process('Generating Excel...', '', function () { return _this.getProgress(uuid); }, 5000);
        return this.httpRestService.post(this.apiUrl + 'downloadMasters', filterPageRequest, {
            params: { uuid: uuid }
        }).pipe(tap(function () { }, function (error) { alerts.error(error, "Error Excel Generation:"); }));
    };
    MasterService.prototype.mastersByIDs = function (masterIDs) {
        var _this = this;
        var result = new Subject();
        this.httpRestService.post(this.apiUrl + 'mastersByIDs', masterIDs)
            .subscribe(function (items) {
            result.next(plainToClass(Master, items).map(function (master) { return _this.afterLoad(master); }));
        });
        return result;
    };
    MasterService.prototype.downloadNominationMasters = function (masterIDs) {
        return this.httpRestService.post(this.apiUrl + 'downloadNominationMasters', masterIDs);
    };
    MasterService.prototype.downloadMastersManifest = function (filterPageRequest) {
        return this.httpRestService.post(this.apiUrl + 'downloadMastersManifest', filterPageRequest);
    };
    MasterService.prototype.downloadMastersManifestByManifest = function (masterManifestExcelRequest) {
        return this.httpRestService.post(this.apiUrl + 'downloadMastersManifestByMasterRequest', masterManifestExcelRequest);
    };
    MasterService.prototype.downloadOrdersDeliveryManifest = function (request) {
        return this.httpRestService.post(this.apiUrl + 'downloadOrdersDeliveryManifest', request);
    };
    MasterService.prototype.buildDownloadUrl = function (fileType) {
        return this.httpRestService.buildUrlForDownload(this.apiUrl + 'downloadMastersInformation', fileType);
    };
    MasterService.prototype.buildDownloadMasterUrl = function (fileType, id) {
        return this.httpRestService.buildUrlForDownload(this.apiUrl + 'downloadMaster/' + id, fileType);
    };
    MasterService.prototype.validateRecoveryLoadPieces = function (master, split, pcsRecovered) {
        if (pcsRecovered <= 0) {
            this.alerts.danger('Recovery count should be greater than 0', 2000);
            return false;
        }
        var num = master.pieces || 0;
        master.splits.forEach(function (s) {
            num -= s.isVirtual ? 0 : s.pcsToRecover || 0;
        });
        if (num < 0) {
            this.alerts.danger('Recovery count should not exceed total number of pieces', 2000);
            return false;
        }
        return true;
    };
    MasterService.prototype._updateMaster = function (master, update) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getMaster(master.id)
                .then(function (m) {
                if (update(m)) {
                    _this.update(m).toPromise()
                        .then(function (m1) { return resolve(_this.afterLoad(plainToClass(Master, m1))); })
                        .catch(function (error) { return reject(error); });
                }
                else {
                    reject();
                }
            })
                .catch(function (error) { return reject(error); });
        });
    };
    MasterService.prototype.searchByMawb = function (mawb, count) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = new HttpParams().set('by', mawb);
        if (count) {
            params.set('count', count.toString());
        }
        return this.httpRestService.getWithOptions(this.apiUrl + 'searchByMawb', httpHeader, params);
    };
    MasterService.prototype.cancelLoadDispatch = function (masterId, loadId, orderId) {
        var _this = this;
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = this.httpUtils.getHTTPParams({ orderId: orderId });
        return this.httpRestService.put(this.apiUrl + 'cancelLoadDispatch/' + masterId + '=' + loadId, null, httpHeader, params)
            .pipe(map(function (master) { return _this.afterLoad(plainToClass(Master, master)); }))
            .toPromise();
    };
    MasterService.prototype.dispatchLoadRecovery = function (master, data, save) {
        var _this = this;
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = new HttpParams()
            .set('masterId', master.id.toString())
            .set('save', save ? '1' : '0');
        return this.httpRestService.put(this.apiUrl + 'dispatchLoadRecovery', classToPlain(data, { enableCircularCheck: true }), httpHeader, params)
            .pipe(map(function (m) { return _this.afterLoad(plainToClass(Master, m)); }))
            .toPromise();
    };
    MasterService.prototype.updateLoadDate = function (master, load, field, date) {
        var _this = this;
        //    console.log('date', date, typeof date);
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = new HttpParams()
            .set('masterId', master.id.toString())
            .set('loadId', load.id.toString())
            .set('field', field)
            .set('date', date ? date.toJSON() : null);
        return this.httpRestService.put(this.apiUrl + 'updateLoadDate', '', httpHeader, params)
            .pipe(map(function (m) { return _this.afterLoad(plainToClass(Master, m)); }))
            .toPromise();
    };
    MasterService.prototype.updateOrderDate = function (master, order, field, date) {
        var _this = this;
        console.log('date', date, typeof date);
        var httpHeader = this.httpUtils.getHTTPHeaders();
        var params = new HttpParams()
            .set('orderId', order.id.toString())
            .set('field', field)
            .set('date', date ? date.toJSON() : null);
        if (master) {
            params = params.set('masterId', master.id.toString());
        }
        return this.httpRestService.put(this.apiUrl + 'updateOrderDate', '', httpHeader, params)
            .pipe(map(function (m) { return _this.afterLoad(plainToClass(Master, m)); }))
            .toPromise();
    };
    MasterService.prototype.updateAirline = function (masterShipmentAirId, airlineId) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'updateMasterAirline/' + masterShipmentAirId + '=' + airlineId, null, httpHeader)
            .toPromise();
    };
    MasterService.prototype.updateFlightNumber = function (masterShipmentAirId, flightNumber) {
        var httpHeader = this.httpUtils.getHTTPHeaders();
        return this.httpRestService.put(this.apiUrl + 'updateMasterFlightNumber/' + masterShipmentAirId + '=' + flightNumber, null, httpHeader)
            .toPromise();
    };
    MasterService.prototype.dispatchMastersRecovery = function (masterIds, date, driver, truck, trailer, pieces, hu) {
        var _this = this;
        var params = new HttpParams();
        if (driver) {
            params = params.set('driver', driver.id.toString());
        }
        if (truck) {
            params = params.set('truck', truck.id.toString());
        }
        if (trailer) {
            params = params.set('trailer', trailer.id.toString());
        }
        if (date) {
            params = params.set('date', date.toJSON());
        }
        if (pieces > 0) {
            params = params.set('pieces', pieces.toString());
        }
        if (hu > 0) {
            params = params.set('hu', hu.toString());
        }
        return this.httpRestService.put(this.apiUrl + 'dispatchMastersRecovery', masterIds, this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (masters) { return plainToClass(Master, masters).map(function (m) { return _this.afterLoad(m); }); }))
            .toPromise();
        //      .then((response) => response.map(m => Master.afterLoad(plainToClass(Master, <Object>m))));
    };
    MasterService.prototype.dispatchOrdersDelivery = function (orderIds, date, driver, truck, trailer, carrier) {
        var params = new HttpParams();
        if (driver) {
            params = params.set('driver', driver.id.toString());
        }
        if (truck) {
            params = params.set('truck', truck.id.toString());
        }
        if (trailer) {
            params = params.set('trailer', trailer.id.toString());
        }
        if (carrier) {
            params = params.set('carrierId', carrier.id.toString());
        }
        if (date) {
            params = params.set('date', date.toJSON());
        }
        return this.httpRestService.put(this.apiUrl + 'dispatchOrdersDelivery', orderIds, this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (orders) { return plainToClass(Order, orders); }))
            .toPromise();
    };
    MasterService.prototype.pickupMasterRecovery = function (master, loads, masterId) {
        var _this = this;
        var masterID = assigned(master) ? master.id.toString() : masterId.toString();
        var params = new HttpParams().set('master', masterID);
        return this.httpRestService.put(this.apiUrl + 'pickupMasterRecovery', classToPlain(loads), this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (m) { return _this.afterLoad(plainToClass(Master, m)); }))
            .toPromise();
    };
    MasterService.prototype.cancelMastersRecovery = function (masterIds) {
        var _this = this;
        var params = new HttpParams();
        return this.httpRestService.put(this.apiUrl + 'cancelMastersRecovery', masterIds, this.httpUtils.getHTTPHeaders(), params)
            .pipe(map(function (masters) { return plainToClass(Master, masters).map(function (master) { return _this.afterLoad(master); }); }))
            .toPromise();
    };
    MasterService.prototype.isDisableMaster = function (master) {
        var _this = this;
        if (!master.nonAMS) {
            return master.orders.some(function (order) { return _this.isDisableOrder(order); });
        }
        return false;
    };
    MasterService.prototype.isDisableOrder = function (order) {
        if (!order.nonAMS) {
            return order.status <= MasterStatusId.ONHAND_COMPLETE_PENDING_1C;
        }
        return false;
    };
    return MasterService;
}(BaseEntityService));
export { MasterService };
