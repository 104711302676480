﻿import {Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {
  FormBuilder,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgModel
} from '@angular/forms';
import {BaseInputComponent} from '../../../modules/shared/components/base/base-input/base-input.component';
import {Master, Uld} from '../../../modules/shared/models';
import {isNullOrUndefined} from "util";
import {convertDateTime, convertMawbNumber} from "../../../modules/shared/services/oms-converters.service";
import {MasterService} from "../../../services/master/master.service";
import {Size} from '../../../common/oms-types';
import {FileUploadService} from "../../../services/file.upload.service";
import {Router} from "@angular/router";
import {Load} from '../../../modules/shared/models';
import {toFixed} from '../../../_helpers/utils';
import {UldNumberValidator} from "../../../common/validators/uld-number.validator";
import {MasterPtt} from '../../../services/master/master-ptt';
import {MasterPTTReceiveAt} from '../../../services/master/master-ptt-receive-at';
import {OmsConstants} from "../../../common/oms-constants.service";
import {OmsAlertsService} from "../../../modules/shared/components/oms-alerts/oms-alerts.service";


@Component({templateUrl: 'master.ptt.creator.dialog.component.html',
            styleUrls: ['./master.ptt.creator.dialog.component.scss'],
            selector: 'oms-master-ptt-creator-dialog'})
export class MasterPttCreatorDialogComponent  extends BaseInputComponent<any> implements OnInit {

  public static MAX_ULD_FIELDS = 16;

  @ViewChild(NgModel) model: NgModel;

  master: Master;
  load: Load;
  pcs: number;
  weight: number;
  public readonly: boolean = false;

  Size = Size;

  form: FormGroup;

  public isSarcona: boolean = false;
  hasFormErrors: boolean = false;
  loadingMessage: string;
  loadingAfterSubmit: boolean = false;
  generatedDocumentInfo: any;
/*
  subCustomer: boolean = false;
*/

  constructor(public dialogRef: MatDialogRef<MasterPttCreatorDialogComponent>,
              @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private alerts: OmsAlertsService,
              private masterService: MasterService,
              private router: Router,
              private fileUploadService: FileUploadService,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    this.master = this.data.master;
    this.readonly = this.data.readonly;
    this.load = this.data.load;
    this.pcs = this.data.pcs || this.data.master.pieces;
    this.weight = this.data.weight || this.data.master.weight;

    this.isSarcona = !!this.master.addressCfs && (this.master.addressCfs.id === OmsConstants.SARCONA_CFS_ID);
    this.createForm();
    this.initCfsProperties(this.isSarcona);

    if (this.data.generatePTTAndClose) {
       this.onSubmit();
    }

  }

  private get driverName() {
    return this.load && this.load.driver ? this.load.driver.fullName : "";
  }

  private get truckNumber() {
    return this.load && this.load.truck ? " TRK#:" + this.load.truck.number : "";
  }

  private get trailerNumber() {
    return this.load && this.load.trailer ? " TRL#:" + this.load.trailer.number : "";
  }

  initCfsProperties(isSarcona: boolean) {
    const cfsProperties = OmsConstants.CFS_PROPERTIES[isSarcona ? 1 : 0];
    const controls = this.form.controls;
    controls['containerSectionName'].setValue(cfsProperties.contactName);
    controls['address1'].setValue(cfsProperties.address1);
    controls['address2'].setValue(cfsProperties.address2);
    controls['address3'].setValue(cfsProperties.address3);
  }

  createForm() {
    this.form = this.fb.group({
      forwarder: [this.getFieldIfObjectNotNull(this.master.freightForwarder, ["name"])],
      customer: [this.master.customerNameBasedOnOrders],
      other: [this.driverName + this.truckNumber + this.trailerNumber],
      airportOfDestination: [this.getFieldIfObjectNotNull(this.master.airport, ["iataCode"])],
      airline: [this.getFieldIfObjectNotNull(this.master.airline, ["name"])],
      flight: [this.master.flightNumber],
      arrivalDate: [convertDateTime(this.arrivalOrEstDate)],
      airportOfOrigin: [this.getFieldIfObjectNotNull(this.master.masterAir, ["originalAirport", "iataCode"])],

      containerSectionName: [""], // Here goes TO name
      address1: [""],
      address2: [""],
      address3: [""],

      mawb: [convertMawbNumber(this.getFieldIfObjectNotNull(this.master, ["masterAir" , "mawbNumber"]))],
      totalAMSPieces: [this.master.pieces],
      totalKG: [toFixed(this.master.weight, 1)],
      countOfHawbs: [this.master.orders.length],
      issuer: [""],
      comment: [""],
      /*receivedAtAirlineConfirmation: [""],
      receivedAtCfsConfirmation: [""],*/

      recoveryAtPersonName: [""],
      recoveryAtTrackAndTrailer: [""],
      recoveryAtDateAndTime: [""],
      recoveryAtPieces: [""],
      recoveryAtNotation: [""],

      receivedAtPersonName: [""],
      receivedAtTrackAndTrailer: [""],
      receivedAtDateAndTime: [""],
      receivedAtPieces: [""],
      receivedAtNotation: [""],
    });

    for (let i = 0; i < MasterPttCreatorDialogComponent.MAX_ULD_FIELDS; i++) {
      let uldValue = this.getULDFromList(this.master.ulds, i);
      this.form.setControl('uldInfo' + (i + 1), this.fb.control(uldValue, UldNumberValidator.validateULDNumber));
    }
  }

  getULDFromList(ulds: Uld[], index: number): string {
    if (ulds.length > index) {
      return ulds[index].uldNumber;
    }
    return '';
  }

  get arrivalOrEstDate() {
    let arrivalDateAct = this.master.dateArrivalAct;
    return !isNullOrUndefined(arrivalDateAct) ? arrivalDateAct : this.master.dateArrivalEst;
  }



  getFieldIfObjectNotNull(object, items) {
    if (isNullOrUndefined(object)) {
      return "";
    }

    if (items.length > 1) {
      return this.getFieldIfObjectNotNull(object[items.shift()], items);
    }
    return object[items[0]];
  }

  getTitle(): string {
    return 'Master PTT Creator';
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && control.touched;
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;
    const controls = this.form.controls;
    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const masterPTT = this.prepare();
    console.log('PREPARE PTT', masterPTT);
    this.create(masterPTT, true);
  }

  prepare(): MasterPtt {
    const controls = this.form.controls;
    const masterPTT = {} as MasterPtt;
    masterPTT.id = this.master.id;
    masterPTT.forwarder = controls['forwarder'].value;
    masterPTT.customer = controls['customer'].value;
    masterPTT.other = controls['other'].value;
    masterPTT.airportOfDestination = controls['airportOfDestination'].value;
    masterPTT.airline = controls['airline'].value;
    masterPTT.flight = controls['flight'].value;
    masterPTT.arrivalDate = controls['arrivalDate'].value;
    masterPTT.airportOfOrigin = controls['airportOfOrigin'].value;
    masterPTT.address1 = controls['address1'].value;
    masterPTT.address2 = controls['address2'].value;
    masterPTT.address3 = controls['address3'].value;
    masterPTT.isSarcona = this.isSarcona;

    let uldNumbers = [];
    for (let i = 0; i < MasterPttCreatorDialogComponent.MAX_ULD_FIELDS; i++) {
      let uldValue = controls['uldInfo' + (i + 1)].value;
      if (uldValue) {
        uldNumbers.push(uldValue);
      }
    }
    masterPTT.uldNumbers = uldNumbers;
    masterPTT.mawb = controls['mawb'].value;
    masterPTT.totalAMSPieces = controls['totalAMSPieces'].value;
    masterPTT.totalKG = controls['totalKG'].value;
    masterPTT.countOfHawbs = controls['countOfHawbs'].value;
    masterPTT.issuer = controls['issuer'].value;
    masterPTT.comment = controls['comment'].value;
    masterPTT.containerSectionName = controls['containerSectionName'].value;


    let receivedAtAirlineConfirmation = {} as MasterPTTReceiveAt;
    receivedAtAirlineConfirmation.personName = controls['recoveryAtPersonName'].value;
    receivedAtAirlineConfirmation.trackAndTrailer = controls['recoveryAtTrackAndTrailer'].value;
    receivedAtAirlineConfirmation.dateAndTime = controls['recoveryAtDateAndTime'].value;
    receivedAtAirlineConfirmation.pieces = controls['recoveryAtPieces'].value;
    receivedAtAirlineConfirmation.notation = controls['recoveryAtNotation'].value;
    masterPTT.receivedAtAirlineConfirmation = receivedAtAirlineConfirmation;

    let receivedAtCfsConfirmation = {} as MasterPTTReceiveAt;
    receivedAtCfsConfirmation.personName = controls['receivedAtPersonName'].value;
    receivedAtCfsConfirmation.trackAndTrailer = controls['receivedAtTrackAndTrailer'].value;
    receivedAtCfsConfirmation.dateAndTime = controls['receivedAtDateAndTime'].value;
    receivedAtCfsConfirmation.pieces = controls['receivedAtPieces'].value;
    receivedAtCfsConfirmation.notation = controls['receivedAtNotation'].value;
    masterPTT.receivedAtCfsConfirmation = receivedAtCfsConfirmation;

    return masterPTT;
  }

  create(masterPPT: MasterPtt, closeOnSuccess: boolean) {
    this.loadingAfterSubmit = true;
    this.loadingMessage = "Generating Document...";
    this.masterService.createMasterPTT(masterPPT).subscribe(res => {
      this.loadingMessage = undefined;
      this.generatedDocumentInfo = res;
        const fileURL = this.buildShowUrl(this.generatedDocumentInfo);
        window.open(fileURL, '_blank');
        if (closeOnSuccess) {
          this.dialogRef.close({res, isEdit: false});
        }
    }, error => {
      this.loadingMessage = undefined;
      this.alerts.error(error);
    });
  }

  buildDownloadUrl(generatedDocumentInfo) {
    return this.fileUploadService.buildDownloadFileUrl(generatedDocumentInfo.id);
  }

  buildShowUrl(generatedDocumentInfo) {
    return this.fileUploadService.buildStreamFileUrl(generatedDocumentInfo.id);
  }

  buildMasterUrl() {
    this.dialogRef.close();
    this.router.navigate(['/home/masters/master', {id: this.master.id}]).then();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getErrorMessage(controlName: string) {
    let control = this.form.controls[controlName];
    return control.errors.uld;
  }

}
