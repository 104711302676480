﻿import {Exclude, Type} from "class-transformer";
import {Labeled} from "../../../common/oms-types";
import {Address, Driver} from "./";
import {BaseDeletable} from "./base-deletable";
import {UserAms} from "./user-ams";
import {UserClient} from "./user-client";
import {UserProfile} from "./user-profile";
import {UserRole} from "./user-role";

export class User extends BaseDeletable implements Labeled {

  public email: string;
  password: string;
//  passwordHash: string; not using on UI
  firstName: string;
  middleName: string;
  lastName: string;
  mobilePhoneNumber: string;
  phoneNumberOne: string;
  phoneNumberTwo: string;
  active: boolean;

  @Type(() => UserProfile)
  profile: UserProfile;
  canCreateCarrier: boolean;
  simplifiedLogin: boolean;
  loginName: string;
  pinCode: string;
  billingApprove: boolean;
  canShowBillInfo: boolean;
  canDriverUpdate: boolean;

  @Type(() => Driver)
  driver: Driver;

  @Type(() => UserClient)
  userClient: UserClient;

  @Type(() => UserAms)
  userAms: UserAms;

  @Type(() => UserRole)
  userRoles: UserRole[] = [];

  @Type(() => Address)
  homeAddress: Address;

  @Exclude()
  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  @Exclude()
  get fullNameWithEmail(): string {
    return this.firstName + ' ' + this.lastName + ' <' + this.email + '>';
  }

  @Exclude()
  get label(): string {
    return this.fullName;
  }

  @Exclude()
  get icon(): string {
    return 'fa fa-user';
  }

  get canDeleteOrder() {
    return this.profile == null || this.profile.canRemoveOrder;
  }

  public hasRole(role: string): boolean {
    return !role || this.userRoles.some((ur) => ur.roleName === role);
  }

  public hasAnyRoles(roles: string[]): boolean {
    if (!roles || !roles.length) {
      return false;
    }
    return roles.some(r => this.hasRole(r));
  }

  public isValid() {
    if (!this.userRoles) {
      return false;
    }
    return true;
  }
}
