var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Transform, Type } from 'class-transformer';
import { RouteInfo } from "./route-info";
import { Address } from "../address";
import { Order } from "../order";
import { Status, transformStatus } from "./status";
import { MasterStatusId, transformMasterStatusId } from "..";
import { Receiving } from "./receiving";
import { LoadOut } from "./load.out";
var ɵ0 = function () { return Date; }, ɵ1 = function () { return Date; }, ɵ2 = function () { return Address; }, ɵ3 = function () { return OrderWarehouse; }, ɵ4 = function (v, o, tt) { return transformMasterStatusId(v, o, tt); }, ɵ5 = function () { return RouteInfo; }, ɵ6 = function () { return RouteInfo; }, ɵ7 = function () { return Address; }, ɵ8 = function () { return Address; }, ɵ9 = function (v, o, tt) { return transformStatus(v, o, tt); }, ɵ10 = function () { return Receiving; }, ɵ11 = function () { return LoadOut; }, ɵ12 = function (v, o, tt) { return transformStatus(v, o, tt); };
var OrderWarehouse = /** @class */ (function (_super) {
    __extends(OrderWarehouse, _super);
    function OrderWarehouse() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // If manifested OrderWarehouse is Recovery Order
        // then receiving contains list of Orders of Recovering Master to receive
        _this.receiving = [];
        _this.masterStatus = MasterStatusId.NEW;
        return _this;
    }
    Object.defineProperty(OrderWarehouse.prototype, "nonAMS", {
        get: function () {
            return this._nonAMS;
        },
        set: function (nonAMS) {
            this._nonAMS = nonAMS;
        },
        enumerable: true,
        configurable: true
    });
    OrderWarehouse.prototype.trackBy = function () {
        return this.id + '-' + this.routeInId + '-' + this.routeOutId;
    };
    __decorate([
        Type(ɵ0),
        __metadata("design:type", Date)
    ], OrderWarehouse.prototype, "dateWhseIn", void 0);
    __decorate([
        Type(ɵ1),
        __metadata("design:type", Date)
    ], OrderWarehouse.prototype, "dateWhseOut", void 0);
    __decorate([
        Type(ɵ2),
        __metadata("design:type", Address)
    ], OrderWarehouse.prototype, "addressWhse", void 0);
    __decorate([
        Type(ɵ3),
        __metadata("design:type", Array)
    ], OrderWarehouse.prototype, "receiving", void 0);
    __decorate([
        Transform(ɵ4),
        __metadata("design:type", Number)
    ], OrderWarehouse.prototype, "masterStatus", void 0);
    __decorate([
        Type(ɵ5),
        __metadata("design:type", RouteInfo)
    ], OrderWarehouse.prototype, "routeIn", void 0);
    __decorate([
        Type(ɵ6),
        __metadata("design:type", RouteInfo)
    ], OrderWarehouse.prototype, "routeOut", void 0);
    __decorate([
        Type(ɵ7),
        __metadata("design:type", Address)
    ], OrderWarehouse.prototype, "addressRouteFrom", void 0);
    __decorate([
        Type(ɵ8),
        __metadata("design:type", Address)
    ], OrderWarehouse.prototype, "addressRouteTo", void 0);
    __decorate([
        Transform(ɵ9),
        __metadata("design:type", Number)
    ], OrderWarehouse.prototype, "receivingStatus", void 0);
    __decorate([
        Type(ɵ10),
        __metadata("design:type", Receiving)
    ], OrderWarehouse.prototype, "lastReceiving", void 0);
    __decorate([
        Type(ɵ11),
        __metadata("design:type", LoadOut)
    ], OrderWarehouse.prototype, "lastLoadOut", void 0);
    __decorate([
        Transform(ɵ12),
        __metadata("design:type", Number)
    ], OrderWarehouse.prototype, "loadOutStatus", void 0);
    return OrderWarehouse;
}(Order));
export { OrderWarehouse };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
