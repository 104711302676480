import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TruckService} from '../../../services/truck-service';
import {OmsAlertsService} from '../../../modules/shared/components/oms-alerts/oms-alerts.service';
import {TrailerService} from '../../../services/trailer-service';
import {DriverService} from '../../../services/driver-service';
import {AddressService} from '../../../services';
import {Master} from '../../../modules/shared/models';
import {Size} from '../../../common/oms-types';
import {AddressSearchService} from '../../../modules/shared/services/address-search.service';
import {Truck} from '../../../modules/shared/models';
import {Trailer} from '../../../modules/shared/models';
import {Driver} from '../../../modules/shared/models';
import {OmsConverters} from '../../../modules/shared/services/oms-converters.service';
import {DialogCloseEvent, DialogResult, DialogType, ModalResult, OmsDialogsService} from "../../common/oms-dialogs";
import {CustomSearchComponent} from "../../../modules/shared/components/common/input/custom-search/custom-search.component";

export interface MastersDispatchData {
  masters: Master[];
  date: Date;
  driver?: Driver;
  truck?: Truck;
  trailer?: Trailer;
  pieces?: number;
  hu?:number;
}

@Component({
  templateUrl: './masters-dispatch-dialog.component.html',
  styleUrls: ['./masters-dispatch-dialog.component.scss']
})
export class MastersDispatchDialogComponent implements OnInit {
  Size = Size;

  public readonly:boolean = false;
  public addressSearch: AddressSearchService;
  @ViewChild('driverInput') public driverInput: CustomSearchComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MastersDispatchData,
    public converter: OmsConverters,
    public cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<MastersDispatchDialogComponent, DialogResult<MastersDispatchData>>,
    public addressService: AddressService,
    private dialogs: OmsDialogsService,
    private alerts: OmsAlertsService,
    public truckService: TruckService,
    public trailerService: TrailerService,
    public driverService: DriverService
  ) {
  }

  ngOnInit() {
    if (this.data.masters && this.data.masters.length == 1) {
      this.data.pieces = this.data.masters[0].pcsToRecover;
      this.data.hu = this.data.masters[0].huToRecover;
    }
  }

  onCancelDispatch() {
    this.dialogs.confirm(DialogType.CONFIRMATION, 'Cancel Dispatch?',
      'The load will be deleted and all related data will be lost.\nContinue?').then(result=> {
        switch (result.result) {
          case ModalResult.YES: {
            this.dialogRef.close({result: ModalResult.DELETE});
          }
        }
    })
  }

  onOk(event: DialogCloseEvent) {
    event.canClose = this.validate();
  }

  onCreateTruck(number: string) {
    const truck = new Truck();
    truck.number = number;
    this.truckService.createEditAndSave(truck, this.readonly)
      .then(truck=>{this.data.truck = truck})
      .catch(error=>this.alerts.danger(error));
  }

  onCreateTrailer(number: string) {
    const trailer = new Trailer();
    trailer.number = number;
    this.trailerService.createEditAndSave(trailer, this.readonly)
      .then(trailer=>{this.data.trailer = trailer})
      .catch(error=>this.alerts.danger(error));
  }

  onCreateDriver() {
    const driver = new Driver();
    this.driverService.createEditAndSave(driver, this.readonly)
      .then(driver => {this.data.driver = driver})
      .catch(error => this.alerts.danger(error));
  }


  public allowDispatch(master: Master): boolean {
    return true;//master.nonAMS || !!master.date1C;
  }

  public validate():boolean {
    if (!this.data.driver) {
      this.alerts.warning('Please select Driver');
      return false;
    }
/*    let m:Master[] = (this.data.masters as Master[]).filter(master=>!this.allowDispatch(master));
    if (m.length>0) {
      this.alerts.warning('All AMS masters must have 1C');
      return false;
    } */

    return true;
  }

  public get isMultiple(): boolean {
    return this.data.masters && this.data.masters.length>1;
  }

}
