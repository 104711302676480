﻿<div class="form-group search" [id]="identifier">
  <span style="width: 100%;">
    <label class="control-label">{{label}}</label>
    <span style="float: right;"><ng-content></ng-content></span>
  </span>
  <div id="group123" class="input-group" [ngClass]="{'input-group-sm': size == Size.SMALL, 'input-group-lg': size == Size.LARGE}" style="width: 100%">
    <span class="input-group-addon" [ngClass]="{'disabled': disabled}" *ngIf="iconClass">
      <i [class]="iconClass"></i>
    </span>

    <ng-select #select class="form-control address"
               [appendTo]="'body'"
               [clearable]="allowClear"
               [disabled]="disabled"
               [searchable] = "!separateSearch"
               [bindLabel]="'label'"
               [items]="addressSearch.items$ | async"
               [loading]="addressSearch.loading"
               [typeahead]="addressSearch.input$"
               (change)="changeAddress($event)"
               (click)="toggleOpen($event)"
               (clickOutside)="_handleOutsideClick($event)"
               [clickOutsideEnabled]="isOpen" [delayClickOutsideInit]="true"
               [(ngModel)]="address"
               [ngClass]="{'form-control-invalid': isInvalid}"
               (open)="onOpen()"
               (keydown)="onKeyDown($event)"
               [popover]="getTooltipValue(address)"
               placeholder="select {{label}} from List">

      <ng-template ng-header-tmp *ngIf="separateSearch">
        <span class="form-group">
          <input #searchInput title='' [placeholder]="'Search...'" class="form-control" style="width: 100%; line-height: 24px"
                 type="text" (input)="select.filter($event.target.value)"/>
        </span>
      </ng-template>

      <!--ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div>{{item.name}}  {{item.top}}</div>
      </ng-template-->

      <ng-template ng-footer-tmp *ngIf="allowCreate || allowShowAll">
        <div class="widget-header">
          <oms-checkbox label="Show All" [(checked)]="showAllAddresses" (checkedChange)="search()" *ngIf="allowShowAll">
          </oms-checkbox>
          <div class="widget-header-toolbar" *ngIf="allowCreate">
            <button class="btn btn-xs  btn-primary" (click)="openCreateAddressDialog()">Create New&hellip;</button>
          </div>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div>
          <b [ngOptionHighlight]="search">{{item.label}}</b><br/>
          <small [ngOptionHighlight]="search">{{item.addressLines || ''}}, {{item.city}} {{getState(item)}}{{getWithComa(item.postCode)}}</small>
        </div>
      </ng-template>

    </ng-select>


  </div>

  <div *ngIf="isControlInvalid()" class="invalid-feedback">{{errorMessage}}</div>

</div>

