import {Injectable} from "@angular/core";
import {BaseEntityService} from "./base/base-entity.service";
import {ScheduledReport} from "../modules/shared/models/reporting/scheduled-report";
import {RestService} from "./rest.service";
import {HttpUtilsService} from "./http-utils.service";
import {Observable} from "rxjs";
import {plainToClass} from "class-transformer";
import {map} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";

@Injectable()
export class ReportService extends BaseEntityService<ScheduledReport> {
  apiUrl = '/oms/report/';
  classType = ScheduledReport;

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService,
  ) {
    super(httpRestService, httpUtils);
  }

  public getAvailableReports(): Observable<ReportType[]> {
    return this.httpRestService.getWithHeader<ReportType[]>(this.apiUrl + '/getAvailableReports', this.httpUtils.getHTTPHeaders());
  }

  public run(id: number, mode: String): Observable<any> {
    let params: HttpParams = this.httpUtils.getHTTPParams({user: mode === 'EMAIL_USER', recipients: mode === 'EMAIL_RECIPIENTS'});
    return this.httpRestService.getWithOptions(this.apiUrl + '/run/' + id, this.httpUtils.getHTTPHeaders(), params);
  }

  public progress(guid): Observable<any> {
    return this.httpRestService.getWithHeader(this.apiUrl + '/progress/' + guid, this.httpUtils.getHTTPHeaders());
  }

  public setActive(report: ScheduledReport, value: boolean): Observable<ScheduledReport> {
    return this.httpRestService.put<ScheduledReport>(this.apiUrl + `/${report.id}/set-active/${value}`, '', this.httpUtils.getHTTPHeaders())
      .pipe(map((result) => this.afterLoad(plainToClass(ScheduledReport, result))));
  }

  getAvailableColumns(reportTypeId: string): Observable<ReportColumn[]> {
    return this.httpRestService.getWithHeader<ReportColumn[]>(this.apiUrl + '/type/' + reportTypeId + "/columns", this.httpUtils.getHTTPHeaders());
  }
}

export interface ReportType {
  reportClass: string;
  typeId: string;
  label: string;
}

export interface ReportColumn {
  id: number;
  label: string;
}
