import {Component, EventEmitter, Inject, Input, Optional, Output, ViewChild} from '@angular/core';
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {BaseInputComponent} from '../base/base-input/base-input.component';

@Component({
  selector: 'oms-checkbox',
  templateUrl: './oms-checkbox.component.html',
  styleUrls: ['./oms-checkbox.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: OmsCheckboxComponent, multi: true}],
})
export class OmsCheckboxComponent extends BaseInputComponent<boolean> {
  @ViewChild(NgModel) model: NgModel;
  @Input() indeterminate: boolean = false;
  @Input() type: 'default' | 'alert' = 'default';
  @Input() mark: 'check' | 'cross' = 'check';
  @Input() condensed: boolean = false;
  @Input() checked: boolean;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  onChange(event) {
    this.value = event;
    this.checkedChange.emit(event);
  }

  writeValue(value: boolean) {
    this.checked = value;
    super.writeValue(value);
  }
}
