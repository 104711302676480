import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {OmsConstants} from '../../../../../../common/oms-constants.service';
import {ValueAccessorBase} from '../../../base/value-accessor';
import {checkNumber} from '../../../../../../_helpers/utils';
import {isNullOrUndefined} from 'util';
import {Size} from '../../../../../../common/oms-types';
import {MatDialog} from "@angular/material";
import {
  UldsDialogComponent,
  UldsDialogInputData
} from "../../../../../../components/common/ulds-dialog/ulds-dialog.component";
import {Uld, Master} from "../../../../models";

@Component({
  selector: 'oms-uld-input',
  templateUrl: './uld-input.component.html',
  styleUrls: ['./uld-input.component.css'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: UldInputComponent, multi: true}]
})
export class UldInputComponent extends ValueAccessorBase<number> {
  Size = Size;
  @Input() label: string = 'Quantity';
  @Input() size: Size = Size.DEFAULT;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showButton: boolean = true;
  @Input() rootId: number;
  @Input() objectType: string;
  @Input() ULDs: any[];
  @Output() ULDsChange = new EventEmitter();
  private inputValue: number;

  constructor(private cdr: ChangeDetectorRef, public _constants: OmsConstants, public dialog: MatDialog) {
    super();
  }

  private toDisplay(value) {return checkNumber(value); }
  private toDefault(value) {return checkNumber(value); }


  get internal() {
    if (isNullOrUndefined(this.inputValue)) {
      this.inputValue = this.toDisplay(super.getValue());
    }
    return this.inputValue;
  }

  set internal(value) {
    if (this.ULDs.length > (value + this.emptyULDsLength)) {
      return;
    }
    if (value !== this.inputValue) {
      this.inputValue = value;
      super.setValue(this.toDefault(value));
      this.initULDs(this.inputValue);
    }
  }

  get emptyULDsLength() {
    let emptyULD  = this.ULDs.filter(uld => {
      return isNullOrUndefined(uld.id) || isNullOrUndefined(uld.uldNumber);
    });
    return emptyULD.length;
  }

  protected setValue(value: number) {
    this.inputValue = this.toDisplay(value);
    this.initULDs(this.inputValue);
//    super.setValue(value);
    this.cdr.detectChanges();
  }

  initULDs(uldCount) {
    if (!isNullOrUndefined(uldCount)) {
      if (this.ULDs.length < uldCount) {
        let difference = uldCount - this.ULDs.length;
        Array(difference).fill(0).map((x, i) => {
          this.ULDs.push(new Uld());
        });
      } else if (this.ULDs.length > uldCount) {
        let difference = this.ULDs.length - uldCount;
        Array(difference).fill(0).map((x, i) => {
          this.ULDs.pop();
        });
      }
    }
  }

  openUldDialog() {
    if (!isNullOrUndefined(this.rootId)) {
      let data = 'Master' === this.objectType ? {
        masterId: this.rootId,
        readonly: this.readonly
      } : {orderId: this.rootId, readonly: this.readonly};
      const dialogRef = this.dialog.open(UldsDialogComponent, {
        width: '30%', data:
          data as UldsDialogInputData
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (!res) {
          return;
        }

        this.setValue(res.uldCount);
        super.setValue(this.toDefault(res.uldCount));
        this.ULDsChange.next(res.ULDs);
      });
    }
  }

}
