import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {RestService} from "./rest.service";
import {Customer} from "../modules/shared/models";
import {HttpUtilsService} from "./http-utils.service";
import {plainToClass} from 'class-transformer';
import {BaseEntityService} from './base/base-entity.service';

@Injectable()
export class CustomerService extends BaseEntityService<Customer> {
  apiUrl = '/oms/customer/';
  classType = Customer;
  public customerSubject = new Subject<Customer[]>();
  public customerPopUpEvent = new Subject<any>();

  public constructor(public httpRestService: RestService,  public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public showCustomerPopUp(item: Customer) {
    this.customerPopUpEvent.next({
      show: true,
      customer: item
    });
  }

  public hideCustomerPopUp() {
    this.customerPopUpEvent.next({
      show: false,
      customer: null
    });
  }

  getAllCustomers() {
    this.findAll().then(items => this.customerSubject.next(items));
  }

  getAllCustomersByCustomerGroup(customerGroupId: number) {
    this.httpRestService.get<Customer[]>(this.apiUrl + 'getAllByCustomerGroupId', customerGroupId).subscribe(items => {
      this.customerSubject.next(plainToClass(Customer, items));
    });
  }

}
