var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, DoCheck, KeyValueDiffers, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DataTableComponent } from '../data-table.component';
import { BaseColumn, TreeColumn } from '../columns/column-types';
import { isEmptyString } from '../../../../../../_helpers/utils';
import { OmsConstants } from '../../../../../../common/oms-constants.service';
import { Logger } from '../../../../../../_helpers/logger';
import { Subject } from "rxjs";
var DataTableColumnComponent = /** @class */ (function () {
    function DataTableColumnComponent(table, differs, cdr) {
        this.table = table;
        this.differs = differs;
        this.cdr = cdr;
        this.unsubscribe$ = new Subject();
        this.hasIcon = true;
        this.isToggleButton = false;
        this.differ = differs.find(this).create();
    }
    DataTableColumnComponent.prototype.ngOnChanges = function (changes) {
        if (changes.baseColumn) {
            this.column = this.baseColumn instanceof TreeColumn ? this.baseColumn.byRow(null) : this.baseColumn;
            this.isHeaderSelectable = this.column.isHeaderSelectable();
            this.iconClass = this.column.getHeaderIconClass();
            this.isToggleButton = this.getItems().length === 2;
        }
    };
    DataTableColumnComponent.prototype.display = function (value) {
        return this.column.editable && isEmptyString(value) ? OmsConstants.EMPTY_VALUE : value;
    };
    Object.defineProperty(DataTableColumnComponent.prototype, "selectedInHeader", {
        get: function () {
            return this.isHeaderSelectable ? this.column.handlers.header.selected : null;
        },
        set: function (item) {
            if (this.isHeaderSelectable && this.column.handlers.header.apply) {
                this.column.handlers.header.apply(item);
                this.table.reRender();
            }
        },
        enumerable: true,
        configurable: true
    });
    DataTableColumnComponent.prototype.ngOnDestroy = function () {
        delete this.table;
        delete this.differ;
        delete this.column;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    DataTableColumnComponent.prototype.ngDoCheck = function () {
        var changed = false;
        if (this.sortBy !== this.table.sortedBy) {
            changed = true;
            this.sortBy = this.table.sortedBy;
        }
        if (this.sortAsc !== this.table.sortedAsc) {
            changed = true;
            this.sortAsc = this.table.sortedAsc;
        }
        /*    if (this.differ.diff(this)) {
              changed = true;
            }*/
        if (changed) {
            this.cdr.markForCheck();
        }
    };
    DataTableColumnComponent.prototype.getItems = function () {
        return (this.column.handlers.header && Array.isArray(this.column.handlers.header.select)) ? this.column.handlers.header.select : [];
    };
    DataTableColumnComponent.prototype.clickByToggleButton = function ($event) {
        var _this = this;
        $event.stopPropagation();
        var notSelected = this.getItems().find(function (item) { return _this.selectedInHeader() !== item; });
        this.selectedInHeader = notSelected;
        this.table.refresh();
    };
    DataTableColumnComponent = __decorate([
        Logger({
            name: 'DataTableColumn'
        }),
        __metadata("design:paramtypes", [DataTableComponent,
            KeyValueDiffers,
            ChangeDetectorRef])
    ], DataTableColumnComponent);
    return DataTableColumnComponent;
}());
export { DataTableColumnComponent };
