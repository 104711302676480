import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {Driver} from '../modules/shared/models';
import {DriverDialogComponent} from '../components/dialogs/driver-dialog/driver-dialog.component';
import {DialogResult, ModalResult, OmsDialogsService} from "../components/common/oms-dialogs";
import {Observable} from "rxjs";
import {OmsAlertsService} from "../modules/shared/components/oms-alerts/oms-alerts.service";
import {UploadedFiles} from "../modules/shared/models/documents/uploaded.files";
import {StringUtil} from "../_helpers/string.util";
import {tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class DriverService extends BaseEntityService<Driver> {
  apiUrl = '/oms/driver/';
  classType = Driver;

  public constructor(
    public dialogs: OmsDialogsService,
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService,
    public alerts: OmsAlertsService) {
    super(httpRestService, httpUtils);
  }

  public createEditAndSave(driver: Driver, readonly?: boolean): Promise<Driver> {
    return this.createEditAndSaveForAdmin(driver, false, readonly);
  }

  public createEditAndSaveForAdmin(driver: Driver, adminEdit: boolean, readonly?: boolean): Promise<Driver> {
    return new Promise<Driver>((success, reject) => {
      this.dialogs.openDialog(DriverDialogComponent, {driver: driver, readonly: readonly, adminEdit: adminEdit})
        .then((result: DialogResult<any>) => {
          if (result.result === ModalResult.OK) {
            this.save(driver).subscribe(saved => success(saved), error => {
              reject(error === 'DataIntegrityViolation' ? 'Can\'t create new Driver: \n Driver ' + driver.fullName + ' already exists!' : error);
            });
          }
        })
        .catch(error => reject(error));
    });
  }

  public downloadAggregatedDrivers(): Observable<UploadedFiles> {
    let uuid = StringUtil.generateUuid();
    this.alerts.process('Generating Excel...', '', () => this.getProgress(uuid), 5000);

    return this.httpRestService.post<UploadedFiles>(this.apiUrl + 'download-aggregated-drivers-excel',
      null,{params: {active: true, uuid: uuid } })
      .pipe(
        tap(
          (response) => response,
          (error) => {
            this.alerts.error(error, "Error Excel Generation:");
          }
        )
      );
  }

}
