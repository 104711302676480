import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {DriverService} from '../../services/driver-service';
import {RowHeightCache} from '../../modules/shared/components/base/data-table/utils/row-height-cache';
import {User} from "../../modules/shared/models";
import {map} from "rxjs/operators";
import {PageResult} from "../../modules/shared/models/query-models/page-result";
import {UserService} from "../../modules/shared/services/user.service";
import {DispatchService} from "../../modules/shared/services/dispatch/dispatch.service";
import {OmsAlertsService} from "../../modules/shared/components/oms-alerts/oms-alerts.service";
import {assigned} from "../../_helpers/utils";
import {SimpleChanges} from "@angular/core/src/metadata/lifecycle_hooks";
import {Manifest} from "../../modules/shared/models/manifest/manifest";
import {ManifestItem} from "../../modules/shared/models/manifest/manifest-item";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Size} from "../../common/oms-types";
import { UserRoleType } from "../../modules/shared/models/user-role.type";

@Component({
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() date: Date = new Date();
  @Input() driver: User;
  @Input() rowDetail: any;
  @Input() externalPaging: boolean;
  @Input() groupedRows: any;
  @Input() virtualization: boolean = true;
  @Input() public fixed: boolean = true;
  @Input() scrollbarV: boolean = true;
  @Input() scrollbarH: boolean = false;
  @Input() offsetX: number;
  @Input() rowHeight: number | ((row?: any) => number) = 40;

  @ViewChild('table') public table: ElementRef<HTMLTableElement>;
  @ViewChild('bodyDiv') public body: ElementRef<HTMLDivElement>;

  public routes: ManifestItem[];
  public tabVisible: boolean = true;
  public weight: number;
  public test: boolean;
  public mawb: string;
  public isEdit: boolean;
  public items: any[] = [];
  public headers: any[] = [];
  public selectDrivers: any[] = [];

  Size = Size;

  expanded: any[] = [];
  public _rows: any[];
  _offset: number;
  _pageSize: number = 20;
  indexes: any = {};
  private _bodyHeight; // : any = '200';
  temp: any[] = [];
  offsetY: number = 0;
  rowIndexes: any = new Map();
  rowExpansions: any = new Map();
  rowHeightsCache: RowHeightCache = new RowHeightCache();

  public activeTab: string = 'test2';


  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 1,
    handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
    suppressScrollX: false,
    suppressScrollY: false,
    useBothWheelAxes: false
  };


  @Output() page: EventEmitter<any> = new EventEmitter();
  @Output() scroll: EventEmitter<any> = new EventEmitter();
  @Output() detailToggle: EventEmitter<any> = new EventEmitter();
  @Input() set pageSize(val: number) {
    this._pageSize = val;
    this.recalcLayout();
  }

  public searchDrivers = (searchText: string) => {
    return this.userService.findUsersByRoles([UserRoleType.ROLE_DRIVER], true, searchText).pipe(map((res) => {
      return PageResult.fromArray(res.content.filter((user) => user.driver).map(user => user.driver));
    }));
  }


  constructor(
      private router: Router,
      private route: ActivatedRoute,
      public cdr: ChangeDetectorRef,
      public alerts: OmsAlertsService,
      public driverService: DriverService,
      public dispatchService: DispatchService,
      public userService: UserService) {

    this.route.queryParams.subscribe(params => {
      this.activeTab = params.tab;
    });
  }


  get pageSize(): number {
    return this._pageSize;
  }

  @Input() set rows(val: any[]) {
    this._rows = val;
    this.rowExpansions.clear();
    this.recalcLayout();
  }

  get rows(): any[] {
    return this._rows;
  }

  get rowCount(): number {
    return this._rows ? this._rows.length : 0;
  }

  @Input() set offset(val: number) {
    this._offset = val;
    this.recalcLayout();
  }

  get offset(): number {
    return this._offset;
  }


  ngOnInit() {
    this.driverService.findAll().then((items) => {this.rows = items; });
    this.refresh();
  }


  public refresh() {
    console.log('Routes Request', this.driver, this.date);
    if (assigned(this.driver) && assigned(this.date)) {
      this.dispatchService.getDriverRoutesOnDate(this.driver.driver.id, this.date)
        .then((manifests: Manifest[]) => {
          console.log('MANIFESTS', manifests);
          this.routes = manifests.flatMap((m) => m.items);
//          this.cdr.markForCheck();
        })
        .catch((error) => {this.alerts.error(error); });
    }

  }

  get bodyHeight() {
    return this._bodyHeight;
  }


  /**
   * Updates the page given a direction.
   */
  updatePage(direction: string): void {
    let offset = this.indexes.first / this.pageSize;

    if (direction === 'up') {
      offset = Math.ceil(offset);
    } else if (direction === 'down') {
      offset = Math.floor(offset);
    }

    if (direction !== undefined && !isNaN(offset)) {
      this.page.emit({ offset });
    }
  }

  /**
   * Updates the rows in the view port
   */
  updateRows(): void {
    const { first, last } = this.indexes;
    console.log('indexes', this.indexes);
    let rowIndex = first;
    let idx = 0;
    const temp: any[] = [];

    this.rowIndexes.clear();

    // if grouprowsby has been specified treat row paging
    // parameters as group paging parameters ie if limit 10 has been
    // specified treat it as 10 groups rather than 10 rows
    if (this.groupedRows) {
      let maxRowsPerGroup = 3;
      // if there is only one group set the maximum number of
      // rows per group the same as the total number of rows
      if (this.groupedRows.length === 1) {
        maxRowsPerGroup = this.groupedRows[0].value.length;
      }

      while (rowIndex < last && rowIndex < this.groupedRows.length) {
        // Add the groups into this page
        const group = this.groupedRows[rowIndex];
        temp[idx] = group;
        idx++;

        // Group index in this context
        rowIndex++;
      }
    } else {

      while (rowIndex < last && rowIndex < this.rowCount) {
        const row = this.rows[rowIndex];

        if (row) {
          this.rowIndexes.set(row, rowIndex);
          temp[idx] = row;
        }

        idx++;
        rowIndex++;
      }
    }

    this.temp = temp;
  }

  /**
   * Recalculates the table
   */
  recalcLayout(): void {
    this.refreshRowHeightCache();
    this.updateIndexes();
    this.updateRows();
  }

  /**
   * Refreshes the full Row Height cache.  Should be used
   * when the entire row array state has changed.
   */
  refreshRowHeightCache(): void {
    if (!this.scrollbarV || (this.scrollbarV && !this.virtualization)) { return; }

    // clear the previous row height cache if already present.
    // this is useful during sorts, filters where the state of the
    // rows array is changed.
    this.rowHeightsCache.clearCache();

    // Initialize the tree only if there are rows inside the tree.
    if (this.rows && this.rows.length) {
      this.rowHeightsCache.initCache({
        rows: this.rows,
        rowHeight: this.rowHeight,
        detailRowHeight: this.getDetailRowHeight,
        externalVirtual: this.scrollbarV && this.externalPaging,
        rowCount: this.rowCount,
        rowIndexes: this.rowIndexes,
        rowExpansions: this.rowExpansions
      });
      console.log('refreshRowHeightCache>>>', this.rowHeightsCache.treeArray);

    }
  }

  /**
   * Updates the index of the rows in the viewport
   */
  updateIndexes(): void {
    let first = 0;
    let last = 0;

    if (this.scrollbarV) {
      if (this.virtualization) {
        // Calculation of the first and last indexes will be based on where the
        // scrollY position would be at.  The last index would be the one
        // that shows up inside the view port the last.
        const height = this.bodyHeight; // parseInt(this.bodyHeight, 0);
        first = this.rowHeightsCache.getRowIndex(this.offsetY);
        last = this.rowHeightsCache.getRowIndex(height + this.offsetY) + 1;
        console.log('update indexes', first, last, height, this.offsetY);
      } else {
        // If virtual rows are not needed
        // We render all in one go
        first = 0;
        last = this.rowCount;
      }
    } else {
      // The server is handling paging and will pass an array that begins with the
      // element at a specified offset.  first should always be 0 with external paging.
      if (!this.externalPaging) {
        first = Math.max(this.offset * this.pageSize, 0);
      }
      last = Math.min(first + this.pageSize, this.rowCount);
    }

    this.indexes = { first, last };
  }

  /**
   * Get the height of the detail row.
   */
  getDetailRowHeight = (row?: any, index?: any): number => {
    if (!this.rowDetail) { return 0; }
    const rowHeight = this.rowDetail.rowHeight;
    return typeof rowHeight === 'function' ? rowHeight(row, index) : rowHeight;
  }

  /**
   * Body was scrolled, this is mainly useful for
   * when a user is server-side pagination via virtual scroll.
   */
  onBodyScroll(event: any): void {
    const scrollYPos: number = event.scrollYPos;
    const scrollXPos: number = event.scrollXPos;

    // if scroll change, trigger update
    // this is mainly used for header cell positions
    if (this.offsetY !== scrollYPos || this.offsetX !== scrollXPos) {
      this.scroll.emit({
        offsetY: scrollYPos,
        offsetX: scrollXPos
      });
    }

    this.offsetY = scrollYPos;
    this.offsetX = scrollXPos;

    this.updateIndexes();
    this.updatePage(event.direction);
    this.updateRows();
  }

  /**
   * Property that would calculate the height of scroll bar
   * based on the row heights cache for virtual scroll and virtualization. Other scenarios
   * calculate scroll height automatically (as height will be undefined).
   */
  get scrollHeight(): number | undefined {
    if (this.scrollbarV && this.virtualization && this.rowCount) {
      return this.rowHeightsCache.query(this.rowCount - 1);
    }
    // avoid TS7030: Not all code paths return a value.
    return undefined;
  }

  getRowStyles(row: number) {
    let styles = {};

    if (this.scrollbarV && this.virtualization) {
      const idx = this.getRowIndex(row);

      let top = this.rowHeightsCache.query(idx - 1); // *(index+this.indexes.first);
      styles['height'] = this.rowHeight + 'px';
      styles['position'] = 'absolute';
      styles['transform'] = 'translate3d(0px, ' + top + 'px, 0px)';
    }
    return styles;
  }


/*  getRowsStyles(rows: any): any {
    const styles = {};

    // only add styles for the group if there is a group
    if (this.groupedRows) {
      styles['width'] = this.columnGroupWidths.total;
    }

    if (this.scrollbarV && this.virtualization) {
      let idx = 0;

      if (this.groupedRows) {
        // Get the latest row rowindex in a group
        const row = rows[rows.length - 1];
        idx = row ? this.getRowIndex(row) : 0;
      } else {
        idx = this.getRowIndex(rows);
      }

      // const pos = idx * rowHeight;
      // The position of this row would be the sum of all row heights
      // until the previous row position.
      const pos = this.rowHeightsCache.query(idx - 1);

      translateXY(styles, 0, pos);
    }

    return styles;
  }  */


  /**
   * Gets the index for the view port
   */
  getAdjustedViewPortIndex(): number {
    // Capture the row index of the first row that is visible on the viewport.
    // If the scroll bar is just below the row which is highlighted then make that as the
    // first index.
    const viewPortFirstRowIndex = this.indexes.first;

    if (this.scrollbarV && this.virtualization) {
      const offsetScroll = this.rowHeightsCache.query(viewPortFirstRowIndex - 1);
      return offsetScroll <= this.offsetY ? viewPortFirstRowIndex - 1 : viewPortFirstRowIndex;
    }

    return viewPortFirstRowIndex;
  }

  toggleExpanded(row, event?) {
    if (this.expanded.hasEquals(row)) {
      this.expanded.removeAll(row);
    } else {
      this.expanded.push(row);
    }
  }

  /**
   * Toggle the Expansion of the row i.e. if the row is expanded then it will
   * collapse and vice versa.   Note that the expanded status is stored as
   * a part of the row object itself as we have to preserve the expanded row
   * status in case of sorting and filtering of the row set.
   */
  toggleRowExpansion(row: any): void {
    // Capture the row index of the first row that is visible on the viewport.
    const viewPortFirstRowIndex = this.getAdjustedViewPortIndex();
    let expanded = this.rowExpansions.get(row);

    // If the detailRowHeight is auto --> only in case of non-virtualized scroll
    if (this.scrollbarV && this.virtualization) {
      const detailRowHeight = this.getDetailRowHeight(row) * (expanded ? -1 : 1);
      // const idx = this.rowIndexes.get(row) || 0;
      const idx = this.getRowIndex(row);
      this.rowHeightsCache.update(idx, detailRowHeight);
    }

    // Update the toggled row and update thive nevere heights in the cache.
    expanded = expanded ^= 1;
    this.rowExpansions.set(row, expanded);

    this.detailToggle.emit({
      rows: [row],
      currentIndex: viewPortFirstRowIndex
    });
  }


  /**
   * Gets the row index given a row
   */
  getRowIndex(row: any): number {
    return this.rowIndexes.get(row) || 0;
  }


  /**
   * Recalculates the dimensions of the table size.
   * Internally calls the page size and row count calcs too.
   *
   */
  recalculateDims(): void {
//    const dims = this.dimensionsHelper.getDimensions(this.element);
//    this._innerWidth = Math.floor(dims.width);

    if (this.scrollbarV) {
//      let height = dims.height;
//      if (this.headerHeight) height = height - this.headerHeight;
//      if (this.footerHeight) height = height - this.footerHeight;
      this._bodyHeight = this.body.nativeElement.getBoundingClientRect().height;
      console.log('height', this._bodyHeight);
    }

//    this.recalculatePages();
  }

  /**
   * Recalc's the sizes of the grid.
   *
   * Updated automatically on changes to:
   *
   *  - Columns
   *  - Rows
   *  - Paging related
   *
   * Also can be manually invoked or upon window resize.
   */
  recalculate(): void {
    this.recalculateDims();
//    this.recalculateColumns();
  }

  /**
   * Window resize handler to update sizes.
   */
  @HostListener('window:resize')
  onWindowResize(): void {
    this.recalculate();
  }

  /**
   * Lifecycle hook that is called after a component's
   * view has been fully initialized.
   */
  ngAfterViewInit(): void {
//    if (!this.externalSorting) {
//      this.sortInternalRows();
//    }

    // this has to be done to prevent the change detection
    // tree from freaking out because we are readjusting
    if (typeof requestAnimationFrame === 'undefined') {
      return;
    }

    requestAnimationFrame(() => {
      this.recalculate();

/*      // emit page for virtual server-side kickoff
      if (this.externalPaging && this.scrollbarV) {
        this.page.emit({
          count: this.count,
          pageSize: this.pageSize,
          limit: this.limit,
          offset: 0
        });
      }*/
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Changes', changes);
//    this.refresh();
  }


  public changeQueryParams() {
    const queryParams: Params = {tab: this.activeTab};
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }).then();
  }

  public onActiveTabChange(active) {
    this.activeTab = active;
    this.changeQueryParams();
  }

}
