import {isNullOrUndefined, isUndefined} from 'util';
import {
  convertDateTime,
  convertToLongDateTime,
  getPendingStatusClass,
} from '../../../../services/oms-converters.service';
import {OmsConstants} from '../../../../../../common/oms-constants.service';
import {
  convertToEST,
  daysDiff,
  getPendingStatusEstimated,
  isBefore,
  maxETCDateFromNow,
  PendingStatus
} from '../../../../../../common/oms-date-time.utils';
import {MeasureUnit, VolumeUnits, WeightUnits} from '../../../../../../common/oms-unit-types';
import {toFixed} from '../../../../../../_helpers/utils';

import * as moment from 'moment-timezone';
import {
  AddressType,
  ColumnAlign,
  ColumnSearchType,
  ColumnSettings,
  HistoryColumnSettings,
  IColumnSearchOptions,
  Load,
  LoadType,
  Master,
  Order
} from '../../../../models';
import {Observable, of, Subject} from 'rxjs';
import {ColorItem} from '../../../../../../components/common/color-search-select/color-item';
import {Logger} from "../../../../../../_helpers/logger";
import {Type} from "@angular/core";
import {NgSelectSearchParams} from "../../../../../settings/util/ng-select-search-params";
import {PageResult} from "../../../../models/query-models/page-result";
import {ISearchItemsFunction} from "../../../../../settings/util/search-items.function";
import {BaseEntityService} from "../../../../../../services/base/base-entity.service";
import {BaseEntity} from "../../../../models";
import {HyperLink} from "../../../../../../common/oms-types";

export interface DateValue extends Object {
  estimated?: Date;
  actual?: Date;
}

export interface DateField {
  actual: string;
  estimated?: string;
}

export interface RowObject {
  rowId: string;
  rowClass?: string;
}

export interface TrackByObject {
  trackBy(): any;
}

export interface InplaceEditorSettings {
  editable?: ((row) => boolean) | boolean;
  external?: boolean;     // Open Dialogs or Table's cell click External Handler
  cleanable?: boolean;    // enable clear button
  placeholder?: ((row, value) => string) | string;
  mask?: any;             // input mask
  clearMask?: boolean;
  showTracking?: ((row) => boolean) | boolean;

  select?: SelectableOptions;
  postUpdate?: boolean;            // don't apply value directly after column edit. Value should updated on Table.onUpdate event;
  converter?: (value: any) => any;    // convert edited string or selected object to the Field Type value
  // searchBy?: (term: string, rowObject: any, selectAll: boolean) => (Promise<any[]> | Observable<any[]>);
}

export interface SelectableOptions<T extends BaseEntity = any> {
  search?: ISearchItemsFunction<T> | BaseEntityService<T>;                    // Search by beginning of entered string service
  preFetch?: boolean | number;                    // todo initialize list on dropdown with number of rows fetched, or default
  items?: T[];                                    // fixed list of selectable objects
  converter?: (value: T) => any;                  // Convert stored field value into selectable object
  placeholder?: string;                           // Placeholder for input line
  // searchFn?: (search: string, obj: any) => boolean, // should return true if obj matches search string
  bindLabel?: string;                              // Displayed field/getter of selectable object
  createNewHandler?: (row: T) => Observable<T>;
  addressTypes?: AddressType[]; // TODO need remove
  data?: any;                                     // any custom data
}

export interface CellButtonOptions {
  icon: ((row: any, value: any) => string) | string;
  onClick?: (row: any, value: any) => void;
  link?: ((row: any, value: any) => string) | string;
  tooltip?: ((row: any, value: any) => string) | string;
  hidden?: ((row: any, value: any) => boolean) | boolean;
  newPage?: boolean;
}

// row means row Object, value is Object field
export interface ColumnHandlers {
  rowObject?: (row) => any;
  link?: (row, value: any, newWindow: boolean) => HyperLink;
  onClick?: (row, value: any) => void;
  converter?: (row, value: any) => any;   // Convert Field Type value to readable string
  getter?: (row: any) => any;        // May be using for Calculated fields, depending on other values
  tooltip?: (row: any) => string;
  info?: string | ((row: any, value: any) => string);     // Additional Info for Checkbox Column (always visible)
  icon?: (row: any, value: any) => string;    // FA icon class based on Value
  hidden?: boolean | ((row: any, value: any) => boolean);  // Cell Value is hidden
  hiddenColumn?: boolean;  // Column is hidden
  class?: (row: any, value: any) => string;    // Additional class based on Value
  editor?: InplaceEditorSettings;
  header?: HeaderColumnHandlers;
  sorted?: {asc: boolean};
  params?: any;
  button?: CellButtonOptions;
  searchBy?: (term: string, rowObject: any, selectAll: boolean) => (Promise<any[]> | Observable<any[]>);
}

export interface HeaderColumnHandlers {
  icon?: string;
  tooltip?: string;
  noarrows?: boolean;
  select?: any[];
  selected?: () => any;
  apply?: (any) => void;
  selectLabel?: string;
  displaySelectedLabel?: boolean;
}

// todo combine with CellDisplayType
export enum FieldType {
  TEXT,
  DATE,
  DATETIME,
  ACTIONS,
  ADDRESS,
  BOOLEAN,
  /** @Deprecated **/
  DIALOG,
  FILL
}

export enum CellDisplayType {
  DEFAULT,
  LINK,
//  NEW_WINDOW_LINK,
//  TARGET,

  DIALOG,
  ACTIONS,

  CHECKBOX,
  TEXTBOX,
  SELECT,
//  BUTTON,
  FILL,  // todo Make Custom
  CUSTOM
}

@Logger({
  name: 'BaseColumn'
})
export class BaseColumn extends ColumnSettings {
  public static readonly selectFilterItems = [{id: 0, label: "Selected"}, {id: 1, label: "Not Selected"}];
  public static readonly ynFilterItems = [{id: 0, label: "Yes"}, {id: 1, label: "No"}];
  public static readonly YES_NO_FILTERS = [{id: 1, label: 'Yes'}, {id: 0, label: 'No'}];
  public static readonly POD_YES_NO_FILTERS = [{id: 1, label: 'POD Yes'}, {id: 0, label: 'POD No'}];
  public static readonly POD_OR_Signed_PTT_YES_NO_FILTERS = [{id: 'POD YES', label: 'POD Yes'}, {id: 'POD NO', label: 'POD No'}, {id: 'SIGNED_PTT YES', label: 'Signed_PTT Yes'}, {id: 'SIGNED_PTT NO', label: 'Signed_PTT No'}];
  public static readonly EMPTY_FILL_FILTERS = [{id: 'N/A', label: 'Empty'}, {id: 'NOT_NULL', label: 'Not empty'}];
  public static readonly problemFilterItems = [{id: 0, label: "Problems"}, {id: 1, label: "No Problem"}, {id: 2, label: "Resolved Problems"}];
  public static readonly loadTypeFilterItems = [{id: 1, label: "Recovery"}, {id: 3, label: 'Pickup'}, {id: 5, label: "X-Dock"}, {id: 2, label: "Delivery"}, {id: 4, label: "Direct"} ];

  public fieldType: FieldType;
  public parent: BaseColumn;
  public handlers: ColumnHandlers = {editor: {editable: false, cleanable: true, mask: {mask: false}, clearMask: false}};
  public ngSelect: NgSelectSearchParams<any>;
  public searchChanged$ = new Subject<void>();

  public static getColorItems(): ColorItem[] {
    let items = [];
    items.push({status: PendingStatus.IMPENDING, class: 'date-impending-background'});
    /* items.push({status:PendingStatus.AT_RISK, class: "date-at-risk-background"});*/
    items.push({status: PendingStatus.PAST_DUE, class: 'date-past-due-background'});
    return items;
  }

  constructor (fieldName: number | string, label: string, field: string | DateField | ((obj: any) => any), fieldType, width, handlers?: ColumnHandlers, search?) {
    super(fieldName, label, field, width);
    this.fieldType = fieldType;
    this.setSearch(search);
    this.setHandlers(handlers);
    this.onInit();
  }

  protected onInit() {}

  get isExternalEditor(): boolean {
    return this.handlers.editor && this.handlers.editor.external;
  }

  get isDialog() {
    return this.fieldType === FieldType.DIALOG;
  }

  public rowObject(row: any): any {
    return (this.handlers.rowObject) ? this.handlers.rowObject(row) : row;
  }

  public setSortField(sortField: any): BaseColumn {
    super.setSortField(sortField);
    return this;
  }

  public setAlign(align: ColumnAlign): BaseColumn {
    this.align = align;
    return this;
  }

  public setSearch(search: IColumnSearchOptions | boolean | ColumnSearchType): BaseColumn {
    if (!isNullOrUndefined(search)) {
      if (typeof search === 'string') {
        this.search = {searchable: true, searchType : search as ColumnSearchType};
      } else if (typeof search === 'boolean') {
        this.search = {searchable: search, searchType: 'text'};
      } else {
        this.search = search;
        if (this.search && this.search.searchable && !this.search.searchType) {
          this.search.searchType = 'text';
        }
        this.initHeadSearch();
      }
    }
    return this;
  }

  public setOnClick( handler: (row, value: any) => void): BaseColumn {
    this.handlers.onClick = handler;
    return this;

  }

  public setItems(items: any[]): void {
    this.search.items = items;
    this.searchChanged$.next();
  }

  public setSelectedItems(items: any[]): void {
    this.search.search = this.getIdsForSelected(items);
    this.search.selected = items;
    this.search['model'] = items;
    this.searchChanged$.next();
  }

  public getSelectedItems(): any[] {
    return this.search.selected || [];
  }

  private getIdsForSelected(items: any[]): [] {
    if (!items || !items.length) {
      return [];
    }
    if (!isNullOrUndefined(items[0].id)) {
      return items.map(item => item.id) as [];
    }
    return items as [];
  }

  initHeadSearch() {
    if (this.search && this.search.searchable && this.search.displayFilter) {
      this.ngSelect = new NgSelectSearchParams<any>((text: string) => {
        if (typeof this.search.items === "function") {
          return this.search.items(text);
        }
        if (Array.isArray(this.search.items)) {
          return of(PageResult.fromArray(this.search.items));
        }
        return of(PageResult.fromArray([]));
      });
    } else {
      this.ngSelect = null;
    }
  }

  public doSearch(search: string) {
    console.log('DO_SEARCH', search);
    if (this.ngSelect && this.ngSelect.input$) {
      this.ngSelect.input$.next(search);
    }
  }

  public get searchText(): string {
    // @ts-ignore
    return this.parent && this.parent.searchText ||
      this.search && this.search.searchable && this.search.searchType === 'text' && this.search.search ||
      undefined;
  }

  public setHistory(history: boolean | HistoryColumnSettings): BaseColumn {
    if (!isNullOrUndefined(history)) {
      if (typeof history === 'boolean') {
        this.history = {logChanges: history};
      } else {
        this.history = history;
      }
    }
    return this;
  }

  // todo deep copy
  public setHandlers(handlers: ColumnHandlers): BaseColumn {
    if (handlers) {
      if (handlers.rowObject) { this.rowObject = handlers.rowObject; }
      if (handlers.getter) { this.handlers.getter = handlers.getter; }
      if (handlers.converter) { this.handlers.converter = handlers.converter; }
      if (handlers.link) { this.handlers.link = handlers.link; }
      if (handlers.onClick) { this.handlers.onClick = handlers.onClick; }
      if (handlers.tooltip) { this.handlers.tooltip = handlers.tooltip; }
      if (handlers.info) { this.handlers.info = handlers.info; }
      if (handlers.icon) { this.handlers.icon = handlers.icon; }
      if (handlers.hidden) { this.handlers.hidden = handlers.hidden; }
      if (handlers.class) { this.handlers.class = handlers.class; }
      if (handlers.sorted) { this.handlers.sorted = handlers.sorted; }
      if (handlers.searchBy) { this.handlers.searchBy = handlers.searchBy; }
      if (handlers.params) { this.handlers.params = handlers.params; }
      if (handlers.header) {
        let header = this.handlers.header || {};
        if (!isNullOrUndefined(handlers.header.icon)) { header.icon = handlers.header.icon; }
        if (!isNullOrUndefined(handlers.header.tooltip)) { header.tooltip = handlers.header.tooltip; }
        if (!isNullOrUndefined(handlers.header.select)) { header.select = handlers.header.select; }
        if (!isNullOrUndefined(handlers.header.selected)) { header.selected = handlers.header.selected; }
        if (!isNullOrUndefined(handlers.header.selectLabel)) { header.selectLabel = handlers.header.selectLabel; }
        if (!isNullOrUndefined(handlers.header.displaySelectedLabel)) { header.displaySelectedLabel = handlers.header.displaySelectedLabel; }
        this.handlers.header = header;
      }
      this.setEditor(handlers.editor);
    }
    return this;
  }

  public setEditor(editor: boolean | InplaceEditorSettings): BaseColumn {
    if (!isNullOrUndefined(editor)) {
      if (typeof editor === 'boolean') {
        this.handlers.editor.editable = editor;
      } else {
        if (!isNullOrUndefined(editor.editable)) { this.handlers.editor.editable = editor.editable; }
        if (!isNullOrUndefined(editor.cleanable)) { this.handlers.editor.cleanable = editor.cleanable; }
        if (editor.placeholder) { this.handlers.editor.placeholder = editor.placeholder; }
        if (editor.mask) { this.handlers.editor.mask = editor.mask; }
        if (!isNullOrUndefined(editor.clearMask)) { this.handlers.editor.clearMask = editor.clearMask; }
        if (editor.select) { this.handlers.editor.select = editor.select; }
        if (editor.showTracking) { this.handlers.editor.showTracking = editor.showTracking; }

        if (editor.converter) { this.handlers.editor.converter = editor.converter; }
        if (editor.postUpdate) { this.handlers.editor.postUpdate = editor.postUpdate; }
        // if (editor.searchBy) this.handlers.editor.searchBy = editor.searchBy;
        if (editor.external) { this.handlers.editor.external = editor.external; }

      }
    }
    return this;
  }

  public setButton(button: CellButtonOptions): BaseColumn {
    this.handlers.button = button;
    return this;
  }

  public setHidden(hidden: boolean | ((row, value) => boolean)): BaseColumn {
    this.handlers.hidden = hidden;
    return this;
  }

  public setInfo(info: string | ((row, value) => string)): BaseColumn {
    this.handlers.info = info;
    return this;
  }


  public setHiddenColumn(hiddenColumn: boolean): BaseColumn {
    this.handlers.hiddenColumn = hiddenColumn;
    return this;
  }

  public setWidth(width: string): BaseColumn {
    this.width = width;
    return this;
  }

  public setMaxWidth(maxWidth: string): BaseColumn {
    this.maxWidth = maxWidth;
    return this;
  }

  /*  public static getProblemsFiler(){
    return [{id:0, label: "Problems"}, {id:1, label: "No Problem"}, {id:2, label: "Resolved Problems"}];
  }*/

  public get headerLabelOfSelected() {
    return this.isHeaderSelectable() && this.handlers.header.selectLabel && this.handlers.header.selected ?
      this.handlers.header.selected()[this.handlers.header.selectLabel] : null;
  }

  editable(row: any): boolean {
    return !isNullOrUndefined(this.handlers.editor) && (typeof this.handlers.editor.editable === 'function' ?   this.handlers.editor.editable(row) : this.handlers.editor.editable);
  }

  showTracking(row: any): boolean {
    return !isNullOrUndefined(this.handlers.editor) && (typeof this.handlers.editor.showTracking === 'function' ?   this.handlers.editor.showTracking(row) : this.handlers.editor.showTracking);
  }


  getDisplayType(row?: any, value?: any): CellDisplayType {
    switch (this.fieldType) {
      case FieldType.BOOLEAN:
        return CellDisplayType.CHECKBOX;
      case FieldType.TEXT:
        return CellDisplayType.SELECT;
      case FieldType.FILL:
        return CellDisplayType.FILL;
      default:
        return CellDisplayType.DEFAULT;
    }
  }

  public isHeaderSelectable(): boolean {
    return this.handlers.header && !isNullOrUndefined(this.handlers.header.select);
  }


  protected getFieldValue(object: any, path: string[]) {
    if (!object || !path || !path.length) {
      return object;
    } else {
      return this.getFieldValue(object[path.pop()], path);
    }
  }

  protected setFieldValue(object: any, path: string[], newValue: any) {
    if (object && path) {
      if (path.length > 1) {
        this.setFieldValue(object[path.pop()], path, newValue);
      } else {
        object[path.pop()] = newValue;
      }
    }
  }

  public getValue(object: any, field?: string | ((obj) => string)) {
    if (this.handlers.getter) {
      return this.handlers.getter(object);
    }

    if (isNullOrUndefined(field)) {
      field = this.field;
    }

    if (isNullOrUndefined(field)) {
      return null;
    }

    if (object && (typeof field === 'string')) {
      let path: string[] = field.split('.').reverse();
      return this.getFieldValue(object, path);
    }

    if (object && (typeof field === 'function')) {
      return field(object);
    }
  }

  get postUpdate(): boolean {
    return this.handlers.editor && this.handlers.editor.postUpdate;
  }

  public setValue(object: any, value: any, field?) {
    if (isNullOrUndefined(field)) {
      field = this.field;
    }

    if (object && typeof field === 'string') {
      let path: string[] = field.split('.').reverse();
      this.setFieldValue(object, path, value);
    }
  }

  public getClass(row, value): string {
    if (this.handlers && this.handlers.class) {
      return this.handlers.class(row, value);
    }

    return null;
  }

  public getIconClass(row, value): string {
    return this.handlers.icon ? this.handlers.icon(row, value) : null;
  }

  public isHidden(row, value): boolean {
    return typeof this.handlers.hidden === 'function' ? this.handlers.hidden(row, value) : this.handlers.hidden;
  }

  public isHiddenColumn(): boolean {
    return this.handlers.hiddenColumn;
  }

  public getHeaderIconClass(): string {
    if (this.handlers.header && this.handlers.header.icon) {
      return this.handlers.header.icon;
    }
  }

  public get headerTooltip(): string {
    return this.handlers.header ? this.handlers.header.tooltip : undefined;
  }

  public getTooltip(object): string {
    return this.handlers.tooltip ? this.handlers.tooltip(object) : null;
  }

  public getInfo(row, value): string {
    return typeof this.handlers.info === "function" ? this.handlers.info(row, value) : this.handlers.info;
  }

  convert(row, value: any | null): string {
    if (this.handlers && this.handlers.converter) {
      return this.handlers.converter(row, value);
    } else {
      if (isNullOrUndefined(value)) {
        return 'N/A';
      }

      switch (this.fieldType) {
        case FieldType.ADDRESS:
          return value.name;

        case FieldType.DATE:
          return moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_FORMAT);

        case FieldType.DATETIME:
          return moment.tz(value, OmsConstants.ETC_ZONE).format(OmsConstants.MOMENT_DATE_TIME_FORMAT);

        default:
          return value;
      }
    }
  }

  convertBack(value: any): any {
    return (this.handlers.editor && this.handlers.editor.converter) ?
      this.handlers.editor.converter(value) : value;
  }

  clone(): BaseColumn {
    let column: any = this;
    let clone: BaseColumn | TreeColumn;
    if (column instanceof TreeColumn) {
      clone = new TreeColumn(this.field, this.label, this.width);
    } else if (column instanceof HyperLinkColumn) {
      clone = new HyperLinkColumn(this.field, this.label, this.field, this.fieldType, this.width, this.handlers);
    } else if (column instanceof DateCheckboxColumn) {
      clone = new DateCheckboxColumn(this.field, this.label, this.field, this.width, this.handlers);
    } else if (column instanceof BaseColumn) {
      clone = new BaseColumn(this.field, this.label, this.field, this.fieldType, this.width, this.handlers);
    } else {
      console.error('Not implemented clone for {}.', column);
      return null;
    }
    clone = Object.assign(clone, column);
    if ((clone as TreeColumn).map) {
      (clone as TreeColumn).map = Object.assign(new Map(), (clone as TreeColumn).map || {});
    }
    if (clone.handlers) {
      clone.handlers = Object.assign({}, clone.handlers || {});
    }
    if (clone.handlers && clone.handlers.editor) {
      clone.handlers.editor = Object.assign({}, clone.handlers.editor || {});
    }
    if (clone.search && clone.search.displayFilter) {
      clone.initHeadSearch();
    }
    return clone;
  }
}

export class TextColumn extends BaseColumn {
  constructor(id: number | string, label: string, field: string | DateField | ((obj: any) => any), fieldType: FieldType, width, handlers?: ColumnHandlers, search?, align?) {
    super(id, label, field, fieldType, width, handlers, search);
    if (!isNullOrUndefined(align)) {
      this.align = align;
    }
  }
}

export class ActionsColumn extends BaseColumn {
  public getDisplayType(row?: any, value?: any): CellDisplayType {return  CellDisplayType.ACTIONS; }

  constructor(id: number, label: string, width) {
    super(id, label, null, FieldType.ACTIONS, width, null, null);
  }
}

export class HyperLinkColumn<T = any> extends TextColumn {
  public newWindow: boolean | 'auto' = true; // if newWindow === auto then detects it if Ctrl Key is in the Mouse Event

  public getDisplayType(row?: any, value?: any): CellDisplayType {
    return CellDisplayType.LINK;
  }

  constructor(id: number | string, label: string, field: string | DateField | ((obj: any) => any), fieldType: FieldType,
              width, handlers?: ColumnHandlers, search?, align?, link?: (value: any) => any) {
    super(id, label, field, fieldType, width, handlers, search, align);
    if (link) {
      this.handlers.link = link;
    }
  }

  public setHyperLink(link?: (item: T, value?: any) => any): HyperLinkColumn<T> {
    this.handlers.link = link;
    return this;
  }

  public getHyperLink(row: any, value: any, newWindow: boolean): HyperLink {
    return this.handlers.link ? this.handlers.link(row, value, newWindow) : null;
  }

  setNewWindow(newWindow: boolean | 'auto'): HyperLinkColumn<T> {
    this.newWindow = newWindow;
    return this;
  }

  convertToBaseColumn(): BaseColumn {
    let column = new BaseColumn(this.id, this.label, this.field, this.fieldType, this.width, this.handlers);
    return column;
  }
}

/*
export class HyperLinkTargetColumn extends TextColumn {
  public getDisplayType(row?:any, value?:any): CellDisplayType {return CellDisplayType.TARGET}

  constructor(id: number, label: string, field: string, fieldType: FieldType, width, handlers?:ColumnHandlers, search?, align?, link?: (value: any)=> any) {
    super(id, label, field, fieldType, width, handlers, search, align);
    if (link)
      this.handlers.link = link;
  }

  public getHyperLink(row: any, value:any): any {
    return this.handlers.link ? this.handlers.link(row, value) : [];
  };
}
*/
export class DialogLinkColumn extends HyperLinkColumn {
  public getDisplayType(row?: any, value?: any): CellDisplayType { return CellDisplayType.DIALOG ; }

  constructor(id: number | string, label: string, field: string, fieldType: FieldType, width, handlers?: ColumnHandlers, search?, align?, link?: (value: any) => any) {
    super(id, label, field, fieldType, width, handlers, search, align, link);
  }
}

export class CustomColumn<T> extends BaseColumn {
  public component: Type<T>;
  public data?: any;
  getDisplayType(row?: T, value?: any) {
    return CellDisplayType.CUSTOM;
  }

  setComponent(component: Type<T>, data?: any): CustomColumn<T> {
    this.component = component;
    this.data = data;
    return this;
  }
}

export class CheckboxColumn extends BaseColumn {
  protected onInit() {
    super.onInit();
    this.align = 'center';
  }

  getDisplayType(row?: any, value?: any) {
    return CellDisplayType.CHECKBOX;
  }
}

export class DateCheckboxColumn extends CheckboxColumn {

  constructor (id, label, field, width, handlers?: ColumnHandlers) {
    super(id, label, field, FieldType.DATETIME, width, handlers);

    if (isNullOrUndefined(this.handlers.converter)) {
      this.handlers.converter = (row, value) => !isNullOrUndefined(value);
    }

    if (isNullOrUndefined(this.handlers.tooltip)) {
      this.handlers.tooltip = (value) => convertDateTime(this.getValue(value));
    }
  }
}

export class WeightColumn extends BaseColumn {
  public units: MeasureUnit = OmsConstants.DEFAULT_WEIGHT_UNITS;


  onInit() {
    super.onInit();
    this.align = 'end';
  }

  constructor (id, label, field,  fieldType, width, handlers?: ColumnHandlers, search?) {
    super(id, label, field, fieldType, width, handlers, search);
    this.label = '';
    this.handlers.header = {select: [WeightUnits.WEIGHT_LBS, WeightUnits.WEIGHT_KG], selectLabel: 'label', selected: () => this.units, apply: (units) => this.units = units, displaySelectedLabel: true};
  }

  getDigits() {
    return !isNullOrUndefined(this.handlers.params) && !isNullOrUndefined(this.handlers.params.digits) ? this.handlers.params.digits : this.units.digits;
  }

  convert(row, value: number): any {
    return toFixed(WeightUnits.fromDefault(value, this.units), this.getDigits()) || OmsConstants.EMPTY_VALUE;
  }

  convertBack(value: number): number {
    return WeightUnits.toDefault(value, this.units);
  }
}

export class VolumeColumn extends BaseColumn {
  public units: MeasureUnit = OmsConstants.DEFAULT_VOLUME_UNITS;

  constructor (id, label, field,  fieldType, width, handlers?: ColumnHandlers, search?) {
    super(id, label, field, fieldType, width, handlers, search);
    this.label = '';
    this.handlers.header = {select: [VolumeUnits.VOLUME_CFT, VolumeUnits.VOLUME_CBM], selectLabel: 'label', selected: () => this.units, apply: (units) => this.units = units, displaySelectedLabel: true};
  }


  onInit() {
    super.onInit();
    this.align = 'end';
  }

  getDigits() {
    return !isNullOrUndefined(this.handlers.params) && !isNullOrUndefined(this.handlers.params.digits) ? this.handlers.params.digits : this.units.digits;
  }

  convert(row, value: number): any {
    return toFixed(VolumeUnits.fromDefault(value, this.units), this.getDigits()) || OmsConstants.EMPTY_VALUE;
  }

  convertBack(value: number): number {
    return VolumeUnits.toDefault(value, this.units);
  }

}

export class LoadTypeColumn extends BaseColumn {

  protected onInit() {
    this.align = 'center';
    this.handlers.icon = (load: Load) => {
      if (isNullOrUndefined(load.loadType)) {
        return null;
      }

      switch (load.loadType) {
        case LoadType.RECOVERY: return 'fa fa-long-arrow-up';
        case LoadType.DELIVERY: return 'fa fa-long-arrow-down';
        default: return 'fa fa-arrows-h';
      }
    };

    this.handlers.converter = () => null;

    this.handlers.tooltip = (load: Load) => {
      if (isNullOrUndefined(load)) {
        return null;
      }
      switch (load.loadType) {
        case LoadType.RECOVERY: return 'Recovery Load';
        case LoadType.DELIVERY: return 'Delivery Load';
        default: return '';
      }

    };
  }
}


export class DateTimeColumn extends BaseColumn {

  constructor(id, label, field: string | DateField | ((obj) => string), width, search?, handlers?: ColumnHandlers) {
    super(id, label, typeof field === 'string' ? {actual: field} : field, FieldType.DATETIME, width, handlers, search);
    this.setSearch({searchable: true, search: '', searchFunction: 'isTheSameDay', searchType: 'time'});
  }

  get estimation(): boolean {return !isUndefined(this.field.estimated); }
  get actualField(): Date { return this.field.actual || this.field; }
  get estimatedField(): Date { return this.field.estimated; }

  public getActual(object): Date {
    return this.field ? super.getValue(object, this.field.actual) : undefined;
  }

  public getEstimated(object): Date | undefined {
    return this.estimation ? super.getValue(object, this.field.estimated) : undefined;
  }

  public getTooltip(object): string {

    return super.getTooltip(object) || this.estimation ?
        'EST: ' + convertToLongDateTime(this.getEstimated(object), OmsConstants.EMPTY_VALUE) + '\n' +
        'ACT: ' + convertToLongDateTime(this.getActual(object), OmsConstants.EMPTY_VALUE) :
      convertToLongDateTime(this.getActual(object), OmsConstants.EMPTY_VALUE);
  }


  public getClass(row, value): string {
    let cls = super.getClass(row, value) || '';
//    //if (!isNullOrUndefined(cls)) {
//      console.log('estimated', cls, value);
//      return cls;
//    }

    if (!this.estimation) {
      return cls;
    }

    if (!isNullOrUndefined(this.getActual(row))) {
      let cssClass = 'date-actual';
      return cls + ' ' + cssClass + ' ' + getPendingStatusClass(getPendingStatusEstimated(this.getEstimated(row))) + '-estimated';
    }

    if (!isNullOrUndefined(this.getEstimated(row))) {
      let result = 'date-estimated';

      let pendingClass = getPendingStatusClass(getPendingStatusEstimated(this.getEstimated(row)));
      if (!isNullOrUndefined(pendingClass)) {
        result += ' ' + pendingClass;
      }

      return cls + ' ' + result;
    }

    return cls;
  }

  public getIconClass(object): string {
/*    if (typeof this.field === 'string')
      return null;

    if (!isNullOrUndefined(this.getActual(object)))
      return 'fa fa-check-circle';

    if (!isNullOrUndefined(this.getEstimated(object)))
      return 'fa fa-angle-double-right'; */
    return null;
  }

  public getValue(object: any, field?: string | ((obj) => string)) {
    return field ? super.getValue(object, field) : (this.getActual(object) || this.getEstimated(object));
  }
}

export class ArrivalDateColumn extends DateTimeColumn {


  public getClass(object: Master|Order): string {
    let masterDateArrivalAct = object instanceof Order ? object.dateArrivalAct : (object instanceof Master ? object.dateArrivalAct : null);
    let masterDateArrivalEst = object instanceof Order ? object.dateArrivalEst : (object instanceof Master ? object.dateArrivalEst : null);

    let datePickupAct = object instanceof Order ? object.datePickupAct : (object instanceof Master ? object.datePickupActual : null);
    let datePickupEst = object instanceof Order ? object.datePickupEst : (object instanceof Master ? object.datePickupEstimated : null);

    let master = object instanceof Order ? object.master : object;
    let skipImpendingWarning = isNullOrUndefined(master) || (!isNullOrUndefined(master.cargoBuilding) && master.cargoBuilding.cfs3plLocation);

    let result = '';
    let nowEST = maxETCDateFromNow();
    if (!isNullOrUndefined(masterDateArrivalAct)) {
      result = 'date-actual';
      if (isNullOrUndefined(datePickupAct) && !skipImpendingWarning ) {
        let days = daysDiff(nowEST, convertToEST(masterDateArrivalAct));
        if (days === 0) {
          result += ' date-impending';
        } else if (days < 0) {
          result += ' date-past-due';
        }

        if (!isNullOrUndefined(masterDateArrivalEst)) {
          let convertedEst = convertToEST(masterDateArrivalEst);
          let beforeEst = isBefore(nowEST, convertedEst);
          let diff = daysDiff(nowEST, convertedEst);
          if (beforeEst && diff === 0) {
            result += ' date-impending-estimated';
          } else if (!beforeEst) {
            result += ' date-past-due-estimated';
          }
        }
      }
    } else if (!isNullOrUndefined(masterDateArrivalEst)) {
      result = 'date-estimated';
      if (isNullOrUndefined(datePickupAct) && !skipImpendingWarning) {  // Matters until Actual Pickup Date is set
        let convertedEst = convertToEST(masterDateArrivalEst);
        let beforeEst = isBefore(nowEST, convertedEst);
        let days = daysDiff(nowEST, convertedEst);

        if (beforeEst && days === 0) {
          result += ' date-impending';
        } else if (!beforeEst) {
          result += ' date-past-due';
        }
      }
    }

    return result;
  }
}

export class DateColumn extends DateTimeColumn {
  onInit() {
    super.onInit();
    this.fieldType = FieldType.DATE;
  }
}

export class TreeColumn extends BaseColumn {
  map: Map<string, BaseColumn> = new Map<string, BaseColumn>();

  constructor(id, label, width, ...columns: {row: string, column: BaseColumn}[]) {
    super(id, label, null, null, width, {converter: (row, value) => isNullOrUndefined(value) ? null : value});
    columns.forEach((column) => {
      this.map.set(column.row, column.column);
      column.column.parent = this;
    });


/*    for (let column of columns) {
      this.map.set(column.row, column.column);
    }*/

  }

  byRow(row: RowObject) {
    let column = isNullOrUndefined(row) ? null : this.map.get(row.rowId);
    return isNullOrUndefined(column) ? this : column;
  }

  public getValue(row: RowObject, field?) {
    let column = isNullOrUndefined(row) ? null : this.map.get(row.rowId);
    return isNullOrUndefined(column) ? super.getValue(row, field) : column.getValue(row, field);
  }

  convert(row, value: any | null): string {
    let column = isNullOrUndefined(row) ? null : this.map.get(row.rowId);
    return isNullOrUndefined(column) ? super.convert(row, value) : column.convert(row, value);
  }

  public setColumns(...columns: { row: string, column: BaseColumn }[]): TreeColumn {
    columns.forEach((column) => this.map.set(column.row, column.column));
    return this;
  }

  clone(): TreeColumn {
    return super.clone() as TreeColumn;
  }

}
