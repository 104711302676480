import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {CfsLocation} from '../modules/shared/models/address';
import {CfsLocationDialogComponent} from '../components/dialogs/cfs-location-dialog/cfs-location-dialog.component';
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {Observable} from "rxjs";
import {DialogResult, ModalResult, OmsDialogsService} from "../components/common/oms-dialogs";

@Injectable({providedIn: 'root'})
export class CfsLocationService extends BaseEntityService<CfsLocation> {
  apiUrl = '/oms/cfs-location/';
  classType = CfsLocation;

  public constructor(
    public dialogs: OmsDialogsService,
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  public edit(location: CfsLocation, save: boolean, readonly?: boolean): Promise<CfsLocation> {
    return new Promise<CfsLocation>((success, reject) => {
      this.dialogs.openDialog(CfsLocationDialogComponent, {location: location, readonly: readonly})
        .then((result: DialogResult<any>) => {
          if (result.result === ModalResult.OK) {
//            console.log('Edited', location);
            if (save) {
              this.save(location).subscribe(saved => success(saved), error => reject(error));
            } else {
              success(location);
            }
          }
        })
        .catch(error => reject(error));
    });
  }

  getLocationsByAddress(addressId: number): Observable<CfsLocation[]> {
    return this.httpRestService.getWithOptions<CfsLocation[]>(this.apiUrl, this.httpUtils.getHTTPHeaders(), {addressId: addressId})
      .pipe(map(items => {
        return items.map(item => plainToClass(this.classType, item));
      }));
  }
}
