import {UserRole} from '../../shared/models/user-role';
import {User} from '../../shared/models';
import {plainToClass} from "class-transformer";
import {LoggedUser} from "../../shared/models/logged-user";

export class UserUtil {

  public static formatUserRole(userRole: UserRole): string {
    if (userRole.label)
      return userRole.label;

    const name = userRole.roleName
      .replace('ROLE_', '')
      .replace('_', ' ');
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  public static hasRole(user: User, userRole: UserRole): boolean {
    return (user.userRoles || [])
      .some(ur => ur.id === userRole.id);
  }

  public static getCurrentUser(): LoggedUser {
    let user = JSON.parse(localStorage.getItem("currentUser")) as LoggedUser;
    user = plainToClass(LoggedUser, user);
    if (user && !user.isValid()) {
      return null;
    }
    return user;
  }
}
