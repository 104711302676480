var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RestService } from '../../../../services/rest.service';
import { HttpUtilsService } from '../../../../services/http-utils.service';
import { BaseEntityService } from '../../../../services/base/base-entity.service';
import { SpecialService } from "../../models/manifest/special-service";
var SpecialServiceService = /** @class */ (function (_super) {
    __extends(SpecialServiceService, _super);
    function SpecialServiceService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/special-services/';
        _this.classType = SpecialService;
        return _this;
    }
    return SpecialServiceService;
}(BaseEntityService));
export { SpecialServiceService };
