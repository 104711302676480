import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../../services/base/base-entity.service';
import {RestService} from '../../../services/rest.service';
import {HttpUtilsService} from '../../../services/http-utils.service';
import {UserVisibilityFilter} from "../models/uservisibilityfilter/user-visibility-filter";
import {Observable} from "rxjs";
import {classToPlain, plainToClass} from "class-transformer";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class UserVisibilityService extends BaseEntityService<UserVisibilityFilter> {

  protected apiUrl = '/api/user-visibility-filters/';
  protected classType = UserVisibilityFilter;

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }


  create(item: UserVisibilityFilter): Observable<UserVisibilityFilter> {
    return this.httpRestService.post<UserVisibilityFilter>(this.apiUrl + 'createDto', classToPlain(item))
      .pipe(map(response => plainToClass(this.classType, response)));
  }


  update(item: UserVisibilityFilter): Observable<UserVisibilityFilter> {
    return this.httpRestService.put<UserVisibilityFilter>(this.apiUrl + 'updateDto', classToPlain(item), {headers: this.httpUtils.getHTTPHeaders()})
      .pipe(map(response => plainToClass(this.classType, response)));
  }
}
