import {Component, Input} from '@angular/core';
import {clamp, toFixed} from '../../../_helpers/utils';


export enum FillStatus {
  NONE, EMPTY, HALF, FULL, OVER
}


function toClass(status: FillStatus):string {
  switch (status) {
    case FillStatus.NONE: return '';
    case FillStatus.EMPTY: return 'empty';
    case FillStatus.HALF: return 'half';
    case FillStatus.FULL: return 'full';
    case FillStatus.OVER: return 'over';
  }
}

@Component({
  selector: 'oms-fill-indicator',
  templateUrl: './fill-indicator.component.html',
  styleUrls: ['./fill-indicator.component.scss']
})
export class FillIndicatorComponent {
  @Input() public fill: number | FillStatus;
  @Input() public label: string;

  get fillClass(): string {
    return typeof this.fill === 'number' ? '' : this.fill;
  }

  get fillPercents():string {
    return typeof this.fill === 'number' ? toFixed(clamp(this.fill, 0, 100), 0)+'%' : null;
  }

  get isFull():boolean {
    return typeof this.fill === 'number' && this.fill == 100;
  }

  get isOver():boolean {
    return typeof this.fill === 'number' && this.fill>100;
  }
}

