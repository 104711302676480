<context-menu #contextMenu [disabled]="false">
  <!-- MENU HEADER -->
  <ng-template contextMenuItem passive="true">
    <span>{{menuHeader}}</span>
  </ng-template>

  <ng-template contextMenuItem (execute)="splitOrder(selectedOneOrder)" [enabled]="!!selectedOneOrder">
    <i class="fa fa-clone"></i><span>Split&hellip;</span>
  </ng-template>


  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem (execute)="consolidate()">
    <i class="fa fa-object-group"></i><span>Consolidate</span>
  </ng-template>

  <ng-template contextMenuItem (execute)="unconsolidate()">
    <i class="fa fa-object-ungroup"></i><span>Deconsolidate</span>
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem (execute)="generateBOL(false, false)">
    <i class="fa fa-object-ungroup"></i><span>Create BOL</span>
  </ng-template>

  <ng-template contextMenuItem (execute)="generateBOL(true, true)">
    <i class="fa fa-object-ungroup"></i><span>Print BOL</span>
  </ng-template>

  <ng-template contextMenuItem (execute)="generateBOL(true, false)" [enabled]="!!selectedOneOrder">
    <i class="fa fa-object-ungroup"></i><span>Create and Print</span>
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem (execute)="redispatchSelected(manifestItem)">
    <i class="fa fa-user-plus"></i><span>Redispatch&hellip;</span>
  </ng-template>

  <ng-template contextMenuItem [visible]="isVisibleSelect" divider="true"></ng-template>


  <ng-template contextMenuItem [visible]="isVisibleSelect" (execute)="selectAll()">
    <i class="fa fa-check-square-o"></i><span>SelectAll</span>
  </ng-template>

  <ng-template contextMenuItem [visible]="isVisibleSelect" (execute)="unselect()">
    <i class="fa fa-square-o"></i><span>Clear Selection</span>
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem [subMenu]="submenuDriverActions" *ngIf="canDriverUpdate">
    <i class="fa fa-truck"></i><span>Driver Updates&hellip;&nbsp;</span>
  </ng-template>

  <context-menu #submenuDriverActions>
    <ng-template contextMenuItem (execute)="driverAction('COMPLETED')">
      <i class="fa fa-check"></i><span>Completed&hellip;</span>
    </ng-template>
    <!--ng-template contextMenuItem (execute)="driverAction('COMPLETED_PROBLEM')">
      <i class="fa fa-exclamation"></i><span>Completed with Problem&hellip;</span>
    </ng-template-->
    <ng-template contextMenuItem (execute)="driverAction('NOT_COMPLETED')">
      <i class="fa fa-times"></i><span>Not Completed&hellip;</span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem (execute)="driverActionCancel()">
      <i class="fa fa-undo"></i><span>Cancel Update</span>
    </ng-template>
  </context-menu>


</context-menu>
