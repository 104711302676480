import {Observable} from 'rxjs';
import {RestService} from '../rest.service';
import {HttpUtilsService} from '../http-utils.service';
import {BaseEntity, Customer} from '../../modules/shared/models';
import {classToPlain, plainToClass} from 'class-transformer';
import {PageResult} from '../../modules/shared/models/query-models/page-result';
import {map, tap} from 'rxjs/operators';
import {FilterPageRequest} from '../../modules/shared/models/filter.page.request';
import {UploadedFiles} from "../../modules/shared/models/documents/uploaded.files";
import {OmsAlertsService} from "../../modules/shared/components/oms-alerts/oms-alerts.service";
import {onAfterLoad, onBeforeSave} from "../oms-decorators";
import {StringUtil} from "../../_helpers/string.util";
import {SearchService, Sort} from "../../common/oms-types";


export abstract class BaseEntityService<T extends BaseEntity> implements SearchService<T> {
  protected DEFAULT_PAGE_SIZE = 15;
  protected abstract apiUrl;
  protected abstract classType;

  // tslint:disable-next-line:no-shadowed-variable
  protected afterLoad<T extends BaseEntity>(entity: T): T {
    // console.log('After Load', entity);
    return onAfterLoad(entity);
  }

  // tslint:disable-next-line:no-shadowed-variable
  protected beforeSave<T extends BaseEntity>(entity: T): T {
    // console.log('Before Save', entity);
    return onBeforeSave(entity);
  }

  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  public constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
  }

  get(id: number, active: boolean = true): Observable<T> {
    const params = this.httpUtils.getHTTPParams({id: id, active: active});
    return this.httpRestService.getWithOptions<T>(this.apiUrl + 'get/' + id, this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((item) => this.afterLoad(plainToClass(this.classType, item))));
  }

  public getMany(ids: number[], active: boolean = true): Observable<T[]> {
    let id: string = ids.join(';');
    const params = this.httpUtils.getHTTPParams({ids: id, active: active});

    return this.httpRestService.getWithOptions<any[]>(this.apiUrl + 'getMany', this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((items) => plainToClass<T, any[]>(this.classType, items).map((item) => this.afterLoad(item))));
  }


  public save(item: T): Observable<T> {
    return item.isNew() ? this.create(item) : this.update(item);
  }

  create(item: T): Observable<T> {
    return this.httpRestService.post<T>(this.apiUrl + 'create', classToPlain(this.beforeSave(item), {enableCircularCheck: true}))
      .pipe(map((i) => this.afterLoad(plainToClass(this.classType, i))));
  }

  update(item: T): Observable<T> {
    let plain = classToPlain(this.beforeSave(item), {enableCircularCheck: true});
    console.log('UPDATE', JSON.stringify(plain));
    return this.httpRestService.put<T>(this.apiUrl + 'update', plain, {headers: this.httpUtils.getHTTPHeaders()})
      .pipe(map((i) => this.afterLoad(plainToClass(this.classType, i))));
  }

  delete(id: number | number[]): Observable<T> {
    return this.httpRestService.delete<T>(this.apiUrl + 'delete', id);
  }

  softDelete(id: number | number[]): Promise<T> {
    return this.httpRestService.delete<any>(this.apiUrl + 'softDelete', id).toPromise();
  }

  restore(ids: number | number[]): Promise<T> {
    return this.httpRestService.delete<T>(this.apiUrl + 'restore', ids).toPromise();
  }

  /**
   * @deprecated use findAllObs
   * @param sort
   */
  findAll(sort?: Sort): Promise<T[]> {
    return this.findAllObs(sort)
      .toPromise();
  }

  findAllObs(sort?: Sort): Observable<T[]> {
    const params = this.httpUtils.getHTTPParams({
      sortField: sort && sort.field || 'id',
      sortOrder: sort && sort.order || 'asc'
    });

    return this.httpRestService.getWithOptions<T[]>(this.apiUrl + 'findAll', this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((items) => plainToClass<T, any[]>(this.classType, items)
        .map((item) => this.afterLoad(item))));
  }

  findBy(by: string, count?: number, active: boolean = true, sort?: Sort): Promise<PageResult<T>> {
//    console.log('find by', by, count);
    return this.findByObs(by, count, active, sort)
      .toPromise();
//      .then((f)=>{console.log('found', f); return f;});
  }

  findByObs(by: string, count?: number, active: boolean = true, sort?: Sort): Observable<PageResult<T>> {
//    sort = sort || {};
    console.log('SEARCH', by, count, active, sort);
    const params = this.httpUtils.getHTTPParams({
      by: by, active: active,
      count: count || this.DEFAULT_PAGE_SIZE,
      sortField: sort && sort.field || 'id',
      sortOrder: sort && sort.order || 'asc'
    });
    const headers = this.httpUtils.getHTTPHeaders();

    return new Observable<PageResult<T>>(sub => {
      this.httpRestService.getWithOptions<PageResult<T>>(this.apiUrl + 'findBy', headers, params).subscribe(
        page => {
          page.content = plainToClass<T, any[]>(this.classType, page.content).map((item) => onAfterLoad(item));
          sub.next(page);
          sub.complete();
        },
        error => {
          console.error('error', error);
          sub.error(error);
          sub.complete();
        });
    });
  }

  find(page: number, size?: number, sort?: { by?: string, asc?: boolean }, active: boolean = true): Promise<PageResult<T>> {
    const params = this.httpUtils.getHTTPParams({
      active: active,
      pageNumber: page,
      pageSize: size || this.DEFAULT_PAGE_SIZE,
      sortOrder: sort ? (sort.asc ? 'asc' : 'desc') : 'asc',
      sortField: sort ? sort.by : 'id'
    });

    return new Promise<PageResult<T>>((result, reject) => {
      this.httpRestService.getWithOptions<PageResult<T>>(this.apiUrl + 'find', this.httpUtils.getHTTPHeaders(), params).subscribe(
        (p) => {
          p.content = plainToClass<T, any[]>(this.classType, p.content).map((item) => this.afterLoad(item));
          result(p);
        },
        error => {
          console.error('error', error);
          reject(error);
        });
    });
  }

  public findByFilters(filterPageRequest: FilterPageRequest, active: boolean = true): Observable<PageResult<T>> {
    if (filterPageRequest && filterPageRequest.filterByColumns) {
      let hasDateDeleted = filterPageRequest.filterByColumns.some((column) => column.field === 'dateDeleted');
      if (hasDateDeleted) {
        active = false;
      }
    }
    return new Observable<PageResult<T>>((obs => {
      return this.httpRestService.post<PageResult<T>>(this.apiUrl + 'findByFilters', filterPageRequest, {params: {active: active}})
        .subscribe(page => {
            page.content = plainToClass<T, any[]>(this.classType, page.content).map((item) => this.afterLoad(item));
            obs.next(page);
            obs.complete();
          },
          error => {
            obs.error(error);
            obs.complete();
          });
    }));
  }

  public getProgress(guid): Observable<any> {
    return this.httpRestService.getWithHeader(this.apiUrl + '/progress/' + guid, this.httpUtils.getHTTPHeaders());
  }

  public downloadExcel(filterPageRequest: FilterPageRequest, alerts: OmsAlertsService, active: boolean = true, mode?: string): Observable<UploadedFiles> {
    let uuid = StringUtil.generateUuid();
    alerts.process('Generating Excel...', '', () => this.getProgress(uuid), 5000);
    return this.httpRestService.post<UploadedFiles>(this.apiUrl + 'downloadExcel', filterPageRequest, {
      params: {
        active: active,
        mode: mode,
        uuid: uuid
      }
    })
      .pipe(tap(
        () => {},
       error => alerts.error(error, "Error Excel Generation:")));
  }

  public downloadExcelByIds(ids: number[], alerts: OmsAlertsService, mode?: string): Observable<UploadedFiles> {
    let uuid = StringUtil.generateUuid();
    alerts.process('Generating Excel...', '', () => this.getProgress(uuid), 2000);
    return this.httpRestService.post<UploadedFiles>(this.apiUrl + 'downloadExcelByIds', null, {
      params: {
        ids: ids.join(","),
        mode: mode,
        uuid: uuid
      }
    })
      .pipe(tap((response) => {
      }, error => {
        alerts.error(error, "Error Excel Generation:");
      }));
  }

}
