<perfect-scrollbar style="max-height: 90vh;">
  <div class="oms-modal widget">

    <div class="widget-header">
      <h3><i class="fa fa-map-marker"></i>
        {{dialogLabel}}
      </h3>
      <div class="btn-group widget-header-toolbar">
        <a class="btn-borderless btn-remove" (click)="closeDialog()">
          <i class="fa fa-times dialog-header-button"></i>
          <!--i class="fa fa-close sb-popup__head-close-icon"></i-->
        </a>
      </div>
    </div>

    <div class="widget-content" style="min-height: 200px;">
      <div class="form-row">

        <div class="form-row col-md-12">
          <div class="form-group">
<!--            <label class="control-label">{{label}}</label>-->
            <oms-string-input [label]="label" [(ngModel)]="text">
            </oms-string-input>
          </div>
        </div>
      </div>

    </div>

    <div class="widget-footer">
      <div></div>
      <div class="dialog-btns">
        <button type="button" class="btn btn-primary" (click)="onSave()">OK</button>
        <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
      </div>
    </div>

  </div>
</perfect-scrollbar>
