import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {plainToClass} from "class-transformer";


import {UldLinked} from "../models/uld-linked";
import {Uld} from "../models";
import {BaseEntityService} from "../../../services/base/base-entity.service";
import {RestService} from "../../../services/rest.service";
import {HttpUtilsService} from "../../../services/http-utils.service";

@Injectable()
export class UldLinkedService extends BaseEntityService<UldLinked> {

  public apiUrl = '/oms/ulds-linked/';
  public classType = UldLinked;

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

}
