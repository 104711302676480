import {Labeled} from '../../../common/oms-types';
import {BaseDeletable} from './base-deletable';
import {Customer} from "./customer";

export class CustomerEmail extends BaseDeletable implements Labeled {
  email: string;
  customer: Customer;
  active: boolean;
  primary: boolean;

  constructor() {
    super();
    this.active = true;
    this.primary = true;
  }

  get label() {
    return this.email;
  }
}
