import {
  ApplicationRef,
  ComponentFactoryResolver, ComponentRef, EmbeddedViewRef,
  Injectable,
  Injector,
  TemplateRef,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {ComponentPortal, ComponentType, DomPortalHost, TemplatePortal} from '@angular/cdk/portal';
import {InputTextDialogComponent} from './input-text-dialog/input-text-dialog.component';
import {NotesCenterDialogComponent} from "../notes-center-dialog";
import {BtnType, BUTTONS_YES_NO, DialogResult, DialogType} from "./oms-dialogs.types";
import {AddressMapInfoDialogComponent} from "../../../modules/shared/components/address-map-info-dialog/address-map-info-dialog.component";
import {Driver, Master, Order, RecoveryOrder} from "../../../modules/shared/models";
import {ChatDialogComponent} from "../../../modules/shared/components/chat-dialog/chat-dialog.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {messageListen, popupCenter} from "../../../_helpers/utils";
import {AlertDialogComponent, AlertDialogConfig } from "./alert-dialog/alert-dialog.component";
import {
  CameraDialogData,
  VideoDialogComponent
} from "../../dialogs/video-dialog/video-dialog.component";
import {Observable} from "rxjs";
import {DocCenterDialogComponent} from "../doc-center/doc-center-dialog";
import {DocCenterComponent, DocCenterConfig} from "../doc-center/doc-center.component";

export enum PageOpenMode {
  NEW_TAB,
  NEW_WINDOW,
  POPUP_DIALOG,
  CHILD_PAGE
}

export interface OmsDialogConfig<D = any> {
  handler?: (d: D) => Promise<D>;
  width?: string;
}


@Injectable({providedIn: 'root'})
export class OmsDialogsService {

  private manifestWindow: Window;
  private mapsWindow: Window;
  private docCenterWindow: Window;

  constructor(private dialog: MatDialog,
              private componentFactoryResolver: ComponentFactoryResolver,
              private applicationRef: ApplicationRef,
              private injector: Injector) { }

  public confirm(type: DialogType, title: string, message: string, buttons: BtnType[] = BUTTONS_YES_NO, options?: {width?: any, maxWidth?: any}): Promise<DialogResult<any>> {
    return new Promise<DialogResult<any>>((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent,
        {disableClose: true, maxWidth: (options ? options.maxWidth : undefined), width: (options ? options.width : undefined ) || 'auto', data: {type: type, title: title, message: message, buttons: buttons}});
      dialogRef.afterClosed().subscribe((res) => resolve(res), error => reject(error));
    });
  }

  public inputText(icon: string, title: string, message: string, text: string, rows?: number): Promise<DialogResult<any>> {
    return this.openDialog(InputTextDialogComponent, {title: title, icon: icon, message: message, text: text, rows: rows});
  }

  public inputInteger(icon: string, title: string, message: string, n: number): Promise<DialogResult<any>> {
    return this.openDialog(InputTextDialogComponent, {title: title, icon: icon, message: message, text: n, rows: 1});
  }

  public openInfoDialog(message: string, config?: AlertDialogConfig): Promise<DialogResult<any>> {
    config = config || {};
    config.message = message;
    return this.openDialog(AlertDialogComponent, config);
  }

  public openErrorDialog(message: string, caption?: string, timeout?: number): Promise<DialogResult<any>> {
    return this.openInfoDialog(message, {mode: 'danger', caption: caption, timeout: timeout});
  }

  public openDialog<T, D>(component: ComponentType<T> | TemplateRef<T>, data: D, params?: OmsDialogConfig): Promise<DialogResult<D>> {
    let config: MatDialogConfig = {
      panelClass: 'oms-dialog-container',
      width: params && params.width || 'auto',
      data: data
    };

    let ref: MatDialogRef<T, DialogResult<any>> = this.dialog.open(component, config);
    // Custom close handler
    if (params && params.handler) {
      ref['oldClose'] = ref.close;
      ref.close = function (res) {
        params.handler(res)
          .then((r) => ref['oldClose'](r))  // call old .close()
          .catch(() => {}); // prevent close
      };
    }

      return ref.afterClosed().toPromise();
//    });
  }


/*  public openComponentWindow<T, D>(component: ComponentType<T>, data: D, width?: string): Promise<DialogResult<D>> {
    const portal = new ComponentPortal<T>(component);
    const externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200');

    const host = new DomPortalHost(
      externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    if (is)
    host.attachComponentPortal(portal);
  }*/

  /**
   * @deprecated
   */
  public openWindow<T, D>(component: ComponentType<T> | TemplateRef<T>, data: D, width?: string): any {
    let isTemplate: boolean = component instanceof TemplateRef;
    const portal: ComponentPortal<T> | TemplatePortal<T> = isTemplate ?
      new TemplatePortal<T>(<TemplateRef<T>>component, null) :
      new ComponentPortal<T>(<ComponentType<T>>component);

    const externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200');
    externalWindow.document.write('<html><head><title>Print it!</title><style type="text/css">.pin-bg { background: pink; width:255px; height: 20px;}</style></head><body onblur="self.focus()"></body>');

    const host = new DomPortalHost(
      externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    let ref: ComponentRef<T> | EmbeddedViewRef<T> = isTemplate ?
      host.attachTemplatePortal(<TemplatePortal<T>>portal) :
      host.attachComponentPortal(<ComponentPortal<T>>portal);

    return ref;


/*    return new Promise<DialogResult<D>>((resolve, reject) => {
      this.dialog.open(component, {panelClass: 'oms-dialog-container', hasBackdrop: false, width: width || 'auto', data: data})
        .afterClosed().subscribe((next) => resolve(next), (error) => reject(error));
    }); */
  }


  private objectType(row: any): {type: 'ORDER' | 'MASTER', obj: any} {
    if (row instanceof Master) {
      return {type: 'MASTER', obj: row};
    }
    if (row instanceof Order) {
      return {type: 'ORDER', obj: row};
    }
    if (row instanceof RecoveryOrder) {
      return {type: 'ORDER', obj: row.order};
    }

    return undefined;
  }


  public openComCenterDialog(id: number, object: 'ORDER' | 'MASTER', noteType: number = 0, readonly: boolean = false) {
    this.dialog.open(NotesCenterDialogComponent, { width: '80%', data: { id: id, objectType: object, noteTypeId: noteType, readonly: readonly} });
  }

  public openObjectComCenterDialog(object: any, noteType: number = 0, readonly: boolean = false) {
    let o = this.objectType(object);
    if (o) { this.openComCenterDialog(o.obj.id, o.type, noteType, readonly); }
  }

  public openDocCenterWindow(config: DocCenterConfig, newTab: boolean = false) {
    let type = config.objectType === 'ORDER' ? 'order' : 'master';
    let url = `/doc-center?${type}=${config.id}`;

    if (newTab) {
      window.open(url, '_blank');
    } else {
      if (this.docCenterWindow && this.docCenterWindow.window) {
        this.docCenterWindow.focus();
        let temp = this.docCenterWindow;
        this.docCenterWindow.location.assign(url);
        setTimeout(() => {
          this.docCenterWindow = temp;
          this.docCenterWindow.addEventListener('unload', () => this.docCenterWindow = undefined);
        });
      } else {
        this.docCenterWindow = popupCenter(url, 'Doc Center', 1024, 800);
        this.docCenterWindow.addEventListener('unload', () => this.docCenterWindow = undefined);
      }
    }
  }

  public openDocCenterDialog(options: DocCenterConfig) {
      this.openDialog(DocCenterDialogComponent, {config: options}, {}).then();
  }

  public openObjectDocCenterDialog(object: any, readonly: boolean = false) {
    let o = this.objectType(object);
    if (o) {
      this.openDocCenterWindow({id: o.obj.id, objectType: o.type, readonly: readonly});
    }
  }

  public openDriverLocationDialog(driver: Driver) {
    this.dialog.open(AddressMapInfoDialogComponent, { width: '50%', data: { driver: driver,  searchByAddress: false}});
  }

  public openChatDialog(driver?: Driver) {
    this.dialog.open(ChatDialogComponent, { width: '70%', data: { driver: driver}});
  }

  public openManifestWindow(id: number, readonly: boolean = false, handler: (data) => void) {
    let url = `/manifest?id=${id}`;

    if (this.manifestWindow && this.manifestWindow.window) {
      console.log('focus', this.manifestWindow);
      this.manifestWindow.focus();
      let temp = this.manifestWindow;
      this.manifestWindow.location.assign(url);
      setTimeout(() => {
        this.manifestWindow = temp;

        messageListen(handler);
        this.manifestWindow.addEventListener('unload', () => {
          this.manifestWindow = undefined;
        });


      });
    } else {
      this.manifestWindow = popupCenter(url, 'Manifest', 1024, 800);
      messageListen(handler);
      this.manifestWindow.addEventListener('unload', () => {
        this.manifestWindow = undefined;
      });

    }
  }

  public openMapsWindow(externalRouteId: number) {
    let url = `/map?id=${externalRouteId}`;

    if (externalRouteId) {
      if (this.mapsWindow && this.mapsWindow.window) {
        this.mapsWindow.focus();
        let temp = this.mapsWindow;
        this.mapsWindow.location.assign(url);
        setTimeout(() => {
          this.mapsWindow = temp;

          this.mapsWindow.addEventListener('unload', () => {
            this.mapsWindow = undefined;
          });
        });
      } else {
        this.mapsWindow = popupCenter(url, 'Map', 1024, 800);

        this.mapsWindow.addEventListener('unload', () => {
          this.mapsWindow = undefined;
        });
      }
    }
  }

  public openCameraDialog(data: CameraDialogData): Observable<any> {
    const dialogRef = this.dialog.open(VideoDialogComponent, {width: 'auto', data: data});

    let obs: Observable<any> = dialogRef.afterClosed();
    obs.subscribe((res) => {
      if (res) { }
    });
    return obs;
  }
}
