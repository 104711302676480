import {BaseEntityService} from './base/base-entity.service';
import {Injectable} from '@angular/core';
import {HttpUtilsService} from './http-utils.service';
import {RestService} from './rest.service';
import {plainToClass} from "class-transformer";
import {Driver} from "../modules/shared/models";
import {ChatRecord} from "../modules/shared/models/chat.record";

@Injectable({providedIn: 'root'})
export class ChatService extends BaseEntityService<ChatRecord> {
  apiUrl = '/oms/chat-records/';
  classType = ChatRecord;

  public constructor(
    public httpRestService: RestService,
    public httpUtils: HttpUtilsService) {
    super(httpRestService, httpUtils);
  }

  findAllByDriverId(driverId: Driver): Promise<ChatRecord[]> {
    return new Promise<ChatRecord[]>((result, reject) => {
      this.httpRestService.getWithHeader<ChatRecord[]>(this.apiUrl + "driver/" + driverId + "/records", this.httpUtils.getHTTPHeaders())
        .subscribe(
          items => {
            result(items.map(item => plainToClass(this.classType, item)));
          },
          error => reject(error));
    });
  }
}
