
export class WizardHeader {
  public config: any = {};

  constructor() {
    this.config = [
      {
        applyFor: ["/recovery", "cfs_and_delivery", "billing", "all"],
        steps: [
          {
            label: "Recovery",
            href: "/recovery"
          },
          {
            label: "CFS And Delivery",
            href: "/cfs_and_delivery"
          },
          {
            label: "Billing",
            href: "/billing"
          },
          {
            label: "All",
            href: "/all",
            iconClass: "fa-plus-circle",
            role:"ROLE_ADMIN"
          }
        ]
      }
    ];
  }
}
