import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {AbstractComponent} from "../../../common/component/abstract.component";
import {LoadStatusFilterChoose} from "./load-status-filter-choose";
import {LoadStatusFilterType} from "./load-status-filter-type";

@Component({
  selector: 'oms-load-status-filter',
  styleUrls: ["./load-status-filter.component.scss"],
  templateUrl: 'load-status-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadStatusFilterComponent extends AbstractComponent {

  @Input() chooses: LoadStatusFilterChoose[] = this.createDefaultTypes();
  @Output() choose = new EventEmitter<LoadStatusFilterChoose>();

  @HostBinding('class.load-status-filter') rootClass: boolean = true;

  public applyFilterByStatus(choose: LoadStatusFilterChoose): void {
    choose.selected = !choose.selected;
    this.choose.emit(choose);
  }

  private createDefaultTypes(): LoadStatusFilterChoose[] {
    return LoadStatusFilterType.values().map(type => new LoadStatusFilterChoose(type, false));
  }
}
