import { DataTableComponent } from "./components/base/data-table/data-table.component";
import { TableFilterPipe, TablePagingPipe, TableSortPipe } from './components/base/data-table/data-table.utils';
import { OmsScrollerDirective } from "./components/base/data-table/utils/oms-scroller.directive";
import { DateInputComponent } from "./components/common/input/date-input/date-input.component";
import { HighlightSearch, MasterNumberPipe, MawbNumberPipe, OmsDatePipe, OmsDateTimePipe, OmsDateTimeZonePipe, OmsWeightPipe, SafeHtmlPipe, SafeUrlPipe, OrderNumberPipe } from './services/oms-converters.service';
import { BasePageComponent } from "./components/base/base-page/base-page.component";
import { SlidePanelComponent } from '../../components/common/slide-panel/slide-panel.component';
import { OmsCheckboxComponent } from './components/oms-checkbox/oms-checkbox.component';
import { OffClickDirective } from './components/offclick.directive';
import { CustomSearchComponent } from "./components/common/input/custom-search/custom-search.component";
import { WeightInputComponent } from "./components/common/input/weight-input/weight-input.component";
import { VolumeInputComponent } from "./components/common/input/volume-input/volume-input.component";
import { StringInputComponent } from "./components/common/input/string-input/string-input.component";
import { SelectableTextAreaInputComponent } from "./components/common/input/selectable-text-area-input/selectable-text-area-input.component";
import { PiecesInputComponent } from "./components/common/input/pieces-input/pieces-input.component";
import { MaxCountInputComponent } from "./components/common/input/max-count-input/max-count-input.component";
import { SelectButtonComponent } from "./components/common/select-button/select-button.component";
import { FormSelectComponent, FormTextComponent, ValidationComponent } from './components/base/base-input/components';
import { ToggleSwitchComponent } from "./components/common/toggle-switch/toggle-switch.component";
import { UldInputComponent } from "./components/common/input/uld-input/uld-input.component";
import { IfRoleDirective } from '../../_helpers/if-role.directive';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { OnInitDirective } from './components/on-init.directive';
import { OmsWidgetComponent } from "../../components/common/oms-widget/oms-widget.component";
import { BaseDialogComponent } from "../../components/common/oms-dialogs/base-dialog/base-dialog.component";
import { ColumnsConfigurationComponent } from '../../components/common/columns-configuration';
import { DialogDraggableTitleDirective } from '../../components/common/oms-dialogs/utils/dialog-draggable-title.directive';
import { MasterEditorComponent } from "../../pages/masters/master-editor/master-editor.component";
import { LoadTableColumnsFilter, MasterImportAirComponent, OrderTableColumnsFilter, PartsBySelectedLoadFilter } from "../../pages/masters/master-editor/master-import-air/master-import-air.component";
import { AddressSearchComponent } from "../../components/common/address-search";
import { AirportSearchComponent } from "../../components/common/airport-search";
import { tooltipConfig } from "../../common/oms-constants.service";
import { OrderEditorComponent } from "../../pages/orders/order-editor/order-editor.component";
import { GenericOrderFormComponent } from "../../pages/orders/order-editor/generic-order-form/generic-order-form.component";
import { FreightForwarderSearchComponent } from "../../components/common/freight-forwarder-search/freight-forwarder-search.component";
import { CustomerDialogComponent } from "../../components/common/customer-dialog/customer.dialog.component";
import { FreightForwarderDialogComponent } from "../../components/common/freight-forwarder-dialog";
import { CustomerSearchComponent } from "../../components/common/customer-search";
import { TooltipConfig } from "ngx-bootstrap/tooltip";
import { OrdersTableComponent } from '../../pages/masters/masters-list/orders-table/orders-table.component';
import { HexadecimalValueValidator, MaxCountValidator, MinCountValidator } from "../../common/validators";
import { UldNumberValidator } from "../../common/validators/uld-number.validator";
import { OmsSpinnerComponent } from "../../components/common/oms-spinner/oms-spinner.component";
import { MastersTableComponent } from "../../pages/masters/masters-list/masters-table/masters-table.component";
import { ManifestCreateContentComponent } from "../dispatch/pages/manifest-create/manifest-create-content.component";
import { ManifestCreatePanelComponent } from "../dispatch/pages/manifest-create-panel/manifest-create-panel.component";
import { EditableCellComponent } from "../dispatch/pages/manifest-create/editable-cell/editable-cell.component";
import { ShipmentListComponent } from "../dispatch/pages/shipment-list/shipment-list.component";
import { OrdersListComponent } from "../logs/pages/orders-list/orders-list.component";
import { SplitOrderDialogComponent } from "../dispatch/pages/manifest-create/split-order-dialog/split-order-dialog.component";
import { RestrictedDirective } from "../../_helpers/restricted.directive";
import { OmsTabsComponent } from './components/common/oms-tabs/oms-tabs.component';
import { OmsTabDirective } from './components/common/oms-tabs/oms-tab.directive';
import { CarrierDialogComponent } from "../settings/pages/address-book/tabs/carrier-list/delivery-dialog/carrier-dialog.component";
import { LoadStatusFilterComponent } from "../../components/common/load-status-filter/load-status-filter.component";
import { ManifestItemContextMenuComponent } from "../dispatch/pages/manifest-create/manifest-item-context-menu/manifest-item-context-menu.component";
import { OrderSubscriptionDialogComponent } from "../logs/pages/orders-list/order-subscription-dialog/order-subscription-dialog.component";
import { DynamicInjectorComponent } from "./components/oms-alerts/dynamic-injector/dynamic-injector.component";
import { FilterItemsComponent } from "../logs/pages/orders-list/filter-items/filter-items.component";
import { BillingInfoListDialogComponent } from "../../pages/orders/order-editor/billing-info-list-dialog/billing-info-list-dialog.component";
import { DocumentPreviewDialogComponent } from "../../components/common/oms-dialogs/document-preview-dialog/document-preview-dialog.component";
import { DropZoneDirective } from "./components/drop-zone.directive";
import { OmsCameraComponent } from "../../components/common/oms-camera/oms-camera.component";
import { LoadPickupComponent } from "../../components/common/load-pickup/load-pickup.component";
import { CurrencyInputComponent } from "./components/common/input/currency-input/currency-input.component";
import { DocCenterPageComponent } from "../../components/common/doc-center/doc-center-page/doc-center-page.component";
import { DocumentPreviewComponent } from "../../components/common/doc-center/document-preview/document-preview.component";
import { DocCenterDialogComponent } from "../../components/common/doc-center/doc-center-dialog";
import { DocCenterComponent } from "../../components/common/doc-center/doc-center.component";
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var omsSharedPipes = [OmsDatePipe, OmsDateTimePipe, OmsDateTimeZonePipe, OmsWeightPipe,
    MawbNumberPipe, MasterNumberPipe, OrderNumberPipe, TableSortPipe, TablePagingPipe, TableFilterPipe];
var omsSharedComponents = [
    DataTableComponent, SlidePanelComponent, HighlightSearch,
    OmsScrollerDirective,
    DateInputComponent,
    BasePageComponent,
    OmsWidgetComponent,
    OmsCheckboxComponent,
    OmsTabsComponent, OmsTabDirective,
    OnInitDirective,
    OffClickDirective,
    DropZoneDirective,
    IfRoleDirective, RestrictedDirective,
    ValidationComponent,
    WeightInputComponent,
    VolumeInputComponent,
    CurrencyInputComponent,
    StringInputComponent,
    SelectableTextAreaInputComponent,
    PiecesInputComponent,
    UldInputComponent,
    MaxCountInputComponent,
    SelectButtonComponent,
    ToggleSwitchComponent,
    CustomSearchComponent,
    BaseDialogComponent,
    ColumnsConfigurationComponent,
    DialogDraggableTitleDirective,
    FormTextComponent,
    FormSelectComponent,
    // todo move to doc-center Module
    [DocCenterComponent, DocumentPreviewDialogComponent, DocCenterPageComponent, DocCenterDialogComponent, DocumentPreviewComponent],
    AddressSearchComponent,
    AirportSearchComponent,
    LoadTableColumnsFilter,
    OrderTableColumnsFilter,
    PartsBySelectedLoadFilter,
    CustomerSearchComponent,
    CustomerDialogComponent,
    FreightForwarderSearchComponent,
    FreightForwarderDialogComponent,
    MaxCountValidator,
    MinCountValidator,
    HexadecimalValueValidator,
    UldNumberValidator,
    MasterEditorComponent,
    MasterImportAirComponent,
    OrderEditorComponent,
    GenericOrderFormComponent,
    OrdersTableComponent,
    OmsSpinnerComponent,
    MastersTableComponent,
    ManifestCreateContentComponent,
    ManifestCreatePanelComponent,
    CarrierDialogComponent,
    SplitOrderDialogComponent,
    OrdersListComponent,
    ShipmentListComponent,
    EditableCellComponent,
    LoadStatusFilterComponent,
    ManifestItemContextMenuComponent,
    OrderSubscriptionDialogComponent,
    DynamicInjectorComponent,
    FilterItemsComponent,
    BillingInfoListDialogComponent, SafeHtmlPipe, SafeUrlPipe,
    OmsCameraComponent,
    LoadPickupComponent
];
var ɵ0 = function () { return Object.assign(new TooltipConfig(), tooltipConfig); }, ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0, ɵ1 };
