var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { HttpUtilsService } from "../http-utils.service";
import { BaseEntityService } from "../base/base-entity.service";
import { OrderSubscription } from "../../modules/shared/models/order/order-subscription";
import { IPostHttpOptions, RestService } from "../rest.service";
var OrderSubscriptionService = /** @class */ (function (_super) {
    __extends(OrderSubscriptionService, _super);
    function OrderSubscriptionService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/order-subscriptions/';
        _this.classType = OrderSubscription;
        return _this;
    }
    OrderSubscriptionService.prototype.deleteByOrders = function (orderIds) {
        return this.httpRestService.deleteWithOptions(this.apiUrl + 'delete', { params: { orderIds: orderIds.join(",") } })
            .pipe(map(function (items) { return plainToClass(OrderSubscription, items); }));
    };
    OrderSubscriptionService.prototype.createByOrderIds = function (orderIds, statuses, additionalStatuses) {
        return this.httpRestService.post(this.apiUrl + 'create-by-orders', null, {
            params: {
                orderIds: orderIds.join(","),
                statuses: statuses.join(","),
                additionalStatuses: additionalStatuses.map(function (s) { return s.id; }).join(",")
            }
        })
            .pipe(map(function (items) { return plainToClass(OrderSubscription, items); }));
    };
    OrderSubscriptionService.prototype.findByOrderIds = function (orderIds) {
        return this.httpRestService.getWithOptions(this.apiUrl + 'find-by-order-ids', null, {
            orderIds: orderIds.join(",")
        }).pipe(map(function (items) { return plainToClass(OrderSubscription, items); }));
    };
    OrderSubscriptionService.prototype.findCustomSubscriptionsByOrderIds = function (orderIds) {
        return this.httpRestService.getWithOptions(this.apiUrl + 'find-custom-subscriptions', null, { orderIds: orderIds.join(",") })
            .pipe(map(function (items) { return plainToClass(OrderSubscription, items); }));
    };
    OrderSubscriptionService.prototype.getAvailableStatuses = function () {
        return this.httpRestService.getWithHeader(this.apiUrl + 'available-statuses', null);
    };
    OrderSubscriptionService.prototype.saveOrderSubscription = function (orderSubscriptions, email) {
        var body = orderSubscriptions.map(function (os) {
            return __assign({}, os, { 
                //        customEmail: email,
                order: { id: os.order.id } });
        });
        var params = email ? this.httpUtils.getHTTPParams({ email: email }) : undefined;
        var options = { headers: this.httpUtils.getHTTPHeaders(), params: params };
        return this.httpRestService.post(this.apiUrl + 'subscribe', body, options)
            .pipe(map(function (items) { return plainToClass(OrderSubscription, items); }));
    };
    OrderSubscriptionService.prototype.addOrderCustomSubscriptions = function (orderIds, emails) {
        var params = this.httpUtils.getHTTPParams({ ids: orderIds });
        var options = { headers: this.httpUtils.getHTTPHeaders(), params: params };
        return this.httpRestService.post(this.apiUrl + 'add-custom-subscriptions', emails, options)
            .pipe(map(function (items) { return plainToClass(OrderSubscription, items); }));
    };
    return OrderSubscriptionService;
}(BaseEntityService));
export { OrderSubscriptionService };
