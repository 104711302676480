import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {BaseInputComponent} from "../../../../base/base-input/base-input.component";
import {Driver} from "../../../../../models";
import {NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgModel} from "@angular/forms";
import {DriverService} from "../../../../../../../services/driver-service";
import {NgSelectSearchParams} from "../../../../../../settings/util/ng-select-search-params";
import {Size} from "../../../../../../../common/oms-types";
import {PageResult} from "../../../../../models/query-models/page-result";
import {UserService} from "../../../../../services/user.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'oms-driver-search',
  templateUrl: './driver-search.component.html',
  styleUrls: ['./driver-search.component.scss']
})
export class DriverSearchComponent extends BaseInputComponent<Driver | Driver[]> implements OnInit {

  @Input() multiple: boolean = false;
  @Input() size: Size = Size.DEFAULT;
  @Input() public value: Driver | Driver[];
  @Output() public valueChange: EventEmitter<Driver | Driver[]> = new EventEmitter<Driver | Driver[]>();
  @Output() searchChange: EventEmitter<Driver | Driver[]> = new EventEmitter<Driver | Driver[]>();

  @ViewChild(NgModel) model: NgModel;
  public search: NgSelectSearchParams<Driver>;

  private searchTimer;
  private searchTimeout: number = 1000;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
              public service: DriverService,
              public userService: UserService) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    this.search = new NgSelectSearchParams<Driver>((text) => {
      return this.userService.findUsersByRoles(['ROLE_DRIVER'], true, text).pipe(map((response) => {
        return PageResult.fromArray(response.content.filter(user => user.driver).map(user => user.driver));
        // response.content.forEach(driver => driver['label'] = driver.fullName);
      }));
    });
  }

  public onSelect(event) {
    this.valueChange.emit(event);

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.searchChange.emit(event);
    }, this.searchTimeout);
  }

}
