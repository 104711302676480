var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AbstractEntityListComponent } from "../../../../modules/settings/pages/abstract-entity-list.component";
import { OmsAlertsService } from "../../../../modules/shared/components/oms-alerts/oms-alerts.service";
import { OmsDialogsService } from "../../oms-dialogs";
import { CbpStatusService } from "../../../../modules/shared/services/stb-status/cbp.status.service";
import { CbpStatusDefaultColumnsService } from "../../../../common/cbp-status-default-columns.service";
import { FileUploadService } from "../../../../services/file.upload.service";
import { FilterPageRequest } from "../../../../modules/shared/models/filter.page.request";
import { FilterSearchColumn } from "../../../../modules/shared/models/filter.search.column";
import { SearchSortRequest } from "../../../../modules/shared/models/search.sort.request";
var CbpStatusTableComponent = /** @class */ (function (_super) {
    __extends(CbpStatusTableComponent, _super);
    function CbpStatusTableComponent(dialog, cdr, alerts, dialogs, cbpStatusService, cbpStatusDefaultColumnsService, fileUploadService) {
        var _this = _super.call(this, cdr, alerts, cbpStatusService, dialogs, fileUploadService) || this;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.alerts = alerts;
        _this.dialogs = dialogs;
        _this.cbpStatusService = cbpStatusService;
        _this.cbpStatusDefaultColumnsService = cbpStatusDefaultColumnsService;
        _this.fileUploadService = fileUploadService;
        _this.specificRowClassHandler = [
            {
                field: "inSystem",
                value: "false",
                class: "table-row-missed"
            }
        ];
        _this.cbpStatuses = [];
        _this.defaultSortColumn = "transactionDate";
        _this.sub = [];
        return _this;
    }
    CbpStatusTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columns = this.cbpStatusDefaultColumnsService.getCbpColumns();
        switch (this.objectType) {
            case "MASTER":
                this.sub.push(this.cbpStatusService.findAllByMasterId(this.objectId, this.uscsFsnStatus).subscribe(function (cbpStatuses) { return _this.cbpStatuses = cbpStatuses; }));
                break;
            case "ORDER":
                this.sub.push(this.cbpStatusService.findAllByOrderId(this.objectId).subscribe(function (cbpStatuses) { return _this.cbpStatuses = cbpStatuses; }));
                break;
        }
    };
    CbpStatusTableComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.sub.forEach(function (s) { return s.unsubscribe(); });
    };
    CbpStatusTableComponent.prototype.getTitle = function () {
        return 'CBP Statuses';
    };
    CbpStatusTableComponent.prototype.createDefaultRequest = function (searchField) {
        var filterByMaster = new FilterSearchColumn(searchField, '' + this.objectId, null, null);
        var filterByColumns = [filterByMaster];
        if (this.uscsFsnStatus) {
            filterByColumns.push(new FilterSearchColumn('hasPart', 'yes', null, null));
            filterByColumns.push(new FilterSearchColumn('uscsFsnStatus', this.uscsFsnStatus, null, null));
        }
        return new FilterPageRequest(1, 999, '', new SearchSortRequest(this.defaultSortColumn, true), filterByColumns);
    };
    CbpStatusTableComponent.prototype.generateExcel = function () {
        var searchField = "";
        switch (this.objectType) {
            case "MASTER":
                searchField = "master.id";
                break;
            case "ORDER":
                searchField = "order.id";
                break;
        }
        this.request = this.createDefaultRequest(searchField);
        this.downloadExcel();
    };
    CbpStatusTableComponent.prototype.getRowClass = function (row) {
        if (row.hawb && !row.order) {
            return 'warning';
        }
        return '';
    };
    return CbpStatusTableComponent;
}(AbstractEntityListComponent));
export { CbpStatusTableComponent };
