var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RestService } from "../rest.service";
import { HttpUtilsService } from "../http-utils.service";
import { Airport } from "../../modules/shared/models/air/airport";
import { BaseEntityService } from '../base/base-entity.service';
var AirportService = /** @class */ (function (_super) {
    __extends(AirportService, _super);
    function AirportService(httpRestService, httpUtils) {
        var _this = _super.call(this, httpRestService, httpUtils) || this;
        _this.httpRestService = httpRestService;
        _this.httpUtils = httpUtils;
        _this.apiUrl = '/oms/airport/';
        _this.classType = Airport;
        return _this;
    }
    AirportService.prototype.findByFilterPromise = function (filterPageRequest, active) {
        var _this = this;
        if (active === void 0) { active = true; }
        return new Promise(function (result, reject) {
            return _this.httpRestService.post(_this.apiUrl + 'findByFilters', filterPageRequest, { params: { active: active } }).subscribe(function (page) {
                result(page.content);
            }, function (error) {
                console.error('error', error);
            });
        });
    };
    return AirportService;
}(BaseEntityService));
export { AirportService };
