import { EventEmitter, OnInit } from '@angular/core';
import { WebcamUtil } from "ngx-webcam";
import { Subject } from "rxjs/Subject";
import { UserUtil } from "../../../modules/settings/util/user.util";
import { assigned } from "../../../_helpers/utils";
var OmsCameraComponent = /** @class */ (function () {
    function OmsCameraComponent() {
        this.title = 'Take Picture';
        this.pictureTaken = new EventEmitter();
        this.images = [];
        this.imagesChange = new EventEmitter();
        this.docType = null;
        this.docTypeChange = new EventEmitter();
        this.submit = new EventEmitter();
        this.docTypes = [];
        this.viewLoading = false;
        this.loadingAfterSubmit = false;
        this.showWebcam = true;
        this.allowCameraSwitch = true;
        this.multipleWebcamsAvailable = false;
        //  public mobHeight: any;
        //  public mobWidth: any;
        this.videoOptions = {
            width: { min: 640, ideal: 2048 /*1920*/, max: 2048 /*4000*/ },
            height: { min: 480, ideal: 1536 /*1080*/, max: 1536 /*3000*/ },
            facingMode: "environment"
        };
        this.errors = [];
        // webcam snapshot trigger
        this.trigger = new Subject();
        // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
        this.nextWebcam = new Subject();
        //    this.mobHeight = window.innerHeight - window.innerHeight / 5;
        //    this.mobWidth = window.innerWidth;
    }
    OmsCameraComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showWebcam = true;
        WebcamUtil.getAvailableVideoInputs()
            .then(function (mediaDevices) {
            _this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            _this.viewLoading = false;
        });
        this.currentUser = UserUtil.getCurrentUser();
    };
    OmsCameraComponent.prototype.triggerSnapshot = function () {
        this.trigger.next();
        this.showWebcam = false;
    };
    OmsCameraComponent.prototype.onSubmit = function () {
        this.submit.emit(this.images);
    };
    OmsCameraComponent.prototype.onDeleteImage = function () {
        if (this.currentImage) {
            //      this.showWebcam = !this.showWebcam;
            var index = this.images.indexOf(this.currentImage);
            this.images.removeAll(this.currentImage);
            this.currentImage = this.images[index] || this.images[0];
            this.imagesChange.emit(this.images);
        }
    };
    OmsCameraComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
    };
    OmsCameraComponent.prototype.showNextWebcam = function (directionOrDeviceId) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    };
    OmsCameraComponent.prototype.handleImage = function (image) {
        console.info('received webcam image', image);
        this.images.push(image);
        this.imagesChange.emit(this.images);
        this.currentImage = image;
        this.pictureTaken.emit(image);
    };
    OmsCameraComponent.prototype.cameraWasSwitched = function (deviceId) {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    };
    Object.defineProperty(OmsCameraComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OmsCameraComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    OmsCameraComponent.prototype.hasImages = function () {
        return this.images && this.images.length > 0;
    };
    OmsCameraComponent.prototype.getImagesAdData = function () {
        return this.hasImages() ? this.images.map(function (i) { return i.imageAsDataUrl; }) : [];
    };
    OmsCameraComponent.prototype.docTypeChanged = function (docType) {
        if (assigned(docType)) {
            this.docType = docType;
            this.docTypeChange.emit(this.docType);
        }
    };
    return OmsCameraComponent;
}());
export { OmsCameraComponent };
