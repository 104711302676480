<div class="card-data">
    <div class="card" *ngFor="let options of filterOptions">
        <oms-report-filter [filters]="filters" [options]="options">
        </oms-report-filter>
    </div>
</div>

<hr class="form-separator"/>

<div class="row">
  <div class="col-xs-6">
    <oms-custom-search [multiple] = "true" [searchable]="true" [service]="customerService" label="Customer"
                       [(ngModel)]="customer" [sort-by]="'name'" (changing)="onCustomerChange()">
      <ng-container group-before>
        <!--mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon-->
        <i class="fa fa-user"></i>
      </ng-container>
    </oms-custom-search>
  </div>


  <div class="col-xs-6">
    <oms-custom-search  [multiple] = "true" [searchable]="true" [service]="freightForwarderService" label="F.Forwarder"
                        [(ngModel)]="freightForwarder" [sort-by]="'name'" (changing)="onFreightForwarderChange()">
      <ng-container group-before>
        <mat-icon style="font-size: 18px;" [svgIcon]="'icon-check-list'"></mat-icon>
      </ng-container>
    </oms-custom-search>
  </div>



</div>

<hr class="form-separator"/>

<div class="row form-group">
  <div class="col-md-6">
    <oms-checkbox label="Masters Only (No Order Output)" [(checked)]="mastersOnly"></oms-checkbox>
  </div>
  <div class="col-md-6">
    <oms-checkbox label="Created or Updated in Current Year (YTD)" [(checked)]="updatedYtd"></oms-checkbox>
  </div>
</div>

<div class="row form-group">
  <div class="col-md-6">
  </div>
  <div class="col-md-6">
    <oms-custom-search [(ngModel)]="mastersBilled" [inline]="true" [multiple]="false" [label]="'Billed'"
                       [items]="[filterYes, filterNo]">
    </oms-custom-search>
  </div>
</div>



<!--div *ngFor="let options of filterOptions">
  <oms-report-filter [filters]="filters" [options]="options">
  </oms-report-filter>
</div-->
