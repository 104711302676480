import {
  AfterViewInit,
  Component,
  EventEmitter, HostBinding, HostListener,
  Inject,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {NgxSpinnerService} from 'ngx-spinner';
import {PerfectScrollbarComponent} from "ngx-perfect-scrollbar";
import {ButtonConfig, DialogCloseEvent, DialogResult, ModalResult} from "../oms-dialogs.types";
import {Subscription} from "rxjs";

@Component({
  selector: 'oms-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent implements OnDestroy, OnInit, AfterViewInit {
  private static stack: MatDialogRef<any>[] = [];

  @Input('height') height;
  @Input('width') width;
  @Input('min-height') minHeight;
  @Input('min-width') minWidth;
  @Input('max-height') maxHeight;
  @Input('max-width') maxWidth;

  @Input('draggable') draggable: boolean = true;
  @Input('sizable') sizable: boolean = true;
  @Input('no-padding') no_padding: boolean = false;
  @Input('icon') _icon: string;
  @Input('svg') svg_icon: string;
  @Input() visible: boolean = true;
  @Input('title') _title: string;
  @Input('readonly') _readonly: boolean = false;
  @Input('can-ok') canOk: boolean = true;
  @Input('auto-close') autoClose = true;
  @Input('store-position') storePosition = true;
  @Input('show-label') showLabel: boolean = true;
  @Input('show-dialog-btns') showDialogBtns: boolean = true;
  @Input('show-footer') showFooter: boolean = true;
  @Input('flex-content') flexContent: boolean = false;

  @Input('loading') set loading(value: boolean) {
    this.viewLoading = value;
    if (value) {
      this.spinner.show('spModalDialog').then();
    } else {
      this.spinner.hide('spModalDialog').then();
    }
  }
  @Output('ok') eventOK: EventEmitter<DialogCloseEvent> = new EventEmitter<DialogCloseEvent>(false);
  @Output('cancel') eventCancel: EventEmitter<DialogCloseEvent> = new EventEmitter<DialogCloseEvent>(false);
  @ViewChild(PerfectScrollbarComponent) scroll: PerfectScrollbarComponent;

  @Input() btnOk: ButtonConfig = {icon: 'fa fa-check', caption: 'OK'};
  @Input() btnCancel: ButtonConfig = {icon: 'fa fa-times', caption: 'Cancel'};

  private sub: Subscription[] = [];
  loadingAfterSubmit = false;
  viewLoading = false;
  public sbDisableOnInit: boolean = true;

  constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public spinner: NgxSpinnerService,
        public dialogRef: MatDialogRef<BaseDialogComponent, DialogResult<any>>) {

    this.dialogRef.disableClose = true; // disable closing by background click;
    this.sub.push(this.dialogRef.afterOpen().subscribe(() => BaseDialogComponent.stack.push(this.dialogRef)));
    this.sub.push(this.dialogRef.afterClosed().subscribe(() => BaseDialogComponent.stack.removeAll(this.dialogRef)));
  }

  public ngOnDestroy() {
    BaseDialogComponent.stack.removeAll(this.dialogRef);
    this.sub.forEach((s) => s.unsubscribe());
  }

  @HostBinding('class')
  get class() {
    return this.sizable ? 'sizable' : '';
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent) {
    console.log(BaseDialogComponent.stack, this.dialogRef);
    if (this.dialogRef === BaseDialogComponent.stack.last()) {
      event.stopImmediatePropagation();
      this.onCancel();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    if (this.dialogRef === BaseDialogComponent.stack.last()) {
      // Browser Back button Handler
      event.stopImmediatePropagation();
      window.history.forward();
      this.onCancel();
    }
  }

  onOK() {
    console.log('OK Button', this.btnOk);
    if (this.btnOk && this.btnOk.handler && typeof this.btnOk.handler === 'function') {
      this.btnOk.handler(); //.bind(this);
    } else {
      if (this.eventOK.observers && this.eventOK.observers.length > 0) {
        let event: DialogCloseEvent = {canClose: this.autoClose, result: {result: ModalResult.OK, data: this.data}};
        this.eventOK.emit(event);
        if (event.canClose) {
          this.dialogRef.close(event.result);
        }
      }
    }
  }

  onCancel() {
    if (this.btnCancel && this.btnCancel.handler) {
      this.btnCancel.handler().bind(this);
    } else {
      let event: DialogCloseEvent = {canClose: true, result: {result: ModalResult.CANCEL, data: this.data}};
      this.eventCancel.emit(event);
      if (event.canClose) {
        this.dialogRef.close(event.result);
      }
    }
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    setTimeout(() => {this.sbDisableOnInit = false; }, 500);
  }

}
