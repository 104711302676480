import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {TruckDialogComponent} from '../truck-dialog/truck-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Driver} from '../../../modules/shared/models/driver';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {StringInputComponent} from '../../../modules/shared/components/common/input/string-input/string-input.component';
import {User} from "../../../modules/shared/models";

@Component({
  selector: 'app-driver-dialog',
  templateUrl: './driver-dialog.component.html',
  styleUrls: ['./driver-dialog.component.scss']
})
export class DriverDialogComponent implements OnInit, AfterViewInit {

  public driver: Driver;
  form: FormGroup;
  hasFormErrors: boolean = false;
  viewLoading: boolean = false;
  loadingAfterSubmit: boolean = false;
  public readonly: boolean = false;
  adminEdit: boolean = false;
  @ViewChildren(StringInputComponent) inputs: QueryList<StringInputComponent>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TruckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.readonly = data.readonly;
  }

  createForm() {
    this.driver.user = this.driver.user || new User();
    this.form = new FormGroup({
        'firstName': new FormControl(this.driver.user.firstName, [Validators.required]),
        'lastName': new FormControl(this.driver.user.lastName, [Validators.required]),
        'email': new FormControl(this.driver.user.email, []),
        'username': new FormControl(this.driver.username, []),
        'pin': new FormControl(this.driver.pin, []),
      }
    );


    /*    this.fb.group({
          firstName: [this.driver.firstName],
          lastName: [this.driver.lastName],
          email: [this.driver.email]
        }); */
  }

  onOk(event) {
    this.hasFormErrors = false;
    this.loadingAfterSubmit = false;
    const controls = this.form.controls;

    /** check form */
    if (this.form.invalid) {

      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
      this.inputs.forEach(i => i.model.control.markAsTouched());

      this.hasFormErrors = true;
      event.canClose = false;
    }

    this.driver.user.firstName = this.form.controls['firstName'].value;
    this.driver.user.lastName = this.form.controls['lastName'].value;
    this.driver.user.email = this.form.controls['email'].value;
    this.driver.username = this.form.controls['username'].value;
    this.driver.pin = this.form.controls['pin'].value;

  }

  ngOnInit(): void {
    this.driver = this.data.driver;
    this.adminEdit = this.data.adminEdit;
    this.createForm();
    // this.form.reset();
  }

  ngAfterViewInit(): void {
    console.log(this.inputs);
//    this.form.markAsTouched();
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  controlErrors(controlName: string): ValidationErrors | null {
    const control = this.form.controls[controlName];
    const result = control.errors;
    return result;
  }


}
