/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i3 from "ngx-perfect-scrollbar";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./comment-dialog.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../oms-alerts/oms-alerts.service";
var styles_CommentDialogComponent = [i0.styles];
var RenderType_CommentDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentDialogComponent, data: {} });
export { RenderType_CommentDialogComponent as RenderType_CommentDialogComponent };
function View_CommentDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comments required"]))], null, null); }
export function View_CommentDialogComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "perfect-scrollbar", [["style", "max-height: 60vh;"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i2.View_PerfectScrollbarComponent_0, i2.RenderType_PerfectScrollbarComponent)), i1.ɵdid(1, 507904, null, 0, i3.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 35, "div", [["class", "oms-modal widget"], ["style", "width: 500px"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "m-portlet--body-progress-overlay": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "widget-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-comment"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Manifest Item Comments "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "btn-group widget-header-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "btn-borderless btn-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-times dialog-header-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 18, "form", [["class", "widget-content"], ["novalidate", ""], ["style", "min-height: 250px;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i5.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(14, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(16, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 13, "div", [["class", "form-row col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 12, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "label", [["class", "control-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comment"])), (_l()(), i1.ɵeld(21, 0, null, null, 7, "textarea", [["class", "form-control"], ["formControlName", "comments"], ["placeholder", "Comment"], ["rows", "5"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 24)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 24)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { "form-control-invalid": 0 }), i1.ɵdid(24, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(26, 671744, null, 0, i5.FormControlName, [[3, i5.ControlContainer], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_j]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlName]), i1.ɵdid(28, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentDialogComponent_1)), i1.ɵdid(30, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 6, "div", [["class", "widget-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 4, "div", [["class", "dialog-btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = "oms-modal widget"; var currVal_3 = _ck(_v, 4, 0, false); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_11 = _co.form; _ck(_v, 14, 0, currVal_11); var currVal_19 = "form-control"; var currVal_20 = _ck(_v, 23, 0, _co.isControlInvalid("comments")); _ck(_v, 22, 0, currVal_19, currVal_20); var currVal_21 = "comments"; _ck(_v, 26, 0, currVal_21); var currVal_22 = _co.isControlInvalid("address"); _ck(_v, 30, 0, currVal_22); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).autoPropagation; var currVal_1 = i1.ɵnov(_v, 1).scrollIndicators; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 16).ngClassValid; var currVal_9 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 12, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 28).ngClassUntouched; var currVal_13 = i1.ɵnov(_v, 28).ngClassTouched; var currVal_14 = i1.ɵnov(_v, 28).ngClassPristine; var currVal_15 = i1.ɵnov(_v, 28).ngClassDirty; var currVal_16 = i1.ɵnov(_v, 28).ngClassValid; var currVal_17 = i1.ɵnov(_v, 28).ngClassInvalid; var currVal_18 = i1.ɵnov(_v, 28).ngClassPending; _ck(_v, 21, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }); }
export function View_CommentDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oms-comment-dialog", [], null, null, null, View_CommentDialogComponent_0, RenderType_CommentDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.CommentDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA, i5.FormBuilder, i8.OmsAlertsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentDialogComponentNgFactory = i1.ɵccf("oms-comment-dialog", i6.CommentDialogComponent, View_CommentDialogComponent_Host_0, {}, {}, []);
export { CommentDialogComponentNgFactory as CommentDialogComponentNgFactory };
