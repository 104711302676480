import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseEntity, Order} from '../modules/shared/models';
import {RestService} from './rest.service';
import {HttpUtilsService} from './http-utils.service';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';
import {onAfterLoad} from "./oms-decorators";
import {HttpParams} from "@angular/common/http";


@Injectable()
export class PublicApiService {
  public apiUrl = '/public-api/status-info/';

  constructor(public httpRestService: RestService, public httpUtils: HttpUtilsService) {
  }

  // tslint:disable-next-line:no-shadowed-variable
  private static afterLoad<T extends BaseEntity>(entity: T): T {
    // console.log('After Load', entity);
    return onAfterLoad(entity);
  }


  public orderInfoByHawb(hawbNumber: string, ffg): Promise<Order[]> {
    const params = new HttpParams().set('ffg', ffg);
    return this.httpRestService.getWithOptions<Order[]>(this.apiUrl + `order-info-by-hawb/${hawbNumber}`,
      this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((o) => plainToClass(Order, o).map((item) => PublicApiService.afterLoad(item))))
      .toPromise();
  }

  public orderInfoByRef3(ref3: string, ffg): Promise<Order[]> {
    const params = new HttpParams().set('ffg', ffg);
    return this.httpRestService.getWithOptions<Order[]>(this.apiUrl + `order-info-by-ref3/${ref3}`,
      this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((o) => plainToClass(Order, o).map((item) => PublicApiService.afterLoad(item))))
      .toPromise();
  }

  public orderInfoById(ffg): Promise<Order[]> {
    const params = new HttpParams().set('ffg', ffg);
    return this.httpRestService.getWithOptions<Order[]>(this.apiUrl + `order-info-by-id/`,
      this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((o) => plainToClass(Order, o).map((item) => PublicApiService.afterLoad(item))))
      .toPromise();
  }



  public orderInfoByMaster(masterId: number, ffg): Promise<Order[]> {
    const params = new HttpParams().set('ffg', ffg);
    return this.httpRestService.getWithOptions<Order[]>(this.apiUrl + `order-info-by-master/${masterId}`,
      this.httpUtils.getHTTPHeaders(), params)
      .pipe(map((o) => plainToClass(Order, o).map((item) => PublicApiService.afterLoad(item))))
      .toPromise();
  }


  public searchMastersByMawb(mawb: string, ffg): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const params = new HttpParams().set('by', mawb).set('count', '1').set('ffg', ffg);
    return this.httpRestService.getWithOptions<any>(this.apiUrl + 'searchMasterByMawb', httpHeader, params);
  }


}
