import {Exclude, Type} from "class-transformer";

export enum ScheduleType {
  DAILY = 0,
  WEEKLY = 1,
  MONTHLY = 2,
  HOURLY = 3,
}

export class Schedule {
  public scheduleType: ScheduleType = ScheduleType.DAILY;

  public hourPeriod: number = 0;
  public dayNumber: number = 1;
  public weekNumber: number = 1;
  public weekDays: boolean[] = [false, false, false, false, false, false, false];
  public monthlyType: number = 0;
  public monthNumber: number = 1;
  public dayOfMonthNumber: number = 1;
  public dayOfWeekInMonthNumber: number = 0;
  public dayOfWeek: number = 1;
  public hour: number = 0;
  public minute: number = 0;
  public timeZone: string = "America/New_York";

  @Type(() => Date)
  public dateFrom: Date = new Date();

  @Type(() => Date)
  public dateTo: Date;


  @Exclude()
  public get isHourly(): boolean {
    return this.scheduleType === ScheduleType.HOURLY;
  }

  @Exclude()
  public get isDaily(): boolean {
    return this.scheduleType === ScheduleType.DAILY;
  }

  @Exclude()
  public get isWeekly(): boolean {
    return this.scheduleType === ScheduleType.WEEKLY;
  }

  @Exclude()
  public get isMonthly(): boolean {
    return this.scheduleType === ScheduleType.MONTHLY;
  }


}
